import { NgModule } from '@angular/core';
import { TreatmentPlanComponent } from './treatment-plan.component';
import { TreatmentPlanDrawToolComponent } from './treatment-plan-draw-tool/treatment-plan-draw-tool.component';
import { PlannedTreatmentsListComponent } from './planned-treatments-list/planned-treatments-list.component';
import { TreatmentQuestionsComponent } from './treatment-questions/treatment-questions.component';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { SharedModule } from '../../../../../shared/shared.module';
import { ProductRecommendationListComponent } from './product-recommendation-list/product-recommendation-list.component';
import { TreatmentPlanFormService } from '@services/treatment-planning/treatment-plan-form.service';
import { TreatmentPlanQuestionService } from '@services/treatment-planning/treatment-plan-question.service';
import { PlannedTreatmentsComponent } from './planned-treatments-list/planned-treatments/planned-treatments.component';
import { PlannedTreatmentMultipleComponent } from './planned-treatments-list/planned-treatment-multiple/planned-treatment-multiple.component';

import { GridModule } from '@progress/kendo-angular-grid';
@NgModule({
  declarations: [
    TreatmentPlanComponent,
    PlannedTreatmentsListComponent,
    ProductRecommendationListComponent,
    TreatmentQuestionsComponent,
    PlannedTreatmentsComponent,
    PlannedTreatmentMultipleComponent,
    TreatmentPlanDrawToolComponent
  ],
  imports: [
    SharedModule,
    GridModule
  ],
  providers: [
    TreatmentPlanService,
    TreatmentPlanFormService,
    TreatmentPlanQuestionService
  ],
  exports: [
    TreatmentPlanComponent
  ]
})
export class TreatmentPlanModule { }
