import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SatDatepickerInputEvent } from 'saturn-datepicker/datepicker';

@Component({
  selector: 'app-date-select-dialog',
  templateUrl: './date-select-dialog.component.html',
  styleUrls: ['./date-select-dialog.component.less']
})
export class DateSelectDialogComponent implements OnInit {
  public dialog: MatDialog;
  title: string;
  content: string;
  inputLabel: string;
  confirmButtonText: string;
  maximumDate = new Date(2077, 9, 15);
  minimumDate = new Date(1900, 1, 1);
  date: Date = new Date(Date.now());
  minimumDuration = 15;

  constructor(public dialogRef: MatDialogRef<DateSelectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.title = this.data.title;
    this.content = this.data.content;
    this.inputLabel = this.data.inputLabel;
    this.confirmButtonText = this.data.confirmButtonText;
    if (this.data.minimumDuration)
      this.minimumDuration = this.data.minimumDuration;
    if (this.data.date)
      this.date = this.data.date;
    if (this.data.minimumDate)
      this.minimumDate = this.data.minimumDate;
    if (this.data.maximumDate)
      this.maximumDate = this.data.maximumDate;
  }

  onCalendarDateChange(event) {
    const eventDate = event.value.toDate() as Date;
    const newDate = new Date(eventDate.getFullYear(),
                             eventDate.getMonth(),
                             eventDate.getDate(),
                             this.date.getHours(),
                             this.date.getMinutes());
    this.date = newDate;
    console.log(this.date.toString());
  }

  getMaximumTime() {
    const maxDate = new Date(this.maximumDate.getFullYear(), this.maximumDate.getMonth(), this.maximumDate.getDate());
    const selectedDate = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
    if (maxDate.getTime() == selectedDate.getTime())
      return this.maximumDate;
   
    else
      return null;
  }
}
