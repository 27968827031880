import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { replaceAll } from '@azure/ms-rest-js/es/lib/util/utils';
import { Clinic } from '@models/clinic';
import { ClinicEmailTemplate, EmailTemplateType } from '@models/clinic-email-template';
import { ServiceOrProduct, ServiceOrProductType } from '@models/communications/service-or-product.model';
import { editorOptions } from '@models/email-editor-options';
import { GooglePlaceSearchResult } from '@models/reviews/google-place-search-result';
import { ClinicReviewSettings, ReviewsScheduleEnum } from '@models/reviews/review-settings';
import { BlobService } from '@services/blob.service';
import { ClinicEmailTemplateService } from '@services/clinic-email-template.service';
import { ClinicProductsService } from '@services/clinic-products.service';
import { ClinicsService } from '@services/clinics.service';
import { CommunicationsService } from '@services/communications.service';
import { ReviewService } from '@services/review.service';
import { ServiceProviderService } from '@services/service-provider.service';
import { ServiceTemplatesService } from '@services/service-templates.service';
import { UsersService } from '@services/users.service';
import { UnlayerEmailEditorComponent } from '@app/shared/components/unlayer-email-editor/unlayer-email-editor.component';
import { Observable, Subject, combineLatest, merge, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'reviews-settings',
  templateUrl: './reviews-settings.component.html',
  styleUrls: ['./reviews-settings.component.less'],
})
export class ReviewsSettingsComponent implements OnInit, OnDestroy {
  private unsub: Subject<void> = new Subject<void>();
  loading = false;
  templateLoading = false;
  form: FormGroup;
  serviceProviders = [];
  serviceOrProductSearchFocus$ = new Subject<string>();
  companySearchFocus$ = new Subject<string>();
  allServicesOrProducts: ServiceOrProduct[] = [];
  selectedServicesOrProducts: ServiceOrProduct[] = [];
  initialized = false;
  selectedTemplateSrc: string = null;
  reviewSettings: ClinicReviewSettings;
  clinicId: number = 0;
  clinic: Clinic;
  reviewsScheduleEnum = ReviewsScheduleEnum;
  companyQueryHelper = '';
  iPad =
    navigator.userAgent.match(/(iPad)/) /* iOS pre 13 */ ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) /* iPad OS 13 */;
  emailTemplates: ClinicEmailTemplate[] = [];
  showSuccessMessage = false;
  @ViewChild(UnlayerEmailEditorComponent) emailEditor: UnlayerEmailEditorComponent;
  editorOptions = editorOptions;
  emailDetailsObj: object;
  emailHtml: String;
  emailEditorEdited: boolean = false;
  developerPolicy = Policies.developer;

  constructor(
    private router: Router,
    private communicationsService: CommunicationsService,
    private providerService: ServiceProviderService,
    private serviceTemplatesService: ServiceTemplatesService,
    private productsService: ClinicProductsService,
    private reviewService: ReviewService,
    private fb: FormBuilder,
    private clinicService: ClinicsService,
    private blobService: BlobService,
    private usersService: UsersService,
    private clinicEmailTemplateService: ClinicEmailTemplateService,
    private zone: NgZone,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.initialized = true;
    this.clinicId = +localStorage.getItem('clinicId');
    this.reviewSettings = await this.reviewService.getClinicReviewSettings(this.clinicId).toPromise();
    this.clinicService.getClinicById(parseInt(localStorage.getItem('clinicId'))).subscribe((clinic) => {
      this.clinic = clinic;
      this.initForm();
      this.loadLookupsAndSettings();
    });
  }

  get f() {
    return this.form.controls;
  }

  initForm() {
    const emailReviews = this.reviewSettings.emailReviewsOn ? true : false;
    const smsReviews = this.reviewSettings.smsReviewsOn ? true : false;
    const googleReviews = this.reviewSettings.googleReviewsOn ? true : false;
    const schedule = this.reviewSettings.reviewsSchedule
      ? this.reviewSettings.reviewsSchedule
      : this.reviewsScheduleEnum.oneTimeOnly;
    const maxFrequency = this.reviewSettings.maxFrequencyInDays ? this.reviewSettings.maxFrequencyInDays : 0;
    const sendReviewsDelayInMinutes = this.reviewSettings.sendReviewsDelayInMinutes
      ? this.reviewSettings.sendReviewsDelayInMinutes
      : 30;
    const highRatingText = this.reviewSettings.highRatingTextContent
      ? this.reviewSettings.highRatingTextContent
      : 'At [clinicname], we’re committed to excellence. Please help us improve the patient experience by leaving us some fast feedback.\n[Review link].';
    const lowRatingText = this.reviewSettings.lowRatingTextContent
      ? this.reviewSettings.lowRatingTextContent
      : "Oh dear! We do our best to make sure all of our patients have positive experiences. It's clear we did not accomplish this in your situation, so we would like to apologize. Please give us the opportunity to make this right so we can further improve how we deal with our patients. Please give us a call or we will reach out to you to discuss this further.";
    var smsTextPreview = this.reviewSettings.reviewsSmsContent;
    smsTextPreview = replaceAll(smsTextPreview, '[FirstName]', 'John');
    smsTextPreview = replaceAll(smsTextPreview, '[PatientName]', 'John Doe');
    smsTextPreview = replaceAll(smsTextPreview, '[ClinicName]', this.clinic.name);
    smsTextPreview = replaceAll(smsTextPreview, '[ClinicPhoneNumber]', String(this.clinic.phoneNumber));
    smsTextPreview = replaceAll(smsTextPreview, '[ClinicEmail]', this.clinic.email);
    smsTextPreview = replaceAll(smsTextPreview, '[ClinicWebsite]', this.clinic.website);
    smsTextPreview = replaceAll(smsTextPreview, '[ReviewLink]', this.reviewSettings.reviewLink);
    smsTextPreview = smsTextPreview + '\nReply OPTOUT to unsubscribe';

    this.form = this.fb.group({
      emailReviewsOn: [emailReviews, Validators.required],
      smsReviewsOn: [smsReviews, Validators.required],
      googleReviewsOn: [googleReviews, Validators.required],
      googlePlaceId: [this.clinic.googlePlaceId],
      googleCompanyName: [this.clinic.googleCompanyName],
      googleAccountId: [this.reviewSettings.googleAccountId],
      googleLocationId: [this.reviewSettings.googleLocationId],
      highRatingTextContent: [highRatingText],
      lowRatingTextContent: [lowRatingText],
      sendReviewsDelayInMinutes: [sendReviewsDelayInMinutes, Validators.min(0)],
      reviewsSchedule: [schedule, Validators.required],
      maxFrequencyInDays: [maxFrequency],
      serviceIds: [this.reviewSettings.serviceIds],
      productIds: [this.reviewSettings.productIds],
      reviewsSmsContent: [this.reviewSettings.reviewsSmsContent],
      reviewsSmsPreview: [{ value: smsTextPreview, disabled: true }],
      reviewLink: [this.reviewSettings.reviewLink],
      emailSubjectLine: new FormControl(this.reviewSettings.emailSubjectLine, Validators.required),
      template: new FormControl(Validators.required),
    });

    //Enable and require placeId if googleReviews is enabled
    const googleCompanyNameControl = this.form.controls['googleCompanyName'];
    this.form.controls['googleReviewsOn'].valueChanges.subscribe({
      next: (googleReviewsOn) => {
        if (googleReviewsOn) {
          googleCompanyNameControl.setValidators(Validators.required);
        } else {
          googleCompanyNameControl.clearValidators();
        }
        googleCompanyNameControl.updateValueAndValidity();
        googleCompanyNameControl.markAllAsTouched();
      },
    });
    googleCompanyNameControl.disable();
    this.form.controls['googleReviewsOn'].updateValueAndValidity();

    // Enable and require maxFrequency if afterXDays is selected
    const maxFrequencyControl = this.form.controls['maxFrequencyInDays'];
    this.form.controls['reviewsSchedule'].valueChanges.subscribe({
      next: (value) => {
        if (ReviewsScheduleEnum.afterXDays) {
          maxFrequencyControl.enable();
          maxFrequencyControl.setValidators([Validators.required, Validators.min(0)]);
        } else {
          maxFrequencyControl.disable();
          maxFrequencyControl.clearValidators();
        }
      },
    });
    this.form.controls['reviewsSchedule'].updateValueAndValidity();

    this.form.controls['reviewsSmsContent'].valueChanges.subscribe({
      next: (value) => {
        value = replaceAll(value, '[FirstName]', 'John');
        value = replaceAll(value, '[PatientName]', 'John Doe');
        value = replaceAll(value, '[ClinicName]', this.clinic.name);
        value = replaceAll(value, '[ClinicPhoneNumber]', String(this.clinic.phoneNumber));
        value = replaceAll(value, '[ClinicEmail]', this.clinic.email);
        value = replaceAll(value, '[ClinicWebsite]', this.clinic.website);
        value = replaceAll(value, '[ReviewLink]', this.form.controls['reviewLink'].value);

        value = value + '\nReply OPTOUT to unsubscribe';
        this.form.controls['reviewsSmsPreview'].setValue(value);
      },
    });

    this.form.controls['reviewLink'].valueChanges.subscribe({
      next: (value) => {
        var text = this.form.controls['reviewsSmsContent'].value;
        text = replaceAll(text, '[FirstName]', 'John');
        text = replaceAll(text, '[PatientName]', 'John Doe');
        text = replaceAll(text, '[ClinicName]', this.clinic.name);
        text = replaceAll(text, '[ClinicPhoneNumber]', String(this.clinic.phoneNumber));
        text = replaceAll(text, '[ClinicEmail]', this.clinic.email);
        text = replaceAll(text, '[ClinicWebsite]', this.clinic.website);
        text = replaceAll(text, '[ReviewLink]', value);
        text = text + '\nReply OPTOUT to unsubscribe';
        this.form.controls['reviewsSmsPreview'].setValue(text);
      },
    });

    this.form
      .get('template')
      .valueChanges.pipe(takeUntil(this.unsub))
      .subscribe((template: ClinicEmailTemplate) => {
        // this.getTemplateDetails();
        this.emailEditor.editor.loadDesign(JSON.parse(template.definition));
        if (this.iPad) this.emailEditor.editor.showPreview('Mobile');
      });
  }

  loadLookupsAndSettings() {
    this.loading = true;
    this.selectedServicesOrProducts = [];
    combineLatest([
      this.clinicEmailTemplateService.getClinicEmailTemplatesByType(EmailTemplateType.Reviews),
      this.providerService.getServiceProviders(),
      this.serviceTemplatesService.getServiceTemplates(),
      this.productsService.getProducts(),
    ])
      .pipe(takeUntil(this.unsub))
      .subscribe(([emailTemplates, serviceProviders, serviceTemplates, products]) => {
        this.populateLookups(emailTemplates, serviceProviders, serviceTemplates, products);
        if (this.reviewSettings.serviceIds) {
          this.reviewSettings.serviceIds.split(',').forEach((id) => {
            const service = this.allServicesOrProducts.find(
              (_) => _.type === ServiceOrProductType.service && _.id === +id
            );
            this.selectedServicesOrProducts.push(service);
          });
        }
        if (this.reviewSettings.productIds) {
          this.reviewSettings.productIds.split(',').forEach((id) => {
            const product = this.allServicesOrProducts.find(
              (_) => _.type === ServiceOrProductType.product && _.id === +id
            );
            this.selectedServicesOrProducts.push(product);
          });
        }
        this.populateEmailSettingsValues();
        this.loading = false;
      });
  }

  populateLookups(emailTemplates, serviceProviders, serviceTemplates, products) {
    this.serviceProviders = serviceProviders;
    this.emailTemplates = emailTemplates;
    this.allServicesOrProducts = [
      ...serviceTemplates.map((st) => {
        const sop = new ServiceOrProduct();
        sop.type = ServiceOrProductType.service;
        sop.id = st.id;
        sop.name = st.serviceName;
        return sop;
      }),
      ...products.map((p) => {
        const sop = new ServiceOrProduct();
        sop.type = ServiceOrProductType.product;
        sop.id = p.productId;
        sop.name = p.name;
        return sop;
      }),
    ];
  }

  populateEmailSettingsValues() {
    if (this.emailEditor?.editor && this.reviewSettings.emailJson && this.reviewSettings.emailJson != '') {
      this.reloadEmailEditor(this.reviewSettings.emailJson, this.reviewSettings.emailHtml);
    }

    let selectedTemplate = this.emailTemplates.find((_) => _.id === this.reviewSettings.createdFromEmailTemplateId);

    this.form.controls['template'].setValue(selectedTemplate, { emitEvent: false });
  }

  async saveChanges() {
    this.loading = true;
    if (!this.form.valid) {
      return;
    }

    const data = Object.assign(this.reviewSettings, this.form.value);
    data.serviceIds = this.selectedServicesOrProducts
      .filter((_) => _.type === ServiceOrProductType.service)
      .map((_) => _.id)
      .join(',');
    data.productIds = this.selectedServicesOrProducts
      .filter((_) => _.type === ServiceOrProductType.product)
      .map((_) => _.id)
      .join(',');
    delete data.googlePlaceId;

    data.createdFromEmailTemplateId = this.form.controls['template'].value.id;
    data.emailSubjectLine = this.form.controls['emailSubjectLine'].value;

    this.emailEditor.exportHtml((htmlObj: any) => {
      this.emailEditor.editor.saveDesign((definition) => {
        data.emailHtml = htmlObj.html;
        data.emailJson = JSON.stringify(definition);
        this.saveClinicReviewSettings(data);
      });
    });
  }

  async saveClinicReviewSettings(data: ClinicReviewSettings) {
    const reviewSettingsResult = await this.reviewService
      .updateClinicSettings(data)
      .toPromise()
      .catch((error) => {
        console.error('Error updating review settings: %o', error);
      });
    this.reviewSettings = reviewSettingsResult as ClinicReviewSettings;

    const placeId = this.form.controls['googlePlaceId'].value;
    const googleCompanyName = this.form.controls['googleCompanyName'].value;
    if (placeId != this.clinic.googlePlaceId || googleCompanyName != this.clinic.googleCompanyName) {
      const editedClinic = Object.assign({}, this.clinic);
      editedClinic.googlePlaceId = placeId;
      editedClinic.googleCompanyName = googleCompanyName;
      await this.clinicService
        .updateClinic(editedClinic)
        .toPromise()
        .catch((error) => {
          console.error("Error updating clinic's Google Place ID: %o", error);
        });
      this.clinic = editedClinic;
      this.clinicService.clinicUpdated.next(editedClinic);
    }
    this.loading = false;
    this.initialized = false;
    this.emailEditorEdited = false;
    this.initForm();
    this.loadLookupsAndSettings();
    this.form.markAsPristine();
  }

  async cancelUpdate() {
    this.loading = true;
    this.initialized = false;
    this.emailEditorEdited = false;
    this.reviewSettings = await this.reviewService.getClinicReviewSettings(this.clinicId).toPromise();
    this.initForm();
    this.loadLookupsAndSettings();
    this.form.markAsPristine();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  searchCompanyName = (text$: Observable<string>): Observable<GooglePlaceSearchResult[]> => {
    const debouncedText$ = text$ == null ? of('') : text$.pipe(debounceTime(300), distinctUntilChanged());
    const inputFocus$ = this.companySearchFocus$;

    return merge(debouncedText$, inputFocus$).pipe(
      switchMap((searchString) =>
        searchString == ''
          ? of([])
          : this.reviewService.searchGooglePlaceCompanies(searchString + this.companyQueryHelper)
      )
    );
  };
  companyNameInputFormatter = (x: GooglePlaceSearchResult) => x.name;
  companyNameResultFormatter = (x: GooglePlaceSearchResult) => {
    let res = x.name;
    if (x.formatted_address) {
      const chunks = x.formatted_address.split(', ');
      res += ' ' + chunks[1] + ', ' + chunks[2];
    }
    return res;
  };
  selectCompanyName(company: GooglePlaceSearchResult) {
    const placeControl = this.form.controls['googlePlaceId'];
    placeControl.patchValue(company.place_id);
    placeControl.updateValueAndValidity();
    placeControl.markAsTouched();
    placeControl.markAsDirty();
    const googleNameControl = this.form.controls['googleCompanyName'];
    googleNameControl.patchValue(company.name);
    googleNameControl.updateValueAndValidity();
    googleNameControl.markAllAsTouched();
    googleNameControl.markAsDirty();
  }

  searchServiceOrProduct = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.serviceOrProductSearchFocus$;
    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        term === ''
          ? this.allServicesOrProducts
          : this.allServicesOrProducts.filter((v) => v.name?.toLowerCase().indexOf(term.toLowerCase()) > -1)
      )
    );
  };
  serviceOrProductInputFormatter = (x: { name: string }) => null;
  serviceOrProductResultFormatter = (x: { name: string }) => x.name;
  addServiceOrProduct(sop) {
    if (this.selectedServicesOrProducts.indexOf(sop) === -1) {
      this.selectedServicesOrProducts.push(sop);
      this.form.markAsDirty();
    }
  }
  deleteServiceOrProduct(sop) {
    const i = this.selectedServicesOrProducts.indexOf(sop);
    if (i !== -1) {
      this.selectedServicesOrProducts.splice(i, 1);
      this.form.markAsDirty();
    }
  }

  sendTest() {
    this.router.navigate(['/reviews', { outlets: { 'action-panel': ['send-test-visit-reminder'] } }]);
  }

  loadEditor(event) {
    if (this.emailDetailsObj) this.emailEditor.editor.loadDesign(this.emailDetailsObj);
    if (this.iPad) this.emailEditor.editor.showPreview('Mobile');
  }

  editorReady(event) {
    this.zone.run(() => (this.templateLoading = false));
    this.emailEditor.editor.addEventListener('design:updated', (data) => {
      this.emailEditorEdited = true;
      this.form.markAsDirty();
    });
    this.emailEditor.editor.addEventListener('design:loaded', (data) => {
      this.zone.run(() => (this.templateLoading = false));
    });
  }

  reloadEmailEditor(definition: string, html: string): void {
    this.templateLoading = true;
    this.emailDetailsObj = JSON.parse(definition);
    this.emailEditor.editor.loadDesign(this.emailDetailsObj);
    if (this.iPad) this.emailEditor.editor.showPreview('Mobile');
    if (html) this.emailHtml = html;
    this.templateLoading = false;
  }

  revertToOriginalTemplate() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '330px',
      data: {
        title: 'Revert to Original Template',
        content:
          'Are you sure you want to revert to the original email template definition? All your changes will be lost.',
        confirmButtonText: 'Yes',
        showCancel: true,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') {
          this.loading = true;
          var selectedTemplateId = this.form.controls['template'].value.id;
          let selectedTemplate = this.emailTemplates.find((_) => _.id === selectedTemplateId);
          if (this.emailEditor?.editor && selectedTemplate) {
            this.reloadEmailEditor(selectedTemplate.definition, selectedTemplate.html);
          }
          this.loading = false;
        }
      });
  }

  onFocus(event: Event, subject: Subject<any>) {
    const value = (event.target as HTMLInputElement).value;
    subject.next(value);
  }
}
