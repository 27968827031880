<ng-template #upperStars let-fill="fill">
  <span class="star" [class.full]="fill === 100">
    <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
  </span>
</ng-template>
<ng-template #lowerStars let-fill="fill">
  <span class="starSmall" [class.full]="fill === 100">
    <span class="halfSmall" [style.width.%]="fill">&#9733;</span>&#9733;
  </span>
</ng-template>

<div *ngIf="state" [ngStyle]="{ height: 'calc(100% - 130px)' }">
  <ng-template #template let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
  </ng-template>
  <div
    kendoTooltip
    showOn="none"
    [tooltipTemplate]="template"
    filter=".k-grid td"
    class="h-100"
    (mouseover)="showTooltip($event)"
  >
    <kendo-grid
      class="emr-kendo-grid hide-default-sort large-header"
      #grid
      [data]="gridView"
      [style.maxHeight.%]="100"
      [style.width.%]="100"
      [sortable]="{
        allowUnsort: allowUnsort,
        mode: 'single'
      }"
      filterable="menu"
      [resizable]="true"
      [sort]="state.sort"
      [filter]="state.filter"
      [pageSize]="pageSize"
      [skip]="skip"
      [loading]="loading"
      [pageable]="{
        buttonCount: buttonCount,
        info: info,
        previousNext: previousNext,
        type: pagerType
      }"
      (dataStateChange)="dataStateChange($event)"
      (pageChange)="pageChange($event)"
    >
      <!-- <kendo-grid-column
          width="40"
          >
          <ng-template kendoGridCellTemplate let-isNew="isNew">
            <button kendoGridEditCommand class="btn p-0 navy-font">
              <i class="fal fa-pencil"></i>
            </button>
          </ng-template>
        </kendo-grid-column> -->

      <kendo-grid-column title="Type" field="reviewId" [filterable]="false" [width]="72">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>
            {{ dataItem.reviewId ? 'Internal' : 'Google' }}
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [sortable]="true" #patientCol title="Patient" [width]="250">
        <ng-template kendoGridHeaderTemplate let-column>
          {{ column.title }}
          <i [ngClass]="sortIconClass(column)"></i>
        </ng-template>

        <ng-template kendoGridCellTemplate let-dataItem>
          <div>
            <span
              kendoTooltip
              class="font-weight-bold text-truncate"
              [ngClass]="{ 'pointer underline': dataItem.reviewId }"
              (click)="dataItem.reviewId ? navigateToPatientChart(dataItem) : null"
              title="{{ dataItem.patient.firstName }} {{ dataItem.patient.lastName }}"
            >
              {{ dataItem.patient.firstName }} {{ dataItem.patient.lastName }}
            </span>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column #appointmentCol title="Appointment" [width]="250" [filterable]="false" [sortable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a
            kendoTooltip
            title="{{ dataItem.serviceName }}"
            class="text-truncate"
            *ngIf="dataItem?.patient && dataItem?.appointment"
            >{{ dataItem.serviceName }}</a
          >
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="ratingValue" title="Rating" [width]="100" [filterable]="true">
        <ng-template kendoGridHeaderTemplate let-column>
          {{ column.title }}
          <i [ngClass]="sortIconClass(column)"></i>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <ngb-rating
            [starTemplate]="lowerStars"
            [rate]="dataItem.ratingValue"
            [max]="5"
            [readonly]="true"
          ></ngb-rating>
        </ng-template>
        <ng-template kendoGridFilterCellTemplate> </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-column="column"
          let-filter="filter"
          let-filterService="filterService"
        >
          <app-multicheck-filter
            [isPrimitive]="true"
            [field]="'starRating'"
            [currentFilter]="filter"
            [filterService]="filterService"
            [data]="[1, 2, 3, 4, 5]"
          ></app-multicheck-filter>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column #textCol title="Text" field="ratingText" [filterable]="false" [sortable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div [title]="dataItem.ratingText" class="truncate-two-lines">{{ dataItem.ratingText }}</div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Staff" [width]="140">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div *ngIf="dataItem?.staff">{{ dataItem.staff.firstName }} {{ dataItem.staff.lastName }}</div>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          {{ column.title }}
          <i [ngClass]="sortIconClass(column)"></i>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="appointmentDate"
        title="Appointment Date"
        [width]="180"
        [sortable]="true"
        [filterable]="true"
      >
        <ng-template kendoGridHeaderTemplate let-column>
          {{ column.title }}
          <i [ngClass]="sortIconClass(column)"></i>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div *ngIf="dataItem.appointmentDate && dataItem.reviewId">
            {{ dataItem.appointmentDate | convertUTC : true }}
          </div>
        </ng-template>
        <ng-template kendoGridFilterCellTemplate> </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-column="column"
          let-filter="filter"
          let-filterService="filterService"
        >
          <app-multicheck-filter
            [isPrimitive]="true"
            [field]="column.field"
            [currentFilter]="filter"
            [filterService]="filterService"
            [data]="appointmentDates"
          ></app-multicheck-filter>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="createdAt" title="Review Date" [width]="142" [filterable]="true" [sortable]="true">
        <ng-template kendoGridHeaderTemplate let-column>
          {{ column.title }}
          <i [ngClass]="sortIconClass(column)"></i>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.createdAt | date : 'yyyy-MM-dd h:mm aaa' }}
        </ng-template>
        <ng-template kendoGridFilterCellTemplate> </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-column="column"
          let-filter="filter"
          let-filterService="filterService"
        >
          <app-multicheck-filter
            [isPrimitive]="true"
            [field]="column.field"
            [currentFilter]="filter"
            [filterService]="filterService"
            [data]="createdAtDates"
          ></app-multicheck-filter>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <span class="k-pager-input k-label"> Page {{ currentPage }} of {{ totalPages }} </span>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
      </ng-template>
    </kendo-grid>
  </div>
</div>
