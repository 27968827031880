import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { passwordValidator } from '@app/shared/validators/password.validator';
import { passwordsMatchValidator } from '@app/shared/validators/passwords-match.validator';
import { Address } from '@models/address';
import { Clinic } from '@models/clinic';
import { MedicalCollege } from '@models/medical-college';
import { User } from '@models/user';
import { UserCategory } from '@models/user-category';
import { ClinicsService } from '@services/clinics.service';
import { DoctorsService } from '@services/doctors.service';
import { FormatterService } from '@services/formatter.service';
import { GeographyService } from '@services/geography.service';
import { UsersService } from '@services/users.service';
import { ValidationService } from '@services/validation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.less'],
})
export class EditUserComponent implements OnInit, OnDestroy {
  countryOptions: string[] = [];
  provinceAndStateOptions: string[] = [];
  userCategories: UserCategory[] = [{ userCategoryId: 0, categoryName: '' }];
  unsub: Subject<void> = new Subject<void>();
  serverValidationError: '';
  doctorUserCategoryId: number;
  medicalColleges: MedicalCollege[];
  hidePassword = true;
  hideConfirmPassword = true;
  defaultAddress: Address;
  loading = false;

  userForm = new FormGroup(
    {
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      userName: new FormControl('', Validators.required),
      email: new FormControl('', Validators.email),
      password: new FormControl('', passwordValidator(false)),
      passwordConfirm: new FormControl('', passwordValidator(false)),
      address: new FormGroup({
        address1: new FormControl(),
        address2: new FormControl(),
        city: new FormControl(),
        province: new FormControl(),
        country: new FormControl(),
        postalCode: new FormControl(),
      }),
      phoneNumber: new FormControl('', this.validationService.validatePhoneNumber),
      userCategories: new FormControl([]),
      serviceProvider: new FormControl(false),
      hourlyWage: new FormControl(),
      medicalCollegeId: new FormControl(''),
      medicalLicenseNumber: new FormControl(''),
      provincialBillingNumber: new FormControl(null, Validators.pattern('^[0-9]*$')),
      clinicAidPayeeNumber: new FormControl(null, Validators.pattern('^[0-9]*$')),
    },
    {
      validators: passwordsMatchValidator(),
    }
  );

  get isNewUser(): boolean {
    return this.user == null;
  }

  private _editingUser: User;
  get user(): User {
    return this._editingUser;
  }
  set user(user: User) {
    this._editingUser = user;
    this.setUserForm(user);
  }

  constructor(
    private usersService: UsersService,
    private geographyService: GeographyService,
    private validationService: ValidationService,
    private route: ActivatedRoute,
    private router: Router,
    private doctorService: DoctorsService,
    private clinicsService: ClinicsService,
    public formatterService: FormatterService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.doctorService
      .getMedicalColleges()
      .pipe(takeUntil(this.unsub))
      .subscribe((colleges) => {
        this.medicalColleges = colleges;
      });

    this.usersService.getUserCategories().subscribe((userCategories: UserCategory[]) => {
      this.userCategories = userCategories;
      this.doctorUserCategoryId = userCategories.find((i) => i.categoryName === 'Doctor').userCategoryId;
    });

    this.clinicsService.clinicIdSelected$
      .pipe(takeUntil(this.unsub))
      .subscribe((clinicId) => this.setDefaultAddress(this.clinicsService.clinic));

    this.clinicsService.clinicIdSelected$
      .pipe(takeUntil(this.unsub))
      .subscribe((clinic) => this.setDefaultAddress(this.clinicsService.clinic));

    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      const userIdParam = params['userid'];
      if (userIdParam !== '_' && userIdParam != null) {
        this.usersService.getUserById(userIdParam).subscribe((user: User) => {
          this.user = user;
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    });

    this.userForm.get('address.country').valueChanges.subscribe((value) => this.onChangeCountry(value));

    for (const key in this.geographyService.countriesByName()) {
      if (key) {
        this.countryOptions.push(key);
      }
    }
  }

  private setDefaultAddress(clinic: Clinic) {
    if (clinic == null) return;
    this.defaultAddress = {
      address1: '',
      address2: '',
      city: '',
      country: this.countryOptions[this.countryOptions.indexOf(clinic.address.country)],
      postalCode: '',
      province: clinic.address.province,
    };
    if (this.isNewUser) {
      this.userForm.patchValue({
        address: this.defaultAddress,
      });
    }
  }

  private setUserForm(user: User) {
    this.userForm.patchValue({
      firstName: user.firstName,
      lastName: user.lastName,
      userName: user.userName,
      email: user.email,
      address: user.address,
      phoneNumber: user.phoneNumber,
      userCategories: user.userUserCategories,
      serviceProvider: user.serviceProvider,
      medicalCollegeId: user.medicalCollege,
      medicalLicenseNumber: user.medicalLicenseNumber,
      provincialBillingNumber: user.provincialBillingNumber,
      clinicAidPayeeNumber: user.payeeNumber,
      hourlyWage: user.hourlyWage?.toFixed(2),
    });

    if (this.isNewUser) {
      this.userForm.patchValue({
        address: this.defaultAddress,
      });
      this.userForm.get('password').addValidators(Validators.required);
      this.userForm.get('passwordConfirm').addValidators(Validators.required);
    } else {
      this.userForm.patchValue({
        address: user.address,
      });
    }

    // this.onChangeCountry();
  }

  private onChangeCountry(country: string) {
    this.provinceAndStateOptions = this.geographyService.updateProvinceStateList(country);
    if (country != this.user.address.country) {
      this.userForm.get('address.postalCode').setValue('');
    }
    switch (country.toLowerCase()) {
      case 'canada':
        this.userForm.get('address.postalCode').setValidators(this.validationService.validatePostalCode);
        break;
      case 'united states':
        this.userForm.get('address.postalCode').setValidators(this.validationService.validatePostalCode);
        break;
      default:
        this.userForm.get('address.postalCode').validator = null;
        break;
    }
  }

  onCurrencyChange() {
    const value = this.userForm.get('hourlyWage').value;
    this.userForm.get('hourlyWage').setValue(value ? Number.parseFloat(value).toFixed(2) : '');
  }

  submitUser() {
    const user = this.isNewUser ? new User() : this.user;
    user.firstName = this.userForm.get('firstName').value;
    user.lastName = this.userForm.get('lastName').value;
    user.userName = this.userForm.get('userName').value;
    user.email = this.userForm.get('email').value;
    user.address = this.userForm.get('address').value;
    user.phoneNumber = this.userForm.get('phoneNumber').value;
    user.userUserCategories = this.userForm.get('userCategories').value;
    user.serviceProvider = this.userForm.get('serviceProvider').value;
    user.medicalCollege = this.userForm.get('medicalCollegeId').value;
    user.medicalLicenseNumber = this.userForm.get('medicalLicenseNumber').value;
    user.provincialBillingNumber = this.userForm.get('provincialBillingNumber').value;
    user.payeeNumber = this.userForm.get('clinicAidPayeeNumber').value;
    user.hourlyWage = this.userForm.get('hourlyWage').value;

    if (this.isNewUser) {
      user.clinicId = this.usersService.getUserClinicId();
      const password = this.userForm.get('password').value;
      this.usersService.addUser(user, password).subscribe(
        () => {
          this.usersService.updateRefreshRequired(true);
          this.router.navigate(['/management/organization/clinics/users', { outlets: { 'action-panel': null } }]);
        },
        (error) => {
          this.serverValidationError = error.error;
        }
      );
    } else {
      const password = this.userForm.get('password').value;
      this.usersService.updateUser(user, password).subscribe(
        () => {
          if (this.user.id === this.usersService.loggedInUser.id) {
            // Be careful of ordering, this will update the editedUser (overriding avatar with a roSAS appended)
            this.usersService.loggedInUserUpdated(this.user);
          }
          this.usersService.updateRefreshRequired(true);
          this.router.navigate(['/management/organization/clinics/users', { outlets: { 'action-panel': null } }]);
        },
        (error) => {
          this.serverValidationError = error.error;
        }
      );
    }
  }

  cancelUpdate() {
    this.usersService.updateRefreshRequired(false);
    this.router.navigate(['/management/organization/clinics/users', { outlets: { 'action-panel': null } }]);
  }

  compareCategories(a: UserCategory, b: UserCategory) {
    return a && b ? a.userCategoryId === b.userCategoryId : a === b;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
