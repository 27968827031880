import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { authRoles } from '@app/auth/auth-permissions';
import { AuthService } from '@app/auth/auth.service';
import { environment } from '@environments/environment';
import { User, UserClinicCompany, UserClinicCompanyType } from '@models/user';
import { UserCategory } from '@models/user-category';
import { Observable, ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BlobService } from './blob.service';
import { CryptoHelperService } from './crypto/crypto-helper';
import { Policies } from '@app/auth/auth-policies';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private refreshRequired = new Subject<any>();
  refreshRequired$ = this.refreshRequired.asObservable();

  loggedInUser: User;

  // Replay Subject will save the number of last emissions and replay them - in our case the last one (emits to new sub but not old differing from BehaviorSubject)
  private loggedInUserUpdatedSource$ = new ReplaySubject<User>(1);
  loggedInUserUpdated$: Observable<User>;

  loggedInUserCompany: number = parseInt(localStorage.getItem('companyId'));
  loggedInUserClinic: number = parseInt(localStorage.getItem('clinicId'));
  loggedInUserTimezone: string = localStorage.getItem('timezone');

  constructor(
    private http: HttpClient,
    private blobService: BlobService,
    private authService: AuthService,
    private cryptoService: CryptoHelperService
  ) {
    this.loggedInUserUpdated$ = combineLatest([
      this.blobService.getReadOnlySASObservable(),
      this.loggedInUserUpdatedSource$.asObservable(),
    ]).pipe(
      map(([readOnlySAS, u]) => {
        const user = new User(u);
        if (readOnlySAS && u.avatar) {
          user.avatar = (u.avatar ? u.avatar.trim() : '') + readOnlySAS;
        } else {
          if (u.id) {
            user.avatar = '/assets/Avatars/avatar-placeholder.png';
          }
        }
        if (localStorage.getItem('clinicId') != null) {
          user.clinicId = parseInt(localStorage.getItem('clinicId'));
        }

        this.loggedInUser = user;

        return user;
      })
    );

    this.loggedInUser = new User();

    // Subscribe to auth updates and update the current loggedInUser
    this.authService.userIdUpdated$.subscribe((userId) => {
      if (userId) {
        this.setCurrentUserById(userId);
      } else {
        this.resetCurrentlyLoggedInUser();
      }
    });
  }

  updateRefreshRequired(state: boolean) {
    this.refreshRequired.next(state);
  }

  loadUserClinicAndCompany() {
    return this.http
      .get<UserClinicCompany>(environment.baseUrl + 'api/Users/UserClinicCompany/' + this.authService.userId)
      .pipe(map((res) => res as UserClinicCompanyType));
  }

  setUserClinicCompanyDefaults(details: UserClinicCompanyType) {
    //Links User to 'a' clinic and company
    this.loggedInUserCompany = details.companyId;
    this.loggedInUserClinic = details.clinicId;
    //Ensure a DEV gets a clinic
    if (this.loggedInUser.clinicId == null) {
      //Required for users with null clinicId
      this.loggedInUser.clinicId = details.clinicId;
    }
    this.loggedInUserTimezone = details.timezone;

    //Save to local storage ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    if (!localStorage.getItem('companyId')) {
      localStorage.setItem('companyId', details.companyId.toString());
      localStorage.setItem('clinicId', details.clinicId.toString());
      localStorage.setItem('timezone', details.timezone.toString());
    }
  }

  resetCurrentlyLoggedInUser() {
    this.loggedInUserUpdatedSource$.next(new User());
  }

  loggedInUserUpdated(user: User) {
    this.loggedInUserUpdatedSource$.next(user);
  }

  setCurrentUserById(userId: string) {
    this.getUserById(userId).subscribe((user) => {
      if (localStorage.getItem('clinicId') != null) {
        user.clinicId = parseInt(localStorage.getItem('clinicId'));
      }
      this.loggedInUserUpdated(user);
    });
  }

  addUser(user: User, password: string) {
    const cryptoData = this.cryptoService.encrypt(password);
    return this.http.post<User>(environment.baseUrl + 'api/Users', { user, cryptoData });
  }

  updateUser(user: User, newPassword: string) {
    const cryptoData = this.cryptoService.encrypt(newPassword);
    return this.http.put<void>(environment.baseUrl + 'api/Users/' + user.id, { user, cryptoData });
  }

  removeUser(user: User) {
    return this.http.delete(environment.baseUrl + 'api/Users/' + user.id);
  }

  updateUserStatus(userId: string, status: boolean) {
    const statusFlag = status ? 1 : 0;
    return this.http.put(environment.baseUrl + 'api/Users/' + userId + '/' + statusFlag, null);
  }

  toggleUserProviderSetting() {
    if (!this.loggedInUser) return of();
    return this.http.put(environment.baseUrl + 'api/Users/ProviderSettings/' + this.loggedInUser.id, null);
  }

  getUserById(userId: string) {
    return this.http.get<User>(environment.baseUrl + 'api/Users/' + userId).pipe(map((user) => new User(user)));
  }

  getUserByUserName(userName: string) {
    return this.http
      .get<User>(environment.baseUrl + 'api/Users/GetByUserName/' + userName)
      .pipe(map((user) => new User(user)));
  }

  getUsers(activeOnly: boolean = true) {
    const isDeveloper = this.authService.userSatisfiesPolicy(Policies.developer);
    let clinicId =
      this.loggedInUser.clinicId == null ? parseInt(localStorage.getItem('clinicId')) : this.loggedInUser.clinicId;
    if (clinicId === 0 && !isDeveloper) {
      clinicId = this.loggedInUserClinic == null ? parseInt(localStorage.getItem('clinicId')) : this.loggedInUserClinic;
    }

    return this.http
      .get<User[]>(environment.baseUrl + 'api/Users/Clinic/' + clinicId + '/' + +activeOnly)
      .pipe(map((users) => users.map((user) => new User(user))));
  }

  getDevUsers() {
    return this.http
      .get<User[]>(environment.baseUrl + 'api/Users/Clinic/0/0')
      .pipe(map((users) => users.map((user) => new User(user))));
  }

  getUserCategories() {
    return this.http.get<UserCategory[]>(environment.baseUrl + 'api/UserCategories');
  }

  getUserClinicId(): number {
    return this.loggedInUser.clinicId;
  }
}
