<ngx-loading [show]="loading"></ngx-loading>

<div class="modal-header border-0 p-0">
  <button
    type="button"
    class="btn white-font p-0 ml-auto mr-2"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
    <i class="fal fa-times"></i>
  </button>
</div>

<form [formGroup]="form" class="modal-form" (ngSubmit)="onSubmit()">
  <div class="modal-body p-0">
    <div class="add-document-container grey-two-background">
      <h5 class="modal-title text-uppercase navy-font text-center">Upload Document</h5>
      <div class="row pt-3">
        <div class="col">
          <label class="teal-font d-block m-0">Upload PDF</label>
          <label class="d-block file-label">
            <ng-container *ngIf="!fileList.length">
              Choose File
            </ng-container>
            <ng-container *ngIf="fileList.length === 1">
              {{ fileList[0].name }}
            </ng-container>
            <ng-container *ngIf="fileList.length > 1">
              Multiple Files Selected
            </ng-container>
          </label>

          <label class="text-right d-block">
            <input type="file" data-test-id="patient-document-file-chooser" placeholder="Choose File" class="invisible" (change)="onFileChange($event)" />
            <div class="btn em-btn em-btn-green">Browse</div>
          </label>
        </div>
      </div>
    </div>

    <div class="add-document-container">
      <h6 class="modal-title navy-font">Document settings</h6>
      <div class="row pt-3">
        <div class="col">
          <label class="teal-font m-0">Document Name</label>
          <input data-test-id="add-document-name-input" type="text" class="form-control input-filed" placeholder="Add document name" formControlName="name" />
          <small *ngIf="form.get('name').errors && form.get('name').errors['duplicateName']" class="text-danger">
            The name "{{form.get('name').errors['duplicateName'].value}}" has already been used by this patient.
          </small>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col">
          <label class="teal-font m-0">Notes</label>
          <textarea  data-test-id="add-document-notes-textarea" class="form-control input-filed" rows="1" placeholder="Add notes" formControlName="notes"></textarea>
        </div>
      </div>

      <div class="row pt-3">
        <div class="col">
          <label class="teal-font m-0">Add New Tag</label>
          <tag-input [formControlName]="'tags'" name="tags" [inputClass]="'tag-input'" #input [onlyFromAutocomplete]="true">
            <ng-template let-item="item" let-index="index">
              <div class="tag-pill" [ngStyle]="{ 'border-color': pillColours[item.type] }">
                <ng-container *ngIf="item.title; else text">
                  {{ item.title }}
                </ng-container>
                <ng-template #text>
                  {{ item.display }}
                </ng-template>
                <delete-icon (click)="input.removeItem(item, index)"></delete-icon>
              </div>
            </ng-template>
            <tag-input-dropdown
              [focusFirstElement]="true"
              [autocompleteItems]="tags"
              [zIndex]="99999"
              [displayBy]="'title'"
              [identifyBy]="'tagId'"
              [showDropdownIfEmpty]="true"
              [keepOpen]="false"
            >
            </tag-input-dropdown>
          </tag-input>
        </div>
      </div>
    </div>

    <div class="row py-3">
      <div class="col text-center pb-2">
        <button
          type="submit" data-test-id="add-document-upload-button"
          class="btn em-btn em-btn-green"
          [disabled]="!form.valid || !hasSelectedFiles()">
          Upload
        </button>
        <button
          type="submit"
          class="btn em-btn em-btn-green ml-2"
          (click)="activeModal.dismiss('Cross click')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</form>
