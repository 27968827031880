export const EformConfig = {
  appUrl: 'http://localhost:4200',
  apiUrl: 'http://localhost:4200',
  //appUrl: 'https://example.form.io',
  //apiUrl: 'https://api.form.io'
  formIoUrl: 'https://formio.form.io',
  filesUrl: 'https://files.form.io',
  account: {
    email: 'dev@bigbearsoftware.ca',
    password: 'VG54GBJ2gStA'
  },
  projectId: '5e0e6352770e543be1d36e98',
  liveEndpoint: 'https://jbhuhgficrzgmxe.form.io',
  xFileToken: 'KOTEyDMZSVh356lIK7mc1huBmw67cz'
};
