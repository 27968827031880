<form [formGroup]="plannedTreatmentsForm">
  <div class="form-row grey-four-background negative-row-margin">
    <div class="col-1 text-center navy-font font-weight-bold p-2">Id</div>
    <div class="col-1 text-center navy-font font-weight-bold p-2">Scheduled</div>
    <div class="col-1 text-center navy-font font-weight-bold p-2">Invoice</div>
    <div class="col-2 text-center navy-font font-weight-bold p-2">Date</div>
    <div class="col-7 navy-font font-weight-bold grey-six-border-left p-2">Service</div>
  </div>
  <ng-container formArrayName="plannedTreatments">
    <ng-container *ngFor="let plannedTreatment of getPlannedTreatmentControls(); let i = index" [formGroupName]="i">
      <div
        class="form-row negative-row-margin grey-six-border-bottom"
        kendoTooltip
        tooltipClass="em-tooltip"
        position="top"
      >
        <div class="col-1 grey-two-background p-2 text-center">
          <div class="form-row">
            <div class="col">
              {{ plannedTreatment.value.rank }}
            </div>
          </div>
        </div>
        <div class="col-1 grey-two-background grey-six-border-left p-2 text-center">
          <div class="form-row">
            <div class="col = d-flex justify-content-center align-items-center">
              <i
                [ngClass]="{
                  'fa-calendar': plannedTreatment.value.treatmentState == treatmentState.Unplanned,
                  'fa-calendar-day': plannedTreatment.value.treatmentState == treatmentState.Scheduled,
                  'fa-calendar-check': plannedTreatment.value.treatmentState == treatmentState.Completed,
                  'fas fa-sad-tear purple-font': plannedTreatment.value.paymentStatus == PaymentStatus.Refund
                }"
                class="pl-3 far"
                style="font-size: 1.25rem"
                [title]="
                  plannedTreatment.value.treatmentState == treatmentState.Unplanned
                    ? 'Unplanned'
                    : plannedTreatment.value.treatmentState == treatmentState.Scheduled
                    ? 'Scheduled'
                    : plannedTreatment.value.treatmentState == treatmentState.Completed
                    ? 'Completed'
                    : plannedTreatment.value.paymentStatus == PaymentStatus.Refund
                    ? 'Returned'
                    : ''
                "
              ></i>
            </div>
          </div>
        </div>
        <div class="col-1 grey-two-background grey-six-border-left p-2">
          <div class="form-row">
            <div class="col = d-flex justify-content-center align-items-center">
              <button
                *ngIf="plannedTreatment.value.paymentStatus == PaymentStatus.Refund"
                class="btn btn-sm em-btn em-btn-green mr-2"
                (click)="onGoToReturnInvoice(plannedTreatment.value.plannedTreatmentMultipleId)"
              >
                <i class="far fa-file-invoice-dollar" title="Goto Return Invoice"></i>
              </button>
              <span *ngIf="plannedTreatment.value.paymentStatus != PaymentStatus.Refund"> - </span>
            </div>
          </div>
        </div>
        <div class="col-2 grey-two-background grey-six-border-left p-2">
          <div class="form-row">
            <div class="col = d-flex justify-content-center align-items-center">
              <span *ngIf="plannedTreatment.value.treatmentState != treatmentState.Unplanned">
                {{ plannedTreatment.value.plannedDate | date : 'yyyy-MM-dd h:mma' }}</span
              >
              <span *ngIf="plannedTreatment.value.treatmentState == treatmentState.Unplanned"> - </span>
            </div>
          </div>
        </div>
        <div class="col-7 grey-two-background grey-six-border-left p-2">
          <div class="form-row">
            <div class="col">
              {{ plannedTreatment.value.service.serviceName }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</form>
