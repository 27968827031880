<div class="d-flex flex-column position-relative">
  <ngx-loading [show]="loading"></ngx-loading>
  <div class="d-flex flex-column pb-2">
    <span class="text-danger"> Images with the following extensions are supported: png, jpg, jpeg </span>
    <div kendoTooltip [tooltipTemplate]="emojiTemplate" filter="i" position="right">
      Dimensions <i class="fa fa-question-circle teal-font" style="font-size: 12px"></i>
    </div>
    <ng-template #emojiTemplate>
      <span>
        The image will be resized to either 1500px width or 750px height, which ever comes first, maintaining aspect
        ratio
      </span>
      <br />
      <span>
        Displayed image is resized to either 500px width or 250px height, which ever comes first, maintaining aspect
        ratio
      </span>
    </ng-template>
  </div>
  <div class="d-flex mb-2">
    <img
      [src]="clinicLogo ? (clinicLogo | safeUrl) : '/assets/Avatars/clinic-logo.png'"
      style="max-width: 500px; max-height: 250px"
    />
    <label class="uploader-circle position-relative pointer text-center">
      <i class="fal fa-camera white-font"></i>
      <input (change)="onFileSelected($event)" type="file" id="uploader" accept="image/*" />
    </label>
    <label class="uploader-circle position-relative pointer text-center" *ngIf="clinicLogo">
      <i class="fal fa-times-circle white-font" (click)="removeLogo()"></i>
    </label>
  </div>
</div>
