import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { ConfirmFormDeleteComponent } from '@app/management/forms/forms-list/confirm-form-delete/confirm-form-delete.component';
import { FormBuilderComponent as FormioBuilderComponent } from '@formio/angular';
import { eTreatmentForm } from '@models/etreatment-forms/etreatment-form';
import { eTreatmentFormCategory } from '@models/etreatment-forms/etreatment-form-category';
import { EditEvent, GridComponent, GridDataResult, RemoveEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { ActionPanelService } from '@services/actionpanel.service';
import { eTreatmentFormCategoryService } from '@services/etreatment-form-category.service';
import { eTreatmentFormService } from '@services/etreatment-form.service';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-catalogue-etreatment-forms',
  templateUrl: './catalogue-etreatment-forms.component.html',
  styleUrls: ['./catalogue-etreatment-forms.component.less'],
})
export class CatalogueEtreatmentFormsComponent implements OnInit {
  @ViewChild(GridComponent) private grid: GridComponent;
  @ViewChild('builder') builder: FormioBuilderComponent;
  @Input() assignMode: boolean = false;
  @Output() assigneTreatmentForm = new EventEmitter<eTreatmentForm>();

  searchValue = '';
  searchCtrl: FormControl;
  filteredeTreatmentForm: Observable<eTreatmentForm[]>;

  eTreatmentFormCategories: eTreatmentFormCategory[] = [];

  unsub: Subject<void> = new Subject<void>();

  disableTabsAndGrid = false;
  loading = false;

  eTreatmentForms: eTreatmentForm[];
  public gridView: GridDataResult;

  gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  selectedRowIndex: number;
  selectedDataItem: eTreatmentForm = new eTreatmentForm();
  editingDataItem: eTreatmentForm = new eTreatmentForm();

  eTreatmentFormSelected: boolean = false;
  editMode: boolean = false;

  public selectedKeys: number[] = [];

  public eTreatmentFormRefreshed$: Subject<eTreatmentForm> = new Subject<eTreatmentForm>();

  developerPolicy = Policies.developer;

  constructor(
    private eTreatmentFormService: eTreatmentFormService,
    private eTreatmentFormCategoryService: eTreatmentFormCategoryService,
    private dialog: MatDialog,
    private router: Router,
    private actionPanelService: ActionPanelService
  ) {}

  ngOnInit() {
    this.loading = false;
    this.disableTabsAndGrid = false;
    this.geteTreatmentFormCategories();

    this.searchCtrl = new FormControl();
    this.loadForms();

    this.initFormAddedSubscription();
    this.initFormUpdatedSubscription();
    this.initFormChangeCancelledSubscription();
    this.initFormDeactivatedSubscription();
    this.initFormActivatedSubscription();
    this.initFormDeletedSubscription();
    this.initFormTemplateLibraryPanelSubscription();
    this.initFormAddedFromTemplateLibrarySubscription();
  }

  geteTreatmentFormCategories() {
    this.eTreatmentFormCategories = [];
    this.eTreatmentFormCategoryService.geteTreatmentFormCategories().subscribe((res) => {
      this.eTreatmentFormCategories = res;
    });
  }

  clearFormSelection() {
    this.eTreatmentFormSelected = false;
    this.editMode = false;
    this.selectedKeys = [];
    this.selectedDataItem = null;
  }

  loadForms(eTreatmentFormCategoryForm?: eTreatmentFormCategory | null) {
    this.loading = true;

    this.eTreatmentFormService.geteTreatmentForms(eTreatmentFormCategoryForm).subscribe((eTreatmentForms) => {
      this.eTreatmentForms = eTreatmentForms;

      this.gridView = {
        data: eTreatmentForms.slice(),
        total: eTreatmentForms.length,
      };

      this.filteredeTreatmentForm = this.searchCtrl.valueChanges.pipe(
        startWith(''),
        map((eTreatmentform) => this.filterForms(eTreatmentform))
      );

      if (!this.isTemplateLibraryOpen()) {
        this.loading = false;
      }
    });
  }

  isTemplateLibraryOpen(): boolean {
    return this.router.url.indexOf('(action-panel-secondary:form-library-overview)') !== -1 ? true : false;
  }

  initFormAddedSubscription() {
    this.eTreatmentFormService.eTreatmentFormAdded
      .pipe(takeUntil(this.unsub))
      .subscribe((eTreatmentForm: eTreatmentForm) => {
        this.loadForms();
        this.selectRow(eTreatmentForm);
      });
  }

  initFormUpdatedSubscription() {
    this.eTreatmentFormService.eTreatmentFormUpdated
      .pipe(takeUntil(this.unsub))
      .subscribe((eTreatmentForm: eTreatmentForm) => {
        this.loadForms();
      });
  }

  initFormChangeCancelledSubscription() {
    this.eTreatmentFormService.eTreatmentFormChangeCancelled.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.loading = false;
      this.disableTabsAndGrid = false;
    });
  }

  initFormDeactivatedSubscription() {
    this.eTreatmentFormService.eTreatmentFormDeactivated.pipe(takeUntil(this.unsub)).subscribe(() => {
      if (this.selectedDataItem && this.selectedDataItem.eTreatmentFormCategoryId) {
        this.loadForms();
      }
    });
  }

  initFormActivatedSubscription() {
    this.eTreatmentFormService.eTreatmentFormActivated.pipe(takeUntil(this.unsub)).subscribe(() => {
      if (this.selectedDataItem && this.selectedDataItem.eTreatmentFormCategoryId) {
        this.loadForms();
      }
    });
  }

  initFormDeletedSubscription() {
    this.eTreatmentFormService.eTreatmentFormDeleted.pipe(takeUntil(this.unsub)).subscribe(() => {
      if (this.selectedDataItem && this.selectedDataItem.eTreatmentFormCategoryId) {
        this.loadForms();
      }
    });
  }

  initFormTemplateLibraryPanelSubscription() {
    this.actionPanelService.actionPanelClosed$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.loading = false;
      this.disableTabsAndGrid = false;
      this.loadForms();
    });
  }

  initFormAddedFromTemplateLibrarySubscription() {
    /*  this.sharedFormService.sharedFormAddedFromLibrary
      .pipe(takeUntil(this.unsub))
      .subscribe((eTreatmentForm: eTreatmentForm) => {
        if (eTreatmentForm.eTreatmentFormCategoryId) {
          const eTreatmentFormCategory = this.eTreatmentFormCategories.find(
            (p) => p.id === eTreatmentForm.eTreatmentFormCategoryId
          );
          if (eTreatmentFormCategory === this.activeTab) this.loadForms(eTreatmentFormCategory);
        }
      }); */
  }

  public onSelectRow(e) {
    this.editMode = false;
    if (e.selectedRows.length > 0) {
      this.selectRow(e.selectedRows[0].dataItem);
    }
  }

  private selectRow(dataItem: eTreatmentForm) {
    this.selectedDataItem = dataItem;
    this.eTreatmentFormRefreshed$.next(this.selectedDataItem);
    if (this.selectedKeys.length > 0) {
      this.selectedKeys = [];
    }
    this.selectedKeys.push(this.selectedDataItem.id);
    this.eTreatmentFormSelected = true;
  }

  public editHandler(args: EditEvent) {
    this.editMode = false;
    this.selectRow(args.dataItem);
    this.router.navigate([
      '/management/catalogue/etreatment-forms',
      { outlets: { 'action-panel': ['edit-etreatment-form', args.dataItem.id] } },
    ]);
  }

  public editeTreatmentFormHandler(dataItem) {
    this.editMode = true;
    this.disableTabsAndGrid = true;
    Object.assign(this.editingDataItem, dataItem);
    this.selectRow(dataItem);
  }

  public addHandler(args: any) {
    this.router.navigate([
      '/management/catalogue/etreatment-forms',
      {
        outlets: {
          'action-panel': ['edit-etreatment-form', this.eTreatmentFormService.defaultId],
        },
      },
    ]);
  }

  public saveHandler(): void {
    const eTreatmentForm: eTreatmentForm = this.editingDataItem;

    this.eTreatmentFormService
      .updateeTreatmentForm(eTreatmentForm)
      .pipe(takeUntil(this.unsub))
      .subscribe((updatedeTreatmentForm) => {
        this.selectedDataItem = updatedeTreatmentForm;
        this.editMode = false;
        this.disableTabsAndGrid = false;
      });
  }

  public cancelHandler() {
    this.editMode = false;
    this.disableTabsAndGrid = false;
  }

  cloneeTreatmentFormHandler(eTreatmentFormId: number) {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '325px',
      data: {
        title: 'Clone eTreatment Form?',
        content: 'Are you sure you want to clone this eTreatment Form?',
        confirmButtonText: 'Clone',
        showCancel: true,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') {
          this.loading = true;
          this.eTreatmentFormService
            .cloneeTreatmentForm(eTreatmentFormId)
            .subscribe((neweTreatmentForm: eTreatmentForm) => {});
        }
      });
  }

  public deactivateHandler(args: RemoveEvent): void {
    if (this.selectedKeys.length > 0) {
      this.selectedKeys = [];
    }
    this.selectedKeys.push(args.dataItem.id);
    this.editMode = false;
    this.selectRow(args.dataItem);

    const dialogRef = this.dialog.open(ConfirmFormDeleteComponent, {
      width: '400px',
      data: {},
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'deactivate') {
          args.dataItem.isDeleted = true;
          this.eTreatmentFormService.deactivateeTreatmentForm(args.dataItem).subscribe();
        } else if (result === 'delete') {
          this.eTreatmentFormService.deleteeTreatmentForm(args.dataItem).subscribe();
        }
      });
  }

  activateeTreatmentFormHandler(eTreatmentForm: eTreatmentForm) {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '325px',
      data: {
        title: 'Activate eTreatment Form?',
        content: 'Are you sure you want to activate this eTreatment Form?',
        confirmButtonText: 'Activate',
        showCancel: true,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') {
          eTreatmentForm.isDeleted = false;
          this.eTreatmentFormService.activateeTreatmentForm(eTreatmentForm).subscribe(() => {});
        }
      });
  }

  /*   shareFormHandler(form: Form) {
    let shareFormModal = this.modalService.open(ShareFormComponent, {
      centered: true,
    });
    shareFormModal.componentInstance.formToShare = form;
  } */

  addFromTemplateHandler() {
    this.loading = true;
    this.router.navigate([
      '/management/forms/list',
      { outlets: { 'action-panel-secondary': ['form-library-overview'] } },
    ]);
  }

  assigneTreatmentFormHandler(eTreatmentForm: eTreatmentForm) {
    this.assigneTreatmentForm.emit(eTreatmentForm);
  }

  viewForm(formId: number) {
    this.router.navigate(['/management/forms/form-viewer/' + formId]);
  }

  filterForms(name: string) {
    let filterResults: eTreatmentForm[] = [];

    if (name !== '') {
      this.gridView = {
        data: this.eTreatmentForms.filter((form) => form.name.toLowerCase().includes(name.toLowerCase())),
        total: this.eTreatmentForms.filter((form) => form.name.toLowerCase().includes(name.toLowerCase())).length,
      };
      filterResults = this.eTreatmentForms.filter((form) => form.name.toLowerCase().includes(name.toLowerCase()));
    } else {
      this.gridView = {
        data: this.eTreatmentForms,
        total: this.eTreatmentForms.length,
      };
      filterResults = [];
    }
    return filterResults;
  }

  public rowCallback = (context: RowClassArgs) => {
    if (context.dataItem.isDeleted) return { disabled: true };
    else return {};
  };

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
