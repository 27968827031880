import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BillingSettingsService } from '@services/billing-settings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BillingSettings } from '@models/billing/ministry-billing/billing-settings';

@Component({
  selector: 'app-billing-settings-edit',
  templateUrl: './billing-settings-edit.component.html',
  styleUrls: ['./billing-settings-edit.component.less'],
})
export class BillingSettingsEditComponent implements OnInit, OnDestroy {
  loading = true;
  form: FormGroup;
  unsub: Subject<void> = new Subject<void>();
  billingSettingsId: number;
  billingSettings: BillingSettings;

  constructor(
    private fb: FormBuilder,
    private billingSettingsService: BillingSettingsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      this.billingSettingsId = +params['id'];

      this.billingSettingsService.getBillingSettingsById(this.billingSettingsId).subscribe((bset: BillingSettings) => {
        this.initForm(bset);
        this.billingSettings = bset;
      });
    });
  }

  initForm(bset: BillingSettings) {
    this.form = this.fb.group({
      id: [this.billingSettingsId],
      clinic: [bset.clinic],
      clinicAidId: [bset.clinicAidId, [Validators.required]],
      clinicAidApiKey: [bset.clinicAidApiKey, [Validators.required]],
    });
    this.loading = false;
  }

  onSubmit() {
    this.loading = true;
    this.billingSettingsService.updateBillingSettings(this.form.value).subscribe(() => {
      this.billingSettingsService.editModeToggled.next();
      this.billingSettingsService.updateRefreshRequired(true);
      this.router.navigate(['/management/organization/clinics/payments', { outlets: { 'action-panel': null } }]);
    });
  }

  cancelUpdate() {
    this.billingSettingsService.editModeToggled.next();
    this.billingSettingsService.updateRefreshRequired(false);
    this.router.navigate(['/management/organization/clinics/payments', { outlets: { 'action-panel': null } }]);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
