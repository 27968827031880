import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { AccessLocation } from '@models/access-location';

@Injectable({
  providedIn: 'root'
})
export class AccessLocationService {
  private accessLocationSource = new BehaviorSubject<any>(undefined);
  accessLocationSource$ = this.accessLocationSource.asObservable();

  private accessLocationUpdateSource = new Subject<any>();
  accessLocationUpdateSource$ = this.accessLocationUpdateSource.asObservable();

  constructor(private http: HttpClient) { }

  shareAccessLocation(accessLocation: AccessLocation) {
    this.accessLocationSource.next(accessLocation);
  }

  updateAccessLocationData(state: boolean) {
    this.accessLocationUpdateSource.next(state);
  }

  getAccessLocations(clinicId: number = +localStorage.getItem('clinicId')) {
    return this.http.get<AccessLocation[]>(environment.baseUrl + 'api/Auth/GetAccessLocations');
  }

  addUpdateAccessLocations(accessLocation: [AccessLocation]) {
    return this.http.post<AccessLocation>(environment.baseUrl + 'api/Auth/AddUpdateAccessLocation', accessLocation);
  }

  deleteAccessLocations(accessLocation: AccessLocation) {
    return this.http.post<AccessLocation>(environment.baseUrl + 'api/Auth/DeleteAccessLocation', accessLocation);
  }
}
