<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>

<div class="grid-container">
  <kendo-grid
    class="ml-1"
    [data]="gridView"
    [sort]="gridState.sort"
    [sortable]="true"
    [selectable]="true"
    [style.height.%]="100"
    (add)="onAddClick()"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)"
  >
    <ng-template *appAuthGuard="developerPolicy" kendoGridToolbarTemplate>
      <div class="d-flex">
        <button kendoGridAddCommand class="p-2 btn em-btn"><i class="fal fa-plus-circle"></i> Add Type</button>
      </div>
    </ng-template>
    <kendo-grid-column field="name" title="Payment Type Name"></kendo-grid-column>
    <kendo-grid-command-column
      *appAuthGuard="developerPolicy"
      title="Actions"
      min-width="125px"
      [width]="125"
      class="text-center"
    >
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <button kendoGridEditCommand class="btn kendo-btn" [disabled]="!dataItem.isEditable">
          <i class="fas fa-edit"></i>
        </button>
        <button kendoGridRemoveCommand class="btn kendo-btn" [disabled]="!dataItem.isEditable">
          <i class="fas fa-minus-circle"></i>
        </button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>

<p-overlayPanel #hoverPanel id="hoverPanel" [dismissable]="false"> Content </p-overlayPanel>
<app-actionpanel></app-actionpanel>
