<ngx-loading [show]="loading" [config]="{ fullScreenBackdrop: true }"></ngx-loading>
<div class="z-index-3 white-background row w-100">
  <div class="d-flex w-100" appDetermineMaxScrollHeight>
    <form *ngIf="form" class="teal-font line-height-1 col-4 px-0 light-grey-background" [formGroup]="form">
      <perfect-scrollbar appDetermineMaxScrollHeight [itemsToWatch]="[reviewsSmsPreview]">
        <div class="container py-3">
          <!-- This is hidden because email is unused at the moment 
              Uncomment to use again
            -->
          <!-- <div class="col-12 col-xl-6 d-flex">
              <label class="switch align-text-top">
                <input
                  type="checkbox"
                  id="emailReviewsOn"
                  data-test-id="emailReviewsOn"
                  name="emailReviewsOn"
                  formControlName="emailReviewsOn"
                  #emailReviewsOn
                />
                <span
                  [ngStyle]="form.enabled === false && { 'pointer-events': 'none' }"
                  class="slider round"
                  [ngClass]="{ 'disabled-slider': !form.enabled }"
                ></span>
              </label>
              <label class="pl-2 d-flex align-content-center" for="emailReviewsOn">
                <mat-label class="d-block mb-0 label-header text-truncate">Automated email reviews</mat-label>
              </label>
            </div> -->
          <div class="row mx-0 mb-2">
            <div class="col-12 d-flex">
              <label class="switch align-text-top">
                <input
                  type="checkbox"
                  id="googleReviewsOn"
                  data-test-id="googleReviewsOn"
                  name="googleReviewsOn"
                  formControlName="googleReviewsOn"
                  #googleReviewsOn
                />
                <span
                  [ngStyle]="form.enabled === false && { 'pointer-events': 'none' }"
                  class="slider round"
                  [ngClass]="{ 'disabled-slider': !form.enabled }"
                ></span>
              </label>
              <label class="pl-2 d-flex align-content-center" for="googleReviewsOn">
                <mat-label class="d-block mb-0 label-header text-truncate">Manual Google Review Link</mat-label>
              </label>
              <div class="text-dark pl-2">
                <ng-template #googleReviewsToggle>
                  Allow google review links to be sent from the "Request Google Reviews" button in a patients profile
                </ng-template>
                <div kendoTooltip [tooltipTemplate]="googleReviewsToggle" filter="i" position="right">
                  <i class="fa fa-question-circle teal-font"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="row mx-0 mb-2">
            <div class="col-12 mb-xl-2 d-flex">
              <label class="switch align-text-top">
                <input
                  type="checkbox"
                  id="smsReviewsOn"
                  data-test-id="smsReviewsOn"
                  name="smsReviewsOn"
                  formControlName="smsReviewsOn"
                  #smsReviewsOn
                />
                <span
                  [ngStyle]="form.enabled === false && { 'pointer-events': 'none' }"
                  class="slider round"
                  [ngClass]="{ 'disabled-slider': !form.enabled }"
                ></span>
              </label>
              <label class="pl-2 d-flex align-content-center" for="smsReviewsOn">
                <mat-label class="d-block mb-0 label-header text-truncate">Automated Google Review Link</mat-label>
              </label>
              <div class="text-dark pl-2">
                <ng-template #smsReviewsToggle> Send google review link automatically </ng-template>
                <div kendoTooltip [tooltipTemplate]="smsReviewsToggle" filter="i" position="right">
                  <i class="fa fa-question-circle teal-font"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="row mx-0 d-flex flex-grow-1 align-items-center mb-1">
            <mat-label class="label-header col">Search Company Name</mat-label>
            <mat-label class="label-header col">
              Selected Company Name
              <span *ngIf="form.controls['googleReviewsOn'].value === true" style="color: red">*</span>
            </mat-label>
          </div>
          <div class="row mx-0 mb-2">
            <mat-form-field
              for="companyName"
              class="col-6 flex-grow-1 small-material-text-input"
              [floatLabel]="'always'"
            >
              <mat-label></mat-label>
              <ng-template #rt let-r="result" let-t="term">
                <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
                <br />
                <span class="company-match-address"> {{ r.formatted_address }}</span>
              </ng-template>
              <input
                placeholder="Enter company name"
                name="companyName"
                id="companyNameInput"
                data-test-id="companyName"
                [ngbTypeahead]="searchCompanyName"
                [resultFormatter]="companyNameResultFormatter"
                [inputFormatter]="companyNameInputFormatter"
                [resultTemplate]="rt"
                (focus)="onFocus($event, companySearchFocus$)"
                (selectItem)="selectCompanyName($event.item)"
                matInput
              />
              <mat-hint
                *ngIf="form.controls['googleReviewsOn'].value === true && form.controls['googlePlaceId'].value == ''"
                style="color: red"
                >Please search for company name on the left or disable Google Reviews
              </mat-hint>
            </mat-form-field>
            <mat-form-field
              for="googleCompanyName"
              class="col-6 flex-grow-1 small-material-text-input"
              [floatLabel]="'always'"
            >
              <mat-label></mat-label>
              <input
                readonly="true"
                formControlName="googleCompanyName"
                id="googleCompanyName"
                data-test-id="googleCompanyName"
                [attr.disabled]="''"
                matInput
              />
            </mat-form-field>
            <!--
            <div class="col-12 col-xl-5 mb-2 pr-0 pl-xl-0 mx-xl-0 row d-flex flex-grow-1 align-items-center">
              <mat-label class="col-12 pr-0 label-header"
                >Google Place ID:<span *ngIf="form.controls['googleReviewsOn'].value === true" style="color: red"
                  >*</span
                ></mat-label
              >
              <mat-form-field
                for="googlePlaceId"
                class="col-12 col-xl-auto pr-0 flex-grow-1 small-material-text-input"
                [floatLabel]="'always'"
              >
                <mat-label></mat-label>
                <input
                  formControlName="googlePlaceId"
                  id="googlePlaceId"
                  data-test-id="googlePlaceId"
                  [attr.disabled]="''"
                  matInput
                />
                <mat-error>Please enter your Google Place ID or disable Google Reviews</mat-error>
              </mat-form-field>
            </div> -->
          </div>

          <div class="row mx-0 d-flex flex-grow-1 align-items-center mb-1">
            <mat-label class="label-header col">Google Account ID</mat-label>
            <mat-label class="label-header col">Google Location ID</mat-label>
          </div>
          <div class="row mx-0 mb-2">
            <mat-form-field
              for="googleAccountId"
              class="col-6 flex-grow-1 small-material-text-input"
              [floatLabel]="'always'"
            >
              <mat-label></mat-label>
              <input
                formControlName="googleAccountId"
                id="googleAccountId"
                data-test-id="googleAccountId"
                [attr.disabled]="''"
                matInput
              />
            </mat-form-field>
            <mat-form-field
              for="googleLocationId"
              class="col-6 flex-grow-1 small-material-text-input"
              [floatLabel]="'always'"
            >
              <mat-label></mat-label>
              <input
                formControlName="googleLocationId"
                id="googleLocationId"
                data-test-id="googleLocationId"
                [attr.disabled]="''"
                matInput
              />
            </mat-form-field>
          </div>

          <div class="row mx-0 teal-background mt-4">
            <div class="col">
              <h6 class="white-font uppercase my-0 py-2">Timing</h6>
            </div>
          </div>
          <div class="row mx-0">
            <!--Hidden. See task #1663-->
            <!-- <div class="col-12">
              <mat-label class="label-header mr-2">Delay in minutes before sending request:</mat-label>
              <mat-form-field class="small-material-text-input"
                              style="width: 100px;">
                <input
                  matInput
                  formControlName="sendReviewsDelayInMinutes"
                  data-test-id="sendReviewsDelayInMinutes"
                  type="number">
              </mat-form-field>
            </div> -->
            <div class="col pt-2">
              <mat-radio-group class="theme-radio-group" formControlName="reviewsSchedule">
                <mat-label class="label-header">Minimum days before requesting another review:</mat-label>
                <div
                  class="d-flex flex-column flex-xl-row align-items-start align-items-xl-center flex-column justify-content-start mt-2 pt-1"
                >
                  <mat-radio-button
                    class="mr-2"
                    [value]="reviewsScheduleEnum.afterEachVisit"
                    data-test-id="minimumDaysEachVisit"
                  >
                    Each visit
                  </mat-radio-button>
                  <mat-radio-button
                    class="mr-2"
                    [value]="reviewsScheduleEnum.oneTimeOnly"
                    data-test-id="minimumDaysOneTimeOnly"
                  >
                    One time only
                  </mat-radio-button>
                  <mat-radio-button
                    class="mr-2 custom-radio-button"
                    #customRadio
                    [value]="reviewsScheduleEnum.afterXDays"
                    data-test-id="minimumDaysCustom"
                  >
                    <mat-label>Wait X days</mat-label>
                  </mat-radio-button>
                  <mat-form-field
                    class="small-material-text-input radio-aligned-text"
                    [hidden]="!customRadio.checked"
                    [floatLabel]="'always'"
                  >
                    <mat-label></mat-label>
                    <input
                      matInput
                      type="number"
                      formControlName="maxFrequencyInDays"
                      data-test-id="maxFrequencyInDays"
                      min="0"
                      placeholder="# of days"
                    />
                    <mat-error *ngIf="form.controls['maxFrequencyInDays'].errors?.required">
                      Please enter a number
                    </mat-error>
                    <mat-error *ngIf="form.controls['maxFrequencyInDays'].errors?.min">
                      Value must be positive
                    </mat-error>
                  </mat-form-field>
                </div>
              </mat-radio-group>
            </div>
          </div>

          <div class="row mx-0 teal-background mt-4">
            <div class="col">
              <h6 class="white-font uppercase my-0 py-2">Service/Product Targeting</h6>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col pt-2">
              <mat-label class="label-header mb-2"
                >Select specific services and products that will request a review for</mat-label
              >
              <div class="navy-background p-3 mt-2">
                <div class="input-group">
                  <input
                    type="text"
                    #inputArea1
                    class="form-control border-0 grey-three-background"
                    placeholder="Select Service or Product"
                    data-test-id="searchServiceOrProduct"
                    [ngbTypeahead]="searchServiceOrProduct"
                    [resultFormatter]="serviceOrProductResultFormatter"
                    [inputFormatter]="serviceOrProductInputFormatter"
                    (focus)="onFocus($event, serviceOrProductSearchFocus$)"
                    (selectItem)="addServiceOrProduct($event.item)"
                  />
                </div>
              </div>

              <div class="d-flex flex-wrap">
                <div *ngFor="let sop of selectedServicesOrProducts">
                  {{ sop?.name }}
                  <button
                    class="btn trash-btn pl-1"
                    [attr.data-test-id]="'removeFromTargetList'"
                    [attr.data-test-value]="sop?.name"
                    (click)="deleteServiceOrProduct(sop)"
                  >
                    <small><i class="far fa-trash-alt"></i></small>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row mx-0 teal-background mt-4">
            <div class="col">
              <h6 class="white-font uppercase mb-0 py-2">Email Settings</h6>
            </div>
          </div>
          <div class="py-2">
            <div class="row mx-0">
              <div class="col">
                <mat-form-field class="w-75">
                  <mat-label>Original Email Template</mat-label>
                  <mat-select formControlName="template" required>
                    <mat-option *ngFor="let template of emailTemplates" [value]="template">
                      {{ template.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button
                  class="btn btn-sm em-btn em-btn-green py-2 mx-2 font-weight-bold mb-3"
                  style="line-height: 1em"
                  tooltipclass="em-tooltip"
                  title="Revert back to original email template."
                  (click)="revertToOriginalTemplate()"
                >
                  <i class="fas fa-undo pr-2"></i>Revert
                </button>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col">
                <mat-form-field class="w-100">
                  <input formControlName="emailSubjectLine" matInput required placeholder="Email Subject" />
                </mat-form-field>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-4 mx-0">
                <div class="text-dark">
                  <ng-template #emojiTemplate>
                    Include emojis 😍💖😊 to your email subject or SMS messages!
                    <ul class="mb-0">
                      <li>On Mac: Press Control+Command+Space while editing the text</li>
                      <li>On Windows: Press Windows+. while editing the text</li>
                    </ul>
                  </ng-template>
                  <div kendoTooltip [tooltipTemplate]="emojiTemplate" filter="i" position="right">
                    Use Emoji <i class="fa fa-question-circle teal-font"></i>
                  </div>
                </div>
              </div>
              <div class="col-4 mx-0">
                <div class="text-dark">
                  <ng-template #tokenTemplate>
                    Include token variables in your email subject line.
                    <ul class="mb-0">
                      <li>{{ '{' + '{FirstName}' + '}' }}</li>
                      <li>{{ '{' + '{PatientName}' + '}' }}</li>
                      <li>{{ '{' + '{ClinicName}' + '}' }}</li>
                    </ul>
                  </ng-template>
                  <div kendoTooltip [tooltipTemplate]="tokenTemplate" filter="i" position="right">
                    Use token variables <i class="fa fa-question-circle teal-font"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mx-0 teal-background mt-4">
            <div class="col">
              <h6 class="white-font uppercase my-0 py-2">SMS Template</h6>
            </div>
          </div>
          <!--Hidden from user. See task #1663-->
          <div *appAuthGuard="developerPolicy">
            <div class="row mx-0">
              <div class="col pt-2">
                <!-- <textarea
                formControlName="reviewsSmsContent"
                data-test-id="reviewsSmsContent"
                #textArea1
                placeholder="SMS Template Preview"
                class="w-100 white-background border-0"
                style="box-shadow: rgb(0 0 0 / 35%) 0px 1px 3px"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="5"
              >
              </textarea> -->

                <mat-form-field class="w-100 textbox" appearance="outline">
                  <mat-label>SMS Text</mat-label>

                  <textarea
                    formControlName="reviewsSmsContent"
                    data-test-id="reviewsSmsContent"
                    placeholder="SMS Template"
                    matInput
                    #reviewsSmsContent
                    rows="4"
                    class="overflow-hidden no-resize"
                  >
                  </textarea>
                  <mat-hint align="start" class="larger-hint text-danger">
                    ** "Reply OPTOUT to unsubscribe" is appended to each sms message automatically
                  </mat-hint>
                  <mat-hint align="end" [ngClass]="{ 'text-danger': reviewsSmsContent.value.length > 140 }"
                    >{{ reviewsSmsContent.value.length }} / 140</mat-hint
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col text-dark">
                <p class="py-2 my-0">
                  Use variables to personalize a message! ex. {{ '[FirstName]' }} -> Jane, {{ '[PatientName]' }} -> Jane
                  Smith, <br />
                  {{ '[ClinicName]' }} -> DermMedica
                </p>
              </div>
            </div>
            <div class="row mx-0 mt-2">
              <div class="col">
                <mat-label class="label-header">Link for Google Reviews: </mat-label>
                <mat-form-field
                  for="reviewLink"
                  class="small-material-text-input"
                  style="width: 70%"
                  [floatLabel]="'always'"
                >
                  <mat-label></mat-label>
                  <input
                    name="reviewLink"
                    formControlName="reviewLink"
                    id="reviewLink"
                    data-test-id="reviewLink"
                    [attr.disabled]="''"
                    matInput
                    #reviewLink
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row mx-0 mt-2">
            <div class="col">
              <mat-label class="label-header">SMS Preview</mat-label>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col pt-2">
              <mat-form-field class="w-100 textbox l-background" appearance="outline">
                <textarea
                  formControlName="reviewsSmsPreview"
                  data-test-id="reviewsSmsPreview"
                  placeholder="SMS Template Preview"
                  matInput
                  #reviewsSmsPreview
                  rows="4"
                  class="overflow-hidden no-resize"
                >
                </textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="d-flex flex-nowrap justify-content-center mt-3">
            <!--(/form?.controls['googleReviewsOn'].value === true && form?.controls['googlePlaceId'].value === '')-->
            <button
              class="btn em-btn em-btn-green campaign-button mr-1"
              data-test-id="saveReviewSettingsForm"
              (click)="saveChanges()"
              [disabled]="!form?.dirty || !form?.valid || form?.controls['googlePlaceId'].value === ''"
            >
              Save
            </button>
            <button
              class="btn em-btn em-btn-green campaign-button"
              data-test-id="cancelReviewSettingsForm"
              (click)="cancelUpdate()"
            >
              Cancel
            </button>
          </div>
        </div>
      </perfect-scrollbar>
    </form>

    <div *ngIf="form" class="flex-grow-1 px-3 mr-3 h-100">
      <ngx-loading [show]="templateLoading"></ngx-loading>

      <div class="d-flex flex-column h-100">
        <div class="row mx-0 teal-background">
          <div class="col d-flex justify-content-between">
            <ng-template #editorHelpTemplate>
              For the best email editing experience use a desktop or laptop computer.
            </ng-template>
            <h6 class="white-font uppercase my-0 mr-auto py-2">Email Template Definition</h6>
            <h6
              *ngIf="iPad"
              kendoTooltip
              [tooltipTemplate]="editorHelpTemplate"
              position="left"
              filter="span"
              class="white-font uppercase my-0 py-2"
            >
              <span class="ml-auto pl-5"><i class="fa fa-question-circle"></i></span>
            </h6>
            <!-- <a class="btn kendo-btn  white-font pl-1"  style="display:contents" target="_blank" [href]="'https://app.mailjet.com/template/' + form.get('template')?.value?.id + '/build'"><i class="fas fa-edit"></i></a> -->
          </div>
        </div>

        <div class="w-100 flex-grow-1 d-flex align-items-start justify-content-center px-0 pb-2 light-grey-background">
          <div class="h-100 w-100">
            <app-unlayer-email-editor
              id="unlayer"
              class="email-editor"
              [options]="editorOptions"
              (loaded)="loadEditor($event)"
              (ready)="editorReady($event)"
              #editor
            ></app-unlayer-email-editor>
          </div>
          <!--   <div
            #templateFiller
            *ngIf="reminderSettingsForm?.controls['campaignType']?.value == 'SMS'"
            frameBorder="0"
            class="w-100"
          >
            <span class="m-auto font-weight-bold" style="font-size: 2em">
              {{
                reminderSettingsForm?.controls['campaignType']?.value == 'SMS'
                  ? 'HTML template disabled for SMS only campaigns'
                  : 'Please select an email template'
              }}
            </span>
          </div>
          <div *ngIf="disabledForm">
            <perfect-scrollbar style="max-width: 100%; top: 0; left: 0" appDetermineMaxScrollHeight [addHeight]="0">
              <div [innerHTML]="emailHtml | keepHtml"></div>
            </perfect-scrollbar>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
