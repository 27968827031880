import { CommunicationSettingType } from './communications/notifications-settings-base.model';
import { User } from './user';

export class ClinicEmailTemplate {
  id: number = 0;
  emailTemplateType: EmailTemplateType;
  name: string;
  description: string;
  definition: string;
  html: string;
  createdByUser: User = null;
  createdDate: Date = new Date(Date.now());
  modifiedByUser: User = null;
  modifiedDate: Date = new Date();
}

export enum EmailTemplateType {
  AppointmentReminder = 1,
  RetentionCampaign = 2,
  Reviews = 3,
  PortalWelcome = 4,
  PortalPasswordReset = 5,
  PortalSetPassword = 6,
  PortalCompleteDocuments = 7,
  AppointmentScheduled = 8,
  AppointmentChanged = 9,
  AppointmentCancelled = 10,
  RequestCreditCard = 11,
}

export function compareTemplateToSettingType(templateType: EmailTemplateType, settingType: CommunicationSettingType) {
  switch (settingType) {
    case CommunicationSettingType.ScheduledConfirmation:
      return templateType == EmailTemplateType.AppointmentScheduled; 
    case CommunicationSettingType.ChangedConfirmation: 
      return templateType == EmailTemplateType.AppointmentChanged;
    case CommunicationSettingType.CancelledConfirmation: 
      return templateType == EmailTemplateType.AppointmentCancelled;
    case CommunicationSettingType.Reminder:
      return templateType == EmailTemplateType.AppointmentReminder;
    default:
      return false;
  }
}
