import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { mergeMap, takeUntil, map } from 'rxjs/operators';
import { isNullOrUndefined } from '@app/shared/helpers';
import * as moment from 'moment';
import { TypeaheadMatch, TypeaheadDirective } from 'ngx-bootstrap/typeahead';

import { Patient } from '@models/patient';
import { PatientService } from '@services/patient.service';
import { MasterOverlayService } from '@services/actionpanel.service';
import { AuthService } from '@app/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { MergePatientsComponent } from '@app/patients/merge-patients/merge-patients.component';
import { EventsService } from '@services/events.service';
import { toTitleCase } from '../../../shared/helpers';

@Component({
  selector: 'app-search-patient',
  templateUrl: './search-patient.component.html',
  styleUrls: ['./search-patient.component.less'],
})
export class SearchPatientComponent implements OnInit, OnDestroy {
  _searchPatientClicked: boolean;
  mergePatient = false;
  selection = false;
  @Input() sideNavExpanded: boolean;
  @Input() alternativeLayout = false;
  @Input()
  set searchPatientClicked(searchPatientClicked) {
    this._searchPatientClicked = searchPatientClicked;
    if (searchPatientClicked) {
      if (this._typeahead) {
        setTimeout(() => this.inputFocus.element.nativeElement.focus(), 0);
      }
    }
  }
  get searchPatientClicked() {
    return this._searchPatientClicked;
  }
  @ViewChild('typeaheadDirective', { static: true }) _typeahead: TypeaheadDirective;
  @ViewChild('typeaheadDirective', { static: true }) inputFocus: any;
  public get container() {
    return this._typeahead._container;
  }

  searchTerm = new Subject<string>();
  searchCtrl: FormControl;
  filteredPatients: Observable<Patient[]>;
  dataSource: Observable<any>;
  patients: Patient[] = [];
  SelectedPatients: Number[] = [];
  asyncSelected: string;
  typeaheadLoading: boolean;
  noResult = false;
  unsub: Subject<void> = new Subject<void>();

  noShowActive = false;
  staffScheduleActive = false;
  selectedFilter = 'name';
  showFilter = false;

  constructor(
    private patientService: PatientService,
    private masterOverlayService: MasterOverlayService,
    private router: Router,
    public authService: AuthService,
    private mergePatientDialog: MatDialog,
    private eventService: EventsService
  ) {}

  ngOnInit() {
    this.dataSource = new Observable((observer: any) => {
      observer.next(this.asyncSelected);
    }).pipe(
      mergeMap((token: string) => {
        return this.patientService.searchPatientByTerm(token, this.selectedFilter).pipe(
          map((value) => {
            return value.map((i) => {
              i['formatOption'] = function () {
                return this.firstName + ' ' + this.lastName;
              };
              return i;
            });
          })
        );
      }),
      map((val) => {
        setTimeout(() => {
          let maxHeight: string = 'inherit';
          try {
            let typeaheadContainer = document.getElementsByTagName('typeahead-container')[0];
            maxHeight = String(window.innerHeight - typeaheadContainer.getBoundingClientRect().top) + 'px';
            typeaheadContainer['style'].maxHeight = maxHeight;
          } catch (e) {}
        });
        return val;
      }),
      takeUntil(this.unsub)
    );
  }

  public getBirthday(date: string): string {
    if (!isNullOrUndefined(date)) {
      return moment(date).format('YYYY-MM-DD');
    } else {
      return 'N/A';
    }
  }

  /*
     Date:11/01/2021
     Desc: To select patient from search
  */
  public selectPatient(patientId: number, event) {
    if (event.target.checked) this.SelectedPatients.push(patientId);
    else this.SelectedPatients.splice(this.SelectedPatients.indexOf(patientId), 1);

    this.selection = this.SelectedPatients.length > 0;
    this.mergePatient = this.SelectedPatients.length == 2;
  }

  clearSelection() {
    this.SelectedPatients = [];
    this.selection = false;
  }

  public isPatientSelected(patientId: Number) {
    return this.SelectedPatients.indexOf(patientId) != -1;
  }

  /*
    Date:11/01/2021
    Desc: To open merge page with patients id
  */
  mergePatients() {
    if (this.SelectedPatients.length == 2) {
      const dialogRef = this.mergePatientDialog.open(MergePatientsComponent, {
        panelClass: 'custom-dialog-container',
        width: '1200px',
        height: 'calc(100dvh - 100px)',

        data: { patientId: this.SelectedPatients[0], duplicatePatientId: this.SelectedPatients[1] },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsub))
        .subscribe((result) => {
          if (result === true) {
            this.clearSelection();
          }
        });
    }
  }

  public getAge(DOB: string) {
    if (!isNullOrUndefined(DOB)) {
      const today = new Date();
      const birthDate = new Date(DOB);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    } else {
      return 'N/A';
    }
  }

  patientSelected(patientId: number, match, event) {
    this.eventService.closePanel();
    this.masterOverlayService.masterOverlayState(true);
    this.patientService.patientPanelPatient = null;
    this.router.navigate(['/schedule', { outlets: { 'action-panel': ['patient', patientId + '_patientprofiletab'] } }]);
    this.SelectedPatients = [];
    if (this.container !== null && this.container !== undefined) {
      this.container.selectMatch(match, event);
    }
  }

  public getPatientByTerm(term: string) {
    this.patientService.searchPatientByTerm(term).subscribe((res) => {
      this.patients = res;
    });
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  public typeaheadOnSelect(e: TypeaheadMatch): void {
    this.asyncSelected = '';
    var patientId = e.item.patientId;
    this.patientSelected(patientId, new TypeaheadMatch(patientId, ''), e);
  }

  public typeaheadNoResults(event: boolean): void {
    this.noResult = event;
  }

  public openPatientFilterPanel(e) {
    if (this.showFilter) this.showFilter = false;
    else this.showFilter = true;
  }

  public closePatientFilterPanel() {
    this.showFilter = false;
  }

  public changeFilter() {
    this.showFilter = false;
    this.asyncSelected = '';
  }

  public formatResultString(item) {
    let token = this.asyncSelected;
    let displayString;

    //Create the desired format when only "one word" is in the input
    if (token.indexOf(' ') == -1) {
      //Building the desired string output first
      displayString =
        toTitleCase(item.firstName) +
        ' ' +
        (item.nickname ? toTitleCase(item.nickname) + ' ' : '') +
        toTitleCase(item.lastName) +
        ' ' +
        this.getBirthday(item.birthDate.toString()) +
        ' (' +
        this.getAge(item.birthDate.toString()) +
        ')';
      if (this.selectedFilter == 'email') {
        displayString = displayString.concat(' ' + item.email.toLowerCase());
      }
      if (this.selectedFilter == 'phone') {
        displayString = displayString.concat(
          (item.homeNumber ? ' H:' + item.homeNumber : '') + (item.mobileNumber ? ' M:' + item.mobileNumber : '')
        );
      }

      displayString = this.matchAndBold(displayString, token);
    } else {
      let tokensList = token.split(' ');
      let displayFirst = toTitleCase(item.firstName) + ' ' + (item.nickname ? toTitleCase(item.nickname) : '');
      let displayLast = toTitleCase(item.lastName);
      displayFirst = this.matchAndBold(displayFirst, tokensList[0]);
      if (tokensList[1]) displayLast = this.matchAndBold(displayLast, tokensList[1]);

      //We dont need to worry about email or phone as this will never find email or phone with split text (ie with a space " ")
      displayString =
        displayFirst +
        ' ' +
        displayLast +
        ' ' +
        this.getBirthday(item.birthDate.toString()) +
        ' (' +
        this.getAge(item.birthDate.toString()) +
        ')';
    }

    return displayString;
  }

  //Find all possible matches in text and return text with bolding the match
  matchAndBold(text: string, token: string) {
    let tokenLen = token.length;
    let lowerText = text.toLowerCase();
    let lowerToken = token.toLowerCase();

    //Find all possible indexes of matching text
    let pos = 0;
    let result = [];
    while (lowerText.indexOf(lowerToken, pos) != -1) {
      if (result.length == 0) result.push(lowerText.indexOf(lowerToken, pos));
      else result.push(lowerText.indexOf(lowerToken, pos) + 17 * result.length); //Add 17*result.length is to account for the addition of <strong></strong>
      pos = lowerText.indexOf(lowerToken, pos) + 1;
    }

    //Bold all found matches
    for (let i = 0; i < result.length; i++) {
      text = `${text.substring(0, result[i])}<strong>${text.substring(
        result[i],
        result[i] + tokenLen
      )}</strong>${text.substring(result[i] + tokenLen)}`;
    }

    return text;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
