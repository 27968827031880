import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.authService.loginToken;
    if (user) {
      if (
        // User is Authenticated, Now we check if they have permission and then invert it
        // in other words if they have permission they skip this block otherwise they redirect
        !this.authService.userSatisfiesPolicy(route.data)
      ) {
        // not authorised so redirect to home page
        this.router.navigateByUrl('/login', { replaceUrl: true });
        return false;
      }
      //No clinic presently, don't allow any other routes
      if (
        state.url.indexOf('/management/organization/clinics/general') === -1 &&
        parseInt(this.authService.activeClinicId) === -1
      ) {
        this.router.navigateByUrl('/management/organization/clinics/general');
        return false;
      }
      // logged in with clinic so return true
      return true;
    } else {
      // not logged in so redirect to login page with the return url if we are not on the login page already
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }, replaceUrl: true});
      return false;
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
