<ngx-loading [show]="loading"></ngx-loading>
<div
  *ngIf="treatmentPlan?.plannedTreatments?.length > 0"
  class="row mx-0 pb-3"
  kendoTooltip
  tooltipClass="em-tooltip"
  position="top"
>
  <div class="col-12 px-2">
    <ng-scrollbar style="height: 200px">
      <mat-accordion [multi]="true" class="accrd-visit-treatment">
        <app-planned-treatment-list-panel
          [treatmentPlan]="treatmentPlan"
          [isLocked]="false"
          [serviceTemplateIds]="serviceTemplateIds"
          [isVisitPanel]="true"
          (addServiceToSchedule)="sendServiceToSchedule($event)"
          [typeOfTab]="TabType.VisitPanel"
          [patientId]="patientId"
        >
        </app-planned-treatment-list-panel>
      </mat-accordion>
    </ng-scrollbar>
  </div>
</div>
