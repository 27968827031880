import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { InputsModule } from '@progress/kendo-angular-inputs';

import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { CatalogueClinicSuppliesComponent } from './catalogue-clinic-supplies.component';
import { ClinicSuppliesListComponent } from './clinic-supplies-list/clinic-supplies-list.component';
import { ClinicSuppliesEditComponent } from './clinic-supplies-edit/clinic-supplies-edit.component';
import { ClinicSuppliesAddComponent } from './clinic-supplies-add/clinic-supplies-add.component';
import { ObservationGroupsListComponent } from './observation-groups-list/observation-groups-list.component';
import { EditObservationGroupComponent } from '../actionpanel/edit-observation-group/edit-observation-group.component';

@NgModule({
    declarations: [
        CatalogueClinicSuppliesComponent,
        ClinicSuppliesListComponent,
        ClinicSuppliesEditComponent,
        ClinicSuppliesAddComponent,
        ObservationGroupsListComponent,
        EditObservationGroupComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        MatTabsModule,
        MatChipsModule,
        MatAutocompleteModule,
        GridModule,
        ExcelModule,
        PDFModule,
        InputsModule,
    ],
    exports: [
        CatalogueClinicSuppliesComponent,
        ClinicSuppliesListComponent,
        ClinicSuppliesEditComponent,
        ClinicSuppliesAddComponent,
    ]
})
export class ClinicSuppliesModule {}
