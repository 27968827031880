<ng-container *ngIf="appointments?.length > 0">
  <ng-scrollbar class="h-100">
    <mat-accordion class="treatments-list" [multi]="true">
      <mat-expansion-panel
        *ngFor="let previousTreatment of appointments; trackBy: trackByFunction"
        class="mb-2 expansion-panel-margin"
        [expanded]="false"
        [disabled]="true"
        #panelList
      >
        <mat-expansion-panel-header class="px-0 py-0" [collapsedHeight]="'36px'" [expandedHeight]="'36px'">
          <mat-panel-title>
            <div class="d-flex align-items-center panel-box panel-box--small pl-0 pr-2">
              <div
                class="color-sign mr-2 d-flex align-items-center justify-content-center white-font"
                [ngStyle]="{ background: previousTreatment?.serviceIdColour }"
              ></div>
              <div class="panel-box-column d-flex flex-column">
                <div class="line-height-1 mb-1">
                  {{ previousTreatment?.serviceName }}
                </div>
                <div class="line-height-1">
                  {{ previousTreatment?.date | date : 'yyyy-MM-dd' }}
                </div>
              </div>
              <div class="panel-box-column panel-box-column--main d-flex"></div>
              <div class="panel-box-column" [ngClass]="{ danger: previousTreatment?.hasPriceBeenModified }">
                {{ previousTreatment?.price | currency }}
              </div>
            </div>
          </mat-panel-title>
          <button
            *ngIf="previousTreatment?.details?.length > 0 && !isLocked"
            type="button"
            class="panel-box-button h-auto"
            (click)="applyPreviousTreatment(previousTreatment)"
          >
            <i class="far fa-plus" title="Apply Details"></i>
          </button>
          <button
            *ngIf="previousTreatment?.details?.length > 0"
            type="button"
            class="btn btn--smaller d-flex px-1"
            (click)="panelList.expanded = !panelList.expanded"
          >
            <mat-icon>{{ panelList.expanded ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
          </button>
        </mat-expansion-panel-header>
        <div class="panel-box-content pr-2 pt-2 pb-0 border-0">
          <div class="content-row" *ngFor="let detail of previousTreatment?.details">
            <div class="pr-1">{{ detail?.area }}</div>
            <div class="pr-1">{{ detail?.detail }}</div>
            <div class="pr-1">{{ detail?.unitDetail }}</div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-scrollbar>
</ng-container>

<ng-container *ngIf="appointments?.length === 0">
  <div class="px-3 pb-3">The patient hasn't had treatments yet</div>
</ng-container>
