import { PatientService } from '@services/patient.service';
import { Appointment } from './appointment';
import { PaymentStatus } from './payment-status';
import * as moment from 'moment';
import { AppointmentService } from '@services/appointments.service';

export class AppointmentTile {
  constructor(appointment: Appointment, classList: string, scheduleDate: Date, isOverlappingEvent: boolean) {
    this.classList = classList;
    this.start = appointment.start;
    this.end = appointment.end;
    this.backColor = appointment.backgroundColor ?? appointment.service.serviceTemplate.serviceIDColour;
    this.id = appointment.appointmentId;
    this.resourceId = appointment.resourceId;
    this.source = appointment.source;
    this.title = appointment.title;
    this.notes = appointment.notes;
    this.visitIdString = appointment.visitIdString;
    this.visitId = appointment.visitId;
    this.rendering = appointment.rendering;
    this.apptType = appointment.appointmentType;
    this.isPreferred = false;
    this.paymentStatus = appointment.paymentStatus;
    this.isPlaceholder = appointment.isPlaceholder;
    this.createdDate = appointment.createdDate;
    this.onlineBooked = appointment.onlineBooked ?? false;
    this.daysUntilBirthday = PatientService.getDaysUntilBirthday(appointment.patient, moment(scheduleDate));
    this.isMinistry = appointment.isMinistry;
    this.reasonCancellationNotCharged = appointment.reasonCancellationNotCharged;
    this.convergePaymentId = appointment.convergePaymentId;
    this.squarePaymentId = appointment.squarePaymentId;
    this.requestCreditCard = appointment.requestCreditCard;

    if (appointment.service) {
      this.serviceName = appointment.service.serviceName;
      this.isLocked = appointment.service.isLocked;
      this.serviceIcon = appointment.service.templateIcon;
      if (
        !this.notes &&
        appointment.service.plannedTreatmentNotes &&
        appointment.service.plannedTreatmentNotes.length > 0
      ) {
        this.notes = appointment.service.plannedTreatmentNotes;
      }
      this.roomName = appointment.roomName;
    }

    if (appointment.appointmentType === 1) {
      this.color = AppointmentService.staffUnavailabilityColor;
    } else if (appointment.appointmentType === 2) {
      this.color = AppointmentService.blockedUnavailabilityColor;
    } else {
      this.color = appointment.color ?? appointment.service.serviceTemplate.serviceIDColour;
      this.className = isOverlappingEvent ? 'appointmentCard--smaller' : '';
    }

    if (appointment.patient) {
      this.isPreferred = appointment.patient.isPreferred;
      this.patientName =
        appointment.patient.firstName +
        (appointment.patient.nickName ? ' "' + appointment.patient.nickName + '"' : '') +
        ' ' +
        appointment.patient.lastName;
      this.patientBirthday = moment(appointment.patient.birthDate).format('MM-DD');
    } else {
      this.patientName = '';
    }
  }

  classList: string;
  start: Date;
  end: Date;
  color: string;
  className: string;
  resourceId: string;
  id: number;
  altid: number | undefined;
  source: any;
  title: string;
  notes: string;
  visitIdString: string;
  visitId: number;
  backColor: string;
  rendering: string;
  patientName: string;
  patientBirthday: string;
  serviceName: string;
  apptType: number;
  isPreferred: boolean;
  isLocked: boolean;
  daysUntilBirthday: number = 365;
  paymentStatus: PaymentStatus;
  isMinistry: boolean;
  serviceIcon: string;
  roomName: string;
  onlineBooked: boolean = false;
  createdDate: Date;
  isPlaceholder: boolean;
  reasonCancellationNotCharged: string;
  squarePaymentId: number;
  convergePaymentId: number;
  requestCreditCard: boolean;
}
