import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ObrPlottingEventType } from '@models/observation/obr-plotting-event-type';
import { Observation } from '@models/observation/observation';
import { ServiceEventService } from './service-event.service';

@Injectable()
export class PlottingEventService {

  constructor(private serviceEventService: ServiceEventService) { }

  private observationSource = new Subject<any>();
  private plottingSource = new Subject<any>();
  private pinSource = new Subject<any>();

  startPinDrop(obr: Observation) {
    this.observationSource.next({ event: ObrPlottingEventType.StartPinDrop, observation: obr });
  }

  stopPinDrop() {
    this.observationSource.next({ event: ObrPlottingEventType.StopPinDrop });
  }

  plotObservations(observations: Observation[], treatmentIsFromToday: boolean) {
    this.observationSource.next({ event: ObrPlottingEventType.PlotObservations, observations: observations, today: treatmentIsFromToday });
  }

  sendPlotDetails(plotDetails: Map<number, Observation>) {
    this.plottingSource.next({ event: ObrPlottingEventType.SendDetails, details: plotDetails });
  }

  sendAnnotations(annotationDetails: Map<number, any>) {
    this.plottingSource.next({ event: ObrPlottingEventType.SendAnnotations, details: annotationDetails });
  }

  deletePoints(leafletIds: number[], mapKey: string) {
    this.observationSource.next({ event: ObrPlottingEventType.DeletePoints, leafletIds: leafletIds, mapKey: mapKey });
  }

  deleteAnnotations(leafletIds: number[]) {
    this.plottingSource.next({ event: ObrPlottingEventType.DeleteAnnotations, leafletIds: leafletIds });
  }

  updatedPinCount(pins: number) {
    this.pinSource.next(pins);
  }

  getObservationSource(): Observable<any> {
    return this.observationSource.asObservable();
  }

  getPlotSource(): Observable<any> {
    return this.plottingSource.asObservable();
  }

  getPinCount(): Observable<any> {
    return this.pinSource.asObservable();
  }

}
