import { Directive, ElementRef, Output, OnDestroy, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appDomChangeListener]'
})
export class DomChangeListenerDirective implements OnDestroy {
  private changes: MutationObserver;

  @Output()
  public domChanged = new EventEmitter();

  constructor(private elementRef: ElementRef) {
    const element = this.elementRef.nativeElement;

    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
          mutations.forEach((mutation: MutationRecord) => this.domChanged.emit(mutation));
    });

    this.changes.observe(element, {
      attributes: true,
      childList: true,
      characterData: true, 
      subtree: true
    });
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }

}
