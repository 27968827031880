import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { eTreatmentForm } from '@models/etreatment-forms/etreatment-form';
import { eTreatmentFormService } from '@services/etreatment-form.service';
import { combineLatest, Subject } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-assign-etreatment-form',
  templateUrl: './assign-etreatment-form.component.html',
  styleUrls: ['./assign-etreatment-form.component.less'],
})
export class AssigneTreatmentFormComponent implements OnInit {
  searchFormControl: FormControl;
  @Input() patientId: number;
  @Input() quickView = false;
  @Input() filterType: DocumentType;
  unsub: Subject<void>;
  eTreatmentForms: eTreatmentForm[];
  alleTreatmentForms: eTreatmentForm[];
  lastAddedeTreatmentForm: eTreatmentForm;

  assignedeTreatmentFormIdList: number[];

  loading = false;

  constructor(
    @Optional() public dialogRef: MatDialogRef<AssigneTreatmentFormComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private eTreatmentFormService: eTreatmentFormService
  ) {}

  ngOnInit() {
    this.unsub = new Subject();
    this.loading = true;

    this.assignedeTreatmentFormIdList = [];

    // Search related setup
    this.searchFormControl = new FormControl('');

    combineLatest([
      this.eTreatmentFormService.geteTreatmentForms(null).pipe(
        tap(() => {
          this.loading = false;
        })
      ),
      this.searchFormControl.valueChanges.pipe(startWith('')),
    ])
      .pipe(
        map(([eTreatmentForms, searchString]) =>
          searchString ? eTreatmentForms.filter(this.filtereTreatmentForms(searchString)) : eTreatmentForms.slice()
        ),
        takeUntil(this.unsub)
      )
      .subscribe((eTreatmentForms) => {
        this.eTreatmentForms = eTreatmentForms;
      }); // End of the search related setup
  }

  resetFilter() {
    this.filterType = null;
    this.eTreatmentForms = this.eTreatmentForms;
  }

  alreadyAssigned(eTreatmentFormId: number) {
    return this.assignedeTreatmentFormIdList.includes(eTreatmentFormId);
  }

  filtereTreatmentForms = (searchString: string) => (eTreatmentForm: eTreatmentForm) => {
    return eTreatmentForm.name && eTreatmentForm.name.toLowerCase().includes(searchString.toLowerCase());
  };

  public assigneTreatmentForm(item) {
    this.dialogRef.close(item);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
