import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GridModule } from '@progress/kendo-angular-grid';
import { TagInputModule } from 'ngx-chips';

import { SharedModule } from '../../../shared/shared.module';
import { AddDocumentComponent } from './add-document/add-document.component';
import { DocumentDetailsComponent } from './document-details/document-details.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { PatientsDocumentTabComponent } from './patients-document-tab.component';
import { AssignClinicDocumentComponent } from './assign-clinic-document/assign-clinic-document.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ClinicDocumentsModule } from '@app/management/clinic-documents/clinic-documents.module';
import { SignatureComponent } from './signature/signature.component';
import { FormioModule } from '@formio/angular';
import { AssignClinicFormComponent } from './assign-clinic-form/assign-clinic-form.component';
import { FormsModule } from '@app/management/forms/forms.module';

@NgModule({
    declarations: [
        PatientsDocumentTabComponent,
        DocumentListComponent,
        DocumentDetailsComponent,
        AddDocumentComponent,
        AssignClinicDocumentComponent,
        SignatureComponent,
        AssignClinicFormComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        FormioModule,
        TagInputModule,
        GridModule,
        PdfViewerModule,
        ClinicDocumentsModule,
        FormsModule,
    ],
    exports: [PatientsDocumentTabComponent]
})
export class PatientDocumentsModule {}
