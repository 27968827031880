<div class="modal-header p-1 spring-green-background">
  <button type="button" class="btn white-font p-0 ml-auto mr-2" aria-label="Close" (click)="activeModal.dismiss()">
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="p-4">
  <h5 class="text-uppercase navy-font text-center mt-0 mb-3">Discount Invoice</h5>
  <div class="navy-font font-weight-bold center mb-2">This discount will be applied to each line item.</div>
  <div class="row py-3">
    <div class="col text-center align-items-center">
      <label class="mr-1 bold bigger" [for]="discountTextbox">Discount Percentage:</label>
      <kendo-numerictextbox
        #discountTextbox
        [(value)]="discountValue"
        [decimals]="1"
        [min]="0"
        [max]="100"
        [autoCorrect]="true"
        [format]="'# \\%'"
        class="bigger"
      ></kendo-numerictextbox>
    </div>
  </div>
  <br />
  <div class="save-changes-container mt-3 text-center">
    <button class="btn em-btn em-btn-green mr-2" (click)="activeModal.dismiss()">Cancel</button>
    <button [disabled]="!discountValue" class="btn em-btn em-btn-green" (click)="activeModal.close(discountValue)">
      Apply
    </button>
  </div>
</div>
