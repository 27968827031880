import { Subject } from 'rxjs';
import { Component } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { SignalrConnectionService } from '@services/signalr-connections.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  title = 'app';
  show_signalr_disconnect_message: string = null;
  constructor(
    public signalrConnections: SignalrConnectionService,
    // private idleService: IdleService,
    // private authService: AuthService
  ) {
    // this.signalrConnections.signalrDisconnected$.subscribe((connectionName: string) => {
    //   // this.show_signalr_disconnect_message = connectionName; //turn off the banner
    // })
  }

  refreshPage() {
    location.reload();
  }
}
