import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Tax } from '@models/tax';
import { TaxService } from '@services/tax.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-tax',
  templateUrl: './edit-tax.component.html',
  styleUrls: ['./edit-tax.component.less'],
})
export class EditTaxComponent implements OnInit {
  editTaxForm: FormGroup;
  states: any[];
  unsub = new Subject<any>();
  isNew: boolean;
  addOrEdit: string;
  errorMessage: string;
  taxEdit: Tax;
  taxEditEnabled: boolean;

  stateChoices: any[] = [
    {
      title: 'Active',
      value: true,
    },
    {
      title: 'Inactive',
      value: false,
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private taxService: TaxService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.unsub)).subscribe((paramMap) => {
      const taxIdStr = paramMap.get('taxId');
      if (taxIdStr != '_') {
        const taxId = Number.parseInt(taxIdStr);
        combineLatest([this.taxService.getTax(taxId), this.taxService.getClinicTaxEnabled(taxId)])
          .pipe(
            map(([tax, taxIsEnabled]) => {
              this.taxEdit = tax;
              this.taxEditEnabled = taxIsEnabled;
              this.initForm(this.taxEdit, this.taxEditEnabled);
              this.isNew = false;
              this.addOrEdit = 'Edit';
            })
          )
          .pipe(takeUntil(this.unsub))
          .subscribe();
      } else {
        this.taxEdit = new Tax();
        this.taxEditEnabled = true;
        this.initForm();
        this.isNew = true;
        this.addOrEdit = 'Add';
      }
    });
  }

  private initForm(taxToEdit?: Tax, taxEditEnabled?: boolean) {
    if (taxToEdit) {
      this.editTaxForm = this.fb.group({
        name: [taxToEdit.name, [Validators.required]],
        value: [
          (taxToEdit.value * 1000) / 10,
          // For integers only /^[0-9]+$/
          [Validators.required, Validators.max(100), Validators.min(0), Validators.pattern(/^[0-9]*\.?[0-9]*$/)],
        ],
        isActive: [taxEditEnabled, Validators.required],
      });
    } else {
      this.editTaxForm = this.fb.group({
        name: ['', Validators.required],
        value: ['', Validators.required],
        isActive: [true, Validators.required],
      });
    }

    this.editTaxForm.valueChanges.pipe(takeUntil(this.unsub)).subscribe((res) => {
      if (this.errorMessage) {
        this.errorMessage = '';
      }
    });
  }

  public cancelUpdate() {
    this.taxService.updateTaxData(false);
    this.router.navigate([this.router.routerState.snapshot.url.split('(')[0], { outlets: { 'action-panel': null } }]);
  }

  public submitTax() {
    const data = Object.assign({}, this.taxEdit);
    data.name = this.editTaxForm.controls['name'].value;
    data.value = Number.parseFloat(this.editTaxForm.controls['value'].value) / 100;
    const activate = this.editTaxForm.controls['isActive'].value;
    if (this.isNew) this.addTax(data, activate);
    else this.updateTax(data, activate);
  }

  private updateTax(data: Tax, activate: boolean) {
    let enableDisableObs: Observable<any>;
    if (activate) enableDisableObs = this.taxService.enableTax(data);
    else enableDisableObs = this.taxService.disableTax(data);
    combineLatest([this.taxService.updateTax(data), enableDisableObs])
      .pipe(
        map(([tax, clinicTax]) => {
          this.taxService.updateTaxData(true);
          this.router.navigate([
            this.router.routerState.snapshot.url.split('(')[0],
            { outlets: { 'action-panel': null } },
          ]);
        })
      )
      .pipe(takeUntil(this.unsub))
      .subscribe();
  }

  private addTax(data: Tax, activate: boolean) {
    this.taxService
      .addTax(data)
      .pipe(takeUntil(this.unsub))
      .subscribe((tax) => {
        if (activate) {
          this.taxService
            .enableTax(tax)
            .pipe(takeUntil(this.unsub))
            .subscribe((ct) => {
              this.taxService.updateTaxData(true);
              this.router.navigate([
                this.router.routerState.snapshot.url.split('(')[0],
                { outlets: { 'action-panel': null } },
              ]);
            });
        } else {
          this.taxService.updateTaxData(true);
          this.router.navigate([
            this.router.routerState.snapshot.url.split('(')[0],
            { outlets: { 'action-panel': null } },
          ]);
        }
      });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
