<div class="d-flex row w-100 h-100 ml-0 mr-0">
  <div class="col-3 px-0 h-100">
    <app-messaging-sidebar></app-messaging-sidebar>
  </div>
  <div class="col d-flex w-100 h-100 flex-column pl-0 pr-0">
    <app-error *ngIf="disconnected" [message]="getConnectionErrorMessage()"></app-error>
    <div [ngClass]="channelSelected ? 'd-flex' : 'd-none'" class="flex-column flex-grow-1 minh-0">
      <app-messaging-header class=""></app-messaging-header>
      <app-messaging-feed class="flex-grow-1 minh-0"></app-messaging-feed>
    </div>
  </div>
</div>

<app-actionpanel></app-actionpanel>
