<ngx-loading [show]="loading"></ngx-loading>
<form [formGroup]="form">
  <div class="container">
    <div class="row mb-4">
      <div class="col"><mat-label class="label-header">Choose a payment processor:</mat-label></div>
    </div>

    <div class="row">
      <div class="col" [class.isDisabled]="form?.value?.paymentProcessorType !== 0">
        <mat-radio-group
          class="example-radio-group mr-2"
          name=""
          formControlName="paymentProcessorType"
          (change)="whenPaymentProcessorTypeChanged(0)"
        >
          <mat-radio-button [value]="0"> </mat-radio-button>
        </mat-radio-group>
        <span class="align-top">None</span>
        <div class="row mx-0 mt-2">
          <div class="col ml-4 d-flex flex-column">
            <span class="square-info">No payment processor</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col" [class.isDisabled]="form?.value?.paymentProcessorType !== 1">
        <mat-radio-group
          class="example-radio-group mr-2"
          name=""
          formControlName="paymentProcessorType"
          (change)="whenPaymentProcessorTypeChanged(1)"
        >
          <mat-radio-button [value]="1"> </mat-radio-button>
        </mat-radio-group>
        <span class="align-top">emily Pay</span>
        <div class="row">
          <div class="col ml-5">
            <mat-form-field>
              <input matInput placeholder="Account Id" aria-label="Account Id" formControlName="merchantId" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field>
              <input matInput placeholder="User Id" aria-label="User Id" formControlName="merchantUserId" />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col mx-5">
            <mat-form-field class="w-100">
              <input
                matInput
                placeholder="Pin code"
                aria-label="Pin code"
                formControlName="merchantPinCode"
                class="pincode"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col" [class.isDisabled]="form?.value?.paymentProcessorType !== 2">
        <mat-radio-group
          class="example-radio-group mr-2"
          name=""
          formControlName="paymentProcessorType"
          (change)="whenPaymentProcessorTypeChanged(2)"
        >
          <mat-radio-button [value]="2"> </mat-radio-button>
        </mat-radio-group>
        <span class="align-top">Square</span>
        <div class="row mx-0 mt-2" *ngIf="isSquareLinked != null">
          <div class="col ml-4 d-flex flex-column">
            <div>
              <button
                class="btn em-btn em-btn-green"
                (click)="onLinkSquareAccountClick()"
                [disabled]="isSquareLinked || form?.value?.processor !== 2"
              >
                Link Square Account
              </button>
            </div>
            <ng-container *ngIf="!isSquareLinked; else squareLinked">
              <span class="square-info">Square account not linked.</span>
            </ng-container>
            <ng-template #squareLinked>
              <span class="square-info">Square account linked.</span>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col mt-5">
        <button class="btn em-btn em-btn-green" (click)="onSave()" [disabled]="!form.valid">Save</button>
      </div>
    </div>
  </div>
</form>
