import { authPermissions } from './auth-permissions';

export interface IPolicy {
  allPermissions?: string[];
  orPermissions?: string[];
}

export class Policies {
  constructor() {}

  private static createPolicy(allPermissions?: string[], orPermissions?: string[]): IPolicy {
    const policy = {};
    if (allPermissions) {
      policy['allPermissions'] = allPermissions;
    }
    if (orPermissions) {
      policy['orPermissions'] = orPermissions;
    }
    return policy;
  }

  // #region Management Policies
  static get management(): IPolicy {
    const allPermissions = [];
    const orPermissions = [
      authPermissions.Catalogue,
      authPermissions.ClinicAccessLocations,
      authPermissions.ClinicCancellationReasons,
      authPermissions.ClinicGeneral,
      authPermissions.ClinicMerchantDevices,
      authPermissions.ClinicOnlineBooking,
      authPermissions.ClinicPayments,
      authPermissions.ClinicPhotos,
      authPermissions.ClinicResources,
      authPermissions.ClinicUserPermissions,
      authPermissions.ClinicUsers,
      authPermissions.Forms,
      authPermissions.Organization,
    ];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get managementCatalogue(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.Catalogue];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get managementForms(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.Forms];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get managementOrganization(): IPolicy {
    const allPermissions = [];
    const orPermissions = [
      authPermissions.ClinicAccessLocations,
      authPermissions.ClinicCancellationReasons,
      authPermissions.ClinicGeneral,
      authPermissions.ClinicMerchantDevices,
      authPermissions.ClinicOnlineBooking,
      authPermissions.ClinicPayments,
      authPermissions.ClinicPhotos,
      authPermissions.ClinicResources,
      authPermissions.ClinicUserPermissions,
      authPermissions.ClinicUsers,
      authPermissions.Organization,
    ];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get addressBook(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.AddressBook];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get appointments(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.Appointments];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get billing(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.Billing];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get gallery(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.Gallery];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get messaging(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.Messaging];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get organization(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.Organization];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get staffSchedule(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.StaffSchedule];
    return this.createPolicy(allPermissions, orPermissions);
  }
  // #endregion

  // #region Clinic Policies
  static get clinic(): IPolicy {
    const allPermissions = [];
    const orPermissions = [
      authPermissions.ClinicAccessLocations,
      authPermissions.ClinicCancellationReasons,
      authPermissions.ClinicGeneral,
      authPermissions.ClinicMerchantDevices,
      authPermissions.ClinicOnlineBooking,
      authPermissions.ClinicPayments,
      authPermissions.ClinicPhotos,
      authPermissions.ClinicResources,
      authPermissions.ClinicUsers,
      authPermissions.ClinicUserPermissions,
    ];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get clinicAccessLocations(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ClinicAccessLocations];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get clinicCancellationReasons(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ClinicCancellationReasons];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get clinicGeneral(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ClinicGeneral];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get clinicMerchantDevices(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ClinicMerchantDevices];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get clinicOnlineBooking(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ClinicOnlineBooking];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get clinicPayments(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ClinicPayments];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get clinicPhotos(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ClinicPhotos];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get clinicResources(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ClinicResources];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get clinicUsers(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ClinicUsers];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get clinicUserPermissions(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ClinicUserPermissions];
    return this.createPolicy(allPermissions, orPermissions);
  }
  // #endregion

  // #region Communications Policies
  static get communications(): IPolicy {
    const allPermissions = [];
    const orPermissions = [
      authPermissions.CommunicationsAppointments,
      authPermissions.CommunicationsEmailTemplates,
      authPermissions.CommunicationsRetentionCampaigns,
      authPermissions.CommunicationsSummary,
    ];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get communicationsAppointments(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.CommunicationsAppointments];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get communicationsEmailTemplates(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.CommunicationsEmailTemplates];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get communicationsRetentionCampaigns(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.CommunicationsRetentionCampaigns];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get communicationsSummary(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.CommunicationsSummary];
    return this.createPolicy(allPermissions, orPermissions);
  }
  // #endregion

  // #region Patient Policies
  static get patientPanel(): IPolicy {
    const allPermissions = [];
    const orPermissions = [
      authPermissions.PatientAccount,
      authPermissions.PatientChart,
      authPermissions.PatientCommunications,
      authPermissions.PatientDocuments,
      authPermissions.PatientPhotos,
      authPermissions.PatientProfile,
      authPermissions.PatientTreatmentPlan,
      authPermissions.PatientNudges,
    ];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get patientList(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.PatientList];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get patientMerge(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.PatientMerge];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get patientAccount(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.PatientAccount];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get patientAccountTransactions(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.PatientAccountTransactions];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get patientCommunications(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.PatientCommunications];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get patientChart(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.PatientChart];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get patientChartDownload(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.PatientChartDownload];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get patientDocuments(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.PatientDocuments];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get patientNudges(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.PatientNudges];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get patientPhotos(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.PatientPhotos];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get patientProfile(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.PatientProfile];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get patientTreatmentPlan(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.PatientTreatmentPlan];
    return this.createPolicy(allPermissions, orPermissions);
  }
  // #endregion

  // #region Reports Policies
  static get reports(): IPolicy {
    const allPermissions = [];
    const orPermissions = [
      authPermissions.ReportsClinic,
      authPermissions.ReportsClinicSupplies,
      authPermissions.ReportsCommissions,
      authPermissions.ReportsInvoices,
      authPermissions.ReportsLiabilities,
      authPermissions.ReportsPatients,
      authPermissions.ReportsProducts,
      authPermissions.ReportsServices,
    ];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get reportsClinic(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ReportsClinic];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get reportsClinicSupplies(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ReportsClinicSupplies];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get reportsCommissions(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ReportsCommissions];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get reportsInvoices(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ReportsInvoices];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get reportsLiabilities(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ReportsLiabilities];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get reportsPatients(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ReportsPatients];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get reportsProducts(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ReportsProducts];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get reportsProviders(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ReportsProviders];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get reportsServices(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ReportsServices];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get reportsPower(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ReportsPower];
    return this.createPolicy(allPermissions, orPermissions);
  }
  // #endregion

  // #region Reviews
  static get reviews(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ReviewsView, authPermissions.ReviewsEdit];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get reviewsRequest(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ReviewsRequest];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get reviewsView(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ReviewsView];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get reviewsEdit(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.ReviewsEdit];
    return this.createPolicy(allPermissions, orPermissions);
  }
  // #endregion

  // #region Patient Messaging
  static get patientMessaging(): IPolicy {
    const allPermissions = [];
    const orPermissions = [
      authPermissions.PatientMessagingView,
      authPermissions.PatientMessagingSend,
      authPermissions.PatientMessagingRemove,
    ];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get patientMessagingSend(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.PatientMessagingSend];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get patientMessagingRemove(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.PatientMessagingRemove];
    return this.createPolicy(allPermissions, orPermissions);
  }
  // #endregion

  // #region Memberships
  static get editMemberships(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.MembershipsEdit];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get cancelMemberships(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.MembershipsCancel, authPermissions.MembershipsEarlyCancel];
    return this.createPolicy(allPermissions, orPermissions);
  }

  static get earlyCancelMemberships(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.MembershipsEarlyCancel];
    return this.createPolicy(allPermissions, orPermissions);
  }
  // #endregion

  // #region Developer
  static get developer(): IPolicy {
    const allPermissions = [];
    const orPermissions = [authPermissions.Developer];
    return this.createPolicy(allPermissions, orPermissions);
  }
  // #endregion

  // #region Other
  static get empty(): IPolicy {
    const allPermissions = null;
    const orPermissions = null;
    return this.createPolicy(allPermissions, orPermissions);
  }
  // #endregion
}
