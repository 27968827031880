import { Component, OnInit, OnDestroy, Inject, Optional, Input, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DiagnosticCodeModalComponent } from '../diagnostic-code-modal/diagnostic-code-modal.component';
import { ServicesService } from '@services/services.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-billing-code-modal',
  templateUrl: './billing-code-modal.component.html',
  styleUrls: ['./billing-code-modal.component.less']
})
export class BillingCodeModalComponent implements OnInit, OnDestroy {
  @Input() isInsideMinistry: boolean;
  @Input() initialSearchTerm: string;
  @Output() selectCode = new EventEmitter();

  searchFormControl: FormControl;
  unsub = new Subject<void>();
  billingCodeItems: any[] = [];
  loading = false;
  searchTerm = new Subject<string>();
  noResults = false;
  errors: any[] = [];

  constructor(
    @Optional() public dialogRef: MatDialogRef<DiagnosticCodeModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private servicesService: ServicesService
  ) { }

  ngOnInit() {
    this.errors = [];
    this.searchFormControl = new FormControl(this.initialSearchTerm);
    if (this.initialSearchTerm) {
      this.loading = true;
      this.getBillingCode(this.initialSearchTerm);
    }

    this.searchFormControl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      this.loading = true;
      this.getBillingCode(searchTerm);
    });
  }

  private getBillingCode(searchTerm: string) {
    this.servicesService.getBillingCodeByTerm(searchTerm)
      .subscribe(
        res => {
          this.billingCodeItems = res;
          this.noResults = !this.billingCodeItems.length;
          this.loading = false;
          this.errors = [];
        },
        (errorResponse: HttpErrorResponse) => {
          if (errorResponse.error) {
            this.errors = errorResponse.error.errors;
          }
          this.loading = false;
          this.billingCodeItems = [];
          this.noResults = false;
        }
      );
  }

  public addBillingCode(item) {
    if (this.isInsideMinistry) {
      this.selectCode.next(item);
    } else {
      this.dialogRef.close(item);
    }
  }

  public closeModal() {
    if (this.isInsideMinistry) {
      this.selectCode.next();
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
