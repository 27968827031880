import { Component, OnInit, OnDestroy, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, combineLatest } from 'rxjs';
import { startWith, map, takeUntil, tap, groupBy } from 'rxjs/operators';

import { ClinicDocument } from '@models/document/clinic-document';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ClinicSupplyType } from '@models/clinic-supply-type';
import { ClinicSupplyService } from '@services/clinic-supply.service';
import { ObservationListItemsService } from '@services/observation-list-items.service';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-clinic-supplies-add',
  templateUrl: './clinic-supplies-add.component.html',
  styleUrls: ['./clinic-supplies-add.component.less'],
})
export class ClinicSuppliesAddComponent implements OnInit {
  @Input() patientId: number;
  @Input() quickView = false;
  unsub: Subject<void>;
  clinicSupplies: ClinicSupplyType[];
  lastAddedClinicDocument: ClinicDocument;

  assignedClinicDocumentIdList: number[];

  loading = false;
  groupedClinicSupplies: Map<string, ClinicSupplyType[]>;

  constructor(
    public activeModal: NgbActiveModal,
    private clinicSupplyService: ClinicSupplyService,
    private router: Router,
    private observationListItemService: ObservationListItemsService
  ) {}

  ngOnInit() {
    this.unsub = new Subject();
    this.loading = true;

    this.clinicSupplyService.getAllClinicSupplies().subscribe((clinicSupplies) => {
      this.clinicSupplies = clinicSupplies;
      let groups = new Map<string, ClinicSupplyType[]>();

      groups.set('Toxins', []);
      groups.set('Fillers', []);
      groups.set('CoolSculpting Applicators', []);
      groups.set('CoolTone Applicators', []);
      groups.set('Needles', []);
      groups.set('Deoxycholate', []);

      this.clinicSupplies.forEach((supply) => {
        const { categoryName } = supply;
        groups.get(categoryName).push(supply);
      });

      this.groupedClinicSupplies = groups;
      this.loading = false;
    });
  }

  assignClinicSupply(clinicSupplyType: ClinicSupplyType) {
    this.activeModal.close();
    this.observationListItemService.newClinicSupplyTypeAdded.next(clinicSupplyType);
    /*   this.router.navigate([
      '/management/catalogue/clinic-supplies',
      { outlets: { 'action-panel': ['edit-clinic-supply-item', 55] } },
    ]); */

    /* const toAssign = new PatientDocument({
      id: 0,
      name: clinicDocument.name,
      filePath: clinicDocument.filePath,
      file: clinicDocument.file,
      patientId: this.patientId,
      documentTypeId: clinicDocument.documentTypeId,
      tags: clinicDocument.tags,
      uploadDate: new Date(),
      modifiedDate: new Date(),
      isClinicDocument: true,
      clinicDocumentId: clinicDocument.id,
      pdfToHtmlUrl: clinicDocument.pdfToHtmlUrl,
      eFormDefinition: clinicDocument.eFormDefinition,
    });

    toAssign.tags.forEach((t) => {
      t.id = 0;
      t.refId = t.tagId.split('-')[1];
    });

    this.loading = true;
    this.patientDocumentService.assignPatientClinicDocuments(toAssign); */
  }

  originalOrder(a: KeyValue<any, any>, b: KeyValue<any, any>): number {
    return 0;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
