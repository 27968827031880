import { ClinicServiceTemplate } from './../service/clinic-service-template';
import { DiscountType } from './discount-type.enum';

export class MembershipService {
  id: number;
  membershipId: number;
  clinicServiceTemplateId: number;
  discountType: DiscountType;
  amount: number;
  clinicServiceTemplate: ClinicServiceTemplate;
}
