import { Injectable } from '@angular/core';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { ServiceTemplatesService } from './service-templates.service';
import { AuthService } from '@app/auth/auth.service';
import { SignalrBase } from './signalr-base';
import { SignalrHttpClientService } from './signalr-http-client.service';
import { Policies } from '@app/auth/auth-policies';

@Injectable({
  providedIn: 'root',
})
export class ServiceTemplatesSignalrService extends SignalrBase<ClinicServiceTemplate> {
  constructor(
    private serviceTemplatesService: ServiceTemplatesService,
    authService: AuthService,
    private signalrHttpClientService: SignalrHttpClientService
  ) {
    super(
      'ServiceTemplates',
      Policies.empty,
      authService,
      signalrHttpClientService
    );
    this.registerServerEvents();
  }

  private registerServerEvents(): void {
    super.registerServerEvent('UpdateServiceTemplate', (data: ClinicServiceTemplate) => {
      this.checkUpdateServiceTemplatesTriggers();
    });

    super.registerServerEvent('AddServiceTemplate', (data: ClinicServiceTemplate) => {
      this.checkAddServiceTemplatesTriggers(data);
    });

    super.registerServerEvent('DeleteServiceTemplate', (data: ClinicServiceTemplate) => {
      this.checkDeleteServiceTemplatesTriggers();
    });
  }

  private checkUpdateServiceTemplatesTriggers() {}

  private checkAddServiceTemplatesTriggers(serviceTemplate: ClinicServiceTemplate) {
    this.serviceTemplatesService.serviceTemplatesHaveUpdated(serviceTemplate);
  }

  private checkDeleteServiceTemplatesTriggers() {}
}
