import { Component, Input, OnInit } from '@angular/core';
import { Patient } from '@models/patient';
import { PatientService } from '@services/patient.service';
import { SquareService } from '@services/square.service';
import { observable, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-patient-square-overview',
  templateUrl: './patient-square-overview.component.html',
  styleUrls: ['./patient-square-overview.component.less'],
})
export class PatientSquareOverviewComponent implements OnInit {
  private unsub: Observable<any> = new Observable<any>();

  private _patientId: number;
  @Input() set patientId(patientId: number) {
    if (patientId != 0 && patientId != this._patientId) {
      this._patientId = patientId;
      this.getPatientSquareDetails();
    }
  }

  get patientId() {
    return this._patientId;
  }

  @Input() panelHeight: number;

  public customer: any;

  constructor(private squareService: SquareService) {}

  ngOnInit(): void {}

  getPatientSquareDetails() {
    this.squareService
      .getCustomerByPatientId(this.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((customer) => {
        this.customer = customer;
      });
  }
}
