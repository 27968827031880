import { PatientService } from './patient.service';
import { isNullOrUndefined } from '@app/shared/helpers';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BlobService } from './blob.service';
import { ImageService } from './image.service';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { Observable, combineLatest, Subject, from } from 'rxjs';
import { environment } from '@environments/environment';
import { map, take } from 'rxjs/operators';
import { SeriesType } from '@models/photo/series-type';
import { TagType } from '@models/tag/tag-type';

@Injectable({
  providedIn: 'root',
})
export class PhotoSeriesService {
  private photoSeriesSource = new Subject<any>();
  photoSeriesSource$ = this.photoSeriesSource.asObservable();

  private metadataUpdated = new Subject<any>();
  metadataUpdated$ = this.metadataUpdated.asObservable();
  constructor(
    private http: HttpClient,
    private blobService: BlobService,
    private imageService: ImageService,
    private patientService: PatientService
  ) {}

  addPhotoToSeries(photo) {
    this.photoSeriesSource.next(photo);
  }

  createPhotoSeries(photoMetaData: PhotoMetaData): Observable<PhotoMetaData> {
    // photoMetaData.tags = photoMetaData.tags.filter(tag => tag.type != TagType.photoType);
    let series = JSON.parse(JSON.stringify(photoMetaData));
    this.imageService.sanitizePhotoMetaData(series);

    return combineLatest(
      this.blobService.getReadOnlySASObservable(),
      this.http.post<PhotoMetaData>(`${environment.baseUrl}api/Series`, series)
    ).pipe(
      map(([readOnlySAS, series]) => {
        series.filePathThumb = (series.filePathThumb ? series.filePathThumb.trim() : '') + readOnlySAS;
        if (!isNullOrUndefined(series.seriesPhotos)) {
          series.seriesPhotos.forEach((sp: PhotoMetaData) => {
            sp.filePath = (sp.filePath ? sp.filePath.trim() : '') + readOnlySAS;
            sp.filePathThumb = (sp.filePathThumb ? sp.filePathThumb.trim() : '') + readOnlySAS;
          });
        }

        this.patientService.thePatientHasBeenUpdatedById(series.patientId);
        return series;
      })
    );
  }

  updatePhotoSeries(photoMetaData: PhotoMetaData): Observable<PhotoMetaData> {
    // photoMetaData.tags = photoMetaData.tags.filter(tag => tag.type != TagType.photoType);
    let series = JSON.parse(JSON.stringify(photoMetaData));
    this.imageService.sanitizePhotoMetaData(series);
    return combineLatest(
      this.blobService.getReadOnlySASObservable(),
      this.http.put<PhotoMetaData>(`${environment.baseUrl}api/Series`, series)
    ).pipe(
      take(1),
      map(([readOnlySAS, photoMeta]) => {
        let series = new PhotoMetaData(photoMeta, photoMetaData);
        series.filePath = (series.filePath ? series.filePath.trim() : '') + readOnlySAS;
        series.filePathThumb = (series.filePathThumb ? series.filePathThumb.trim() : '') + readOnlySAS;
        if (!isNullOrUndefined(series.seriesPhotos)) {
          series.seriesPhotos.forEach((sp: PhotoMetaData) => {
            sp.filePath = (sp.filePath ? sp.filePath.trim() : '') + readOnlySAS;
            sp.filePathThumb = (sp.filePathThumb ? sp.filePathThumb.trim() : '') + readOnlySAS;
          });
        }
        this.metadataUpdated.next(series);
        return series;
      })
    );
  }

  deletePhotoSeries(id: number, patientId: number) {
    return this.http.delete(`${environment.baseUrl}api/Series/${id}`).pipe(
      map((data) => {
        this.patientService.thePatientHasBeenUpdatedById(patientId);
        return data;
      })
    );
  }

  downloadPhotoSeries(photoSeries: PhotoMetaData) {
    return from(
      new Promise((resolve, reject) => {
        //only b/a first
        if (photoSeries.isSeries) {
          var canvas: HTMLCanvasElement = <HTMLCanvasElement>document.createElement('canvas');

          var context = canvas.getContext('2d');
          var imgs = [];
          let imgCount = photoSeries.seriesPhotos.length;
          photoSeries.seriesPhotos.forEach((photo, i) => {
            var img = new Image();
            img.crossOrigin = 'anonymous';
            img.onload = () => {
              if (--imgCount == 0) {
                drawCanvas();
              }
            };
            img.onerror = () => {
              reject('image could not load ' + photo.imageName);
            };
            img.src = photo.filePath;
            imgs.push(img);
          });

          const drawCanvas = () => {
            imgs.forEach((img, i) => {
              canvas.width = i == 0 ? img.width : img.width + canvas.width;
              canvas.height = i == 0 ? img.height : canvas.height > img.height ? canvas.height : img.height;
            });
            let x = 0;
            imgs.forEach((img, i) => {
              context.drawImage(img, x, canvas.height == img.height ? 0 : canvas.height / 2);
              x += img.width;
            });

            var dt = canvas.toDataURL('image/png');
            resolve(dt);
          };
        } else {
          reject('Only can download B/A for now');
        }
      })
    );
  }
}
