import { Component, OnInit } from '@angular/core';
import { Clinic } from '@models/clinic';
import { ClinicsService } from '../../../services/clinics.service';

@Component({
  selector: 'app-org-online-booking',
  templateUrl: './org-online-booking.component.html',
  styleUrls: ['./org-online-booking.component.less'],
})
export class OrgOnlineBookingComponent implements OnInit {
  disableGrid = false;
  clinic: Clinic;
  clinicId: number;
  constructor(private clinicsService: ClinicsService) {}

  ngOnInit(): void {
    this.clinicId = +localStorage.getItem('clinicId');
    this.clinicsService.getClinicById(this.clinicId).subscribe((snapshot) => {
      this.clinic = snapshot as Clinic;
    });
  }

  changeDisableGrid($event: boolean) {
    this.disableGrid = $event;
  }

  updateOnlineBooking() {
    this.clinicsService.updateClinicBookingSettings(this.clinic).subscribe(() => {});
  }
}
