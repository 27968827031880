
<div *ngIf="externalPrescriptionEntry" [ngStyle]="{'max-height': panelHeight  ? panelHeight + 'px' : 'inherit'}" class="external-prescription grey-two-background border mt-3 print-element">
  <h6>External Prescriptions</h6>
  <app-textarea-widget
    [minRows]="1"
    [maxRows]="4"
    [textValue]="externalPrescriptionEntry.description"
    (emitText)="addExternalPrescription($event)"
  >
  </app-textarea-widget>
</div>
