import { Component, OnInit, OnDestroy, Input, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppointmentService } from '@services/appointments.service';
import { PreviousTreatment } from '@models/treatment-planning/previous-treatment';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { PaymentStatus } from '@models/appointments/payment-status';

@Component({
  selector: 'app-patient-appointments-list',
  templateUrl: './patient-appointments-list.component.html',
  styleUrls: ['./patient-appointments-list.component.less'],
})
export class PatientAppointmentsListComponent implements OnInit, OnDestroy {
  @HostBinding('hidden')
  isHidden: boolean = false;

  @Input() patientId: number;
  public previousTreatmentAppt: PreviousTreatment[] = [];
  public loading: boolean;
  private unsub: Subject<void> = new Subject<void>();
  moment: any = moment;

  @Input() panelHeight: number;
  @Input() startDate: Date = new Date(1980, 10, 30); // set random start date
  @Input() endDate: Date = moment().subtract(1, 'day').toDate();
  @Input() autoHide = true;
  isFuture = false;

  AppointmentPaymentStatus = PaymentStatus;

  constructor(
    private appointmentService: AppointmentService,
    private treatmentPlanService: TreatmentPlanService,
    private route: ActivatedRoute
  ) {
    // Set the end date to be before today
    //See #309 this needs to be added back in once the API is fixed to exclude non-paid services -- for now show all
    // this.endDate.setDate(this.endDate.getDate() - 1);
  }

  ngAfterViewInit() {}

  ngOnInit() {
    this.isFuture = this.startDate > new Date(new Date().toDateString());
    this.getPatientId();
    this.getTreatmentAppointments();

    this.treatmentPlanService.treatmentPlanScheduled$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.getTreatmentAppointments();
    });

    this.treatmentPlanService.plannedTreatmentCompleted$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.getTreatmentAppointments();
    });

    this.treatmentPlanService.plannedTreatmentCancelled$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.getTreatmentAppointments();
    });
  }

  ngOnChanges() {
    this.getPatientId();
    this.getTreatmentAppointments();
  }

  private getPatientId() {
    if (this.route.snapshot.params.patId) {
      this.patientId = this.route.snapshot.params.patId.split('_')[0];
    }
  }

  private getTreatmentAppointments(serviceTemplateId?: number) {
    this.loading = true;
    let today = new Date(new Date().toDateString());
    let startDate = this.isFuture ? today : undefined;
    let endDate = this.isFuture ? undefined : today;

    this.appointmentService
      .getTreatmentAppointmentsInDateRange(this.patientId, serviceTemplateId, startDate, endDate, undefined, false)
      .pipe(takeUntil(this.unsub))
      .subscribe((appointments: PreviousTreatment[]) => {
        this.previousTreatmentAppt = appointments.filter((appt) => {
          let startTime = moment(appt.date).startOf('day').add(moment.duration(appt.startTime)).toDate();
          let curTime = new Date();

          return this.isFuture ? startTime > curTime : startTime <= curTime;
        });
        this.loading = false;
        this.isHidden = this.previousTreatmentAppt.length == 0 && this.autoHide;
      });
  }

  public applyFilter(service?: PreviousTreatment) {
    this.appointmentService.shareServiceTemplateId(service.serviceTemplateId);
  }

  public trackByFunction(index: number, item: PreviousTreatment) {
    return index;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
