import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { EventsService } from '@services/events.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-current-date',
  templateUrl: './current-date.component.html',
  styleUrls: ['./current-date.component.less']
})
export class CurrentDateComponent implements OnInit, OnDestroy {

  public currentDate: Date;
  private unsub: Subject<void> = new Subject<void>();

  constructor(private _eventsService: EventsService) { }

  ngOnInit() {
    setTimeout(() => {
      //Switching clinic in 'Users' to 'Developer' and then switching to Scheduler
      //caused a console error - 0 timeout corrected it
      this._eventsService.currentDate.pipe(takeUntil(this.unsub)).subscribe(value => {
        this.currentDate = value;
      });
    }, 0);
  }

  getDayOfWeek(date: Date) {
    return moment(date).format('dddd');
  }

  getDate(date: Date) {
    return moment(date).format('MMMM D, YYYY');
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

}
