import { PhotoMetaData } from "./photo-meta-data";

export class PhotoDrawing {
    id: number; 
    photoId: number;
    photo: PhotoMetaData;
    serviceId?: number;
    patientNoteId?: number;
    clinicId: number;
    drawingData: string;

    public constructor(init?: Partial<PhotoDrawing>) {
        Object.assign(this, init);
      }
}

export interface DrawingData {
  history: fabric.Object[];
  transformMatrix: number[];
  canvasData: fabric.Canvas;
}