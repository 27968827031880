import { PlannedTreatment } from '@models/treatment-planning/planned-treatment';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ObservationType, ObservationTypes } from '@models/observation/observation';
import { Service } from '@models/service/service';
import { Patient } from '@models/patient';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { ObservationTypesService } from '@services/observation-types.service';
import { ActivatedRoute } from '@angular/router';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';

@Component({
  selector: 'app-service-observations',
  templateUrl: './service-observations.component.html',
  styleUrls: ['./service-observations.component.less'],
})
export class ServiceObservationsComponent implements OnInit, OnDestroy {
  @Input()
  service: Service;
  @Input()
  patient: Patient;
  @Input()
  associatedPlannedTreatment: PlannedTreatment;

  treatmentPlanning: boolean;
  observationType: ObservationType;
  treatmentDate: moment.Moment;

  unsub = new Subject<any>();

  public serviceDetailTemplate = ServiceDetailTemplate;

  constructor(private observationTypesService: ObservationTypesService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.findObservationType();
    this.route.params.subscribe((params) => {
      this.treatmentPlanning = JSON.parse(params.treatmentPlanning);
    });
  }

  private findObservationType() {
    switch (this.service.serviceDetailTemplateId) {
      
      case this.serviceDetailTemplate.Injections:
        this.observationTypesService.getObservationTypeByName(ObservationTypes.Injectables).subscribe((response) => {
          this.observationType = response;
        });
        break;

      case this.serviceDetailTemplate.Coolsculpting:
        this.observationTypesService.getObservationTypeByName(ObservationTypes.Injectables).subscribe((response) => {
          this.observationType = response;
        });
        break;

      case this.serviceDetailTemplate.CoolTone:
        this.observationTypesService.getObservationTypeByName(ObservationTypes.CoolTone).subscribe((response) => {
          this.observationType = response;
        });
        break;

      default:
        break;
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
