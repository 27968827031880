import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { PatientService } from '@services/patient.service';
import { Router } from '@angular/router';
import { Patient } from '@models/patient';
import { Subject } from 'rxjs';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { Address } from '@models/address';
import { MasterOverlayService } from '@services/actionpanel.service';
import { FormatterService } from '@services/formatter.service';
import { Doctor } from '@models/doctor';
import { Pharmacy } from '@models/pharmacy';
import { EventsService } from '@services/events.service';
import { EditPatientContentComponent } from '../edit-patient-content/edit-patient-content.component';

@Component({
  selector: 'app-edit-patient',
  templateUrl: './edit-patient.component.html',
  styleUrls: ['./edit-patient.component.less'],
})
export class EditPatientComponent implements OnInit, AfterViewInit, OnDestroy {
  editPatientPanelVisible = false;
  addOrEdit = 'Add';
  patientIdParam: string;
  selectedPatient: Patient;
  editedPatient: Patient;
  selectedAddress: Address;
  editedAddress: Address;
  theDoctors: Doctor[];
  thePharmacies: Pharmacy[];

  countriesOfTheWorld: string[] = [];
  provincesAndStates: string[] = [];

  unsub: Subject<void> = new Subject<void>();

  @ViewChild(EditPatientContentComponent, { static: true }) patientContentComponent: EditPatientContentComponent;
  isValid;
  constructor(
    private patientService: PatientService,
    private catalogueUpdatesService: CatalogueUpdatesService,
    public formatterService: FormatterService,
    private masterOverlayService: MasterOverlayService,
    private router: Router
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  closePanel() {
    this.patientService.patientHasBeenAdded(null);
    this.catalogueUpdatesService.refreshRequired = false;
    this.catalogueUpdatesService.catalogueUpdateComplete();
    this.masterOverlayService.masterOverlayState(false);
    const returnURL = this.router.url.slice(0, this.router.url.indexOf('('));
    this.router.navigate([returnURL, { outlets: { 'action-panel': null } }]);
  }
}
