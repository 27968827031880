import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ServiceTemplatesService } from '@services/service-templates.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-org-online-service-settings',
  templateUrl: './org-online-service-settings.component.html',
  styleUrls: ['./org-online-service-settings.component.less'],
})
export class OrgOnlineServiceSettingsComponent implements OnInit {
  gridView: GridDataResult;
  disableGrid = false;
  @Output() disableGridEvent = new EventEmitter<boolean>();
  loading = false;
  unsub: Subject<void> = new Subject<void>();
  services: ClinicServiceTemplate[] = [];

  constructor(
    private router: Router,
    private serviceTemplatesService: ServiceTemplatesService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.serviceTemplatesService.refreshRequired$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.disableGrid = false;
      this.sendDisableGrid(this.disableGrid);
      if (this.serviceTemplatesService.refreshRequired$) {
        this.refreshData();
      }
    });
    this.refreshData();
  }

  loadItems() {
    this.gridView = {
      data: this.services,
      total: this.services.length,
    };
  }

  public editHandler({ dataItem }) {
    this.disableGrid = true;
    this.sendDisableGrid(this.disableGrid);
    this.router.navigate([
      '/management/organization/clinics/online-booking',
      { outlets: { 'action-panel': ['edit-online-booking-service', dataItem.id] } },
    ]);
  }

  sendDisableGrid(value: boolean) {
    this.disableGridEvent.emit(value);
  }

  refreshData() {
    this.loading = true;
    this.serviceTemplatesService.getServiceTemplates().subscribe((result) => {
      this.services = result;
      this.loadItems();
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }

  async updateServiceAvailable(service: ClinicServiceTemplate, event) {
    if (!event.target.checked && service.prerequisiteForIds?.length) {
      let serviceString = '<span class="font-weight-bold">';
      for (let serviceId of service.prerequisiteForIds) {
        serviceString += `${this.services.find((s) => s.id == serviceId).serviceName}<br/>`;
      }
      serviceString += '</span>';
      const dialogResult = await this.dialog
        .open(GenericDialogComponent, {
          width: '330px',
          data: {
            showCancel: true,
            title: 'Warning',
            content: `Disabling <span class="font-weight-bold">${service.serviceName}</span> will remove it as a prerequisite for:<br/><br/>${serviceString}<br/>Would you like to continue?`,
            confirmButtonText: 'Disable',
          },
        })
        .afterClosed()
        .pipe(takeUntil(this.unsub))
        .toPromise();
      if (!(dialogResult === 'confirm')) {
        event.target.checked = true;
        return;
      }
    }
    service.allowOnlineBooking = event.target.checked;
    this.serviceTemplatesService.updateServiceTemplateOnlineBooking(service).subscribe(() => {});
  }
}
