import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'keepStyle' })
export class EscapeStylePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(content: string) {
    return this.sanitizer.bypassSecurityTrustStyle(content);
  }
}
