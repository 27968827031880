<div class="grey-two-background">
  <div class="row px-3" kendoTooltip tooltipClass="em-tooltip" position="top">
    <div class="col">
      <div class="row" [ngClass]="{ 'pb-2': !showActionButtons }">
        <div class="col-8 pr-0">
          <div class="row d-flex align-items-center">
            <div class="col my-2">
              <div class="d-flex align-items-center justify-content-between">
                <h6
                  class="d-inline-block text-truncate my-auto"
                  [ngClass]="{
                    pointer: patientPanelPolicySatisfied
                  }"
                  (click)="patientPanelPolicySatisfied && goToPatientPanel()"
                  [title]="getPatientName() | titlecase"
                >
                  {{ getPatientName() | titlecase }}
                </h6>
                <button
                  *ngIf="showActionButtons"
                  title="Edit"
                  (click)="onEditPatient()"
                  class="btn btn-sm em-btn em-btn-green pull-right ml-2"
                >
                  <i class="far fa-pencil"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="row d-flex align-items-center mb-1">
            <div class="col-1 small text-center">
              <i class="fas fa-map-marker-alt" title="Location"></i>
            </div>
            <div class="col">
              {{ patient ? patient?.address.city : '' }}
              <ng-container *ngIf="patient && patient.address.city && patient.address.province">, </ng-container>
              {{ patient ? patient.address.province : '' }}
            </div>
          </div>
          <div class="row d-flex align-items-center mb-1">
            <div class="col-1 small text-center">
              <i class="fas fa-gift gift" title="Birth Date"></i>
            </div>
            <div class="col" defaultBirthdayCheck [birthDate]="patient?.birthDate">
              {{ formatBirthday(patient?.birthDate) }} (Age: {{ formatAge(patient?.birthDate) }})
            </div>
            <div class="col-1 small text-center">
              <ng-container *ngIf="patient?.gender === 'Female'"
                ><i class="fas fa-venus" title="Gender"></i>
              </ng-container>
              <ng-container *ngIf="patient?.gender === 'Male'"
                ><i class="fas fa-mars" title="Gender"></i>
              </ng-container>
              <ng-container *ngIf="patient && patient.gender !== 'Male' && patient.gender !== 'Female'"
                ><i class="fas fa-genderless" title="Gender"></i
              ></ng-container>
            </div>
            <div class="col-4">
              {{ patient ? patient.gender : '' }}
            </div>
          </div>
          <div class="row d-flex align-items-center mb-1">
            <div class="col-1 small text-center">
              <i class="fas fa-phone" title="Mobile Number"></i>
            </div>
            <div class="col">
              {{ patient ? patient.mobileNumber : '' }}
            </div>
            <div class="col-1 small text-center">
              <i class="fas fa-dollar-sign" title="Credit Amount"></i>
            </div>
            <div class="col-4">
              {{ patient?.creditAmount | number : '1.2-2' }}
            </div>
          </div>
        </div>
        <div class="col-4 mt-2 pr-0" style="height: 114px">
          <ngx-loading [show]="imgLoading" class="img-loading"></ngx-loading>
          <img
            [src]="patient?.avatar ?? '/assets/profile/noprofile.jpg'"
            (load)="imgLoaded()"
            class="w-100 mh-100"
            style="object-fit: contain"
          />
        </div>
      </div>
      <div *ngIf="showActionButtons" class="row">
        <div class="col">
          <div class="d-flex align-items-center justify-content-between mt-0 mb-1">
            <div class="d-flex align-items-center justify-content-start">
              <h6 class="navy-font sub-heading mt-2 mb-0">Notes/Alerts</h6>
              <mat-slide-toggle
                [color]="'warn'"
                class="rush-note-toggle pl-2"
                [ngModel]="patient?.rushPatientNote"
                title="Toggle important patient note"
                data-test-id="rushNoteSlider"
                (change)="togglePatientRushNote($event?.checked)"
              >
              </mat-slide-toggle>
            </div>
            <div *appAuthGuard="patientProfilePolicy" class="d-flex align-items-center justify-content-between mt-1 mb-1 px-0" style="width: 121px">
              <button title="Patient Profile" (click)="goToPatientProfile()" class="btn btn-sm em-btn em-btn-green">
                <i class="fal fa-user"></i>
              </button>
              <button *appAuthGuard="patientChartPolicy" title="Patient Chart" (click)="goToPatientChart()" class="btn btn-sm em-btn em-btn-green">
                <i class="fal fa-notes-medical"></i>
              </button>
              <button *appAuthGuard="patientAccountPolicy" title="Patient Invoices" (click)="goToPatientInvoices()" class="btn btn-sm em-btn em-btn-green">
                <i class="fal fa-file-invoice-dollar"></i>
              </button>
            </div>
          </div>
          <div class="mb-3">
            <app-textarea-widget
              [minRows]="1"
              [maxRows]="3"
              [textValue]="patient?.notesAndAlerts"
              [isDanger]="patient?.rushPatientNote"
              (emitText)="savePatientNotes($event)"
            >
            </app-textarea-widget>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
