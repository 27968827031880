<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>

<div [ngStyle]="{'height': 'calc(100dvh - 75px)'}">
  <kendo-grid
    [data]="gridView"
    [sort]="gridState.sort"
    [sortable]="true"
    [selectable]="true"
    [style.height.%]="100"
    (add)="onAddClick()"
    (cancel)="cancelHandler($event)"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)">

    <ng-template kendoGridToolbarTemplate>
      <div class="d-flex">
        <span class="pl-4 search-box border border-radius">
          <span class="fa fa-search"></span>
          <mat-form-field class="w-75 p-3">
            <input 
              matInput 
              placeholder="Search..."
              aria-label="Search Documents" 
              [formControl]="searchCtrl"/>
            <button 
              *ngIf="searchCtrl.value"
              mat-button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="searchCtrl.reset()">
                <mat-icon><i class="fa fa-times-circle"></i></mat-icon>
            </button>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option 
                *ngFor="let textTemplate of (filteredTextTemplates | async)" 
                [value]="textTemplate.name">
                  <span>{{ textTemplate.name }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </span>
      </div>
      <button kendoGridAddCommand class="btn em-btn"><i class="fal fa-plus-circle"></i> Add Text Template</button>
      <button 
        type="button"
        class="btn em-btn" 
        kendoGridExcelCommand>
        <i class="fal fa-file-excel"></i> Export to Excel
      </button>
    </ng-template>
    <kendo-grid-excel fileName="text_templates.xlsx"></kendo-grid-excel>
    <kendo-grid-column field="name" title="Name"></kendo-grid-column>
    <kendo-grid-column field="text" title="Text"></kendo-grid-column>

    <kendo-grid-command-column title="Actions" min-width="125px" [width]="125" class="text-center">
      <ng-template kendoGridCellTemplate>
        <button kendoGridEditCommand class="btn kendo-btn"><i class="fas fa-edit"></i></button>
        <button kendoGridRemoveCommand class="btn kendo-btn"><i class="fas fa-minus-circle"></i></button>
      </ng-template>
    </kendo-grid-command-column>

  </kendo-grid>
</div>
  
<app-actionpanel></app-actionpanel>