import { LogLevel } from '@microsoft/signalr';

export const environment = {
  production: true,
  hmr: false,
  mixpanelToken: 'd141e30f370ce665973da921e8eaec5e',
  baseUrl: document.getElementsByTagName('base')[0].href,
  signalRUrl: '',
  signalRLogLevel: LogLevel.Error,
  jQuerySource: 'https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js',
  payWithConvergeSource: 'https://api.convergepay.com/hosted-payments/PayWithConverge.js',
  squarePaymentSource: 'https://web.squarecdn.com/v1/square.js ',
};
