import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Form } from '@models/forms/form';
import { FormType } from '@models/forms/form-type';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  formsLoaded = new Subject<Form[]>();
  formSelected = new Subject<Form>();
  formUpdated = new Subject<Form>();
  formAdded = new Subject<Form>();
  formDeactivated = new Subject<Form>();
  formActivated = new Subject<Form>();
  formChangeCancelled = new Subject<Form>();
  formDeleted = new Subject<Form>();

  defaultId = 0;
  constructor(private http: HttpClient) {}

  getForms(formType?: FormType | null) {
    if (formType) return this.getFormsByFormType(formType);
    else return this.http.get<Form[]>(environment.baseUrl + 'api/ClinicForms');
  }

  getActiveForms() {
    return this.http.get<Form[]>(environment.baseUrl + 'api/ClinicForms/Active');
  }

  getFormsByFormType(formType: FormType): Observable<Form[]> {
    return this.http.get<Form[]>(environment.baseUrl + 'api/ClinicForms/ByFormType/' + formType.id);
  }

  getFormsByServiceTemplateType(serviceTemplateTypeId: number): Observable<Form[]> {
    return this.http.get<Form[]>(
      environment.baseUrl + 'api/ClinicForms/ByServiceTemplateType/' + serviceTemplateTypeId
    );
  }

  getFormById(formId): Observable<Form> {
    return this.http.get<Form>(environment.baseUrl + 'api/ClinicForms/' + formId);
  }

  addForm(form: Form) {
    return this.http.post<Form>(environment.baseUrl + 'api/ClinicForms', form).pipe(
      map((addedForm) => {
        this.formAdded.next(addedForm);
        return addedForm;
      })
    );
  }

  updateForm(form: Form) {
    return this.http.put<Form>(environment.baseUrl + 'api/ClinicForms', form).pipe(
      map((updatedForm) => {
        this.formUpdated.next(updatedForm);
        return updatedForm;
      })
    );
  }

  deactivateForm(form: Form) {
    return this.http.put(environment.baseUrl + 'api/ClinicForms/SetFormActiveStatus/' + form.id + '/' + 0, {}).pipe(
      map(() => {
        this.formDeactivated.next();
        return;
      })
    );
  }

  deleteForm(form: Form) {
    return this.http.delete(environment.baseUrl + 'api/ClinicForms/' + form.id).pipe(
      map(() => {
        this.formDeleted.next();
        return;
      })
    );
  }

  activateForm(form: Form) {
    return this.http.put(environment.baseUrl + 'api/ClinicForms/SetFormActiveStatus/' + form.id + '/' + 1, {}).pipe(
      map(() => {
        this.formActivated.next();
        return;
      })
    );
  }
  cloneForm(formId: number) {
    return this.http.post<Form>(environment.baseUrl + 'api/ClinicForms/CloneClinicForm/' + formId, {}).pipe(
      map((clonedForm) => {
        this.formAdded.next(clonedForm);
        return clonedForm;
      })
    );
  }
}
