<form [formGroup]="toolbarForm">
  <div class="form-row">
    <div class="form-group col">
      <label for="treatmentType">Treatment Type</label>
      <select class="form-control" formControlName="treatmentType" id="treatmentType">
        <option [ngValue]="treatmentTypes.Toxins">{{ treatmentTypes.Toxins }}</option>
        <option [ngValue]="treatmentTypes.Fillers">{{ treatmentTypes.Fillers }}</option>
        <!-- Deoxycholate has been added as it's own TreatmentDetailTemplate -->
        <!-- <option [ngValue]="treatmentTypes.Deoxycholate">{{ treatmentTypes.Deoxycholate }}</option> -->
      </select>
    </div>
    <ng-container formGroupName="observation">
      <!-- Toxin -->
      <ng-container *ngIf="treatmentType === treatmentTypes.Toxins">
        <ng-container formGroupName="details">
          <div class="form-group col">
            <label for="area">Area</label>
            <select class="form-control" formControlName="area" data-test-id="injectables-toolbar-area-select">
              <option *ngFor="let area of injectionFormService.toxinAreas" [ngValue]="area.id">
                {{ area.name }}
              </option>
            </select>
          </div>
          <div class="form-group col">
            <label for="injected">Toxin</label>
            <select
              class="form-control"
              formControlName="injected"
              (change)="onObrListTypeSelected($event)"
              data-test-id="injectables-toolbar-toxin-select"
            >
              <ng-container *ngFor="let toxin of injectionFormService.toxins">
                <option [ngValue]="toxin.id">
                  {{ toxin.displayName }}
                </option>
              </ng-container>
            </select>
          </div>
        </ng-container>
        <div *ngIf="isInventoryRemainingDisplayed()" class="form-group col">
          <label for="inventory">Inventory</label>
          <input
            name="inventory"
            class="form-control text-white text-center w-100"
            disabled
            [ngClass]="{
              'bg-danger': getInventoryRemaining() <= 0 && getInventoryRemaining() !== null,
              'bg-success': getInventoryRemaining() > 0,
              'bg-white': getInventoryRemaining() === null
            }"
            [value]="getInventoryRemainingString()"
          />
        </div>
        <div class="form-group col">
          <label for="value">Amount (Units)</label>
          <input
            type="number"
            class="form-control w-100"
            data-test-id="injectables-toolbar-amount-input"
            formControlName="value"
            min="0"
            inputmode="numeric"
            RestrictDecimalPlacesNumber
            [nDecimalPlaces]="toolbarForm?.get('treatmentType')?.value == treatmentTypes.Toxins ? 1 : 2"
          />
        </div>
        <div class="form-group col">
          <label>Total</label>
          <div>{{ total }}</div>
        </div>
      </ng-container>

      <!-- Filler -->
      <ng-container *ngIf="treatmentType === treatmentTypes.Fillers">
        <ng-container formGroupName="details">
          <div class="form-group col">
            <label for="area">Area</label>
            <select class="form-control" formControlName="area">
              <option *ngFor="let area of injectionFormService.fillerAreas" [ngValue]="area.id">
                {{ area.name }}
              </option>
            </select>
          </div>
          <div class="form-group col">
            <label for="injected">Filler</label>
            <select
              class="form-control"
              formControlName="injected"
              (change)="onObrListTypeSelected($event)"
            >
              <ng-container *ngFor="let filler of injectionFormService.fillers">
                <option [ngValue]="filler.id">
                  {{ filler.displayName }}
                </option>
              </ng-container>
            </select>
          </div>
        </ng-container>
        <div *ngIf="isInventoryRemainingDisplayed()" class="form-group col-1">
          <label for="inventory">Inventory</label>
          <input
            class="form-control text-white text-center w-100"
            disabled
            name="inventory"
            [ngClass]="{
              'bg-danger': getInventoryRemaining() <= 0 && getInventoryRemaining() !== null,
              'bg-success': getInventoryRemaining() > 0,
              'bg-white': getInventoryRemaining() === null
            }"
            [value]="getInventoryRemainingString()"
          />
        </div>
        <div class="form-group col-1">
          <label for="value">Amount</label>
          <input
            type="number"
            class="form-control w-100"
            formControlName="value"
            min="0"
            inputmode="numeric"
            RestrictDecimalPlacesNumber
            [nDecimalPlaces]="toolbarForm.get('treatmentType').value == treatmentTypes.Toxins ? 1 : 2"
          />
        </div>
        <ng-container formGroupName="details">
          <div class="form-group col">
            <label for="needle">Needle</label>
            <select class="form-control" formControlName="needle">
              <option *ngFor="let needle of injectionFormService.needles" [ngValue]="needle.id">
                {{ needle.name }}
              </option>
            </select>
          </div>
          <div class="form-group col">
            <label for="depth">Depth</label>
            <select class="form-control" formControlName="depth">
              <option *ngFor="let depth of injectionFormService.depths" [ngValue]="depth.id">
                {{ depth.name }}
              </option>
            </select>
          </div>
          <div class="form-group col">
            <label for="deliveryMethod">Delivery</label>
            <select class="form-control" formControlName="deliveryMethod">
              <option *ngFor="let delivery of injectionFormService.deliveries" [ngValue]="delivery.id">
                {{ delivery.name }}
              </option>
            </select>
          </div>
          <div class="form-group col">
            <label>Total</label>
            <div>{{ total }}</div>
          </div>
        </ng-container>
      </ng-container>

      <!-- Deoxycholate -->
      <ng-container *ngIf="treatmentType === treatmentTypes.Deoxycholate">
        <ng-container formGroupName="details">
          <div class="form-group col">
            <label for="area">Area</label>
            <select class="form-control" formControlName="area" data-test-id="injectables-toolbar-area-select">
              <option *ngFor="let area of injectionFormService.deoxycholateAreas" [ngValue]="area.id">
                {{ area.name }}
              </option>
            </select>
          </div>
          <div class="form-group col">
            <label for="injected">Toxin</label>
            <select
              class="form-control"
              formControlName="injected"
              (change)="onObrListTypeSelected($event)"
              data-test-id="injectables-toolbar-toxin-select"
            >
              <ng-container *ngFor="let deoxycholate of injectionFormService.deoxycholates">
                <option [ngValue]="deoxycholate.id">
                  {{ deoxycholate.displayName }}
                </option>
              </ng-container>
            </select>
          </div>
        </ng-container>
        <div *ngIf="isInventoryRemainingDisplayed()" class="form-group col">
          <label for="inventory">Inventory</label>
          <input
            class="form-control text-white text-center w-100"
            disabled
            name="inventory"
            [ngClass]="{
              'bg-danger': getInventoryRemaining() <= 0 && getInventoryRemaining() !== null,
              'bg-success': getInventoryRemaining() > 0,
              'bg-white': getInventoryRemaining() === null
            }"
            [value]="getInventoryRemainingString()"
          />
        </div>
        <div class="form-group col">
          <label for="value">Amount (Units)</label>
          <input
            type="number"
            class="form-control w-100"
            data-test-id="injectables-toolbar-amount-input"
            formControlName="value"
            min="0"
            inputmode="numeric"
            RestrictDecimalPlacesNumber
            [nDecimalPlaces]="toolbarForm?.get('treatmentType')?.value == treatmentTypes.Toxins ? 1 : 2"
          />
        </div>
        <div class="form-group col">
          <label>Total</label>
          <div>{{ total }}</div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</form>
