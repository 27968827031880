export enum TagType {
  custom = 0,
  service = 1,
  document = 2,
  bodyPart = 3,
  photoType = 4,
  autoGenerated = 5,
  avatar = 6,
  consentType = 7,
  supply = 10,
}
