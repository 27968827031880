import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ConvergeSessionTokenRequest } from '@models/payments/converge-session-token-request';

@Injectable({
  providedIn: 'root',
})
export class ConvergePaymentsService {
  private baseUrl = environment.baseUrl + 'api/Converge';

  constructor(private http: HttpClient) {}

  getSessionToken(tokenRequest: ConvergeSessionTokenRequest) {
    return this.http.post<any>(`${this.baseUrl}/GetSessionToken`, tokenRequest);
  }

  savePaymentInfo(patientId: number, responseJson: string) {
    return this.http.post<any>(`${this.baseUrl}/SavePaymentInfo/${patientId}`, responseJson);
  }

  getPatientCards(patientId: number) {
    return this.http.get<any>(`${this.baseUrl}/GetPatientCards/${patientId}`);
  }

  getPatientCardDetails(patientTokenId: number) {
    return this.http.get<any>(`${this.baseUrl}/GetPatientCardDetails/${patientTokenId}`);
  }
}
