import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { Review } from '@models/reviews/review';
import { ReviewService } from '@services/review.service';
import { SignalrBase } from './signalr-base';
import { SignalrHttpClientService } from './signalr-http-client.service';
import { Policies } from '@app/auth/auth-policies';

@Injectable({
  providedIn: 'root',
})
export class ReviewsSignalrService extends SignalrBase<Review> {
  constructor(
    authService: AuthService,
    private reviewsService: ReviewService,
    private signalrHttpClientService: SignalrHttpClientService
  ) {
    super(
      'PatientReviews',
      Policies.appointments,
      authService,
      signalrHttpClientService
    );
    this.registerServerEvents();
  }

  private registerServerEvents(): void {
    super.registerServerEvent('ReviewsUpdated', () => {
      this.reviewsService.updateReviewNotificationsCount();
    });
  }
}
