import { NgModule } from '@angular/core';
import { ImageComponent } from './image/image.component';
import { ServiceObservationsComponent } from './service-observations/service-observations.component';
import { CoolsculptingComponent } from './service-observations/coolsculpting/coolsculpting.component';
import { InjectionComponent } from './service-observations/injection/injection.component';
import { InjectionToolbarComponent } from './treatment-toolbar/injection-toolbar/injection-toolbar.component';
import { TreatmentToolbarComponent } from './treatment-toolbar/treatment-toolbar.component';
import { CoolsculptingToolbarComponent } from './treatment-toolbar/coolsculpting-toolbar/coolsculpting-toolbar.component';
import { ObservationBillingComponent } from './service-observations/observation-billing/observation-billing.component';
import { AdvancedTreatmentComponent } from './advanced-treatment.component';
import { TreatmentBillingComponent } from './service-observations/treatment-billing/treatment-billing.component';
import { PatientTransactionsModule } from '../../../../../components/patient-transactions/patient-transactions.module';
import { ToolbarEventService } from '@services/service-detail/toolbar-event.service';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { SharedModule } from '../../../../../shared/shared.module';
import { AssessmentFeedComponent } from './service-observations/coolsculpting/assessment-feed/assessment-feed.component';
import { UnitBillingComponent } from './service-observations/unit-billing/unit-billing.component';
import { CooltoneComponent } from './service-observations/cooltone/cooltone.component';
import { CooltoneToolbarComponent } from './treatment-toolbar/cooltone-toolbar/cooltone-toolbar.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DeoxycholateToolbarComponent } from './treatment-toolbar/deoxycholate-toolbar/deoxycholate-toolbar.component';

@NgModule({
  declarations: [
    ImageComponent,
    ServiceObservationsComponent,
    CoolsculptingComponent,
    InjectionComponent,
    InjectionToolbarComponent,
    TreatmentToolbarComponent,
    CoolsculptingToolbarComponent,
    ObservationBillingComponent,
    AdvancedTreatmentComponent,
    TreatmentBillingComponent,
    AssessmentFeedComponent,
    UnitBillingComponent,
    CooltoneComponent,
    CooltoneToolbarComponent,
    DeoxycholateToolbarComponent,
  ],
  imports: [SharedModule, LeafletModule, LeafletDrawModule, PatientTransactionsModule, MatCheckboxModule],
  providers: [ToolbarEventService],
  exports: [AdvancedTreatmentComponent],
})
export class AdvancedTreatmentModule {}
