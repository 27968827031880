<div class="d-flex flex-wrap w-100 transactions-item mb-2 px-3 py-2">
  <div class="transactions-item-row d-flex align-items-center justify-content-between">
    <span class="teal-font">{{ getFormattedTransactionDate(patientTransaction.createDate) }}</span>
    <!-- Removed until details is implemented, CJS -->
    <!-- <button
      kendoTooltip
      tooltipClass="em-tooltip"
      position="top"
      *ngIf="isRoutableItem()"
      type="button"
      class="btn transactions-item-btn service-note-btn navy-font px-0 py-0"
      (click)="routeToItemObject()"
    >
      <i class="far fa-file-alt" title="Details"></i>
    </button> -->
  </div>

  <div class="transactions-item-row" *ngIf="patientTransaction?.createdBy">
    <strong>Created by: </strong>{{ patientTransaction.createdBy.firstName }}
    {{ patientTransaction.createdBy.lastName }}
  </div>

  <div class="transactions-item-row" [innerHTML]="patientTransaction.description">
    <!-- {{ patientTransaction.description }} -->
  </div>
</div>
