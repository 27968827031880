import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ChatMessage } from '../models/chat-message.model';
import { Observable, Subject } from 'rxjs';
import { ChatMessageFromServer } from '../models/chat-message-from-server.model';

@Injectable()
export class ChatMessagesService {

  private messageReceived  = new Subject<ChatMessageFromServer>();
  public onMessageReceived$ = this.messageReceived.asObservable();

  constructor(
    private http: HttpClient) { }

  getAllMessagesByChannelId(channelId: number, page: number = 0): Observable<ChatMessage[]> {
    let url = environment.baseUrl + `api/Messaging/GetAllChatMessagesByChannelId?channelId=${channelId}`;
    if (page > 0) {
      url += `&page=${page}`;
    }
    return this.http.get<ChatMessage[]>(url);
  }

  addMessage(message: ChatMessageFromServer) {
    this.messageReceived.next(message);
  }
}
