import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, Injector, Input, ElementRef } from '@angular/core';
// import { Observable, Subject } from 'rxjs';
// import { GridDataResult, DataStateChangeEvent, DataBindingDirective, SelectableSettings } from '@progress/kendo-angular-grid';
import { Patient } from '@models/patient';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmMergePatientComponent } from '@app/management/dialogs/confirm-patient-merge/confirm-patient-merge.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PatientService } from '@services/patient.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientStatus } from '@models/patient-status.enum';
import { TabType } from '@models/tab-type.enum';
import { TwilioConversationsService } from '@services/twilio-conversations.service';
import { Conversation } from '@twilio/conversations';

@Component({
  selector: 'app-merge-patients',
  templateUrl: './merge-patients.component.html',
  styleUrls: ['./merge-patients.component.less'],
})
export class MergePatientsComponent implements OnInit {
  @Input() panelHeight: number;
  loading = false;
  primaryPatient: Patient;
  duplicatePatient: Patient;
  patientConversations = new Map<number, Conversation>();
  patientId: number;
  duplicatePatientId: number;
  patientName: string;
  unsub: Subject<void> = new Subject<void>();
  duplicatePatientName: string;
  todaysDate = new Date();
  endDate = new Date('3000-01-01');
  PatientStatus = PatientStatus;
  isMerged = false;
  patientDialogRef: any;
  insideDialog = false;
  TabType = TabType;

  @ViewChild('container') container: ElementRef;
  @ViewChild('header') header: ElementRef;

  constructor(
    private dialog: MatDialog,
    private injector: Injector,
    public patientService: PatientService,
    private route: ActivatedRoute,
    private twilioService: TwilioConversationsService
  ) {
    if (this.injector) {
      let data = this.injector.get(MAT_DIALOG_DATA);
      this.patientId = data.patientId;
      this.duplicatePatientId = data.duplicatePatientId;
      this.patientDialogRef = this.injector.get(MatDialogRef, null);
      if (this.patientDialogRef) {
        this.insideDialog = true;
      }
    }
  }

  setPatientConversations(patient: Patient) {
    if (patient)
      this.twilioService.findExistingConversation(patient.patientId).then((conversation) => {
        this.patientConversations.set(patient.patientId, conversation);
      });
  }

  typeaheadOnChange($event) {
    this.isMerged = false;
    if ($event.type == 'primary') {
      this.patientId = $event.data ? $event.data.patientId : 0;
      this.primaryPatient = $event.data as Patient;
      this.setPatientConversations(this.primaryPatient);
    } else {
      this.duplicatePatientId = $event.data ? $event.data.patientId : 0;
      this.duplicatePatient = $event.data as Patient;
      this.setPatientConversations(this.duplicatePatient);
    }
  }

  ngOnInit() {
    /*
    Date: 06-01-2021
    Description: to get patients id and fetch primary and duplicate patient data
  */
    if (!this.injector) {
      this.patientId = this.route.snapshot.params['id1'];
      this.duplicatePatientId = this.route.snapshot.params['id2'];
    }

    if (this.patientId) {
      this.loading = true;
      this.patientService.getPatientById(this.patientId).subscribe((response) => {
        this.primaryPatient = response;
        if (this.primaryPatient) this.patientName = this.primaryPatient.firstName + ' ' + this.primaryPatient.lastName;
        this.setPatientConversations(this.primaryPatient);
        this.loading = false;
      });
    }
    if (this.duplicatePatientId) {
      this.loading = true;
      this.patientService.getPatientById(this.duplicatePatientId).subscribe((response) => {
        this.duplicatePatient = response;
        if (this.duplicatePatient)
          this.duplicatePatientName = this.duplicatePatient.firstName + ' ' + this.duplicatePatient.lastName;
        this.setPatientConversations(this.duplicatePatient);
        this.loading = false;
      });
    }

    // var pId = this.route.snapshot.params.get('patientId');
    // debugger;
    // if (pId) {
    //   this.patientId = Number(pId);
    // }
  }

  ngAfterViewChecked() {
    this.panelHeight = this.container.nativeElement.offsetHeight - this.header.nativeElement.offsetHeight;
  }

  public onGetAvatarURI(avatar: string): string {
    return this.patientService.getAvatarURI(avatar);
  }

  swapValue() {
    var pId = this.patientId;
    var patient = Object.assign({}, this.primaryPatient);

    if (this.primaryPatient)
      this.duplicatePatientName = this.primaryPatient.firstName + ' ' + this.primaryPatient.lastName;

    if (this.duplicatePatient)
      this.patientName = this.duplicatePatient.firstName + ' ' + this.duplicatePatient.lastName;

    this.patientId = this.duplicatePatientId;
    this.duplicatePatientId = pId;

    this.primaryPatient = this.duplicatePatient;
    this.duplicatePatient = patient;
  }

  closeModal() {
    this.patientDialogRef.close(this.isMerged);
  }

  mergePatient() {
    const dialogRef = this.dialog.open(ConfirmMergePatientComponent, {
      width: '450px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') {
          this.loading = true;
          this.patientService.mergePatientData(this.patientId, this.duplicatePatientId).subscribe((result: any) => {
            var patientId = this.patientId;
            var duplicatePatientId = this.duplicatePatientId;
            this.resetComponent();
            this.refreshComponent(patientId, duplicatePatientId);
            this.isMerged = true;
          });
        }
      });
  }

  resetComponent() {
    this.duplicatePatientId = 0;
    this.duplicatePatient = null;
    this.patientId = 0;
    this.primaryPatient = null;
  }

  refreshComponent(patientId, duplicatePatientId) {
    setTimeout(() => {
      this.patientId = patientId;
      this.duplicatePatientId = duplicatePatientId;

      this.patientService
        .getPatientById(patientId)
        .pipe(takeUntil(this.unsub))
        .subscribe((patient) => {
          this.primaryPatient = patient;
          this.setPatientConversations(this.primaryPatient);
          this.loading = false;
        });

      this.patientService
        .getPatientById(duplicatePatientId)
        .pipe(takeUntil(this.unsub))
        .subscribe((duplicatePatient) => {
          this.duplicatePatient = duplicatePatient;
          this.setPatientConversations(this.duplicatePatient);
          this.loading = false;
        });
    }, 1000);
  }

  getPatientConversation(patientId) {
    return this.twilioService.findExistingConversation(patientId)
  }
}
