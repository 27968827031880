import { ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';

export function phoneNumberValidator(countryCode?: CountryCode): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value === '') return null;
        const valid = isValidPhoneNumber(control.value, countryCode)
        return valid ? null : {phoneNumberInvalid: {value: control.value}}
    }
}