import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ChatChannel } from '@app/messaging/models/chat-channel.model';
import { ChatChannelsService } from '@app/messaging/services/chat-channels.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-channel',
  templateUrl: './edit-channel.component.html',
  styleUrls: ['./edit-channel.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class EditChannelComponent implements OnInit, OnDestroy {
  @Input() set channel(inputChannel: ChatChannel) {
    this._channel = inputChannel;
    this.channelName.setValue(inputChannel.name);
  }
  get channel(): ChatChannel {
    return this._channel;
  }
  private _channel: ChatChannel;
  private unsub: Subject<any> = new Subject<any>();
  channelName = new FormControl('', [Validators.required, Validators.maxLength(60)]);
  loading = false;

  constructor(public activeModal: NgbActiveModal, private channelService: ChatChannelsService) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  saveChanges(name: string) {
    if (this.channel) {
      this.loading = true;
      this.channel.name = this.channelName.value;
      this.channelService.updateChannel(this.channel).subscribe(() => {
        this.loading = false;
        this.activeModal.dismiss('channel updated');
      });
    } else {
      this.channelService
        .createChatChannel({ name })
        .pipe(takeUntil(this.unsub))
        .subscribe(() => {
          this.activeModal.dismiss('channel created');
        });
    }
  }
}
