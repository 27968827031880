import { HoursOfOperationDay } from '@models/hoursofoperation';
import { WeekDay } from '@models/week-day';
import * as moment from 'moment';
import 'moment-duration-format';

export function isNullOrUndefined(value: any) : boolean {
  return value === null || value === undefined;
}

export function isValidId(id: number): boolean {
  return !isNullOrUndefined(id) && id > 0;
}

export function toTitleCase(val: string): string {
  return val.replace(
    /\w\S*/g,
    function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
);
}

export function formatHoursOfOperation(days: HoursOfOperationDay[]): string {
  // M 8-5, T 8-5, W 8-5, R 8-5, F 8-5, S 8-5, U 8-5.
  let hours = '';

  if (days) {
    for (let i = 0; i < days.length; i++) {
      days[i].openTime = moment.duration(days[i].openTime);
      days[i].closeTime = moment.duration(days[i].closeTime);

      const f = formatDay(days[i]);

      if (f) {
        hours = hours ? (hours += `, \n ${f}`) : f;
      }
    }
  }

  return hours;
}

export function formatDay(day: HoursOfOperationDay): string {
  if (day) {
    const abbr = getAbbr(day.dayofweek);
    const openTime = formatTime(day.openTime);
    const closeTime = formatTime(day.closeTime);

    return day.closed ? '' : `${abbr} ${openTime} - ${closeTime}`;
  }

  return '';
}

export function formatTime(t: moment.Duration): string {
  // t = t ? new Date(Date.parse(t.toString())) : null;
  // return t ? t.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : '';
  return t.format();
}

export function getAbbr(dayofweek: string) {
  switch (dayofweek) {
    case WeekDay.Sunday:
      return 'U';

    case WeekDay.Monday:
      return 'M';

    case WeekDay.Tuesday:
      return 'T';

    case WeekDay.Wednesday:
      return 'W';

    case WeekDay.Thursday:
      return 'R';

    case WeekDay.Friday:
      return 'F';

    case WeekDay.Saturday:
      return 'S';

    default:
      return '?';
  }
}

export function getArrayFrom<T>(item: T | T[]): T[] {
  if (!Array.isArray(item)) {
    item = [item];
  }
  return item;
}

export function dataURLtoFile(dataurl: string, filename: string): File {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}