import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthGuardDirective } from './auth-guard.directive';
import { AuthComponent } from './auth-component/auth-component.component';
import { AuthService } from './auth.service';
import { AuthGuard } from './app.auth.guard';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { LoadingModule } from 'ngx-loading';

@NgModule({
  declarations: [AuthComponent, AuthGuardDirective],
  imports: [MatCardModule, MatInputModule, MatFormFieldModule, MatButtonModule, ReactiveFormsModule, LoadingModule],
  exports: [AuthGuardDirective],
  providers: [
    AuthGuard,
    {
      provide: 'any',
      useClass: AuthService,
      deps: [HttpClient, Router, ActivatedRoute],
      multi: true,
    },
  ],
})
export class AuthModule {}
