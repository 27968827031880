import { NgModule } from '@angular/core';

import { ServiceDetailComponent } from './service-detail.component';
import { SharedModule } from '../../../../shared/shared.module';
import { AdvancedTreatmentModule } from './advanced-treatment/advanced-treatment.module';
import { TreatmentPlanModule } from './treatment-plan/treatment-plan.module';

@NgModule({
  declarations: [
    ServiceDetailComponent
  ],
  imports: [
    SharedModule,
    AdvancedTreatmentModule,
    TreatmentPlanModule,
  ],
  exports: [
    ServiceDetailComponent
  ]
})
export class ServiceDetailModule { }
