<div class="row mx-0 mt-1">
  <!--Amounts-->
  <div class="col px-0 mx-2">
    <div class="section-header">Online Gift Card Amounts</div>
    <div class="border">
      <kendo-grid
        [createAmountFormGroup]="createAmountFormGroup"
        [kendoGridBinding]="amounts"
        [hideHeader]="true"
        scrollable="none">
        <ng-template kendoGridToolbarTemplate>
          <button kendoGridAddCommand class="btn kendo-btn em-btn-green">Add new</button>
        </ng-template>
        <kendo-grid-column 
          field="amount" 
          title="Amounts"
          format="{0:c}"
          editor="numeric">
        </kendo-grid-column>
        <kendo-grid-command-column title="Actions" [width]="100">
          <ng-template kendoGridCellTemplate let-isNew="isNew">
            <button kendoGridEditCommand class="btn kendo-btn"><i class="fas fa-edit"></i></button>
            <button kendoGridRemoveCommand class="btn kendo-btn"><i class="fas fa-minus-circle"></i></button>
            <button kendoGridSaveCommand class="btn kendo-btn" [disabled]="formGroup?.invalid">
              {{ isNew ? "Add" : "Update" }}
            </button>
            <button kendoGridCancelCommand class="btn kendo-btn">
              {{ isNew ? "Discard changes" : "Cancel" }}
            </button>
          </ng-template>
        </kendo-grid-command-column>
      </kendo-grid>
      <div class="teal-font p-2">
        <label class="switch mb-0">
          <!--<input type="checkbox" [(ngModel)]="" (change)=""/>-->
          <input type="checkbox"/>
          <span class="slider round"></span>
        </label>
        <span class="pl-2">Allow Custom Amount</span>
      </div>
    </div>
  </div>
  <!--Promotion-->
  <div class="col px-0 mx-2">
    <div class="section-header">Promotion</div>
    <div class="border">
      Promotion
    </div>
  </div>
  <!--Promotin Codes-->
  <div class="col px-0 mx-2">
    <div class="section-header">Promotion Codes</div>
    <div class="border">
      Promotion Codes
    </div>
  </div>
  <!--Policy-->
  <div class="col px-0 mx-2">
    <div class="section-header">Gift Card Policy</div>
    <div class="border">
      Policy Section
    </div>
  </div>
</div>
