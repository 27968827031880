import { Component, OnInit, Input } from '@angular/core';
import { ImageService } from '@services/image.service';
import { User } from '@models/user';
import { BlobService } from '@services/blob.service';
import { UsersService } from '@services/users.service';

@Component({
  selector: 'app-avatar-uploader',
  templateUrl: './avatar-uploader.component.html',
  styleUrls: ['./avatar-uploader.component.less']
})
export class AvatarUploaderComponent implements OnInit {
  _selectedUser: User;
  @Input()
  set selectedUser(selectedUser) {
    this._selectedUser = selectedUser;
    this.changeAvatar();
  }

  get selectedUser() {
    return this._selectedUser;
  }
  selectedFile: File = null;
  userAvatar: string;
  readOnlySas: string;
  constructor(
    private imageService: ImageService,
    private blobService: BlobService,
    private usersService: UsersService
  ) { }

  ngOnInit() {
  }

  changeAvatar() {
    this.readOnlySas = this.blobService.getReadOnlySAS();
    this.userAvatar = this.selectedUser.avatar ? this.selectedUser.avatar + this.readOnlySas : '';
  }

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];

    if (this.selectedFile) {
      this.imageService.uploadUserAvatar(this.selectedFile).subscribe(avatarUri => {
        this.selectedUser.avatar = avatarUri;
        this.userAvatar = avatarUri + this.readOnlySas;
        this.updateUser();
      });
    }
  }

  updateUser() {
    this.usersService.updateUser(this.selectedUser, null).subscribe(() => {
        if (this.selectedUser.id === this.usersService.loggedInUser.id) {
          // Be careful of ordering, this will update the editedUser (overriding avatar with a roSAS appended)
          this.usersService.loggedInUserUpdated(this.selectedUser);
        }
      });
  }

  removeAvatar() {
    this.selectedUser.avatar = '';
    this.userAvatar = '';
    this.updateUser();
  }
}
