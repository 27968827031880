<ngx-loading [show]="loading"></ngx-loading>
<div class="modal-header border-0 px-2 py-1">
  <button type="button" class="btn white-font p-0 ml-auto" aria-label="Close" (click)="closeModal()">
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="modal-body p-0">
  <form [formGroup]="createTreatmentPlanPackageForm" class="modal-form" (ngSubmit)="onPackageFormSubmit()">
    <div class="grey-two-background p-4">
      <h5 class="modal-title text-uppercase navy-font text-center">{{ isNew ? 'Add' : 'Edit' }} Multiples</h5>
      <div class="row pt-3">
        <ng-container *ngFor="let error of errors">
          <app-error *ngFor="let fieldError of error.fieldErrors" [message]="fieldError"></app-error>
        </ng-container>
        <div class="col">
          <mat-form-field floatLabel="always" class="mat-form-field--full">
            <mat-label class="label">Select Service</mat-label>
            <mat-select
              placeholder="Service"
              name="Service"
              formControlName="serviceTemplate"
              (selectionChange)="onServiceTemplateChange($event)"
              class="pt-2"
              [disabled]="!isNew"
            >
              <mat-option *ngFor="let serviceTemplate of serviceTemplates" [value]="serviceTemplate.id">
                {{ serviceTemplate.serviceName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="p-4">
      <h6 class="modal-title navy-font">Details</h6>
      <div class="row pt-3">
        <div class="col-4">
          <mat-form-field [floatLabel]="'always'" class="">
            <mat-label class="label">Price ($)</mat-label>
            <input
              matInput
              formControlName="price"
              numericType="decimal"
              type="number"
              (change)="updatePriceCalculations()"
              cdkFocusInitial
              class="pt-1"
            />
            <mat-error *ngIf="createTreatmentPlanPackageForm.controls.price.hasError('required')"
              >Price is required.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field [floatLabel]="'always'" class="ml-2">
            <mat-label class="label">Quantity</mat-label>
            <input
              #quantity
              matInput
              formControlName="quantity"
              type="number"
              numericType="decimal"
              (change)="updatePriceCalculations()"
              (click)="checkScheduled()"
              class="pt-1"
            />
            <mat-error *ngIf="createTreatmentPlanPackageForm.controls.quantity.hasError('required')"
              >Quantity is required.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field [floatLabel]="'always'" class="ml-2">
            <mat-label class="label">Total Amount ($)</mat-label>
            <input matInput formControlName="totalAmount" readonly class="pt-1" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="px-4">
      <mat-form-field [floatLabel]="'always'" class="mat-form-field--full">
        <mat-label class="label">Notes</mat-label>
        <input formControlName="notes" class="w-100" matInput class="pt-1" />
      </mat-form-field>
    </div>
    <div class="p-4">
      <div class="text-center">
        <button
          type="submit"
          [disabled]="!createTreatmentPlanPackageForm.valid"
          class="btn em-btn em-btn-green ml-auto"
        >
          {{ isNew ? 'Add' : 'Update' }}
        </button>
        <button type="button" class="btn em-btn em-btn-green ml-2" (click)="closeModal()">Cancel</button>
      </div>
    </div>
  </form>
</div>
