<div class="position-relative h-100 document-container">
  <button type="button" class="btn em-btn em-btn-green close-btn" aria-label="Close" (click)="closeModal()">
    Close
  </button>
  <ng-container *ngIf="document?.isDeleted">
    <div class="pastel-red-font text-center ">
      <h2>DOCUMENT DELETED</h2>
    </div>
  </ng-container>
  <pdf-viewer 
    *ngIf="pdfSrc && !pdfToHtmlSrc"
    [src]="pdfSrc"
    [original-size]="false"
    [fit-to-page]="true">
  </pdf-viewer>

  <formio 
    #formView
    [form]="form"
    (submit)="onSubmit($event)"
    [submission]="submission"
    (change)="onFormChange($event)"
    [viewOnly]="true"
    [readOnly]="true">
  </formio>
</div>