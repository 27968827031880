import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-form-delete',
  templateUrl: './confirm-form-delete.component.html',
  styleUrls: ['./confirm-form-delete.component.less'],
})
export class ConfirmFormDeleteComponent {
  public dialog: MatDialog;

  constructor(public dialogRef: MatDialogRef<ConfirmFormDeleteComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
}
