<ngx-loading [show]="loading"></ngx-loading>
<div class="h-100 px-3 d-flex flex-column">
  <div class="d-flex align-items-center w-100" style="height: 48px">
    <div class="column-header py-0 mr-2">{{ isFuture ? 'Future' : 'Previous' }} Appointments Filter</div>
  </div>
  <ng-container *ngIf="previousTreatmentAppt?.length > 0">
    <ng-scrollbar class="flex-grow-1 minh-0">
      <mat-accordion [multi]="true">
        <mat-expansion-panel
          *ngFor="let previousTreatment of previousTreatmentAppt; let last = last; trackBy: trackByFunction"
          [ngClass]="{ 'mb-2': !last }"
          [expanded]="false"
          [disabled]="true"
          #panelList
        >
          <mat-expansion-panel-header class="px-0 py-0" [collapsedHeight]="'36px'" [expandedHeight]="'36px'">
            <mat-panel-title>
              <div class="d-flex align-items-center panel-box panel-box--small pl-0 pr-2">
                <div
                  class="color-icon mr-2 d-flex align-items-center justify-content-center white-font"
                  [ngStyle]="{ background: previousTreatment?.serviceIdColour }"
                  (click)="applyFilter(previousTreatment)"
                >
                  <ng-container *ngIf="previousTreatment?.serviceIcon; else noIcon">
                    <i [class]="previousTreatment?.serviceIcon" title="Filter by Treatment"></i>
                  </ng-container>
                  <ng-template #noIcon>
                    <i class="fal fa-filter" title="Filter by Treatment"></i>
                  </ng-template>
                </div>
                <div class="panel-box-column d-flex flex-column">
                  <div class="line-height-1 mb-1">
                    {{ previousTreatment?.serviceName }}
                    <span *ngIf="previousTreatment?.associatedPlannedTreatment?.isPlannedTreatmentMultiple"
                      >({{ previousTreatment?.associatedPlannedTreatment?.rank }} of
                      {{ previousTreatment?.associatedPlannedTreatment?.plannedTreatmentMultipleQuantity }})</span
                    >
                  </div>
                  <div class="line-height-1">
                    {{
                      moment(previousTreatment?.date).add(moment.duration(previousTreatment?.startTime)).toDate()
                        | date : 'yyyy-MM-dd h:mma'
                    }}
                  </div>
                </div>
                <div class="panel-box-column panel-box-column--main d-flex"></div>
                <div
                  [attr.data-test-id]="'appointment-' + previousTreatment.appointmentId + '-price'"
                  [attr.data-test-value]="previousTreatment?.price | currency"
                  class="panel-box-column"
                  [ngClass]="{
                    danger: previousTreatment?.paymentStatus == AppointmentPaymentStatus.Unpaid,
                    'spring-green-font': previousTreatment?.paymentStatus == AppointmentPaymentStatus.Paid,
                    'purple-font': previousTreatment?.paymentStatus == AppointmentPaymentStatus.Refund
                  }"
                >
                  {{ previousTreatment?.price | currency }}
                </div>
              </div>
            </mat-panel-title>
            <button
              *ngIf="previousTreatment?.details?.length > 0"
              type="button"
              class="btn btn--smaller d-flex px-1"
              (click)="panelList.expanded = !panelList.expanded"
            >
              <mat-icon>{{ panelList.expanded ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
            </button>
          </mat-expansion-panel-header>
          <div class="panel-box-content pr-2 pt-2 pb-0 border-0">
            <div class="content-row" *ngFor="let detail of previousTreatment?.details">
              <div class="pr-1">{{ detail?.area }}</div>
              <div class="pr-1">{{ detail?.detail }}</div>
              <div class="pr-1">{{ detail?.unitDetail }}</div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-scrollbar>
  </ng-container>

  <ng-container *ngIf="previousTreatmentAppt?.length === 0 && !loading">
    <div class="pb-3">This patient doesn't have any {{ isFuture ? 'future' : 'previous' }} appointments</div>
  </ng-container>
</div>
