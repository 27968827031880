import { CommonModule } from '@angular/common';
import { NgModule, Injector } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { MatChipsModule } from '@angular/material/chips';

import { SharedModule } from '../../shared/shared.module';
import { ClinicDocumentsEditComponent } from './clinic-documents-edit/clinic-documents-edit.component';
import { ClinicDocumentsListComponent } from './clinic-documents-list/clinic-documents-list.component';
import { ClinicDocumentsComponent } from './clinic-documents.component';

import { RouterModule } from '@angular/router';
import { ClinicEformEditComponent } from './clinic-eform-edit/clinic-eform-edit.component';
import { EformBuilderComponent } from './eform-builder/eform-builder.component';
import { FormioModule, FormioAppConfig } from 'angular-formio-emilyemr';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { EformViewerComponent } from './eform-viewer/eform-viewer.component';
import { FormIoApiService } from '@services/formio-api.service';
import { EformConfig } from '@app/eform.config';
import { CustomTextfieldWrapperComponent } from './eform-builder/custom-components/custom-text-field';
import { registerCustomTextFieldComponent } from './eform-builder/custom-components/custom-text-field.formio';
import { EformPrintComponent } from './eform-print/eform-print.component';
import { TagInputModule } from 'ngx-chips';

@NgModule({
    declarations: [
        ClinicDocumentsComponent,
        ClinicDocumentsEditComponent,
        ClinicEformEditComponent,
        ClinicDocumentsListComponent,
        EformBuilderComponent,
        EformViewerComponent,
        CustomTextfieldWrapperComponent,
        EformPrintComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        MatChipsModule,
        MatAutocompleteModule,
        GridModule,
        TagInputModule,
        ExcelModule,
        FormioModule,
        PdfViewerModule
    ],
    exports: [
        ClinicDocumentsComponent,
        ClinicDocumentsEditComponent,
        ClinicEformEditComponent,
        ClinicDocumentsListComponent,
        EformViewerComponent
    ],
    providers: [
        { provide: FormioAppConfig, useValue: EformConfig },
        FormIoApiService
    ]
})
export class ClinicDocumentsModule {
  constructor(injector: Injector) {
    registerCustomTextFieldComponent(injector);
  }
}
