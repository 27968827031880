<ng-container *ngFor="let error of errors">
  <app-error *ngFor="let fieldError of error.fieldErrors" [message]="fieldError"></app-error>
</ng-container>

<div *ngIf="ministryBillingForm" class="p-4 billing-modal-wrapper d-flex flex-column align-items-center">
  <h6 class="mt-0 mb-4 navy-font">MINISTRY BILLING</h6>
  <form [formGroup]="ministryBillingForm" class="w-100">
    <div class="field-wrapper mb-2">
      <div class="teal-font d-flex flex-column form-field">Invoice Date:</div>
      <kendo-datepicker
        [disabledDates]="disabledDates"
        [placeholder]="selectedServiceChartEntry?.date.toDateString()"
        formControlName="invoiceDate"
        >>
      </kendo-datepicker>
    </div>

    <div class="field-wrapper">
      <div class="teal-font d-flex flex-column form-field">
        Dx Code
        <div class="flex-grow-1 d-flex align-items-center search-service-code">
          <mat-form-field [floatLabel]="'never'" class="form-field padding-0-field">
            <input
              matInput
              formControlName="dxCode"
              autocomplete="off"
              (input)="dxCodeUpdated($event)"
              [readonly]="selectedServiceChartEntry?.paymentStatus === PaymentStatus.Paid || loader"
              #dxCodeInput
            />
            <input
              type="hidden"
              [value]="dxCodeInput.value"
              [ngbTypeahead]="searchDxCode"
              [resultFormatter]="codeResultFormatter"
              [inputFormatter]="codeDxInputFormatter"
              #searchDxCodeInput
            />
          </mat-form-field>
          <i
            *ngIf="selectedServiceChartEntry?.paymentStatus !== PaymentStatus.Paid"
            class="fas fa-search"
            [ngClass]="{ 'search-disabled ': !dxCodeInput.value }"
            (click)="searchDxCodeFunc()"
          >
          </i>
        </div>
        <ngx-loading [show]="loader"></ngx-loading>
      </div>
    </div>

    <div class="field-wrapper">
      <div
        *ngIf="
          ministryBillingForm.get('serviceBillingCodes')['controls'].length === 0 && selectedServiceChartEntry?.paymentStatus !== PaymentStatus.Paid
        "
        class="d-flex align-items-center pb-2"
      >
        Billing Code
        <button
          *ngIf="selectedServiceChartEntry?.paymentStatus !== PaymentStatus.Paid"
          type="button"
          class="btn-clean px-0 ml-1"
          (click)="addServiceCode()"
        >
          <i class="far fa-plus-circle"></i>
        </button>
      </div>
      <div
        formArrayName="serviceBillingCodes"
        *ngFor="let serviceCode of ministryBillingForm.get('serviceBillingCodes')['controls']; let i = index"
      >
        <div
          [formGroupName]="i"
          [ngClass]="{
            'pr-4':
              i !== ministryBillingForm.get('serviceBillingCodes')['controls'].length - 1 &&
              selectedServiceChartEntry?.paymentStatus !== PaymentStatus.Paid
          }"
          class="w-100 d-flex align-items-center justify-content-between"
        >
          <button
            *ngIf="selectedServiceChartEntry?.paymentStatus !== PaymentStatus.Paid"
            type="button"
            class="btn-clean mr-2 mt-1 px-0"
            (click)="removeServiceCode(i)"
          >
            <i class="far fa-minus-circle"></i>
          </button>
          <div class="teal-font d-flex flex-column form-field form-field--8">
            Billed Service Code
            <div class="flex-grow-1 d-flex align-items-center search-service-code">
              <mat-form-field [floatLabel]="'never'" class="form-field padding-0-field w-auto flex-grow-1">
                <input
                  matInput
                  formControlName="billingCode"
                  (input)="billingCodeUpdated($event, i)"
                  [readonly]="selectedServiceChartEntry?.paymentStatus === PaymentStatus.Paid || loader"
                  autocomplete="off"
                  #billingCodeInput
                />
                <input
                  type="hidden"
                  [value]="billingCodeInput.value"
                  [ngbTypeahead]="searchBillingCode"
                  [resultFormatter]="codeResultFormatter"
                  [inputFormatter]="codeBillingInputFormatter"
                  #searchBillingCodeInput
                />
              </mat-form-field>
              <i
                *ngIf="selectedServiceChartEntry?.paymentStatus !== PaymentStatus.Paid"
                class="fas fa-search"
                [ngClass]="{ 'search-disabled ': !billingCodeInput.value }"
                (click)="searchBillingCodeFunc(i)"
              >
              </i>
            </div>
            <ngx-loading [show]="loader"></ngx-loading>
          </div>

          <div class="ml-3 teal-font d-flex flex-column form-field form-field--4">
            Billed Service Units
            <div class="flex-grow-1">
              <mat-form-field [floatLabel]="'never'" class="form-field padding-0-field">
                <input
                  matInput
                  formControlName="serviceUnits"
                  onlyNumber
                  autocomplete="off"
                  numericType="decimal"
                  [readonly]="selectedServiceChartEntry?.paymentStatus === PaymentStatus.Paid"
                />
              </mat-form-field>
            </div>
          </div>

          <button
            *ngIf="
              i === ministryBillingForm.get('serviceBillingCodes')['controls'].length - 1 &&
              selectedServiceChartEntry?.paymentStatus !== PaymentStatus.Paid
            "
            type="button"
            class="btn-clean ml-2 px-0 mt-1"
            (click)="addServiceCode()"
          >
            <i class="far fa-plus-circle"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="field-wrapper w-100 d-flex align-items-center justify-content-between">
      <div class="teal-font d-flex flex-column form-field form-field">
        <mat-form-field class="mr-1" class="family-physician">
          <mat-select
            [(value)]="selectedDoctor"
            name="doctors"
            (selectionChange)="
              !$event ? null : $event.value === 0 ? quickAddDoctor() : setReferralCode($event.value.provincialIdNumber)
            "
          >
            <mat-option [value]="0"> Quick Add </mat-option>
            <mat-option [value]="null"> None </mat-option>
            <mat-option *ngFor="let doctor of theDoctors" [value]="doctor">
              {{ doctor.proTitle }} {{ doctor.lastName }}, {{ doctor.firstName }}
            </mat-option>
          </mat-select>

          <mat-placeholder class="placeholder">Referral Doctor</mat-placeholder>
        </mat-form-field>
      </div>
      <div class="teal-font d-flex flex-column form-field form-field--4 align-self-end ml-2">
        <div>
          <mat-form-field [floatLabel]="'never'" class="form-field padding-0-field">
            <input
              #referralCodeInput
              matInput
              placeholder="Ref #"
              (input)="selectedDoctor = null"
              autocomplete="off"
              formControlName="referralCode"
              [readonly]="selectedServiceChartEntry?.paymentStatus === PaymentStatus.Paid"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="ml-3 teal-font d-flex form-field form-field--4 align-items-center justify-content-end w-auto">
        <!-- By<mat-slide-toggle  kendoTooltip tooltipClass="em-tooltip" position="left" [title]="!referralCodeInput.value || referralCodeInput.value == 'Select a referring doctor to enable this toggle...' ? 'Is this patient referred to or referred by the selected doctor?' : ''" [disabled]="!referralCodeInput.value || referralCodeInput.value == ''" class="mx-1" formControlName="isReferredTo"></mat-slide-toggle>To -->
        <mat-radio-group
          formControlName="isReferredTo"
          [disabled]="!referralCodeInput.value || referralCodeInput.value == ''"
          kendoTooltip
          tooltipClass="em-tooltip"
          position="left"
          [title]="
            !referralCodeInput.value || referralCodeInput.value == ''
              ? 'Select a referring doctor to enable this toggle...'
              : 'Is this patient referred to or referred by the selected doctor?'
          "
        >
          <mat-radio-button [value]="false">
            <span>Referred by doctor</span>
          </mat-radio-button>
          <mat-radio-button [value]="true">
            <span>Referred to doctor</span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="field-wrapper">
      <div class="teal-font d-flex flex-column form-field">
        Claim Note
        <div class="flex-grow-1">
          <mat-form-field [floatLabel]="'never'" class="form-field padding-0-field">
            <input
              autocomplete="off"
              matInput
              formControlName="claimNote"
              [readonly]="selectedServiceChartEntry?.paymentStatus === PaymentStatus.Paid"
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center flex-column">
      <button
        *ngIf="selectedServiceChartEntry && selectedServiceChartEntry.paymentStatus !== PaymentStatus.Paid"
        type="button"
        class="schedNewCommButton center em-btn em-btn-green py-2 px-3 mb-2"
        (click)="onMinistryBillingSave()"
        [disabled]="!ministryBillingForm.valid || checkFormDisabled()"
      >
        SAVE
      </button>
      <button
        *ngIf="selectedServiceChartEntry &&selectedServiceChartEntry.paymentStatus !== PaymentStatus.Paid"
        type="button"
        class="schedNewCommButton center em-btn em-btn-green py-2 px-3 mb-2"
        [disabled]="!ministryBillingForm.valid || checkFormDisabled()"
        (click)="onMinistryBillingSave(true)"
      >
        SAVE + SEND TO MINISTRY
      </button>
      <button
        type="button"
        class="schedNewCommButton cancel-btn em-btn center py-2 px-3 mb-0"
        (click)="closePanel.next()"
      >
        CANCEL
      </button>
    </div>
  </form>
</div>
