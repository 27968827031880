import { InvoiceLineItemTax } from './invoice-line-item-tax';
import { User } from '../user';

export class InvoiceLineItem {
  id: number;
  invoiceId: number;
  note: string;
  discountPrice?: number;
  isPercentDiscount?: boolean; 
  unitPrice: number;
  quantity: number;
  total: number;
  serviceTemplateId: number;
  serviceType: string;
  serviceTypeDescription: string;
  clinicProductId: number;
  isManual: boolean;
  isRecommendedProduct: boolean;
  isDeleted: boolean;
  finalTotal: number;
  isCredit: boolean;
  serviceId?: number;
  addBackToInventory: boolean;
  relatedInvoiceLineItemId?: number;
  invoiceLineItemTaxes: InvoiceLineItemTax[];
  attributedToUserId: string;
  attributedToUser: User;
  plannedTreatmentMultipleId: number;
  isPaid?: boolean = false;
  membershipId?: number;
  patientMembershipId?: number;

  constructor(init?: Partial<InvoiceLineItem>) {
    Object.assign(this, init);
  }
}
