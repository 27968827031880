import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService) {}

  handleResponseError(error, request?: HttpRequest<any>, next?) {
    if (
      [401].includes(error.status) &&
      request.url != window.location.origin + '/api/auth/logout' &&
      !(request.url == window.location.origin + '/api/auth' && request.method == 'POST')
    ) {
      if (request.url == window.location.origin + '/api/auth/refreshtoken') {
        //refresh token is expired - logout
        this._authService.logout();
        return throwError('Refresh token expired.');
      } else {
        //try to refresh the bearer token
        return this._authService.refreshTokenRequest('emilyEMRActiveUserRefresh').pipe(
          map((apiKey) => {
            console.log('Token Refreshed after unauthorized api call...', apiKey && apiKey != null);
            return apiKey;
          }),
          switchMap((apiKey) => {
            request = request.clone({
              headers: request.headers.set('Authorization', `Bearer ${apiKey}`),
            });
            return next.handle(request);
          }),
          catchError((e) => {
            if (e.status !== 401) {
              return this.handleResponseError(e);
            } else {
              this._authService.logout();
              return throwError('Refresh token expired.');
            }
          })
        );
      }
    }
    const err = (error && error.error && error.error.message) || error.statusText;
    console.error(err);
    return throwError(error);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        return this.handleResponseError(err, req, next);
      })
    );
  }
}
