import { ClinicReviewSettings } from '@models/reviews/review-settings';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable, Subject, throwError, of } from 'rxjs';
import { ReviewResponse } from '@models/reviews/review-response';
import { GooglePlaceSearchResult } from '@models/reviews/google-place-search-result';
import { CommunicationHistory } from '@models/communications/communication-history.model';

export interface ReviewViewModel {
  reviewId?: number;
  appointmentId: number;
  patientId: number;
  ratingText: string;
  ratingValue: 1 | 2 | 3 | 4 | 5;
  staffId: string;
  createdAt: Date;
  appointmentDate: Date;
  lastUpdatedAt: Date;
}
@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  private reviewNotificationsCountUpdated = new Subject<void>();
  public reviewNotificationsCountUpdated$ = this.reviewNotificationsCountUpdated.asObservable();
  constructor(private http: HttpClient) {}

  getClinicReviewSettings(clinicId: number): Observable<ClinicReviewSettings> {
    return this.http.get<ClinicReviewSettings>(environment.baseUrl + `api/Reviews/Settings/${clinicId}`, {});
  }

  updateReviewNotificationsCount() {
    this.reviewNotificationsCountUpdated.next();
  }

  updateClinicSettings(settings: ClinicReviewSettings): Observable<ClinicReviewSettings> {
    const params = Object.assign({}, settings);

    return this.http.put<ClinicReviewSettings>(environment.baseUrl + 'api/Reviews/Settings', params);
  }

  getInternalAverageRating(clinicId: number): Observable<number> {
    return this.http.get<number>(environment.baseUrl + `api/Reviews/InternalAverageRating/${clinicId}`, {});
  }

  getGoogleAverageRating(clinicId: number): Observable<number> {
    return this.http.get<number>(environment.baseUrl + `api/Reviews/GoogleAverageRating/${clinicId}`, {});
  }

  getReviews({ state, addState }: { state; addState }): Observable<ReviewResponse> {
    const params = Object.assign({}, state, addState);

    return this.http.get<ReviewResponse>(environment.baseUrl + 'api/Reviews', { params: params });
  }

  getGoogleReviews({ state, addState }: { state; addState }, nextPageToken: string): Observable<ReviewResponse> {
    const params = Object.assign({}, state, addState, { nextPageToken: nextPageToken });
    return this.http.get<ReviewResponse>(environment.baseUrl + 'api/Reviews/GetGoogleReviewsList/', { params }).pipe(
      catchError((err) => {
        if (err && err.error.indexOf('Google place id not setup in clinic management') != -1) {
          return throwError('googlePlaceIdMissing');
        }

        return of(new ReviewResponse());
      }),
      map((data) => data)
    );
  }

  lookupGooglePlaceId(searchQuery: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http
      .put<string>(environment.baseUrl + 'api/Reviews/SearchGooglePlaceId', JSON.stringify(searchQuery), {
        headers: headers,
      })
      .pipe(
        catchError((err) => {
          if (err && err.error.indexOf('Error looking up Google Place ID') != -1) {
            return throwError('lookupGooglePlaceId');
          }

          return of(null);
        })
      )
      .pipe(
        map((placeData: { place_id: string }) => {
          return placeData.place_id;
        })
      );
  }

  searchGooglePlaceCompanies(searchQuery: string): Observable<GooglePlaceSearchResult[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http
      .put<any>(environment.baseUrl + 'api/Reviews/SearchGooglePlaceCompanies', JSON.stringify(searchQuery), {
        headers: headers,
      })
      .pipe(
        catchError((err) => {
          if (err && err.error.indexOf('Error searching Google Place Companies') != -1) {
            return throwError('searchGooglePlaceCompanies');
          }
          return of([]);
        })
      )
      .pipe(
        map((placeData: any) => {
          return placeData.results;
        })
      );
  }

  manuallyRequestPatientReview(patientId: number) {
    return this.http.post<CommunicationHistory[]>(environment.baseUrl + `api/Reviews/PatientRequest/${patientId}`, null);
  }

  getReviewNotificationsCount(): Observable<number> {
    return this.http.get<number>(environment.baseUrl + 'api/Reviews/my-reviews-count');
  }
}
