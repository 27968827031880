export class PatientTreatmentAnswer {
  public id: number;
  public patientId: number;
  public treatmentAnswerId: number;
  public treatmentPlanId: number;
  public notes: string;

  public constructor(init?: Partial<PatientTreatmentAnswer>) {
    Object.assign(this, init);
  }
}
