import { Directive, Input, ElementRef, AfterViewInit, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';


@Directive({
  selector: '[defaultBirthdayCheck]'
})
export class DefaultBirthdayCheckDirective  {
  _birthDate: Date;
  @Input() set birthDate(date: any){
    if (date){
      if (typeof(date) == 'string'){
        date = moment(date).toDate();
      }
      else if (typeof(date) === 'object' && date['toDate'] !== undefined){
        date = (<Moment>date).toDate();
      }
      this._birthDate = date;
      this.checkDate(date);
    }
  }

  @Input() set defaultBirthdayCheckClass(defaultBirthdayCheckClass: string ){ 
    this.checkDate(this._birthDate, defaultBirthdayCheckClass);
  }

  public constructor(private el: ElementRef) {
  }

  checkDate(date: Date, defaultBirthdayCheckClass: string = null){
    let element: HTMLElement = this.el.nativeElement;
    if (defaultBirthdayCheckClass){
      element = <HTMLElement>element.getElementsByClassName(defaultBirthdayCheckClass)[0];  
    }
    let isDefaultBirthday = date.getTime() === new Date('January 1, 1980').getTime();
    if (isDefaultBirthday){
      element.style.fontWeight = 'bold';
      element.style.color = 'red';
    }
    else{
      element.style.fontWeight = 'inherit';
      element.style.color = 'inherit';
    }
  }
}
