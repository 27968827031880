import { Address } from './address';
import { MedicalCollege } from './medical-college';
import { StaffVisibleOnSchedule } from './staffVisibleOnSchedule';
import { UserUserCategory } from './user-user-category';

export class User {
  id: string;
  userName: string;
  firstName: string;
  lastName: string;
  avatar: string;
  phoneNumber: string;
  email: string;
  address: Address;
  clinicId: number;
  companyId: number;
  userUserCategories: UserUserCategory[];
  serviceProvider: boolean;
  showOnlyWorkingProvidersOnSchedule: boolean = false;
  provincialBillingNumber: number;
  payeeNumber: number;
  medicalLicenseNumber: string;
  isActive: number;
  medicalCollege?: MedicalCollege;
  medicalCollegeId?: number;
  staffVisibleOnSchedule: StaffVisibleOnSchedule[];
  isPortalUser: boolean;
  hourlyWage?: number;

  constructor(init?: Partial<User>) {
    // Set the defaults
    this.id = '';
    this.firstName = '';
    this.lastName = '';
    this.avatar = '';
    this.phoneNumber = '';
    this.email = '';
    this.serviceProvider = false;
    this.isActive = 1;

    // Override the defaults
    Object.assign(this, init);
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}

export interface Role {
  id: string;
  name: string;
}

export interface Claim {
  type: string;
  value: string;
}

export interface UserClinicCompany {
  companyId: number;
  clinicId: number;
  timezone: string;
}

export interface UserClaims {
  userId: string;
  fullName: string;
  claimValues: string[];
}

export type UserClinicCompanyType = UserClinicCompany;
