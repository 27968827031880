<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>
<div class="row">
  <div class="col-9">
    <div class="section-header">Clinic Supplies</div>
    <app-clinic-supplies-list (disableGridEvent)="changeDisableGrid($event)"></app-clinic-supplies-list>
  </div>
  <div class="col-3">
    <div class="section-header">Injectables Group Pricing</div>
    <app-observation-groups-list (disableGridEvent)="changeDisableGrid($event)"></app-observation-groups-list>
  </div>
</div>

<app-actionpanel></app-actionpanel>
