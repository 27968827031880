<ngx-loading [show]="loading" [config]="{ fullScreenBackdrop: true }"></ngx-loading>
<div class="edit-container white-background d-flex flex-column h-100 z-index-3 position-fixed w-100">
  <div class="re-links-container">
    <button class="btn em-btn teal-background" (click)="cancelUpdate()">
      <i class="far fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <div class="campaigns-toolbar row px-2 navy-background mx-auto w-100">
    <div class="col-12 d-flex align-items-center justify-content-between">
      <h5 class="white-font text-uppercase m-0 mr-2">Email Template Builder</h5>
      <div class="d-flex flex-nowrap mr-3">
        <!-- <button class="btn em-btn em-btn-green mr-4 campaign-button" (click)="saveChanges()">Save</button>
        <button class="btn em-btn em-btn-green mr-4 campaign-button send-test" (click)="sendTest('email')">
          Send Test
        </button>
        <button class="btn em-btn em-btn-green mr-4 campaign-button" (click)="cancelUpdate()">Cancel</button>
        <button class="btn em-btn em-btn-green campaign-button" (click)="deleteCampaign()">Delete</button> -->
      </div>
    </div>
  </div>
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0">
    <!-- <form class="teal-font line-height-1" [formGroup]="form">

      <div class="row mx-0 teal-background">
        <div class="col">
          <h6 class="white-font uppercase my-0 py-2">
            {{actionType}} Email Template
          </h6>
        </div>
      </div>

      <div class="py-3">
        <div class="row mx-0">
          <div class="col">
            <mat-form-field class="w-100">
              <input
                formControlName="name"
                matInput
                placeholder="Template Title">
            </mat-form-field>
          </div>
        </div>


      </div>

      <div class="row mx-0 teal-background">
        <div class="col">
          <h6 class="white-font uppercase mb-0 py-2">
            Template
          </h6>
        </div>
      </div>

      <div class="w-100 h-100 d-flex align-items-center justify-content-center">
        <perfect-scrollbar appDetermineMaxScrollHeight>
          <iframe class="w-100 h-100" *ngIf="id" src="https://app.mailjet.com/template/1274586/build"></iframe>
      
        </perfect-scrollbar>
      </div>
 
    </form> -->
  </perfect-scrollbar>
</div>
