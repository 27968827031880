<div class="edit-container position-absolute d-block z-index-3 white-background">
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0;">
    <form [formGroup]="textTemplateForm" (ngSubmit)="onSubmit()">
      <div class="row mx-0 teal-background">
        <div class="col px-2">
          <h6 class="white-font uppercase p-2 mb-0">
            {{actionType}} Text Template
          </h6>
        </div>
      </div>
      <div class="row mx-0 mt-2">
        <div class="col px-2">
          <mat-form-field class="w-100">
            <input 
              matInput
              formControlName="name" 
              placeholder="Template Name" 
              aria-label="Template Name" 
              required/>
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0 mt-2">
        <div class="col px-2">
          <mat-form-field class="w-100">
            <textarea 
              matInput 
              rows="5"
              formControlName="text" 
              placeholder="Template Text"
              aria-label="Template Text"
              required>
            </textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-2 mx-0">
        <div class="col px-2 text-center">
          <button 
            type="submit" 
            class="btn em-btn em-btn-green" 
            [disabled]="!textTemplateForm.valid">
              {{actionType}}
            </button>
        </div>
        <div class="col px-2 text-center">
          <button 
            class="btn em-btn em-btn-green" 
            (click)="cancelUpdate()">
              Cancel
          </button>
        </div>
      </div>
    </form>
  </perfect-scrollbar>
</div>
  