import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PhotoData } from '@models/photo/photo-data';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import Cropper from 'cropperjs';
import { GenericPhotoService } from '../services/generic-photo.service';

enum DragMode {
  Crop = 'crop',
  Move = 'move',
  None = 'none',
}

@Component({
  selector: 'app-generic-photo-series-creator',
  templateUrl: './generic-photo-series-creator.component.html',
  styleUrls: ['./generic-photo-series-creator.component.less'],
})
export class GenericPhotoSeriesCreatorComponent implements OnInit, AfterViewInit {
  @ViewChild('editor1', { static: false }) editorElement1: ElementRef;
  @ViewChild('editor2', { static: false }) editorElement2: ElementRef;

  private _seriesPhotos: PhotoMetaData[];
  @Input()
  set seriesPhotos(photos: PhotoMetaData[]) {
    this._seriesPhotos = photos;
    if (this._seriesPhotos[0]) {
      this.cropper1.replace(this._seriesPhotos[0].filePath);
      this.loadingImage1 = true;
    }
    if (this._seriesPhotos[1]) {
      this.cropper2.replace(this._seriesPhotos[1].filePath);
      this.loadingImage2 = true;
    }
  }
  get seriesPhotos(): PhotoMetaData[] {
    return this._seriesPhotos;
  }

  @Output() toggleSeriesCreationMode: EventEmitter<boolean> = new EventEmitter();
  @Output() seriesCreated: EventEmitter<PhotoMetaData> = new EventEmitter();

  loading: boolean = false;
  imageDest1: any;
  imageDest2: any;
  cropper1: Cropper;
  cropper2: Cropper;
  loadingImage1: boolean = false;
  loadingImage2: boolean = false;

  // https://github.com/fengyuanchen/cropperjs/blob/main/README.md#options
  cropOptions: Cropper.Options = {
    viewMode: 0,
    dragMode: DragMode.Move,
    toggleDragModeOnDblclick: false,
    //aspectRatio: 8/9,
    guides: false,
    autoCrop: true,
    cropBoxResizable: false,
    autoCropArea: 1,
    // Set high to always fill editor area with crop box
    minCropBoxHeight: 100000,
    minCropBoxWidth: 100000,
  };

  isUpDown: boolean = false;

  constructor(private photoService: GenericPhotoService) {}

  isIpad: boolean = false;

  ngOnInit(): void {
    this.isIpad = navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
  }

  cropper1Options: Cropper.Options;
  cropper2Options: Cropper.Options;

  ngAfterViewInit() {
    this.cropper1Options = {
      cropend: (event) => {
        this.onCrop(event, this.cropper1, 'imageDest1');
      },
      ready: () => {
        this.onReady(this.cropper1);
        this.loadingImage1 = false;
      },
    };
    this.cropper2Options = {
      cropend: (event) => {
        this.onCrop(event, this.cropper2, 'imageDest2');
      },
      ready: () => {
        this.onReady(this.cropper2);
        this.loadingImage2 = false;
      },
    };
    this.cropper1 = new Cropper(
      this.editorElement1.nativeElement,
      Object.assign(this.cropOptions, this.cropper1Options)
    );
    this.cropper2 = new Cropper(
      this.editorElement2.nativeElement,
      Object.assign(this.cropOptions, this.cropper2Options)
    );
  }

  saveBeforeAfter() {
    this.loading = true;
    const croppedImage1 = this.getCroppedImage(this.cropper1).substring('data:image/png;base64,'.length);
    console.log('Image 1 size: ' + croppedImage1.length);
    const croppedImage2 = this.getCroppedImage(this.cropper2).substring('data:image/png;base64,'.length);
    console.log('Image 2 size: ' + croppedImage2.length);
    const photoData1 = new PhotoData(croppedImage1, this.seriesPhotos[0]);
    const photoData2 = new PhotoData(croppedImage2, this.seriesPhotos[1]);
    this.photoService
      .createBeforeAfter(photoData1, photoData2, this.seriesPhotos[0].patientId, this.isUpDown)
      .subscribe({
        next: (baPhoto) => {
          // this.filter.setSelectedPhoto(seriesPhoto, this.photos);
          this.seriesCreated.emit(baPhoto);
          this.loading = false;
        },
        error: (err) => {
          console.error(err);
          this.loading = false;
        },
      });
  }

  saveSeries() {
    this.loading = true;
    let photoIds: number[] = this.seriesPhotos.map((p) => p.id);
    const patientId = this.seriesPhotos[0].patientId;
    this.photoService.createSeriesImage(photoIds, patientId).subscribe((seriesPhoto) => {
      // this.filter.setSelectedPhoto(seriesPhoto, this.photos);
      this.seriesCreated.emit(seriesPhoto);
      this.loading = false;
    });
  }

  onCrop(event: CustomEvent, cropper: Cropper, dest: any) {
    // console.log('getData');
    // console.log(cropper.getData());
    // console.log('getImageData');
    // console.log(cropper.getImageData());
    // this[dest] = this.getCroppedImage(cropper);
  }

  getCroppedImage(cropper: Cropper) {
    const imageData = cropper.getImageData();
    const options = {
      height: 2160,
      maxWidth: imageData.naturalHeight,
      maxHeight: imageData.naturalWidth,
      imageSmoothingQuality: 'high',
    };
    const canvas = cropper.getCroppedCanvas(options as Cropper.GetCroppedCanvasOptions);
    return canvas.toDataURL('image/png');
  }

  onReady(cropper: Cropper) {
    //cropper.zoomTo(1);
  }

  cancelSeriesCreation() {
    this.toggleSeriesCreationMode.emit(false);
  }

  saveSeriesCreation() {
    if (this.seriesPhotos.length > 2) {
      this.saveSeries();
    } else {
      this.saveBeforeAfter();
    }
  }

  selectOrientation() {
    this.cropper1.destroy();
    this.cropper2.destroy();
    this.cropper1 = new Cropper(
      this.editorElement1.nativeElement,
      Object.assign(this.cropOptions, this.cropper1Options)
    );
    this.cropper2 = new Cropper(
      this.editorElement2.nativeElement,
      Object.assign(this.cropOptions, this.cropper2Options)
    );

    if (this._seriesPhotos[0]) {
      this.cropper1.replace(this._seriesPhotos[0].filePath);
      this.loadingImage1 = true;
    }
    if (this._seriesPhotos[1]) {
      this.cropper2.replace(this._seriesPhotos[1].filePath);
      this.loadingImage2 = true;
    }
  }
}
