import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceProvider } from '@models/service-provider';
import { ServiceProviderService } from '@services/service-provider.service';
@Component({
  selector: 'app-edit-online-booking-provider',
  templateUrl: './edit-online-booking-provider.component.html',
  styleUrls: ['./edit-online-booking-provider.component.less'],
})
export class EditOnlineBookingProviderComponent implements OnInit {
  loading = false;
  providerIdParam: string;
  form: FormGroup;

  public editedProvider: ServiceProvider;

  constructor(
    private serviceProviderService: ServiceProviderService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.loading = true;
    this.editedProvider = this.initProvider(this.editedProvider);
    this.route.params.subscribe((params) => {
      this.providerIdParam = params['provId'];
      this.initForm();
      if (this.providerIdParam !== '_' && this.providerIdParam != null) {
        this.serviceProviderService.getServiceProviderById(this.providerIdParam).subscribe((provider) => {
          this.editedProvider = provider;
          this.setForm();
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    });
  }

  updateProvider() {
    this.editedProvider.onlineBookingProviderName = this.form.controls['onlineBookingProviderName'].value;
    this.serviceProviderService.updateServiceProviderBookingSettings(this.editedProvider).subscribe(() => {
      this.serviceProviderService.updateRefreshRequired(true);
      this.router.navigate(['/management/organization/clinics/online-booking', { outlets: { 'action-panel': null } }]);
    });
  }

  cancelUpdate() {
    this.serviceProviderService.updateRefreshRequired(false);
    this.router.navigate(['/management/organization/clinics/online-booking', { outlets: { 'action-panel': null } }]);
  }

  initProvider(provider: ServiceProvider) {
    provider = {
      id: '0',
      title: 'Test',
      onlineBookingProviderName: 'Online',
      userCategory: null,
      authorizedServiceIds: [],
      visible: false,
      working: false,
      clinicId: 2,
      allowGovernmentBilling: false,
      availableForOnlineBooking: false,
    };
    return provider;
  }

  initForm() {
    this.form = new FormGroup({
      title: new FormControl({ value: this.editedProvider.title, disabled: true }),
      onlineBookingProviderName: new FormControl(this.editedProvider.onlineBookingProviderName),
    });
  }

  setForm() {
    this.form.controls['title'].setValue(this.editedProvider.title);
    this.form.controls['onlineBookingProviderName'].setValue(this.editedProvider.onlineBookingProviderName);
  }
}
