import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { Router } from '@angular/router';
import { BillingSettings } from '@models/billing/ministry-billing/billing-settings';
import { BillingSettingsService } from '@services/billing-settings.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-billing-settings-list',
  templateUrl: './billing-settings-list.component.html',
  styleUrls: ['./billing-settings-list.component.less'],
})
export class BillingSettingsListComponent implements OnInit, OnDestroy {
  unsub: Subject<void> = new Subject<void>();
  disableGrid = false;
  loading = false;
  billingSettings: BillingSettings[];
  public gridView: GridDataResult;

  gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };

  editedRowIndex: number;
  editedDataItem: BillingSettings;

  constructor(private billingSettingsService: BillingSettingsService, private router: Router) {}

  ngOnInit() {
    this.loading = true;
    this.disableGrid = false;
    this.loadData();
    this.initEditModeSubscription();
    this.initUpdateSubscription();
  }

  loadData() {
    this.billingSettingsService.getBillingSettings().subscribe((bset: BillingSettings[]) => {
      this.billingSettings = bset;

      this.gridView = {
        data: bset.slice(),
        total: bset.length,
      };

      this.loading = false;
      this.disableGrid = false;
    });
  }

  public editHandler({ dataItem }) {
    this.disableGrid = true;
    this.router.navigate([
      '/management/organization/clinics/payments',
      { outlets: { 'action-panel': ['edit-billing-settings', dataItem.id] } },
    ]);
  }

  initEditModeSubscription() {
    this.billingSettingsService.editModeToggled.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.disableGrid = false;
      this.editedRowIndex = undefined;
    });
  }

  initUpdateSubscription() {
    this.billingSettingsService.refreshRequired$.pipe(takeUntil(this.unsub)).subscribe((updated) => {
      if (updated) this.loadData();
    });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
