import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';
import { PaymentMethod } from '@models/finance/payment-method';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { ClinicsService } from '@services/clinics.service';
import { PaymentMethodsService } from '@services/payment-methods.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-org-payment-methods',
  templateUrl: './org-payment-methods.component.html',
  styleUrls: ['./org-payment-methods.component.less'],
})
export class OrgPaymentMethodsComponent implements OnInit, OnDestroy {
  unsub: Subject<void> = new Subject<void>();
  disableGrid = false;
  loading = false;
  paymentMethods: PaymentMethod[];
  gridView: GridDataResult;
  gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  editedRowIndex: number;
  editedDataItem: PaymentMethod;
  clinicId: number;
  developerPolicy = Policies.developer;

  constructor(
    private paymentMethodsService: PaymentMethodsService,
    private clinicsService: ClinicsService,
    private deleteDialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.clinicsService.clinicIdSelected$
      .asObservable()
      .pipe(takeUntil(this.unsub))
      .subscribe((clinicId) => {
        this.clinicId = clinicId;
        this.initDefaultValue();
        this.initData();
      });
    this.initDefaultValue();
    this.initData();
    this.refreshData();
  }

  initDefaultValue() {
    this.gridView = {
      data: [],
      total: 0,
    };
    this.loading = true;
    this.disableGrid = false;
  }

  initData() {
    this.paymentMethodsService
      .getPaymentMethods(this.clinicId)
      .pipe(takeUntil(this.unsub))
      .subscribe((paymentMethods: PaymentMethod[]) => {
        this.paymentMethods = paymentMethods;

        this.gridView = {
          data: paymentMethods,
          total: paymentMethods.length,
        };

        this.loading = false;
        this.disableGrid = false;
      });
  }

  refreshData() {
    this.paymentMethodsService.paymentMethodsUpdatedSource$.pipe(takeUntil(this.unsub)).subscribe((shouldRefresh) => {
      this.disableGrid = false;
      if (shouldRefresh) {
        this.initData();
      }
    });
  }

  public editHandler({ dataItem }) {
    this.disableGrid = true;
    this.router.navigate([
      this.router.routerState.snapshot.url,
      { outlets: { 'action-panel': ['edit-payment-method', dataItem.id] } },
    ]);
  }

  onAddClick() {
    this.disableGrid = true;
    this.router.navigate([
      this.router.routerState.snapshot.url,
      { outlets: { 'action-panel': ['edit-payment-method', '_'] } },
    ]);
  }

  public removeHandler({ dataItem }) {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'delete') {
          this.paymentMethodsService
            .archivePaymentMethod(dataItem.id)
            .pipe(takeUntil(this.unsub))
            .subscribe(() => {
              this.paymentMethodsService.paymentMethodsUpdatedSource.next(true);
            });
        }
      });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
