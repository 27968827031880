import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

export interface CanComponentDeactivate {
  confirm(): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ConfirmDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(component: CanComponentDeactivate ) {
    if (!component.confirm()) {
      return confirm('You have unsaved changes! If you leave, your changes will be lost.');
    } else {
      return true;
    }
  }
}
