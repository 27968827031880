<h1 mat-dialog-title class="text-center">Select Provider</h1>
<div mat-dialog-content class="text-center" >


  <button mat-button  *ngFor="let serviceProvider of serviceProviders" class="select-provider-modal-selection"
  [title]="serviceProvider['authorizedServiceIds']?.indexOf(serviceTemplateId) == -1 ? 'Provider not authorized to perform this service' :  (isMedicalPlanBilling && !serviceProvider.allowGovernmentBilling)  ? 'Provider cannot do government-billed service' :  serviceProvider.working ? 'Click to select' :   'Provider not working currently' "

  [disabled]="!serviceProvider.working || serviceProvider['authorizedServiceIds']?.indexOf(serviceTemplateId) == -1 || (isMedicalPlanBilling && !serviceProvider.allowGovernmentBilling)" (click)="providerSelected(serviceProvider)"  cdkFocusInitial>{{serviceProvider.title}}</button>

</div>
<div mat-dialog-actions class="justify-content-center">
  <button mat-button [mat-dialog-close]="'cancel'" >Cancel</button>
</div>
