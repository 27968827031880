import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { AddPhotoComponent } from '@app/patients/patient-tabs/patient-photos-tab/add-photo/add-photo.component';
import { PhotoFilter, PhotoGalleryMode } from '@app/photos/photo-filter';
import { Patient } from '@models/patient';
import { PhotoDrawing } from '@models/photo/photo-drawing';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageService } from '@services/image.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-service-chart-draw-tool',
  templateUrl: './service-chart-draw-tool.component.html',
  styleUrls: ['./service-chart-draw-tool.component.less'],
})
export class ServiceChartDrawToolComponent implements OnInit, OnDestroy {
  private _patient: Patient;
  @Input() set patient(patient: Patient) {
    this._patient = patient;
    if (this.selectedBtn !== 'Clinic') this.photoList = patient?.photos;
  }
  get patient() {
    return this._patient;
  }

  @Input() photoDrawing?: PhotoDrawing = new PhotoDrawing();
  selectedBtn: string;
  unsub: Subject<void> = new Subject<void>();
  addMode: boolean = false;
  filter: PhotoFilter = new PhotoFilter(false);
  photoList: PhotoMetaData[] = [];
  clinicPhotos: PhotoMetaData[] = [];

  private headPhotoTemplate: PhotoMetaData;
  private bodyPhotoTemplate: PhotoMetaData;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private imageService: ImageService,
    private dialog: MatDialog
  ) {
    this.filter.gallery_mode = PhotoGalleryMode.Patient;
  }

  ngOnInit() {
    if (!this.photoDrawing.photo) {
      this.addMode = true;
      this.initPhotos();
      this.selectOption('Head');
    } else {
      this.addMode = false;
    }
  }

  private initPhotos() {
    let headFilePath =
      this.patient?.gender.toLowerCase() === 'female'
        ? '@app/../assets/service-detail/FemaleInjectables.jpg'
        : '@app/../assets/service-detail/MaleInjectables.jpg';

    let bodyFilePath =
      this.patient?.gender.toLowerCase() === 'female'
        ? '@app/../assets/service-detail/FemaleCoolsculpting.jpg'
        : '@app/../assets/service-detail/MaleCoolsculpting.jpg';

    this.headPhotoTemplate = new PhotoMetaData({
      filePath: headFilePath,
      filePathThumb: headFilePath,
      filePathOriginal: headFilePath,
      imageName: 'Head',
      imageType: 'image/jpeg',
      patientId: this.patient?.patientId,
      patientContainerFolderName: this.patient?.patientId.toString(),
    });

    this.bodyPhotoTemplate = new PhotoMetaData({
      filePath: bodyFilePath,
      filePathThumb: bodyFilePath,
      filePathOriginal: bodyFilePath,
      imageName: 'Body',
      imageType: 'image/jpeg',
      patientId: this.patient?.patientId,
      patientContainerFolderName: this.patient?.patientId.toString(),
    });

    this.photoList = this.patient?.photos;

    this.imageService
      .getClinicStockPhotos()
      .pipe(takeUntil(this.unsub))
      .subscribe((photos) => {
        this.clinicPhotos = photos;
      });
  }

  selectOption(selectedBtn: string) {
    this.selectedBtn = selectedBtn;
    switch (selectedBtn) {
      case 'Head':
        this.photoDrawing.photo = this.headPhotoTemplate;
        this.photoDrawing = JSON.parse(JSON.stringify(this.photoDrawing)); // trigger change detection
        this.filter.selectedPhoto = null;
        this.photoList = this.patient?.photos;
        break;
      case 'Body':
        this.photoDrawing.photo = this.bodyPhotoTemplate;
        this.photoDrawing = JSON.parse(JSON.stringify(this.photoDrawing)); // trigger change detection
        this.filter.selectedPhoto = null;
        this.photoList = this.patient?.photos;
        break;
      case 'Clinic':
        this.photoList = this.clinicPhotos;
        this.selectListPhoto(this.photoList[0]);
        break;
    }
  }

  selectListPhoto(photo: PhotoMetaData) {
    if (this.selectedBtn !== 'Clinic') this.selectedBtn = null;
    this.filter.setSelectedPhoto(photo, this.photoList);
    this.photoDrawing.photo = photo;
    this.photoDrawing.photoId = photo.id;
    if (this.addMode) {
      photo.filePathOriginal = photo.filePath;
    }
    this.photoDrawing = JSON.parse(JSON.stringify(this.photoDrawing)); // trigger change detection
  }

  savePhoto = (data: File, photoDrawing: PhotoDrawing) => {
    this.activeModal.close([data, photoDrawing]);
  };

  addPhoto() {
    const modalRef = this.modalService.open(AddPhotoComponent, { windowClass: 'add-photo-modal', centered: true });
    (modalRef.componentInstance as AddPhotoComponent).singleImageSelect = true;
    (modalRef.componentInstance as AddPhotoComponent).showGallery = false;
    modalRef.result
      .then((photoMetaData) => {
        this.selectedBtn = '';
        this.patient.photos = [...this.patient.photos, photoMetaData];
        this.photoDrawing.photo = photoMetaData;
        this.photoDrawing.photoId = photoMetaData.id;
        this.photoDrawing = new PhotoDrawing(this.photoDrawing);
        this.filter.selectedPhoto = photoMetaData;
      })
      .catch(() => {
        // user exited the modal without selecting a photo
      });
  }

  deleteDrawing() {
    const modalRef = this.dialog.open(GenericDialogComponent, {
      width: '325px',
      data: {
        title: 'Delete Chart Drawing?',
        content: 'Are you sure you want to delete this Chart Drawing?',
        confirmButtonText: 'Delete',
        showCancel: true,
      },
    });

    modalRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') {
          this.activeModal.close('delete');
        }
      });
  }

  close() {
    this.activeModal.close();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
