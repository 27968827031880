import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { ObservationListType } from '@models/observation/observation';

@Injectable({
  providedIn: 'root'
})
export class ObservationListTypesService {

  constructor(private http: HttpClient) { }

  getObservationListTypes() {
    return this.http.get<ObservationListType[]>(environment.baseUrl + 'api/ObservationListTypes/ClinicSupplies');
  }

}
