import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Clinic } from '@models/clinic';
import { ClinicsService } from '@services/clinics.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-org-online-general-settings',
  templateUrl: './org-online-general-settings.component.html',
  styleUrls: ['./org-online-general-settings.component.less'],
})
export class OrgOnlineGeneralSettingsComponent implements OnInit {
  loading = false;
  form: FormGroup;
  clinic: Clinic;
  clinicId: number;
  unsub: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, private clinicsService: ClinicsService) {}

  ngOnInit(): void {
    this.loading = true;
    this.clinicId = +localStorage.getItem('clinicId');
    this.initClinic(this.clinicId);
  }

  initClinic(clinicId: number) {
    this.clinicsService.getClinicById(clinicId).subscribe((clinic) => {
      this.clinic = clinic;
      this.initForm();
      this.loading = false;
    });
  }

  initForm() {
    this.form = this.fb.group({
      onlineBookingBufferHours: [
        this.clinic && this.clinic.onlineBookingBufferHours ? this.clinic.onlineBookingBufferHours : 0,
        Validators.required,
      ],
      onlineBookingAllowSameDay: [
        this.clinic && this.clinic.onlineBookingAllowSameDay ? this.clinic.onlineBookingAllowSameDay : false,
      ],
      onlineBookingCancellationPeriodHours: [
        this.clinic && this.clinic.onlineBookingCancellationPeriodHours
          ? this.clinic.onlineBookingCancellationPeriodHours
          : 0,
        Validators.required,
      ],
      onlineBookingCanCancelAppointment: [
        this.clinic && this.clinic.onlineBookingCanCancelAppointment
          ? this.clinic.onlineBookingCanCancelAppointment
          : false,
      ],
      onlineBookingAppointmentLimit: [
        this.clinic && this.clinic.onlineBookingAppointmentLimit ? this.clinic.onlineBookingAppointmentLimit : 0,
        Validators.required,
      ],
      onlineBookingCancellationPrice: [
        this.clinic && this.clinic.onlineBookingCancellationPrice ? this.clinic.onlineBookingCancellationPrice : 0,
        Validators.required,
      ],
      checkPrerequisiteValidity: [
        this.clinic && this.clinic.checkPrerequisiteValidity ? this.clinic.checkPrerequisiteValidity : false,
      ],
      prerequisiteValidityPeriod: [
        this.clinic && this.clinic.prerequisiteValidityPeriod ? this.clinic.prerequisiteValidityPeriod : 0,
        Validators.required,
      ],
      onlineBookingReservationDurationMinutes: [
        this.clinic?.onlineBookingReservationDurationMinutes ?? 5, Validators.required,
      ]
    });
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }

  updateSettings() {
    this.loading = true;
    this.clinicsService.getClinicById(this.clinicId).subscribe((clinic) => {
      clinic.onlineBookingAppointmentLimit = this.form.get('onlineBookingAppointmentLimit').value;
      clinic.onlineBookingBufferHours = this.form.get('onlineBookingBufferHours').value;
      clinic.onlineBookingAllowSameDay = this.form.get('onlineBookingAllowSameDay').value;
      clinic.onlineBookingCancellationPeriodHours = this.form.get('onlineBookingCancellationPeriodHours').value;
      clinic.onlineBookingCanCancelAppointment = this.form.get('onlineBookingCanCancelAppointment').value;
      clinic.onlineBookingCancellationPrice = this.form.get('onlineBookingCancellationPrice').value;
      clinic.checkPrerequisiteValidity = this.form.get('checkPrerequisiteValidity').value;
      clinic.prerequisiteValidityPeriod = this.form.get('prerequisiteValidityPeriod').value;
      clinic.onlineBookingReservationDurationMinutes = this.form.get('onlineBookingReservationDurationMinutes').value;
      this.clinicsService.updateClinicBookingSettings(clinic).subscribe(() => {
        this.form.markAsPristine();
        this.loading = false;
      });
    });
  }
}
