import { isNullOrUndefined } from '@app/shared/helpers';
import { BlobService } from '@services/blob.service';
import { map } from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';
import { environment } from './../../../environments/environment.prod';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PhotoMetaData } from '@models/photo/photo-meta-data';

import { Injectable } from '@angular/core';
import { PhotoFilter, PhotoGalleryMode } from '../photo-filter';
import { PhotoData } from '@models/photo/photo-data';
import { generateRandomString } from '@app/lib/fun';
import * as moment from 'moment';
import { DeviceEvent } from '@models/photo/device-event';

@Injectable({
  providedIn: 'root',
})
export class GenericPhotoService {
  constructor(private http: HttpClient, private blobService: BlobService) {}

  photoSaved$: Subject<boolean> = new Subject<boolean>();
  photoEdited$: Subject<boolean> = new Subject<boolean>();
  photoDeleted$: Subject<PhotoMetaData> = new Subject<PhotoMetaData>();

  getPhotos(photoFilter: PhotoFilter) {
    return combineLatest(
      this.blobService.getReadOnlySASObservable(),
      this.http.put<{ results: PhotoMetaData[]; totalResults: number }>(`${environment.baseUrl}api/Photo/List`, {
        patientId: photoFilter.gallery_mode === PhotoGalleryMode.Consultation ? null : photoFilter.patientId,
        photoTypesSelected: photoFilter.photo_types_selected,
        sharingTypesSelected: photoFilter.sharing_types_selected,
        photoTagsSelected: photoFilter.body_tag_filters
          .concat(photoFilter.service_tag_filters)
          .concat(photoFilter.supply_tag_filters as any),
        serviceTemplatesSelected: photoFilter.service_template_filters.map((t) => +t.tagId),
        genderFilter: photoFilter.gender_filter,
        onlyShowFavourites: photoFilter.only_show_favourites,
        includeStockPhotos: photoFilter.include_stock_photos,
        take: photoFilter.take,
        skip: photoFilter.skip,
      })
    ).pipe(
      map(([readOnlySAS, imagedata]) => {
        imagedata.results = imagedata.results.map((image) => this.applySaS(readOnlySAS, image));
        return imagedata;
      })
    );
  }

  updatePhotoTags(photoId: number, tagIds: string[]) {
    return combineLatest(
      this.blobService.getReadOnlySASObservable(),
      this.http.put<PhotoMetaData>(environment.baseUrl + 'api/Tag/PhotoTag/' + photoId, tagIds)
    ).pipe(
      map(([readOnlySAS, imagedata]) => {
        return this.applySaS(readOnlySAS, imagedata);
      })
    );
  }

  createSeriesImage(photoIds: number[], patientId: number) {
    return combineLatest(
      this.blobService.getReadOnlySASObservable(),
      this.http.post<PhotoMetaData>(environment.baseUrl + 'api/Photo/Series/' + patientId, photoIds)
    ).pipe(
      map(([readOnlySAS, imagedata]) => {
        return this.applySaS(readOnlySAS, imagedata);
      })
    );
  }

  createBeforeAfter(photo1: PhotoData, photo2: PhotoData, patientId: number, isUpDown: boolean) {
    var params = new HttpParams().set('isUpDown', String(isUpDown));
    return combineLatest([
      this.blobService.getReadOnlySASObservable(),
      this.http.post<PhotoMetaData>(environment.baseUrl + 'api/Photo/BeforeAfter/' + patientId, [photo1, photo2], {
        params: params,
      }),
    ]).pipe(
      map(([readOnlySAS, imagedata]) => {
        return this.applySaS(readOnlySAS, imagedata);
      })
    );
  }

  restoreToOriginal(photo: PhotoMetaData): Observable<PhotoMetaData> {
    return combineLatest([
      this.blobService.getReadOnlySASObservable(),
      this.http.get<PhotoMetaData>(environment.baseUrl + 'api/Photo/Restore/' + photo.id),
    ]).pipe(
      map(([readOnlySAS, imagedata]) => {
        return this.applySaS(readOnlySAS, imagedata);
      })
    );
  }

  rotatePhoto(photo: PhotoMetaData, rotation: number) {
    return combineLatest([
      this.blobService.getReadOnlySASObservable(),
      this.http.put<PhotoMetaData>(environment.baseUrl + 'api/Photo/Rotate/' + photo.id, rotation),
    ]).pipe(map(([readOnlySAS, imagedata]) => this.applySaS(readOnlySAS, imagedata)));
  }

  startCapture(patientId: number) {
    return this.http.post<DeviceEvent>(environment.baseUrl + 'api/Photo/StartCapture?patientId=' + patientId, {});
  }

  stopCapture(patientId: number) {
    return this.http.post<DeviceEvent>(environment.baseUrl + 'api/Photo/StopCapture?patientId=' + patientId, {});
  }

  private applySaS(readOnlySAS, image: PhotoMetaData) {
    const paramString = readOnlySAS.split('?')[1];
    const params = new URLSearchParams(paramString);
    const sasStartDate = moment(params.get('st'));
    const modifiedDate = moment(image.modifiedDate as any);
    image.filePath = (image.filePath ? image.filePath.trim() : '') + readOnlySAS;
    image.filePathThumb = (image.filePathThumb ? image.filePathThumb.trim() : '') + readOnlySAS;
    if (modifiedDate.isAfter(sasStartDate)) {
      image.filePath += `&forceRefresh=${generateRandomString()}`;
      image.filePathThumb += `&forceRefresh=${generateRandomString()}`;
    }
    if (!isNullOrUndefined(image.seriesPhotos)) {
      image.seriesPhotos.forEach((sp: PhotoMetaData) => {
        sp.filePath = (sp.filePath ? sp.filePath.trim() : '') + readOnlySAS;
        sp.filePathThumb = (sp.filePathThumb ? sp.filePathThumb.trim() : '') + readOnlySAS;
      });
    }
    return image;
  }
}
