<ngx-loading [show]="loading"></ngx-loading>

<div [ngStyle]="{'height': 'calc(100dvh - 75px)'}">
  <kendo-grid [data]="gridView" [pageSize]="gridState.take" [skip]="gridState.skip" [sort]="gridState.sort"
      [sortable]="true" [selectable]="true" [style.height.%]="100">
      <ng-template kendoGridToolbarTemplate>
            <div class="flex-fill grey-two-background px-4 py-3 m-1">
              <div>
                <h5>Activations: &nbsp;&nbsp; {{2830 | currency}}</h5>
                <h6 class="small">Last 30 Days</h6>
              </div>
            </div>
            <div class="flex-fill grey-two-background px-4 py-3 m-1">
              <div>
                <h5>Redemptions: &nbsp;&nbsp; {{200 | currency}}</h5>
                <h6 class="small">Last 30 Days</h6>
              </div>
            </div>
            <div class="flex-fill grey-two-background px-4 py-3 m-1">
              <div>
                <h5>Outstanding: &nbsp;&nbsp; {{2630 | currency}}</h5>
                <h6 class="small">Last 30 Days</h6>
              </div>
            </div>

    </ng-template>
    <kendo-grid-column title="Number" [width]="60"></kendo-grid-column>
    <kendo-grid-column title="Type" [width]="60"></kendo-grid-column>
    <kendo-grid-column title="Clinic" [width]="60"></kendo-grid-column>
    <kendo-grid-column title="Invoice" [width]="60"></kendo-grid-column>
    <kendo-grid-column title="Purchased" [width]="60"></kendo-grid-column>
    <kendo-grid-column title="Last Used" [width]="60"></kendo-grid-column>
    <kendo-grid-column title="Balance" [width]="60"></kendo-grid-column>
  </kendo-grid>
</div>