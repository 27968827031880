import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhotoDrawing } from '@models/photo/photo-drawing';
import { environment } from '@environments/environment';
import { ImageService } from './image.service';
import { forkJoin, Observable, from, of } from 'rxjs';
import { mergeMap, map, first, tap } from 'rxjs/operators';
import { PhotoMetaData } from '@models/photo/photo-meta-data';

@Injectable({
  providedIn: 'root',
})
export class PhotoDrawingService {
  constructor(private http: HttpClient, private imageService: ImageService) {}

  getPhotoDrawingsByPhotoId(photoId: number) {
    return this.http.get<PhotoDrawing[]>(environment.baseUrl + 'api/PhotoDrawings/GetByPhotoId/' + photoId);
  }

  addPhotoDrawing(photoDrawing: PhotoDrawing): Observable<PhotoDrawing> {
    return this.drawingToFile(photoDrawing).pipe(
      mergeMap((photoFile) => {
        const metadata = new PhotoMetaData(photoDrawing.photo);
        metadata.id = 0;
        return this.imageService.uploadPhoto(photoFile, metadata, { createOriginal: false });
      }),
      mergeMap((metadata) => {
        photoDrawing.photo = metadata;
        photoDrawing.photoId = metadata.id;
        return this.http.post<PhotoDrawing>(environment.baseUrl + 'api/PhotoDrawings', photoDrawing).pipe(
          map((pd) => {
            pd.photo = metadata;
            return pd;
          })
        );
      }),
      first()
    );
  }

  updatePhotoDrawing(photoDrawing: PhotoDrawing): Observable<PhotoDrawing> {
    const metadata = new PhotoMetaData(photoDrawing.photo);
    return this.drawingToFile(photoDrawing).pipe(
      mergeMap((photoFile) => {
        return this.imageService.uploadPhoto(photoFile, metadata, { createOriginal: false });
      }),
      mergeMap((resultMetadata) => {
        photoDrawing.photo = resultMetadata;
        return this.http.put<PhotoDrawing>(environment.baseUrl + 'api/PhotoDrawings', photoDrawing).pipe(
          map((pd) => {
            pd.photo = resultMetadata;
            return pd;
          })
        );
      }),
      first()
    );
  }

  deletePhotoDrawing(photoDrawing: PhotoDrawing): Observable<any> {
    return this.http.delete(environment.baseUrl + 'api/PhotoDrawings/' + photoDrawing.id).pipe(first());
  }

  addPhotoDrawings(photoDrawings: PhotoDrawing[]): Observable<PhotoDrawing[]> {
    if (photoDrawings.length == 0) return of([]);

    let addRequests = photoDrawings.map((drawing) => this.addPhotoDrawing(drawing));
    return forkJoin(addRequests);
  }

  updatePhotoDrawings(photoDrawings: PhotoDrawing[]): Observable<PhotoDrawing[]> {
    if (photoDrawings.length == 0) return of([]);

    let updateRequests = photoDrawings.map((drawing) => this.updatePhotoDrawing(drawing));
    return forkJoin(updateRequests);
  }

  deletePhotoDrawings(photoDrawings: PhotoDrawing[]): Observable<any> {
    if (photoDrawings.length == 0) return of([]);

    let deleteRequests = photoDrawings.map((drawing) => this.deletePhotoDrawing(drawing));

    return forkJoin(deleteRequests);
  }

  private drawingToFile(drawing: PhotoDrawing): Observable<File> {
    return from(fetch(drawing.photo.filePath)).pipe(
      mergeMap((res) => res.blob()),
      map((blob) => new File([blob], drawing.photo.imageName, { type: drawing.photo.imageType }))
    );
  }
}
