<div class="top-nav">
  <img class="user-avatar mx-3" [src]="user?.avatar" onerror="src='../../assets/Avatars/avatar-placeholder.png'" />
  <div class="clinic-logo"><img [src]="clinic?.logoUrl" onerror="src='../../assets/svg/emily-logo-final.svg'" /></div>
  <button class="btn em-btn btn-clean p-3" mat-button [matMenuTriggerFor]="ellipsisMenu" [disableRipple]="true">
    <i class="fal fa-ellipsis-v"></i>
  </button>
</div>

<div class="content">
  <router-outlet></router-outlet>
  <div *ngIf="displayCalendar" class="cal-overlay">
    <mat-calendar [selected]="currentDate" (selectedChange)="setSelectedDate($event)"></mat-calendar>
  </div>
  <!-- <div class="swipe-hint">
    <i class="far fa-chevron-left"></i>
    <i class="far fa-chevron-right"></i>
  </div> -->
</div>

<div class="bottom-nav">
  <div class="date-controls-group ml-3">
    <button class="btn em-btn btn-clean" (click)="goBackOneDay()">
      <i class="fal fa-chevron-left large-icon"></i>
    </button>
    <button class="btn em-btn btn-clean today" (click)="goToToday()">Today</button>
    <button class="btn em-btn btn-clean" (click)="goForwardOneDay()">
      <i class="fal fa-chevron-right large-icon"></i>
    </button>
  </div>
  <span class="ml-3">{{ currentDate | date : 'EEE, MMM d, YY' }}</span>
  <button class="btn em-btn btn-clean ml-auto mr-2" (click)="openCalendar($event)">
    <i class="fal fa-calendar-alt large-icon"></i>
  </button>
</div>

<mat-menu #ellipsisMenu="matMenu" class="mr-1">
  <button mat-menu-item (click)="logout()"><i class="far fa-sign-out fa-lg mr-2"></i><span>Logout</span></button>
</mat-menu>
