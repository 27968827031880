import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ITransactionTargetItem } from '@interfaces/ITransactionTargetItem';
import { PatientTransaction } from '@models/patient-transaction/patient-transaction';
import { TargetTypes } from '@models/patient-transaction/target-types';
import { AppointmentService } from '@services/appointments.service';
import { PatientTransactionFactoryService } from '@services/factories/patient-transaction-factory';
import { PatientTransactionService } from '@services/patient-transaction.service';
import { StyleService } from '@services/style.service';

@Component({
  selector: 'app-patient-transactions-item',
  templateUrl: './patient-transactions-item.component.html',
  styleUrls: ['./patient-transactions-item.component.less']
})
export class PatientTransactionsItemComponent implements OnInit {
  @Input() patientTransaction: PatientTransaction;
  isNoteInputActive: boolean;
  transactionItem: ITransactionTargetItem;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ptFactoryService: PatientTransactionFactoryService,
    private styleService: StyleService,
    private apptService: AppointmentService,
    private ptService: PatientTransactionService
  ) { }

  ngOnInit() {
    this.transactionItem = this.ptFactoryService.getTargetObject(this.patientTransaction);
  }

  getFormattedTransactionDate(date: Date) {
    return moment
      .utc(date)
      .local()
      .format('MMMM DD YYYY, h:mm A');
  }

  isServiceTransaction() {
    return this.patientTransaction.targetTypeId === TargetTypes.Appointment;
  }

  isRoutableItem() {
    return this.transactionItem && this.transactionItem.isRoutable() ? this.transactionItem.isRoutable() : false;
  }

  getItemPanelColor(): string {
    return this.transactionItem && this.transactionItem.getColor() ? this.transactionItem.getColor() : 'gainsboro';
  }

  routeToItemObject() {
    switch (this.patientTransaction.targetTypeId) {
      case TargetTypes.Appointment: {
        this.apptService.getAppointmentById(+this.patientTransaction.targetId).subscribe(appt => {
          const treatmentIsFromToday = appt.startTime.asMinutes() > moment().startOf('day').minutes();
          this.router.navigate(['../detail', appt.serviceId, treatmentIsFromToday, false], {
            relativeTo: this.route
          });
        });
        break;
      }
      case TargetTypes.PatientDocument: {
        this.router.navigate(['../../patientdocumentstab/', { documentId: this.patientTransaction.targetId }], {
          relativeTo: this.route
        });
        break;
      }
      case TargetTypes.Photo: {
        this.router.navigate(['../../patientphotostab/', { photoId: this.patientTransaction.targetId }], {
          relativeTo: this.route
        });
        break;
      }
      case TargetTypes.TreatmentPlan: {
        this.router.navigate(['../detail', 'treatmentplan', true, true], {
          relativeTo: this.route
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  getItemColor(): string {
    return this.transactionItem && this.transactionItem.getColor()
      ? this.styleService.hexToTranslucentRgbA(this.transactionItem.getColor())
      : 'ghostwhite';
  }

  activateNoteElement() {
    this.ptService.toggleItemInput.next({patientTransaction: this.patientTransaction});
  }
}
