<div class="edit-container position-absolute d-block z-index-3 white-background">
  <div class="re-links-container" kendoTooltip tooltipClass="em-tooltip" position="top">
    <div class="re-links">
      <button class="btn em-btn" (click)="cancelUpdate()">
        <i class="far fa-times" aria-hidden="true" title="Close Panel"></i>
      </button>
    </div>
  </div>

  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row mx-0 teal-background">
        <div class="col px-2">
          <h6 class="white-font uppercase p-2 mb-0">{{ addOrEdit }} Clinic Supply</h6>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col px-2">
          <mat-form-field [floatLabel]="'always'" class="remove-underline">
            <mat-select readonly matInput placeholder="Supply Type" formControlName="observationListTypeId">
              <mat-option *ngFor="let obrListTypeItem of allObservationListTypes" [value]="obrListTypeItem.id">
                {{ obrListTypeItem.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col px-2">
          <mat-form-field [floatLabel]="'always'" class="remove-underline">
            <mat-select readonly matInput placeholder="Unit Type" formControlName="unitId">
              <mat-option *ngFor="let obrUnit of allObservationUnits" [value]="obrUnit.id">
                {{ obrUnit.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col px-2">
          <mat-form-field [floatLabel]="'always'" class="remove-underline">
            <input matInput placeholder="Name" formControlName="name" class="capitalized" readonly/>
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0 teal-background">
        <div class="col">
          <h6 class="white-font uppercase mb-0 py-2">Clinic Supply Details</h6>
        </div>
      </div>
      <div class="row mt-2 mx-0">
        <div class="col px-2">
          <mat-form-field class="w-100">
            <input
              matInput
              placeholder="Display Name (if different from 'Name' above)"
              formControlName="displayName"
              class="capitalized"
            />
          </mat-form-field>
          <!--  <span class="px-2" style="font-size: xx-small; text-align: left"
            >If you wish to show a clinic supply name that is different, you can define one here.</span
          > -->
        </div>
      </div>
      <div class="row mx-0">
        <div class="col px-2">
          <mat-form-field>
            <input matInput placeholder="Price Per Unit" formControlName="pricePerUnit" class="capitalized" />
          </mat-form-field>
        </div>
        <div class="col px-2">
          <mat-form-field>
            <input matInput placeholder="Cost Per Unit" formControlName="costPerUnit" class="capitalized" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col px-2">
          <mat-form-field>
            <input
              matInput
              placeholder="Units On Hand"
              formControlName="unitsOnHand"
              class="capitalized"
              RestrictDecimalPlacesNumber
              [nDecimalPlaces]="getDecimalPlaces()"
            />
          </mat-form-field>
        </div>
        <div class="col px-2">
          <mat-form-field>
            <input matInput placeholder="List Sort Order" formControlName="sequenceNumber" class="capitalized" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0 mt-5 mr-2">
        <div class="col d-flex justify-content-end">
          <button type="submit" class="btn em-btn em-btn-green" [disabled]="!form.valid">
            {{ addOrEdit == 'Add' ? 'Add' : 'Update' }}
          </button>
        </div>
        <button class="btn em-btn em-btn-green" (click)="cancelUpdate()">Cancel</button>
      </div>
    </form>
  </perfect-scrollbar>
</div>
