<form [formGroup]="toolbarForm">
  <div class="form-row">
    <ng-container formGroupName="observation">
      <ng-container formGroupName="details">
        <!-- Area -->
        <div class="form-group col-2 mb-0">
          <label for="area">Area</label>
          <select class="form-control" formControlName="area" data-test-id="coolsculpting-toolbar-area-select">
            <option *ngFor="let area of coolsculptingFormService.areas" [ngValue]="area.id" [label]="area.name">
              {{ area.name }}
            </option>
          </select>
        </div>
        <!-- Applicator -->
        <div class="form-group col-2 mb-0">
          <label for="applicator">Applicator</label>
          <select
            class="form-control"
            formControlName="applicator"
            data-test-id="coolsculpting-toolbar-applicator-select"
            (change)="onObrListTypeSelected($event)"
          >
            <ng-container *ngFor="let applicator of coolsculptingFormService.applicators">
              <option [ngValue]="applicator.id" [label]="applicator.name">
                {{ applicator.name }}
              </option>
            </ng-container>
          </select>
        </div>
        <!-- Stock -->
        <div *ngIf="isInventoryRemainingDisplayed()" class="form-group col-1 pl-3 mb-0">
          <label for="inventory">Inventory</label>
          <input
            class="form-control text-white text-center w-100"
            name="inventory"
            disabled
            [ngClass]="{
              'bg-danger': getInventoryRemaining() <= 0 && getInventoryRemaining() !== null,
              'bg-success': getInventoryRemaining() > 0,
              'bg-white': getInventoryRemaining() === null
            }"
            [value]="getInventoryRemainingString()"
          />
        </div>
        <!-- Position -->
        <div class="form-group col d-flex mb-0">
          <div class="ml-5 pl-2">
            <label for="position" class="d-block">L/M/R</label>
            <div class="btn-group btn-group-toggle" ngbRadioGroup formControlName="position">
              <label ngbButtonLabel class="btn em-btn btn-label mr-2 pointer">
                <input ngbButton type="radio" value="left" /> L
              </label>
              <label ngbButtonLabel class="btn em-btn btn-label pointer">
                <input ngbButton type="radio" value="middle" /> M
              </label>
              <label ngbButtonLabel class="btn em-btn btn-label ml-2 pointer">
                <input ngbButton type="radio" value="right" /> R
              </label>
            </div>
          </div>
        </div>

        <!-- Angle -->
        <div class="form-group col mb-0">
          <label for="position" class="d-block">Angle</label>
          <div class="btn-group btn-group-toggle" ngbRadioGroup formControlName="angle">
            <label ngbButtonLabel class="btn em-btn btn-label mr-2">
              <input ngbButton type="radio" value="0" /> 0&#176;
              <img
                src="../../../../../../../assets/service-detail/coolsculpting-icons/button-icons/Ellipse-pink0.png"
              />
            </label>
            <label ngbButtonLabel class="btn em-btn btn-label mr-2">
              <input ngbButton type="radio" value="45" /> 45&#176;
              <img
                src="../../../../../../../assets/service-detail/coolsculpting-icons/button-icons/Ellipse-pink45.png"
              />
            </label>
            <label ngbButtonLabel class="btn em-btn btn-label mr-2">
              <input ngbButton type="radio" value="90" /> 90&#176;
              <img
                src="../../../../../../../assets/service-detail/coolsculpting-icons/button-icons/Ellipse-pink90.png"
              />
            </label>
            <label ngbButtonLabel class="btn em-btn btn-label">
              <input ngbButton type="radio" value="135" /> -45&#176;
              <img
                src="../../../../../../../assets/service-detail/coolsculpting-icons/button-icons/Ellipse-pink-45.png"
              />
            </label>
          </div>
        </div>
      </ng-container>
      <!-- Cycles -->
      <div class="form-group col-1 mb-0">
        <label for="value">Cycles</label>
        <input type="number" class="form-control w-100" formControlName="value" min="1" inputmode="numeric" />
      </div>
    </ng-container>
  </div>
</form>
