import { Pipe } from "@angular/core";

@Pipe({
  name: "phoneNumber"
})
export class PhonePipe {
  transform(rawNum) {
    if (rawNum.length == 11){
      return rawNum[0] + '-' + rawNum[1] + rawNum[2] + rawNum[3]   + '-' + rawNum[4] + rawNum[5] + rawNum[6]  + '-' + rawNum[7] + rawNum[8] + rawNum[9]  + rawNum[10]   
    }
    else if (rawNum.length == 10){

      return  rawNum[0] + rawNum[1] + rawNum[2]   + '-' + rawNum[3] + rawNum[4] + rawNum[5] + '-'  + rawNum[6] + rawNum[7] + rawNum[8] + rawNum[9] ;
    }
    else return rawNum;
  }
}