import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BillingSettings } from '@models/billing/ministry-billing/billing-settings';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BillingSettingsService {
  public editModeToggled = new Subject<void>();

  private refreshRequired = new Subject<boolean>();
  refreshRequired$ = this.refreshRequired.asObservable();

  constructor(private http: HttpClient) {}

  updateRefreshRequired(state: boolean) {
    this.refreshRequired.next(state);
  }

  updateBillingSettings(billingSettings: BillingSettings): Observable<BillingSettings> {
    return this.http.put<BillingSettings>(`${environment.baseUrl}api/BillingSettings`, billingSettings);
  }

  getBillingSettingsById(id: number): Observable<BillingSettings> {
    return this.http.get<BillingSettings>(`${environment.baseUrl}api/BillingSettings/${id}`);
  }

  getBillingSettings(): Observable<BillingSettings[]> {
    return this.http.get<BillingSettings[]>(`${environment.baseUrl}api/BillingSettings`);
  }
}
