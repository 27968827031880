import { Component, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.less']
})
export class ConfirmDeleteDialogComponent {
  public dialog: MatDialog;
  extraMessage: string = null;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
     
     }

  ngOnInit(){
    this.extraMessage = this.data && this.data.extraMessage ? this.data.extraMessage : null;
  }

  onNoClick(): void {
    this.data.result = 'cancel';
  }
}
