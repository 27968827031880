import { Component, OnDestroy, OnInit } from '@angular/core';
import { IReportComponent } from '@models/reports/ireport-component';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataSourceRequestState, groupBy } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { ReportsService } from '@services/reports.service';

@Component({
  selector: 'app-supplies-reports',
  templateUrl: './supplies-reports.component.html',
  styleUrls: ['./supplies-reports.component.less'],
})
export class SuppliesReportsComponent implements OnInit, OnDestroy, IReportComponent {
  gridView: GridDataResult;
  gridState: DataSourceRequestState;
  providerList: any[];
  loading: boolean = false;
  unsub: Subject<void> = new Subject<void>();
  categoryOrder = ['Toxins', 'Fillers', 'CoolSculpting Applicators', 'CoolTone Applicators', 'Needles', 'Deoxycholate']; //hard coded until we have sort order

  constructor(private reportsService: ReportsService) {}

  ngOnInit(): void {
    this.initDefaultValue();
    this.getReportData();
  }

  initDefaultValue(): void {
    this.gridState = {
      skip: 0,
      take: 100,
      sort: [],
      group: [{ field: 'category', dir: 'asc' }],
    };
  }

  getReportData(): void {
    this.loading = true;

    const getSupplyReport = this.reportsService.getSuppliesReportEntities(this.gridState);
    const getProviderReport = this.reportsService.getProviderSuppliesReportEntities(this.gridState);

    combineLatest([getSupplyReport, getProviderReport])
      .pipe(takeUntil(this.unsub))
      .subscribe(([supplyReport, providerReport]) => {
        this.processResults(supplyReport, providerReport);
        this.loading = false
      });
  }

  private processResults(supplyReport: GridDataResult, providerReport: GridDataResult): void {
    let providers = {};

    providerReport.data.forEach((r) => {
      r.userId = this.makeValidIdentifier(r.userId);
      if (!(r.userId in providers)) providers[r.userId] = r.fullName;
    });
    this.providerList = Object.entries(providers).map((p) => ({ userId: p[0], fullName: p[1] }));

    supplyReport.data.forEach((sr) => {
      for (let p in providers) {
        sr[p] = 0;
      }
      providerReport.data.forEach((pr) => {
        if (pr.observationListId == sr.observationListId) sr[pr.userId] = pr.used;
      });
    });

    let grouped = groupBy(supplyReport.data, this.gridState.group); // group not working in GetSuppliesReportEntities
    let groupedSorted = grouped.sort((a, b) => {
      let rankA = this.categoryOrder.indexOf(a.value);
      let rankB = this.categoryOrder.indexOf(b.value);
      return rankA - rankB;
    });
    this.gridView = {
      data: groupedSorted,
      total: supplyReport.total,
    };
  }

  private makeValidIdentifier(str: string): string {
    if (!str) return null;
    str = str.replace(/-/g, '_');
    return 'x' + str;
  }

  exportToExcel(): void {
    throw new Error('exportToExcel not implemented.');
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.gridState = state;
    this.getReportData();
  }

  public checkAreasExist(dataItem: any): boolean {
    return dataItem['used'] > 0;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
