<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>

<div class="edit-container edit-document position-absolute d-block z-index-3 white-background">
  <div class="re-links-container">
    <button class="btn em-btn teal-background" (click)="cancelUpdate()">
      <i class="far fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row mx-0 teal-background">
        <div class="col px-2">
          <h6 class="white-font uppercase p-2 mb-0">{{ addOrUpdate }} eTreatment Form</h6>
        </div>
      </div>

      <!-- eTreatment Form Category -->
      <div class="row mx-0 mt-2">
        <div class="col">
          <mat-form-field class="w-75">
            <mat-select
              placeholder="eTreatment Form Category"
              formControlName="eTreatmentFormCategoryId"
              autofocus
              required
            >
              <mat-option
                *ngFor="let eTreatmentFormCategory of eTreatmentFormCategories"
                [value]="eTreatmentFormCategory.id"
              >
                {{ eTreatmentFormCategory.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- eTreatment Form Name-->
      <div class="row mx-0 mt-2">
        <div class="col">
          <mat-form-field class="w-75">
            <input matInput placeholder="eTreatment Form Title" formControlName="name" required />
          </mat-form-field>
        </div>
      </div>

      <!-- Submit Buttons-->
      <div class="row mx-4 mt-4">
        <button type="submit" class="btn em-btn em-btn-green ml-auto mr-2" [disabled]="isFormDisabled()">
          {{ addOrUpdate }}
        </button>
        <button class="btn em-btn em-btn-green" (click)="cancelUpdate()">Cancel</button>
      </div>
    </form>
  </perfect-scrollbar>
</div>
