import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Prescription } from '@models/prescriptions/prescription';
import { PrescriptionService } from '@services/prescriptions/prescription.service';

@Component({
  selector: 'app-prescriptions-widget',
  templateUrl: './prescriptions-widget.component.html',
  styleUrls: ['./prescriptions-widget.component.less']
})
export class PrescriptionsWidgetComponent implements OnInit {
  public prescriptionList: Prescription[];
  @Input() panelHeight: number;
  @Input() patientId: number;
  @Input() showprescriptions: boolean = true;

  constructor(
    private prescriptionService: PrescriptionService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    if (!this.patientId) {
      this.patientId = this.route.snapshot.params.patId.split('_')[0];
    }
    this.getPrescriptionsByPatientId();
  }

  ngOnChanges() {
    this.getPrescriptionsByPatientId();
  }

  public getPrescriptionsByPatientId() {
    if (!this.patientId) return;
    this.prescriptionService.getPrescriptionsByPatientId(this.patientId)
      .subscribe(
        (res) => {
          this.prescriptionList = res;
        }
      );
  }

  public navigateToAddPrescription() {
    this.router.navigate(['prescription'], { relativeTo: this.route.parent });
  }

  public navigateToPrescriptionView() {
    this.router.navigate(['prescription/view'], { relativeTo: this.route.parent });
  }

  public navigateToAddPrescriptionById(id: number) {
    this.router.navigate(['prescription/view'], { relativeTo: this.route.parent, queryParams: {prescriptionId: id} });
  }
}
