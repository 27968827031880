import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from '@app/shared/helpers';
import { ObrPlottingEventType } from '@models/observation/obr-plotting-event-type';
import { Observation, ObservationListItem, ObservationType, ObservationTypes } from '@models/observation/observation';
import { ObservationPlot } from '@models/observation/observation-plot';
import { Service } from '@models/service/service';
import { ServiceDetailEventTypes } from '@models/service/service-detail-event-types';
import { PlannedTreatment } from '@models/treatment-planning/planned-treatment';
import { InvoicesService } from '@services/invoices.service';
import { ObservationListItemsService } from '@services/observation-list-items.service';
import { ObservationTypesService } from '@services/observation-types.service';
import { ObservationService } from '@services/observation.service';
import { PatientService } from '@services/patient.service';
import { PlottingEventService } from '@services/plotting-event.service';
import { CoolsculptingFormService } from '@services/service-detail/coolsculpting-form.service';
import { ToolbarEventService } from '@services/service-detail/toolbar-event.service';
import { ServicesService } from '@services/services.service';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-coolsculpting-toolbar',
  templateUrl: './coolsculpting-toolbar.component.html',
  styleUrls: ['./coolsculpting-toolbar.component.less'],
})
export class CoolsculptingToolbarComponent implements OnInit, OnDestroy {
  toolbarForm: FormGroup;
  unsub: Subject<void>;
  obrType: ObservationType;
  obrListItem: ObservationListItem;
  pendingSaveNewObrPlots: ObservationPlot[] = [];
  existingObrPlots: ObservationPlot[] = [];
  pendingDeleteObrPlots: ObservationPlot[] = [];
  treatmentPlanning: boolean;
  @Input() associatedPlannedTreatment: PlannedTreatment;
  @Input() service: Service;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public coolsculptingFormService: CoolsculptingFormService,
    private toolbarEventService: ToolbarEventService,
    private plottingEventService: PlottingEventService,
    private observationTypesService: ObservationTypesService,
    private observationService: ObservationService,
    private patientService: PatientService,
    private observationListItemsService: ObservationListItemsService,
    private route: ActivatedRoute,
    private treatmentPlanService: TreatmentPlanService,
    private servicesService: ServicesService,
    private invoiceService: InvoicesService
  ) {
    this.unsub = new Subject<void>();

    this.observationTypesService.getObservationTypeByName(ObservationTypes.Coolsculpting).subscribe((obrType) => {
      this.obrType = obrType;
    });
  }

  ngOnInit() {
    this.initForm();
    this.initToolbarSubscription();
    this.initPlottingSubscription();

    this.route.params.subscribe((params) => {
      this.treatmentPlanning = JSON.parse(params.treatmentPlanning);
    });
  }

  initToolbarSubscription() {
    this.toolbarEventService
      .getToolbarControlSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((response) => {
        switch (response.event) {
          case ServiceDetailEventTypes.ResetToolbar: {
            this.initForm();
            break;
          }
          case ServiceDetailEventTypes.TogglePinDrop: {
            this.coolsculptingFormService.droppingPins = !this.coolsculptingFormService.droppingPins;
            if (this.coolsculptingFormService.droppingPins) {
              // this.toolbarForm.disable();
              const formObr = this.toolbarForm.value.observation;
              // Reset the plot details
              formObr.details.plotDetails = { leafletId: null, coords: null };

              const obr = new Observation({
                id: formObr.id,
                typeId: this.obrType.id,
                name: 'Coolsculpting',
                value: formObr.value,
                unitId: null,
                details: formObr.details,
                patientId: this.patientService.patientPanelPatient.patientId,
                serviceId: response.service.serviceId,
              });

              this.observationListItemsService.assignPriceToObservation(obr);

              this.plottingEventService.startPinDrop(obr);
            } else {
              this.plottingEventService.stopPinDrop();
              // this.toolbarForm.enable();
            }
            break;
          }
          default: {
            break;
          }
        }
      });
  }

  initPlottingSubscription() {
    this.plottingEventService
      .getPlotSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((response) => {
        response.details.forEach((obr: Observation, leafletId: number) => {
          if (obr.id === 0) {
            if (this.pendingSaveNewObrPlots.findIndex((op) => op.leafletId === leafletId) === -1) {
              this.pendingSaveNewObrPlots.push(new ObservationPlot(leafletId, obr));
            }
          } else {
            if (this.existingObrPlots.findIndex((op) => op.leafletId === leafletId) === -1) {
              this.existingObrPlots.push(new ObservationPlot(leafletId, obr));
            }
          }
        });
      });

    this.plottingEventService
      .getObservationSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((response) => {
        if (response.event === ObrPlottingEventType.DeletePoints) {
          response.leafletIds.forEach((leafletId: number) => {
            const pendingItemIndex = this.pendingSaveNewObrPlots.findIndex((op) => op.leafletId === leafletId);
            if (pendingItemIndex !== -1) {
              this.pendingSaveNewObrPlots.splice(pendingItemIndex, 1);
            }

            const existingItem = this.existingObrPlots.find((op) => op.leafletId === leafletId);
            if (!isNullOrUndefined(existingItem)) {
              this.pendingDeleteObrPlots.push(new ObservationPlot(leafletId, existingItem.observation));
            }
          });
        }
      });
  }

  initForm() {
    this.obrListItem = null;
    this.toolbarForm = this.fb.group({
      observation: this.fb.group({
        id: [0],
        // Value === cycles
        value: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
        details: this.fb.group({
          area: [null, Validators.required],
          applicator: [null, Validators.required],
          position: [null, Validators.required],
          angle: [null, Validators.required],
          massage: [false],
          plotDetails: [
            {
              coords: null,
              leafletId: null,
            },
          ],
          // When we are in treatment planning mode our cycles are avaliable
          avaliable: [null],
        }),
      }),
    });
    this.onFormChange();
  }

  onFormChange() {
    this.toolbarForm.valueChanges.subscribe(() => {
      this.toolbarForm.patchValue(
        {
          observation: {
            details: {
              avaliable: this.toolbarForm.get('observation').get('value').value,
            },
          },
        },
        { emitEvent: false, onlySelf: true }
      );

      this.toolbarEventService.updateToToolbarForm(this.toolbarForm.valid || this.toolbarForm.disabled);
    });
  }

  onObrListTypeSelected(event: Event) {
    const obrListType = (event.target as HTMLSelectElement).value;
    const obrListId = +obrListType.split(' ')[1];

    this.observationListItemsService.getObservationListItem(obrListId).subscribe((obrListItem) => {
      this.obrListItem = obrListItem;
    });
  }

  public getInventoryRemaining() {
    return this.observationListItemsService.getInventoryRemaining(
      this.obrListItem,
      this.pendingSaveNewObrPlots.concat(this.existingObrPlots),
      this.pendingDeleteObrPlots,
      false
    ) as number;
  }

  public getInventoryRemainingString() {
    return this.observationListItemsService.getInventoryRemaining(
      this.obrListItem,
      this.pendingSaveNewObrPlots.concat(this.existingObrPlots),
      this.pendingDeleteObrPlots,
      true
    ) as string;
  }

  isInventoryRemainingDisplayed() {
    return true;
    /*
    return this.observationListItemsService.isInventoryRemainingDisplayed(
      this.obrListItem,
      this.pendingSaveNewObrPlots,
      this.pendingDeleteObrPlots
    ) && !this.treatmentPlanning;
    */
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
