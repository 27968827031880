import { Component, Input, OnInit } from '@angular/core';
import { ConvergeCard } from '@models/payments/converge-card';
import { PaymentHelper } from '@services/helpers/payment-helper';

@Component({
  selector: 'app-converge-card',
  templateUrl: './converge-card.component.html',
  styleUrls: ['./converge-card.component.less'],
})
export class ConvergeCardComponent implements OnInit {
  @Input() card: ConvergeCard;
  @Input() showSmallBrandLogo: boolean = false;

  PaymentHelper = PaymentHelper;

  constructor() {}

  ngOnInit(): void {}
}
