import { ClinicReviewSettings } from '@models/reviews/review-settings';
import { catchError, map } from 'rxjs/operators';
import { Patient } from '@models/patient';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable, Subject, throwError, of } from 'rxjs';
import { Review } from '@models/reviews/review';
import { ReviewResponse } from '@models/reviews/review-response';
export interface PatientFacingReviewInfo{
  clinicName: string;
	clinicLogo: string;

	lowRatingTextContent : string;
	highRatingTextContent : string;

	googleReviewUrl: string;
  phoneNumber: string;
  email: string;
  website: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReviewSettingsService {

  constructor(private http: HttpClient) { }


  getInfoForPatientReview(patientId: number, appointmentId?: number){

    return this.http.get<PatientFacingReviewInfo>(environment.baseUrl + `api/Reviews/PatientReviewInfo/${patientId}${appointmentId ? '/' + appointmentId : ''}`, {  });
  }

  submitPatientReview(patientId: number, ratingText: string, ratingValue: number,  appointmentId: number = null){
    const params = {appointmentId, ratingText, ratingValue, patientId};

    return this.http.post<ClinicReviewSettings>(environment.baseUrl + 'api/Reviews',  params );
  }


  unsubscribePatientFromReviewRequests(patientId: number): Observable<any> {


    return this.http.put<ClinicReviewSettings>(environment.baseUrl + `api/Reviews/Unsubscribe/${patientId}`, {}  );
  }


}
