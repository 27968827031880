import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { Subject, zip } from 'rxjs';
import { takeUntil, distinctUntilChanged, debounceTime, take } from 'rxjs/operators';

import { Patient } from '@models/patient';
import { PatientTransaction } from '@models/patient-transaction/patient-transaction';
import { PatientNoteService } from '@services/patient-note.service';
import { PatientTransactionService } from '@services/patient-transaction.service';
import { PatientService } from '@services/patient.service';
import { UsersService } from '@services/users.service';
import { User } from '@models/user';

@Component({
  selector: 'app-patient-transactions-list',
  templateUrl: './patient-transactions-list.component.html',
  styleUrls: ['./patient-transactions-list.component.less'],
})
export class PatientTransactionsListComponent implements OnInit, AfterViewInit, OnDestroy {
  patientTransactions: PatientTransaction[];
  users: User[];
  unsub: Subject<void> = new Subject<void>();
  searchTerm$ = new Subject<string>();
  patient: Patient;
  pageNumber: number;
  transactionCountForPatient: number;
  defaultPageSize: number;
  selectedUsers: string[] = [];
  searchTermText = '';
  loading = false;
  pageSearchNumber: number;
  constructor(
    private patientService: PatientService,
    private ptService: PatientTransactionService,
    private patientNotesService: PatientNoteService,
    private userService: UsersService
  ) {}

  ngOnInit() {
    this.defaultPageSize = this.ptService.defaultPageSize;
    this.pageNumber = 1;
    this.pageSearchNumber = 1;

    this.patientService.thePatientUpdated$.pipe(takeUntil(this.unsub)).subscribe((patient) => {
      this.patient = this.patientService.patientPanelPatient;
      this.loadInitialData();
    });

    this.patientNotesService.patientNotesChanged.pipe(takeUntil(this.unsub)).subscribe((pts) => {
      this.ptService
        .getPatientTransactions(this.patient.patientId, 1, this.ptService.defaultPageSize)
        .pipe(takeUntil(this.unsub))
        .subscribe((ptrs) => {
          this.patientTransactions = ptrs;
        });
    });

    this.loadUser();
    this.searchByText();
  }

  ngAfterViewInit() {
    if (this.patientService.patientPanelPatient && this.patientService.patientPanelPatient.patientId > 0) {
      this.patient = this.patientService.patientPanelPatient;
      this.loadInitialData();
    }
  }

  searchByText() {
    this.searchTerm$.pipe(distinctUntilChanged(), debounceTime(500)).subscribe((searchTerm) => {
      this.searchTermText = searchTerm;
      this.searchPatientTransactions();
    });
  }

  searchByUser(id: string) {
    if (!this.selectedUsers.includes(id)) {
      this.selectedUsers.push(id);
      this.searchPatientTransactions();
    }
  }

  removeUser(id: string) {
    if (this.selectedUsers.includes(id)) {
      const position = this.selectedUsers.findIndex((user) => user === id);
      this.selectedUsers.splice(position, 1);
      this.searchPatientTransactions();
    }
  }

  searchPatientTransactions() {
    this.loading = true;
    zip(
      this.ptService.getPatientTransactionCount(this.patient.patientId, this.selectedUsers, this.searchTermText),
      this.ptService.searchPatientTransactions(
        this.patient.patientId,
        this.selectedUsers,
        this.searchTermText,
        1,
        this.ptService.defaultPageSize
      )
    )
      .pipe(take(1))
      .subscribe(
        ([count, patientTransactions]) => {
          this.transactionCountForPatient = count;
          this.patientTransactions = patientTransactions;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  loadUser() {
    this.userService
      .getUsers()
      .pipe(takeUntil(this.unsub))
      .subscribe((users: User[]) => {
        this.users = users;
      });
  }

  getUserNameById(id: string): string {
    return this.users.find((user) => user.id === id).fullName;
  }

  loadInitialData() {
    this.ptService
      .getPatientTransactionCount(this.patient.patientId, this.selectedUsers, this.searchTermText)
      .subscribe((count) => {
        this.transactionCountForPatient = count;
      });

    this.ptService.getPatientTransactions(this.patient.patientId, 1, this.ptService.defaultPageSize).subscribe((pt) => {
      this.patientTransactions = pt;
      this.pageNumber = 2;
    });
  }

  loadPagedData() {
    if (this.selectedUsers.length > 0 || this.searchTermText !== '') {
      this.ptService
        .searchPatientTransactions(
          this.patient.patientId,
          this.selectedUsers,
          this.searchTermText,
          this.pageSearchNumber,
          this.ptService.defaultPageSize
        )
        .subscribe((patientTransactions) => {
          this.patientTransactions = this.patientTransactions.concat(patientTransactions);
          this.pageSearchNumber++;
        });
    } else {
      this.ptService
        .getPatientTransactions(this.patient.patientId, this.pageNumber, this.ptService.defaultPageSize)
        .subscribe((pts) => {
          this.patientTransactions = this.patientTransactions.concat(pts);
          this.pageNumber++;
        });
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
