export class SquarePayment {
  id: number;
  type: SquarePaymentType;
  status: SquarePaymentStatus;
  squareCardId: string;
  squareTransactionId?: string;
  amount: number;
  finalTotal?: number;
  clinicId: number;
  errors?: string;
}

export enum SquarePaymentType {
  Payment = 0,
  Cancellation = 1,
  Refund = 2,
}

export enum SquarePaymentStatus {
  Reserved = 0,
  Pending = 1,
  Failed = 2,
  Completed = 3,
}
