<!-- <ngx-loading [show]="loading"></ngx-loading> -->

<div class="modal-header">
  <h4 class="modal-title no-transform">Add Photo</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form *ngIf="addPhotoForm" [formGroup]="addPhotoForm" class="photo-form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <!-- Image -->
    <div class="row py-1">
      <div class="col">
        <div class="custom-file">
          <input *ngIf="!isServicePhoto" type="file" class="custom-file-input" (change)="onFileChange($any($event.target).files)" accept="image/png, image/jpeg" multiple>
          <input *ngIf="isServicePhoto || singleImageSelect" type="file" class="custom-file-input" (change)="onFileChange($any($event.target).files)" accept="image/png, image/jpeg">
          <label class="custom-file-label" for="addImage">
            <div *ngIf="filesSelected === selectedFiles.None">
              Choose File
            </div>
            <div *ngIf="filesSelected === selectedFiles.Single">
              {{addPhotoForm.value.file.name}}
            </div>
            <div *ngIf="filesSelected === selectedFiles.Multiple">
              Multiple Files Selected
            </div>
          </label>
        </div>
      </div>
    </div>
    <ng-container formGroupName="metadata">
      <!-- Name -->
      <div class="row py-1">
        <div class="col">
          <input type="text" class="form-control" placeholder="Add image name" formControlName="imageName">
        </div>
      </div>
      <!-- Notes -->
      <div class="row py-1">
        <div class="col">
          <textarea class="form-control" rows="3" placeholder="Add notes" formControlName="notes"></textarea>
        </div>
      </div>
      <!-- Tags -->
      <div *ngIf="!isClinicPhoto" class="row py-1">
        <div class="col">
          <tag-input [formControlName]="'tags'" name="tags" #input [onlyFromAutocomplete]="true">
            <ng-template let-item="item" let-index="index">
              <div class="tag-pill" [ngStyle]="{'border-color': pillColours[item.type]}">
                <ng-container *ngIf="item.title; else text">
                  {{item.title}}
                </ng-container>
                <ng-template #text>
                  {{item.display}}
                </ng-template>
                <delete-icon (click)="input.removeItem(item, index)"></delete-icon>
              </div>
            </ng-template>
            <tag-input-dropdown [focusFirstElement]="true" [autocompleteItems]="tagList" [zIndex]="99999"
              [displayBy]="'title'" [identifyBy]="'tagId'" [showDropdownIfEmpty]="true" [keepOpen]="false">
            </tag-input-dropdown>
          </tag-input>
        </div>
      </div>
      <!-- Consent to share -->
      <div *ngIf="!isClinicPhoto" class="row py-1">
        <div class="col">
          <div class="form-check pl-0">
            <label for="consentToShare">
              Consent to Share Photo(s)
            </label>
            <select id="photoConsentSelect" #photoConsentSelect class="form-control" (change)="setPhotoConsent()" formControlName="photoConsentTypeId">
              <option *ngFor="let option of photoConsentOptions" [value]="option.value">{{option.title}}</option>
            </select>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="row py-3">
      <div class="col text-center">
        <button type="submit" class="btn em-btn em-btn-green" [disabled]="uploadIsInvalid()">
          Upload
        </button>
      </div>
      <div *ngIf="patient && patient.avatar" class="col text-center">
        <button type="submit"  class="btn em-btn em-btn-green" (click)="deleteAvatar()">
          Remove Avatar
        </button>
      </div>
    </div>
    <div *ngIf="isServicePhoto || showGallery" class="row py-3 photo-gallery">
      <div class="col text-center">
        <ngx-gallery [options]="galleryOptions" [images]="galleryImages" (change)="imageClicked($event)"></ngx-gallery>
      </div>
    </div>
  </div>
</form>
