<ng-template #upperStars let-fill="fill">
  <span class="star" [class.full]="fill === 100">
    <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
  </span>
</ng-template>
<ng-template #lowerStars let-fill="fill">
  <span class="starSmall" [class.full]="fill === 100">
    <span class="halfSmall" [style.width.%]="fill">&#9733;</span>&#9733;
  </span>
</ng-template>
<div class="reviews-container h-100 content">
  <div *ngIf="!patientId" class="reviews-header container-fluid">
    <div class="reviews-toolbar row px-2 navy-background">
      <div class="col-12 d-flex align-items-center py-1 justify-content-start">
        <h5 class="white-font text-uppercase m-0 mr-2">
          Reviews
          <!-- <button class="btn kendo-btn white-font" (click)="editReviewSettings()">
            <i class="fas fa-edit"></i>
          </button> -->
        </h5>
        <!--<div
          class="d-flex align-items-center ml-5"
          kendoTooltip
          tooltipClass="em-tooltip"
          position="bottom"
          title="Average internal rating: {{ internalAverageScore | number: '1.0-1' }} / 5"
        >
          <img class="" style="height: 50px; width: 50px" src="/assets/logo/logo-192.png" />
          <div class="ml-1 d-flex flex-column align-items-center justify-content-center">
            <h6 class="white-font pt-2 text-uppercase m-0 mr-2">{{ internalAverageScore | number: '1.2-2' }}</h6>
            <span class="white-font text-uppercase m-0 mr-2">
              <ngb-rating
                [starTemplate]="upperStars"
                [rate]="internalAverageScore"
                [max]="5"
                [readonly]="true"
              ></ngb-rating
            ></span>
          </div>
        </div> -->
        <div class="d-flex align-items-center ml-5" *ngIf="clinic?.googlePlaceId && googleAverageScore > 0">
          <a
            [href]="getGoogleMapsQuery()"
            target="_blank"
            kendoTooltip
            tooltipClass="em-tooltip"
            position="bottom"
            title="Click to go to {{ clinic?.name }} Google Maps page."
          >
            <img alt="Google Logo" style="height: 50px" src="/assets/reviews/google.png" />
          </a>
          <div
            class="ml-1 d-flex flex-column align-items-center justify-content-center"
            kendoTooltip
            tooltipClass="em-tooltip"
            position="bottom"
            title="Average Google Reviews rating: {{ googleAverageScore | number : '1.0-1' }} / 5"
          >
            <h6 class="white-font pt-2 text-uppercase m-0 mr-2">{{ googleAverageScore | number : '1.0-1' }}</h6>
            <span class="white-font text-uppercase m-0 mr-2">
              <ngb-rating
                [starTemplate]="upperStars"
                [rate]="googleAverageScore"
                [max]="5"
                [readonly]="true"
              ></ngb-rating
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="reviews-content p-4 h-100">
    <nav mat-tab-nav-bar class="reviews-nav border-0">
      <button
        *appAuthGuard="reviewsViewPolicy"
        class="btn text-uppercase em-btn-nav-tab mr-2 position-relative"
        data-test-id="historyTabButton"
        routerLink="history"
        routerLinkActive
        #historyTab="routerLinkActive"
        [class.selected]="historyTab.isActive"
      >
        Reviews
      </button>
      <button
        *appAuthGuard="reviewsEditPolicy"
        class="btn text-uppercase em-btn-nav-tab mr-2 position-relative"
        data-test-id="settingsTabButton"
        routerLink="settings"
        routerLinkActive
        #settingsTab="routerLinkActive"
        [class.selected]="settingsTab.isActive"
      >
        Settings
      </button>
    </nav>
    <div class="header-line mb-2"></div>
    <div class="reviews-type d-flex justify-content-between">
      <div class="teal-font w-100 d-flex align-items-end" *ngIf="showGooglePlaceSetupLink">
        <span
          >This clinic does not have a google place ID specified in clinic configuration.
          <a [routerLink]="['/management/organization/clinics/general']">Click here</a> to configure.</span
        >
      </div>
    </div>

    <router-outlet></router-outlet>
  </div>
</div>

<app-actionpanel></app-actionpanel>
