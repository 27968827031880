<ngx-loading [show]="loading"></ngx-loading>
  <kendo-grid
  class="emr-kendo-grid"
    [data]="gridView"
    [style.maxHeight.%]="100"
    [pageable]="false"
    [sortable]="{
      allowUnsort: false,
      mode: 'single'
    }"
    [sort]="gridState.sort"
    (dataStateChange)="stateChange($event)"
    #grid="kendoGrid"
  >
    <kendo-grid-column field="patientName" title="Patient">
      <ng-template kendoGridHeaderTemplate let-column>
        {{ column.title }}
        <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <button
          type="button"
          class="btn-clean px-0 text-left underline"
          (click)="navigateToPatient(dataItem.patientId)"
        >
          {{ dataItem.patientName }}
        </button>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="serviceDate" title="Service Date">
      <ng-template kendoGridHeaderTemplate let-column>
        {{ column.title }}
        <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        {{ dataItem.serviceDate | date : 'yyyy-MM-dd h:mma' }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="salesAmount" title="Service Amount" format="{0:c}">
      <ng-template kendoGridHeaderTemplate let-column>
        {{ column.title }}
        <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngFor="let tax of possibleTaxes" [field]="tax.name" [title]="tax.name">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        {{ dataItem.taxCollected[tax.name] | currency }}
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
