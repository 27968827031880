export enum NudgeTypes {
  'Dictation/Letter' = 1,
  'Follow-Up',
  Investigation,
  Referral,
  Other,
  Inventory
}

export enum NudgeTypesTitle {
  'Dictation/Letter' = 'Dictation/Letter',
  'Follow-Up' = 'Follow-Up',
  Investigation = 'Investigation',
  Referral = 'Referral',
  Other = 'Other',
  Inventory = 'Inventory'
}

