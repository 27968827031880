import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CancellationReason } from '@models/cancellation-reason';
import { CancellationReasonService } from '@services/cancellation-reason.service';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { FormatterService } from '@services/formatter.service';

@Component({
  selector: 'app-edit-cancellation-reason',
  templateUrl: './edit-cancellation-reason.component.html',
  styleUrls: ['./edit-cancellation-reason.component.less']
})
export class EditCancellationReasonComponent implements OnInit, AfterViewInit {

  editCancellationReasonPanelVisible = false;
  addOrEdit = 'Add';

  cancellationReasonIdParam: string;

  name: FormControl;
  isNew: boolean;

  public selectedCancellationReason: CancellationReason;
  public editedCancellationReason: CancellationReason;

  constructor(private cancellationReasonService: CancellationReasonService,
    private catalogueUpdatesService: CatalogueUpdatesService,
    public formatterService: FormatterService,
    private route: ActivatedRoute,
    private router: Router) {
    this.name = new FormControl();
  }

  ngOnInit() {
    this.selectedCancellationReason = this.initCancellationReason(this.selectedCancellationReason);
    this.editedCancellationReason = this.initCancellationReason(this.editedCancellationReason);

    this.isNew = true;
    this.addOrEdit = 'Add';
    this.route.params.subscribe(params => {
      this.cancellationReasonIdParam = params['cnclid'];
      if (this.cancellationReasonIdParam !== '_' && this.cancellationReasonIdParam != null) {
        this.cancellationReasonService.getCancellationReasonById(this.cancellationReasonIdParam).subscribe(snapshot => {
          this.selectedCancellationReason = snapshot as CancellationReason;
          this.editedCancellationReason = snapshot as CancellationReason;
          this.isNew = false;
          this.addOrEdit = 'Edit';
        });
      }
    });
  }

  ngAfterViewInit() {
  }

  updateCancellationReason() {
    if (this.isNew) {
      this.cancellationReasonService.addCancellationReason(this.editedCancellationReason).subscribe(() => {
        this.catalogueUpdatesService.refreshRequired = true;
        this.catalogueUpdatesService.catalogueUpdateComplete();
        this.router.navigate(['/management/organization/clinics/cancellation-reason', { outlets: { 'action-panel': null } }]);
      });
    } else {
      this.cancellationReasonService.updateCancellationReason(this.editedCancellationReason).subscribe(() => {
        this.catalogueUpdatesService.refreshRequired = true;
        this.catalogueUpdatesService.catalogueUpdateComplete();
        this.router.navigate(['/management/organization/clinics/cancellation-reason', { outlets: { 'action-panel': null } }]);
      });
    }
  }

  cancelUpdate() {
    this.catalogueUpdatesService.refreshRequired = false;
    this.catalogueUpdatesService.catalogueUpdateComplete();
    this.router.navigate(['/management/organization/clinics/cancellation-reason', { outlets: { 'action-panel': null } }]);
  }

  initCancellationReason(reason: CancellationReason) {
    reason = {
      cancellationReasonId: 0,
      name: '',
    };
    return reason;
  }
}
