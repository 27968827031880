import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentMethodsService } from '@services/payment-methods.service';
import { takeUntil } from 'rxjs/operators';
import { PaymentMethod } from '@models/finance/payment-method';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';

@Component({
  selector: 'app-edit-payment-method',
  templateUrl: './edit-payment-method.component.html',
  styleUrls: ['./edit-payment-method.component.less'],
})
export class EditPaymentMethodComponent implements OnInit, OnDestroy {
  unsub: Subject<void> = new Subject<void>();
  paymentMethodId: number;
  paymentMethodForm: FormGroup;
  actionType: String;
  isNewPaymentMethod: boolean;

  constructor(
    private catalogueUpdatesService: CatalogueUpdatesService,
    private paymentMethodsService: PaymentMethodsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      const id = params['id'];
      if (id && id === '_') {
        this.actionType = 'Add';
        this.isNewPaymentMethod = true;
        this.paymentMethodId = 0;
      } else {
        this.paymentMethodId = Number(id);
        this.actionType = 'Update';
        this.isNewPaymentMethod = false;
      }
      this.initForm();
    });
  }

  initForm() {
    this.paymentMethodForm = new FormGroup({
      id: new FormControl(this.paymentMethodId),
      name: new FormControl('', Validators.required),
    });

    if (!this.isNewPaymentMethod) {
      this.paymentMethodsService
        .getPaymentMethodById(this.paymentMethodId)
        .pipe(takeUntil(this.unsub))
        .subscribe((paymentMethod: PaymentMethod) => {
          this.paymentMethodForm.controls['name'].setValue(paymentMethod.name);
        });
    }
  }

  onSubmit() {
    if (this.isNewPaymentMethod) {
      this.paymentMethodsService
        .createPaymentMethod(this.paymentMethodForm.value)
        .pipe(takeUntil(this.unsub))
        .subscribe(() => {
          this.paymentMethodsService.paymentMethodsUpdatedSource.next(true);
          this.router.navigate([
            this.router.routerState.snapshot.url.split('(')[0],
            { outlets: { 'action-panel': null } },
          ]);
        });
    } else {
      this.paymentMethodsService
        .updatePaymentMethod(this.paymentMethodForm.value)
        .pipe(takeUntil(this.unsub))
        .subscribe(() => {
          this.paymentMethodsService.paymentMethodsUpdatedSource.next(true);
          this.router.navigate([
            this.router.routerState.snapshot.url.split('(')[0],
            { outlets: { 'action-panel': null } },
          ]);
        });
    }
  }

  cancelUpdate() {
    this.paymentMethodsService.paymentMethodsUpdatedSource.next(true);
    this.router.navigate([this.router.routerState.snapshot.url.split('(')[0], { outlets: { 'action-panel': null } }]);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
