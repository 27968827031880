<ngx-loading [show]="loading"></ngx-loading>
<kendo-grid
  class="emr-kendo-grid h-100"
  [data]="gridView"
  [groupable]="{ showFooter: true, enabled: false }"
  [pageable]="false"
  [pageSize]="gridState.take"
  [skip]="gridState.skip"
  [group]="gridState.group"
  [style.height.%]="100"
>
  <kendo-grid-column field="name" title="Service" [width]="180"> </kendo-grid-column>
  <kendo-grid-column field="clinicSupplyName" title="Clinic Supply" [width]="180"> </kendo-grid-column>
  <kendo-grid-column-group *ngFor="let user of distinctUsers" title="{{ user.name }}" [headerClass]="'group-header'">
    <kendo-grid-column
      title="Sales"
      [width]="160"
      [class]="'user-left'"
      [headerClass]="'user-left-header'"
      [footerClass]="'user-left'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ getSales(dataItem, user) }}
      </ng-template>
      <!--
        <ng-template kendoGridGroupFooterTemplate let-aggregates="aggregates">
          <div class="agg">Sub Total: {{ getAggregates(dataItem, user) }}</div>
        </ng-template>
        <ng-template kendoGridFooterTemplate>
          <div class="text-right">Total: {{ totalAggregates[user.id].sum | currency }}</div>
        </ng-template>
    -->
    </kendo-grid-column>
    <kendo-grid-column
      title="Rate"
      [width]="90"
      [class]="'user-center'"
      [headerClass]="'user-center-header'"
      [footerClass]="'user-center'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ getCommission(dataItem, user) }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      title="Due"
      format="{0:c}"
      [width]="160"
      [class]="'user-right'"
      [headerClass]="'user-right-header'"
      [footerClass]="'user-right'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ getDue(dataItem, user) }}
      </ng-template>
      <ng-template kendoGridGroupFooterTemplate let-group="group">
        <div class="agg">Sub Total: {{ getSubTotal(group, user) | currency }}</div>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right">Total: {{ getTotal(user) | currency }}</div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column field="clinicSupplyTypeName" [hidden]="true" [width]="160">
    <ng-template kendoGridGroupHeaderTemplate let-group>
      {{ group.value ? group.value : 'Other' }}
    </ng-template>
  </kendo-grid-column>
  <!-- Takes up extra space when view width < grid width -->
  <kendo-grid-column [class]="'user-left'" [footerClass]="'user-left'"> </kendo-grid-column>
</kendo-grid>
