import * as moment from 'moment';
import { TreatmentPlan } from './treatment-plan';
import { ClinicProduct } from '../clinic-product';

export class ProductRecommendation {
  public id: number;
  public treatmentPlanId: number;
  public createdDate: moment.Moment;
  public usageInstructions: string;
  public quantity: number;
  public retailPrice: number;
  public overrideRetailPrice: number;
  public clinicProductId: number;
  public clinicProduct: ClinicProduct;

  public constructor(init?: Partial<ProductRecommendation>) {
    Object.assign(this, init);
  }
}
