<div
  class="forms-library-container d-flex flex-column h-100 z-index-3 position-fixed white-background"
  cssTransitionEnd
  (transitionEnded)="formLibraryOpen($event)"
  [ngClass]="{ 'w-0': !initialized, 'w-100': initialized }"
>
  <div class="forms-library-links" kendoTooltip tooltipClass="em-tooltip" position="top">
    <div class="re-links">
      <button (click)="closeFormLibrary()" class="btn em-btn close-panel">
        <i class="far fa-times" title="Close Panel"></i>
      </button>
    </div>
  </div>

  <div class="forms-library-header row m-0 grey-three-background" kendoTooltip tooltipClass="em-tooltip" position="top">
    <ng-container>
      <div class="title-row d-flex text-nowrap pl-3 py-1">
        <h6 class="text-uppercase"><i class="far fa-folder-open"></i>&nbsp;&nbsp;Form Template Library</h6>
      </div>
    </ng-container>
  </div>

  <!-- Nav Tabs -->
  <nav class="forms-library-tabs tabs-no-pagination" mat-tab-nav-bar>
    <a
      *ngFor="let formType of formTypes"
      mat-tab-link
      [active]="activeTab === formType"
      style="border-left: none"
      (click)="selectTab(formType)"
    >
      {{ formType.name }}</a
    >
  </nav>

  <div class="content row" kendoTooltip tooltipClass="em-tooltip" position="top">
    <!-- Left Column - Grid -->
    <div class="col-3">
      <perfect-scrollbar appDetermineMaxScrollHeight [itemsToWatch]="[forms]">
        <ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>
        <kendo-grid
          [data]="gridView"
          [pageSize]="gridState.take"
          [skip]="gridState.skip"
          [sort]="gridState.sort"
          [sortable]="true"
          [selectable]="true"
          [style.height.%]="100"
          (selectionChange)="onSelectRow($event)"
          kendoGridSelectBy="id"
          [selectedKeys]="selectedKeys"
        >
          <kendo-grid-column field="name" title="Name" [headerClass]="'teal-background white-font'">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem.name }}
              <div *ngIf="dataItem.notes && dataItem.notes !== ''">
                <span> (<strong>Notes:</strong>{{ dataItem.notes }}) </span>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            field="serviceTemplateTypeName"
            title="Service"
            [headerClass]="'teal-background white-font'"
            min-width="100px"
            [width]="125"
          >
          </kendo-grid-column>
          <ng-template kendoGridDetailTemplate let-dataItem>
            <section>
              <div>
                <strong>Shared by User:</strong>
                {{
                  dataItem.sharedByUser
                    ? dataItem.sharedByUser.firstName + ' ' + dataItem.sharedByUser.lastName
                    : 'Anonymous'
                }}
              </div>
              <div>
                <strong>Shared by Clinic:</strong>
                {{ dataItem.sharedByClinic ? dataItem.sharedByClinic.name : 'Anonymous' }}
              </div>
              <div><strong>Shared Date:</strong> {{ dataItem.createdDate | date : 'yyyy-MM-dd' }}</div>
            </section>
          </ng-template>
          <kendo-grid-command-column min-width="50px" [width]="50" [headerClass]="'teal-background white-font'">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
              <div>
                <button
                  class="k-button"
                  (click)="addFromLibraryHandler(dataItem.id)"
                  kendoTooltip
                  tooltipClass="em-tooltip"
                  position="top"
                  title="Add From Template Library"
                >
                  <i class="far fa-cloud-download-alt"></i>
                </button>
              </div>
            </ng-template>
          </kendo-grid-command-column>
        </kendo-grid>
      </perfect-scrollbar>
    </div>
    <!-- Right Column - Viewer -->
    <div class="col-9">
      <perfect-scrollbar appDetermineMaxScrollHeight>
        <div
          class="w-100 h-100 align-items-start justify-content-center px-0 light-grey-background"
          style="height: 800px !important"
        >
          <div *ngIf="formSelected">
            <div class="card">
              <div class="card-body">
                <div class="card bg-light p-3">
                  <app-form-viewer
                    [form]="this.selectedDataItem"
                    [editable]="false"
                  ></app-form-viewer>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="!formSelected"
            frameBorder="0"
            class="w-100 h-100 d-flex align-items-start justify-content-center px-0 pb-2 light-grey-background"
          >
            <span class="m-auto font-weight-bold" style="font-size: 2em">Please select a form.</span>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>
