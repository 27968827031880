<!-- In the future we will silently reconnect - this will help with debugging any issues in the meantime -->
<div class="signalr-disconnect-message d-flex text-center align-items-center justify-content-center" *ngIf="show_signalr_disconnect_message">
  <span>Signalr ({{show_signalr_disconnect_message}}) has encountered a problem and is unable to recieve live updates. Please refresh the page...</span>
  <button
    type="button"
    title="Refresh Page"
    class="btn btn-sm em-btn em-btn-green btn-clean mx-2"
    (click)="refreshPage()"
  >
    <i class="fas fa-redo-alt"></i>
  </button>
  <button
    type="button"
    title="Refresh Page"
    class="btn btn-sm em-btn em-btn-green btn-clean mr-2 "
    (click)="show_signalr_disconnect_message = null"
  >
    <i class="fas fa-times"></i>
  </button>
</div>
<router-outlet class="h-100"></router-outlet>
