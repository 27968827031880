<mat-form-field>
  <mat-select
    placeholder="Title"
    [ngModel]="titleValue"
    (ngModelChange)="change($event)"
    data-test-id="proTitle"
    name="Title">
    <mat-option *ngFor="let title of titles" [value]="title">
      {{ title }}
    </mat-option>
  </mat-select>
</mat-form-field>