import { Injectable } from '@angular/core';
import { EformConfig } from '@app/eform.config';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ClinicDocument } from '@models/document/clinic-document';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class FormIoApiService {

  config = EformConfig;

  constructor(private http: HttpClient) {
  }

  login() {
    // To get the x-file-token login to get the x-jwt-token then do a PUT to the config.liveEndpoint and get the token from response.settings.filetoken
    const data = { data : { email: this.config.account.email, password: this.config.account.password } };
    this.http.post<any>(this.config.formIoUrl + '/user/login', data, {observe: 'response'}).subscribe(resp => {
      const token = resp.headers.get('x-jwt-token');
      this.http.get(this.config.formIoUrl + '/project/' + this.config.projectId, { headers: { 'x-jwt-token': token } }).subscribe();
    });
  }

  uploadPdf(clinicDocument: ClinicDocument): Observable<string> {
    const url = this.config.filesUrl + '/pdf/' + this.config.projectId + '/file';

    const formData = new FormData();
    formData.append('file', clinicDocument.file, clinicDocument.filePath);

    const options = {
      headers: {
        'x-file-token': this.config.xFileToken
      }
    };

    return this.http.post<any>(`${url}`, formData, options).pipe(map(resp => {
      return this.config.filesUrl + resp.path + '.html';
    }));
  }

  deletePdf(clinicDocument: ClinicDocument) {
    const url =  clinicDocument.pdfToHtmlUrl.replace('.html', '');
    const options = {
      headers: {
        'x-file-token': this.config.xFileToken
      }
    };

    return this.http.delete(`${url}`, options);
  }

}
