import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ClinicsService } from '@services/clinics.service';
import { staffBlockedScheduleCacheBuster$ } from '@services/service-provider.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../auth.service';
export class PasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-auth-component',
  templateUrl: './auth-component.component.html',
  styleUrls: ['./auth-component.component.less'],
})
export class AuthComponent implements OnInit, OnDestroy {
  loading = false;
  unsub = new Subject<any>();
  userNameFormControl = new FormControl('', [Validators.required]);
  passwordFormControl = new FormControl('', [Validators.required]);
  matcher = new PasswordErrorStateMatcher();
  validationErrorMessage = '';
  validationError = false;
  clinicLogoUrl = '';

  constructor(private authService: AuthService, private clinicsService: ClinicsService) {}

  ngOnInit() {
    this.clinicsService.getLoginClinicLogo().subscribe((logoUrl) => (this.clinicLogoUrl = logoUrl));
  }

  @HostListener('document:keydown', ['$event'])
  updateModalAnnouncementState(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.login();
    }
  }

  login() {
    this.loading = true;
    this.validationError = false;

    const password = this.passwordFormControl.value;
    const userName = this.userNameFormControl.value;

    this.authService
      .login(userName, password)
      .pipe(takeUntil(this.unsub))
      .subscribe(
        () => {
          staffBlockedScheduleCacheBuster$.next();
          this.loading = false;
        },
        (error: HttpErrorResponse) => {
          this.showError(error);
          this.loading = false;
        }
      );
  }

  showError(err: HttpErrorResponse) {
    this.validationError = true;
    if (err && err.status === 401) {
      this.validationErrorMessage = 'Your username/password combination seems to be wrong. Please try again.';
    } else {
      this.validationErrorMessage = 'Something has gone wrong with the magical cloud. Please try again.';
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
