<div class="modal-header border-0 px-2 py-1">
  <button type="button" class="btn white-font p-0 ml-auto" aria-label="Close" (click)="closeModal()">
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="p-4 d-flex flex-column align-items-center">
  <h6 class="mt-0 mb-3 navy-font">QUICK ADD PHARMACY</h6>
  <form [formGroup]="pharmacyForm" class="w-100 mb-2">
    <div class="navy-font d-flex flex-column justify-content-center font-weight-bold form-field">
      <span>Pharmacy Name: <span class="text-danger">*</span></span>
      <div class="flex-grow-1">
        <mat-form-field
          [floatLabel]="'never'"
          data-test-id="quickAddPharmacyName"
          class="w-100">
          <input
            matInput
            (input)="checkForDuplicates()"
            formControlName="name">
          <mat-error *ngIf="nameControl.hasError('required')">
            Please enter the pharmacy name.
          </mat-error>
        </mat-form-field>
      </div>
      <span>Pharmacy Fax #: <span class="text-danger">*</span></span>
      <div class="flex-grow-1">
        <mat-form-field
          [floatLabel]="'never'"
          class="w-100">
          <input
            matInput
            data-test-id="quickAddPharmacyFax"
            (change)="formatterService.formatPhoneNumber(faxControl);"
            (input)="checkForDuplicates()"
            formControlName="fax">
          <mat-error *ngIf="faxControl.hasError('required')">
            Please enter the pharmacy fax number.
          </mat-error>
          <mat-error *ngIf="faxControl.hasError('phoneError') &&
                            !faxControl.hasError('required')">
            Please enter a valid fax number.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="container" *ngIf="possibleDuplicates?.length > 0">
    <div class="row">
        <span class="navy-font font-weight-bold">Possible duplicates:</span>
        <ul>
          <li *ngFor="let pharm of possibleDuplicates">
            {{pharm.name}} {{pharm.faxNumber ? '(' + pharm.faxNumber +')' : ''}}
          </li>
        </ul>
    </div>
  </div>
  <div class="text-center">
    <button
      type="button"
      class="btn em-btn em-btn-green form-button"
      [disabled]="!pharmacyForm.valid"
      (click)="pharmacyFormSubmit()">
        Add
    </button>
    <button
      type="button"
      class="btn em-btn em-btn-green form-button ml-2"
      (click)="closeModal()">
        Cancel
    </button>
  </div>
</div>
    