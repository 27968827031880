<ngx-loading [show]="loading"></ngx-loading>
<div class="h-100 d-flex flex-column px-3">
  <div class="d-flex align-items-center w-100 title-header flex-shrink-0">
    <div class="column-header py-0 mr-2">Memberships</div>
  </div>
  <ng-scrollbar [autoHeightDisabled]="false" class="flex-grow-1">
    <mat-accordion [multi]="true" *ngIf="patientMemberships?.length > 0; else noMemberships">
      <mat-expansion-panel
        *ngFor="let patientMembership of patientMemberships; let last = last"
        [ngClass]="{ 'pb-1': !last }"
        [expanded]="false"
        #panelItem
      >
        <mat-expansion-panel-header class="px-0 py-0" [collapsedHeight]="'42px'" [expandedHeight]="'42px'">
          <mat-panel-title class="w-100">
            <div class="d-flex panel-box panel-box--small align-items-center expand">
              <div class="panel-box-column panel-box-column--main">
                <div class="text-truncate" [title]="patientMembership.membership.name">
                  {{ patientMembership.membership.name }}
                </div>
              </div>
              <div
                *ngIf="patientMembership.cancelled && !patientMembership.expired"
                class="panel-box-column text-uppercase"
              >
                Cancelled
              </div>
              <div *ngIf="patientMembership.expired" class="panel-box-column text-uppercase calendar-red-font">
                Expired
              </div>
              <div class="panel-box-column">
                {{ patientMembership.membership.amount | currency }}
              </div>
              <div class="panel-box-column">
                <button
                  title="Show Details"
                  type="button"
                  class="btn btn--smaller p-0 d-flex justify-content-center align-items-center"
                >
                  <mat-icon>{{ panelItem.expanded ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
                </button>
              </div>
            </div>
            <ng-container *appAuthGuard="cancelPolicy">
              <button
                *ngIf="allowCancellation && !patientMembership.cancelled; else cancelledPlaceholder"
                title="Cancel Membership"
                type="button"
                class="panel-box-button em-btn-green"
                (click)="$event.stopPropagation(); cancelMembership(patientMembership)"
              >
                <i class="far fa-ban"></i>
              </button>
            </ng-container>
            <ng-template #cancelledPlaceholder>
              <div class="panel-box-button grey-two-background"></div>
            </ng-template>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="p-2 mx-2">
          <div>
            <span class="navy-font">First Payment:</span>
            <span class="float-right">{{ patientMembership.firstPaymentDate | date }}</span>
          </div>
          <div>
            <span class="navy-font">Last Payment:</span>
            <span class="float-right">{{ patientMembership.lastPaymentDate | date }}</span>
          </div>
          <div *ngIf="!patientMembership.cancelled">
            <span class="navy-font">Next Payment:</span>
            <span class="float-right">{{ patientMembership.nextPaymentDate | date }}</span>
          </div>
          <div>
            <span class="navy-font">Expiration:</span>
            <span class="float-right">{{
              patientMembership.membership.expiresAfter ? (patientMembership.expirationDate | date) : 'Never'
            }}</span>
          </div>
          <div *ngIf="!patientMembership.cancelled">
            <span class="navy-font">Cancellable After:</span>
            <span class="float-right">{{
              patientMembership.membership.cancellableAfter ? (patientMembership.cancellableDate | date) : 'Anytime'
            }}</span>
          </div>
          <div *ngIf="patientMembership.cancelled">
            <span class="navy-font">Cancelled On:</span>
            <span class="float-right">{{ patientMembership.dateCancelled | date }}</span>
          </div>
          <div *ngIf="patientMembership.cancelled">
            <span class="navy-font">Cancelled By:</span>
            <span class="float-right">{{ getCancelledByUserName(patientMembership) }}</span>
          </div>
          <div>
            <span class="navy-font">Billing Cycle Redemptions:</span>
            <span class="float-right">
              {{ patientMembership.cycleRedemptions }} of
              {{
                patientMembership.membership.allowableRedemptions > 0
                  ? patientMembership.membership.allowableRedemptions
                  : '∞'
              }}
            </span>
          </div>
          <div>
            <span class="navy-font">Percentage Applied To Credit:</span>
            <span class="float-right">
              {{
                patientMembership.membership.percentageAppliedToCredit
                  ? patientMembership.membership.percentageAppliedToCredit + '%'
                  : 'None'
              }}
            </span>
          </div>
          <ng-container *ngIf="patientMembership.membership?.membershipServices?.length > 0">
            <div class="navy-font">Service Discounts:</div>
            <ul class="mb-1">
              <li *ngFor="let membershipService of patientMembership.membership?.membershipServices; let i = index">
                <span class="mr-2">{{ membershipService.clinicServiceTemplate.serviceName }}</span>
                <span class="float-right">{{
                  membershipService.discountType === DiscountType.Dollars
                    ? (membershipService.amount | currency)
                    : membershipService.amount + '%'
                }}</span>
              </li>
            </ul>
          </ng-container>
          <ng-container *ngIf="patientMembership.membership?.membershipProducts?.length > 0">
            <div class="navy-font">Product Discounts:</div>
            <ul class="mb-1">
              <li *ngFor="let membershipProduct of patientMembership.membership?.membershipProducts; let i = index">
                <span class="mr-2">{{ membershipProduct.clinicProduct.displayName }}</span>
                <span class="float-right">{{
                  membershipProduct.discountType === DiscountType.Dollars
                    ? (membershipProduct.amount | currency)
                    : membershipProduct.amount + '%'
                }}</span>
              </li>
            </ul>
          </ng-container>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-scrollbar>

  <ng-template #noMemberships>
    <div>This patient hasn't purchased any memberships yet.</div>
  </ng-template>
</div>
