import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  AfterViewInit,
  AfterViewChecked,
  OnChanges,
  DoCheck,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { FormioAppConfig, FormioComponent } from 'angular-formio-emilyemr';
import { takeUntil } from 'rxjs/operators';
import { PatientDocumentService } from '@services/patient-documents.service';
import { PatientDocument } from '@models/document/patient-document';
import { isNullOrUndefined } from '@app/shared/helpers';

@Component({
  selector: 'app-eform-print',
  templateUrl: './eform-print.component.html',
  styleUrls: ['./eform-print.component.less'],
})
export class EformPrintComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('formView') formView: FormioComponent;
  docId: number;
  patientId: number;
  formDefinition: Object;
  formData: string;
  public form: any;
  unsub: Subject<void> = new Subject<void>();
  public submission: any = {};
  isLoadedSubmission = false;

  constructor(private route: ActivatedRoute, private patientDocService: PatientDocumentService) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      this.docId = +params['docId'];
      this.patientId = +params['patientId'];
    });
  }

  ngAfterViewInit(): void {
    this.patientDocService.getPatientClinicDocument(this.docId).subscribe((doc) => {
      this.initForm(doc);
    });
  }

  onChange(event: any) {
    if (this.isLoadedSubmission && !isNullOrUndefined(event.changed)) {
    }
  }

  onReady(event: any) {
    this.isLoadedSubmission = true;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  private initForm(doc: PatientDocument) {
    this.form = JSON.parse(doc.eFormDefinition);
    this.form.settings.pdf.src = doc.pdfToHtmlUrl + '&zoom=1';
    this.formData = JSON.parse(doc.eFormData);
    this.submission = { data: this.formData };
  }
}
