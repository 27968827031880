import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import {
  Calendar,
  CalendarOptions,
  DateSelectArg,
  EventClickArg,
  EventDropArg,
  EventHoveringArg,
  EventInput,
} from '@fullcalendar/core';
import interactionPlugin, { EventDragStartArg, EventResizeDoneArg } from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import { ResourceInput, ResourceLabelMountArg } from '@fullcalendar/resource';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Appointment, AppointmentType } from '@models/appointments/appointment';
import { Clinic } from '@models/clinic';
import { ServiceProvider } from '@models/service-provider';
import { AppointmentService } from '@services/appointments.service';
import { CurrentDataService } from '@services/currentData.service';
import { EventsService, ScheduleMode, ScheduleView } from '@services/events.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-fc-schedule-wrapper',
  templateUrl: './fc-schedule-wrapper.component.html',
  styleUrls: ['./fc-schedule-wrapper.component.less'],
})
export class FcScheduleWrapperComponent implements OnInit, OnDestroy {
  @ViewChild(FullCalendarComponent) calendarComponent: FullCalendarComponent;
  @Output() timeSlotSelected = new EventEmitter<DateSelectArg>();
  @Output() resourceSelected = new EventEmitter<string>();
  @Output() eventDropped = new EventEmitter<EventDropArg>();
  @Output() eventDragStarted = new EventEmitter<EventDragStartArg>();
  @Output() eventResized = new EventEmitter<EventResizeDoneArg>();
  @Output() eventHovered = new EventEmitter<EventHoveringArg>();
  @Output() eventClicked = new EventEmitter<EventClickArg>();
  @Output() rightClicked = new EventEmitter<MouseEvent>();
  @Input() loading = false;

  @Input() set appointments(appointments: Appointment[]) {
    this.setEvents(appointments);
  }

  @Input() set providers(providers: ServiceProvider[]) {
    this.resources = providers.map((provider) => {
      return {
        id: provider.id,
        title: provider.title,
        order: provider.order,
      };
    });
  }

  @Input() set date(date: Date) {
    this.calendar?.gotoDate(date);
  }

  private _editable = true;
  @Input() set editable(editable: boolean) {
    this._editable = editable;
    if (this.calendarOptions) {
      this.calendarOptions.editable = editable;
    }
  }
  get editable(): boolean {
    return this._editable;
  }

  private _clinic: Clinic;
  @Input() set clinic(clinic: Clinic) {
    this._clinic = clinic;
    this.setSlotDuration();
    this.setScheduleHours();
  }
  get clinic(): Clinic {
    return this._clinic;
  }

  private get calendar(): Calendar {
    return this.calendarComponent?.getApi();
  }

  get scheduleView(): ScheduleView {
    return this.eventsService.scheduleView;
  }

  get scheduleMode(): ScheduleMode {
    return this.eventsService.scheduleMode;
  }

  calendarOptions: CalendarOptions;
  resources: ResourceInput[] = [];
  events: EventInput[] = [];
  AppointmentType = AppointmentType;
  ScheduleView = ScheduleView;
  ScheduleMode = ScheduleMode;
  private appointmentTouches: any[] = [];
  private unsub = new Subject<void>();

  constructor(
    private eventsService: EventsService,
    private currentDataService: CurrentDataService,
    private appointmentService: AppointmentService
  ) {}

  ngOnInit(): void {
    this.initConfig();
    this.setSlotDuration();
    this.setScheduleHours();

    this.eventsService.actionPanelOpenListener.pipe(takeUntil(this.unsub)).subscribe(() => {
      // timeout is needed here to wait for action panel transition
      setTimeout(() => {
        this.calendar.updateSize();
        this.calendar.unselect();
      }, 400);
    });

    this.eventsService.closeSidePanel$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.calendar.unselect();
    });

    this.appointmentService.allApptsUpdated$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.calendar.unselect();
    });

    this.eventsService.scheduleViewChanged$.pipe(takeUntil(this.unsub)).subscribe((view: ScheduleView) => {
      this.eventsService.setScheduleMode(ScheduleMode.DayView);
    });

    this.eventsService.scheduleModeChangedListener.pipe(takeUntil(this.unsub)).subscribe((mode) => {
      this.setViewMode(mode);
    });
  }

  private initConfig() {
    this.calendarOptions = {
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      plugins: [resourceTimeGridPlugin, timeGridPlugin, momentPlugin, interactionPlugin, scrollGridPlugin],
      editable: this.editable,
      height: '100%',
      initialView: 'resourceTimeGridDay',
      defaultTimedEventDuration: '00:00:00',
      slotLabelInterval: '01:00:00',
      slotLabelFormat: 'h A',
      dayMinWidth: 315,
      resourceOrder: 'order',
      nowIndicator: true,
      slotEventOverlap: false,
      selectable: true,
      selectOverlap: true,
      unselectAuto: false,
      allDaySlot: false,
      longPressDelay: 500,
      headerToolbar: {
        left: 'prev next',
        center: '',
        right: '',
      },
      views: {
        resourceTimeGridWeek: {
          duration: { weeks: 1 },
          hiddenDays: [],
        },
        resourceTimeGridDay: {},
      },
      navLinks: true,
      navLinkDayClick: this.onNavLinkDayClick,
      select: this.onSelect,
      eventDrop: this.onEventDrop,
      eventDragStart: this.onEventDragStart,
      eventResize: this.onEventResize,
      resourceLabelDidMount: this.onResourceLabelDidMount,
    };
  }

  private setEvents(appointments: Appointment[]) {
    this.events = appointments
      .map((appointment) => ({
        id: appointment.appointmentId?.toString(),
        allDay: appointment.allDay,
        backgroundColor: appointment.backgroundColor,
        borderColor: appointment.borderColor,
        className: appointment.className,
        color: appointment.color,
        constraint: appointment.constraint,
        date: appointment.date,
        display: appointment.rendering,
        editable: this.editable && appointment.editable,
        end: appointment.end,
        extendedProps: { appointment: appointment },
        overlap: appointment.overlap,
        resourceId: appointment.resourceId,
        start: appointment.start,
        textColor: appointment.textColor,
        title: appointment.title,
      }))
      .map((event) => {
        if (event.extendedProps.appointment.appointmentType === AppointmentType.Staff) {
          event['groupId'] = event.resourceId;
        }
        return event;
      });
    // If the provider is not working (no schedule appointments provided), background event.
    // This could eventually be refactored in to the backend.
    this.resources.forEach((resource) => {
      if (
        this.scheduleView !== ScheduleView.StaffSchedules &&
        !this.events.some(
          (event) =>
            event.extendedProps?.appointment?.appointmentType === AppointmentType.Staff &&
            event.resourceId === resource.id
        )
      ) {
        this.events.push({
          // id: resource.id,
          resourceId: resource.id,
          title: resource.title,
          display: 'inverse-background',
          backgroundColor: '#C9C9C9',
          start: new Date(),
          end: new Date(),
        });
      }
    });
  }

  // #region Schedule Callbacks

  private onNavLinkDayClick = (date: Date) => {
    this.eventsService.setScheduleMode(ScheduleMode.DayView);
    this.eventsService.selectedDate.next(date);
    this.scrollToNow();
  };

  private onSelect = (arg: DateSelectArg) => {
    this.timeSlotSelected.emit(arg);
  };

  private onEventDrop = (arg: EventDropArg) => {
    this.eventDropped.emit(arg);
  };

  private onEventDragStart = (arg: EventDragStartArg) => {
    this.eventDragStarted.emit(arg);
  };

  private onEventResize = (arg: EventResizeDoneArg) => {
    this.eventResized.emit(arg);
  };

  private onResourceLabelDidMount = (arg: ResourceLabelMountArg) => {
    if (this.scheduleMode === ScheduleMode.DayView) {
      arg.el.addEventListener('click', () => this.onResourceClick(arg.resource.id));
    }
    if (this.scheduleMode === ScheduleMode.WeekView) {
      arg.el.removeEventListener('click', () => this.onResourceClick(arg.resource.id));
    }
  };

  // #endregion

  // #region Appointment Callbacks

  onAppointmentHover = (htmlEvent: Event, arg: any) => {
    const hoverEvent: EventHoveringArg = {
      el: htmlEvent.target as HTMLElement,
      event: arg.event,
      jsEvent: htmlEvent as MouseEvent,
      view: arg.view,
    };
    this.eventHovered.emit(hoverEvent);
  };

  onAppointmentClick = (htmlEvent: Event, arg: any) => {
    const clickEvent: EventClickArg = {
      el: htmlEvent.target as HTMLElement,
      event: arg.event,
      jsEvent: htmlEvent as MouseEvent,
      view: arg.view,
    };
    this.eventClicked.emit(clickEvent);
  };

  // #endregion

  // #region Event Handlers

  // onTouchStart(event: TouchEvent) {
  //   const target = event?.target as HTMLElement;
  //   if (target.classList.contains('touch-fix')) {
  //     (target.closest('.fc-event') as any).focus();
  //     target.click();
  //     event.preventDefault();
  //   }
  // }

  onRightClick(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.rightClicked.emit(event);
  }

  private onResourceClick = (resourceId: string) => {
    if (this.scheduleMode === ScheduleMode.DayView) {
      this.resourceSelected.emit(resourceId);
    }
  };

  private setSlotDuration() {
    if (this.clinic && this.calendarOptions) {
      const slotDurationString = '00:' + this.clinic.minimumDuration.toString() + ':00';
      this.calendarOptions.slotDuration = slotDurationString;
    }
  }

  private setScheduleHours() {
    if (this.clinic && this.calendarOptions) {
      let minTime = '07:00:00';
      let maxTime = '17:00:00';
      const currentDate = this.currentDataService.currentDate;
      const currentDayHours = this.clinic.hoursOfOperation?.hoursOfOperationDays[currentDate.getDay()];
      if (currentDayHours && !currentDayHours.closed) {
        minTime = currentDayHours.openTime.subtract(currentDayHours.openTime.minutes(), 'minutes').format();
        maxTime = currentDayHours.closeTime.format();
      }
      this.calendarOptions.slotMinTime = minTime;
      this.calendarOptions.slotMaxTime = maxTime;

      const daysClosed = [];
      this.clinic.hoursOfOperation.hoursOfOperationDays.forEach((dayHours, i) => {
        if (dayHours.closed) {
          daysClosed.push(i);
        }
      });
      this.calendarOptions.views.resourceTimeGridWeek.hiddenDays = daysClosed;
    }
  }

  private setViewMode(mode: ScheduleMode) {
    this.calendar?.changeView(mode);
  }

  private addWeekViewForwardBackButtons() {
    const axisHeader = document.getElementsByClassName('fc-axis ui-widget-header');
    if (axisHeader.length) {
      const el = axisHeader[0];
      if (el.childNodes.length === 0) {
        const div = document.createElement('div');
        div.id = 'weekViewBtns';
        div.classList.add('d-flex', 'justify-content-center');
        const leftDiv = document.createElement('div');
        leftDiv.classList.add('btn', 'em-btn-green');
        leftDiv.style.cssText = 'padding: .2rem .4rem;';
        const rightDiv = document.createElement('div');
        rightDiv.classList.add('btn', 'em-btn-green');
        rightDiv.style.cssText = 'padding: .2rem .4rem; margin-left: 0.1rem;';
        const left = document.createElement('i');
        left.classList.add('fal', 'fa-chevron-left', 'white-font');
        const right = document.createElement('i');
        right.classList.add('fal', 'fa-chevron-right', 'white-font');
        leftDiv.onclick = () => this.weekViewBack();
        rightDiv.onclick = () => this.weekViewForward();
        leftDiv.appendChild(left);
        rightDiv.appendChild(right);
        div.appendChild(leftDiv);
        div.appendChild(rightDiv);
        el.appendChild(div);
      }
    }
  }

  private weekViewForward() {
    this.weekViewMove(true);
  }
  private weekViewBack() {
    this.weekViewMove(false);
  }

  private weekViewMove(isForward: boolean) {
    const currentData = this.currentDataService.currentDate;
    const newDate = new Date(currentData);
    newDate.setDate(newDate.getDate() + 7 * (isForward ? 1 : -1));
    this.currentDataService.currentDate = new Date(newDate);
    this.eventsService.setSelectedDate(new Date(newDate));
    this.eventsService.setScheduleMode(ScheduleMode.WeekView);
    this.calendar.gotoDate(this.currentDataService.currentDate);
  }

  // #endregion

  // #region Helper Methods

  scrollToNow() {
    const now = new Date();
    this.calendar.scrollToTime(now.getMilliseconds());
  }

  // #endregion

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }
}
