<div class="edit-container position-absolute d-block z-index-3 white-background" *ngIf="accessLocationForm">
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0;">
  <ng-container [formGroup]="accessLocationForm">
    <div class="row teal-background">
      <div class="col">
        <h6 class="white-font uppercase p-2 mb-0">
        {{addOrEdit}} Access Location
        </h6>
      </div>
    </div>
    <app-error *ngIf="errorMessage" [message]="errorMessage"></app-error>
    
    <div class="row mt-2 mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input
            matInput
            placeholder="Description"
            class="capitalized"
            formControlName="description"
            required
            autocomplete="off">
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input
            matInput
            placeholder="IP Address"
            class="capitalized"
            formControlName="ipAddress"
            required
            autocomplete="off">
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-2 mx-0">
      <div class="col px-2">
        <mat-form-field>
          <mat-select placeholder="State" name="state" formControlName="isActive" required>
            <mat-option *ngFor="let state of stateChoices" [value]="state.value">
              {{ state.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row mx-0 mt-2">
      <div class="pt-2 pb-3 px-2">
        Click <a href="https://www.whatismyip.com/" target="_blank">here</a> to find your IPv4 address and copy it into the IP Address field above.
      </div>
      <div class="col px-2 text-center">
        <button
          [disabled]="!accessLocationForm.valid"
          class="btn em-btn em-btn-green"
          (click)="updateAccessLocation()">
          {{isNew ? 'Add' : 'Update'}}
        </button>
      </div>
      <div class="col px-2 text-center">
        <button class="btn em-btn em-btn-green" (click)="cancelUpdate()">Cancel</button>
      </div>
    </div>
  </ng-container>
  </perfect-scrollbar>
</div>
    