<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>

<div class="d-flex h-100 pb-2">
  <kendo-grid
    class="ml-1"
    [data]="gridView"
    [pageSize]="gridState.take"
    [skip]="gridState.skip"
    [sort]="gridState.sort"
    [sortable]="{
      allowUnsort: true,
      mode: 'multiple'
    }"
    [selectable]="{
      mode: 'single'
    }"
    kendoGridSelectBy="id"
    [selectedKeys]="selectedPhotoIds"
    [style.height.%]="100"
    (sortChange)="sortChange($event)"
    (add)="onAddClick($event)"
    (remove)="removeHandler($event)"
    (selectionChange)="selectionChange($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <div class="d-flex">
        <button kendoGridAddCommand class="p-2 btn em-btn"><i class="fal fa-plus-circle"></i> Add Photo</button>
      </div>
    </ng-template>
    <kendo-grid-column field="imageName" title="Name" [width]="110"></kendo-grid-column>
    <kendo-grid-column field="notes" title="Notes"></kendo-grid-column>
    <kendo-grid-column field="uploadDate" title="Upload Date" [width]="120">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.uploadDate | date : 'yyyy-MM-dd h:mm:ss a' }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="Actions" [width]="80">
      <ng-template kendoGridCellTemplate>
        <button kendoGridRemoveCommand class="btn kendo-btn"><i class="fas fa-minus-circle"></i></button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
  <div class="ml-2 img-container">
    <img *ngIf="selectedPhoto" class="p-2 img-photo" [src]="selectedPhoto.filePath" [alt]="selectedPhoto.imageName" />
    <div *ngIf="!selectedPhoto" class="d-flex h-100 w-100 align-items-center justify-content-center">
      <span class="text-center">No clinic photos yet.</span>
    </div>
  </div>
</div>

<p-overlayPanel #hoverPanel id="hoverPanel" [dismissable]="false"> Content </p-overlayPanel>
<app-actionpanel></app-actionpanel>
