<app-select-clinic class="main-clinic-select" (changeClinic)="loadClinic($event)"></app-select-clinic>
<div class="btn-group d-flex align-items-center flex-wrap grey-seven-background px-4 py-3">
  <button
    type="button"
    class="btn btn--arrow em-btn medium em-btn-nav-tab mr-1"
    [class.selected]="activeDateFilter === filterRanges.Today || activeDateFilter === filterRanges.Picker"
    [disabled]="activeDateFilter !== filterRanges.Today && activeDateFilter !== filterRanges.Picker"
    (click)="incrementDayBy(-1)"
  >
    <i class="fal fa-chevron-left white-font small"></i>
  </button>
  <button
    type="button"
    class="btn medium em-btn em-btn-nav-tab mr-1 flex-nowrap"
    [class.selected]="activeDateFilter === filterRanges.Today"
    (click)="filterByPresetRange(filterRanges.Today)"
  >
    TODAY
  </button>
  <button
    type="button"
    class="btn btn--arrow em-btn medium em-btn-nav-tab mr-4"
    [class.selected]="activeDateFilter === filterRanges.Today || activeDateFilter === filterRanges.Picker"
    [disabled]="activeDateFilter !== filterRanges.Today && activeDateFilter !== filterRanges.Picker"
    (click)="incrementDayBy(1)"
  >
    <i class="fal fa-chevron-right white-font small"></i>
  </button>
  <button
    type="button"
    class="btn medium em-btn em-btn-nav-tab mr-4 flex-nowrap"
    [class.selected]="activeDateFilter === filterRanges.Week"
    (click)="filterByPresetRange(filterRanges.Week)"
  >
    THIS WEEK
  </button>
  <button
    type="button"
    class="btn medium em-btn em-btn-nav-tab mr-4 flex-nowrap"
    [class.selected]="activeDateFilter === filterRanges.Month"
    (click)="filterByPresetRange(filterRanges.Month)"
  >
    THIS MONTH
  </button>
  <button
    type="button"
    class="btn medium em-btn em-btn-nav-tab mr-4 flex-nowrap"
    [class.selected]="activeDateFilter === filterRanges.LastMonth"
    (click)="filterByPresetRange(filterRanges.LastMonth)"
  >
    LAST MONTH
  </button>
  <button
    type="button"
    class="btn medium em-btn em-btn-nav-tab mr-4 flex-nowrap"
    [class.selected]="activeDateFilter === filterRanges.ThreeMonths"
    (click)="filterByPresetRange(filterRanges.ThreeMonths)"
  >
    THREE MONTHS
  </button>
  <button
    type="button"
    class="btn medium em-btn em-btn-nav-tab mr-4 flex-nowrap"
    [class.selected]="activeDateFilter === filterRanges.YearToDate"
    (click)="filterByPresetRange(filterRanges.YearToDate)"
  >
    YTD
  </button>
  <div>
    <mat-form-field floatLabel="always" class="mr-4 filter-date">
      <mat-label>Date Range</mat-label>
      <input
        matInput
        placeholder="Select"
        [satDatepicker]="picker"
        [formControl]="dateRange"
        (click)="activeDateFilter = filterRanges.Picker"
      />
      <sat-datepicker #picker [rangeMode]="true" (opened)="activeDateFilter = filterRanges.Picker"> </sat-datepicker>
      <sat-datepicker-toggle [class.selectedPicker]="activeDateFilter === filterRanges.Picker" matSuffix [for]="picker">
      </sat-datepicker-toggle>
    </mat-form-field>
  </div>
  <button type="button" class="btn medium em-btn em-btn-green lex-nowrap mr-4" (click)="clearFilter()">
    CLEAR FILTERS
  </button>
  <button type="button" class="btn medium em-btn em-btn-green flex-nowrap" (click)="exportToExcel()">
    Export Excel
  </button>
</div>

<div class="px-4 py-3 flex-grow-1 minh-0 d-flex flex-column">
  <div *ngIf="authorizedTabs.size > 1">
    <ng-container *ngFor="let tab of authorizedTabs | keyvalue">
      <button
        class="btn btn--tab text-uppercase em-btn-nav-tab mr-2"
        [class.selected]="activeTab === tab.key"
        (click)="activeTab = tab.key"
      >
        {{ tab.key | uppercase }}
      </button>
    </ng-container>
  </div>

  <app-clinic-reports class="flex-grow-1 minh-0" *ngIf="defaultInitialized && activeTab === 'Clinic'" #reportTab>
  </app-clinic-reports>

  <app-liabilities-report
    class="flex-grow-1 minh-0"
    *ngIf="defaultInitialized && activeTab === 'Liabilities'"
    #reportTab
  >
  </app-liabilities-report>

  <app-patient-reports class="flex-grow-1 minh-0" *ngIf="defaultInitialized && activeTab === 'Patients'" #reportTab>
  </app-patient-reports>

  <app-product-reports class="flex-grow-1 minh-0" *ngIf="defaultInitialized && activeTab === 'Products'" #reportTab>
  </app-product-reports>

  <app-service-reports class="flex-grow-1 minh-0" *ngIf="defaultInitialized && activeTab === 'Services'" #reportTab>
  </app-service-reports>

  <app-provider-reports class="flex-grow-1 minh-0" *ngIf="defaultInitialized && activeTab === 'Providers'" #reportTab>
  </app-provider-reports>

  <app-invoices-reports class="flex-grow-1 minh-0" *ngIf="defaultInitialized && activeTab === 'Invoices'" #reportTab>
  </app-invoices-reports>

  <app-supplies-reports
    class="flex-grow-1 minh-0"
    *ngIf="defaultInitialized && activeTab === 'Clinic Supplies'"
    #reportTab
  >
  </app-supplies-reports>

  <app-commissions-reports
    class="flex-grow-1 minh-0"
    *ngIf="defaultInitialized && activeTab === 'Commissions'"
    #reportTab
  >
  </app-commissions-reports>

  <app-memberships-reports
    class="flex-grow-1 minh-0"
    *ngIf="defaultInitialized && activeTab === 'Memberships'"
    #reportTab
  >
  </app-memberships-reports>
</div>

<app-actionpanel></app-actionpanel>
