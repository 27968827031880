import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.less'],
})
export class PanelComponent implements OnInit {
  @Input() isVisible: boolean;
  @Input() isHuge: boolean;
  @Input() isMedium: boolean;
  @Output() isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  close() {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }
}
