<ngx-loading [show]="loading"></ngx-loading>
<kendo-grid
  class="p-3 emr-kendo-grid communications h-100"
  [data]="gridView"
  [style.maxHeight.%]="100"
  [pageSize]="gridState.take"
  [pageable]="true"
  [skip]="gridState.skip"
  [sort]="gridState.sort"
  [sortable]="{
    allowUnsort: false,
    mode: 'single'
  }"
  (pageChange)="pageChange($event)"
  (sortChange)="sortChange($event)"
  #grid="kendoGrid"
>
  <kendo-grid-column field="category" title="Category" class="font-weight-bold" [sortable]="false"></kendo-grid-column>
  <kendo-grid-column field="type" title="Via">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <span
        class="font-weight-bold"
        [ngClass]="{
          'email-type': dataItem.type.toLowerCase() === 'email',
          'sms-type': dataItem.type.toLowerCase() === 'sms'
        }"
      >
        {{ dataItem.type }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="patientContact" title="Email/Mobile" [sortable]="false"></kendo-grid-column>
  <kendo-grid-column field="campaignTitle" title="Campaign Title"></kendo-grid-column>
  <kendo-grid-column field="date" title="Date Sent">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <div
        [attr.data-test-id]="'communication-timestamp'"
        [attr.data-test-value]="dataItem?.date | date : 'dd.MM.yyyy &nbsp; HH:mm:ss.SSS'"
      >
        {{ dataItem.date | date : 'yyyy-MM-dd h:mm a' }}
      </div>
    </ng-template>
  </kendo-grid-column>
  <!-- <kendo-grid-column field="subject" title="Subject" [sortable]="false"></kendo-grid-column> -->

  <kendo-grid-column field="status" title="Status" [sortable]="false"></kendo-grid-column>
  <kendo-grid-column field="confirmationDate" title="Date Confirmed" [sortable]="false">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{ dataItem.status == 'Confirmed' ? (dataItem.confirmationDate | date : 'yyyy-MM-dd h:mm a') : '' }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Details" [sortable]="false">
    <ng-template let-dataItem kendoGridCellTemplate>
      <button
        *ngIf="dataItem.category == 'Invoice' && dataItem.reason"
        type="button"
        class="btn-clean px-0 text-left underline"
        (click)="navigateToPatientInvoice(patientId, dataItem.reason)"
      >
        Invoice ID: {{ dataItem.reason }}
      </button>
      <button
        *ngIf="dataItem.category == 'Photo' && dataItem.reason"
        class="btn-clean px-0 text-left underline"
        (click)="openPhoto(dataItem.reason)"
      >
        Photo
      </button>
      <ng-container *ngIf="dataItem.category != 'Photo' && dataItem.category != 'Invoice'">
        {{ dataItem.reason }}
      </ng-container>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
