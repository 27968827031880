export enum NudgeStatuses {
  Deferred = 1,
  Active,
  Completed,
  Cancelled,
  Deleted,
  Overdue
}

export enum NudgeStatusesTitle {
  Deferred = 'Deferred',
  Active  = 'Active',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Deleted = 'Deleted',
  Overdue = 'Overdue'
}
