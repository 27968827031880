import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MergePatientsComponent } from '@app/patients/merge-patients/merge-patients.component';
import { PatientStatus } from '@models/patient-status.enum';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { MasterOverlayService } from '@services/actionpanel.service';
import { PatientService } from '@services/patient.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.less'],
})
export class PatientsListComponent implements OnInit, OnDestroy {
  loading = false;
  unsub: Subject<void> = new Subject<void>();
  errorMessage: string;
  searchValue = '';
  patientStatusData = PatientStatus;
  statusEnumKeys = [];
  gridView: GridDataResult;
  gridState: DataSourceRequestState = {
    sort: [
      {
        field: 'firstName',
        dir: 'asc',
      },
    ],
    skip: 0,
    take: 100,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  private baseUrl = '/patients-list';

  constructor(
    private patientsService: PatientService,
    private router: Router,
    private masterOverlayService: MasterOverlayService,
    private mergePatientDialog: MatDialog
  ) {
    this.statusEnumKeys = Object.keys(this.patientStatusData).filter((f) => !isNaN(Number(f)));
  }

  ngOnInit() {
    this.patientsService.patientsSourceUpdated$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.loading = true;
      this.refreshData();
    });

    this.gridView = {
      data: [],
      total: 0,
    };
    this.loading = true;
    this.refreshData();
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.gridState = state;
    this.refreshData();
  }

  search(searchValue: string) {
    this.searchValue = searchValue;
    this.gridState.skip = 0;
    this.gridState.take = 100;
    this.gridState.sort = [
      {
        field: 'firstName',
        dir: 'asc',
      },
    ];
    this.gridState.filter = {
      logic: 'and',
      filters: [],
    };
    this.refreshData();
  }

  refreshData() {
    this.loading = true;
    this.patientsService.getPatientsList(this.gridState, this.searchValue).subscribe((result) => {
      this.gridView = {
        data: result.data,
        total: result.total || result.data.length,
      };

      this.loading = false;
    });
  }

  onAddClick({ sender }) {
    this.patientsService.editPatientSourceURL = this.baseUrl;
    this.masterOverlayService.masterOverlayState(true);
    this.router.navigate([this.baseUrl, { outlets: { 'action-panel': ['edit-patient', '_'] } }]);
  }

  openPatientPanel(dataItem): void {
    this.patientsService.editPatientSourceURL = this.baseUrl;
    this.patientsService.patientPanelPatient = null;
    this.router.navigate([
      this.baseUrl,
      { outlets: { 'action-panel': ['patient', dataItem.patientId + '_patientprofiletab'] } },
    ]);
  }

  mergePatients() {
    const dialogRef = this.mergePatientDialog.open(MergePatientsComponent, {
      panelClass: 'custom-dialog-container',
      width: '1200px',
      height: 'calc(100dvh - 100px)',
      data: { patientId: null, duplicatePatientId: null },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === true) {
          this.refreshData();
        }
      });
  }

  allData = (): Observable<any> => {
    this.loading = true;
    return this.patientsService
      .getPatientsList(
        {
          sort: [
            {
              field: 'lastName',
              dir: 'asc',
            },
          ],
          skip: 0,
          take: 0,
          filter: {
            logic: 'and',
            filters: [],
          },
        },
        ''
      )
      .pipe(
        map((patients) => {
          setTimeout(() => {
            this.loading = false;
          });
          return {
            data: patients.data,
            total: patients.total || patients.data.length,
          };
        })
      );
  };

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }
}
