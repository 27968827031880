import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Injectable } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Tag } from '@models/tag/tag';
import { BehaviorSubject, Subject } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { PhotoFilter, TagCategory } from '@app/photos/photo-filter';
import { ITagTypes } from '@services/tag.service';
import { takeUntil } from 'rxjs/operators';

export class TagNode {
  title: string;
  children?: TagNode[];
  tag?: Tag;
  category?: TagCategory;
}

export class TagFlatNode {
  tag?: Tag;
  category?: TagCategory;
  title: string;
  level: number;
  expandable: boolean;
}

@Injectable()
export class TagDatabase {
  dataChange = new BehaviorSubject<TagNode[]>([]);

  get data(): TagNode[] {
    return this.dataChange.value;
  }

  constructor() {}

  initialize(tags: ITagTypes) {
    // Build the tree from ITagTypes interface
    const data = this.buildFileTree(tags);

    // Notify the change
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree.
   */
  buildFileTree(tags: ITagTypes): TagNode[] {
    return Object.keys(tags)
      .sort((a, b) => a.localeCompare(b))
      .reduce<TagNode[]>((accumulator, key) => {
        const node = new TagNode();
        const value = tags[key] as Tag[];
        if (value.length) {
          node.title = key;
          node.children = this.addChildrenToType(value);
          return accumulator.concat(node);
        } else return accumulator;
      }, []);
  }

  addChildrenToType(tags: Tag[]) {
    let result = [] as TagNode[];
    if (tags.some((t) => t.category)) {
      const categoryMap = new Map<Number, TagCategory>();
      tags.map((tag) => {
        if (tag.category)
          if (!categoryMap.has(tag.category.id)) {
            const category = tag.category;
            category.tags.push(tag);
            category.tags.sort((a, b) => a.title.localeCompare(b.title));
            categoryMap.set(tag.category.id, tag.category);
          }
      });
      // Sort the categories by order, then name
      const categoryArray = Array.from(categoryMap, ([name, value]) => value);
      categoryArray.sort((a, b) => a.name.localeCompare(b.name));
      categoryArray.sort((a, b) => a.order - b.order);
      for (const value of categoryArray) {
        const node = new TagNode();
        node.title = value.name;
        node.category = value;
        node.children = this.addTagsToParent(value.tags);
        result.push(node);
      }
    } else {
      result = this.addTagsToParent(tags);
    }
    return result;
  }

  addTagsToParent(tags: Tag[]) {
    const result = [] as TagNode[];
    for (const tag of tags) {
      const node = new TagNode();
      node.title = tag.title;
      delete tag.category; // Remove category from tag to prevent HTTP serialization error
      node.tag = tag;
      result.push(node);
    }
    return result;
  }
}

@Component({
  selector: 'app-photo-tag-tree-dropdown',
  templateUrl: './photo-tag-tree-dropdown.component.html',
  styleUrls: ['./photo-tag-tree-dropdown.component.less'],
  providers: [TagDatabase],
})
export class PhotoTagTreeDropdownComponent implements OnInit, OnDestroy {
  private _tags: ITagTypes = {
    Service: [],
    BodyPart: [],
    PhotoType: [],
    Supply: [],
    AutoGenerated: [],
  };

  get tags() {
    return this._tags;
  }
  @Input() set tags(tags: ITagTypes) {
    const newTags = JSON.parse(JSON.stringify(tags));
    newTags['Body Part'] = newTags.BodyPart;
    delete newTags.AutoGenerated;
    delete newTags.PhotoType;
    delete newTags.BodyPart;
    if (newTags && !this._tags['Body Part']?.length && newTags['Body Part'].length) {
      this.database.initialize(newTags);
      if (this._preSlelectedTags) this.preSelectTags(this._preSlelectedTags);
    }
    this._tags = newTags;
  }

  private _preSlelectedTags: ITagTypes;
  get preselectedTags() {
    return this._preSlelectedTags;
  }
  @Input() set preselectedTags(tags: ITagTypes) {
    this._preSlelectedTags = tags;
    if (this._tags['Body Part']?.length) this.preSelectTags(this._preSlelectedTags);
  }

  private _filter: PhotoFilter;
  get filter() {
    return this._filter;
  }
  @Input() set filter(filter: PhotoFilter) {
    if (filter) {
      this._filter = filter;
      this.filter.tagPillClicked$.pipe(takeUntil(this.unsub)).subscribe((tag) => {
        const node = this.treeControl.dataNodes.find((node) => node.tag?.tagId === tag.tagId);
        if (this.tagSelection.isSelected(node)) this.tagSelection.deselect(node);
        else this.tagSelection.select(node);
      });
    }
  }

  private _disabled: boolean = false;
  get disabled() {
    return this._disabled;
  }
  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  @Output() tagsSelected = new EventEmitter<Tag[]>();

  private unsub: Subject<void> = new Subject<void>();

  flatNodeMap = new Map<TagFlatNode, TagNode>();
  nestedNodeMap = new Map<TagNode, TagFlatNode>();
  selectedParent: TagFlatNode | null = null;

  treeControl: FlatTreeControl<TagFlatNode>;
  treeFlattener: MatTreeFlattener<TagNode, TagFlatNode>;
  dataSource: MatTreeFlatDataSource<TagNode, TagFlatNode>;

  tagSelection = new SelectionModel<TagFlatNode>(true);

  constructor(private database: TagDatabase) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TagFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    database.dataChange.pipe(takeUntil(this.unsub)).subscribe((data) => {
      this.dataSource.data = data;
    });
  }

  getLevel = (node: TagFlatNode) => node.level;
  isExpandable = (node: TagFlatNode) => node.expandable;
  getChildren = (node: TagNode) => node.children;
  hasChild = (_: number, _nodeData: TagFlatNode) => _nodeData.expandable;

  transformer = (node: TagNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode &&
      existingNode.category === node.category &&
      existingNode.tag === node.tag &&
      existingNode.title === node.title
        ? existingNode
        : new TagFlatNode();
    flatNode.title = node.title;
    flatNode.category = node.category;
    flatNode.tag = node.tag;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  descendantsAllSelected(node: TagFlatNode) {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) => this.tagSelection.isSelected(child));
    return descAllSelected;
  }

  descendantsPartiallySelected(node: TagFlatNode) {
    const descendants = this.treeControl.getDescendants(node);
    const descSomeSelected = descendants.some((child) => this.tagSelection.isSelected(child));
    const descSomeDeselected = descendants.some((child) => !this.tagSelection.isSelected(child));
    return descSomeSelected && descSomeDeselected;
  }

  getSelectedTags() {
    const selectedTags = this.tagSelection.selected.filter((node) => node.tag).map((node) => node.tag);
    return selectedTags;
  }

  leafSelectionToggle(node: TagFlatNode) {
    this.tagSelection.toggle(node);
    this.checkAllParentsSelection(node);

    this.tagsSelected.emit(this.getSelectedTags());
  }

  parentSelectionToggle(node: TagFlatNode) {
    this.tagSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.tagSelection.isSelected(node)
      ? this.tagSelection.select(...descendants)
      : this.tagSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every((child) => this.tagSelection.isSelected(child));
    this.checkAllParentsSelection(node);

    this.tagsSelected.emit(this.getSelectedTags());
  }

  checkAllParentsSelection(node: TagFlatNode) {
    let parent: TagFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkRootNodeSelection(node: TagFlatNode) {
    const nodeSelected = this.tagSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) => this.tagSelection.isSelected(child));
    if (nodeSelected && !descAllSelected) {
      this.tagSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.tagSelection.select(node);
    }
  }

  getParentNode(node: TagFlatNode): TagFlatNode | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  preSelectTags(tags: ITagTypes) {
    this.tagSelection.deselect(...this.tagSelection.selected);
    if (tags) {
      const tagIds: string[] = [];
      for (const key in tags) {
        tags[key].forEach((tag) => tagIds.push(tag.tagId));
      }
      const nodes = this.treeControl.dataNodes.filter((node) => tagIds.includes(node.tag?.tagId));
      this.tagSelection.select(...nodes);
      nodes.forEach((node) => this.checkAllParentsSelection(node));
    }
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
