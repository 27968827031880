<ngx-loading [show]="loading"></ngx-loading>
<div class="general">
  <div class="d-flex align-items-center justify-content-between teal-background white-font header">
    <span class="mr-auto">GENERAL</span>
  </div>
  <ng-container *ngIf="form" [formGroup]="form">
    <div class="border border-lightgrey panel-container">
      <div class="row mx-0 py-2 panel-item-border">
        <!--Online booked appointment limit-->
        <div class="col d-flex justify-content-between">
          <span class="pr-2">Online booked appointment limit:</span>
          <input
            class="input-number light-grey-background"
            onlyNumber
            formControlName="onlineBookingAppointmentLimit"
            min="0"
          />
        </div>
        <div class="col d-flex">
          <span class="pr-2">Reservation duration in minutes:</span>
          <input
            class="input-number light-grey-background ml-auto"
            onlyNumber
            formControlName="onlineBookingReservationDurationMinutes"
            min="1"
          />
        </div>
      </div>
      <div class="row mx-0 py-2 panel-item-border grey-two-background">
        <!--Allow same day booking-->
        <div class="col">
          <mat-checkbox
            class="mr-2"
            formControlName="onlineBookingAllowSameDay"
            name="onlineBookingAllowSameDay"
            aria-label="Allow same day booking"
          ></mat-checkbox>
          <span>Allow same day booking</span>
        </div>
        <!--Same day buffer hours-->
        <div class="col d-flex justify-content-between">
          <span class="pr-2" [ngClass]="{ 'disabled-element': !this.form.controls['onlineBookingAllowSameDay'].value }">
            Same day buffer hours:
          </span>
          <input
            class="input-number light-grey-background"
            onlyNumber
            formControlName="onlineBookingBufferHours"
            min="1"
            [ngClass]="{ 'disabled-element': !this.form.controls['onlineBookingAllowSameDay'].value }"
          />
        </div>
      </div>
      <div class="row mx-0 py-2 panel-item-border">
        <div class="col">
          <div class="row">
            <!--Allow online booking cancellations-->
            <div class="col">
              <mat-checkbox
                class="mr-2"
                formControlName="onlineBookingCanCancelAppointment"
                name="onlineBookingCanCancelAppointment"
                aria-label="Allow online booking cancellations"
              ></mat-checkbox>
              <span>Allow online booking cancellations</span>
            </div>
            <!--Cancellation period hours-->
            <div class="col d-flex justify-content-between">
              <span
                class="pr-2"
                [ngClass]="{ 'disabled-element': !this.form.controls['onlineBookingCanCancelAppointment'].value }"
              >
                Cancellation period hours:
              </span>
              <input
                class="input-number light-grey-background"
                onlyNumber
                formControlName="onlineBookingCancellationPeriodHours"
                min="0"
                [ngClass]="{ 'disabled-element': !this.form.controls['onlineBookingCanCancelAppointment'].value }"
              />
            </div>
          </div>
          <div class="row pt-2">
            <div class="col"></div>
            <!--Cancellation Price-->
            <div class="col d-flex justify-content-between">
              <span
                class="pr-2"
                [ngClass]="{ 'disabled-element': !this.form.controls['onlineBookingCanCancelAppointment'].value }"
              >
                Online cancellation price:
              </span>
              <input
                class="input-number light-grey-background"
                onlyNumber
                formControlName="onlineBookingCancellationPrice"
                min="0"
                [ngClass]="{ 'disabled-element': !this.form.controls['onlineBookingCanCancelAppointment'].value }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mx-0 py-2 panel-item-border grey-two-background">
        <!--Prerequisite Limit Active-->
        <div class="col">
          <mat-checkbox
            class="mr-2"
            type="checkbox"
            formControlName="checkPrerequisiteValidity"
            name="checkPrerequisiteValidity"
            aria-label="Limit accepted prerequisite time"
          ></mat-checkbox>
          <span>Limit prerequisite validity</span>
        </div>
        <!--Prerequisite accepted time months-->
        <div class="col d-flex justify-content-between">
          <span class="pr-2" [ngClass]="{ 'disabled-element': !this.form.controls['checkPrerequisiteValidity'].value }">
            Prerequisite validity period months:
          </span>
          <input
            class="input-number light-grey-background"
            onlyNumber
            formControlName="prerequisiteValidityPeriod"
            min="0"
            [ngClass]="{ 'disabled-element': !this.form.controls['checkPrerequisiteValidity'].value }"
          />
        </div>
      </div>
      <div class="row py-2 mx-0" *ngIf="form.dirty && form.valid">
        <div class="col text-right">
          <button class="btn em-btn em-btn-green" (click)="updateSettings()">UPDATE</button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
