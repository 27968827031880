import { Injectable } from '@angular/core';
import { ObservationListItem, Observation, ObservationListStatic } from '@models/observation/observation';
import { ObservationListItemsService } from '../observation-list-items.service';
import { ColourVariables } from '@models/constants/colour-variables';
import { ObservationService } from '../observation.service';
import { ReplaySubject, Subject } from 'rxjs';
import { LatLng } from 'leaflet';
import { TreatmentType } from '@models/treatment-type';
import { PlottingEventService } from '@services/plotting-event.service';
import { ObrPlottingEventType } from '@models/observation/obr-plotting-event-type';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CoolToneFormService {
  applicator: ObservationListItem;
  areas: ObservationListStatic[] = [];
  observationKeys: Set<string> = new Set();
  observationListItemsMap: Map<number, ObservationListItem> = new Map();
  observationListStaticMap: Map<number, ObservationListStatic> = new Map();
  maleMarkerPositionMap: Map<string, LatLng> = new Map([
    ['Abs/L', new LatLng(610, 220)],
    ['Abs/M', new LatLng(610, 180)],
    ['Abs/R', new LatLng(610, 136)],
    ['Glutes/L', new LatLng(524, 649)],
    ['Glutes/M', new LatLng(524, 691)],
    ['Glutes/R', new LatLng(524, 737)],
    ['Thighs/AL', new LatLng(443, 226)],
    ['Thighs/AM', new LatLng(443, 183)],
    ['Thighs/AR', new LatLng(443, 137)],
    ['Thighs/PL', new LatLng(443, 649)],
    ['Thighs/PM', new LatLng(443, 694)],
    ['Thighs/PR', new LatLng(443, 739)],
  ]);
  femaleMarkerPositionMap: Map<string, LatLng> = new Map([
    ['Abs/L', new LatLng(610, 220)],
    ['Abs/M', new LatLng(610, 190)],
    ['Abs/R', new LatLng(610, 159)],
    ['Glutes/L', new LatLng(541, 650)],
    ['Glutes/M', new LatLng(541, 701)],
    ['Glutes/R', new LatLng(541, 752)],
    ['Thighs/AL', new LatLng(438, 233)],
    ['Thighs/AM', new LatLng(438, 187)],
    ['Thighs/AR', new LatLng(438, 141)],
    ['Thighs/PL', new LatLng(459, 656)],
    ['Thighs/PM', new LatLng(459, 704)],
    ['Thighs/PR', new LatLng(459, 751)],
  ]);
  droppingPins: boolean;
  treatmentSaved: Subject<void>;
  treatmentRemoved: Subject<number>;
  saveTrigger: Subject<void>;
  saveTriggerComplete: Subject<number>;

  // List population observable flags
  private observationListsPopulatedSubject$ = new ReplaySubject<boolean>(1);
  public observationListsPopulated$ = this.observationListsPopulatedSubject$.asObservable();
  private observationListsStaticPopulatedSubject$ = new ReplaySubject<boolean>(1);
  public observationListsStaticPopulated$ = this.observationListsStaticPopulatedSubject$.asObservable();

  /**
   * Notifies this component to update the inventory count of CoolTone applicator.
   */
  public applicatorRefreshRequired = new Subject<boolean>();

  unsub: Subject<void> = new Subject<void>();

  constructor(
    private observationListItemsService: ObservationListItemsService,
    private observationService: ObservationService,
    private plottingEventService: PlottingEventService
  ) {
    this.droppingPins = false;
    this.treatmentRemoved = new Subject();
    this.treatmentSaved = new Subject();
    this.saveTrigger = new Subject();
    this.saveTriggerComplete = new Subject();

    this.initObservationListsChangedSub();
    this.initObservationListStatic();
    this.initObservationLists();
    this.initPlottingEventService();

    this.applicatorRefreshRequired.subscribe({
      next: () => {
        if (this.applicator)
          this.observationListItemsService.getObservationListItem(this.applicator.id).subscribe({
            next: (obrListItem) => (this.applicator = obrListItem),
          });
      },
    });
  }

  private initObservationListsChangedSub() {
    this.observationListItemsService.observationListsChanged.pipe(takeUntil(this.unsub)).subscribe((oli) => {
      this.initObservationLists();
    });
  }

  private initObservationLists() {
    this.observationListItemsService.getAllObservationListItems().subscribe((observationListItems) => {
      observationListItems.forEach((oli) => {
        this.observationListItemsMap.set(oli.id, oli);
        if (oli.clinicSupplyType.observationListType.name === 'CoolTone Applicators') {
          this.applicator = oli;
        }
      });
      this.observationListsPopulatedSubject$.next(true);
    });
  }

  private initObservationListStatic() {
    const colourVariables = new ColourVariables();
    const uniqueColours = colourVariables.getCoolsculptingColourList();
    this.observationListItemsService.getObservationListStatic().subscribe((observationListStatic) => {
      observationListStatic.forEach((oli) => {
        this.observationListStaticMap.set(oli.id, oli);
        if (oli.observationListType.name === 'CoolTone Areas') {
          this.areas.push(oli);
          const colourIndex = Math.floor(Math.random() * uniqueColours.length);
          oli.colour = uniqueColours[colourIndex];
          uniqueColours.splice(colourIndex, 1);
        }
      });
      this.observationListsStaticPopulatedSubject$.next(true);
    });
  }

  private initPlottingEventService() {
    this.plottingEventService.getPlotSource().subscribe((response) => {
      if (response.event === ObrPlottingEventType.SendDetails) {
        response.details.forEach((obs: Observation, leafletId: number) => {
          // Use a copy to not modify the observation for other subscribers
          const copy = JSON.parse(JSON.stringify(obs)) as Observation;
          const obsKey = this.getObsKey(copy);
          this.observationKeys.add(obsKey);
        });
      }
    });

    this.plottingEventService.getObservationSource().subscribe((response) => {
      if (response.event === ObrPlottingEventType.DeletePoints) {
        this.observationKeys.delete(response.mapKey);
      }
    });
  }

  getObsKey(obs: Observation): string {
    'area/position/side?';
    obs.name = TreatmentType.CoolTone;
    return this.observationService.getObrKey(obs);
  }

  getAreaKey(obr: Observation): string {
    const obrKey = this.getObsKey(obr);
    return obrKey.split('/')[0];
  }

  getAreaByObsKey(obsKey: string): ObservationListStatic {
    const areaId = parseInt(obsKey.split('/')[0], 10);
    return this.observationListItemsService.observationListStatic.get(areaId);
  }

  getAreaById(areaId: number): ObservationListStatic {
    return this.observationListItemsService.observationListStatic.get(areaId);
  }

  getAreaFromObs(obs: Observation): ObservationListStatic {
    const areaId = parseInt(this.getObsKey(obs).split('/')[0], 10);
    return this.observationListItemsService.observationListStatic.get(areaId);
  }

  getPositionAndLocation(obr: Observation): string {
    const details = this.getObsKey(obr).split('/');
    if (details.length == 3) {
      const location = details[1] ? details[1].substring(0, 1).toUpperCase() : '';
      const position = details[2].substring(0, 1).toUpperCase();
      return location + position;
    } else {
      return details[1].substring(0, 1).toUpperCase();
    }
  }

  getPositionAndLocationText(obr: Observation): string {
    const details = this.getObsKey(obr).split('/');
    if (details.length == 3) {
      const location = details[1] ? details[1] : '';
      const position = details[2].substring(0, 1).toUpperCase();
      return location + ' ' + position;
    } else {
      return details[1].substring(0, 1).toUpperCase();
    }
  }

  pinAvailable(obs: Observation): boolean {
    const obsKey = this.getObsKey(obs);
    const hasKey = this.observationKeys?.has(obsKey);
    return !hasKey;
  }

  getTreatmentsAsText(observations: Observation[]) {
    let treatmentItems = observations.map((obs) => this.getTreatmentText(obs));
    return treatmentItems;
  }

  getTreatmentText(obs: Observation) {
    this.observationService.detailsToJson([obs]);
    return {
      area: this.getAreaFromObs(obs).name,
      details: this.getPositionAndLocationText(obs),
      totalDiscountPercentage: obs.details.isOverrideTotalPrice
        ? 1 - obs.details.overrideTotalPrice / obs.details.productPrice
        : obs.details.isOverrideProductPrice
        ? 1 - obs.details.overrideProductPrice / obs.details.productPrice
        : 0,
      price: obs.details.isOverrideTotalPrice
        ? obs.details.overrideTotalPrice
        : obs.details.isOverrideProductPrice
        ? obs.details.overrideProductPrice
        : obs.details.productPrice,
    };
  }
}
