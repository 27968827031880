import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { PatientTypeheadComponent } from '@app/patients/patient-typehead/patient-typehead.component';
import { PatientSearchModalComponent } from '@app/shared/components/patient-search-modal/patient-search-modal.component';
import { Patient } from '@models/patient';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterOverlayService } from '@services/actionpanel.service';
import { BlobService } from '@services/blob.service';
import { PatientService } from '@services/patient.service';
import { TwilioConversationsService } from '@services/twilio-conversations.service';
import { Conversation } from '@twilio/conversations';
import * as moment from 'moment';
import { NgScrollbar } from 'ngx-scrollbar';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, shareReplay, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-conversations-sidebar',
  templateUrl: './conversations-sidebar.component.html',
  styleUrls: ['./conversations-sidebar.component.less'],
})
export class ConversationsSidebarComponent implements OnInit, OnDestroy {
  @ViewChild('scrollbar') scrollbar: NgScrollbar;
  @ViewChild('patientSearch') patientSearch: PatientTypeheadComponent;
  @ViewChild('conversationSelectionList') selectionList: MatSelectionList;
  @Output() conversationSelected: EventEmitter<Conversation> = new EventEmitter();
  @Output() conversationPatientSelected: EventEmitter<Patient> = new EventEmitter();
  searchFormControl = new FormControl('');
  conversations: Conversation[] = [];
  filteredConversations: Conversation[] = [];
  conversationsPatient: Map<string, Observable<Patient>> = new Map();
  conversationsUnreadCount: Map<string, Observable<number>> = new Map();
  conversationsLastMessage: Map<string, Observable<string>> = new Map();
  loading = false;
  removePolicy = Policies.patientMessagingRemove;
  sendPolicy = Policies.patientMessagingSend;
  private unsub = new Subject<void>();

  private _selectedConversation: Conversation = null;
  get selectedConversation() {
    return this._selectedConversation;
  }
  set selectedConversation(conversation: Conversation) {
    this._selectedConversation = conversation;
    if (conversation) {
      this.conversationsPatient
        .get(conversation?.sid)
        ?.toPromise()
        ?.then((patient) => {
          this.conversationPatientSelected.emit(patient);
          this.conversationSelected.emit(conversation);
        });
    } else {
      this.conversationPatientSelected.emit(null);
      this.conversationSelected.emit(null);
    }
  }

  get readOnlySAS() {
    return this.blobService.getReadOnlySAS();
  }

  constructor(
    private twilioService: TwilioConversationsService,
    private blobService: BlobService,
    private modalService: NgbModal,
    private patientService: PatientService,
    private masterOverlayService: MasterOverlayService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  getPatient(conversation: Conversation): Observable<Patient> {
    const patientId = this.twilioService.parsePatientId(conversation);
    return this.patientService.getPatientById(patientId);
  }

  ngOnInit(): void {
    this.loading = true;

    this.twilioService.conversations$.pipe(takeUntil(this.unsub)).subscribe(async (result) => {
      this.conversations = result.filter((c) => !this.twilioService.getConversationArchiveStatus(c));
      this.setObserverMaps(this.conversations);
      this.filteredConversations = this.searchFilter('');
      if (this.selectedConversation) {
        await this.scrollToConversation(this.selectedConversation);
      }
      this.loading = false;
    });

    this.searchFormControl.valueChanges.pipe(takeUntil(this.unsub)).subscribe((searchString) => {
      this.filteredConversations = this.searchFilter(searchString);
      this.selectedConversation = null;
      this.selectionList.deselectAll();
    });
  }

  private setObserverMaps(conversations: Conversation[]) {
    for (const conversation of conversations) {
      const patientId = this.twilioService.parsePatientId(conversation);
      this.conversationsPatient.set(
        conversation.sid,
        this.patientService.getPatientById(patientId).pipe(shareReplay(1))
      );
      this.conversationsUnreadCount.set(conversation.sid, this.unreadCountObserver(conversation));
      this.conversationsLastMessage.set(conversation.sid, this.latestMessageObserver(conversation));
    }
  }

  private unreadCountObserver(conversation: Conversation): Observable<number> {
    const subj = new BehaviorSubject<number>(null);

    const onMessageAdded = (message) => {
      if (this.selectedConversation?.sid === conversation.sid) {
        subj.next(0);
      } else {
        conversation.getUnreadMessagesCount().then((unreadCount) => subj.next(unreadCount));
      }
    };

    const onMessageUpdated = (update) => {
      if (this.selectedConversation?.sid === conversation.sid) {
        subj.next(0);
      } else {
        conversation.getUnreadMessagesCount().then((unreadCount) => subj.next(unreadCount));
      }
    };

    conversation.getUnreadMessagesCount().then((unreadCount) => subj.next(unreadCount));
    conversation.on('messageAdded', onMessageAdded);
    conversation.on('updated', onMessageUpdated);

    return subj.pipe(
      takeUntil(this.unsub),
      finalize(() => {
        conversation.off('messageAdded', onMessageAdded);
        conversation.off('updated', onMessageUpdated);
      })
    );
  }

  private latestMessageObserver(conversation: Conversation): Observable<string> {
    const subj = new BehaviorSubject<string>('');

    conversation.getMessages(1).then((page) => {
      const lastMessage = page.items[0];
      if (lastMessage) {
        const fromName = lastMessage.author.includes('clinic') ? 'Clinic' : 'Patient';
        subj.next(`${fromName}: ${lastMessage.body}`);
      }
    });

    const onMessageAdded = (message) => {
      const fromName = message.author.includes('clinic') ? 'Clinic' : 'Patient';
      subj.next(`${fromName}: ${message.body}`);
    };

    conversation.on('messageAdded', onMessageAdded);
    return subj.pipe(
      takeUntil(this.unsub),
      finalize(() => conversation.off('messageAdded', onMessageAdded))
    );
  }

  private searchFilter(searchString: string) {
    const searchTerms = searchString.split(' ').map((term) => term.trim().toLowerCase());
    return this.conversations.filter((conversation) =>
      searchTerms.every((term) => conversation.friendlyName.toLowerCase().includes(term))
    );
  }

  async onSelectionChange(change: MatSelectionListChange) {
    this.selectedConversation = change.options[0]?.value;
  }

  addConversation() {
    const modalRef = this.modalService.open(PatientSearchModalComponent, { centered: true });
    modalRef.result.then(async (patient: Patient) => {
      if (patient) {
        this.loading = true;
        try {
          const fullPatient = await this.patientService.getPatientById(patient.patientId).toPromise();
          this.selectedConversation = await this.twilioService.getPatientConversation(fullPatient);
        } catch (error) {
          if (error.message) {
            this.dialog.open(GenericDialogComponent, {
              width: '350px',
              data: {
                title: 'Can not create a new conversation',
                content: error.message ?? 'An error occurred while trying to open the patient chat.',
                confirmButtonText: 'OK',
                showCancel: false,
              },
            });
          }
          throw error;
        } finally {
          this.loading = false;
        }
      }
    });
  }

  // For internal use. Will permanently delete to conversation. Button commented out in template.
  async deleteConversation(conversation: Conversation) {
    await conversation.delete();
    if (conversation.sid === this.selectedConversation?.sid) {
      this.selectedConversation = null;
    }
  }

  async removeConversation(conversation: Conversation) {
    await this.twilioService.archiveConversation(conversation);
    if (conversation.sid === this.selectedConversation?.sid) {
      this.selectedConversation = null;
    }
  }

  goToPatientProfile(patient: Patient) {
    if (!patient) return;
    this.patientService.patientPanelPatient = patient;
    this.masterOverlayService.masterOverlayState(true);
    this.router.navigate([
      '/patient-messaging',
      { outlets: { 'action-panel': ['patient', patient.patientId + '_' + 'patientprofiletab'] } },
    ]);
  }

  getAge(date: Date) {
    return moment(new Date(Date.now())).diff(moment(date), 'years');
  }

  private async scrollToConversation(conversation: Conversation) {
    if (conversation) {
      const element = document.getElementById(conversation.uniqueName);
      if (element) {
        await this.scrollbar.scrollToElement(element);
      }
    }
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }
}
