import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map, startWith } from 'rxjs/operators';
import { isNullOrUndefined } from '@app/shared/helpers';
import { Company } from '@models/company';
import { Clinic } from '@models/clinic';
import { Address } from '@models/address';
import { CompanyService } from '@services/company.service';
import { AddressService } from '@services/address.service';
import { ClinicsService } from '@services/clinics.service';
import { GeographyService } from '@services/geography.service';
import { ValidationService } from '@services/validation.service';
import { FormatterService } from '@services/formatter.service';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';
import { AuthService } from '@app/auth/auth.service';
import { authRoles } from '@app/auth/auth-permissions';
import { UsersService } from '@services/users.service';
import { Policies } from '@app/auth/auth-policies';

@Component({
  selector: 'app-org-company',
  templateUrl: './org-company.component.html',
  styleUrls: ['./org-company.component.less'],
})
export class EditCompanyComponent implements OnInit, OnDestroy {
  private unsub: Subject<void> = new Subject<void>();
  private companyIdParam = parseInt(this.authService.userCompanyId);
  private defaultAddress: Address = {
    address1: '',
    address2: '',
    city: '',
    province: 'British Columbia',
    country: 'Canada',
    postalCode: '',
  };
  private selectedCompany: Company;
  private allAvailableClinics: Clinic[] = [];

  loading = false;
  editedCompany: Company;
  countriesOfTheWorld: string[] = [];
  provincesAndStates: string[] = [];
  timezonesOfTheWorld: any;
  submitButtonDisabledState = false;
  selectedClinic: FormControl = new FormControl();
  filteredClinics: Observable<Clinic[]>;
  selectedClinics: Clinic[] = [];
  companyId: FormControl;
  name: FormControl;
  clinics: FormControl;
  addressAddress1: FormControl;
  addressAddress2: FormControl;
  addressCity: FormControl;
  addressCountry: FormControl;
  addressPostalCode: FormControl;
  addressProvince: FormControl;
  contactName: FormControl;
  contactPhone: FormControl;
  minimumDuration: FormControl;
  url: FormControl;
  isSharingPatients: FormControl;
  developerPolicy = Policies.developer;
  developerPolicySatisfied = false;

  constructor(
    private usersService: UsersService,
    private authService: AuthService,
    private companyService: CompanyService,
    private addressService: AddressService,
    private clinicsService: ClinicsService,
    private geographyService: GeographyService,
    private dialog: MatDialog,
    public validationService: ValidationService,
    public formatterService: FormatterService
  ) {
    this.companyId = new FormControl();
    this.name = new FormControl();
    this.clinics = new FormControl();
    this.addressAddress1 = new FormControl();
    this.addressAddress2 = new FormControl();
    this.addressCity = new FormControl();
    this.addressCountry = new FormControl();
    this.addressPostalCode = new FormControl('', null);
    this.addressProvince = new FormControl();
    this.contactName = new FormControl();
    this.contactPhone = new FormControl('', this.validationService.validatePhoneNumber);
    this.url = new FormControl('', Validators.required);
    this.isSharingPatients = new FormControl();
  }

  ngOnInit() {
    this.refreshData();
    // tslint:disable-next-line:forin
    for (const key in this.geographyService.countriesByName()) {
      this.countriesOfTheWorld.push(key);
    }
    // tslint:disable-next-line:forin
    this.timezonesOfTheWorld = this.geographyService.timeZonesMap();

    this.companyIdParam = Number.isNaN(this.usersService.loggedInUserCompany)
      ? parseInt(this.authService.userCompanyId)
      : this.usersService.loggedInUserCompany;

    // tslint:disable-next-line:forin
    for (const key in this.geographyService.countriesByName()) {
      this.countriesOfTheWorld.push(key);
    }

    this.selectedClinics = [];
    this.selectedCompany = this.initCompany(this.selectedCompany, this.defaultAddress, this.selectedClinics);
    this.editedCompany = this.initCompany(this.editedCompany, this.defaultAddress, this.selectedClinics);
    this.developerPolicySatisfied = this.authService.userSatisfiesPolicy(Policies.developer);
  }

  private refreshData() {
    this.companyService.getCompanyById(this.companyIdParam).subscribe((company) => {
      if (company != null) {
        this.selectedCompany = company;
        this.editedCompany = company;

        this.selectedClinics = [];
        this.selectedCompany.clinics.forEach((c) => {
          if (isNullOrUndefined(c.address)) {
            c.address = {
              address1: '',
              address2: '',
              city: '',
              province: '',
              country: '',
              postalCode: '',
            };
          }
          this.selectedClinics.push(c);
        });
        this.updateProvincesStates();
        this.updateSubmitButtonState();
        this.loading = false;
      }
    });
    this.getAllAvailableClinics();
    this.filteredClinics = this.selectedClinic.valueChanges.pipe(
      startWith(''),
      map((val) => this.clinicFilter(val))
    );
  }

  private clinicFilter(val: any): Clinic[] {
    if (val && val.name) {
      return this.allAvailableClinics.filter((option) => option.name.toLowerCase().includes(val.name.toLowerCase()));
    } else {
      if (val) {
        return this.allAvailableClinics.filter((option) => option.name.toLowerCase().includes(val.toLowerCase()));
      } else {
        return this.allAvailableClinics;
      }
    }
  }

  public clinicDisplayFn(user?: Clinic): string | undefined {
    return user ? user.name : undefined;
  }

  public onChangeCountry() {
    this.updateProvincesStates();
    this.addressPostalCode.setValue('');
  }

  private updateProvincesStates() {
    this.provincesAndStates = this.geographyService.updateProvinceStateList(this.editedCompany.address.country);
    if (this.editedCompany.address.country.toLowerCase() === 'canada') {
      this.addressPostalCode.validator = this.validationService.validatePostalCode;
    } else if (this.editedCompany.address.country.toLowerCase() === 'united states') {
      this.addressPostalCode.validator = this.validationService.validateZipCode;
    } else {
      this.addressPostalCode.validator = null;
    }
  }

  public updateSubmitButtonState() {
    if (
      this.contactPhone.hasError('phoneError') ||
      this.addressPostalCode.hasError('postalCodeError') ||
      this.addressPostalCode.hasError('zipCodeError')
    ) {
      this.submitButtonDisabledState = true;
    } else {
      this.submitButtonDisabledState = false;
    }
  }

  public addClinicToCompany() {
    if (this.selectedClinic.value != null) {
      const companyHasClinic = this.editedCompany.clinics.some(
        (item) => item.clinicId === this.selectedClinic.value.clinicId
      );
      if (companyHasClinic) {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
          width: '250px',
          data: {
            showCancel: false,
            title: 'Warning',
            content: 'You cannot add same clinic to the list multiple times',
            confirmButtonText: 'Ok',
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.selectedClinic.reset();
        });
      } else {
        const clinic: Clinic = this.selectedClinic.value;
        clinic.companyId = this.editedCompany.companyId;
        this.clinicsService.updateClinic(clinic).subscribe(() => {
          this.selectedClinics.push(clinic);
          this.editedCompany.clinics.push(clinic);
        });
      }
    }
  }

  public removeClinicFromCompany(clinicToRemove: Clinic) {
    this.confirmRemoval(clinicToRemove);
  }

  private getAllAvailableClinics() {
    this.allAvailableClinics = [];
    this.clinicsService.getClinics().subscribe((clinicSnapshot) => {
      if (clinicSnapshot !== undefined && clinicSnapshot !== null) {
        clinicSnapshot.forEach((clinicDoc) => {
          this.allAvailableClinics.push(clinicDoc);
        });
      }
    });
  }

  public confirmRemoval(dataItem: Clinic) {
    if (this.selectedClinics.length > 1) {
      const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
        width: '250px',
      });
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsub))
        .subscribe((result) => {
          if (result === 'delete') {
            const dataItemToRemove = {
              clinicId: dataItem.clinicId,
              name: dataItem.name,
              blobContainerName: dataItem.blobContainerName,
              address: dataItem.address,
              addressId: dataItem.addressId,
              clinicRooms: dataItem.clinicRooms,
              clinicTaxes: dataItem.clinicTaxes,
              companyId: null,
              email: dataItem.email,
              phoneNumber: dataItem.phoneNumber,
              timezone: dataItem.timezone,
              hoursOfOperation: dataItem.hoursOfOperation,
              minimumDuration: dataItem.minimumDuration,
              //loyaltyPointsToDollarsRate: dataItem.loyaltyPointsToDollarsRate,
              customInvoiceText: dataItem.customInvoiceText,
              url: dataItem.url,
              sendPatientPortalEmail: dataItem.sendPatientPortalEmail,
            };
            this.clinicsService.updateClinic(dataItemToRemove).subscribe(() => {
              this.selectedClinics.splice(
                this.selectedClinics.findIndex((sc) => sc.clinicId === dataItemToRemove.clinicId),
                1
              );
              this.editedCompany.clinics.splice(
                this.editedCompany.clinics.findIndex((sc) => sc.clinicId === dataItemToRemove.clinicId),
                1
              );
            });
          }
        });
    } else {
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '250px',
        data: {
          showCancel: false,
          title: 'Warning',
          content: 'There must be at least one Clinic associated with the Company',
          confirmButtonText: 'Ok',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {});
    }
  }

  public updateCompany() {
    this.loading = true;
    this.companyService.updateCompany(this.editedCompany).subscribe(
      () => {
        this.loading = false;
        this.addressService.updateAddress(this.editedCompany.address).subscribe(
          () => {
            this.loading = false;
          },
          (error) => {
            this.loading = false;
          }
        );
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  private initCompany(theCompany: Company, theAddress: Address, theClinics: Clinic[]) {
    theCompany = {
      companyId: 0,
      name: '',
      address: theAddress,
      clinics: theClinics,
      contactName: '',
      contactPhone: '',
      timezone: '',
      url: '',
      isSharingPatients: false,
    };
    return theCompany;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
