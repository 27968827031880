import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Resource } from '@models/resource';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { ResourcesService } from '@services/resources.service';
import { FormatterService } from '@services/formatter.service';
import { ResourceType, ResourceTypesTitle } from '@models/resource-type';

@Component({
  selector: 'app-edit-resource',
  templateUrl: './edit-resource.component.html',
  styleUrls: ['./edit-resource.component.less'],
})
export class EditResourceComponent implements OnInit, AfterViewInit {
  editResourcePanelVisible = false;
  addOrEdit = 'Add';

  resourceIdParam: string;
  resourceCategoryParam: string;

  name: FormControl;
  resourceType: FormControl;
  isNew: boolean;

  public selectedResource: Resource;
  public editedResource: Resource;
  public resourceTypes: any;

  ResourceType = ResourceType;

  constructor(
    private resourcesService: ResourcesService,
    private catalogueUpdatesService: CatalogueUpdatesService,
    public formatterService: FormatterService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.name = new FormControl();
    this.resourceType = new FormControl();
  }

  ngOnInit() {
    this.resourceTypes = Object.keys(ResourceTypesTitle).map((e) => ({
      title: ResourceTypesTitle[e],
      value: ResourceType[e],
    }));
    this.selectedResource = this.initResource(this.selectedResource);
    this.editedResource = this.initResource(this.editedResource);

    this.isNew = true;
    this.addOrEdit = 'Add';
    this.route.params.subscribe((params) => {
      this.resourceIdParam = params['rsrcid'];
      if (this.resourceIdParam !== '_' && this.resourceIdParam !== null) {
        this.resourcesService.getResourceById(this.resourceIdParam).subscribe((snapshot) => {
          this.selectedResource = snapshot as Resource;
          this.editedResource = snapshot as Resource;
          this.isNew = false;
          this.addOrEdit = 'Edit';
        });
      }
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  updateResource() {
    if (this.isNew) {
      this.resourcesService.addResource(this.editedResource).subscribe(() => {
        this.resourcesService.resourcesUpdated.next(true);
        this.router.navigate(['/management/organization/clinics/resources', { outlets: { 'action-panel': null } }]);
      });
    } else {
      this.resourcesService.updateResource(this.editedResource).subscribe(() => {
        this.resourcesService.resourcesUpdated.next(true);
        this.router.navigate(['/management/organization/clinics/resources', { outlets: { 'action-panel': null } }]);
      });
    }
  }

  cancelUpdate() {
    this.resourcesService.resourcesUpdated.next(false);
    this.router.navigate(['/management/organization/clinics/resources', { outlets: { 'action-panel': null } }]);
  }

  initResource(pakage: Resource) {
    pakage = {
      resourceId: 0,
      name: '',
      resourceType: null,
      disabled: false,
    };
    return pakage;
  }
}
