<div class="manage-categories">
  <h1 mat-dialog-title>{{ title }}</h1>

  <perfect-scrollbar style="max-height: 230px">
    <app-category-item
      *ngFor="let item of list"
      [data]="item"
      [categoryType]="categoryType"
      (deleted)="remove(item.id)"
      (itemAdded)="changeState()"
    >
    </app-category-item>
  </perfect-scrollbar>

  <div class="row pt-2" *ngIf="!isHiddedAddBtn">
    <div class="col text-center">
      <button class="btn em-btn em-btn-green" (click)="addCategory()">Add Category</button>
    </div>
  </div>
  <div class="row pt-2 mt-auto">
    <div class="col text-right">
      <button class="btn em-btn em-btn-green" (click)="close()">Close</button>
    </div>
  </div>
</div>
