<div class="edit-container position-absolute d-block z-index-3 white-background">
  <form [formGroup]="observationGroupForm" (ngSubmit)="onSubmit()">
    <div class="row mx-0 teal-background">
      <div class="col px-2">
        <h6 class="white-font uppercase p-2 mb-0">{{ actionType }} Observation Group</h6>
      </div>
    </div>
    <div class="row mx-0 mt-2">
      <div class="col px-2">
        <mat-form-field class="w-100">
          <input
            matInput
            formControlName="name"
            placeholder="Observation Group Name"
            aria-label="Observation Group Name"
            required
          />
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field class="w-100">
          <input
            matInput
            onlyNumber
            numericType="decimal"
            formControlName="price"
            placeholder="Observation Group Price"
            aria-label="Observation Group Price"
            required
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-2 mx-0">
      <div class="col px-2 text-center">
        <button type="submit" class="btn em-btn em-btn-green" [disabled]="!observationGroupForm.valid">
          {{ actionType }}
        </button>
      </div>
      <div class="col px-2 text-center">
        <button class="btn em-btn em-btn-green" (click)="cancelUpdate()">Cancel</button>
      </div>
    </div>
  </form>
</div>
