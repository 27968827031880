
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChildren, ElementRef, QueryList, ViewChild } from '@angular/core';
import { PatientFacingReviewInfo, ReviewSettingsService } from '@services/review-settings.service';
import { FormatterService } from '@services/formatter.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'app-patient-review',
  templateUrl: './patient-review.component.html',
  styleUrls: ['./patient-review.component.less']
})
export class PatientReviewComponent implements OnInit, OnDestroy {
  @ViewChildren("ratingStar") ratingStars:QueryList<ElementRef>;

  @ViewChild(TooltipDirective)
  public tooltipDir: TooltipDirective;

  unsub: Subject<void> = new Subject<void>();

  currentRating = 0;

  appointmentId: number = null;
  patientId: number = 0;
  reviewText : string = '';
  unsubscribed = false;
  formSubmitted  = false;
  loading: boolean;
  ratingResult;

  get isPositiveReview(){
    return this.currentRating > 3;
  }

  static starClassActive = "rating__star fas fa-star";
  static starClassUnactive = "rating__star far fa-star";

  info: PatientFacingReviewInfo;


  constructor(private route: ActivatedRoute,
              public formatterService: FormatterService,
              private reviewSettingsService: ReviewSettingsService) {
    this.unsubscribed = this.route.routeConfig.path.indexOf("/Unsubscribe/Review") != -1;
  }

  ngOnInit(): void {
    this.loading = true;
    this.route.params.pipe(takeUntil(this.unsub)).subscribe(params => {

      this.appointmentId = params['appointmentId'];
      this.patientId = params['patientId'];

      this.reviewSettingsService.getInfoForPatientReview(this.patientId, this.appointmentId).subscribe((info) => {
        this.info = info;
        this.info.phoneNumber = this.formatterService.formatPhoneString(info.phoneNumber);
        if (!this.unsubscribed)
          this.loading = false;
      });
      if (this.unsubscribed) {
        this.unsubscribePatient();
      }

    });

  }

  onFormSubmit(e){
    if (this.currentRating <= 0) {
      alert('Please choose a rating between 1 and 5.');
    }
    else if (!this.reviewText || this.reviewText == ''){
      alert('Please write a short description of your experience.');
    }
    else{
      this.loading = true;
      this.reviewSettingsService.submitPatientReview(this.patientId,this.reviewText, this.currentRating,  this.appointmentId).subscribe(() => {
        this.formSubmitted = true;
        this.loading = false;
      },
      () => this.loading = false,
      () => this.loading = false);
    }

  }

  starClicked(event){
    if (this.formSubmitted)
      return;
    var star = event.target;
    var stars = this.ratingStars.map(s => s.nativeElement);
    var i = stars.indexOf(star);

    if (star.className.indexOf(PatientReviewComponent.starClassUnactive) !== -1) {
      this.currentRating =  i + 1;
      for (i; i >= 0; --i) stars[i].className = PatientReviewComponent.starClassActive;
    } else {
      this.currentRating =  i;
      for (i; i < this.ratingStars.length; ++i) stars[i].className = PatientReviewComponent.starClassUnactive;
    }
  }

  copyClicked(inputElement) {
    const wasDisabled = inputElement.disabled;
    inputElement.disabled = false;
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0,0);
    inputElement.disabled = wasDisabled;
    this.tooltipDir.show(inputElement);
    setTimeout(() => {
      this.tooltipDir.hide();
    }, 3000);
  }

  unsubscribePatient(){
    this.loading = true;
    this.reviewSettingsService.unsubscribePatientFromReviewRequests(this.patientId).subscribe(() => {
      this.unsubscribed = true;
      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
