export class ReportsFilterModel {
  startDate?: Date;
  endDate?: Date;
  showDeletedRecords: boolean;
  clinicId: number;

  constructor(init?: Partial<ReportsFilterModel>) {
    Object.assign(this, init);
  }
}
