import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { isNullOrUndefined } from '@app/shared/helpers';
import { AdminFeePurchased } from '@models/invoice/admin-fee-purchased';
import { Invoice } from '@models/invoice/invoice';
import { PaidCancellation } from '@models/invoice/paid-cancellation';
import { ProductPurchased } from '@models/invoice/product-purchased';
import { ServicePurchased } from '@models/invoice/service-purchased';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { PlannedTreatment } from '@models/treatment-planning/planned-treatment';
import { PlannedTreatmentMultiple } from '@models/treatment-planning/planned-treatment-multiple';
import { TreatmentState } from '@models/treatment-planning/treatment-state';
import { FinanceService } from '@services/finance.service';
import { InvoicesService } from '@services/invoices.service';
import { ReturnService } from '@services/return.service';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { PaymentType } from '../invoice-payment/invoice-payment.component';
import { PatientService } from './../../../../services/patient.service';

@Component({
  selector: 'app-return-payment',
  templateUrl: './return-payment.component.html',
  styleUrls: ['./return-payment.component.less'],
})
export class ReturnPaymentComponent implements OnInit, OnDestroy {
  private _isPanelVisible: boolean;
  paidCancellation: PaidCancellation;
  get isPanelVisible(): boolean {
    return this._isPanelVisible;
  }
  @Input() set isPanelVisible(value: boolean) {
    this._isPanelVisible = value;
    if (value) {
      this.getPurchases();
    } else {
      this.checkIfInvoiceUnpaid();
    }
  }

  @Output() invoicePay: EventEmitter<boolean> = new EventEmitter<boolean>();
  TreatmentState = TreatmentState;
  patientId: number;
  productsPurchased: ProductPurchased[];
  creditsPurchased: number;
  servicesPurchased: ServicePurchased[];
  adminFeesPurchased: AdminFeePurchased[];
  paidCancellations: PaidCancellation[];
  serviceTemplates: ClinicServiceTemplate[];
  returnForm: FormGroup;
  formReturnProducts: FormArray;
  formReturnCredits: FormArray;
  formReturnServices: FormArray;
  formReturnFees: FormArray;
  formPaidCancellations: FormArray;
  selectedInvoice$: BehaviorSubject<Invoice> = new BehaviorSubject<Invoice>(null);
  isOpenPaymentModal = false;
  isEnteredQuantityValid: boolean = true;

  unsub = new Subject<any>();
  loading = false;
  plannedTreatmentMultiple: PlannedTreatmentMultiple;
  isPlannedTreatmentMultiple: boolean = false;
  plannedTreatmentMultipleList: PlannedTreatment[] = [];
  plannedTreatmentMultiplesForm: FormGroup;
  PaymentType = PaymentType;

  get returnType(): PaymentType {
    return this.returnForm?.get('returnType').value;
  }

  constructor(
    private formBuilder: FormBuilder,
    private invoicesService: InvoicesService,
    private financeService: FinanceService,
    private patientService: PatientService,
    private treatmentPlanService: TreatmentPlanService,
    private returnService: ReturnService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getPatientId();
    this.initForm();
    this.detectInvoicePaid();
  }

  private async getPurchases() {
    this.loading = true;
    await Promise.all([
      this.getProductsPurchased(),
      this.getCreditsPurchased(),
      this.getServicesPurchased(),
      this.getAdminFeesPurchased(),
      this.getPaidCancellations(),
    ]);
    this.loading = false;
  }

  private initForm() {
    this.returnForm = this.formBuilder.group({
      formReturnProducts: this.formBuilder.array([this.createProduct()]),
      formReturnCredits: this.formBuilder.array([this.createCredit()]),
      formReturnServices: this.formBuilder.array([this.createService()]),
      formReturnFees: this.formBuilder.array([this.createFee()]),
      formPaidCancellations: this.formBuilder.array([this.createPaidCancellation()]),
      returnType: new FormControl(PaymentType.RefundProduct, Validators.required),
    });
    this.formReturnProducts = this.returnForm.get('formReturnProducts') as FormArray;
    this.formReturnServices = this.returnForm.get('formReturnServices') as FormArray;
    this.formReturnCredits = this.returnForm.get('formReturnCredits') as FormArray;
    this.formReturnFees = this.returnForm.get('formReturnFees') as FormArray;
    this.formPaidCancellations = this.returnForm.get('formPaidCancellations') as FormArray;

    this.plannedTreatmentMultipleList = [];
    this.plannedTreatmentMultiplesForm = this.formBuilder.group({});
    this.plannedTreatmentMultiple = null;
  }

  createProduct(): FormGroup {
    return this.formBuilder.group({
      product: new FormControl(null, Validators.required),
      productQuantity: new FormControl(null, Validators.required),
    });
  }

  createCredit(): FormGroup {
    return this.formBuilder.group({
      creditQuantity: new FormControl(null, Validators.required),
    });
  }

  createService(): FormGroup {
    return this.formBuilder.group({
      service: new FormControl(null, Validators.required),
    });
  }

  createFee(): FormGroup {
    return this.formBuilder.group({
      fee: new FormControl(null, Validators.required),
    });
  }

  createPaidCancellation(): FormGroup {
    return this.formBuilder.group({
      paidCancellation: new FormControl(null, Validators.required),
    });
  }

  addProduct() {
    this.formReturnProducts.push(this.createProduct());
  }

  addCredit() {
    this.formReturnCredits.push(this.createCredit());
  }

  addService() {
    this.formReturnServices.push(this.createService());
  }

  removeProduct(i: number) {
    this.formReturnProducts.removeAt(i);
  }

  removeCredit() {
    this.formReturnCredits.removeAt(this.formReturnCredits.length - 1);
  }

  removeService(i: number) {
    this.formReturnServices.removeAt(i);
  }

  validateProductsQuantity(event: any, maxValue: number) {
    if (
      !isNullOrUndefined(maxValue) &&
      event.target.value &&
      (Number(event.target.value) < 1 || Number(event.target.value) > maxValue)
    ) {
      this.isEnteredQuantityValid = false;
    } else {
      this.isEnteredQuantityValid = true;
    }
  }

  detectInvoicePaid() {
    this.financeService.invoicePaid$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.getServicesPurchased();
      this.getCreditsPurchased();
      this.getProductsPurchased();
    });
  }

  getPatientId() {
    if (!this.patientId) {
      this.patientId = this.route.snapshot.params.patId.split('_')[0];
    }
  }

  private async getServicesPurchased() {
    this.servicesPurchased = await this.invoicesService.getServicesPurchasedByPatient(this.patientId).toPromise();
  }

  private async getCreditsPurchased() {
    const patient = await this.patientService.getPatientById(this.patientId).toPromise();
    this.creditsPurchased = patient.creditAmount ?? 0;
  }

  private async getProductsPurchased() {
    this.productsPurchased = await this.invoicesService.getProductsPurchasedByPatient(this.patientId).toPromise();
  }

  private async getAdminFeesPurchased() {
    this.adminFeesPurchased = await this.invoicesService.getAdminFeesPurchasedByPatient(this.patientId).toPromise();
  }

  private async getPaidCancellations() {
    this.paidCancellations = await this.invoicesService.getPaidCancellationsByPatient(this.patientId).toPromise();
  }

  isExistItemInArray(item: any, array: any[]) {
    if (item.productId) {
      return array.some((invoiceLineItem) => item.productId === invoiceLineItem.productId);
    } else {
      return array.some((invoiceLineItem) => item.serviceTemplateId === invoiceLineItem.serviceTemplateId);
    }
  }

  isSelectedProduct(item) {
    const productsReturn = (this.returnForm.get('formReturnProducts') as FormArray)['controls'].map((item) => {
      return item.value.product;
    });
    return productsReturn.includes(item);
  }

  isSelectedService(item) {
    const servicesRefund = (this.returnForm.get('formReturnServices') as FormArray)['controls'].map((item) => {
      return item.value.service;
    });
    return servicesRefund.includes(item);
  }

  isSelectedFee(item) {
    const feeRefund = (this.returnForm.get('formReturnFees') as FormArray)['controls'].map((item) => {
      return item.value.fee;
    });
    return feeRefund.includes(item);
  }

  isSelectedPaidCancellation(item) {
    const paidCancellationRefund = (this.returnForm.get('formPaidCancellations') as FormArray)['controls'].map(
      (item) => {
        return item.value.paidCancellation;
      }
    );
    return paidCancellationRefund.includes(item);
  }

  private getFormArrayValues(arrayNameName: string) {
    return (this.returnForm.get(arrayNameName) as FormArray)['controls'].map((item) => item.value);
  }

  async onReturnFormSubmit() {
    this.loading = true;
    let returnInvoice: Invoice;
    switch (this.returnType) {
      case PaymentType.RefundProduct:
        const productsToReturn: ProductPurchased[] = this.getFormArrayValues('formReturnProducts').map((value) => {
          if (value.productQuantity) value.product.quantity = Number(value.productQuantity);
          return value.product;
        });
        returnInvoice = await this.invoicesService
          .createReturnInvoiceForProductsPurchased(this.patientId, productsToReturn)
          .toPromise();
        break;

      case PaymentType.RefundService:
        const servicesToRefund: ServicePurchased[] = [];
        this.getFormArrayValues('formReturnServices').forEach((value) => {
          const returnService = value.service as ServicePurchased;
          if (returnService.plannedTreatmentMultipleId) {
            this.plannedTreatmentMultipleList.forEach((planedTreatment) => {
              servicesToRefund.push({
                name: returnService.name,
                purchaseDate: returnService.purchaseDate,
                unitPrice: returnService.unitPrice / this.plannedTreatmentMultiple.quantity,
                pricePaid: this.plannedTreatmentMultiple.price,
                serviceId: planedTreatment.service.serviceId,
                quantity: 1,
                invoiceLineItemId: returnService.invoiceLineItemId,
                invoiceId: returnService.invoiceId,
                serviceTemplateId: returnService.serviceTemplateId,
                serviceTypeDescription: `Package Refund - 1/${this.plannedTreatmentMultiple.quantity}`,
                plannedTreatmentMultipleId: returnService.plannedTreatmentMultipleId,
                plannedTreatmentMultiple: this.plannedTreatmentMultiple,
              });
            });
          } else {
            servicesToRefund.push(returnService);
          }
        });
        const continueRefund = await this.returnService.checkForServiceCancellation(servicesToRefund);
        if (continueRefund) {
          returnInvoice = await this.invoicesService
            .createReturnInvoiceForServicesPurchased(this.patientId, servicesToRefund)
            .toPromise();
        }
        break;

      case PaymentType.RefundCredit:
        const creditsToRefund: number[] = this.getFormArrayValues('formReturnCredits').map(
          (credit) => credit.creditQuantity
        );
        returnInvoice = await this.invoicesService
          .createReturnInvoiceForCreditsPurchased(this.patientId, creditsToRefund)
          .toPromise();
        break;
      case PaymentType.RefundAdminFee:
        const feesToRefund = this.getFormArrayValues('formReturnFees').map((value) => {
          const fee = new AdminFeePurchased(value.fee);
          fee.serviceTypeDescription += ` for Invoice #${fee.invoiceId}`;
          return fee;
        });
        returnInvoice = await this.invoicesService
          .createReturnInvoiceForAdminFeesPurchased(this.patientId, feesToRefund)
          .toPromise();
        break;
      case PaymentType.RefundPaidCancellation:
        const paidCancellationsToRefund = this.getFormArrayValues('formPaidCancellations').map((value) => {
          const paidCancellation = new PaidCancellation(value.paidCancellation);
          paidCancellation.description += ` for Invoice #${paidCancellation.invoiceId}`;
          return paidCancellation;
        });
        this.paidCancellation = paidCancellationsToRefund[0];
        returnInvoice = await this.invoicesService
          .createReturnInvoiceForPaidCancellations(this.patientId, paidCancellationsToRefund)
          .toPromise();
        break;
      default:
    }

    this.loading = false;
    this.plannedTreatmentMultipleList = [];

    if (returnInvoice) {
      this.isOpenPaymentModal = true;
      this.selectedInvoice$.next(returnInvoice);
    }
  }

  nextDisabled(): boolean {
    switch (this.returnType) {
      case PaymentType.RefundProduct:
        return !this.formReturnProducts?.valid || !this.isEnteredQuantityValid;
      case PaymentType.RefundService:
        return !this.formReturnServices?.valid;
      case PaymentType.RefundCredit:
        return !this.isEnteredQuantityValid;
      case PaymentType.RefundAdminFee:
        return !this.formReturnFees?.valid;
      case PaymentType.RefundPaidCancellation:
        return !this.formPaidCancellations?.valid;
      default:
        return true;
    }
  }

  onSelectProductsPurchased(index: number) {
    const formReturnProducts = (<FormArray>this.returnForm.get('formReturnProducts')).at(index);
    formReturnProducts['controls'].productQuantity.setValue(1);
  }

  onSelectServicePurchased(index: number) {
    this.plannedTreatmentMultiplesForm.reset();
    this.plannedTreatmentMultipleList = [];
    this.plannedTreatmentMultiple = null;

    const returnService = this.formReturnServices.at(index).value?.service as ServicePurchased;

    if (returnService?.plannedTreatmentMultipleId) {
      var plannedTreatmentMultipleId = returnService.plannedTreatmentMultipleId;
      this.treatmentPlanService
        .getPlannedTreatmentMultiple(plannedTreatmentMultipleId)
        .pipe(takeUntil(this.unsub))
        .subscribe((plannedTreatmentMultiple: PlannedTreatmentMultiple) => {
          this.plannedTreatmentMultiple = plannedTreatmentMultiple;
          this.isPlannedTreatmentMultiple = true;
        });
    } else {
      this.isPlannedTreatmentMultiple = false;
      this.plannedTreatmentMultiple = null;
    }
  }

  public plannedTreatmentMultipleClick(plannedTreatment: PlannedTreatment) {
    if (this.plannedTreatmentMultipleList.includes(plannedTreatment)) {
      this.plannedTreatmentMultipleList.splice(this.plannedTreatmentMultipleList.indexOf(plannedTreatment), 1);
    } else {
      this.plannedTreatmentMultipleList.push(plannedTreatment);
    }
  }

  onInvoicePay() {
    this.initForm();
    this.isOpenPaymentModal = false;
    this.invoicePay.next(true);
  }

  backToReturnPanel() {
    const invoice = this.selectedInvoice$.getValue();
    this.removeInvoice(invoice);
    this.selectedInvoice$.next(null);
    this.initForm();
    this.isOpenPaymentModal = false;
  }

  private checkIfInvoiceUnpaid() {
    const invoice = this.selectedInvoice$.getValue();
    if (invoice && invoice.id && !invoice.isPaid) {
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '300px',
        data: {
          title: 'Leave Invoice',
          content:
            'An Invoice has been created for this return but has not yet been paid. Do you want to save it for later?',
          confirmButtonText: 'Save',
          cancelButtonText: 'Remove',
          showCancel: true,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'confirm') {
          this.invoicesService.invoicesListUpdated.next(invoice);
        } else {
          this.removeInvoice(invoice);
        }
        this.selectedInvoice$.next(null);
        this.isOpenPaymentModal = false;
        this.initForm();
      });
    }
  }

  private removeInvoice(invoice: Invoice) {
    if (invoice && invoice.id && !invoice.isPaid) {
      this.loading = true;
      if (invoice.invoiceLineItems?.length) {
        this.invoicesService
          .getInvoiceById(invoice.id)
          .pipe(
            mergeMap((invoice) =>
              forkJoin(
                invoice.invoiceLineItems.map((lineItem) => this.invoicesService.deleteInvoiceLineItem(lineItem.id))
              )
            ),
            mergeMap(() => this.invoicesService.deleteInvoice(invoice.id))
          )
          .subscribe(
            () => (this.loading = false),
            (error) => (this.loading = false)
          );
      } else {
        this.invoicesService.deleteInvoice(invoice.id).subscribe(
          () => (this.loading = false),
          (error) => (this.loading = false)
        );
      }
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
    this.selectedInvoice$.next(null);
    this.selectedInvoice$.complete();
  }
}
