import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { TreatmentPlan } from '@models/treatment-planning/treatment-plan';
import { Patient } from '@models/patient';
import { Subject, zip, Observable, merge } from 'rxjs';
import { PatientService } from '@services/patient.service';
import { takeUntil, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { isNullOrUndefined } from '@app/shared/helpers';
import { ClinicProduct } from '@models/clinic-product';
import { ClinicProductsService } from '@services/clinic-products.service';
import { ProductRecommendation } from '@models/treatment-planning/product-recommendation';
import * as moment from 'moment';
import { TreatmentPlanFormService } from '@services/treatment-planning/treatment-plan-form.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { GenericDialogComponent } from '../../../../../../management/dialogs/generic-confirm/generic-confirm.component';

@Component({
  selector: 'app-product-recommendation-list',
  templateUrl: './product-recommendation-list.component.html',
  styleUrls: ['./product-recommendation-list.component.less'],
})
export class ProductRecommendationListComponent implements OnInit, OnDestroy {
  patient: Patient;
  treatmentPlan: TreatmentPlan;
  unsub = new Subject<any>();
  productRecommendationsForm: FormGroup;
  clinicProducts: ClinicProduct[];
  loading = false;
  formLoaded = false;
  productRecommendationList: ProductRecommendation[];
  productRecommendationsToDelete: number[];
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  @ViewChild('productInput') productInput: ElementRef<HTMLInputElement>;
  inputFocus$ = new Subject<string>();

  constructor(
    private patientService: PatientService,
    private treatmentPlanService: TreatmentPlanService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private clinicProductsService: ClinicProductsService,
    public treatmentPlanFormService: TreatmentPlanFormService
  ) {
    this.patient = this.patientService.patientPanelPatient;
    this.productRecommendationList = [];
    this.productRecommendationsToDelete = [];
  }

  ngOnInit() {
    this.loading = true;

    zip(
      this.clinicProductsService.getProducts(),
      this.treatmentPlanService.getTreatmentPlanByPatientId(this.patient.patientId)
    ).subscribe(([cp, tp]) => {
      this.treatmentPlan = tp;
      this.loading = false;
      this.clinicProducts = cp;
      this.initForm();
      this.loading = false;
    });

    this.patientService.thePatientUpdated$.pipe(takeUntil(this.unsub)).subscribe((patient) => {
      this.patient = patient;
      this.getTreatmentPlan();
    });

    this.treatmentPlanService.treatmentPlanUpdated$.pipe(takeUntil(this.unsub)).subscribe((tp) => {
      this.treatmentPlan = tp;
      this.productRecommendationsToDelete = [];
      this.initForm();
      this.loading = false;
    });
  }

  private getTreatmentPlan() {
    this.treatmentPlanService.getTreatmentPlanByPatientId(this.patient.patientId).subscribe((tp) => {
      this.treatmentPlan = tp;
      this.initForm();
      this.loading = false;
    });
  }

  initForm() {
    if (!isNullOrUndefined(this.treatmentPlan)) {
      this.productRecommendationsForm = this.fb.group({
        productRecommendations: this.fb.array([]),
      });
      this.initFormProductRecommendations();
      this.onFormChange();
      this.formLoaded = true;
    }
  }

  initFormProductRecommendations() {
    this.productRecommendationList = [];
    const formArray = <FormArray>this.productRecommendationsForm.get('productRecommendations');
    if (!isNullOrUndefined(this.treatmentPlan.productRecommendations)) {
      this.treatmentPlan.productRecommendations.forEach((pr) => {
        this.productRecommendationList.push(pr);
        formArray.push(
          this.fb.group({
            id: [pr.id],
            treatmentPlanId: [this.treatmentPlan.id],
            createdDate: [pr.createdDate],
            usageInstructions: [pr.usageInstructions],
            quantity: [pr.quantity],
            retailPrice: [pr.retailPrice],
            overrideRetailPrice: [pr.overrideRetailPrice],
            clinicProduct: this.fb.group(pr.clinicProduct),
            clinicProductId: pr.clinicProduct.id,
          })
        );
      });
    } else {
      this.treatmentPlan.productRecommendations = [];
    }
    this.loading = false;
  }

  onFormChange() {
    this.productRecommendationsForm.valueChanges.subscribe(() => {
      const productRecommendations: ProductRecommendation[] = [];
      this.getProductRecommendationControls().forEach((pt) => {
        const productRecommendation = new ProductRecommendation(pt.value);
        productRecommendations.push(productRecommendation);
      });
      this.treatmentPlanFormService.updateProductRecomendations(
        productRecommendations,
        this.productRecommendationsToDelete,
        this.productRecommendationsForm.valid
      );
    });
  }

  async updateRecommendedProduct(productRecommendation: ProductRecommendation) {
    await this.treatmentPlanService.updateProductRecommendations(productRecommendation).toPromise();
  }

  async onDeleteProductRecommendation(index: number, productRecommendation: ProductRecommendation) {
    if (productRecommendation.id > 0 && (await this.verifyRemovalOfProduct().toPromise())) {
      // The recommendation has been saved in the database so we must remove it from the database as well
      // this.treatmentPlanFormService.treatmentPlanDirty = true;
      // this.productRecommendationsToDelete.push(productRecommendation.id);
      await this.treatmentPlanService.deleteProductRecommendations([productRecommendation.id]).toPromise();
      (<FormArray>this.productRecommendationsForm.get('productRecommendations')).removeAt(index);
      this.productRecommendationList.splice(index, 1);
    }
  }

  async onAddProductRecommendation(clinicProduct: ClinicProduct) {
    // const pr = new ProductRecommendation({
    //   id: 0,
    //   treatmentPlanId: this.treatmentPlan.id,
    //   createdDate: moment(),
    //   usageInstructions: product.usageInstructions,
    //   quantity: product.quantity,
    //   retailPrice: product.retailPrice,
    //   overrideRetailPrice: product.overrideRetailPrice,
    //   product: product,
    //   productId: product.productId
    // });

    // (<FormArray>this.productRecommendationsForm.get('productRecommendations')).push(this.fb.group({
    //   id: [pr.id],
    //   treatmentPlanId: [this.treatmentPlan.id],
    //   createdDate: [pr.createdDate],
    //   usageInstructions: [pr.usageInstructions],
    //   quantity: [pr.quantity, Validators.required],
    //   retailPrice: [pr.retailPrice],
    //   overrideRetailPrice: [pr.overrideRetailPrice],
    //   product: this.fb.group(pr.product),
    //   productId: pr.product.productId
    // }));

    // const newService = this.servicesService.mapServiceFromTemplate(serviceTemplate, this.patientService.patientPanelPatient);
    // newService.recommendedProducts = [];
    // if (isNullOrUndefined(newService.observations)) {
    //   newService.observations = [];
    // }
    // // Save the new service
    this.productInput.nativeElement.blur(); // Deselects input allowing click to open drop-down again
    let productRecommendation = await this.treatmentPlanService
      .createProductRecommendations(this.treatmentPlan.id, clinicProduct)
      .toPromise();
    this.productRecommendationList.push(productRecommendation);
    (<FormArray>this.productRecommendationsForm.get('productRecommendations')).push(
      this.fb.group({
        id: [productRecommendation.id],
        treatmentPlanId: [this.treatmentPlan.id],
        createdDate: [productRecommendation.createdDate],
        usageInstructions: [productRecommendation.usageInstructions],
        quantity: [productRecommendation.quantity, Validators.required],
        retailPrice: [productRecommendation.retailPrice],
        overrideRetailPrice: [productRecommendation.overrideRetailPrice],
        clinicProduct: this.fb.group(clinicProduct),
        clinicProductId: clinicProduct.id,
      })
    );
    this.treatmentPlan.productRecommendations.push(productRecommendation);
    this.treatmentPlanFormService.treatmentPlanUpdated(this.treatmentPlan);
  }

  getProductRecommendationControls() {
    if (isNullOrUndefined(this.treatmentPlan)) {
      return this.fb.array([]).controls;
    }
    return (<FormArray>this.productRecommendationsForm.get('productRecommendations')).controls;
  }

  public productsInputFormatter = (input: string) => null;

  public productsResultFormatter = (result: ClinicProduct) => result.displayName;

  public searchProducts = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.inputFocus$;
    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        term === ''
          ? this.clinicProducts
          : this.clinicProducts.filter((v) => v.displayName.toLowerCase().indexOf(term.toLowerCase()) > -1)
      )
    );
  };

  private verifyRemovalOfProduct(): Observable<boolean> {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '300px',
      data: {
        title: 'Warning',
        content: 'Are you sure you want to remove this product recommendation?',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancel: true,
      },
    });

    return dialogRef.afterClosed().pipe(
      takeUntil(this.unsub),
      map((result) => {
        if (result === 'confirm') {
          return true;
        }
        return false;
      })
    );
  }

  onFocus(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.inputFocus$.next(value);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
