import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommunicationsService } from '@services/communications.service';
import { MailjetEmailTemplate } from '@models/communications/mailjet-email-template.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, repeat } from 'rxjs/operators';
import { FormControl, Validators, FormGroup, AbstractControl, ValidatorFn } from '@angular/forms';
import {
  CommunicationSettingType,
  CommunicationsSettingsBase,
} from '@models/communications/notifications-settings-base.model';
import { isNullOrUndefined } from '@app/shared/helpers';
import { EmailNotificationsSettings } from '@models/communications/email-notifications-settings.model';
import { SmsNotificationsSettings } from '@models/communications/sms-notifications-settings.model';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-visit-reminder-settings',
  templateUrl: './edit-visit-reminder-settings.component.html',
  styleUrls: ['./edit-visit-reminder-settings.component.less'],
})
export class EditVisitReminderSettingsComponent implements OnInit, OnDestroy {
  private unsub: Subject<void> = new Subject<void>();
  loading: boolean;
  type: string;
  reminderSettingsForm: FormGroup;
  emailTemplates: MailjetEmailTemplate[] = [];
  minEndTime = moment().toDate();
  minimumDuration: number = 5;
  senderUnits: string[] = ['hours', 'days', 'weeks', 'months'];

  workerStartTime = moment().startOf('day').toDate();
  workerEndTime = moment().startOf('day').toDate();

  defaultStartTime = '7:00:00';
  defaultEndTime = '19:00:00';

  enabled: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private communicationsService: CommunicationsService
  ) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      this.type = params['type'];
      this.initForm(this.type);
      this.toggleRepeatNotificationValidators();
      this.populateCommunicationSettingsValue(this.type);
    });
  }

  initForm(type: string) {
    this.reminderSettingsForm = new FormGroup({
      emailTemplate: new FormControl(
        '',
        this.conditionalValidator(() => type === 'email', Validators.required)
      ),
      emailSenderName: new FormControl(
        '',
        this.conditionalValidator(() => type === 'email', Validators.required)
      ),
      emailSenderEmail: new FormControl('', [
        Validators.email,
        this.conditionalValidator(() => type === 'email', Validators.required),
      ]),
      smsMessage: new FormControl(
        '',
        this.conditionalValidator(() => type === 'sms', Validators.required)
      ),
      smsTitle: new FormControl(
        '',
        this.conditionalValidator(() => type === 'sms', Validators.required)
      ),
      firstNotificationPeriod: new FormControl('', Validators.required),
      firstNotificationUnit: new FormControl('', Validators.required),
      repeatNotificationPeriod: new FormControl('', Validators.required),
      repeatNotificationUnit: new FormControl('', Validators.required),
      enabledRepeatNotification: new FormControl(true),
    });
  }

  toggleRepeatNotificationValidators() {
    this.reminderSettingsForm.controls['enabledRepeatNotification'].valueChanges.subscribe((status: boolean) => {
      this.reminderSettingsForm.controls['repeatNotificationPeriod'].setValidators(
        status ? [Validators.required] : null
      );
      this.reminderSettingsForm.controls['repeatNotificationUnit'].setValidators(status ? [Validators.required] : null);
      this.reminderSettingsForm.controls['repeatNotificationPeriod'].updateValueAndValidity();
      this.reminderSettingsForm.controls['repeatNotificationUnit'].updateValueAndValidity();
    });
  }

  conditionalValidator(condition: () => boolean, validator: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!condition()) {
        return null;
      }
      return validator(control);
    };
  }

  populateCommunicationSettingsValue(type: string) {
    this.loading = true;
    if (type === 'email') {
      forkJoin([
        this.communicationsService.getEmailTemplates(),
        this.communicationsService.getEmailNotificationsSettings(),
      ])
        .pipe(takeUntil(this.unsub))
        .subscribe(([templates, settings]) => {
          let reminderSettings = settings.find(
            (es) => es.communicationSettingType === CommunicationSettingType.Reminder
          );
          this.populateEmailSettingsValues(templates, reminderSettings);
          this.populateCommonSettingsValues(reminderSettings);
          this.loading = false;
        });
    } else if (type === 'sms') {
      this.communicationsService
        .getSmsNotificationsSettings()
        .pipe(takeUntil(this.unsub))
        .subscribe((settings) => {
          this.populateSmsSettings(settings);
          this.populateCommonSettingsValues(settings);
          this.loading = false;
        });
    }
  }

  populateEmailSettingsValues(templates: MailjetEmailTemplate[], settings: EmailNotificationsSettings) {
    this.emailTemplates = templates;
    let selectedTemplate = this.emailTemplates.find((_) => _.id === settings.templateReferenceId);
    if (isNullOrUndefined(selectedTemplate) && templates.length > 0) {
      selectedTemplate = templates[0];
    }
    this.reminderSettingsForm.controls['emailTemplate'].setValue(selectedTemplate);
    this.reminderSettingsForm.controls['emailSenderName'].setValue(settings.senderName);
    this.reminderSettingsForm.controls['emailSenderEmail'].setValue(settings.senderEmail);
  }

  populateSmsSettings(settings: SmsNotificationsSettings) {
    this.reminderSettingsForm.controls['smsTitle'].setValue(settings.title);
    this.reminderSettingsForm.controls['smsMessage'].setValue(settings.message);
  }

  populateCommonSettingsValues(settings: CommunicationsSettingsBase) {
    if (settings.workerStartTime.hours() == 0) {
      settings.workerStartTime = moment.duration(this.defaultStartTime);
    }

    if (settings.workerEndTime.hours() == 0) {
      settings.workerEndTime = moment.duration(this.defaultEndTime);
    }

    this.enabled = settings.enabled;
    this.workerStartTime = moment().startOf('day').add(settings.workerStartTime).toDate();
    this.workerEndTime = moment().startOf('day').add(settings.workerEndTime).toDate();
    if (settings.firstNotificationBefore) {
      this.parseSenderValue(settings.firstNotificationBefore, 'firstNotificationPeriod', 'firstNotificationUnit');
    }

    if (settings.repeatNotificationEvery) {
      this.parseSenderValue(settings.repeatNotificationEvery, 'repeatNotificationPeriod', 'repeatNotificationUnit');
    } else {
      this.reminderSettingsForm.controls['enabledRepeatNotification'].setValue(false);
    }
  }

  saveChanges() {
    if (this.type === 'email') {
      this.saveEmailSettings();
    } else if (this.type === 'sms') {
      this.saveSmsSettings();
    }
    this.router.navigate(['/management/communication/appointments/', { outlets: { 'action-panel': null } }]);
  }

  saveEmailSettings() {
    const data = new EmailNotificationsSettings();
    data.enabled = this.enabled;
    data.communicationSettingType = CommunicationSettingType.Reminder;
    data.templateReferenceId = (this.reminderSettingsForm.controls['emailTemplate'].value as MailjetEmailTemplate).id;
    data.senderName = this.reminderSettingsForm.controls['emailSenderName'].value;
    data.senderEmail = this.reminderSettingsForm.controls['emailSenderEmail'].value;
    data.workerStartTime = this.stripTimeAsDuration(this.workerStartTime);
    data.workerEndTime = this.stripTimeAsDuration(this.workerEndTime);
    data.firstNotificationBefore = this.formatSenderValue('firstNotificationPeriod', 'firstNotificationUnit');
    if (this.reminderSettingsForm.controls['enabledRepeatNotification'].value) {
      data.repeatNotificationEvery = this.formatSenderValue('repeatNotificationPeriod', 'repeatNotificationUnit');
    }
    this.communicationsService.updateEmailNotificationsSettings(data).subscribe(() => {
      this.communicationsService.refreshRequired = true;
      this.communicationsService.communicationsSettingsUpdated.next();
    });
  }

  saveSmsSettings() {
    const data = new SmsNotificationsSettings();
    data.enabled = this.enabled;
    data.communicationSettingType = CommunicationSettingType.Reminder;
    data.title = this.reminderSettingsForm.controls['smsTitle'].value;
    data.message = this.reminderSettingsForm.controls['smsMessage'].value;
    data.workerStartTime = this.stripTimeAsDuration(this.workerStartTime);
    data.workerEndTime = this.stripTimeAsDuration(this.workerEndTime);
    data.firstNotificationBefore = this.formatSenderValue('firstNotificationPeriod', 'firstNotificationUnit');
    if (this.reminderSettingsForm.controls['enabledRepeatNotification'].value) {
      data.repeatNotificationEvery = this.formatSenderValue('repeatNotificationPeriod', 'repeatNotificationUnit');
    }
    this.communicationsService.updateSmsNotificationsSettings(data).subscribe(() => {
      this.communicationsService.refreshRequired = true;
      this.communicationsService.communicationsSettingsUpdated.next();
    });
  }

  cancelUpdate() {
    this.communicationsService.refreshRequired = false;
    this.communicationsService.communicationsSettingsUpdated.next();
    this.router.navigate(['/management/communication/appointments/', { outlets: { 'action-panel': null } }]);
  }

  onStartTimeChange(date: Date) {
    this.minEndTime = moment(date).add(this.minimumDuration.toString(), 'minutes').toDate();
  }

  onEndTimeChange(date: Date) {}

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  private parseSenderValue(value: string, periodCtrl: string, unitCtrl: string) {
    const split = value.split(' ');
    if (split.length === 1) {
      this.reminderSettingsForm.controls[periodCtrl].setValue(1);
      this.reminderSettingsForm.controls[unitCtrl].setValue(split[0] + 's');
    } else {
      this.reminderSettingsForm.controls[periodCtrl].setValue(split[0]);
      this.reminderSettingsForm.controls[unitCtrl].setValue(split[1]);
    }
  }

  private formatSenderValue(periodCtrl: string, unitCtrl: string): string {
    const value = this.reminderSettingsForm.controls[periodCtrl].value;
    const unit = this.reminderSettingsForm.controls[unitCtrl].value;
    if (value === 1) {
      return unit.substr(0, unit.length - 1);
    }
    return value + ' ' + unit;
  }

  private stripTimeAsDuration(dateTime: Date): moment.Duration {
    const startStr = dateTime.getHours() + ':' + dateTime.getMinutes() + ':00';
    return moment.duration(startStr);
  }
}
