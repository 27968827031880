<div class="grid-container pb-2">
  <div class="d-flex flex-column w-33 mr-2">
    <div class="d-flex flex-column w-100 h-50">
      <div class="section-header ml-1">Payment Methods</div>
      <app-org-payment-methods class="w-100"></app-org-payment-methods>
    </div>
    <div class="d-flex flex-column w-100 h-50">
      <div class="section-header ml-1 mt-2">Taxes</div>
      <app-org-taxes class="w-100"></app-org-taxes>
    </div>
    <div class="d-flex flex-column w-100 h-50">
      <div class="section-header ml-1 mt-2">Government Billing</div>
      <app-billing-settings-list></app-billing-settings-list>
    </div>
  </div>
  <div class="d-flex flex-column w-33 ml-2">
    <div class="section-header ml-1">Invoice Text</div>
    <app-org-invoice-text-editor class="ml-1"></app-org-invoice-text-editor>
  </div>
  <div class="d-flex flex-column w-33 ml-4">
    <div class="section-header">Payment Processors</div>
    <div class="border flex-grow-1">
      <app-org-payment-processors class="ml-1"></app-org-payment-processors>
    </div>
  </div>
</div>
