<ngx-loading [show]="loading"></ngx-loading>

<div class="m-2">
  <!--Close Button-->
  <button *ngIf="modalView" class="d-flex ml-auto btn em-btn em-btn-green mr-2 mb-1" (click)="closeDialog()">
    <i class="far fa-times" title="Close"></i>
  </button>

  <div class="card document-container mx-2 mb-2">
    <div class="card-body">
      <app-form-viewer
        class="mx-2"
        [form]="appointmenteTreatmentForm"
        [submission]="appointmenteTreatmentForm.formEntry"
        [editable]="editable"
        [modalView]="modalView"
        (submitted)="onFormSubmit($event)"
        (changed)="onFormChange($event)"
      >
      </app-form-viewer>
    </div>
  </div>
</div>
