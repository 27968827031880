import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BookingServiceCategoryService } from '@services/booking-categories.service';
import { FormatterService } from '@services/formatter.service';
import { BookingServiceCategory } from '@models/booking-service-category';

@Component({
  selector: 'app-online-booking-category-provider',
  templateUrl: './edit-online-booking-category.component.html',
  styleUrls: ['./edit-online-booking-category.component.less'],
})
export class EditOnlineBookingCategoryComponent implements OnInit {
  loading = false;
  addOrEdit = 'Add';
  categoryIdParam: string;

  name: FormControl;
  isNew: boolean;

  public selectedCategory: BookingServiceCategory;
  public editedCategory: BookingServiceCategory;

  constructor(
    private bookingCategoryService: BookingServiceCategoryService,
    public formatterService: FormatterService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.name = new FormControl();
  }

  ngOnInit() {
    this.loading = true;
    this.selectedCategory = this.initCategory(this.selectedCategory);
    this.editedCategory = this.initCategory(this.editedCategory);
    this.isNew = true;
    this.addOrEdit = 'Add';
    this.route.params.subscribe((params) => {
      this.categoryIdParam = params['catId'];
      if (this.categoryIdParam !== '_' && this.categoryIdParam != null) {
        this.bookingCategoryService.getBookingServiceCategoryById(this.categoryIdParam).subscribe((category) => {
          this.selectedCategory = category;
          this.editedCategory = category;
          this.isNew = false;
          this.addOrEdit = 'Edit';
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    });
  }

  updateCategory() {
    if (this.isNew) {
      this.bookingCategoryService.addBookingServiceCategory(this.editedCategory).subscribe(() => {
        this.bookingCategoryService.updateRefreshRequired(true);
        this.router.navigate([
          '/management/organization/clinics/online-booking',
          { outlets: { 'action-panel': null } },
        ]);
      });
    } else {
      this.bookingCategoryService.updateBookingServiceCategory(this.editedCategory).subscribe(() => {
        this.bookingCategoryService.updateRefreshRequired(true);
        this.router.navigate([
          '/management/organization/clinics/online-booking',
          { outlets: { 'action-panel': null } },
        ]);
      });
    }
  }

  cancelUpdate() {
    this.bookingCategoryService.updateRefreshRequired(false);
    this.router.navigate(['/management/organization/clinics/online-booking', { outlets: { 'action-panel': null } }]);
  }

  initCategory(category: BookingServiceCategory) {
    category = {
      bookingServiceCategoryId: 0,
      categoryName: '',
    };
    return category;
  }
}
