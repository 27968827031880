<ngx-loading [show]="loading"></ngx-loading>
<div class="edit-container position-absolute d-block z-index-3 white-background">
  <div class="re-links-container" kendoTooltip tooltipClass="em-tooltip" position="top">
    <div class="re-links">
      <button class="btn em-btn" (click)="cancelUpdate()">
        <i class="far fa-times" aria-hidden="true" title="Close Panel"></i>
      </button>
    </div>
  </div>
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0">
    <div class="row teal-background">
      <div class="col">
        <h6 class="white-font uppercase p-2 mb-0">Edit Service</h6>
      </div>
    </div>
    <form [formGroup]="form">
      <div class="row mx-0 mt-2">
        <div class="col px-2">
          <mat-form-field class="w-100">
            <input
              matInput
              placeholder="Service Name Reference"
              class="capitalized"
              aria-label="Service Name Reference"
              formControlName="serviceName"
            />
          </mat-form-field>
        </div>
        <div class="col px-2">
          <mat-form-field class="w-100">
            <input
              matInput
              placeholder="Online Service Display Name"
              class="capitalized"
              aria-label="Online Service Display Name"
              formControlName="onlineName"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0 mt-2">
        <div class="col-6 px-2">
          <mat-form-field class="w-100">
            <input
              matInput
              placeholder="Price Reference"
              class="capitalized"
              aria-label="Price Reference"
              formControlName="servicePrice"
            />
          </mat-form-field>
        </div>
        <div class="col-6 px-2">
          <mat-form-field class="w-100">
            <input
              matInput
              placeholder="Online Display Price"
              class="capitalized"
              aria-label="Online Display Price"
              formControlName="onlinePrice"
              onlyNumber
              numericType="decimal"
            />
            <span matPrefix>$&nbsp;</span>
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0 mt-2">
        <div class="col-6 px-2">
          <mat-form-field class="w-100">
            <input
              matInput
              placeholder="Duration Reference"
              class="capitalized"
              aria-label="Duration Reference"
              formControlName="serviceDuration"
            />
          </mat-form-field>
        </div>
        <div class="col-6 px-2">
          <mat-form-field class="w-100">
            <input
              matInput
              placeholder="Online Display Duration"
              class="capitalized"
              aria-label="Online Display Duration"
              formControlName="onlineDuration"
              onlyNumber
            />
            <span matSuffix>mins</span>
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0 mt-2">
        <div class="col px-2">
          <mat-form-field class="w-100">
            <textarea
              matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="5"
              placeholder="Description"
              class="capitalized light-border"
              aria-label="Description"
              formControlName="onlineDescription"
            ></textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="row mx-0 mt-2">
        <div class="col px-2">
          <mat-form-field>
            <mat-select placeholder="Category" formControlName="selectCategory" name="category">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let category of allCategories" [value]="category.bookingServiceCategoryId">
                {{ category.categoryName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!--  <mat-form-field>
            <mat-select
              placeholder="Service Providers"
              multiple="true"
              formControlName="selectedServiceProviders"
              [(ngModel)]="serviceProvidersSelected"
              [compareWith]="compareServiceProviders"
            >
              <mat-option
                *ngFor="let serviceProvider of serviceProviders"
                [value]="serviceProvider"
                [disabled]="!serviceProvider.availableForOnlineBooking"
              >
                {{ serviceProvider.title }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>
        <div class="col px-2">
          <mat-form-field id="prerequisitesField">
            <mat-select placeholder="Prerequisites" formControlName="selectedPrerequisites" name="Prerequisites">
              <!-- Add multiple back to mat-select to have more than 1 prereq -->
              <mat-option>None</mat-option>
              <mat-option *ngFor="let prerequisite of allServiceTemplatesPrerequisites" [value]="prerequisite.id">
                {{ prerequisite.serviceName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0 mt-2 align-items-center">
        <div class="col-6 px-2"></div>
        <div class="col-6">
          <label class="switch">
            <input
              type="checkbox"
              formControlName="isPrerequisiteItself"
              name="isPrerequisiteItself"
              aria-label="Prerequisite to itself"
              id="isPrerequisiteItself"
            />
            <span [ngStyle]="{ 'pointer-events': 'none' }" class="slider round"></span>
          </label>
          <span class="pl-2">Prerequisite to itself</span>
          <div><span class="text-danger small-font">This will override prerequisites</span></div>
        </div>
      </div>

      <div class="row mx-0 mt-5">
        <div class="col px-2 text-center">
          <button
            class="btn em-btn em-btn-green"
            (click)="updateService()"
            [disabled]="editedService?.serviceName === '' || !form.dirty"
          >
            Update
          </button>
        </div>
        <div class="col px-2 text-center">
          <button class="btn em-btn em-btn-green" (click)="cancelUpdate()">Cancel</button>
        </div>
      </div>
      <ng-container *ngIf="displayError">
        <div class="row mx-0 justify-content-center">
          <span class="text-danger">{{ errorMsg }}</span>
        </div>
      </ng-container>
    </form>
  </perfect-scrollbar>
</div>
