import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validator function that checks if two password inputs match.
 *
 * The function expects the input to be an object with two properties: `password`
 * and `passwordConfirm`. If the two properties have the same value, the function
 * returns `null`. Otherwise, it returns an object with the property
 * `passwordsNotMatching` set to `true`.
 *
 * @returns {ValidationErrors | null}
 */
export function passwordsMatchValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value.password === control.value.passwordConfirm) {
      return null;
    }
    control.get('passwordConfirm').setErrors({ passwordsNotMatching: true });
    return { passwordsNotMatching: true };
  };
}
