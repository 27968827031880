import { Injectable } from '@angular/core';
import { ColourVariables } from '@models/constants/colour-variables';
import { Observation, ObservationListItem, ObservationListStatic } from '@models/observation/observation';
import { ReplaySubject, Subject } from 'rxjs';
import { ObservationListItemsService } from '../observation-list-items.service';
import { ObservationService } from '../observation.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CoolsculptingFormService {
  areas: ObservationListStatic[] = [];
  applicators: ObservationListItem[] = [];
  observationListItemsMap: Map<number, ObservationListItem> = new Map();
  observationListStaticMap: Map<number, ObservationListStatic> = new Map();
  // String const that will eventually be replaced with the vaccum type used (various depending on room)
  vacuum = '60-75-60';
  colours: string[];
  colourIndex: number;
  droppingPins: boolean;
  treatmentSaved: Subject<void>;
  treatmentRemoved: Subject<number>;
  saveTrigger: Subject<void>;
  saveTriggerComplete: Subject<number>;

  // List population observable flags
  private observationListsPopulatedSubject$ = new ReplaySubject<boolean>(1);
  public observationListsPopulated$ = this.observationListsPopulatedSubject$.asObservable();
  private observationListsStaticPopulatedSubject$ = new ReplaySubject<boolean>(1);
  public observationListsStaticPopulated$ = this.observationListsStaticPopulatedSubject$.asObservable();

  unsub: Subject<void> = new Subject<void>();

  constructor(
    private observationListItemsService: ObservationListItemsService,
    private observationService: ObservationService
  ) {
    const colourVariables = new ColourVariables();
    this.colours = colourVariables.getCoolsculptingColourList();
    this.colourIndex = 0;
    this.droppingPins = false;
    this.treatmentRemoved = new Subject();
    this.treatmentSaved = new Subject();
    this.saveTrigger = new Subject();
    this.saveTriggerComplete = new Subject();

    this.initObservationListsStaticChangedSub();
    this.initObservationListsChangedSub();

    this.initObservationListStatic();
    this.initObservationLists();
  }

  initObservationListsChangedSub() {
    this.observationListItemsService.observationListsChanged.pipe(takeUntil(this.unsub)).subscribe((oli) => {
      this.initObservationLists();
    });
  }

  initObservationListsStaticChangedSub() {
    this.observationListItemsService.observationListsStaticUpdated$.pipe(takeUntil(this.unsub)).subscribe((ols) => {
      this.initObservationListStatic();
    });
  }

  initObservationLists() {
    this.observationListItemsService.getAllObservationListItems().subscribe((observationListItems) => {
      observationListItems.forEach((oli) => {
        this.observationListItemsMap.set(oli.id, oli);
        if (oli.clinicSupplyType.observationListType.name === 'CoolSculpting Applicators') {
          // Assign a colour to the applicator
          oli.colour = this.onGetColour();
          this.applicators.push(oli);
        }
      });
      this.observationListsPopulatedSubject$.next(true);
    });
  }

  initObservationListStatic() {
    this.observationListItemsService.getObservationListStatic().subscribe((observationListStatic) => {
      observationListStatic.forEach((oli) => {
        this.observationListStaticMap.set(oli.id, oli);
        if (oli.observationListType.name === 'CoolSculpting Areas') {
          this.areas.push(oli);
        }
      });
      this.observationListsStaticPopulatedSubject$.next(true);
    });
  }

  onGetTreatmentText(obr: Observation, count: number) {
    this.observationService.detailsToJson([obr]);
    return {
      applicator: new ObservationListItem(this.observationListItemsMap.get(obr.details.applicator)).displayName,
      area: new ObservationListStatic(this.observationListStaticMap.get(obr.details.area)).name,
      unit: count,
      avaliable: obr.details.avaliable,
      totalDiscountPercentage: obr.details.isOverrideTotalPrice
        ? 1 - obr.details.overrideTotalPrice / (obr.details.productPrice * count)
        : obr.details.isOverrideProductPrice
        ? 1 - (obr.details.overrideProductPrice * count) / (obr.details.productPrice * count)
        : 0,
      price: obr.details.isOverrideTotalPrice
        ? obr.details.overrideTotalPrice
        : obr.details.isOverrideProductPrice
        ? obr.details.overrideProductPrice * count
        : obr.details.productPrice * count,
    };
  }

  onGetColour() {
    if (this.colourIndex >= this.colours.length) {
      this.colourIndex = 0;
    }
    const colour = this.colours[this.colourIndex];
    this.colourIndex++;
    return colour;
  }

  onGetMapKey(obr: Observation): string {
    // mapKey is area/applicator/position/angle
    return this.observationService.getObrKey(obr);
  }

  onGetArea(mapKey: string): ObservationListStatic {
    // mapKey is area/applicator/position/angle
    const areaId = parseInt(mapKey.split('/')[0], 10);
    return this.observationListItemsService.observationListStatic.get(areaId);
  }

  onGetApplicator(mapKey: string): ObservationListItem {
    // mapKey is area/applicator/position/angle
    const applicatorId = parseInt(mapKey.split('/')[1], 10);
    return this.observationListItemsService.observationListItems.get(applicatorId);
  }

  onGetPosition(mapKey: string): string {
    // mapKey is area/applicator/position/angle
    const position = mapKey.split('/')[2];
    return position.substring(0, 1).toUpperCase();
  }

  onGetCycles(observations: Observation[]): number {
    // Total cycles is the sum of the cycles for each observation
    let numCycles = 0;
    observations.forEach((obr: Observation) => {
      numCycles += parseInt(obr.value, 10);
    });
    return numCycles;
  }

  onGetCoolObrKey(observation: Observation): string {
    return this.onGetMapKey(observation);
  }

  onGetTreatmentGroupsCoolsculpting(observations: Observation[]) {
    let treatmentItems = [];
    if (observations && observations.length) {
      // We need to sort the observations to make sure that we get the same obrs grouped
      // together so the cycle counts are displayed correctly
      observations.sort((a: Observation, b: Observation) => {
        const aKey = this.onGetCoolObrKey(a);
        const bKey = this.onGetCoolObrKey(b);
        if (aKey < bKey) {
          return -1;
        }
        if (aKey > bKey) {
          return 1;
        }
        return 0;
      });
      let previousKey = this.onGetCoolObrKey(observations[0]);
      let treatmentCount = +observations[0].value;

      for (let i = 1; i < observations.length; i++) {
        const currKey = this.onGetCoolObrKey(observations[i]);
        if (currKey !== previousKey) {
          treatmentItems.push(this.onGetTreatmentText(observations[i - 1], treatmentCount));
          treatmentCount = +observations[i].value;
        } else {
          treatmentCount += +observations[i].value;
        }
        previousKey = currKey;
      }
      treatmentItems.push(this.onGetTreatmentText(observations[observations.length - 1], treatmentCount));
    }
    return treatmentItems;
  }
}
