import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ClinicConvergeMerchant } from '@models/payments/clinic-converge-merchant';

@Injectable({
  providedIn: 'root',
})
export class ClinicConvergeMerchantService {
  constructor(private http: HttpClient) {}

  addClinicConvergeMerchant(clinicConvergeMerchant: ClinicConvergeMerchant) {
    return this.http.post<ClinicConvergeMerchant>(
      environment.baseUrl + 'api/ClinicConvergeMerchants',
      clinicConvergeMerchant
    );
  }

  updateClinicConvergeMerchant(clinicConvergeMerchant: ClinicConvergeMerchant) {
    return this.http.put<ClinicConvergeMerchant>(
      environment.baseUrl + 'api/ClinicConvergeMerchants/' + clinicConvergeMerchant.id,
      clinicConvergeMerchant
    );
  }

  getClinicConvergeMerchant() {
    return this.http.get<ClinicConvergeMerchant>(environment.baseUrl + 'api/ClinicConvergeMerchants');
  }

  deleteClinicConvergeMerchant(clinicConvergeMerchantId: number) {
    return this.http.delete<ClinicConvergeMerchant>(
      `${environment.baseUrl}api/ClinicConvergeMerchants/${clinicConvergeMerchantId}`
    );
  }
}
