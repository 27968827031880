import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  ObservationListItem,
  ObservationUnit,
  Observation,
  ObservationListStatic,
} from '@models/observation/observation';
import { ObservationListItemsService } from '../observation-list-items.service';
import { ObservationUnitsService } from '../observation-units.service';
import { ObservationService } from '../observation.service';
import { ReplaySubject, Subject } from 'rxjs';
import { UsersService } from '@services/users.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InjectionFormService {
  // Observation list items
  fillers: ObservationListItem[] = [];
  fillerAreas: ObservationListStatic[] = [];
  toxins: ObservationListItem[] = [];
  toxinAreas: ObservationListStatic[] = [];
  needles: ObservationListItem[] = [];
  depths: ObservationListStatic[] = [];
  deliveries: ObservationListStatic[] = [];
  units: ObservationUnit[] = [];
  deoxycholates: ObservationListItem[] = [];
  deoxycholateAreas: ObservationListStatic[] = [];

  // Observation maps
  observationListItemsMap: Map<number, ObservationListItem> = new Map();
  observationUnitsMap: Map<number, ObservationUnit> = new Map();
  observationListStaticMap: Map<number, ObservationListStatic> = new Map();

  // List population observable flags
  private observationListsPopulatedSubject$ = new ReplaySubject<boolean>(1);
  public observationListsPopulated$ = this.observationListsPopulatedSubject$.asObservable();
  private observationListsStaticPopulatedSubject$ = new ReplaySubject<boolean>(1);
  public observationListsStaticPopulated$ = this.observationListsStaticPopulatedSubject$.asObservable();

  unsub: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private observationListItemsService: ObservationListItemsService,
    private observationUnitsService: ObservationUnitsService,
    private observationService: ObservationService,
    private usersService: UsersService
  ) {
    // HTTP requests for observation list items will fail if the user is not logged in
    // Wait for login before initializing the observation lists
    this.usersService.loggedInUserUpdated$.subscribe({
      next: (user) => {
        if (user && user.id) {
          this.initObservationLists();
          this.initObservationListStatic();
          this.initObservationUnits(null);
        }
      },
    });
    this.initObservationListsChangedSub();
  }

  initData() {}

  private initObservationListsChangedSub() {
    this.observationListItemsService.observationListsChanged.pipe(takeUntil(this.unsub)).subscribe((oli) => {
      this.initObservationLists();
    });
  }

  initObservationLists() {
    this.observationListItemsService.getAllObservationListItems().subscribe((observationListItems) => {
      this.observationListItemsMap.clear();
      this.toxins = [];
      this.fillers = [];
      this.needles = [];
      this.deoxycholates = [];
      observationListItems.forEach((oli) => {
        this.observationListItemsMap.set(oli.id, oli);
        switch (oli.clinicSupplyType.observationListType.name) {
          case 'Toxins':
            this.toxins.push(oli);
            break;
          case 'Fillers':
            this.fillers.push(oli);
            break;
          case 'Needles':
            this.needles.push(oli);
            break;
          case 'Deoxycholate':
            this.deoxycholates.push(oli);
            break;
          default:
            break;
        }
      });

      // Sort the lists by sequence numbers
      this.toxins.sort(function compare(a, b) {
        return a.sequenceNumber < b.sequenceNumber ? -1 : a.sequenceNumber > b.sequenceNumber ? 1 : 0;
      });
      this.fillers.sort(function compare(a, b) {
        return a.sequenceNumber < b.sequenceNumber ? -1 : a.sequenceNumber > b.sequenceNumber ? 1 : 0;
      });
      this.needles.sort(function compare(a, b) {
        return a.sequenceNumber < b.sequenceNumber ? -1 : a.sequenceNumber > b.sequenceNumber ? 1 : 0;
      });
      this.deoxycholates.sort(function compare(a, b) {
        return a.sequenceNumber < b.sequenceNumber ? -1 : a.sequenceNumber > b.sequenceNumber ? 1 : 0;
      });
      this.observationListsPopulatedSubject$.next(true);
    });
  }

  initObservationListStatic() {
    this.observationListItemsService.getObservationListStatic().subscribe((observationListStatic) => {
      this.observationListStaticMap.clear();
      this.toxinAreas = [];
      this.fillerAreas = [];
      this.deoxycholateAreas = [];
      this.depths = [];
      this.deliveries = [];
      observationListStatic.forEach((oli) => {
        this.observationListStaticMap.set(oli.id, oli);
        switch (oli.observationListType.name) {
          case 'Toxin Areas':
            this.toxinAreas.push(oli);
            break;
          case 'Filler Areas':
            this.fillerAreas.push(oli);
            break;
          case 'Deoxycholate Areas':
            this.deoxycholateAreas.push(oli);
            break;
          case 'Depths':
            this.depths.push(oli);
            break;
          case 'Deliveries':
            this.deliveries.push(oli);
            break;
          default:
            break;
        }
      });

      // Sort the lists by sequence numbers
      this.toxinAreas.sort(function compare(a, b) {
        return a.sequenceNumber < b.sequenceNumber ? -1 : a.sequenceNumber > b.sequenceNumber ? 1 : 0;
      });
      this.fillerAreas.sort(function compare(a, b) {
        return a.sequenceNumber < b.sequenceNumber ? -1 : a.sequenceNumber > b.sequenceNumber ? 1 : 0;
      });
      this.depths.sort(function compare(a, b) {
        return a.sequenceNumber < b.sequenceNumber ? -1 : a.sequenceNumber > b.sequenceNumber ? 1 : 0;
      });
      this.deliveries.sort(function compare(a, b) {
        return a.sequenceNumber < b.sequenceNumber ? -1 : a.sequenceNumber > b.sequenceNumber ? 1 : 0;
      });
      this.deoxycholateAreas.sort(function compare(a, b) {
        return a.sequenceNumber < b.sequenceNumber ? -1 : a.sequenceNumber > b.sequenceNumber ? 1 : 0;
      });
      this.observationListsStaticPopulatedSubject$.next(true);
    });
  }

  initObservationUnits(observationTypeId: number): ObservationUnit[] {
    this.observationUnitsService.getAllObservationUnits().subscribe((units) => {
      this.observationUnitsMap.clear();
      this.units = [];
      units.forEach((unit) => {
        this.observationUnitsMap.set(unit.id, unit);
        if (unit.observationType.id === observationTypeId) {
          this.units.push(unit);
        }
      });
      return this.units;
    });
    return this.units;
  }

  onCreateFiller() {
    return this.fb.group({
      id: [0],
      // Value === amount
      value: [null, Validators.required],
      details: this.fb.group({
        area: [null, Validators.required],
        injected: [null, Validators.required],
        needle: [null],
        depth: [null],
        deliveryMethod: [null],
        plotDetails: [
          {
            coords: null,
            leafletId: null,
          },
        ],
      }),
    });
  }

  onCreateToxin() {
    return this.fb.group({
      id: [0],
      // Value === amount
      value: [null, Validators.required],
      details: this.fb.group({
        area: [null, Validators.required],
        injected: [null, Validators.required],
        plotDetails: [
          {
            coords: null,
            leafletId: null,
          },
        ],
      }),
    });
  }

  onCreateDeoxycholate() {
    return this.fb.group({
      id: [0],
      // Value === amount
      value: [null, Validators.required],
      details: this.fb.group({
        area: [null, Validators.required],
        injected: [null, Validators.required],
        plotDetails: [
          {
            coords: null,
            leafletId: null,
          },
        ],
      }),
    });
  }

  onGetTreatmentText(observations: Observation[]) {
    this.observationService.detailsToJson(observations);
    let totalUnits = Number(observations.reduce((sum, current) => sum + Number(current.value), 0).toFixed(2));
    return {
      area: this.observationListStaticMap.get(observations[0].details.area)?.name,
      unit: totalUnits + ' ' + this.observationUnitsMap.get(observations[0].unitId)?.name,
      details: this.observationListItemsMap.get(observations[0].details.injected)?.displayName,
      totalDiscountPercentage: observations[0].details.isOverrideTotalPrice
        ? 1 - observations[0].details.overrideTotalPrice / (observations[0].details.productPrice * totalUnits)
        : observations[0].details.isOverrideProductPrice
        ? 1 -
          (observations[0].details.overrideProductPrice * totalUnits) /
            (observations[0].details.productPrice * totalUnits)
        : 0,
      price: observations[0].details.isOverrideTotalPrice
        ? observations[0].details.overrideTotalPrice
        : observations[0].details.isOverrideProductPrice
        ? observations[0].details.overrideProductPrice * totalUnits
        : observations[0].details.productPrice * totalUnits,
    };
  }

  onGetTreatmentGroupsInjections(observations: Observation[]) {
    this.observationService.detailsToJson(observations);
    let treatmentItems = [];
    let treatmentItemsMap = new Map<string, Observation[]>();
    if (observations && observations.length) {
      observations.forEach((obs) => {
        const key = this.onGetInjObrKey(obs);
        if (treatmentItemsMap.has(key)) {
          treatmentItemsMap.get(key).push(obs);
        } else {
          treatmentItemsMap.set(key, [obs]);
        }
      });
      treatmentItemsMap.forEach((val, key) => {
        treatmentItems.push(this.onGetTreatmentText(val));
      });
    }
    return treatmentItems;
  }

  onGetInjObrKey(observation: Observation): string {
    return observation.details.area + '/' + observation.details.injected;
  }
}
