import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Nudge } from '@models/nudges/nudge';
import { NudgeResponse } from '@models/nudges/nudge-response';
import { NudgesQueryParameters } from '@models/nudges/nudges-query-paramaters';
import { NudgeTypes } from '@models/nudges/type';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NudgeService {
  private nudgeCountUpdated = new Subject<void>();
  public nudgeCountUpdated$ = this.nudgeCountUpdated.asObservable();
  constructor(private http: HttpClient) {}

  getNudges(parameters: NudgesQueryParameters): Observable<NudgeResponse> {
    const params = new HttpParams({ fromObject: parameters as any });
    return this.http.get<NudgeResponse>(environment.baseUrl + 'api/nudges', { params: params });
  }

  updateNudgeCount() {
    this.nudgeCountUpdated.next();
  }

  createNudge(data: Nudge): Observable<Nudge> {
    return this.http.post<Nudge>(environment.baseUrl + 'api/nudges', data);
  }

  updateNudge(data: Nudge, id: number): Observable<Nudge> {
    return this.http.put<Nudge>(environment.baseUrl + `api/nudges/${id}`, data);
  }

  getNudgesCount(type?: NudgeTypes): Observable<number> {
    let params = {};
    if (type) {
      params = {
        nudgeType: type,
      };
    }

    return this.http.get<number>(environment.baseUrl + 'api/Nudges/my-nudges-count', { params: params });
  }

  getNudgeHistory(id: number): Observable<any> {
    return this.http.get<any>(environment.baseUrl + `api/Nudges/${id}/history`);
  }
}
