import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { dataURLtoFile } from '@app/shared/helpers';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClinicsService } from '@services/clinics.service';
import { ImageService } from '@services/image.service';
import { fabric } from 'fabric';
import moment from 'moment';
import { first } from 'rxjs/operators';
import { SelectClinicPhotoComponent } from './select-clinic-photo/select-clinic-photo.component';

@Component({
  selector: 'app-image-watermark',
  templateUrl: './image-watermark.component.html',
  styleUrls: ['./image-watermark.component.less'],
})
export class ImageWatermarkComponent implements OnInit {
  @Input() photo: PhotoMetaData;
  @Output() result = new EventEmitter<PhotoMetaData>();
  clinicPhotos: PhotoMetaData[] = [];
  backgroundLoading = false;
  markLoading = false;
  initialAlpha = 0.8;
  private scaleFactor = 1;
  private canvas: fabric.Canvas;
  private watermarkImage: fabric.Image;
  private imageWidth: number;
  private imageHeight: number;

  constructor(
    private clinicsService: ClinicsService,
    private imageService: ImageService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.imageService.getClinicStockPhotos().subscribe((photos) => {
      this.clinicPhotos = photos;
    });
    this.initCanvas();
  }

  private initCanvas() {
    const canvas = (this.canvas = new fabric.Canvas('canvas', {}));
    canvas.backgroundColor = 'white';
    canvas.renderOnAddRemove = false;

    this.backgroundLoading = true;
    fabric.Image.fromURL(
      this.photo.filePath,
      (bgImage) => this.setImage(bgImage),
      {
        originX: 'left',
        originY: 'top',
        crossOrigin: 'anonymous',
      }
    );
  }

  private setImage(bgImage: fabric.Image) {
    this.backgroundLoading = false;
    const imageTextureSize = bgImage.width > bgImage.height ? bgImage.width : bgImage.height;
    if (imageTextureSize > fabric.textureSize) {
      fabric.textureSize = imageTextureSize;
    }
    const size = bgImage.getOriginalSize();
    this.imageWidth = size.width;
    this.imageHeight = size.height;
    this.canvas.setWidth(size.width);
    this.canvas.setHeight(size.height);
    this.canvas.setBackgroundImage(bgImage, this.canvas.renderAll.bind(this.canvas));
    this.setWatermark(this.clinicsService.clinic.logoUrl); 
  }

  private setWatermark(watermarkUrl: string) {
    this.markLoading = true;
    fabric.Image.fromURL(
      watermarkUrl,
      (img) => {
        this.watermarkImage = img;
        this.canvas.remove(...this.canvas.getObjects());
        this.canvas.add(img);
        this.setCanvasZoom();
        this.markLoading = false;
        this.canvas.renderAll();
      },
      {
        originX: 'right',
        originY: 'bottom',
        opacity: this.initialAlpha,
        crossOrigin: 'anonymous',
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  setCanvasZoom() {
    this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    const canvasContainer = document.getElementById('canvas-container');
    const containerWidth = canvasContainer.clientWidth;
    const containerHeight = canvasContainer.clientHeight;
    
    const scaleWidth = containerWidth / this.imageWidth;
    const scaleHeigh = containerHeight / this.imageHeight;

    let reScale = 1;
    if (scaleWidth < scaleHeigh) {
      reScale = scaleWidth;
    }
    if (scaleHeigh < scaleWidth) {
      reScale = scaleHeigh;
    }

    this.scaleFactor = reScale;
    this.canvas.setDimensions({ width: this.imageWidth * reScale, height: this.imageHeight * reScale });
    this.canvas.setViewportTransform([reScale, 0, 0, reScale, 0, 0]);

    
    this.watermarkImage.scaleToWidth(this.canvas.getWidth() / 4.5);
    this.watermarkImage.set({
      left: (this.canvas.getWidth() / reScale) - 40,
      top: (this.canvas.getHeight() / reScale) - 40,
    });
    this.watermarkImage.setCoords();
  }

  alphaChange(change: MatSliderChange) {
    this.canvas.forEachObject((obj) => {
      obj.set({
        opacity: change.value,
      });
    });
    this.canvas.renderAll();
  }

  onCancel() {
    this.result.emit(null);
  }

  async onSave() {
    this.backgroundLoading = true;
    const metadata = new PhotoMetaData(this.photo);
    metadata.id = 0;
    metadata.fileId = null;
    metadata.filePathOriginal = metadata.filePath;
    metadata.filePath = null;
    metadata.filePathThumb = null;
    metadata.isOriginal = false;
    metadata.modifiedDate = moment();
    metadata.imageType = 'image/jpeg';
    const fileUrl = this.canvas.toDataURL({ multiplier: 1 / this.scaleFactor, format: 'jpeg' });
    const file = dataURLtoFile(fileUrl, metadata.imageName);
    const uploaded = await this.imageService.uploadPhoto(file, metadata).pipe(first()).toPromise();

    this.backgroundLoading = false;
    this.result.emit(uploaded);
  }

  selectClinicPhoto() {
    const selectClinicPhotoModal = this.modalService.open(SelectClinicPhotoComponent);
    selectClinicPhotoModal.componentInstance.photos = this.clinicPhotos;
    selectClinicPhotoModal.result.then((selectedPhoto: PhotoMetaData) => {
      if (selectedPhoto) {
        this.setWatermark(selectedPhoto.filePath);
      }
    });
  }
}
