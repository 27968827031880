<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>

<div class="grid-container pb-2">
  <kendo-grid
    class="ml-1"
    [data]="gridView"
    [pageSize]="gridState.take"
    [skip]="gridState.skip"
    [sort]="gridState.sort"
    [sortable]="true"
    [selectable]="true"
    [style.height.%]="100"
    (add)="onAddClick($event)"
    (cancel)="cancelHandler($event)"
    (save)="saveHandler($event)"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)"
  >
    <ng-template *appAuthGuard="developerPolicy" kendoGridToolbarTemplate>
      <div class="d-flex">
        <button kendoGridAddCommand class="p-2 btn em-btn"><i class="fal fa-plus-circle"></i> Add Reason</button>
      </div>
    </ng-template>

    <kendo-grid-column field="name" title="Name" [width]="150"></kendo-grid-column>
    <kendo-grid-command-column title="Actions" [width]="100">
      <ng-template *appAuthGuard="developerPolicy" kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridEditCommand class="btn kendo-btn"><i class="fas fa-edit"></i></button>
        <button kendoGridRemoveCommand class="btn kendo-btn"><i class="fas fa-minus-circle"></i></button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>

<app-actionpanel></app-actionpanel>
