import { Injectable } from '@angular/core';
import { CacheStats } from './cache-stats';
import { IStorageStrategy, ICachePair } from './decorator/base';

import { InMemoryStorageStrategy } from './decorator/base/InMemoryStorageStrategy';

@Injectable({
  providedIn: 'root'
})
export class HybridStorageStrategy extends IStorageStrategy {
  static storageInstance: InMemoryStorageStrategy;
  private storage: InMemoryStorageStrategy;
  private cacheStats = new CacheStats();

  constructor() {
    super();
    if (!HybridStorageStrategy.storageInstance) {
      HybridStorageStrategy.storageInstance = new InMemoryStorageStrategy();
    }
    this.storage = HybridStorageStrategy.storageInstance;
  }

  getAll(cacheKey: string): ICachePair<any>[] {
    return this.storage.getAll(cacheKey);
  }

  add(entity: ICachePair<any>, cacheKey: string): void {
    entity.created = new Date();
    this.storage.add(entity, cacheKey);
    this.cacheStats.update(this.getRawData());
  }

  updateAtIndex(index: number, entity: ICachePair<any>, cacheKey: string): void {
    this.storage.updateAtIndex(index, entity, cacheKey);
    this.cacheStats.update(this.getRawData());
  }

  removeAtIndex(index: number, cacheKey: string): void {
    this.storage.removeAtIndex(index, cacheKey);
    this.cacheStats.update(this.getRawData());
  }

  removeAll(cacheKey: string): void {
    this.storage.removeAll(cacheKey);
    this.cacheStats.update(this.getRawData());
  }

  getByGroup(cacheGroup: string): { key: string, cachePair: ICachePair<any>}[] {
    const result = [];
    this.storage.getRawData().forEach((value, key) => {
      value.forEach(cachePair => {
        if (cachePair.cacheGroup === cacheGroup) {
          result.push({ key, cachePair });
        }
      });
    });
    return result;
  }

  removeByServerKey(cacheKey: string, serverCacheKey: string) {
    const cacheEntries = this.getAll(cacheKey);
    const cachePair = cacheEntries.find(c => c.response.serverCacheKey === serverCacheKey);
    if (cachePair) {
      this.removeAtIndex(cacheEntries.indexOf(cachePair), cacheKey);
    }
    this.cacheStats.update(this.getRawData());
  }

  removeCachePair(cacheKey: string, cachePair: ICachePair<any>) {
    const cacheEntries = this.getAll(cacheKey);
    if (cachePair) {
      this.removeAtIndex(cacheEntries.indexOf(cachePair), cacheKey);
    }
    this.cacheStats.update(this.getRawData());
  }

  deleteByGroup(cacheGroup: string) {
    const caches = this.getByGroup(cacheGroup);
    caches.forEach(i => {
      caches.forEach(i => {
        this.removeCachePair(i.key, i.cachePair);
      });
    });
  }

  getRawData(): Map<string, Array<ICachePair<any>>> {
    return this.storage.getRawData();
  }
}
