import { Injectable } from '@angular/core';
import { ProductCategory } from '@models/product-category';
import { ClinicProduct } from '@models/clinic-product';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Tax } from '@models/tax';
import { ProductBrand } from '@models/product-brand';

@Injectable()
export class ClinicProductsService {
  constructor(private http: HttpClient) {}

  //dotNetProduct: ProductDotNet;

  addProduct(product: ClinicProduct) {
    //this.productToDotNetProduct(product);
    return this.http.post<ClinicProduct>(environment.baseUrl + 'api/ClinicProducts', product);
  }

  updateProduct(product: ClinicProduct) {
    //this.productToDotNetProduct(product);
    return this.http.put<void>(environment.baseUrl + 'api/ClinicProducts/' + product.id, product);
  }

  removeProduct(product: ClinicProduct) {
    //this.productToDotNetProduct(product);
    return this.http.delete(environment.baseUrl + 'api/ClinicProducts/' + product.id);
  }

  getProducts() {
    return this.http.get<ClinicProduct[]>(environment.baseUrl + 'api/ClinicProducts');
  }

  getProductById(productId) {
    return this.http.get<ClinicProduct>(environment.baseUrl + 'api/ClinicProducts/' + productId);
  }

  getProductByCategory(categoryId) {
    return this.http.get<ClinicProduct[]>(environment.baseUrl + 'api/ClinicProducts/Category/' + categoryId);
  }

  getProductsByCompany(companyId) {
    return this.http.get<ClinicProduct[]>(environment.baseUrl + 'api/ClinicProducts/Company/' + companyId);
  }

  getBrandAndProducts() {
    return this.http.get<ProductBrand[]>(environment.baseUrl + 'api/ClinicProducts/BrandAndProducts');
  }

  addProductCategory(productCategory: ProductCategory) {
    return this.http.post<ProductCategory>(environment.baseUrl + 'api/ProductCategories', productCategory);
  }

  updateProductCategory(productCategory: ProductCategory) {
    return this.http.put<void>(
      environment.baseUrl + 'api/ProductCategories/' + productCategory.productCategoryId,
      productCategory
    );
  }

  removeProductCategory(productCategory: ProductCategory) {
    return this.deleteProductCategory(productCategory.productCategoryId);
  }

  deleteProductCategory(id: number) {
    return this.http.delete(`${environment.baseUrl}api/ProductCategories/${id}`);
  }

  getProductCategories() {
    return this.http.get<ProductCategory[]>(environment.baseUrl + 'api/ProductCategories');
  }

  getProductCategoryById(productCategoryId) {
    return this.http.get<ProductCategory>(environment.baseUrl + 'api/ProductCategories/' + productCategoryId);
  }

  getProductsByVisit(visitId: number) {
    return this.http.get<any>(environment.baseUrl + 'api/VisitProducts/GetByVisitId/' + visitId);
  }

  addProductToVisit(visitId, productId, quantity) {
    const visitProduct = { visitId: visitId, productId: productId, quantity: quantity };
    return this.http.post<any>(environment.baseUrl + 'api/VisitProducts/', visitProduct);
  }

  updateVisitProduct(visitId, productId, quantity) {
    const visitProduct = { visitId: visitId, productId: productId, quantity: quantity };
    return this.http.put<ClinicProduct>(environment.baseUrl + 'api/VisitProducts/', visitProduct);
  }

  removeProductFromVisit(visitId, productId) {
    return this.http.delete<any>(`${environment.baseUrl}api/VisitProducts/${visitId}/${productId}`);
  }

  /*
  productToDotNetProduct(product: ClinicProduct) {
    this.dotNetProduct = {
      productId: product.id,
      name: product.displayName,
      productCode: product.productCode,
      quantityInStock: product.quantityInStock,
      retailPrice: product.retailPrice,
      overrideRetailPrice: product.overrideRetailPrice,
      wholesalePrice: product.wholesalePrice,
      overrideWholesalePrice: product.overrideWholesalePrice,
      productCategoryId: product.productCategoryId,
      productTaxes: product.productTaxes,
      quantity: product.quantity,
      usageInstructions: product.usageInstructions,
      usageDuration: product.usageDuration,
      dollarsToLoyaltyPointRate: product.dollarsToLoyaltyPointRate,
    };
  }
  */

  getListsForNewProduct() {
    return this.http.get<GetListsForNewProductDTO>(`${environment.baseUrl}api/ClinicProducts/new`);
  }

  getProductForEdit(id) {
    return this.http.get<GetProductForEditDTO>(`${environment.baseUrl}api/ClinicProducts/${id}/edit`);
  }
}

export interface GetListsForNewProductDTO {
  productBrands: ProductBrand[];
  productCategories: ProductCategory[];
  taxes: Tax[];
}

export interface GetProductForEditDTO {
  productBrands: ProductBrand[];
  clinicProduct: ClinicProduct;
  productCategories: ProductCategory[];
  taxes: Tax[];
}
