import { Component, OnInit, Optional, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientDocument } from '@models/document/patient-document';
import { Subject } from 'rxjs';
import { Clinic } from '@models/clinic';
import { moment } from 'fullcalendar';
import { UsersService } from '@services/users.service';
import { PatientService } from '@services/patient.service';
import { PatientDocumentService } from '@services/patient-documents.service';
import { ClinicsService } from '@services/clinics.service';
import { takeUntil } from 'rxjs/operators';
import { FormioComponent } from 'angular-formio-emilyemr';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.less'],
})
export class DocumentPreviewComponent implements OnInit {
  @ViewChild('formView') formView: FormioComponent;
  docId: number;
  document: PatientDocument;
  isDocumentsExist = false;
  unsub: Subject<void> = new Subject<void>();
  pdfSrc: string;
  pdfToHtmlSrc: string;
  eFormDefinition: any = {};
  eFormData: { [k: string]: any } = {};
  printEFormUrl: string;
  clinic: Clinic;
  formData: string;
  public form: any;
  public submission: any = {};

  constructor(
    @Optional() public dialogRef: MatDialogRef<DocumentPreviewComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private patientService: PatientService,
    private usersService: UsersService,
    private clinicService: ClinicsService
  ) {}

  ngOnInit() {
    this.getClinic();
    this.initForm();
  }

  onSubmit(event) {}

  getClinic() {
    this.clinicService
      .getClinics()
      .pipe(takeUntil(this.unsub))
      .subscribe((clinics) => {
        this.clinic = clinics[0];
      });
  }

  private initForm() {
    this.pdfSrc = '';
    this.pdfToHtmlSrc = '';
    if (this.data.document.pdfToHtmlUrl) {
      this.pdfToHtmlSrc = this.data.document.pdfToHtmlUrl;
      this.pdfSrc = '';
    } else {
      this.pdfToHtmlSrc = '';
      this.pdfSrc = this.data.document.filePath ?? this.data.document.blobUrl;
    }
    this.document = this.data.document;
    this.docId = this.data.document.id;

    if (this.data.document.eFormDefinition) {
      this.eFormDefinition = JSON.parse(this.data.document.eFormDefinition);
      this.eFormDefinition.settings.pdf.src = this.data.document.pdfToHtmlUrl + '&zoom=1';
      this.eFormData = {};
      if (this.data.document.eFormData) {
        this.eFormData = JSON.parse(this.data.document.eFormData);
      }

      if (this.data.patientId) {
        this.patientService.getPatientById(this.data.patientId).subscribe((res) => {
          const patient = res;
          if (patient && patient.patientId !== 0) {
            this.printEFormUrl = `/print-eform/${this.docId}/${patient.patientId}`;
            this.setDataField('firstName', patient.firstName);
            this.setDataField('lastName', patient.lastName);
            this.setDataField('genderString', patient.gender);
            this.setDataField('genderMale', patient.gender === 'Male');
            this.setDataField('genderFemale', patient.gender === 'Female');
            this.setDataField('birthMonth', moment(patient.birthDate).month() + 1);
            this.setDataField('birthDay', moment(patient.birthDate, 'YYYY-MM-DD').date());
            this.setDataField('birthYear', moment(patient.birthDate).year());
            this.setDataField('healthCareNumber', patient.clientId);

            if (patient.address) {
              this.setDataField('address1', patient.address.address1);
              this.setDataField('address2', patient.address.address2);
              this.setDataField('city', patient.address.city);
              this.setDataField('country', patient.address.country);
              this.setDataField('postalCode', patient.address.postalCode);
              this.setDataField('province', patient.address.province);
            }
          }

          if (this.clinic) {
            this.setDataField('clinicPhoneNumber', this.clinic.phoneNumber);
            this.setDataField('clinicFaxNumber', this.clinic.faxNumber);
            if (this.clinic.address) {
              this.setDataField('clinicAddress1', this.clinic.address.address1);
              this.setDataField('clinicAddress2', this.clinic.address.address2);
              this.setDataField('clinicCity', this.clinic.address.city);
              this.setDataField('clinicCountry', this.clinic.address.country);
              this.setDataField('clinicPostalCode', this.clinic.address.postalCode);
              this.setDataField('clinicProvince', this.clinic.address.province);
            }
          }

          if (this.usersService.loggedInUser) {
            this.setDataField('mspNumber', this.usersService.loggedInUser.provincialBillingNumber);
            this.setDataField('cpsbc', this.usersService.loggedInUser.medicalLicenseNumber);
            this.setDataField('physicianFirstName', this.usersService.loggedInUser.firstName);
            this.setDataField('physicianLastName', this.usersService.loggedInUser.lastName);
          }

          setTimeout(() => {
            this.formView.submit.next({ data: this.eFormData });
            this.submission = { data: this.eFormData };
          }, 100);
        });
      }
    }

    if (this.eFormDefinition) {
      this.form = this.eFormDefinition;
    }

    if (this.formData) {
      this.submission = { data: this.eFormData };
    }
  }

  private setDataField(key: string, value: any) {
    const elements = this.eFormDefinition.components.filter((el) => (<string>el.key).startsWith(key));
    elements.forEach((element) => {
      this.eFormData[element.key] = value;
      element.disabled = true; // make it read only
    });
  }

  onFormChange(value: any) {}

  public closeModal() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
    this.closeModal();
  }
}
