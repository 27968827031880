import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';
import { BookingServiceCategory } from '@models/booking-service-category';
import { BookingServiceCategoryService } from '@services/booking-categories.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-org-online-categories-settings',
  templateUrl: './org-online-categories-settings.component.html',
  styleUrls: ['./org-online-categories-settings.component.less'],
})
export class OrgOnlineCategoriesSettingsComponent implements OnInit {
  loading = false;
  @Output() disableGridEvent = new EventEmitter<boolean>();
  disableGrid = false;
  unsub: Subject<void> = new Subject<void>();

  categories: BookingServiceCategory[] = [];

  constructor(
    private router: Router,
    private bookingCategoryService: BookingServiceCategoryService,
    private deleteDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.bookingCategoryService.refreshRequired$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.disableGrid = false;
      this.sendDisableGrid(this.disableGrid);
      if (this.bookingCategoryService.refreshRequired$) {
        this.refreshData();
      }
    });
    this.refreshData();
  }

  onAddClick() {
    this.disableGrid = true;
    this.sendDisableGrid(this.disableGrid);
    this.router.navigate([
      '/management/organization/clinics/online-booking',
      { outlets: { 'action-panel': ['edit-online-booking-category', '_'] } },
    ]);
  }

  public editHandler(dataItem) {
    this.disableGrid = true;
    this.sendDisableGrid(this.disableGrid);
    this.router.navigate([
      '/management/organization/clinics/online-booking',
      { outlets: { 'action-panel': ['edit-online-booking-category', dataItem.bookingServiceCategoryId] } },
    ]);
  }

  public removeHandler(dataItem) {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
      data: {
        extraMessage: 'Any services under this category will be unlinked',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        const dataItemToRemove = dataItem.bookingServiceCategoryId;
        this.bookingCategoryService.removeBookingServiceCategory(dataItemToRemove).subscribe(() => {
          this.refreshData();
        });
      }
    });
  }
  sendDisableGrid(value: boolean) {
    this.disableGridEvent.emit(value);
  }

  refreshData() {
    this.loading = true;
    this.bookingCategoryService.getBookingServiceCategories().subscribe((result) => {
      this.categories = result;
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }

  public async onCategoryDropped(event: CdkDragDrop<BookingServiceCategory[]>) {
    this.loading = true;
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
    await this.bookingCategoryService
      .updateOnlineCategoriesOrder(this.categories.map((_) => _.bookingServiceCategoryId))
      .toPromise();
    this.loading = false;
  }
}
