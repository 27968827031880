<formio
  [ngClass]="{ 'modal-version': modalView, 'editable-version': editable }"
  #formView
  [form]="formObj"
  [submission]="submissionObj"
  [readOnly]="!editable"
  (submit)="onSubmit($event)"
  appDomChangeListener
  (domChanged)="onDomChange($event)"
  (change)="onFormChange($event)"
>
</formio>
