import { RetentionCampaignsService } from '@services/retention-campaigns.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject, Observable, merge, combineLatest } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommunicationsService } from '@services/communications.service';
import { takeUntil } from 'rxjs/operators';
import { MailjetEmailTemplate } from '@models/communications/mailjet-email-template.model';
import { ServiceProviderService } from '@services/service-provider.service';
import { ServiceTemplatesService } from '@services/service-templates.service';
import { ServiceOrProductType, ServiceOrProduct } from '@models/communications/service-or-product.model';
import { ClinicProductsService } from '@services/clinic-products.service';
import * as moment from 'moment';
@Component({
  selector: 'app-edit-email-templates',
  templateUrl: './edit-email-templates.component.html',
  styleUrls: ['./edit-email-templates.component.less'],
})
export class EditEmailTemplatesComponent implements OnInit, OnDestroy {
  private unsub: Subject<void> = new Subject<void>();
  loading = false;
  actionType = 'Create';
  saveBtnName = 'Create';
  form: FormGroup;
  campaignStatuses = ['Draft', 'Active', 'Suspended'];
  timePeriodUnits: string[] = ['minutes', 'hours', 'days', 'weeks', 'months'];
  lessMoreTypes: string[] = ['less than', 'more than'];
  firstLastTypes: string[] = ['first', 'last'];
  beforeAfterTypes: string[] = ['before', 'after'];
  hasNotTypes: string[] = ['has', 'has not'];
  numberOfDays: string[] = ['number of days'];
  emailTemplates: MailjetEmailTemplate[] = [];
  serviceProviders = [];
  focus$ = new Subject<string>();
  allServicesOrProducts: ServiceOrProduct[] = [];
  selectedServicesOrProducts: ServiceOrProduct[] = [];
  id = 0;
  submitted = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private communicationsService: CommunicationsService,
    private providerService: ServiceProviderService,
    private serviceTemplatesService: ServiceTemplatesService,
    private productsService: ClinicProductsService,
    private retentionCampaignsService: RetentionCampaignsService
  ) {}

  ngOnInit() {
    this.initForm();
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      // if (params['templateName']) {
      //   this.form.controls['name'].setValue(params['templateName']);
      // }
      if (params['id'] !== '_') {
        this.actionType = 'Edit';
        this.saveBtnName = 'Save';
        this.id = +params['id'];
        // this.populateTemplateValues(this.id);
      } else {
      }
    });
  }
  populateTemplateValues(templateId: number) {
    this.loading = true;
    this.communicationsService
      .getEmailTemplateDetails(templateId)
      .pipe(takeUntil(this.unsub))
      .subscribe((template) => {
        if (template == null) {
          this.loading = false;
          return;
        }
        this.form.controls['htmlTemplate'].setValue(template.htmlTemplate);
        this.loading = false;
      });
  }

  initForm() {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      htmlTemplate: new FormControl('', Validators.required),
    });
  }

  saveChanges() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }

    // const data = new Campaign();
    // data.id = this.id;
    // data.title = this.form.controls['title'].value;
    // data.templateId = (this.form.controls['template'].value as MailjetEmailTemplate).id;
    // data.status = this.campaignStatuses.indexOf(this.form.controls['status'].value);
    // data.startOn = this.form.controls['startOn'].value;
    // if (this.form.controls['whenSend'].value === 'recurring') {
    //   data.startOn = this.form.controls['recurringStartOn'].value;
    //   if (this.form.controls['endOnCheckbox'].value === true) {
    //     data.endOn = this.form.controls['endOn'].value;
    //     if (moment(data.endOn) < moment(data.startOn)) { data.endOn = data.startOn; }
    //   }
    //   data.repeatPeriod = this.formatRepeatPeriod('repeatPeriodValue', 'repeatPeriodUnit');
    // }

    // data.sendToAll = this.form.controls['sendTo'].value === 'all';

    // if (!data.sendToAll) {
    //   data.timeElapsedSinceVisit = this.formatTimeElapsed(
    //     'timeElapsedCheckbox',
    //     'timeElapsedLessMore',
    //     'timeElapsedValue',
    //     'timeElapsedFirstLast');
    //   data.andTimeElapsedSinceVisit = this.formatTimeElapsed(
    //     'timeElapsedCheckbox2',
    //     'timeElapsedLessMore2',
    //     'timeElapsedValue2',
    //     'timeElapsedFirstLast2');

    //   data.visitDateWasBefore = this.formatVisitDate(
    //     'visitDateCheckbox',
    //     'visitDateFirstLast',
    //     'visitDateBeforeAfter',
    //     'visitDateValue');
    //   data.andVisitDateWasBefore = this.formatVisitDate(
    //     'visitDateCheckbox2',
    //     'visitDateFirstLast2',
    //     'visitDateBeforeAfter2',
    //     'visitDateValue2');

    //   if (this.form.controls['lastProviderCheckbox'].value === true) {
    //     data.lastStaffId = this.form.controls['lastProviderId'].value;
    //   }

    //   data.amountOfVisits = this.formatAmountExpression(
    //     'amountOfVisitsCheckbox',
    //     'amountOfVisitsMoreLess',
    //     'amountOfVisitsValue',
    //     'visits'
    //   );

    //   data.amountOfLoyaltyPoints = this.formatAmountExpression(
    //     'amountOfLoyaltyPointsCheckbox',
    //     'amountOfLoyaltyPointsMoreLess',
    //     'amountOfLoyaltyPointsValue',
    //     'loyalty points'
    //   );

    //   data.amountSpent = this.formatAmountExpressionWithDate(
    //     'amountSpentCheckbox',
    //     'amountSpentMoreLess',
    //     'amountSpentValue',
    //     'amountSpentSinceDate'
    //   );

    //   data.serviceIds = this.selectedServicesOrProducts
    //     .filter(_ => _.type === ServiceOrProductType.service)
    //     .map(_ => _.id).join(',');
    //   data.productIds = this.selectedServicesOrProducts
    //     .filter(_ => _.type === ServiceOrProductType.product)
    //     .map(_ => _.id).join(',');

    //   data.arePurchased = this.form.controls['arePurchasedHasNot'].value === 'has';
    //   if (this.form.controls['withinPeriodCheckbox'].value) {
    //     data.withinPeriod =
    //       this.form.controls['withinPeriodLessMore'].value + ' ' +
    //       this.form.controls['withinPeriodValue'].value + ' days';
    //   }
    //   data.notPurchasedSince = this.form.controls['notPurchasedSinceCheckbox'].value;
    //   data.noFutureAppointmentsSince = this.form.controls['noFutureAppointmentsSinceCheckbox'].value;
    // }

    // this.communicationsService.saveEmailTemplate(data)
    //   .subscribe(() => {
    //     this.router.navigate(['/management/communication/email-templates/', { outlets: { 'action-panel': null } }]);
    //   });
  }

  ngAfterViewInit() {}
  cancelUpdate() {
    this.communicationsService.refreshRequired = false;
    this.communicationsService.communicationsSettingsUpdated.next();

    this.router.navigate(['/management/communication/email-templates/', { outlets: { 'action-panel': null } }]);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
