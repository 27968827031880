import { FormType } from '@models/forms/form-type';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Form } from '@models/forms/form';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedFormService } from '@services/shared-form.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ServiceTemplateType } from '@models/service/service-template-type';
import { FormTypeService } from '@services/form-type.service';
import { ServiceTemplateTypeService } from '@services/service-template-type.service';
import { FormTypeEnum } from '@models/forms/form-type.enum';
import { UsersService } from '@services/users.service';
import { ClinicsService } from '@services/clinics.service';
import { Console } from 'console';

@Component({
  selector: 'app-share-form',
  templateUrl: './share-form.component.html',
  styleUrls: ['./share-form.component.less'],
})
export class ShareFormComponent implements OnInit {
  @Input() formToShare: Form;
  form: FormGroup;
  unsub: Subject<void> = new Subject<void>();

  loading = false;

  formTypes: FormType[] = [];
  serviceTemplateTypes: ServiceTemplateType[] = [];
  serviceTemplateTypeValidationRequired: boolean = true;

  loggedInUser;
  loggedInUserFullName;
  loggedInClinicName;

  constructor(
    public activeModal: NgbActiveModal,
    private sharedFormService: SharedFormService,
    private formTypeService: FormTypeService,
    private serviceTemplateTypesService: ServiceTemplateTypeService,
    private userService: UsersService,
    private clinicService: ClinicsService
  ) {
    this.loggedInUser = this.userService.loggedInUser;
    this.loggedInUserFullName = `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`;

    this.clinicService
      .getClinicById(this.loggedInUser.clinicId)
      .pipe(takeUntil(this.unsub))
      .subscribe((clinic) => {
        this.loggedInClinicName = clinic.name;
      });
  }

  ngOnInit() {
    this.getServiceTemplateTypesList();
    this.getFormTypes();
    this.initForm();
    this.setFormValues();
  }

  getFormTypes() {
    this.formTypes = [];
    this.formTypeService.getFormTypes().subscribe((res) => {
      this.formTypes = res;
    });
  }

  getServiceTemplateTypesList() {
    this.serviceTemplateTypes = [];
    this.serviceTemplateTypesService.getAllServiceTemplateTypes().subscribe((res) => {
      res.forEach((type) => {
        const typeData = type;
        const pushItem: ServiceTemplateType = {
          id: typeData.id,
          name: typeData.name,
        };
        this.serviceTemplateTypes.push(pushItem);
      });
    });
  }

  private initForm() {
    this.form = new FormGroup({
      id: new FormControl(0, [Validators.required]),
      name: new FormControl('', [Validators.required]),
      notes: new FormControl(),
      formTypeId: new FormControl(0, [Validators.required]),
      serviceTemplateTypeId: new FormControl(null, [Validators.required]),
      formDefinition: new FormControl(''),
      sharedByUserId: new FormControl(''),
      sharedByClinicId: new FormControl(0),
    });
  }

  private setFormValues() {
    if (this.formToShare !== null && this.formToShare !== undefined) {
      this.form.controls['id'].setValue(0);
      this.form.controls['formTypeId'].setValue(this.formToShare.formTypeId);
      this.form.controls['serviceTemplateTypeId'].setValue(this.formToShare.serviceTemplateTypeId);
      this.form.controls['name'].setValue(this.formToShare.name);
      this.form.controls['notes'].setValue(this.formToShare.notes);
      this.form.controls['formDefinition'].setValue(this.formToShare.formDefinition);

      this.form.controls['sharedByUserId'].setValue(this.loggedInUser.id);
      this.form.controls['sharedByClinicId'].setValue(this.loggedInUser.clinicId);

      this.updateServiceTemplateTypeValidator(this.formToShare.formTypeId);
    } else {
      console.log('Form not shared to Share modal.');
    }
  }

  onFormTypeChange(args: any) {
    let selectedFormTypeId = args.target.value;
    this.updateServiceTemplateTypeValidator(selectedFormTypeId);
  }

  updateServiceTemplateTypeValidator(formTypeId: number) {
    this.serviceTemplateTypeValidationRequired =
      formTypeId == FormTypeEnum.Questionnaire || formTypeId == FormTypeEnum.Intake ? false : true;
    this.form.controls['serviceTemplateTypeId'].clearValidators();
    this.form.controls['serviceTemplateTypeId'].setValidators(
      this.serviceTemplateTypeValidationRequired ? [Validators.required] : null
    );
    this.form.controls['serviceTemplateTypeId'].updateValueAndValidity();
  }

  onSubmit() {
    this.loading = true;
    try {
      this.sharedFormService
        .addSharedForm(this.form.value)
        .pipe(takeUntil(this.unsub))
        .subscribe(() => {
          this.loading = false;
          this.activeModal.dismiss();
        });
    } catch (e) {
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
