<ngx-loading [show]="loading"></ngx-loading>
<!-- <ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading> -->
<div class="categories">
  <div class="d-flex align-items-center justify-content-between teal-background white-font header">
    <span class="mr-auto">CATEGORIES</span>
  </div>
  <div class="border border-lightgrey panel-container">
    <div class="d-flex px-2 addControl background-light-gray flex-column">
      <button class="btn em-btn em-btn-green" (click)="onAddClick()">
        <i class="fal fa-plus-circle pr-1"></i>ADD CATEGORY
      </button>
      <span class="note">Drag and drop to change order of display</span>
    </div>
    <div class="container">
      <div class="row p-0 background-light-gray header">
        <div class="px-2 flex-grow-1 border border-left-0 border-lightgrey d-flex align-items-center">Name</div>
        <div class="px-2 border-top border-bottom border-lightgrey d-flex align-items-center" style="width: 78px">
          Actions
        </div>
      </div>
    </div>
    <div class="container pb-0" cdkDropList (cdkDropListDropped)="onCategoryDropped($event)">
      <div class="row p-0" *ngFor="let category of categories; let i = index" cdkDrag>
        <div
          class="px-2 flex-grow-1 border-right border-lightgrey d-flex align-items-center"
          [ngClass]="{ 'line-background': i % 2 != 0 }"
        >
          {{ category.categoryName }}
        </div>
        <div class="d-flex align-items-center" style="width: 78px" [ngClass]="{ 'line-background': i % 2 != 0 }">
          <button class="btn" (click)="editHandler(category)"><i class="fas fa-edit"></i></button>
          <button class="btn" (click)="removeHandler(category)"><i class="fas fa-minus-circle"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>
