import { ITransactionTargetItem } from '@interfaces/ITransactionTargetItem';
import * as moment from 'moment';
import { ColourVariables } from '../constants/colour-variables';
import { PhotoTag } from './../../photos/photo-filter';
import { PhotoConsentType } from './photo-consent-type';
import { SeriesType } from './series-type';

export class PhotoMetaData implements ITransactionTargetItem {
  id: number;
  fileId: string;
  filePath: string;
  filePathThumb: string;
  filePathOriginal: string;
  patientId: number;
  patientFullName: string;
  patientGender: string;
  patientContainerFolderName: string;
  uploadDate: moment.Moment;
  modifiedDate: moment.Moment;
  dateTaken: moment.Moment;
  relatedServiceTemplateIds: number[] = [];
  notes: string;
  imageType: string;
  imageName: string;
  imageHeight: number;
  consentToShare: string;
  isOriginal: boolean;
  get isGlobalSharedImage() : boolean{
    return this.photoConsentTypeId && this.photoConsentTypeId != PhotoConsentType.None
  }
  tags: PhotoTag[];
  isSeries: boolean;
  isFavourite: boolean = false;
  isStockPhoto: boolean = false;
  seriesPhotos?: PhotoMetaData[];
  seriesType?: SeriesType;
  colourVariables = new ColourVariables();
  isTreatmentPlanOriginal = false;
  photoConsentTypeId: PhotoConsentType;

  public constructor(init?: Partial<PhotoMetaData>, copy?: Partial<PhotoMetaData>) {
    this.isOriginal = true;
    if (copy) Object.assign(this, copy);
    Object.assign(this, init);
    if (this.modifiedDate === null || this.modifiedDate === undefined) {
      this.modifiedDate = moment();
    }
    if (this.uploadDate === null || this.uploadDate === undefined) {
      this.uploadDate = moment();
    }
    if (this.dateTaken === null || this.dateTaken === undefined) {
      this.dateTaken = moment();
    }
    if (this.tags === null || this.tags === undefined) {
      this.tags = [];
    }
  }

  getColor(): string {
    return this.colourVariables.blue;
  }

  isRoutable(): boolean {
    return true;
  }

  // addConsentTags(){
  //   let consentTagIndex = this.tags.findIndex(t => t.type == TagType.consentType);
  //   if (this.tags && consentTagIndex != -1){
  //     this.tags.splice(consentTagIndex,1);   //only one consent type tag per image
  //   }


  //   this.tags = [
  //     this.photoConsentTypeId == PhotoConsentType.None ?  <Tag>Tag.consentedTags[0] :
  //     this.photoConsentTypeId == PhotoConsentType.Internal ?  <Tag>Tag.consentedTags[1] : <Tag>Tag.consentedTags[2]

  //   ].concat(this.tags);

  // }

  // addAvatarTag(){
  //   this.tags = [
  //     <Tag>Tag.avatarTag
  //   ].concat(this.tags);
  // }
}
