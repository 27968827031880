import { Subject } from 'rxjs';
import { ReviewsSignalrService } from './reviews-signalr.service';

import { Injectable } from '@angular/core';

import { MessagingSignalrService } from '@app/messaging/services/messaging-signalr.service';
import { NudgesSignalrService } from '@app/nudges/services/nudges-signalr.service';
import { AppointmentSignalrService } from '@services/appointment-signalr.service';
import { ObservationListsSignalrService } from '@services/observation-lists-signalr.service';
import { PatientSignalrService } from '@services/patient-signalr.service';
import { ServiceTemplatesSignalrService } from '@services/service-templates-signalr.service';
import { ServicesSignalrService } from '@services/services-signalr.service';
import { VisitSignalrService } from '@services/visit-signalr.service';
import { PhotoSignalrService } from './photo-signalr.service';
import { SignalrBase } from './signalr-base';

// This class is just to handle the connections of all services that extend a connection hub - initially created to handle crashes on one or more to notify the user
@Injectable({
  providedIn: 'root',
})
export class SignalrConnectionService {
  private signalrDisconnected = new Subject<any>();
  signalrDisconnected$ = this.signalrDisconnected.asObservable();
  private hubServices: Array<SignalrBase<any>> = [];

  //add any signalr hubs here to init on startup - match other impl
  constructor(
    private visitSignalrService: VisitSignalrService,
    private appointmentSignalrService: AppointmentSignalrService,
    private patientSignalrService: PatientSignalrService,
    private servicesSignalrService: ServicesSignalrService,
    private messagingSignalrService: MessagingSignalrService,
    private serviceTemplatesSignalrService: ServiceTemplatesSignalrService,
    private nudgesSignlarService: NudgesSignalrService,
    private photoSignalrService: PhotoSignalrService,
    private reviewsSignalrService: ReviewsSignalrService,
    private obrListsSignalrService: ObservationListsSignalrService
  ) {
    this.hubServices.push(this.visitSignalrService);
    this.hubServices.push(this.appointmentSignalrService);
    this.hubServices.push(this.photoSignalrService);
    this.hubServices.push(this.serviceTemplatesSignalrService);
    this.hubServices.push(this.nudgesSignlarService);
    this.hubServices.push(this.reviewsSignalrService);
    this.hubServices.push(this.patientSignalrService);
    this.hubServices.push(this.servicesSignalrService);
    this.hubServices.push(this.messagingSignalrService);
    this.hubServices.push(this.obrListsSignalrService);

    this.initDisconnectListener();
  }

  private initDisconnectListener() {
    this.hubServices.forEach((hs) =>
      hs.disconnectedFromServer$.subscribe((connectionName) => {
        this.signalrDisconnected.next(connectionName);
      })
    );
  }
}
