<ngx-loading [show]="loading"></ngx-loading>
<div class="modal-header border-0 px-2 py-1">
  <button type="button" class="btn white-font p-0 ml-auto" aria-label="Close" (click)="closeModal()">
    <i class="fal fa-times"></i>
  </button>
</div>

<div *ngIf="data?.nudgeId" class="nugde-modal-body grey-five-background px-4 py-3">
  <div class="d-flex">
    <div class="d-flex w-100">
      <i class="far fa-bell pr-2"></i>
      <ng-container *ngIf="data?.referenceId">
        <div class="d-flex align-items-center ml-2">
          <a (click)="navigateToReferenceLink()" class="spring-green-font reference-link pointer">{{data?.referenceName}}</a>
        </div>
        <div class="align-self-center teal-font mx-2">for</div>
      </ng-container>
      <div *ngIf="data?.patient" class="d-flex align-items-center">
        <a (click)="navigateToPatient()" class="spring-green-font reference-link pointer">{{data.patient.firstName}} {{data.patient.lastName}}</a>
      </div>
    </div>
  </div>
</div>
<div class="grey-seven-background" *ngIf="nudgeForm" [formGroup]="nudgeForm">
  <div class="p-4 d-flex flex-column align-items-center">
    <!-- Text form field-->
    <div *ngIf="!checkStatus()" class="d-flex w-100 mb-2">
      <mat-form-field floatLabel="always"
                      class="mat-form-field--full">
        <mat-label>Text</mat-label>
        <div class="subject-line-items px-2">
          <perfect-scrollbar style="max-height: 200px;">
            <div class="row pb-3" *ngFor="let line of data.subject">
              <div class="col-3">
                <div class="row"></div>
                {{line.authorFirstName}} {{line.authorLastName | slice:0:1}} {{line.authorLastName ? '.' : ''}} {{line.timestamp| convertUTC: true}}
              </div>
              <div class="col font-weight-bold">
                {{line.line}}
              </div>
            </div>
          </perfect-scrollbar>
        </div>
        <textarea class="p-2 bg-white"
          matInput
          placeholder="Add Text"
          formControlName="newSubject"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="4"
          cdkAutosizeMaxRows="4">
        </textarea>
        <!-- <app-textarea-widget [minRows]="4"
                             [maxRows]="4">
        </app-textarea-widget> -->
      </mat-form-field>
    </div>

    <!-- Text read only -->
    <div *ngIf="checkStatus()" class="d-flex w-100 mb-2 grey-five-font">
      <div class="subject-line-items">
        <perfect-scrollbar style="max-height: 230px;">
          <div class="row pb-3" *ngFor="let line of data.subject">
            <div class="col-3">
              <div class="row"></div>
              {{line.authorFirstName}} {{line.authorLastName | slice:0:1}}. {{line.timestamp| convertUTC: true}}
            </div>
            <div class="col font-weight-bold">
              {{line.line}}
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row mb-2">
        <mat-form-field floatLabel="always"
                        class="col-6 pl-0">
          <mat-label>Assigned To</mat-label>
          <mat-select
            panelClass="nugdes-select"
            placeholder="Select"
            name="Receiver"
            formControlName="assignedTo">
            <mat-option *ngFor="let user of users" [value]="user.id">
              {{ user.firstName }} {{ user.lastName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field floatLabel="always"
                        class="col-6 pr-0">
          <mat-label>Type</mat-label>
          <mat-select
            panelClass="nugdes-select"
            placeholder="Select"
            name="Type"
            formControlName="nudgeType">
            <mat-option *ngFor="let item of types" [value]="item.value">
              {{ item.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field floatLabel="always"
                        class="col-6 pl-0">
          <mat-label>Importance</mat-label>
          <mat-select
            panelClass="nugdes-select"
            placeholder="Select"
            name="Priority"
            formControlName="importance">
            <mat-option *ngFor="let item of priority" [value]="item.value">
              {{ item.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field floatLabel="always"
                        class="col-6 pr-0">
          <mat-label>Due Date</mat-label>
          <input
            matInput
            required
            placeholder="Due Date"
            [matDatepicker]="dueDate"
            formControlName="deferredUntil">
          <mat-datepicker-toggle matSuffix class="datepicker-icon" [for]="dueDate"></mat-datepicker-toggle>
          <mat-datepicker #dueDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div [@.disabled]="disableAnimation" *ngIf="data?.nudgeId" class="d-flex justify-content-between w-100 mb-2">
    <mat-accordion class="w-100">
      <mat-expansion-panel class="nudge-history grey-seven-background" [expanded]="false" [disabled]="true" #panel>
        <mat-expansion-panel-header class="p-0" [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
          <mat-panel-title>
            <div class="d-flex align-items-center w-100"
                 style="cursor: pointer"
                 (click)="panel.expanded = !panel.expanded; getHistory()">
              <div class="py-0 mr-2">
                History
              </div>
              <button type="button" class="btn ml-auto px-0 py-0" (click)="panel.expanded = !panel.expanded; getHistory()">
                <mat-icon class="expand-btn">{{panel.expanded ? 'arrow_drop_up' : 'arrow_drop_down'}}</mat-icon>
              </button>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <perfect-scrollbar class="scrollbar-container" style="max-height: 230px;">
          <div
            class="grey-seven-background mt-1 mb-2">
            <div class="py-2">
              <span class="font-weight-bold">{{ data.createdBy.firstName }} {{ data.createdBy.lastName }}</span> created nudge on
              {{data.createdDate | convertUTC: true}}
            </div>
            <ng-container *ngIf="nudgeHistory.length">
              <div class="py-2" *ngFor="let item of nudgeHistory">
                <div>
                  <span class="font-weight-bold">{{ item.userFirstName }} {{item.userLastName }}</span> changed the
                  <span class="font-weight-bold text-capitalize">{{ item.fieldName }}</span> {{item.timestamp | convertUTC}}</div>
                <div>
                  <ng-container *ngIf="item.oldValue">
                    <span class="old-value">{{ item.oldValue}}</span><i class="fal fa-long-arrow-right mx-2"></i>
                  </ng-container>
                  <span class="new-value">{{item.newValue}}</span>
                </div>
              </div>
            </ng-container>
          </div>
        </perfect-scrollbar>
      </mat-expansion-panel>
    </mat-accordion>
    </div>

    <div class="d-flex justify-content-center w-100">
      <button
        *ngIf="data.nudgeId && !(data.status === Status.Completed)"
        type="button"
        [disabled]="!nudgeForm.valid"
        (click)="cancelNudge()"
        class="btn em-btn em-btn-red p-2 mb-0 mr-auto">
        {{data.status === Status.Deleted ? 'reactivate' : 'delete'}}
      </button>
      <button
        *ngIf="data.nudgeId && !(data.status === Status.Deleted)"
        type="button"
        [disabled]="!nudgeForm.valid || loading"
        (click)="completeNudge()"
        class="btn em-btn em-btn-blue p-2 mb-0 mr-2">
        {{data.status === Status.Completed ? 'reactivate' : 'complete'}}
      </button>
      <button
        *ngIf="!data?.nudgeId"
        class="btn em-btn em-btn-green ml-auto"
        (click)="closeModal()">
        Cancel
      </button>
      <button
        type="button"
        *ngIf="!(data.status === Status.Deleted) && !(data.status === Status.Completed)"
        [disabled]="!nudgeForm.valid || loading"
        (click)="onNudgeFormSubmit()"
        class="btn em-btn em-btn-green p-2 mb-0 ml-2">
        SAVE
      </button>
    </div>
  </div>
</div>


