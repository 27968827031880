import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';

import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { PhotoConsentType } from '@models/photo/photo-consent-type';
import { Tag } from '@models/tag/tag';
import { TagType } from '@models/tag/tag-type';
import { PhotoEventType } from '@models/photo/photo-event-type';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { PhotoEditingService } from '@services/photo-editing.service';
import { ImageService } from '@services/image.service';
import { PhotosFilterService } from '@services/photos-filter.service';
import { PhotoSeriesService } from '@services/photo-series.service';
import { SeriesType } from '@models/photo/series-type';
import { ConfirmUnsavedChangesDialogComponent } from '@app/management/dialogs/confirm-unsaved-changes/confirm-unsaved-changes.component';
import { PhotoLightboxComponent } from '../shared-photos/photo-lightbox/photo-lightbox.component';
import { PatientService } from '@services/patient.service';

@Component({
  selector: 'app-patient-photos-consented-tab',
  templateUrl: './patient-photos-consented-tab.component.html',
  styleUrls: ['./patient-photos-consented-tab.component.less'],
})
export class PatientPhotosConsentedTabComponent {
  // loading: boolean;
  // editMode: boolean;
  // photo: PhotoMetaData;
  // photoEditorConfig: { ImageName: any; ImageUrl: any; ImageType: any; };
  // undoPhotoEdit: any;
  // photoCropped: boolean;
  // editedPhoto: any;
  // photoSubscription: any;
  // _adminPhotos: PhotoMetaData[] = [];
  // set adminPhotos(photos: PhotoMetaData[]){
  //   var selectNewPhoto = this.photo && photos.findIndex(p => p.id == this.photo.id) == -1;
  //   this._adminPhotos  = photos;
  //   if (selectNewPhoto && photos.length > 0) {
  //     let photo = this._adminPhotos.find(p => this.showStockImages ? true : !p.isStockPhoto)
  //     this.photoEditingService.loadPhoto(photo)
  //   }

  // }
  // get adminPhotos(){
  //   return this._adminPhotos;
  // }

  // _consentedImages: PhotoMetaData[] = [];
  // set consentedImages(photos: PhotoMetaData[]){
  //   var selectNewPhoto = this.photo && photos.findIndex(p => p.id == this.photo.id) == -1;
  //   this._consentedImages  = photos;

  //   if (selectNewPhoto && photos.length > 0) {
  //     let photo = this._consentedImages.find(p => this.showStockImages ? true : !p.isStockPhoto)
  //     this.photoEditingService.loadPhoto(photo)
  //   }
  // }
  // get consentedImages(){
  //   return this._consentedImages;
  // }
  // previousPhotos = [];
  // adminMode = false;
  // showStockImages = false;
  // isFilterMode = false;
  // isFilterApplied = false;
  // tags: Tag[] = [];
  // photoSeriesList: PhotoMetaData[] = [];
  unsub: Subject<void> = new Subject<void>();
  // seriesViewMode = false;
  // seriesMode = false;
  // isNewPhotoSeries = false;
  // newItemId: number;
  // seriesType: SeriesType;
  // clearTags = true;
  // term = '';
  // clearToolbarSearch = false;

  constructor(
    private photoEditingService: PhotoEditingService,
    private imageService: ImageService,
    private confirmDialog: MatDialog,
    private photosFilterService: PhotosFilterService,
    private photoSeriesService: PhotoSeriesService,
    private patientService: PatientService
  ) {
    // this.editMode = false;
    // this.tags = [];
    // this.undoPhotoEdit = new Subject<void>();
    //   this.photoEditorConfig = {
    //     ImageName: 'Some image',
    //     ImageUrl: 'https://static.pexels.com/photos/248797/pexels-photo-248797.jpeg',
    //     ImageType: 'image/png'
    //   };
  }

  // ngOnInit() {
  //   this.getConsentedPhotos();
  //   this.adminPhotos = [];
  //   this.imageService.photosTabLoadingState$.pipe(takeUntil(this.unsub)).subscribe(loading => {
  //     this.loading = loading;
  //   });

  //   this.patientService.thePatientUpdated$.pipe(takeUntil(this.unsub)).subscribe(patient => {
  //     if (patient && patient.photos ){
  //       this.getConsentedPhotos();
  //     } else {
  //       this.getFilteredPhotos(this.tags);
  //     }
  //   });

  //   this.imageService.metadataUpdated$.pipe(takeUntil(this.unsub)).subscribe(image => {
  //     let indexToUpdate = this.consentedImages.findIndex(p => p.id === image.id && !p.isSeries);
  //     if (indexToUpdate > -1) this.consentedImages[indexToUpdate] = image;
  //     indexToUpdate = this.adminPhotos.findIndex(p => p.id === image.id && !p.isSeries);
  //     if (indexToUpdate > -1) this.adminPhotos[indexToUpdate] = image;
  //     this.photo = image;
  //   });

  //   this.photoSeriesService.metadataUpdated$.pipe(takeUntil(this.unsub)).subscribe(image => {
  //     let indexToUpdate = this.consentedImages.findIndex(p => p.id === image.id && !p.isSeries);
  //     if (indexToUpdate > -1) this.consentedImages[indexToUpdate] = image;
  //     var deepCopy = [...this._consentedImages];
  //     this._consentedImages = [];

  //     setTimeout(() => {
  //       this._consentedImages = deepCopy;
  //     });
  //     indexToUpdate = this.adminPhotos.findIndex(p => p.id === image.id && !p.isSeries);
  //     if (indexToUpdate > -1) this.adminPhotos[indexToUpdate] = image;

  //     this.photo = image;
  //   });

  //   this.photosFilterService.filterSource$.pipe(takeUntil(this.unsub)).subscribe(
  //     tags => {
  //       this.tags = tags;
  //       this.clearToolbarSearch = true;
  //       this.term = '';
  //       if (!this.adminMode) {
  //         this.getFilteredPhotos(tags);
  //         this.filterPhotoByTerm(this.term);
  //       } else {
  //         this.getFilteredAdminPhotos(tags);
  //       }

  //     }
  //   );

  //   this.photoSeriesService.photoSeriesSource$.pipe(takeUntil(this.unsub)).subscribe(
  //     (res) => {
  //       this.photoSeriesList = res;
  //     }
  //   );

  //   const extionsMap = {
  //     [PhotoEventType.EditPhoto]: photoEvent => {
  //       this.editMode = true;
  //       this.photo = photoEvent.photo;
  //       if (this.photo && this.photo.isSeries) {
  //         this.seriesMode = true;
  //         this.consentedImages = this.consentedImages.filter(i => !i.isSeries);
  //       }
  //       this.photoEditorConfig = {
  //         ImageName: this.photo.imageName,
  //         ImageUrl: this.photo.filePath,
  //         ImageType: this.photo.imageType
  //       };
  //     },
  //     [PhotoEventType.LoadPhoto]: photoEvent => {
  //       this.editMode = false;
  //       this.seriesViewMode = false;
  //       this.photo = photoEvent.photo ? Object.assign({}, photoEvent.photo) : photoEvent.photo;
  //       if (this.photo && this.photo.isSeries) {
  //         this.seriesType = this.photo.seriesType;
  //         this.seriesViewMode = true;
  //         this.photoSeriesList = this.photo.seriesPhotos ? this.photo.seriesPhotos : [];
  //         this.newItemId = 0;
  //       }
  //     },
  //     [PhotoEventType.CancelEditPhoto]: photoEvent => {
  //       this.editMode = false;
  //       if (this.photo && this.photo.isSeries) {
  //         if (!this.isNewPhotoSeries) {
  //           this.consentedImages = this.previousPhotos;
  //           this.seriesMode = false;
  //         }
  //       }
  //     },
  //     [PhotoEventType.UndoPhotoChanges]: photoEvent => {
  //       this.undoPhotoEdit.next();
  //     },
  //     [PhotoEventType.ClearPhoto]: photoEvent => {
  //       if (this.consentedImages && this.consentedImages.length == 1){
  //         this.photo = null;
  //       }
  //       else{
  //         var i = this.consentedImages.findIndex(p => p.id == this.photo.id);
  //         this.photoEditingService.loadPhoto( this.consentedImages[i + 1 < this.consentedImages.length ? i + 1 : i -1]);
  //       }
  //     },
  //     [PhotoEventType.PhotoCropped]: photoEvent => {
  //       this.photoCropped = true;
  //     },
  //     [PhotoEventType.FileChanged]: photoEvent => {
  //       this.editedPhoto = photoEvent.file;
  //     }
  //   };

  //   this.photoSubscription = this.photoEditingService.getPhotoSource()
  //     .pipe(takeUntil(this.unsub), delay(0))
  //     .subscribe(photoEvent => extionsMap[photoEvent.event](photoEvent));

  //   this.clearTags = false;
  // }

  // public saveChanges($event: FormGroup) {
  //   this.loading = true;
  //   Object.assign(this.photo, new PhotoMetaData($event.value));
  //   if (isNullOrUndefined(this.photo.notes)) {
  //     this.photo.notes = '';
  //   }
  //   if (isNullOrUndefined(this.photo.photoConsentTypeId)) {
  //     this.photo.photoConsentTypeId = PhotoConsentType.None;
  //   }
  //   this.photo.tags = this.processTags($event.value.tags);

  //   if (this.photo.isSeries) {
  //     this.updatePhotoSeries();
  //   } else {
  //     this.updateSinglePhoto();
  //   }
  // }

  // private updateSinglePhoto() {
  //   if (!isNullOrUndefined(this.editedPhoto) && this.photoCropped) {
  //     this.imageService.uploadPatientPhoto(this.editedPhoto, this.photo, { createOriginal: false }).pipe(takeUntil(this.unsub)).subscribe(result => {
  //       this.photoEditingService.cancelEdit();
  //       if (this.term) {
  //         const indexToUpdate = this.consentedImages.findIndex(p => p.id === result.id);
  //         this.consentedImages[indexToUpdate] = Object.assign(this.consentedImages[indexToUpdate], result);
  //         this.consentedImages = this.consentedImages.slice();
  //         this.loading = false;
  //         return;
  //       }

  //       this.getConsentedPhotos();
  //       this.loading = false;
  //     },
  //       (err) => {
  //         this.loading = false;
  //         console.error('Upload Error:', err);
  //       });
  //   } else {
  //     this.imageService.uploadPhotoMetaData(this.photo).pipe(takeUntil(this.unsub)).subscribe(result => {
  //       this.photoEditingService.cancelEdit();
  //       if (this.term) {
  //         const indexToUpdate = this.consentedImages.findIndex(p => p.id === result.id);
  //         this.consentedImages[indexToUpdate] = Object.assign(this.consentedImages[indexToUpdate], result);
  //         this.consentedImages = this.consentedImages.slice();
  //         this.loading = false;
  //         return;
  //       }
  //       this.getConsentedPhotos();
  //       this.loading = false;
  //     });
  //   }
  // }

  // private updatePhotoSeries() {
  //   if (this.isNewPhotoSeries) {
  //     this.photoEditingService.cancelEdit();
  //     this.loading = false;
  //     return;
  //   } else {
  //     this.photo.seriesPhotos = this.photoSeriesList;
  //     this.photoSeriesService.updatePhotoSeries(this.photo).subscribe((result) => {
  //       this.photoEditingService.cancelEdit();
  //       this.getConsentedPhotos();
  //       this.loading = false;
  //     });
  //   }
  // }

  // private processTags(tags: any[]): Tag[] {
  //   const tagList: Tag[] = [];
  //   tags.forEach(t => {
  //     if (isNullOrUndefined(t.photoId)) {
  //       const type = !isNullOrUndefined(t.type) ? t.type : TagType.custom;
  //       const title = !isNullOrUndefined(t.title) ? t.title : t.display;
  //       let tagRefId = '';
  //       if (t.type !== TagType.custom) {
  //         if (!isNullOrUndefined(t.tagId)) {
  //           tagRefId = t.tagId.split('-')[1];
  //         }
  //       }
  //       tagList.push(new Tag(t.id, t.tagId, title, type, tagRefId));
  //     } else {
  //       tagList.push(t);
  //     }
  //   });
  //   return tagList;
  // }

  // public revertPhoto() {
  //   const dialogRef = this.confirmDialog.open(GenericDialogComponent, {
  //     width: '300px',
  //     data: {
  //       title: 'Revert Image',
  //       content: 'Are you sure?',
  //       confirmButtonText: 'Confirm'
  //     }
  //   });

  //   dialogRef
  //     .afterClosed()
  //     .pipe(
  //       filter(result => result === 'confirm'),
  //       switchMap(() => {
  //         this.imageService.photosTabLoadingStateChanged(true);

  //         const tempPhotoMetaData: PhotoMetaData = new PhotoMetaData(this.photo);

  //         // this call will download the original version of the image, delete the modified version(both main and thumbnail), and return the file in it's original state
  //         return this.imageService.revertPatientPhoto(tempPhotoMetaData);
  //       }),
  //       takeUntil(this.unsub))
  //     .subscribe(result => {
  //       const indexToUpdate = this.consentedImages.findIndex(p => p.id === result.id);
  //       this.consentedImages[indexToUpdate] = Object.assign(this.consentedImages[indexToUpdate], result);
  //       this.consentedImages = this.consentedImages.slice();
  //       this.editMode = false;
  //       this.photoEditingService.cancelEdit();
  //       this.imageService.photosTabLoadingStateChanged(false);
  //     });
  // }

  // private getConsentedPhotos() {
  //   this.loading = true;
  //   this.consentedImages = [];
  //   this.imageService.getConsentedImages().subscribe(ci => {
  //     this.consentedImages = ci;
  //     this.previousPhotos = ci;
  //     this.loading = false;
  //   });
  // }

  // public getAdminData($event: boolean) {
  //   this.adminMode = $event;
  //   this.seriesMode = false;
  //   this.seriesViewMode = false;
  //   this.isNewPhotoSeries = false;
  //   this.photo = null;
  //   if ($event) {
  //     if (!this.tags.length) {
  //       if (this.term) {
  //         this.filterPhotoByTerm(this.term);
  //       } else {
  //         this.loading = true;
  //         this.imageService.getGlobalSharedImages().subscribe(adminPhotos => {
  //           this.adminPhotos = adminPhotos;
  //           this.loading = false;
  //         });
  //       }
  //     } else {
  //       this.getFilteredAdminPhotos(this.tags);
  //     }
  //   } else {
  //     if (!this.tags.length) {
  //       if (this.term) {
  //         this.filterPhotoByTerm(this.term);
  //       } else {
  //         this.getConsentedPhotos();
  //       }
  //     } else {
  //       this.getFilteredPhotos(this.tags);
  //     }
  //   }
  // }

  // getToolbarHeight(toolbarEl): number{
  //   try{

  //     var div = toolbarEl.getElementsByClassName('section-photo-toolbar')[0];
  //     return div.clientHeight;
  //   }
  //   catch(e){
  //     return 56;
  //   }
  // }

  // private getFilteredPhotos(tags: Tag[]) {
  //   this.loading = true;
  //   if (tags.length) {
  //     this.photosFilterService.getConsentedPhotosByTags(tags).subscribe(
  //       res => {
  //         if (this.seriesMode) {
  //           this.consentedImages = res.filter(e => !e.isSeries);
  //         } else {
  //           this.consentedImages = res;
  //         }

  //         this.isFilterApplied = true;
  //         this.loading = false;
  //       }
  //     );
  //   } else {
  //     if (this.seriesMode) {
  //       this.consentedImages = this.previousPhotos.filter(e => !e.isSeries);
  //     } else {
  //       this.consentedImages = this.previousPhotos;
  //     }

  //     this.isFilterApplied = false;
  //     this.loading = false;
  //   }
  // }

  // private getFilteredAdminPhotos(tags: Tag[]) {
  //   this.loading = true;
  //   if (tags.length) {
  //     this.photosFilterService.getGlobalSharedPhotosByTags(tags).subscribe(
  //       res => {
  //         this.adminPhotos = res;
  //         this.isFilterApplied = true;
  //         this.loading = false;
  //       }
  //     );
  //   } else {
  //     this.imageService.getGlobalSharedImages().subscribe(adminPhotos => {
  //       this.adminPhotos = adminPhotos;
  //       this.loading = false;
  //       this.isFilterApplied = false;
  //     });
  //   }
  // }

  // public toggleFilter() {
  //   this.isFilterMode = !this.isFilterMode;
  // }

  // public switchSeriesMode($event: any) {
  //   if (this.editMode) {
  //     const dialogRef = this.confirmDialog.open(ConfirmUnsavedChangesDialogComponent, {
  //       width: '250px'
  //     });

  //     dialogRef
  //       .afterClosed()
  //       .pipe(takeUntil(this.unsub))
  //       .subscribe(result => {
  //         if (result === 'confirm') {
  //           this.editMode = false;

  //           this.photoSeriesList = [];
  //           this.seriesMode = $event.state;
  //           this.seriesViewMode = $event.state;
  //           this.isNewPhotoSeries = true;
  //           this.consentedImages = this.consentedImages.filter(i => !i.isSeries);
  //         }
  //       });
  //   } else {
  //     if ($event.state) {
  //       this.photoSeriesList = [];
  //       this.seriesMode = $event.state;
  //       this.seriesViewMode = $event.state;
  //       this.seriesType = $event.type;
  //       this.isNewPhotoSeries = true;
  //       this.consentedImages = this.consentedImages.filter(i => !i.isSeries);
  //     } else {
  //       this.clearToolbarSearch = true;
  //       this.loading = true;
  //       this.isNewPhotoSeries = false;
  //       this.seriesMode = $event.state;
  //       this.seriesType = null;
  //       this.seriesViewMode = $event.state;
  //       this.getConsentedPhotos();
  //     }
  //   }
  // }

  // public createSeries() {
  //   this.loading = true;
  //   const patientId = this.photoSeriesList[0].patient.patientId;
  //   const data = JSON.parse(JSON.stringify(this.photoSeriesList));
  //   const series = new PhotoMetaData({
  //     patientId: patientId,
  //     seriesPhotos: data
  //   });

  //   Object.assign(series, this.photo);

  //   this.photoSeriesService.createPhotoSeries(series).subscribe((series: PhotoMetaData) => {
  //     this.isNewPhotoSeries = false;
  //     this.getConsentedPhotos();
  //     this.newItemId = series.id;
  //     this.clearToolbarSearch = true;
  //     this.term = '';

  //     this.photoEditingService.loadPhoto(series);
  //     this.loading = false;
  //   });
  //   this.seriesMode = false;
  // }

  // public filterPhotoByTerm(event: string) {
  //   this.clearToolbarSearch = false;
  //   this.term = event;
  //   this.loading = true;
  //   if (event) {
  //     this.photosFilterService.getPatientPhotosSearchTerm(event, this.adminMode, null)
  //       .subscribe(
  //         res => {
  //           if (this.adminMode) {
  //             this.adminPhotos = res;
  //             this.resetTagsAndLoading();
  //           } else {
  //           this.consentedImages = res;
  //             this.resetTagsAndLoading();
  //           }
  //         }
  //       );
  //   } else {
  //     if (this.seriesMode) {
  //       this.consentedImages = this.previousPhotos.filter(e => !e.isSeries);
  //     } else {
  //       if (this.adminMode) {
  //         this.getAdminData(this.adminMode);
  //       } else {
  //         this.consentedImages = this.previousPhotos;
  //       }
  //     }
  //     this.resetTagsAndLoading();
  //   }
  // }

  // private resetTagsAndLoading() {
  //   this.loading = false;
  //   this.clearTags = false;
  // }

  // public openLightBox(event: number) {
  //   const dialogRef = this.confirmDialog.open(PhotoLightboxComponent, {
  //     panelClass: 'lightbox-dialog',
  //     width: '100%',
  //     height: '100%',
  //     maxWidth: '100%',
  //     data: {
  //       photos: this.adminMode ? this.adminPhotos : this.consentedImages,
  //       photo: this.photo,
  //       index: event,
  //       patientPhotoIndex: this.adminMode ? this.adminPhotos.findIndex(p => p.id == this.photo.id) : this.consentedImages.findIndex(p => p.id == this.photo.id)

  //     }
  //   });
  // }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
