export class ServiceTemplateUserCommission {
  id: number;
  serviceTemplateId: number;
  serviceTemplateTypeId: number;
  serviceName: string;
  observationListId: number;
  userId: string;
  commission: number;
  isFlatRate: boolean; // Commission rate is either a percentage or flat

  constructor() {
    this.id = 0;
    this.serviceTemplateId = null;
    this.serviceTemplateTypeId = null;
    this.serviceName = '';
    this.observationListId = null;
    this.userId = '';
    this.commission = null;
    this.isFlatRate = false;
  }
}
