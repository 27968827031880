import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TextTemplate } from '@models/text-template';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TextTemplatesService {

  constructor(private http: HttpClient) { }

  addTextTemplate(textTemplate: TextTemplate): Observable<TextTemplate> {
    return this.http.post<TextTemplate>(`${environment.baseUrl}api/TextTemplates`, textTemplate);
  }

  updateTextTemplate(textTemplate: TextTemplate): Observable<TextTemplate> {
    return this.http.put<TextTemplate>(`${environment.baseUrl}api/TextTemplates`, textTemplate);
  }

  getTextTemplateById(id: number): Observable<TextTemplate> {
    return this.http.get<TextTemplate>(`${environment.baseUrl}api/TextTemplates/${id}`);
  }

  getAllTextTemplates(): Observable<TextTemplate[]> {
    return this.http.get<TextTemplate[]>(`${environment.baseUrl}api/TextTemplates`);
  }

  deleteTextTemplate(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.baseUrl}api/TextTemplates/${id}`);
  }
}
