import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Form } from '@models/forms/form';
import { FormType } from '@models/forms/form-type';
import { SharedForm } from '@models/forms/shared-form';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SharedFormService {
  sharedFormsLoaded = new Subject<SharedForm[]>();
  sharedFormSelected = new Subject<SharedForm>();
  sharedFormAdded = new Subject<SharedForm>();
  sharedFormAddedFromLibrary = new Subject<Form>();

  defaultId = 0;
  constructor(private http: HttpClient) {}

  addSharedForm(form: Form) {
    return this.http.post<SharedForm>(environment.baseUrl + 'api/SharedForms', form).pipe(
      map((addedSharedForm: SharedForm) => {
        this.sharedFormAdded.next(addedSharedForm);
        return addedSharedForm;
      })
    );
  }

  getSharedFormsByFormType(formType: FormType): Observable<SharedForm[]> {
    return this.http.get<SharedForm[]>(environment.baseUrl + 'api/SharedForms/ByFormType/' + formType.id);
  }

  getFormsByServiceTemplateType(serviceTemplateTypeId: number): Observable<Form[]> {
    return this.http.get<Form[]>(
      environment.baseUrl + 'api/SharedForms/ByServiceTemplateType/' + serviceTemplateTypeId
    );
  }

  addFromLibrary(sharedFormId: number) {
    return this.http.post<Form>(environment.baseUrl + 'api/SharedForms/AddFromLibrary/' + sharedFormId, null).pipe(
      map((addedForm) => {
        this.sharedFormAddedFromLibrary.next(addedForm);
        return addedForm;
      })
    );
  }
}
