import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { FormBuilderComponent as FormioBuilderComponent, FormioOptions, FormioRefreshValue } from '@formio/angular';
import { Form } from '@models/forms/form';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { builderOptions } from './builder-options';
import { eTreatmentForm } from '@models/etreatment-forms/etreatment-form';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.less'],
})
export class FormBuilderComponent implements OnInit {
  @Input() form: Form | eTreatmentForm;
  @Input() refreshBuilder: Observable<Form | eTreatmentForm>;

  @ViewChild('builder') builder: FormioBuilderComponent;
  @ViewChild('json') jsonElement?: ElementRef;
  @ViewChild('code') codeElement?: ElementRef;
  @ViewChild(PerfectScrollbarComponent, { static: false }) perfectScrollbar: PerfectScrollbarComponent;

  unsub: Subject<void> = new Subject<void>();
  formId: number;
  formObj: Object;
  builderOptions: FormioOptions = builderOptions as FormioOptions;
  developerPolicy = Policies.developer;

  constructor(
    private route: ActivatedRoute
  ) {}

  public refreshForm: EventEmitter<FormioRefreshValue> = new EventEmitter();

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      this.formId = +params['id'];
    });

    this.refreshBuilder.pipe(takeUntil(this.unsub)).subscribe((form) => {
      this.form = form;
      this.initBuilder();
    });

    this.initBuilder();
  }

  private initBuilder() {
    // Initialize an empty instance
    this.formObj = { components: [] };

    if (this.form) {
      if (this.form.formDefinition && this.form.formDefinition !== '') {
        this.formObj = JSON.parse(this.form.formDefinition);
        if (this.jsonElement) {
          this.jsonElement.nativeElement.innerHTML = '';
          this.jsonElement.nativeElement.appendChild(document.createTextNode(this.form.formDefinition));
        }
      }
    }

    if (this.builder) {
      this.builder.form = this.formObj;
      this.builder.buildForm(this.formObj);
    }
  }

  onChange(event) {
    this.form.formDefinition = JSON.stringify(event.form, null, 4);

    if (this.jsonElement) {
      this.jsonElement.nativeElement.innerHTML = '';
      this.jsonElement.nativeElement.appendChild(document.createTextNode(JSON.stringify(event.form, null, 4)));
    }

    this.refreshForm.emit({
      property: 'form',
      value: event.form,
    });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
