<ngx-loading [show]="loading"></ngx-loading>
<div class="container-fluid h-100 pb-3">
  <div class="row h-100">
    <div class="col-2 h-100">
      <div (click)="goToOverview()" class="text-left text-uppercase p-4 navy-background white-font pointer w-100">
        <i class="far fa-arrow-left mr-2"></i>Chart
      </div>
      <div class="favourite-prescriptions border">
        <h6 class="px-3 py-2">Favourite Prescriptions</h6>
        <div class="favourite-prescriptions-list">
          <perfect-scrollbar>
            <div class="px-3">
              <div
                class="favourite-prescriptions-item d-flex align-items-center navy-font"
                *ngFor="let item of favouritePrescriptionList"
              >
                <div class="text-elipsis p-2 font-weight-bold">
                  {{ item.name }}
                </div>
                <i
                  (click)="addFavouriteToPrescription(item.prescription)"
                  class="far fa-plus-circle navy-font ml-auto mr-2 pointer"
                ></i>
                <i (click)="deleteFavouritePrescription(item)" class="far fa-times-circle navy-font mr-2 pointer"></i>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
    <div class="prescription-main-area col-10 d-flex flex-column">
      <app-error *ngIf="errorMessage" [message]="errorMessage" [showCloseButton]="true"></app-error>
      <form (keydown.enter)="$event.preventDefault()" #prescriptionsForm="ngForm">
        <div class="prescription-header align-items-center d-flex border-bottom pt-3 pb-3">
          <h6 class="text-uppercase navy-font">Create Prescription</h6>
          <button type="button" (click)="navigateToPrescriptionView()" class="btn em-btn em-btn-green ml-auto mr-4">
            cancel
          </button>
          <button
            [disabled]="!(prescriptionsForm?.valid && prescriptionItems.length) && !anyListedDrugsValid()"
            (click)="printPrescription()"
            class="btn em-btn em-btn-green mr-4"
          >
            PRINT
          </button>
          <button
            [disabled]="!(prescriptionsForm?.valid && prescriptionItems.length) && !anyListedDrugsValid()"
            (click)="faxPrescription()"
            class="btn em-btn em-btn-green mr-4"
          >
            FAX
          </button>
        </div>
        <div class="pt-3 pb-3">
          <button (click)="addNewPrescriptionForm()" class="btn em-btn em-btn-green mr-4 mb-1">
            <i class="fal fa-plus-circle pr-1"></i>
            Add New Entry
          </button>
          <div class="input-group" [hidden]="true">
            <!--  see #213 for details -->
            <div class="input-group-prepend">
              <div class="input-group-text border-radius grey-three-background border-0">
                <i class="fa fa-search"></i>
              </div>
            </div>
            <input
              #typeaheadDirective="bs-typeahead"
              (keyup.enter)="addNewPrescriptionForm()"
              [(ngModel)]="asyncSelected"
              [typeaheadAsync]="true"
              [typeahead]="dataSource"
              (typeaheadLoading)="changeTypeaheadLoading($event)"
              (typeaheadOnSelect)="typeaheadOnSelect($event)"
              (typeaheadNoResults)="typeaheadNoResults($event)"
              [typeaheadOptionsLimit]="10"
              [typeaheadItemTemplate]="customItemTemplate"
              [optionsListTemplate]="customListTemplate"
              typeaheadOptionField="drugName"
              [typeaheadWaitMs]="400"
              type="text"
              name="drugSearch"
              class="form-control border-0 grey-three-background"
              placeholder="Enter Drug Name"
            />

            <ng-template #customListTemplate let-matches="matches" let-itemTemplate="itemTemplate" let-query="query">
              <ng-template ngFor let-match let-i="index" [ngForOf]="matches">
                <h6 *ngIf="match.isHeader()" class="dropdown-header">{{ match }}</h6>
                <ng-template [ngIf]="!match.isHeader()">
                  <button
                    #liElements
                    class="dropdown-item"
                    (click)="container.selectMatch(match, $event)"
                    (mouseenter)="container.selectActive(match)"
                    [class.active]="container.isActive(match)"
                  >
                    <ng-template
                      [ngTemplateOutlet]="itemTemplate"
                      [ngTemplateOutletContext]="{ item: match.item, index: i, match: match, query: query }"
                    ></ng-template>
                  </button>
                </ng-template>
              </ng-template>
            </ng-template>

            <ng-template #customItemTemplate let-match="match" let-query="query">
              <span [innerHtml]="container.highlight(match, query)"></span>
            </ng-template>
          </div>

          <div class="mt-1 no-result px-4 py-2 navy-font" *ngIf="noResult">Press 'Enter' to add new drug</div>

          <perfect-scrollbar appDetermineMaxScrollHeight [itemsToWatch]="[prescriptionItems]">
            <ng-container *ngIf="prescriptionItems.length">
              <div class="d-flex mt-1" *ngFor="let item of prescriptionItems; let i = index">
                <div class="border d-flex flex-grow-1 p-3">
                  <div class="col-5">
                    <div class="pt-2 d-flex" [ngClass]="{ 'pb-2': drugName.valid }">
                      <span class="font-weight-bold navy-font flex-shrink-0">Drug Name: </span>
                      <input
                        (focus)="input_focussed = i"
                        (blur)="input_focussed = -1"
                        type="text"
                        required
                        class="ml-1 border-0 w-100"
                        placeholder="Add Drug Name"
                        [(ngModel)]="item.drugName"
                        name="drugName-{{ item.index }}"
                        #drugName="ngModel"
                      />
                    </div>
                    <div *ngIf="!drugName.valid && input_focussed == i" class="error-message pb-2 navy-font">
                      Drug name must be included
                    </div>
                    <div class="pb-2 pt-2 d-flex flex-column">
                      <div class="d-flex">
                        <span class="font-weight-bold navy-font">QTY/Mitte: </span>
                        <input
                          type="text"
                          class="ml-1 border-0 w-100"
                          placeholder="Add Quantity"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          [(ngModel)]="item.quantity"
                          name="quantity-{{ item.index }}"
                          #quantity="ngModel"
                        />
                      </div>
                      <div *ngIf="!quantity.valid" class="error-message py-2 navy-font">Quantity must be a number</div>
                    </div>
                    <div class="pb-2 pt-2 d-flex">
                      <span class="font-weight-bold navy-font">SIG: </span>
                      <input
                        type="text"
                        class="ml-1 border-0 w-100"
                        placeholder="Add SIG"
                        [(ngModel)]="item.sig"
                        name="sig-{{ item.index }}"
                      />
                    </div>
                  </div>
                  <div class="col-5 d-flex align-items-center">
                    <div class="pb-2 pt-2 d-flex w-100 flex-column">
                      <div class="d-flex">
                        <span class="font-weight-bold navy-font">Repeats: </span>
                        <input
                          type="text"
                          class="ml-1 border-0 w-100"
                          placeholder="Add Repeats"
                          pattern="[0-9]+"
                          [(ngModel)]="item.refillCount"
                          name="refillCount-{{ item.index }}"
                          #refillCount="ngModel"
                        />
                      </div>
                      <div *ngIf="!refillCount.valid" class="error-message py-2 navy-font">
                        Repeats must be a number
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="pb-2 pt-2">
                      <span class="font-weight-bold navy-font">QHS: </span>
                      <label class="switch align-text-top">
                        <input type="checkbox" [(ngModel)]="item.isQhs" name="isQhs-{{ item.index }}" />
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <div class="pb-2 pt-2">
                      <span class="font-weight-bold navy-font">PRN: </span>
                      <label class="switch align-text-top">
                        <input type="checkbox" [(ngModel)]="item.isPrn" name="isPrn-{{ item.index }}" />
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <div class="pb-2 pt-2">
                      <span class="font-weight-bold navy-font">No Subs: </span>
                      <label class="switch align-text-top">
                        <input type="checkbox" [(ngModel)]="item.isNoSubs" name="isNoSubs-{{ item.index }}" />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="controls d-flex flex-column align-items-center ml-auto pl-3">
                  <button
                    class="btn btn-sm em-btn em-btn-green btn-clean mr-2 mb-2"
                    kendoTooltip
                    tooltipClass="em-tooltip"
                    position="left"
                    title="Add to Favourites"
                    tooltipclass="em-tooltip"
                  >
                    <i
                      (click)="selectFavourite(item)"
                      [popover]="popTemplate"
                      [outsideClick]="true"
                      placement="left"
                      data-container="body"
                      [container]="'body'"
                      #pop="bs-popover"
                      class="far fa-heart pointer"
                    ></i>
                  </button>
                  <button
                    (click)="removePrescription(i)"
                    class="btn btn-sm em-btn em-btn-green btn-clean mr-2 mb-2"
                    kendoTooltip
                    tooltipClass="em-tooltip"
                    position="left"
                    title="Delete Prescription"
                    tooltipclass="em-tooltip"
                  >
                    <i class="far fa-trash-alt"></i>
                  </button>
                  <ng-template #popTemplate>
                    <div class="d-flex flex-column">
                      <div class="p-2">Add Favourite Name</div>
                      <input
                        type="text"
                        class="form-control"
                        name="favName"
                        required
                        #favName="ngModel"
                        [(ngModel)]="favouritePresciption.name"
                      />
                      <button
                        (click)="addFavouritePrescription(); pop.hide()"
                        class="btn em-btn em-btn-green p-2 mt-2"
                        [disabled]="favName.invalid"
                      >
                        add
                      </button>
                    </div>
                  </ng-template>
                </div>
              </div>
            </ng-container>
          </perfect-scrollbar>
        </div>
      </form>
    </div>
  </div>
</div>

<div style="opacity: 0; position: absolute; width: 0; height: 0">
  <ng-template #exportContainer></ng-template>
</div>
