import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';
import { Clinic } from '@models/clinic';
import { Patient } from '@models/patient';
import { Prescription } from '@models/prescriptions/prescription';
import { BlobService } from '@services/blob.service';
import { ClinicsService } from '@services/clinics.service';
import { PatientService } from '@services/patient.service';
import { PrescriptionService } from '@services/prescriptions/prescription.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-prescriptions-view',
  templateUrl: './prescriptions-view.component.html',
  styleUrls: ['./prescriptions-view.component.less'],
})
export class PrescriptionsViewComponent implements OnInit, OnDestroy {
  @ViewChild('prescriptionDetail') prescriptionDetail: any;
  @ViewChild('exportContainer', { read: ViewContainerRef }) exportContainer: ViewContainerRef;
  prescriptionList: Prescription[];
  patientId: number;
  prescriptionId: number;
  selectedPrescription: Prescription;
  clinic: Clinic;
  prescriptionLoading = false;
  hasError = false;
  errorMessage: string;

  unsub: Subject<void> = new Subject<void>();

  get patient(): Patient {
    return this.patientService.patientPanelPatient;
  }
  constructor(
    private prescriptionService: PrescriptionService,
    private patientService: PatientService,
    private clinicsService: ClinicsService,
    private blobService: BlobService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private resolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    this.clinic = this.clinicsService.clinic;
    this.patientId = this.route.snapshot.params.patId.split('_')[0];
    this.route.queryParams.subscribe((params) => {
      this.prescriptionId = +params['prescriptionId'];
    });
    this.getPrescription();
  }

  private getPrescription() {
    this.prescriptionLoading = true;
    this.prescriptionService.getPrescriptionsByPatientId(this.patientId).subscribe((prescriptions) => {
      this.prescriptionList = prescriptions;
      if (prescriptions.length > 0) {
        if (this.prescriptionId) {
          this.selectedPrescription = this.prescriptionList.find((i) => i.id === this.prescriptionId);
        } else {
          this.selectedPrescription = prescriptions[0];
          this.prescriptionId = prescriptions[0].id;
        }
      }
      this.prescriptionLoading = false;
    });
  }

  async printPrescription(prescription: Prescription) {
    this.prescriptionLoading = true;
    await this.prescriptionService.printPrescription([prescription], this.exportContainer, this.patient);
    this.prescriptionLoading = false;
  }

  async faxPrescription(prescription: Prescription) {
    this.prescriptionLoading = true;
    await this.prescriptionService
      .faxPrescription([prescription], this.exportContainer, this.patient)
      .catch((reason) => {
        this.hasError = true;
        this.errorMessage = reason;
      });
    this.prescriptionLoading = false;
  }

  goToOverview() {
    this.router.navigate(['overview'], { relativeTo: this.route.parent });
  }

  getDateStr(date: string) {
    return moment(date).format('YYYY-MM-DD');
  }

  deletePrescription(prescription: Prescription) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'delete') {
          this.prescriptionService.deletePrescription(prescription.id).subscribe(() => {
            this.getPrescription();
          });
        }
      });
  }

  changePrescription(item: Prescription) {
    this.selectedPrescription = item;
    this.router.navigate(['.'], { relativeTo: this.route, queryParams: { prescriptionId: item.id } });
  }

  goToCreatePrescription() {
    this.router.navigate(['prescription'], { relativeTo: this.route.parent });
  }

  createNewPrescription() {
    this.prescriptionService.sharePrescription();
    this.goToCreatePrescription();
  }

  discontinuePrescription(item?: Prescription) {
    if (item) {
      this.prescriptionService.discontinuePrescription(item.id).subscribe((res) => {
        this.getPrescription();
      });
    } else {
      this.prescriptionService.discontinuePrescription(this.selectedPrescription.id).subscribe((res) => {
        this.getPrescription();
      });
    }
  }

  renewPrescription(item?: Prescription) {
    if (item) {
      item.signatureId = null;
      this.prescriptionService.sharePrescription(item);
    } else {
      this.selectedPrescription.signatureId = null;
      this.prescriptionService.sharePrescription(this.selectedPrescription);
    }
    this.goToCreatePrescription();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
