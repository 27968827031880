import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { HoursOfOperationDialogComponent } from '@app/management/dialogs/hours-of-operation/hours-of-operation.component';
import { toTitleCase } from '@app/shared/helpers';
import { phoneNumberValidator } from '@app/shared/validators/phone-number.validator';
import { postalCodeValidator } from '@app/shared/validators/postal-code.validator';
import { Clinic, ClinicRoom, ClinicTax } from '@models/clinic';
import { HoursOfOperationDay, HoursOfOperationType, defaultHoursOfOperation } from '@models/hoursofoperation';
import { MasterOverlayService } from '@services/actionpanel.service';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { ClinicsService } from '@services/clinics.service';
import { CurrentDataService } from '@services/currentData.service';
import { FormatterService } from '@services/formatter.service';
import { GeographyService } from '@services/geography.service';
import { ReviewService } from '@services/review.service';
import { SquareService } from '@services/square.service';
import { TagService } from '@services/tag.service';
import { TaxService } from '@services/tax.service';
import { UsersService } from '@services/users.service';
import { ValidationService } from '@services/validation.service';
import { CountryName, CountrySlug, RegionName, RegionSlug, allCountries } from 'country-region-data';
import { AsYouType, CountryCode, getExampleNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Tag } from '../../../../models/tag/tag';
import { PaymentProcessorType, PaymentProcessorTypeTitle } from '@models/payments/payment-processor-type.enum';

interface RegionData {
  name: RegionName;
  slug: RegionSlug;
}

interface CountryData {
  name: CountryName;
  slug: CountrySlug;
  regions: RegionData[];
}
@Component({
  selector: 'app-edit-clinic',
  templateUrl: './edit-clinic.component.html',
  styleUrls: ['./edit-clinic.component.less'],
})
export class EditClinicComponent implements OnInit, OnDestroy {
  loading = false;
  unsub: Subject<any> = new Subject<any>();
  clinicIdParam: string;
  websiteUrlRegex: RegExp;
  isNewClinic = false;
  canAddClinic = false;
  countries: CountryData[];
  selectedCountryRegions: RegionData[];
  timezonesOfTheWorld: any;
  durationOptions: number[] = [5, 10, 15, 20, 30];
  clinicFormGroup: FormGroup;
  clinic: Clinic = null;
  lookingUpPlaceId = false;
  possibleClinicTaxes: ClinicTax[] = [];
  possibleClinicRooms: ClinicRoom[] = [];
  allPhotoSection: Tag[] = [];
  developerPolicy = Policies.developer;

  paymentProcessorTypes: { title: any; value: any }[];

  private phoneNumberFormatter = new AsYouType();

  get formDisabled() {
    return this.clinicFormGroup ? this.clinicFormGroup.disabled : true;
  }

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private usersService: UsersService,
    public currentDataService: CurrentDataService,
    private clinicsService: ClinicsService,
    private taxService: TaxService,
    private catalogueUpdatesService: CatalogueUpdatesService,
    private geographyService: GeographyService,
    private route: ActivatedRoute,
    private reviewService: ReviewService,
    private fb: FormBuilder,
    public validationService: ValidationService,
    public formatterService: FormatterService,
    private masterOverlayService: MasterOverlayService,
    private squareService: SquareService,
    private tagService: TagService
  ) {
    this.taxService.getTaxes().subscribe((taxes) => {
      if (taxes) {
        taxes.forEach((element) => {
          const clinicTax = {
            clinicId: 0,
            clinic: null,
            taxId: element.taxId,
            tax: element,
          };
          this.possibleClinicTaxes.push(clinicTax);
        });
      }
    });

    this.websiteUrlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

    this.tagService.getExternalPhotoTags().subscribe((res) => {
      this.allPhotoSection = res.Service;
    });

    this.countries = allCountries.map((country) => ({
      name: country[0],
      slug: country[1],
      regions: country[2].map((region) => ({ name: region[0], slug: region[1] })),
    }));
    const can = this.countries.find((country) => country.slug == 'CA');
    const usa = this.countries.find((country) => country.slug == 'US');
    const aus = this.countries.find((country) => country.slug == 'AU');
    this.countries.unshift(can, usa, aus);

    this.paymentProcessorTypes = Object.keys(PaymentProcessorTypeTitle).map((e) => ({
      title: PaymentProcessorTypeTitle[e],
      value: PaymentProcessorType[e],
    }));
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }

  ngOnInit() {
    this.route.queryParamMap.pipe(takeUntil(this.unsub)).subscribe((paramMap) => {
      if (paramMap.has('code') && paramMap.has('response_type') && paramMap.has('state')) {
        console.log('code ', paramMap.get('code'));
      }
    });

    this.timezonesOfTheWorld = this.geographyService.timeZonesMap();

    this.clinicsService.clinicIdSelected$
      .asObservable()
      .pipe(takeUntil(this.unsub))
      .subscribe((clinicId) => {
        this.canAddClinic = clinicId && clinicId > 0;
        this.initClinic(clinicId);
      });
  }

  private async initClinic(clinicId?: number) {
    this.clinicIdParam = String(clinicId) ?? '0';
    if (clinicId && clinicId > 0) {
      this.isNewClinic = false;
      this.loading = true;
      this.clinic = await this.clinicsService.getClinicById(clinicId).toPromise();
      this.loading = false;
      if (this.clinic.clinicId === 0) {
        console.log("Clinic either doesn't exist or you are not authorized: " + clinicId);
        this.authService.logout();
      }
    } else {
      this.isNewClinic = true;
      this.clinic = this.initNewClinic(this.clinic?.address?.country ?? '');
    }
    this.initClinicForm();
  }

  onChangeCountry(country: CountryData) {
    if (!country) return;
    this.selectedCountryRegions = country.regions;
    this.clinicFormGroup
      .get('address.postalCode')
      .setValidators([Validators.required, postalCodeValidator(country.slug)]);
    this.clinicFormGroup.get('address.postalCode').updateValueAndValidity();
    this.clinicFormGroup
      .get('phoneNumber')
      .setValidators([Validators.required, phoneNumberValidator(country.slug as CountryCode)]);
    this.clinicFormGroup.get('phoneNumber').updateValueAndValidity();
    this.clinicFormGroup.get('faxNumber').setValidators(phoneNumberValidator(country.slug as CountryCode));
    this.clinicFormGroup.get('faxNumber').updateValueAndValidity();
    this.phoneNumberFormatter = new AsYouType(country.slug as CountryCode);
  }

  onAddClinic() {
    this.initClinic();
    this.canAddClinic = false;
    this.clinicFormGroup.enable();
    this.clinicFormGroup.markAsPristine();
    this.clinicFormGroup.markAsUntouched();
  }

  onClinicFormEdit() {
    this.clinicFormGroup.enable();
    this.clinicFormGroup.markAsPristine();
    this.clinicFormGroup.markAsUntouched();
  }

  onClinicFormSubmit() {
    const valid = this.checkFormValidation();
    if (valid) {
      this.updateClinic();
      // Needed to disable "Send Patient Portal Emails" checkbox. Persisits as enabled without.
      setTimeout(() => this.clinicFormGroup.disable());
    }
  }

  onClinicFormCancel() {
    if (this.isNewClinic) {
      const clinicId = this.clinicsService.clinicIdSelected$.getValue();
      this.initClinic(clinicId);
      this.canAddClinic = true;
    } else {
      this.initClinicForm();
    }
    // Needed to disable "Send Patient Portal Emails" checkbox. Persisits as enabled without.
    setTimeout(() => this.clinicFormGroup.disable());
  }

  private checkFormValidation(): boolean {
    if (this.clinicFormGroup.valid) return true;
    for (const field in this.clinicFormGroup.controls) {
      const control = this.clinicFormGroup.get(field);
      if (control.invalid) {
        control.markAsTouched({ onlySelf: true });
      }
    }
    return false;
  }

  private async updateClinic() {
    const country = this.clinicFormGroup.get('address.country').value as CountryData;
    const province = this.clinicFormGroup.get('address.province').value as CountryData;
    this.clinic = Object.assign(this.clinic, this.clinicFormGroup.value);
    this.clinic.address.country = country.name;
    this.clinic.address.province = province.name;

    if (this.isNewClinic) {
      const clinic = await this.clinicsService
        .addClinic(this.clinic)
        .toPromise()
        .catch((error) => {
          throw error;
        });
      this.catalogueUpdatesService.refreshRequired = true;
      this.catalogueUpdatesService.catalogueUpdateComplete();
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '250px',
        data: {
          showCancel: false,
          title: 'Add',
          content: 'A new clinic has been created.',
          confirmButtonText: 'Ok',
        },
      });
      if (parseInt(this.authService.activeClinicId) === -1) {
        //Added initial clinic - we need to refresh the token for navigation,
        //this refreshes the token and navigation is operable again.
        // this.authService.refreshTokenRequest('/').toPromise();
      } else {
        // This will cause the clinic form to be renitialized
        this.clinicsService.clinicIdSelected$.next(clinic.clinicId);
      }
      this.isNewClinic = false;
    } else {
      await this.clinicsService
        .updateClinic(this.clinic)
        .toPromise()
        .catch((error) => {
          throw error;
        });
      this.catalogueUpdatesService.refreshRequired = true;
      this.catalogueUpdatesService.catalogueUpdateComplete();
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '250px',
        data: {
          showCancel: false,
          title: 'Update',
          content: 'The clinic details have been updated.',
          confirmButtonText: 'Ok',
        },
      });
      this.initClinicForm();
    }
  }

  cancelUpdate() {
    this.catalogueUpdatesService.refreshRequired = false;
    this.catalogueUpdatesService.catalogueUpdateComplete();
    //this.router.navigate(['/management/organization/clinics/general', {outlets: {'action-panel': null}}]);
  }

  initNewClinic(country: string) {
    const clinic = {
      clinicId: 0,
      name: '',
      blobContainerName: '',
      address: {
        addressId: 0,
        address1: '',
        address2: '',
        city: '',
        country: country,
        postalCode: '',
        province: '',
      },
      clinicRooms: [],
      clinicTaxes: [],
      companyId: Number.isNaN(this.usersService.loggedInUserCompany)
        ? parseInt(this.authService.userCompanyId)
        : this.usersService.loggedInUserCompany,
      email: '',
      website: '',
      googlePlaceId: '',
      srFaxId: null,
      srFaxPassword: '',
      hoursOfOperation: defaultHoursOfOperation,
      timezone:
        this.usersService.loggedInUserTimezone == null || this.usersService.loggedInUserTimezone === ''
          ? this.timezonesOfTheWorld[0]
          : this.usersService.loggedInUserTimezone,
      minimumDuration: 5,
      loyaltyPointsToDollarsRate: null,
      staffBookingCancellationPrice: null,
      customInvoiceText: '',
      url: null,
      sendPatientPortalEmail: false,
      defaultPortalPhotoTagId: null,
      paymentProcessorType: 0,
    };
    return clinic;
  }

  initClinicForm() {
    const countryName = toTitleCase(this.clinic.address?.country ?? '') as CountryName;
    const country = this.getCountryDataByName(countryName);
    const province = this.getRegionDataByName(country, this.clinic.address?.province);

    this.clinicFormGroup = this.fb.group({
      name: [this.clinic.name, [Validators.required]],
      address: this.fb.group({
        addressId: [this.clinic.address ? this.clinic.address.addressId : 0],
        address1: [this.clinic.address ? this.clinic.address.address1 : null, [Validators.required]],
        address2: [this.clinic.address ? this.clinic.address.address2 : null],
        city: [this.clinic.address ? this.clinic.address.city : null, [Validators.required]],
        country: [country, [Validators.required]],
        postalCode: [
          this.clinic.address?.postalCode.toUpperCase(),
          [Validators.required, postalCodeValidator(country?.slug)],
        ],
        province: [province, [Validators.required]],
      }),
      phoneNumber: [
        this.clinic.phoneNumber ? this.clinic.phoneNumber : '',
        [phoneNumberValidator(country?.slug as CountryCode), Validators.required],
      ],
      faxNumber: [
        this.clinic.faxNumber ? this.clinic.faxNumber : '',
        phoneNumberValidator(country?.slug as CountryCode),
      ],
      email: [this.clinic.email ? this.clinic.email : '', [Validators.email, Validators.required]],
      website: [this.clinic.website ? this.clinic.website : '', Validators.pattern(this.websiteUrlRegex)],
      clinicRooms: [this.clinic.clinicRooms],
      clinicTaxes: [this.clinic.clinicTaxes, Validators.required],
      googlePlaceId: [this.clinic.googlePlaceId],
      srFaxId: [this.clinic.srFaxId, [Validators.pattern('^[0-9]*$'), Validators.minLength(5)]],
      srFaxPassword: [this.clinic.srFaxPassword],
      hoursOfOperation: this.clinic.hoursOfOperation ? this.clinic.hoursOfOperation : defaultHoursOfOperation,
      timezone: [this.clinic.timezone],
      minimumDuration: [this.clinic.minimumDuration == null ? 5 : this.clinic.minimumDuration, Validators.required],
      loyaltyPointsToDollarsRate: [
        this.clinic.loyaltyPointsToDollarsRate == null ? null : this.clinic.loyaltyPointsToDollarsRate,
      ],
      staffBookingCancellationPrice: [
        this.clinic.staffBookingCancellationPrice == null ? null : this.clinic.staffBookingCancellationPrice,
      ],
      url: [this.clinic.url == null ? '' : this.clinic.url], //This can be null/undefined
      blobContainerName: [
        this.clinic?.blobContainerName == null ? '' : this.clinic.blobContainerName,
        Validators.required,
      ],
      sendPatientPortalEmail: this.clinic.sendPatientPortalEmail == null ? false : this.clinic.sendPatientPortalEmail,
      defaultPortalPhotoTagId: [this.clinic.defaultPortalPhotoTagId],
      twilioAccountSid: [this.clinic.twilioAccountSid],
      twilioAuthToken: [this.clinic.twilioAuthToken],
      twilioConversationServiceSid: [this.clinic.twilioConversationServiceSid],
      twilioFromNumber: [this.clinic.twilioFromNumber],
      twilioApiKey: [this.clinic.twilioApiKey],
      twilioApiSecret: [this.clinic.twilioApiSecret],
      paymentProcessorType: [this.clinic.paymentProcessorType],
    });

    this.onChangeCountry(country);

    this.clinicFormGroup
      .get('address.country')
      .valueChanges.subscribe((country: CountryData) => this.onChangeCountry(country));

    this.clinicFormGroup.get('phoneNumber').valueChanges.subscribe((mobileInput: string) => {
      const previousInput = this.phoneNumberFormatter.getChars();
      this.phoneNumberFormatter.reset();
      let formatted = this.phoneNumberFormatter.input(mobileInput);
      const newInput = this.phoneNumberFormatter.getChars();
      if (newInput === previousInput && formatted.length - mobileInput.length === 1) {
        formatted = formatted.slice(0, -1);
      }
      this.clinicFormGroup.get('phoneNumber').setValue(formatted, { emitEvent: false });
    });

    this.clinicFormGroup.get('faxNumber').valueChanges.subscribe((mobileInput: string) => {
      const previousInput = this.phoneNumberFormatter.getChars();
      this.phoneNumberFormatter.reset();
      let formatted = this.phoneNumberFormatter.input(mobileInput);
      const newInput = this.phoneNumberFormatter.getChars();
      if (newInput === previousInput && formatted.length - mobileInput.length === 1) {
        formatted = formatted.slice(0, -1);
      }
      this.clinicFormGroup.get('faxNumber').setValue(formatted, { emitEvent: false });
    });

    this.clinicFormGroup.get('address.postalCode').valueChanges.subscribe((postalCode: string) => {
      this.clinicFormGroup.get('address.postalCode').setValue(postalCode.toUpperCase(), { emitEvent: false });
    });

    this.clinicFormGroup.disable();
  }

  getExampleNumber(): string {
    const exampleNumber = getExampleNumber(this.clinicFormGroup.get('address.country').value?.slug, examples);
    return exampleNumber?.formatNational();
  }

  lookupPlaceId() {
    this.lookingUpPlaceId = true;
    let searchString =
      this.clinicFormGroup.controls['name'].value +
      ' ' +
      this.clinicFormGroup.controls['address'].get('city').value +
      ' ' +
      this.clinicFormGroup.controls['address'].get('province').value +
      ' ' +
      this.clinicFormGroup.controls['address'].get('country').value;

    this.reviewService.lookupGooglePlaceId(searchString).subscribe((possible_token) => {
      this.clinicFormGroup.controls['googlePlaceId'].setValue(possible_token);
      this.lookingUpPlaceId = false;
    });
  }

  compareClinicRoomObjects(o1: any, o2: any): boolean {
    return o1.room.roomName === o2.room.roomName && o1.room.roomId === o2.room.roomId;
  }

  compareClinicTaxObjects(o1: any, o2: any): boolean {
    return o1.tax.name === o2.tax.name && o1.tax.taxId === o2.tax.taxId;
  }

  capitalizeValue(formName: string) {
    this.formatterService.textCapitalize(this.clinicFormGroup.get(formName));
  }

  public openHoursOfOperationDialog(): void {
    const clinic = this.clinic;

    if (clinic) {
      if (!clinic.hoursOfOperation) {
        clinic.hoursOfOperation = defaultHoursOfOperation;
      }

      const dialogRef = this.dialog.open(HoursOfOperationDialogComponent, {
        width: '700px',
        height: '400px',
        data: {
          unitId: this.clinicIdParam,
          buzzHours: clinic.hoursOfOperation,
          hoursType: HoursOfOperationType.Clinic,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          clinic.hoursOfOperation = result;
          this.clinicFormGroup.controls['hoursOfOperation'].setValue(result);
          this.clinicFormGroup.controls['hoursOfOperation'].markAsDirty();
        }
      });
    }
  }

  formatDayHours(day: HoursOfOperationDay) {
    let res: string;
    if (day.closed) res = 'Closed';
    else if (typeof day.openTime == 'string' && typeof day.closeTime == 'string') {
      res = moment.utc(moment.duration(day.openTime).asMilliseconds()).format('h:mma');
      res += ' - ';
      res += moment.utc(moment.duration(day.closeTime).asMilliseconds()).format('h:mma');
    } else {
      res = moment.utc(day.openTime.asMilliseconds()).format('h:mma');
      res += ' - ';
      res += moment.utc(day.closeTime.asMilliseconds()).format('h:mma');
    }
    return res;
  }

  private getCountryDataByName(countryName: CountryName): CountryData {
    return this.countries.find((country) => country.name === countryName);
  }

  private getRegionDataByName(country: CountryData, regionName: RegionName): RegionData {
    const byFullName = country?.regions.find((region) => region.name === regionName);
    return byFullName ?? country?.regions.find((region) => region.slug === regionName);
  }
}
