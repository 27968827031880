import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ServiceTemplateType } from '@models/service/service-template-type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ServiceTemplateTypeService {
  constructor(private http: HttpClient) {}

  serviceTemplateTypes: ServiceTemplateType[];

  getAllServiceTemplateTypes(): Observable<ServiceTemplateType[]> {
    return this.http.get<ServiceTemplateType[]>(environment.baseUrl + 'api/ServiceTemplateType').pipe(
      map((serviceTemplateTypes: ServiceTemplateType[]) => {
        this.serviceTemplateTypes = serviceTemplateTypes;
        return serviceTemplateTypes;
      })
    );
  }
}
