import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { CreateNudgesComponent } from '@app/patients/patient-tabs/patient-nudges-tab/create-nudges/create-nudges.component';
import { PatientTypeheadComponent } from '@app/patients/patient-typehead/patient-typehead.component';
import { TempEvent } from '@models/appointments/temp-event';
import { PatientForm } from '@models/forms/patient-form';
import { ServiceForm } from '@models/forms/service-form';
import { VisitForms } from '@models/forms/visit-forms';
import { NudgeReferenceType } from '@models/nudges/reference-type';
import { Patient } from '@models/patient';
import { Appointment } from '@models/appointments/appointment';
import { PaymentStatus } from '@models/appointments/payment-status';
import { ServiceProvider } from '@models/service-provider';
import { Service } from '@models/service/service';
import { PreviousTreatment } from '@models/treatment-planning/previous-treatment';
import { Visit } from '@models/visit';
import { MasterOverlayService } from '@services/actionpanel.service';
import { AppointmentService } from '@services/appointments.service';
import { CurrentDataService } from '@services/currentData.service';
import { EventsService } from '@services/events.service';
import { PatientFormService } from '@services/patient-form.service';
import { PatientService } from '@services/patient.service';
import { ServicesService } from '@services/services.service';
import { UsersService } from '@services/users.service';
import { VisitService } from '@services/visit.service';
import * as moment from 'moment';
import 'moment-duration-format';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
@Component({
  selector: 'app-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.less'],
})
export class VisitsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('patientSearch') patientSearch: PatientTypeheadComponent;

  private _patient: Patient;
  get patient(): Patient {
    return this._patient;
  }
  set patient(patient: Patient) {
    if (!this.patient || patient?.patientId !== this._patient?.patientId) {
      this._patient = patient;
      this.onSetPatient();
    }
  }
  private _visit: Visit;
  get visit(): Visit {
    return this._visit;
  }
  set visit(newVisit: Visit) {
    this._visit = newVisit;
    this.onSetVisit();
  }

  get loading(): boolean {
    return this.visitService.visitPanelLoading;
  }
  set loading(state: boolean) {
    this.visitService.visitPanelLoading = state;
  }

  moment = moment;
  PaymentStatus = PaymentStatus;

  firstName: FormControl = new FormControl();
  lastName: FormControl = new FormControl();
  visitNotes: FormControl = new FormControl();
  selectedProduct: FormControl = new FormControl();
  allServicesControl: FormControl = new FormControl();

  changingPatient = false;
  addPatientVisible = false;
  addOrEdit = 'Add';
  editIsEnabled = false;
  visitNotesEditIsEnabled = false;
  selectedStaff: ServiceProvider;
  selectedTreatmentService: Service;
  previousAppointments: PreviousTreatment[] = [];
  futureAppointments: PreviousTreatment[] = [];
  visitServiceForms: ServiceForm[] = [];
  visitServiceFormsMap = new Map<Appointment, ServiceForm[]>();
  appointmentPatientForms: PatientForm[] = [];
  appointmentFormsMap = new Map<Appointment, PatientForm[]>();
  currentFormsSelectionModel: SelectionModel<number> = new SelectionModel<number>();

  private currentDate: Date;
  private clickEvent: TempEvent;
  private unsub: Subject<void> = new Subject<void>();

  constructor(
    public appointmentService: AppointmentService,
    private patientService: PatientService,
    private visitService: VisitService,
    private eventsService: EventsService,
    private userService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private masterOverlayService: MasterOverlayService,
    public servicesService: ServicesService,
    private eventService: EventsService,
    public currentDataService: CurrentDataService,
    private dialog: MatDialog,
    public authService: AuthService,
    private aLocation: Location,
    private patientFormService: PatientFormService
  ) {}

  ngOnInit() {
    this.eventsService.currentDate.pipe(takeUntil(this.unsub)).subscribe(async (date) => {
      this.currentDate = date;
      if (this.patient) {
        const existingVisit = await this.checkForExistingVisit();
        if (existingVisit) {
          this.visit = existingVisit;
        }
        this.handleReservationAfterVisitChange(this.visit);
      }
    });

    this.patientService.patientAdded$.pipe(takeUntil(this.unsub)).subscribe((patient) => {
      this.addPatientVisible = false;
      if (patient) {
        this.patientService.addPatient(patient).subscribe(() => {});
      }
    });

    this.eventsService.updateCreateVisitTimeProvider$.pipe(takeUntil(this.unsub)).subscribe(async (update) => {
      if (update) {
        const clickedDate = moment(update.start);
        const isSameDate = clickedDate.isSame(moment(this.currentDate), 'day');
        const tempEvent = this.eventService.getTempEvent();
        this.selectedStaff = update.provider;

        if (!isSameDate) {
          this.currentDate = clickedDate.toDate();
          if (this.patient) {
            const existingVisit = await this.checkForExistingVisit();
            if (existingVisit) {
              this.visit = existingVisit;
            }
            this.handleReservationAfterVisitChange(this.visit);
          }
        }

        if (tempEvent.isSelection && this.visit) {
          if (this.visitService.reservation) {
            this.appointmentService.deleteAppointmentReservation().then(() => {
              this.appointmentService.addAppointmentReservation(this.visit, this.selectedStaff);
            });
          } else {
            this.appointmentService.addAppointmentReservation(this.visit, this.selectedStaff);
          }
        }
      }
    });

    if (this.eventService.getTempEvent()) {
      this.clickEvent = this.eventService.getTempEvent();
    }

    if (this.clickEvent.provider) {
      this.selectedStaff = this.clickEvent.provider;
    }

    this.route.params.pipe(takeUntil(this.unsub)).subscribe(async (params) => {
      const visitId = params['id'];
      const patientId = params['patientId'];

      if (patientId !== '_') {
        await this.getPatientById(+patientId);
      }

      if (visitId !== '_') {
        this.getVisitById(+visitId);
      }
    });

    this.appointmentService.apptsAdded$.pipe(takeUntil(this.unsub)).subscribe((appt) => {
      this.updatePatientAppointments();
    });

    window.onbeforeunload = () => this.ngOnDestroy();
  }

  ngAfterViewInit() {
    if (this.patientSearch) {
      this.patientSearch.setInitialFocus();
    }
  }

  typeaheadOnChange($event) {
    this.patient = $event.data as Patient;
  }

  onChangePatient() {
    this.changingPatient = !this.changingPatient;
    if (this.patientSearch) {
      this.patientSearch.setInitialFocus();
    }
  }

  private async onSetPatient() {
    this.updatePatientAppointments();
    const existingVisit = await this.checkForExistingVisit();
    if (existingVisit) {
      this.visit = existingVisit;
    }
    this.changingPatient = false;
    this.handleReservationAfterVisitChange(this.visit);
  }

  private onSetVisit() {
    this.visitService.lastVisit = this.visit;
    if (this.visit) {
      if (this.visit.visitId) {
        this.aLocation.go(location.pathname.replace('/_/', '/' + this.visit.visitId + '/'));
      }
      if (this.visit.visitNotes) {
        this.visitNotes.setValue(this.visit.visitNotes);
      }
      if (this.visit.patientId && this.visit.patientId !== this.patient?.patientId) {
        this.getPatientById(this.visit.patientId);
      }
      this.refreshCurrentFormsSelectionModel();
    }
  }

  private getPatientById(patientId: number): Promise<Patient> {
    return this.patientService
      .getPatientById(patientId)
      .pipe(tap((patient) => (this.patient = patient)))
      .toPromise();
  }

  private getVisitById(visitId: number): Promise<Visit> {
    return this.visitService
      .getVisitById(visitId)
      .pipe(tap((result) => (this.visit = result)))
      .toPromise();
  }

  private async checkForExistingVisit(): Promise<Visit> {
    const visitIdString = this.patient.patientId.toString() + this.currentDate.toDateString();
    let existingVisit = await this.visitService.getVisitByEvent(visitIdString).toPromise();
    existingVisit = existingVisit?.cancelled ? null : existingVisit;

    if (this.visit && this.changingPatient) {
      const prevAppointments = [...this.visit.appointments];
      if (existingVisit && existingVisit.visitId === this.visit.visitId) {
        return;
      }
      if (existingVisit && existingVisit.visitId !== this.visit.visitId) {
        this.reassignPatientAppointments(this.visit.visitId, existingVisit, prevAppointments);
        return existingVisit;
      }
      if (!existingVisit) {
        const newVisit = await this.createNewVisit();
        this.reassignPatientAppointments(this.visit.visitId, newVisit, prevAppointments);
        return newVisit;
      }
    } else {
      if (existingVisit) {
        return existingVisit;
      } else {
        return await this.createNewVisit();
      }
    }
  }

  private createNewVisit(): Promise<Visit> {
    const visitIdString = this.patient.patientId.toString() + this.currentDate.toDateString();
    let newVisit: Visit = this.visitService.initEmptyVisit(
      this.patient.patientId,
      visitIdString,
      [],
      this.currentDate,
      this.userService.loggedInUser.firstName + ' ' + this.userService.loggedInUser.lastName
    );
    return this.visitService.addVisit(newVisit).toPromise();
  }

  private updatePatientAppointments() {
    this.getPreviousTreatmentAppointments();
    this.getFutureTreatmentAppointments();
  }

  private refreshCurrentFormsSelectionModel() {
    this.visitService.getVisitFormsByVisitId(this.visit.visitId).subscribe((visitForms: VisitForms) => {
      this.visitServiceFormsMap = new Map<Appointment, ServiceForm[]>();
      this.appointmentFormsMap = new Map<Appointment, PatientForm[]>();
      this.visitServiceForms = visitForms.serviceForms;
      this.appointmentPatientForms = visitForms.appointmentForms;

      this.visit.appointments.forEach((va) => {
        if (va.service.serviceTemplate.serviceForms) {
          this.visitServiceFormsMap.set(va, va.service.serviceTemplate.serviceForms);
        }
        if (va.appointmentForms) {
          this.appointmentFormsMap.set(va, va.appointmentForms);
        }
      });

      if (this.appointmentPatientForms.length > 0) {
        this.currentFormsSelectionModel = new SelectionModel<number>(
          true, // <- multi-select
          this.appointmentPatientForms.map((pf: PatientForm) => pf.clinicFormId) || [], // <- Initial selections
          true // <- emit an event on selection change
        );
      } else {
        this.currentFormsSelectionModel = new SelectionModel<number>();
      }
    });
  }

  onChangeCurrentFormsSelectionModel(event: MatCheckboxChange, serviceForm: ServiceForm) {
    if (event.checked) {
      // Adding an Appointment Form
      // Find a VisitAppointment that has the Service/ServiceTemplate with this ServiceForm
      // clinicFormId,
      let patientForm = PatientForm.fromForm(this.patient.patientId, serviceForm.clinicForm);
      let appointment: Appointment = this.getAppointmentByServiceForm(serviceForm);
      patientForm.appointmentId = appointment.appointmentId;
      this.patientFormService.addPatientForm(patientForm).subscribe(() => {});
    } else {
      // Find Appointment Patient Form
      let appointmentPatientForm = this.appointmentPatientForms.filter(
        (apf) => apf.clinicFormId == serviceForm.clinicFormId
      )[0];
      // Remove it
      this.patientFormService.deletePatientForm(appointmentPatientForm.id).subscribe(() => {});
    }
  }

  private getAppointmentByServiceForm(serviceForm: ServiceForm) {
    for (let [appointment, serviceForms] of this.visitServiceFormsMap.entries()) {
      if (serviceForms.filter((s) => s.clinicServiceTemplateId === serviceForm.clinicServiceTemplateId))
        return appointment;
    }
  }

  isFormSigned(serviceForm: ServiceForm) {
    // Find Appointment Patient Form
    let appointmentPatientForm = this.appointmentPatientForms.filter(
      (apf) => apf.clinicFormId == serviceForm.clinicFormId
    )[0];
    if (appointmentPatientForm) {
      return appointmentPatientForm.isSigned;
    } else {
      return false;
    }
  }

  private updateVisit() {
    if (this.visit) {
      this.loading = true;
      this.visitService.getVisitById(this.visit.visitId).subscribe((visit) => {
        this.visit = visit;
        this.visit.patientId = this.patient.patientId;
        this.visit.visitNotes = this.visitNotes.value;
        this.visitService.updateVisit(this.visit).subscribe(
          (v) => {
            this.eventService.appointmentAdded.next();
            this.visit.appointments.forEach((a) => {
              const appointmentStartTime = moment(a.date).startOf('day').add(moment.duration(a.startTime)).toDate();
              const appointmentCalculatedDuration =
                moment.duration(a.endTime).asMinutes() - moment.duration(a.startTime).asMinutes();
              const dt = this.appointmentService.getStartEndTime(appointmentStartTime, appointmentCalculatedDuration);
              a.patientId = this.patient.patientId;
              a.startTime = dt.startTime;
              a.endTime = dt.endTime;
              this.appointmentService.updateAppointment(a).subscribe(() => {});
            });

            this.loading = false;
          },
          (err) => {
            this.loading = false;
          }
        );
        this.changingPatient = false;
      });
    }
  }

  async handleUpdatedPatient(patientId: number) {
    await this.getPatientById(patientId);
    this.updateVisit();
    this.addPatientVisible = false;
  }

  cancelPatientUpdate() {
    this.patientService.editedPatient = null;
    this.patientService.reservationPatient = null;
    this.patientService.patientHasBeenAdded(null);
    this.masterOverlayService.masterOverlayState(false);
  }

  public getTitle(title) {
    const splitTitle = title.split(' ');
    let serviceFullName = '';
    for (let i = 1; i < splitTitle.length; i++) {
      serviceFullName += splitTitle[i];
      if (i < splitTitle.length - 1) {
        serviceFullName += ' ';
      }
    }
    if (serviceFullName === '') {
      serviceFullName = title;
    }
    return serviceFullName;
  }

  onNewPatientHandler() {
    this.addPatientVisible = true;
    this.patientService.editedPatient = null;
    this.patientService.reservationPatient = null;
    this.addOrEdit = 'Add';
  }

  onEditPatientHandler() {
    this.addPatientVisible = true;
    this.patientService.editedPatient = this.patient;
    this.patientService.reservationPatient = this.patient;
    this.addOrEdit = 'Update';
    this.masterOverlayService.masterOverlayState(true);
  }

  onSelectPlannedTreatment(event$: Service) {
    if (event$) {
      this.selectedTreatmentService = event$;
    }
  }

  private reassignPatientAppointments(prevVisitId: number, visit: Visit, appointments: Appointment[]) {
    const visitAppointments = [...visit.appointments, ...appointments];
    this.updateVisitAppointmentPatient(visitAppointments);
    this.visit.appointments = visitAppointments;
    this.visitService.updateVisitPatient(prevVisitId, visit.visitId, this.patient.patientId).subscribe(() => {
      this.loading = false;
      this.changingPatient = false;
      this.appointmentService.apptsSelected.clear();
      this.router.navigate([
        '/schedule',
        { outlets: { 'action-panel': ['visit-details', this.visit.visitId, this.patient.patientId] } },
      ]);
      this.appointmentService.onAllApptsUpdated();
    });
  }

  private updateVisitAppointmentPatient(prevPatientAppointments) {
    prevPatientAppointments.forEach((a) => {
      a.patientId = this.patient.patientId;
      a.visitId = this.visit.visitId;
      a.title = this.patient.firstName + ' ' + a.service.serviceName;
    });
  }

  handleReservationAfterVisitChange(visit: Visit) {
    if (!this.visitService.reservation && !this.eventService.movingAppointment) {
      this.appointmentService.addAppointmentReservation(visit, this.selectedStaff);
    } else if (this.visitService.reservation) {
      this.appointmentService
        .deleteAppointmentReservation()
        .then(() => this.appointmentService.addAppointmentReservation(visit, this.selectedStaff));
    }
  }

  saveVisitNotes(newNotes: string) {
    this.loading = true;
    this.visit.visitNotes = newNotes;
    this.visitService
      .updateVisit(this.visit)
      .pipe(take(1))
      .subscribe(() => {
        this.loading = false;
      });
  }

  private getPreviousTreatmentAppointments() {
    if (!this.patient) return;
    this.appointmentService
      .getTreatmentAppointmentsInDateRange(
        this.patient.patientId,
        undefined,
        undefined,
        moment(new Date(new Date().toDateString())).subtract(1, 'day').toDate(),
        3,
        false
      )
      .pipe(takeUntil(this.unsub))
      .subscribe((appointments: PreviousTreatment[]) => {
        this.previousAppointments = appointments.filter((appt) => {
          return appt.paymentStatus == PaymentStatus.Paid && appt.isLocked;
        });
      });
  }

  private getFutureTreatmentAppointments() {
    if (!this.patient) return;
    this.appointmentService
      .getTreatmentAppointmentsInDateRange(
        this.patient.patientId,
        undefined,
        moment(new Date(new Date().toDateString())).add(1, 'day').toDate(),
        undefined,
        3,
        false
      )
      .pipe(takeUntil(this.unsub))
      .subscribe((appointments: PreviousTreatment[] = []) => {
        this.futureAppointments = appointments.reverse();
      });
  }

  openNudgeModal() {
    const dialogRef = this.dialog.open(CreateNudgesComponent, {
      panelClass: 'custom-dialog-container',
      width: '550px',
      data: {
        patientId: this.patient.patientId,
        referenceType: NudgeReferenceType.Service,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  closePanel() {
    this.visitService.closePanel();
  }

  ngOnDestroy() {
    this.appointmentService.deleteAppointmentReservation();
    this.patientService.reservationPatient = null;
    this.unsub.next();
    this.unsub.complete();
  }
}
