<h1 mat-dialog-title>Confirmation</h1>
<div *ngIf="errors?.length > 0" class="errors-wrapper mb-4">
  <ng-container *ngFor="let error of errors">
    <app-error *ngFor="let fieldError of error.fieldErrors" [message]="fieldError"></app-error>
  </ng-container>
</div>
<div mat-dialog-content>
  <p>{{ data.title }}</p>
  <mat-form-field class="panel-flex-row-item">
    <mat-select
      placeholder="Reason for Cancellation"
      [(ngModel)]="data.selectedCancelReason"
      name="cancellationReasons"
    >
      <mat-option *ngFor="let reason of cancelReasons" [value]="reason">
        {{ reason }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div *ngIf="data.selectedCancelReason === 'Custom Reason'">
    <mat-form-field class="edit-panel-flex-row">
      <textarea
        #customCancelReasonEntry
        placeholder="Reason for cancelling appointment?"
        [(ngModel)]="data.customCancelReason"
        matInput
        >{{ data.customCancelReason }}</textarea
      >
    </mat-form-field>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
  <div *ngIf="chargeableAppointment.isChargeable" class="mt-2 grey-three-background p-4">
    <div class="flex-shrink-0">
      Cancellation fee: <strong>{{ chargeableAppointment.chargeableAmount | currency }}</strong> can be charged on:
    </div>
    <div class="row py-1">
      <div class="col-2">
        <i class="fa-3x" [class]="PaymentHelper.getCreditCardIcon(chargeableAppointment.cardBrand)"></i>
      </div>
      <div class="col-10">
        <div class="row">
          <div class="ml-3">
            <strong>{{ chargeableAppointment.cardMaskedDigits }}</strong>
          </div>
        </div>
        <div class="row">
          <div class="ml-3">
            expiring on <strong>{{ chargeableAppointment.cardExpiry }}</strong>
          </div>
        </div>
      </div>
    </div>
    <span class="flex-shrink-0 pr-2">Charge credit card?</span>
    <mat-radio-group
      class="example-radio-group mr-2"
      [(ngModel)]="data.chargeCancelAppointment"
      aria-label="Select an option"
    >
      <mat-radio-button class="example-radio-button charge-appointment-radio my-1 pr-2" [value]="true"
        >Yes</mat-radio-button
      >
      <mat-radio-button class="example-radio-button charge-appointment-radio my-1 pr-2" [value]="false"
        >No</mat-radio-button
      >
    </mat-radio-group>
    <div *ngIf="!data?.chargeCancelAppointment">
      <mat-form-field class="edit-panel-flex-row">
        <textarea
          placeholder="Reason for not charging the credit card?"
          #creditCardCancelReasonEntry
          [(ngModel)]="data.reasonCancellationNotCharged"
          matInput
          required
          >{{ data.reasonCancellationNotCharged }}</textarea
        >
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions class="pt-4">
    <button
      [disabled]="
        errors.length > 0 ||
        data.selectedCancelReason === '' ||
        (!data.chargeCancelAppointment && data.reasonCancellationNotCharged === '')
      "
      [mat-dialog-close]="{ event: 'confirm', data: data }"
      cdkFocusInitial
      class="btn em-btn em-btn-green mr-2"
    >
      Confirm
    </button>
    <button class="btn em-btn em-btn-green" [mat-dialog-close]="{ event: 'cancel' }">Cancel</button>
  </div>
</div>
