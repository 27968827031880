import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';

@Component({
  selector: 'app-gift-cards-overview',
  templateUrl: './gift-cards-overview.component.html',
  styleUrls: ['./gift-cards-overview.component.less']
})
export class GiftCardsOverviewComponent implements OnInit, OnDestroy {

  unsub: Subject<void> = new Subject<void>();
  loading = false;

  gridView: GridDataResult;
  gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  
  constructor() { }

  ngOnInit(): void {
    this.gridView = {
      data: [],
      total: 0
    };
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

}
