import { IChargeableAsset } from '@interfaces/IChargeableAsset';
import { ChargeableAsset } from './chargeable-asset';
import { Service } from '../service/service';

export class ServiceChargeableAsset extends ChargeableAsset {
  service: Service;

  constructor(service: Service) {
    super();
    this.service = service;
  }

  public getChargeableAsset(): IChargeableAsset {
      return this.service;
  }
}
