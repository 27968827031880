import { ImageService } from '@services/image.service';

import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  AfterContentInit,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import * as moment from 'moment';
import { Subject, Subscription, of, throwError } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';

import { PhotoEventType } from '@models/photo/photo-event-type';
import { PhotoSeriesService } from '@services/photo-series.service';
import { PhotoConsentType } from '@models/photo/photo-consent-type';
import { SeriesType } from '@models/photo/series-type';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { Patient } from '@models/patient';
import { PhotoEditingService } from '@services/photo-editing.service';
import { PatientService } from '@services/patient.service';
import { ConfirmUnsavedChangesDialogComponent } from '@app/management/dialogs/confirm-unsaved-changes/confirm-unsaved-changes.component';
import { isNullOrUndefined } from '@app/shared/helpers';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class PhotoGalleryComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {
  unsub: Subject<void> = new Subject<void>();
  patient: Patient;
  photoEditingSubscription: Subscription;
  editingPhoto: boolean;
  routedPhotoId: number;
  patientPhotos: any[];
  selectedPhoto: PhotoMetaData;
  previousPhotos: any[];
  photoUpdate: boolean;
  photosSeriesSet = new Set();
  selectedSeriesPhotos = {};
  _needsReload: boolean;
  newItemAdded: boolean;
  SeriesType = SeriesType;
  photoConsentType = PhotoConsentType;
  @Input() showStockImages = false; //in prep for using this in non-consultation gallery as i expect we might
  @Input() patientId: number;

  _seriesMode: boolean = false;
  @Input() set seriesMode(seriesMode: boolean) {
    this.selectedSeriesPhotos = {};
    this.photosSeriesSet = new Set();
    this._seriesMode = seriesMode;
  }
  get seriesMode() {
    return this._seriesMode;
  }
  @Input() seriesType: SeriesType;
  @Input() isNewPhotoSeries: boolean;
  @Input() isNewItemAdded: boolean;
  @Input() photoSeriesList: PhotoMetaData[];
  @Input() photos: PhotoMetaData[];
  @Input()
  set needsReload(needsReload) {
    this._needsReload = needsReload;
  }
  get needsReload() {
    return this._needsReload;
  }

  constructor(
    private photoEditingService: PhotoEditingService,
    private patientService: PatientService,
    private confirmDialog: MatDialog,
    private route: ActivatedRoute,
    private photoSeriesService: PhotoSeriesService,
    private imageService: ImageService
  ) {
    this.patient = this.patientService.patientPanelPatient;
    this.editingPhoto = false;
    this.patientPhotos = [];
  }

  ngOnInit() {
    // get patient detail if not available
    if (this.patientId) {
      this.getPatientProfile(this.patientId);
    }

    this.route.params.subscribe((params) => {
      if (!isNaN(+params.photoId)) {
        this.routedPhotoId = +params.photoId;
      }
    });

    this.imageService.metadataUpdated$.pipe(takeUntil(this.unsub)).subscribe((image) => {
      let indexToUpdate = this.photos.findIndex((p) => p.id === image.id && !p.isSeries);
      if (indexToUpdate > -1) this.photos[indexToUpdate] = image;
      this.selectedPhoto = image;
      this.transformPhotoData();
    });

    this.photoEditingSubscription = this.photoEditingService
      .getPhotoSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((photoEvent) => {
        if (photoEvent.event === PhotoEventType.EditPhoto) {
          this.editingPhoto = true;
        } else if (photoEvent.event === PhotoEventType.CancelEditPhoto) {
          this.editingPhoto = false;
          this.photoUpdate = true;
        } else if (photoEvent.event === PhotoEventType.LoadPhoto) {
          this.selectedPhoto = photoEvent.photo;
        }
      });

    this.loadPatientPhotos(this.photos);
  }

  getPatientProfile(patientId) {
    if (patientId > 0) {
      this.patientService
        .getPatientById(patientId)
        .pipe(takeUntil(this.unsub))
        .subscribe((patient) => {
          this.patient = patient;
          this.photos = this.patient.photos;
          this.transformPhotoData();
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['photos'] && !isNullOrUndefined(changes['photos'].currentValue)) {
      this.photos = [...changes['photos'].currentValue];
      this.patientPhotos = [];
      this.transformPhotoData();
    }

    if (this.patientId) {
      this.getPatientProfile(this.patientId);
    }

    //this.loadPatientPhotos();
  }

  ngAfterContentInit() {
    if (this.patientPhotos.length) {
      this.updateSelectedPhoto();
    }

    if (this.routedPhotoId) {
      const selectedPhoto = this.photos.find((photo) => photo.id === this.routedPhotoId);
      this.updateSelectedPhoto(selectedPhoto);
    }
  }

  private loadPatientPhotos(photos?: PhotoMetaData[]) {
    if (photos && photos.length) {
      this.transformPhotoData();
    }

    if (this.isNewItemAdded) {
      this.selectedPhoto = this.photos[this.photos.length - 1];
      this.photoEditingService.loadPhoto(this.selectedPhoto);
      return;
    }

    if (this.selectedPhoto && this.selectedPhoto.isSeries && this.editingPhoto) {
      this.selectedSeriesPhotos = {};
      this.photosSeriesSet.clear();
      if (this.photoSeriesList && this.photoSeriesList.length) {
        this.photos.map((photo) => {
          this.photoSeriesList.map((selectedPhoto) => {
            if (photo.id === selectedPhoto.id) {
              this.photosSeriesSet.add(photo);
              this.selectedSeriesPhotos[photo.id] = true;
            }
          });
        });
      }

      return;
    }

    if (this.seriesMode && this.isNewPhotoSeries) {
      this.selectedSeriesPhotos = {};

      if (!this.photoSeriesList.length) {
        this.photosSeriesSet.clear();
      } else {
        this.photos.map((photo) => {
          this.photoSeriesList.map((selectedPhoto) => {
            if (photo.id === selectedPhoto.id) {
              this.selectedSeriesPhotos[photo.id] = true;
            }
          });
        });
        return;
      }

      const photo = new PhotoMetaData({
        isOriginal: true,
        uploadDate: moment(),
        modifiedDate: moment(),
        dateTaken: moment(),
        notes: '',
        photoConsentTypeId: PhotoConsentType.Internal,
        seriesType: this.seriesType,
        isSeries: true,
      });

      this.selectedPhoto = photo;
      this.photoEditingService.loadPhoto(photo);

      return;
    }

    if (this.patientPhotos.length) {
      this.updateSelectedPhoto();
    } else {
      this.photoEditingService.clearSelectedPhoto();
    }
  }

  private transformPhotoData() {
    const groups = this.photos.reduce((groups, photo) => {
      const date = moment(photo.dateTaken).format('MMMM DD, YYYY');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(photo);
      return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((date) => {
      const sortedGroups = groups[date].sort((a: PhotoMetaData, b: PhotoMetaData) => {
        return moment(b.dateTaken).diff(moment(a.dateTaken));
      });

      return {
        date,
        photos: sortedGroups,
      };
    });
    // Sort the Groups of Dates
    groupArrays.sort((a: any, b: any) => {
      return moment(b.photos[0].dateTaken).diff(moment(a.photos[0].dateTaken));
    });
    this.patientPhotos = groupArrays;
  }

  private updateSelectedPhoto(item?: PhotoMetaData) {
    if (item) {
      this.photoEditingService.loadPhoto(item);
      this.selectedPhoto = item;
      this.previousPhotos = this.photos.slice();
    } else {
      if (
        this.selectedPhoto &&
        this.selectedPhoto.id &&
        this.photos.findIndex((i) => i.id === this.selectedPhoto.id && i.isSeries === this.selectedPhoto.isSeries) ===
          -1
      ) {
        const previtemIndex = this.previousPhotos.findIndex(
          (i) => i.id === this.selectedPhoto.id && i.isSeries === this.selectedPhoto.isSeries
        );
        if (previtemIndex && previtemIndex <= this.photos.length) {
          this.photoEditingService.loadPhoto(this.photos[previtemIndex - 1]);
          this.selectedPhoto = this.photos[previtemIndex - 1];
        } else {
          this.photoEditingService.loadPhoto(this.patientPhotos[0].photos[0]);
          this.selectedPhoto = this.patientPhotos[0].photos[0];
        }
        this.previousPhotos = this.photos.slice();
      } else {
        if (this.selectedPhoto && this.photoUpdate) {
          this.selectedPhoto = this.photos.find(
            (i) => i.id === this.selectedPhoto.id && i.isSeries === this.selectedPhoto.isSeries
          );
          this.photoEditingService.loadPhoto(this.selectedPhoto);
          this.previousPhotos = this.photos.slice();
          this.photoUpdate = false;
        } else {
          this.photoEditingService.loadPhoto(this.patientPhotos[0].photos[0]);
          this.selectedPhoto = this.patientPhotos[0].photos[0];
          this.previousPhotos = this.photos.slice();
        }
      }
    }
  }

  public imageClicked(item: PhotoMetaData) {
    if (!this.editingPhoto) {
      this.selectedPhoto = Object.assign({}, item);
      this.updateSelectedPhoto(item);
    } else {
      this.showUnsavedChangesModal(item);
    }
  }

  private showUnsavedChangesModal(item: PhotoMetaData) {
    const dialogRef = this.confirmDialog.open(ConfirmUnsavedChangesDialogComponent, {
      width: '250px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') {
          this.editingPhoto = false;
          this.photoEditingService.cancelEdit();
          this.selectedPhoto = item;
          this.photoEditingService.loadPhoto(item);
        }
      });
  }

  public imageSeriesClicked(item: PhotoMetaData) {
    if (this.photosSeriesSet.has(item)) {
      this.photosSeriesSet.delete(item);
      this.selectedSeriesPhotos[item.id] = false;
    } else {
      if (this.seriesType === SeriesType.BeforeAfter && this.photosSeriesSet.size < 2) {
        this.selectedSeriesPhotos[item.id] = true;
        this.photosSeriesSet.add(item);
      }
      if (this.seriesType === SeriesType.Multi) {
        this.selectedSeriesPhotos[item.id] = true;
        this.photosSeriesSet.add(item);
      }
    }

    this.photoSeriesService.addPhotoToSeries(Array.from(this.photosSeriesSet));
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
