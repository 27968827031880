import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';


@Component({
  selector: 'app-balloons',
  templateUrl: './balloons.component.html',
  styleUrls: ['./balloons.component.less']
})
export class BalloonsComponent {
  @Input('showBalloons') showBalloons: boolean = false;
  @Input('customHeight') customHeight: string = null;
  randomBalloons = [];
  constructor(private el: ElementRef) {
    this.randomBalloons = Array(100).fill(0).map(a => this.getRandomStyles());
  } 
  private random(num) {
    return Math.floor(Math.random() * num)
  }

  private getRandomStyles() {
    var r = this.random(255);
    var g = this.random(255);
    var b = this.random(255);
    var mt = this.random(200);
    var ml = this.random(50);
    var dur = this.random(5) + 9;
    return `
    background-color: rgba(${r},${g},${b},0.7);
    color: rgba(${r},${g},${b},0.7); 
    box-shadow: inset -7px -3px 10px rgba(${r - 10},${g - 10},${b - 10},0.7);
    margin: ${mt}px 0 0 ${ml}px;
    animation: float ${dur}s ease-in infinite
    `
  }
}
