<ngx-loading [show]="loading"></ngx-loading>
<div class="services">
  <div class="d-flex align-items-center justify-content-between teal-background white-font header">
    <span class="mr-auto">SERVICES</span>
  </div>
  <kendo-grid #grid="kendoGrid" [data]="gridView" (edit)="editHandler($event)" class="half-view">
    <!--Available for online booking-->
    <kendo-grid-column field="available" title="Available" [width]="100">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div style="text-align: center; width: 100%">
          <label class="switch">
            <input
              type="checkbox"
              name="available"
              aria-label="Available for online booking"
              id="available"
              [checked]="dataItem.allowOnlineBooking"
              (change)="updateServiceAvailable(dataItem, $event)"
            />
            <span [ngStyle]="{ 'pointer-events': 'none' }" class="slider round"></span>
          </label>
        </div>
      </ng-template>
    </kendo-grid-column>
    <!--Name of service-->
    <kendo-grid-column field="name" title="Name">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.serviceName }} </ng-template>
    </kendo-grid-column>
    <!--Online name of service-->
    <kendo-grid-column field="onlineName" title="Online Name">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.onlineServiceName }} </ng-template>
    </kendo-grid-column>
    <!--Prerequisites of service-->
    <kendo-grid-column field="prerequisite" title="Prerequisite">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.prerequisiteNames }} </ng-template>
    </kendo-grid-column>
    <!--Appointment duration of service-->
    <kendo-grid-column field="onlineduration" title="Online Duration" [width]="130">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.onlineBookingDurationMinutes }} </ng-template>
    </kendo-grid-column>
    <!--Online price of service-->
    <kendo-grid-column field="onlineprice" title="Online Price" [width]="115">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.onlineBookingPrice | currency }} </ng-template>
    </kendo-grid-column>
    <!--Actions-->
    <kendo-grid-command-column title="Actions" [width]="100">
      <ng-template kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridEditCommand class="btn kendo-btn"><i class="fas fa-edit"></i></button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
