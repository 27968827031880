import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { FormType } from '@models/forms/form-type';

@Injectable({
  providedIn: 'root',
})
export class FormTypeService {
  constructor(private http: HttpClient) {}

  getFormTypes() {
    return this.http.get<FormType[]>(environment.baseUrl + 'api/FormType');
  }
}
