import { Component, OnInit, OnDestroy } from '@angular/core';
import { Clinic } from '@models/clinic';
import { ClinicsService } from '@services/clinics.service';
import { UsersService } from '@services/users.service';
import { User } from '@models/user';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-messaging-sidebar-header',
  templateUrl: './messaging-sidebar-header.component.html',
  styleUrls: ['./messaging-sidebar-header.component.less']
})
export class MessagingSidebarHeaderComponent implements OnInit, OnDestroy {
  private unsub: Subject<any> = new Subject<any>();
  clinic: Clinic;
  currentUser: User;

  constructor(private clinicsService: ClinicsService, private usersService: UsersService) {
  }

  ngOnInit() {
    this.clinicsService.getClinics()
    .pipe(takeUntil(this.unsub))
    .subscribe((clinics: Clinic[]) => {
      // We don't support a multi-clinic model at this time so just assign the current clinic as the first clinic
      this.clinic = clinics[0];
    });

    this.currentUser = this.usersService.loggedInUser;

    this.usersService.loggedInUserUpdated$
    .pipe(takeUntil(this.unsub))
    .subscribe((user: User) => {
      this.currentUser = user;
    });
  }
  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

}
