<ngx-loading [show]="loading"></ngx-loading>
<div class="mt-2">
  <mat-label *ngIf="squareCards?.length > 0" class="label-header teal-font" [class.isDisabled]="isDisabled"
    >Please select a Credit Card on file:</mat-label
  >
</div>
<div class="radio-container mb-1">
  <mat-radio-group
    #selectCardGroup
    aria-labelledby="select-time-radio-group-label"
    class="select-time-radio-group"
    [(ngModel)]="selectedSquareCard"
    (change)="onCardSelected($event)"
  >
    <ng-container *ngFor="let cof of squareCards?.slice(0, showAllCards ? 999 : 6)">
      <mat-radio-button
        class="select-card-radio-button card mb-4 p-2 mr-2 grey-four-background"
        #radioBtn
        [disabled]="compareExpiryToMinimum(cof)"
        [value]="cof"
      >
        <div class="d-flex flex-column">
          <app-square-card-on-file [card]="cof"></app-square-card-on-file>
          <span *ngIf="compareExpiryToMinimum(cof)" class="card-error">Card expires before appointment</span>
        </div>
      </mat-radio-button>
    </ng-container>
  </mat-radio-group>
</div>
<div class="justify-content-center" [ngClass]="{ 'd-flex': !showRequestCreditCardButton }">
  <ng-container *ngIf="!showRequestCreditCardButton">
    <div class="request-credit-card float-left mt-2 mr-3">
      <mat-slide-toggle
        title="Request credit card"
        [checked]="requestCreditCard"
        (change)="onRequestCreditCardChangedEvent($event)"
        class="align-middle"
      >
      </mat-slide-toggle>
      <label class="ml-1 mb-0 teal-font text-truncate d-inline font-weight-bold">Request new card from patient</label>
    </div>
  </ng-container>
  <button
    class="add-credit-card btn em-btn em-btn-green mb-1"
    [disabled]="isDisabled"
    (click)="openAddNewCardModal()"
    [ngClass]="{ 'flex-column': !showRequestCreditCardButton, 'w-100': showRequestCreditCardButton }"
  >
    Add New Credit Card
  </button>
  <ng-container *ngIf="showRequestCreditCardButton">
    <button
      class="request-credit-card btn em-btn em-btn-green mb-1 w-100"
      [disabled]="isDisabled"
      (click)="openRequestNewCardModal()"
    >
      Request Credit Card
    </button>
  </ng-container>
</div>
