<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>
<div class="container-fluid d-flex flex-column h-100">
  <div class="row flex-shrink-0">
    <div class="col d-flex align-items-center teal-font bigger" *ngIf="clinic">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="clinic.allowOnlineBooking" (change)="updateOnlineBooking()" />
        <span class="slider round"></span>
      </label>
      <span class="pl-2">Allow online booking</span>
    </div>
  </div>
  <div class="row flex-grow-1">
    <div class="col-xl-4 my-1">
      <app-org-online-general-settings></app-org-online-general-settings>
    </div>
    <div class="col-xl-8 my-1">
      <app-org-online-providers-settings
        (disableGridEvent)="changeDisableGrid($event)"
      ></app-org-online-providers-settings>
    </div>
  </div>
  <div class="row flex-grow-1 minh-0">
    <div class="col-xl-4 my-1">
      <app-org-online-categories-settings
        (disableGridEvent)="changeDisableGrid($event)"
      ></app-org-online-categories-settings>
    </div>
    <div class="col-xl-8 my-1">
      <app-org-online-service-settings (disableGridEvent)="changeDisableGrid($event)"></app-org-online-service-settings>
    </div>
  </div>
</div>

<app-actionpanel></app-actionpanel>
