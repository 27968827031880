import { Injectable } from '@angular/core';
import { ObservationListItem } from '@models/observation/observation';
import { ObservationListItemsService } from './observation-list-items.service';
import { AuthService } from '@app/auth/auth.service';
import { SignalrBase } from './signalr-base';
import { SignalrHttpClientService } from './signalr-http-client.service';
import { Policies } from '@app/auth/auth-policies';

@Injectable({
  providedIn: 'root',
})
export class ObservationListsSignalrService extends SignalrBase<any> {
  constructor(
    private obrListItemsService: ObservationListItemsService,
    authService: AuthService,
    private signalrHttpClientService: SignalrHttpClientService
  ) {
    super(
      'ObservationLists',
      Policies.appointments,
      authService,
      signalrHttpClientService
    );
    this.registerServerEvents();
  }

  private registerServerEvents(): void {
    super.registerServerEvent('UpdateObservationList', (data: ObservationListItem) => {
      this.checkUpdateObservationListTriggers(data);
    });

    super.registerServerEvent('AddObservationList', (data: ObservationListItem) => {
      this.checkAddObservationListTriggers(data);
    });

    super.registerServerEvent('DeleteObservationList', (data: ObservationListItem) => {
      this.checkDeleteObservationListTriggers(data);
    });
  }

  private checkUpdateObservationListTriggers(data: ObservationListItem) {
    this.obrListItemsService.emitEventObservationListsUpdated(data);
  }

  private checkAddObservationListTriggers(data: ObservationListItem) {
    this.obrListItemsService.emitEventObservationListsUpdated(data);
  }

  private checkDeleteObservationListTriggers(data: ObservationListItem) {
    this.obrListItemsService.emitEventObservationListsUpdated(data);
  }
}
