<div class="edit-container position-absolute d-block z-index-3 white-background">
  <div class="re-links-container" kendoTooltip tooltipClass="em-tooltip" position="top">
    <div class="re-links">
      <button type="button" class="btn em-btn" (click)="cancelUpdate()">
        <i class="far fa-times" aria-hidden="true" title="Close Panel"></i>
      </button>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0">
    <form [formGroup]="membershipForm" (ngSubmit)="onSubmit()">
      <div class="row mx-0 teal-background">
        <div class="col px-2">
          <h6 class="white-font uppercase p-2 mb-0">{{ actionType }} Membership</h6>
        </div>
      </div>
      <app-error *ngIf="errorMessage" [message]="errorMessage"></app-error>
      <div class="row mx-1">
        <div class="col-9 px-2">
          <mat-form-field class="w-100">
            <input matInput formControlName="name" placeholder="Name" aria-label="Name" required autocomplete="off" />
          </mat-form-field>
        </div>
        <div class="col-3 d-flex align-items-center teal-font bigger">
          <label class="switch mt-2">
            <input formControlName="isActive" type="checkbox" />
            <span class="slider round"></span>
          </label>
          <span class="pl-2">Active</span>
        </div>
      </div>
      <div class="row mx-1 mt-2">
        <div class="col px-2">
          <mat-form-field class="w-100">
            <textarea
              matInput
              rows="5"
              formControlName="description"
              placeholder="Description"
              aria-label="Description"
              required
            >
            </textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-1 mt-2">
        <div class="col px-2">
          <mat-form-field>
            <input
              matInput
              aria-label="Amount per month"
              placeholder="Amount per month"
              formControlName="amount"
              mask="separator.2"
              thousandSeparator=","
              prefix="$"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
            <mat-select
              placeholder="Taxes"
              multiple
              formControlName="selectedTaxes"
              [compareWith]="compareClinicTaxObjects"
            >
              <mat-option *ngFor="let clinicTax of clinicTaxes" [value]="clinicTax">
                {{ clinicTax.tax.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0 teal-background">
        <div class="col">
          <h6 class="white-font uppercase mb-0 py-2">Photo</h6>
        </div>
      </div>
      <div class="row mx-1 panel-item-border grey-two-background">
        <div class="col p-2" style="min-height: 150px">
          <div class="d-flex flex-nowrap justify-content-around" style="position: absolute; top: 0">
            <button type="button" class="btn mt-3" (click)="onAddPhoto()">
              <label class="uploader-circle position-relative pointer text-center"
                ><i title="Add Photo" data-title="" class="fal white-font fa-camera photo-button"></i
              ></label>
            </button>
            <button type="button" class="btn mt-3" *ngIf="membershipImageUrl" (click)="onRemoveImageUrl()">
              <label class="uploader-circle position-relative pointer text-center">
                <i class="fal fa-times-circle white-font photo-button"></i>
              </label>
            </button>
          </div>

          <img
            [src]="membershipImageUrl != '' ? (membershipImageUrl | safeUrl) : '/assets/hosted/blank.png'"
            style="max-width: 500px; max-height: 300px"
            class="my-2 rounded mx-auto d-block"
          />
        </div>
      </div>
      <div class="row mx-0 teal-background">
        <div class="col">
          <h6 class="white-font uppercase mb-0 py-2">Usage</h6>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col pt-2">
          <mat-radio-group class="theme-radio-group teal-font" formControlName="expirationOption">
            <mat-label class="label-header">Membership expiration</mat-label>
            <div class="d-flex flex-column flex-xl-row align-items-start flex-column justify-content-start mt-2 pt-1">
              <mat-radio-button class="mr-2 custom-label-size" [value]="0" (click)="disableExpiration()">
                Never expires
              </mat-radio-button>
              <mat-radio-button
                class="mr-2 custom-label-size"
                (click)="enableExpiration()"
                #customRadioExpiration
                [value]="1"
              >
                <span class="teal-font">After&nbsp;&nbsp;</span>
                <mat-form-field class="small-material-text-input radio-aligned-text" [floatLabel]="'never'">
                  <mat-label></mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="expiresAfter"
                    min="0"
                    class="number custom-label-size"
                    autocomplete="off"
                  />
                  <mat-error *ngIf="membershipForm.controls['expiresAfter'].errors?.required">
                    Please enter a number
                  </mat-error>
                  <mat-error *ngIf="membershipForm.controls['expiresAfter'].errors?.min">
                    Value must be positive
                  </mat-error>
                </mat-form-field>
                <span class="teal-font">&nbsp;billing cycles</span>
              </mat-radio-button>
            </div>
          </mat-radio-group>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col pt-2">
          <mat-radio-group class="theme-radio-group teal-font" formControlName="cancellationOption">
            <mat-label class="label-header">Membership cancellation</mat-label>
            <div class="d-flex flex-column flex-xl-row align-items-start flex-column justify-content-start mt-2 pt-1">
              <mat-radio-button class="mr-2 custom-label-size" (click)="disableCancellation()" [value]="0">
                Cancellable anytime
              </mat-radio-button>
              <mat-radio-button
                class="mr-2 custom-label-size"
                (click)="enableCancellation()"
                #customRadioCancellation
                [value]="1"
              >
                <span class="teal-font custom-label-size">After&nbsp;&nbsp;</span>
                <mat-form-field class="small-material-text-input radio-aligned-text" [floatLabel]="'never'">
                  <mat-label></mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="cancellableAfter"
                    min="0"
                    class="number custom-label-size"
                    autocomplete="off"
                  />
                  <mat-error *ngIf="membershipForm.controls['cancellableAfter'].errors?.required">
                    Please enter a number
                  </mat-error>
                  <mat-error *ngIf="membershipForm.controls['cancellableAfter'].errors?.min">
                    Value must be positive
                  </mat-error>
                </mat-form-field>
                <span class="teal-font custom-label-size">&nbsp;billing cycles</span>
              </mat-radio-button>
            </div>
          </mat-radio-group>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col py-2">
          <mat-radio-group class="theme-radio-group teal-font" formControlName="redemptionOption">
            <mat-label class="label-header">Allowable redemptions per billing cycle</mat-label>
            <div class="d-flex flex-column flex-xl-row align-items-start flex-column justify-content-start mt-2 pt-1">
              <mat-radio-button class="mr-2 custom-label-size" (click)="disableRedemption()" [value]="0">
                Unlimited redemptions
              </mat-radio-button>
              <mat-radio-button
                class="mr-2 custom-radio-button"
                (click)="enableRedemption()"
                #customRadioRedemption
                [value]="1"
              >
                <mat-form-field class="small-material-text-input radio-aligned-text" [floatLabel]="'never'">
                  <input
                    matInput
                    type="number"
                    formControlName="allowableRedemptions"
                    min="0"
                    class="number custom-label-size"
                    autocomplete="off"
                  />
                  <mat-error *ngIf="membershipForm.controls['allowableRedemptions'].errors?.required">
                    Please enter a number
                  </mat-error>
                  <mat-error *ngIf="membershipForm.controls['allowableRedemptions'].errors?.min">
                    Value must be positive
                  </mat-error>
                </mat-form-field>
                <span class="custom-label-size">&nbsp;&nbsp;redemptions</span>
              </mat-radio-button>
            </div>
          </mat-radio-group>
        </div>
      </div>
      <div class="row mx-0 teal-background">
        <div class="col">
          <h6 class="white-font uppercase mb-0 py-2">Credits</h6>
        </div>
      </div>
      <div class="row mx-1">
        <div class="col px-2 pb-4">
          <label class="d-block mb-0 teal-font fake-mat-label custom-label-size"
            >Percentage of membership fee added to patient balance each billiyng cycle</label
          >
          <mat-form-field class="percentage-amount" floatLabel="always">
            <input
              matInput
              type="number"
              formControlName="percentageAppliedToCredit"
              min="0"
              class="number custom-label-size"
              autocomplete="off"
            />
            <mat-icon matSuffix>percent</mat-icon>
            <mat-error *ngIf="membershipForm.controls['percentageAppliedToCredit'].errors?.required">
              Please enter a number
            </mat-error>
            <mat-error *ngIf="membershipForm.controls['percentageAppliedToCredit'].errors?.min">
              Value must be positive
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0 teal-background">
        <div class="col">
          <h6 class="white-font uppercase mb-0 py-2">Services</h6>
        </div>
      </div>
      <div class="row mx-1">
        <div class="col p-2">
          <button type="button" (click)="onAddServices()" class="btn em-btn em-btn-green">
            <i
              class="fal pr-1"
              [ngClass]="membershipServices.length <= 0 ? 'fa-plus-circle bigger-icon' : 'fa-pencil'"
            ></i>
            {{ membershipServices.length <= 0 ? 'Add' : 'Edit' }} Services
          </button>
        </div>
      </div>
      <div class="row mx-1">
        <div class="col p-2">
          <perfect-scrollbar class="items-view">
            <kendo-grid [data]="membershipServices">
              <kendo-grid-column field="clinicServiceTemplate.serviceName" title="Service"> </kendo-grid-column>
              <kendo-grid-column field="amount" [width]="110" title="Discount">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ formatDiscount(dataItem) }}
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>
          </perfect-scrollbar>
        </div>
      </div>
      <div class="row mx-0 teal-background">
        <div class="col">
          <h6 class="white-font uppercase mb-0 py-2">Products</h6>
        </div>
      </div>
      <div class="row mx-1">
        <div class="col p-2">
          <button type="button" (click)="onAddProducts()" class="btn em-btn em-btn-green">
            <i
              class="fal pr-1"
              [ngClass]="membershipProducts.length <= 0 ? 'fa-plus-circle bigger-icon' : 'fa-pencil'"
            ></i>
            {{ membershipProducts.length <= 0 ? 'Add' : 'Edit' }} Products
          </button>
        </div>
      </div>
      <div class="row mx-1">
        <div class="col p-2">
          <perfect-scrollbar class="items-view">
            <kendo-grid [data]="membershipProducts">
              <!-- <kendo-grid-column field="clinicProduct.product.productBrand.name" title="Brand"> </kendo-grid-column> -->
              <kendo-grid-column field="clinicProduct.displayName" title="Product"> </kendo-grid-column>
              <kendo-grid-column field="amount" [width]="110" title="Discount">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ formatDiscount(dataItem) }}
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>
          </perfect-scrollbar>
        </div>
      </div>
      <div class="row mx-1 my-3">
        <div class="col px-2 text-center">
          <button type="submit" class="btn em-btn em-btn-green" [disabled]="!membershipForm?.valid">
            {{ actionType }}
          </button>
          <button type="button" class="btn em-btn em-btn-green ml-2" (click)="cancelUpdate()">Cancel</button>
        </div>
      </div>
    </form>
  </perfect-scrollbar>
</div>
