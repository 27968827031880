<div class="row py-2" kendoTooltip tooltipClass="em-tooltip" position="top">
  <div class="col">
    <h5 class="d-inline" style="font-size: 1rem">
      {{
        service.serviceDetailTemplateId === serviceDetailTemplate.Injections
          ? 'Injectables'
          : service.serviceDetailTemplateId === serviceDetailTemplate.Coolsculpting
          ? 'Coolsculpting'
          : service.serviceDetailTemplateId === serviceDetailTemplate.CoolTone
          ? 'CoolTone'
          : service.serviceDetailTemplateId === serviceDetailTemplate.Deoxycholate
          ? 'Deoxycholate'
          : 'Treatments'
      }}
      Toolbar
    </h5>
    <button
      class="btn btn-sm em-btn em-btn-green ml-2 d-inline align-baseline btn btn-sm"
      [ngClass]="{ disabled: droppingPins }"
      (click)="onResetForm()"
    >
      <i class="fa fa-sync" aria-hidden="true" title="Reset"></i>
    </button>
  </div>
  <div class="col text-right">
    <button
      type="button"
      data-test-id="add-pin-toolbar-button"
      class="btn em-btn em-btn-green btn-sm"
      [disabled]="onPlottingButtonDisabled()"
      (click)="onTogglePinDrop()"
    >
      <ng-container *ngIf="service.serviceDetailTemplateId === serviceDetailTemplate.Injections || service.serviceDetailTemplateId == serviceDetailTemplate.Deoxycholate">
        {{ droppingPins ? 'Stop' : 'Start' }} Pin Drop
      </ng-container>
      <ng-container
        *ngIf="
          service.serviceDetailTemplateId === serviceDetailTemplate.Coolsculpting ||
          service.serviceDetailTemplateId === serviceDetailTemplate.CoolTone
        "
        >Add Pin</ng-container
      >
    </button>
  </div>
</div>
<ng-container *ngIf="service.serviceDetailTemplateId === serviceDetailTemplate.Injections">
  <app-injection-toolbar></app-injection-toolbar>
</ng-container>

<ng-container *ngIf="service.serviceDetailTemplateId === serviceDetailTemplate.Coolsculpting">
  <app-coolsculpting-toolbar
    [associatedPlannedTreatment]="associatedPlannedTreatment"
    [service]="service"
  ></app-coolsculpting-toolbar>
</ng-container>

<ng-container *ngIf="service.serviceDetailTemplateId === serviceDetailTemplate.CoolTone">
  <app-cooltone-toolbar
    [associatedPlannedTreatment]="associatedPlannedTreatment"
    [service]="service"
  ></app-cooltone-toolbar>
</ng-container>

<ng-container *ngIf="service.serviceDetailTemplateId === serviceDetailTemplate.Deoxycholate">
  <app-deoxycholate-toolbar></app-deoxycholate-toolbar>
</ng-container>