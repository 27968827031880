import { Injectable } from '@angular/core';
import { Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { Nudge } from '@models/nudges/nudge';
import { NudgeService } from '@services/nudge.service';
import { SignalrBase } from '@services/signalr-base';
import { SignalrHttpClientService } from '@services/signalr-http-client.service';
import { UsersService } from '@services/users.service';
@Injectable({
  providedIn: 'root',
})
export class NudgesSignalrService extends SignalrBase<any> {
  constructor(
    private userService: UsersService,
    authService: AuthService,
    private nudgesService: NudgeService,
    private signalrHttpClientService: SignalrHttpClientService
  ) {
    super('MyNudges', Policies.empty, authService, signalrHttpClientService);
    this.registerServerEvents();
  }

  private registerServerEvents(): void {
    super.registerServerEvent('AddNudge', (message: Nudge) => {
      if (
        message &&
        (!message.assignedTo ||
          (message.assignedTo &&
            this.userService.loggedInUser &&
            message.assignedTo.id == this.userService.loggedInUser.id))
      ) {
        this.nudgesService.updateNudgeCount();
      }
    });
    super.registerServerEvent('UpdateNudge', (message: Nudge) => {
      if (
        message &&
        (!message.assignedTo ||
          (message.assignedTo &&
            this.userService.loggedInUser &&
            message.assignedTo.id == this.userService.loggedInUser.id))
      ) {
        this.nudgesService.updateNudgeCount();
      }
    });
  }

  // sendNudge(message: Nudge) {
  //   this._hubConnection.invoke('NudgeFromClient', message);
  // }
}
