import { Component, Input, OnInit } from '@angular/core';
import { PhotoFilter, PhotoGalleryMode } from '@app/photos/photo-filter';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-select-clinic-photo',
  templateUrl: './select-clinic-photo.component.html',
  styleUrls: ['./select-clinic-photo.component.less'],
})
export class SelectClinicPhotoComponent implements OnInit {
  isIpad = navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
  filter: PhotoFilter;
  @Input() photos: PhotoMetaData[] = [];

  constructor(public activeModal: NgbActiveModal,) {}

  ngOnInit(): void {
    this.filter = new PhotoFilter(this.isIpad);
    this.filter.gallery_mode = PhotoGalleryMode.Clinic;
  }
}
