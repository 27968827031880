import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormatterService } from '@services/formatter.service';
import { DocumentTypeService } from '@services/document-type.service';

@Component({
  selector: 'app-edit-document-type',
  templateUrl: './edit-document-type.component.html',
  styleUrls: ['./edit-document-type.component.less']
})
export class EditDocumentTypeComponent implements OnInit, OnDestroy {
  unsub: Subject<void> = new Subject<void>();
  docTypeId: number;

  docTypeForm: FormGroup;

  addOrEdit: String;
  isNewDocType: boolean;

  constructor(
    private docTypeService: DocumentTypeService,
    public formatterService: FormatterService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsub)).subscribe(params => {
      if (+params['id'] === this.docTypeService.defaultId) {
        this.isNewDocType = true;
        this.addOrEdit = 'Add';
      } else {
        this.docTypeId = +params['id'];
        this.isNewDocType = false;
        this.addOrEdit = 'Edit';
      }

      this.initForm();
    });
  }

  private initForm() {
    let name = '';

    this.docTypeForm = new FormGroup({
      id: new FormControl(this.docTypeId),
      name: new FormControl(name, Validators.required)
    });

    if (this.isNewDocType === false) {
      this.docTypeService
        .getDocType(this.docTypeId)
        .subscribe(docType => {
          name = docType.name;
          this.docTypeForm.controls['name'].setValue(name);
        });
    }
  }

  onSubmit() {

    if (this.isNewDocType) {
      this.docTypeService.addDocType(this.docTypeForm.value).subscribe(() => {
        this.router.navigate([
          '/management/catalogue/document-types',
          { outlets: { 'action-panel': null } }
        ]);
      });
    } else {
      this.docTypeService.updateDocType(this.docTypeForm.value).subscribe(() => {
        this.router.navigate([
          '/management/catalogue/document-types',
          { outlets: { 'action-panel': null } }
        ]);
      });
    }
  }

  cancelUpdate() {
    this.docTypeService.docTypesChangeCancelled.next();
    this.router.navigate([
      '/management/catalogue/document-types',
      { outlets: { 'action-panel': null } }
    ]);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
