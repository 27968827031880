import { Invoice } from '@models/invoice/invoice';
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { InvoicesService } from '@services/invoices.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-service-products',
  templateUrl: './service-products.component.html',
  styleUrls: ['./service-products.component.less'],
})
export class ServiceProductsComponent implements OnInit, OnDestroy {
  _serviceProducts: any[];
  @Input() set serviceProducts(serviceProducts: any[]) {
    this._serviceProducts = serviceProducts;
    if (this.invoice && this.invoice.id) {
      this.determineWhichProductsAreAvailable();
    }
  }

  get serviceProducts() {
    return this._serviceProducts;
  }
  @Output() selectProduct = new EventEmitter();
  @Input() invoice: Invoice;
  @Input() panelHeight: number;

  unsub = new Subject<any>();

  constructor(private invoiceService: InvoicesService) {
    this.invoiceService.invoiceUpdated$.pipe(takeUntil(this.unsub)).subscribe((invoiceId) => {
      if (invoiceId == this.invoice.id) {
        this.determineWhichProductsAreAvailable();
      }
    });
  }

  ngOnInit() {
    this.determineWhichProductsAreAvailable();
  }

  determineWhichProductsAreAvailable() {
    this.serviceProducts.forEach((product) => {
      product.isInCurrentInvoice =
        this.invoice.invoiceLineItems.findIndex((ili) => ili.clinicProductId == product.clinicProductId) != -1;
    });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
