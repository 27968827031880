<ngx-loading [show]="loading"></ngx-loading>
<perfect-scrollbar class="scrollbar-container" *ngIf="filter?.selectedPhoto || photoURL">
  <button class="btn close-btn grey-three-background" (click)="closeLightBox()">
    <i class="fal fa-times"></i>
  </button>
  <!-- <div class="index-info">
    Patient Photo #{{filter?.selectedPhotoIndex + 1 }}/{{filter?.take  }}
  </div> -->
  <div class="d-flex align-items-center h-100 lightbox-container p-2" mat-dialog-content>
    <ng-container *ngIf="filter; else singlePhoto">
      <button
        class="btn prev-btn navy-font position-absolute p-0"
        (click)="incrementPhoto(-1)">
        <i class="far fa-arrow-left"></i>
      </button>
      <img
        class="mw-100 h-100 photo-lightbox ml-auto mr-auto"
        src={{filter?.selectedPhoto.filePath}}
        (load)="loading=false"
        (error)="loading=false"
        alt="{{filter?.selectedPhoto?.imageName}}">
      <button
        class="btn next-btn navy-font position-absolute p-0"
        (click)="incrementPhoto(1)">
        <i class="far fa-arrow-right"></i>
      </button>
    </ng-container>
    <ng-template #singlePhoto>
      <img
        class="mw-100 h-100 photo-lightbox ml-auto mr-auto"
        src={{photoURL}}
        (load)="loading=false"
        (error)="loading=false"
        alt="{{photoURL}}"> 
    </ng-template>
  </div>
</perfect-scrollbar>
