import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { AuthService } from '@app/auth/auth.service';
import { Clinic } from '@models/clinic';
import { User } from '@models/user';
import { ClinicsService } from '@services/clinics.service';
import { EventsService } from '@services/events.service';
import { UsersService } from '@services/users.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.less'],
})
export class MobileNavComponent implements OnInit, OnDestroy {
  clinic: Clinic;
  user: User;
  currentDate: Date;
  displayCalendar = false;

  private touchstartX = 0;
  private readonly touchSensitivity = 120;
  private unsub: Subject<void> = new Subject<void>();

  @ViewChild(MatCalendar, { read: ElementRef }) calendar: ElementRef;

  @HostListener('touchstart', ['$event'])
  touchStart(event: TouchEvent) {
    this.touchstartX = event.changedTouches[0].screenX;
  }

  @HostListener('touchend', ['$event'])
  touchEnd(event: TouchEvent) {
    const touchendX = event.changedTouches[0].screenX;
    if (touchendX < this.touchstartX - this.touchSensitivity) {
      // swiped left
      this.goForwardOneDay();
    }
    if (touchendX > this.touchstartX + this.touchSensitivity) {
      // swiped right
      this.goBackOneDay();
    }
  }

  @HostListener('click', ['$event'])
  click(event: PointerEvent) {
    const target = event.target as HTMLElement;
    const calClicked = this.calendar?.nativeElement.contains(target);
    if (!calClicked) {
      this.displayCalendar = false;
    }
  }

  constructor(
    private clinicsService: ClinicsService,
    private usersService: UsersService,
    private authService: AuthService,
    private eventsService: EventsService
  ) {}

  ngOnInit(): void {
    this.clinicsService.clinicIdSelected$.subscribe((clinicId) => {
      this.clinic = this.clinicsService.clinic;
    });

    this.usersService.loggedInUserUpdated$.subscribe((user) => {
      this.user = user;
    });

    this.eventsService.currentDate.subscribe((date) => {
      this.currentDate = date;
    });
  }

  openCalendar(event: Event) {
    event.stopPropagation();
    this.displayCalendar = !this.displayCalendar;
  }

  setSelectedDate(date: moment.Moment) {
    this.eventsService.selectedDate.next(date.toDate());
    this.displayCalendar = false;
  }

  goBackOneDay() {
    this.eventsService.selectedDate.next(new Date(this.currentDate.setDate(this.currentDate.getDate() - 1)));
  }

  goForwardOneDay() {
    this.eventsService.selectedDate.next(new Date(this.currentDate.setDate(this.currentDate.getDate() + 1)));
  }

  goToToday() {
    this.eventsService.selectedDate.next(new Date());
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }
}
