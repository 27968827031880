import { isNullOrUndefined } from '@app/shared/helpers';

export class UrlHelper {
  public static addStartEndDateParameters(url: string, startDate?: Date, endDate?: Date): string {
    let delim = '?';
    if (!isNullOrUndefined(startDate)) {
      const startDateParam = startDate.toDateString();
      url += delim + 'startDate=' + startDateParam;
      delim = '&';
    }
    if (!isNullOrUndefined(endDate)) {
      const endDateParam = endDate.toDateString();
      url += delim + 'endDate=' + endDateParam;
    }
    return url;
  }
}
