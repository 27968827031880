<ngx-loading [show]="loading"></ngx-loading>
<kendo-grid
  class="emr-kendo-grid hide-default-sort h-100"
  [data]="gridView"
  [style.maxHeight.%]="100"
  [pageSize]="gridState.take"
  [pageable]="true"
  [skip]="gridState.skip"
  [sort]="gridState.sort"
  [sortable]="{
    allowUnsort: false,
    mode: 'single'
  }"
  (pageChange)="pageChange($event)"
  (sortChange)="sortChange($event)"
  (excelExport)="onExcelExport($event)"
  #grid="kendoGrid"
>
  <kendo-grid-column field="providerName" title="Provider">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="totalSales" title="Total" format="{0:c}">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="creditAmount" title="Credits" format="{0:c}">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="serviceAmount" title="Service" format="{0:c}">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="productAmount" title="Product" format="{0:c}">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="returnAmount" title="Return" format="{0:c}">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="appointmentCount" title="# Invoices">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-excel fileName="providers_report.xlsx" [fetchData]="allData"></kendo-grid-excel>
  <div *kendoGridDetailTemplate="let dataItem">
    <app-provider-details [serviceProviderId]="dataItem?.providerId" [gridState]="gridState"></app-provider-details>
  </div>
</kendo-grid>