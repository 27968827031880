import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { BusinessDay, BusinessWeek, HoursOfOperation, HoursOfOperationDay } from '@models/hoursofoperation';
import { WeekDay } from '@models/week-day';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Injectable()
export class HoursOfOperationService {
  constructor(private http: HttpClient) {}

  getHoursOfOperation(companyId: number = 0) {
    // api/HoursOfOperation/company/1
    return this.http.get<HoursOfOperation>(`${environment.baseUrl}api/HoursOfOperation/company/${companyId}`);
  }

  async getHoursOfOperationClinc(clinicId: number = 0): Promise<HoursOfOperation> {
    // api/HoursOfOperation/clinic/1
    return new Promise((resolve) => {
      this.http
        .get<HoursOfOperation>(`${environment.baseUrl}api/HoursOfOperation/clinic/${clinicId}`)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  saveCompanyHoursOfOperation(companyId: Number, buzzHours: HoursOfOperation) {
    return this.http.put<HoursOfOperation>(
      `${environment.baseUrl}api/HoursOfOperation/${companyId}/${buzzHours.hoursOfOperationId}`,
      buzzHours
    );
  }

  saveClinicHoursOfOperation(clinicId: Number, buzzHours: HoursOfOperation) {
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put<HoursOfOperation>(
      `${environment.baseUrl}api/HoursOfOperation/${clinicId}/${buzzHours.hoursOfOperationId}`,
      buzzHours,
      { headers }
    );
  }

  saveHoursOfOperation(buzzHours: HoursOfOperation) {
    return this.http.put<HoursOfOperation>(
      `${environment.baseUrl}api/HoursOfOperation/${buzzHours.hoursOfOperationId}`,
      buzzHours
    );
  }
}

export function getBusinessWeek(days: HoursOfOperationDay[]): BusinessWeek {
  const b = new BusinessWeek();

  if (days) {
    days.forEach((d) => {
      if (d) {
        switch (d.dayofweek) {
          case WeekDay.Sunday:
            setDay(b.sun, d);
            break;

          case WeekDay.Monday:
            setDay(b.mon, d);
            break;

          case WeekDay.Tuesday:
            setDay(b.tue, d);
            break;

          case WeekDay.Wednesday:
            setDay(b.wed, d);
            break;

          case WeekDay.Thursday:
            setDay(b.thu, d);
            break;

          case WeekDay.Friday:
            setDay(b.fri, d);
            break;

          case WeekDay.Saturday:
            setDay(b.sat, d);
            break;
        }
      }
    });
  }

  function setDay(day: BusinessDay, hood: HoursOfOperationDay) {
    day.id = hood.hoursOfOperationDayId;
    day.closed = hood.closed;
    day.openTime = hood.openTime ? moment().startOf('day').add(moment.duration(hood.openTime)).toDate() : null;
    day.closeTime = hood.closeTime ? moment().startOf('day').add(moment.duration(hood.closeTime)).toDate() : null;
  }

  return b;
}

export function getHoursOfOperationDays(buzz: BusinessWeek): HoursOfOperationDay[] {
  if (buzz) {
    return [
      fromDay(WeekDay.Sunday, buzz.sun),
      fromDay(WeekDay.Monday, buzz.mon),
      fromDay(WeekDay.Tuesday, buzz.tue),
      fromDay(WeekDay.Wednesday, buzz.wed),
      fromDay(WeekDay.Thursday, buzz.thu),
      fromDay(WeekDay.Friday, buzz.fri),
      fromDay(WeekDay.Saturday, buzz.sat),
    ];
  }

  function fromDay(dayofweek: WeekDay, day: BusinessDay): HoursOfOperationDay {
    return {
      hoursOfOperationDayId: day.id,
      closed: day.closed,
      dayofweek: dayofweek,
      openTime: moment.duration(moment(day.openTime).format('HH:mm')),
      closeTime: moment.duration(moment(day.closeTime).format('HH:mm')),
    };
  }

  return [];
}
