<div class="panel-box-content pb-0 border-0 d-flex flex-column" *ngIf="plannedTreatmentMultiple?.plannedTreatments">
  <ng-container *ngFor="let plannedTreatment of plannedTreatmentMultiple.plannedTreatments; let i = index">
    <div class="d-flex mb-1">
      <div class="content-row w-100">
        <div class="pr-1">{{ plannedTreatment.rank }} - {{ plannedTreatment.service.serviceName }}</div>
        <div *ngIf="plannedTreatment.treatmentAppointments.length > 0" class="pr-1">
          {{ plannedTreatment.treatmentAppointments[0].dateTime | date : 'yyyy-MM-dd h:mma' }}
        </div>
        <div
          *ngIf="
            plannedTreatment.treatmentAppointments === undefined || plannedTreatment.treatmentAppointments.length === 0
          "
          class="pr-1"
        >
          <span> - </span>
        </div>
        <div class="pr-1">
          <i
            [ngClass]="{
              'fa-calendar': plannedTreatment.treatmentState == treatmentState.Unplanned,
              'fa-calendar-day': plannedTreatment.treatmentState == treatmentState.Scheduled,
              'fa-calendar-check': plannedTreatment.treatmentState == treatmentState.Completed,
              'fas fa-sad-tear purple-font': plannedTreatment.paymentStatus == PaymentStatus.Refund
            }"
            class="pl-3 far"
            style="font-size: 1rem"
            [title]="
              plannedTreatment.treatmentState == treatmentState.Unplanned
                ? 'Unplanned'
                : plannedTreatment.treatmentState == treatmentState.Scheduled
                ? 'Scheduled'
                : plannedTreatment.treatmentState == treatmentState.Completed
                ? 'Completed'
                : plannedTreatment.treatmentState == treatmentState.Returned
                ? 'Returned'
                : ''
            "
          ></i>
        </div>
      </div>
    </div>
  </ng-container>
</div>
