<nav mat-tab-nav-bar>
  <a mat-tab-link routerLink="services" routerLinkActive #serv="routerLinkActive" [active]="serv.isActive">Services</a>
  <a mat-tab-link routerLink="products" routerLinkActive #pro="routerLinkActive" [active]="pro.isActive">Products</a>
  <!-- Unused components, may be implemented later -->
  <!-- <a mat-tab-link routerLink="packages" routerLinkActive #pack="routerLinkActive" [active]="pack.isActive">Packages</a>
  <a mat-tab-link routerLink="specials" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">Specials</a> -->
  <!-- <a mat-tab-link routerLink="document-types" routerLinkActive #cla="routerLinkActive" [active]="cla.isActive">Document Types</a> -->
  <a
    mat-tab-link
    routerLink="clinic-supplies"
    routerLinkActive
    #clinicSupply="routerLinkActive"
    [active]="clinicSupply.isActive"
    >Clinic Supplies</a
  >
  <a
    mat-tab-link
    routerLink="text-templates"
    routerLinkActive
    #textTemplates="routerLinkActive"
    [active]="textTemplates.isActive"
    >Text Templates</a
  >
  <a
    mat-tab-link
    routerLink="memberships"
    routerLinkActive
    #memberships="routerLinkActive"
    [active]="memberships.isActive"
    >Memberships</a
  >
  <!-- Disabled photo tag when made consistent across clinics -->
  <!-- <a mat-tab-link routerLink="custom-tags" routerLinkActive #photoTags="routerLinkActive" [active]="photoTags.isActive">Photo Tags</a> -->
</nav>
<div class="content">
  <router-outlet></router-outlet>
</div>
