<div class="image-map patient-image h-100 panel-item-border" kendoTooltip tooltipClass="em-tooltip" position="top">
  <div
    class="map"
    leaflet
    leafletDraw
    [leafletOptions]="mapOptions"
    [leafletDrawOptions]="drawOptions"
    (leafletMapReady)="onMapReady($event)"
    (leafletClick)="onMapClick($event)"
    (leafletDrawReady)="onDrawReady($event)"
    (leafletDrawCreated)="onDrawCreated($event)"
    (leafletDrawDeleted)="onDrawDeleted($event)"
  >
    <div class="float-right mt-2 photo-buttons">
      <button
        *ngIf="service.serviceDetailTemplateId === serviceDetailTemplate.Injections || service.serviceDetailTemplateId == serviceDetailTemplate.Deoxycholate"
        class="btn btn-sm em-btn em-btn-green mr-2"
        (click)="onToggleTooltips()"
      >
        <i class="far fa-file-alt" title="Toggle Details"></i>
      </button>
      <!-- Leaving this here because Craig will likely want it re-enabled later -->
      <!-- <button *ngIf="treatmentIsFromToday" class="btn plot-btn" (click)="onAnnotate()">
        <i class="far fa-pen-alt"></i>
      </button> -->
      <button
        *ngIf="!service?.isLocked"
        class="btn btn-sm em-btn em-btn-green mr-2"
        (click)="onSetPhoto()"
      >
        <i class="far fa-camera" title="Add Photo"></i>
      </button>
      <button *ngIf="!service?.isLocked && isUndoActive" class="btn btn-sm em-btn em-btn-green mr-2" (click)="onUndo()">
        <i class="fas fa-undo"></i>
      </button>
    </div>
  </div>
</div>
