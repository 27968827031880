<h1 mat-dialog-title>Delete or Deactivate Form?</h1>
<div mat-dialog-content>
  <p>
    Do you want to permanently delete this form or just deactivate? Following deactivation this form can be reactivated
    again for future use.
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="'cancel'">Cancel</button>
  <button mat-button [mat-dialog-close]="'delete'">Delete</button>
  <button mat-button [mat-dialog-close]="'deactivate'" cdkFocusInitial>Deactivate</button>
</div>
