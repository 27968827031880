import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { GridDataResult, PageChangeEvent, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { ProductReport } from '@models/reports/product/product-report';
import { IReportComponent } from '@models/reports/ireport-component';
import { Tax } from '@models/reports/tax/tax';
import { ReportsService } from '@services/reports.service';
import { takeUntil, map } from 'rxjs/operators';
import { DataSourceRequestState, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-product-reports',
  templateUrl: './product-reports.component.html',
  styleUrls: ['./product-reports.component.less']
})
export class ProductReportsComponent implements OnInit, OnDestroy, IReportComponent {
  @ViewChild('grid') grid;
  gridView: GridDataResult;
  gridState: DataSourceRequestState;
  loading: boolean = false;
  possibleTaxes: Tax[] = [];
  unsub: Subject<void> = new Subject<void>();

  constructor(
    private reportsService: ReportsService,
    private currencyPipe: CurrencyPipe
  ) { }

  ngOnInit() {
    this.initDefaultValue();
    this.getPossibleTaxes();
    this.getReportData();
    this.allData = this.allData.bind(this);
  }

  initDefaultValue() {
    this.gridState = {
      skip: 0,
      take: 100,
      // set default state of sorting icon
      sort: [{field: 'productName', dir: 'asc'}]
    };

    this.gridView = {
      data: [],
      total: 0
    };
  }

  getPossibleTaxes() {
    this.reportsService.getProductPossibleTaxes()
      .pipe(takeUntil(this.unsub))
      .subscribe((taxes: Tax[]) => {
        taxes.forEach((tax: Tax) => {
          if (!this.possibleTaxes.some((possibleTax: Tax) => possibleTax.id === tax.id)) {
            this.possibleTaxes.push(tax);
          }
        });
      });
  }

  getReportData() {
    this.loading = true;

    this.reportsService.getProductReportEntities(this.gridState)
      .pipe(takeUntil(this.unsub))
      .subscribe((productReports: any) => { 
        this.gridView = {
          data: productReports.data,
          total: productReports.total || productReports.data.length
        };
        this.loading = false;
    });
  }

  exportToExcel() {
    this.grid.saveAsExcel();
  }

  onExcelExport(e: any) {
    this.reportsService.onExcelExport(e, 'Products Report');
  }

  pageChange(event: DataStateChangeEvent) {
    this.gridState = event;
    this.getReportData();
  }

  sortChange(sort: SortDescriptor[]) {
    this.gridState.sort = sort;
    this.gridView.data = orderBy(this.gridView.data, this.gridState.sort);
  }

  sortIconClass(column: any) {
    return this.gridState.sort.length && (this.gridState.sort[0].field === column.field && this.gridState.sort[0].dir === 'asc') ? 'fad fa-sort-up'
           : this.gridState.sort.length && (this.gridState.sort[0].field === column.field && this.gridState.sort[0].dir === 'desc') ? 'fad fa-sort-down'
           : 'fas fa-sort';
  }

  allData = (): Observable<any> => {
    const gridState: DataSourceRequestState = {};
    return this.reportsService.getProductReportEntities(gridState)
            .pipe(map((productReports: any) => {
              return {
                data: productReports.data.map((element: ProductReport) => {
                    this.possibleTaxes.forEach((item) => {
                      element[item.name] = this.currencyPipe.transform(element.taxCollected[item.name]);
                    });
                    return {
                      ...element,
                      salesAmount: this.currencyPipe.transform(element.salesAmount)
                    };
                }),
                total: productReports.total
              };
            }));
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
