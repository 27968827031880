import { Clinic } from '@models/clinic';
import { ClinicsService } from '@services/clinics.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ReviewService } from '@services/review.service';
import { Policies } from '@app/auth/auth-policies';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.less']
})
export class ReviewsComponent implements OnInit {
  public loading = false;
  public patientId: number;

  showGooglePlaceSetupLink = false;
  clinic: Clinic;
  internalAverageScore = 0;
  googleAverageScore = -1;
  reviewsViewPolicy = Policies.reviewsView;
  reviewsEditPolicy = Policies.reviewsEdit;

  private unsub: Subject<void> = new Subject<void>();

  constructor(
    private reviewService: ReviewService,
    private router: Router,
    private clinicService: ClinicsService
  ) { }

  ngOnInit() {

    this.internalAverageScore = 0;
    const clinicId = parseInt(localStorage.getItem('clinicId'));
    this.clinicService.getClinicById(clinicId).subscribe(clinic => {
      this.clinic = clinic;
      this.showGooglePlaceSetupLink = clinic && !clinic.googlePlaceId;
    });
    this.initReviews(clinicId);
    this.clinicService.clinicUpdated$.subscribe((updatedClinic) => {
        if (clinicId == updatedClinic.clinicId) {
          this.clinic = updatedClinic;
          this.initReviews(clinicId);
        }
    });

    /* Internal rating is no longer shown.
    this.reviewService.getInternalAverageRating(clinicId).subscribe({
      next: rating => this.internalAverageScore = rating
    });
    */

  }

  initReviews(clinicId) {
    this.reviewService.getGoogleAverageRating(clinicId).subscribe({
      next: rating => this.googleAverageScore = rating,
      error: () => this.googleAverageScore = -1
    });
  }

  editReviewSettings(){
    this.router.navigate(['reviews','settings' ]);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  getGoogleMapsQuery(): string {
    //if (this.clinic == null || this.clinic.address == null)
    //  return "";
    //return `https://www.google.com/maps/search/${this.clinic.name}+${this.clinic.address.address1}+${this.clinic.address.address2}+${this.clinic.address.city}+${this.clinic.address.postalCode}+${this.clinic.address.province}+${this.clinic.address.country}`;
    if (this.clinic == null || !this.clinic.googlePlaceId)
      return "";
    return `https://www.google.com/maps/place/?q=place_id:${this.clinic.googlePlaceId}`;
  }
}
