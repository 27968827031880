 
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { MinistryBilling } from '@models/billing/ministry-billing/ministry-billing';
import { Observable } from 'rxjs';
import { UsersService } from './users.service';

export class AgedBillingCode{
   billingCodeId: number;
   billingGroupId: number;
   code: string;
   start: number = 0;
   end?: number
}

export class AgedBillingGroup{
  billingCodes: AgedBillingCode[];
  billingGroupId: number;
  description: string;
  provinceCode: string; 
}
@Injectable({
  providedIn: 'root'
})
export class MinistryService {
  AgeSpecificBillingCodes: AgedBillingGroup[] = null;
  constructor(
    private http: HttpClient,
    private usersService: UsersService
    ) {
    this.usersService.loggedInUserUpdated$.subscribe({
      next: user => {
        if (user && user.id) {
          this.getAgeSpecificBillingCodes().subscribe((bc: AgedBillingGroup[]) => {
            this.AgeSpecificBillingCodes = bc;
          });
        }
      }});
   }

  sendInvoice(ministryInvoiceId: number): Observable<{isSuccess: boolean, errorMessage?: string, httpStatusCode?: number}>  {
    return this.http.post<{isSuccess: boolean, errorMessage?: string}>(`${environment.baseUrl}api/Ministry/SendInvoice/${ministryInvoiceId}`, {});
  }

  getMinistryInvoicesFor(patientId: number): Observable<MinistryBilling[]> {
    return this.http.get<MinistryBilling[]>(`${environment.baseUrl}api/Ministry/GetMinistryInvoicesFor/${patientId}`);
  }

  getMinistryInvoice(invoiceId: number): Observable<MinistryBilling> {
    return this.http.get<MinistryBilling>(`${environment.baseUrl}api/Ministry/GetMinistryInvoice/${invoiceId}`).pipe(map((invoice:MinistryBilling) => {
      invoice.invoiceDate = new Date(Date.parse(invoice.invoiceDate.toString()) + (new Date().getTimezoneOffset() * 60 * 1000)); //invoice date is created from appointment date which is stored as UTC midnight so we must apply offset with local timezone to get correct date
      return invoice;
    }));
  }


  getAgeSpecificBillingCodes(clinicProvinceCode: string = 'BC'){
    return this.http.get<AgedBillingGroup[]>(`${environment.baseUrl}api/Ministry/GetAgeSpecificBillingCodes/${clinicProvinceCode}`);
  }

  updateMinistryInvoice(invoice: MinistryBilling): Observable<MinistryBilling> {
    return this.http.put<MinistryBilling>(`${environment.baseUrl}api/Ministry/UpdateMinistryInvoice`, invoice);
  }
}
