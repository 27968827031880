import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ObservationListItem } from '@models/observation/observation';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { ObservationListItemsService } from '@services/observation-list-items.service';
import { TagService } from '@services/tag.service';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { ConfirmDeleteDialogComponent } from '../../../../management/dialogs/confirm-delete/confirm-delete.component';
import { ClinicSuppliesAddComponent } from '../clinic-supplies-add/clinic-supplies-add.component';

@Component({
  selector: 'app-clinic-supplies-list',
  templateUrl: './clinic-supplies-list.component.html',
  styleUrls: ['./clinic-supplies-list.component.less'],
})
export class ClinicSuppliesListComponent implements OnInit, OnDestroy {
  @Input() height = 'calc(100dvh - 75px)';
  @Output() disableGridEvent = new EventEmitter<boolean>();

  searchValue = '';
  searchCtrl: FormControl;
  filteredClinicSupplies: Observable<ObservationListItem[]>;

  unsub: Subject<void> = new Subject<void>();
  disableGrid = false;
  loading = false;

  clinicSupplies: ObservationListItem[];
  public gridView: GridDataResult;

  gridState: State = {
    sort: [
      {
        field: 'clinicSupplyType.observationListType.name',
        dir: 'desc',
      },
    ],
    skip: 0,
    take: 20,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  editedRowIndex: number;
  editedDataItem: ObservationListItem;

  constructor(
    private obrListItemService: ObservationListItemsService,
    private tagService: TagService,
    private deleteDialog: MatDialog,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.searchCtrl = new FormControl();
    this.filteredClinicSupplies = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map((cs) => this.filterObservationListItems(cs))
    );
  }

  ngOnInit() {
    this.gridView = {
      data: [],
      total: 0,
    };
    this.obrListItemService.refreshRequired$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.disableGrid = false;
      this.sendDisableGrid(this.disableGrid);
      if (this.obrListItemService.refreshRequired$) {
        this.refreshData();
      }
    });
    this.refreshData();
    this.initObservationListsChangedSub();
    this.initClinicDocChangeCancelledSubscription();
    this.initNewClinicSupplyAddedSubscription();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.gridState = state;
    this.loadData();
  }

  refreshData(showLoad: boolean = true) {
    if (showLoad) this.loading = true;
    this.clinicSupplies = [];
    this.obrListItemService.getClinicSupplies().subscribe((cs) => {
      this.clinicSupplies = cs;
      this.loadData();
      this.loading = false;
    });
  }

  loadData() {
    this.gridView = process(this.clinicSupplies, this.gridState);
  }

  public onFilter(inputValue: string): void {
    this.gridView = process(this.clinicSupplies, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'name',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'observationListType.name',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    });
  }

  initObservationListsChangedSub() {
    this.obrListItemService.observationListsChanged.pipe(takeUntil(this.unsub)).subscribe((oli) => {
      this.refreshData();
      this.disableGrid = false;
    });
  }

  initClinicDocChangeCancelledSubscription() {
    this.obrListItemService.observationListsChangeCancelled.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.loading = false;
      this.disableGrid = false;
    });
  }

  initNewClinicSupplyAddedSubscription() {
    this.obrListItemService.newClinicSupplyTypeAdded$.pipe(takeUntil(this.unsub)).subscribe((clinicSupplyType) => {
      this.disableGrid = true;

      // Add the new Clinic Supply, open the Edit panel
      let observationListItem = new ObservationListItem({
        id: 0,
        clinicSupplyTypeId: clinicSupplyType.id,
        displayName: '',
        pricePerUnit: 0,
        costPerUnit: 0,
        unitsOnHand: 0,
      });
      this.obrListItemService.addObservationListItem(observationListItem).subscribe((observationListItem) => {
        this.obrListItemService.observationListsChanged.next();
        this.editHandler({ dataItem: observationListItem });
      });
    });
  }

  public editHandler({ dataItem }) {
    this.disableGrid = true;
    this.sendDisableGrid(this.disableGrid);
    this.router.navigate([
      '/management/catalogue/clinic-supplies',
      { outlets: { 'action-panel': ['edit-clinic-supply-item', dataItem.id] } },
    ]);
  }

  onAddClick() {
    const assignModal = this.modalService.open(ClinicSuppliesAddComponent, {
      centered: true,
    });
    assignModal.componentInstance.quickView = true;

    //assignModal.componentInstance.patientId = this.patientId;
    /* this.disableGrid = true;
    this.router.navigate([
      '/management/catalogue/clinic-supplies',
      {
        outlets: {
          'action-panel': ['edit-clinic-supply-item', this.obrListItemService.newEntityId],
        },
      },
    ]);*/
  }

  filterObservationListItems(name: string) {
    let filterResults: ObservationListItem[] = [];

    if (name !== '') {
      this.gridView = {
        data: this.clinicSupplies.filter((cs) => cs.clinicSupplyType.name.toLowerCase().includes(name.toLowerCase())),
        total: this.clinicSupplies.filter((cs) => cs.clinicSupplyType.name.toLowerCase().includes(name.toLowerCase()))
          .length,
      };
      filterResults = this.clinicSupplies.filter((cs) =>
        cs.clinicSupplyType.name.toLowerCase().includes(name.toLowerCase())
      );
    } else {
      this.gridView = {
        data: this.clinicSupplies,
        total: this.clinicSupplies.length,
      };
      filterResults = [];
    }
    return filterResults;
  }

  public removeHandler({ dataItem }) {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'delete') {
          this.obrListItemService.deleteObservationListItem(dataItem.id).subscribe(() => {
            this.refreshData();
          });
        }
      });
  }

  updateIsActive(observationListItem: ObservationListItem, event) {
    this.obrListItemService.updateIsActive(observationListItem, event.target.checked).subscribe(() => {
      this.refreshData(false);
    });
  }

  toggleSupplyTag(observationListItem: ObservationListItem, event) {
    const addOrRemoveTag = event.target.checked
      ? this.tagService.addSupplyTag(observationListItem.id)
      : this.tagService.deleteSupplyTag(observationListItem.id);
    addOrRemoveTag.subscribe((supplyTag) => {
      this.refreshData(false);
    });
  }

  sendDisableGrid(value: boolean) {
    this.disableGridEvent.emit(value);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
