<div
  class="d-flex flex-nowrap h-100 w-100 justify-content-between align-items-center red-background"
  style="position: relative"
  *ngIf="filter"
>
  <ngx-loading [show]="loading"></ngx-loading>
  <div class="d-flex flex-column w-25 h-100 light-grey-background">
    <app-generic-photo-filter [tags]="tags" [filter]="filter" class="w-100"></app-generic-photo-filter>
    <!--Add + B/A-->
    <div class="d-flex justify-items-start py-2" style="border-bottom: 1px solid grey" *ngIf="!seriesMode && patient">
      <button class="btn em-btn em-btn-green em-btn-small mx-1" (click)="uploadPhoto()">
        <i class="far fa-plus-circle"></i> Add Photo
      </button>
      <button class="btn em-btn em-btn-green em-btn-small mx-1" (click)="toggleSeriesCreationMode(true)">
        <i class="far fa-user-friends"></i> Create B/A
      </button>
      <button [hidden]="cameraConnected" class="btn em-btn em-btn-green em-btn-small" (click)="startCapture()">
        <i class="far fa-camera"></i> Start Capture
      </button>
      <button [hidden]="!cameraConnected" class="btn em-btn em-btn-red em-btn-small mx-1" (click)="stopCapture()">
        <i class="far fa-ban"></i> Stop Capture
      </button>
    </div>
    <app-generic-photo-list
      class="w-100"
      [photos]="photos"
      [seriesMode]="seriesMode"
      [selectedPhoto]="filter.selectedPhoto"
      [filter]="filter"
      (selectedPhotosForSeriesCreationChange)="selectedPhotosForSeriesCreation = $event"
      (selectedPhotoChange)="filter?.setSelectedPhoto($event, photos)"
    >
    </app-generic-photo-list>
  </div>
  <div class="d-flex flex-column w-75 h-100">
    <app-generic-photo-metadata
      *ngIf="!seriesMode"
      class="w-100"
      [tags]="tags"
      [photos]="photos"
      [filter]="filter"
      [photo]="filter.selectedPhoto"
      (photoUpdated)="updatePhotoMetaData($event)"
      (editModeChanged)="updateEditMode($event)"
      [(applyWatermark)]="applyWatermark"
    >
    </app-generic-photo-metadata>
    <app-generic-photo-display
      *ngIf="!seriesMode"
      class="w-100"
      [filter]="filter"
      [photo]="filter.selectedPhoto"
      [editModeEnabled]="editModeEnabled"
      [(applyWatermark)]="applyWatermark"
      (photoUpdated)="updatePhotoMetaData($event)"
    >
    </app-generic-photo-display>
    <app-generic-photo-series-creator
      *ngIf="seriesMode"
      [seriesPhotos]="selectedPhotosForSeriesCreation"
      (toggleSeriesCreationMode)="toggleSeriesCreationMode($event)"
      (seriesCreated)="onSeriesCreated($event)"
    >
    </app-generic-photo-series-creator>
  </div>
  <div id="banner" *ngIf="cameraConnected" class="success-banner">{{ successBannerLabel }}</div>
</div>
