import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil, startWith, map } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

import { DocumentType } from '@models/document/document-type';
import { DocumentTypeService } from '@services/document-type.service';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';

@Component({
  selector: 'app-document-type-details',
  templateUrl: './document-type-details.component.html',
  styleUrls: ['./document-type-details.component.less']
})
export class DocumentTypeDetailsComponent implements OnInit, OnDestroy {
  searchValue = '';
  searchCtrl: FormControl;
  filteredDocumentTypes: Observable<DocumentType[]>;

  unsub: Subject<void> = new Subject<void>();
  disableGrid = false;
  loading = false;

  documentTypes: DocumentType[];
  public gridView: GridDataResult;

  gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  editedRowIndex: number;
  editedDataItem: DocumentType;

  constructor(
    private docTypeService: DocumentTypeService,
     private deleteDialog: MatDialog,
     private router: Router
  ) {}

  ngOnInit() {
    this.gridView = {
      data: [],
      total: 0
    };
    this.loading = true;
    this.disableGrid = false;

    this.searchCtrl = new FormControl();

    this.loadData();
    this.initDocTypesChangedSubscription();
    this.initDocTypesChangeCancelledSubscription();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  loadData() {
    this.docTypeService.getDocTypes().subscribe(docTypes => {
      this.documentTypes = docTypes;

      this.gridView = {
        data: docTypes.slice(),
        total: docTypes.length
      };

      this.filteredDocumentTypes = this.searchCtrl.valueChanges.pipe(
        startWith(''),
        map(docType => this.filterDocumentTypes(docType))
      );

      this.loading = false;
      this.disableGrid = false;
    });
  }

  initDocTypesChangedSubscription() {
    this.docTypeService.docTypesChanged.pipe(takeUntil(this.unsub)).subscribe(docTypes => {
      this.documentTypes = docTypes;

      this.gridView = {
        data: docTypes.slice(),
        total: docTypes.length
      };
      this.loading = false;
      this.disableGrid = false;
    });
  }

  initDocTypesChangeCancelledSubscription() {
    this.docTypeService.docTypesChangeCancelled.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.loading = false;
      this.disableGrid = false;
    });
  }

  public editHandler({ dataItem }) {
    this.disableGrid = true;
    this.router.navigate([
      '/management/catalogue/document-types',
      { outlets: { 'action-panel': ['edit-document-type', dataItem.id] } }
    ]);
  }

  onAddClick({ sender }) {
    this.disableGrid = true;
    this.router.navigate([
      '/management/catalogue/document-types',
      {
        outlets: {
          'action-panel': ['edit-document-type', this.docTypeService.defaultId]
        }
      }
    ]);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  filterDocumentTypes(name: string) {
    let filterResults: DocumentType[] = [];

    if (name !== '') {
      this.gridView = {
        data: this.docTypeService.documentTypes
          .filter(docType => docType.name.toLowerCase().includes(name.toLowerCase())),
        total: this.docTypeService.documentTypes.filter(docType =>
          docType.name.toLowerCase().includes(name.toLowerCase())
        ).length
      };
      filterResults = this.docTypeService.documentTypes.filter(docType =>
        docType.name.toLowerCase().includes(name.toLowerCase())
      );
    } else {
      this.gridView = {
        data: this.docTypeService.documentTypes,
        total: this.docTypeService.documentTypes.length
      };
      filterResults = [];
    }
    return filterResults;
  }

  public removeHandler({ dataItem }) {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px'
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe(result => {
        if (result === 'delete') {
          this.docTypeService.deleteDocType(dataItem.id).subscribe();
        }
      });
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
  }
}
