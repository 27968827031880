import { IStorageStrategy } from './IStorageStrategy';
import { ICachePair } from '.';

export class InMemoryStorageStrategy extends IStorageStrategy {
  private rawData: Map<string, Array<ICachePair<any>>> = new Map();

  add(cachePair: ICachePair<any>, cacheKey: string) {
    const allCachedData = this.getRawData();
    if (!allCachedData.has(cacheKey)) {
      allCachedData.set(cacheKey, []);
    }
    allCachedData.get(cacheKey).push(cachePair);
  }

  getAll(cacheKey: string) {
    if (!this.getRawData().has(cacheKey)) { return []; }
    return this.getRawData().get(cacheKey) || [];
  }

  removeAtIndex(index: number, cacheKey: string) {
    const allCachedData = this.getRawData();
    if (allCachedData.has(cacheKey) && allCachedData.get(cacheKey).length) {
      allCachedData.get(cacheKey).splice(index, 1);
    }
  }

  updateAtIndex(index: number, entity: any, cacheKey: string) {
    const allCachedData = this.getRawData();
    if (allCachedData.has(cacheKey) && allCachedData.get(cacheKey)[index]) {
      allCachedData.get(cacheKey)[index] = entity;
    }
  }

  removeAll(cacheKey: string) {
    const allCachedData = this.getRawData();
    if (allCachedData.has(cacheKey) && allCachedData.get(cacheKey).length) {
      allCachedData.get(cacheKey).length = 0;
    }
  }

  public getRawData(): Map<string, Array<ICachePair<any>>> {
    return this.rawData;
  }

  deleteByGroup(cacheGroup: string): void {
    throw new Error("Method not implemented.");
  }
}
