import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { PrescriptionFavourite } from '@models/prescriptions/prescription-favourite';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionFavouriteService {

  constructor(private http: HttpClient) { }

  addPrescriptionFavourite(prescriptionFavourite: PrescriptionFavourite): Observable<PrescriptionFavourite> {
    return this.http.post<PrescriptionFavourite>(`${environment.baseUrl}api/PrescriptionFavourites`, prescriptionFavourite);
  }

  updatePrescriptionFavourite(prescriptionFavourite: PrescriptionFavourite): Observable<PrescriptionFavourite> {
    return this.http.put<PrescriptionFavourite>(`${environment.baseUrl}api/PrescriptionFavourites`, prescriptionFavourite);
  }

  getPrescriptionFavouriteById(id: number): Observable<PrescriptionFavourite> {
    return this.http.get<PrescriptionFavourite>(`${environment.baseUrl}api/PrescriptionFavourites/${id}`);
  }

  getPrescriptionFavouritesByUserId(userId: string): Observable<PrescriptionFavourite[]> {
    return this.http.get<PrescriptionFavourite[]>(`${environment.baseUrl}api/PrescriptionFavourites/ByUserId/${userId}`);
  }

  deletePrescriptionFavourite(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.baseUrl}api/PrescriptionFavourites/${id}`);
  }

  getDrug(searchText: string, userId: string) {
    if (searchText.length > 0) {
      return this.http.get<PrescriptionFavourite[]>(`${environment.baseUrl}api/DrugSearch/${searchText}/${userId}`);
    }
  }
}
