import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientToolbarComponent } from './patient-toolbar/patient-toolbar.component';
import { PhotoComponent } from './photo/photo.component';
import { PhotoDetailsComponent } from './photo-details/photo-details.component';
import { SharedModule } from '../../../shared/shared.module';
import { TagService } from '@services/tag.service';
import { TagInputModule } from 'ngx-chips';
import { AdminGalleryComponent } from './admin-gallery/admin-gallery.component';
import { PhotoFilterComponent } from './photo-filter/photo-filter.component';
import { PhotoSeriesComponent } from './photo-series/photo-series.component';
import { PhotoSeriesItemComponent } from './photo-series/photo-series-item/photo-series-item.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { PhotoLightboxComponent } from './photo-lightbox/photo-lightbox.component';
import { IntlModule } from '@progress/kendo-angular-intl';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { PhotoRotationComponent } from './photo-rotation/photo-rotation.component';

@NgModule({
    declarations: [
        PatientToolbarComponent,
        PhotoComponent,
        PhotoDetailsComponent,
        AdminGalleryComponent,
        PhotoFilterComponent,
        PhotoSeriesComponent,
        PhotoSeriesItemComponent,
        PhotoLightboxComponent,
        PhotoRotationComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        TagInputModule,
        MatAutocompleteModule,
        TypeaheadModule.forRoot(),
        IntlModule,
        DateInputsModule
    ],
    providers: [
        TagService,
    ],
    exports: [
        PatientToolbarComponent,
        PhotoComponent,
        PhotoDetailsComponent,
        AdminGalleryComponent,
        PhotoFilterComponent,
        PhotoSeriesComponent,
        PhotoSeriesItemComponent
    ]
})
export class SharedPhotosModule { }
