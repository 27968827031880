import { Injectable } from '@angular/core';

@Injectable()
export class TextToolsService {

    constructor() { }

    highlightSearchText(event) {
        const conversationContent = document.getElementsByName('conversation-content');
        for (let i = 0; i < conversationContent.length; i++) {
            while (conversationContent[i].innerHTML.includes('<span style="background:#C6E170">')) {
                conversationContent[i].innerHTML = conversationContent[i].innerHTML.replace('<span style="background:#C6E170">', '');
            }
            while (conversationContent[i].innerHTML.includes('</span>')) {
                conversationContent[i].innerHTML = conversationContent[i].innerHTML.replace('</span>', '');
            }
        }
        if (event.srcElement.value && event.srcElement.value !== '') {
            for (let i = 0; i < conversationContent.length; i++) {
                conversationContent[i].innerHTML = conversationContent[i].innerHTML.replace(new RegExp(event.srcElement.value, 'gi'), match => {
                    return `<span style="background:#C6E170">${match}</span>`;
                });
            }
        }
    }
}
