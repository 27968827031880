import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CommunicationsService } from '@services/communications.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, forkJoin } from 'rxjs';
import { EmailNotificationsSettings } from '@models/communications/email-notifications-settings.model';
import { SmsNotificationsSettings } from '@models/communications/sms-notifications-settings.model';
import { MailjetEmailTemplate } from '@models/communications/mailjet-email-template.model';
import { CommunicationSettingType } from '@models/communications/notifications-settings-base.model';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from '../dialogs/generic-confirm/generic-confirm.component';

@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.less'],
})
export class CommunicationsComponent implements OnInit, OnDestroy {
  disableGrid: boolean;
  loading: boolean;
  confirmScheduledSettings: EmailNotificationsSettings;
  confirmChangedSettings: EmailNotificationsSettings;
  confirmCancelledSettings: EmailNotificationsSettings;
  reminderEmailSettings: EmailNotificationsSettings;
  reminderSmsSettings: SmsNotificationsSettings;
  emailTemplate: MailjetEmailTemplate;

  unsub: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private communicationsService: CommunicationsService,
    private viewEmailTemplateDialog: MatDialog
  ) {}

  ngOnInit() {
    this.getCommunicationsSettings();
    this.detectChanges();
  }

  getCommunicationsSettings() {
    this.loading = true;
    forkJoin([
      this.communicationsService.getEmailNotificationsSettings(),
      this.communicationsService.getSmsNotificationsSettings(),
    ])
      .pipe(takeUntil(this.unsub))
      .subscribe(([emailSettings, smsSettings]) => {
        emailSettings.forEach((setting) => {
          switch (setting.communicationSettingType) {
            case CommunicationSettingType.Reminder:
              this.reminderEmailSettings = setting;
              break;
            case CommunicationSettingType.ScheduledConfirmation:
              this.confirmScheduledSettings = setting;
              break;
            case CommunicationSettingType.ChangedConfirmation:
              this.confirmChangedSettings = setting;
              break;
            case CommunicationSettingType.CancelledConfirmation:
              this.confirmCancelledSettings = setting;
          }
        });
        this.reminderSmsSettings = smsSettings;
        if (smsSettings.message) {
          this.reminderSmsSettings.messageWithLineBreaks = smsSettings.message.split('\n').join('<br>');
        }
        this.loading = false;
      });
  }

  detectChanges() {
    this.communicationsService.communicationsSettingsUpdated.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.disableGrid = false;
      if (this.communicationsService.refreshRequired) {
        this.communicationsService.refreshRequired = false;
        this.getCommunicationsSettings();
      }
    });
  }

  updateEmailNotification(emailSettings: EmailNotificationsSettings) {
    this.communicationsService.updateEmailNotificationsSettings(emailSettings).subscribe();
  }

  updateSmsNotification() {
    this.communicationsService.updateSmsNotificationsSettings(this.reminderSmsSettings).subscribe();
  }

  navigateToEditVisitReminderPanel(type: string) {
    this.disableGrid = true;
    if (type === 'email') {
      this.router.navigate([
        '/management/communication/appointments',
        { outlets: { 'action-panel-secondary': ['edit-email-visit-reminder'] } },
      ]);
    } else {
      this.router.navigate([
        '/management/communication/appointments',
        { outlets: { 'action-panel': ['edit-visit-reminder-settings', type] } },
      ]);
    }
  }

  navigateToSendTestVisitReminderPanel(type: string) {
    this.disableGrid = true;

    this.router.navigate([
      '/management/communication/appointments',
      { outlets: { 'action-panel': ['send-test-visit-reminder', type] } },
    ]);
  }

  viewEmailTemplate(emailSetting: EmailNotificationsSettings) {
    const dialogRef = this.viewEmailTemplateDialog.open(GenericDialogComponent, {
      width: '30%',
      data: {
        content: emailSetting.emailHtml,
        showCancel: false,
        confirmButtonText: 'Ok',
      },
    });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
