import { IChargeableAsset } from '@interfaces/IChargeableAsset';
import { Visit } from '../visit';
import { ChargeableAsset } from './chargeable-asset';

export class VisitChargeableAsset extends ChargeableAsset {
  visit: Visit;

  constructor(visit: Visit) {
    super();
    this.visit = visit;
  }

  public getChargeableAsset(): IChargeableAsset {
      return this.visit;
  }
}
