import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * A validator function that checks if a given password is valid.
 *
 * The password must:
 * - be at least 8 characters long
 * - be at most 32 characters long
 * - contain at least one uppercase letter
 * - contain at least one lowercase letter
 * - contain at least one number
 * - contain at least one special character
 *
 * If the password is invalid, the function will return an object with a
 * property that describes the problem. For example, if the password is too
 * short, the function will return { minLength: { requiredLength: 8, actualLength: 3 } }.
 *
 * If the password is valid, the function will return null.
 */
export function passwordValidator(required = true): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const minLength = /^[\s\S]{8,}$/,
      maxLength = /^[\s\S]{0,32}$/,
      upper = /[A-Z]/,
      lower = /[a-z]/,
      number = /[0-9]/,
      special = /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

    if (required === false && control.value.length === 0) {
      return null;
    }
    if (!minLength.test(control.value))
      return {
        minLength: { requiredLength: 8, actualLength: control.value.length },
      };
    if (!maxLength.test(control.value))
      return {
        maxLength: { requiredLength: 32, actualLength: control.value.length },
      };
    if (!upper.test(control.value)) return { noUpperCase: { value: control.value } };
    if (!lower.test(control.value)) return { noLowerCase: { value: control.value } };
    if (!number.test(control.value)) return { noNumberCharacter: { value: control.value } };
    if (!special.test(control.value)) return { noSpecialCharacter: { value: control.value } };
    return null;
  };
}
