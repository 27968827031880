<ngx-loading [show]="loading"></ngx-loading>

<div class="modal-header border-0 p-0">
  <button
    type="button"
    class="btn white-font p-0 ml-auto mr-2"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="p-4">
  <h6 class="text-uppercase navy-font text-center mt-0 mb-3">{{ channel ? 'Edit' : 'Create New' }} Channel</h6>

  <input
    #channelNameInput
    required
    type="text"
    autofocus
    class="form-control border-0 grey-three-background"
    placeholder="Enter Channel Name"
    [formControl]="channelName"
  />

  <div class="save-changes-container mt-3 text-center">
    <button [disabled]="!channelName.dirty || channelName.invalid" class="btn em-btn em-btn-green" (click)="saveChanges(channelNameInput.value)">
      {{ channel ? 'Save' : 'Create' }}
    </button>
    <button class="btn em-btn em-btn-green ml-2" (click)="activeModal.dismiss('Cancel click')">Cancel</button>
  </div>
</div>
