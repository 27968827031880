<ngx-loading #ngxLoading [show]="loading"></ngx-loading>

<ng-container *ngIf="formLoaded && serviceTemplates?.length > 0">
  <div class="row">
    <div class="col">
      <ng-container *ngFor="let error of errors">
        <app-error *ngFor="let fieldError of error.fieldErrors" [message]="fieldError"></app-error>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h5 class="mt-2">Treatments of Interest</h5>
    </div>
    <div class="col-1 text-right pr-0">
      <!-- <button
        class="btn btn-sm em-btn em-btn-green mr-2"
        (click)="onSave()"
        [disabled]="!treatmentPlanFormService.treatmentPlanDirty"
        [ngClass]="{ invalid: !treatmentPlanFormService.formValid }"
      >
        <i class="far fa-save" title="Save"></i>
      </button> -->
    </div>
  </div>
  <form [formGroup]="treatmentPlanForm">
    <div class="form-row">
      <div class="form-group col">
        <label for="area">Treatment Notes</label>
        <mat-form-field class="w-100 document-list-textarea">
          <textarea
            (blur)="saveNotes($event)"
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="10"
            formControlName="notes"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <h5>Treatments</h5>
      </div>
    </div>
    <div class="form-row pb-3 pt-1">
      <div class="col-4 px-0">
        <input
          class="input-dropdown"
          type="text"
          #serviceTypeahead
          placeholder="Add service"
          [ngbTypeahead]="searchServiceTemplates"
          [resultFormatter]="serviceTemplatesResultFormatter"
          [inputFormatter]="serviceTemplatesInputFormatter"
          (focus)="onFocus($event)"
          (selectItem)="onAddService($event.item)"
          [disabled]="serviceTemplates?.length === 0"
          #instance="ngbTypeahead"
          autocomplete="off"
          (mousedown)="serviceTypeahead.blur()"
          (blur)="instance.dismissPopup()"
        />
      </div>
    </div>
    <div class="form-row grey-four-background negative-row-margin">
      <div class="col-1 text-center navy-font font-weight-bold p-2">Scheduled</div>
      <div class="col-3 navy-font font-weight-bold grey-six-border-left p-2">Service</div>
      <div class="col-4 navy-font font-weight-bold grey-six-border-left p-2">Summary</div>
      <div class="col-1 text-center navy-font font-weight-bold grey-six-border-left p-2">Pricing</div>
      <div class="col-1 text-center navy-font font-weight-bold grey-six-border-left p-2">Due Date</div>
      <div class="col-1 text-center navy-font font-weight-bold grey-six-border-left p-2">Creation Date</div>
      <div class="col-1 text-center navy-font font-weight-bold grey-six-border-left p-2">Action</div>
    </div>
    <!-- Unplanned and Scheduled Services-->
    <ng-container formArrayName="plannedTreatments">
      <ng-container *ngFor="let plannedTreatment of getPlannedTreatmentControls(); let i = index" [formGroupName]="i">
        <div
          class="form-row negative-row-margin grey-six-border-bottom"
          kendoTooltip
          tooltipClass="em-tooltip"
          position="top"
        >
          <div class="col-1 p-2 text-center">
            <div class="form-row">
              <div class="col = d-flex justify-content-center align-items-center">
                <input
                  type="text"
                  (blur)="updatePlannedTreatment(plannedTreatment.value)"
                  class="form-control d-inline w-25 text-center grey-two-border"
                  formControlName="rank"
                />
                <i
                  [ngClass]="{
                    'fa-calendar-times': plannedTreatment.value.treatmentState == treatmentState.Unplanned,
                    'fa-calendar': plannedTreatment.value.treatmentState == treatmentState.Scheduled
                  }"
                  class="pl-3 far"
                  style="font-size: 1.25rem"
                  [title]="
                    plannedTreatment.value.treatmentState == treatmentState.Unplanned
                      ? 'Unplanned'
                      : plannedTreatment.value.treatmentState == treatmentState.Scheduled
                      ? 'Scheduled'
                      : ''
                  "
                ></i>
              </div>
            </div>
            <div *ngIf="plannedTreatment?.value?.treatmentAppointments?.length > 1; else scheduledDate">
              <div *ngFor="let appt of plannedTreatment.value.treatmentAppointments" class="form-row pt-1 testme">
                <div class="col">
                  {{ appt | txDate | date : 'yyyy-MM-dd h:mm a' }}
                </div>
              </div>
            </div>
            <ng-template #scheduledDate>
              <div *ngIf="plannedTreatment.value.treatmentState == treatmentState.Scheduled" class="form-row pt-1">
                <div class="col">
                  {{ plannedTreatment.value.plannedDate | convertUTC : true }}
                </div>
              </div>
            </ng-template>
          </div>
          <div class="col-3 grey-two-background grey-six-border-left p-2">
            <div class="form-row">
              <div class="col">
                {{ plannedTreatment.value.service.serviceName }}
              </div>
            </div>
            <div class="form-row pt-1">
              <div class="col">
                <label class="d-block mb-0">Concern/Notes</label>
                <input
                  type="text"
                  class="form-control grey-two-border w-100 px-1"
                  formControlName="notes"
                  (blur)="updatePlannedTreatment(plannedTreatment.value)"
                />
              </div>
            </div>
          </div>
          <div class="col-4 p-2 grey-six-border-left">
            <ng-container
              *ngIf="plannedTreatment.value.service.serviceDetailTemplateId === serviceDetailTemplate.Injections"
            >
              <app-item-detail-display
                [service]="serviceList[i]"
                [observationType]="observationTypes.Injectables"
                [isLocked]="true"
                [itemType]="serviceListType.history"
              >
              </app-item-detail-display>
            </ng-container>
            <ng-container
              *ngIf="plannedTreatment.value.service.serviceDetailTemplateId === serviceDetailTemplate.Coolsculpting"
            >
              <app-item-detail-display
                [service]="serviceList[i]"
                [observationType]="observationTypes.Coolsculpting"
                [isLocked]="true"
                [itemType]="serviceListType.history"
              >
              </app-item-detail-display>
            </ng-container>
            <ng-container
              *ngIf="plannedTreatment.value.service.serviceDetailTemplateId === serviceDetailTemplate.Deoxycholate"
            >
              <app-item-detail-display
                [service]="serviceList[i]"
                [observationType]="observationTypes.Injectables"
                [isLocked]="true"
                [itemType]="serviceListType.history"
              >
              </app-item-detail-display>
            </ng-container>
          </div>
          <div class="col-1 text-center grey-two-background grey-six-border-left p-2">
            <!-- Handle the governement billing scenario, we don't show a price -->
            <ng-container *ngIf="plannedTreatment.value.service.governmentBilling; else privatePay"> - </ng-container>
            <ng-template #privatePay>
              <!-- If the Service doesn't have a Template -->
              <ng-container
                *ngIf="
                  plannedTreatment.value.service.serviceDetailTemplateId == serviceDetailTemplate.None;
                  else serviceDetail
                "
              >
                <!-- If the service doesn't have a Template, and it's paid, we show the default price or overrideDefaultPrice -->
                <ng-container *ngIf="plannedTreatment.value.paymentStatus == PaymentStatus.Paid; else notPaid">
                  <span
                    class="spring-green-font"
                    [attr.data-test-id]="'pt-calculated-price-' + plannedTreatment.value.id"
                    [attr.data-test-value]="
                      plannedTreatment.value.service.overrideDefaultPrice
                        ? plannedTreatment.value.service.overrideDefaultPrice
                        : plannedTreatment.value.service.defaultPrice
                    "
                    >{{
                      plannedTreatment.value.service.overrideDefaultPrice
                        ? (plannedTreatment.value.service.overrideDefaultPrice | currency : '$')
                        : (plannedTreatment.value.service.defaultPrice | currency : '$')
                    }}
                    <div>
                      {{
                        plannedTreatment.value.treatmentState == treatmentState.Scheduled ||
                        plannedTreatment.value.treatmentState == treatmentState.Unplanned
                          ? 'Prepaid'
                          : 'Paid'
                      }}
                    </div>
                  </span>
                </ng-container>
                <!-- If it's not paid, we can change the total -->
                <ng-template #notPaid>
                  <span class="w-25"
                    >$<input
                      #priceInput
                      (blur)="updatePlannedTreatment(plannedTreatment.value, priceInput)"
                      type="number"
                      [ngClass]="{
                        'red-border': plannedTreatment.value.treatmentState == treatmentState.Scheduled,
                        'grey-two-border': plannedTreatment.value.treatmentState == treatmentState.Unplanned
                      }"
                      class="ml-1 text-center form-control small d-inline price-input"
                      [attr.data-test-id]="'pt-calculated-price-' + plannedTreatment.value.id"
                      [attr.data-test-value]="priceInput.value"
                      formControlName="price"
                    />
                    <div [ngClass]="{ 'purple-font': plannedTreatment.value.paymentStatus == PaymentStatus.Refund }">
                      {{ plannedTreatment.value.paymentStatus == PaymentStatus.Refund ? 'Refunded' : 'Unpaid' }}
                    </div>
                  </span>
                </ng-template>
              </ng-container>

              <!-- And if the Service does have a Service Template, it has service details to take into account -->
              <ng-template #serviceDetail>
                <ng-container
                  *ngIf="
                    plannedTreatment.value.service.serviceDetailTemplateId != serviceDetailTemplate.None &&
                      plannedTreatment.value.service.chargeAmount;
                    else noPrice
                  "
                >
                  <span
                    [ngClass]="{
                      'spring-green-font': plannedTreatment.value.paymentStatus == PaymentStatus.Paid,
                      'purple-font': plannedTreatment.value.paymentStatus == PaymentStatus.Refund,
                      'calendar-red-font':
                        plannedTreatment.value.paymentStatus == PaymentStatus.Unpaid &&
                        plannedTreatment.value.treatmentState == treatmentState.Scheduled
                    }"
                    [attr.data-test-id]="'pt-calculated-price-' + plannedTreatment.value.id"
                    [attr.data-test-value]="plannedTreatment.value.service.chargeAmount"
                  >
                    {{ plannedTreatment.value.service.chargeAmount | currency : '$' }}
                    <div>
                      {{
                        plannedTreatment.value.paymentStatus == PaymentStatus.Paid
                          ? plannedTreatment.value.treatmentState == treatmentState.Scheduled ||
                            plannedTreatment.value.treatmentState == treatmentState.Unplanned
                            ? 'Prepaid'
                            : 'Paid'
                          : plannedTreatment.value.paymentStatus == PaymentStatus.Refund
                          ? 'Refunded'
                          : 'Unpaid'
                      }}
                    </div>
                  </span>
                </ng-container>
              </ng-template>
              <ng-template #noPrice> - </ng-template>
            </ng-template>
          </div>

          <div class="col-1 text-center grey-two-background grey-six-border-left p-2">
            <input
              [matDatepicker]="dueDatePicker"
              (focus)="dueDatePicker.open()"
              formControlName="dueDate"
              (blur)="updatePlannedTreatment(plannedTreatment.value)"
              class="grey-four-border-bottom text-center w-100 form-control grey-two-border small"
            />
            <mat-datepicker #dueDatePicker></mat-datepicker>
          </div>
          <div class="col-1 text-center grey-six-border-left p-2">
            {{ plannedTreatment.value.createdDate | date : 'yyyy-MM-dd' }}
          </div>
          <div class="col-1 text-center grey-six-border-left p-2">
            <ng-container
              *ngIf="
                plannedTreatment.value.service.serviceDetailTemplateId !== serviceDetailTemplate.None;
                else hiddenBtn
              "
            >
              <button
                class="btn btn-sm em-btn em-btn-green mr-2 edit-pt-id-{{ plannedTreatment.value.id }}"
                (click)="onViewServiceDetail(plannedTreatment.value)"
              >
                <ng-container *ngIf="plannedTreatment.value.service.templateIcon; else noIcon">
                  <i
                    [class]="plannedTreatment.value.service.templateIcon"
                    [title]="
                      plannedTreatment.value.paymentStatus == PaymentStatus.Paid
                        ? 'Prepaid: No Editing'
                        : plannedTreatment.value.paymentStatus == PaymentStatus.Refund
                        ? 'Refunded: No Editing'
                        : 'Service Details'
                    "
                  ></i>
                </ng-container>
                <ng-template #noIcon>
                  <i
                    class="far fa-file-alt small"
                    [title]="
                      plannedTreatment.value.paymentStatus == PaymentStatus.Paid
                        ? 'Prepaid: No Editing'
                        : 'Service Details'
                    "
                  ></i>
                </ng-template>
              </button>
            </ng-container>
            <button
              [disabled]="
                plannedTreatment.value.paymentStatus == PaymentStatus.Paid ||
                plannedTreatment.value.treatmentState != treatmentState.Unplanned
              "
              class="btn btn-sm em-btn em-btn-green delete-pt-id-{{ plannedTreatment.value.id }}"
              (click)="onDeleteService(i, plannedTreatment.value)"
            >
              <i class="far fa-trash-alt small" title="Delete"></i>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <!-- Completed Services -->
    <ng-container *ngFor="let plannedTreatment of completedTreatments; let i = index">
      <div class="form-row negative-row-margin grey-six-border-bottom grey-four-background">
        <div class="col-1 p-2 text-center">
          <div class="form-row">
            <div class="col d-flex justify-content-center align-items-center">
              <input
                type="text"
                class="form-control d-inline w-25 text-center border-0"
                disabled
                [value]="plannedTreatment.rank"
              />
              <i style="font-size: 1.25rem" class="pl-3 far fa-calendar-check" title="Completed"></i>
            </div>
          </div>
          <div class="form-row pt-1">
            <div class="col">
              {{ plannedTreatment.plannedDate | convertUTC : true }}
            </div>
          </div>
        </div>
        <div class="col-3 grey-six-border-left p-2">
          <div class="form-row">
            <div class="col">
              {{ plannedTreatment.service.serviceName }}
            </div>
          </div>
          <div class="form-row pt-1">
            <div class="col">
              <label class="d-block mb-0">Concern/Notes</label>
              <input type="text" class="form-control w-100 border-0 px-1" disabled />
            </div>
          </div>
        </div>
        <div class="col-4 p-2 grey-six-border-left">
          <ng-container *ngIf="plannedTreatment.service.serviceDetailTemplateId === serviceDetailTemplate.Injections">
            <app-item-detail-display
              [service]="plannedTreatment.service"
              [observationType]="observationTypes.Injectables"
              [isLocked]="true"
              [itemType]="serviceListType.history"
            >
            </app-item-detail-display>
          </ng-container>
          <ng-container
            *ngIf="plannedTreatment.service.serviceDetailTemplateId === serviceDetailTemplate.Coolsculpting"
          >
            <app-item-detail-display
              [service]="plannedTreatment.service"
              [observationType]="observationTypes.Coolsculpting"
              [isLocked]="true"
              [itemType]="serviceListType.history"
            >
            </app-item-detail-display>
          </ng-container>
          <ng-container *ngIf="plannedTreatment.service.serviceDetailTemplateId === serviceDetailTemplate.Deoxycholate">
            <app-item-detail-display
              [service]="plannedTreatment.service"
              [observationType]="observationTypes.Injectables"
              [isLocked]="true"
              [itemType]="serviceListType.history"
            >
            </app-item-detail-display>
          </ng-container>
        </div>
        <div class="col-1 text-center grey-six-border-left p-2">
          <ng-container *ngIf="plannedTreatment.service.governmentBilling; else privatePay"> - </ng-container>
          <ng-template #privatePay>
            <ng-container
              *ngIf="plannedTreatment.service.serviceDetailTemplateId == serviceDetailTemplate.None; else serviceDetail"
            >
              <ng-container *ngIf="plannedTreatment.paymentStatus == PaymentStatus.Paid; else notPaid">
                <span class="spring-green-font"
                  >{{
                    plannedTreatment.service.overrideDefaultPrice
                      ? (plannedTreatment.service.overrideDefaultPrice | currency : '$')
                      : (plannedTreatment.service.defaultPrice | currency : '$')
                  }}
                  <div
                    [attr.data-test-id]="'pt-calculated-price-' + plannedTreatment.id"
                    [attr.data-test-value]="plannedTreatment.service.defaultPrice"
                  >
                    {{
                      plannedTreatment.paymentStatus != PaymentStatus.Paid
                        ? 'Unp'
                        : plannedTreatment.treatmentState == treatmentState.Scheduled ||
                          plannedTreatment.treatmentState == treatmentState.Unplanned
                        ? 'Prep'
                        : 'P'
                    }}aid
                  </div>
                </span>
              </ng-container>
              <ng-template #notPaid>
                <span class="calendar-red-font"
                  >{{ plannedTreatment.service.defaultPrice | currency : '$' }}
                  <div
                    [attr.data-test-id]="'pt-calculated-price-' + plannedTreatment.id"
                    [attr.data-test-value]="plannedTreatment.service.defaultPrice"
                  >
                    {{
                      plannedTreatment.paymentStatus != PaymentStatus.Paid
                        ? 'Unp'
                        : plannedTreatment.treatmentState == treatmentState.Scheduled ||
                          plannedTreatment.treatmentState == treatmentState.Unplanned
                        ? 'Prep'
                        : 'P'
                    }}aid
                  </div>
                </span>
              </ng-template>
            </ng-container>
            <ng-template #serviceDetail>
              <ng-container *ngIf="plannedTreatment.service.chargeAmount; else noPrice">
                <span
                  [attr.data-test-id]="'pt-calculated-price-' + plannedTreatment.id"
                  [attr.data-test-value]="plannedTreatment.service.chargeAmount"
                  [ngClass]="{
                    'spring-green-font': plannedTreatment.paymentStatus == PaymentStatus.Paid,
                    'calendar-red-font': plannedTreatment.paymentStatus != PaymentStatus.Paid
                  }"
                >
                  {{ plannedTreatment.service.chargeAmount | currency : '$' }}
                  <div>
                    {{
                      plannedTreatment.paymentStatus != PaymentStatus.Paid
                        ? 'Unp'
                        : plannedTreatment.treatmentState == treatmentState.Scheduled ||
                          plannedTreatment.treatmentState == treatmentState.Unplanned
                        ? 'Prep'
                        : 'P'
                    }}aid
                  </div>
                </span>
              </ng-container>
              <ng-template #noPrice> - </ng-template>
            </ng-template>
          </ng-template>
        </div>
        <div class="col-1 text-center grey-six-border-left p-2">
          {{ plannedTreatment.dueDate | date : 'yyyy-MM-dd' }}
        </div>
        <div class="col-1 text-center grey-six-border-left p-2">
          {{ plannedTreatment.createdDate | date : 'yyyy-MM-dd' }}
        </div>
        <div class="col-1 text-center grey-six-border-left p-2">
          <ng-container
            *ngIf="plannedTreatment.service.serviceDetailTemplateId !== serviceDetailTemplate.None; else hiddenBtn"
          >
            <button
              class="btn btn-sm em-btn em-btn-green mr-2 completed-edit-pt-id-{{ plannedTreatment.id }}"
              (click)="onViewServiceDetail(plannedTreatment)"
            >
              <ng-container *ngIf="plannedTreatment.service.templateIcon; else noIcon">
                <i
                  [class]="plannedTreatment.service.templateIcon"
                  [title]="
                    plannedTreatment.paymentStatus == PaymentStatus.Paid ? 'Prepaid: No Editing' : 'Service Details'
                  "
                ></i>
              </ng-container>
              <ng-template #noIcon>
                <i
                  class="far fa-file-alt small"
                  [title]="
                    plannedTreatment.paymentStatus == PaymentStatus.Paid ? 'Prepaid: No Editing' : 'Service Details'
                  "
                ></i>
              </ng-template>
            </button>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <!-- Planned Treatment Multiples -->
    <hr class="mt-4 mb-2" />
    <ng-container>
      <div class="form-row">
        <div class="col">
          <h5 class="mt-2">Multiples</h5>
        </div>
      </div>
      <div class="form-row pb-3 pt-1">
        <div class="px-0">
          <input
            #multiplesTypeahead
            class="input-dropdown ml-1 input-multiples px-1"
            type="text"
            placeholder="Add multiples"
            [ngbTypeahead]="searchServiceTemplateMultiples"
            [resultFormatter]="serviceTemplatesResultFormatter"
            [inputFormatter]="serviceTemplatesInputFormatter"
            (focus)="onFocusMultiples($event)"
            (selectItem)="onCreatePackage($event.item)"
            [disabled]="serviceTemplateMultiples?.length === 0"
            #instanceMultiples="ngbTypeahead"
            autocomplete="off"
            (blur)="instanceMultiples.dismissPopup()"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <kendo-grid
            [data]="this.treatmentPlan.plannedTreatmentMultiples"
            [loading]="this.loading"
            [navigable]="false"
          >
            <kendo-grid-column
              field="serviceName"
              title="Service"
              headerClass="navy-font font-weight-bold grey-six-border-left"
              [width]="250"
            ></kendo-grid-column>
            <kendo-grid-column
              field="notes"
              title="Notes"
              headerClass="navy-font font-weight-bold grey-six-border-left"
              [width]="300"
            ></kendo-grid-column>
            <kendo-grid-column
              field="price"
              title="Unit Price"
              format="{0:c}"
              headerClass="navy-font font-weight-bold grey-six-border-left text-center"
              [width]="150"
              class="text-center"
            ></kendo-grid-column>
            <kendo-grid-column
              field="quantity"
              title="Available"
              headerClass="navy-font font-weight-bold grey-six-border-left text-center"
              [width]="150"
              class="text-center"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <div>
                  {{ getQuantityAvailable(dataItem) }}
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="totalAmount"
              title="Subtotal"
              format="{0:c}"
              headerClass="navy-font font-weight-bold grey-six-border-left text-center"
              class="text-center"
              [width]="150"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <div
                  [ngClass]="{
                    'calendar-green-font': dataItem.paymentStatus == PaymentStatus.Paid && !dataItem.partialRefund,
                    'calendar-red-font': dataItem.paymentStatus == PaymentStatus.Unpaid,
                    'purple-font': dataItem.paymentStatus == PaymentStatus.Refund || dataItem.partialRefund
                  }"
                >
                  {{ dataItem.totalAmount | currency }}
                  <span class="mt-auto mb-auto mx-2">
                    <i
                      [class]="dataItem.paymentStatus == PaymentStatus.Refund ? 'fas fa-sad-tear' : 'fas fa-usd-circle'"
                      [title]="
                        dataItem.paymentStatus == PaymentStatus.Paid
                          ? 'Paid'
                          : dataItem.paymentStatus == PaymentStatus.Unpaid
                          ? 'Unpaid'
                          : 'Refunded'
                      "
                    ></i>
                    <ng-container *ngIf="dataItem.paymentStatus != PaymentStatus.Refund && dataItem.partialRefund">
                      /
                      <i class="fas fa-sad-tear" title="Refunded"></i>
                    </ng-container>
                  </span>
                </div>
              </ng-template>
            </kendo-grid-column>
            <!-- <kendo-grid-column field="chargeAmount" title="Total" format="{0:c}" headerClass="navy-font font-weight-bold grey-six-border-left text-center" class="text-center" >
              <ng-template kendoGridCellTemplate let-dataItem>
                <div *ngIf="dataItem.paymentStatus == PaymentStatus.Paid" class="calendar-green-font">
                {{ dataItem.chargeAmount | currency }}
                  <span class="mt-auto mb-auto mx-2 calendar-green-font">
                      <i class="fas fa-usd-circle"
                      [title]="'Paid'">
                      </i>
                  </span>
                </div>
              </ng-template>
            </kendo-grid-column> -->
            <kendo-grid-column
              field="createdDate"
              title="Creation Date"
              headerClass="navy-font font-weight-bold grey-six-border-left  text-center"
              [width]="200"
              class="text-center"
            >
              <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                {{ dataItem.createdDate | date : 'yyyy-MM-dd h:mm a' }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-command-column
              title="Actions"
              headerClass="text-center navy-font font-weight-bold grey-six-border-left"
              class="text-center"
              [width]="200"
            >
              <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                <button
                  [disabled]="!isEditable(dataItem)"
                  class="btn btn-sm em-btn em-btn-green mr-2"
                  (click)="onEditMultiple(dataItem)"
                >
                  <i class="fas fa-pencil small" title="Edit Multiple"></i>
                </button>
                <button
                  *ngIf="dataItem.paymentStatus === PaymentStatus.Unpaid"
                  class="btn btn-sm em-btn em-btn-green mr-2"
                  (click)="onPayForMultiple(dataItem)"
                >
                  <i class="far fa-dollar-sign small" title="Pay Invoice"></i>
                </button>
                <button
                  *ngIf="dataItem.paymentStatus !== PaymentStatus.Unpaid"
                  class="btn btn-sm em-btn em-btn-green mr-2"
                  (click)="onGoToInvoice(dataItem)"
                >
                  <i class="far fa-file-invoice-dollar" title="Goto Invoice"></i>
                </button>
                <button
                  [disabled]="!canMultipleBeDeleted(dataItem)"
                  class="btn btn-sm em-btn em-btn-green"
                  (click)="onDeleteMultiple(dataItem)"
                >
                  <i class="far fa-trash-alt small" title="Delete"></i>
                </button>
              </ng-template>
            </kendo-grid-command-column>
            <div *kendoGridDetailTemplate="let dataItem">
              <app-planned-treatment-multiple
                [plannedTreatments]="dataItem.plannedTreatments"
              ></app-planned-treatment-multiple>
            </div>
          </kendo-grid>
        </div>
      </div>
    </ng-container>
    <ng-template #hiddenBtn>
      <button class="btn opacity-0 no-pointer"><i class="far fa-file-alt small"></i></button>
    </ng-template>
  </form>
</ng-container>
