import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-messaging-sidebar',
  templateUrl: './messaging-sidebar.component.html',
  styleUrls: ['./messaging-sidebar.component.less'],
})
export class MessagingSidebarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

}
