import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChatMessageFromServer } from '@app/messaging/models/chat-message-from-server.model';
import { ChatMessage } from '@app/messaging/models/chat-message.model';
import { ChatChannelsService } from '@app/messaging/services/chat-channels.service';
import { ChatMessagesService } from '@app/messaging/services/chat-messages.service';
import { ChatUsersService } from '@app/messaging/services/chat-users.service';
import { isNullOrUndefined } from '@app/shared/helpers';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { EMPTY, Subject, of } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-messaging-feed',
  templateUrl: './messaging-feed.component.html',
  styleUrls: ['./messaging-feed.component.less'],
})
export class MessagingFeedComponent implements OnInit, OnDestroy {
  private unsub: Subject<any> = new Subject<any>();
  @ViewChild(PerfectScrollbarComponent, { static: true }) perfectScrollbar: PerfectScrollbarComponent;
  today = new Date();
  messages: ChatMessage[] = [];
  loading = false;
  page = 0;
  allLoaded = false;
  unreadNotificationsCount = 0;

  constructor(
    private channelService: ChatChannelsService,
    private messagesService: ChatMessagesService,
    private chatUsersService: ChatUsersService
  ) {}

  ngOnInit() {
    this.channelService.onChannelSelected$.pipe(takeUntil(this.unsub)).subscribe((channel) => {
      if (isNullOrUndefined(channel)) {
        this.messages = [];
        return;
      }
      this.page = 0;
      if (isNullOrUndefined(channel.id)) {
        return;
      }
      this.allLoaded = false;
      this.loading = true;
      this.messagesService
        .getAllMessagesByChannelId(channel.id)
        .pipe(takeUntil(this.unsub))
        .subscribe((messages) => {
          this.messages = this.chatUsersService.mapUserDetails(messages);
          if (this.messages.some((m) => m.userName === 'unknown')) {
            this.chatUsersService
              .getAllChatUsers()
              .pipe(takeUntil(this.unsub))
              .subscribe(() => (this.messages = this.chatUsersService.mapUserDetails(messages)));
          }
          setTimeout(() => {
            this.perfectScrollbar.directiveRef.scrollToBottom();
            this.loading = false;
          });
          setTimeout(() => {
            this.unreadNotificationsCount = 0;
          }, 500);
        });
    });

    this.channelService.channelUnreadNotificationCount$.pipe(takeUntil(this.unsub)).subscribe((count) => {
      this.unreadNotificationsCount = count;
    });

    this.messagesService.onMessageReceived$
      .pipe(
        takeUntil(this.unsub),
        mergeMap((message: ChatMessageFromServer) => {
          if (this.channelService.selectedChannel && this.channelService.selectedChannel.id === message.channelId) {
            let privateChannel = this.channelService.privateChannels.find((_) => _.id === message.channelId);
            if (privateChannel) privateChannel.hasMessages = true;
            const m = new ChatMessage();
            m.chatUserId = message.chatUserId;
            m.when = message.when;
            m.content = message.content;
            const messages = [...this.messages, m];
            this.messages = this.chatUsersService.mapUserDetails(messages);
            setTimeout(() => {
              this.perfectScrollbar.directiveRef.scrollToBottom();
            });
            if (message.chatUserId !== this.chatUsersService.currentChatUser.chatUserId) {
              return this.channelService.markMessagesAsReadForChannel(message.channelId);
            }
          }
          return EMPTY;
        })
      )
      .subscribe((count) => {});

    this.perfectScrollbar.psYReachStart
      .asObservable()
      .pipe(
        takeUntil(this.unsub),
        mergeMap(() => {
          if (this.loading || this.allLoaded) {
            return EMPTY;
          }
          if (
            isNullOrUndefined(this.channelService.selectedChannel) ||
            isNullOrUndefined(this.channelService.selectedChannel.id)
          ) {
            return EMPTY;
          }
          this.loading = true;
          this.page++;
          return this.messagesService.getAllMessagesByChannelId(this.channelService.selectedChannel.id, this.page);
        }),
        mergeMap((messages) => {
          if (messages.length === 0) {
            this.allLoaded = true;
            this.loading = false;
            return EMPTY;
          }
          messages = this.chatUsersService.mapUserDetails(messages);
          if (messages.some((m) => m.userName === 'unknown')) {
            return this.chatUsersService
              .getAllChatUsers()
              .pipe(map(() => this.chatUsersService.mapUserDetails(messages)));
          }
          return of(messages);
        })
      )
      .subscribe((messages) => {
        this.messages = [...messages, ...this.messages];
        this.perfectScrollbar.directiveRef.scrollToElement('#message-' + (messages.length - 1).toString());
        this.loading = false;
      });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
