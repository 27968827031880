import { Component, OnInit, OnDestroy, Inject, Optional, Input, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { ServicesService } from '@services/services.service';
import { takeUntil, debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-diagnostic-code-modal',
  templateUrl: './diagnostic-code-modal.component.html',
  styleUrls: ['./diagnostic-code-modal.component.less']
})
export class DiagnosticCodeModalComponent implements OnInit, OnDestroy {
  @Input() initialSearchTerm: string;
  @Input() isInsideMinistry: boolean;
  @Output() selectCode = new EventEmitter();

  searchFormControl: FormControl;
  unsub = new Subject<void>();
  rxCodeItems: any[] = [];
  loading = false;
  searchTerm = new Subject<string>();
  noResults = false;
  errors: any[] = [];

  constructor(
    @Optional() public dialogRef: MatDialogRef<DiagnosticCodeModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private servicesService: ServicesService
  ) { }

  ngOnInit() {
    this.searchFormControl = new FormControl(this.initialSearchTerm);
    if (this.initialSearchTerm) {
      this.loading = true;
      this.getRxCode(this.initialSearchTerm);
    }

    this.searchFormControl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      this.loading = true;
      this.getRxCode(searchTerm);
    });
  }

  private getRxCode(searchTerm: string) {
    this.servicesService.getDxCodeByTerm(searchTerm)
      .subscribe(
        res => {
          this.rxCodeItems = res;
          this.noResults = !this.rxCodeItems.length;
          this.loading = false;
          this.errors = [];
        },
        (errorResponse: HttpErrorResponse) => {
          this.loading = false;
          if (errorResponse.error) {
            this.errors = errorResponse.error.errors;
          }
          this.rxCodeItems = [];
          this.noResults = false;
        }
      );
  }

  public addRxCode(item) {
    if (this.isInsideMinistry) {
      this.selectCode.next(item);
    } else {
      this.dialogRef.close(item);
    }
  }

  public closeModal() {
    if (this.isInsideMinistry) {
      this.selectCode.next();
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
