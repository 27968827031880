<ngx-loading [show]="loading"></ngx-loading>
<div class="providers">
  <div class="d-flex align-items-center justify-content-between teal-background white-font header">
    <span class="mr-auto">PROVIDERS</span>
  </div>
  <kendo-grid [data]="gridView" (edit)="editHandler($event)" class="half-view">
    <!--Available for online booking-->
    <kendo-grid-column field="available" title="Available" [width]="100">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div style="text-align: center; width: 100%">
          <label class="switch">
            <input
              type="checkbox"
              name="available"
              aria-label="Available for online booking"
              id="available"
              [checked]="dataItem.availableForOnlineBooking"
              (change)="updateProviderAvailable(dataItem, $event)"
            />
            <span [ngStyle]="{ 'pointer-events': 'none' }" class="slider round"></span>
          </label>
        </div>
      </ng-template>
    </kendo-grid-column>
    <!--Name of provider-->
    <kendo-grid-column field="title" title="Provider">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.title }} </ng-template>
    </kendo-grid-column>
    <!--Alternative if provider wants a different name show in booking-->
    <kendo-grid-column field="onlineName" title="Online Name">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.onlineBookingProviderName }}</ng-template>
    </kendo-grid-column>
    <!--Time available-->
    <kendo-grid-column field="availabletime" title="Available Time">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.availableTime }}</ng-template>
    </kendo-grid-column>
    <!--Actions-->
    <kendo-grid-command-column title="Actions" [width]="100">
      <ng-template kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridEditCommand class="btn kendo-btn"><i class="fas fa-edit"></i></button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
