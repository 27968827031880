import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ObrPlottingEventType } from '@models/observation/obr-plotting-event-type';
import { Service } from '@models/service/service';
import { ServiceDetailEventTypes } from '@models/service/service-detail-event-types';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { PlannedTreatment } from '@models/treatment-planning/planned-treatment';
import { PlottingEventService } from '@services/plotting-event.service';
import { ToolbarEventService } from '@services/service-detail/toolbar-event.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-treatment-toolbar',
  templateUrl: './treatment-toolbar.component.html',
  styleUrls: ['./treatment-toolbar.component.less']
})
export class TreatmentToolbarComponent implements OnInit, OnDestroy {
  @Input() service: Service;
  @Input() treatmentPlanning: boolean;

  droppingPins: boolean;
  plottingDisabled: boolean;
  lockInteraction: boolean;
  unsub: Subject<void>;

  public serviceDetailTemplate = ServiceDetailTemplate;
  @Input() associatedPlannedTreatment: PlannedTreatment;

  constructor(
    private toolbarEventService: ToolbarEventService,
    private plottingEventService: PlottingEventService,
  ) {
    this.droppingPins = false;
    this.plottingDisabled = true;
    this.lockInteraction = false;
    this.unsub = new Subject<void>();
  }

  ngOnInit() {
    this.initTreatmentToolbarSubscription();
  }

  initTreatmentToolbarSubscription() {
    this.toolbarEventService.getFormSource()
      .pipe(takeUntil(this.unsub))
      .subscribe(response => {
        if (response.event === ServiceDetailEventTypes.UpdateToServiceForm) {
          this.plottingDisabled = !response.formValid;
        }
      });

    this.plottingEventService.getObservationSource()
      .pipe(takeUntil(this.unsub))
      .subscribe(response => {
        switch (response.event) {
          case ObrPlottingEventType.StartPinDrop: {
            this.lockInteraction = true;
            this.droppingPins = true;
            break;
          }
          case ObrPlottingEventType.StopPinDrop: {
            this.lockInteraction = false;
            this.droppingPins = false;
            break;
          }
          default:
            break;
        }
      });
  }

  public onTogglePinDrop() {
    this.droppingPins = !this.droppingPins;
    this.lockInteraction = !this.lockInteraction;
    this.toolbarEventService.togglePinDrop(this.service, this.associatedPlannedTreatment != null && !this.treatmentPlanning);
    if (this.droppingPins) {
      this.plottingEventService.updatedPinCount(0);
    }
  }

  public onResetForm() {
    if (!this.droppingPins && !this.lockInteraction) {
      this.toolbarEventService.resetForm();
      this.plottingEventService.updatedPinCount(0);
      this.plottingDisabled = true;
    }
  }

  public onPlottingButtonDisabled(): boolean {
    if (this.droppingPins) {
      return false;
    }
    if (this.plottingDisabled) {
      return true;
    }
    if (this.lockInteraction) {
      return true;
    }
  }


  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

}
