import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewContainerRef,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { FinanceService } from '@services/finance.service';
import { InvoicesService } from '@services/invoices.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PatientNoteService } from '@services/patient-note.service';

@Component({
  selector: 'app-update-credit',
  templateUrl: './update-credit.component.html',
  styleUrls: ['./update-credit.component.less'],
})
export class UpdateCreditComponent implements OnInit, OnDestroy {
  @Input() patientId: number;
  @Input() patientAvailableCredit: number;
  @Output() closePanel = new EventEmitter();
  @Output() creditUpdated: EventEmitter<number> = new EventEmitter<number>();

  loading = false;
  updateCreditForm: FormGroup;
  //newPatientAvailableCredit: number;
  errors: any[] = [];
  unsub = new Subject<any>();
  componentRef: any;
  @ViewChild('messagecontainer', { read: ViewContainerRef, static: true }) entry: ViewContainerRef;

  constructor(
    private formBuilder: FormBuilder,
    private financeService: FinanceService,
    private invoiceService: InvoicesService,
    private patientNotesService: PatientNoteService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.updateCreditForm = this.formBuilder.group({
      newCreditAmount: [null, Validators.required]
    });
  }

  onUpdateClick() {
    this.loading = true;
    const newPatientAvailableCredit = this.updateCreditForm.get('newCreditAmount').value;
      this.financeService.updatePatientAvailableCredit(this.patientId, newPatientAvailableCredit).subscribe(
        () => {
          this.creditUpdated.next(newPatientAvailableCredit);
          this.invoiceService.invoicesListUpdated.next();
          this.patientNotesService.patientNotesChanged.next();
          this.closePanel.next();
          this.updateCreditForm.reset();
          this.loading = false;
        },
        (errorResponse: HttpErrorResponse) => {
          this.errors = errorResponse.error.errors;
          this.loading = false;
        }
      );
    }

    onCancelClick() {
      this.closePanel.next();
    }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}