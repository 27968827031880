export class ServiceNote {
  public serviceNoteId: number;
  public entryDate: Date;
  public enteredBy: string;
  public entryText: string;
  public draftText: string;
  public serviceId: number;

  public constructor(init?: Partial<ServiceNote>) {
    Object.assign(this, init);
  }
}
