<ngx-loading *ngIf="loading"></ngx-loading>
<div class="d-flex flex-column">
  <div class="d-flex">
    <h6>Allergies</h6>
  </div>
  <app-textarea-widget
    *ngIf="allergy"
    [minRows]="4"
    [maxRows]="4"
    [textValue]="allergy.description"
    (emitText)="saveAllergies($event)">
  </app-textarea-widget>
  <div *ngIf="errorMessage" class="error">
    Error: {{ errorMessage }}
  </div>
</div>

