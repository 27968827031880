import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceForm } from '@models/forms/service-form';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ServiceFormService {
  constructor(private http: HttpClient) {}

  getServiceForms(clinicServiceTemplateId: number) {
    return this.http.get<ServiceForm[]>(environment.baseUrl + 'api/ServiceForms/' + clinicServiceTemplateId);
  }

  assignFormToService(serviceForm: ServiceForm) {
    return this.http.post<ServiceForm>(environment.baseUrl + 'api/ServiceForms', serviceForm);
  }

  assignFormsToService(serviceForms: ServiceForm[]) {
    return forkJoin(
      serviceForms.map((sf) => this.http.post<ServiceForm>(environment.baseUrl + 'api/ServiceForms', sf))
    );
  }

  updatedServiceForms(serviceForms: ServiceForm[]) {
    return forkJoin(serviceForms.map((sf) => this.http.put<ServiceForm>(environment.baseUrl + 'api/ServiceForms', sf)));
  }

  deleteServiceForm(serviceFormId: number) {
    return this.http.delete(environment.baseUrl + 'api/ServiceForms/' + serviceFormId);
  }

  deleteServiceForms(serviceForms: ServiceForm[]) {
    return forkJoin(
      serviceForms.map((sf) =>
        this.http.delete(environment.baseUrl + 'api/ServiceForms/' + sf.clinicServiceTemplateId + '/' + sf.clinicFormId)
      )
    );
  }
}
