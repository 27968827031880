
import { Resource } from "@models/resource";

export class ServiceResource {
  serviceResourceId: number;
  serviceId: number;
  resourceId: number;
  resource: Resource;

  constructor(init?: Partial<ServiceResource>) {
    Object.assign(this, init);
  }
}