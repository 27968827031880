<div
  class="row section-photo-toolbar navy-background photo-toolbar align-items-center py-2 w-100 m-0"
  style="position: relative"
>
  <ngx-loading [show]="loading"></ngx-loading>
  <form class="w-100" #metadataForm="ngForm">
    <div class="col-12 d-flex justify-content-between pr-0 text-white align-items-center justify-content-between">
      <div *ngIf="!photo" style="height: 40px"></div>
      <div *ngIf="photo && !filter?.isConsult()" class="d-flex pr-0 text-center">
        <div *ngIf="photo" class="d-flex flex-column pr-2 meta-col-first">
          <!--Name-->
          <div class="d-flex meta-row">
            <label class="m-0"><b>Name:</b></label>
            <input
              *ngIf="filter.isConsult() && patient"
              class="flex-nowrap editable-div w-100"
              disabled="true"
              placeholder="Cannot Edit: Consent Gallery Mode"
            />
            <input
              *ngIf="!filter.isConsult() || !patient"
              class="flex-nowrap editable-div w-100"
              name="photoName"
              photoName="ngModel"
              data-test-id="photo-name"
              #imageNameInput
              [(ngModel)]="photo.imageName"
              placeholder="Enter photo name..."
              [disabled]="!editModeEnabled"
            />
          </div>
          <!--Notes-->
          <div class="d-flex meta-row">
            <label class="m-0"><b>Notes:</b></label>
            <input
              *ngIf="filter.isConsult() && patient"
              class="flex-nowrap editable-div w-100"
              disabled="true"
              placeholder="Cannot Edit: Consent Gallery Mode"
            />
            <input
              *ngIf="!filter.isConsult() || !patient"
              class="flex-nowrap editable-div w-100"
              name="photoNotes"
              data-test-id="photo-notes"
              [(ngModel)]="photo.notes"
              placeholder="Enter image notes..."
              [disabled]="!editModeEnabled"
            />
          </div>
        </div>
        <div *ngIf="photo" class="d-flex flex-column pr-2 meta-col-second">
          <div class="d-flex meta-row text-white">
            <label class="m-0"><b>Date:</b></label>
            <kendo-datepicker
              *ngIf="photo.dateTaken"
              class="emr-datepicker kendo-date"
              name="photoDateTaken"
              data-test-id="photo-date-taken"
              placeholder="Image Date"
              [format]="'yyyy-MM-dd'"
              [(ngModel)]="photoDate"
              [disabled]="filter.isConsult() || !editModeEnabled"
            >
            </kendo-datepicker>
          </div>
          <div class="d-flex meta-row">
            <label class="m-0"><b>Size:</b></label>
            <input
              class="flex-nowrap editable-div"
              name="photoDimensions"
              [value]="filter.selectedPhotoDimensions"
              data-test-id="photo-dimensions"
              [disabled]="true"
            />
          </div>
        </div>
      </div>
      <div class="align-self-start mr-auto pl-0 pr-2" *ngIf="photo">
        <div class="d-flex row">
          <div class="col d-flex flex-nowrap">
            <mat-select
              class="select-header select-sharing"
              name="photoSharingTags"
              data-test-id="photo-sharing-tags"
              [(ngModel)]="photo.photoConsentTypeId"
              [placeholder]="'Sharing'"
              [disabled]="!editModeEnabled"
            >
              <mat-select-trigger>Sharing</mat-select-trigger>
              <mat-option *ngFor="let consentType of PhotoConsentType | enumToArray" value="{{ +consentType?.index }}">{{
                getConsentString(+consentType?.index)
              }}</mat-option>
            </mat-select>
            <div class="tag-list-container ml-1">
              <span>{{ getConsentString(photo.photoConsentTypeId) }}</span>
            </div>
          </div>
          <div class="col d-flex flex-nowrap">
            <mat-select
              class="select-header select-type"
              name="photoTypeTag"
              data-test-id="photo-type-tag"
              multiple
              [compareWith]="compareObjects"
              [(ngModel)]="currentPhotoTags.PhotoType"
              placeholder="Type"
              [disabled]="!editModeEnabled"
            >
              <mat-select-trigger>Photo Type</mat-select-trigger>
              <mat-option *ngFor="let tag of tags.PhotoType" [value]="tag">{{ tag.title }}</mat-option>
            </mat-select>
            <div class="tag-list-container ml-1 w-10">
              <span>
                <ng-container *ngFor="let tag of currentPhotoTags.PhotoType | slice : 0 : 3; let i = index"
                  >{{ tag.title }}<span *ngIf="i < currentPhotoTags.PhotoType.length - 1">, </span></ng-container
                >
              </span>

              <span *ngIf="currentPhotoTags.PhotoType.length > 3" class="font-weight-light"
                >+{{ currentPhotoTags.PhotoType.length - 3 }} more</span
              >
            </div>
          </div>
        </div>
        <div class="d-flex dropdown-container row">
          <div class="d-flex flex-nowrap col">
            <app-photo-tag-tree-dropdown
              class="select-header select-tags"
              [preselectedTags]="currentPhotoTags"
              [tags]="tags"
              [disabled]="!editModeEnabled"
              (tagsSelected)="onTagsSelected($event)"
            >
            </app-photo-tag-tree-dropdown>
            <div class="tag-list-container ml-1">
              <span>
                <ng-container *ngFor="let tag of currentGenericPhotoTags | slice : 0 : 4; let i = index"
                  >{{ tag.title }}<span *ngIf="i < currentGenericPhotoTags.length - 1">, </span></ng-container
                ></span
              >
              <span *ngIf="currentGenericPhotoTags.length > 4" class="font-weight-light"
                >+{{ currentGenericPhotoTags.length - 4 }} more</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-auto d-flex pl-0 flex-nowrap justify-content-end">
        <button
          *ngIf="editModeEnabled && photo"
          type="submit"
          kendoTooltip
          tooltipClass="em-tooltip"
          position="top"
          title="Save changes"
          class="btn em-btn em-btn-green ml-2"
          [disabled]="!(photoEdited || form.dirty || photoTagsEdited) || !form.valid"
          (click)="onSubmit()"
        >
          <i class="far fa-save"></i>
        </button>
        <button
          *ngIf="photo && filter.patient && editModeEnabled"
          type="reset"
          kendoTooltip
          tooltipClass="em-tooltip"
          position="top"
          title="Discard changes and stop editing"
          class="btn em-btn em-btn-red ml-2"
          (click)="resetChanges()"
        >
          <a type="button" style="color: white">
            <i class="fal fa-ban"></i>
          </a>
        </button>
        <button
          *ngIf="photo && filter.patient && !editModeEnabled"
          type="button"
          kendoTooltip
          tooltipClass="em-tooltip"
          position="top"
          title="Edit photo"
          class="btn em-btn em-btn-green ml-2"
          [disabled]="applyWatermark"
          (click)="enableEditMode()"
        >
          <a type="button" style="color: white">
            <i class="fal fa-pencil"></i>
          </a>
        </button>
        <button
          *ngIf="photo"
          type="button"
          kendoTooltip
          tooltipClass="em-tooltip"
          position="top"
          title="Download Photo"
          class="btn em-btn em-btn-green ml-2 hide-on-small"
          (click)="downloadPhoto(photo)"
        >
          <a type="button" style="color: white">
            <i class="fal fa-download"></i>
          </a>
        </button>
        <app-generic-send-email-popover
          buttonClass="btn em-btn em-btn-green ml-2"
          iconClass="fal fa-envelope"
          toolTipTitle="Email Photo"
          [initialEmailAddress]="emailAddress.value"
          [sendEmailObservable]="emailPhoto"
        ></app-generic-send-email-popover>
        <button
          kendoTooltip
          tooltipClass="em-tooltip"
          position="top"
          [title]="filter.isConsult() && filter?.isPatient() ? 'Cannot delete in consult mode' : 'Delete Photo'"
          *ngIf="photo && filter?.isPatient()"
          class="btn em-btn em-btn-green ml-2"
          [disabled]="filter.isConsult() && filter?.isPatient()"
          (click)="deletePhoto(photo)"
        >
          <i class="far fa-trash-alt"></i>
        </button>
      </div>
    </div>
  </form>
</div>
<div class="overlay-button-container pt-2">
  <button
    *ngIf="photo"
    kendoTooltip
    tooltipClass="em-tooltip"
    position="top"
    title="Favourite Photo"
    class="btn em-btn em-btn-green ml-2"
    (click)="favouriteToggle()"
  >
    <a type="button" style="color: white">
      <i
        [ngClass]="{
          fal: !photo.isFavourite,
          fas: photo.isFavourite,
          'fa-heart': true,
          'calendar-red-font': photo.isFavourite
        }"
      ></i>
    </a>
  </button>
  <button
    *ngIf="photo"
    type="button"
    kendoTooltip
    tooltipClass="em-tooltip"
    position="top"
    title="Apply Filters"
    class="btn em-btn em-btn-green ml-2"
    [disabled]="editModeEnabled"
    [matMenuTriggerFor]="menu"
  >
    <i class="far fa-filter"></i>
  </button>
  <mat-menu #menu="matMenu" [xPosition]="'after'">
    <button mat-menu-item (click)="applyFilter('VascularFilter')">Vascular Filter</button>
    <button mat-menu-item (click)="applyFilter('MelaninFilter')">Melanin Filter</button>
  </mat-menu>
  <button
    *ngIf="photo"
    type="button"
    kendoTooltip
    tooltipClass="em-tooltip"
    position="top"
    title="Apply Watermark"
    class="btn em-btn em-btn-green ml-2"
    [disabled]="applyWatermark || editModeEnabled"
    (click)="onApplyWatermark()"
  >
    <i class="far fa-stamp"></i>
  </button>
</div>
