<button 
    class="{{buttonClass}}"
    type="button" 
    [disabled]="disabled"

    kendoTooltip
    tooltipClass="em-tooltip"
    position="top"
    title="{{toolTipTitle}}"
    
    [popover]="popTemplate"
    [outsideClick]="true"
    data-container="body"
    [container]="'body'"
    placement="left"
    #pop="bs-popover"
>
    <ng-container *ngIf="buttonText">
        {{buttonText}}
    </ng-container>
    <i *ngIf="iconClass" [ngClass]="iconClass"></i>
    <ng-template #popTemplate>
        <div class="d-flex flex-column">
            <div class="p-2">Email Address</div>
            <input
                type="email"
                name="email"
                autocomplete="email"
                class="form-control"
                required
                [formControl]="emailAddress"
            />
            <button
                (click)="onSendClick(); pop.hide();"
                class="btn em-btn em-btn-green p-2 mt-2"
                [disabled]="emailAddress.invalid"
            >
                Send
            </button>
        </div>
    </ng-template>
</button>
