<ngx-loading [show]="loading"></ngx-loading>
<kendo-grid
  class="emr-kendo-grid campaign-recipients-table"
  [data]="gridView"
  [style.maxHeight.%]="100"
  [pageSize]="pageSize"
  [skip]="skip"
  [pageable]="true"
  #grid="kendoGrid"
  (pageChange)="pageChange($event)"
  [height]="800"
>
  <kendo-grid-column field="name" title="Patient" [style]="{ padding: '4px 20px' }">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <button
        type="button"
        class="btn-clean px-0 text-left underline"
        (click)="navigateToPatientProfile(dataItem.patientId)"
      >
        {{ dataItem.name }}
      </button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    *ngIf="details?.length && details[0].communicationType != communicationType.SMS"
    field="email"
    title="Email"
    [style]="{ padding: '4px 20px' }"
  >
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <div class="truncate-ellisis">{{ dataItem.email }}</div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="mobileNumber" title="Phone #" [style]="{ padding: '4px 20px' }">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <div class="truncate-ellisis">{{ formatterService.formatPhoneString(dataItem.mobileNumber) }}</div>
    </ng-template>
  </kendo-grid-column>

  <!-- <kendo-grid-column field="communicationType" title="Communication" [style]="{ padding: '4px 20px' }">
      <ng-template kendoGridHeaderTemplate let-column>
        {{ column.title }}
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <div class="truncate-ellisis">{{ getCommunicationType(dataItem.communicationType) }}</div>
      </ng-template>
    </kendo-grid-column> -->
  <kendo-grid-column field="lastVisit" title="Last Visit" [style]="{ padding: '4px 20px' }">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <div class="truncate-ellisis">{{ dataItem.lastVisit?.date | date : 'yyyy-MM-dd' }}</div>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
