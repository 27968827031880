import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { BillingSettingsEditComponent } from './billing-settings-edit/billing-settings-edit.component';
import { BillingSettingsListComponent } from './billing-settings-list/billing-settings-list.component';
import { BillingSettingsService } from '@services/billing-settings.service';
import { BillingComponent } from './billing.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [BillingSettingsEditComponent, BillingSettingsListComponent, BillingComponent],
  imports: [SharedModule, GridModule, RouterModule],
  providers: [BillingSettingsService],
  exports: [BillingComponent, BillingSettingsListComponent, BillingSettingsEditComponent],
})
export class SiteBillingModule {}
