<!-- See #802/#794 -->
<!--
<div class="row panel-item-border mt-4 mx-0">
  <div class="col pt-2">
    <mat-accordion>
      <mat-expansion-panel class="mb-2" [hideToggle]="false" [expanded]="false" [disabled]="false">
        <mat-expansion-panel-header class="p-0">
          <mat-panel-title>
            <div class="col p-0">
              <h5 class="mt-2">Injection Template</h5>
            </div>
            <button
              type="button"
              class="btn font-white ml-auto px-0 py-0 white-background"
              (click)="isExpanded = !isExpanded"
            >
              <mat-icon>{{ !isExpanded ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
            </button>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container>
          <app-draw-tool></app-draw-tool>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
-->

<div class="row panel-item-border mt-2 mx-0">
  <div class="col pt-2">
    <!-- Planned Treatments List -->
    <app-planned-treatments-list></app-planned-treatments-list>
  </div>
</div>
<div class="row panel-item-border mt-4 mx-0">
  <div class="col pt-2">
    <!-- Product Recommendations -->
    <app-product-recommendation-list></app-product-recommendation-list>
  </div>
</div>
<div class="row panel-item-border mt-4 mx-0">
  <div class="col pt-2">
    <!-- Treatment Questions -->
    <app-treatment-questions></app-treatment-questions>
  </div>
</div>
<div class="row">
  <div class="col text-right">
    <div class="py-2">
      <!-- <button
        class="btn em-btn em-btn-green btn-sm mr-2"
        [ngClass]="{'invalid': !treatmentPlanFormService.formValid}"
        [disabled]="!treatmentPlanFormService.treatmentPlanDirty"
        (click)="onSave()">
        <i class="far fa-save" title="Save"></i>
      </button> -->
    </div>
  </div>
</div>
