import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BookingServiceCategory } from '@models/booking-service-category';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class BookingServiceCategoryService {
  private refreshRequired = new Subject<any>();
  refreshRequired$ = this.refreshRequired.asObservable();

  constructor(private http: HttpClient) {}

  updateRefreshRequired(state: boolean) {
    this.refreshRequired.next(state);
  }

  addBookingServiceCategory(bookingCategory: BookingServiceCategory) {
    return this.http.post<BookingServiceCategory>(
      environment.baseUrl + 'api/BookingServiceCategories/AddBookingServiceCategory',
      bookingCategory
    );
  }

  updateBookingServiceCategory(bookingCategory: BookingServiceCategory) {
    return this.http.put<void>(
      environment.baseUrl +
        'api/BookingServiceCategories/UpdateBookingServiceCategory/' +
        bookingCategory.bookingServiceCategoryId,
      bookingCategory
    );
  }

  removeBookingServiceCategory(bookingCategoryId: number) {
    return this.http.delete(
      environment.baseUrl + 'api/BookingServiceCategories/DeleteOnlineBookingCategory/' + bookingCategoryId
    );
  }

  getBookingServiceCategories() {
    return this.http.get<BookingServiceCategory[]>(
      environment.baseUrl + 'api/BookingServiceCategories/GetOnlineBookingCategories'
    );
  }

  getBookingServiceCategoryById(bookingCategoryId: string) {
    return this.http.get<BookingServiceCategory>(
      environment.baseUrl + 'api/BookingServiceCategories/GetOnlineBookingCategoryById/' + bookingCategoryId
    );
  }

  updateOnlineCategoriesOrder(categoryIds: number[]): Observable<any> {
    return this.http.post(
      environment.baseUrl + 'api/BookingServiceCategories/UpdateOnlineCategoriesOrder',
      categoryIds
    );
  }
}
