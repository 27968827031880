<div class="w-100" style="height: fit-content">
  <ng-container *ngIf="service?.serviceDetailTemplateId === serviceDetailTemplate.Coolsculpting">
    <app-coolsculpting
      [associatedPlannedTreatment]="associatedPlannedTreatment"
      [service]="service"
      [serviceId]="service?.serviceId"
      [observationType]="observationType"
    >
    </app-coolsculpting>
  </ng-container>
  <ng-container *ngIf="service?.serviceDetailTemplateId === serviceDetailTemplate.Injections || service?.serviceDetailTemplateId === serviceDetailTemplate.Deoxycholate">
    <app-injection
      [associatedPlannedTreatment]="associatedPlannedTreatment"
      [service]="service"
      [serviceId]="service?.serviceId"
      [observationType]="observationType"
    >
    </app-injection>
  </ng-container>
  <ng-container *ngIf="service?.serviceDetailTemplateId === serviceDetailTemplate.CoolTone">
    <app-cooltone
      [associatedPlannedTreatment]="associatedPlannedTreatment"
      [service]="service"
      [serviceId]="service?.serviceId"
      [observationType]="observationType"
    >
    </app-cooltone>
  </ng-container>
</div>
