export enum PaymentProcessorType {
  None = 0,
  EmilyPay = 1,
  Square = 2,
}

export enum PaymentProcessorTypeTitle {
  None = 'None',
  EmilyPay = 'emily Pay',
  Square = 'Square',
}
