export enum CampaignStatus {
    Draft = 0,
    Active = 1,
    Suspended = 2,
    Deleted = 3
}

export enum CampaignStatusTitle {
    Draft = 'Draft',
    Active = 'Active',
    Suspended = 'Suspended',
    Deleted = 'Deleted'
}
