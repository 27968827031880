import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Prescription } from '@models/prescriptions/prescription';
import { Observable, BehaviorSubject, combineLatest, forkJoin } from 'rxjs';
import { environment } from '@environments/environment';
import { FileData } from '@models/file-data';
import { ProviderSignature } from '@models/prescriptions/provider-signature';
import { BlobService } from '@services/blob.service';
import { map } from 'rxjs/operators';
import printJS from 'print-js';
import { dataURLtoFile } from '@app/shared/helpers';
import { MatDialog } from '@angular/material/dialog';
import { PrescriptionSignatureModalComponent } from '@app/patients/patient-tabs/patient-chart-tab/prescriptions/prescription-signature-modal/prescription-signature-modal.component';
import { PrescriptionFaxModalComponent } from '@app/patients/patient-tabs/patient-chart-tab/prescriptions/prescription-fax-modal/prescription-fax-modal.component';
import { PrescriptionDetailsComponent } from '@app/patients/patient-tabs/patient-chart-tab/prescriptions/prescriptions-view/prescription-details/prescription-details.component';
import { Patient } from '@models/patient';
import { ClinicsService } from '@services/clinics.service';

@Injectable({
  providedIn: 'root',
})
export class PrescriptionService {
  private prescriptionSource = new BehaviorSubject<Prescription>(undefined);
  prescriptionSource$ = this.prescriptionSource.asObservable();

  constructor(
    private http: HttpClient,
    private blobService: BlobService,
    private dialog: MatDialog,
    private resolver: ComponentFactoryResolver,
    private clinicsService: ClinicsService
  ) {}

  sharePrescription(prescription?: Prescription) {
    this.prescriptionSource.next(prescription);
  }

  addPrescription(prescription: Prescription): Observable<Prescription> {
    delete prescription.createdByUser;
    delete prescription.patient;

    return this.http.post<Prescription>(`${environment.baseUrl}api/Prescriptions`, prescription).pipe(
      map((added) => {
        const sas = this.blobService.getReadOnlySAS();
        this.applySasToPrescriptions([added], sas);
        return added;
      })
    );
  }

  updatePrescription(prescription: Prescription): Observable<Prescription> {
    return this.http.put<Prescription>(`${environment.baseUrl}api/Prescriptions`, prescription).pipe(
      map((prescription) => {
        const sas = this.blobService.getReadOnlySAS();
        this.applySasToPrescription(prescription, sas);
        return prescription;
      })
    );
  }

  getPrescriptionById(id: number): Observable<Prescription> {
    return combineLatest([
      this.http.get<Prescription>(`${environment.baseUrl}api/Prescriptions/${id}`),
      this.blobService.getReadOnlySASObservable(),
    ]).pipe(
      map(([presc, sas]) => {
        this.applySasToPrescription(presc, sas);
        return presc;
      })
    );
  }

  getPrescriptionsByPatientId(patientId: number): Observable<Prescription[]> {
    return combineLatest([
      this.http.get<Prescription[]>(`${environment.baseUrl}api/Prescriptions/ByPatientId/${patientId}`),
      this.blobService.getReadOnlySASObservable(),
    ]).pipe(
      map(([prescs, sas]) => {
        this.applySasToPrescriptions(prescs, sas);
        return prescs;
      })
    );
  }

  deletePrescription(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.baseUrl}api/Prescriptions/${id}`);
  }

  discontinuePrescription(prescriptionId: number): Observable<Prescription> {
    return combineLatest([
      this.http.put<Prescription>(`${environment.baseUrl}api/Prescriptions/Discontinue/${prescriptionId}`, null),
      this.blobService.getReadOnlySASObservable(),
    ]).pipe(
      map(([presc, sas]) => {
        this.applySasToPrescription(presc, sas);
        return presc;
      })
    );
  }

  getDiscontinuedPrescriptionsByPatientId(patientId: number): Observable<Prescription[]> {
    return combineLatest([
      this.http.get<Prescription[]>(`${environment.baseUrl}api/Prescriptions/DiscontinuedByPatientId/${patientId}`),
      this.blobService.getReadOnlySASObservable(),
    ]).pipe(
      map(([prescs, sas]) => {
        this.applySasToPrescriptions(prescs, sas);
        return prescs;
      })
    );
  }

  faxPrescriptionFile(prescriptionId: number, prescription: FileData, address: string) {
    const params = {
      toFaxAddress: address,
    };

    return this.http.post<FileData>(`${environment.baseUrl}api/Fax/FaxPrescription/${prescriptionId}`, prescription, {
      params: params,
    });
  }

  addSignature(signature: File): Observable<ProviderSignature> {
    const formData = new FormData();
    formData.append('file', signature, signature.name);
    return this.http.post<ProviderSignature>(`${environment.baseUrl}api/Prescriptions/Signature`, formData).pipe(
      map((sig) => {
        const sas = this.blobService.getReadOnlySAS();
        this.applySasToSignature(sig, sas);
        return sig;
      })
    );
  }

  async printPrescription(
    prescriptions: Prescription[],
    exportContainer: ViewContainerRef,
    patient: Patient
  ): Promise<void> {
    const signed = await this.checkSignature(prescriptions);
    if (!signed) return;
    const exportComponentRef = this.generateExportComponent(prescriptions, exportContainer, patient);
    await new Promise<void>((resolve) =>
      setTimeout(async () => {
        let data: { blob: Blob; base64: string; url: string } = await exportComponentRef.instance.exportToPDF();
        printJS({ printable: data.url, type: 'pdf' });
        exportComponentRef.destroy();
        return resolve();
      })
    );
  }

  async faxPrescription(
    prescriptions: Prescription[],
    exportContainer: ViewContainerRef,
    patient: Patient
  ): Promise<void> {
    const signed = await this.checkSignature(prescriptions);
    if (!signed) return;
    const faxNumber = await this.openFaxModal(patient);
    if (!faxNumber) return;
    const exportComponentRef = this.generateExportComponent(prescriptions, exportContainer, patient);
    await new Promise<void>((resolve, reject) =>
      setTimeout(async () => {
        let data: { blob: Blob; base64: string; url: string } = await exportComponentRef.instance.exportToPDF();
        let fileObject: any = data.blob;
        const fileData = new FileData({
          contentType: fileObject.type,
          fileName: prescriptions[0].id.toString() + '.pdf',
          base64: data.base64,
        });
        await this.faxPrescriptionFile(prescriptions[0].id, fileData, faxNumber)
          .toPromise()
          .catch(() => {
            reject('Fax could not be sent');
          });
        exportComponentRef.destroy();
        return resolve();
      })
    );
  }

  private async checkSignature(prescriptions: Prescription[]): Promise<boolean> {
    const allSigned = prescriptions.every((prescription) => prescription.signatureId);
    if (allSigned) return true;
    const signature = await this.openSignatureModal();
    if (!signature) return false;
    const file = dataURLtoFile(signature, 'file');
    const providerSignature = await this.addSignature(file).toPromise();
    const updatePrescriptions = [];
    const addPrescriptions = [];
    prescriptions.forEach((prescription) => {
      prescription.isLocked = true;
      prescription.signatureId = providerSignature.id;
      prescription.signature = providerSignature;
      prescription.id ? updatePrescriptions.push(prescription) : addPrescriptions.push(prescription);
    });

    const updateCalls = updatePrescriptions.map((prescription) => this.updatePrescription(prescription));
    await forkJoin(updateCalls)
      .toPromise()
      .catch(() => {
        updatePrescriptions.forEach((prescription) => {
          prescription.isLocked = false;
          prescription.signatureId = null;
          prescription.signature = null;
        });
        throw new Error('Could not save prescription');
      });

    const addCalls = addPrescriptions.map((prescription) => this.addPrescription(prescription));
    await forkJoin(addCalls)
      .toPromise()
      .then((added) => {
        addPrescriptions.forEach((original, i) => {
          original.id = added[i].id;
        });
      })
      .catch(() => {
        addPrescriptions.forEach((prescription) => {
          prescription.isLocked = false;
          prescription.signatureId = null;
          prescription.signature = null;
        });
        throw new Error('Could not save prescription');
      });

    return true;
  }

  private async openSignatureModal() {
    const dialogRef = this.dialog.open<PrescriptionSignatureModalComponent, any, string>(
      PrescriptionSignatureModalComponent,
      {
        width: '700px',
        panelClass: 'prescription-signature-dialog',
      }
    );
    return await dialogRef.afterClosed().toPromise();
  }

  private async openFaxModal(patient: Patient) {
    const dialogRef = this.dialog.open<PrescriptionFaxModalComponent, any, string>(PrescriptionFaxModalComponent, {
      width: '700px',
      panelClass: 'prescription-signature-dialog',
      data: { patient: patient },
    });
    return await dialogRef.afterClosed().toPromise();
  }

  private generateExportComponent(
    prescriptions: Prescription[],
    exportContainer: ViewContainerRef,
    patient: Patient
  ): ComponentRef<PrescriptionDetailsComponent> {
    const factory = this.resolver.resolveComponentFactory(PrescriptionDetailsComponent);
    exportContainer.clear();
    const componentRef = exportContainer.createComponent(factory);
    componentRef.instance.clinic = this.clinicsService.clinic;
    componentRef.instance.patient = patient;
    componentRef.instance.prescription = prescriptions;
    return componentRef;
  }

  private applySasToPrescriptions(prescriptions: Prescription[], sas: string) {
    prescriptions.forEach((p) => this.applySasToPrescription(p, sas));
    return prescriptions;
  }

  private applySasToPrescription(prescription: Prescription, sas: string) {
    if (prescription.signature) {
      this.applySasToSignature(prescription.signature, sas);
    }
    return prescription;
  }

  private applySasToSignature(signature: ProviderSignature, sas: string) {
    signature.filePath = signature.filePath.trim() + sas;
    return signature;
  }
}
