import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Appointment } from '@models/appointments/appointment';
import { ServiceProvider } from '@models/service-provider';

@Component({
  selector: 'app-service-provider-selector-modal',
  templateUrl: './service-provider-selector-modal.component.html',
  styleUrls: ['./service-provider-selector-modal.component.less'],
})
export class ServiceProviderSelectorModalComponent implements OnInit {
  public dialog: MatDialog;
  content: string;
  providers: ServiceProvider[] = [];
  selectedProvider: ServiceProvider;
  isMedicalPlanBilling = false;
  appointment: Appointment;
  serviceTemplateId: number;

  constructor(
    public dialogRef: MatDialogRef<ServiceProviderSelectorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { content; providers; appointment }
  ) {}

  isSelected(provider: ServiceProvider) {
    return provider && this.selectedProvider.id === provider.id;
  }

  ngOnInit() {
    this.appointment = this.data.appointment;
    this.providers = this.data.providers;
    this.content = this.data.content;

    this.isMedicalPlanBilling = this.appointment.service.governmentBilling;
    this.serviceTemplateId = this.appointment.service.templateId;
  }

  providerSelected(provider: ServiceProvider) {
    this.selectedProvider = provider;
    this.dialogRef.close(this.selectedProvider);
    return;
  }

  getTitle(serviceProvider: ServiceProvider) {
    var title =
      serviceProvider.authorizedServiceIds.indexOf(this.serviceTemplateId) === -1
        ? 'Provider not authorized to perform this service'
        : this.isMedicalPlanBilling && !serviceProvider.allowGovernmentBilling
        ? 'Provider cannot do government-billed service'
        : serviceProvider.working
        ? 'Click to select'
        : 'Provider not working currently';

    return title;
  }

  isDisabled(serviceProvider: ServiceProvider) {
    return (
      serviceProvider.authorizedServiceIds.indexOf(this.serviceTemplateId) === -1 ||
      (this.isMedicalPlanBilling && !serviceProvider.allowGovernmentBilling)
    );
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
