<div class="row justify-content-between container-fluid pr-0 h-100">
  <div *ngIf="showSaveMessage" class="success-banner">Saved successfully!</div>
  <div class="teal-font line-height-1 px-0 col-3 h-100">
    <ng-container *ngIf="!selectedSetting; else editSetting">
      <div class="h-100">
        <div class="row mx-0 teal-background">
          <div class="col">
            <h6 class="white-font uppercase my-0 py-2">Email Settings</h6>
          </div>
        </div>
        <ng-scrollbar class="h-100">
          <div class="m-2">
            <ng-container *ngFor="let setting of emailSettings">
              <div class="d-flex grey-two-background mb-2 clickable selected-element" (click)="selectSetting(setting)">
                <div class="py-2 px-3 navy-font">
                  <span class="bigger font-weight-bold">{{ getSettingName(setting) | spaceTitleCase }}</span>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-scrollbar>
      </div>
    </ng-container>
  </div>
  <div class="col d-flex flex-column pr-0 h-100">
    <div class="row mx-0 teal-background">
      <div class="col">
        <h6 class="white-font uppercase my-0 py-2">Email Preview</h6>
      </div>
    </div>
    <div
      class="w-100 minh-0 flex-grow-1 d-flex align-items-start justify-content-center px-0 pb-2 light-grey-background"
    >
      <div
        *ngIf="!selectedSetting"
        class="w-100 h-100 d-flex align-items-start justify-content-center px-0 pb-2 light-grey-background"
      >
        <span class="m-auto font-weight-bold" style="font-size: 2em">Please select an email setting.</span>
      </div>
      <ng-scrollbar [hidden]="!selectedSetting" class="w-100 h-100">
        <app-unlayer-email-editor [options]="editorOptions"></app-unlayer-email-editor>
      </ng-scrollbar>
    </div>
  </div>
</div>

<ng-template #editSetting>
  <form [formGroup]="settingsForm" class="line-height-1 px-0 light-grey-background h-100">
    <div class="row mx-0 teal-background">
      <div class="col">
        <h6 class="white-font uppercase my-0 py-2">Email Settings</h6>
      </div>
    </div>
    <div class="py-3">
      <div class="row mx-0">
        <div class="col">
          <mat-form-field class="w-100">
            <mat-label>Email Template</mat-label>
            <mat-select formControlName="createdFromEmailTemplateId">
              <mat-option *ngFor="let template of allowedEmailTemplates" [value]="template.id">
                {{ template.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <button
            class="btn btn-sm em-btn em-btn-green py-2 mx-2 font-weight-bold mb-3"
            style="line-height: 1em"
            tooltipclass="em-tooltip"
            title="Revert back to original email template."
            (click)="revertToOriginalTemplate()"
          >
            <i class="fas fa-undo pr-2"></i>Revert
          </button> -->
        </div>
      </div>
      <div class="row mx-0">
        <div class="col">
          <mat-form-field class="w-100">
            <input matInput placeholder="Sender Name" formControlName="senderName" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col">
          <mat-form-field class="w-100">
            <input matInput placeholder="Sender Email Address" formControlName="senderEmail" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col">
          <mat-form-field class="w-100">
            <input matInput placeholder="Email Subject" formControlName="emailSubjectLine" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0 mb-3">
        <div class="col mx-0">
          <div class="text-dark">
            <ng-template #emojiTemplate>
              Include emojis 😍💖😊 in your email subject line!
              <ul class="mb-0">
                <li>On Mac: Press Control+Command+Space while editing the text</li>
                <li>On Windows: Press Windows+. while editing the text</li>
              </ul>
            </ng-template>
            <div kendoTooltip [tooltipTemplate]="emojiTemplate" filter="i" position="right">
              Use Emoji <i class="fa fa-question-circle teal-font"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 teal-background">
      <div class="col">
        <h6 class="white-font uppercase mb-0 py-2">Available fields</h6>
      </div>
    </div>
    <div class="py-3">
      <div class="row mx-0">
        <div class="col">
          <ul class="mb-0">
            <li>{{ 'FirstName' }}</li>
            <li>{{ 'PatientName' }}</li>
            <li>{{ 'VisitDate' }}</li>
            <li>{{ 'VisitTime' }}</li>
            <li>{{ 'ConfirmVisitEndpoint' }}</li>
            <li>{{ 'UnsubscribeEndpoint' }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end py-3">
      <button class="btn medium em-btn em-btn-green mr-2" type="button" (click)="onSave()">
        <i class="far fa-save"></i> Save
      </button>
      <button class="btn medium em-btn em-btn-green" type="button" (click)="onCancel()">
        <i class="far fa-minus-circle"></i> Cancel
      </button>
    </div>
  </form>
</ng-template>
