import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TitleCasePipe, UpperCasePipe } from '@angular/common';
import * as moment from 'moment';
import { isNullOrUndefined } from '@app/shared/helpers';

@Injectable()
export class ValidationService {
  constructor() {}

  validatePhoneNumber(c: FormControl) {
    const PHONE_REGEXP = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    var returnVal;

    if (c.value !== '') {
      returnVal = PHONE_REGEXP.test(c.value)
        ? null
        : {
            phoneError: {},
          };
    } else {
      returnVal = null;
    }
    return returnVal;
  }

  validatePostalCode(c: FormControl) {
    const POSTALCODE_REGEXP = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    var returnVal;

    if (c.value !== '') {
      returnVal = POSTALCODE_REGEXP.test(c.value)
        ? null
        : {
            postalCodeError: {},
          };
    } else {
      returnVal = null;
    }
    return returnVal;
  }

  validateName(c: FormControl) {
    const NAME_REGEXP = /^[a-z A-Z-'0-9]+$/;
    var returnVal;

    if (c.value !== '') {
      returnVal = NAME_REGEXP.test(c.value)
        ? null
        : {
            nameError: {},
          };
    } else {
      returnVal = null;
    }
    return returnVal;
  }

  validateZipCode(c: FormControl) {
    const ZIPCODE_REGEXP = /^[0-9]{5}(?:-[0-9]{4})?$/;
    var returnVal;

    if (c.value !== '') {
      returnVal = ZIPCODE_REGEXP.test(c.value)
        ? null
        : {
            zipCodeError: {},
          };
    } else {
      returnVal = null;
    }
    return returnVal;
  }

  validateNotInFuture(c: FormControl) {
    let date = moment(c.value);
    if (moment().startOf('day').diff(moment(date).startOf('day'), 'days', true) >= 0) {
      return null;
    } else {
      return {
        dateError: {},
      };
    }
  }

  validateDate(c: FormControl) {
    // date format MM/DD/YYYY
    let returnVal = null;

    if (c.value === null) {
      returnVal = {
        dateError: {},
      };
    } else {
      // Moment date parsing is flaky when using _i
      // See https://stackoverflow.com/a/28132227
      // and https://momentjs.com/guides/#/lib-concepts/internal-properties/
      /*
            if (c.value._i !== undefined && c.value._i.year === undefined) {
                const dateComponents = c.value._i.split('/');
                const month = parseInt(dateComponents[0], 10);
                const day = parseInt(dateComponents[1], 10);
                const year = parseInt(dateComponents[2], 10);
                if ((!isNaN(month) && month > 0 && month < 13) &&
                    (!isNaN(day) && day > 0 && day <= 31) &&
                    (!isNaN(year) && year >= 1900 && year <= (new Date()).getFullYear())) {
                        // Make sure day is valid for specific month.
                        // Setting day in new Date(...) to 0 sets date to last day of month:
                        const daysInMonth = new Date(year, month, 0).getDate();
                        if (daysInMonth < day) {
                            returnVal = {
                                dateError: {}
                            };
                        } else {
                            returnVal = null;
                        }
                } else {
                    returnVal = {
                        dateError: {}
                    };
                }
            } else {
            */
      if (c.value._isAMomentObject) {
        const date = c.value.toDate();
        if (date.getFullYear() > 0 && date.getFullYear() < 1900) {
          returnVal = { dateError: {} };
        }
      } else {
        if (c.value instanceof Date) {
          if (c.value.getFullYear() > 0 && c.value.getFullYear() < 1900) {
            returnVal = {
              dateError: {},
            };
          } else {
            returnVal = null;
          }
        } else if (c.value._i && c.value._i.year >= 0 && c.value._i.year < 1900) {
          returnVal = {
            dateError: {},
          };
        } else {
          returnVal = null;
        }
      }
    }
    return returnVal;
  }

  validateWebsite(c: FormControl) {
    const URL_REGEXP =
      /^(?![^\n]*\.$)(?:https?:\/\/)?(?:(?:[2][1-4]\d|25[1-5]|1\d{2}|[1-9]\d|[1-9])(?:\.(?:[2][1-4]\d|25[1-5]|1\d{2}|[1-9]\d|[0-9])){3}(?::\d{4})?|[a-z\-]+(?:\.[a-z\-]+){2,})$/;
    var returnVal;

    if (c.value !== '') {
      returnVal = URL_REGEXP.test(c.value)
        ? null
        : {
            websiteError: {},
          };
    } else {
      returnVal = null;
    }
    return returnVal;
  }

  validateMSPNumber(control: FormControl) {
    const NUMBER_REGEXP = /^[0-9]+$/;
    const group = control.parent;
    var returnVal = null;
    if (!isNullOrUndefined(group) && !isNullOrUndefined(group.controls['address']) && control.value !== '') {
      const provinceValue = group.controls['address'].controls['province'].value;
      const clientIdValue = control.value;
      if (provinceValue === 'British Columbia') {
        if (
          clientIdValue &&
          ((clientIdValue.length > 0 && clientIdValue.length !== 10) || !NUMBER_REGEXP.test(clientIdValue))
        ) {
          returnVal = { mspInvalidLength: true };
        } else if (clientIdValue.charAt(0) !== '9') {
          returnVal = { mspInvalid: true };
        } else {
          // MSP validation described here: https://silo.tips/download/appendix-d-phn-check-digit-number-routine
          var weight = 2;
          var digits: number[] = [];
          var msp: number = parseInt(clientIdValue);
          for (var i = 9; i >= 0; i--) {
            digits[i] = msp % 10;
            msp = Math.floor(msp / 10);
          }
          var sum = 0;
          for (var i = 1; i < 9; i++) {
            const val = digits[i] * weight;
            sum += val % 11;
            weight = (weight * 2) % 11;
          }
          const validationDigit = 11 - (sum % 11);
          if (digits[9] !== validationDigit) {
            returnVal = { mspInvalid: true };
          }
        }
      }
    }
    return returnVal;
  }
}
