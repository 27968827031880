import { PatientService } from '@services/patient.service';
import { Service } from '@models/service/service';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild,
  NgZone,
  Optional,
  Inject,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { take, switchMap, takeUntil } from 'rxjs/operators';
import { ServicesService } from '@services/services.service';
import { ServiceTemplatesService } from '@services/service-templates.service';
import { Subject } from 'rxjs';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { createTrue } from 'typescript';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { PlannedTreatmentMultiple } from '@models/treatment-planning/planned-treatment-multiple';
import { HttpErrorResponse } from '@angular/common/http';
import { TreatmentPlan } from '@models/treatment-planning/treatment-plan';
import { CurrencyPipe } from '@angular/common';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { isNullOrUndefined } from '@app/shared/helpers';
import { TreatmentState } from '@models/treatment-planning/treatment-state';

@Component({
  selector: 'app-create-treatment-plan-package',
  templateUrl: './create-treatment-plan-package.component.html',
  styleUrls: ['./create-treatment-plan-package.component.less'],
})
export class CreateTreatmentPlanPackageComponent implements OnInit, OnDestroy {
  @ViewChild('quantity') quantityRef: ElementRef;
  createTreatmentPlanPackageForm: FormGroup;
  loading = false;
  unsub = new Subject<void>();
  serviceTemplates: ClinicServiceTemplate[];
  selectedServiceTemplate: ClinicServiceTemplate;
  patientId: number;
  errors: any[] = [];
  treatmentPlanId: number;
  treatmentPlan: TreatmentPlan;

  isNew: boolean = true;
  editPlannedTreatmentMultiple: PlannedTreatmentMultiple;

  constructor(
    @Optional() public dialogRef: MatDialogRef<CreateTreatmentPlanPackageComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private treatmentPlanService: TreatmentPlanService,
    private servicesService: ServicesService,
    private patientService: PatientService,
    private currencyPipe: CurrencyPipe,
    private scheduledDialog: MatDialog
  ) {}

  ngOnInit() {
    if (!isNullOrUndefined(this.data.isNew)) this.isNew = this.data.isNew;

    this.serviceTemplates = this.data.serviceTemplateMultiples;
    this.selectedServiceTemplate = this.data.serviceTemplate;
    this.patientId = this.data.patientId;
    this.treatmentPlanId = this.data.treatmentPlanId;
    this.treatmentPlan = this.data.treatmentPlan;

    if (!this.isNew) this.editPlannedTreatmentMultiple = this.data.editPlannedTreatmentMultiple;

    this.initForm();
  }

  ngAfterViewInit() {
    // Quantity should be read only if we are editing and the package is scheduled
    if (!this.isNew && this.isEditPackageScheduled()) {
      this.quantityRef.nativeElement.readOnly = true;
    }
  }

  initForm() {
    if (this.selectedServiceTemplate) {
      var price: number;
      var quantity: number;
      var totalAmount: number;
      var notes: string;

      if (this.isNew) {
        price = this.selectedServiceTemplate.defaultPrice != null ? this.selectedServiceTemplate.defaultPrice * 1 : 0;
        quantity = 1;
        totalAmount = price != null ? price * quantity : 0;
        notes = '';
      } else {
        price = this.data.plannedTreatmentMultiple.price;
        quantity = this.data.plannedTreatmentMultiple.quantity;
        totalAmount = this.data.plannedTreatmentMultiple.totalAmount;
        notes = this.data.plannedTreatmentMultiple.notes;
        this.editPlannedTreatmentMultiple = this.data.plannedTreatmentMultiple;
      }

      this.createTreatmentPlanPackageForm = this.formBuilder.group({
        serviceTemplate: [this.selectedServiceTemplate, Validators.required],
        price: [price, Validators.required],
        quantity: [quantity, Validators.required],
        totalAmount: [totalAmount],
        notes: notes,
      });

      this.createTreatmentPlanPackageForm.controls['serviceTemplate'].setValue(this.selectedServiceTemplate.id);
    }
  }

  public onPackageFormSubmit() {
    this.loading = true;
    // Get the values from the form
    const service = this.servicesService.mapServiceFromTemplate(
      this.selectedServiceTemplate,
      this.patientService.patientPanelPatient
    );
    const price = this.createTreatmentPlanPackageForm.controls['price'].value;
    const quantity = this.createTreatmentPlanPackageForm.controls['quantity'].value;
    const totalAmount = this.createTreatmentPlanPackageForm.controls['totalAmount'].value;
    const notes = this.createTreatmentPlanPackageForm.controls['notes'].value;

    const plannedTreatmentMultiple = new PlannedTreatmentMultiple({
      patientId: this.patientId,
      treatmentPlanId: this.treatmentPlanId,
      serviceTemplateId: this.selectedServiceTemplate.id,
      service: service,
      price: price,
      quantity: quantity,
      totalAmount: totalAmount,
      notes: notes,
    });

    if (this.isNew) {
      // Create multiples package
      this.treatmentPlanService
        .addPlannedTreatmentMultiple(plannedTreatmentMultiple)
        .pipe(takeUntil(this.unsub))
        .subscribe(
          (ptm) => {
            this.treatmentPlan.plannedTreatmentMultiples.push(ptm);
            this.loading = false;
            this.closeModal('created');
          },
          (errorResponse: HttpErrorResponse) => {
            this.errors = errorResponse.error.errors;
            this.loading = false;
          }
        );
    } else {
      // Updated multiple package
      plannedTreatmentMultiple.id = this.editPlannedTreatmentMultiple.id;
      this.treatmentPlanService
        .updatePlannedTreatmentMultiple(plannedTreatmentMultiple)
        .pipe(takeUntil(this.unsub))
        .subscribe(
          (ptm) => {
            const indexToUpdate = this.treatmentPlan.plannedTreatmentMultiples.findIndex(
              (p) => p.id === plannedTreatmentMultiple.id
            );
            this.treatmentPlan.plannedTreatmentMultiples[indexToUpdate] = ptm;
            this.loading = false;
            this.closeModal('updated');
          },
          (errorResponse: HttpErrorResponse) => {
            this.errors = errorResponse.error.errors;
            this.loading = false;
          }
        );
    }
  }

  public closeModal(message?: string) {
    this.dialogRef.close(message);
  }

  public onServiceTemplateChange(serviceTemplateChange: MatSelectChange) {
    const serviceTemplate = this.serviceTemplates.find(
      (serviceTemplate: ClinicServiceTemplate) => serviceTemplate.id === serviceTemplateChange.value
    );
    this.selectedServiceTemplate = serviceTemplate;

    if (this.selectedServiceTemplate.defaultPrice) {
      this.createTreatmentPlanPackageForm.controls['price'].setValue(this.selectedServiceTemplate.defaultPrice);
      const quantity = this.createTreatmentPlanPackageForm.controls['quantity'].value;
      this.createTreatmentPlanPackageForm.controls['totalAmount'].setValue(
        this.selectedServiceTemplate.defaultPrice * quantity
      );
    } else {
      this.createTreatmentPlanPackageForm.controls['price'].setValue(0);
      this.createTreatmentPlanPackageForm.controls['totalAmount'].setValue(0);
    }
  }

  public updatePriceCalculations() {
    const quantity = this.createTreatmentPlanPackageForm.controls['quantity'].value;
    const price = this.createTreatmentPlanPackageForm.controls['price'].value;

    let totalAmount = this.currencyPipe.transform(price * quantity, 'USD', '', '1.2-2');
    this.createTreatmentPlanPackageForm.controls['price'].setValue(price);
    this.createTreatmentPlanPackageForm.controls['totalAmount'].setValue(totalAmount);
  }

  public checkScheduled() {
    // If you are editing the package and there's a scheduled treatment, we cannot change the quantityInStock
    if (!this.isNew && this.isEditPackageScheduled()) {
      const dialogRef = this.scheduledDialog.open(GenericDialogComponent, {
        width: '330px',
        data: {
          title: 'Scheduled Multiple',
          content: 'The quantity cannot be changed because the package has already been scheduled.',
          confirmButtonText: 'Ok',
          showCancel: false,
        },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsub))
        .subscribe((result) => {
          this.quantityRef.nativeElement.blur();
        });
    }
  }

  isEditPackageScheduled(): boolean {
    // If we aren't editing then it doesn't matter if it's scheduled
    if (isNullOrUndefined(this.editPlannedTreatmentMultiple)) return false;

    var scheduledIndex = this.editPlannedTreatmentMultiple.plannedTreatments.findIndex(
      (pt) => pt.treatmentState == TreatmentState.Scheduled || pt.treatmentState == TreatmentState.Completed
    );
    if (scheduledIndex >= 0) return true;
    else return false;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
