import { Review } from './review';

export class ClinicReviewSettings {
  clinicId: number;
  emailReviewsOn: boolean;
  smsReviewsOn: boolean;
  googleReviewsOn: boolean;
  googlePlaceId: string;
  googleAccountId?: string;
  googleLocationId?: string;
  sendReviewsDelayInMinutes: number;
  reviewsSchedule: ReviewsScheduleEnum;
  automatedReviewsActivated: boolean;
  initialVisitRequestFeedback: boolean;
  maxFrequencyInDays: number;
  serviceIds: string;
  productIds: string;
  reviewsEmailContent: string;
  reviewsSmsContent: string;
  reviewsNotificationEmailAddresses: string;
  lowRatingTextContent: string;
  highRatingTextContent: string;
  reviewLink: string;
  emailJson: string;
  emailHtml: string;
  emailSubjectLine: string;
  createdFromEmailTemplateId: number;
}

export enum ReviewsScheduleEnum {
  oneTimeOnly = 1,
  afterEachVisit = 2,
  afterXDays = 3,
}
