<div class="panel-box-content mt-2 pb-0 border-0 d-flex flex-column">
  <ng-container *ngFor="let item of treatmentItems; let i = index">
    <div class="d-flex mb-1">
      <div class="content-row w-100">
        <div *ngIf="item.area" class="pr-1 area">
          {{ item.area }}
        </div>
        <div *ngIf="item.applicator" class="pr-1 applicator">
          {{ item.applicator }}
        </div>
        <div *ngIf="item.details" class="pr-1 details">
          {{ item.details }}
        </div>
        <div *ngIf="item.unit" class="pr-1 unit d-flex">
          {{ item.unit }}
          <div *ngIf="item.avaliable != undefined">{{ '&nbsp;Cycle' }}</div>
        </div>
        <div *ngIf="item.price | number" class="pr-1 price">
          <div style="padding-right: 8px">{{ item.price | currency }}</div>
          <div *ngIf="item?.totalDiscountPercentage > 0" style="color: red">
            ({{ item?.totalDiscountPercentage * 100 | number: '1.0-0' }}% off)
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="d-flex">
    <button
      *ngIf="itemType === serviceListType.favourites"
      class="p-1 smaller ml-auto em-btn btn em-btn-green"
      (click)="onDeleteTreatment()"
    >
      DELETE
    </button>
  </div>
</div>
