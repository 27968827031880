import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { isNullOrUndefined } from '@app/shared/helpers';

import { InjectionFormService } from '@services/service-detail/injection-form.service';
import { ObservationUnitsService } from '@services/observation-units.service';
import { ToolbarEventService } from '@services/service-detail/toolbar-event.service';
import { ObservationTypesService } from '@services/observation-types.service';
import { PatientService } from '@services/patient.service';
import { PlottingEventService } from '@services/plotting-event.service';
import { ObservationListItemsService } from '@services/observation-list-items.service';

import { ObservationUnit, ObservationListItem, Observation, ObservationTypes } from '@models/observation/observation';
import { ObservationPlot } from '@models/observation/observation-plot';
import { ObrPlottingEventType } from '@models/observation/obr-plotting-event-type';
import { ServiceDetailEventTypes } from '@models/service/service-detail-event-types';
import { TreatmentType } from '@models/treatment-type';

@Component({
  selector: 'app-deoxycholate-toolbar',
  templateUrl: './deoxycholate-toolbar.component.html',
  styleUrls: ['./deoxycholate-toolbar.component.less'],
})
export class DeoxycholateToolbarComponent implements OnInit, OnDestroy {
  treatmentType: TreatmentType;
  treatmentTypes = TreatmentType;
  toolbarForm: FormGroup;
  units: ObservationUnit[];
  unsub: Subject<void>;
  droppingPins: boolean;
  obrListItem: ObservationListItem;

  pendingSaveNewObrPlots: ObservationPlot[] = [];
  existingObrPlots: ObservationPlot[] = [];
  pendingDeleteObrPlots: ObservationPlot[] = [];

  isTreatmentPlanning: boolean;
  total = 0;

  constructor(
    private fb: FormBuilder,
    public injectionFormService: InjectionFormService,
    private observationUnitsService: ObservationUnitsService,
    private toolbarEventService: ToolbarEventService,
    private observationTypesService: ObservationTypesService,
    private patientService: PatientService,
    private plottingEventService: PlottingEventService,
    private observationListItemsService: ObservationListItemsService,
    private route: ActivatedRoute
  ) {
    // Default treatment type to toxins
    this.treatmentType = TreatmentType.Deoxycholate;
    this.unsub = new Subject<void>();
    this.droppingPins = false;
  }

  ngOnInit() {
    this.isTreatmentPlanning = this.route.snapshot.params.treatmentPlanning === 'true';
    this.initForm();
    this.initToolbarSubscription();
    this.initPlottingSubscription();
  }

  initPlottingSubscription() {
    this.plottingEventService
      .getObservationSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((response) => {
        switch (response.event) {
          case ObrPlottingEventType.StartPinDrop: {
            this.toolbarForm.disable();
            this.droppingPins = true;
            break;
          }
          case ObrPlottingEventType.StopPinDrop: {
            this.toolbarForm.enable();
            this.droppingPins = false;
            break;
          }
          default:
            break;
        }
      });

    this.plottingEventService
      .getPlotSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((response) => {
        response.details.forEach((obr: Observation, leafletId: number) => {
          if (obr.id === 0) {
            if (this.pendingSaveNewObrPlots.findIndex((op) => op.leafletId === leafletId) === -1) {
              this.pendingSaveNewObrPlots.push(new ObservationPlot(leafletId, obr));
            }
          } else {
            if (this.existingObrPlots.findIndex((op) => op.leafletId === leafletId) === -1) {
              this.existingObrPlots.push(new ObservationPlot(leafletId, obr));
            }
          }
        });
      });

    this.plottingEventService
      .getObservationSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((response) => {
        if (response.event === ObrPlottingEventType.DeletePoints) {
          response.leafletIds.forEach((leafletId: number) => {
            const pendingSaveNewItemIndex = this.pendingSaveNewObrPlots.findIndex((op) => op.leafletId === leafletId);
            if (pendingSaveNewItemIndex !== -1) {
              this.pendingSaveNewObrPlots.splice(pendingSaveNewItemIndex, 1);
            }

            const existingItem = this.existingObrPlots.find((op) => op.leafletId === leafletId);
            if (!isNullOrUndefined(existingItem)) {
              this.pendingDeleteObrPlots.push(new ObservationPlot(leafletId, existingItem.observation));
            }
          });
        }
      });

    this.plottingEventService
      .getPinCount()
      .pipe(takeUntil(this.unsub))
      .subscribe((res) => {
        const obsForm = this.toolbarForm.get('observation');
        this.total = +(obsForm['controls']['value'].value * res).toFixed(2);
      });
  }

  public initToolbarSubscription() {
    this.toolbarEventService
      .getToolbarControlSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((response) => {
        switch (response.event) {
          case ServiceDetailEventTypes.ResetToolbar: {
            this.initForm();
            break;
          }
          case ServiceDetailEventTypes.TogglePinDrop: {
            this.droppingPins = !this.droppingPins;
            if (this.droppingPins) {
              this.toolbarForm.disable();
              this.observationTypesService
                .getObservationTypeByName(ObservationTypes.Injectables)
                .subscribe((obrType) => {
                  const formObr = this.toolbarForm.value.observation;
                  const obr = new Observation({
                    id: formObr.id,
                    typeId: obrType.id,
                    name: this.treatmentType,
                    value: formObr.value,
                    unitId: this.obrListItem.clinicSupplyType.observationUnitId,
                    details: formObr.details,
                    patientId: this.patientService.patientPanelPatient.patientId,
                    serviceId: response.service.serviceId,
                  });

                  this.observationListItemsService.assignPriceToObservation(obr);

                  this.plottingEventService.startPinDrop(obr);
                });
            } else {
              this.toolbarForm.enable();
              this.plottingEventService.stopPinDrop();
            }
            break;
          }
          default: {
            break;
          }
        }
      });
  }

  private initForm() {
    this.obrListItem = null;
    this.toolbarForm = this.fb.group({
      // treatmentType: [this.treatmentType],
      observation: this.injectionFormService.onCreateDeoxycholate(),
    });
    this.onFormChange();
    this.defaultSelections();
    // We don't change treatment types with Deoxycholate
    // this.onTreatmentTypeChange();
  }

  private defaultSelections() {
    // Set default clinic supply selection when the service list gets populated
    this.injectionFormService.observationListsPopulated$.pipe(takeUntil(this.unsub), take(1)).subscribe({
      next: () => {
        if (this.injectionFormService.deoxycholates.length === 1) {
          const supplyControl = this.toolbarForm.get('observation.details.injected');
          supplyControl.setValue(this.injectionFormService.deoxycholates[0].id);
          this.obrListTypeSelected('[0]: ' + this.injectionFormService.deoxycholates[0].id.toString());
        }
      },
    });

    // Set default area selection when the service list gets populated
    this.injectionFormService.observationListsStaticPopulated$.pipe(takeUntil(this.unsub), take(1)).subscribe({
      next: () => {
        if (this.injectionFormService.deoxycholateAreas.length === 1) {
          const areaControl = this.toolbarForm.get('observation.details.area');
          areaControl.setValue(this.injectionFormService.deoxycholateAreas[0].id);
        }
      },
    });
  }

  /*
  private onTreatmentTypeChange() {
    this.toolbarForm.get('treatmentType').valueChanges.subscribe((val) => {
      if (this.treatmentType !== val) {
        this.treatmentType = val;
        this.total = 0;
        this.initForm();
      }
    });
  }
  */

  private onFormChange() {
    this.toolbarForm.valueChanges.subscribe(() => {
      this.toolbarEventService.updateToToolbarForm(this.toolbarForm.valid || this.toolbarForm.disabled);
    });
  }

  onObrListTypeSelected(event: Event) {
    const obrListType = (event.target as HTMLSelectElement).value;
    this.obrListTypeSelected(obrListType);
  }

  private obrListTypeSelected(obrListType: string) {
    const obrListId = +obrListType.split(' ')[1];
    this.observationListItemsService
      .getObservationListItem(obrListId)
      .pipe(takeUntil(this.unsub))
      .subscribe((obrListItem) => {
        this.obrListItem = obrListItem;
      });
  }

  public getInventoryRemaining() {
    return this.observationListItemsService.getInventoryRemaining(
      this.obrListItem,
      this.pendingSaveNewObrPlots.concat(this.existingObrPlots),
      this.pendingDeleteObrPlots,
      false
    ) as number;
  }

  public getInventoryRemainingString() {
    return this.observationListItemsService.getInventoryRemaining(
      this.obrListItem,
      this.pendingSaveNewObrPlots.concat(this.existingObrPlots),
      this.pendingDeleteObrPlots,
      true
    ) as string;
  }

  public isInventoryRemainingDisplayed() {
    return true;
    /*
    return this.observationListItemsService.isInventoryRemainingDisplayed(
      this.obrListItem,
      this.pendingSaveNewObrPlots.concat(this.existingObrPlots),
      this.pendingDeleteObrPlots
    ) && !this.isTreatmentPlanning;
    */
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
