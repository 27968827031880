<h1 mat-dialog-title>Confirmation</h1>
<label id="confirmation-radio-group-label">Choose an Update Method</label>
<mat-radio-group
  [(ngModel)]="selectedMethod">
  <mat-radio-button class="confirmation-radio-button" *ngFor="let method of updateMethods" [value]="method">
    {{method}}
  </mat-radio-button>
</mat-radio-group>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="'cancel'">Cancel</button>
  <button mat-button [mat-dialog-close]=getSelectedMethodKey() cdkFocusInitial>Confirm</button>
</div>