import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MerchantDevice } from '@models/finance/merchant-device';
import { MerchantDeviceService } from '@services/merchant-device.service';
import { Subject, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-merchant-device',
  templateUrl: './edit-merchant-device.component.html',
  styleUrls: ['./edit-merchant-device.component.less'],
})
export class EditMerchantDeviceComponent implements OnInit {
  deviceIdParam: string;
  merchantDeviceForm: FormGroup;
  unsub = new Subject<any>();
  isNew: boolean;
  addOrEdit: string;
  editedMerchantDevice: MerchantDevice;
  errorMessage: string;
  loading = false;

  constructor(
    private merchantDeviceService: MerchantDeviceService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      this.deviceIdParam = params['deviceId'];
      if (this.deviceIdParam !== '_' && this.deviceIdParam != null) {
        this.merchantDeviceService.getMerchantDeviceById(this.deviceIdParam).subscribe((merchantDevice) => {
          this.initForm(merchantDevice);
          this.isNew = false;
          this.addOrEdit = 'Edit';
        });
      } else {
        this.initForm();
        this.isNew = true;
        this.addOrEdit = 'Add';
      }
    });

    this.merchantDeviceService.merchantDeviceSource$.pipe(takeUntil(this.unsub)).subscribe((res) => {
      if (res) {
        this.editedMerchantDevice = res;
        this.initForm(res);
        this.isNew = false;
        this.addOrEdit = 'Edit';
      } else {
        this.initForm();
        this.isNew = true;
        this.addOrEdit = 'Add';
      }
    });
  }

  private initForm(editedMerchantDevice?: MerchantDevice) {
    if (editedMerchantDevice) {
      this.editedMerchantDevice = editedMerchantDevice;
      this.merchantDeviceForm = this.fb.group({
        deviceFriendlyName: [editedMerchantDevice.deviceFriendlyName, [Validators.required, Validators.maxLength(12)]],
        location: [editedMerchantDevice.location, [Validators.required, Validators.maxLength(15)]],
        pairingCode: [
          { value: editedMerchantDevice.pairingCode, disabled: true },
          [Validators.required, Validators.maxLength(6)],
        ],
        deviceId: [{ value: editedMerchantDevice.deviceId, disabled: true }],
        serialNumber: [{ value: editedMerchantDevice.serialNumber, disabled: true }],
      });
    } else {
      this.merchantDeviceForm = this.fb.group({
        deviceFriendlyName: ['', [Validators.required, Validators.maxLength(12)]],
        location: ['', [Validators.required, Validators.maxLength(15)]],
        pairingCode: ['', [Validators.required, Validators.maxLength(6)]],
        deviceId: [''],
        serialNumber: [''],
      });
    }

    this.merchantDeviceForm.valueChanges.pipe(takeUntil(this.unsub)).subscribe((res) => {
      if (this.errorMessage) {
        this.errorMessage = '';
      }
    });
  }

  public cancelUpdate() {
    this.merchantDeviceService.updateMerchantDeviceData(false);
    this.router.navigate(['/management/organization/clinics/merchant-devices', { outlets: { 'action-panel': null } }]);
  }

  public updateMerchantDevice() {
    this.loading = true;
    let data: MerchantDevice;
    if (this.editedMerchantDevice) {
      data = Object.assign(this.editedMerchantDevice, this.merchantDeviceForm.value);
    } else {
      data = Object.assign({}, this.merchantDeviceForm.value);
    }

    if (this.isNew)
      this.merchantDeviceService.addMerchantDevice(data).subscribe(
        (res) => {
          this.merchantDeviceService.updateMerchantDeviceData(true);
          this.loading = false;
          this.router.navigate([
            '/management/organization/clinics/merchant-devices',
            { outlets: { 'action-panel': null } },
          ]);
        },
        (error) => {
          this.loading = false;
          if (error.error && error.message) {
            this.errorMessage = error.error;
            console.log(error.message);
          } else {
            return throwError(error);
          }
        }
      );
    else
      this.merchantDeviceService.updateMerchantDevice(data).subscribe(
        (res) => {
          this.merchantDeviceService.updateMerchantDeviceData(true);
          this.loading = false;
          this.router.navigate([
            '/management/organization/clinics/merchant-devices',
            { outlets: { 'action-panel': null } },
          ]);
        },
        (error) => {
          this.loading = false;
          if (error.error && error.message) {
            this.errorMessage = error.error;
            console.log(error.message);
          } else {
            return throwError(error);
          }
        }
      );
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
