import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Subject, Observable, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Campaign } from '@models/retention-campaigns/campaign.model';
import { DataSourceRequestState, process } from '@progress/kendo-data-query';
import { PagedDataResult } from '@models/paged-data-result.model';
import { CampaignGrid } from '@models/retention-campaigns/campaign-grid.model';
import { CampaignRunRecipient } from '@models/retention-campaigns/campaign-run-recipient';
import { CampaignPreviewRecipient } from '@models/retention-campaigns/campaign-preview-recipient';

@Injectable({
  providedIn: 'root'
})
export class RetentionCampaignsService {
  retentionCampaignSettingsUpdated = new Subject();
  retentionCampaignChanged = new Subject();
  retentionCampaignChanged$ = this.retentionCampaignChanged.asObservable();
  refreshRequired = false;

  constructor(private http: HttpClient) { }

  getCampaigns(state: DataSourceRequestState): Observable<PagedDataResult<CampaignGrid>> {
    // return this.http.get<Campaign[]>(environment.baseUrl + 'api/Communications/GetAllMailjetCampaigns').pipe(map(campaigns => {

    //   return process(campaigns, state);
    // })); //not integrating mailjet campaigns now
    return this.http.post<PagedDataResult<CampaignGrid>>(environment.baseUrl + 'api/RetentionCampaigns/GetAllCampaigns', state);

  }

  getCampaign(id: number): Observable<Campaign> {
    return this.http.get<Campaign>(environment.baseUrl + 'api/RetentionCampaigns/GetCampaign?id=' + id);
  }

  deleteCampaign(id: number): Observable<Campaign> {
    return this.http.delete<any>(environment.baseUrl + 'api/RetentionCampaigns/DeleteCampaign?id=' + id);
  }

  cloneCampaign(id: number): Observable<Campaign> {
    return this.http.post<Campaign>(environment.baseUrl + 'api/RetentionCampaigns/CloneCampaign?id=' + id, {});
  }

  getRecipientsForCampaignPreview(id: number) {
    return this.http.get<CampaignPreviewRecipient[]>(environment.baseUrl + 'api/RetentionCampaigns/GetRecipientsForCampaignPreview?id=' + id);
  }

  getPreviousRecipientsForCampaign(id: number) {
    return this.http.get<CampaignPreviewRecipient[]>(environment.baseUrl + 'api/RetentionCampaigns/GetPreviousRecipientsForCampaign?id=' + id);
  }


  save(data: Campaign) {
    return this.http.post(environment.baseUrl + 'api/RetentionCampaigns/SaveCampaign', data);
  }
}
