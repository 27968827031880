import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';
import { AddPhotoComponent } from '@app/patients/patient-tabs/patient-photos-tab/add-photo/add-photo.component';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { ClinicsService } from '@services/clinics.service';
import { ImageService } from '@services/image.service';
import { orderBy, SortDescriptor, State } from '@progress/kendo-data-query';
import { AddEvent, GridDataResult, RemoveEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-org-photos',
  templateUrl: './org-photos.component.html',
  styleUrls: ['./org-photos.component.less'],
})
export class OrgPhotosComponent implements OnInit, OnDestroy {
  disableGrid = false;
  loading = false;
  unsub: Subject<void> = new Subject<void>();
  selectedPhoto: PhotoMetaData;
  selectedPhotoIds: number[] = [];
  gridView: GridDataResult;
  gridState: State = {
    sort: [
      {
        field: 'uploadDate',
        dir: 'desc',
      },
    ],
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  constructor(
    private clinicsService: ClinicsService,
    private imageService: ImageService,
    private dialog: MatDialog,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.gridView = {
      data: [],
      total: 0,
    };

    this.clinicsService.clinicIdSelected$
      .asObservable()
      .pipe(takeUntil(this.unsub))
      .subscribe((clinicId) => {
        this.refreshData();
      });

    this.imageService
      .getClinicStockPhotos()
      .pipe(takeUntil(this.unsub))
      .subscribe((photos) => {
        this.setGridView(photos);
        this.disableGrid = false;
      });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  setGridView(photos: PhotoMetaData[]) {
    this.gridView = {
      data: orderBy(photos, this.gridState.sort),
      total: photos.length,
    };
    this.selectedPhoto = this.gridView.data.length > 0 ? this.gridView.data[0] : null;
    this.selectedPhotoIds[0] = this.gridView.data.length > 0 ? this.gridView.data[0].id : null;
  }

  refreshData() {
    this.loading = true;
    this.imageService
      .getClinicStockPhotos()
      .pipe(first())
      .subscribe((photos) => {
        this.setGridView(photos);
        this.loading = false;
      });
  }

  onAddClick(event: AddEvent) {
    const modalRef = this.modalService.open(AddPhotoComponent, { windowClass: 'add-photo-modal', centered: true });
    (modalRef.componentInstance as AddPhotoComponent).singleImageSelect = true;
    (modalRef.componentInstance as AddPhotoComponent).showGallery = false;
    (modalRef.componentInstance as AddPhotoComponent).isClinicPhoto = true;
    modalRef.result
      .then((photoMetaData) => this.refreshData())
      .catch((error) => {
        if (error !== 'Cross click') throw error;
      });
  }

  removeHandler(event: RemoveEvent) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.imageService.deletePhoto(event.dataItem).subscribe(() => {
          this.refreshData();
        });
      }
    });
  }

  sortChange(sort: SortDescriptor[]) {
    this.gridState.sort = sort;
  }

  selectionChange(event: SelectionEvent) {
    this.selectedPhoto = event.selectedRows[0].dataItem;
  }
}
