import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { PatientTransactionsComponent } from './patient-transactions.component';
import { PatientTransactionsListComponent } from './patient-transactions-list/patient-transactions-list.component';
import { PatientTransactionsItemComponent } from './patient-transactions-item/patient-transactions-item.component';
import { PatientTransactionsTabComponent } from '../../patients/patient-tabs/patient-transactions-tab/patient-transactions-tab.component';
import { TransactionsOverviewComponent } from '../../patients/patient-tabs/patient-transactions-tab/transactions-overview/transactions-overview.component';
import { Routing } from '../../app.routing';

@NgModule({
  declarations: [
    PatientTransactionsComponent,
    PatientTransactionsListComponent,
    PatientTransactionsItemComponent,
    PatientTransactionsTabComponent,
    TransactionsOverviewComponent
  ],
  imports: [CommonModule, SharedModule, Routing],
  exports: [
    PatientTransactionsComponent,
    PatientTransactionsListComponent,
    PatientTransactionsItemComponent,
    PatientTransactionsTabComponent
  ]
})
export class PatientTransactionsModule {}
