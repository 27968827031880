export const editorOptions = {
  projectId: 49368,
  tools: {
    video: {
      enabled: true,
    },
    social: {
      enabled: true,
    },
    text: {
      properties: {
        text: {
          value:
            '<p style="font-size: 14px; line-height: 140%; text-align: center;">This is a new Text block. Change the text.</p>',
        },
      },
    },
  },
  tabs: {
    images: {
      enabled: true,
    },
  },
  mergeTags: {
    patientInfo: {
      name: 'Patient Info',
      mergeTags: {
        firstName: {
          name: 'First Name',
          value: '{{FirstName}}',
        },
        lastName: {
          name: 'Last Name',
          value: '{{LastName}}',
        },
      },
    },
    clinicInfo: {
      name: 'Clinic Info',
      mergeTags: {
        name: {
          name: 'Name',
          value: '{{ClinicName}}',
        },
        address: {
          name: 'Address',
          value: '{{ClinicAddress}}',
        },
        phoneNumber: {
          name: 'Phone Number',
          value: '{{ClinicPhoneNumber}}',
        },
        email: {
          name: 'Email Address',
          value: '{{ClinicEmail}}',
        },
        website: {
          name: 'Website',
          value: '{{ClinicWebsite}}',
        },
        map: {
          name: 'Map Link',
          value: '{{ClinicMapLink}}',
        },
      },
    },
  },
};

export const emptyDesign = {
  counters: { u_column: 1, u_row: 1 },
  body: {
    rows: [
      {
        cells: [1],
        columns: [
          {
            contents: [],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              _meta: { htmlID: 'u_column_1', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: { url: '', fullWidth: true, repeat: false, center: true, cover: false },
          padding: '0px',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_1', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
    ],
    values: {
      textColor: '#000000',
      backgroundColor: '#e7e7e7',
      backgroundImage: { url: '', fullWidth: true, repeat: false, center: true, cover: false },
      contentWidth: '500px',
      contentAlign: 'center',
      fontFamily: { label: 'Arial', value: 'arial,helvetica,sans-serif' },
      preheaderText: '',
      linkStyle: {
        body: true,
        linkColor: '#0000ee',
        linkHoverColor: '#0000ee',
        linkUnderline: true,
        linkHoverUnderline: true,
      },
      _meta: { htmlID: 'u_body', htmlClassNames: 'u_body' },
    },
  },
  schemaVersion: 6,
};
