import { PhotoMetaData } from './../models/photo/photo-meta-data';
import { TagType } from '@models/tag/tag-type';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ServiceTag, SupplyTag, Tag } from '@models/tag/tag';
import { Observable, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

export interface ITagTypes {
  Service: Tag[];
  BodyPart: Tag[];
  PhotoType: Tag[];
  Supply: Tag[];
  AutoGenerated: Tag[];
}
@Injectable({
  providedIn: 'root',
})
export class TagService {
  constructor(private http: HttpClient) {}

  tags: Tag[];
  pillColours = ['#7c9a19', '#b8de36', '#e7f4bd'];

  getAllTags() {
    return this.http.get<Tag[]>(environment.baseUrl + 'api/Tag');
  }

  getAllPhotoTags() {
    return this.http.get<Tag[]>(environment.baseUrl + 'api/Tag/PhotoTags');
  }

  getTagByTagId(tagId: string) {
    return this.http.get<Tag>(environment.baseUrl + 'api/Tag/' + tagId);
  }

  getPillColors() {
    return this.pillColours.slice();
  }

  getPhotoTagsByType(): Observable<ITagTypes> {
    return this.http.get<ITagTypes>(environment.baseUrl + 'api/Tag/PhotoTagsByType');
  }

  getDocumentTags() {
    return this.http.get<Tag[]>(environment.baseUrl + 'api/Tag/DocumentTags');
  }
  getTagTypeString(type: TagType): string {
    switch (type) {
      case TagType.bodyPart:
        return 'Body Part';
      case TagType.service:
        return 'Service';
      case TagType.photoType:
        return 'Photo Type';
      case TagType.document:
        return 'Document Type';
      case TagType.autoGenerated:
        return 'Service (auto)';
      case TagType.avatar:
        return 'Avatar';
      case TagType.consentType:
        return 'Share Consent';
      case TagType.supply:
        return 'Supply';
      case TagType.custom:
      default:
        return 'Other';
    }
  }

  /**
   * @deprecated We aren't currently allowing tags to be created or edited.
   */
  addCustomTag(tag: Tag): Observable<Tag> {
    let tagId = uuidv4();
    tag.tagId = tagId;
    if (!tag.hexColour || tag.hexColour == '') delete tag.hexColour;
    return this.http.post<Tag>(`${environment.baseUrl}api/Tag`, tag);
  }

  updateCustomTag(tag: Tag): Observable<Tag> {
    if (!tag.hexColour || tag.hexColour == '') delete tag.hexColour;
    return this.http.put<Tag>(`${environment.baseUrl}api/Tag`, tag);
  }

  removeCustomTag(tag: Tag) {
    return this.http.delete<Tag>(environment.baseUrl + 'api/Tag/' + tag.tagId);
  }

  // addPhotoTag(photoId: number, tagId: string){
  //   return this.http.post<PhotoMetaData>(environment.baseUrl + 'api/Tag/PhotoTag/'+  photoId + '/' + tagId, {});
  // }

  // removePhotoTag( photoId: number, tagId: string){
  //   return this.http.delete<PhotoMetaData>(environment.baseUrl + 'api/Tag/PhotoTag/'+  photoId + '/' + tagId );
  // }

  getExternalPhotoTags(): Observable<ITagTypes> {
    return this.http.get<ITagTypes>(environment.baseUrl + 'api/Tag/GetExternalPhotoTags');
  }

  addServiceTag(clinicServiceTemplateId: number) {
    return this.http.post<ServiceTag>(environment.baseUrl + 'api/Tag/ServiceTags', clinicServiceTemplateId);
  }
  deleteServiceTag(clinicServiceTemplateId: number) {
    return this.http.delete<ServiceTag>(environment.baseUrl + 'api/Tag/ServiceTags/' + clinicServiceTemplateId);
  }
  addSupplyTag(observationListId: number) {
    return this.http.post<SupplyTag>(environment.baseUrl + 'api/Tag/SupplyTags', observationListId);
  }
  deleteSupplyTag(observationListId: number) {
    return this.http.delete<SupplyTag>(environment.baseUrl + 'api/Tag/SupplyTags/' + observationListId);
  }
}
