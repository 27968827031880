import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PasswordErrorStateMatcher } from '@app/auth/auth-component/auth-component.component';
import { AuthService } from '@app/auth/auth.service';
import { User } from '@models/user';
import { BlobService } from '@services/blob.service';
import { UsersService } from '@services/users.service';
import { Subject } from 'rxjs';
import { distinct, filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-account-dialog',
  templateUrl: './user-account-dialog.component.html',
  styleUrls: ['./user-account-dialog.component.less'],
})
export class UserAccountDialogComponent implements OnInit, OnDestroy {
  errorMessage = '';
  currentUser: User;
  selectedUser: User;
  clinicUsers: User[] = [];
  passwordFormControl = new FormControl('', [Validators.required]);
  matcher = new PasswordErrorStateMatcher();
  private unsub: Subject<void> = new Subject<void>();

  constructor(
    private usersService: UsersService,
    private authService: AuthService,
    private blobService: BlobService,
    private dialogRef: MatDialogRef<UserAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.usersService.loggedInUserUpdated$
      .pipe(
        takeUntil(this.unsub),
        filter((user) => !!user),
        distinct((user) => user.id)
      )
      .subscribe(async (currentUser) => {
        this.currentUser = currentUser;
        const readOnlySAS = this.blobService.getReadOnlySAS();
        const clinicUsers = (await this.usersService.getUsers().toPromise()).filter(
          (user) => user.id !== currentUser.id
        );
        this.clinicUsers = clinicUsers.map((user) => {
          user.avatar = user.avatar ? user.avatar.trim() + readOnlySAS : 'assets/Avatars/avatar-placeholder.png';
          return user;
        });
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  setSelectedUser(user: User) {
    this.selectedUser = user;
    this.passwordFormControl.reset('');
    this.errorMessage = '';
  }

  clearSelectedUser() {
    this.selectedUser = null;
    this.passwordFormControl.reset('');
    this.errorMessage = '';
  }

  loginSelectedUser() {
    this.errorMessage = '';
    const userName = this.selectedUser.userName;
    const password = this.passwordFormControl.value;
    this.authService.login(userName, password).subscribe(
      () => {
        this.passwordFormControl.reset('');
        this.selectedUser = null;
      },
      () => {
        this.passwordFormControl.reset('');
        this.errorMessage = 'Could not log in as the selected user.';
      }
    );
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }
}
