import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { ClinicProduct, ProductTax } from '@models/clinic-product';
import { ProductCategory } from '@models/product-category';
import { ClinicProductsService, GetProductForEditDTO } from '@services/clinic-products.service';
import { FormatterService } from '@services/formatter.service';
import { TaxService } from '@services/tax.service';
import { Tax } from '@models/tax';
import { ClinicsService } from '@services/clinics.service';
import { isNullOrUndefined } from '@app/shared/helpers';
import { ProductBrand } from '@models/product-brand';
import { TaxIndicator, TaxIndicatorTitle } from '@models/finance/tax-indicator.enum';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.less'],
})
export class EditProductComponent implements OnInit, OnDestroy {
  view: Observable<ClinicProduct>;
  editProductPanelVisible = false;
  addOrEdit = 'Add';

  unsub: Subject<void> = new Subject<void>();
  loading: boolean;

  productId: FormControl;
  name: FormControl;
  productCode: FormControl;
  quantityInStock: FormControl;
  retailPrice: FormControl;
  wholesalePrice: FormControl;
  category: FormControl;
  taxes: Tax[];
  selectedTaxes: Tax[] = [];
  quantity: FormControl;
  dollarsToLoyaltyPointRate: FormControl;
  productBrand: FormControl;
  taxIndicator: FormControl;

  categories: ProductCategory[] = [];
  productBrands: ProductBrand[] = [];

  isNew = true;

  // scaffold clinic product
  clinicProduct = new ClinicProduct({
    id: 0,
    displayName: '',
    productId: 0,
    product: { id: 0, name: '', countryCode: '' },
    productCode: '',
    quantityInStock: 0,
    retailPrice: 0,
    overrideRetailPrice: 0,
    wholesalePrice: 0,
    overrideWholesalePrice: 0,
    quantity: 1,
    usageDuration: 0,
    usageInstructions: '',
    productCategoryId: 0,
    category: {
      productCategoryId: 0,
      name: '',
    },
    productTaxes: [],
    dollarsToLoyaltyPointRate: 0,
    taxIndicator: TaxIndicator.NoTax,
  });
  taxIndicators: { title: any; value: any }[];

  constructor(
    private clinicProductsService: ClinicProductsService,
    private clinicService: ClinicsService,
    private taxService: TaxService,
    private catalogueUpdatesService: CatalogueUpdatesService,
    public formatterService: FormatterService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.productId = new FormControl();
    this.name = new FormControl();
    this.productCode = new FormControl();
    this.quantityInStock = new FormControl();
    this.retailPrice = new FormControl();
    this.wholesalePrice = new FormControl();
    this.category = new FormControl('', [Validators.required]);
    this.quantity = new FormControl();
    this.dollarsToLoyaltyPointRate = new FormControl();
    this.productBrand = new FormControl({value: '', disabled: true}, [Validators.required]);
    this.taxIndicator = new FormControl('', [Validators.required]);
  }

  ngOnInit() {
    this.loading = true;

    this.taxIndicators = Object.keys(TaxIndicatorTitle).map((e) => ({
      title: TaxIndicatorTitle[e],
      value: TaxIndicator[e],
    }));

    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      const id = params['prodid'];

      if (id && id !== '_') {
        this.clinicProductsService.getProductForEdit(id).subscribe(
          (dto: GetProductForEditDTO) => {
            if (dto.clinicProduct) {
              this.clinicProduct = dto.clinicProduct;
              this.categories = dto.productCategories;
              this.productBrands = dto.productBrands;
              this.taxes = this.getClinicTaxes(0);
              dto.clinicProduct.productTaxes.forEach((pt) => {
                this.selectedTaxes.push(pt.tax);
              });
              this.isNew = false;
              this.addOrEdit = 'Edit';
            } else {
              // TODO: decide what to do if there is no more the package in the database
            }
            this.loading = false;
          },
          (err) => {
            this.loading = false;
            // TODO: decide what to do with err
          }
        );
      } else {
        this.clinicProductsService.getListsForNewProduct().subscribe(
          (dto) => {
            this.categories = dto.productCategories;
            this.productBrands = dto.productBrands;
            this.taxes = this.getClinicTaxes(0);
            this.loading = false;
          },
          (err) => {
            this.loading = false;
            // TODO: decide what to do with err
          }
        );
      }
    });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  updateProduct() {
    const id = this.clinicProduct.id;
    const selectedTaxes = this.selectedTaxes;
    const productTaxes: ProductTax[] = [];

    selectedTaxes.forEach((t) => {
      productTaxes.push({
        clinicProductId: id,
        clinicProduct: null,
        taxId: t.taxId,
        tax: null,
      });
    });

    this.clinicProduct.productTaxes = productTaxes;

    if (this.isNew) {
      this.clinicProductsService.addProduct(this.clinicProduct).subscribe(() => {
        this.catalogueUpdatesService.refreshRequired = true;
        this.catalogueUpdatesService.catalogueUpdateComplete();
        this.router.navigate(['/management/catalogue/products', { outlets: { 'action-panel': null } }]);
      });
    } else {
      this.clinicProductsService.updateProduct(this.clinicProduct).subscribe(() => {
        this.catalogueUpdatesService.refreshRequired = true;
        this.catalogueUpdatesService.catalogueUpdateComplete();
        this.router.navigate(['/management/catalogue/products', { outlets: { 'action-panel': null } }]);
      });
    }
  }

  getClinicTaxes(clinicId: number): Tax[] {
    const clinicTaxes: Tax[] = [];
    this.clinicService.getClinics().subscribe((c) => {
      if (!isNullOrUndefined(c[0].clinicTaxes)) {
        if (c[0].clinicTaxes.length > 0) {
          c[0].clinicTaxes.forEach((ct) => {
            this.taxService.getTaxes().subscribe((taxes) => {
              taxes.forEach((t) => {
                if (t.taxId === ct.taxId) {
                  clinicTaxes.push(t);
                }
              });
              return clinicTaxes;
            });
          });
        } else {
          return clinicTaxes;
        }
      } else {
        return clinicTaxes;
      }
    });
    return clinicTaxes;
  }

  cancelUpdate() {
    this.catalogueUpdatesService.refreshRequired = false;
    this.catalogueUpdatesService.catalogueUpdateComplete();
    this.router.navigate(['/management/catalogue/products', { outlets: { 'action-panel': null } }]);
  }

  productFormValid() {
    return (
      this.clinicProduct.productCategoryId !== 0 &&
      this.clinicProduct.displayName !== '' &&
      !isNullOrUndefined(this.clinicProduct.retailPrice)
    );
  }

  compareClinicTaxObjects(o1: any, o2: any): boolean {
    return o1.name === o2.name && o1.taxId === o2.taxId;
  }
}
