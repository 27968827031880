<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>

<app-error *ngIf="errorMessage" [message]="errorMessage"></app-error>

<div [ngStyle]="{'height': 'calc(100dvh - 75px)'}">
  <kendo-grid 
    [data]="gridView"
    [pageSize]="gridState.take"
    [skip]="gridState.skip"
    [sort]="gridState.sort"
    [sortable]="true"
    [selectable]="true"
    [style.height.%]="100"
    (add)="onAddClick($event)"
    (cancel)="cancelHandler($event)"
    (save)="saveHandler($event)"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)">
    <ng-template kendoGridToolbarTemplate>
      <div class="d-flex">
        <span class="pl-4 search-box border border-radius">
          <span class="fa fa-search"></span>
          <mat-form-field class="w-75 p-3">
            <input matInput placeholder="Search..." aria-label="Search Doctors" [(ngModel)]="searchValue"
              [formControl]="searchCtrl">
            <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="searchValue=''">
              <mat-icon><i class="fa fa-times-circle"></i></mat-icon>
            </button>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let doctor of filteredDoctors | async"
                [value]="doctor.firstName + ' ' + doctor.lastName">
                <span>{{doctor.firstName}} {{doctor.lastName}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </span>
      </div>
      <button kendoGridAddCommand class="p-2 btn em-btn"><i class="fal fa-plus-circle"></i> Add Doctor</button>
    </ng-template>
    <kendo-grid-column field="specialty" title="Specialty" [width]="85"></kendo-grid-column>
    <kendo-grid-column field="proTitle" title="Title" [width]="40"></kendo-grid-column>
    <kendo-grid-column field="firstName" title="First Name" [width]="85"></kendo-grid-column>
    <kendo-grid-column field="lastName" title="Last Name" [width]="100"></kendo-grid-column>
    <kendo-grid-column field="phoneNumber" title="Phone #" [width]="65"></kendo-grid-column>
    <kendo-grid-column field="faxNumber" title="Fax #" [width]="65"></kendo-grid-column>
    <kendo-grid-column field="email" title="Email Address" [width]="125"></kendo-grid-column>
    <kendo-grid-column field="website" title="Website" [width]="125"></kendo-grid-column>
    <kendo-grid-command-column title="Actions" min-width="125px" [width]="125">
      <ng-template kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridEditCommand class="btn kendo-btn"><i class="fas fa-edit"></i></button>
        <button kendoGridRemoveCommand class="btn kendo-btn"><i class="fas fa-minus-circle"></i></button>
        <button kendoGridSaveCommand [disabled]="formGroup?.invalid">{{ isNew ? 'Add' : 'Update' }}</button>
        <button kendoGridCancelCommand>{{ isNew ? 'Discard changes' : 'Cancel' }}</button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>

<p-overlayPanel #hoverPanel id="hoverPanel" [dismissable]="false">
  Content
</p-overlayPanel>

<app-actionpanel></app-actionpanel>
