import { Platform } from '@angular/cdk/platform';
import {
  AfterContentInit,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UnlayerEmailEditorComponent } from '@app/shared/components/unlayer-email-editor/unlayer-email-editor.component';
import { ClinicEmailTemplate, EmailTemplateType } from '@models/clinic-email-template';
import { ServiceOrProduct, ServiceOrProductType } from '@models/communications/service-or-product.model';
import { editorOptions } from '@models/email-editor-options';
import { ObservationListItem } from '@models/observation/observation';
import { Campaign } from '@models/retention-campaigns/campaign.model';
import { RecipientRequestType } from '@models/retention-campaigns/recipient-request-types';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { WeekDay } from '@models/week-day';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ActionPanelService } from '@services/actionpanel.service';
import { ClinicEmailTemplateService } from '@services/clinic-email-template.service';
import { ClinicProductsService } from '@services/clinic-products.service';
import { ObservationListItemsService } from '@services/observation-list-items.service';
import { RetentionCampaignsService } from '@services/retention-campaigns.service';
import { ServiceProviderService } from '@services/service-provider.service';
import { ServiceTemplatesService } from '@services/service-templates.service';
import * as moment from 'moment';
import { Observable, Subject, combineLatest, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { RetentionCampaignType } from '../../../../models/retention-campaigns/retention-campaign-types';
import { ClinicSuppliesSelectorModalComponent } from '../../../dialogs/clinic-supplies-selector-modal/clinic-supplies-selector-modal.component';
import { SendTestEmailComponent } from '../../../dialogs/send-test-email/send-test-email.component';
import { ConfirmDeleteDialogComponent } from './../../../dialogs/confirm-delete/confirm-delete.component';
import { GenericDialogComponent } from './../../../dialogs/generic-confirm/generic-confirm.component';
@Component({
  selector: 'app-edit-retention-campaign',
  templateUrl: './edit-retention-campaign.component.html',
  styleUrls: ['./edit-retention-campaign.component.less'],
})
export class EditRetentionCampaignComponent implements OnInit, OnDestroy, AfterContentInit {
  @ViewChild('instance') instance: NgbTypeahead;
  @ViewChild('elem') elem: ElementRef<HTMLInputElement>;
  @ViewChild('instanceNegative') instanceNegative: NgbTypeahead;
  @ViewChild('elemNegative') elemNegative: ElementRef<HTMLInputElement>;

  private unsub: Subject<void> = new Subject<void>();
  loading = false;
  errorMessage = '';
  templateLoading = false;
  disabledForm = false;
  isRecurringRBCheck = false;
  actionType = 'Create';
  saveBtnName = 'Create';
  form: FormGroup;
  campaignStatuses = ['Draft', 'Active', 'Suspended'];
  campaignType: 'Email' | 'SMS' | 'Email/SMS' = 'Email';
  smsCampaignText: string = 'Hey [FirstName], remember to check back in with [ClinicName] soon! We miss you';
  timePeriodUnits: string[] = [
    //'minutes', leave only weeks for now as the other units are not supported at the moment
    // 'days',
    'weeks',
    // 'months',
  ];
  iPad =
    navigator.userAgent.match(/(iPad)/) /* iOS pre 13 */ ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) /* iPad OS 13 */;

  //We have a second array for other controls that need more options
  timePeriodUnitsSecondary: string[] = ['days', 'weeks', 'months'];
  lessMoreTypes: string[] = ['less than', 'more than'];
  firstLastTypes: string[] = ['first', 'last'];
  genderTypes: string[] = ['Male', 'Female', 'X'];
  beforeAfterTypes: string[] = ['before', 'after'];
  numberOfDays: string[] = ['number of days'];
  showSuccessMessage = false;
  emailTemplates: ClinicEmailTemplate[] = [];
  serviceProviders = [];
  focus$ = new Subject<string>();
  focusNegative$ = new Subject<string>();
  click$ = new Subject<string>();
  allServicesOrProducts: ServiceOrProduct[] = [];
  selectedServicesOrProducts: ServiceOrProduct[] = [];
  selectedServicesOrProductsNegative: ServiceOrProduct[] = [];
  selectedClinicSupplies = [
    {
      detailTemplate: ServiceDetailTemplate.Injections,
      supplies: Array<ObservationListItem>(),
    },
    {
      detailTemplate: ServiceDetailTemplate.Coolsculpting,
      supplies: Array<ObservationListItem>(),
    },
  ];
  selectedClinicSuppliesNegative = [
    {
      detailTemplate: ServiceDetailTemplate.Injections,
      supplies: Array<ObservationListItem>(),
    },
    {
      detailTemplate: ServiceDetailTemplate.Coolsculpting,
      supplies: Array<ObservationListItem>(),
    },
  ];
  submitted = false;
  public min: Date;
  beginningYear = moment().startOf('year').toDate();
  endYear = moment().endOf('year').toDate();
  initialized = false;

  clinicSupplies = [
    {
      detailTemplate: ServiceDetailTemplate.Injections,
      supplies: Array<ObservationListItem>(),
    },
    {
      detailTemplate: ServiceDetailTemplate.Coolsculpting,
      supplies: Array<ObservationListItem>(),
    },
  ];

  ServiceDetailEnum = ServiceDetailTemplate;

  selectedTemplateSrc: string = null;

  isOpenRecipientsModal: boolean = false;
  campaignId: number = 0;
  @ViewChild(UnlayerEmailEditorComponent) emailEditor: UnlayerEmailEditorComponent;
  editorOptions = editorOptions;
  emailDetailsObj: object;
  emailHtml: string;
  emailEditorEdited: boolean = false;
  patientPanelOpen: boolean = false;
  recipientRequestType: RecipientRequestType;

  weekdays = Object.keys(WeekDay);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private retentionCampaignsService: RetentionCampaignsService,
    private providerService: ServiceProviderService,
    private serviceTemplatesService: ServiceTemplatesService,
    private productsService: ClinicProductsService,
    private obrListItemService: ObservationListItemsService,
    private dialog: MatDialog,
    private actionPanelService: ActionPanelService,
    private zone: NgZone,
    private clinicEmailTemplateService: ClinicEmailTemplateService,
    public platform: Platform
  ) {}

  ngOnInit() {
    this.initForm();
    this.recipientRequestType = RecipientRequestType.Preview;
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      if (params['id'] !== '_') {
        this.actionType = 'Edit';
        this.saveBtnName = 'Save';
        this.campaignId = +params['id'];
        this.populateCampaignValues(this.campaignId);
      } else {
        this.loadLookups();
      }
      setTimeout(() => {
        this.initialized = true;
      });
    });

    this.min = moment().toDate();
  }

  ngAfterContentInit(): void {
    //this.emailEditor.minHeight = '750px';
  }

  get f() {
    return this.form.controls;
  }

  initForm() {
    this.form = new FormGroup({
      campaignType: new FormControl('Email', Validators.required),
      smsCampaignText: new FormControl(this.smsCampaignText, Validators.required),
      title: new FormControl('', Validators.required),
      emailSubjectLine: new FormControl('', Validators.required),
      template: new FormControl(''),
      status: new FormControl(this.campaignStatuses[0]),
      whenSend: new FormControl('once', Validators.required),
      isRecurring: new FormControl(false),

      dontEmailCheckbox: new FormControl(false),
      dontEmailPeriodValue: new FormControl(90),
      dontEmailPeriodUnit: new FormControl('days'),

      futureBirthdayCheckbox: new FormControl(false),
      futureBirthdayPeriodValue: new FormControl(14),
      futureBirthdayPeriodUnit: new FormControl('days'),

      startOnDate: new FormControl(null, Validators.required),
      startOnTime: new FormControl(null, Validators.required),

      recurringStartOnDate: new FormControl(null),
      repeatPeriodValue: new FormControl(1),
      repeatPeriodUnit: new FormControl('weeks'),
      selectedDayOfWeek: new FormControl({ value: null, disabled: true }),
      recurringSendTime: new FormControl(null),
      endOnCheckbox: new FormControl({ value: false, disabled: true }),
      endOnDate: new FormControl({ value: null, disabled: true }),

      sendTo: new FormControl('all'),
      gender: new FormControl(),
      birthdayBefore: new FormControl(),
      birthdayAfter: new FormControl(),
      minBirthYear: new FormControl(null, [Validators.minLength(4), Validators.maxLength(4)]),
      maxBirthYear: new FormControl(null, [Validators.minLength(4), Validators.maxLength(4)]),
      timeElapsedCheckbox: new FormControl(false),
      timeElapsedLessMore: new FormControl(),
      timeElapsedValue: new FormControl(),
      timeElapsedFirstLast: new FormControl(),
      timeElapsedCheckbox2: new FormControl(false),
      timeElapsedLessMore2: new FormControl(),
      timeElapsedValue2: new FormControl(),
      timeElapsedFirstLast2: new FormControl(),

      visitDateCheckbox: new FormControl(false),
      visitDateFirstLast: new FormControl(),
      visitDateBeforeAfter: new FormControl(),
      visitDateValue: new FormControl(),
      visitDateCheckbox2: new FormControl(false),
      visitDateFirstLast2: new FormControl(),
      visitDateBeforeAfter2: new FormControl(),
      visitDateValue2: new FormControl(),

      lastProviderCheckbox: new FormControl(false),
      lastProviderId: new FormControl(),

      amountOfVisitsCheckbox: new FormControl(false),
      amountOfVisitsMoreLess: new FormControl(),
      amountOfVisitsValue: new FormControl(),

      amountOfLoyaltyPointsCheckbox: new FormControl(false),
      amountOfLoyaltyPointsMoreLess: new FormControl('more than'),
      amountOfLoyaltyPointsValue: new FormControl(100),

      amountSpentCheckbox: new FormControl(false),
      amountSpentMoreLess: new FormControl('more than'),
      amountSpentValue: new FormControl(100),
      //amountSpentSinceDate: new FormControl(1),
      amountSpentRepeatPeriodValue: new FormControl(1),
      amountSpentRepeatPeriodUnit: new FormControl('days'),

      //Old fields
      //purchaseDateStartOn: new FormControl(),
      //purchaseDateEndOn: new FormControl(),

      filterProductServiceStartPeriod: new FormControl(1),
      filterProductServiceEndPeriod: new FormControl(3),
      filterProductServicePeriodUnit: new FormControl('months'),
      //Change when field implemeted
      hasPurchasedCheckbox: new FormControl(false),
      //Change when field implemeted
      hasNotPurchasedCheckbox: new FormControl(false),
      notPurchasedSinceCheckbox: new FormControl(false),
      notPurchasedSinceNegativeCheckbox: new FormControl(false),
      //Old fields
      //noFutureAppointmentsSinceCheckbox: new FormControl(false),
    });

    this.form
      .get('template')
      .valueChanges.pipe(takeUntil(this.unsub))
      .subscribe((template: ClinicEmailTemplate) => {
        // this.getTemplateDetails();
        this.emailEditor.editor.loadDesign(JSON.parse(template.definition));
      });
    this.form
      .get('hasPurchasedCheckbox')
      .valueChanges.pipe(takeUntil(this.unsub))
      .subscribe((newVal) => {
        if (newVal === false) this.form.get('notPurchasedSinceCheckbox').setValue(false);
        //Old field
        //this.form.get('noFutureAppointmentsSinceCheckbox').setValue(false);
      });

    this.form
      .get('hasNotPurchasedCheckbox')
      .valueChanges.pipe(takeUntil(this.unsub))
      .subscribe((newVal) => {
        if (newVal === false) this.form.get('notPurchasedSinceNegativeCheckbox').setValue(false);
      });

    // Change required fields when campaign type changes.
    this.form
      .get('campaignType')
      .valueChanges.pipe(takeUntil(this.unsub))
      .subscribe((newType) => {
        const emailSubjectLineControl = this.form.get('emailSubjectLine');
        const smsCampaignTextControl = this.form.get('smsCampaignText');
        emailSubjectLineControl.setValidators(null);
        smsCampaignTextControl.setValidators(null);
        switch (newType) {
          case 'Email':
            emailSubjectLineControl.setValidators(Validators.required);
            break;
          case 'SMS':
            smsCampaignTextControl.setValidators(Validators.required);
            break;
          case 'Email/SMS':
            emailSubjectLineControl.setValidators(Validators.required);
            smsCampaignTextControl.setValidators(Validators.required);
            break;
        }
        emailSubjectLineControl.updateValueAndValidity();
        smsCampaignTextControl.updateValueAndValidity();
      });
  }

  loadLookups() {
    this.loading = true;
    this.errorMessage = '';
    combineLatest([
      this.clinicEmailTemplateService.getClinicEmailTemplatesByType(EmailTemplateType.RetentionCampaign),
      this.providerService.getServiceProviders(),
      this.serviceTemplatesService.getServiceTemplates(),
      this.productsService.getProducts(),
      this.obrListItemService.getClinicSupplies(),
    ])
      .pipe(takeUntil(this.unsub))
      .subscribe({
        next: ([emailTemplates, serviceProviders, serviceTemplates, products, clinicSupplies]) => {
          this.populateLookups(emailTemplates, serviceProviders, serviceTemplates, products, clinicSupplies, null);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
          this.errorMessage = 'Could not load campaign';
        },
      });
  }

  populateLookups(emailTemplates, serviceProviders, serviceTemplates, products, clinicSupplies, campaign) {
    this.emailTemplates = emailTemplates;

    if (
      emailTemplates.length > 0 &&
      !(
        campaign &&
        campaign.createdFromEmailTemplateId &&
        emailTemplates.findIndex((et) => et.id == campaign.createdFromEmailTemplateId) != -1
      )
    ) {
      const selectedTemplate = emailTemplates[0];
      //this.form.controls['template'].setValue(selectedTemplate);
    }
    this.serviceProviders = serviceProviders;

    this.allServicesOrProducts = [
      ...serviceTemplates.map((st: ClinicServiceTemplate) => {
        const sop = new ServiceOrProduct();
        sop.type = ServiceOrProductType.service;
        sop.id = st.id;
        sop.name = st.serviceName;
        sop.serviceDetailTemplateId = st.serviceDetailTemplateId;
        return sop;
      }),
      ...products.map((p) => {
        const sop = new ServiceOrProduct();
        sop.type = ServiceOrProductType.product;
        sop.id = p.productId;
        sop.name = p.name;
        return sop;
      }),
    ];

    clinicSupplies
      .filter(
        (supplies) =>
          supplies.clinicSupplyType.observationListTypeId == 5 ||
          supplies.clinicSupplyType.observationListTypeId == 7 ||
          supplies.clinicSupplyType.observationListTypeId == 8
      )
      .forEach((supply) => {
        let index = this.clinicSupplies.findIndex(
          (s) => s.detailTemplate == supply.clinicSupplyType.observationListType.observationTypeId
        );
        this.clinicSupplies[index].supplies.push(supply);
      });
  }

  populateCampaignValues(campaignId: number) {
    this.loading = true;
    this.errorMessage = '';
    combineLatest([
      this.retentionCampaignsService.getCampaign(campaignId),
      this.clinicEmailTemplateService.getClinicEmailTemplatesByType(EmailTemplateType.RetentionCampaign),
      this.providerService.getServiceProviders(),
      this.serviceTemplatesService.getServiceTemplates(),
      this.productsService.getProducts(),
      this.obrListItemService.getClinicSupplies(),
    ])
      .pipe(takeUntil(this.unsub))
      .subscribe({
        next: ([campaign, emailTemplates, serviceProviders, serviceTemplates, products, clinicSupplies]) => {
          this.populateLookups(emailTemplates, serviceProviders, serviceTemplates, products, clinicSupplies, campaign);

          if (campaign == null) {
            this.loading = false;
            return;
          }

          if (this.emailEditor?.editor && campaign.emailJSON && campaign.emailJSON != '') {
            this.templateLoading = true;
            this.emailDetailsObj = JSON.parse(campaign.emailJSON);
            this.emailEditor.editor.loadDesign(this.emailDetailsObj);
            this.emailHtml = campaign.emailHTML;
          }

          let selectedTemplate = this.emailTemplates.find((_) => _.id === campaign.createdFromEmailTemplateId);

          this.form.controls['title'].setValue(campaign.title);
          this.form.controls['campaignType'].setValue(
            campaign.campaignType == RetentionCampaignType.Email
              ? 'Email'
              : campaign.campaignType == RetentionCampaignType.SMS
              ? 'SMS'
              : 'Email/SMS'
          );
          this.form.controls['smsCampaignText'].setValue(campaign.smsCampaignText);
          this.form.controls['emailSubjectLine'].setValue(campaign.emailSubjectLine);

          this.form.controls['template'].setValue(selectedTemplate, { emitEvent: false });
          if (campaign.status) {
            this.form.controls['status'].setValue(this.campaignStatuses[campaign.status]);
          }
          this.form.controls['whenSend'].setValue('once');
          this.form.controls['startOnDate'].setValue(new Date(campaign.startOnDate));

          //Format sendTime to be a Date object
          var sendTime = moment().utc().startOf('day').add(campaign.sendTime).toDate();

          this.form.controls['startOnTime'].setValue(sendTime);
          if (campaign.isRecurring) {
            this.form.controls['isRecurring'].setValue(true);
            this.isRecurringRBCheck = true;
            this.form.controls['recurringStartOnDate'].setValue(new Date(campaign.startOnDate));
            this.form.controls['whenSend'].setValue('recurring');
            this.form.controls['selectedDayOfWeek'].setValue(campaign.dayToSend);
            this.form.controls['recurringSendTime'].setValue(sendTime);
            if (campaign.endOnDate) {
              this.form.controls['endOnCheckbox'].setValue(true);
              this.form.controls['endOnDate'].setValue(new Date(campaign.endOnDate));
            }
            this.parseRepeatPeriod(campaign.repeatPeriod, 'repeatPeriodValue', 'repeatPeriodUnit');
          }

          this.whenSendChanged(this.form.controls['whenSend'].value);

          if (campaign.dontEmailPeriod && campaign.dontEmailPeriodUnit) {
            this.form.controls['dontEmailCheckbox'].setValue(true);
            this.form.controls['dontEmailPeriodValue'].setValue(campaign.dontEmailPeriod);
            this.form.controls['dontEmailPeriodUnit'].setValue(campaign.dontEmailPeriodUnit);
          } else {
            this.form.controls['dontEmailCheckbox'].setValue(false);
          }

          if (campaign.futureBirthdayPeriod && campaign.futureBirthdayPeriodUnit) {
            this.form.controls['futureBirthdayCheckbox'].setValue(true);
            this.form.controls['futureBirthdayPeriodValue'].setValue(campaign.futureBirthdayPeriod);
            this.form.controls['futureBirthdayPeriodUnit'].setValue(campaign.futureBirthdayPeriodUnit);
          } else {
            this.form.controls['futureBirthdayCheckbox'].setValue(false);
          }

          this.form.controls['sendTo'].setValue('targeted');

          if (campaign.completed) {
            this.form.disable();
            this.disabledForm = true;
          }

          if (campaign.sendToAll === true) {
            this.form.controls['sendTo'].setValue('all');
            this.loading = false;
            return;
          }

          this.form.controls['gender'].setValue(campaign.gender);
          this.form.controls['minBirthYear'].setValue(campaign.minBirthYear);
          this.form.controls['maxBirthYear'].setValue(campaign.maxBirthYear);
          if (campaign.birthdayAfter) {
            this.form.controls['birthdayAfter'].setValue(new Date(campaign.birthdayAfter));
          }
          if (campaign.birthdayBefore) {
            this.form.controls['birthdayBefore'].setValue(new Date(campaign.birthdayBefore));
          }

          this.parseTimeElapsed(
            campaign.timeElapsedSinceVisit,
            'timeElapsedCheckbox',
            'timeElapsedLessMore',
            'timeElapsedValue',
            'timeElapsedFirstLast'
          );

          this.parseTimeElapsed(
            campaign.andTimeElapsedSinceVisit,
            'timeElapsedCheckbox2',
            'timeElapsedLessMore2',
            'timeElapsedValue2',
            'timeElapsedFirstLast2'
          );

          this.parseVisitDate(
            campaign.visitDateWasBefore,
            'visitDateCheckbox',
            'visitDateFirstLast',
            'visitDateBeforeAfter',
            'visitDateValue'
          );
          this.parseVisitDate(
            campaign.andVisitDateWasBefore,
            'visitDateCheckbox2',
            'visitDateFirstLast2',
            'visitDateBeforeAfter2',
            'visitDateValue2'
          );

          if (campaign.lastStaffId) {
            this.form.controls['lastProviderCheckbox'].setValue(true);
            this.form.controls['lastProviderId'].setValue(campaign.lastStaffId);
          }

          this.parseAmountExpression(
            campaign.amountOfVisits,
            'amountOfVisitsCheckbox',
            'amountOfVisitsMoreLess',
            'amountOfVisitsValue'
          );

          this.parseAmountExpression(
            campaign.amountOfLoyaltyPoints,
            'amountOfLoyaltyPointsCheckbox',
            'amountOfLoyaltyPointsMoreLess',
            'amountOfLoyaltyPointsValue'
          );

          this.parseAmountExpressionWithPeriod(
            campaign.amountSpent,
            'amountSpentCheckbox',
            'amountSpentMoreLess',
            'amountSpentValue',
            'amountSpentRepeatPeriodValue',
            'amountSpentRepeatPeriodUnit'
          );

          // Purchased Services and Products filters
          // Time span
          if (campaign.filterProductServiceStartPeriod) {
            this.form.controls['filterProductServiceStartPeriod'].setValue(campaign.filterProductServiceStartPeriod);
          }

          if (campaign.filterProductServiceEndPeriod) {
            this.form.controls['filterProductServiceEndPeriod'].setValue(campaign.filterProductServiceEndPeriod);
          }

          if (campaign.filterProductServicePeriodUnit) {
            this.form.controls['filterProductServicePeriodUnit'].setValue(campaign.filterProductServicePeriodUnit);
          }

          //Has Purchased
          if (campaign.hasPurchasedFilter) {
            this.form.controls['hasPurchasedCheckbox'].setValue(true);
          }

          if (campaign.serviceIds) {
            campaign.serviceIds.split(',').forEach((id) => {
              const service = this.allServicesOrProducts.find(
                (_) => _.type === ServiceOrProductType.service && _.id === +id
              );
              if (service) this.selectedServicesOrProducts.push(service);
            });
          }
          if (campaign.productIds) {
            campaign.productIds.split(',').forEach((id) => {
              const product = this.allServicesOrProducts.find(
                (_) => _.type === ServiceOrProductType.product && _.id === +id
              );
              if (product) this.selectedServicesOrProducts.push(product);
            });
          }
          if (campaign.obsListIds) {
            let obsList = campaign.obsListIds.split(',');
            let observationsInjections = obsList
              .map((id) =>
                this.clinicSupplies
                  .find((supply) => supply.detailTemplate == ServiceDetailTemplate.Injections)
                  .supplies.find((obs1) => obs1.id == +id)
              )
              .filter(Boolean);
            let observationsCS = obsList
              .map((id) =>
                this.clinicSupplies
                  .find((supply) => supply.detailTemplate == ServiceDetailTemplate.Coolsculpting)
                  .supplies.find((obs1) => obs1.id == +id)
              )
              .filter(Boolean);
            this.selectedClinicSupplies = [
              {
                detailTemplate: ServiceDetailTemplate.Injections,
                supplies: observationsInjections,
              },
              {
                detailTemplate: ServiceDetailTemplate.Coolsculpting,
                supplies: observationsCS,
              },
            ];
          }

          // if (campaign.arePurchased === true) {
          //   this.form.controls['arePurchasedHasNot'].setValue('has');
          // } else {
          //   this.form.controls['arePurchasedHasNot'].setValue('has not');
          // }

          // if (campaign.purchaseDateStartOn) {
          //   this.form.controls['purchaseDateStartOn'].setValue(new Date(campaign.purchaseDateStartOn));

          // }
          // if (campaign.purchaseDateEndOn) {
          //   this.form.controls['purchaseDateEndOn'].setValue(new Date(campaign.purchaseDateEndOn));
          // }

          //Has Not Purchased
          if (campaign.hasNotPurchasedFilter) {
            this.form.controls['hasNotPurchasedCheckbox'].setValue(true);
          }
          if (campaign.serviceIdsNegative) {
            campaign.serviceIdsNegative.split(',').forEach((id) => {
              const service = this.allServicesOrProducts.find(
                (_) => _.type === ServiceOrProductType.service && _.id === +id
              );
              if (service) this.selectedServicesOrProductsNegative.push(service);
            });
          }
          if (campaign.productIdsNegative) {
            campaign.productIdsNegative.split(',').forEach((id) => {
              const product = this.allServicesOrProducts.find(
                (_) => _.type === ServiceOrProductType.product && _.id === +id
              );
              if (product) this.selectedServicesOrProductsNegative.push(product);
            });
          }
          if (campaign.obsListIdsNegative) {
            let obsList = campaign.obsListIdsNegative.split(',');
            let observationsInjections = obsList
              .map((id) =>
                this.clinicSupplies
                  .find((supply) => supply.detailTemplate == ServiceDetailTemplate.Injections)
                  .supplies.find((obs1) => obs1.id == +id)
              )
              .filter(Boolean);
            let observationsCS = obsList
              .map((id) =>
                this.clinicSupplies
                  .find((supply) => supply.detailTemplate == ServiceDetailTemplate.Coolsculpting)
                  .supplies.find((obs1) => obs1.id == +id)
              )
              .filter(Boolean);
            this.selectedClinicSuppliesNegative = [
              {
                detailTemplate: ServiceDetailTemplate.Injections,
                supplies: observationsInjections,
              },
              {
                detailTemplate: ServiceDetailTemplate.Coolsculpting,
                supplies: observationsCS,
              },
            ];
          }

          //Have not purchased since AND have no future appointments
          if (campaign.notPurchasedSince === true) {
            this.form.controls['notPurchasedSinceCheckbox'].setValue(true);
          }

          if (campaign.notPurchasedSinceNegative === true) {
            this.form.controls['notPurchasedSinceNegativeCheckbox'].setValue(true);
          }
          // if (campaign.noFutureAppointmentsSince === true) {
          //   this.form.controls['noFutureAppointmentsSinceCheckbox'].setValue(true);
          // }

          // if (campaign.emailJSON && campaign.emailHTML) {
          //   this.addVirtualTemplateToList(campaign);
          //   this.form.controls['template'].setValue(this.emailTemplates[0], { emitEvent: false });
          // }

          this.loading = false;
        },
        error: () => {
          this.loading = false;
          this.errorMessage = 'Could not load campaign';
        },
      });
  }

  getObservationFilterString(detailTemplateEnum: ServiceDetailTemplate, positive = true) {
    if (
      detailTemplateEnum != ServiceDetailTemplate.Coolsculpting &&
      detailTemplateEnum != ServiceDetailTemplate.Injections
    )
      return '';
    var supplies;
    if (positive) {
      supplies = this.selectedClinicSupplies.find((supply) => supply.detailTemplate == detailTemplateEnum).supplies;
    } else {
      supplies = this.selectedClinicSuppliesNegative.find(
        (supply) => supply.detailTemplate == detailTemplateEnum
      ).supplies;
    }
    const count = supplies.length;
    let res = '';
    if (count == 0) res = ' (All supplies)';
    else {
      res += '(';
      for (let i = 0; i < count - 1; i++) {
        res += `${supplies[i].name}, `;
      }
      res += `${supplies[count - 1].name})`;
    }
    return res;
  }

  saveChanges() {
    this.loading = true;
    this.errorMessage = '';
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    var sendDateTime;
    const data = new Campaign();
    data.id = this.campaignId;
    data.title = this.form.controls['title'].value;
    data.status = this.campaignStatuses.indexOf(this.form.controls['status'].value);
    let ct = this.form.controls['campaignType'].value;
    data.campaignType =
      ct == 'Email'
        ? RetentionCampaignType.Email
        : ct == 'SMS'
        ? RetentionCampaignType.SMS
        : RetentionCampaignType.EmailSms;
    if (ct != 'SMS') {
      if (this.form.controls['template'].value != null)
        data.createdFromEmailTemplateId = this.form.controls['template'].value.id;
    }
    data.smsCampaignText = this.form.controls['smsCampaignText'].value;
    data.emailSubjectLine = this.form.controls['emailSubjectLine'].value;
    data.isRecurring = this.form.controls['isRecurring'].value;
    if (this.form.controls['whenSend'].value === 'recurring') {
      //need to combine for correct time zone
      sendDateTime = this.form.controls['recurringStartOnDate'].value;
      sendDateTime.setHours(this.form.controls['recurringSendTime'].value.getHours());
      sendDateTime.setMinutes(this.form.controls['recurringSendTime'].value.getMinutes());

      data.startOnDate = sendDateTime;
      data.sendTime = this.stripTimeAsDuration(this.form.controls['recurringSendTime'].value);
      data.dayToSend = this.form.controls['selectedDayOfWeek'].value;
      if (this.form.controls['endOnCheckbox'].value === true) {
        //need to combine for correct time zone
        var endDateTime = this.form.controls['endOnDate'].value;
        endDateTime.setHours(this.form.controls['recurringSendTime'].value.getHours());
        endDateTime.setMinutes(this.form.controls['recurringSendTime'].value.getMinutes());

        data.endOnDate = endDateTime;
        if (moment(data.endOnDate) < moment(data.startOnDate)) {
          data.endOnDate = data.startOnDate;
        }
      }
      data.repeatPeriod = this.formatRepeatPeriod('repeatPeriodValue', 'repeatPeriodUnit');
    } else {
      //need to combine for correct time zone
      sendDateTime = this.form.controls['startOnDate'].value;
      sendDateTime.setHours(this.form.controls['startOnTime'].value.getHours());
      sendDateTime.setMinutes(this.form.controls['startOnTime'].value.getMinutes());

      data.startOnDate = sendDateTime;
      data.sendTime = this.stripTimeAsDuration(this.form.controls['startOnTime'].value);
    }

    data.sendToAll = this.form.controls['sendTo'].value === 'all';

    if (this.form.controls['dontEmailCheckbox'].value) {
      data.dontEmailPeriod = this.form.controls['dontEmailPeriodValue'].value;
      data.dontEmailPeriodUnit = this.form.controls['dontEmailPeriodUnit'].value;
    } else {
      data.dontEmailPeriod = null;
      data.dontEmailPeriodUnit = null;
    }

    if (this.form.controls['futureBirthdayCheckbox'].value) {
      data.futureBirthdayPeriod = this.form.controls['futureBirthdayPeriodValue'].value;
      data.futureBirthdayPeriodUnit = this.form.controls['futureBirthdayPeriodUnit'].value;
    } else {
      data.futureBirthdayPeriod = null;
      data.futureBirthdayPeriodUnit = null;
    }

    if (!data.sendToAll) {
      data.timeElapsedSinceVisit = this.formatTimeElapsed(
        'timeElapsedCheckbox',
        'timeElapsedLessMore',
        'timeElapsedValue',
        'timeElapsedFirstLast'
      );
      data.andTimeElapsedSinceVisit = this.formatTimeElapsed(
        'timeElapsedCheckbox2',
        'timeElapsedLessMore2',
        'timeElapsedValue2',
        'timeElapsedFirstLast2'
      );

      data.visitDateWasBefore = this.formatVisitDate(
        'visitDateCheckbox',
        'visitDateFirstLast',
        'visitDateBeforeAfter',
        'visitDateValue'
      );
      data.andVisitDateWasBefore = this.formatVisitDate(
        'visitDateCheckbox2',
        'visitDateFirstLast2',
        'visitDateBeforeAfter2',
        'visitDateValue2'
      );

      if (this.form.controls['lastProviderCheckbox'].value === true) {
        data.lastStaffId = this.form.controls['lastProviderId'].value;
      }

      data.amountOfVisits = this.formatAmountExpression(
        'amountOfVisitsCheckbox',
        'amountOfVisitsMoreLess',
        'amountOfVisitsValue',
        'visits'
      );

      data.amountOfLoyaltyPoints = this.formatAmountExpression(
        'amountOfLoyaltyPointsCheckbox',
        'amountOfLoyaltyPointsMoreLess',
        'amountOfLoyaltyPointsValue',
        'loyalty points'
      );

      data.amountSpent = this.formatAmountExpressionWithPeriod(
        'amountSpentCheckbox',
        'amountSpentMoreLess',
        'amountSpentValue',
        'amountSpentRepeatPeriodValue',
        'amountSpentRepeatPeriodUnit'
      );

      // data.amountSpent = this.formatAmountExpressionWithDate(
      //   'amountSpentCheckbox',
      //   'amountSpentMoreLess',
      //   'amountSpentValue',
      //   'amountSpentSinceDate'
      // );

      data.gender = this.form.controls['gender'].value;
      data.minBirthYear = this.form.controls['minBirthYear'].value;
      data.maxBirthYear = this.form.controls['maxBirthYear'].value;

      if (this.form.controls['birthdayBefore'].value) {
        data.birthdayBefore = this.form.controls['birthdayBefore'].value;
      }
      if (this.form.controls['birthdayAfter'].value) {
        data.birthdayAfter = this.form.controls['birthdayAfter'].value;
      }

      //Purchased Services and Products Filter
      //Time Span
      if (this.form.controls['filterProductServiceStartPeriod'].value) {
        data.filterProductServiceStartPeriod = this.form.controls['filterProductServiceStartPeriod'].value;
      }
      if (this.form.controls['filterProductServiceEndPeriod'].value) {
        data.filterProductServiceEndPeriod = this.form.controls['filterProductServiceEndPeriod'].value;
      }
      if (this.form.controls['filterProductServicePeriodUnit'].value) {
        data.filterProductServicePeriodUnit = this.form.controls['filterProductServicePeriodUnit'].value;
      }

      //Has purchased
      //data.arePurchased = this.form.controls['arePurchasedHasNot'].value === 'has';
      data.hasPurchasedFilter = this.form.controls['hasPurchasedCheckbox'].value;

      data.serviceIds = this.selectedServicesOrProducts
        .filter((_) => _.type === ServiceOrProductType.service)
        .map((_) => _.id)
        .join(',');
      data.productIds = this.selectedServicesOrProducts
        .filter((_) => _.type === ServiceOrProductType.product)
        .map((_) => _.id)
        .join(',');

      let csObservationSupplies = this.selectedClinicSupplies.find(
        (supply) => supply.detailTemplate == ServiceDetailTemplate.Coolsculpting
      ).supplies;
      let injectableObservationSupplies = this.selectedClinicSupplies.find(
        (supply) => supply.detailTemplate == ServiceDetailTemplate.Injections
      ).supplies;
      data.obsListIds = injectableObservationSupplies
        .map((_) => _.id)
        .concat(csObservationSupplies.map((_) => _.id))
        .join(',');

      //Old fields
      // if (this.form.controls['purchaseDateStartOn'].value) {
      //   data.purchaseDateStartOn =
      //     this.form.controls['purchaseDateStartOn'].value ;
      // }
      // if (this.form.controls['purchaseDateEndOn'].value) {
      //   data.purchaseDateEndOn =
      //     this.form.controls['purchaseDateEndOn'].value ;
      // }

      //Has not Purchased

      data.hasNotPurchasedFilter = this.form.controls['hasNotPurchasedCheckbox'].value;
      data.serviceIdsNegative = this.selectedServicesOrProductsNegative
        .filter((_) => _.type === ServiceOrProductType.service)
        .map((_) => _.id)
        .join(',');
      data.productIdsNegative = this.selectedServicesOrProductsNegative
        .filter((_) => _.type === ServiceOrProductType.product)
        .map((_) => _.id)
        .join(',');

      let csObservationSuppliesNegative = this.selectedClinicSuppliesNegative.find(
        (supply) => supply.detailTemplate == ServiceDetailTemplate.Coolsculpting
      ).supplies;
      let injectableObservationSuppliesNegative = this.selectedClinicSuppliesNegative.find(
        (supply) => supply.detailTemplate == ServiceDetailTemplate.Injections
      ).supplies;
      data.obsListIdsNegative = injectableObservationSuppliesNegative
        .map((_) => _.id)
        .concat(csObservationSuppliesNegative.map((_) => _.id))
        .join(',');

      //Have not purchased since AND have no future appointments
      data.notPurchasedSince = this.form.controls['notPurchasedSinceCheckbox'].value;
      data.notPurchasedSinceNegative = this.form.controls['notPurchasedSinceNegativeCheckbox'].value;

      //Old field
      //data.noFutureAppointmentsSince = this.form.controls['noFutureAppointmentsSinceCheckbox'].value;
    }

    if (data.campaignType === RetentionCampaignType.SMS) this.saveCampaign(data);
    else {
      this.emailEditor.exportHtml((htmlObj: any) => {
        this.emailEditor.editor.saveDesign((definition) => {
          data.emailHTML = htmlObj.html;
          data.emailJSON = JSON.stringify(definition);
          // Update virtual template in list
          if (this.emailTemplates.length >= 1 && this.emailTemplates[0].id == 0) {
            this.emailTemplates[0].name = data.title + "'s Saved Template";
            this.emailTemplates[0].definition = data.emailJSON;
            this.emailTemplates[0].html = data.emailHTML;
          } else {
            this.addVirtualTemplateToList(data);
          }
          this.saveCampaign(data);
        });
      });
    }
    this.form.markAsPristine();
  }

  saveCampaign(data: Campaign) {
    this.retentionCampaignsService
      .save(data)
      .pipe(takeUntil(this.unsub))
      .subscribe({
        next: (id: number) => {
          this.loading = false;
          this.retentionCampaignsService.refreshRequired = true;
          this.campaignId = id;
          this.saveBtnName = 'save';

          this.showSuccessMessage = true;
          this.zone.run(() => (this.loading = false));
          this.form.markAsPristine();
          this.emailEditorEdited = false;
          setTimeout(async () => {
            this.showSuccessMessage = false;
          }, 5000);
        },
        error: () => {
          this.loading = false;
          this.errorMessage = 'Could not save campaign';
        },
      });
  }

  checkUnsavedChanges() {
    const unsavedEmailChanges = this.emailEditorEdited;
    const unsavedFormChanges = this.form.dirty;
    if (unsavedEmailChanges || unsavedFormChanges) {
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '330px',
        data: {
          title: 'Cancel Editing Campaign',
          content: 'Do you want to stop editing this campaign? All unsaved changes will be lost.',
          confirmButtonText: 'Yes',
          showCancel: true,
        },
      });
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsub))
        .subscribe((result) => {
          if (result === 'confirm') {
            this.cancelUpdate();
          }
        });
    } else {
      this.cancelUpdate();
    }
  }

  cancelUpdate() {
    this.loading = true;
    this.initialized = false;
    setTimeout(() => {
      this.retentionCampaignsService.retentionCampaignSettingsUpdated.next();
      this.router.navigate([
        '/management/communication/retention-campaigns/',
        { outlets: { 'action-panel-secondary': null } },
      ]);
    }, 950);
  }

  // typeChanged(type: RetentionCampaignType){
  //   if (this.campaignType == "Email" && type != RetentionCampaignType.Email){
  //     this.smsCampaignText = "Thank you for choosing [ClinicName]! We'd be so excited to read about your experience. Please take a moment and share your valuable feedback with us.";

  //     this.form.controls['smsCampaignText'].setValue( this.smsCampaignText);
  //   } else if (type == RetentionCampaignType.Email){
  //     this.smsCampaignText = "";

  //     this.form.controls['smsCampaignText'].setValue( '');
  //   }
  // }

  deleteCampaign() {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe({
        next: (result) => {
          if (result === 'delete') {
            this.loading = true;
            this.initialized = false;
            this.retentionCampaignsService
              .deleteCampaign(this.campaignId)
              .pipe(takeUntil(this.unsub))
              .subscribe(() => {
                this.retentionCampaignsService.refreshRequired = true;
                this.retentionCampaignsService.retentionCampaignSettingsUpdated.next();
                this.loading = false;
                this.router.navigate([
                  '/management/communication/retention-campaigns/',
                  { outlets: { 'action-panel-secondary': null } },
                ]);
              });
          }
        },
        error: () => {
          this.loading = false;
          this.errorMessage = 'Could not delete campaign';
        },
      });
  }

  addVirtualTemplateToList(campaign: Campaign) {
    this.emailTemplates.unshift({
      id: 0,
      emailTemplateType: 2,
      name: campaign.title + "'s Saved Template",
      description: '',
      definition: campaign.emailJSON,
      html: campaign.emailHTML,
      createdByUser: null,
      createdDate: new Date(),
      modifiedByUser: null,
      modifiedDate: new Date(),
    });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  whenSendChanged(value) {
    if (value === 'once') {
      this.form.controls['startOnDate'].enable();
      this.form.controls['startOnDate'].setValidators(Validators.required);
      this.form.controls['startOnTime'].enable();
      this.form.controls['startOnTime'].setValidators(Validators.required);

      this.form.controls['isRecurring'].setValue(false);

      this.form.controls['recurringStartOnDate'].disable();
      this.form.controls['recurringStartOnDate'].clearValidators();
      this.form.controls['repeatPeriodValue'].disable();
      this.form.controls['repeatPeriodValue'].clearValidators();
      this.form.controls['repeatPeriodUnit'].disable();
      this.form.controls['selectedDayOfWeek'].disable();
      this.form.controls['selectedDayOfWeek'].clearValidators();
      this.form.controls['recurringSendTime'].disable();
      this.form.controls['recurringSendTime'].clearValidators();
      this.form.controls['endOnCheckbox'].disable();
      this.form.controls['endOnDate'].disable();
    } else {
      this.form.controls['startOnDate'].disable();
      this.form.controls['startOnDate'].clearValidators();
      this.form.controls['startOnTime'].disable();
      this.form.controls['startOnTime'].clearValidators();

      this.form.controls['isRecurring'].setValue(true);

      this.form.controls['recurringStartOnDate'].enable();
      this.form.controls['recurringStartOnDate'].setValidators(Validators.required);
      this.form.controls['repeatPeriodValue'].enable();
      this.form.controls['repeatPeriodValue'].setValidators(Validators.required);
      this.form.controls['repeatPeriodUnit'].enable();
      this.form.controls['selectedDayOfWeek'].enable();
      this.form.controls['selectedDayOfWeek'].setValidators(Validators.required);
      this.form.controls['recurringSendTime'].enable();
      this.form.controls['recurringSendTime'].setValidators(Validators.required);
      this.form.controls['endOnCheckbox'].enable();
      this.form.controls['endOnDate'].enable();
    }
    this.form.controls['startOnDate'].updateValueAndValidity();
    this.form.controls['startOnTime'].updateValueAndValidity();
    this.form.controls['recurringStartOnDate'].updateValueAndValidity();
    this.form.controls['repeatPeriodValue'].updateValueAndValidity();
    this.form.controls['selectedDayOfWeek'].updateValueAndValidity();
    this.form.controls['recurringSendTime'].updateValueAndValidity();
  }

  endOnChecked(e) {
    if (e.checked) {
      this.form.controls['endOnDate'].setValue(this.form.controls['startOnDate'].value);
    }
  }

  purchaseEndOnChecked(e) {
    if (e.checked) {
      this.form.controls['purchaseDateEndOn'].setValue(this.form.controls['purchaseDateStartOn'].value);
    }
  }

  private parseRepeatPeriod(expression: string, periodCtrl: string, unitCtrl: string) {
    if (!expression) {
      return;
    }
    const split = expression.split(' ');
    if (split.length === 1) {
      this.form.controls[periodCtrl].setValue(1);
      this.form.controls[unitCtrl].setValue(split[0] + 's');
    } else {
      this.form.controls[periodCtrl].setValue(split[0]);
      this.form.controls[unitCtrl].setValue(split[1]);
    }
  }

  private formatRepeatPeriod(periodCtrl: string, unitCtrl: string): string {
    const value = this.form.controls[periodCtrl].value;
    const unit = this.form.controls[unitCtrl].value;
    if (value === 1) {
      return unit.substr(0, unit.length - 1);
    }
    return value + ' ' + unit;
  }

  private parseTimeElapsed(
    expression: string,
    checkBoxCtrl: string,
    lessMoreCtrl: string,
    valueCtrl: string,
    firstLastCtrl: string
  ) {
    if (!expression) {
      return;
    }
    const split = expression.split(' ');
    this.form.controls[checkBoxCtrl].setValue(true);
    this.form.controls[lessMoreCtrl].setValue(split[0] + ' ' + split[1]);
    this.form.controls[valueCtrl].setValue(split[2]);
    this.form.controls[firstLastCtrl].setValue(split[5]);
  }

  private formatTimeElapsed(
    checkBoxCtrl: string,
    lessMoreCtrl: string,
    valueCtrl: string,
    firstLastCtrl: string
  ): string {
    if (this.form.controls[checkBoxCtrl].value !== true) {
      return null;
    }
    const lessMore = this.form.controls[lessMoreCtrl].value;
    const value = this.form.controls[valueCtrl].value;
    const firstLast = this.form.controls[firstLastCtrl].value;
    return `${lessMore} ${value} days since ${firstLast} visit`;
  }

  private parseVisitDate(
    expression: string,
    checkBoxCtrl: string,
    firstLastCtrl: string,
    beforeAfterCtrl: string,
    valueCtrl: string
  ) {
    if (!expression) {
      return;
    }
    const split = expression.split(' ');
    this.form.controls[checkBoxCtrl].setValue(true);
    this.form.controls[firstLastCtrl].setValue(split[0]);
    this.form.controls[beforeAfterCtrl].setValue(split[2]);
    this.form.controls[valueCtrl].setValue(this.parseIsoDate(split[3]));
  }

  private formatVisitDate(
    checkBoxCtrl: string,
    firstLastCtrl: string,
    beforeAfterCtrl: string,
    valueCtrl: string
  ): string {
    if (this.form.controls[checkBoxCtrl].value !== true) {
      return null;
    }
    const firstLast = this.form.controls[firstLastCtrl].value;
    const beforeAfter = this.form.controls[beforeAfterCtrl].value;
    const value: Date = this.form.controls[valueCtrl].value;
    return `${firstLast} visit ${beforeAfter} ${value.toISOString().split('T')[0]}`;
  }

  private parseAmountExpression(expression: string, checkBoxCtrl: string, lessMoreCtrl: string, valueCtrl: string) {
    if (!expression) {
      return;
    }
    const split = expression.split(' ');
    this.form.controls[checkBoxCtrl].setValue(true);
    this.form.controls[lessMoreCtrl].setValue(split[0] + ' ' + split[1]);
    this.form.controls[valueCtrl].setValue(split[2]);
  }

  private parseAmountExpressionWithPeriod(
    expression: string,
    checkBoxCtrl: string,
    lessMoreCtrl: string,
    valueCtrl: string,
    periodCtrl: string,
    unitCtrl: string
  ) {
    if (!expression) {
      return;
    }
    const split = expression.split(' ');
    this.parseAmountExpression(expression, checkBoxCtrl, lessMoreCtrl, valueCtrl);
    this.parseRepeatPeriod(split[6] + ' ' + split[7], periodCtrl, unitCtrl);
  }

  // private parseAmountExpressionWithDate(expression: string, checkBoxCtrl: string, lessMoreCtrl: string, valueCtrl: string, dateValueCtrl: string) {
  //   if (isNullOrEmptyString(expression)) { return; }
  //   const split = expression.split(' ');
  //   this.parseAmountExpression(expression, checkBoxCtrl, lessMoreCtrl, valueCtrl);
  //   this.form.controls[dateValueCtrl].setValue(this.parseIsoDate(split[4]));
  // }

  private formatAmountExpression(
    checkBoxCtrl: string,
    lessMoreCtrl: string,
    valueCtrl: string,
    description: string
  ): string {
    if (this.form.controls[checkBoxCtrl].value !== true) {
      return null;
    }
    const lessMore = this.form.controls[lessMoreCtrl].value;
    const value: Date = this.form.controls[valueCtrl].value;
    return `${lessMore} ${value} ${description}`;
  }

  private formatAmountExpressionWithPeriod(
    checkBoxCtrl: string,
    lessMoreCtrl: string,
    valueCtrl: string,
    periodCtrl: string,
    unitCtrl: string
  ): string {
    if (this.form.controls[checkBoxCtrl].value !== true) {
      return null;
    }

    return (
      this.formatAmountExpression(checkBoxCtrl, lessMoreCtrl, valueCtrl, '') +
      'in the last ' +
      this.form.controls[periodCtrl].value +
      ' ' +
      this.form.controls[unitCtrl].value
    );
  }

  // private formatAmountExpressionWithDate(checkBoxCtrl: string, lessMoreCtrl: string, valueCtrl: string, dateValueCtrl: string): string {
  //   if (this.form.controls[checkBoxCtrl].value !== true) { return null; }
  //   const dateValue: Date = this.form.controls[dateValueCtrl].value;
  //   return this.formatAmountExpression(checkBoxCtrl, lessMoreCtrl, valueCtrl, '') +
  //     `since ${dateValue.toISOString().split('T')[0]}`;
  // }

  private parseIsoDate(dateStr: string): Date {
    const parts = dateStr.split('-');
    return new Date(+parts[0], +parts[1] - 1, +parts[2]);
  }

  searchServiceOrProduct = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focus$;
    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        term === ''
          ? this.allServicesOrProducts
          : this.allServicesOrProducts.filter((v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)
      )
    );
  };

  searchServiceOrProductNegative = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusNegative$;
    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        term === ''
          ? this.allServicesOrProducts
          : this.allServicesOrProducts.filter((v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)
      )
    );
  };

  serviceOrProductInputFormatter = (x: { name: string }) => null;
  serviceOrProductResultFormatter = (x: { name: string }) => x.name;

  getUserSelectionsForRelatedClinicSupplies(supply, positive = true): Observable<ObservationListItem[]> {
    let index = this.clinicSupplies.findIndex((s) => s.detailTemplate == supply.serviceDetailTemplateId);

    let selections: ObservationListItem[] = [];
    var dialogRef;
    if (positive) {
      dialogRef = this.dialog.open(ClinicSuppliesSelectorModalComponent, {
        height: 'auto',
        data: {
          clinicSupplies: this.clinicSupplies[index].supplies,
          selectedClinicSupplies: this.selectedClinicSupplies.find(
            (s) => s.detailTemplate == supply.serviceDetailTemplateId
          ).supplies,
        },
      });
    } else {
      dialogRef = this.dialog.open(ClinicSuppliesSelectorModalComponent, {
        height: 'auto',
        data: {
          clinicSupplies: this.clinicSupplies[index].supplies,
          selectedClinicSupplies: this.selectedClinicSuppliesNegative.find(
            (s) => s.detailTemplate == supply.serviceDetailTemplateId
          ).supplies,
        },
      });
    }

    return dialogRef.afterClosed().pipe(
      takeUntil(this.unsub),
      map((result: ObservationListItem[]) => {
        if (result && result.length > 0) {
          selections = result;
        }
        return result;
      })
    );
  }

  async editServiceWithObservationList(sop, positive = true) {
    //get selections from user via modal for which obs to include in this filter (or all if none selected);
    if (positive) {
      let clinicSuppliesToFilter = await this.getUserSelectionsForRelatedClinicSupplies(sop).toPromise();
      if (clinicSuppliesToFilter) {
        let supply = this.selectedClinicSupplies.find(
          (supply2) => supply2.detailTemplate == sop.serviceDetailTemplateId
        );
        supply.supplies = clinicSuppliesToFilter;
      }
    } else {
      let clinicSuppliesToFilter = await this.getUserSelectionsForRelatedClinicSupplies(sop, false).toPromise();
      if (clinicSuppliesToFilter) {
        let supply = this.selectedClinicSuppliesNegative.find(
          (supply2) => supply2.detailTemplate == sop.serviceDetailTemplateId
        );
        supply.supplies = clinicSuppliesToFilter;
      }
    }
  }

  async addServiceOrProduct(sop, positive = true) {
    this.form.markAsDirty();
    if (positive) {
      const foundIndex = this.selectedServicesOrProducts.indexOf(sop);
      if (sop && foundIndex === -1) {
        if (
          (sop && sop.serviceDetailTemplateId == ServiceDetailTemplate.Coolsculpting) ||
          sop.serviceDetailTemplateId == ServiceDetailTemplate.Injections
        ) {
          this.elem.nativeElement.blur();
          //get selections from user via modal for which obs to include in this filter (or all if none selected);
          let clinicSuppliesToFilter = await this.getUserSelectionsForRelatedClinicSupplies(sop).toPromise();
          if (clinicSuppliesToFilter) {
            let supply = this.selectedClinicSupplies.find(
              (supply2) => supply2.detailTemplate == sop.serviceDetailTemplateId
            );
            supply.supplies = clinicSuppliesToFilter;
            this.selectedServicesOrProducts.push(sop);
          }
        } else {
          this.selectedServicesOrProducts.push(sop);
        }
      }
      // Edit sop if it has a modal and is already added to the list
      else if (
        sop &&
        foundIndex !== -1 &&
        (sop.serviceDetailTemplateId == ServiceDetailTemplate.Coolsculpting ||
          sop.serviceDetailTemplateId == ServiceDetailTemplate.Injections)
      ) {
        this.elem.nativeElement.blur();
        this.editServiceWithObservationList(sop);
      }
      this.elem.nativeElement.blur();
    } else {
      const foundIndex = this.selectedServicesOrProductsNegative.indexOf(sop);
      if (sop && foundIndex === -1) {
        if (
          (sop && sop.serviceDetailTemplateId == ServiceDetailTemplate.Coolsculpting) ||
          sop.serviceDetailTemplateId == ServiceDetailTemplate.Injections
        ) {
          this.elemNegative.nativeElement.blur();
          //get selections from user via modal for which obs to include in this filter (or all if none selected);
          let clinicSuppliesToFilter = await this.getUserSelectionsForRelatedClinicSupplies(sop, false).toPromise();
          if (clinicSuppliesToFilter) {
            let supply = this.selectedClinicSuppliesNegative.find(
              (supply2) => supply2.detailTemplate == sop.serviceDetailTemplateId
            );
            supply.supplies = clinicSuppliesToFilter;
            this.selectedServicesOrProductsNegative.push(sop);
          }
        } else {
          this.selectedServicesOrProductsNegative.push(sop);
        }
      }
      // Edit sop if it has a modal and is already added to the list
      else if (
        sop &&
        foundIndex !== -1 &&
        (sop.serviceDetailTemplateId == ServiceDetailTemplate.Coolsculpting ||
          sop.serviceDetailTemplateId == ServiceDetailTemplate.Injections)
      ) {
        this.elemNegative.nativeElement.blur();
        this.editServiceWithObservationList(sop, false);
      }
      this.elemNegative.nativeElement.blur();
    }
  }

  deleteServiceOrProduct(sop, positive = true) {
    this.form.markAsDirty();
    if (positive) {
      const i = this.selectedServicesOrProducts.indexOf(sop);
      if (i !== -1 && sop) {
        this.selectedServicesOrProducts.splice(i, 1);
        if (
          sop.serviceDetailTemplateId == ServiceDetailTemplate.Coolsculpting ||
          (sop.serviceDetailTemplateId == ServiceDetailTemplate.Injections &&
            this.selectedServicesOrProducts.find((s) => s.serviceDetailTemplateId == sop.serviceDetailTemplateId) ==
              null)
        ) {
          //also make sure there are no other selected services that use these obs before removing
          this.selectedClinicSupplies.find(
            (supply2) => supply2.detailTemplate == sop.serviceDetailTemplateId
          ).supplies = [];
          //remove clinic supplies that were added with that service
        }
      }
    } else {
      const i = this.selectedServicesOrProductsNegative.indexOf(sop);
      if (i !== -1 && sop) {
        this.selectedServicesOrProductsNegative.splice(i, 1);
        if (
          sop.serviceDetailTemplateId == ServiceDetailTemplate.Coolsculpting ||
          (sop.serviceDetailTemplateId == ServiceDetailTemplate.Injections &&
            this.selectedServicesOrProductsNegative.find(
              (s) => s.serviceDetailTemplateId == sop.serviceDetailTemplateId
            ) == null)
        ) {
          //also make sure there are no other selected services that use these obs before removing
          this.selectedClinicSuppliesNegative.find(
            (supply2) => supply2.detailTemplate == sop.serviceDetailTemplateId
          ).supplies = [];
          //remove clinic supplies that were added with that service
        }
      }
    }
  }

  onPreview() {
    // Indicate to the recipients modal that the retention campaign has been set
    this.retentionCampaignsService.retentionCampaignChanged.next(this.campaignId);

    this.isOpenRecipientsModal = true;
    this.patientPanelOpen = false;

    this.actionPanelService.actionPanelClosed$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.patientPanelOpen = false;
    });
  }

  cloneCampaign() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '330px',
      data: {
        title: 'Clone Retention Campaign',
        content:
          'Do you want to clone all aspects of this campaign (filters, recipients, dates, etc) into a new campaign?',
        confirmButtonText: 'Yes',
        showCancel: true,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') {
          this.loading = true;
          this.retentionCampaignsService
            .cloneCampaign(this.campaignId)
            .pipe(takeUntil(this.unsub))
            .subscribe({
              next: (newCampaign) => {
                this.retentionCampaignsService.refreshRequired = true;
                this.retentionCampaignsService.retentionCampaignSettingsUpdated.next();
                this.router.navigate([
                  '/management/communication/retention-campaigns',
                  { outlets: { 'action-panel-secondary': ['edit-retention-campaigns', newCampaign.id] } },
                ]);
              },
              error: () => {
                this.loading = false;
                this.errorMessage = 'Could not delete campaign';
              },
            });
        }
      });
  }

  sendTest(type: string) {
    const sendTestModal = this.modalService.open(SendTestEmailComponent, {
      centered: true,
      windowClass: 'send-test-modal',
    });
    sendTestModal.componentInstance.type = type;
    sendTestModal.componentInstance.quickView = true;
    sendTestModal.componentInstance.emailTemplateType = EmailTemplateType.RetentionCampaign;
    sendTestModal.componentInstance.campaignId = this.campaignId;
  }

  loadEditor(event) {
    if (this.emailDetailsObj) this.emailEditor.editor.loadDesign(this.emailDetailsObj);

    if (this.iPad) {
      this.emailEditor.editor.showPreview('Mobile');
    }
  }

  editorReady(event) {
    this.zone.run(() => (this.templateLoading = false));
    this.emailEditor.editor.addEventListener('design:updated', (data) => {
      this.emailEditorEdited = true;
    });
    this.emailEditor.editor.addEventListener('design:loaded', (data) => {
      this.zone.run(() => (this.templateLoading = false));
    });
  }

  public getRecipientRequestType(): RecipientRequestType {
    return this.recipientRequestType;
  }

  private stripTimeAsDuration(dateTime: Date): moment.Duration {
    if (dateTime != null) {
      const startStr = dateTime.getUTCHours() + ':' + dateTime.getUTCMinutes() + ':00';
      return moment.duration(startStr);
    }
    return null;
  }
}
