import { DataSourceRequestState } from '@progress/kendo-data-query';
import { Component, OnInit, OnDestroy, ViewChildren } from '@angular/core';
import { ReportsService } from '@services/reports.service';
import { Subject, forkJoin } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { takeUntil } from 'rxjs/operators';
import { IReportComponent } from '@models/reports/ireport-component';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-clinic-reports',
  templateUrl: './clinic-reports.component.html',
  styleUrls: ['./clinic-reports.component.less']
})
export class ClinicReportsComponent implements OnInit, OnDestroy, IReportComponent {
  @ViewChildren('grid') grid;
  gridViews: GridDataResult[];
  gridTotals: {title: string, total: number}[];
  gridHeaders: string[];
  gridStates: DataSourceRequestState[];
   
  loading: boolean = false;
  unsub: Subject<void> = new Subject<void>();

  constructor(
    private reportsService: ReportsService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit() {
    // this.initDefaultValue();
    this.getReportData();
  }

  initDefaultValue() {
    this.gridViews = [
      {data: [], total: 0},
      {data: [], total: 0 },
      {data: [], total: 0},
      {data: [], total: 0}
    ];
    this.gridTotals = [
      {title: 'Sales', total: 0},
      {title: 'Payments', total: 0},
      {title: 'Category Sales', total: 0},
      {title: 'Tax Liability', total: 0}
    ];
    this.gridHeaders = [
      'Sales Summary',
      'Payment Type Summary',
      'Category Sales Summary',
      'Tax Summary',
    ];

 
  }

  getReportData() {

    this.loading = true;
    this.initDefaultValue(); //move this here instead of ngOnInit if we want to auto-close all the table's details sections
    forkJoin(
      this.reportsService.getClinicIncomeRevenueReportEntities(),
      this.reportsService.getClinicPaymentTypeReportEntities(),
      this.reportsService.getClinicCategorySalesReportEntities(),
      this.reportsService.getClinicTaxReportEntities(),
    ).pipe(takeUntil(this.unsub))
     .subscribe((response: any) => {
      this.gridViews.forEach((gridView, index) => {
          gridView.data = response[index].map((item) => ({...item, total: this.currencyPipe.transform(item.total)}));
          gridView.total = response[index].length;
          this.gridTotals[index].total = response[index].reduce((acc, obj) => acc + Number(obj.total), 0);
        });
        this.loading = false;
     });

     
  }

  exportToExcel() {
    this.grid.toArray().forEach((item) => item.saveAsExcel());
  }

  onExcelExport(e: any, index: number) {
    this.reportsService.onExcelExport(e, `${this.gridTotals[index].title} Report`, this.currencyPipe.transform(this.gridTotals[index].total));
  }

  getFileName(pdf: boolean, index: number) {
    const format = pdf ? '.pdf' : '.xlsx';
    const title = this.gridTotals[index].title.replace(/\s+/g, '_').toLowerCase();
    return `${title}_report${format}`;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
