import { Patient } from './patient';
import { User } from './user';
import * as moment from 'moment';

export class Allergy {
  allergyId: number;
  dateCreated: Date;
  dateUpdated: Date;
  description: string;
  patientId: number;
  patient: Patient;
  user: User;

  constructor(init?: Partial<Allergy>) {
      Object.assign(this, init);
  }
}
