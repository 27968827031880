import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { IReportComponent } from '@models/reports/ireport-component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataSourceRequestState, groupBy } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ReportsService } from '@services/reports.service';
import { CommissionReport } from '@models/reports/commission/commission-report';

class User {
  id: string;
  name: string;
  constructor(comm: CommissionReport) {
    this.id = comm.userId;
    this.name = comm.fullName;
  }
}

class Row {
  name: string;
  serviceId: number;
  clinicSupplyId?: number;
  clinicSupplyName?: string;
  clinicSupplyTypeId?: number;
  clinicSupplyTypeName?: string;

  constructor(comm: CommissionReport) {
    this.serviceId = comm.serviceTemplateId;
    this.name = comm.serviceAltName;
    this.clinicSupplyId = comm.observationListId;
    this.clinicSupplyName = comm.observationListName;
    this.clinicSupplyTypeId = comm.observationListTypeId;
    this.clinicSupplyTypeName = comm.observationListTypeName;
  }

  get id() {
    return this.serviceId.toString() + (this.clinicSupplyId ?? 0).toString();
  }
}

class Cell {
  isFlatRate: boolean;
  serviceCount: number;
  sales: number;
  rate: number;

  constructor(comm: CommissionReport) {
    this.isFlatRate = comm.isFlatRate;
    this.serviceCount = comm.serviceCount;
    this.sales = comm.totalSum;
    this.rate = comm.commission;
  }

  get due() {
    return this.isFlatRate ? this.serviceCount * this.rate : (this.sales * this.rate) / 100;
  }
}

type NestedMap<T> = Map<string, Map<string, T>>;

@Component({
  selector: 'app-commissions-reports',
  templateUrl: './commissions-reports.component.html',
  styleUrls: ['./commissions-reports.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class CommissionsReportsComponent implements OnInit, OnDestroy, IReportComponent {
  gridView: GridDataResult;
  gridState: DataSourceRequestState;

  loading: boolean = false;
  unsub: Subject<void> = new Subject<void>();

  distinctUsers: User[];

  private commCells: NestedMap<Cell>;
  private subTotals: NestedMap<number>;
  private totals: Map<string, number>;
  private categoryOrder = ['Toxins', 'Fillers', 'CoolSculpting Applicators', 'Needles', 'Deoxycholate', null];

  constructor(private reportsService: ReportsService) {}

  ngOnInit(): void {
    this.initDefaultValue();
    this.getReportData();
  }

  initDefaultValue(): void {
    this.gridState = {
      skip: 0,
      take: 100,
      sort: [],
      group: [],
    };
  }

  getReportData(): void {
    this.loading = true;
    this.reportsService
      .getCommissionsReportEntities(this.gridState)
      .pipe(takeUntil(this.unsub))
      .subscribe((commissionsReports: GridDataResult) => {
        this.processResults(commissionsReports);
        this.loading = false;
      });
  }

  getSales(dataItem: Row, user: User): string {
    let cell = this.commCells.get(dataItem.id).get(user.id);
    if (!cell) return 'None';
    return cell.isFlatRate
      ? cell.serviceCount.toString()
      : cell.sales.toLocaleString(undefined, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        });
  }

  getCommission(service: Row, user: User): string {
    const cell = this.commCells.get(service.id).get(user.id);
    if (!cell) return '';
    return cell.isFlatRate
      ? cell.rate.toLocaleString(undefined, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })
      : (cell.rate / 100).toLocaleString(undefined, {
          style: 'percent',
          maximumFractionDigits: 1,
        });
  }

  getDue(service: Row, user: User): string {
    const cell = this.commCells.get(service.id).get(user.id);
    if (!cell) return '$0.00';
    return cell.due.toLocaleString(undefined, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
    });
  }

  getSubTotal(group, user: User): number {
    return this.subTotals.get(group.value).get(user.id) ?? 0;
  }

  getTotal(user: User): number {
    return this.totals.get(user.id) ?? 0;
  }

  private processResults(result: GridDataResult): void {
    const commissions: CommissionReport[] = result.data;
    const distinctRows = this.getDistinct(commissions);

    const grouped = groupBy(distinctRows, [
      {
        field: 'clinicSupplyTypeName',
        dir: 'desc',
      },
    ]);

    const groupedSorted = grouped.sort((a, b) => {
      let rankA = this.categoryOrder.indexOf(a.value);
      let rankB = this.categoryOrder.indexOf(b.value);
      return rankA - rankB;
    });

    this.gridView = { data: groupedSorted, total: grouped.length };
  }

  private getDistinct(commissions: CommissionReport[]): Row[] {
    const distinctUsers: User[] = [];
    const distinctRows: Row[] = [];
    const cells: NestedMap<Cell> = new Map();
    const subTotals: NestedMap<number> = new Map();
    const totals: Map<string, number> = new Map();

    commissions.forEach((comm) => {
      const commRow = new Row(comm);
      const commCell = new Cell(comm);

      const rowDistinct = !distinctRows.some((dr) => dr.id == commRow.id);
      if (rowDistinct) distinctRows.push(commRow);

      const userDistinct = !distinctUsers.some((du) => du.id == comm.userId);
      if (userDistinct) distinctUsers.push(new User(comm));

      if (!cells.has(commRow.id)) cells.set(commRow.id, new Map());
      cells.get(commRow.id).set(comm.userId, commCell);

      if (!subTotals.has(commRow.clinicSupplyTypeName)) subTotals.set(commRow.clinicSupplyTypeName, new Map());
      if (!subTotals.get(commRow.clinicSupplyTypeName).has(comm.userId))
        subTotals.get(commRow.clinicSupplyTypeName).set(comm.userId, 0);
      const currentSubTotal = subTotals.get(commRow.clinicSupplyTypeName).get(comm.userId);
      subTotals.get(commRow.clinicSupplyTypeName).set(comm.userId, currentSubTotal + commCell.due);

      if (!totals.has(comm.userId)) totals.set(comm.userId, 0);
      const currentTotal = totals.get(comm.userId);
      totals.set(comm.userId, currentTotal + commCell.due);
    });

    this.distinctUsers = distinctUsers;
    this.commCells = cells;
    this.subTotals = subTotals;
    this.totals = totals;
    return distinctRows;
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }

  exportToExcel(): void {
    throw new Error('exportToExcel not implemented.');
  }
}
