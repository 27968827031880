<h1 mat-dialog-title class="text-center mb-3">{{title}}</h1>
<div mat-dialog-content class="text-center mb-3">
  <p *ngIf="content !== undefined">{{content}}</p>
  <!-- <input type="text" class="form-control" [placeholder]="inputLabel" id="input" required [(ngModel)]="date" name="input"> -->
  <mat-form-field floatLabel="always" class="filter-date">
    <mat-label>Date</mat-label>
    <input
      matInput
	  [placeholder]="inputLabel"
	  [satDatepicker]="picker"
    [max]="maximumDate"
    [min]="minimumDate"
	  [value]="date"
	  (dateChange)="onCalendarDateChange($event)"
	>
    <sat-datepicker #picker></sat-datepicker>
    <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
  </mat-form-field>
  <kendo-timepicker
    [max]="getMaximumTime()"
    [min]="minimumDate"
    [fillMode]="'flat'"
    [placeholder]="inputLabel"
  	[steps]="{ minute: minimumDuration }"
	[(value)]="date"
  >
  </kendo-timepicker>
</div>
<div mat-dialog-actions class="justify-content-center">
  <button mat-button [mat-dialog-close]="'cancel'">Cancel</button>
  <button mat-button [mat-dialog-close]="date" cdkFocusInitial>{{confirmButtonText}}</button>
</div>