<ngx-loading [show]="loading"></ngx-loading>

<div class="modal-header border-0 p-0">
  <button
    type="button"
    class="btn white-font p-0 ml-auto mr-2"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="container p-0 pb-3">
  <div class="search-container navy-background justify-content-center forms-container">
    <h6 class="modal-title text-uppercase white-font text-center mb-4">Assign eForm</h6>
    <div class="input-group pb-2">
      <div class="input-group-prepend">
        <div class="input-group-text border-radius grey-three-background border-0">
          <i class="fa fa-search"></i>
        </div>
      </div>
      <input
        [formControl]="searchFormControl"
        type="text"
        name="docSearch"
        class="form-control border-0 grey-three-background"
        placeholder="Search"
      />
    </div>
  </div>

  <div class="forms-container" style="height: 440px">
    <perfect-scrollbar>
      <mat-accordion multi="false">
        <ng-container *ngFor="let formItemGroup of formItemGroups">
          <mat-expansion-panel *ngIf="formItemGroup.forms.length > 0" class="my-1">
            <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
              <mat-panel-title>
                {{ formItemGroup.name }}
              </mat-panel-title>
              <mat-icon>arrow_drop_up</mat-icon>
            </mat-expansion-panel-header>
            <div
              *ngFor="let form of formItemGroup.forms"
              class="form-item"
              [ngClass]="{ 'form-assigned': form['assigned'] }"
            >
              <div class="navy-font">{{ form.name }}</div>
              <button class="btn-clean" (click)="closeModal(form)">
                <i class="fas fa-plus-circle calendar-green-font bigger-icon"></i>
              </button>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </perfect-scrollbar>
  </div>

  <div class="save-changes-container my-3 text-center">
    <button class="btn em-btn em-btn-green ml-2" (click)="activeModal.dismiss('Cross click')">Cancel</button>
  </div>
</div>
