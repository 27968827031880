<!-- <ng-container *ngIf="editMode && !isServicePaid; else text"> -->
<span class="disable-input-number-spinner w-100">
  <div class="row m-0 p-0">
    <div class="col-1 m-0 p-0">$</div>
    <div class="col-11 m-0 p-0">
      <input
        #obrChargeDisplayedInput
        data-test-id="edit-total-observation-price-input"
        type="number"
        class="form-control inline-input text-right d-inline w-90"
        [ngClass]="{ 'calendar-red-font': hasOverrideCharge() }"
        [(ngModel)]="obrChargeDisplayed"
        (change)="onOverrideCharge()"
        (keyup.enter)="onOverrideCharge()"
        [readonly]="isLocked || service.isLocked"
      />
    </div>
  </div>
</span>
<!-- </ng-container> -->
<!-- <ng-template #text>
  <div
    class="font-weight-bold text-right pl-1"
    [ngClass]="{ 'calendar-red-font': hasOverrideCharge() }"
    (click)="!isLocked && !service.isLocked && editMode = true">
    {{ obrChargeDisplayed | currency }}
  </div>
</ng-template> -->
