import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '@app/shared/helpers';
import { environment } from '@environments/environment';
import { ClinicProduct } from '@models/clinic-product';
import { Observation } from '@models/observation/observation';
import { Patient } from '@models/patient';
import { TargetTypes } from '@models/patient-transaction/target-types';
import { Resource } from '@models/resource';
import { ResourceType } from '@models/resource-type';
import { Appointment } from '@models/appointments/appointment';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { Service } from '@models/service/service';
import { ServiceBillingCode } from '@models/service/service-billing-code';
import { ServiceCategory } from '@models/service/service-category';
import { ServiceNote } from '@models/service/service-note';
import { Tax } from '@models/tax';
import { MinistryService } from '@services/ministry.service';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppointmentSignalrService } from './appointment-signalr.service';
import { ObservationService } from './observation.service';
import { UsersService } from './users.service';

@Injectable()
export class ServicesService {
  private servicePhotoUpdated = new Subject<any>();
  servicePhotoUpdated$ = this.servicePhotoUpdated.asObservable();

  serviceNoteUpdated = new Subject<ServiceNote>();
  serviceNoteDeleted = new Subject<ServiceNote>();
  obervationOverridden = new Subject();

  servicePhotoFilePath: string;
  servicePhotoService: Service;
  isPaid = false;

  constructor(
    private http: HttpClient,
    private observationService: ObservationService,
    private usersService: UsersService,
    private appointmentSignalrService: AppointmentSignalrService,
    private ministryService: MinistryService
  ) {}

  addService(service: Service) {
    this.prepServiceForDotNet(service, true);
    return this.http.post<Service>(environment.baseUrl + 'api/Services', service);
  }

  updateService(service: Service) {
    const serviceToUpdate = JSON.parse(JSON.stringify(service));
    this.prepServiceForDotNet(serviceToUpdate, false);
    return this.http.put<void>(environment.baseUrl + 'api/Services/' + serviceToUpdate.serviceId, serviceToUpdate);
  }

  removeService(service: Service) {
    return this.http.delete(environment.baseUrl + 'api/Services/' + service.serviceId);
  }

  addServiceNote(noteTargetId: number, serviceNote: ServiceNote, targetTypeId: number): Observable<ServiceNote> {
    switch (targetTypeId) {
      case TargetTypes.Appointment: {
        return this.http
          .post<ServiceNote>(
            environment.baseUrl + 'api/Appointments/PostServiceNoteByAppointmentId/' + noteTargetId,
            serviceNote
          )
          .pipe(
            map((serviceNote: ServiceNote) => {
              if (!isNullOrUndefined(serviceNote.serviceId)) {
                // Update the service
                this.getServiceById(serviceNote.serviceId).subscribe((service: Service) => {});
                return serviceNote;
              }
            })
          );
      }
      default: {
        return this.http.post<ServiceNote>(environment.baseUrl + 'api/ServiceNotes', serviceNote).pipe(
          map((serviceNote: ServiceNote) => {
            if (!isNullOrUndefined(serviceNote.serviceId)) {
              // Update the service
              this.getServiceById(serviceNote.serviceId).subscribe((service: Service) => {
                // EMILY TODO - is this broken?
              });
              return serviceNote;
            }
          })
        );
      }
    }
  }

  updateServiceNote(serviceNote: ServiceNote): Observable<ServiceNote> {
    if (serviceNote.serviceNoteId > 0) {
      return this.http.put<ServiceNote>(
        environment.baseUrl + 'api/ServiceNotes/' + serviceNote.serviceNoteId,
        serviceNote
      );
    } else {
      return this.addServiceNote(serviceNote.serviceNoteId, serviceNote, TargetTypes.Note);
    }
  }

  deleteServiceNote(targetId: number, serviceNoteId: number, targetTypeId: number) {
    switch (targetTypeId) {
      case TargetTypes.Appointment: {
        return this.http
          .put<ServiceNote>(
            environment.baseUrl + 'api/Appointments/DeleteServiceNotesByApptId/' + targetId,
            serviceNoteId
          )
          .pipe(
            map((serviceNote: ServiceNote) => {
              if (!isNullOrUndefined(serviceNote.serviceId)) {
                // Update the service
                this.getServiceById(serviceNote.serviceId).subscribe((service: Service) => {
                  // EMILY TODO - is this broken?
                });
                return serviceNote;
              }
            })
          );
      }
      default: {
        return this.http.delete<ServiceNote>(environment.baseUrl + 'api/ServiceNotes/' + serviceNoteId).pipe(
          map((serviceNote: ServiceNote) => {
            if (!isNullOrUndefined(serviceNote.serviceId)) {
              // Update the service
              this.getServiceById(serviceNote.serviceId).subscribe((service: Service) => {
                // EMILY TODO - is this broken?
              });
              return serviceNote;
            }
          })
        );
      }
    }
  }

  getServices() {
    return this.http.get<Service[]>(environment.baseUrl + 'api/Services').pipe(
      map((services: Service[]) => {
        services.forEach((s) => {
          // Map observations details from string to TS JSON object
          this.observationService.detailsToJson(s.observations);
        });
        return services;
      })
    );
  }

  getServiceById(serviceId: number) {
    return this.http.get<Service>(environment.baseUrl + 'api/Services/' + serviceId);
  }

  getServiceByAppointmentId(appointmentId: number) {
    return this.http.get<Service>(environment.baseUrl + 'api/Services/GetServiceByAppointmentId/' + appointmentId);
  }

  getServiceByCategory(serviceCategory: ServiceCategory) {
    return this.http.get<Service[]>(environment.baseUrl + 'api/Services/Category/' + serviceCategory.serviceCategoryId);
  }

  addServiceCategory(serviceCategory: ServiceCategory) {
    return this.http.post<ServiceCategory>(environment.baseUrl + 'api/ServiceCategories', serviceCategory);
  }

  updateServiceCategory(serviceCategory: ServiceCategory) {
    return this.http.put<void>(
      `${environment.baseUrl}api/ServiceCategories/${serviceCategory.serviceCategoryId}`,
      serviceCategory
    );
  }

  deleteServiceCategory(id: number) {
    return this.http.delete(`${environment.baseUrl}api/ServiceCategories/${id}`);
  }

  getServiceCategories() {
    return this.http.get<ServiceCategory[]>(environment.baseUrl + 'api/ServiceCategories');
  }

  getServiceCategoryById(serviceCategoryId) {
    return this.http.get<ServiceCategory>(environment.baseUrl + 'api/ServiceCategories/' + serviceCategoryId);
  }

  prepServiceForDotNet(service: Service, isAdding: boolean) {
    service.category = null;

    if (service.userCategories) {
      service.userCategories.forEach((uc) => {
        uc.userCategory = null;
        uc.serviceTemplate = null;
      });
    }

    if (service.observations) {
      service.observations = null;
    }

    if (isAdding && service.serviceBillingCodes) {
      service.serviceBillingCodes.forEach((code, index, array) => {
        array[index].id = 0;
      });
    }
  }

  getListsForNewService() {
    return this.http.get<GetListsForNewServiceDTO>(`${environment.baseUrl}api/Services/new`);
  }

  getServiceForEdit(id) {
    return this.http.get<GetServiceForEditDTO>(`${environment.baseUrl}api/Services/${id}/edit`);
  }

  servicePhotoIsUpdated(value) {
    this.servicePhotoService = value;
    if (value.isLocked) {
      setTimeout(() => {
        this.servicePhotoUpdated.next(value);
      });
    } else {
      this.updateService(value).subscribe((s) => {
        this.servicePhotoUpdated.next(value);
      });
    }
  }

  getBlockedScheduleService() {
    return this.http.get<Service>(environment.baseUrl + 'api/Services/Blocked');
  }

  getStaffScheduleService() {
    return this.http.get<Service>(environment.baseUrl + 'api/Services/Staff');
  }

  lockService(serviceId: number, appointment: Appointment) {
    return this.http.post(environment.baseUrl + 'api/Services/LockService/' + serviceId, {}).pipe(
      map(() => {
        this.appointmentSignalrService.updateAppointment(appointment);
      })
    );
  }

  unLockService(serviceId: number, appointment: Appointment) {
    return this.http.post(environment.baseUrl + 'api/Services/UnLockService/' + serviceId, {}).pipe(
      map(() => {
        this.appointmentSignalrService.updateAppointment(appointment);
      })
    );
  }

  updateServicePrice(service: { serviceId: number; appointmentId: number; price: number | string }) {
    return this.http.put(environment.baseUrl + 'api/Services/UpdateServicePrice', service);
  }

  mapServiceFromTemplate(serviceTemplate: ClinicServiceTemplate, patient: Patient): Service {
    const newService = new Service();
    const serviceNotes: ServiceNote[] = [];
    let billingCodes: ServiceBillingCode[] = [];

    if (serviceTemplate !== null && serviceTemplate !== undefined) {
      if (serviceTemplate.notes !== null && serviceTemplate.notes !== undefined && serviceTemplate.notes.length > 0) {
        serviceNotes.push(
          new ServiceNote({
            serviceNoteId: 0,
            entryDate: new Date(),
            enteredBy: this.usersService.loggedInUser.firstName + ' ' + this.usersService.loggedInUser.lastName,
            entryText: serviceTemplate.notes,
            serviceId: 0,
          })
        );
      }

      if(serviceTemplate && serviceTemplate.governmentBilling) {
        billingCodes = serviceTemplate.serviceTemplateBillingCodes ?? [];
        if (patient && serviceTemplate.billingGroupId) {
          let patientAge = moment().diff(patient.birthDate, 'years', false);
          let serviceGrouping = [].concat(
            ...(this.ministryService.AgeSpecificBillingCodes
              ? this.ministryService.AgeSpecificBillingCodes.filter(
                  (bc) => bc.billingGroupId == serviceTemplate.billingGroupId
                ).map((bc) => bc.billingCodes)
              : [])
          );
          serviceGrouping.forEach((group: { code: string; start: number; end?: number; nameWithAge: string }) => {
            if (group && !group.start) group.start = 0; //start at 0 if no early age specified
            if (group.start <= patientAge && (!group.end || group.end >= patientAge)) {
              let sbc: ServiceBillingCode = {
                billingCode: group.code,
                id: 0,
                serviceUnits: 1,
                isPaid: false,
              };
              billingCodes.push(sbc);
            }
          });
        }
      }

      newService.setFullObject(
        0, // serviceId
        serviceTemplate.serviceName,
        serviceTemplate.quantity,
        serviceTemplate.serviceCategoryId,
        serviceTemplate.serviceCategory,
        serviceTemplate.serviceRecProductsString,
        serviceTemplate.resourcesString,
        serviceTemplate.id,
        serviceTemplate,
        serviceTemplate.serviceDetailTemplateId,
        serviceTemplate.status,
        serviceTemplate.serviceAltName,
        serviceTemplate.defaultDurationMinutes,
        serviceTemplate.diagnosticCode,
        serviceTemplate.templateIcon,
        isNullOrUndefined(serviceTemplate.defaultPrice) ? 0 : serviceTemplate.defaultPrice,
        null, //overrideDefaultPrice
        serviceTemplate.attachedForms,
        serviceTemplate.governmentBilling,
        serviceTemplate.serviceTaxes,
        null, // serviceTemplate.userCategories,
        serviceTemplate.room,
        serviceNotes,
        null, //planned treatment note
        null, // servicePhotoPath,
        [], // observations
        serviceTemplate.signedTime,
        serviceTemplate.signedBy,
        serviceTemplate.equipment,
        false, // isLocked,
        billingCodes,
        serviceTemplate.serviceTemplateResources,
        serviceTemplate.requireCardOnFile
      );
    }

    return newService;
  }

  getDxCodeByTerm(searchText: string) {
    return this.http.get<any[]>(environment.baseUrl + `api/CodeLookup/DxCode/${searchText}`);
  }

  getBillingCodeByTerm(searchText: string) {
    return this.http.get<any[]>(environment.baseUrl + `api/CodeLookup/BillingCode/${searchText}`);
  }

  applyTreatmentObservationsToService(serviceToApply: Service, service: Service, patient: Patient): Observation[] {
    // Update the obrId, patientId, serviceId, dateCreated, dateUpdate
    const observations = JSON.parse(JSON.stringify(serviceToApply.observations));
    observations.forEach((obr: Observation) => {
      obr.id = 0;
      obr.patientId = patient.patientId;
      obr.patient = null;
      obr.serviceId = service.serviceId;
      obr.dateCreated = new Date();
      obr.dateUpdated = new Date();
    });
    return observations;
  }

  applyServiceNotesToService(serviceToApply: Service, service: Service): string {
    // We only allow users to have one service note so just append the text if there is text already there
    if (!isNullOrUndefined(serviceToApply.serviceNotes) && serviceToApply.serviceNotes.length > 0) {
      if (service.serviceNotes.length > 0) {
        return service.serviceNotes[0].entryText + '\n' + serviceToApply.serviceNotes[0].entryText;
      } else {
        return serviceToApply.serviceNotes[0].entryText;
      }
    }
    return '';
  }

  hasSingleRoom(serviceTemplate: ClinicServiceTemplate): boolean {
    if (
      serviceTemplate.serviceTemplateResources === null ||
      serviceTemplate.serviceTemplateResources === undefined ||
      serviceTemplate.serviceTemplateResources.length === 0
    ) {
      return false;
    }

    var roomCount = serviceTemplate.serviceTemplateResources.filter(
      (r) => r.resource.resourceType === ResourceType.Room
    ).length;
    if (roomCount === 1) {
      return true;
    } else {
      return false;
    }
  }

  hasMultipleRooms(serviceTemplate: ClinicServiceTemplate): boolean {
    if (
      serviceTemplate.serviceTemplateResources === null ||
      serviceTemplate.serviceTemplateResources === undefined ||
      serviceTemplate.serviceTemplateResources.length === 0
    ) {
      return false;
    }

    var roomCount = serviceTemplate.serviceTemplateResources.filter(
      (r) => r.resource.resourceType === ResourceType.Room
    ).length;
    if (roomCount > 1) {
      return true;
    } else {
      return false;
    }
  }

  getFirstRoom(serviceTemplate: ClinicServiceTemplate): Resource {
    if (
      serviceTemplate.serviceTemplateResources === null ||
      serviceTemplate.serviceTemplateResources === undefined ||
      serviceTemplate.serviceTemplateResources.length === 0
    ) {
      return null;
    }

    var rooms = serviceTemplate.serviceTemplateResources.filter((r) => r.resource.resourceType === ResourceType.Room);
    return rooms[0].resource;
  }

  hasSingleEquipment(serviceTemplate: ClinicServiceTemplate): boolean {
    if (
      serviceTemplate.serviceTemplateResources === null ||
      serviceTemplate.serviceTemplateResources === undefined ||
      serviceTemplate.serviceTemplateResources.length === 0
    ) {
      return false;
    }

    var roomCount = serviceTemplate.serviceTemplateResources.filter(
      (r) => r.resource.resourceType === ResourceType.Equipment
    ).length;
    if (roomCount === 1) {
      return true;
    } else {
      return false;
    }
  }

  hasMultipleEquipment(serviceTemplate: ClinicServiceTemplate): boolean {
    if (
      serviceTemplate.serviceTemplateResources === null ||
      serviceTemplate.serviceTemplateResources === undefined ||
      serviceTemplate.serviceTemplateResources.length === 0
    ) {
      return false;
    }

    var roomCount = serviceTemplate.serviceTemplateResources.filter(
      (r) => r.resource.resourceType === ResourceType.Equipment
    ).length;
    if (roomCount > 1) {
      return true;
    } else {
      return false;
    }
  }

  getFirstEquipment(serviceTemplate: ClinicServiceTemplate): Resource {
    if (
      serviceTemplate.serviceTemplateResources === null ||
      serviceTemplate.serviceTemplateResources === undefined ||
      serviceTemplate.serviceTemplateResources.length === 0
    ) {
      return null;
    }

    var equipment = serviceTemplate.serviceTemplateResources.filter(
      (r) => r.resource.resourceType === ResourceType.Equipment
    );
    return equipment[0].resource;
  }
}

export interface GetListsForNewServiceDTO {
  serviceCategories: ServiceCategory[];
  products: ClinicProduct[];
  taxes: Tax[];
}

export interface GetServiceForEditDTO {
  service: Service;
  serviceCategories: ServiceCategory[];
  products: ClinicProduct[];
  taxes: Tax[];
}
