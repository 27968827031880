import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TextTemplatesService } from '@services/text-templates.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { TextTemplate } from '@models/text-template';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';

@Component({
  selector: 'app-edit-text-template',
  templateUrl: './edit-text-template.component.html',
  styleUrls: ['./edit-text-template.component.less']
})
export class EditTextTemplateComponent implements OnInit, OnDestroy {
  unsub: Subject<void> = new Subject<void>();
  textTemplateId: number;
  textTemplateForm: FormGroup;
  actionType: String;
  isNewTextTemplate: boolean;

  constructor(
    private textTemplatesService: TextTemplatesService,
    private catalogueUpdatesService: CatalogueUpdatesService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsub)).subscribe(params => {
      const id = params['id'];
      if (id && id === '_') {
        this.actionType = 'Add';
        this.isNewTextTemplate = true;
        this.textTemplateId = 0;
      } else {
        this.textTemplateId = Number(id);
        this.actionType = 'Update';
        this.isNewTextTemplate = false;
      }
      this.initForm();
    });
  }

  private initForm() {
    this.textTemplateForm = new FormGroup({
      id: new FormControl(this.textTemplateId),
      name: new FormControl('', Validators.required),
      text: new FormControl('', Validators.required)
    });

    if (!this.isNewTextTemplate) {
      this.textTemplatesService.getTextTemplateById(this.textTemplateId)
        .pipe(takeUntil(this.unsub))
        .subscribe((textTemplate: TextTemplate) => {
          this.textTemplateForm.controls['name'].setValue(textTemplate.name);
          this.textTemplateForm.controls['text'].setValue(textTemplate.text);
        });
    }
  }

  onSubmit() {
    if (this.isNewTextTemplate) {
      this.textTemplatesService.addTextTemplate(this.textTemplateForm.value).subscribe(() => {
        this.catalogueUpdatesService.refreshRequired = true;
        this.catalogueUpdatesService.catalogueUpdateComplete();
        this.router.navigate([
          '/management/catalogue/text-templates',
          { outlets: { 'action-panel': null } }
        ]);
      });
    } else {
      this.textTemplatesService.updateTextTemplate(this.textTemplateForm.value).subscribe(() => {
        this.catalogueUpdatesService.refreshRequired = true;
        this.catalogueUpdatesService.catalogueUpdateComplete();
        this.router.navigate([
          '/management/catalogue/text-templates',
          { outlets: { 'action-panel': null } }
        ]);
      });
    }
  }

  cancelUpdate() {
    this.catalogueUpdatesService.refreshRequired = true;
    this.catalogueUpdatesService.catalogueUpdateComplete();
    this.router.navigate([
      '/management/catalogue/text-templates',
      { outlets: { 'action-panel': null } }
    ]);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
