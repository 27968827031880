import { ProductRecommendation } from '@models/treatment-planning/product-recommendation';
import { ClinicProduct } from '../../models/clinic-product';
import { ServicesService } from './../services.service';
import { Service } from './../../models/service/service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TreatmentPlan } from '@models/treatment-planning/treatment-plan';
import { environment } from '@environments/environment';
import { Observable, combineLatest, zip, Subject, of } from 'rxjs';
import { map, share, catchError } from 'rxjs/operators';
import { ObservationService } from '../observation.service';
import { isNullOrUndefined } from '@app/shared/helpers';
import { BlobService } from '../blob.service';
import { ImageService } from '../image.service';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { PhotoConsentType } from '@models/photo/photo-consent-type';
import { TreatmentPlanPhoto } from '@models/treatment-planning/treatment-plan-photo';
import { PatientService } from '../patient.service';
import { Patient } from '@models/patient';
import { TreatmentState } from '@models/treatment-planning/treatment-state';
import { PlannedTreatment } from '@models/treatment-planning/planned-treatment';
import * as moment from 'moment';
import { PlannedTreatmentMultiple } from '@models/treatment-planning/planned-treatment-multiple';

@Injectable({
  providedIn: 'root',
})
export class TreatmentPlanService {
  public treatmentPlanUpdated$: Subject<TreatmentPlan>;
  public treatmentPlanScheduled: PlannedTreatment;
  public treatmentPlanScheduled$ = new Subject();
  public plannedTreatmentCompleted$ = new Subject();
  public plannedTreatmentCancelled$ = new Subject();

  constructor(
    private http: HttpClient,
    private observationService: ObservationService,
    private blobService: BlobService,
    private servicesService: ServicesService,
    private imageService: ImageService
  ) {
    this.treatmentPlanUpdated$ = new Subject<TreatmentPlan>();
  }

  /**
   * Returns the treatment plan for the give patient - this is a mess that needs to be untanled but in the meantime  just combine all the duplicated requests using the share pipe
   */
  lastPatientId = null;
  lastPatientIdExcludeCompleted = null;
  getTxInProgressExcludeCompleted: Observable<TreatmentPlan> = null;
  getTxInProgress: Observable<TreatmentPlan> = null;
  public getTreatmentPlanByPatientId(patientId: number): Observable<TreatmentPlan> {
    if (patientId != this.lastPatientId) {
      this.getTxInProgressExcludeCompleted = null;
      this.getTxInProgress = null;
    }
    this.lastPatientId = patientId;
    if (!this.getTxInProgress) this.getTxInProgress = this._getTreatmentPlanByPatientId(patientId, true).pipe(share());
    return this.getTxInProgress;
  }

  private _getTreatmentPlanByPatientId(patientId: number, includeCompleted?: boolean): Observable<TreatmentPlan> {
    if (isNullOrUndefined(patientId) || patientId === 0) {
      return of(null);
    }

    if (isNullOrUndefined(includeCompleted)) {
      includeCompleted = true;
    }
    return combineLatest([
      this.blobService.getReadOnlySASObservable(),
      this.http.get<TreatmentPlan>(
        environment.baseUrl + 'api/TreatmentPlan/' + patientId + '?includeCompleted=' + includeCompleted
      ),
    ]).pipe(
      catchError((err) => {
        this.getTxInProgress = null;
        return of(null);
      }),
      map(([readOnlySAS, treatmentPlan]) => {
        this.obrToJson(treatmentPlan);
        treatmentPlan.photos.forEach((tpPhoto) => {
          tpPhoto.photo.filePath += readOnlySAS;
          tpPhoto.photo.filePathThumb += readOnlySAS;
        });
        this.getTxInProgress = null;
        this.getTxInProgressExcludeCompleted = null;
        return treatmentPlan;
      })
    );
  }
  public getTreatmentPlanByPatientIdExcludeCompleted(patientId: number): Observable<TreatmentPlan> {
    if (patientId != this.lastPatientIdExcludeCompleted) {
      this.getTxInProgressExcludeCompleted = null;
      this.getTxInProgress = null;
    }
    this.lastPatientIdExcludeCompleted = patientId;
    if (!this.getTxInProgressExcludeCompleted)
      this.getTxInProgressExcludeCompleted = this._getTreatmentPlanByPatientId(patientId, false).pipe(share());
    return this.getTxInProgressExcludeCompleted;
  }

  public getAssociatedTreatmentByServiceId(serviceId: number) {
    return this.http.get<PlannedTreatment>(
      environment.baseUrl + 'api/TreatmentPlan/PlannedTreatmentByServiceId/' + serviceId
    );
  }

  public getUnplannedTreatmentPlanByPatientId(patientId: number) {
    if (isNullOrUndefined(patientId) || patientId === 0) {
      return of(null);
    }
    return this.http.get<TreatmentPlan>(environment.baseUrl + 'api/TreatmentPlan/UnplannedTreatments/' + patientId);
  }

  public getPlannedTreatmentMultiple(plannedTreatmentMultipleId: number): Observable<PlannedTreatmentMultiple> {
    if (isNullOrUndefined(plannedTreatmentMultipleId) || plannedTreatmentMultipleId === 0) {
      return of(null);
    }
    return this.http.get<PlannedTreatmentMultiple>(
      environment.baseUrl + 'api/TreatmentPlan/PlannedTreatmentMultiple/' + plannedTreatmentMultipleId
    );
  }

  public updateTreatmentState(plannedTreatmentId: number, treatmentStateId: TreatmentState, plannedDate: Date) {
    return this.http.put(
      environment.baseUrl +
        'api/TreatmentPlan/PlannedTreatment/UpdateTreatmentState/' +
        plannedTreatmentId +
        '/' +
        treatmentStateId +
        '?plannedDate=' +
        plannedDate.toISOString(),
      {}
    );
  }

  public addPlannedTreatmentMultiple(
    plannedTreatmentMultiple: PlannedTreatmentMultiple
  ): Observable<PlannedTreatmentMultiple> {
    return this.http.post<PlannedTreatmentMultiple>(
      environment.baseUrl + 'api/TreatmentPlan/PlannedTreatment/AddPlannedTreatmentMultiple/',
      plannedTreatmentMultiple
    );
  }

  public updatePlannedTreatmentMultiple(
    plannedTreatmentMultiple: PlannedTreatmentMultiple
  ): Observable<PlannedTreatmentMultiple> {
    return this.http.put<PlannedTreatmentMultiple>(
      environment.baseUrl + 'api/TreatmentPlan/PlannedTreatment/UpdatePlannedTreatmentMultiple/',
      plannedTreatmentMultiple
    );
  }

  public deletePlannedTreatmentMultiple(plannedTreatmentMultiple: PlannedTreatmentMultiple) {
    return this.http.delete(
      environment.baseUrl + 'api/TreatmentPlan/DeletePlannedTreatmentMultiple/' + plannedTreatmentMultiple.id
    );
  }

  public returnPlannedTreatmentMultiple(plannedTreatmentMultipleId: number, quantity: number): Observable<void> {
    return this.http.put<void>(
      environment.baseUrl + `api/TreatmentPlan/ReturnPlannedTreatmentMultiple/${plannedTreatmentMultipleId}`,
      quantity
    );
  }

  isChangeOverriden(array): boolean {
    if (!isNullOrUndefined(array)) {
      return array.some((item) => !isNullOrUndefined(item.details.overrideProductPrice));
    } else {
      return false;
    }
  }

  // Disabled in backend
  
  // public schedulePlannedTreatment(plannedTreatmentId: number, scheduledServiceId: number, plannedDate: Date) {
  //   return this.http.put(
  //     environment.baseUrl +
  //       'api/TreatmentPlan/PlannedTreatment/SchedulePlannedTreatment/' +
  //       plannedTreatmentId +
  //       '/' +
  //       scheduledServiceId +
  //       '/' +
  //       plannedDate.toISOString(),
  //     {}
  //   );
  // }

  public cancelScheduledPlannedTreatment(plannedTreatmentId: number, plannedDate: Date) {
    return this.http.put(
      environment.baseUrl +
        'api/TreatmentPlan/PlannedTreatment/CancelScheduledPlannedTreatment/' +
        plannedTreatmentId +
        '/' +
        plannedDate.toISOString(),
      {}
    );
  }

  public createPlannedTreatment(patientId: number, service: Service) {
    this.servicesService.prepServiceForDotNet(service, true);
    return this.http.post<PlannedTreatment>(
      environment.baseUrl + `api/TreatmentPlan/PlannedTreatment/${patientId}`,
      service
    );
  }

  public readPlannedTreatment(plannedTreatmentId: number) {
    return this.http.get<PlannedTreatment>(
      environment.baseUrl + `api/TreatmentPlan/PlannedTreatment/${plannedTreatmentId}`
    );
  }

  public updatePlannedTreatment(plannedTreatment: PlannedTreatment) {
    return this.http.put<PlannedTreatment>(
      environment.baseUrl + 'api/TreatmentPlan/PlannedTreatment',
      plannedTreatment
    );
  }

  public deletePlannedTreatments(idList: number[]) {
    return this.http.post<void>(environment.baseUrl + 'api/TreatmentPlan/PlannedTreatment/Delete', idList);
  }

  public createProductRecommendations(txId: number, clinicProduct: ClinicProduct) {
    let pr = new ProductRecommendation({
      id: 0,
      treatmentPlanId: txId,
      createdDate: moment(),
      usageInstructions: clinicProduct.usageInstructions,
      quantity: 1,
      retailPrice: clinicProduct.retailPrice,
      clinicProductId: clinicProduct.id,
      clinicProduct: clinicProduct,
    });
    return this.http.post<ProductRecommendation>(environment.baseUrl + `api/TreatmentPlan/ProductRecommendation`, pr);
  }

  public readProductRecommendations(plannedTreatmentId: number) {
    return this.http.get<PlannedTreatment>(
      environment.baseUrl + `api/TreatmentPlan/ProductRecommendation/${plannedTreatmentId}`
    );
  }

  public updateProductRecommendations(productRecommendation: ProductRecommendation) {
    return this.http.put<PlannedTreatment>(
      environment.baseUrl + 'api/TreatmentPlan/ProductRecommendation',
      productRecommendation
    );
  }

  public deleteProductRecommendations(idList: number[]) {
    return this.http.post<void>(environment.baseUrl + 'api/TreatmentPlan/ProductRecommendation/Delete', idList);
  }

  updatePatientNotes(notes: string, patientId: number) {
    return this.http.put<PlannedTreatment>(environment.baseUrl + `api/TreatmentPlan/PatientNotes/${patientId}`, notes);
  }

  updatePatientTreatmentPlanNotes(notes: string, patientId: number) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put<PlannedTreatment>(
      environment.baseUrl + `api/TreatmentPlan/TreatmentPlanNotes/${patientId}`,
      JSON.stringify(notes),
      { headers }
    );
  }

  public async createFile(filePath: string): Promise<File> {
    const response = await fetch(filePath);
    const data = await response.blob();
    const metadata = {
      type: 'image/' + filePath.slice(filePath.lastIndexOf('.') + 1),
    };
    return new File([data], filePath.slice(filePath.lastIndexOf('/') + 1), metadata);
  }

  public async addTreatmentPlanPhotos(treatmentPlan: TreatmentPlan, patient: Patient) {
    let faceFile: File;
    const faceFilePath: string =
      patient.gender.toLowerCase() === 'female'
        ? '../../../../../../../assets/service-detail/FemaleInjectables.jpg'
        : '../../../../../../../assets/service-detail/MaleInjectables.jpg';
    this.createFile(faceFilePath).then((file) => (faceFile = file));
    const faceMetaData = new PhotoMetaData({
      imageName: 'Treatment Plan Face',
      photoConsentTypeId: PhotoConsentType.None,
      patientId: patient.patientId,
      imageType: 'image/jpeg',
      isTreatmentPlanOriginal: true,
    });

    let bodyFile: File;
    const bodyFilePath: string =
      patient.gender.toLowerCase() === 'female'
        ? '../../../../../../../assets/service-detail/FemaleCoolsculpting.jpg'
        : '../../../../../../../assets/service-detail/MaleCoolsculpting.jpg';
    await this.createFile(bodyFilePath).then((file) => (bodyFile = file));
    const bodyMetaData = new PhotoMetaData({
      imageName: 'Treatment Plan Body',
      photoConsentTypeId: PhotoConsentType.None,
      patientId: patient.patientId,
      imageType: 'image/png',
      isTreatmentPlanOriginal: true,
    });

    const faceImage$ = this.imageService.uploadPhoto(faceFile, faceMetaData);
    const bodyImage$ = this.imageService.uploadPhoto(bodyFile, bodyMetaData);

    zip(faceImage$, bodyImage$).subscribe(([faceImage, bodyImage]) => {
      const photos: TreatmentPlanPhoto[] = [];
      photos.push(
        new TreatmentPlanPhoto({
          photo: faceImage,
          treatmentPlanId: treatmentPlan.id,
        })
      );

      photos.push(
        new TreatmentPlanPhoto({
          photo: bodyImage,
          treatmentPlanId: treatmentPlan.id,
        })
      );

      // treatmentPlan.photos = photos;
      treatmentPlan.patient = patient;
      // Save the treatment plan to make the photos persist
      // this.updateTreatmentPlan(treatmentPlan).subscribe((tp) => {
      //   this.treatmentPlanUpdated$.next(tp);
      // });
    });
  }

  private obrToJson(treatmentPlan: TreatmentPlan) {
    // Map each of the treatment plan observations
    if (!isNullOrUndefined(treatmentPlan.plannedTreatments)) {
      treatmentPlan.plannedTreatments.forEach((pt) => {
        if (!isNullOrUndefined(pt.service.observations)) {
          this.observationService.detailsToJson(pt.service.observations);
        }
      });
    }
  }

  private obrToString(treatmentPlan: TreatmentPlan) {
    // Map each of the treatment plan observations
    if (!isNullOrUndefined(treatmentPlan.plannedTreatments)) {
      treatmentPlan.plannedTreatments.forEach((pt) => {
        if (!isNullOrUndefined(pt.service) && !isNullOrUndefined(pt.service.observations)) {
          this.observationService.detailsToString(pt.service.observations);
        }
      });
    }
  }
}
