import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { PatientNote } from '@models/patient';

@Injectable({
  providedIn: 'root'
})
export class PatientNoteService {
  patientNotesChanged = new Subject<PatientNote[]>();
  patientNotesChangeCancelled = new Subject();
  patientNotes: PatientNote[] = [];

  constructor(private http: HttpClient) {}

  getPatientNotes() {
    return this.http.get<PatientNote[]>(environment.baseUrl + 'api/PatientNotes').pipe(
      map(patientNotes => {
        this.patientNotes = patientNotes;
        return patientNotes;
      })
    );
  }

  setLoadedPatientNotes(patientNotes: PatientNote[]) {
    this.patientNotes = patientNotes;
  }

  getLoadedPatientNotes() {
    return this.patientNotes.slice();
  }

  getPatientNote(id: number) {
    return this.http.get<PatientNote>(environment.baseUrl + 'api/PatientNotes/' + id);
  }

  getLoadedPatientNote(id: number) {
    const itemIndex = this.patientNotes.findIndex(pn => pn.patientNoteId === id);
    return this.patientNotes[itemIndex];
  }

  updatePatientNote(patientId: number, patientNote: PatientNote): Observable<any> {
    return this.http.put<PatientNote>(environment.baseUrl + 'api/PatientNotes/' + patientId, patientNote).pipe(
      map((updatedPatientNote: PatientNote) => {
        const itemIndex = this.patientNotes.findIndex(pn => pn.patientNoteId === patientNote.patientNoteId);
        this.patientNotes[itemIndex] = updatedPatientNote;
        this.patientNotesChanged.next(this.patientNotes);
        return this.patientNotes;
      })
    );
  }

  addPatientNote(patientId: number, patientNote: PatientNote): Observable<PatientNote> {
    return this.http.post<PatientNote>(environment.baseUrl + 'api/PatientNotes/' + patientId, patientNote).pipe(
      map((addedPatientNote: PatientNote) => {
        patientNote.patientNoteId = addedPatientNote.patientNoteId;
        this.patientNotes.push(addedPatientNote);
        this.patientNotesChanged.next(this.patientNotes.sort(this.sortById).slice());
        return patientNote;
      })
    );
  }

  lockPatientNote(patientNoteId: number) {
    return this.http.post(environment.baseUrl + 'api/PatientNotes/LockPatientNote/' + patientNoteId, {});
  }

  unLockPatientNote(patientNoteId: number) {
    return this.http.post(environment.baseUrl + 'api/PatientNotes/UnLockPatientNote/' + patientNoteId, {});
  }

  sortById(val1: PatientNote, val2: PatientNote) {
    if (val1.patientNoteId < val2.patientNoteId) {
      return -1;
    }
    if (val1.patientNoteId > val2.patientNoteId) {
      return 1;
    }
    return 0;
  }

  deletePatientNote(patientNoteId: number) {
    return this.http.delete(`${environment.baseUrl}api/PatientNotes/${patientNoteId}`).pipe(
      map(() => {
        const itemIndex = this.patientNotes.findIndex(patientNote => patientNote.patientNoteId === patientNoteId);

        this.patientNotes.splice(itemIndex, 1);
        this.patientNotesChanged.next(this.patientNotes);
        return this.patientNotes;
      })
    );
  }
}
