import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { DoctorsService } from '@services/doctors.service';
import { Doctor } from '@models/doctor';
import { takeUntil } from 'rxjs/operators';
import { FormatterService } from '@services/formatter.service';
import { ValidationService } from '@services/validation.service';

@Component({
  selector: 'app-quick-add-physician',
  templateUrl: './quick-add-physician.component.html',
  styleUrls: ['./quick-add-physician.component.less']
})
export class QuickAddPhysicianComponent implements OnInit, OnDestroy {
  physicianForm: FormGroup;
  unsub = new Subject<void>();
  possibleDuplicates: Doctor[] = [];
  doctors: Doctor[];

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<QuickAddPhysicianComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formatterService: FormatterService,
    private validationService: ValidationService,
    private doctorsService: DoctorsService
  ) { }

  ngOnInit() {
    this.doctors = this.data.doctors;
    this.initForm();
  }

  initForm() {
    this.physicianForm = this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      provincialIdNumber: [null],
      faxNumber: [null, [Validators.required, this.validationService.validatePhoneNumber]]
    });
  }

  physicianFormSubmit() {
    const doctor: Doctor = this.initDoctor(this.physicianForm.value.firstName, this.physicianForm.value.lastName, this.physicianForm.value.faxNumber, this.physicianForm.value.provincialIdNumber);
    this.doctorsService.addDoctor(doctor)
      .pipe(takeUntil(this.unsub))
      .subscribe((physician) => {
        this.dialogRef.close(physician);
      });
  }

  validatePhysician(){
    // Rather than remove the hyphen on each potential match fax, add hyphens to the entered fax number
    const enteredNumber = this.formatterService.formatPhoneString(this.physicianForm.get('faxNumber').value);
    if (!this.physicianForm.dirty && this.physicianForm.value['lastName'] == '' && this.physicianForm.value['faxNumber'] == '') this.possibleDuplicates = [];
    else{
      let regex =   "^.*(" + (this.physicianForm.value['lastName'] ? this.physicianForm.value['lastName'] : 'NoLastName') + '|' +  (this.physicianForm.value['faxNumber'] ? enteredNumber : 'NoFax')  + ").*$";
      var re = new RegExp(regex,"gi");
      
      this.possibleDuplicates = 
      this.doctors.filter(doc => re.test(doc['faxNumber'] )).concat(
      this.doctors.filter(doc => re.test(doc['lastName'] )))
      .slice(0,10);
    }
  }

  initDoctor(firstName: string, lastName: string, faxNumber: string, provincialIdNumber: string = null): Doctor {
    const address = {
      address1: '',
      address2: '',
      city: '',
      country: '',
      postalCode: '',
      province: ''
    };
    const doctor = {
      doctorId: 0,
      proTitle: 'Dr.',
      firstName: firstName,
      lastName: lastName,
      address: address,
      phoneNumber: '',
      faxNumber: faxNumber,
      email: '',
      website: '',
      hoursOfOperation: null,
      specialty: '',
      provincialIdNumber: +provincialIdNumber
    };
    return doctor;
  }

  closeModal() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
