<ngx-loading [show]="loading"></ngx-loading>

<div class="modal-header border-0 p-0">
  <button
    type="button"
    class="btn white-font p-0 ml-auto mr-2"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <i class="fal fa-times"></i>
  </button>
</div>

<form [formGroup]="form" class="modal-form" (ngSubmit)="onSubmit()">
  <div class="modal-body p-0">
    <div class="passcode-container grey-two-background">
      <h5 class="text-center" style="font-size: 100px"><i _ngcontent-djm-c3="" class="fas fa-lock"></i></h5>
      <h5 class="modal-title text-uppercase navy-font text-center">Locked</h5>
    </div>

    <div class="passcode-container">
      <div class="row pt-3">
        <div class="col">
          <app-error *ngIf="errorMessage" [message]="errorMessage"></app-error>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col">
          <label class="teal-font m-0">Passcode *</label>
          <input
            type="text"
            class="form-control input-field"
            placeholder="Enter the passcode"
            formControlName="passcode"
            required
          />
        </div>
      </div>
    </div>

    <div class="row py-4">
      <div class="col text-center pb-2">
        <button type="submit" class="btn em-btn em-btn-green" [disabled]="!form.valid">Submit</button>
        <button type="submit" class="btn em-btn em-btn-green ml-2" (click)="activeModal.dismiss('Cross click')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</form>
