import { Injectable } from '@angular/core';
import { Resource } from '@models/resource';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Subject } from 'rxjs';
import { ResourceType } from '@models/resource-type';
import { ResourceAllocationFilterModel } from '@models/resource/resource-allocation-filter-model';

@Injectable()
export class ResourcesService {
  resourcesUpdated = new Subject<boolean>();
  resourcesUpdated$ = this.resourcesUpdated.asObservable();

  constructor(private http: HttpClient) {}

  addResource(resource: Resource) {
    return this.http.post<Resource>(environment.baseUrl + 'api/Resources', resource);
  }

  updateResource(resource: Resource) {
    return this.http.put<void>(environment.baseUrl + 'api/Resources/' + resource.resourceId, resource);
  }

  removeResource(resource: Resource) {
    return this.http.delete(environment.baseUrl + 'api/Resources/' + resource.resourceId);
  }

  saveResource(resource: Resource, isNew: boolean) {
    if (isNew) {
      return this.addResource(resource);
    } else {
      return this.updateResource(resource);
    }
  }

  getResources(clinicId: number = +localStorage.getItem('clinicId')) {
    return this.http.get<Resource[]>(environment.baseUrl + 'api/Resources');
  }

  getResourceById(resourceId) {
    return this.http.get<Resource>(environment.baseUrl + 'api/Resources/' + resourceId);
  }

  getResourcesAllocated(
    clinicId: number = +localStorage.getItem('clinicId'),
    date: Date,
    startTime: moment.Duration,
    endTime: moment.Duration,
    appointmentId: number = null
  ) {
    var resourceAllocationFilter = new ResourceAllocationFilterModel();
    resourceAllocationFilter.date = date;
    resourceAllocationFilter.startTime = startTime;
    resourceAllocationFilter.endTime = endTime;
    resourceAllocationFilter.clinicId = clinicId;
    resourceAllocationFilter.appointmentId = appointmentId;

    return this.http.post<Resource[]>(
      environment.baseUrl + 'api/Resources/GetResourcesAllocated',
      resourceAllocationFilter
    );
  }

  /**
   * Sorts an array of rooms & resources into two separate arrays, the
   * first for rooms the second for resources. The arrays are sorted
   * strings first, then numbers.
   * @param roomsAndResources An array of Resource or an array of ServiceTemplateResource
   * @returns A 2-D array, rooms in [0] resources in [1].
   */
  sortRoomsAndResources(inputArray: any[]): Resource[][] {
    let res: Resource[][] = new Array();
    res.push(new Array());
    res.push(new Array());

    if (inputArray.length && inputArray[0].serviceTemplateId) {
      inputArray.forEach((current) => {
        if (current.resource !== null && current.resource !== undefined) {
          if (current.resource.resourceType === ResourceType.Room) {
            res[0].push(current.resource as Resource);
          } else {
            res[1].push(current.resource as Resource);
          }
        }
      });
    } else if (inputArray.length && inputArray[0].resourceType) {
      inputArray.forEach((current) => {
        if (current.resourceType === ResourceType.Room) {
          res[0].push(current as Resource);
        } else {
          res[1].push(current as Resource);
        }
      });
    }
    /*
    const sortResource = (a: Resource, b: Resource): number => {
      const aIsString = isNaN(+a.name);
      const bIsString = isNaN(+b.name);
      if (aIsString && bIsString) { // both are strings
        const nameA = a.name.toLocaleLowerCase();
        const nameB = b.name.toLocaleLowerCase();
        if (nameA > nameB) {
          return 1;
        } else if (nameA < nameB) {
          return -1;
        } else {
          return 0;
        }
      } else if (aIsString && !bIsString) { // one or the other is a string
        return -1;
      } else if (!aIsString && bIsString) {
        return 1;
      } else { // they're both numbers
        return Number(a.name) - Number(b.name);
      }
    }
    res[0].sort(sortResource);
    res[1].sort(sortResource);
    */
    this.sortResources(res[0]);
    this.sortResources(res[1]);

    return res;
  }

  sortResources(resources: Resource[]): Resource[] {
    return resources.sort((a: Resource, b: Resource): number => {
      const aIsString = isNaN(+a.name);
      const bIsString = isNaN(+b.name);
      if (aIsString && bIsString) {
        // both are strings
        const nameA = a.name.toLocaleLowerCase();
        const nameB = b.name.toLocaleLowerCase();
        if (nameA > nameB) {
          return 1;
        } else if (nameA < nameB) {
          return -1;
        } else {
          return 0;
        }
      } else if (aIsString && !bIsString) {
        // one or the other is a string
        return -1;
      } else if (!aIsString && bIsString) {
        return 1;
      } else {
        // they're both numbers
        return Number(a.name) - Number(b.name);
      }
    });
  }
}
