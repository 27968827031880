<ngx-loading [show]="loading"></ngx-loading>
<kendo-grid
  class="emr-kendo-grid hide-default-sort h-100"
  [data]="gridView"
  [style.maxHeight.%]="100"
  [pageSize]="gridState.take"
  [pageable]="true"
  [skip]="gridState.skip"
  [sort]="gridState.sort"
  [sortable]="{
    allowUnsort: false,
    mode: 'single'
  }"
  (dataStateChange)="pageChange($event)"
  (excelExport)="onExcelExport($event)"
  #grid="kendoGrid"
>
  <kendo-grid-column field="membershipName" title="Membership">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="isActive" title="Active">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <mat-checkbox
        [checked]="dataItem.isActive"
        [disableRipple]="true"
        (click)="$event.preventDefault()"
      ></mat-checkbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="price" title="Price" format="{0:c2}">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="salesCount" title="Sales Count">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="salesAmount" title="Sales" format="{0:c2}">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngFor="let tax of possibleTaxes" [field]="tax.name" [title]="tax.name">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{ dataItem.taxCollected[tax.name] | currency }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-excel fileName="services_report.xlsx" [fetchData]="allData"></kendo-grid-excel>
  <div *kendoGridDetailTemplate="let dataItem">
    <app-membership-detail-report
      [membershipIdId]="dataItem?.membershipId"
      [possibleTaxes]="possibleTaxes"
    ></app-membership-detail-report>
  </div>
</kendo-grid>
