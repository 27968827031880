import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataSourceRequestState, SortDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { CommunicationsService } from '@services/communications.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { PatientService } from '@services/patient.service';
import { Router } from '@angular/router';
import { BlobService } from '@services/blob.service';
import { MatDialog } from '@angular/material/dialog';
import { PhotoLightboxComponent } from '@app/patients/patient-tabs/shared-photos/photo-lightbox/photo-lightbox.component';

@Component({
  selector: 'app-patient-communication-tab',
  templateUrl: './patient-communication-tab.component.html',
  styleUrls: ['./patient-communication-tab.component.less'],
})
export class PatientCommunicationTabComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid;
  gridView: GridDataResult;
  gridState: DataSourceRequestState;
  pageable: any;
  loading = false;
  unsub: Subject<void> = new Subject<void>();
  patientId: number;

  constructor(
    private route: ActivatedRoute,
    private communicationsService: CommunicationsService,
    private patientService: PatientService,
    private router: Router,
    private blobService: BlobService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.initDefaultValue();
    this.patientId = this.route.snapshot.params.patId.split('_')[0];
    this.getCommunicationData();
  }

  initDefaultValue() {
    this.gridState = {
      skip: 0,
      take: 50,
      sort: [{ dir: 'desc', field: 'date' }],
    };

    this.gridView = {
      data: [],
      total: 0,
    };
  }

  getCommunicationData() {
    this.loading = true;
    this.communicationsService
      .getHistoryForPatient(this.patientId, this.gridState)
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        this.gridView = {
          data: result.data,
          total: result.total || result.data.length,
        };
        this.loading = false;
      });
  }

  pageChange(event: PageChangeEvent) {
    this.gridState.skip = event.skip;
    this.getCommunicationData();
  }

  sortChange(sort: SortDescriptor[]) {
    this.gridState.sort = sort;
    this.getCommunicationData();
  }

  sortIconClass(column: any) {
    return this.gridState.sort.length &&
      this.gridState.sort[0].field === column.field &&
      this.gridState.sort[0].dir === 'asc'
      ? 'fad fa-sort-up'
      : this.gridState.sort.length &&
        this.gridState.sort[0].field === column.field &&
        this.gridState.sort[0].dir === 'desc'
      ? 'fad fa-sort-down'
      : 'fas fa-sort';
  }

  navigateToPatientInvoice(patientId: number, reason: string) {
    const invoiceId = parseInt(reason);
    this.patientService
      .getPatientById(patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((patient: any) => {
        this.patientService.patientPanelPatient = patient;
        const path = this.router.url.split('(')[0];
        this.router.navigate([
          path,
          {
            outlets: {
              'action-panel': [
                'patient',
                patientId + '_patientprofiletab',
                'patienttabs',
                'patientaccounttab',
                'invoice',
                invoiceId,
              ],
            },
          },
        ]);
      });
  }

  openPhoto(url: string): void {
    this.dialog.open(PhotoLightboxComponent, {
      panelClass: 'lightbox-dialog',
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      data: {
        photoURL: this.applySaS(url),
      },
    });
  }

  applySaS(baseUrl: string): string {
    const readOnlySAS = this.blobService.getReadOnlySAS();
    return baseUrl + readOnlySAS;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
