<div style="height: calc(100% - 65px)" kendoTooltip tooltipClass="em-tooltip" position="left">
  <ngx-loading [show]="loading || navLocked"></ngx-loading>
  <perfect-scrollbar style="position: relative">
    <div class="container panel-item-border pt-2">
      <div class="row">
        <div class="col">
          <h5>{{ observationType?.name === 'Injectables' ? 'Injectables' : 'Treatment' }} Details</h5>
          <p class="teal-font">{{ treatmentDate | date : 'yyyy-MM-dd' }}</p>
          <div class="btn-treatment">
            <div *ngIf="!isGrouping">
              <button
                *ngIf="service?.serviceDetailTemplateId != ServiceDetailTemplate.Deoxycholate && !service.isLocked"
                (click)="toggleSelection(true)"
                data-test-id="observation-price-grouping"
                class="btn btn-sm em-btn em-btn-green border-right mr-2"
              >
                Group Pricing
              </button>

              <button
                (click)="onSaveAsFavourite()"
                data-test-id="observation-favourite-save"
                class="btn btn-sm em-btn em-btn-green border-right"
                [ngClass]="{ 'mr-2': !isServicePaid }"
              >
                <i class="far fa-heart" title="Save as Favorite"></i>
              </button>
            </div>
            <div *ngIf="isGrouping && service?.serviceDetailTemplateId != ServiceDetailTemplate.Deoxycholate">
              <button
                (click)="toggleSelection(false)"
                data-test-id="observation-price-grouping"
                class="btn btn-sm em-btn em-btn-green border-right mr-2"
              >
                Cancel
              </button>
              <button
                [disabled]="selectedObservationsGrouping.length == 0"
                (click)="addPriceGrouping()"
                data-test-id="observation-price-grouping"
                class="btn btn-sm em-btn em-btn-green border-right mr-2"
              >
                Confirm Grouping
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        <!-- Toxin Observations -->
        <div class="injectable-detail">
          <div class="injectable-detail-wrapper d-flex pb-2">
            <div class="action-column"></div>
            <div class="injectable-detail-row teal-font px-2 w-100">
              <div>Area</div>
              <div>Treatment</div>
              <div>U I ml</div>
              <div>Price Per</div>
              <div>Total</div>
            </div>
            <div class="action-column"></div>
          </div>

          <div *ngFor="let toxin of toxinMap | keyvalue; let i = index" class="injectable-detail-wrapper d-flex pb-2">
            <mat-checkbox
              *ngIf="isGrouping && (selectedObservationsGrouping.length == 0 || isToxinSelected)"
              (change)="onSelection(toxin.key, toxin.value)"
              class="mr-2 my-auto"
            ></mat-checkbox>

            <div class="action-column">
              <i [ngStyle]="{ color: onGetCircleColor(toxin.key) }" class="fas fa-circle"></i>
            </div>
            <div class="w-100 injectable-detail-row navy-font grey-two-background font-weight-bold p-2">
              <div>{{ onGetArea(toxin.key) }}</div>
              <div>{{ onGetInjected(toxin.key) }}</div>
              <div>{{ onGetInjectedUnitsCount(toxin.key) }} {{ onGetInjectedUnits(toxin.key) }}</div>
              <div class="d-flex row-billing">
                <app-unit-billing
                  [service]="service"
                  [key]="toxin.key"
                  [observations]="onGetObservations(toxin.key)"
                  [isLocked]="false"
                >
                </app-unit-billing>
              </div>
              <div class="d-flex row-billing">
                <app-observation-billing
                  [service]="service"
                  [key]="toxin.key"
                  [observations]="onGetObservations(toxin.key)"
                  [isLocked]="false"
                >
                </app-observation-billing>
                <button
                  *ngIf="isPriceOverriden(toxin.key) && !service.isLocked && !isServicePaid"
                  type="button"
                  class="btn em-btn em-btn-green d-flex align-items-center justify-content-center"
                  (click)="undoOverridePrice(toxin.key)"
                >
                  <i class="far fa-undo" title="Undo Price Override"></i>
                </button>
              </div>
            </div>
            <div class="action-column">
              <button
                *ngIf="!service?.isLocked && associatedPlannedTreatment?.paymentStatus != PaymentStatus.Paid"
                type="button"
                class="btn btn-sm em-btn em-btn-green ml-1"
                data-test-id="observation-delete"
                (click)="onDeleteTreatment(toxin.key)"
              >
                <small><i class="far fa-trash-alt" title="Delete Treatment"></i></small>
              </button>
            </div>
          </div>

          <!-- Deoxycholate observations -->
          <div
            *ngFor="let deoxycholate of deoxycholateMap | keyvalue; let i = index"
            class="injectable-detail-wrapper d-flex pb-2"
          >
            <div class="action-column">
              <i [ngStyle]="{ color: onGetCircleColor(deoxycholate.key) }" class="fas fa-circle"></i>
            </div>
            <div class="w-100 injectable-detail-row navy-font grey-two-background py-2 font-weight-bold px-2">
              <div>{{ onGetArea(deoxycholate.key) }}</div>
              <div>{{ onGetInjected(deoxycholate.key) }}</div>
              <div>{{ onGetInjectedUnitsCount(deoxycholate.key) }} {{ onGetInjectedUnits(deoxycholate.key) }}</div>
              <div class="d-flex row-billing">
                <app-unit-billing
                  [service]="service"
                  [key]="deoxycholate.key"
                  [observations]="onGetObservations(deoxycholate.key)"
                  [isLocked]="false"
                >
                </app-unit-billing>
              </div>
              <div class="d-flex row-billing">
                <app-observation-billing
                  [service]="service"
                  [key]="deoxycholate.key"
                  [observations]="onGetObservations(deoxycholate.key)"
                  [isLocked]="false"
                >
                </app-observation-billing>
                <button
                  *ngIf="isPriceOverriden(deoxycholate.key) && !service.isLocked && !isServicePaid"
                  type="button"
                  class="btn em-btn em-btn-green d-flex align-items-center justify-content-center"
                  (click)="undoOverridePrice(deoxycholate.key)"
                >
                  <i class="far fa-undo" title="Undo Price Override"></i>
                </button>
              </div>
            </div>
            <div class="action-column">
              <button
                *ngIf="!service?.isLocked && associatedPlannedTreatment?.paymentStatus != PaymentStatus.Paid"
                type="button"
                class="btn btn-sm em-btn em-btn-green ml-1"
                data-test-id="observation-delete"
                (click)="onDeleteTreatment(deoxycholate.key)"
              >
                <small><i class="far fa-trash-alt" title="Delete Treatment"></i></small>
              </button>
            </div>
          </div>

          <!-- Filler Observations -->
          <div *ngIf="fillerMap.size > 0" class="injectable-detail">
            <div *ngFor="let filler of fillerMap | keyvalue" class="injectable-detail-wrapper d-flex pb-2">
              <mat-checkbox
                *ngIf="isGrouping && (selectedObservationsGrouping.length == 0 || !isToxinSelected)"
                (change)="onSelection(filler.key, filler.value)"
                class="mr-2 my-auto"
              ></mat-checkbox>
              <div class="action-column">
                <i [ngStyle]="{ color: onGetCircleColor(filler.key) }" class="fas fa-circle"></i>
              </div>
              <div class="w-100 injectable-detail-row navy-font grey-two-background py-2 font-weight-bold px-2">
                <div>{{ onGetArea(filler.key) }}</div>
                <div>{{ onGetInjected(filler.key) }}</div>
                <div>{{ onGetInjectedUnitsCount(filler.key) }} {{ onGetInjectedUnits(filler.key) }}</div>
                <div class="d-flex row-billing">
                  <app-unit-billing
                    [service]="service"
                    [key]="filler.key"
                    [observations]="onGetObservations(filler.key)"
                    [isLocked]="service.isLocked"
                  >
                  </app-unit-billing>
                </div>
                <div class="d-flex row-billing">
                  <app-observation-billing
                    [service]="service"
                    [key]="filler.key"
                    [observations]="onGetObservations(filler.key)"
                    [isLocked]="service.isLocked"
                  >
                  </app-observation-billing>
                  <button
                    *ngIf="isPriceOverriden(filler.key) && !service.isLocked && !isServicePaid"
                    type="button"
                    class="btn em-btn em-btn-green d-flex align-items-center justify-content-center"
                    (click)="undoOverridePrice(filler.key)"
                  >
                    <i class="far fa-undo" title="Undo Price Override"></i>
                  </button>
                </div>
              </div>
              <div class="action-column">
                <button
                  *ngIf="!service?.isLocked && associatedPlannedTreatment?.paymentStatus != PaymentStatus.Paid"
                  class="btn py-0 pr-0"
                  (click)="onDeleteTreatment(filler.key)"
                >
                  <small><i class="far fa-trash-alt" title="Delete Treatment"></i></small>
                </button>
              </div>
            </div>
          </div>

          <!-- Grouped Observations -->
          <div *ngFor="let group of groupedMap | keyvalue">
            <div class="w-100 grey-four-border-top">
              <div class="d-flex pt-2 pb-1">
                <div class="action-column"></div>
                <div class="injectable-detail-grouping-row font-weight-bold group-heading p-2 w-100">
                  <div>{{ group.key.split('/')[0] }} Cost:</div>
                  <div class="d-flex justify-content-end pr-3">{{ group.key.split('/')[1] | currency }}</div>
                </div>
                <div class="action-column">
                  <button
                    *ngIf="!service?.isLocked && associatedPlannedTreatment?.paymentStatus != PaymentStatus.Paid"
                    type="button"
                    class="btn btn-sm em-btn em-btn-green ml-1"
                    (click)="removePriceGrouping(group.key)"
                  >
                    <small><i class="far fa-trash-alt" title="Delete Treatment"></i></small>
                  </button>
                </div>
              </div>

              <div class="w-100 grey-four-border-top pt-1">
                <div *ngFor="let obs of group.value | keyvalue" class="injectable-detail-wrapper d-flex pb-2">
                  <div class="action-column">
                    <i [ngStyle]="{ color: onGetCircleColor(obs.key) }" class="fas fa-circle"></i>
                  </div>
                  <div class="w-100 injectable-detail-row navy-font grey-two-background py-2 font-weight-bold px-2">
                    <div>{{ onGetArea(obs.key) }}</div>
                    <div>{{ onGetInjected(obs.key) }}</div>
                    <div>{{ onGetInjectedUnitsCount(obs.key) }} {{ onGetInjectedUnits(obs.key) }}</div>
                    <div class="d-flex row-billing justify-content-end pr-3">
                      {{ onGetInjectedUnitsPrice(obs.key) | currency }}
                    </div>
                    <div class="d-flex row-billing justify-content-end pr-3">
                      {{ onGetInjectedTotalPrice(obs.key) | currency }}
                    </div>
                  </div>
                  <div class="action-column"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex">
            <div class="w-100 grey-four-border-top">
              <app-treatment-billing
                [service]="service"
                (sendChargeAmount)="updateChargeAmount($event)"
              ></app-treatment-billing>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Treatment Notes -->
    <div class="grey-two-background p-2">
      <div class="row px-2">
        <div class="col d-flex">
          <h6 class="mt-1 small-heading navy-font">Treatment Notes</h6>
        </div>
      </div>
      <div class="row px-2">
        <div class="col">
          <textarea
            [ngClass]="{ 'grey-two-background': service.isLocked }"
            class="service-chart-input treatment-notes-input w-100"
            placeholder="Add a note"
            [readonly]="service.isLocked"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            [(ngModel)]="serviceNoteText"
            (change)="currentDataService.treatmentIsDirty = true"
          ></textarea>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</div>

<div class="row mt-2 px-3">
  <button (click)="onToggleView()" class="btn btn-grey-two btn-clean em-btn-black-text ml-auto">CLOSE</button>
  <button
    *ngIf="!service?.isLocked"
    (click)="onSave(false)"
    [disabled]="!currentDataService.treatmentIsDirty"
    class="btn em-btn em-btn-green ml-2"
  >
    Save
  </button>
  <button
    *ngIf="!service?.isLocked"
    (click)="onSave(true)"
    [disabled]="!currentDataService.treatmentIsDirty"
    class="btn em-btn em-btn-green ml-2"
  >
    Save & Close
  </button>
</div>
