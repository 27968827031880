<ngx-loading [show]="loading"></ngx-loading>
<kendo-grid
  class="emr-kendo-grid"
  [data]="gridView"
  [style.maxHeight.%]="100"
  [pageable]="false"
  [pageSize]="gridState.take"
  [skip]="gridState.skip"
  [group]="gridState.group"
  #grid="kendoGrid"
>
  <kendo-grid-column [width]="180" field="supplyName" title="Clinic Supply" format="{0:0.##}"> </kendo-grid-column>
  <kendo-grid-column [width]="110" field="start" title="Start" format="{0:0.##}" headerClass="text-right">
  </kendo-grid-column>
  <kendo-grid-column [width]="110" field="end" title="End" format="{0:0.##}" headerClass="text-right">
  </kendo-grid-column>
  <kendo-grid-column [width]="110" field="used" title="Used" format="{0:0.##}" headerClass="text-right">
  </kendo-grid-column>
  <kendo-grid-column [width]="110" field="added" title="Added" format="{0:0.##}" headerClass="text-right">
  </kendo-grid-column>
  <kendo-grid-column [width]="110" field="removed" title="Removed" format="{0:0.##}" headerClass="text-right">
  </kendo-grid-column>
  <kendo-grid-column [width]="110" field="onHand" title="On Hand" format="{0:0.##}" headerClass="text-right">
  </kendo-grid-column>
  <kendo-grid-column
    *ngFor="let provider of providerList"
    [width]="180"
    title="{{ provider.fullName }}"
    field="{{ provider.userId }}"
    format="{0:0.##}"
    headerClass="text-right"
  >
  </kendo-grid-column>
  <kendo-grid-column field="category" [hidden]="true">
    <ng-template kendoGridGroupHeaderTemplate let-group="group">
      {{
        group.value +
          ' ' +
          (group.items[0].unit && group.items[0].unit != 'applicator' ? '(' + group.items[0].unit + ')' : '')
      }}
    </ng-template>
  </kendo-grid-column>
  <!--padding column-->
  <kendo-grid-column> </kendo-grid-column>
  <ng-template kendoGridDetailTemplate let-dataItem [kendoGridDetailTemplateShowIf]="checkAreasExist">
    <app-supply-details [observationListId]="dataItem.observationListId" [gridState]="gridState"></app-supply-details>
  </ng-template>
</kendo-grid>
