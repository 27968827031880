// These match exactly the back end permissions
export enum authPermissions {
  // Clinic
  ClinicGeneral = 'ClinicGeneral',
  ClinicUsers = 'ClinicUsers',
  ClinicUserPermissions = 'ClinicUserPermissions',
  ClinicResources = 'ClinicResources',
  ClinicPhotos = 'ClinicPhotos',
  ClinicCancellationReasons = 'ClinicCancellationReasons',
  ClinicAccessLocations = 'ClinicAccessLocations',
  ClinicPayments = 'ClinicPayments',
  ClinicOnlineBooking = 'ClinicOnlineBooking',
  ClinicMerchantDevices = 'ClinicMerchantDevices',

  // Communications
  CommunicationsSummary = 'CommunicationsSummary',
  CommunicationsAppointments = 'CommunicationsAppointments',
  CommunicationsRetentionCampaigns = 'CommunicationsRetentionCampaigns',
  CommunicationsEmailTemplates = 'CommunicationsEmailTemplates',

  // Management
  AccessFromAnyLocation = 'AccessFromAnyLocation',
  AddressBook = 'AddressBook',
  Appointments = 'Appointments',
  Billing = 'Billing',
  Catalogue = 'Catalogue',
  Forms = 'Forms',
  Gallery = 'Gallery',
  Inventory = 'Inventory',
  InventoryNotifications = 'InventoryNotifications',
  Messaging = 'Messaging',
  Organization = 'Organization',
  StaffSchedule = 'StaffSchedule',

  // Patients
  PatientList = 'PatientList',
  PatientMerge = 'PatientMerge',
  PatientProfile = 'PatientProfile',
  PatientTreatmentPlan = 'PatientTreatmentPlan',
  PatientChart = 'PatientChart',
  PatientChartDownload = 'PatientChartDownload',
  PatientAccount = 'PatientAccount',
  PatientAccountTransactions = 'PatientAccountTransactions',
  PatientPhotos = 'PatientPhotos',
  PatientCommunications = 'PatientCommunications',
  PatientDocuments = 'PatientDocuments',
  PatientNudges = 'PatientNudges',

  // Reports
  ReportsClinic = 'ReportsClinic',
  ReportsClinicSupplies = 'ReportsClinicSupplies',
  ReportsCommissions = 'ReportsCommissions',
  ReportsInvoices = 'ReportsInvoices',
  ReportsLiabilities = 'ReportsLiabilities',
  ReportsPatients = 'ReportsPatients',
  ReportsProducts = 'ReportsProducts',
  ReportsProviders = 'ReportsProviders',
  ReportsServices = 'ReportsServices',
  ReportsPower = 'ReportsPower',

  // Reviews
  ReviewsRequest = 'ReviewsRequest',
  ReviewsView = 'ReviewsView',
  ReviewsEdit = 'ReviewsEdit',

  // Patient Messaging
  PatientMessagingView = 'PatientMessagingView',
  PatientMessagingSend = 'PatientMessagingSend',
  PatientMessagingRemove = 'PatientMessagingRemove',

  // Developer
  Developer = 'Developer',

  // Memberships
  MembershipsEdit = 'MembershipsEdit',
  MembershipsCancel = 'MembershipsCancel',
  MembershipsEarlyCancel = 'MembershipsEarlyCancel',
}

export enum authRoles {
  Clinic = 'Clinic',
  Catalogue = 'Catalogue',
  Communications = 'Communications',
  Management = 'Management',
  Patients = 'Patients',
  Reports = 'Reports',
  Reviews = 'Reviews',
  PatientMessaging = 'PatientMessaging',
  Developer = 'Developer',
  Memberships = 'Memberships',
}
