import { Component, OnInit } from '@angular/core';

import { PatientService } from '@services/patient.service';
import { TreatmentPlanFormService } from '@services/treatment-planning/treatment-plan-form.service';
import { TreatmentPlan } from '@models/treatment-planning/treatment-plan';
import { Patient } from '@models/patient';
import { TabType } from '@models/tab-type.enum';

@Component({
  selector: 'app-treatment-plan',
  templateUrl: './treatment-plan.component.html',
  styleUrls: ['./treatment-plan.component.less']
})
export class TreatmentPlanComponent implements OnInit {
  treatmentPlan: TreatmentPlan;
  patient: Patient;
  updatePatientCalledLocally: boolean;
  isExpanded = false;
  TabType = TabType;

  constructor(
    private patientService: PatientService,
    public treatmentPlanFormService: TreatmentPlanFormService
  ) {
    this.patient = this.patientService.patientPanelPatient;
  }

  ngOnInit() {
  }

}
