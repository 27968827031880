<ngx-loading [show]="loading" [config]="{ fullScreenBackdrop: true }"></ngx-loading>
<div
  class="edit-container position-absolute z-index-3 white-background d-flex flex-column w-100"
  [ngClass]="{ 'w-0': !initialized, 'w-100': initialized }"
>
  <div class="re-links-container">
    <button class="btn em-btn teal-background" (click)="checkUnsavedChanges()">
      <i class="far fa-times" aria-hidden="true"></i>
    </button>
  </div>

  <div class="campaigns-toolbar row px-2 navy-background mx-auto w-100">
    <div class="col-12 d-flex align-items-center justify-content-between">
      <h5 class="white-font text-uppercase m-0 mr-2">
        Campaign Builder {{ form?.get('title').value ? '-' : '' }} {{ form?.get('title').value }}
        {{ disabledForm ? '(COMPLETED)' : '' }}
      </h5>
      <div class="d-flex flex-nowrap mr-3">
        <button
          class="btn em-btn em-btn-green mr-4 campaign-button"
          (click)="saveChanges()"
          [disabled]="!form.valid"
          [hidden]="disabledForm"
        >
          {{ saveBtnName }}
        </button>
        <button
          *ngIf="form.value?.sendTo === 'targeted' || form.value?.sendTo === 'all'"
          class="btn em-btn em-btn-green mr-4 campaign-button view-recipients"
          (click)="onPreview()"
          [disabled]="campaignId == 0 || form.dirty"
          [hidden]="disabledForm"
        >
          View Recipients
        </button>
        <button
          *ngIf="
            campaignId != 0 &&
            (form?.controls['campaignType']?.value == 'SMS' || form?.controls['campaignType']?.value == 'Email/SMS')
          "
          class="btn em-btn em-btn-green mr-4 campaign-button send-test"
          (click)="sendTest('sms')"
          [hidden]="disabledForm"
        >
          SMS Test
        </button>
        <button
          *ngIf="
            campaignId != 0 &&
            (form?.controls['campaignType']?.value == 'Email' || form?.controls['campaignType']?.value == 'Email/SMS')
          "
          class="btn em-btn em-btn-green mr-4 campaign-button send-test"
          (click)="sendTest('email')"
          [hidden]="disabledForm"
        >
          Send Test
        </button>
        <button class="btn em-btn em-btn-green mr-4 campaign-button clone" (click)="cloneCampaign()">Clone</button>
        <button class="btn em-btn em-btn-green mr-4 campaign-button" (click)="checkUnsavedChanges()">Cancel</button>
        <button class="btn em-btn em-btn-green campaign-button" (click)="deleteCampaign()" [hidden]="disabledForm">
          Delete
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="showSuccessMessage" class="success-banner">Saved successfully!</div>

  <div class="d-flex flex-column flex-grow-1 w-100">
    <app-error class="w-100" *ngIf="errorMessage" [message]="errorMessage"></app-error>
    <div class="d-flex w-100 flex-grow-1">
      <form
        autocomplete="off"
        class="flex-shrink-1 teal-font line-height-1 px-0 pb-2 light-grey-background h-100"
        style="min-width: 25rem; max-width: 30rem; width: calc(50%)"
        [formGroup]="form"
      >
        <perfect-scrollbar
          style="max-width: 100%; top: 0; left: 0"
          appDetermineMaxScrollHeight
          [addHeight]="0"
          [itemsToWatch]="[panelHeader._isExpanded(), panelHeader0._isExpanded(), panelHeader1._isExpanded()]"
        >
          <div>
            <div class="row mx-0 teal-background">
              <div class="col">
                <h6 class="white-font uppercase my-0 py-2">{{ actionType }} Campaign</h6>
              </div>
            </div>

            <div class="py-2">
              <div class="row mx-0">
                <div class="col">
                  <mat-form-field class="w-100">
                    <input autocomplete="off" formControlName="title" matInput placeholder="Campaign Title" />
                  </mat-form-field>
                </div>
              </div>

              <div class="row mx-0">
                <div class="col">
                  <mat-form-field class="w-100">
                    <mat-label>Type</mat-label>
                    <mat-select #typeSelect formControlName="campaignType">
                      <mat-option [value]="'Email'"> Email </mat-option>
                      <mat-option [value]="'SMS'"> SMS </mat-option>
                      <mat-option [value]="'Email/SMS'"> Email/SMS </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field class="w-100">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status">
                      <mat-option *ngFor="let status of campaignStatuses" [value]="status">
                        {{ status }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>

          <div [hidden]="form?.controls['campaignType']?.value == 'SMS'">
            <div class="row mx-0 teal-background">
              <div class="col">
                <h6 class="white-font uppercase mb-0 py-2">Email Message</h6>
              </div>
            </div>
            <div class="py-3">
              <div class="col" style="padding-right: 15px; padding-left: 15px">
                <mat-form-field class="w-100">
                  <mat-label>Original Email Template</mat-label>
                  <mat-select formControlName="template">
                    <mat-option *ngFor="let template of emailTemplates" [value]="template">
                      {{ template.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- <a  target="_blank" class="btn teal-font kendo-btn d-flex align-items-center" [href]="'https://app.mailjet.com/template/' + form.get('template')?.value?.id + '/build'"><i class="fas fa-edit"></i></a> -->
              </div>
              <div class="col">
                <mat-form-field class="w-100">
                  <input autocomplete="off" formControlName="emailSubjectLine" matInput placeholder="Email Subject" />
                </mat-form-field>
              </div>
              <div class="col mx-0">
                <div class="text-dark">
                  <ng-template #emojiTemplate>
                    Include emojis 😍💖😊 to your email subject or SMS messages!
                    <ul class="mb-0">
                      <li>On Mac: Press Control+Command+Space while editing the text</li>
                      <li>On Windows: Press Windows+. while editing the text</li>
                    </ul>
                  </ng-template>
                  <div kendoTooltip [tooltipTemplate]="emojiTemplate" filter="i" position="right">
                    Use Emoji <i class="fa fa-question-circle teal-font"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div [hidden]="form?.controls['campaignType']?.value == 'Email'">
            <div class="row mx-0 teal-background">
              <div class="col">
                <h6 class="white-font uppercase mb-0 py-2">SMS Message</h6>
              </div>
            </div>
            <div class="py-3">
              <div class="row mx-0">
                <div class="col-12">
                  <mat-form-field class="w-100 textbox" appearance="outline">
                    <mat-label>SMS Text</mat-label>

                    <textarea
                      formControlName="smsCampaignText"
                      matInput
                      #smsMessage
                      maxlength="140"
                      rows="4"
                      class="overflow-hidden no-resize"
                    >
                    </textarea>
                    <mat-hint align="start" [ngClass]="disabledForm ? 'text-dark' : 'text-danger'" class="larger-hint">
                      ** "Reply UNSUB to unsubscribe" is appended to each sms message automatically
                    </mat-hint>
                    <mat-hint align="end">{{ smsMessage.value.length }} / 140</mat-hint>
                  </mat-form-field>
                </div>
              </div>
              <div class="row mx-0">
                <div class="col text-dark">
                  <p class="py-2 my-0">
                    Use variables to personalize a message! ex. {{ '[FirstName]' }} -> Jane, {{ '[PatientName]' }} ->
                    Jane Smith, <br />
                    {{ '[ClinicName]' }} -> DermMedica
                  </p>
                </div>
              </div>
              <div class="row mx-0">
                <div class="col text-dark">
                  <ng-template #emojiTemplate>
                    Include emojis 😍💖😊 to your email subject or SMS messages!
                    <ul class="mb-0">
                      <li>On Mac: Press Control+Command+Space while editing the text</li>
                      <li>On Windows: Press Windows+. while editing the text</li>
                    </ul>
                  </ng-template>
                  <div kendoTooltip [tooltipTemplate]="emojiTemplate" filter="i" position="right">
                    Use Emoji <i class="fa fa-question-circle teal-font"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="row mx-0 teal-background">
              <div class="col">
                <h6 class="white-font uppercase mb-0 py-2">When to send</h6>
              </div>
            </div>

            <div class="py-3">
              <div class="row mx-0">
                <div class="col d-flex">
                  <mat-radio-group
                    class="example-radio-group mr-2"
                    name="whenSend"
                    formControlName="whenSend"
                    (change)="whenSendChanged('once')"
                  >
                    <mat-radio-button class="example-radio-button" value="once"> </mat-radio-button>
                  </mat-radio-group>
                  <div
                    class="d-flex flex-column w-100"
                    [class.isDisabled]="form.value?.whenSend !== 'once'"
                    [ngClass]="{ 'text-dark': disabledForm }"
                  >
                    Send this email campaign one time on:
                    <div class="d-flex align-items-center mt-2">
                      <kendo-datepicker
                        [disabled]="form.value?.whenSend == 'recurring'"
                        [placeholder]="'Select Date...'"
                        [min]="actionType == 'Edit' ? null : min"
                        [format]="'yyyy-MM-dd'"
                        formControlName="startOnDate"
                      >
                      </kendo-datepicker>
                      <kendo-timepicker
                        [disabled]="form.value?.whenSend == 'recurring'"
                        [placeholder]="'Select Time...'"
                        [min]="actionType == 'Edit' ? null : min"
                        class="ml-2"
                        [format]="'HH:mm'"
                        formControlName="startOnTime"
                      >
                      </kendo-timepicker>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3 mx-0">
                <div class="col d-flex">
                  <mat-radio-group
                    #whenSend
                    class="example-radio-group mr-2"
                    name="whenSend"
                    formControlName="whenSend"
                    (change)="whenSendChanged('recurring')"
                  >
                    <mat-radio-button class="example-radio-button" value="recurring"> </mat-radio-button>
                  </mat-radio-group>

                  <div
                    class="d-flex flex-column w-100"
                    [class.isDisabled]="form.value?.whenSend !== 'recurring'"
                    [ngClass]="{ 'text-dark': disabledForm }"
                  >
                    Automatically send this email on a recurring basis:

                    <div class="d-flex align-items-center mt-2">
                      <span class="flex-shrink-0">Starting on:</span>

                      <kendo-datepicker
                        [disabled]="disabledForm || form.value?.whenSend !== 'recurring'"
                        [placeholder]="'Select Start Date...'"
                        class="ml-2"
                        [format]="'yyyy-MM-dd'"
                        formControlName="recurringStartOnDate"
                        [ngClass]="{ 'ng-invalid ng-touched': submitted && f.recurringStartOnDate.errors }"
                      >
                      </kendo-datepicker>
                    </div>

                    <div class="d-flex align-items-center mt-2">
                      <span class="flex-shrink-0 pr-2">Send email every:</span>
                      <input
                        autocomplete="off"
                        [ngClass]="{ 'text-dark': disabledForm }"
                        [class.disabled-element]="form.value?.whenSend !== 'recurring'"
                        [disabled]="form.value?.whenSend !== 'recurring'"
                        class="input-number mr-2 flex-grow-1 light-grey-background"
                        onlyNumber
                        matInput
                        formControlName="repeatPeriodValue"
                        min="1"
                      />
                      <mat-form-field class="sender-type ml-2 flex-grow-1">
                        <mat-select
                          [ngClass]="{ 'text-dark': disabledForm }"
                          [class.disabled-element]="form.value?.whenSend !== 'recurring'"
                          [disabled]="form.value?.whenSend !== 'recurring'"
                          formControlName="repeatPeriodUnit"
                        >
                          <mat-option *ngFor="let type of timePeriodUnits" [value]="type">
                            {{ type }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="d-flex align-items-center mt-2">
                      <span class="flex-shrink-0 pr-2">Days To Send:</span>

                      <mat-radio-group
                        class="example-radio-group mr-2"
                        name="selectedDayOfWeek"
                        formControlName="selectedDayOfWeek"
                      >
                        <mat-radio-button
                          class="example-radio-button days-to-send-radio my-1 pr-2"
                          *ngFor="let day of weekdays"
                          [value]="day"
                          [class.disabled-element]="form.value?.whenSend !== 'recurring'"
                        >
                          {{ day | slice : 0 : 2 }}
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div class="d-flex align-items-center mt-2">
                      <span class="flex-shrink-0">At:</span>

                      <kendo-timepicker
                        [disabled]="disabledForm || form.value?.whenSend !== 'recurring'"
                        [placeholder]="'Select Send Time...'"
                        class="ml-2"
                        [format]="'HH:mm'"
                        formControlName="recurringSendTime"
                      >
                      </kendo-timepicker>
                    </div>

                    <div class="d-flex align-items-center mt-2">
                      <mat-checkbox
                        [class.disabled]="form.value?.whenSend !== 'recurring'"
                        formControlName="endOnCheckbox"
                        #endRecurringDate
                        (change)="endOnChecked($event)"
                        class="reports-checkbox mr-2"
                      >
                      </mat-checkbox>
                      <span class="flex-shrink-0">Ending:</span>

                      <kendo-datepicker
                        [disabled]="endRecurringDate?.disabled"
                        [placeholder]="'Select End Date...'"
                        [min]="actionType == 'Edit' ? null : min"
                        class="ml-2"
                        [format]="'yyyy-MM-dd'"
                        formControlName="endOnDate"
                      >
                      </kendo-datepicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="row mx-0 teal-background">
              <div class="col">
                <h6 class="white-font uppercase mb-0 py-2">Who to Send to</h6>
              </div>
            </div>

            <div class="py-3">
              <div class="row mx-0">
                <div
                  class="col d-flex align-items-center"
                  [ngClass]="{ 'text-dark': disabledForm }"
                  [class.isDisabled]="form.value?.sendTo == 'targeted'"
                >
                  <mat-radio-group class="example-radio-group mr-2" name="" formControlName="sendTo">
                    <mat-radio-button class="example-radio-button" value="all"> </mat-radio-button>
                  </mat-radio-group>
                  Send to all active patients
                </div>
              </div>

              <div class="row mt-2 mx-0">
                <div
                  class="col d-flex align-items-center"
                  [ngClass]="{ 'text-dark': disabledForm }"
                  [class.isDisabled]="form.value?.sendTo !== 'targeted'"
                >
                  <mat-radio-group class="example-radio-group mr-2" name="" formControlName="sendTo">
                    <mat-radio-button class="example-radio-button" value="targeted"> </mat-radio-button>
                  </mat-radio-group>
                  Send to targeted list of patients
                </div>
              </div>
              <div class="row mx-0 pl-3" *ngIf="form.value?.sendTo == 'targeted' && !disabledForm">
                <div class="text-danger">
                  <p class="pt-2 pb-0 my-0">
                    * Please select at least one criteria from Demographics, Visit History, or Purchased Services &
                    Products
                  </p>
                </div>
              </div>
              <div class="row mx-0 my-2">
                <div class="col d-flex flex-wrap align-items-center">
                  <mat-checkbox #dontEmailCheckbox formControlName="dontEmailCheckbox" class="reports-checkbox mr-2">
                  </mat-checkbox>
                  <span
                    [ngClass]="{ 'text-dark': disabledForm }"
                    [class.disabled-element]="!dontEmailCheckbox.checked"
                    class="flex-shrink-0"
                    >Don't email patients if they've been contacted by this campaign&nbsp;</span
                  >
                  <span
                    [ngClass]="{ 'text-dark': disabledForm }"
                    [class.disabled-element]="!dontEmailCheckbox.checked"
                    class="flex-shrink-0"
                    >in the last&nbsp;&nbsp;</span
                  >
                  <input
                    autocomplete="off"
                    [ngClass]="{ 'text-dark': disabledForm }"
                    [class.disabled-element]="!dontEmailCheckbox.checked"
                    class="input-number flex-grow-0 light-grey-background"
                    onlyNumber
                    matInput
                    formControlName="dontEmailPeriodValue"
                    min="1"
                  />
                  <mat-form-field class="sender-type ml-2 flex-grow-0 pt-1">
                    <mat-select
                      [ngClass]="{ 'text-dark': disabledForm }"
                      [class.disabled-element]="!dontEmailCheckbox.checked"
                      [disabled]="!dontEmailCheckbox.checked"
                      formControlName="dontEmailPeriodUnit"
                    >
                      <mat-option *ngFor="let type of timePeriodUnitsSecondary" [value]="type">
                        {{ type }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div [class.isHidden]="form.value?.sendTo !== 'targeted'" class="pb-4">
                <mat-accordion [multi]="true">
                  <mat-expansion-panel #panelList0="matExpansionPanel" [expanded]="true">
                    <mat-expansion-panel-header
                      [collapsedHeight]="'auto'"
                      class="light-grey-background"
                      [expandedHeight]="'auto'"
                      #panelHeader0
                    >
                      <mat-panel-title>
                        <div class="d-flex align-items-center panel-box-retention py-1">
                          DEMOGRAPHICS
                          <button
                            type="button"
                            class="btn d-flex flex-column expand-btn ml-auto p-0"
                            (click)="panelHeader0._toggle()"
                          >
                            <mat-icon (click)="panelHeader0._toggle()">{{
                              panelList0.expanded ? 'arrow_drop_up' : 'arrow_drop_down'
                            }}</mat-icon>
                          </button>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="retention-campaign-accordion teal-font">
                      <div class="row mx-0">
                        <div class="col d-flex align-items-center" [ngClass]="{ 'text-dark': disabledForm }">
                          Specified Gender:
                          <mat-form-field class="mx-2">
                            <mat-select formControlName="gender">
                              <mat-option *ngFor="let type of genderTypes" [value]="type">
                                {{ type }}
                              </mat-option>
                              <mat-option [value]="null"> Not Specified </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row mx-0 mb-2">
                        <div class="col d-flex align-items-center">
                          <mat-checkbox
                            #futureBirthdayCheckbox
                            formControlName="futureBirthdayCheckbox"
                            class="reports-checkbox mr-2"
                          >
                          </mat-checkbox>
                          <span
                            [ngClass]="{ 'text-dark': disabledForm }"
                            [class.disabled-element]="!futureBirthdayCheckbox.checked"
                            class="flex-shrink-0"
                            >Their birthday is within&nbsp;</span
                          >

                          <input
                            autocomplete="off"
                            [ngClass]="{ 'text-dark': disabledForm }"
                            [class.disabled-element]="!futureBirthdayCheckbox.checked"
                            class="input-number flex-grow-0 light-grey-background"
                            onlyNumber
                            matInput
                            formControlName="futureBirthdayPeriodValue"
                            min="1"
                          />
                          <mat-form-field class="sender-type ml-2 flex-grow-0 pt-1">
                            <mat-select
                              [ngClass]="{ 'disabled-element': !futureBirthdayCheckbox.checked }"
                              [disabled]="!futureBirthdayCheckbox.checked"
                              formControlName="futureBirthdayPeriodUnit"
                            >
                              <mat-option *ngFor="let type of timePeriodUnitsSecondary" [value]="type">
                                {{ type }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <span
                            [ngClass]="{ 'text-dark': disabledForm }"
                            [class.disabled-element]="!futureBirthdayCheckbox.checked"
                            class="flex-shrink-0"
                            >&nbsp;&nbsp;from today</span
                          >
                        </div>
                      </div>
                      <div class="row mx-0">
                        <div class="col d-flex flex-wrap align-items-center">
                          <!-- Commented out as it is currently broken and not needed -->
                          <!-- <div class="d-flex align-items-center">
                          <span class="birthday-label flex-shrink-0 pr-1 my-2" [ngClass]="{ 'text-dark': disabledForm }"> Birthday After:</span>
                          <kendo-datepicker
                            [placeholder]="'Not Specified'"
                            [min]="beginningYear"
                            [max]="endYear"
                            class="emr-datepicker w-100 ml-2"
                            [format]="'MM-dd'"
                            [ngClass]="{ 'ng-invalid ng-touched': submitted && f.birthdayAfter.errors }"
                            formControlName="birthdayAfter"
                          >
                          </kendo-datepicker>
                        </div>
  
                        <div class="d-flex align-items-center">
                          <span class="birthday-label flex-shrink-0 pr-1 my-2" [ngClass]="{ 'text-dark': disabledForm }">
                            Birthday Before:</span
                          >
  
                          <kendo-datepicker
                            [placeholder]="'Not Specified'"
                            [min]="beginningYear"
                            [max]="endYear"
                            class="emr-datepicker w-100 ml-2"
                            [format]="'MM-dd'"
                            [ngClass]="{ 'ng-invalid ng-touched': submitted && f.birthdayBefore.errors }"
                            formControlName="birthdayBefore"
                          >
                          </kendo-datepicker>
                        </div> -->

                          <div class="d-flex align-items-center">
                            <span
                              class="birthday-label flex-shrink-0 pr-1 my-2"
                              [ngClass]="{ 'text-dark': disabledForm }"
                            >
                              Min Birth Year:</span
                            >
                            <input
                              autocomplete="off"
                              formControlName="minBirthYear"
                              onlyNumber
                              matInput
                              [maxDigits]="4"
                              placeholder="Not Specified"
                              class="input-number flex-grow-1 ml-2 mr-2 light-grey-background"
                              style="min-width: 6rem"
                            />
                          </div>
                          <div class="d-flex align-items-center">
                            <span
                              class="birthday-label flex-shrink-0 pr-1 my-2"
                              [ngClass]="{ 'text-dark': disabledForm }"
                            >
                              Max Birth Year:</span
                            >
                            <input
                              autocomplete="off"
                              matInput
                              formControlName="maxBirthYear"
                              onlyNumber
                              [maxDigits]="4"
                              placeholder="Not Specified"
                              class="input-number flex-grow-1 ml-2 mr-2 light-grey-background"
                              style="min-width: 6rem"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>

                  <div class="my-1"></div>

                  <mat-expansion-panel #panelList="matExpansionPanel" [expanded]="true" class="visit-history-panel">
                    <mat-expansion-panel-header
                      [collapsedHeight]="'auto'"
                      class="light-grey-background"
                      [expandedHeight]="'auto'"
                      #panelHeader
                    >
                      <mat-panel-title>
                        <div class="d-flex align-items-center panel-box-retention py-1">
                          VISIT HISTORY
                          <button
                            type="button"
                            class="btn d-flex flex-column expand-btn ml-auto p-0"
                            (click)="panelHeader._toggle()"
                          >
                            <mat-icon (click)="panelHeader._toggle()">{{
                              panelList.expanded ? 'arrow_drop_up' : 'arrow_drop_down'
                            }}</mat-icon>
                          </button>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="retention-campaign-accordion teal-font">
                      <!--
                  <div class="row mx-0 mb-2">
                    <div class="col px-0 d-flex align-items-center">
                      <mat-checkbox
                        formControlName="timeElapsedCheckbox"
                        class="reports-checkbox mr-2">
                      </mat-checkbox>
                      <span class="flex-shrink-0">It has been</span>
                      <mat-form-field class="sender-type mx-2" floatLabel='none'>
                        <mat-select formControlName="timeElapsedLessMore">
                          <mat-option *ngFor="let type of lessMoreTypes" [value]="type">
                            {{ type }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <input
                        type="number"
                        class="input-number flex-grow-1 mr-2"
                        value="1"
                        min="1"
                        formControlName="timeElapsedValue">
                        days since their
                      <mat-form-field class="sender-type mx-2" floatLabel='none'>
                        <mat-select formControlName="timeElapsedFirstLast">
                          <mat-option *ngFor="let type of firstLastTypes" [value]="type">
                            {{ type }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      visit
                    </div>
                  </div>
  
                  <div class="row mx-0 mb-2">
                    <div class="col px-0 d-flex align-items-center">
                      <mat-checkbox
                        formControlName="timeElapsedCheckbox2"
                        class="reports-checkbox mr-2">
                      </mat-checkbox>
                      <span class="flex-shrink-0">And it has been</span>
                      <mat-form-field class="sender-type mx-2" floatLabel='none'>
                        <mat-select formControlName="timeElapsedLessMore2">
                          <mat-option *ngFor="let type of lessMoreTypes" [value]="type">
                            {{ type }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <input
                        type="number"
                        class="input-number flex-grow-1 mr-2"
                        value="1"
                        min="1"
                        formControlName="timeElapsedValue2">
                        days since their
                      <mat-form-field class="sender-type mx-2" floatLabel='none'>
                        <mat-select formControlName="timeElapsedFirstLast2">
                          <mat-option *ngFor="let type of firstLastTypes" [value]="type">
                            {{ type }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      visit
                    </div>
                  </div>
  
                  <div class="divider my-3"></div>
  
                  <div class="row mx-0 mb-2">
                    <div class="col px-0 d-flex align-items-center">
                      <mat-checkbox
                        formControlName="visitDateCheckbox"
                        class="reports-checkbox mr-2">
                      </mat-checkbox>
                      <span class="flex-shrink-0">Their</span>
                      <mat-form-field class="sender-type mx-2" floatLabel='none'>
                        <mat-select formControlName="visitDateFirstLast">
                          <mat-option *ngFor="let type of firstLastTypes" [value]="type">
                            {{ type }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <span class="flex-shrink-0">visit was</span>
                      <mat-form-field class="sender-type ml-2" floatLabel='none'>
                        <mat-select formControlName="visitDateBeforeAfter">
                          <mat-option *ngFor="let type of beforeAfterTypes" [value]="type">
                            {{ type }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <kendo-datepicker
                        class="emr-datepicker w-100 ml-2"
                        placeholder="Select"
                        formControlName="visitDateValue">
                      </kendo-datepicker>
                    </div>
                  </div>
  
  
                  <div class="row mx-0 mb-2">
                    <div class="col px-0 d-flex align-items-center">
                      <mat-checkbox
                        formControlName="visitDateCheckbox2"
                        class="reports-checkbox mr-2">
                      </mat-checkbox>
                      <span class="flex-shrink-0">And their</span>
                      <mat-form-field class="sender-type mx-2" floatLabel='none'>
                        <mat-select formControlName="visitDateFirstLast2">
                          <mat-option *ngFor="let type of firstLastTypes" [value]="type">
                            {{ type }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <span class="flex-shrink-0">visit was</span>
                      <mat-form-field class="sender-type ml-2" floatLabel='none'>
                        <mat-select formControlName="visitDateBeforeAfter2">
                          <mat-option *ngFor="let type of beforeAfterTypes" [value]="type">
                            {{ type }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <kendo-datepicker
                        class="emr-datepicker w-100 ml-2"
                        placeholder="Select"
                        formControlName="visitDateValue2">
                      </kendo-datepicker>
                    </div>
                  </div> -->

                      <!-- <div class="divider my-3"></div> -->

                      <!-- <div class="row mx-0 mb-2">
                    <div class="col px-0 d-flex align-items-center">
                      <mat-checkbox formControlName="lastProviderCheckbox"
                        class="reports-checkbox mr-2">
                      </mat-checkbox>
                      <span class="flex-shrink-0">The last employee to service this client was</span>
                      <mat-form-field class="sender-type w-100 ml-2" floatLabel='none'>
                        <mat-select formControlName="lastProviderId">
                          <mat-option *ngFor="let user of serviceProviders" [value]="user.id">
                            {{ user.title }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div> -->

                      <!-- <div class="row mx-0 mb-2">
                    <div class="col px-0 d-flex align-items-center">
                      <mat-checkbox
                        formControlName="amountOfVisitsCheckbox"
                        class="reports-checkbox mr-2">
                      </mat-checkbox>
                      The client has had
                      <mat-form-field class="sender-type mx-2" floatLabel='none'>
                        <mat-select formControlName="amountOfVisitsMoreLess">
                          <mat-option *ngFor="let type of lessMoreTypes" [value]="type">
                            {{ type }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <input
                        formControlName="amountOfVisitsValue"
                        type="number"
                        class="input-number flex-grow-1 mr-2"
                        value="1"
                        min="1">
                      visit(s)
                    </div>
                  </div> -->

                      <div class="row mx-0">
                        <div class="col d-flex flex-wrap align-items-center">
                          <mat-checkbox
                            #amountOfLoyaltyPointsCheckbox
                            formControlName="amountOfLoyaltyPointsCheckbox"
                            class="reports-checkbox mr-2"
                          >
                          </mat-checkbox>
                          <span
                            class="flex-shrink-0"
                            [ngClass]="{ 'text-dark': disabledForm }"
                            [class.disabled-element]="!amountOfLoyaltyPointsCheckbox.checked"
                            >The patient has</span
                          >
                          <mat-form-field
                            class="sender-type mb-0 mx-2 pt-1"
                            [ngClass]="{ 'text-dark': disabledForm }"
                            [class.disabled-element]="!amountOfLoyaltyPointsCheckbox.checked"
                          >
                            <mat-select formControlName="amountOfLoyaltyPointsMoreLess">
                              <mat-option *ngFor="let type of lessMoreTypes" [value]="type">
                                {{ type }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <input
                            autocomplete="off"
                            [ngClass]="{ 'text-dark': disabledForm }"
                            [class.disabled-element]="!amountOfLoyaltyPointsCheckbox.checked"
                            formControlName="amountOfLoyaltyPointsValue"
                            onlyNumber
                            matInput
                            class="input-number points flex-grow-0 mr-2 light-grey-background"
                            value="1"
                            min="1"
                          />
                          <span
                            class="flex-shrink-0"
                            [ngClass]="{ 'text-dark': disabledForm }"
                            [class.disabled-element]="!amountOfLoyaltyPointsCheckbox.checked"
                            >ePoints</span
                          >
                        </div>
                      </div>

                      <div class="row mx-0">
                        <div class="col d-flex flex-wrap align-items-center">
                          <mat-checkbox
                            #amountSpentCheckbox
                            formControlName="amountSpentCheckbox"
                            class="reports-checkbox mr-2"
                          >
                          </mat-checkbox>
                          <span
                            [ngClass]="{ 'text-dark': disabledForm }"
                            [class.disabled-element]="!amountSpentCheckbox.checked"
                            class="flex-shrink-0 mw-100 text-wrap"
                            >The patient has spent</span
                          >
                          <mat-form-field
                            class="sender-type mx-2 mb-0 pt-1"
                            [ngClass]="{ 'text-dark': disabledForm }"
                            [class.disabled-element]="!amountSpentCheckbox.checked"
                          >
                            <mat-select formControlName="amountSpentMoreLess">
                              <mat-option *ngFor="let type of lessMoreTypes" [value]="type">
                                {{ type }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <span
                            [ngClass]="{ 'text-dark': disabledForm }"
                            [class.disabled-element]="!amountSpentCheckbox.checked"
                            class="flex-shrink-0 mw-100 text-wrap"
                            >&nbsp;&nbsp;$</span
                          >
                          <input
                            autocomplete="off"
                            [ngClass]="{ 'text-dark': disabledForm }"
                            [class.disabled-element]="!amountSpentCheckbox.checked"
                            formControlName="amountSpentValue"
                            onlyNumber
                            matInput
                            class="input-number money flex-grow-0 mr-2 light-grey-background"
                            value="1"
                            min="1"
                          />
                          <span
                            [ngClass]="{ 'text-dark': disabledForm }"
                            [class.disabled-element]="!amountSpentCheckbox.checked"
                            class="flex-shrink-0"
                            >in the last&nbsp;&nbsp;</span
                          >
                          <input
                            autocomplete="off"
                            [ngClass]="{ 'text-dark': disabledForm }"
                            [class.disabled-element]="!amountSpentCheckbox.checked"
                            class="input-number mr-2 flex-grow-0 light-grey-background"
                            onlyNumber
                            matInput
                            formControlName="amountSpentRepeatPeriodValue"
                            min="1"
                          />
                          <mat-form-field class="sender-type ml-2 flex-grow-0 pt-1">
                            <mat-select
                              [ngClass]="{ 'text-dark': disabledForm }"
                              [class.disabled-element]="!amountSpentCheckbox.checked"
                              [disabled]="!amountSpentCheckbox.checked"
                              formControlName="amountSpentRepeatPeriodUnit"
                            >
                              <mat-option *ngFor="let type of timePeriodUnitsSecondary" [value]="type">
                                {{ type }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <!-- <kendo-datepicker
                        [disabled]="!amountSpentCheckbox.checked"
                        formControlName="amountSpentSinceDate"
                        class="emr-datepicker ml-2"
                        placeholder="Select"
                        name="">
                      </kendo-datepicker> -->
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>

                  <div class="my-1"></div>

                  <mat-expansion-panel #panelList1="matExpansionPanel" [expanded]="true" class="service-product-panel">
                    <mat-expansion-panel-header
                      [collapsedHeight]="'auto'"
                      [expandedHeight]="'auto'"
                      #panelHeader1
                      class="light-grey-background"
                    >
                      <mat-panel-title>
                        <div class="d-flex align-items-center panel-box-retention py-1">
                          PURCHASED SERVICES & PRODUCTS
                          <button
                            type="button"
                            class="btn d-flex flex-column expand-btn ml-auto p-0"
                            (click)="panelHeader1._toggle()"
                          >
                            <mat-icon (click)="panelHeader1._toggle()">{{
                              panelList1.expanded ? 'arrow_drop_up' : 'arrow_drop_down'
                            }}</mat-icon>
                          </button>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="retention-campaign-accordion teal-font">
                      <div class="row mx-0 mt-2">
                        <div class="col">
                          <div class="d-flex align-items-center" [ngClass]="{ 'text-dark': disabledForm }">
                            <span class="flex-shrink-0"> Date filter for selection below:&nbsp;&nbsp; </span>
                            <input
                              autocomplete="off"
                              class="input-number mr-2 light-grey-background"
                              onlyNumber
                              matInput
                              formControlName="filterProductServiceStartPeriod"
                              min="1"
                            />
                            to&nbsp;&nbsp;
                            <input
                              autocomplete="off"
                              class="input-number mr-2 light-grey-background"
                              onlyNumber
                              matInput
                              formControlName="filterProductServiceEndPeriod"
                              min="1"
                            />
                            <mat-form-field class="sender-type ml-2 flex-grow-0 pt-1">
                              <mat-select formControlName="filterProductServicePeriodUnit">
                                <mat-option *ngFor="let type of timePeriodUnitsSecondary" [value]="type">
                                  {{ type }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <!-- <kendo-datetimepicker
                        [placeholder]="'Earliest Purchase Date...'"
  
                        class="emr-datepicker w-100 ml-2"
                        [format]="'yyyy-MM-dd HH:mm'"
                        [ngClass]="{ 'ng-invalid ng-touched': submitted && f.purchaseDateStartOn.errors }"
                        formControlName="purchaseDateStartOn"
                        > </kendo-datetimepicker> -->
                          </div>
                        </div>
                      </div>
                      <div class="row mx-0">
                        <div class="col d-flex align-items-center">
                          <mat-checkbox
                            #hasPurchasedCheckbox
                            formControlName="hasPurchasedCheckbox"
                            class="reports-checkbox mr-2"
                          >
                          </mat-checkbox>
                          <span class="d-flex align-items-center teal-font my-0 py-2">Filter for "Has Purchased"</span>
                        </div>
                      </div>
                      <div class="row mt-0 mx-0" [hidden]="!hasPurchasedCheckbox.checked && !disabledForm">
                        <div class="col">
                          <div
                            [ngClass]="[disabledForm ? 'grey-background' : 'navy-background']"
                            class="navy-background p-1"
                          >
                            <div class="input-group">
                              <input
                                autocomplete="off"
                                [disabled]="disabledForm || !hasPurchasedCheckbox.checked"
                                #instance="ngbTypeahead"
                                #elem
                                type="text"
                                class="form-control border-0 grey-three-background"
                                placeholder="Search and Select Service or Product"
                                [ngbTypeahead]="searchServiceOrProduct"
                                [resultFormatter]="serviceOrProductResultFormatter"
                                [inputFormatter]="serviceOrProductInputFormatter"
                                (focus)="focus$.next($any($event.target).value)"
                                (selectItem)="addServiceOrProduct($event.item)"
                                popupClass="service-product-dropdown-menu"
                                container="body"
                              />
                            </div>
                          </div>

                          <div
                            class="d-flex flex-nowrap justify-content-start align-items-center"
                            *ngFor="let sop of selectedServicesOrProducts"
                          >
                            <div class="d-flex align-items-center" [ngClass]="{ 'text-dark': disabledForm }">
                              {{ sop.name }}
                              <div
                                style="cursor: pointer"
                                *ngIf="
                                  sop.serviceDetailTemplateId == ServiceDetailEnum.Coolsculpting ||
                                  ServiceDetailEnum.Injections
                                "
                                class="pl-1"
                                (click)="editServiceWithObservationList(sop)"
                              >
                                <b>{{ getObservationFilterString(sop.serviceDetailTemplateId) }}</b>
                              </div>
                            </div>
                            <button
                              [disabled]="disabledForm || !hasPurchasedCheckbox.checked"
                              [ngClass]="{ transparent: disabledForm || !hasPurchasedCheckbox.checked }"
                              class="btn trash-btn"
                              (click)="deleteServiceOrProduct(sop)"
                            >
                              <small><i class="far fa-trash-alt"></i></small>
                            </button>
                          </div>
                          <div
                            class="row mx-0"
                            [ngClass]="{ 'text-dark': disabledForm || !hasPurchasedCheckbox.checked }"
                            [class.disabled-element]="!hasPurchasedCheckbox.checked"
                          >
                            <div class="col d-flex align-items-center mb-2 mt-1">
                              <mat-checkbox
                                #notPurchasedSinceCheckbox
                                formControlName="notPurchasedSinceCheckbox"
                                class="reports-checkbox mr-2"
                              >
                              </mat-checkbox>
                              <span
                                class="flex-shrink-0 mw-100 text-wrap"
                                [ngClass]="{ 'text-dark': disabledForm }"
                                [class.disabled-element]="!notPurchasedSinceCheckbox.checked"
                                >Have not purchased since AND have no future appointments</span
                              >
                            </div>
                          </div>
                          <hr />
                        </div>
                      </div>

                      <!--Negative filter-->
                      <div class="row mx-0">
                        <div class="col d-flex align-items-center">
                          <mat-checkbox
                            #hasNotPurchasedCheckbox
                            formControlName="hasNotPurchasedCheckbox"
                            class="reports-checkbox mr-2"
                          >
                          </mat-checkbox>
                          <span class="d-flex align-items-center teal-font my-0 py-2"
                            >Filter for "Has Not Purchased"</span
                          >
                        </div>
                      </div>
                      <div class="row mt-0 mx-0" [hidden]="!hasNotPurchasedCheckbox.checked && !disabledForm">
                        <div class="col">
                          <div
                            [ngClass]="[disabledForm ? 'grey-background' : 'navy-background']"
                            class="navy-background p-1"
                          >
                            <div class="input-group">
                              <input
                                autocomplete="off"
                                [disabled]="disabledForm || !hasNotPurchasedCheckbox.checked"
                                #instanceNegative="ngbTypeahead"
                                #elemNegative
                                type="text"
                                class="form-control border-0 grey-three-background"
                                placeholder="Search and Select Service or Product"
                                [ngbTypeahead]="searchServiceOrProductNegative"
                                [resultFormatter]="serviceOrProductResultFormatter"
                                [inputFormatter]="serviceOrProductInputFormatter"
                                (focus)="focusNegative$.next($any($event.target).value)"
                                (selectItem)="addServiceOrProduct($event.item, false)"
                                popupClass="service-product-dropdown-menu"
                                container="body"
                              />
                            </div>
                          </div>

                          <div
                            class="d-flex flex-nowrap justify-content-start align-items-center"
                            *ngFor="let sop of selectedServicesOrProductsNegative"
                          >
                            <div class="d-flex align-items-center" [ngClass]="{ 'text-dark': disabledForm }">
                              {{ sop.name }}
                              <div
                                style="cursor: pointer"
                                *ngIf="
                                  sop.serviceDetailTemplateId == ServiceDetailEnum.Coolsculpting ||
                                  ServiceDetailEnum.Injections
                                "
                                class="pl-1"
                                (click)="editServiceWithObservationList(sop, false)"
                              >
                                <b>{{ getObservationFilterString(sop.serviceDetailTemplateId, false) }}</b>
                              </div>
                            </div>
                            <button
                              [disabled]="disabledForm || !hasNotPurchasedCheckbox.checked"
                              [ngClass]="{ transparent: disabledForm || !hasNotPurchasedCheckbox.checked }"
                              class="btn trash-btn"
                              (click)="deleteServiceOrProduct(sop, false)"
                            >
                              <small><i class="far fa-trash-alt"></i></small>
                            </button>
                          </div>
                          <div
                            class="row mx-0"
                            [ngClass]="{ 'text-dark': disabledForm || !hasNotPurchasedCheckbox.checked }"
                            [class.disabled-element]="!hasNotPurchasedCheckbox.checked"
                          >
                            <div class="col d-flex align-items-center mb-2 mt-1">
                              <mat-checkbox
                                #notPurchasedSinceNegativeCheckbox
                                formControlName="notPurchasedSinceNegativeCheckbox"
                                class="reports-checkbox mr-2"
                              >
                              </mat-checkbox>
                              <span
                                class="flex-shrink-0 mw-100 text-wrap"
                                [ngClass]="{ 'text-dark': disabledForm }"
                                [class.disabled-element]="!notPurchasedSinceNegativeCheckbox.checked"
                                >Have not purchased since AND have no future appointments</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--  <div class="row mx-0" [ngClass]="{ 'disabled-element': !arePurchasedCheckbox.checked }">
                      <div class="col d-flex align-items-center mt-3 mb-3">
                        <mat-checkbox formControlName="noFutureAppointmentsSinceCheckbox" class="reports-checkbox mr-2">
                        </mat-checkbox>
                        And do not have an appointment scheduled for any of these item in the future
                      </div>
                    </div> -->
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>

          <!-- Recipients -->
          <!-- <div >
          <div class="row mx-0 teal-background">
            <div class="col">
              <h6 class="white-font uppercase mb-0 py-2">
                Recipients
              </h6>
            </div>
          </div>
  
          <div class="py-3">
            <div class="row mx-0">
              <div class="col d-flex align-items-center">
                <mat-radio-group
                  class="example-radio-group mr-2"
                  name=""
                  formControlName="sendTo">
                    <mat-radio-button
                      class="example-radio-button"
                      value="all">
                    </mat-radio-button>
                </mat-radio-group>
                Send to all active clients
              </div>
            </div>
  
            <div class="row my-3 mx-0">
              <div class="col d-flex align-items-center">
                <mat-radio-group
                  class="example-radio-group mr-2"
                  name=""
                  formControlName="sendTo">
                    <mat-radio-button
                      class="example-radio-button"
                      value="targeted">
                    </mat-radio-button>
                </mat-radio-group>
                Send to targeted list of clients
              </div>
            </div>
  
          </div>
        </div> -->
        </perfect-scrollbar>
      </form>

      <div class="d-flex flex-column h-100 px-3 flex-grow-1">
        <ngx-loading [show]="templateLoading"></ngx-loading>
        <div class="row mx-0 teal-background">
          <div class="col d-flex justify-content-between">
            <ng-template #editorHelpTemplate>
              For the best email editing experience use a desktop or laptop computer.
            </ng-template>
            <h6 class="white-font uppercase my-0 mr-auto py-2">Template Definition</h6>
            <h6
              *ngIf="iPad"
              kendoTooltip
              [tooltipTemplate]="editorHelpTemplate"
              position="left"
              filter="span"
              class="white-font uppercase my-0 py-2"
            >
              <span class="ml-auto pl-5"><i class="fa fa-question-circle"></i></span>
            </h6>
            <!-- <a class="btn kendo-btn  white-font pl-1"  style="display:contents" target="_blank" [href]="'https://app.mailjet.com/template/' + form.get('template')?.value?.id + '/build'"><i class="fas fa-edit"></i></a> -->
          </div>
        </div>
        <div class="w-100 d-flex align-items-start justify-content-center px-0 pb-2 light-grey-background flex-grow-1">
          <div class="h-100 w-100" [hidden]="form?.controls['campaignType']?.value == 'SMS' || disabledForm">
            <app-unlayer-email-editor
              id="unlayer"
              class="email-editor h-100"
              [options]="editorOptions"
              (loaded)="loadEditor($event)"
              (ready)="editorReady($event)"
              #editor
            ></app-unlayer-email-editor>
          </div>
          <div #templateFiller *ngIf="form?.controls['campaignType']?.value == 'SMS'" frameBorder="0" class="w-100">
            <span class="m-auto font-weight-bold" style="font-size: 2em">
              {{
                form?.controls['campaignType']?.value == 'SMS'
                  ? 'HTML template disabled for SMS only campaigns'
                  : 'Please select an email template'
              }}
            </span>
          </div>
          <div *ngIf="disabledForm">
            <perfect-scrollbar style="max-width: 100%; top: 0; left: 0" appDetermineMaxScrollHeight [addHeight]="0">
              <div [innerHTML]="emailHtml | keepHtml"></div>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-panel
    [(isVisible)]="isOpenRecipientsModal"
    class="campaign-recipients-modal"
    [isHuge]="true"
    [hidden]="patientPanelOpen"
  >
    <app-campaign-recipients
      [standby]="!isOpenRecipientsModal"
      (closePanel)="patientPanelOpen = true"
      [recipientRequestType]="getRecipientRequestType()"
    ></app-campaign-recipients>
  </app-panel>
</div>
