import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { InvoiceLineItem } from '@models/invoice/invoice-line-item';
import { Membership } from '@models/memberships/membership';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root',
})
export class MembershipsService {
  constructor(private http: HttpClient, private usersService: UsersService) {}

  addMembership(membership: Membership) {
    return this.http.post<Membership>(environment.baseUrl + 'api/Memberships', membership);
  }

  updateMembership(membership: Membership) {
    return this.http.put<Membership>(environment.baseUrl + 'api/Memberships/' + membership.id, membership);
  }

  getMemberships(includeInactive = true) {
    return this.http.get<Membership[]>(environment.baseUrl + 'api/Memberships', {
      params: { includeInactive: includeInactive },
    });
  }

  getMembershipById(id) {
    return this.http.get<Membership>(environment.baseUrl + 'api/Memberships/' + id);
  }

  updateIsActive(membership: Membership, isActive: any) {
    return this.http.put(environment.baseUrl + 'api/Memberships/UpdateIsActive', {
      id: membership.id,
      isActive: isActive,
    });
  }

  deleteMembership(membershipId: number) {
    return this.http.delete<Membership>(`${environment.baseUrl}api/Memberships/${membershipId}`);
  }

  createMembershipLineItem(membership: Membership, invoiceId: number) {
    const lineItem: InvoiceLineItem = {
      id: 0,
      invoiceId: invoiceId,
      membershipId: membership.id,
      quantity: 1,
      unitPrice: membership.amount,
      total: 0.0,
      invoiceLineItemTaxes: [],
      note: '',
      serviceType: 'Membership',
      serviceTypeDescription: membership.name,
      clinicProductId: null,
      isManual: true,
      isDeleted: false,
      finalTotal: null,
      isCredit: false,
      serviceTemplateId: null,
      addBackToInventory: false,
      attributedToUserId: this.usersService.loggedInUser.id,
      attributedToUser: this.usersService.loggedInUser,
      plannedTreatmentMultipleId: null,
      isRecommendedProduct: false,

    }
    return lineItem;
  }
}
