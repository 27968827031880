import { PaymentHelper } from '@services/helpers/payment-helper';
import { Component, Input, OnInit } from '@angular/core';
import { SquareCard } from '@models/square/square-card';

@Component({
  selector: 'app-square-card-on-file',
  templateUrl: './square-card-on-file.component.html',
  styleUrls: ['./square-card-on-file.component.less'],
})
export class SquareCardOnFileComponent implements OnInit {
  @Input() card: SquareCard;
  @Input() showSmallBrandLogo: boolean = false;

  PaymentHelper = PaymentHelper;

  constructor() {}

  ngOnInit(): void {}
}
