import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { Clinic } from '@models/clinic';
import { Resource } from '@models/resource';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { MasterOverlayService } from '@services/actionpanel.service';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { ClinicsService } from '@services/clinics.service';
import { Observable, Subject } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { AuthService } from './../../../auth/auth.service';

@Component({
  selector: 'app-org-clinics',
  templateUrl: './org-clinics.component.html',
  styleUrls: ['./org-clinics.component.less'],
})
export class OrgClinicsComponent implements OnInit, OnDestroy {
  disableGrid = false;
  loading = false;
  unsub: Subject<void> = new Subject<void>();
  clinics: Clinic[] = [];
  selectedClinicId: number;
  searchCtrl: FormControl;
  filteredClinics: Observable<Clinic[]>;
  formGroup: FormGroup;
  editedRowIndex: number;
  editedDataItem: Resource;
  gridView: GridDataResult;
  gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  authorizedTabLinks: Map<string, string> = null;
  private tabPolicies = new Map([
    ['General', Policies.clinicGeneral],
    ['Users', Policies.clinicUsers],
    ['Permissions', Policies.clinicUserPermissions],
    ['Role Permissions', Policies.developer],
    ['Resources', Policies.clinicResources],
    ['Photos', Policies.clinicPhotos],
    ['Cancellation Reasons', Policies.developer],
    ['Access Locations', Policies.clinicAccessLocations],
    ['Payments', Policies.clinicPayments],
    ['Online Booking', Policies.clinicOnlineBooking],
    ['Merchant Devices', Policies.clinicMerchantDevices],
  ]);
  private tabRouterLinks = new Map([
    ['General', 'general'],
    ['Users', 'users'],
    ['Permissions', 'user-permissions'],
    ['Role Permissions', 'role-permissions'],
    ['Resources', 'resources'],
    ['Photos', 'photos'],
    ['Cancellation Reasons', 'cancellation-reason'],
    ['Access Locations', 'access-locations'],
    ['Payments', 'payments'],
    ['Online Booking', 'online-booking'],
    ['Merchant Devices', 'merchant-devices'],
  ]);

  constructor(
    private clinicsService: ClinicsService,
    private router: Router,
    private catalogueUpdatesService: CatalogueUpdatesService,
    private deleteDialog: MatDialog,
    private authService: AuthService,
    private masterOverlayService: MasterOverlayService
  ) {
    this.searchCtrl = new FormControl();
    this.filteredClinics = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map((pack) => this.filterClinics(pack))
    );
  }

  ngOnInit() {
    this.loading = true;
    this.authorizedTabLinks = new Map(
      [...this.tabRouterLinks].filter(([tab, route]) => this.authService.userSatisfiesPolicy(this.tabPolicies.get(tab)))
    );
    this.gridView = {
      data: [],
      total: 0,
    };
    this.router.events
      .pipe(
        takeUntil(this.unsub),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => this.checkForTabInUrl());
    this.masterOverlayService.masterOverlayEnabled.pipe(takeUntil(this.unsub)).subscribe((overlayEnabled) => {
      this.loading = overlayEnabled;
    });
    this.catalogueUpdatesService.catalogueUpdated.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.disableGrid = false;
      if (this.catalogueUpdatesService.refreshRequired) {
        this.catalogueUpdatesService.refreshRequired = false;
        this.refreshData();
      }
    });
    this.checkForTabInUrl();
    this.refreshData();
  }

  get currentRoute(): string {
    return this.router.url.split('/').pop();
  }

  private checkForTabInUrl() {
    if (this.currentRoute === 'clinics') {
      const tabLink = Array.from(this.authorizedTabLinks.values())[0];
      const tabRoute = this.router.url + '/' + tabLink;
      this.router.navigateByUrl(tabRoute);
    }
  }

  filterClinics(name: string) {
    let filterResults: Clinic[] = [];

    if (name !== '') {
      this.gridView = {
        data: this.clinics.filter((clinic) => clinic.name.toLowerCase().includes(name.toLowerCase())),
        total: this.clinics.filter((clinic) => clinic.name.toLowerCase().includes(name.toLowerCase())).length,
      };
      filterResults = this.clinics.filter((clinic) => clinic.name.toLowerCase().includes(name.toLowerCase()));
    } else {
      this.gridView = {
        data: this.clinics,
        total: this.clinics.length,
      };
      filterResults = [];
    }

    return filterResults;
  }

  refreshData() {
    this.loading = true;
    this.clinics = [];
    this.clinicsService.getClinics().subscribe((res) => {
      res.forEach((doc) => {
        const docData = doc;
        const pushItem: Clinic = {
          clinicId: docData.clinicId,
          name: docData.name,
          blobContainerName: docData.blobContainerName,
          address: docData.address,
          clinicTaxes: docData.clinicTaxes,
          companyId: docData.companyId,
          email: docData.email,
          phoneNumber: docData.phoneNumber,
          logoUrl: docData.logoUrl,
          timezone: doc.timezone,
          hoursOfOperation: doc.hoursOfOperation,
          minimumDuration: doc.minimumDuration,
          customInvoiceText: doc.customInvoiceText,
          sendPatientPortalEmail: doc.sendPatientPortalEmail,
        };
        this.clinics.push(pushItem);
      });
      if (this.clinics.length > 0) this.selectedClinicId = this.clinics[0].clinicId;
      this.loadItems();
      this.loading = false;
    });
  }

  addClinic(clinic: Clinic) {
    this.clinicsService.addClinic(clinic);
  }

  onAddClick({ sender }) {
    this.disableGrid = true;
    this.router.navigate(['/management/organization/clinics', { outlets: { 'action-panel': ['edit-clinic', '_'] } }]);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.disableGrid = true;
    this.router.navigate([
      '/management/organization/clinics',
      { outlets: { 'action-panel': ['edit-clinic', dataItem.clinicId] } },
    ]);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    const clinic: Clinic = formGroup.value;
    if (isNew) {
      this.clinicsService.addClinic(clinic).subscribe(() => {
        this.refreshData();
      });
    } else {
      this.clinicsService.updateClinic(clinic).subscribe(() => {
        this.refreshData();
      });
    }
    sender.closeRow(rowIndex);
  }

  public removeHandler({ dataItem }) {
    if (this.clinics.length > 1) {
      const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
        width: '250px',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'delete') {
          const dataItemToRemove = {
            clinicId: dataItem.clinicId,
            name: dataItem.name,
            blobContainerName: dataItem.blobContainerName,
            address: dataItem.address,
            clinicTaxes: dataItem.clinicTaxes,
            companyId: dataItem.companyId,
            email: dataItem.email,
            phoneNumber: dataItem.phoneNumber,
            timezone: dataItem.timezone,
            hoursOfOperation: dataItem.hoursOfOperation,
            minimumDuration: dataItem.minimumDuration,
            customInvoiceText: dataItem.customInvoiceText,
            sendPatientPortalEmail: dataItem.sendPatientPortalEmail,
          };
          this.clinicsService.removeClinic(dataItemToRemove).subscribe(() => {
            this.refreshData();
          });
        }
      });
    } else {
      const dialogRef = this.deleteDialog.open(GenericDialogComponent, {
        width: '250px',
        data: {
          showCancel: false,
          title: 'Error',
          content: 'You must have at least one clinic',
          confirmButtonText: 'Ok',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {});
    }
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  loadItems() {
    this.gridView = {
      data: this.clinics,
      total: this.clinics.length,
    };
  }

  openDeleteDialog(): void {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
      data: { result: '' },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  originalOrder(): number {
    return 0;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
