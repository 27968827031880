import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { Review } from './../../models/reviews/review';
import { Clinic } from '@models/clinic';
import { ClinicsService } from '@services/clinics.service';
import { ReviewResponse } from '@models/reviews/review-response';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import * as moment from 'moment';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { User } from '@models/user';
import { State } from '@progress/kendo-data-query';
import { PageChangeEvent, DataStateChangeEvent, PagerType } from '@progress/kendo-angular-grid';
import { UsersService } from '@services/users.service';
import { ReviewService } from '@services/review.service';

@Component({
  selector: 'app-reviews-history',
  templateUrl: './reviews-history.component.html',
  styleUrls: ['./reviews-history.component.less'],
})
export class ReviewsHistoryComponent implements OnInit {
  public users: User[];
  public patientId: number;
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  public pageSize = 50;
  public skip = 0;

  public state: State = {
    skip: this.skip,
    take: this.pageSize,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [
      {
        field: 'createdAt',
        dir: 'desc',
      },
    ],
  };

  public gridView: any;
  public allowUnsort = true;
  public buttonCount = 5;
  public info = true;
  public previousNext = true;
  public pagerType: PagerType = 'input';

  appointmentDates: Date[] = [];
  createdAtDates: Date[] = [];

  public loading = false;
  currentUser: User;

  private additionalQueries: any = {};
  private gridQueries: any = {};
  showGooglePlaceSetupLink = false;
  clinic: Clinic;

  private unsub: Subject<void> = new Subject<void>();

  private pageTokens: string[] = [''];

  constructor(
    private userService: UsersService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private reviewService: ReviewService,
    private router: Router,
    private clinicService: ClinicsService
  ) {}

  ngOnInit() {
    this.currentUser = this.userService.loggedInUser;
    // this.state.filter.filters.push(
    //   {
    //     filters: [{
    //       field: 'StarRating',
    //       operator: 'eq',
    //       value: this.userService.loggedInUser.id
    //     } ],
    //     logic: 'or'
    //   }
    // );

    this.clinicService.getClinicById(parseInt(localStorage.getItem('clinicId'))).subscribe((clinic) => {
      this.clinic = clinic;
      this.showGooglePlaceSetupLink = clinic && !clinic.googlePlaceId;
    });

    this.patientId = this.route.snapshot.params.patId && +this.route.snapshot.params.patId.split('_')[0];
    if (this.patientId) {
      this.additionalQueries = { patientId: this.patientId };
    }
    this.createQuery(this.state);
    this.userService.getUsers().subscribe((users: User[]) => {
      this.users = users;
    });
    this.gridQueries.limit = this.pageSize;
    this.gridQueries.page = 0;
    this.loadReviews(this.gridQueries, this.additionalQueries);
  }

  public isGreaterThanOneWeekOverdue(dueDate: Date) {
    return moment().diff(moment(dueDate), 'days') > 7;
  }

  private loadReviews(state, addState, pageToken = ''): void {
    this.loading = true;
    // if (this.selectedBtn == 'Internal'){

    //If all reviews needed switch .getGoogleReviews to .getReviews
    this.reviewService
      .getGoogleReviews({ state, addState }, pageToken)
      // .catch(err => {
      //       if (err && err == 'googlePlaceIdMissing'){
      //         this.showGooglePlaceSetupLink = true;
      //       }
      //       this.loading = false;
      //       return null;
      //     })
      .subscribe((res: ReviewResponse) => {
        if (res) {
          // this.appointmentDates = res.results.map((a) => a.appointmentDate);
          this.createdAtDates = res.results.map((a) => a.createdAt);
          this.gridView = {
            data: res.results,
            total: res.total,
          };

          if (!this.pageTokens.includes(res.nextPageToken)) {
            this.pageTokens.push(res.nextPageToken);
          }
        }
        this.loading = false;
      });
    // }
    // else{
    //   this.reviewService.getGoogleReviews({ state, addState } )
    //   .catch(err => {
    //     if (err && err == 'googlePlaceIdMissing'){
    //       this.showGooglePlaceSetupLink = true;
    //     }
    //     this.loading = false;
    //     return null;
    //   })
    //   .subscribe(
    //     (res: ReviewResponse) => {

    //       if (res){

    //         this.appointmentDates = [];
    //         this.createdAtDates = [];
    //         this.gridView = {
    //           data: res.results,
    //           total: res.total
    //         };

    //       }

    //       this.loading = false;
    //     }
    //   );
    // }
  }

  public dataStateChange(event: DataStateChangeEvent) {
    this.createQuery(event);
    this.gridQueries.limit = event.take;
    this.gridQueries.page = Math.floor((event.skip || 0) / event.take);
    this.skip = event.skip;
    this.pageSize = event.take;

    var pageToken = this.pageTokens[this.gridQueries.page];

    this.loadReviews(this.gridQueries, this.additionalQueries, pageToken);
    this.state = event;
  }

  private createQuery(event) {
    const filterQueries = event.filter.filters.map((e) => {
      if (e.filters) {
        const arrayOfFilters = e.filters.map((fil) => {
          return { [fil.field]: fil.value };
        });

        return arrayOfFilters;
      }
    });

    this.gridQueries = filterQueries.reduce((obj, e) => {
      return Object.assign(
        obj,
        e.reduce((r, o) => {
          Object.entries(o).forEach(([k, v]) => (r[k] = r[k] || []).push(v));
          return r;
        }, {})
      );
    }, {});

    if (event.sort.length) {
      let sortQuery: any;
      if (event.sort[0].dir) {
        sortQuery = {
          SortBy: event.sort[0].field,
          SortDirection: event.sort[0].dir,
        };
      }

      this.gridQueries = Object.assign({}, this.gridQueries, sortQuery);
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
  }

  public clearFilters() {
    this.state = {
      skip: 0,
      take: 10,

      // Initial filter descriptor
      filter: {
        logic: 'and',
        filters: [],
      },
      sort: [
        {
          field: 'deferredUntil',
          dir: 'asc',
        },
      ],
    };

    this.createQuery(this.state);
    this.gridQueries.page = 0;
    this.gridQueries.limit = this.pageSize;

    this.loadReviews(this.gridQueries, this.additionalQueries);
  }
  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (element.nodeName === 'DIV' && element.className.indexOf('truncate-two-lines') != -1) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  editReviewSettings() {
    this.router.navigate(['reviews', 'settings']);
  }

  public returnDateTimeUTC(date: string | moment.Moment) {
    return moment(date).format('YYYY-MM-DD hh:mma');
  }

  public filterReviews() {
    // this.removeMyFilter();
    this.skip = 0;
    this.createQuery(this.state);
    this.gridQueries.page = 0;
    this.gridQueries.limit = this.pageSize;
    this.loadReviews(this.gridQueries, this.additionalQueries);
  }

  // private removeMyFilter() {
  //   const indexAssignedToFilters = this.state.filter.filters.findIndex(
  //     (e: CompositeFilterDescriptor) => {
  //       return e.filters[0]['field'] === 'ReviewDate';
  //     }
  //   );

  //   const currentUserFilter = this.state.filter.filters[indexAssignedToFilters]['filters'].findIndex(
  //     (e: FilterDescriptor) => {
  //       return e.value === this.currentUser.id;
  //     }
  //   );

  //   this.state.filter.filters[indexAssignedToFilters]['filters'].splice(currentUserFilter, 1);
  //   if (!this.state.filter.filters[indexAssignedToFilters]['filters'].length) {
  //     this.state.filter.filters.splice(indexAssignedToFilters, 1);
  //   }
  // }

  public sortIconClass(column: any) {
    return this.state.sort.length && this.state.sort[0].field === column.field && this.state.sort[0].dir === 'asc'
      ? 'fad fa-sort-up'
      : this.state.sort.length && this.state.sort[0].field === column.field && this.state.sort[0].dir === 'desc'
      ? 'fad fa-sort-down'
      : 'fas fa-sort';
  }

  public navigateToPatientChart(review: Review): void {
    this.router.navigate([
      '/reviews',
      {
        outlets: {
          'action-panel': [
            'patient',
            review.patient.patientId + '_patientcharttab',
            'patienttabs',
            'patientcharttab',
            'overview',
          ],
        },
      },
    ]);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
