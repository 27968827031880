import { GenericPhotoService } from './services/generic-photo.service';
import { GenericPhotoGalleryComponent } from './generic-photo-gallery/generic-photo-gallery.component';
import { SharedModule } from '@app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagService } from '@services/tag.service';
import { TagInputModule } from 'ngx-chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { IntlModule } from '@progress/kendo-angular-intl';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GenericPhotoFilterComponent } from './generic-photo-filter/generic-photo-filter.component';
import { GenericPhotoListComponent } from './generic-photo-list/generic-photo-list.component';
import { GenericPhotoMetadataComponent } from './generic-photo-metadata/generic-photo-metadata.component';
import { GenericPhotoDisplayComponent } from './generic-photo-display/generic-photo-display.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { GenericPhotoSeriesCreatorComponent } from './generic-photo-series-creator/generic-photo-series-creator.component';
import { MatTreeModule } from '@angular/material/tree';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PhotoTagTreeDropdownComponent } from './shared/photo-tag-tree-dropdown/photo-tag-tree-dropdown.component';
import { MatMenuModule } from '@angular/material/menu';
import { ImageProcessingComponent } from './generic-photo-display/image-processing/image-processing.component';
import { ImageWatermarkComponent } from './generic-photo-display/image-watermark/image-watermark.component';
import { MatSliderModule } from '@angular/material/slider';
import { SelectClinicPhotoComponent } from './generic-photo-display/image-watermark/select-clinic-photo/select-clinic-photo.component';

@NgModule({
    declarations: [
        GenericPhotoGalleryComponent,
        GenericPhotoFilterComponent,
        GenericPhotoListComponent,
        GenericPhotoMetadataComponent,
        GenericPhotoDisplayComponent,
        GenericPhotoSeriesCreatorComponent,
        PhotoTagTreeDropdownComponent,
        ImageProcessingComponent,
        ImageWatermarkComponent,
        SelectClinicPhotoComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        TagInputModule,
        MatAutocompleteModule,
        TypeaheadModule.forRoot(),
        IntlModule,
        DateInputsModule,
        ImageCropperModule,
        MatTreeModule,
        CdkTreeModule,
        MatCheckboxModule,
        MatMenuModule,
        MatSliderModule,
    ],
    providers: [TagService, GenericPhotoService],
    exports: [GenericPhotoGalleryComponent, GenericPhotoListComponent]
})
export class PhotosModule {}
