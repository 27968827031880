import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccessLocationService } from '@services/access-location.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccessLocation } from '@models/access-location';

@Component({
  selector: 'app-edit-access-location',
  templateUrl: './edit-access-location.component.html',
  styleUrls: ['./edit-access-location.component.less']
})
export class EditAccessLocationComponent implements OnInit, OnDestroy {
  accessLocationForm: FormGroup;
  states: any[];
  unsub = new Subject<any>();
  isNew: boolean;
  addOrEdit: string;
  editedAccessLocation: AccessLocation;
  errorMessage: string;

  stateChoices: any[] = [
    {
      title: 'Active',
      value: true
    },
    {
      title: 'Inactive',
      value: false
    }
  ];

  constructor(
    private accessLocationService: AccessLocationService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.accessLocationService.accessLocationSource$
      .pipe(takeUntil(this.unsub))
      .subscribe(
        res => {
          if (res) {
            this.editedAccessLocation = res;
            this.editedAccessLocation.isActive = this.editedAccessLocation.isActive || false;
            this.initForm(res);
            this.isNew = false;
            this.addOrEdit = 'Edit';
          } else {
            this.initForm();
            this.isNew = true;
            this.addOrEdit = 'Add';
          }
        }
      );
  }

  private initForm(accessLocationEdit?: AccessLocation) {
    if (accessLocationEdit) {
      this.accessLocationForm = this.fb.group({
        description: [accessLocationEdit.description, Validators.required],
        ipAddress: [accessLocationEdit.ipAddress, Validators.required],
        isActive: [accessLocationEdit.isActive, Validators.required],
      });
    } else {
      this.accessLocationForm = this.fb.group({
        description: ['', Validators.required],
        ipAddress: ['', Validators.required],
        isActive: ['', Validators.required],
      });
    }

    this.accessLocationForm.valueChanges
      .pipe(takeUntil(this.unsub))
      .subscribe(
        res => {
          if (this.errorMessage) {
            this.errorMessage = '';
          }
        }
      );
  }

  public cancelUpdate() {
    this.accessLocationService.updateAccessLocationData(false);
    this.router.navigate(['/management/organization/clinics/access-locations', { outlets: { 'action-panel': null } }]);
  }

  public updateAccessLocation() {
    let data: AccessLocation;
    if (this.editedAccessLocation) {
      data = Object.assign(this.editedAccessLocation, this.accessLocationForm.value);
    } else {
      data = Object.assign({}, this.accessLocationForm.value);
    }

    this.accessLocationService.addUpdateAccessLocations([data])
      .subscribe(
        res => {
          this.accessLocationService.updateAccessLocationData(true);
          this.router.navigate(['/management/organization/clinics/access-locations', { outlets: { 'action-panel': null } }]);
        },
        error => {
          if (error.error && error.error.errors) {
            const errors = error.error.errors;
            this.errorMessage = errors[0].fieldErrors[0];
          }
        }
      );
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
