import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ClinicProduct } from '@models/clinic-product';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClinicProductsService } from '@services/clinic-products.service';
import { Subject, combineLatest } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.less'],
})
export class ProductSelectionComponent implements OnInit {
  unsub: Subject<void>;
  searchFormControl: FormControl;
  productBrandGroups = new Map<string, ClinicProduct[]>();
  selectedProducts = new Set<ClinicProduct>();
  loading = false;

  get shouldExpand(): boolean {
    return Boolean(this.searchFormControl.value);
  }

  constructor(private activeModal: NgbActiveModal, private clinicProductsService: ClinicProductsService) {}

  ngOnInit() {
    this.unsub = new Subject();
    this.loading = true;
    this.searchFormControl = new FormControl('');

    combineLatest([this.clinicProductsService.getProducts(), this.searchFormControl.valueChanges.pipe(startWith(''))])
      .pipe(takeUntil(this.unsub))
      .subscribe(([products, searchString]) => {
        this.productBrandGroups.clear();
        products = products.filter((product) => product.product.productBrand);
        const searchedProducts = this.filterProducts(products, searchString);
        searchedProducts.forEach((clinicProduct) => {
          if (this.productBrandGroups.has(clinicProduct.product.productBrand.name)) {
            this.productBrandGroups.get(clinicProduct.product.productBrand.name).push(clinicProduct);
          } else {
            this.productBrandGroups.set(clinicProduct.product.productBrand.name, [clinicProduct]);
          }
        });
        this.loading = false;
      });
  }

  private filterProducts(products: ClinicProduct[], searchString: string) {
    if (!searchString) return products.slice();
    searchString = searchString.toLocaleLowerCase();
    return products.filter(
      (clinicProduct) =>
        clinicProduct.displayName.toLocaleLowerCase().includes(searchString) ||
        clinicProduct.product.productBrand.name.toLocaleLowerCase().includes(searchString)
    );
  }

  productIsSelected(product: ClinicProduct) {
    return this.selectedProducts.has(product);
  }

  toggleProductSelection(product: ClinicProduct) {
    if (this.selectedProducts.has(product)) {
      this.selectedProducts.delete(product);
    } else {
      this.selectedProducts.add(product);
    }
  }

  unselectProduct(product: ClinicProduct) {
    this.selectedProducts.delete(product);
  }

  closeModal() {
    this.activeModal.close(Array.from(this.selectedProducts));
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
