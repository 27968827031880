import { ReportsService } from '@services/reports.service';
import { ClinicReportDetailsViewModel } from '../../../../services/reports.service'; 
import { Router } from '@angular/router';
import { Component, OnInit, Input, OnDestroy } from '@angular/core'; 
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { PatientService } from '@services/patient.service'; 
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
 
@Component({
  selector: 'app-liabilities-credit-report-details',
  templateUrl: './liabilities-credit-report-details.component.html',
  styleUrls: ['./liabilities-credit-report-details.component.less']
})
export class LiabilitiesReportDetailsComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  unsub = new Subject<any>();  
 
  @Input() gridState: DataSourceRequestState;
  @Input() details: ClinicReportDetailsViewModel[] = [];

  constructor(
    private router: Router,
    private reportsService: ReportsService,
    private patientService: PatientService
  ) {
   
  }

  async ngOnInit() { 
      if (!this.details) this.details = []; 
      this.loading = false;
  }

  navigateToPatientInvoice(patientId: number, invoiceId: number) {
    this.patientService.getPatientById(patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((patient: any) => {
        this.patientService.patientPanelPatient = patient;
        this.router.navigate(['/reports', { outlets: { 'action-panel': ['patient', patientId + '_patientprofiletab', 'patienttabs', 'patientaccounttab', 'invoice', invoiceId] } }]);
      });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
