import { PhotoMetaData } from '../photo/photo-meta-data';
import { isNullOrUndefined } from '@app/shared/helpers';

export class TreatmentPlanPhoto {
  public id: number;
  public photo: PhotoMetaData;
  public treatmentPlanId: number;

  public constructor(init?: Partial<TreatmentPlanPhoto>) {
    if (isNullOrUndefined(this.id)) {
      this.id = 0;
    }
    Object.assign(this, init);
  }
}
