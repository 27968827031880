<ngx-loading [show]="loading"></ngx-loading>

<div class="modal-header border-0 p-0">
  <button
    type="button"
    class="btn white-font p-0 ml-auto mr-2"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <i class="fal fa-times"></i>
  </button>
</div>

<form [formGroup]="form" class="modal-form" (ngSubmit)="onSubmit()">
  <div class="modal-body p-0">
    <div class="share-form-container grey-two-background">
      <h5 class="modal-title text-uppercase navy-font text-center">Share Form</h5>
    </div>

    <div class="share-form-container">
      <h6 class="modal-title navy-font">Form settings</h6>
      <div class="row pt-3">
        <div class="col">
          <label class="teal-font m-0">Form Name *</label>
          <input
            type="text"
            class="form-control input-field"
            placeholder="Add form name"
            formControlName="name"
            required
          />
        </div>
      </div>
      <div class="row pt-3">
        <div class="col">
          <label class="teal-font m-0">Notes</label>
          <textarea
            class="form-control input-field"
            rows="2"
            placeholder="Add notes"
            formControlName="notes"
          ></textarea>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col">
          <label class="teal-font m-0">Form Type</label>
          <select formControlName="formTypeId" required (change)="onFormTypeChange($event)" class="form-control">
            <option *ngFor="let formType of formTypes" [value]="formType.id">
              {{ formType.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col">
          <label class="teal-font m-0"
            >Service Template Type <span *ngIf="serviceTemplateTypeValidationRequired">*</span></label
          >
          <select
            formControlName="serviceTemplateTypeId"
            placeholder="Service Template"
            aria-label="Service Template"
            class="form-control"
            [required]="serviceTemplateTypeValidationRequired"
          >
            <option *ngIf="!serviceTemplateTypeValidationRequired" [ngValue]="null">None</option>
            <option *ngFor="let serviceTemplateType of serviceTemplateTypes" [value]="serviceTemplateType.id">
              {{ serviceTemplateType.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col">
          <label class="teal-font m-0">Shared By User</label>&nbsp;
          <mat-button-toggle-group formControlName="sharedByUserId">
            <mat-button-toggle [value]="null">Anonymous</mat-button-toggle>
            <mat-button-toggle [value]="loggedInUser.id">{{ this.loggedInUserFullName }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col">
          <label class="teal-font m-0">Shared By Clinic</label>&nbsp;
          <mat-button-toggle-group formControlName="sharedByClinicId">
            <mat-button-toggle [value]="null">Anonymous</mat-button-toggle>
            <mat-button-toggle [value]="loggedInUser.clinicId">{{ this.loggedInClinicName }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>

    <div class="row py-4">
      <div class="col text-center pb-2">
        <button type="submit" class="btn em-btn em-btn-green" [disabled]="!form.valid">Submit</button>
        <button type="submit" class="btn em-btn em-btn-green ml-2" (click)="activeModal.dismiss('Cross click')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</form>
