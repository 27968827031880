import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';
import { ManageCategoriesDialogComponent } from '@app/management/dialogs/manage-categories/manage-categories.component';
import { CategoryType } from '@models/category-type';
import { ResourceType } from '@models/resource-type';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { ServiceCategory, ServiceIDColour, ServiceTemplateIcon } from '@models/service/service-category';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { ServiceTemplateResource } from '@models/service/service-template-resource';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { ServiceTemplateTypeService } from '@services/service-template-type.service';
import { ServiceTemplatesService } from '@services/service-templates.service';
import { ServicesService } from '@services/services.service';
import { TagService } from '@services/tag.service';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { ServiceTemplateType } from './../../../models/service/service-template-type';

@Component({
  selector: 'app-catalogue-services',
  templateUrl: './catalogue-services.component.html',
  styleUrls: ['./catalogue-services.component.less'],
})
export class CatalogueServicesComponent implements OnInit, OnDestroy {
  @Input()
  public category: ServiceCategory;
  searchValue = '';
  testColor = 'red';
  loading = false;
  disableGrid = false;
  unsub: Subject<void> = new Subject<void>();
  serviceTemplates: ClinicServiceTemplate[] = [];
  searchCtrl: FormControl;
  colorCtrl: FormControl;
  ServiceDetailTemplate = ServiceDetailTemplate;
  filteredServiceTemplates: Observable<ClinicServiceTemplate[]>;
  serviceTemplateTypesDropDown: ServiceTemplateType[] = [];
  serviceIDColoursDropDown: Array<ServiceIDColour> = [];
  templateIconsDropDown: Array<ServiceTemplateIcon> = [];
  currentTemplateIcon = 'fa fa-cog';
  checkedtest = true;
  CoolsculptingDetailEnum = ServiceDetailTemplate.Coolsculpting;
  InjectableDetailEnum = ServiceDetailTemplate.Injections;

  serviceCategory: ServiceCategory[] = [
    { serviceCategoryId: 0, name: 'Consultations' },
    { serviceCategoryId: 0, name: 'Applications' },
  ];

  gridView: GridDataResult;
  gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  formGroup: FormGroup;
  editedRowIndex: number;
  editedDataItem: ClinicServiceTemplate;

  constructor(
    private servicesService: ServicesService,
    private serviceTemplatesService: ServiceTemplatesService,
    private catalogueUpdatesService: CatalogueUpdatesService,
    private tagService: TagService,
    private serviceTemplateTypesService: ServiceTemplateTypeService,
    private manageCategoriesDialog: MatDialog,
    private deleteDialog: MatDialog,
    private router: Router
  ) {
    this.searchCtrl = new FormControl();
    this.colorCtrl = new FormControl();
    this.filteredServiceTemplates = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map((serviceTemplate) => this.filterServiceTemplates(serviceTemplate))
    );
  }

  ngOnInit() {
    this.gridView = {
      data: [],
      total: 0,
    };
    this.loading = true;
    this.catalogueUpdatesService.catalogueUpdated.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.disableGrid = false;
      if (this.catalogueUpdatesService.refreshRequired) {
        this.catalogueUpdatesService.refreshRequired = false;
        this.refreshData();
      }
    });
    this.getServiceIDColoursList();
    this.getTemplateIconsList();
    this.getServiceTemplateTypesList();
    this.refreshData();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  getTemplateIconsList() {
    this.templateIconsDropDown = [
      { serviceTemplateIconId: 0, icon: 'fas fa-syringe' },
      { serviceTemplateIconId: 0, icon: 'fas fa-neuter' },
      { serviceTemplateIconId: 0, icon: 'fas fa-child' },
      { serviceTemplateIconId: 0, icon: 'fas fa-user-md' },
    ];
  }

  getServiceIDColoursList() {
    this.serviceIDColoursDropDown = [
      { serviceIDColourId: 0, colour: 'Red' },
      { serviceIDColourId: 0, colour: 'Orange' },
      { serviceIDColourId: 0, colour: 'Yellow' },
      { serviceIDColourId: 0, colour: 'Green' },
      { serviceIDColourId: 0, colour: 'Blue' },
      { serviceIDColourId: 0, colour: 'Indigo' },
      { serviceIDColourId: 0, colour: 'Violet' },
    ];
  }

  getServiceTemplateTypesList() {
    this.serviceTemplateTypesDropDown = [];
    this.serviceTemplateTypesService.getAllServiceTemplateTypes().subscribe((res) => {
      res.forEach((type) => {
        const typeData = type;
        const pushItem: ServiceTemplateType = {
          id: typeData.id,
          name: typeData.name,
        };
        this.serviceTemplateTypesDropDown.push(pushItem);
      });
    });
  }

  filterServiceTemplates(name: string) {
    let filterResults: ClinicServiceTemplate[] = [];

    if (name !== '') {
      this.gridView = {
        data: this.serviceTemplates.filter((serviceTemplate) =>
          serviceTemplate.serviceName.toLowerCase().includes(name.toLowerCase())
        ),
        total: this.serviceTemplates.filter((serviceTemplate) =>
          serviceTemplate.serviceName.toLowerCase().indexOf(name.toLowerCase())
        ).length,
      };
      filterResults = this.serviceTemplates.filter((serviceTemplate) =>
        serviceTemplate.serviceName.toLowerCase().includes(name.toLowerCase())
      );
    } else {
      this.gridView = {
        data: this.serviceTemplates,
        total: this.serviceTemplates.length,
      };
      filterResults = [];
    }
    return filterResults;
  }

  refreshData(showLoad: boolean = true) {
    if (showLoad) this.loading = true;
    this.serviceTemplates = [];
    this.serviceTemplatesService.getServiceTemplates().subscribe((services) => {
      this.serviceTemplates = services.filter((service) => !service.serviceName.startsWith('_'));
      this.loadItems();
      this.loading = false;
    });
  }

  onAddClick({ sender }) {
    this.disableGrid = true;
    this.router.navigate(['/management/catalogue/services', { outlets: { 'action-panel': ['edit-service', '', ''] } }]);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.disableGrid = true;
    this.router.navigate([
      '/management/catalogue/services',
      {
        outlets: {
          'action-panel': ['edit-service', dataItem.category?.name, dataItem.id],
        },
      },
    ]);
  }

  openManageCategoryDialog(): void {
    const dialogRef = this.manageCategoriesDialog.open(ManageCategoriesDialogComponent, {
      width: '600px',
      data: CategoryType.Service,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshData();
    });
  }

  openManageUserServicesDialog(): void {
    const dialogRef = this.manageCategoriesDialog.open(ManageCategoriesDialogComponent, {
      width: '600px',
      data: CategoryType.Service,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshData();
    });
  }

  public removeHandler({ dataItem }) {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'delete') {
          this.serviceTemplatesService.deactivateServiceTemplate(dataItem).subscribe(() => {
            this.refreshData();
          });
        }
      });
  }

  loadItems() {
    this.gridView = {
      data: this.serviceTemplates,
      total: this.serviceTemplates.length,
    };
  }

  formatResourcesToString(data: ClinicServiceTemplate) {
    var roomResourcesString = '';
    var equipmentResourcesString = '';

    data.serviceTemplateResources.forEach((str: ServiceTemplateResource) => {
      if (str.resource.resourceType === ResourceType.Equipment)
        equipmentResourcesString = equipmentResourcesString + str.resource.name + ', ';
      if (str.resource.resourceType === ResourceType.Room)
        roomResourcesString = roomResourcesString + str.resource.name + ', ';
    });

    // Remove trailing commas
    roomResourcesString = roomResourcesString.replace(/,\s*$/, '');
    equipmentResourcesString = equipmentResourcesString.replace(/,\s*$/, '');

    if (roomResourcesString.length > 0 && equipmentResourcesString.length > 0)
      return roomResourcesString + ', ' + equipmentResourcesString;
    else if (roomResourcesString.length > 0 && equipmentResourcesString.length == 0) return roomResourcesString;
    else if (roomResourcesString.length == 0 && equipmentResourcesString.length > 0) return equipmentResourcesString;
  }

  toggleServiceTag(data: ClinicServiceTemplate) {
    let waitStatus = setInterval(() => {
      //You have to wait as the CellEvent may not fire first
      clearInterval(waitStatus);
      waitStatus = undefined;
      let addOrRemoveTag =
        data.serviceTag && data.serviceTag.isActive
          ? this.tagService.deleteServiceTag(data.id)
          : this.tagService.addServiceTag(data.id);
      addOrRemoveTag.subscribe((serviceTag) => {
        this.refreshData();
      });
    }, 20);
  }

  updateRequireCardOnFile(clinicServiceTemplate: ClinicServiceTemplate, event) {
    this.serviceTemplatesService
      .updateRequireCardOnFile(clinicServiceTemplate, event.target.checked)
      .subscribe(() => {});
  }
}
