import { MedicalInfo } from './../models/patient-portal/medical-info';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PatientPortalService {
  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient) {}

  getMedicalInfo(patientId: number): Observable<MedicalInfo> {
    return this.http.get<MedicalInfo>(environment.baseUrl + 'api/PatientPortal/GetMedicalInfo/' + patientId);
  }

  addWizardData(patientId: number, wizardData: any): Observable<void> {
    if (wizardData.mobileNumber && wizardData.mobileNumber.value) {
      wizardData.mobileNumber = wizardData.mobileNumber.value
    }
    return this.http
      .post<void>(environment.baseUrl + 'api/PatientPortal/AddWizardInfo/' + patientId, wizardData, this.httpOptions)
      .pipe(
        map((addedWizardInfo: any) => {
          return addedWizardInfo;
        })
      );
  }
}
