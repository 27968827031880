<ngx-loading [show]="loading"></ngx-loading>
<kendo-grid
  class="emr-kendo-grid"
  [data]="gridView"
  [style.maxHeight.%]="100"
  [pageable]="false"
  [sortable]="{
    allowUnsort: false,
    mode: 'single'
  }"
  [sort]="gridState.sort"
  (dataStateChange)="stateChange($event)"
  #grid="kendoGrid"
>
  <kendo-grid-column field="patientName" title="Patient">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <button type="button" class="btn-clean px-0 text-left underline" (click)="navigateToPatient(dataItem.patientId)">
        {{ dataItem.patientName }}
      </button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="firstPaymentDate" title="First Payment Date">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{ dataItem.firstPaymentDate | date : 'yyyy-MM-dd h:mma' }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="lastPaymentDate" title="Last Payment Date">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{ dataItem.lastPaymentDate | date : 'yyyy-MM-dd h:mma' }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="nextPaymentDate" title="Next Payment Date">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{ dataItem.nextPaymentDate | date : 'yyyy-MM-dd h:mma' }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="expirationDate" title="Expiration Date">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{ dataItem.expirationDate | date : 'yyyy-MM-dd h:mma' }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="cancellableDate" title="Cancellable Date">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{ dataItem.cancellableDate | date : 'yyyy-MM-dd h:mma' }}
    </ng-template>
  </kendo-grid-column>
  <!-- <kendo-grid-column field="cancelled" title="Cancelled">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column> -->
  <kendo-grid-column field="dateCancelled" title="Date Cancelled">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{ dataItem.cancelled ? (dataItem.dateCancelled | date : 'yyyy-MM-dd h:mma') : null }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="cycleRedemptions" title="Cycle Redemptions">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <!-- <kendo-grid-column *ngFor="let tax of possibleTaxes" [field]="tax.name" [title]="tax.name">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{ dataItem.taxCollected[tax.name] | currency }}
    </ng-template>
  </kendo-grid-column> -->
</kendo-grid>
