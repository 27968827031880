import { Form } from './form';

export class ServiceForm {
  public id: number;
  public clinicServiceTemplateId: number;
  public clinicFormId: number;
  public clinicForm: Form;
  public isSelectedByDefault: boolean = true;

  public static fromForm(clinicServiceTemplateId: number, form: Form) {
    let serviceForm = new ServiceForm();
    serviceForm.clinicServiceTemplateId = clinicServiceTemplateId;
    serviceForm.clinicFormId = form.id;
    serviceForm.clinicForm = form;
    return serviceForm;
  }

  public constructor(init?: Partial<ServiceForm>) {
    Object.assign(this, init);
  }
}
