import { Component, OnInit } from '@angular/core';

import { Service } from '@models/service/service';
import { PatientTransactionService } from '@services/patient-transaction.service';

@Component({
  selector: 'app-patient-treatment-plan-tab',
  templateUrl: './patient-treatment-plan-tab.component.html',
  styleUrls: ['./patient-treatment-plan-tab.component.less'],
})
export class PatientTreatmentPlanTabComponent implements OnInit {
  showOverview = true;
  service: Service;
  treatmentIsFromToday: boolean;

  constructor() {}

  ngOnInit() {}
}
