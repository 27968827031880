import { ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { postcodeValidator } from 'postcode-validator';

export function postalCodeValidator(countryCode: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === '') return null;
    const valid = postcodeValidator(control.value, countryCode);
    return valid ? null : { postalCodeInvalid: { value: control.value } };
  };
}
