<div class="container">
  <div class="row h-75 p-3 flex-column flex-nowrap" *ngIf="patientPhotos">
    <div class="photo-gallery-wrapper mt-1" *ngFor="let item of patientPhotos">
      <h6 class="sub-heading navy-font">{{ item.date }}</h6>
      <div class="photo-wrapper d-flex flex-wrap">
        <ng-container *ngIf="!seriesMode">
        <div

          *ngFor="let photo of item.photos; let i = index">
          <div *ngIf="showStockImages || !photo.isStockPhoto"  class="photo-container d-flex rounded-2 position-relative"
          (click)="imageClicked(photo)"
          [ngClass]="{'selected-photo': selectedPhoto?.id === photo.id && selectedPhoto?.isSeries === photo.isSeries }">
          <!-- <ngx-loading [show]="photo?.loading"></ngx-loading> -->
          <!-- <a type="button" *ngIf="photo?.isSeries"   class="close em-btn btn-clean download-gallery-button"   aria-label="Close"  (click)="$event.stopPropagation();downloadSeries(photo)">
            <i class="fal fa-download"></i>
          </a>
          <a type="button" *ngIf="!photo?.isSeries" download class="close em-btn btn-clean download-gallery-button"   aria-label="Close" [href]="photo.filePath">
            <i class="fal fa-download"></i>
          </a> -->

          <ul *ngIf="photo.isSeries  && photo.seriesPhotos?.length > 0; else regThumb" class="series-thumbnail">
            <li *ngFor="let p of photo.seriesPhotos;let i = index;"  [ngStyle]="{'width':100 / photo?.seriesPhotos?.length +  '%' }">
              <img [src]="p.filePathThumb"  class="series-thumb-img" [ngStyle]="{'margin-left': i != photo?.seriesPhotos?.length - 1 ? (-1 * (100 / photo?.seriesPhotos?.length)) + '%' : '0'}">
            </li> 
          </ul>
          <ng-template #regThumb>
            <img [src]="photo.filePathThumb"  class="gallery-photo"  >
          </ng-template>
          
          <div *ngIf="photo.photoConsentType !== photoConsentType.None || !photo.isSeries" class="photo-overlay d-flex white-font position-absolute" kendoTooltip tooltipClass="em-tooltip" position="top">
            <i *ngIf="photo.photoConsentTypeId !== photoConsentType.None" class="far fa-user-check p-1" title="Consent Given"></i>
            <i *ngIf="photo.photoConsentTypeId === photoConsentType.InternalExternal" class="far fa-check-double p-1" title="Internal and External"></i>
            <i *ngIf="photo.isFavourite" class="fas fa-heart p-1" title="Favourite"></i>
            <i *ngIf="photo.seriesType === SeriesType.BeforeAfter" class="far fa-book-open p-1 ml-auto" title="Before and After"></i>
            <div class="ml-auto d-flex align-self-center" *ngIf="photo.seriesType === SeriesType.Multi">{{photo?.seriesPhotos?.length}}</div>
            <i *ngIf="photo.seriesType === SeriesType.Multi" class="far fa-clone p-1" title="Series"></i>
          </div>
        </div>
        </div>
        </ng-container>
        <ng-container *ngIf="seriesMode">
        <div
          class="photo-container d-flex rounded-2 position-relative"
          (click)="imageSeriesClicked(photo)"
          [ngClass]="{'selected-photo': selectedSeriesPhotos[photo.id] }"
          *ngFor="let photo of item.photos">

          <img [src]="photo.filePathThumb"  class="gallery-photo"  > 
          <div class="photo-overlay d-flex white-font position-absolute" kendoTooltip tooltipClass="em-tooltip" position="top">
            <i *ngIf="photo.photoConsentTypeId !== photoConsentType.None" class="far fa-user-check p-1" title="Consent Given"></i>
            <i *ngIf="photo.photoConsentTypeId === photoConsentType.InternalExternal" class="far fa-check-double p-1" title="Internal and External"></i>
            <i *ngIf="photo.isFavourite" class="fas fa-heart p-1" title="Favourite"></i>
          </div>
        </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
