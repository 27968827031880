<ngx-loading [show]="loading"></ngx-loading>
<ng-scrollbar class="h-100">
  <div class="header-line"></div>
  <div class="d-flex justify-content-between">
    <div *ngFor="let gridView of gridViews; let i = index" class="grid-wrapper">
      <kendo-grid
        class="emr-kendo-grid"
        [data]="gridView"
        [style.maxHeight.%]="100"
        (excelExport)="onExcelExport($event, i)"
        #grid="kendoGrid"
      >
        <kendo-grid-column field="summary" [title]="gridHeaders[i]"></kendo-grid-column>
        <kendo-grid-column field="total" title="Total" [width]="150" headerClass="text-right"></kendo-grid-column>
        <kendo-grid-excel [fileName]="getFileName(false, i)"></kendo-grid-excel>
        <ng-template
          kendoGridDetailTemplate
          let-dataItem
          *ngIf="gridHeaders[i] == 'Credits Summary'"
          class="credit-liabilities-area"
        >
          <app-liabilities-credit-report-details [details]="dataItem.details"></app-liabilities-credit-report-details>
        </ng-template>
      </kendo-grid>
    </div>
  </div>
</ng-scrollbar>
