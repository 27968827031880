<div class="h-100 d-flex flex-column">
  <div class="container-fluid">
    <div class="row py-4 px-2 navy-background">
      <div class="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center py-1">
        <h5 class="white-font text-uppercase m-0 mr-2">Patients</h5>
      </div>
    </div>
  </div>

  <ngx-loading [show]="loading"></ngx-loading>
  <app-error *ngIf="errorMessage" [message]="errorMessage"></app-error>

  <div class="content flex-grow-1 minh-0">
    <kendo-grid
      [data]="gridView"
      [pageSize]="gridState.take"
      [skip]="gridState.skip"
      [sort]="gridState.sort"
      [sortable]="true"
      [pageable]="true"
      kendoGridSelectBy="patientId"
      [style.height.%]="100"
      [filter]="gridState.filter"
      [filterable]="false"
      (dataStateChange)="dataStateChange($event)"
      (add)="onAddClick($event)"
    >
      <ng-template kendoGridToolbarTemplate>
        <div class="d-flex">
          <span class="pl-4 search-box border border-radius">
            <span class="fa fa-search"></span>
            <mat-form-field class="w-75 p-3">
              <input
                matInput
                placeholder="Search patients..."
                aria-label="Search Patients"
                [(ngModel)]="searchValue"
                (ngModelChange)="search($event)"
              />
              <button
                mat-button
                *ngIf="searchValue"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="search('')"
                style="vertical-align: bottom"
                class="btn-clean"
              >
                <i class="fas fa-times-circle"></i>
              </button>
            </mat-form-field>
          </span>
        </div>
        <button kendoGridAddCommand type="button" class="btn em-btn">
          <i class="fal fa-plus-circle"></i> Add Patient
        </button>
        <button kendoButton type="button" class="btn em-btn" (click)="mergePatients()">
          <i class="fal fa-user-friends"></i> Merge Patients
        </button>
        <button kendoGridExcelCommand class="btn em-btn m-0" type="button">
          <i class="fal fa-file-excel"></i> Export to Excel
        </button>
      </ng-template>
      <kendo-grid-command-column title="" [width]="28">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          <button
            class="btn kendo-btn px-0"
            (click)="openPatientPanel(dataItem)"
            kendoTooltip
            tooltipClass="em-tooltip"
            position="top"
            title="Open Patient Panel"
          >
            <i class="fas fa-user pl-2"></i>
          </button>
        </ng-template>
      </kendo-grid-command-column>
      <kendo-grid-column title="First Name" [width]="115">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          <a (click)="openPatientPanel(dataItem)">{{ dataItem.firstName | titlecase }}</a>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Last Name" [width]="115">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          <a (click)="openPatientPanel(dataItem)">{{ dataItem.lastName | titlecase }}</a>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="gender" title="Gender" [width]="75"> </kendo-grid-column>
      <kendo-grid-column field="mobileNumber" title="Mobile Phone" [width]="100" [sortable]="false"></kendo-grid-column>
      <kendo-grid-column field="email" title="Email Address" [width]="135" [sortable]="false"></kendo-grid-column>
      <kendo-grid-column
        field="isPreferred"
        title="VIP"
        [width]="40"
        filter="boolean"
        [sortable]="false"
        [headerStyle]="{ justifyContent: 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="text-center">
            <i *ngIf="dataItem.isPreferred" class="fas fa-check"></i>
            <i *ngIf="!dataItem.isPreferred" class="fas fa-times"></i>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="address.city" title="City" [width]="100"></kendo-grid-column>
      <kendo-grid-column field="address.province" title="Province" [width]="100" [sortable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="birthDate" title="Birth Date" [width]="85">
        <ng-template kendoGridFilterCellTemplate let-filter> </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.birthDate | convertUTC }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="patientStatus" title="Status" [width]="75" [sortable]="false">
        <ng-template kendoGridFilterCellTemplate let-filter> </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div *ngFor="let patientStatus of statusEnumKeys">
            <div *ngIf="dataItem.patientStatus == patientStatus">{{ patientStatusData[patientStatus] }}</div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-excel fileName="Patients.xlsx" [fetchData]="allData"></kendo-grid-excel>
      <kendo-grid-pdf fileName="Patients.pdf" [allPages]="true">
        <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
      </kendo-grid-pdf>
    </kendo-grid>
  </div>

  <p-overlayPanel #hoverPanel id="hoverPanel" [dismissable]="false"> Content </p-overlayPanel>
  <app-actionpanel></app-actionpanel>
</div>
