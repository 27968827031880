import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Allergy } from '@models/allergy';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AllergiesService {

  constructor(private http: HttpClient) { }

  /**
   * Sends the allergy passed in to the back end to be added to the database.
   * @param allergy The allergy to add
   */
  public addAllergy(allergy: Allergy): Observable<Allergy> {
    return this.http.post<Allergy>(`${environment.baseUrl}api/Allergies`, allergy);
  }

  /**
   * Sends the allergy passed in to the back end to be updated in the database.
   * @param allergy The allergy to updated
   */
  public updateAllergy(allergy: Allergy): Observable<Allergy> {
    return this.http.put<Allergy>(`${environment.baseUrl}api/Allergies`, allergy);
  }

  /**
   * Retrieves the allergy with the ID passed in from the back end.
   * @param allergyId The ID of the allergy to get
   */
  public getAllergy(allergyId: number): Observable<Allergy> {
    return this.http.get<Allergy>(`${environment.baseUrl}api/Allergies/${allergyId}`);
  }

  /**
   * Retrieves the allergies of the patient with the ID passed in from the back end.
   * @param patientId The ID of the patient whose allergies to get
   */
  public getAllergiesByPatientId(patientId: number): Observable<Allergy> {
    return this.http.get<Allergy>(`${environment.baseUrl}api/Allergies/ByPatientId/${patientId}`);
  }

  /**
   * Sends the allergy ID passed in to the back end to be deleted from the database.
   * @param allergyId The ID of the allergy to be deleted
   */
  public deleteAllergy(allergyId: number): Observable<Allergy> {
    return this.http.delete<Allergy>(`${environment.baseUrl}api/Allergies/${allergyId}`);
  }
}
