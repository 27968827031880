import { InvoiceType } from '@models/invoice/invoice-type';
import { InvoicesReport } from '@models/reports/invoices/invoices-report';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ReportsService } from '@services/reports.service';
import { GridDataResult, DataStateChangeEvent, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { Subject, Observable, pipe } from 'rxjs';
import { takeUntil, map, take } from 'rxjs/operators';
import { IReportComponent } from '@models/reports/ireport-component';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { DataSourceRequestState, SortDescriptor, orderBy, process, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { Router } from '@angular/router';
import { PatientService } from '@services/patient.service'; 

@Component({
  selector: 'app-invoices-reports',
  templateUrl: './invoices-reports.component.html',
  styleUrls: ['./invoices-reports.component.less']
})
export class InvoicesReportsComponent implements OnInit, OnDestroy, IReportComponent {
  @ViewChild('grid') grid;
  gridView: GridDataResult;
  gridState: DataSourceRequestState;
  pageable: any;
  loading: boolean = false;
  unsub: Subject<void> = new Subject<void>();

  constructor(
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private reportsService: ReportsService,
    private patientService: PatientService,
    private router: Router
  ) { }

  ngOnInit() {
    this.initDefaultValue();
    this.getReportData();
  }

  initDefaultValue() {
    this.gridState = {
      skip: 0,
      take: 100,
      sort: [{field: 'patientName', dir: 'asc'}],
      // Initial filter descriptor
      filter: {
        logic: 'and',
        filters: []
      }
    };

    this.gridView = {
      data: [],
      total: 0
    };
  }

  getReportData() {
    this.loading = true;
    this.reportsService.getInvoicesReportEntities(this.gridState)
      .pipe(takeUntil(this.unsub))
      .subscribe((invoicesReports: any) => { 
        this.gridView = {
          data: invoicesReports.data,
          total: invoicesReports.total || invoicesReports.data.length
        };
        this.loading = false;
    });
  }

  dataStateChange(event: DataStateChangeEvent){
    this.gridState = event;
    this.getReportData();
  }

  navigateToPatientInvoice(patientId: number, invoiceId: number) {
    this.patientService.getPatientById(patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((patient: any) => {
        this.patientService.patientPanelPatient = patient;
        this.router.navigate(['/reports', { outlets: { 'action-panel': ['patient', patientId + '_patientprofiletab', 'patienttabs', 'patientaccounttab', 'invoice', invoiceId] } }]);
      });
  }

  navigateToPatientAccountTab(patientId: number) {
    this.patientService.getPatientById(patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((patient: any) => {
        this.patientService.patientPanelPatient = patient;
        this.router.navigate(['/reports', { outlets: { 'action-panel': ['patient', patientId + '_patientprofiletab', 'patienttabs', 'patientaccounttab', 'overview'] } }]);
      });
  }

  exportToExcel() {
    this.grid.saveAsExcel();
  }

  onExcelExport(e: any) {
    this.reportsService.onExcelExport(e, 'Invoices Report');
  }

  pageChange(event: PageChangeEvent) {
    this.gridState.skip = event.skip;
    this.getReportData();
  }
  isPaidChange(filter: CompositeFilterDescriptor) { 
    this.gridState.filter =filter;
   
    this.getReportData();
  }

  sortChange(sort: SortDescriptor[]) {
    this.gridState.sort = sort;
    this.gridView.data = orderBy(this.gridView.data, this.gridState.sort);
  }

  sortIconClass(column: any) {
    return this.gridState.sort.length && (this.gridState.sort[0].field === column.field && this.gridState.sort[0].dir === 'asc') ? 'fad fa-sort-up'
           : this.gridState.sort.length && (this.gridState.sort[0].field === column.field && this.gridState.sort[0].dir === 'desc') ? 'fad fa-sort-down'
           : 'fas fa-sort';
  }

  isReturnInvoice(context: RowClassArgs){
    let isReturnInvoice = context.dataItem.invoiceTypeId != InvoiceType.Regular;
    return {
      'is-regular': !isReturnInvoice,
      'is-return': isReturnInvoice
    }
  }

  allData = (): Observable<any> => {
    const gridState: DataSourceRequestState = {};
    return this.reportsService.getInvoicesReportEntities(gridState)
             .pipe(map((invoicesReports: any) => {
                return {
                  data: invoicesReports.data.map((item: InvoicesReport) => ({...item, invoiceId: item.invoiceId, invoiceDate: this.datePipe.transform(item.invoiceDate,  'yyyy-MM-dd'),
                        subtotal: this.currencyPipe.transform(item.subtotal), tax: this.currencyPipe.transform(item.tax), invoiceAmount: this.currencyPipe.transform(item.invoiceAmount),
                        isPaid: item.isPaid ? true : false, patientFullName: item.patientFullName})),
                  total: invoicesReports.total
                };
              }));
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
