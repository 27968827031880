export enum TargetTypes {
  Note = 1,
  Appointment = 2,
  Photo = 3,
  PatientDocument = 4,
  TreatmentPlan = 5,
  Patient = 6,
  Invoice = 7,
  Prescription = 8
}
