<div class="px-3" [ngStyle]="{ height: panelHeight ? panelHeight + 'px' : 'inherit' }">
  <div class="d-flex align-items-center w-100" style="height: 48px">
    <div class="column-header py-0 mr-2">Recommended Products</div>
  </div>

  <ng-scrollbar style="height: calc(100% - 48px)">
    <ng-container *ngIf="serviceProducts?.length > 0; else noServiceProducts">
      <div class="d-flex mb-2" *ngFor="let product of serviceProducts">
        <div class="d-flex flex-column w-100">
          <div class="d-flex panel-box">
            <div class="panel-box-column panel-box-column--main d-flex flex-column">
              {{ product?.name }}
            </div>
            <div class="panel-box-column">
              <span class="text-lowercase font-weight-normal">x {{ product?.quantity }}</span>
            </div>
            <div class="panel-box-column">
              {{ product?.price | currency }}
            </div>
          </div>
          <div class="font-weight-normal">
            {{ product?.note }}
          </div>
        </div>
        <button
          *ngIf="product && !product.isInCurrentInvoice"
          kendoTooltip
          tooltipClass="em-tooltip"
          position="top"
          type="button"
          class="panel-box-button h-auto"
          (click)="product.isInCurrentInvoice = true; selectProduct.next(product)"
        >
          <i class="far fa-plus" title="Add to Invoice"></i>
        </button>
      </div>
    </ng-container>
  </ng-scrollbar>
  <ng-template #noServiceProducts> The patient hasn't purchased products yet </ng-template>
</div>
