import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AppointmenteTreatmentForm } from '@models/etreatment-forms/appointment-etreatment-form';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppointmenteTreatmentFormService {
  constructor(private http: HttpClient) {}

  updateAppointmenteTreatmentForm(appointmenteTreatmentForm: AppointmenteTreatmentForm, submission: any) {
    let submissionData = submission.data;
    if (submission.dateTime) {
      submission.dateTime = new Date().toISOString();
    }
    appointmenteTreatmentForm.formEntry = JSON.stringify({
      data: submissionData,
      metadata: { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
    });

    let packageJson = require('./../../../package-lock.json');
    appointmenteTreatmentForm.rendererVersion = packageJson['packages']['node_modules/@formio/angular']['version'];

    return this.http
      .put<AppointmenteTreatmentForm>(environment.baseUrl + 'api/AppointmenteTreatmentForms', appointmenteTreatmentForm)
      .pipe(tap((form) => {}));
  }
}
