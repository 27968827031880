import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { isNullOrUndefined } from '@app/shared/helpers';
import { ObrPlottingEventType } from '@models/observation/obr-plotting-event-type';
import { Observation, ObservationType, ObservationTypes } from '@models/observation/observation';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { ServiceListType } from '@models/service/service-list-type';
import { UserFavouriteService } from '@models/service/user-favourite-service';
import { CurrentDataService } from '@services/currentData.service';
import { ObservationTypesService } from '@services/observation-types.service';
import { PlottingEventService } from '@services/plotting-event.service';
import { ServiceEventService } from '@services/service-event.service';
import { ServiceTemplatesService } from '@services/service-templates.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FavouriteServicesService } from '../../../services/favourite-services.service';
import { ObservationListItemsService } from '@services/observation-list-items.service';

@Component({
  selector: 'app-favourite-item',
  templateUrl: './favourite-item.component.html',
  styleUrls: ['./favourite-item.component.less'],
})
export class FavouriteItemComponent implements OnInit, OnDestroy {
  @Input() services: UserFavouriteService[];
  @Input() serviceTemplateId: number;
  @Input() isLocked: boolean;
  @Input() serviceIDColour: string;

  serviceListType = ServiceListType;
  observationTypes = ObservationTypes;
  observationType: ObservationType;
  expanded: number;
  private unsub = new Subject<any>();
  private serviceDetailTemplate = ServiceDetailTemplate;
  loading = false;
  plottingActive: boolean = false;

  constructor(
    private currentDataService: CurrentDataService,
    private observationTypesService: ObservationTypesService,
    private serviceEventService: ServiceEventService,
    private serviceTemplatesService: ServiceTemplatesService,
    private plottingEventService: PlottingEventService,
    private favouriteServicesService: FavouriteServicesService,
    private observationListItemsService: ObservationListItemsService
  ) {
    // All items collapsed by default
    this.expanded = -1;
  }

  ngOnInit() {
    this.findObservationType();
    this.initPlottingSubscription();
    this.currentDataService.favouritesDataUpdated$.pipe(takeUntil(this.unsub)).subscribe(() => (this.expanded = -1));
  }

  findObservationType() {
    this.loading = true;
    this.serviceTemplatesService.getServiceTemplateById(this.serviceTemplateId).subscribe((st) => {
      if (!isNullOrUndefined(st)) {
        //const observationTypes = this.currentDataService.observationTypes;
        this.observationTypesService.getAllObservationTypes().subscribe((observationTypes) => {
          if (
            st.serviceDetailTemplateId === this.serviceDetailTemplate.Injections ||
            st.serviceDetailTemplateId === this.serviceDetailTemplate.Deoxycholate
          ) {
            this.observationType = observationTypes.find((obrType) => obrType.name === 'Injectables');
          } else if (st.serviceDetailTemplateId === this.serviceDetailTemplate.Coolsculpting) {
            this.observationType = observationTypes.find((obrType) => obrType.name === 'Coolsculpting');
          } else if (st.serviceDetailTemplateId === this.serviceDetailTemplate.CoolTone) {
            this.observationType = observationTypes.find((obrType) => obrType.name === 'CoolTone');
          }
        });
      }
      this.loading = false;
    });
  }

  initPlottingSubscription() {
    this.plottingEventService
      .getObservationSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((response) => {
        switch (response.event) {
          case ObrPlottingEventType.StartPinDrop: {
            this.plottingActive = true;
            break;
          }
          case ObrPlottingEventType.StopPinDrop: {
            this.plottingActive = false;
            break;
          }
          default:
            break;
        }
      });
  }

  onApplyTreatment(service) {
    if (this.isLocked) {
      return;
    }
    if (this.plottingActive) this.plottingEventService.stopPinDrop();
    const observationType: ObservationTypes = this.observationType.name as ObservationTypes;
    // Apply updated costs
    const observations = JSON.parse(JSON.stringify(service.observations));
    observations.forEach((obr: Observation) => {
      this.observationListItemsService.assignCostToObservation(obr);
    });
    service.observations = observations;
    this.serviceEventService.emitApplyTreatment(service, observationType);
    this.currentDataService.treatmentIsDirty = true;
  }

  onToggleExpand(index: number) {
    if (this.services[index].service.observations.length > 0) {
      if (this.expanded === index) {
        this.expanded = -1;
      } else {
        this.expanded = index;
      }
    }
  }

  public async onFavouriteDropped(event: CdkDragDrop<UserFavouriteService[]>) {
    this.loading = true;
    moveItemInArray(this.services, event.previousIndex, event.currentIndex);
    var favs: UserFavouriteService[] = [];
    this.services.forEach((s) =>
      favs.push({
        userId: s.userId,
        serviceId: s.serviceId,
        order: s.order,
      })
    );
    await this.favouriteServicesService.updateFavouritesServiceOrder(favs).toPromise();
    this.loading = false;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
