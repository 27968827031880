import { NgModule } from '@angular/core';
import { PrescriptionService } from '@services/prescriptions/prescription.service';
import { ExternalPrescriptionService } from '@services/prescriptions/external-prescription.service';
import { PrescriptionFavouriteService } from '@services/prescriptions/prescription-favourite.service';
import { AddPrescriptionComponent } from './add-prescription/add-prescription.component';
import { SharedModule } from '@app/shared/shared.module';
import { PrescriptionsViewComponent } from './prescriptions-view/prescriptions-view.component';
import { RouterModule } from '@angular/router';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { GridModule } from '@progress/kendo-angular-grid';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { PrescriptionDetailsComponent } from './prescriptions-view/prescription-details/prescription-details.component';
import { MatRadioModule } from '@angular/material/radio';
import { FormioModule } from '@formio/angular';
import { BlobService } from '@services/blob.service';
import { PrescriptionSignatureModalComponent } from './prescription-signature-modal/prescription-signature-modal.component';
import { PrescriptionFaxModalComponent } from './prescription-fax-modal/prescription-fax-modal.component';

@NgModule({
    declarations: [
        AddPrescriptionComponent,
        PrescriptionsViewComponent,
        PrescriptionDetailsComponent,
        PrescriptionSignatureModalComponent,
        PrescriptionFaxModalComponent
    ],
    imports: [
        SharedModule,
        RouterModule,
        TypeaheadModule.forRoot(),
        PopoverModule.forRoot(),
        GridModule,
        PDFExportModule,
        MatRadioModule,
        FormioModule
    ],
    providers: [
        PrescriptionService,
        ExternalPrescriptionService,
        PrescriptionFavouriteService,
        BlobService
    ]
})
export class PrescriptionsModule { }
