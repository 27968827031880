import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-passcode',
  templateUrl: './passcode.component.html',
  styleUrls: ['./passcode.component.less'],
})
export class PasscodeComponent implements OnInit {
  @Input() patientId: number;
  form: FormGroup;
  unsub: Subject<void> = new Subject<void>();

  loading = false;
  errorMessage: string = '';

  constructor(
    public activeModal: NgbActiveModal,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.form = new FormGroup({
      passcode: new FormControl('', [Validators.required]),
    });
  }

  onSubmit() {
    var passcode = this.form.controls['passcode'].value;
    if (passcode == '99999') {
      if (this.errorMessage) {
        this.errorMessage = '';
      }
      this.activeModal.dismiss('Cross click');

      this.router.navigate([
        'schedule',
        { outlets: { 'action-panel': ['patient', this.patientId + '_' + 'patientprofiletab'] } },
      ]);
    } else {
      this.errorMessage = 'Invalid passcode.';
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
