<div *ngIf="displaySelection" class="sidebar-wrapper h-100">
  <app-conversations-sidebar
    class="h-100"
    (conversationSelected)="selectedConversation = $event"
    (conversationPatientSelected)="onConversationPatientSelected($event)"
  ></app-conversations-sidebar>
</div>
<div class="d-flex flex-column flex-grow-1 grey-one-background">
  <ngx-loading [show]="loading"></ngx-loading>
  <div *ngIf="!displaySelection && !displayOnly" class="d-flex spring-green-background p-0">
    <button
      type="button"
      class="btn white-font p-0 ml-auto mr-2"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <i class="fal fa-times"></i>
    </button>
  </div>
  <app-error *ngIf="statusMessage" [message]="statusMessage" [type]="statusMessageType"></app-error>
  <app-error *ngIf="optInMessage" [message]="optInMessage" [type]="optInMessageType"></app-error>
  <kendo-chat
    *ngIf="connected && selectedConversation && clientUser; else noneSelected"
    class="ml-3 my-3 rounded grey-two-background"
    [ngClass]="{ 'mr-3': !displaySelection }"
    [messages]="messages"
    [user]="clientUser"
    (executeAction)="onAction($event)"
  >
    <ng-template kendoChatMessageBoxTemplate>
      <!-- (keydown)="onKeyDown($event)" -->
      <ng-container *appAuthGuard="sendPolicy">
        <ng-container *ngIf="!displayOnly">
          <div *ngIf="imagePreview" class="p-2 pt-3 preview-image-container">
            <img class="preview-image" [src]="imagePreview" />
            <button (click)="selectedImage = null" class="btn-clean clear-btn">
              <i class="fad fa-times-circle"></i>
            </button>
          </div>
          <kendo-textarea
            (inputFocus)="onInputFocus()"
            (inputBlur)="onInputBlur()"
            placeholder="Type a message..."
            resizable="none"
            [rows]="4"
            #messageBoxInput
          >
            <kendo-textarea-suffix class="custom-suffix">
              <input (change)="onFileSelect($event)" #uploadInput type="file" style="display: none" />
              <button (click)="uploadInput.click()" class="btn btn-clean mr-auto">
                <i class="fas fa-image"></i>
              </button>
              <button
                [disabled]="!patient?.sendTwoWayMessages"
                (click)="sendMessage()"
                fillMode="flat"
                class="btn em-btn em-btn-green m-1"
              >
                Send <i class="fas fa-paper-plane"></i>
              </button>
            </kendo-textarea-suffix>
          </kendo-textarea>
        </ng-container>
      </ng-container>
    </ng-template>
  </kendo-chat>

  <ng-template #noneSelected>
    <div
      *ngIf="displaySelection"
      class="w-100 h-100 d-flex align-items-start justify-content-center px-0 pb-2 light-grey-background"
    >
      <span class="m-auto font-weight-bold" style="font-size: 2em">Please select a chat from the left.</span>
    </div>
    <div
      *ngIf="!displaySelection"
      class="loading-placeholder d-flex align-items-start justify-content-center m-3 light-grey-background"
    >
      <!-- <span class="m-auto font-weight-bold" style="font-size: 2em">Placeholder.</span> -->
    </div>
  </ng-template>
</div>

<app-actionpanel></app-actionpanel>
