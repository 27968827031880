import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-title-select',
  templateUrl: './title-select.component.html',
  styleUrls: ['./title-select.component.less']
})
export class TitleSelectComponent implements OnInit {
  @Input() titleValue: string;
  @Output() titleValueChange = new EventEmitter();
  titles: string[];

  constructor() { }

  ngOnInit() {
    this.titles = ['Dr.', 'Mr.', 'Ms.', 'Mrs.'];
  }

  change(newValue: string) {
    this.titleValue = newValue;
    this.titleValueChange.emit(newValue);
  }
}
