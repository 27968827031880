import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TreatmentType } from '@models/treatment-type';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ObservationListItem, ObservationType, ObservationUnit } from '@models/observation/observation';
import { ObservationListItemsService } from '@services/observation-list-items.service';
import { ObservationListTypesService } from '@services/observation-list-types.service';
import { ObservationUnitsService } from '@services/observation-units.service';

@Component({
  selector: 'app-clinic-supplies-edit',
  templateUrl: './clinic-supplies-edit.component.html',
  styleUrls: ['./clinic-supplies-edit.component.less'],
})
export class ClinicSuppliesEditComponent implements OnInit, OnDestroy {
  unsub: Subject<void> = new Subject<void>();
  obrListItemId: number;
  observationListItem: ObservationListItem = null;
  treatmentTypes = TreatmentType;
  form: FormGroup;
  addOrEdit: String;
  isNewItem: boolean;
  allObservationListTypes: ObservationType[];
  allObservationUnits: ObservationUnit[];

  constructor(
    private fb: FormBuilder,
    private obrListItemTypeService: ObservationListTypesService,
    private obrListItemService: ObservationListItemsService,
    private obrUnitService: ObservationUnitsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.obrListItemTypeService.getObservationListTypes().subscribe((olt) => {
      this.allObservationListTypes = olt;
    });

    this.obrUnitService.getAllObservationUnits().subscribe((ou) => {
      this.allObservationUnits = ou;
    });

    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      if (+params['id'] === this.obrListItemService.newEntityId) {
        this.isNewItem = true;
        this.addOrEdit = 'Add';
      } else {
        this.obrListItemId = +params['id'];
        this.isNewItem = false;
        this.addOrEdit = 'Edit';
      }

      this.initForm();
    });
  }

  getDecimalPlaces() {
    if (this.allObservationListTypes && this.allObservationListTypes.length > 0 && this.form) {
      let obsList = this.allObservationListTypes.find((ol) => ol.id == this.form.get('observationListTypeId').value);
      return obsList && obsList.name == this.treatmentTypes.Fillers ? 2 : 1;
    }
  }

  private initForm() {
    this.form = this.fb.group({
      id: [0, Validators.required],
      clinicSupplyTypeId: [{ value: 0, disabled: true }, Validators.required],
      name: [{ value: null, disabled: true }, Validators.required],
      displayName: [null],
      observationListTypeId: [{ value: null, disabled: true }, Validators.required],
      sequenceNumber: [null],
      pricePerUnit: [null],
      costPerUnit: [null],
      unitsOnHand: [null],
      unitId: [{ value: null, disabled: true }],
    });

    if (this.isNewItem === false) {
      this.obrListItemService.getObservationListItem(this.obrListItemId).subscribe((oli) => {
        this.observationListItem = oli;
        this.form.controls['name'].setValue(oli.name);
        this.form.controls['displayName'].setValue(oli.displayName);
        this.form.controls['observationListTypeId'].setValue(oli.clinicSupplyType.observationListTypeId);
        this.form.controls['sequenceNumber'].setValue(oli.sequenceNumber);
        this.form.controls['pricePerUnit'].setValue(oli.pricePerUnit);
        this.form.controls['costPerUnit'].setValue(oli.costPerUnit);
        this.form.controls['unitsOnHand'].setValue(oli.unitsOnHand);
        this.form.controls['unitId'].setValue(oli.clinicSupplyType.observationUnitId);
      });
    }
  }

  onSubmit() {
    const observationListItem: ObservationListItem = {
      id: this.observationListItem ? this.observationListItem.id : 0,
      clinicSupplyTypeId: this.observationListItem ? this.observationListItem.clinicSupplyTypeId : 0,
      iconURL: this.observationListItem ? this.observationListItem.iconURL : '',
      name: this.form.get('name').value,
      displayName: this.form.get('displayName').value,
      sequenceNumber: this.form.get('sequenceNumber').value,
      pricePerUnit: this.form.get('pricePerUnit').value,
      costPerUnit: this.form.get('costPerUnit').value,
      unitsOnHand: this.form.get('unitsOnHand').value,
      isActive: this.isNewItem ? true : this.observationListItem.isActive,
    };

    if (this.isNewItem) {
      this.obrListItemService.addObservationListItem(observationListItem).subscribe(() => {
        this.obrListItemService.observationListsChanged.next();
        this.obrListItemService.updateRefreshRequired(true);
        this.router.navigate(['/management/catalogue/clinic-supplies']);
      });
    } else {
      this.obrListItemService.updateObservationListItem(observationListItem).subscribe(() => {
        this.obrListItemService.observationListsChanged.next();
        this.obrListItemService.updateRefreshRequired(true);
        this.router.navigate(['/management/catalogue/clinic-supplies']);
      });
    }
  }

  cancelUpdate() {
    this.obrListItemService.updateRefreshRequired(false);
    this.router.navigate(['/management/catalogue/clinic-supplies']);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
