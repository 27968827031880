import { PatientData } from './patient-data';
import { UserData } from './user-data';

const componentsToHide = [
  {
    key: 'labelPosition',
    ignore: true,
  },
  {
    key: 'widget.type',
    ignore: true,
  },
  {
    key: 'inputMask',
    ignore: true,
  },
  {
    key: 'displayMask',
    ignore: true,
  },
  {
    key: 'allowMultipleMasks',
    ignore: true,
  },
  {
    key: 'customClass',
    ignore: true,
  },
  {
    key: 'prefix',
    ignore: true,
  },
  {
    key: 'suffix',
    ignore: true,
  },
  {
    key: 'hidden',
    ignore: true,
  },
  {
    key: 'hideLabel',
    ignore: true,
  },
  {
    key: 'showWordCount',
    ignore: true,
  },
  {
    key: 'showCharCount',
    ignore: true,
  },
  {
    key: 'autofocus',
    ignore: true,
  },
  {
    key: 'spellcheck',
    ignore: true,
  },
  {
    key: 'disabled',
    ignore: true,
  },
  {
    key: 'tableView',
    ignore: true,
  },
  {
    key: 'modalEdit',
    ignore: true,
  },
  {
    key: 'hideInput',
    ignore: true,
  },
  {
    key: 'mask',
    ignore: true,
  },
  {
    key: 'tabindex',
    ignore: true,
  },
  {
    key: 'autocomplete',
    ignore: true,
  },
];

const textAreaComponentsToHide = componentsToHide;
textAreaComponentsToHide.push({ key: 'editor', ignore: true });

const emailComponentsToHide = componentsToHide;

const phoneNumberComponentsToHide = componentsToHide;
phoneNumberComponentsToHide.push({ key: 'inputMaskPlaceholderChar', ignore: true });

const contentComponentsToHide = componentsToHide;
contentComponentsToHide.push({ key: 'refreshOnChange', ignore: true });

const columnsComponentsToHide = componentsToHide;
columnsComponentsToHide.push({ key: 'autoAdjust', ignore: true });

const datetimeComponentsToHide = componentsToHide;
datetimeComponentsToHide.push({ key: 'useLocaleSettings', ignore: true });
datetimeComponentsToHide.push({ key: 'displayInTimezone', ignore: true });
datetimeComponentsToHide.push({ key: 'shortcutButtons', ignore: true });

const checkboxComponentsToHide = componentsToHide;
checkboxComponentsToHide.push({ key: 'shortcut', ignore: true });

const selectComponentsToHide = componentsToHide;
selectComponentsToHide.push({ key: 'widget', ignore: true });
selectComponentsToHide.push({ key: 'uniqueOptions', ignore: true });

export const builderOptions = {
  builder: {
    basic: false,
    advanced: false,
    layout: {
      weight: 0,
      default: true,
      components: {
        fieldset: false,
        panel: false,
        table: false,
        tabs: false,
        well: false,
        content: {
          title: 'Content',
          icon: 'far fa-edit',
          schema: {
            type: 'content',
            input: 'false',
            label: 'Content',
            key: 'content',
            html: '',
          },
        },
        columns: {
          title: 'Columns',
          icon: 'columns',
          weight: 10,
          schema: {
            label: 'Columns',
            key: 'columns',
            type: 'columns',
          },
        },
        htmlelement: {
          title: 'HTML Element',
          icon: 'code',
          weight: 20,
          schema: {
            label: 'HTML',
            key: 'htmlelement',
            type: 'htmlelement',
          },
        },
      },
    },
    pdf: false,
    premium: false,
    customBasic: {
      title: 'Form Fields',
      default: false,
      weight: 10,
      components: {
        textfield: true,
        checkbox: true,
        datetime: {
          title: 'Date / Time',
          icon: 'calendar',
          schema: {
            label: 'Date / Time',
            type: 'datetime',
            key: 'dateTime',
            disabled: true,
          },
        },
        textarea: true,
        selectboxes: true,
        select: true,
        radio: {
          title: 'Radio',
          icon: 'dot-circle',
          schema: {
            type: 'radio',
            inputType: 'radio',
            label: 'Radio',
            key: 'radio',
            values: [{ label: '', value: '' }],
            data: {
              url: '',
            },
            fieldSet: false,
          },
        },
        email: true,
        phoneNumber: true,
        signature: {
          title: 'Signature',
          key: 'signature',
          icon: 'fa fa-pencil',
          schema: {
            label: 'Signature',
            type: 'signature',
            key: 'signature',
            input: true,
            backgroundColor: 'transparent',
            width: '-webkit-fill-available',
            height: 'auto',
            footer: 'Sign above',
          },
        },
      },
    },
    data: {
      weight: 20,
      components: {
        datamap: false,
        hidden: false,
        container: false,
        datagrid: true,
        editgrid: true,
        tree: false,
      },
    },
    patientData: PatientData,
    userData: UserData,
    // clinicData: ClinicData,
  },
  editForm: {
    textfield: [
      {
        key: 'display',
        //  components: componentsToHide,
      },
      {
        key: 'data',
        ignore: false,
      },
      {
        key: 'api',
        ignore: false,
      },
      {
        key: 'conditional',
        ignore: false,
      },
      {
        key: 'logic',
        ignore: false,
      },
      {
        key: 'validation',
        ignore: false,
      },
      {
        key: 'layout',
        ignore: false,
      },
    ],
    textarea: [
      {
        key: 'display',
        // components: textAreaComponentsToHide,
      },
      {
        key: 'data',
        ignore: false,
      },
      {
        key: 'api',
        ignore: false,
      },
      {
        key: 'conditional',
        ignore: false,
      },
      {
        key: 'logic',
        ignore: false,
      },
      {
        key: 'validation',
        ignore: false,
      },
      {
        key: 'layout',
        ignore: false,
      },
    ],
    email: [
      {
        key: 'display',
        // components: emailComponentsToHide,
      },
      {
        key: 'data',
        ignore: false,
      },
      {
        key: 'api',
        ignore: false,
      },
      {
        key: 'conditional',
        ignore: false,
      },
      {
        key: 'validation',
        ignore: false,
      },
      {
        key: 'logic',
        ignore: false,
      },
      {
        key: 'layout',
        ignore: false,
      },
    ],
    phoneNumber: [
      {
        key: 'display',
        // components: phoneNumberComponentsToHide,
      },
      {
        key: 'data',
        ignore: false,
      },
      {
        key: 'api',
        ignore: false,
      },
      {
        key: 'conditional',
        ignore: false,
      },
      {
        key: 'logic',
        ignore: false,
      },
      {
        key: 'validation',
        ignore: false,
      },
      {
        key: 'layout',
        ignore: false,
      },
    ],
    content: [
      {
        key: 'display',
        //components: contentComponentsToHide,
      },
      {
        key: 'data',
        ignore: false,
      },
      {
        key: 'api',
        ignore: false,
      },
      {
        key: 'conditional',
        ignore: false,
      },
      {
        key: 'logic',
        ignore: false,
      },
      {
        key: 'validation',
        ignore: false,
      },
      {
        key: 'layout',
        ignore: false,
      },
    ],
    htmlelement: [
      {
        key: 'display',
        // components: contentComponentsToHide,
      },
      {
        key: 'data',
        ignore: false,
      },
      {
        key: 'api',
        ignore: false,
      },
      {
        key: 'conditional',
        ignore: false,
      },
      {
        key: 'logic',
        ignore: false,
      },
      {
        key: 'validation',
        ignore: false,
      },
      {
        key: 'layout',
        ignore: false,
      },
    ],
    columns: [
      {
        key: 'display',
        //components: contentComponentsToHide,
      },
      {
        key: 'data',
        ignore: false,
      },
      {
        key: 'api',
        ignore: false,
      },
      {
        key: 'conditional',
        ignore: false,
      },
      {
        key: 'logic',
        ignore: false,
      },
      {
        key: 'validation',
        ignore: false,
      },
      {
        key: 'layout',
        ignore: false,
      },
    ],
    datetime: [
      {
        key: 'display',
        //components: datetimeComponentsToHide,
      },
      {
        key: 'data',
        ignore: false,
      },
      {
        key: 'api',
        ignore: false,
      },
      {
        key: 'conditional',
        ignore: false,
      },
      {
        key: 'logic',
        ignore: false,
      },
      {
        key: 'validation',
        ignore: false,
      },
      {
        key: 'layout',
        ignore: false,
      },
    ],
    checkbox: [
      {
        key: 'display',
        //components: checkboxComponentsToHide,
      },
      {
        key: 'data',
        ignore: false,
      },
      {
        key: 'api',
        ignore: false,
      },
      {
        key: 'conditional',
        ignore: false,
      },
      {
        key: 'logic',
        ignore: false,
      },
      {
        key: 'validation',
        ignore: false,
      },
      {
        key: 'layout',
        ignore: false,
      },
    ],
    selectboxes: [
      {
        key: 'display',
        //components: checkboxComponentsToHide,
      },
      {
        key: 'data',
        ignore: false,
      },
      {
        key: 'api',
        ignore: false,
      },
      {
        key: 'conditional',
        ignore: false,
      },
      {
        key: 'logic',
        ignore: false,
      },
      {
        key: 'validation',
        ignore: false,
      },
      {
        key: 'layout',
        ignore: false,
      },
    ],
    select: [
      {
        key: 'display',
        //components: selectComponentsToHide,
      },
      {
        key: 'data',
        ignore: false,
      },
      {
        key: 'api',
        ignore: false,
      },
      {
        key: 'conditional',
        ignore: false,
      },
      {
        key: 'logic',
        ignore: false,
      },
      {
        key: 'validation',
        ignore: false,
      },
      {
        key: 'layout',
        ignore: false,
      },
    ],
    radio: [
      {
        key: 'display',
        // components: checkboxComponentsToHide,
      },
      {
        key: 'data',
        ignore: false,
      },
      {
        key: 'api',
        ignore: false,
      },
      {
        key: 'conditional',
        ignore: false,
      },
      {
        key: 'logic',
        ignore: false,
      },
      {
        key: 'validation',
        ignore: false,
      },
      {
        key: 'layout',
        ignore: false,
      },
    ],
    signature: [
      {
        key: 'display',
        //components: checkboxComponentsToHide,
      },
      {
        key: 'data',
        ignore: false,
      },
      {
        key: 'api',
        ignore: false,
      },
      {
        key: 'conditional',
        ignore: false,
      },
      {
        key: 'logic',
        ignore: false,
      },
      {
        key: 'validation',
        ignore: false,
      },
      {
        key: 'layout',
        ignore: false,
      },
    ],
  },
};

/* export const builderOptions = {
  builder: {
    data: false,
    premium: false,
    basic: {
      components: {
        password: false,
      },
    },
  },
  editForm: {
    textfield: [
      {
        key: 'display',
        components: componentsToHide,
      },
      {
        key: 'data',
        ignore: true,
      },
      {
        key: 'api',
        ignore: true,
      },
      {
        key: 'conditional',
        ignore: true,
      },
      {
        key: 'logic',
        ignore: true,
      },
      {
        key: 'validation',
        ignore: true,
      },
      {
        key: 'layout',
        ignore: true,
      },
    ],
    //We can do same as above for the textarea component
    textarea: [
      {
        key: 'display',
        components: textAreaComponentsToHide,
      },
      {
        key: 'data',
        ignore: true,
      },
      {
        key: 'api',
        ignore: true,
      },
      {
        key: 'conditional',
        ignore: true,
      },
      {
        key: 'logic',
        ignore: true,
      },
      {
        key: 'validation',
        ignore: true,
      },
      {
        key: 'layout',
        ignore: true,
      },
    ],
    password: {
      key: 'password',
      hidden: true,
    },
  },
}; */
