<div class="container-fluid h-100">
  <div class="row h-100">
    <div class="col-2 p-0 h-100 document-list">
      <app-document-list class="h-100 d-block"></app-document-list>
    </div>
    <div class="col-10 document-details p-0">
      <app-document-details></app-document-details>
    </div>
  </div>
</div>
