import { MembershipService } from './membership-service';
import { MembershipProduct } from './membership-product';
import { BillingFrequency } from './billing-frequency.enum';
import { MembershipClinicTax } from './membership-clinic-tax';

export class Membership {
  constructor(init?: Partial<Membership>) {
    Object.assign(this, init);
  }

  id: number = 0;
  name: string = '';
  description: string = '';
  imageUrl: string = '';
  amount: number = 0;
  billingFrequency = BillingFrequency.Monthly;
  membershipClinicTaxes: MembershipClinicTax[] = [];
  priceIncludesTax: boolean = false;
  expiresAfter: number = 0;
  cancellableAfter: number = 0;
  allowableRedemptions: number = 0;
  percentageAppliedToCredit: number = 0;
  membershipServices: MembershipService[] = [];
  membershipProducts: MembershipProduct[] = [];
  isActive: boolean = false;
}
