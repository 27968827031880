import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';
import { ObservationGroup } from '@models/observation/observation-group';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ObservationGroupService } from '../../../../services/observation-group.service';

@Component({
  selector: 'app-observation-groups-list',
  templateUrl: './observation-groups-list.component.html',
  styleUrls: ['./observation-groups-list.component.less'],
})
export class ObservationGroupsListComponent implements OnInit {
  @Input() height = 'calc(100dvh - 95px)';
  @Output() disableGridEvent = new EventEmitter<boolean>();
  unsub: Subject<void> = new Subject<void>();
  disableGrid = false;
  loading = false;

  observationGroups: ObservationGroup[];
  public gridView: GridDataResult;

  gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  editedRowIndex: number;
  editedDataItem: ObservationGroup;
  clinicId: number;

  constructor(
    private deleteDialog: MatDialog,
    private router: Router,
    private observationGroupService: ObservationGroupService
  ) {}

  ngOnInit() {
    this.gridView = {
      data: [],
      total: 0,
    };
    this.observationGroupService.refreshRequired$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.disableGrid = false;
      this.sendDisableGrid(this.disableGrid);
      if (this.observationGroupService.refreshRequired$) {
        this.refreshData();
      }
    });
    this.refreshData();
  }

  refreshData() {
    this.loading = true;
    this.observationGroupService.getObservationGroups().subscribe((result) => {
      this.observationGroups = result;
      this.loadItems();
      this.loading = false;
    });
  }

  loadItems() {
    this.gridView = {
      data: this.observationGroups,
      total: this.observationGroups.length,
    };
  }

  sendDisableGrid(value: boolean) {
    this.disableGridEvent.emit(value);
  }

  onAddClick() {
    this.disableGrid = true;
    this.sendDisableGrid(this.disableGrid);
    this.router.navigate([
      this.router.routerState.snapshot.url,
      { outlets: { 'action-panel': ['edit-observation-group', '_'] } },
    ]);
  }

  public editHandler({ dataItem }) {
    this.disableGrid = true;
    this.sendDisableGrid(this.disableGrid);
    this.router.navigate([
      this.router.routerState.snapshot.url,
      { outlets: { 'action-panel': ['edit-observation-group', dataItem.id] } },
    ]);
  }

  public removeHandler({ dataItem }) {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'delete') {
          this.observationGroupService
            .deleteObservationGroup(dataItem.id)
            .pipe(takeUntil(this.unsub))
            .subscribe(() => {
              this.observationGroupService.updateRefreshRequired(true);
            });
        }
      });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
