import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from '@app/shared/helpers';
import { ObrPlottingEventType } from '@models/observation/obr-plotting-event-type';
import { Observation, ObservationListItem, ObservationType, ObservationTypes } from '@models/observation/observation';
import { ObservationPlot } from '@models/observation/observation-plot';
import { Service } from '@models/service/service';
import { ServiceDetailEventTypes } from '@models/service/service-detail-event-types';
import { PlannedTreatment } from '@models/treatment-planning/planned-treatment';
import { ObservationListItemsService } from '@services/observation-list-items.service';
import { ObservationTypesService } from '@services/observation-types.service';
import { PatientService } from '@services/patient.service';
import { PlottingEventService } from '@services/plotting-event.service';
import { CoolToneFormService } from '@services/service-detail/cooltone-form.service';
import { ToolbarEventService } from '@services/service-detail/toolbar-event.service';
import { ServiceEventService } from '@services/service-event.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cooltone-toolbar',
  templateUrl: './cooltone-toolbar.component.html',
  styleUrls: ['./cooltone-toolbar.component.less'],
})
export class CooltoneToolbarComponent implements OnInit, OnDestroy {
  toolbarForm: FormGroup;
  unsub: Subject<void>;
  obrType: ObservationType;
  pendingSaveNewObrPlots: ObservationPlot[] = [];
  existingObrPlots: ObservationPlot[] = [];
  pendingDeleteObrPlots: ObservationPlot[] = [];
  treatmentPlanning: boolean;
  disableMiddlePosition = true;
  @Input() associatedPlannedTreatment: PlannedTreatment;
  @Input() service: Service;

  constructor(
    private fb: FormBuilder,
    public coolToneFormService: CoolToneFormService,
    private toolbarEventService: ToolbarEventService,
    private plottingEventService: PlottingEventService,
    private observationTypesService: ObservationTypesService,
    private patientService: PatientService,
    private serviceEventService: ServiceEventService,
    private observationListItemsService: ObservationListItemsService,
    private route: ActivatedRoute
  ) {
    this.unsub = new Subject<void>();

    this.observationTypesService.getObservationTypeByName(ObservationTypes.CoolTone).subscribe((obrType) => {
      this.obrType = obrType;
    });

    // Notify the form service to update the applicator - required for accurate clinic supply inventory count
    this.coolToneFormService.applicatorRefreshRequired.next(true);
  }

  ngOnInit(): void {
    this.initForm();
    this.initFormSubscriptions();
    this.initToolbarSubscription();
    this.initPlottingSubscription();
    this.initServiceEventSubscription();

    this.route.params.subscribe((params) => {
      this.treatmentPlanning = JSON.parse(params.treatmentPlanning);
    });
  }

  private initServiceEventSubscription() {
    this.serviceEventService.treatmentObservationsGroupDeleted
      .pipe(takeUntil(this.unsub))
      .subscribe(() => this.toolbarForm.updateValueAndValidity());
  }

  private initToolbarSubscription() {
    this.toolbarEventService
      .getToolbarControlSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((response) => {
        switch (response.event) {
          case ServiceDetailEventTypes.ResetToolbar: {
            this.initForm();
            break;
          }
          case ServiceDetailEventTypes.TogglePinDrop: {
            this.coolToneFormService.droppingPins = !this.coolToneFormService.droppingPins;
            if (this.coolToneFormService.droppingPins) {
              const formObr = this.toolbarForm.value.observation;
              formObr.details.applicator = this.coolToneFormService.applicator.id;
              formObr.details.plotDetails = { leafletId: null, coords: null };

              const obr = new Observation({
                id: formObr.id,
                typeId: this.obrType.id,
                name: 'CoolTone',
                unitId: null,
                details: formObr.details,
                value: '1',
                patientId: this.patientService.patientPanelPatient.patientId,
                serviceId: response.service.serviceId,
              });

              this.observationListItemsService.assignPriceToObservation(obr);

              this.plottingEventService.startPinDrop(obr);
              this.toolbarEventService.updateToToolbarForm(false);
            } else {
              this.plottingEventService.stopPinDrop();
            }
            break;
          }
          default: {
            break;
          }
        }
      });
  }

  private initPlottingSubscription() {
    this.plottingEventService
      .getPlotSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((response) => {
        response.details.forEach((obr: Observation, leafletId: number) => {
          if (obr.id === 0) {
            if (this.pendingSaveNewObrPlots.findIndex((op) => op.leafletId === leafletId) === -1) {
              this.pendingSaveNewObrPlots.push(new ObservationPlot(leafletId, obr));
            }
          } else {
            if (this.existingObrPlots.findIndex((op) => op.leafletId === leafletId) === -1) {
              this.existingObrPlots.push(new ObservationPlot(leafletId, obr));
            }
          }
        });
      });

    this.plottingEventService
      .getObservationSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((response) => {
        if (response.event === ObrPlottingEventType.DeletePoints) {
          response.leafletIds.forEach((leafletId: number) => {
            const pendingItemIndex = this.pendingSaveNewObrPlots.findIndex((op) => op.leafletId === leafletId);
            if (pendingItemIndex !== -1) {
              this.pendingSaveNewObrPlots.splice(pendingItemIndex, 1);
            }

            const existingItem = this.existingObrPlots.find((op) => op.leafletId === leafletId);
            if (!isNullOrUndefined(existingItem)) {
              this.pendingDeleteObrPlots.push(new ObservationPlot(leafletId, existingItem.observation));
            }
          });
        }
      });
  }

  private initForm() {
    this.toolbarForm = this.fb.group({
      observation: this.fb.group({
        id: [0],
        details: this.fb.group({
          area: [null, Validators.required],
          position: [null, Validators.required],
          plotDetails: [
            {
              coords: null,
              leafletId: null,
            },
          ],
        }),
      }),
    });
  }

  private initFormSubscriptions() {
    this.toolbarForm.valueChanges.subscribe((value) => {
      const areaName = this.coolToneFormService.getAreaFromObs(value.observation)?.name;
      this.disableMiddlePosition = areaName === 'Glutes' || areaName === 'Thighs';
      //Using short-circuit evaluation
      const valid = this.toolbarForm.valid && this.coolToneFormService.pinAvailable(value.observation);
      this.toolbarEventService.updateToToolbarForm(valid || this.toolbarForm.disabled);
    });

    this.toolbarForm.get('observation.details.area').valueChanges.subscribe((value) => {
      const area = this.coolToneFormService.getAreaById(value);
      this.disableMiddlePosition = area.name === 'Glutes' || area.name === 'Thighs';
      const detailsGroup = this.toolbarForm.get('observation.details') as FormGroup;
      if (this.disableMiddlePosition && detailsGroup.get('position').value === 'middle') {
        detailsGroup.get('position').reset();
      }
      if (area.name === 'Thighs') {
        detailsGroup.addControl('location', new FormControl(null, Validators.required));
      } else {
        detailsGroup.removeControl('location');
      }
    });
  }

  getInventoryRemaining(formatString: boolean) {
    return this.observationListItemsService.getInventoryRemainingCoolTone(
      this.coolToneFormService.applicator,
      this.pendingSaveNewObrPlots.concat(this.existingObrPlots),
      this.pendingDeleteObrPlots,
      formatString
    );
  }

  displayLocation(): boolean {
    return this.toolbarForm.get('observation.details.location') !== null;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
