import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { InjectionFormService } from '@services/service-detail/injection-form.service';
import { ServiceEventService } from '@services/service-event.service';
import { CurrentDataService } from '@services/currentData.service';
import { ServicesService } from '@services/services.service';
import { CoolsculptingFormService } from '@services/service-detail/coolsculpting-form.service';
import { Service } from '@models/service/service';
import { ObservationTypes, Observation } from '@models/observation/observation';
import { ServiceListType } from '@models/service/service-list-type';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';
import { FavouriteServicesService } from '../../../services/favourite-services.service';
import { CoolToneFormService } from '@services/service-detail/cooltone-form.service';

@Component({
  selector: 'app-item-detail-display',
  templateUrl: './item-detail-display.component.html',
  styleUrls: ['./item-detail-display.component.less'],
})
export class ItemDetailDisplayComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  service: Service;
  @Input()
  observationType: ObservationTypes;
  @Input()
  isLocked: boolean;
  @Input()
  itemType: ServiceListType;
  serviceListType = ServiceListType;
  treatmentItems: any[];
  unsub = new Subject<any>();

  constructor(
    private injectionFormService: InjectionFormService,
    private dialog: MatDialog,
    private currentDataService: CurrentDataService,
    private coolsculptingFormService: CoolsculptingFormService,
    private coolToneFormService: CoolToneFormService,
    private favouriteServicesService: FavouriteServicesService
  ) {
    this.treatmentItems = [];
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    // If the service or observation type changes, re-init the treatment items
    if (changes.service.previousValue !== changes.service.currentValue || changes.observationType.previousValue !== changes.observationType.currentValue) {
      this.init();
    }
  }

  init() {
    if (this.service?.observations) {
      switch (this.observationType) {
        case ObservationTypes.Injectables:
          // Wait for both the static and non-static lists to be populated before getting the treatment groups
          combineLatest([this.injectionFormService.observationListsPopulated$, this.injectionFormService.observationListsStaticPopulated$]).pipe(takeUntil(this.unsub), take(1)).subscribe({
            next: () => {
              this.treatmentItems = this.injectionFormService.onGetTreatmentGroupsInjections(this.service.observations);
            }
          });
          break;

        case ObservationTypes.Coolsculpting:
          combineLatest([this.coolsculptingFormService.observationListsPopulated$, this.coolsculptingFormService.observationListsStaticPopulated$]).pipe(takeUntil(this.unsub), take(1)).subscribe({
            next: () => {
              this.treatmentItems = this.coolsculptingFormService.onGetTreatmentGroupsCoolsculpting(this.service.observations);
            }
          });
          break;

        case ObservationTypes.CoolTone:
          combineLatest([this.coolToneFormService.observationListsPopulated$, this.coolToneFormService.observationListsStaticPopulated$]).pipe(takeUntil(this.unsub), take(1)).subscribe({
            next: () => {
              this.treatmentItems = this.coolToneFormService.getTreatmentsAsText(this.service.observations);
            }
          });
          break;
        default:
          break;
      }
    }
  }

  onDeleteTreatment() {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'delete') {
          this.favouriteServicesService.deleteFavouriteService(this.service.serviceId).subscribe(() => {
            this.currentDataService.favouritesDataHasBeenUpdated(null);
          });
        }
      });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
