<div class="edit-container position-absolute d-block z-index-3 white-background">
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0">
    <div class="row teal-background">
      <div class="col">
        <h6 class="white-font uppercase p-2 mb-0">{{ addOrEdit }} Cancellation Reason</h6>
      </div>
    </div>
    <div class="row mx-0 mt-2">
      <div class="col px-2">
        <mat-form-field class="w-100">
          <input
            matInput
            placeholder="Cancellation Reason"
            class="capitalized"
            aria-label="Cancellation Reason"
            (change)="formatterService.textCapitalize(name)"
            [(ngModel)]="editedCancellationReason.name"
            [formControl]="name"
            required
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0 mt-2">
      <div class="col px-2 text-center">
        <button
          class="btn em-btn em-btn-green"
          (click)="updateCancellationReason()"
          [disabled]="editedCancellationReason?.name === ''"
        >
          {{ addOrEdit == 'Add' ? 'Add' : 'Update' }}
        </button>
      </div>
      <div class="col px-2 text-center">
        <button class="btn em-btn em-btn-green" (click)="cancelUpdate()">Cancel</button>
      </div>
    </div>
  </perfect-scrollbar>
</div>
