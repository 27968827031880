export class ChargeableAppointment {
  isChargeable: boolean = false;
  chargeableAmount: number = 0;
  cardBrand: string = '';
  cardMaskedDigits: string = '';
  cardExpiry: string = '';

  constructor(init?: Partial<ChargeableAppointment>) {
    Object.assign(this, init);
  }
}
