import { Component, OnInit, OnDestroy, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormioAppConfig, FormioComponent } from 'angular-formio-emilyemr';
import { takeUntil, switchMap } from 'rxjs/operators';
import { Subject, EMPTY, Observable, timer, Subscriber, Subscription } from 'rxjs';
import { ClinicDocument } from '@models/document/clinic-document';
import { PatientDocumentService } from '@services/patient-documents.service';
import { PatientDocument } from '@models/document/patient-document';

@Component({
  selector: 'app-eform-viewer',
  templateUrl: './eform-viewer.component.html',
  styleUrls: ['./eform-viewer.component.less']
})
export class EformViewerComponent implements OnInit, OnDestroy {
  @ViewChild('formView') formView: FormioComponent;
  @Input() docId: number;
  @Input() editable = true;
  @Input() modalView = false;
  @Input() formDefinition: Object;
  @Input() formData: string;
  public form: any;
  patientId: number;
  clinicDoc: PatientDocument;
  unsub: Subject<void> = new Subject<void>();

  public submission: any = {};
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  @Output() changed: EventEmitter<any> = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private documentService: PatientDocumentService
  ) { }

  ngOnInit() {
    if (Object.keys(this.route.snapshot.params).length > 0) {
      this.patientId = +this.route.snapshot.params.patId.split('_')[0];
    }

    if (this.docId && (this.patientId || this.modalView)) {
      this.documentService.getPatientClinicDocument(this.docId)
        .subscribe((document) => {
          this.clinicDoc = document;
          this.initForm();
        });
    }
    this.documentService.documentSelected.asObservable()
      .pipe(takeUntil(this.unsub))
      .subscribe((document) => {
        setTimeout(() => {
          this.clinicDoc = document;
          this.initForm();
        });
      });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  private initForm() {
    if (this.formDefinition) {
      this.form = this.formDefinition;
    } else if (this.clinicDoc.eFormDefinition) {
      this.form = JSON.parse(this.clinicDoc.eFormDefinition);
      this.form.settings.pdf.src = this.clinicDoc.pdfToHtmlUrl;
    }
    if (this.formData) {
      this.submission = {data: this.formData };
    }
  }

  submit(data) {
    this.formView.submit.next(data);
  }

  reload() {
    this.initForm();
  }

  onSubmit(value: any) {
    this.submitted.next(value);
  }


  //this is a temporary workaround for loading spinner on large formio documents disappearing seconds before the document renders
  //all the formio events are triggering too early
  //see dom-change-listener directive for details

  lastMutation: number = null;
  timeoutSubscriber: Subscription = null;
  mutationSubscriber: Subscription = null;
  onFormChange(value: MutationEvent) {
    if (!this.lastMutation){
      this.timeoutSubscriber = timer(6000).subscribe(() => {
        this.timeoutSubscriber.unsubscribe();
        this.mutationSubscriber.unsubscribe();
        this.timeoutSubscriber = null;
        this.mutationSubscriber = null;
        this.lastMutation = null;
        this.changed.next(value);
      })
    }

    let lastMutation =  Date.now();
    this.lastMutation = lastMutation;

    this.mutationSubscriber = timer(1000).subscribe(() => {
      if (lastMutation === this.lastMutation) {
        this.timeoutSubscriber.unsubscribe();
        this.mutationSubscriber.unsubscribe();
        this.timeoutSubscriber = null;
        this.mutationSubscriber = null;
        this.lastMutation = null;
        this.changed.next(value);
      }
    });
  }
}
