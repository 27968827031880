import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CreateFormGroupArgs } from "@progress/kendo-angular-grid";

@Component({
  selector: 'app-gift-cards-settings',
  templateUrl: './gift-cards-settings.component.html',
  styleUrls: ['./gift-cards-settings.component.less']
})
export class GiftCardsSettingsComponent implements OnInit, OnDestroy {

  unsub: Subject<void> = new Subject<void>();
  loading = false;
  gridView: GridDataResult;
  formGroup : FormGroup;

  amounts = [
    {amount: 100},
    {amount: 200},
    {amount: 400},
    {amount: 800},
    {amount: 1000}
  ]

  constructor() { }

  ngOnInit(): void {
    this.gridView = {
      data: [
        {amount: 100},
        {amount: 200},
        {amount: 400},
        {amount: 800},
        {amount: 1000}
      ],
      total: 5
    };
  }

  public createAmountFormGroup = (args: CreateFormGroupArgs) : FormGroup  => {
    const item = args.isNew ? null : args.dataItem;
    this.formGroup = new FormGroup({
      amount: new FormControl(item, Validators.required)
    });
    return this.formGroup;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

}
