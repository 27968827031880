import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { DataSourceRequestState, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ReportsService } from '@services/reports.service';
import { IReportComponent } from '@models/reports/ireport-component';
import { takeUntil, map } from 'rxjs/operators';
import { CurrencyPipe } from '@angular/common';
import { ProviderReport } from '@models/reports/provider/provider-report';

@Component({
  selector: 'app-provider-reports',
  templateUrl: './provider-reports.component.html',
  styleUrls: ['./provider-reports.component.less']
})
export class ProviderReportsComponent implements OnInit, OnDestroy, IReportComponent {
  @ViewChild('grid') grid;
  gridView: GridDataResult;
  gridState: DataSourceRequestState;
  loading: boolean = false;
  unsub: Subject<void> = new Subject<void>();

  constructor(
    private reportsService: ReportsService,
    private currencyPipe: CurrencyPipe
  ) { }

  ngOnInit() {
    this.initDefaultValue();
    this.getReportData();
    this.allData = this.allData.bind(this);
  }

  initDefaultValue() {
    this.gridState = {
      skip: 0,
      take: 100,
      // set default state of sorting icon
      sort: [{field: 'providerName', dir: 'asc'}]
    };

    this.gridView = {
      data: [],
      total: 0
    };
  }

  getReportData() {
    this.loading = true;

    this.reportsService.getProvidersReportEntities(this.gridState)
      .pipe(takeUntil(this.unsub))
      .subscribe((providerReports: any) => {
        this.gridState.sort = [{field: 'providerName', dir: 'asc'}];
        this.gridView = {
          data: providerReports.data,
          total: providerReports.total || providerReports.data.length
        };
        this.loading = false;
    });
  }

  exportToExcel() {
    this.grid.saveAsExcel();
  }

  onExcelExport(e: any) {
    this.reportsService.onExcelExport(e, 'Providers Report');
  }

  pageChange(event: PageChangeEvent) {
    this.gridState.skip = event.skip;
    this.getReportData();
  }

  sortChange(sort: SortDescriptor[]) {
    this.gridState.sort = sort;
    this.gridView.data = orderBy(this.gridView.data, this.gridState.sort);
  }

  sortIconClass(column: any) {
    return this.gridState.sort.length && (this.gridState.sort[0].field === column.field && this.gridState.sort[0].dir === 'asc') ? 'fad fa-sort-up'
           : this.gridState.sort.length && (this.gridState.sort[0].field === column.field && this.gridState.sort[0].dir === 'desc') ? 'fad fa-sort-down'
           : 'fas fa-sort';
  }

  allData = (): Observable<any> => {
    const gridState: DataSourceRequestState = {};
    return this.reportsService.getProvidersReportEntities(gridState)
            .pipe(map((providerReports: any) => {
              return {
                data: providerReports.data.map((item: ProviderReport) => ({
                  ...item,
                  totalSales: this.currencyPipe.transform(item.totalSales),
                  serviceAmount: this.currencyPipe.transform(item.serviceAmount),
                  productAmount: this.currencyPipe.transform(item.productAmount),
                  returnAmount: this.currencyPipe.transform(item.returnAmount)
                })),
                total: providerReports.total,
              };
            }));
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
