import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HoursOfOperation, BusinessWeek, HoursOfOperationType } from '@models/hoursofoperation';
import { HoursOfOperationService, getBusinessWeek, getHoursOfOperationDays } from '@services/hoursofoperation.service';
import { ObservationListItem } from '@models/observation/observation';

@Component({
  selector: 'clinic-supplies-selector-modal',
  templateUrl: './clinic-supplies-selector-modal.component.html',
  styleUrls: ['./clinic-supplies-selector-modal.component.less']
})
export class ClinicSuppliesSelectorModalComponent implements OnInit {
  public dialog: MatDialog;


  clinicSupplies:ObservationListItem[] =  Array<ObservationListItem>();
  selectedClinicSupplies:ObservationListItem[] =  Array<ObservationListItem>();


  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ClinicSuppliesSelectorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { clinicSupplies, selectedClinicSupplies},

  ) {

  }

  isSelected(item: ObservationListItem){
    return item && this.selectedClinicSupplies.findIndex(obs => obs.id == item.id) != -1;
  }

  ngOnInit() {
    this.clinicSupplies = this.data.clinicSupplies;
    this.selectedClinicSupplies = this.data.selectedClinicSupplies;
  }

  selectedCheck(checkIndex: number){
    let selectedObservatinList = this.clinicSupplies[checkIndex];
    let index = this.selectedClinicSupplies.findIndex(supply => supply.id == selectedObservatinList.id);

    if (index > -1){
      this.selectedClinicSupplies.splice(index,1);
    }
    else{
      this.selectedClinicSupplies.push(selectedObservatinList);
    }
  }

  save() {
    this.dialogRef.close(this.selectedClinicSupplies);
      return;
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
