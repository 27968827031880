import { CampaignRunRecipient } from './../../../../models/retention-campaigns/campaign-run-recipient';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RetentionCampaignsService } from '@services/retention-campaigns.service';
import { CampaignPreviewRecipient } from '@models/retention-campaigns/campaign-preview-recipient';

@Component({
  selector: 'app-preview-campaign-recipients',
  templateUrl: './preview-campaign-recipients.component.html',
  styleUrls: ['./preview-campaign-recipients.component.less']
})
export class PreviewCampaignRecipientsComponent implements OnInit, OnDestroy {
  private unsub: Subject<void> = new Subject<void>();
  loading = false;
  recipients: CampaignPreviewRecipient[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private retentionCampaignsService: RetentionCampaignsService) { }

  ngOnInit() {
    this.route.params
      .pipe(takeUntil(this.unsub))
      .subscribe(params => {
        const id = +params['id'];
        this.retentionCampaignsService.getRecipientsForCampaignPreview(id).subscribe((result) => {
          this.recipients = result;
        });
      });
  }

  cancel() {
    this.router.navigate(['/management/communication/retention-campaigns/', { outlets: { 'action-panel': null } }]);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
