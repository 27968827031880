import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isNullOrUndefined } from '@app/shared/helpers';
import { ServiceProvider } from '@models/service-provider';

export interface SelectProviderDialogData {
  serviceProviders: ServiceProvider[];
  isGovernmentBilling: boolean;
  serviceTemplateId: number;
}

@Component({
  selector: 'app-select-provider',
  templateUrl: './select-provider.component.html',
  styleUrls: [],
})
export class SelectProviderDialogComponent implements OnInit {
  public dialog: MatDialog;
  serviceProviders: ServiceProvider[] = [];
  serviceTemplateId: number = null;
  isMedicalPlanBilling = false;

  constructor(
    public dialogRef: MatDialogRef<SelectProviderDialogComponent, ServiceProvider>,
    @Inject(MAT_DIALOG_DATA) public data: SelectProviderDialogData
  ) {}

  ngOnInit() {
    this.serviceProviders = this.data.serviceProviders;
    this.isMedicalPlanBilling = this.data.isGovernmentBilling;
    this.serviceTemplateId = this.data.serviceTemplateId;
    this.serviceProviders.forEach((sp) => {
      sp.working = true;
    });
  }

  providerSelected(provider: ServiceProvider) {
    this.dialogRef.close(provider);
  }

  ngOnDestroy() {
    this.serviceProviders = [];
    this.serviceTemplateId = null;
  }
}
