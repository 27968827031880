<div class="sidenav-and-viewer-container h-100">
  <div class="h-100 wizard-container container-fluid p-0">
    <ngx-loading [show]="loading"></ngx-loading>
    <div class="container-fluid container-xl">
      <div class="main grid-container">
        <div class="p-2 fix-width grid-item custom-bg">
          <div class="view-mobile-center">
            <div class="row h-100">
              <div class="col">
                <div class="card card-body bg-light">
                  <div *ngIf="errors?.length > 0" class="errors-wrapper">
                    <ng-container *ngFor="let error of errors">
                      <app-error *ngFor="let fieldError of error.fieldErrors" [message]="fieldError"></app-error>
                    </ng-container>
                  </div>
                  <formio
                    [form]="formObj"
                    [submission]="submissionObj"
                    [readOnly]="false"
                    (change)="onFormChange($event)"
                    (submit)="onSubmit($event)"
                    #formio
                  >
                  </formio>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
