import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceTemplateUserCommission } from '@models/service/service-template-user-commission';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserCommissionsService {
  constructor(private http: HttpClient) {}

  getCommissions(userId: string): Observable<ServiceTemplateUserCommission[]> {
    return this.http.get<ServiceTemplateUserCommission[]>(`${environment.baseUrl}api/Commissions/${userId}`)
  }

  addCommission(commission: ServiceTemplateUserCommission) : Observable<ServiceTemplateUserCommission> {
    return this.http.post<ServiceTemplateUserCommission>(`${environment.baseUrl}api/Commissions`, commission);
  }

  updateCommission(commission: ServiceTemplateUserCommission) : Observable<any> {
    return this.http.put(`${environment.baseUrl}api/Commissions`, commission)
  }

  deleteCommission(id: number) : Observable<unknown> {
    return this.http.delete(`${environment.baseUrl}api/Commissions/${id}`)
  }
}
