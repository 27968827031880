import { ConfirmFormDeleteComponent } from './confirm-form-delete/confirm-form-delete.component';
import { FormLibraryOverviewComponent } from './../form-library-overview/form-library-overview.component';
import { ShareFormComponent } from './../share-form/share-form.component';
import { FormType } from '@models/forms/form-type';
import { FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  GridDataResult,
  GridComponent,
  EditEvent,
  CancelEvent,
  RemoveEvent,
  RowClassArgs,
} from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';
import { FormService } from '@services/form.service';
import { FormTypeService } from '@services/form-type.service';
import { Form } from '@models/forms/form';
import { FormBuilderComponent as FormioBuilderComponent } from '@formio/angular';
import { DropDownTreeHierarchyBindingDirective } from '@progress/kendo-angular-dropdowns';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionPanelService } from '@services/actionpanel.service';
import { SharedFormService } from '@services/shared-form.service';
@Component({
  selector: 'app-forms-list',
  templateUrl: './forms-list.component.html',
  styleUrls: ['./forms-list.component.less'],
})
export class FormsListComponent implements OnInit {
  @ViewChild(GridComponent) private grid: GridComponent;
  @ViewChild('builder') builder: FormioBuilderComponent;

  searchValue = '';
  searchCtrl: FormControl;
  filteredForms: Observable<Form[]>;

  formTypes: FormType[] = [];

  unsub: Subject<void> = new Subject<void>();

  disableTabsAndGrid = false;
  loading = false;

  forms: Form[];
  public gridView: GridDataResult;

  gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  selectedRowIndex: number;
  selectedDataItem: Form = new Form();
  editingDataItem: Form = new Form();

  activeTab: any;

  formSelected: boolean = false;
  editMode: boolean = false;

  public selectedKeys: number[] = [];

  public formRefreshed$: Subject<Form> = new Subject<Form>();

  constructor(
    private formService: FormService,
    private formTypeService: FormTypeService,
    private dialog: MatDialog,
    private router: Router,
    private modalService: NgbModal,
    private actionPanelService: ActionPanelService,
    private sharedFormService: SharedFormService
  ) {}

  ngOnInit() {
    this.loading = false;
    this.disableTabsAndGrid = false;

    this.getFormTypes();

    this.searchCtrl = new FormControl();

    this.initFormAddedSubscription();
    this.initFormUpdatedSubscription();
    this.initFormChangeCancelledSubscription();
    this.initFormDeactivatedSubscription();
    this.initFormActivatedSubscription();
    this.initFormDeletedSubscription();
    this.initFormTemplateLibraryPanelSubscription();
    this.initFormAddedFromTemplateLibrarySubscription();
  }

  getFormTypes() {
    this.formTypes = [];
    this.formTypeService.getFormTypes().subscribe((res) => {
      this.formTypes = res;
      if (this.formTypes.length > 0) {
        this.activeTab = this.formTypes[0];
        this.selectTab(this.formTypes[0]);
      }
    });
  }

  selectFormInTab(form: Form) {
    if (!form.formType) form.formType = this.formTypes.find((p) => p.id === form.formTypeId);
    this.selectTab(form.formType);
    this.selectRow(form);
  }

  selectTab(formType: FormType) {
    // Clear out selected Form
    this.clearFormSelection();
    this.loadForms(formType);
    this.activeTab = formType;
  }

  clearFormSelection() {
    this.formSelected = false;
    this.editMode = false;
    this.selectedKeys = [];
    this.selectedDataItem = null;
  }

  loadForms(formType?: FormType | null) {
    this.loading = true;

    this.formService.getForms(formType).subscribe((forms) => {
      this.forms = forms;

      this.gridView = {
        data: forms.slice(),
        total: forms.length,
      };

      this.filteredForms = this.searchCtrl.valueChanges.pipe(
        startWith(''),
        map((form) => this.filterForms(form))
      );

      if (!this.isTemplateLibraryOpen()) {
        this.loading = false;
      }
    });
  }

  isTemplateLibraryOpen(): boolean {
    return this.router.url.indexOf('(action-panel-secondary:form-library-overview)') !== -1 ? true : false;
  }

  initFormAddedSubscription() {
    this.formService.formAdded.pipe(takeUntil(this.unsub)).subscribe((form: Form) => {
      this.selectFormInTab(form);
    });
  }

  initFormUpdatedSubscription() {
    this.formService.formUpdated.pipe(takeUntil(this.unsub)).subscribe((form: Form) => {
      this.selectFormInTab(form);
    });
  }

  initFormChangeCancelledSubscription() {
    this.formService.formChangeCancelled.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.loading = false;
      this.disableTabsAndGrid = false;
    });
  }

  initFormDeactivatedSubscription() {
    this.formService.formDeactivated.pipe(takeUntil(this.unsub)).subscribe(() => {
      if (this.selectedDataItem && this.selectedDataItem.formTypeId) {
        const formType = this.formTypes.find((p) => p.id === this.selectedDataItem.formTypeId);
        this.loadForms(formType);
      }
    });
  }

  initFormActivatedSubscription() {
    this.formService.formActivated.pipe(takeUntil(this.unsub)).subscribe(() => {
      if (this.selectedDataItem && this.selectedDataItem.formTypeId) {
        const formType = this.formTypes.find((p) => p.id === this.selectedDataItem.formTypeId);
        this.loadForms(formType);
      }
    });
  }

  initFormDeletedSubscription() {
    this.formService.formDeleted.pipe(takeUntil(this.unsub)).subscribe(() => {
      if (this.selectedDataItem && this.selectedDataItem.formTypeId) {
        const formType = this.formTypes.find((p) => p.id === this.selectedDataItem.formTypeId);
        this.loadForms(formType);
      }
    });
  }

  initFormTemplateLibraryPanelSubscription() {
    this.actionPanelService.actionPanelClosed$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.loading = false;
      this.disableTabsAndGrid = false;
    });
  }

  initFormAddedFromTemplateLibrarySubscription() {
    this.sharedFormService.sharedFormAddedFromLibrary.pipe(takeUntil(this.unsub)).subscribe((form: Form) => {
      if (form.formTypeId) {
        const formType = this.formTypes.find((p) => p.id === form.formTypeId);
        if (formType === this.activeTab) this.loadForms(formType);
      }
    });
  }

  public onSelectRow(e) {
    this.editMode = false;
    if (e.selectedRows.length > 0) {
      this.selectRow(e.selectedRows[0].dataItem);
    }
  }

  private selectRow(dataItem: Form) {
    this.selectedDataItem = dataItem;
    this.formRefreshed$.next(this.selectedDataItem);
    if (this.selectedKeys.length > 0) {
      this.selectedKeys = [];
    }
    this.selectedKeys.push(this.selectedDataItem.id);
    this.formSelected = true;
  }

  public editHandler(args: EditEvent) {
    this.editMode = false;
    this.selectRow(args.dataItem);
    this.router.navigate(['/management/forms/list', { outlets: { 'action-panel': ['edit-form', args.dataItem.id] } }]);
  }

  public editFormHandler(dataItem) {
    this.disableTabsAndGrid = true;
    this.editMode = true;
    Object.assign(this.editingDataItem, dataItem);
    this.selectRow(dataItem);
  }

  public addHandler() {
    this.router.navigate([
      '/management/forms/list',
      {
        outlets: {
          'action-panel': ['edit-form', this.formService.defaultId],
        },
      },
    ]);
  }

  public saveHandler(): void {
    const form: Form = this.editingDataItem;

    this.formService
      .updateForm(form)
      .pipe(takeUntil(this.unsub))
      .subscribe((updatedForm) => {
        this.selectedDataItem = updatedForm;
        this.editMode = false;
        this.disableTabsAndGrid = false;
      });
  }

  public cancelHandler() {
    this.editMode = false;
    this.disableTabsAndGrid = false;
  }

  cloneFormHandler(formId: number) {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '325px',
      data: {
        title: 'Clone eForm?',
        content: 'Are you sure you want to clone this eForm?',
        confirmButtonText: 'Clone',
        showCancel: true,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') {
          this.loading = true;
          this.formService.cloneForm(formId).subscribe((newForm: Form) => {});
        }
      });
  }

  public deactivateHandler(args: RemoveEvent): void {
    if (this.selectedKeys.length > 0) {
      this.selectedKeys = [];
    }
    this.selectedKeys.push(args.dataItem.id);
    this.editMode = false;
    this.selectRow(args.dataItem);

    const dialogRef = this.dialog.open(ConfirmFormDeleteComponent, {
      width: '400px',
      data: {},
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'deactivate') {
          args.dataItem.isDeleted = true;
          this.formService.deactivateForm(args.dataItem).subscribe();
        } else if (result === 'delete') {
          this.formService.deleteForm(args.dataItem).subscribe();
        }
      });
  }

  activateFormHandler(form: Form) {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '325px',
      data: {
        title: 'Activate eForm?',
        content: 'Are you sure you want to activate this eForm?',
        confirmButtonText: 'Activate',
        showCancel: true,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') {
          form.isDeleted = false;
          this.formService.activateForm(form).subscribe(() => {});
        }
      });
  }

  shareFormHandler(form: Form) {
    let shareFormModal = this.modalService.open(ShareFormComponent, {
      centered: true,
    });
    shareFormModal.componentInstance.formToShare = form;
  }

  addFromTemplateHandler() {
    this.loading = true;
    this.router.navigate([
      '/management/forms/list',
      { outlets: { 'action-panel-secondary': ['form-library-overview'] } },
    ]);
  }

  viewForm(formId: number) {
    this.router.navigate(['/management/forms/form-viewer/' + formId]);
  }

  filterForms(name: string) {
    let filterResults: Form[] = [];

    if (name !== '') {
      this.gridView = {
        data: this.forms.filter((form) => form.name.toLowerCase().includes(name.toLowerCase())),
        total: this.forms.filter((form) => form.name.toLowerCase().includes(name.toLowerCase())).length,
      };
      filterResults = this.forms.filter((form) => form.name.toLowerCase().includes(name.toLowerCase()));
    } else {
      this.gridView = {
        data: this.forms,
        total: this.forms.length,
      };
      filterResults = [];
    }
    return filterResults;
  }

  public rowCallback = (context: RowClassArgs) => {
    if (context.dataItem.isDeleted) return { disabled: true };
    else return {};
  };

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
