<ngx-loading [show]="loading"></ngx-loading>
<ng-scrollbar class="h-100">
  <div class="header-line"></div>
  <div class="d-flex justify-content-between mt-2">
    <div *ngFor="let gridView of gridViews; let i = index" class="grid-wrapper">
      <div class="d-flex align-items-center justify-content-between teal-background white-font total">
        <span class="mr-auto">{{ gridTotals[i].title }}: </span> {{ gridTotals[i].total | currency }}
      </div>
      <kendo-grid
        class="emr-kendo-grid"
        [data]="gridView"
        [style.maxHeight.%]="100"
        (excelExport)="onExcelExport($event, i)"
        #grid="kendoGrid"
      >
        <kendo-grid-column field="summary" [title]="gridHeaders[i]"></kendo-grid-column>
        <kendo-grid-column field="total" title="Total" [width]="150" headerClass="text-right"></kendo-grid-column>
        <kendo-grid-excel [fileName]="getFileName(false, i)"></kendo-grid-excel>
        <ng-template kendoGridDetailTemplate let-dataItem *ngIf="gridHeaders[i] == 'Payment Type Summary'">
          <app-payment-type-details [paymentTypeId]="dataItem.paymentTypeId"></app-payment-type-details>
        </ng-template>
        <ng-template kendoGridDetailTemplate let-dataItem *ngIf="gridHeaders[i] == 'Sales Summary'">
          <app-sales-summary-details [summaryTitle]="dataItem.summary"></app-sales-summary-details>
        </ng-template>
        <ng-template kendoGridDetailTemplate let-dataItem *ngIf="gridHeaders[i] == 'Category Sales Summary'">
          <app-category-sales-details [summaryTitle]="dataItem.summary"></app-category-sales-details>
        </ng-template>
        <ng-template
          kendoGridDetailTemplate
          let-dataItem
          *ngIf="gridHeaders[i] == 'Tax Summary'"
          class="payment-type-details-area"
        >
          <app-clinic-tax-details [summaryTitle]="dataItem.summary"></app-clinic-tax-details>
        </ng-template>
      </kendo-grid>
    </div>
  </div>
</ng-scrollbar>
