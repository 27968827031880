<div class="px-3" [ngStyle]="{ height: panelHeight ? panelHeight + 'px' : '100%' }">
  <div class="d-flex align-items-center w-100" style="height: 48px">
    <div class="column-header py-0 mr-2">Product Purchase History</div>
  </div>

  <ng-scrollbar style="height: calc(100% - 48px)">
    <ng-container *ngIf="productsPurchased?.length > 0; else noProductsPurchased">
      <div
        *ngFor="let product of productsPurchased; let last = last"
        [ngClass]="{ 'pb-1': last }"
        class="d-flex p-0 justify-content-between mb-2"
      >
        <div class="d-flex panel-box ng-star-inserted justify-content-between p-2">
          <div
            class="panel-box-column panel-box-column--main d-flex flex-column align-self-center"
            [ngStyle]="{
              color: product?.daysToExpiry ? getColourFromDaysToExpiry(product.daysToExpiry) : 'inherit'
            }"
          >
            <div
              class="text-truncate d-block"
              kendoTooltip
              tooltipClass="em-tooltip"
              position="top"
              title="{{ product?.name }}"
            >
              {{ product?.name }}
            </div>
            <div
              class="date"
              [ngStyle]="{
                color: product?.daysToExpiry ? getColourFromDaysToExpiry(product.daysToExpiry) : 'inherit'
              }"
            >
              <span class="font-weight-normal">{{ (product?.purchaseDate | date : 'yyyy-MM-dd') + ' | ' }}</span>
              <span class="font-weight-normal">{{
                (getMonthsFromPurchase(product?.purchaseDate) | number : '1.1-1') + 'mo'
              }}</span>
            </div>
          </div>
          <div class="panel-box-column align-self-center">
            <span
              class="text-lowercase font-weight-normal d-block w-100"
              [ngStyle]="{
                color: product?.daysToExpiry ? getColourFromDaysToExpiry(product.daysToExpiry) : 'inherit'
              }"
              >x {{ product?.quantity }}</span
            >
          </div>
          <div
            class="panel-box-column align-self-center"
            [ngStyle]="{
              color: product?.daysToExpiry ? getColourFromDaysToExpiry(product.daysToExpiry) : 'inherit'
            }"
          >
            {{ product?.pricePaid | currency }}
          </div>
        </div>

        <button
          *ngIf="product && isInvoice"
          kendoTooltip
          tooltipClass="em-tooltip"
          position="top"
          type="button"
          class="panel-box-button h-auto em-btn-green"
          (click)="selectProduct.next(product)"
        >
          <i class="far fa-plus" title="Add to Invoice"></i>
        </button>
      </div>
    </ng-container>
  </ng-scrollbar>

  <ng-template #noProductsPurchased> The patient hasn't purchased products yet </ng-template>
</div>
