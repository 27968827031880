import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ClinicEmailTemplate, EmailTemplateType } from '@models/clinic-email-template';

@Injectable()
export class ClinicEmailTemplateService {
  constructor(private http: HttpClient) {}

  getClinicEmailTemplates() {
    return this.http.get<ClinicEmailTemplate[]>(environment.baseUrl + 'api/ClinicEmailTemplates');
  }

  getClinicEmailTemplatesByType(type: EmailTemplateType) {
    return this.http.get<ClinicEmailTemplate[]>(environment.baseUrl + 'api/ClinicEmailTemplates?type=' + type);
  }

  addClinicEmailTemplate(template: ClinicEmailTemplate) {
    return this.http.post<ClinicEmailTemplate>(environment.baseUrl + 'api/ClinicEmailTemplates', template);
  }

  updateClinicEmailTemplate(template: ClinicEmailTemplate) {
    return this.http.put<ClinicEmailTemplate>(environment.baseUrl + 'api/ClinicEmailTemplates', template);
  }

  deleteClinicEmailTemplate(templateId: number) {
    return this.http.delete<ClinicEmailTemplate>(environment.baseUrl + 'api/ClinicEmailTemplates/' + templateId);
  }
}
