import { Injectable } from '@angular/core';
import { Patient } from '@models/patient';
import { Address } from '@models/address';
import * as moment from 'moment';
import { toTitleCase } from '../shared/helpers';

@Injectable({
  providedIn: 'root'
})
export class MspCardSwipeService {
  private END_CHAR = '^';

  constructor() { }

  processCardSwipe(swipeValue: string): Patient {
    // if the first three characters are %BC it's a combo card, otherwise it's a standalone card
    if (swipeValue.substring(0, 3) === '%BC') {
      return this.comboCardSwipe(swipeValue);
    } else {
      return this.standAloneCardSwipe(swipeValue);
    }
  }

  private comboCardSwipe(swipeValue: string): Patient {
    let index = 3;
    const swipeLines = swipeValue.split('?');

    // City is max 13 characters, if less that 13 terminated by a ^
    let city = toTitleCase(swipeLines[0].substr(index, 13));
    let terminateIndex = city.indexOf(this.END_CHAR);
    if (terminateIndex > 0) {
      city = city.substring(0, terminateIndex);
      index += 1;
    }
    index += city.length;
    terminateIndex = -1;

    // Name fields are a max of 35 characters, if less terminated by a ^
    const names = swipeLines[0].substr(index, index + 35);

    // last name is terminated by a ,$
    const lastName = toTitleCase(names.substring(0, names.indexOf(',')));
    let firstName = toTitleCase(names.substring(lastName.length + 2));

    terminateIndex = firstName.indexOf(this.END_CHAR);
    if (terminateIndex > 0) {
      firstName = firstName.substring(0, terminateIndex);
      index += 1;
    }
    index += lastName.length + 2 + firstName.length;
    terminateIndex = -1;

    // Addresses are multiple lines, all address lines terminated by a ?, total of all address lines is 29 characters
    const addresses = swipeLines[0].substr(index);
    const addressLines = addresses.split('$');

    const addressLineOne = toTitleCase(addressLines[0]);
    let addressLineTwo = '';

    if (addressLines.length === 3) {
      // There is a second address for the home
      addressLineTwo = toTitleCase(addressLines[1]);
    }

    // Province must be BC because this is MSP card swipte
    const province = 'British Columbia';
    const country = 'Canada';

    // --- START LINE 2 ---
    // Index of MSP info + the 4 digit expiry date
    index = swipeLines[1].indexOf('=') + 1 + 4;

    const birthDate = swipeLines[1].substr(index, 8);

    // --- START LINE 3 ---
    // Unused characters at the beginning of the line
    index = 3;
    let postalCode = swipeLines[2].substr(index, 6);
    // Add a spect
    postalCode = `${postalCode.substr(0, 3)} ${postalCode.substr(3, 5)}`;

    // Move the index forward to the gauranteed position of the gender
    index = 30;
    let gender = swipeLines[2].substr(index, 1);
    if (gender === 'M') {
      gender = 'Male';
    } else if (gender === 'F') {
      gender = 'Female';
    } else {
      gender = 'Unknown';
    }

    // Move the index forward to the gauranteed position of PHN
    index = 43;
    const phn = swipeLines[2].substr(index, 10);

    const patient = new Patient({
      clientId: phn,
      firstName: firstName,
      lastName: lastName,
      birthDate: moment(birthDate, 'YYYYMMDD').toDate(),
      gender: gender,
      address: new Address({
        address1: addressLineOne,
        address2: addressLineTwo,
        city: city,
        country: country,
        postalCode: postalCode,
        province: province
      })
    });

    return patient;
  }

  private standAloneCardSwipe(swipeValue: string): Patient {
    const phn = swipeValue.substr(8, 10);

    // Names are 26 characters
    // If names < 26 characters it is padded with blank spaces
    // If names are > 26 characters they are truncated
    const names = swipeValue.substring(20, 46);
    const lastName = toTitleCase(names.substring(0, names.indexOf('/')));
    let firstName = names.substring(names.indexOf('/') + 1);
    firstName = toTitleCase(firstName.substring(0, firstName.indexOf(' ')));

    const birthDate = swipeValue.substr(55, 8);

    const patient = new Patient({
      clientId: phn,
      firstName: firstName,
      lastName: lastName,
      birthDate: moment(birthDate, 'YYYYMMDD').toDate(),
      address: new Address()
    });
    return patient;
  }

  fillInfoFromSwipe(patient: Patient, patientData?: Partial<Patient>){
    patient.address = patientData.address ? patientData.address : patient.address;
    patient.clientId = patientData.clientId ? patientData.clientId : patient.clientId;
    patient.firstName = patientData.firstName ? patientData.firstName : patient.firstName;
    patient.lastName = patientData.lastName ? patientData.lastName : patient.lastName;
    patient.birthDate = patientData.birthDate ? patientData.birthDate : patient.birthDate;
    patient.gender = patientData.gender ? patientData.gender : patient.gender;
    return patient;
  }

  //written by Mitchell Vivian - based on Task #1383: MSP Number Validation in edit patient form
  generateValidPHN(): string {
    let nums = [];
    let sum = 0;
    let weight = 0;
    let finalDigit = 10;
    while (finalDigit > 9) {
      nums = [ 9 ];
      sum = 0;
      weight = 2;
      for (let i=0; i<8; i++) {
        const num = Math.floor(Math.random() * 10)
        nums.push(num);
        sum += (num * weight) % 11;
        weight = (weight * 2) % 11;
      }
      finalDigit = 11 - sum % 11;
    }
    nums.push(finalDigit);
    return nums.join('');
  }
}
