import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceTemplatesService } from '@services/service-templates.service';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { BookingServiceCategoryService } from '../../../../services/booking-categories.service';
import { BookingServiceCategory } from '../../../../models/booking-service-category';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from '@models/user';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ServiceProvider } from '@models/service-provider';
import { ServiceProviderService } from '@services/service-provider.service';
import { MatSelect } from '@angular/material/select';
import { TaxIndicator } from '@models/finance/tax-indicator.enum';

@Component({
  selector: 'app-edit-online-booking-service',
  templateUrl: './edit-online-booking-service.component.html',
  styleUrls: ['./edit-online-booking-service.component.less'],
})
export class EditOnlineBookingServiceComponent implements OnInit {
  loading = false;
  serviceIdParam: string;

  form: FormGroup;
  allServiceTemplatesPrerequisites: ClinicServiceTemplate[] = [];
  allCategories: BookingServiceCategory[] = [];
  editedService: ClinicServiceTemplate;
  errorMsg: string;
  displayError = false;
  serviceProviders: ServiceProvider[];
  serviceProvidersSelected: ServiceProvider[] = [];
  unsub: Subject<void> = new Subject<void>();

  constructor(
    private serviceTemplatesService: ServiceTemplatesService,
    private bookingServiceCategoryService: BookingServiceCategoryService,
    private serviceProviderService: ServiceProviderService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.loading = true;
    this.editedService = this.initService(this.editedService);

    this.serviceProviderService.getServiceProvidersOnlineBooking().subscribe((result) => {
      this.serviceProviders = result;
      this.loading = false;
    });

    this.route.params.subscribe((params) => {
      this.serviceIdParam = params['servId'];
      this.initForm();
      if (this.serviceIdParam !== '_' && this.serviceIdParam != null) {
        this.serviceTemplatesService.getServiceTemplateBookingSettingsById(this.serviceIdParam).subscribe((result) => {
          this.bookingServiceCategoryService.getBookingServiceCategories().subscribe((catResult) => {
            this.allCategories = catResult;
          });
          this.serviceTemplatesService.getServiceTemplates().subscribe((servResult) => {
            this.allServiceTemplatesPrerequisites = servResult.filter(
              (serv) => serv.id !== result.id && serv.allowOnlineBooking
            );
          });
          this.editedService = result;
          this.setForm();
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    });
  }

  initForm() {
    this.form = new FormGroup({
      serviceName: new FormControl({ value: this.editedService.serviceName, disabled: true }),
      onlineName: new FormControl(this.editedService.onlineServiceName),
      servicePrice: new FormControl({ value: this.editedService.defaultPrice, disabled: true }),
      onlinePrice: new FormControl(this.editedService.onlineBookingPrice),
      serviceDuration: new FormControl({ value: this.editedService.defaultDurationMinutes, disabled: true }),
      onlineDuration: new FormControl(this.editedService.onlineBookingDurationMinutes),
      onlineDescription: new FormControl(this.editedService.onlineBookingDescription),
      selectedPrerequisites: new FormControl(this.editedService.prerequisiteIds),
      selectCategory: new FormControl(this.editedService.bookingServiceCategoryId),
      isPrerequisiteItself: new FormControl(this.editedService.isPrerequisiteItself),
      //selectedServiceProviders: new FormControl(),
    });
  }

  setForm() {
    this.form.controls['serviceName'].setValue(this.editedService.serviceName);
    this.form.controls['onlineName'].setValue(this.editedService.onlineServiceName);
    this.form.controls['servicePrice'].setValue(this.editedService.defaultPrice);
    this.form.controls['onlinePrice'].setValue(this.editedService.onlineBookingPrice);
    this.form.controls['serviceDuration'].setValue(this.editedService.defaultDurationMinutes);
    this.form.controls['onlineDuration'].setValue(this.editedService.onlineBookingDurationMinutes);
    this.form.controls['onlineDescription'].setValue(this.editedService.onlineBookingDescription);
    this.form.controls['selectedPrerequisites'].setValue(this.editedService.prerequisiteIds[0]); //Remove the [0] to make multiple work again
    this.form.controls['selectCategory'].setValue(this.editedService.bookingServiceCategoryId);
    this.form.controls['isPrerequisiteItself'].setValue(this.editedService.isPrerequisiteItself);
    //this.form.controls['selectedServiceProviders'].setValue(this.serviceProvidersSelected);
  }

  updateService() {
    this.editedService.onlineServiceName = this.form.controls['onlineName'].value;
    this.editedService.onlineBookingPrice = this.form.controls['onlinePrice'].value;
    this.editedService.onlineBookingDurationMinutes = this.form.controls['onlineDuration'].value;
    this.editedService.onlineBookingDescription = this.form.controls['onlineDescription'].value;
    this.editedService.prerequisiteIds =
      this.form.controls['selectedPrerequisites'].value == undefined
        ? []
        : [this.form.controls['selectedPrerequisites'].value]; //Remove outer [] and inline conditional to make multiple work again
    this.editedService.bookingServiceCategoryId = this.form.controls['selectCategory'].value;
    this.editedService.isPrerequisiteItself = this.form.controls['isPrerequisiteItself'].value;

    this.serviceTemplatesService.updateServiceTemplateOnlineBooking(this.editedService).subscribe(
      () => {
        this.serviceTemplatesService.updateRefreshRequired(true);
        this.router.navigate([
          '/management/organization/clinics/online-booking',
          { outlets: { 'action-panel': null } },
        ]);
      },
      (error: HttpErrorResponse) => {
        this.showError(error.error.errors[0]);
      }
    );
  }

  cancelUpdate() {
    this.serviceTemplatesService.updateRefreshRequired(false);
    this.router.navigate(['/management/organization/clinics/online-booking', { outlets: { 'action-panel': null } }]);
  }

  initService(service: ClinicServiceTemplate) {
    service = {
      id: 0,
      serviceTemplateTypeId: 0,
      serviceTemplateType: null,
      serviceDetailTemplateId: 0,
      serviceName: 'Test',
      quantity: 0,
      serviceCategoryId: 0,
      serviceCategory: null,
      serviceRecProductsString: '',
      resourcesString: '',
      notes: '',
      serviceTemplateBillingCodes: [],
      taxIndicator: TaxIndicator.NoTax,
    };
    return service;
  }

  showError(error) {
    var errorMsgOut = error.fieldErrors[0] + ' (';
    for (let i = 1; i < error.fieldErrors.length; i++) {
      errorMsgOut += this.allServiceTemplatesPrerequisites.find((_) => _.id == error.fieldErrors[i]).serviceName;
      if (i != error.fieldErrors.length - 1) {
        errorMsgOut += ', ';
      }
    }
    this.errorMsg = errorMsgOut + ')';
    this.displayError = true;
  }

  compareServiceProviders(s1: ServiceProvider, s2: ServiceProvider): boolean {
    return s1 && s2 && s1.id === s2.id;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
