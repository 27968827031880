import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HoursOfOperation, BusinessWeek, HoursOfOperationType } from '@models/hoursofoperation';
import { HoursOfOperationService, getBusinessWeek, getHoursOfOperationDays } from '@services/hoursofoperation.service';

@Component({
  selector: 'app-hours-of-operation',
  templateUrl: './hours-of-operation.component.html',
  styleUrls: ['./hours-of-operation.component.less']
})
export class HoursOfOperationDialogComponent implements OnInit {
  public dialog: MatDialog;

  businessWeek: BusinessWeek;

  constructor(
    public dialogRef: MatDialogRef<HoursOfOperationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public business: { unitId: Number; buzzHours: HoursOfOperation; hoursType: HoursOfOperationType},
    private hoursOfOperationService: HoursOfOperationService,
  ) { }

  ngOnInit() {
    this.businessWeek = getBusinessWeek(this.business.buzzHours.hoursOfOperationDays);
  }

  save() {
    const days = getHoursOfOperationDays(this.businessWeek);
    this.business.buzzHours.hoursOfOperationDays = days;
    if (this.business.buzzHours.hoursOfOperationId == null) {
      this.business.buzzHours.hoursOfOperationId = 0;
    }

    // Any changes will be reflected only in the local model and will ony be updated or added
    // when the user clicks 'Add' or 'Update'
    if(this.business.unitId){
      this.dialogRef.close(this.business.buzzHours);
      return;
    }

    //I've left this all in case in the future ideas change and it needs to be reused
    if (this.business.hoursType === HoursOfOperationType.Clinic) {
      this.hoursOfOperationService.saveClinicHoursOfOperation(this.business.unitId, this.business.buzzHours).subscribe(
        res => {
          this.dialogRef.close(res);
        },
        err => {
          console.error(err);
        }
      );
    } else {
      this.hoursOfOperationService.saveHoursOfOperation(this.business.buzzHours).subscribe(
        res => {
          this.dialogRef.close(res);
        },
        err => {
          console.error(err);
        }
      );
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
