<div
  class="d-flex flex-nowrap align-items-center justify-content-between m-2"
  *ngIf="filter && photoGroupings && filter.totalPhotos > filter.take"
>
  <button type="button" class="btn em-btn em-btn-green px-4 py-1 smaller" (click)="filter.page = filter.page - 1">
    <i class="far fa-arrow-left"></i>
  </button>
  <span>Page {{ filter?.page }}/{{ filter?.totalPages }}</span>
  <button type="button" class="btn em-btn em-btn-green px-4 py-1 smaller" (click)="filter.page = filter.page + 1">
    <i class="far fa-arrow-right"></i>
  </button>
</div>
<perfect-scrollbar appDetermineMaxScrollHeight [itemsToWatch]="[photoGroupings]">
  <div class="container">
    <div class="row h-75 p-3 flex-column flex-nowrap" *ngIf="photoGroupings">
      <div class="photo-gallery-wrapper mt-1" *ngFor="let item of photoGroupings">
        <h6 *ngIf="filter.gallery_mode != PhotoGalleryMode.Clinic" class="sub-heading navy-font">
          <a
            *ngIf="filter.gallery_mode != PhotoGalleryMode.Consultation; else onlyText"
            class="pointer"
            (click)="groupingHeaderClicked(item.photos)"
            >{{ item.groupingHeader }}</a
          >
          <ng-template #onlyText>{{ item.groupingHeader }}</ng-template>
        </h6>
        <div class="photo-wrapper d-flex flex-wrap">
          <div *ngFor="let photo of item.photos; let i = index">
            <div
              class="photo-container d-flex rounded-2 position-relative"
              (click)="imageClicked(photo)"
              [ngClass]="{ 'selected-photo': isSelectedPhoto(photo) }"
            >
              <!-- <ngx-loading [show]="photo?.loading"></ngx-loading> -->
              <!-- <a type="button" *ngIf="photo?.isSeries"   class="close em-btn btn-clean download-gallery-button"   aria-label="Close"  (click)="$event.stopPropagation();downloadSeries(photo)">
              <i class="fal fa-download"></i>
            </a>
            <a type="button" *ngIf="!photo?.isSeries" download class="close em-btn btn-clean download-gallery-button"   aria-label="Close" [href]="photo.filePath">
              <i class="fal fa-download"></i>
            </a> -->

              <ul *ngIf="photo.isSeries && photo.seriesPhotos?.length > 0; else regThumb" class="series-thumbnail">
                <li
                  *ngFor="let p of photo.seriesPhotos; let i = index"
                  [ngStyle]="{ width: 100 / photo?.seriesPhotos?.length + '%' }"
                >
                  <img
                    [src]="p.filePathThumb + '&cachebust=' + date.toISOString()"
                    class="series-thumb-img"
                    [ngStyle]="{
                      'margin-left':
                        i != photo?.seriesPhotos?.length - 1 ? -1 * (100 / photo?.seriesPhotos?.length) + '%' : '0'
                    }"
                  />
                </li>
              </ul>
              <ng-template #regThumb>
                <img [src]="photo.filePathThumb" class="gallery-photo" />
              </ng-template>

              <div
                *ngIf="photo.photoConsentTypeId !== PhotoConsentType.None || !photo.isSeries"
                class="photo-overlay d-flex white-font position-absolute"
                kendoTooltip
                tooltipClass="em-tooltip"
                position="top"
              >
                <i *ngIf="photo.isFavourite" class="fas fa-heart p-1" title="Favourite"></i>
                <i
                  *ngIf="photo.photoConsentTypeId !== PhotoConsentType.None"
                  class="far fa-user-check p-1"
                  title="Consent Given"
                ></i>
                <i
                  *ngIf="containsTagTitle(photo, 'Before/After')"
                  class="far fa-book-open p-1"
                  title="Before and After"
                ></i>
                <i *ngIf="containsTagTitle(photo, 'Series')" class="far fa-clone p-1" title="Series"></i>
                <span
                  *ngIf="photo.photoConsentTypeId === PhotoConsentType.Internal"
                  title="Consent given for internal sharing"
                  class="ml-auto pr-1 align-self-center justify-self-end"
                  style="cursor: default"
                >
                  Internal
                </span>
                <span
                  *ngIf="photo.photoConsentTypeId === PhotoConsentType.InternalExternal"
                  title="Consent given for external sharing"
                  class="ml-auto pr-1 align-self-center justify-self-end"
                  style="cursor: default"
                >
                  External
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</perfect-scrollbar>
