import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { objectValues } from '@azure/ms-rest-js/es/lib/util/utils';
import { ClinicEmailTemplate, EmailTemplateType } from '@models/clinic-email-template';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '@services/users.service';

@Component({
  selector: 'app-import-json',
  templateUrl: './import-json.component.html',
  styleUrls: ['./import-json.component.less']
})
export class ImportJSONComponent implements OnInit {

  importJSONForm: FormGroup;
  loading: boolean;
  public emailTemplateTypes = EmailTemplateType;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private userService: UsersService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.importJSONForm = this.fb.group({
      emailTemplateType: [null, Validators.required],
      name: [null, Validators.required],
      description: [null],
      definition: [null]
    });
  }

  public onSubmit() {
    let template = new ClinicEmailTemplate();
    Object.assign(template, this.importJSONForm.value);
    if (template.definition)
      template.definition = JSON.parse(template.definition);
    template.emailTemplateType = Number.parseInt(this.importJSONForm.controls['emailTemplateType'].value);
    template.createdByUser = this.userService.loggedInUser;
    this.activeModal.close(template);
  }

}
