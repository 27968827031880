import { Directive, Input, ElementRef, AfterViewInit, AfterContentInit, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[cssTransitionEnd]'
})
export class CSSTransitionEndDirective  {

  @Output() transitionEnded: EventEmitter<any> = new EventEmitter();

  public constructor(private el: ElementRef) {
    let transition = el.nativeElement;

    transition.addEventListener('transitionend', (event) => {
      this.transitionEnded.next(event);
    });
  }
}
