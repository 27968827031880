import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { PhotoGalleryConsentedComponent } from './photo-gallery-consented/photo-gallery-consented.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { TagInputModule } from 'ngx-chips';
import { PhotoEditingService } from '@services/photo-editing.service';
import { TagService } from '@services/tag.service';

@NgModule({
    declarations: [
        PhotoGalleryConsentedComponent,
    ],
    imports: [
        SharedModule,
        MatProgressSpinnerModule,
        MatSliderModule,
        MatButtonToggleModule,
        TagInputModule
    ],
    providers: [
        PhotoEditingService,
        TagService
    ],
    exports: [
        PhotoGalleryConsentedComponent,
    ]
})
export class PatientPhotosConsentedModule { }
