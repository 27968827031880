<formio
  [ngClass]="{'modal-version': modalView, 'editable-version': editable}"
  #formView
  [form]="form"
  [readOnly]="!editable"
  (submit)="onSubmit($event)"
  [submission]="submission"
  appDomChangeListener
  (domChanged)="onFormChange($event)">
</formio>
