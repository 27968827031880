<ngx-loading [show]="loading"></ngx-loading>
<kendo-grid [data]="gridView">
  <kendo-grid-column field="area" title="Area" [width]="100"></kendo-grid-column>
  <kendo-grid-column
    field="amountUsed"
    title="Amount Used"
    [width]="150"
    [style]="{ 'text-align': 'right' }"
  ></kendo-grid-column>
  <kendo-grid-column
    field="numberOfTreatments"
    title="Number Treatments"
    [width]="180"
    [style]="{ 'text-align': 'right' }"
  ></kendo-grid-column>
  <kendo-grid-column
    field="averageDose"
    title="Average Dose"
    [width]="150"
    [style]="{ 'text-align': 'right' }"
  ></kendo-grid-column>
  <kendo-grid-column
    field="averagePrice"
    title="Average price per U/ml/applicator"
    [width]="280"
    [style]="{ 'text-align': 'right' }"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.averagePrice | currency }}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="totalGross" title="Gross Revenue" [width]="150" [style]="{ 'text-align': 'right' }">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.totalGross | currency }}</span>
    </ng-template>
  </kendo-grid-column>
  <!--padding column-->
  <kendo-grid-column> </kendo-grid-column>
</kendo-grid>
