import { Injectable } from '@angular/core';
import { resolve } from 'dns';
import { ScriptStore, Script } from './script.store';

@Injectable({
  providedIn: 'root'
})
// Based on https://stackoverflow.com/a/42766146
export class ScriptService {

  private scripts: LoadableScript[] = [];

  constructor() {
    ScriptStore.forEach((script) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      }
    });
  }

  load(...scripts: string[]) {
    const promises: Promise<any>[] = [];
    scripts.forEach(script => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string): Promise<ScriptResult> {
    return new Promise((resolve, reject) => {
      // Script is already loaded
      if (this.scripts[name].loaded)
        resolve({ script: name, loaded: true, status: 'Already loaded'});
      // Load script
      else {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        script.onload = () => {
          this.scripts[name].loaded = true;
          resolve({ script: name, loaded: true, status: 'loaded' });
        };
        script.onerror = (error) => reject({ script: name, loaded: false, status: 'Error'});
        document.getElementsByTagName('head')[0].appendChild(script);
      }

    });
  }
}

interface LoadableScript {
  loaded: boolean;
  src: string;
}

interface ScriptResult {
  script: string;
  loaded: boolean;
  status: string;
}
