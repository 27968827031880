import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { PhotoDrawing, DrawingData } from '@models/photo/photo-drawing';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { PhotoConsentType } from '@models/photo/photo-consent-type';
import { fabric } from 'fabric';
import moment from 'moment';

@Component({
  selector: 'app-draw-tool',
  templateUrl: './draw-tool.component.html',
  styleUrls: ['./draw-tool.component.less'],
})
export class DrawToolComponent implements OnInit, OnChanges {
  @Input() photoDrawing: PhotoDrawing;
  @Input() saveCallback: (data: File, photoDrawing: PhotoDrawing) => void;

  loading = false;

  drawingSizes: { [name: string]: number } = {
    Small: 5,
    Medium: 10,
    Large: 25,
  };

  colors: { [name: string]: string } = {
    Black: '#000',
    White: '#fff',
    Yellow: '#ffeb3b',
    Red: '#f44336',
    Blue: '#2196f3',
    Green: '#4caf50',
    Purple: '#7a08af',
  };

  currentSize = 'Small';
  currentColor = 'Black';

  canUndo = false;
  canRedo = false;
  drawingMode = true;

  colorsName: string[] = [];
  drawingSizesName: string[] = [];

  private canvas: fabric.Canvas;
  private imageElement: HTMLImageElement;
  private outputWidth = 1024;
  private outputHeight = 768;
  private isPanning = false;
  private disablePanning = false;
  private lastPos: { x: number; y: number };
  private zoomLevel: number;
  private historyStack: fabric.Object[] = [];

  constructor() {
    fabric.Object.NUM_FRACTION_DIGITS = 8;
  }

  ngOnInit() {
    this.colorsName = Object.keys(this.colors);
    this.drawingSizesName = Object.keys(this.drawingSizes);
    this.initCanvas();
    this.selectColor(this.currentColor);
    this.selectDrawingSize(this.currentSize);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.photoDrawing && !changes.photoDrawing.firstChange) {
      this.photoDrawing = changes.photoDrawing.currentValue;
      this.initBackgroundImage();
    }
  }

  private initCanvas() {
    const canvas = (this.canvas = new fabric.Canvas('canvas', {
      defaultCursor: 'move',
      selection: false,
      isDrawingMode: true,
    }));
    fabric.Object.prototype.selectable = false;

    canvas.setWidth(this.outputWidth);
    canvas.setHeight(this.outputHeight);
    canvas.backgroundColor = 'white';

    canvas.on('path:created', () => {
      this.historyStack = [];
      this.setUndoRedo();
    });

    canvas.on('touch:gesture', (opt: any) => {
      const event = opt.e;
      event.preventDefault();

      if (event.touches?.length == 2) {
        this.disablePanning = true;
        if (event.self.state == 'start') {
          this.zoomLevel = this.canvas.getZoom();
        }
        const delta = this.zoomLevel * event.self.scale;
        this.zoomCanvas(delta, event.self.x, event.self.y);
        this.disablePanning = false;
      }
    });

    canvas.on('mouse:wheel', (opt) => {
      opt.e.preventDefault();
      const delta = opt.e.deltaY;
      this.zoomCanvas(delta, opt.e.offsetX, opt.e.offsetY);
    });

    canvas.on('mouse:down', (opt) => {
      const event = opt.e;
      event.preventDefault();
      this.isPanning = true;

      if (event instanceof MouseEvent)
        this.lastPos = {
          x: event.clientX,
          y: event.clientY,
        };

      if (event instanceof TouchEvent)
        this.lastPos = {
          x: event.touches[0].clientX,
          y: event.touches[0].clientY,
        };
    });

    canvas.on('mouse:move', (opt) => {
      const event = opt.e;
      event.preventDefault();

      if (event instanceof MouseEvent) this.panCanvas(event.clientX, event.clientY);

      if (event instanceof TouchEvent) this.panCanvas(event.touches[0].clientX, event.touches[0].clientY);
    });

    canvas.on('mouse:up', (opt) => {
      // on mouse up we want to recalculate new interaction
      // for all objects, so we call setViewportTransform
      this.canvas.setViewportTransform(this.canvas.viewportTransform);
      this.isPanning = false;
    });

    if (this.photoDrawing.drawingData) {
      this.loading = true;
      const drawingData = JSON.parse(this.photoDrawing.drawingData) as DrawingData;
      canvas.loadFromJSON(drawingData.canvasData, () => {
        canvas.setViewportTransform(drawingData.transformMatrix);
        fabric.util.enlivenObjects(drawingData.history, (objects) => (this.historyStack = objects), 'fabric');
        this.setUndoRedo();
        this.loading = false;
      });
    } else {
      this.initBackgroundImage();
    }
  }

  private zoomCanvas(delta: number, offsetX: number, offsetY: number) {
    const prevZoom = this.canvas.getZoom();
    let zoom = prevZoom * 0.999 ** delta;

    if (zoom > 10) zoom = 10;
    if (zoom < 1) zoom = 1;

    this.canvas.zoomToPoint({ x: offsetX, y: offsetY }, zoom);
    this.selectDrawingSize(this.currentSize);

    const vpt = this.canvas.viewportTransform;
    const canvasWidth = this.canvas.getWidth();
    const canvasHeight = this.canvas.getHeight();

    if (vpt[4] >= 0) {
      vpt[4] = 0;
    } else if (vpt[4] < canvasWidth - canvasWidth * zoom) {
      vpt[4] = canvasWidth - canvasWidth * zoom;
    }

    if (vpt[5] >= 0) {
      vpt[5] = 0;
    } else if (vpt[5] < canvasHeight - canvasHeight * zoom) {
      vpt[5] = canvasHeight - canvasHeight * zoom;
    }
  }

  private panCanvas(toX: number, toY: number) {
    if (this.isPanning && !this.drawingMode && !this.disablePanning) {
      const vpt = this.canvas.viewportTransform;
      vpt[4] += toX - this.lastPos.x;
      vpt[5] += toY - this.lastPos.y;
      this.canvas.requestRenderAll();
      this.lastPos = { x: toX, y: toY };
    }
  }

  private initBackgroundImage() {
    this.loading = true;
    this.imageElement = new Image();
    this.imageElement.src = this.photoDrawing.photo.filePathOriginal;
    this.imageElement.crossOrigin = 'Anonymous';
    this.imageElement.onload = () => {
      this.loading = false;
      this.setCanvasImage();
    };
  }

  private calcImgResize(img: HTMLImageElement, canvas: fabric.Canvas): [number, number, number] {
    let canvasWidth = canvas.getWidth();
    let canvasHeight = canvas.getHeight();
    let originHeight = img.naturalHeight;
    let originWidth = img.naturalWidth;
    let xScale = Math.min(canvasWidth / originWidth, 1);
    let yScale = Math.min(canvasHeight / originHeight, 1);
    let scale = Math.min(xScale, yScale);
    let xCenterShift = (canvasWidth - originWidth * scale) / 2;
    let yCenterShift = (canvasHeight - originHeight * scale) / 2;
    return [scale, xCenterShift, yCenterShift];
  }

  private setCanvasImage() {
    this.clearCanvas();
    let [scale, xCenterShift, yCenterShift] = this.calcImgResize(this.imageElement, this.canvas);
    const canvasImage = new fabric.Image(this.imageElement, {
      scaleX: scale,
      scaleY: scale,
      left: xCenterShift,
      top: yCenterShift,
      originX: 'left',
      originY: 'top',
      crossOrigin: 'anonymous',
    });
    this.canvas.setBackgroundImage(canvasImage, this.canvas.renderAll.bind(this.canvas));
  }

  // Tools
  enableDrawingMode() {
    this.drawingMode = true;
    this.canvas.isDrawingMode = true;
  }

  enablePanningMode() {
    this.drawingMode = false;
    this.canvas.isDrawingMode = false;
  }

  selectDrawingSize(size: string) {
    this.currentSize = size;
    if (this.canvas) {
      const zoom = this.canvas.getZoom();
      this.canvas.freeDrawingBrush.width = this.drawingSizes[size] / zoom;
    }
  }

  selectColor(color: string) {
    this.currentColor = color;
    if (this.canvas) {
      this.canvas.freeDrawingBrush.color = this.colors[color];
    }
  }

  // Actions
  undo() {
    if (this.canUndo) {
      const lastId = this.canvas.getObjects().length - 1;
      const lastObj = this.canvas.getObjects()[lastId];
      this.historyStack.push(lastObj);
      this.canvas.remove(lastObj);
      this.setUndoRedo();
    }
  }

  redo() {
    if (this.canRedo) {
      const firstInStack = this.historyStack.splice(-1, 1)[0];
      if (firstInStack) {
        this.canvas.insertAt(firstInStack, this.canvas.getObjects().length, false);
      }
      this.setUndoRedo();
    }
  }

  clearCanvas() {
    this.canvas.remove(...this.canvas.getObjects());
    this.canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
    this.setUndoRedo();
  }

  saveImage() {
    this.loading = true;
    this.canvas.clone((saveCanvas) => {
      if (this.canvas.getObjects().length > 0) {
        this.canvas.getObjects().forEach((originalObject: fabric.Object, i: number) => {
          originalObject.clone((clonedObject: fabric.Object) => {
            saveCanvas.insertAt(clonedObject, i, false);
          });
        });
      }
      saveCanvas.setViewportTransform(this.canvas.viewportTransform);
      saveCanvas.setWidth(this.outputWidth);
      saveCanvas.setHeight(this.outputHeight);
      saveCanvas.setZoom(this.canvas.getZoom());
      saveCanvas.renderAll();
      saveCanvas.getElement().toBlob(this.saveBlobAndDrawingData, 'image/jpeg', 1.0);
      this.loading = false;
    });
  }

  private saveBlobAndDrawingData = (data: Blob) => {
    let metadata = new PhotoMetaData(this.photoDrawing.photo);
    const options: FilePropertyBag = {
      type: data.type,
    };
    const file = new File([data], metadata.imageName, options);
    metadata.fileId = null;
    metadata.filePath = null;
    metadata.filePathThumb = null;
    metadata.isOriginal = false;
    metadata.photoConsentTypeId = PhotoConsentType.None;
    metadata.modifiedDate = moment();
    metadata.uploadDate = moment();
    metadata.dateTaken = moment();

    const drawingData: DrawingData = {
      history: this.historyStack,
      transformMatrix: this.canvas.viewportTransform,
      canvasData: this.canvas,
    };

    this.photoDrawing.drawingData = JSON.stringify(drawingData);
    this.photoDrawing.photo = metadata;
    this.saveCallback(file, this.photoDrawing);
  };

  private setUndoRedo() {
    this.canUndo = this.canvas.getObjects().length > 0;
    this.canRedo = this.historyStack.length > 0;
  }
}
