import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { PharmaciesService } from '@services/pharmacies.service';
import { Pharmacy } from '@models/pharmacy';
import { takeUntil } from 'rxjs/operators';
import { FaLayersTextComponent } from '@fortawesome/angular-fontawesome';
import { ValidationService } from '@services/validation.service';
import { FormatterService } from '@services/formatter.service';

@Component({
  selector: 'app-quick-add-pharmacy',
  templateUrl: './quick-add-pharmacy.component.html',
  styleUrls: ['./quick-add-pharmacy.component.less']
})
export class QuickAddPharmacyComponent implements OnInit, OnDestroy {
  pharmacyForm: FormGroup;
  unsub = new Subject<void>();
  nameControl: FormControl;
  faxControl: FormControl;
  possibleDuplicates: Pharmacy[];
  pharmacies: Pharmacy[];

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<QuickAddPharmacyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pharfaciesService: PharmaciesService,
    private validationService: ValidationService,
    public formatterService: FormatterService
  ) { }

  ngOnInit() {
    this.pharmacies = this.data.pharmacies;
    this.initForm();
  }

  initForm() {
    this.pharmacyForm = this.formBuilder.group({
      name: [null, Validators.required],
      fax: [null, [Validators.required, this.validationService.validatePhoneNumber]]
    });
    this.nameControl = this.pharmacyForm.controls['name'] as FormControl;
    this.faxControl = this.pharmacyForm.controls['fax'] as FormControl;
  }

  pharmacyFormSubmit() {
    const pharmacy: Pharmacy = this.initPharmacy(this.nameControl.value,
                                                 this.faxControl.value);
    this.pharfaciesService.addPharmacy(pharmacy)
      .pipe(takeUntil(this.unsub))
      .subscribe((pharmacy) => {
        this.dialogRef.close(pharmacy);
      });
  }

  checkForDuplicates(){
    // Rather than remove the hyphen on each potential match fax, add hyphens to the entered fax number
    const enteredNumber = this.formatterService.formatPhoneString(this.faxControl.value);
    if (!this.pharmacyForm.dirty && (this.nameControl.value == '' || this.faxControl.value == '')) this.possibleDuplicates = [];
    else{
      let regex =   "^.*(" + (this.nameControl.value ? this.nameControl.value : 'NoName') + '|' +  (this.faxControl.value ? enteredNumber : 'NoFax')  + ").*$";
      var re = new RegExp(regex,"gi");
      
      this.possibleDuplicates = 
      this.pharmacies.filter(pharm => re.test(pharm['name']) || re.test(pharm['faxNumber']) )
                     .slice(0,10);
    }
  }

  initPharmacy(name: string, faxNumber: string): Pharmacy {
    const address = {
      address1: '',
      address2: '',
      city: '',
      country: '',
      postalCode: '',
      province: ''
    };
    const pharmacy = {
      pharmacyId: 0,
      name: name,
      address: address,
      phoneNumber1: '',
      phoneNumber2: '',
      phoneNumber3: '',
      faxNumber: faxNumber,
      email: '',
      website: '',
      hoursOfOperation: null
    };
    return pharmacy;
  }

  closeModal() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
