import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Claim, Role, UserClaims } from '@models/user';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthRoleClaimsService {
  constructor(private http: HttpClient) {}

  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(environment.baseUrl + 'api/AuthRoles');
  }

  getClaims(): Observable<Claim[]> {
    return this.http.get<Claim[]>(environment.baseUrl + 'api/AuthRoles/Claims');
  }

  getRoleClaims(roleId: string): Observable<Claim[]> {
    return this.http.get<Claim[]>(environment.baseUrl + `api/AuthRoles/RoleClaims/${roleId}`);
  }

  setRoleClaim(roleId: string, claim: Claim): Observable<void> {
    return this.http.put<void>(environment.baseUrl + `api/AuthRoles/AddRoleClaim/${roleId}`, claim);
  }

  removeRoleClaim(roleId: string, claimValue: string): Observable<void> {
    return this.http.delete<void>(environment.baseUrl + `api/AuthRoles/RemoveRoleClaim/${roleId}/${claimValue}`);
  }

  getClinicUsersClaims(isDevClinic: boolean): Observable<UserClaims[]> {
    return this.http.get<UserClaims[]>(environment.baseUrl + `api/AuthRoles/UsersClaims/${isDevClinic}`);
  }

  setAllUserClaimsInRole(userId: string, roleId: string) {
    return this.http.put(environment.baseUrl + `api/AuthRoles/SetUserClaimsFromRole/${userId}/${roleId}`, null);
  }

  removeAllUserClaimsInRole(userId: string, roleId: string) {
    return this.http.delete(environment.baseUrl + `api/AuthRoles/RemoveUserClaimsFromRole/${userId}/${roleId}`);
  }

  setUserClaim(userId: string, claimValue: string) {
    return this.http.put(environment.baseUrl + `api/AuthRoles/SetUserClaim/${userId}/${claimValue}`, null);
  }

  setUserClaims(userId: string, claimValues: string[]) {
    return this.http.put(environment.baseUrl + `api/AuthRoles/SetUserClaims/${userId}`, claimValues);
  }

  removeUserClaim(userId: string, claimValue: string) {
    return this.http.delete(environment.baseUrl + `api/AuthRoles/RemoveUserClaim/${userId}/${claimValue}`);
  }
}
