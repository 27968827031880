import { Component, Input, OnInit } from '@angular/core';
import { UserCommissionsService } from '@services/user-commissions.service';
import { ServiceTemplatesService } from '@services/service-templates.service';
import { ObservationListItemsService } from '@services/observation-list-items.service';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { ServiceTemplateUserCommission } from '@models/service/service-template-user-commission';
import { MatDialog } from '@angular/material/dialog';
import { CommissionEditComponent } from '../../../../dialogs/commission-edit/commission-edit.component';
import { AddEvent, EditEvent, RemoveEvent } from '@progress/kendo-angular-grid';
import { ObservationListItem } from '@models/observation/observation';

@Component({
  selector: 'app-edit-user-commissions',
  templateUrl: './edit-user-commissions.component.html',
  styleUrls: ['./edit-user-commissions.component.less'],
})
export class EditUserCommissionsComponent implements OnInit {
  public userCommissions: ServiceTemplateUserCommission[];
  private services: ClinicServiceTemplate[];
  public clinicSupplies: ObservationListItem[];
  private editedRowIndex: number;
  @Input() userId: string;

  constructor(
    private serviceTemplatesService: ServiceTemplatesService,
    private userCommissionsService: UserCommissionsService,
    private observationListService: ObservationListItemsService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.userCommissionsService
      .getCommissions(this.userId)
      .subscribe((commissions) => (this.userCommissions = commissions));
    this.serviceTemplatesService
      .getServiceTemplates()
      .subscribe((serviceTemplates) => (this.services = serviceTemplates));
    this.observationListService
      .getAllObservationListItems()
      .subscribe((supplies) => (this.clinicSupplies = this.suppliesFilter(supplies)));
  }

  addHandler(args: AddEvent): void {
    let newCommission = new ServiceTemplateUserCommission();
    newCommission.userId = this.userId;
    this.openDialog(newCommission);
  }

  editHandler(args: EditEvent): void {
    this.editedRowIndex = args.rowIndex;
    this.openDialog(args.dataItem);
  }

  removeHandler({ rowIndex }: RemoveEvent): void {
    const commission = this.userCommissions[rowIndex];
    this.userCommissionsService.deleteCommission(commission.id).subscribe(() => {
      this.userCommissions.splice(rowIndex, 1);
    });
  }

  openDialog(commission: ServiceTemplateUserCommission): void {
    const disabledServiceIds = this.userCommissions
      .filter(
        (userComm) =>
          userComm.serviceTemplateId != commission.serviceTemplateId && //Don't disable self
          userComm.serviceTemplateTypeId != 9 //Don't disable injectables
      )
      .map((comm) => comm.serviceTemplateId);

    const disabledSupplyIds = this.userCommissions
      .filter((comm) => comm.observationListId != commission.observationListId && comm.observationListId != null)
      .map((comm) => [comm.serviceTemplateId, comm.observationListId]);

    //Injectable is a required field if general injectable commission already exists
    const injectableReqIds = this.userCommissions
      .filter((comm) => comm.serviceTemplateTypeId == 9 && comm.observationListId == null)
      .map((comm) => comm.serviceTemplateId);

    const dialogRef = this.dialog.open(CommissionEditComponent, {
      width: '390px',
      autoFocus: false,
      data: {
        serviceCommission: commission,
        services: this.services,
        disabledServiceIds: disabledServiceIds,
        disabledSupplyIds: disabledSupplyIds,
        injectableReqIds: injectableReqIds,
        clinicSupplies: this.clinicSupplies,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.saveHandler(result);
    });
  }

  saveHandler(result: ServiceTemplateUserCommission): void {
    if (result.id == 0) {
      this.userCommissionsService.addCommission(result).subscribe((added) => {
        this.userCommissions.unshift(added);
      });
    } else {
      this.userCommissionsService.updateCommission(result).subscribe(() => {
        this.userCommissions[this.editedRowIndex] = result;
      });
    }
  }

  getServiceName(commission: ServiceTemplateUserCommission): string {
    const id = commission.observationListId;
    return id ? `${commission.serviceName} - ${this.getSupplyName(id)}` : commission.serviceName;
  }

  formatCommission(userCommission: ServiceTemplateUserCommission): string {
    if (userCommission.isFlatRate) {
      return userCommission.commission.toLocaleString(undefined, {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
      });
    } else {
      return (userCommission.commission / 100).toLocaleString(undefined, {
        style: 'percent',
        maximumFractionDigits: 1,
      });
    }
  }

  private suppliesFilter(supplies: ObservationListItem[]): ObservationListItem[] {
    return supplies.filter(
      (supply) =>
        supply.clinicSupplyType.observationListType.name == 'Toxins' ||
        supply.clinicSupplyType.observationListType.name == 'Fillers'
    );
  }

  private getSupplyName(id: number): string {
    if (this.clinicSupplies === undefined) return null;
    const result = this.clinicSupplies.find((supply) => supply.id == id);
    return result ? result.name : null;
  }
}
