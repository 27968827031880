import { eTreatmentForm } from './etreatment-form';

export class ServiceeTreatmentForm {
  public id: number;
  public clinicServiceTemplateId: number;
  public eTreatmentFormId: number;
  public eTreatmentForm: eTreatmentForm;
  public isSelectedByDefault: boolean = true;

  public static fromForm(clinicServiceTemplateId: number, eTreatmentForm: eTreatmentForm) {
    let serviceeTreatmentForm = new ServiceeTreatmentForm();
    serviceeTreatmentForm.clinicServiceTemplateId = clinicServiceTemplateId;
    serviceeTreatmentForm.eTreatmentFormId = eTreatmentForm.id;
    serviceeTreatmentForm.eTreatmentForm = eTreatmentForm;
    return serviceeTreatmentForm;
  }

  public constructor(init?: Partial<ServiceeTreatmentForm>) {
    Object.assign(this, init);
  }
}
