<div class="d-inline-flex flex-column card-info mt-2">
  <div class="d-inline-flex">
    <div class="flex-column" *ngIf="!showSmallBrandLogo" class="mb-1">
      <i class="fa-3x fab" [class]="PaymentHelper.getCreditCardIcon(card.brand)"></i>
    </div>
    <div class="flex-column ml-2">
      <div>
        <span class="uppercase mr-2">{{ PaymentHelper.getCreditCardString(card.brand) }} </span>
      </div>
      <div>
        {{ card?.maskedDigits }}
      </div>
      <div>Expiry: {{ card?.expiry }}</div>
    </div>
  </div>
</div>
