export enum ServiceDetailTemplate {
  None = 1,
  Coolsculpting = 2,
  Injections = 3,
  TreatmentPlan = 4,
  CoolTone = 5,
  Deoxycholate = 6,
}

export class ServiceDetailTemplateDetail {
  id: number;
  name: string;
}
