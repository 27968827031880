<div *ngIf="!isInsideMinistry" class="modal-header border-0 px-2 py-1">
  <button type="button" class="btn white-font p-0 ml-auto" aria-label="Close" (click)="closeModal()">
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="container position-relative p-0">
  <ngx-loading [show]="loading"></ngx-loading>
  <div class="search-container navy-background justify-content-center rx-code-container">
    <h6 class="modal-title white-font text-center mb-4">Dx CODE SEARCH</h6>

    <ng-container *ngFor="let error of errors">
      <app-error *ngFor="let fieldError of error.fieldErrors" [message]="fieldError"></app-error>
    </ng-container>


    <div class="input-group pb-2">
      <div class="input-group-prepend">
        <div class="input-group-text border-radius grey-three-background border-0">
          <i class="fa fa-search"></i>
        </div>
      </div>
      <input
        [formControl]="searchFormControl"
        type="text"
        name="docSearch"
        class="form-control border-0 grey-three-background"
        placeholder="Search">
    </div>
  </div>

  <div *ngIf="rxCodeItems.length > 0" class="rx-code-container" [ngClass]="{'grey-two-background': isInsideMinistry}">
    <div
      *ngFor="let item of rxCodeItems | slice:0:5;"
      class="d-flex align-items-center navy-font code-item">
      <div class="p-2">{{item.code}}</div>
      <div class="p-2">{{item.description}}</div>
      <i
        (click)="addRxCode(item)"
        class="far fa-plus-circle navy-font ml-auto mr-2 pointer"></i>
    </div>
    <h6 *ngIf="noResults">No result found</h6>
  </div>

  <div class="save-changes-container py-3 text-center">
    <button
      class="btn em-btn em-btn-green ml-2"
      (click)="closeModal()">Cancel</button>
  </div>
</div>
