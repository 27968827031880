<div class="edit-container position-absolute d-block z-index-3 white-background" *ngIf="editTaxForm">
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0;">
  <ng-container [formGroup]="editTaxForm">
    <div class="row teal-background">
      <div class="col">
        <h6 class="white-font uppercase p-2 mb-0">
        {{addOrEdit}} Tax
        </h6>
      </div>
    </div>
    <app-error *ngIf="errorMessage" [message]="errorMessage"></app-error>
    
    <div class="row mt-2 mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input
            matInput
            placeholder="Name"
            class="capitalized"
            required
            formControlName="name"
            autocomplete="off">
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input
            matInput
            placeholder="Percent(%)"
            formControlName="value"
            required
            autocomplete="off">
        </mat-form-field>
        <mat-error *ngIf="editTaxForm.controls.value.invalid && editTaxForm.controls.value.errors.pattern">
          Not a valid tax amount
        </mat-error>
      </div>
    </div>
    <div class="row mt-2 mx-0">
      <div class="col px-2">
        <mat-form-field>
          <mat-select placeholder="State" name="state" formControlName="isActive" required>
            <mat-option *ngFor="let state of stateChoices" [value]="state.value">
              {{ state.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row mx-0 mt-2">
      <div class="col px-2 text-center">
        <button
          [disabled]="!editTaxForm.valid"
          class="btn em-btn em-btn-green"
          (click)="submitTax()">
          {{isNew ? 'Add' : 'Update'}}
        </button>
      </div>
      <div class="col px-2 text-center">
        <button class="btn em-btn em-btn-green" (click)="cancelUpdate()">Cancel</button>
      </div>
    </div>
  </ng-container>
  </perfect-scrollbar>
</div>
    