<ngx-loading [show]="loading"></ngx-loading>
<div class="d-flex flex-column h-100 m-3">
  <div *ngIf="reports && reports.length > 1">
    <ng-container *ngFor="let report of reports">
      <button
        class="btn btn--tab text-uppercase grey-five-background mr-2"
        [class.selected]="embedConfig.id === report.id"
        (click)="selectReport(report)"
      >
        {{ report.name }}
      </button>
    </ng-container>
  </div>
  <div *ngIf="reports && reports.length > 1" class="header-line"></div>
  <powerbi-report
    class="d-block flex-grow-1"
    [ngClass]="{'mt-1': reports && reports.length > 1}"
    [embedConfig]="embedConfig"
    cssClassName="h-100"
    [phasedEmbedding]="false"
    [eventHandlers]="eventHandlers"
  >
  </powerbi-report>
</div>
<div *ngIf="errorMessage" class="error-info">
  <h3 class="px-4 py-1">{{ errorMessage }}</h3>
</div>
