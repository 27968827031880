import { Observation } from '../observation/observation';

export class TreatmentObservationGroup {
  public key: string;
  public observations: Observation[];

  public constructor(init?: Partial<TreatmentObservationGroup>) {
    Object.assign(this, init);
  }
}
