<ngx-loading [show]="loading"></ngx-loading>

<div class="modal-header p-1 spring-green-background">
  <button type="button" class="btn white-font p-0 ml-auto mr-2" aria-label="Close" (click)="this.closeModal()">
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="container p-0 pb-3">
  <div class="mb-2 navy-background justify-content-center">
    <h6 class="modal-title text-uppercase white-font text-center p-3">Select Memberships</h6>
  </div>
  <ng-scrollbar *ngIf="memberships.length > 0" [autoHeightDisabled]="false" style="max-height: 68vh" class="mb-2 mx-3">
    <section class="d-flex flex-column">
      <ng-container *ngFor="let membership of memberships">
        <mat-checkbox
          class="d-flex p-2 align-items-center navy-font bigger grey-two-background"
          (change)="selectionChanged($event.checked, membership)"
          [disabled]="isActive(membership)"
          >{{ membership.name }}<span class="ml-auto"> {{ membership.amount | currency }} </span>
        </mat-checkbox>
        <div class="p-2 mx-2">
          <div>
            <span class="navy-font">Cancellable After:</span>
            <span class="float-right">{{
              membership.cancellableAfter ? membership.cancellableAfter + ' Month(s)' : 'Anytime'
            }}</span>
          </div>
          <div>
            <span class="navy-font">Expires After:</span>
            <span class="float-right">{{
              membership.expiresAfter ? membership.expiresAfter + ' Month(s)' : 'Never'
            }}</span>
          </div>
          <div>
            <span class="navy-font">Redemptions:</span>
            <span class="float-right">{{
              membership.allowableRedemptions ? membership.allowableRedemptions + ' Per Month' : 'Unlimited'
            }}</span>
          </div>
          <div>
            <span class="navy-font">Percentage Applied To Credit:</span>
            <span class="float-right">{{
              membership.percentageAppliedToCredit ? membership.percentageAppliedToCredit + '%' : 'None'
            }}</span>
          </div>
          <ng-container *ngIf="membership?.membershipServices?.length > 0">
            <div class="navy-font">Service Discounts:</div>
            <ul class="mb-1">
              <li *ngFor="let membershipService of membership?.membershipServices; let i = index">
                <span class="mr-2">{{ membershipService.clinicServiceTemplate.serviceName }}</span>
                <span class="float-right">{{
                  membershipService.discountType === DiscountType.Dollars
                    ? (membershipService.amount | currency)
                    : membershipService.amount + '%'
                }}</span>
              </li>
            </ul>
          </ng-container>
          <ng-container *ngIf="membership?.membershipProducts?.length > 0">
            <div class="navy-font">Product Discounts:</div>
            <ul class="mb-1">
              <li *ngFor="let membershipProduct of membership?.membershipProducts; let i = index">
                <span class="mr-2">{{ membershipProduct.clinicProduct.displayName }}</span>
                <span class="float-right">{{
                  membershipProduct.discountType === DiscountType.Dollars
                    ? (membershipProduct.amount | currency)
                    : membershipProduct.amount + '%'
                }}</span>
              </li>
            </ul>
          </ng-container>
        </div>
      </ng-container>
    </section>
  </ng-scrollbar>
  <div *ngIf="memberships.length === 0 && !loading" class="text-center navy-font bigger mb-2 mx-4">
    No memberships have been created yet.
  </div>
  <div class="save-changes-container mb-3 text-center" [ngClass]="{ 'mt-3': loading }">
    <button
      [disabled]="selectedMemberships.length === 0"
      class="btn em-btn em-btn-green"
      (click)="this.closeModal(selectedMemberships)"
    >
      Select
    </button>
    <button class="btn em-btn em-btn-green ml-2" (click)="this.closeModal()">Cancel</button>
  </div>
</div>
