import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { PhotoFilter, PhotoGalleryMode, PhotoType, PhotoSharingType, TagCategory } from '../photo-filter';
import { ITagTypes } from '@services/tag.service';
import { Subject } from 'rxjs';
import { Tag } from '@models/tag/tag';

@Component({
  selector: 'app-generic-photo-filter',
  templateUrl: './generic-photo-filter.component.html',
  styleUrls: ['./generic-photo-filter.component.less']
})
export class GenericPhotoFilterComponent implements OnInit, OnDestroy {

  @Input() filter: PhotoFilter;

  GALLERY_MODE = PhotoGalleryMode;
  PHOTO_TYPES = PhotoType;
  SHARING_TYPES = PhotoSharingType;
  tagCategories: TagCategory[]
  private _tags: ITagTypes = {
    Service:   [],
    BodyPart:    [],
    PhotoType:   [],
    Supply: [],
    AutoGenerated: [],
  };

  get tags() {
    return this._tags;
  }
  @Input() set tags(tags: ITagTypes) {
    this._tags = tags;
    const categoryMap = new Map<number, TagCategory>();
    for (let bodyTag of this._tags.BodyPart) {
      if (bodyTag.category)
        categoryMap.set(bodyTag.category.id, bodyTag.category);
    }
    this.tagCategories = Array.from(categoryMap, ([name, value]) => value);
  };

  private unsub: Subject<void> = new Subject<void>();
  filterActive: boolean;

  constructor() {}

  async ngOnInit() {
    this.filter.filterChanged$.subscribe({
      next: filterUpdated => {
        this.filter = filterUpdated;
        this.filterActive = this.filter.filterActive();
      }
    });
  }
  
  onTagsSelected(tags: Tag[]) {
    this.filter.all_tag_filters = tags;
  }

  ngOnDestroy() {
    this.filter = null;
    this.unsub.next();
    this.unsub.complete();
  }

}
