<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>
<ngx-loading [show]="loading"></ngx-loading>

<!-- <div class="p-4 navy-background">
  <h5 class="white-font font-weight-bolder my-0 px-3 py-1">Visit Reminders</h5>
</div> -->

<div class="container-fluid p-0 content">
  <div class="row m-0">
    <!--Confirmations-->
    <div class="col p-0 mr-4">
      <div class="section-header">Confirmations</div>
      <div class="border p-2 light-grey-background">
        <!--Scheduled Confirmation-->
        <ng-container *ngIf="confirmScheduledSettings">
          <div class="row mx-0 mb-2">
            <h6 class="col-2 m-0 p-0 d-flex align-items-center teal-font">
              <i class="far fa-calendar-check mr-2"></i>Scheduled
            </h6>
            <div class="col-1 p-0 d-flex align-items-center teal-font">
              <label class="p-0 switch">
                <input
                  type="checkbox"
                  [(ngModel)]="confirmScheduledSettings.enabled"
                  (change)="updateEmailNotification(confirmScheduledSettings)"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="col p-0">
              <button
                type="button"
                class="btn btn-medium em-btn em-btn-green py-1"
                (click)="viewEmailTemplate(confirmScheduledSettings)"
              >
                View Email Template
              </button>
            </div>
          </div>
          <div class="mb-2 mr-2 teal-font">
            <span>Send Email When Appointment Scheduled</span>
          </div>
        </ng-container>
        <!--Changed Confirmation-->
        <ng-container *ngIf="confirmChangedSettings">
          <div class="row mx-0 mb-2">
            <h6 class="col-2 m-0 p-0 d-flex align-items-center teal-font">
              <i class="far fa-calendar-edit mr-2"></i>Changed
            </h6>
            <div class="col-1 p-0 d-flex align-items-center teal-font">
              <label class="p-0 switch">
                <input
                  type="checkbox"
                  [(ngModel)]="confirmChangedSettings.enabled"
                  (change)="updateEmailNotification(confirmChangedSettings)"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="col p-0">
              <button
                type="button"
                class="btn btn-medium em-btn em-btn-green py-1"
                (click)="viewEmailTemplate(confirmChangedSettings)"
              >
                View Email Template
              </button>
            </div>
          </div>
          <div class="mb-2 mr-2 teal-font">
            <span>Send Email When Appointment Changed</span>
          </div>
        </ng-container>
        <!--Cancelled Confirmation-->
        <ng-container *ngIf="confirmCancelledSettings">
          <div class="row mx-0 mb-2">
            <h6 class="col-2 m-0 p-0 d-flex align-items-center teal-font">
              <i class="far fa-calendar-times mr-2"></i>Cancelled
            </h6>
            <div class="col-1 p-0 d-flex align-items-center teal-font">
              <label class="p-0 switch">
                <input
                  type="checkbox"
                  [(ngModel)]="confirmCancelledSettings.enabled"
                  (change)="updateEmailNotification(confirmCancelledSettings)"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="col p-0">
              <button
                type="button"
                class="btn btn-medium em-btn em-btn-green py-1"
                (click)="viewEmailTemplate(confirmCancelledSettings)"
              >
                View Email Template
              </button>
            </div>
          </div>
          <div class="mb-2 mr-2 teal-font">
            <span>Send Email When Appointment Cancelled</span>
          </div>
        </ng-container>
      </div>
    </div>
    <!--Reminders-->
    <div class="col p-0">
      <div class="section-header">Reminders</div>
      <div class="border p-2 light-grey-background">
        <!--Email-->
        <div class="mb-2 d-flex align-items-center">
          <h6 class="m-0 teal-font"><i class="far fa-envelope mr-2"></i>Email</h6>
          <div class="ml-3 d-flex align-items-center teal-font bigger" *ngIf="reminderEmailSettings">
            <label class="switch">
              <input
                type="checkbox"
                [(ngModel)]="reminderEmailSettings.enabled"
                (change)="updateEmailNotification(reminderEmailSettings)"
              />
              <span class="slider round"></span>
            </label>
            <span class="pl-2">Send Email Visit Reminders</span>
          </div>
        </div>
        <div class="card mb-2">
          <div class="card-body">
            <div class="mb-3 d-flex justify-content-between">
              <div class="mr-2">
                <div class="teal-font mb-1">Email Subject</div>
                <div class="medium">
                  {{ reminderEmailSettings?.emailSubjectLine ? reminderEmailSettings?.emailSubjectLine : '-' }}
                </div>
              </div>
              <button
                type="button"
                class="btn btn-medium em-btn em-btn-green py-1 my-2 ml-auto"
                (click)="navigateToEditVisitReminderPanel('email')"
              >
                Edit
              </button>
              <!--<button
                type="button"
                class="btn btn-medium em-btn em-btn-green py-1 my-2 ml-2"
                (click)="navigateToSendTestVisitReminderPanel('email')"
              >
                Send Test
              </button> -->
            </div>
            <div class="mb-3">
              <div class="teal-font mb-1">Sender Name</div>
              <div class="medium">
                {{ reminderEmailSettings?.senderName ? reminderEmailSettings?.senderName : '-' }}
              </div>
            </div>
            <div class="mb-3">
              <div class="teal-font mb-1">Sender Email</div>
              <div class="medium">
                {{ reminderEmailSettings?.senderEmail ? reminderEmailSettings?.senderEmail : '-' }}
              </div>
            </div>
            <div class="pt-3 grey-three-border-top">
              <div class="teal-font mb-1">When to Send</div>
              <div
                *ngIf="
                  reminderEmailSettings?.firstNotificationBefore || reminderEmailSettings?.repeatNotificationEvery;
                  else nonConfigured
                "
                class="medium"
              >
                Send email
                <strong
                  ><u>{{ reminderEmailSettings?.firstNotificationBefore }}</u></strong
                >
                before scheduled visit
                <ng-container *ngIf="reminderEmailSettings?.repeatNotificationEvery">
                  , every
                  <strong
                    ><u>{{ reminderEmailSettings?.repeatNotificationEvery }}</u></strong
                  >
                  until visit is confirmed
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <!--SMS-->
        <div class="mb-2 d-flex align-items-center">
          <h6 class="m-0 teal-font"><i class="far fa-comment-dots mr-2"></i>SMS</h6>
          <div class="ml-3 d-flex align-items-center teal-font bigger" *ngIf="reminderSmsSettings">
            <label class="switch">
              <input type="checkbox" [(ngModel)]="reminderSmsSettings.enabled" (change)="updateSmsNotification()" />
              <span class="slider round"></span>
            </label>
            <span class="pl-2">Send Sms Visit Reminders</span>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="mb-3 d-flex justify-content-between">
              <div class="mr-2">
                <div class="teal-font mb-1">SMS Title</div>
                <div class="medium">{{ reminderSmsSettings?.title ? reminderSmsSettings?.title : '-' }}</div>
              </div>
              <button
                type="button"
                class="btn btn-medium em-btn em-btn-green py-1 my-2 ml-auto"
                (click)="navigateToEditVisitReminderPanel('sms')"
              >
                Edit
              </button>
              <button
                class="btn btn-medium em-btn em-btn-green py-1 my-2 ml-2"
                type="button"
                (click)="navigateToSendTestVisitReminderPanel('sms')"
              >
                Send Test
              </button>
            </div>
            <div class="mb-3">
              <div class="teal-font mb-1">SMS Message</div>
              <div
                class="medium"
                [innerHTML]="reminderSmsSettings?.message ? reminderSmsSettings?.messageWithLineBreaks : '-'"
              ></div>
            </div>
            <div class="pt-3 grey-three-border-top">
              <div class="teal-font mb-1">When to Send</div>
              <div
                *ngIf="
                  reminderSmsSettings?.firstNotificationBefore || reminderSmsSettings?.repeatNotificationEvery;
                  else nonConfigured
                "
                class="medium"
              >
                Send SMS
                <strong
                  ><u>{{ reminderSmsSettings?.firstNotificationBefore }}</u></strong
                >
                before scheduled visit
                <ng-container *ngIf="reminderSmsSettings?.repeatNotificationEvery">
                  , every
                  <strong
                    ><u>{{ reminderSmsSettings?.repeatNotificationEvery }}</u></strong
                  >
                  until visit is confirmed
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #nonConfigured>
    <div class="medium">Please configure schedule</div>
  </ng-template>

  <p-overlayPanel #hoverPanel id="hoverPanel" [dismissable]="false"></p-overlayPanel>
  <app-actionpanel></app-actionpanel>
</div>
