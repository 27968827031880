import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Patient } from '@models/patient';
import { PatientService } from '@services/patient.service';
import { CurrentDataService } from '@services/currentData.service';
import { MasterOverlayService } from '@services/actionpanel.service';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class PatientComponent implements OnInit, OnDestroy {

  get thePatient(): Patient {
    return this.patientService.patientPanelPatient;
  }
  set thePatient(value: Patient) {
    this.patientService.patientPanelPatient = value;
  }

  urlToCloseOverlay = ['/gallery' , '/'];
  unsub: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private currentDataService: CurrentDataService,
    private masterOverlayService: MasterOverlayService
  ) { }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsub))
      .subscribe(params => {
        let id: string = params['patId'];
        if (id && id !== '_') {
          if (id.includes('_')) {
            this.currentDataService.patientPanelActiveTab = id.split('_')[1];
            id = id.split('_')[0];
          }
          // TODO: add this to the condition: && isNumber(id) && id > 0
          this.patientService.getPatientById(id)
            .pipe(takeUntil(this.unsub))
            .subscribe(patient => {
              // patientPanelPatient is usually set by event that calls patient profile dialog
              // but once you are in the dialog and press F5, page refreshes but patientPanelPatient is not being re-set
              this.patientService.patientPanelPatient = patient;
              this.patientService.thePatientHasBeenUpdated(patient);
              this.masterOverlayService.masterOverlayState(true);
            });
        }
      });

      this.router.events
        .pipe(
          filter((event: NavigationEnd) => (event instanceof NavigationStart)),
          takeUntil(this.unsub)
        )
        .subscribe(
          (event: NavigationStart) => {
            if (event.navigationTrigger === 'popstate' && (this.urlToCloseOverlay.indexOf(event.url) >= 0)) {
              this.masterOverlayService.masterOverlayState(false);
            }
          }
        );
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
