import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AfterViewInit, Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { isNullOrUndefined } from '@app/shared/helpers';
import { Patient, PatientHistoryNotes } from '@models/patient';
import { PatientService } from '@services/patient.service';
import { Subject, combineLatest } from 'rxjs';
import { startWith, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-document-patient-note',
  templateUrl: './document-patient-note.component.html',
  styleUrls: ['./document-patient-note.component.less'],
})
export class DocumentPatientNoteComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() documentTypesNames: string[];
  @Input() panelHeight: number;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  patient: Patient;
  unsub: Subject<void>;
  isCollapsed: boolean = false;
  isEdited: boolean = false;

  constructor(
    private patientService: PatientService,
    private ngZone: NgZone
  ) {
    this.unsub = new Subject<void>();
    this.patient = this.patientService.patientPanelPatient;
  }

  ngOnInit() {
    combineLatest([this.patientService.thePatientUpdated$.pipe(startWith(null))])
      .pipe(takeUntil(this.unsub))
      .subscribe(() => {
        this.patient = this.patientService.patientPanelPatient;
      });
  }

  ngAfterViewInit() {
    if (!isNullOrUndefined(this.autosize)) {
      this.triggerResize();
    }
  }

  savePatientNote(event: string, key: string) {
    if (this.patient) {
      switch (key) {
        case 'PMHx':
          this.patient.medicalHistoryNotes = event;
          break;
        case 'PSH':
          this.patient.socialHistoryNotes = event;
          break;
        case 'FMH':
          this.patient.familyHistoryNotes = event;
          break;
      }
      const patientNotes: PatientHistoryNotes = {
        patientId: this.patient.patientId,
        familyHistoryNotes: this.patient.familyHistoryNotes,
        socialHistoryNotes: this.patient.socialHistoryNotes,
        medicalHistoryNotes: this.patient.medicalHistoryNotes,
      };
      this.patientService.updatePatientHistoryNotes(patientNotes).subscribe(() => {});
    }
  }

  getPatientNotesByDocumentKey(key: string) {
    switch (key) {
      case 'PMHx':
        return this.patient?.medicalHistoryNotes;
      case 'PSH':
        return this.patient?.socialHistoryNotes;
      case 'FMH':
        return this.patient?.familyHistoryNotes;
    }
  }

  triggerResize() {
    this.ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
