<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>

<div class="grid-container pb-2">
  <kendo-grid
    class="ml-1"
    [data]="gridData"
    [style.height.%]="100"
    (add)="onAddClick($event)"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <div class="d-flex">
        <button kendoGridAddCommand class="p-2 btn em-btn"><i class="fal fa-plus-circle"></i> Add Location</button>
      </div>
    </ng-template>
    <kendo-grid-column field="description" title="Description"> </kendo-grid-column>
    <kendo-grid-column field="ipAddress" title="IP Address"> </kendo-grid-column>
    <kendo-grid-column field="isActive" title="Active" [width]="100">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.isActive ? 'Active' : 'Inactive' }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="Actions" [width]="100">
      <ng-template kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridEditCommand class="btn kendo-btn"><i class="fas fa-edit"></i></button>
        <button kendoGridRemoveCommand class="btn kendo-btn"><i class="fas fa-minus-circle"></i></button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>

<!-- <p-overlayPanel #hoverPanel id="hoverPanel" [dismissable]="'false'">
  Content
</p-overlayPanel> -->

<app-actionpanel></app-actionpanel>
