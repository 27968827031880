import { GeographyService } from '@services/geography.service';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'provinceCode' })
export class ProvinceCodePipe implements PipeTransform {
  constructor(private geoService: GeographyService) {}

  transform(content: string) {
    return this.geoService.getProvinceCode(content);
  }
}
