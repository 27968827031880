<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>

<div [ngStyle]="{ height: 'calc(100dvh - 75px)' }">
  <kendo-grid
    [data]="gridView"
    [pageSize]="gridState.take"
    [skip]="gridState.skip"
    [sort]="gridState.sort"
    [sortable]="true"
    [selectable]="true"
    [style.height.%]="100"
    (add)="onAddClick($event)"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)"
    [class]="'color-picker-test'"
  >
    <ng-template kendoGridToolbarTemplate>
      <div class="d-flex">
        <span class="pl-4 search-box border border-radius">
          <span class="fa fa-search"></span>
          <mat-form-field class="w-75 p-3">
            <input
              matInput
              placeholder="Search..."
              aria-label="Search Services"
              [(ngModel)]="searchValue"
              [formControl]="searchCtrl"
              autocomplete="off"
            />
            <button
              mat-button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              *ngIf="searchValue"
              (click)="searchValue = ''"
            >
              <mat-icon><i class="fa fa-times-circle"></i></mat-icon>
            </button>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let service of filteredServiceTemplates | async" [value]="service.serviceName">
                <span>{{ service.serviceName }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </span>
      </div>
      <button kendoGridAddCommand class="btn em-btn"><i class="fal fa-plus-circle"></i> Add Service</button>
      <button kendoButton class="btn em-btn" type="button" (click)="openManageCategoryDialog()">
        <i class="fal fa-folder-open"></i> Manage Categories
      </button>
    </ng-template>
    <kendo-grid-column field="templateIcon" title="" [width]="60" [class]="'icon-column'">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <span
          [class]="dataItem.templateIcon"
          [style.font-size.px]="15"
          [style.color]="'white'"
          [style.text-align]="'center'"
          [style.backgroundColor]="dataItem.serviceIDColour ? dataItem.serviceIDColour : 'transparent'"
          [style.min-width.px]="35"
          [style.padding.px]="5"
          [style.border-radius.px]="2"
        >
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
        <kendo-dropdownlist
          [data]="templateIconsDropDown"
          [textField]="'icon'"
          [valueField]="'icon'"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('templateIcon')"
        >
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span [style.font-size.px]="22" [class]="dataItem?.icon"></span>
          </ng-template>
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            <span
              [style.font-size.px]="22"
              [class]="dataItem?.icon"
              [style.width.px]="75"
              [style.text-align]="'center'"
            ></span>
          </ng-template>
        </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="serviceName" title="Name" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="serviceAltName" title="Invoice Service Name" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="serviceCategory.name" title="Category" [width]="125"> </kendo-grid-column>
    <kendo-grid-column field="serviceTemplateTypeId" title="Type" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.serviceTemplateType?.name }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="serviceDetailTemplateId" title="Template" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ ServiceDetailTemplate[dataItem.serviceDetailTemplateId] }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="resourcesString" title="Resources" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ formatResourcesToString(dataItem) }}
      </ng-template>
    </kendo-grid-column>
    <!-- Require Card on file-->
    <kendo-grid-column field="requireCardOnFile" title="Require CC" [width]="85">
      <ng-template kendoGridHeaderTemplate>
        <span>Require CC</span>
        <i
          class="medium far fa-question-circle ml-1"
          kendoTooltip
          tooltipClass="em-tooltip"
          title="Require a Credit Card for scheduling this Service"
        ></i>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div style="text-align: center; width: 100%">
          <label class="switch">
            <input
              type="checkbox"
              name="requireCardOnFile"
              aria-label="Available for online booking"
              id="requireCardOnFile"
              [checked]="dataItem.requireCardOnFile"
              (change)="updateRequireCardOnFile(dataItem, $event)"
            />
            <span [ngStyle]="{ 'pointer-events': 'none' }" class="slider round"></span>
          </label>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="serviceRecProductsString" title="Rec Product(s)" [width]="165"></kendo-grid-column>
    <!-- <kendo-grid-column field="diagnosticCode" title="Dx Code" width="85px"></kendo-grid-column> -->
    <kendo-grid-column field="serviceTag" title="Tag" [width]="85">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div style="text-align: center; width: 100%">
          <!-- <div class="font-weight-bold" style="text-align: center">{{dataItem.serviceTag ? 'Active' : 'Inactive'}}</div> -->
          <label class="switch align-text-top mb-0 ml-2">
            <input
              #serviceTagToggle
              id="is-tag-active-{{ dataItem.id }}-flag"
              type="checkbox"
              name="statusActive"
              [checked]="dataItem.serviceTag && dataItem.serviceTag.isActive ? 'checked' : ''"
              (change)="toggleServiceTag(dataItem)"
            />
            <span class="slider round" id="is-tag-active-{{ dataItem.id }}-slider"></span>
          </label>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="defaultDurationMinutes" title="Duration" [width]="85"></kendo-grid-column>
    <kendo-grid-column field="defaultPrice" title="Price" [width]="85">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.defaultPrice | currency }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="Actions" min-width="125px" [width]="125">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button kendoGridEditCommand class="btn kendo-btn"><i class="fas fa-edit"></i></button>
        <button kendoGridRemoveCommand *ngIf="dataItem.category?.name != 'Admin'" class="btn kendo-btn">
          <i class="fas fa-minus-circle"></i>
        </button>
        <button kendoGridSaveCommand [disabled]="formGroup?.invalid">{{ dataItem.isNew ? 'Add' : 'Update' }}</button>
        <button kendoGridCancelCommand>{{ dataItem.isNew ? 'Discard changes' : 'Cancel' }}</button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>

<app-actionpanel></app-actionpanel>
