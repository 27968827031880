import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { PaymentMethod } from '@models/finance/payment-method';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService {
  public lastPaymentMethodsForUser: PaymentMethod[];
  public paymentMethodsUpdatedSource = new Subject<boolean>();
  public paymentMethodsUpdatedSource$ = this.paymentMethodsUpdatedSource.asObservable();

  constructor(private http: HttpClient) {
    this.getPaymentMethods().subscribe(pms => {
      this.lastPaymentMethodsForUser = pms;
    })
   }

  getPaymentMethods(clinicId: number = +localStorage.getItem('clinicId')): Observable<PaymentMethod[]> {
    return this.http.get<PaymentMethod[]>(`${environment.baseUrl}api/PaymentMethods`);
  }

  getPaymentMethodById(paymentMethodId: number): Observable<PaymentMethod> {
    return this.http.get<PaymentMethod>(`${environment.baseUrl}api/PaymentMethods/${paymentMethodId}`);
  }

  createPaymentMethod(paymentMethod: PaymentMethod): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}api/PaymentMethods`, paymentMethod);
  }

  updatePaymentMethod(paymentMethod: PaymentMethod): Observable<PaymentMethod> {
    return this.http.put<PaymentMethod>(`${environment.baseUrl}api/PaymentMethods`, paymentMethod);
  }

  archivePaymentMethod(paymentMethodId: number): Observable<void> {
    return this.http.delete<void>(`${environment.baseUrl}api/PaymentMethods/${paymentMethodId}`);
  }
}
