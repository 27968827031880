<div class="loading">
  <ng-container *ngFor="let appointment of visitAppointments; let i = index">
    <div class="row mr-2" kendoTooltip tooltipClass="em-tooltip" position="top">
      <div
        class="col-1"
        [ngClass]="{ 'align-self-center': !appointment.editing, 'align-items-stretch': appointment.editing }"
      >
        <button
          class="btn grey-six-font p-0 ml-2"
          (click)="cancelAppointment(appointment, i)"
          [attr.data-test-id]="'visit-panel-cancel-appointment-button'"
          [attr.data-test-value]="appointment.appointmentId"
        >
          <i class="fas fa-ban" title="Cancel Appointment"></i>
        </button>
      </div>
      <div
        class="col-1 pointer"
        [ngClass]="{
          'align-self-center py-3': !appointment.editing,
          'align-items-stretch pt-2': appointment.editing,
          'editing-disabled': showCreate || showCancelAppointment
        }"
        [ngStyle]="{ 'background-color': appointment.color }"
        (click)="appointment.editing ? (appointment.editing = false) : onEditAppointment(appointment.appointmentId)"
        [attr.data-test-id]="'visit-panel-edit-appointment-button'"
        [attr.data-test-value]="appointment.appointmentId"
      >
        <div class="white-font">
          <i *ngIf="!appointment.editing" class="far fa-pencil" title="Edit"></i>
          <i *ngIf="appointment.editing" class="fas fa-times" title="Close"></i>
        </div>
      </div>

      <div
        class="col-10 d-flex flex-column align-items-center panel-box panel-box--small p-0 px-3 w-100"
        [ngStyle]="{ background: hexToTranslucentRgbA(appointment.color) }"
      >
        <div class="row w-100 justify-content-between pt-1">
          <span class="font-weight-bold">
            {{ getTime(appointment.date, appointment.startTime) }}&nbsp; -&nbsp;{{
              getTime(appointment.date, appointment.endTime)
            }}
          </span>
          <ng-container
            *ngIf="appointment?.service?.serviceDetailTemplateId == ServiceDetailTemplate.None; else detailTemplate"
          >
            <div class="text-align-right font-weight-bold">
              {{
                (appointment?.service?.overrideDefaultPrice != null
                  ? appointment?.service?.overrideDefaultPrice
                  : appointment?.service?.defaultPrice
                ) | currency
              }}
            </div>
          </ng-container>
          <ng-template #detailTemplate>
            <div class="text-align-right font-weight-bold">
              {{ appointment?.service?.chargeAmount | currency }}
            </div>
          </ng-template>
        </div>
        <div
          class="row panel-box-column d-flex flex-column justify-content-start align-content-center w-100 font-weight-normal"
        >
          <div
            class="line-height-2 my-1 d-flex justify-content-start align-items-center"
            style="width: -webkit-fill-available; white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
            [innerHTML]="
              appointment?.service?.serviceName
                ? (appointment?.roomName ? 'ROOM ' + appointment?.roomName + '&nbsp;&ndash;&nbsp;' : '') +
                  appointment.service.serviceName
                : ''
            "
          >
            <div
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
              [innerHTML]="'&nbsp;-&nbsp;' + appointment.service.plannedTreatmentNotes"
              *ngIf="appointment?.service?.plannedTreatmentNotes?.length > 0"
            ></div>
          </div>
        </div>
        <!-- <div class="panel-box-column"  >
              ${{ appointment.service.defaultPrice }}
            </div> -->
      </div>
    </div>

    <!-- Editing Appointment Details -->
    <div class="row mr-2" *ngIf="appointment?.editing">
      <div class="col-1">
        <!-- empty -->
      </div>
      <div class="col-1" [ngStyle]="{ 'background-color': appointment.color }">
        <!-- empty -->
      </div>
      <div class="col" [ngStyle]="{ background: hexToTranslucentRgbA(appointment.color) }">
        <div class="row pt-2">
          <div class="col">
            <h6 class="sub-heading">{{ appointment.service.isLocked ? 'Locked Appointment' : 'Edit Appointment' }}</h6>
          </div>
        </div>

        <app-visit-edit-appointment
          [visit]="visit"
          [patient]="patient"
          [editAppointment]="appointment"
        ></app-visit-edit-appointment>
      </div>
    </div>
  </ng-container>
</div>
