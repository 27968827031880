<div class="panel-item-border">
  <mat-accordion kendoTooltip tooltipClass="em-tooltip" position="top">
    <mat-expansion-panel class="pb-2" [expanded]="true" [disabled]="true" #panel>
      <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
        <mat-panel-title>
          <div class="d-flex align-items-center w-100">
            <div class="column-header py-0 mr-2">Payment Cards on File</div>
            <button type="button" class="d-flex btn navy-font p-0 ml-auto" (click)="panel.expanded = !panel.expanded">
              <mat-icon title="Toggle Expansion">{{ panel.expanded ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
            </button>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="row">
        <ng-container *ngFor="let card of cards; index as i">
          <div class="col-md-6 col d-flex mb-3 py-3 card-container">
            <app-converge-card class="card mx-auto" [card]="card"></app-converge-card>
          </div>
        </ng-container>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
