export enum RetentionCampaignType {
  Email = 1,
  SMS = 2,
  EmailSms = 3,
}

export enum RetentionCampaignTypeTitle {
  Email = 'Email',
  SMS = 'SMS',
  EmailSms = 'EmailSms',
}
