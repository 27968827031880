<div class="w-100 h-100 d-flex flex-column" kendoTooltip tooltipClass="em-tooltip" position="top">
  <div *ngIf="refreshNeeded" class="px-0 mb-2 mx-3 d-flex justify-content-start align-items-center font-weight-bold">
    Patient Updates Available...
    <button
      type="button"
      class="btn em-btn-green font-weight-bold white-font flex-shrink-1 ml-2"
      (click)="getChartEntries(); refreshNeeded = false"
    >
      <i class="fas fa-sync"></i> Refresh Chart
    </button>
  </div>
  <div
    *ngIf="!refreshNeeded && !mobileView"
    class="px-0 mb-2 mx-3 d-flex justify-content-between align-items-center chart-actions"
  >
    <mat-form-field
      *appAuthGuard="appointmentsPolicy"
      style="margin-bottom: -2.3em"
      class="flex-grow-1 mr-2 padding-0-field"
    >
      <input
        type="text"
        matInput
        #chartInput="ngbTypeahead"
        placeholder="Add service"
        [ngbTypeahead]="searchServiceTemplates"
        [resultFormatter]="serviceTemplatesResultFormatter"
        [inputFormatter]="serviceTemplatesInputFormatter"
        (focus)="searchFocus$.next($any($event.target).value)"
        (blur)="chartInput.dismissPopup()"
        (selectItem)="applyService($event.item)"
        [disabled]="serviceTemplates?.length === 0"
        autocomplete="off"
      />
    </mat-form-field>

    <button
      type="button"
      class="btn em-btn-green font-weight-bold white-font flex-grow-1 flex-shrink-0 mr-2"
      (click)="addPatientNote()"
      [disabled]="!isLockedAllNotes()"
    >
      <i class="fas fa-plus-circle"></i> Note
    </button>

    <button
      id="addDrawingButton"
      type="button"
      class="btn em-btn-green font-weight-bold white-font flex-grow-1 flex-shrink-0 mr-2"
      (click)="addPhotoDrawing()"
    >
      <i class="fas fa-plus-circle"></i> Drawing
    </button>

    <div class="flex-grow-1 mr-2" ngbDropdown>
      <button
        type="button"
        class="btn em-btn-green font-weight-bold white-font w-100 dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        ngbDropdownToggle
        [disabled]="textTemplates?.length === 0"
      >
        <i class="fas fa-plus-circle"></i> Text Template
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button
          *ngFor="let textTemplate of textTemplates"
          ngbDropdownItem
          type="button"
          class="dropdown-item"
          (click)="applyTextTemplate(textTemplate.text)"
        >
          {{ textTemplate.name }}
        </button>
      </div>
    </div>
    <button
      *appAuthGuard="patientChartDownloadPolicy"
      title="Print Chart"
      class="btn em-btn-green white-font text-center px-2 align-self-center mr-2"
      (click)="printChart()"
    >
      <i class="far fa-print"></i>
    </button>
    <button
      [title]="expandAll ? 'Collapse Entry Texts' : 'Expand Entry Texts'"
      type="submit"
      class="btn em-btn-green white-font text-center px-2 align-self-center"
      (click)="toggleAll()"
    >
      <i [className]="expandAll ? 'fas fa-chevron-double-up' : 'fas fa-chevron-double-down'"></i>
    </button>
  </div>

  <ngx-loading [show]="isLoading"></ngx-loading>
  <div
    class="panel-item-border flex-grow-1"
    [ngClass]="{ 'border border-radius my-2 mx-4 p-0': mobileView, 'px-0 pt-2 mx-3': !mobileView }"
  >
    <div *ngIf="isFiltered" class="px-3 mb-2 d-flex justify-content-end">
      <button type="button" class="btn-clean teal-font" (click)="clearFilter()">Clear Filters</button>
    </div>

    <mat-accordion [multi]="true" class="service-charts">
      <ng-scrollbar #scrollbar class="h-100" (reachedBottom)="onBottomReached()" [reachedOffset]="300">
        <kendo-pdf-export
          #pdf
          paperSize="A4"
          margin="1pt"
          [keepTogether]="'mat-expansion-panel.print-element'"
          [scale]="0.6"
        >
          <!-- will only display for printing -->
          <div class="patient-detail-row text-nowrap pl-2 py-1">
            {{ patient?.gender ? ('(' + patient?.gender[0] + ') - ' | uppercase) : '' }}
            {{
              patient?.firstName + ' ' + (patient?.nickName ? '"' + patient?.nickName + '" ' : '') + patient?.lastName
                | titlecase
            }}
            - | DOB
            {{ patient?.birthDate | date : 'yyyy-MM-dd' }} Age {{ getAge(patient?.birthDate) }}
          </div>
          <ng-container *ngFor="let entry of loadedChartEntries; index as i; last as isLast">
            <app-chart-appointment-entry
              *ngIf="entry.appointmentId != null"
              id="{{ 'scrollEntry' + i.toString() }}"
              class="d-block"
              [ngClass]="{ 'mb-2': !isLast }"
              [scrollIndex]="i"
              [appointmentId]="entry.appointmentId"
              [patient]="patient"
              [expandAll]="expandAll"
              [applyNote$]="applyNote$"
              [applyPhoto$]="applyPhoto$"
              [(focusedIndex)]="focusedIndex"
              (chartEdited)="chartEntryEdited($event)"
              (chartSelected)="selectChartForMinistry($event)"
              (openMinistryBilling)="openMinistryModel()"
              (removeEntry)="removeEntry(i)"
            >
            </app-chart-appointment-entry>
            <app-chart-note-entry
              *ngIf="entry.patientNoteId != null"
              id="{{ 'scrollEntry' + i.toString() }}"
              class="d-block"
              [ngClass]="{ 'mb-2': !isLast }"
              [scrollIndex]="i"
              [noteId]="entry.patientNoteId"
              [patient]="patient"
              [expandAll]="expandAll"
              [applyNote$]="applyNote$"
              [applyPhoto$]="applyPhoto$"
              [(focusedIndex)]="focusedIndex"
              (chartEdited)="chartEntryEdited($event)"
              (removeEntry)="removeEntry(i)"
            >
            </app-chart-note-entry>
          </ng-container>
        </kendo-pdf-export>
      </ng-scrollbar>
    </mat-accordion>
  </div>
</div>

<app-panel *ngIf="isOpenMinistryModal" [(isVisible)]="isOpenMinistryModal" class="ministry-billing-modal">
  <app-ministry-billing
    [patientId]="patientId"
    [selectedChart]="selectedChart"
    (closePanel)="isOpenMinistryModal = false"
  >
  </app-ministry-billing>
</app-panel>
