<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>
<div [ngStyle]="{ height: 'calc(100dvh - 75px)' }">
  <div class="row mx-0 my-2" *ngIf="clinic">
    <div class="col d-flex align-items-center teal-font bigger">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="clinic.isUsingMerchantDevices" (change)="toggleMerchantDevices()" />
        <span class="slider round"></span>
      </label>
      <span class="pl-2">Enable Merchant Devices</span>
    </div>
  </div>
  <div class="row mx-0 my-2 ml-1" style="width: 60%">
    <div class="col px-0">
      <div class="section-header">Details</div>
      <div class="border p-2">Not enabled, here goes text for how to enable merchant devices</div>
    </div>
  </div>

  <div *ngIf="clinic.isUsingMerchantDevices">
    <kendo-grid
      class="ml-1"
      style="width: 60%"
      [data]="gridData"
      [style.height.%]="100"
      (add)="onAddClick($event)"
      (edit)="editHandler($event)"
      (remove)="removeHandler($event)"
    >
      <ng-template kendoGridToolbarTemplate>
        <div class="d-flex">
          <button kendoGridAddCommand class="p-2 btn em-btn">
            <i class="fal fa-plus-circle"></i> Add Merchant Device
          </button>
        </div>
      </ng-template>
      <kendo-grid-column field="deviceFriendlyName" title="Name"> </kendo-grid-column>
      <kendo-grid-column field="location" title="Location"> </kendo-grid-column>
      <kendo-grid-column field="pairingCode" title="Pairing Code"> </kendo-grid-column>
      <kendo-grid-column field="deviceId" title="Device Id"> </kendo-grid-column>
      <kendo-grid-column field="serialNumber" title="Serial Number"> </kendo-grid-column>
      <kendo-grid-command-column title="Actions" [width]="100">
        <ng-template kendoGridCellTemplate let-isNew="isNew">
          <button kendoGridEditCommand class="btn kendo-btn"><i class="fas fa-edit"></i></button>
          <button kendoGridRemoveCommand class="btn kendo-btn"><i class="fas fa-minus-circle"></i></button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>
</div>
<app-actionpanel></app-actionpanel>
