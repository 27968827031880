import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { Appointment, AppointmentType } from '@models/appointments/appointment';
import { AppointmentTile } from '@models/appointments/appointment-tile';
import { PaymentStatus } from '@models/appointments/payment-status';
import { ColourVariables } from '@models/constants/colour-variables';
import { Patient } from '@models/patient';
import { Service } from '@models/service/service';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { MasterOverlayService } from '@services/actionpanel.service';
import { AppointmentService } from '@services/appointments.service';
import { BreakpointService } from '@services/breakpoint.service';
import { CurrentDataService } from '@services/currentData.service';
import { EventsService, ScheduleMode, ScheduleView } from '@services/events.service';
import { PatientService } from '@services/patient.service';
import { VisitService } from '@services/visit.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.less'],
})
export class AppointmentComponent implements OnInit, OnDestroy {
  @Input() set arg(arg: any) {
    this.appointment = arg.event.extendedProps.appointment as Appointment;
    const classList = arg.event.classNames;
    this.appointmentTile = new AppointmentTile(
      this.appointment,
      classList,
      this.currentDataService.currentDate,
      this.isOverlappingEvent
    );
    this.isOverlappingEvent = this.determineOverlappingEvent(this.appointmentTile);
  }

  appointment: Appointment;
  appointmentTile: AppointmentTile;
  colourVariables = new ColourVariables();
  actionPanelOpened = false;
  isWeeklyMode = false;
  isOverlappingEvent = false;
  isIpad = navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
  mobileView = false;

  appointmentsPolicy = Policies.appointments;
  patientAccountPolicy = Policies.patientAccount;
  patientChartPolicy = Policies.patientChart;
  patientProfilePolicy = Policies.patientProfile;

  AppointmentPaymentStatus = PaymentStatus;
  AppointmentType = AppointmentType;
  ScheduleMode = ScheduleMode;
  ScheduleView = ScheduleView;

  private unsub = new Subject<void>();

  get appointmentVisitStatus() {
    const visit = this.appointmentService.activeAppointmentVisits.get(this.appointmentTile?.visitId);
    return this.visitService.getVisitStatus(visit);
  }

  get scheduleView() {
    return this.eventsService.scheduleView;
  }

  constructor(
    private router: Router,
    private titleCasePipe: TitleCasePipe,
    public appointmentService: AppointmentService,
    private visitService: VisitService,
    private eventsService: EventsService,
    public authService: AuthService,
    private currentDataService: CurrentDataService,
    private patientService: PatientService,
    private masterOverlayService: MasterOverlayService,
    private breakpointService: BreakpointService
  ) {}

  ngOnInit() {
    this.isOverlappingEvent = this.determineOverlappingEvent(this.appointmentTile);
    this.breakpointService.mobileBreakpoint$.pipe(takeUntil(this.unsub)).subscribe((mobileView) => {
      this.mobileView = mobileView;
    });
  }

  private determineOverlappingEvent(event: AppointmentTile) {
    const appointments = this.appointmentService.activeAppointments;
    return appointments.some((appointment: Appointment) => {
      return (
        appointment.appointmentId !== event.id &&
        appointment.appointmentType === AppointmentType.Regular &&
        appointment.staffId === event.resourceId &&
        moment(appointment.start).isBefore(moment(event.end)) &&
        moment(appointment.end).isAfter(moment(event.start))
      );
    });
  }

  getAppointmentTitle() {
    let title = '';
    if(!this.appointmentTile) {
      return title;
    }
    if (this.appointmentTile.onlineBooked && this.appointmentTile.isPlaceholder) {
      title = this.appointmentTile.title;
    } else if (!this.appointmentTile.onlineBooked && this.appointmentTile?.isPlaceholder) {
      title = this.titleCasePipe.transform(this.appointmentTile.patientName) + ' - ' + this.appointmentTile.title;
    } else if (this.scheduleView !== 'StaffSchedules') {
      title = this.titleCasePipe.transform(this.appointmentTile.patientName);
    }
    return title;
  }

  isApptSelected(): boolean {
    if (!this.appointmentTile) {
      return false;
    }
    return this.appointmentService.apptsSelected.has(this.appointmentTile.id);
  }

  isApptMarked(): boolean {
    if (!this.appointmentTile) {
      return false;
    }
    return this.appointmentService.apptsMarkedForMove.has(this.appointmentTile.id);
  }

  isVisitPanelOpen(): boolean {
    return this.router.url.indexOf('action-panel:visit-details') !== -1 ? true : false;
  }

  patientProfileClick(event: Event) {
    event.stopPropagation();
    this.openPatientPanel('patientprofiletab');
  }

  patientChartClick(event: Event) {
    event.stopPropagation();
    this.openPatientPanel('patientcharttab');
  }

  patientAccountClick(event: Event) {
    event.stopPropagation();
    this.openPatientPanel('patientaccounttab');
  }

  checkInClick(event: Event) {
    event.stopPropagation();
    this.updateVisitStatus();
  }

  private openPatientPanel(tab: string) {
    this.patientService.getPatientById(this.appointment.patientId).subscribe((patient) => {
      if (patient) {
        this.patientService.patientPanelPatient = patient;
        this.masterOverlayService.masterOverlayState(true);
        this.navigateFromAppointment(tab, patient, this.appointment.service);
      }
    });
  }

  private updateVisitStatus() {
    const visit = this.appointmentService.activeAppointmentVisits.get(this.appointment.visitId);
    if (!visit) throw new Error('Visit not found in active appointment visits');
    this.visitService.updateVisitStatus(visit).subscribe();
  }

  private navigateFromAppointment(tabName: string, patient: Patient, service: Service) {
    this.actionPanelOpened = false;
    if (tabName !== 'patientservicedetail') {
      if (tabName === 'patientcharttab') {
        this.router.navigate([
          '/schedule',
          {
            outlets: {
              'action-panel': [
                'patient',
                patient.patientId + '_patientcharttab',
                'patienttabs',
                'patientcharttab',
                'overview',
              ],
            },
          },
        ]);
      } else if (tabName === 'patientaccounttab') {
        this.router.navigate([
          '/schedule',
          {
            outlets: {
              'action-panel': [
                'patient',
                patient.patientId + '_patientaccounttab',
                'patienttabs',
                'patientaccounttab',
                'overview',
              ],
            },
          },
        ]);
      } else {
        this.router.navigate([
          '/schedule',
          { outlets: { 'action-panel': ['patient', patient.patientId + '_' + tabName] } },
        ]);
      }
    } else {
      if (
        service.serviceDetailTemplateId === ServiceDetailTemplate.Injections ||
        service.serviceDetailTemplateId === ServiceDetailTemplate.Coolsculpting
      ) {
        this.router.navigate([
          '/schedule',
          {
            outlets: {
              'action-panel': [
                'patient',
                patient.patientId + '_patientcharttab',
                'patienttabs',
                'patientcharttab',
                'detail',
                service.serviceId,
                service.isLocked,
                false,
              ],
            },
          },
        ]);
      } else if (service.serviceDetailTemplateId === ServiceDetailTemplate.TreatmentPlan) {
        this.router.navigate([
          '/schedule',
          {
            outlets: {
              'action-panel': [
                'patient',
                patient.patientId + '_patientcharttab',
                'patienttabs',
                'patientcharttab',
                'detail',
                'treatmentplan',
                true,
                true,
              ],
            },
          },
        ]);
      } else {
        this.router.navigate(
          [
            '/schedule',
            {
              outlets: {
                'action-panel': [
                  'patient',
                  patient.patientId + '_patientcharttab',
                  'patienttabs',
                  'patientcharttab',
                  'overview',
                ],
              },
            },
          ],
          { queryParams: { toServiceId: service.serviceId } }
        );
      }
    }
  }

  ngOnDestroy() {}
}
