import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isNullOrUndefined } from '@app/shared/helpers';

@Component({
  selector: 'app-generic-confirm',
  templateUrl: './generic-confirm.component.html',
  styleUrls: [],
})
export class GenericDialogComponent implements OnInit {
  public dialog: MatDialog;
  title: string;
  content: string;
  confirmButtonText: string;
  cancelButtonText: string;
  showCancel: boolean;

  constructor(public dialogRef: MatDialogRef<GenericDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.showCancel = true;
  }

  ngOnInit() {
    this.title = this.data.title;
    this.content = this.data.content;
    this.confirmButtonText = this.data.confirmButtonText;
    this.cancelButtonText = this.data.cancelButtonText;
    if (!isNullOrUndefined(this.data.showCancel)) {
      this.showCancel = this.data.showCancel;
    }
  }

  onNoClick(): void {
    this.data = null;
  }
}
