import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TreatmentQuestion } from '@models/treatment-planning/treatment-questions/treatment-question';
import { Observable, Subject } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { PatientTreatmentAnswer } from '@models/treatment-planning/treatment-questions/patient-treatment-answer';

@Injectable({
  providedIn: 'root'
})
export class TreatmentPlanQuestionService {
  treatmentPlanQuestionsUpdated$: Subject<TreatmentQuestion[]>;

  constructor(private http: HttpClient) {
    this.treatmentPlanQuestionsUpdated$ = new Subject();
  }

  /**
   * Returns the answered treatment plan questions for the give patient, or returns just the questions
   * if no answers have been added for the patient
   */
  getTreatmentPlanQuestionsByPatientId(patientId: number): Observable<TreatmentQuestion[]> {
    return this.http.get<TreatmentQuestion[]>(environment.baseUrl + 'api/TreatmentQuestion/' + patientId).pipe(
      map(response => {
        return response;
      })
    );
  }


  putPatientAnswers(patientAnswer: PatientTreatmentAnswer , treatmentPlanId: number): Observable<PatientTreatmentAnswer> {
    return this.http.put<PatientTreatmentAnswer>(environment.baseUrl + `api/TreatmentQuestion/${treatmentPlanId}`, patientAnswer);
  }

  deletePatientAnswers(idList: number[]): Observable<void> {
    return this.http.post<void>(environment.baseUrl + 'api/TreatmentQuestion/Delete', idList);
  }
}
