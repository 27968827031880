<div *ngIf="clinics.length" id="select-clinic">
  <h6 class="white-font uppercase inline h6">Clinic</h6>
  <mat-form-field appearance="fill" class="clinic-selector" required>
    <mat-label>Clinic Name</mat-label>
    <select (change)="swapClinic($event)" [value]="selectedClinicId" [disabled]="disabled" matNativeControl required>
      <option *ngFor="let clinic of clinics" [selected]="selectedClinicId === clinic.clinicId" [value]="clinic.clinicId">
        {{ clinic.name }}
      </option>
    </select>
  </mat-form-field>
</div>
