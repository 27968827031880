import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceTitleCase'
})
export class SpaceTitleCasePipe implements PipeTransform {

    // Breakdown of Regular Expression
    // (?=) is the positive lookahead - so (?=[A-Z][a-z]) is what it looks for without moving the regex matcher forward - the replaced ' ' happens at this point (think insert in word)
    // (?!) is the negative lookahead
      // (?!^) asserts that the character next to the start will not be matched (no ' ' before the match)
      // (?!$) asserts the character next to the last character won't be matched (no ' ' after the match)
    // more in depth read here https://www.rexegg.com/regex-disambiguation.html#lookarounds
    // [A-Z][a-z]* is the main match - one uppercase followed by 0 or more lowercase this way "ACoolRole" will become "A Cool Role" note: USA will be "U S A"
  static regEx = new RegExp(/(?!^)(?<!^[a-z]{1})(?=[A-Z][a-z])(?!$)/g);

  transform(value: string): string {
    if (!value) {
      return value;
    }

    if (typeof value !== 'string') {
      throw new Error(`Can only transform strings`);
    }

    return value.replace(SpaceTitleCasePipe.regEx, ' ');
  }

}
