<ngx-loading [show]="loading"></ngx-loading>

<ng-container *ngIf="formLoaded">
  <div class="row">
    <div class="col">
      <h5 class="mt-2">Product Recommendations</h5>
    </div>
    <div class="col-1 text-right pr-0">
      <!-- <button (click)="onSave()" [disabled]="!treatmentPlanFormService.treatmentPlanDirty" [ngClass]="{'invalid': !treatmentPlanFormService.formValid}" class="btn btn-sm em-btn em-btn-green mr-2"><i
        class="far fa-save" title="Save"></i></button> -->
    </div>
  </div>
  <div class="form-row pb-3 pt-1">
    <div class="col-4 px-0">
      <input
        #productInput
        class="input-dropdown px-1"
        type="text"
        placeholder="Add product"
        [ngbTypeahead]="searchProducts"
        [resultFormatter]="productsResultFormatter"
        [inputFormatter]="productsInputFormatter"
        (focus)="onFocus($event)"
        (selectItem)="onAddProductRecommendation($event.item)"
        [disabled]="clinicProducts?.length === 0"
        #instance="ngbTypeahead"
        autocomplete="off"
      />
    </div>
  </div>
  <form [formGroup]="productRecommendationsForm">
    <div class="form-row grey-four-background negative-row-margin">
      <div class="col-1 navy-font font-weight-bold p-2">Product</div>
      <div class="col navy-font font-weight-bold grey-six-border-left p-2">Instructions For Use</div>
      <div class="col-1 text-center navy-font font-weight-bold grey-six-border-left p-2">
        QTY <span class="required">*</span>
      </div>
      <div class="col-1 text-center navy-font font-weight-bold grey-six-border-left p-2">Pricing</div>
      <div class="col-2 text-center navy-font font-weight-bold grey-six-border-left p-2">Created Date</div>
      <div class="col-1 text-center navy-font font-weight-bold grey-six-border-left p-2">
        <!-- Empty -->
      </div>
    </div>
    <ng-container formArrayName="productRecommendations">
      <ng-container
        *ngFor="let productRecommendation of getProductRecommendationControls(); let i = index"
        [formGroupName]="i"
      >
        <div
          class="form-row negative-row-margin"
          [attr.data-test-id]="'product-recommendation-id'"
          [attr.data-test-value]="productRecommendation.value.id"
        >
          <div class="col-1 grey-four-border-bottom p-2">
            {{ productRecommendation.value.clinicProduct.displayName }}
          </div>
          <div class="col grey-six-border-left grey-four-border-bottom p-2">
            <input
              (blur)="updateRecommendedProduct(productRecommendation.value)"
              [attr.data-test-id]="'product-recommendation-usageInstructions'"
              type="text"
              formControlName="usageInstructions"
              class="w-100 form-control"
            />
          </div>
          <div class="col-1 grey-six-border-left grey-four-border-bottom p-2 text-center">
            <input
              (blur)="updateRecommendedProduct(productRecommendation.value)"
              [attr.data-test-id]="'product-recommendation-quantity'"
              type="text"
              formControlName="quantity"
              class="w-100 form-control text-center"
            />
          </div>
          <div class="col-1 grey-six-border-left grey-four-border-bottom p-2 text-center">
            <ng-container *ngIf="productRecommendation.value.retailPrice; else noPrice">
              {{ productRecommendation.value.retailPrice | currency : '$' }}
            </ng-container>
            <ng-template #noPrice> - </ng-template>
          </div>
          <div class="col-2 text-center grey-six-border-left grey-four-border-bottom p-2">
            {{ productRecommendation.value.createdDate | date : 'yyyy-MM-dd' }}
          </div>
          <div class="col-1 grey-six-border-left grey-four-border-bottom text-center p-2 flex-column mt-auto">
            <button class="btn py-0" (click)="onDeleteProductRecommendation(i, productRecommendation.value)">
              <i class="far fa-trash-alt small" title="Delete"></i>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </form>
</ng-container>
