import { GenericDialogComponent } from './../dialogs/generic-confirm/generic-confirm.component';
import { ConfirmDeleteDialogComponent } from './../dialogs/confirm-delete/confirm-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DataSourceRequestState, SortDescriptor, orderBy, State, process } from '@progress/kendo-data-query';
import { GridDataResult, PageChangeEvent, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';
import { RetentionCampaignsService } from '@services/retention-campaigns.service';
import { takeUntil } from 'rxjs/operators';
import { isNullOrUndefined } from '@app/shared/helpers';
import { CommunicationsService } from '@services/communications.service';
import { RetentionCampaignsSettings } from '@models/communications/retention-campaigns-settings.model';
import { Campaign } from '@models/retention-campaigns/campaign.model';
import { CampaignRecipientsComponent } from './campaign-recipients/campaign-recipients.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionPanelService } from '@services/actionpanel.service';
import {
  RetentionCampaignType,
  RetentionCampaignTypeTitle,
} from '@models/retention-campaigns/retention-campaign-types';
import { CampaignStatus, CampaignStatusTitle } from '@models/retention-campaigns/campaign-status.enum';
import { RecipientRequestType } from '@models/retention-campaigns/recipient-request-types';
@Component({
  selector: 'app-retention-campaigns',
  templateUrl: './retention-campaigns.component.html',
  styleUrls: ['./retention-campaigns.component.less'],
})
export class RetentionCampaignsComponent implements OnInit, OnDestroy {
  gridView: GridDataResult;
  gridState: DataSourceRequestState;
  pageable: any;
  disableGrid = false;
  loading = false;
  unsub: Subject<void> = new Subject<void>();
  active = false;
  globalStatus = 'Suspended';
  emailSenderName = '';
  emailSenderEmail = '';
  isOpenRecipientsModal: boolean = false;
  campaignId: number;
  displayData: GridDataResult;
  patientPanelOpen: boolean;
  currentDate: Date = new Date(Date.now());
  heightOffsetGrid: number;

  runStatuses: { title: any; value: any }[];
  campaignTypes: { title: any; value: any }[];

  public pageSize = 10;
  public skip = 0;

  public state: State = {
    skip: this.skip,
    take: this.pageSize,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [
      {
        field: '',
        dir: 'desc',
      },
    ],
  };
  selectedTitle: string;
  recipientRequestType: RecipientRequestType;

  constructor(
    private router: Router,
    private retentionCampaignsService: RetentionCampaignsService,
    private communicationService: CommunicationsService,
    private dialog: MatDialog,
    private actionPanelService: ActionPanelService,
    private cdref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initDefaultValue();
    this.getRetentionData();
    this.detectChanges();

    this.communicationService.getRetentionCampaignsSettings().subscribe((_) => {
      this.active = _.enabled;
      this.globalStatus = this.active ? 'Active' : 'Suspended';
      this.emailSenderName = _.senderName;
      this.emailSenderEmail = _.senderEmail;
    });

    this.runStatuses = Object.keys(CampaignStatusTitle).map((e) => ({
      title: CampaignStatusTitle[e],
      value: CampaignStatus[e],
    }));
    this.campaignTypes = Object.keys(RetentionCampaignTypeTitle).map((e) => ({
      title: RetentionCampaignTypeTitle[e],
      value: RetentionCampaignType[e],
    }));
  }

  initDefaultValue() {
    this.gridState = {
      skip: 0,
      take: 30,
      sort: [{ dir: 'desc', field: 'startDate' }],
    };

    this.gridView = {
      data: [],
      total: 0,
    };
  }

  ngAfterViewInit() {
    this.cdref.detectChanges();
  }

  getRetentionData() {
    this.loading = true;
    this.retentionCampaignsService.getCampaigns(this.gridState).subscribe((result) => {
      this.gridView = {
        data: result.data,
        total: result.total || result.data.length,
      };
      this.displayData = this.gridView;
      this.loading = false;
    });
  }

  detectChanges() {
    this.retentionCampaignsService.retentionCampaignSettingsUpdated.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.disableGrid = false;
      if (this.retentionCampaignsService.refreshRequired) {
        this.retentionCampaignsService.refreshRequired = false;
        this.getRetentionData();
      }
    });
  }

  openRetentionPanel(e = null) {
    this.disableGrid = true;
    let id = '_';
    if (!isNullOrUndefined(e) && !isNullOrUndefined(e.dataItem)) {
      id = e.dataItem.id.toString();
    }
    this.router.navigate([
      '/management/communication/retention-campaigns',
      { outlets: { 'action-panel-secondary': ['edit-retention-campaigns', id] } },
    ]);
  }

  cloneCampaign(campaign: Campaign) {
    if (campaign && campaign.id) {
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '330px',
        data: {
          title: 'Clone Retention Campaign',
          content:
            'Do you want to clone all aspects of this campaign (filters, recipients, dates, etc) into a new campaign?',
          confirmButtonText: 'Yes',
          showCancel: true,
        },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsub))
        .subscribe((result) => {
          if (result === 'confirm') {
            this.loading = true;
            this.retentionCampaignsService.cloneCampaign(campaign.id).subscribe((newCampaign) => {
              this.getRetentionData();
            });
          }
        });
    }
  }

  removeCampaign(campaign: Campaign) {
    if (campaign && campaign.id) {
      const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
        width: '250px',
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsub))
        .subscribe((result) => {
          if (result === 'delete') {
            this.loading = true;
            this.retentionCampaignsService.deleteCampaign(campaign.id).subscribe(() => {
              this.getRetentionData();
            });
          }
        });
    }
  }

  pageChange(event: PageChangeEvent) {
    this.gridState.skip = event.skip;
    this.getRetentionData();
  }

  sortChange(sort: SortDescriptor[]) {
    this.gridState.sort = sort;
    this.getRetentionData();
  }

  //this is for filtering columns
  dataStateChange(state: DataStateChangeEvent) {
    this.state = state;
    this.displayData = process(this.gridView.data, this.state);
  }

  sortIconClass(column: any) {
    return this.gridState.sort.length &&
      this.gridState.sort[0].field === column.field &&
      this.gridState.sort[0].dir === 'asc'
      ? 'fad fa-sort-up'
      : this.gridState.sort.length &&
        this.gridState.sort[0].field === column.field &&
        this.gridState.sort[0].dir === 'desc'
      ? 'fad fa-sort-down'
      : 'fas fa-sort';
  }

  settingsChange() {
    this.globalStatus = this.active ? 'Active' : 'Suspended';
    const data = <RetentionCampaignsSettings>{
      enabled: this.active,
      senderName: this.emailSenderName,
      senderEmail: this.emailSenderEmail,
    };
    this.communicationService.updateRetentionCampaignsSettings(data).subscribe();
  }

  onPreview(campaign: Campaign) {
    this.campaignId = campaign.id;
    this.retentionCampaignsService.retentionCampaignChanged.next(campaign.id);
    this.isOpenRecipientsModal = true;
    this.patientPanelOpen = false;
    this.selectedTitle = 'Next Recipients';
    this.recipientRequestType = RecipientRequestType.Preview;

    this.actionPanelService.actionPanelClosed$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.patientPanelOpen = false;
    });
  }

  onHistoryClick(campaign: Campaign) {
    this.campaignId = campaign.id;
    this.retentionCampaignsService.retentionCampaignChanged.next(campaign.id);
    this.isOpenRecipientsModal = true;
    this.patientPanelOpen = false;
    this.selectedTitle = 'Previous Recipients';
    this.recipientRequestType = RecipientRequestType.PreviousRun;

    this.actionPanelService.actionPanelClosed$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.patientPanelOpen = false;
    });
  }

  public getRecipientsTitle(): string {
    return this.selectedTitle;
  }

  public getRecipientRequestType(): RecipientRequestType {
    return this.recipientRequestType;
  }

  public haveRecipients(recipients: string) {
    if (!recipients) return false;

    var result = parseInt(recipients[0]) > 0;
    return result;
  }

  public hasNextSendDate(campaign: any) {
    if (campaign['nextSendDate']) {
      return false;
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
