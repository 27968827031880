import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-generic-send-email-popover',
  templateUrl: './generic-send-email-popover.component.html',
  styleUrls: ['./generic-send-email-popover.component.less'],
})
export class GenericSendEmailPopoverComponent implements OnInit {
  @Input() buttonClass = '';
  @Input() buttonText: string;
  @Input() iconClass = '';
  @Input() toolTipTitle: string;
  @Input() initialEmailAddress: string;
  @Input() disabled = false;
  @Input() sendEmailObservable: (email: string) => Observable<boolean>;
  emailAddress = new FormControl('', Validators.required);

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    if (this.initialEmailAddress) this.emailAddress.setValue(this.initialEmailAddress);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initialEmailAddress && changes.initialEmailAddress.currentValue)
      this.emailAddress.setValue(changes.initialEmailAddress.currentValue);
  }

  onSendClick() {
    const dialogData = {
      width: '250px',
      data: {
        showCancel: false,
        title: 'Sent',
        content: `Email successfully sent to ${this.emailAddress.value}`,
        confirmButtonText: 'Ok',
      },
    };
    this.sendEmailObservable(this.emailAddress.value).subscribe((result) => {
      if (result) {
        const dialogRef = this.dialog.open(GenericDialogComponent, dialogData);
      }
    });
  }
}
