import { Directive, Input, ElementRef, AfterViewInit, AfterContentInit, OnInit, OnChanges } from '@angular/core';

@Directive({
  selector: '[appDetermineMaxScrollHeight]'
})
export class DetermineMaxScrollHeightDirective implements OnChanges{

  @Input() addHeight: number = 0;
  @Input() public nRows: number = 1; //when making template with multiple perfect-scrollbar in a column we need to adjust the calculation per n of rows (ie number of p-scrollbars)
  @Input() public itemsToWatch: Array<any>|any; //any value changes will trigger ngOnChanges (removal/add child)
  @Input() public applyToHeight: boolean = false; //if this turns out to only be necessary for pdf-viewer then we can remove and add to document-detail component instead #326
  public constructor(private el: ElementRef) { }

  ngOnChanges(){
    this.determineScrollHeight();
  }

  determineScrollHeight(){
    let maxHeight =  window.innerHeight - this.el.nativeElement.getBoundingClientRect().top + this.addHeight;
    if (this.applyToHeight){
      this.el.nativeElement.style.height = String(maxHeight  / this.nRows ) + 'px';
    }
    else{
      this.el.nativeElement.style.maxHeight =  String(maxHeight  / this.nRows ) + 'px';
    }
  }


}
