import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormioCustomComponent } from 'angular-formio-emilyemr';

@Component({
  selector: 'formio-texfield-wrapper',
  template: ''
//   templateUrl: './text-field-wrapper.component.html',
//   styleUrls: ['./text-field-wrapper.component.scss']
})
export class CustomTextfieldWrapperComponent implements FormioCustomComponent<string> {
  @Input()
  value: string;

  @Output()
  valueChange = new EventEmitter<string>();

  @Input()
  disabled: boolean;

  updateValue(payload: string) {
    this.value = payload; // Should be updated first
    this.valueChange.emit(payload); // Should be called after this.value update
  }
}
