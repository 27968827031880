<ngx-loading [show]="loading" id="gridDisableDiv"></ngx-loading>

<div class="px-4 py-3">
  <div [ngStyle]="{ height: 'calc(100dvh - 75px)' }">
    <div class="form-types d-flex">
      <div class="form-types d-flex" style="position: relative">
        <ngx-loading [show]="disableTabsAndGrid" id="gridDisableDiv"></ngx-loading>
        <button
          *ngFor="let formType of formTypes"
          class="btn btn--tab text-uppercase em-btn-nav-tab mr-2"
          [class.selected]="activeTab === formType"
          (click)="selectTab(formType)"
        >
          {{ formType.name }}
        </button>
      </div>
      <div *ngIf="!editMode" class="d-flex ml-auto btn-group me-3">
        <button type="button" class="btn em-btn em-btn-green mb-1" (click)="addHandler()">
          <i class="fal fa-plus-circle"></i> &nbsp;Add eForm
        </button>
        <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
          <button type="button" class="btn em-btn em-btn-green mb-1" ngbDropdownToggle></button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button ngbDropdownItem (click)="addFromTemplateHandler()">Add From eForms Template Library</button>
          </div>
        </div>
      </div>
      <div *ngIf="editMode" class="d-flex ml-auto">
        <button type="button" class="btn em-btn em-btn-green flex-nowrap mr-1 mb-1" (click)="saveHandler()">
          <i class="fal fa-save"></i>
          &nbsp;Save
        </button>
        <button type="button" class="btn em-btn em-btn-green flex-nowrap mr-1 mb-1" (click)="cancelHandler()">
          <i class="fal fa-ban"></i>
          &nbsp;Cancel
        </button>
      </div>
    </div>
    <div class="header-line"></div>
    <div class="row mt-2">
      <div class="col-3">
        <perfect-scrollbar appDetermineMaxScrollHeight [itemsToWatch]="[forms]">
          <ngx-loading [show]="disableTabsAndGrid" id="gridDisableDiv"></ngx-loading>
          <kendo-grid
            [data]="gridView"
            [pageSize]="gridState.take"
            [skip]="gridState.skip"
            [sort]="gridState.sort"
            [sortable]="true"
            [selectable]="true"
            [style.height.%]="100"
            (edit)="editHandler($event)"
            (remove)="deactivateHandler($event)"
            (selectionChange)="onSelectRow($event)"
            kendoGridSelectBy="id"
            [selectedKeys]="selectedKeys"
            [rowClass]="rowCallback"
          >
            <!--     <ng-template kendoGridToolbarTemplate>
      <div class="d-flex">
        <span class="pl-4 search-box border border-radius">
          <span class="fa fa-search"></span>
          <mat-form-field class="w-75 p-3">
            <input
              matInput
              placeholder="Search..."
              aria-label="Search Forms"
              [(ngModel)]="searchValue"
              [formControl]="searchCtrl"
            />
            <button
              mat-button
              *ngIf="searchValue"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="searchValue = ''"
            >
              <mat-icon><i class="fa fa-times-circle"></i></mat-icon>
            </button>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let form of filteredForms | async" [value]="form.name">
                <span>{{ form.name }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </span>
      </div>
      <button kendoGridAddCommand class="btn kendo-btn kendo-btn-green em-btn" icon="plus-outline">Add Form</button>
    </ng-template> -->
            <kendo-grid-column
              field="name"
              title="Name"
              [headerClass]="'teal-background white-font'"
              [class]="{ nameColumn: true }"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="d-flex">
                  <span>{{ dataItem.name }}</span>
                  <div *ngIf="dataItem.isSharedForm" class="ml-auto">
                    <i class="far fa-cloud" title="Shared Form"></i>
                  </div>
                </div>
                <div *ngIf="dataItem.notes && dataItem.notes !== ''">
                  <span> (<strong>Notes:</strong> {{ dataItem.notes }}) </span>
                </div>
              </ng-template>
            </kendo-grid-column>
            <ng-template kendoGridDetailTemplate let-dataItem>
              <section>
                <div>
                  <strong>Service:</strong>
                  {{ dataItem.serviceTemplateType ? dataItem.serviceTemplateType.name : 'None' }}
                </div>
                <div><strong>Created Date:</strong> {{ dataItem.createdDate | date : 'yyyy-MM-dd' }}</div>
                <div>
                  <strong>Created by User:</strong>
                  {{
                    dataItem.createdByUser
                      ? dataItem.createdByUser.firstName + ' ' + dataItem.createdByUser.lastName
                      : 'Anonymous'
                  }}
                </div>
                <div *ngIf="dataItem.isSharedForm">
                  <div>
                    <strong>Shared by User:</strong>
                    {{
                      dataItem.sharedByUser
                        ? dataItem.sharedByUser.firstName + ' ' + dataItem.sharedByUser.lastName
                        : 'Anonymous'
                    }}
                  </div>
                  <div>
                    <strong>Shared by Clinic:</strong>
                    {{ dataItem.sharedByClinic ? dataItem.sharedByClinic.name : 'Anonymous' }}
                  </div>

                  <div><strong>Shared Date:</strong> {{ dataItem.sharedFormCreationDate | date : 'yyyy-MM-dd' }}</div>
                </div>
              </section>
            </ng-template>
            <kendo-grid-command-column
              title="Actions"
              min-width="115px"
              [width]="115"
              [headerClass]="'teal-background white-font'"
            >
              <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                <div *ngIf="!dataItem.isDeleted">
                  <button kendoGridEditCommand class="btn kendo-btn" kendoTooltip title="Edit Form Details">
                    <i class="fas fa-pencil"></i>
                  </button>
                  <button
                    class="btn kendo-btn k-button"
                    (click)="editFormHandler(dataItem)"
                    kendoTooltip
                    tooltipClass="em-tooltip"
                    position="top"
                    title="Edit Form"
                  >
                  <i class="far fa-edit"></i>
                  </button>
                  <button
                    class="btn kendo-btn k-button"
                    (click)="cloneFormHandler(dataItem.id)"
                    kendoTooltip
                    title="Clone Form"
                  >
                    <i class="fal fa-copy"></i>
                  </button>
                  <button kendoGridRemoveCommand class="btn kendo-btn" kendoTooltip title="Deactivate Form">
                    <i class="fas fa-minus-circle"></i>
                  </button>
                </div>
                <div *ngIf="dataItem.isDeleted" class="center">
                  <label class="switch align-text-top" kendoTooltip title="Activate Form"
                    ><input type="checkbox" (click)="activateFormHandler(dataItem)" /><span class="slider round"></span
                  ></label>
                </div>
              </ng-template>
            </kendo-grid-command-column>
          </kendo-grid>
        </perfect-scrollbar>
      </div>
      <div class="col-9 d-flex flex-column light-grey-background">
        <perfect-scrollbar>
          <div
            class="w-100 align-items-start justify-content-center px-0 light-grey-background"
            [ngStyle]="{ height: '85dvh' }"
          >
            <div *ngIf="formSelected">
              <app-form-builder
                *ngIf="editMode"
                [form]="this.editingDataItem"
                [refreshBuilder]="formRefreshed$"
              ></app-form-builder>
              <div *ngIf="!editMode" class="card">
                <div class="card-body">
                  <div class="card bg-light p-3">
                    <app-form-viewer
                      [form]="this.selectedDataItem"
                      [editable]="false"
                      [refreshViewer]="formRefreshed$"
                    ></app-form-viewer>
                  </div>
                </div>
              </div>
              <div *ngIf="!editMode" class="mt-2">
                <button
                  type="button"
                  class="btn em-btn em-btn-green flex-nowrap mr-1 mb-1"
                  (click)="shareFormHandler(this.selectedDataItem)"
                >
                  <i class="fas fa-share-alt"></i>
                  &nbsp;Share in Library
                </button>
                <p class="form-text text-muted">Share this form with other clinics in Emily.</p>
                <br />
              </div>
            </div>
            <div
              *ngIf="!formSelected"
              frameBorder="0"
              class="w-100 h-100 d-flex align-items-start justify-content-center px-0 pb-2 light-grey-background"
            >
              <span class="m-auto font-weight-bold" style="font-size: 2em">Please select a form.</span>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</div>

<app-actionpanel></app-actionpanel>
