import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Product } from '@models/product';
import { ClinicProductsService } from '@services/clinic-products.service';
import { ProductsService } from '@services/products.service';
import { Subject, combineLatest } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.less'],
})
export class AddProductComponent implements OnInit, OnDestroy {
  unsub: Subject<void>;
  loading = false;

  productsForm = this.formBuilder.group({
    search: this.formBuilder.control(''),
    brandGroups: this.formBuilder.array([]),
  });

  get brandGroups() {
    return this.productsForm.controls['brandGroups'] as FormArray;
  }

  get searchControl() {
    return this.productsForm.controls['search'] as FormControl;
  }

  constructor(
    public dialogRef: MatDialogRef<AddProductComponent>,
    private productsService: ProductsService,
    private clinicProductsService: ClinicProductsService,
    private formBuilder: FormBuilder
  ) {}

  errorMessage: string;

  ngOnInit() {
    this.unsub = new Subject();
    this.loading = true;

    combineLatest([
      this.productsService.getProducts(),
      this.clinicProductsService.getProducts(),
      this.clinicProductsService.getListsForNewProduct().pipe(
        map((lists) => {
          lists.productBrands.push({ name: 'No Brand', id: 0, clinicProducts: [] });
          return lists;
        })
      ),
      this.searchControl.valueChanges.pipe(startWith('')),
    ])
      .pipe(takeUntil(this.unsub))
      .subscribe(([products, clinicProducts, newProductData, searchString]) => {
        const filteredProducts = this.filterProducts(products, searchString);
        this.brandGroups.clear();
        for (let productBrand of newProductData.productBrands) {
          const brandProducts = filteredProducts.filter((product) => product.productBrandId === productBrand.id);
          const productControls = brandProducts.map((product) => {
            const addDisabled = clinicProducts.some((cp) => cp.productId == product.id);
            return this.formBuilder.group({
              id: this.formBuilder.control(product.id),
              name: this.formBuilder.control(product.name),
              selected: this.formBuilder.control({ value: false, disabled: addDisabled }),
            });
          });

          const groupToAdd = this.formBuilder.group({
            brandName: this.formBuilder.control({ value: productBrand.name, disabled: true }),
            products: this.formBuilder.array(productControls),
          });
          this.brandGroups.push(groupToAdd);
        }

        this.loading = false;
      });
  }

  brandProducts(brandIndex: number) {
    return this.brandGroups.at(brandIndex).get('products') as FormArray;
  }

  onSave() {
    const selectedProductIds = this.brandGroups.controls.reduce((result, current) => {
      const productControls = (current.get('products') as FormArray).controls;
      productControls.forEach((productControl) => {
        if (productControl.get('selected').value === true) {
          result.push(productControl.get('id').value);
        }
      });
      return result;
    }, []);
    this.dialogRef.close(selectedProductIds);
  }

  filterProducts(products: Product[], searchString: string) {
    if (!searchString) return products.slice();
    searchString = searchString.toLocaleLowerCase();
    return products.filter((product) => product.name.toLocaleLowerCase().includes(searchString));
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
