import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Doctor } from '@models/doctor';
import { Address } from '@models/address';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { ValidationService } from '@services/validation.service';
import { FormatterService } from '@services/formatter.service';
import { GeographyService } from '@services/geography.service';
import { Clinic } from '@models/clinic';
import { ClinicsService } from '@services/clinics.service';

import { DoctorsService } from '@services/doctors.service';
import { MedicalCollege } from '@models/medical-college';
@Component({
  selector: 'app-edit-doctor',
  templateUrl: './edit-doctor.component.html',
  styleUrls: ['./edit-doctor.component.less'],
})
export class EditDoctorComponent implements OnInit, OnDestroy {
  editDoctorPanelVisible = false;
  addOrEdit = 'Add';

  doctorIdParam: string;

  proTitle: FormControl;
  firstName: FormControl;
  lastName: FormControl;
  addressAddress1: FormControl;
  addressAddress2: FormControl;
  addressCity: FormControl;
  addressCountry: FormControl;
  addressPostalCode: FormControl;
  addressProvince: FormControl;
  phoneNumber: FormControl;
  faxNumber: FormControl;
  email: FormControl;
  website: FormControl;
  provincialIdNumber: FormControl;
  cpsNumber: FormControl;
  specialty: FormControl;
  isNew: boolean;

  selectedDoctor: Doctor;
  editedDoctor: Doctor;
  selectedAddress: Address;
  editedAddress: Address;

  countriesOfTheWorld: string[] = [];
  provincesAndStates: string[] = [];

  submitButtonDisabledState = true;

  clinic: Clinic;

  unsub: Subject<void> = new Subject<void>();

  specialties: string[];
  medicalColleges: MedicalCollege[];
  selectedMedicalCollege: MedicalCollege;

  constructor(
    private doctorsService: DoctorsService,
    private catalogueUpdatesService: CatalogueUpdatesService,
    public validationService: ValidationService,
    public formatterService: FormatterService,
    private geographyService: GeographyService,
    private route: ActivatedRoute,
    private router: Router,
    private clinicsService: ClinicsService,
    private doctorService: DoctorsService
  ) {
    this.proTitle = new FormControl();
    this.firstName = new FormControl();
    this.lastName = new FormControl();
    this.addressAddress1 = new FormControl();
    this.addressAddress2 = new FormControl();
    this.addressCity = new FormControl();
    this.addressCountry = new FormControl();
    this.addressPostalCode = new FormControl('', null);
    this.addressProvince = new FormControl();
    this.phoneNumber = new FormControl('', this.validationService.validatePhoneNumber);
    this.faxNumber = new FormControl('', this.validationService.validatePhoneNumber);
    this.email = new FormControl('', [Validators.email]);
    this.website = new FormControl();
    this.provincialIdNumber = new FormControl();
    this.cpsNumber = new FormControl();
    this.specialty = new FormControl();
    this.specialties = ['GP', 'Dermatologist', 'Cardiologist', 'Pediatrician', 'Surgeon', 'Plastic Surgery'];
  }

  ngOnInit() {
    for (const key in this.geographyService.countriesByName()) {
      if (this.geographyService.countriesByName().hasOwnProperty(key)) {
        this.countriesOfTheWorld.push(key);
      }
    }

    this.doctorService
      .getMedicalColleges()
      .pipe(takeUntil(this.unsub))
      .subscribe((colleges) => {
        this.medicalColleges = colleges;
      });
    this.selectedDoctor = this.initDoctor(this.selectedDoctor, this.selectedAddress);
    this.editedDoctor = this.initDoctor(this.editedDoctor, this.editedAddress);

    this.isNew = true;
    this.addOrEdit = 'Add';
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      this.doctorIdParam = params['doctid'];
      if (this.doctorIdParam !== '_' && this.doctorIdParam != null) {
        this.doctorsService.getDoctorById(this.doctorIdParam).subscribe((snapshot) => {
          const doc = snapshot as Doctor;
          this.firstName.patchValue(doc.firstName);
          this.lastName.patchValue(doc.lastName);
          this.selectedDoctor = doc;
          this.editedDoctor = doc;
          this.updateProvincesStates();
          this.updateSubmitButtonState();
          this.selectedMedicalCollege = this.medicalColleges.find((mc) => mc.id == doc.medicalCollegeId);
          this.isNew = false;
          this.addOrEdit = 'Edit';
        });
      } else {
        this.clinicsService.getClinics().subscribe((res) => {
          this.clinic = res[0];
          if (this.clinic.address) {
            if (this.countriesOfTheWorld.includes(this.clinic.address.country)) {
              this.selectedDoctor.address.country =
                this.countriesOfTheWorld[this.countriesOfTheWorld.indexOf(this.clinic.address.country)];
              this.editedDoctor.address.country =
                this.countriesOfTheWorld[this.countriesOfTheWorld.indexOf(this.clinic.address.country)];
            }
            this.updateProvincesStates();
            if (this.provincesAndStates.includes(this.clinic.address.province)) {
              this.selectedDoctor.address.province =
                this.provincesAndStates[this.provincesAndStates.indexOf(this.clinic.address.province)];
              this.editedDoctor.address.province =
                this.provincesAndStates[this.provincesAndStates.indexOf(this.clinic.address.province)];
            }
          }
        });
      }
    });
  }

  onChangeCountry() {
    this.updateProvincesStates();
    this.addressPostalCode.setValue('');
  }

  updateProvincesStates() {
    this.provincesAndStates = this.geographyService.updateProvinceStateList(this.editedDoctor.address.country);
    if (this.editedDoctor.address.country.toLowerCase() === 'canada') {
      this.addressPostalCode.validator = this.validationService.validatePostalCode;
    } else if (this.editedDoctor.address.country.toLowerCase() === 'united states') {
      this.addressPostalCode.validator = this.validationService.validateZipCode;
    } else {
      this.addressPostalCode.validator = null;
    }
  }

  onMedicalCollegeSwitched(medicalCollegeId: number) {
    this.selectedMedicalCollege = this.medicalColleges.find((mc) => mc.id == medicalCollegeId);
    this.editedDoctor.medicalCollegeId = this.selectedMedicalCollege.id;
  }

  updateSubmitButtonState() {
    if (
      !this.firstName.value ||
      !this.editedDoctor.firstName ||
      !this.lastName.value ||
      !this.editedDoctor.lastName ||
      (this.email.value !== '' && this.email.hasError('email')) ||
      this.phoneNumber.hasError('phoneError') ||
      this.faxNumber.hasError('phoneError') ||
      this.website.hasError('websiteError') ||
      this.addressPostalCode.hasError('postalCodeError') ||
      this.addressPostalCode.hasError('zipCodeError')
    ) {
      this.submitButtonDisabledState = true;
    } else {
      this.submitButtonDisabledState = false;
    }
  }

  updateDoctor() {
    if (this.isNew) {
      this.doctorsService.addDoctor(this.editedDoctor).subscribe(() => {
        this.catalogueUpdatesService.refreshRequired = true;
        this.catalogueUpdatesService.catalogueUpdateComplete();
        this.router.navigate(['/management/address-book/doctors', { outlets: { 'action-panel': null } }]);
      });
    } else {
      this.doctorsService.updateDoctor(this.editedDoctor).subscribe(() => {
        this.catalogueUpdatesService.refreshRequired = true;
        this.catalogueUpdatesService.catalogueUpdateComplete();
        this.router.navigate(['/management/address-book/doctors', { outlets: { 'action-panel': null } }]);
      });
    }
  }

  cancelUpdate() {
    this.catalogueUpdatesService.refreshRequired = false;
    this.catalogueUpdatesService.catalogueUpdateComplete();
    this.router.navigate(['/management/address-book/doctors', { outlets: { 'action-panel': null } }]);
  }

  initDoctor(doctor: Doctor, address: Address) {
    address = {
      address1: '',
      address2: '',
      city: '',
      country: '',
      postalCode: '',
      province: '',
    };
    doctor = {
      doctorId: 0,
      proTitle: 'Dr.',
      firstName: '',
      lastName: '',
      address: address,
      phoneNumber: '',
      faxNumber: '',
      email: '',
      website: '',
      hoursOfOperation: null,
      specialty: '',
    };
    return doctor;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
