
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'convertUTC' })
export class ConvertUTCPipe implements PipeTransform {
  constructor( ) {}

  //currently all dates generated from appointment date (DateTime) has SOME offset added when converting into the viewmodel's DateTimeOffset
  //on my local machine this converts to -7 because thats PST , but on dev and prod machines it converts to UTC while also returning the LOCAL time (wrong)
  //this is because the DateTime in UTC would need to know the client offset in order to properly append the LOCAL time in UTC format
  /**
   * For Example :
   *  if an appointment is in Kelowna (PST) at 10:45am on July 28, 2021
   *  the date when converted into a DateTimeOffset (for example in ServiceChartEntries or PlannedTreatment.PlannedDate) would return the following by dev api:
   *  2021-07-28T10:45:00+00:00
   *  that date is INCORRECT because when converted by client to local time (-7h) it would be displayed as
   *  Wed Jul 28 2021 03:45:00 GMT-0700 (Pacific Daylight Time)
   *
   *  the correct UTC representation for that date and time is the following in utc:
   *  2021-07-28T17:45:00+00:00
   */
   transform(date: moment.Moment|string, includeTime = false) {
    return this.returnDateUTC(date) + (includeTime ? ' ' + this.returnTimeUTC(date) : '');
  }



  public returnDateUTC(date: moment.Moment|string) {
    return moment.utc(date).format('YYYY-MM-DD');
  }

   public returnTimeUTC(date: moment.Moment|string) {
    return moment.utc(date).format('LT');
  }
}
