<div class="d-flex w-100 h-100">
  <app-calnav *ngIf="displayCalendar && !mobileView" (reloadMainSchedule)="this.changeSelectedClinic()"> </app-calnav>
  <mat-sidenav-container hasBackdrop="false" class="flex-grow-1">
    <mat-sidenav [(opened)]="actionPanelOpened" class="action-panel-sidenav" mode="side" position="end" #actionPanel>
    </mat-sidenav>
    <mat-sidenav-content>
      <app-appointments [mobileView]="mobileView"></app-appointments>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<app-actionpanel [ngClass]="{ mobile: mobileView }"></app-actionpanel>
