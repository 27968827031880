import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ServiceDetailEventTypes } from '@models/service/service-detail-event-types';
import { Service } from '@models/service/service';

@Injectable({
  providedIn: 'root'
})
export class ToolbarEventService {
  private toolbarFormSource = new Subject<any>();
  private toolbarControlSource = new Subject<any>();

  constructor() { }

  updateToToolbarForm(formValid: boolean) {
    this.toolbarFormSource.next({ event: ServiceDetailEventTypes.UpdateToServiceForm, formValid: formValid });
  }

  resetForm() {
    this.toolbarControlSource.next({ event: ServiceDetailEventTypes.ResetToolbar });
  }

  togglePinDrop(service: Service, forAssociatedService: boolean = false) {
    this.toolbarControlSource.next({ event:   ServiceDetailEventTypes.TogglePinDrop, service: service });
  }

  getFormSource(): Observable<any> {
    return this.toolbarFormSource.asObservable();
  }

  getToolbarControlSource(): Observable<any> {
    return this.toolbarControlSource.asObservable();
  }
}
