import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ObservationType } from '@models/observation/observation';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ObservationTypesService {

  public observationTypes: Map<number, ObservationType>;

  constructor(private http: HttpClient) {
    this.observationTypes = new Map();
  }

  getAllObservationTypes() {
    return this.http.get<ObservationType[]>(environment.baseUrl + 'api/ObservationType').pipe(
      map(response => {
        response.forEach(obrType => {
          this.observationTypes.set(obrType.id, obrType);
        });
        return response;
      })
    );
  }

  getObservationTypeByName(name: string) {
    return this.http.get<ObservationType>(environment.baseUrl + 'api/ObservationType/' + name);
  }
}
