<div class="modal-title d-flex" mat-dialog-title>
  <button class="btn ml-auto white-font" (click)="dialogRef.close()">
    <i class="far fa-times"></i>
  </button>
</div>
<app-error *ngIf="hasError" [message]="errorMessage"></app-error>
<div class="modal-container" mat-dialog-content>
  <h6 class="text-center navy-font mb-3">FAX Rx</h6>
  <h6 class="navy-font">Select Pharmacy</h6>
  <label class="container" [ngClass]="{ 'container-disabled': (data?.patient?.preferredPharmacy | json) === '{}' }">
    Preferred Pharmacy:
    <input type="radio" [formControl]="pharmacyMethod" value="default" name="radio" />
    <span class="checkmark"></span>
  </label>
  <div
    class="p-1 border-bottom mb-2 text-elipsis"
    [ngClass]="{ 'container-disabled': pharmacyMethod?.value === 'alternative' }"
  >
    <ng-container *ngIf="data?.patient?.preferredPharmacy">
      {{ data.patient.preferredPharmacy?.name
      }}<ng-container
        *ngIf="
          data.patient.preferredPharmacy?.name ||
          data.patient.preferredPharmacy?.faxNumber ||
          data.patient.preferredPharmacy?.address?.address1 ||
          data.patient.preferredPharmacy?.address?.address1 ||
          data.patient.preferredPharmacy?.address?.city
        "
        >:
      </ng-container>
      {{ data.patient.preferredPharmacy?.faxNumber
      }}<ng-container
        *ngIf="
          (data.patient.preferredPharmacy?.faxNumber && data.patient.preferredPharmacy?.address?.address1) ||
          data.patient.preferredPharmacy?.address?.address2 ||
          data.patient.preferredPharmacy?.address?.city
        "
        >,
      </ng-container>
      {{ data.patient.preferredPharmacy?.address?.address1
      }}<ng-container
        *ngIf="
          (data.patient.preferredPharmacy?.address?.address1 && data.patient.preferredPharmacy?.address?.address1) ||
          data.patient.preferredPharmacy?.address?.city
        "
        >,
      </ng-container>
      {{ data.patient.preferredPharmacy?.address?.address2
      }}<ng-container
        *ngIf="data.patient.preferredPharmacy?.address?.address2 && data.patient.preferredPharmacy?.address?.city"
        >,
      </ng-container>
      {{ data.patient.preferredPharmacy?.address?.city }}
    </ng-container>
  </div>
  <label class="container mt-4"
    >Alternate Pharmacy:
    <input type="radio" [formControl]="pharmacyMethod" value="alternative" name="radio" />
    <span class="checkmark"></span>
  </label>
  <div class="w-100">
    <mat-form-field class="w-100" [floatLabel]="'never'">
      <mat-select
        placeholder="Select Pharmacy"
        [formControl]="pharmacyId"
      >
        <mat-option *ngFor="let pharmacy of pharmacies" [value]="pharmacy.pharmacyId">
          {{ pharmacy?.name
          }}<ng-container
            *ngIf="
              pharmacy?.name ||
              pharmacy?.faxNumber ||
              pharmacy?.address?.address1 ||
              pharmacy?.address?.address1 ||
              pharmacy?.address?.city
            "
            >:
          </ng-container>
          {{ pharmacy?.faxNumber
          }}<ng-container
            *ngIf="
              (pharmacy?.faxNumber && pharmacy?.address?.address1) ||
              pharmacy?.address?.address2 ||
              pharmacy?.address?.city
            "
            >,
          </ng-container>
          {{ pharmacy?.address?.address1
          }}<ng-container
            *ngIf="(pharmacy?.address?.address1 && pharmacy?.address?.address2) || pharmacy?.address?.city"
            >,
          </ng-container>
          {{ pharmacy?.address?.address2
          }}<ng-container *ngIf="pharmacy?.address?.address2 && pharmacy?.address?.city">, </ng-container>
          {{ pharmacy?.address?.city }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="d-flex justify-content-center pb-4" mat-dialog-actions>
  <button type="button" class="btn em-btn em-btn-green mr-4" (click)="dialogRef.close()">CANCEL</button>
  <button
    [disabled]="(pharmacyMethod.value === 'alternative' && !pharmacyId.value) || hasError"
    (click)="dialogRef.close(getFaxNumber())"
    class="btn em-btn em-btn-green"
  >
    Fax
  </button>
</div>
