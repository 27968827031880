import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-invoice-discount',
  templateUrl: './invoice-discount.component.html',
  styleUrls: ['./invoice-discount.component.less'],
})
export class InvoiceDiscountComponent implements OnInit {
  discountValue: number;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
