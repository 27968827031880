<div class="d-flex flex-column h-100">
  <ngx-loading [show]="masterOverlayEnabled" id="overlayDisableDiv"></ngx-loading>

  <div *ngIf="mobileView" class="mobile-nav-container">
    <app-mobile-nav></app-mobile-nav>
  </div>

  <div *ngIf="!mobileView" class="sidenav-and-viewer-container">
    <app-sidenav [ngClass]="{ sideNavCollapsed: !sideNavExpanded }"></app-sidenav>
    <app-viewer [ngClass]="{ sideNavCollapsed: !sideNavExpanded }"></app-viewer>
  </div>
</div>
