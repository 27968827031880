<ngx-loading [show]="loading"></ngx-loading>
<div class="btn-group d-flex align-items-center flex-wrap content">
  <div class="d-flex flex-column mr-4">
    <div class="mt-1 d-flex justify-content-between align-items-center w-100">
      <span class="font-weight-bold d-flex align-self-center">Email: </span>
      <label class="switch align-text-top mb-0 ml-2">
        <input #emailFilterToggle type="checkbox" name="emailFilter" checked (change)="filterByType($event)" />
        <span class="slider round"></span>
      </label>
    </div>
    <div class="mt-1 d-flex justify-content-between align-items-center w-100">
      <span class="font-weight-bold d-flex align-self-center">SMS: </span>
      <label class="switch align-text-top mb-0 ml-2">
        <input #smsFilterToggle type="checkbox" name="smsFilter" checked (change)="filterByType($event)" />
        <span class="slider round"></span>
      </label>
    </div>
  </div>
  <button
    type="button"
    class="btn medium em-btn em-btn-nav-tab mr-4 flex-nowrap"
    [class.selected]="activeDateFilter === 'TODAY'"
    (click)="filterByDate('TODAY')"
  >
    TODAY
  </button>
  <button
    type="button"
    class="btn medium em-btn em-btn-nav-tab mr-4 flex-nowrap"
    [class.selected]="activeDateFilter === 'YESTERDAY'"
    (click)="filterByDate('YESTERDAY')"
  >
    YESTERDAY
  </button>
  <button
    type="button"
    class="btn medium em-btn em-btn-nav-tab mr-4 flex-nowrap"
    [class.selected]="activeDateFilter === 'WEEK'"
    (click)="filterByDate('WEEK')"
  >
    THIS WEEK
  </button>
  <button
    type="button"
    class="btn medium em-btn em-btn-nav-tab mr-4 flex-nowrap"
    [class.selected]="activeDateFilter === 'MONTH'"
    (click)="filterByDate('MONTH')"
  >
    THIS MONTH
  </button>
  <button
    type="button"
    class="btn medium em-btn em-btn-nav-tab mr-4 flex-nowrap"
    [class.selected]="activeDateFilter === 'YTD'"
    (click)="filterByDate('YTD')"
  >
    YTD
  </button>
  <mat-form-field floatLabel="always" class="mr-4 filter-date">
    <mat-label>Date Range</mat-label>
    <input
      matInput
      placeholder="Select"
      [satDatepicker]="picker"
      (dateInput)="filterByDateRange($event)"
      (dateChange)="filterByDateRange($event)"
      [(ngModel)]="dateRange"
    />
    <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
    <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
  </mat-form-field>
  <mat-form-field class="mr-4">
    <input
      matInput
      placeholder="Search..."
      aria-label="Search Doctors"
      [(ngModel)]="searchValue"
      (ngModelChange)="search($event)"
    />
    <button
      mat-button
      *ngIf="searchValue"
      class="mb-2"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="search('')"
    >
      <mat-icon><i class="fa fa-times-circle"></i></mat-icon>
    </button>
  </mat-form-field>
  <button
    type="button"
    class="btn medium em-btn grey-five-background flex-nowrap"
    [class.selected]="activeDateFilter !== ''"
    (click)="smsFilterToggle.checked = true; emailFilterToggle.checked = true; clearFilter()"
    [disabled]="activeDateFilter === ''"
  >
    CLEAR FILTERS
  </button>
</div>
<kendo-grid
  class="emr-kendo-grid communications hide-default-sort mt-3 flex-grow-1 minh-0"
  [data]="gridView"
  appDetermineMaxScrollHeight
  [itemsToWatch]="activeDateFilter"
  [pageSize]="gridState.take"
  [pageable]="true"
  [skip]="gridState.skip"
  [sort]="gridState.sort"
  [filter]="gridState?.filter"
  [sortable]="{
    allowUnsort: false,
    mode: 'single'
  }"
  (pageChange)="pageChange($event)"
  (filterChange)="filterChange($event)"
  (sortChange)="sortChange($event)"
  #grid="kendoGrid"
>
  <kendo-grid-column
    field="category"
    title="Category"
    class="font-weight-bold"
    [sortable]="false"
    [width]="200"
  ></kendo-grid-column>
  <kendo-grid-column field="type" title="Via" [sortable]="false" [width]="80">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <span
        class="font-weight-bold"
        [ngClass]="{
          'email-type': dataItem.type.toLowerCase() === 'email',
          'sms-type': dataItem.type.toLowerCase() === 'sms'
        }"
      >
        {{ dataItem.type }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="patientFullName" title="To" [sortable]="false" [width]="220">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <div (click)="goToPatient(dataItem)" class="pointer underline">
        {{ dataItem.patientFullName }}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="patientContact" title="Email/Mobile" [sortable]="false" [width]="250"></kendo-grid-column>
  <kendo-grid-column field="campaignTitle" title="Campaign Title" [width]="300"></kendo-grid-column>
  <kendo-grid-column field="date" title="Date Sent" [width]="170">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <div
        [attr.data-test-id]="'communication-timestamp'"
        [attr.data-test-value]="dataItem?.date | date : 'dd.MM.yyyy &nbsp; HH:mm:ss.SSS'"
      >
        {{ dataItem.date | date : 'yyyy-MM-dd h:mm a' }}
      </div>
    </ng-template>
  </kendo-grid-column>
  <!--<kendo-grid-column field="category" title="Category" [sortable]="false"></kendo-grid-column>-->
  <kendo-grid-column field="status" title="Status" [sortable]="false" [width]="110"></kendo-grid-column>
  <kendo-grid-column field="confirmationDate" title="Date Confirmed" [sortable]="false" [width]="170">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{ dataItem.status == 'Confirmed' ? (dataItem.confirmationDate | date : 'yyyy-MM-dd h:mm a') : '' }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Details" [sortable]="false">
    <ng-template let-dataItem kendoGridCellTemplate>
      <button
        *ngIf="dataItem.category == 'Invoice' && dataItem.reason"
        type="button"
        class="btn-clean px-0 text-left underline"
        (click)="navigateToPatientInvoice(dataItem.patientId, dataItem.reason)"
      >
        Invoice ID: {{ dataItem.reason }}
      </button>
      <button
        *ngIf="dataItem.category == 'Photo' && dataItem.reason"
        class="btn-clean px-0 text-left underline"
        (click)="openPhoto(dataItem.reason)"
      >
        Photo
      </button>
      <ng-container *ngIf="dataItem.category != 'Photo' && dataItem.category != 'Invoice'">
        {{ dataItem.reason }}
      </ng-container>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<app-actionpanel></app-actionpanel>
