<div class="container-fluid d-flex h-100 flex-column panel-container overflow-auto">
  <div class="flex-grow-1 service-details-container">
    <ng-container
      *ngIf="
        service?.serviceDetailTemplateId === serviceDetailTemplate.Injections ||
          service?.serviceDetailTemplateId === serviceDetailTemplate.Coolsculpting ||
          service?.serviceDetailTemplateId === serviceDetailTemplate.CoolTone ||
          service?.serviceDetailTemplateId === serviceDetailTemplate.Deoxycholate;
        else notAdvanced
      "
    >
      <app-advanced-treatment [service]="service"></app-advanced-treatment>
    </ng-container>
    <ng-template #notAdvanced>
      <ng-container *ngIf="isConsult || service?.serviceDetailTemplateId === serviceDetailTemplate.TreatmentPlan">
        <app-treatment-plan></app-treatment-plan>
      </ng-container>
    </ng-template>
  </div>
</div>
