import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { PhotoGalleryMode } from './../photo-filter';
import { SeriesType } from './../../models/photo/series-type';
import { PhotoMetaData } from './../../models/photo/photo-meta-data';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PhotoFilter } from '../photo-filter';
import * as moment from 'moment';
import { PhotoConsentType } from '../../models/photo/photo-consent-type';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-generic-photo-list',
  templateUrl: './generic-photo-list.component.html',
  styleUrls: ['./generic-photo-list.component.less'],
})
export class GenericPhotoListComponent implements OnInit {
  private unsub: Subject<void> = new Subject<void>();
  private _photos: PhotoMetaData[];

  _selectedPhotosForSeriesCreation: PhotoMetaData[] = [];
  @Output() selectedPhotosForSeriesCreationChange: EventEmitter<PhotoMetaData[]> = new EventEmitter<PhotoMetaData[]>(); //for creating series
  private _seriesMode: boolean;
  @Input() set seriesMode(seriesMode: boolean) {
    this._seriesMode = seriesMode;
    this._selectedPhotosForSeriesCreation = []; //reset selections for b/a
  }
  get seriesMode() {
    return this._seriesMode;
  }
  @Input() set photos(photos: PhotoMetaData[]) {
    this.photoGroupings = this.groupPhotoData(photos);
    this._photos = photos;
    this.date = new Date();
  }

  @Input() filter: PhotoFilter;
  @Input() selectedPhoto: PhotoMetaData;
  @Output() selectedPhotoChange: EventEmitter<PhotoMetaData> = new EventEmitter<PhotoMetaData>(); //for gallery browsing

  photoGroupings: {
    groupingHeader: string;
    photos: PhotoMetaData[];
  }[] = null;
  PhotoConsentType = PhotoConsentType;
  PhotoGalleryMode = PhotoGalleryMode;
  SeriesType = SeriesType;
  date = new Date();

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.photoGroupings = this.groupPhotoData(this._photos);
    this.prepareForSelectedPhotoChangesWithinLightbox();
  }

  private prepareForSelectedPhotoChangesWithinLightbox() {
    this.filter.incrementSelectedPhoto$.pipe(takeUntil(this.unsub)).subscribe((increment: number) => {
      let selectedGroupingIndex = this.photoGroupings.findIndex(
        (grouping) => grouping.photos.find((p) => this.filter.selectedPhoto.id == p.id) != null
      );
      let selectedGrouping = this.photoGroupings[selectedGroupingIndex];
      let indexWithinGrouping = selectedGrouping.photos.findIndex(
        (p: PhotoMetaData) => this.filter.selectedPhoto.id == p.id
      );
      if (indexWithinGrouping + increment == selectedGrouping.photos.length || indexWithinGrouping + increment == -1) {
        let newIndex =
          selectedGroupingIndex == 0 && increment == -1
            ? this.photoGroupings.length - 1
            : selectedGroupingIndex == this.photoGroupings.length - 1 && increment == 1
            ? 0
            : selectedGroupingIndex + increment;
        this.filter.setSelectedPhoto(
          this.photoGroupings[newIndex].photos[increment == 1 ? 0 : this.photoGroupings[newIndex].photos.length - 1],
          this.photos
        );
      } else {
        let newIndex =
          indexWithinGrouping == 0 && increment == -1
            ? selectedGrouping.photos.length - 1
            : indexWithinGrouping == selectedGrouping.photos.length - 1 && increment == 1
            ? 0
            : indexWithinGrouping + increment;
        this.filter.setSelectedPhoto(selectedGrouping.photos[newIndex], this.photos);
      }
    });
  }

  imageClicked(image: PhotoMetaData) {
    if (this.seriesMode) {
      let index = this._selectedPhotosForSeriesCreation.findIndex((selectedImage) => selectedImage.id == image.id);

      if (index == -1) {
        this._selectedPhotosForSeriesCreation.push(image);
      } else {
        this._selectedPhotosForSeriesCreation.splice(index, 1);
      }
      this.selectedPhotosForSeriesCreationChange.emit([].concat(this._selectedPhotosForSeriesCreation));
    } else {
      this.selectedPhotoChange.emit(image);
    }
  }

  private groupPhotoData(photos: PhotoMetaData[]): { groupingHeader: string; photos: PhotoMetaData[] }[] {
    if (!photos || !this.filter) return null;

    switch (this.filter.gallery_mode) {
      case PhotoGalleryMode.Consultation:
        return this.groupPhotosByPatient(photos);
      case PhotoGalleryMode.Patient:
        return this.groupPhotosByDate(photos);
      case PhotoGalleryMode.Global:
        return this.groupPhotosByPatient(photos);
      case PhotoGalleryMode.Clinic:
        return this.noGrouping(photos);
      default:
        return this.groupPhotosByPatient(photos);
    }
  }

  private groupPhotosByDate(photos: PhotoMetaData[]): { groupingHeader: string; photos: PhotoMetaData[] }[] {
    if (!photos || photos.length == 0) return null;
    const groups = photos.reduce((groups, photo) => {
      const date = moment(photo.dateTaken).format('MMMM DD, YYYY');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(photo);
      return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((date) => {
      const sortedGroups = groups[date].sort((a: PhotoMetaData, b: PhotoMetaData) => {
        return moment(b.dateTaken).diff(moment(a.dateTaken));
      });

      return {
        groupingHeader: date,
        photos: sortedGroups,
      };
    });
    // Sort the Groups of Dates
    groupArrays.sort((a: any, b: any) => {
      return moment(b.photos[0].dateTaken).diff(moment(a.photos[0].dateTaken));
    });
    return groupArrays;
  }

  private groupPhotosByPatient(photos: PhotoMetaData[]): { groupingHeader: string; photos: PhotoMetaData[] }[] {
    const groups = photos.reduce((groups, photo) => {
      const userName = photo.patientFullName;
      if (!groups[userName]) {
        groups[userName] = [];
      }
      groups[userName].push(photo);
      return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((userName) => {
      return {
        groupingHeader: userName,
        photos: groups[userName],
      };
    });

    if (this.filter.gallery_mode == PhotoGalleryMode.Consultation) {
      let count = 1;
      groupArrays.forEach((e) => {
        e.groupingHeader = 'Patient ' + count;
        count++;
      });
    }
    return groupArrays;
  }

  private noGrouping(photos: PhotoMetaData[]): { groupingHeader: string; photos: PhotoMetaData[] }[] {
    return [
      {
        groupingHeader: '',
        photos: photos,
      },
    ];
  }

  groupingHeaderClicked(photos: PhotoMetaData[]) {
    var patientId = photos && photos.length > 0 ? photos[0].patientId : 0;
    if (patientId != 0 && this.filter.gallery_mode != PhotoGalleryMode.Patient) {
      this.router.navigate([
        '/gallery',
        {
          outlets: {
            'action-panel': ['patient', patientId + '_patientprofiletab', 'patienttabs', 'patientphotostab'],
          },
        },
      ]);
    }
  }

  isSelectedPhoto(photo: PhotoMetaData) {
    if (this.seriesMode) {
      return this._selectedPhotosForSeriesCreation.findIndex((p) => p.id == photo.id) != -1;
    } else return this.filter && this.filter.selectedPhoto && this.filter.selectedPhoto?.id === photo.id;
  }

  containsTagTitle(photo: PhotoMetaData, title: string) {
    return photo.tags.some((tag) => tag.title === title);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
