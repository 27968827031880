<h1 mat-dialog-title>Select </h1>
<span class="pb-4"><b>If none are selected, all will be used.</b></span>
<div mat-dialog-content>
   <form class="d-flex flex-column" >
     <div *ngFor="let item of clinicSupplies; let i = index"  >

        <mat-checkbox (change)="selectedCheck(i)" [checked]="isSelected(item)"></mat-checkbox>
        <div class="d-inline pl-2 align-top">{{item.name}}</div>

     </div>
    </form>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button (click)="save()" cdkFocusInitial>Set</button>
</div>
