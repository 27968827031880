import { Injectable } from '@angular/core';
import { Service } from '@models/service/service';
import { AuthService } from '@app/auth/auth.service';
import { SignalrBase } from './signalr-base';
import { SignalrHttpClientService } from './signalr-http-client.service';
import { Policies } from '@app/auth/auth-policies';

@Injectable({
  providedIn: 'root',
})
export class ServicesSignalrService extends SignalrBase<Service> {
  constructor(authService: AuthService, private signalrHttpClientService: SignalrHttpClientService) {
    super('Services', Policies.empty, authService, signalrHttpClientService);
    this.registerServerEvents();
  }

  private registerServerEvents(): void {
    super.registerServerEvent('UpdateService', (data: Service) => {
      this.checkUpdateServicesTriggers();
    });

    super.registerServerEvent('AddService', (data: Service) => {
      this.checkAddServicesTriggers();
    });

    super.registerServerEvent('DeleteService', (data: Service) => {
      this.checkDeleteServicesTriggers();
    });
  }

  private checkUpdateServicesTriggers() {
    // this.servicesService.serviceHasBeenUpdated(service);
  }

  private checkAddServicesTriggers() {
    // this.servicesService.serviceHasBeenAdded(service);
  }

  private checkDeleteServicesTriggers() {
    // this.servicesService.serviceHasBeenDeleted(service);
  }
}
