<ngx-loading [show]="loading"></ngx-loading>
<div *ngIf="selectedRoleKey" class="d-flex flex-column h-100 border mx-1">
  <app-error *ngIf="errorMessage" [message]="errorMessage" (onClose)="errorMessage = null"></app-error>
  <div class="d-flex">
    <div class="user-name-cell"></div>
    <div class="role-nav-bar">
      <nav mat-tab-nav-bar>
        <div
          mat-tab-link
          *ngFor="let role of roleNames | keyvalue : valueAscOrder"
          (click)="selectedRoleKey = role.key"
          [active]="selectedRoleKey == role.key"
        >
          {{ role.value | spaceTitleCase }}
        </div>
      </nav>
      <div class="claim-row grey-four-background" (scroll)="updateScroll()" #leadingScroll>
        <div class="claim-cell p-1 d-inline-block text-center" *ngFor="let claim of roleClaims.get(selectedRoleKey)">
          {{ getTrimmedClaimName(claim, selectedRoleKey) | spaceTitleCase }}
        </div>
      </div>
    </div>
  </div>
  <ng-scrollbar class="flex-grow-1 minh-0">
    <div *ngFor="let user of userNames | keyvalue" class="alternate-bg d-flex">
      <div class="user-name-cell p-2">
        <span class="mr-3">{{ user.value }}</span>
        <div kendoTooltip tooltipClass="em-tooltip" position="top" class="ml-auto p-1" (click)="onCopyClick(user.key)">
          <i *ngIf="copyUserId === null" title="Copy All Permissions" class="far fa-copy fa-lg"></i>
          <i
            *ngIf="copyUserId !== null && copyUserId !== user.key"
            title="Paste Permissions"
            class="far fa-paste fa-lg"
          ></i>
          <i *ngIf="copyUserId !== null && copyUserId === user.key" title="Cancel Copy" class="far fa-ban fa-lg"></i>
        </div>
        <mat-checkbox
          kendoTooltip
          tooltipClass="em-tooltip"
          position="top"
          title="Select All"
          class="ml-3"
          [checked]="userHasAllRoleClaims(user.key, selectedRoleKey)"
          [indeterminate]="
            userHasPartialRoleClaims(user.key, selectedRoleKey) && !userHasAllRoleClaims(user.key, selectedRoleKey)
          "
          [disabled]="copyUserId !== null"
          (change)="toggleUserClaimsForRole(user.key, selectedRoleKey)"
        >
        </mat-checkbox>
      </div>
      <div class="claim-row" #followingScroll>
        <div class="claim-cell text-center p-2" *ngFor="let claim of roleClaims.get(selectedRoleKey)">
          <mat-checkbox
            [checked]="userHasClaim(user.key, claim)"
            [disabled]="copyUserId !== null"
            (change)="toggleUserClaim(user.key, claim)"
          >
          </mat-checkbox>
        </div>
      </div>
    </div>
  </ng-scrollbar>
</div>
