<div
  class="print-element" 
  [ngStyle]="{ 'height': panelHeight ? panelHeight + 'px' : '100%' }">
  <mat-accordion
    class="prescriptions-widget"
    [ngClass]="{ 'h-100': !panelHeight }"
    [ngStyle]="{ 'max-height': panelHeight ? panelHeight + 'px' : 'inherit' }"
  >
    <mat-expansion-panel [expanded]="true" [disabled]="true" #panel>
      <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
        <mat-panel-title>
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="column-header py-0 mr-2">Prescriptions</div>
            <div *ngIf="showprescriptions">
              <button
                kendoTooltip
                tooltipClass="em-tooltip"
                position="top"
                class="btn btn-sm em-btn em-btn-green border-right mr-2"
                title="Add Prescription"
                data-title=""
              >
                <i (click)="navigateToAddPrescription()" class="far fa-plus-circle pointer"></i>
              </button>
              <button
                kendoTooltip
                tooltipClass="em-tooltip"
                position="top"
                class="btn btn-sm em-btn em-btn-green border-right ml-auto"
                title="View All Prescriptions"
                data-title=""
              >
                <i (click)="navigateToPrescriptionView()" class="far fa-arrow-right ml-auto pointer"></i>
              </button>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-scrollbar class="h-100">
        <mat-accordion [multi]="true">
          <mat-expansion-panel
            *ngFor="let prescription of prescriptionList"
            [expanded]="false"
            [disabled]="true"
            #panelList
          >
            <mat-expansion-panel-header class="px-0 py-0" [collapsedHeight]="'36px'" [expandedHeight]="'36px'">
              <mat-panel-title (click)="navigateToAddPrescriptionById(prescription.id)">
                <div class="d-flex align-items-center panel-box panel-box--small pl-0 pr-2">
                  <div class="color-sign mr-2 d-flex align-items-center justify-content-center white-font">
                    <ng-template>
                      <i class="fal fa-filter" title="Filter by Treatment"></i>
                    </ng-template>
                  </div>
                  <div class="panel-box-column d-flex flex-column">
                    <div class="line-height-1 mb-1">
                      {{ prescription?.drugName ? prescription.drugName : 'Undefined Drug' }}
                    </div>
                    <div class="line-height-1">
                      {{ prescription?.createdDate | date : 'yyyy-MM-dd' }}
                    </div>
                  </div>
                  <div class="panel-box-column panel-box-column--main d-flex"></div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-scrollbar>
    </mat-expansion-panel>
  </mat-accordion>
</div>
