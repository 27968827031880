import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { EmbeddedReport } from '@models/reports/embeded-report';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PowerBiService {
  constructor(private http: HttpClient) {}

  getReport(): Observable<EmbeddedReport> {
    return this.http.get<EmbeddedReport>(environment.baseUrl + 'api/PowerBi');
  }

  getReports(): Observable<EmbeddedReport[]> {
    return this.http.get<EmbeddedReport[]>(environment.baseUrl + 'api/PowerBi/GetReports');
  }
}
