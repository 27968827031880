import { Patient } from '@models/patient';
import { User } from '@models/user';
import { ProviderSignature } from './provider-signature';

export class Prescription {
  id: number;
  patientId: number;
  patient: Patient;
  drugName: string;
  isCustomDrug: boolean;
  isQhs: boolean;
  isPrn: boolean;
  isNoSubs: boolean;
  isLocked: boolean; // locked (i.e. not deletable) once Rx has been faxed or printed
  isDiscontinued: boolean;
  quantity: number; // decimal number
  refillCount: number;
  sig: string;
  createdDate: string;
  createdByUser: User;
  index?: string; //for ordering drugs in a script
  signatureId?: number = null;
  signature?: ProviderSignature;

  constructor(init?: Partial<Prescription>) {
    Object.assign(this, init);
  }
}
