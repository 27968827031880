<div class="row justify-content-between container-fluid pr-0 h-100">
  <form class="teal-font line-height-1 px-0 col-3 h-100" [formGroup]="form">
    <div [hidden]="editMode" class="h-100 d-flex flex-column">
      <div class="d-flex container-fluid px-0 mb-2">
        <button type="button" class="btn medium em-btn em-btn-green" (click)="onImportJSON()">
          <i class="far fa-file-import"></i>
          Import Unlayer JSON
        </button>
        <button type="button" class="btn medium em-btn em-btn-green ml-2" (click)="createTemplate()">
          <i class="far fa-plus-circle"></i>
          CREATE EMAIL TEMPLATE
        </button>
      </div>
      <div class="pb-2 flex-grow-1 minh-0">
        <ng-scrollbar class="h-100">
          <mat-accordion multi>
            <div class="px-2">
              <mat-expansion-panel expanded #panel1>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="d-flex align-items-center w-100">
                      <div class="column-header py-0 mr-2">Appointments</div>
                      <button
                        type="button"
                        class="d-flex btn navy-font p-0 ml-auto"
                        (click)="panel1.expanded = !panel1.expanded"
                      >
                        <mat-icon title="Toggle Expansion">{{
                          panel1.expanded ? 'arrow_drop_up' : 'arrow_drop_down'
                        }}</mat-icon>
                      </button>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let template of emailTemplates; let i = index">
                  <div
                    class="d-flex grey-two-background mb-2 clickable selected-element"
                    [ngClass]="{ 'clicked-element': i == clickedIndex }"
                    *ngIf="
                      template.emailTemplateType == emailTemplateTypes.AppointmentReminder ||
                      template.emailTemplateType == emailTemplateTypes.AppointmentScheduled ||
                      template.emailTemplateType == emailTemplateTypes.AppointmentChanged ||
                      template.emailTemplateType == emailTemplateTypes.AppointmentCancelled ||
                      template.emailTemplateType == emailTemplateTypes.RequestCreditCard
                    "
                    (click)="selectTemplate(template, i)"
                  >
                    <div class="py-2 px-3 navy-font">
                      <span class="bigger font-weight-bold">{{ template.name }}</span>
                    </div>
                  </div>
                </ng-container>
              </mat-expansion-panel>

              <mat-expansion-panel expanded #panel2>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="d-flex align-items-center w-100">
                      <div class="column-header py-0 mr-2">Retention Campaigns</div>
                      <button
                        type="button"
                        class="d-flex btn navy-font p-0 ml-auto"
                        (click)="panel2.expanded = !panel2.expanded"
                      >
                        <mat-icon title="Toggle Expansion">{{
                          panel2.expanded ? 'arrow_drop_up' : 'arrow_drop_down'
                        }}</mat-icon>
                      </button>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let template of emailTemplates; let i = index">
                  <div
                    class="d-flex grey-two-background mb-2 clickable selected-element"
                    [ngClass]="{ 'clicked-element': i == clickedIndex }"
                    *ngIf="template.emailTemplateType == emailTemplateTypes.RetentionCampaign"
                    (click)="selectTemplate(template, i)"
                  >
                    <div class="py-2 px-3 navy-font">
                      <span class="bigger font-weight-bold">{{ template.name }}</span>
                    </div>
                  </div>
                </ng-container>
              </mat-expansion-panel>

              <mat-expansion-panel expanded #panel3>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="d-flex align-items-center w-100">
                      <div class="column-header py-0 mr-2">Reviews</div>
                      <button
                        type="button"
                        class="d-flex btn navy-font p-0 ml-auto"
                        (click)="panel3.expanded = !panel3.expanded"
                      >
                        <mat-icon title="Toggle Expansion">{{
                          panel3.expanded ? 'arrow_drop_up' : 'arrow_drop_down'
                        }}</mat-icon>
                      </button>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let template of emailTemplates; let i = index">
                  <div
                    class="d-flex grey-two-background mb-2 clickable selected-element"
                    [ngClass]="{ 'clicked-element': i == clickedIndex }"
                    *ngIf="template.emailTemplateType == emailTemplateTypes.Reviews"
                    (click)="selectTemplate(template, i)"
                  >
                    <div class="py-2 px-3 navy-font">
                      <span class="bigger font-weight-bold">{{ template.name }}</span>
                    </div>
                  </div>
                </ng-container>
              </mat-expansion-panel>
              <mat-expansion-panel expanded #panel4>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="d-flex align-items-center w-100">
                      <div class="column-header py-0 mr-2">Portal</div>
                      <button
                        type="button"
                        class="d-flex btn navy-font p-0 ml-auto"
                        (click)="panel4.expanded = !panel4.expanded"
                      >
                        <mat-icon title="Toggle Expansion">{{
                          panel4.expanded ? 'arrow_drop_up' : 'arrow_drop_down'
                        }}</mat-icon>
                      </button>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let template of emailTemplates; let i = index">
                  <div
                    class="d-flex grey-two-background mb-2 clickable selected-element"
                    [ngClass]="{ 'clicked-element': i == clickedIndex }"
                    *ngIf="
                      template.emailTemplateType == emailTemplateTypes.PortalWelcome ||
                      template.emailTemplateType == emailTemplateTypes.PortalPasswordReset ||
                      template.emailTemplateType == emailTemplateTypes.PortalSetPassword ||
                      template.emailTemplateType == emailTemplateTypes.PortalCompleteDocuments
                    "
                    (click)="selectTemplate(template, i)"
                  >
                    <div class="py-2 px-3 navy-font">
                      <span class="bigger font-weight-bold">{{ template.name }}</span>
                    </div>
                  </div>
                </ng-container>
              </mat-expansion-panel>
            </div>
          </mat-accordion>
        </ng-scrollbar>
      </div>
      <div class="d-flex justify-content-end mt-1">
        <button
          class="btn medium em-btn em-btn-green mr-2"
          type="button"
          (click)="onClone()"
          [disabled]="!selectedTemplate"
        >
          <i class="far fa-copy"></i> Clone
        </button>
        <button
          class="btn medium em-btn em-btn-green mr-2"
          type="button"
          (click)="onEdit()"
          [disabled]="!selectedTemplate"
        >
          <i class="far fa-edit"></i> Edit
        </button>
        <button
          class="btn medium em-btn em-btn-green"
          type="button"
          (click)="onDelete()"
          [disabled]="!selectedTemplate"
        >
          <i class="far fa-minus-circle"></i> Delete
        </button>
      </div>
    </div>

    <div [hidden]="!editMode">
      <div class="grey-three-border border-with-bigger p-3">
        <h6 class="mb-2">Details</h6>
        <div class="row mx-0">
          <div class="col">
            <mat-form-field class="w-100">
              <mat-label>Template Type</mat-label>
              <mat-select #typeSelect formControlName="emailTemplateType">
                <mat-option [value]="emailTemplateTypes.AppointmentReminder"> Appointment Reminder </mat-option>
                <mat-option [value]="emailTemplateTypes.AppointmentScheduled"> Appointment Scheduled </mat-option>
                <mat-option [value]="emailTemplateTypes.AppointmentChanged"> Appointment Changed </mat-option>
                <mat-option [value]="emailTemplateTypes.AppointmentCancelled"> Appointment Cancelled </mat-option>
                <mat-option [value]="emailTemplateTypes.RequestCreditCard"> Request Credit Card </mat-option>
                <mat-option [value]="emailTemplateTypes.RetentionCampaign"> Retention Campaign </mat-option>
                <mat-option [value]="emailTemplateTypes.Reviews"> Reviews </mat-option>
                <mat-option [value]="emailTemplateTypes.PortalWelcome"> Portal - Welcome </mat-option>
                <mat-option [value]="emailTemplateTypes.PortalPasswordReset"> Portal - Password Reset</mat-option>
                <mat-option [value]="emailTemplateTypes.PortalSetPassword"> Portal - Set Password </mat-option>
                <mat-option [value]="emailTemplateTypes.PortalCompleteDocuments">
                  Portal - Complete Documents
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col" style="padding-right: 15px; padding-left: 15px">
            <mat-form-field class="w-100">
              <input formControlName="name" matInput placeholder="Name" />
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col" style="padding-right: 15px; padding-left: 15px">
            <mat-form-field class="w-100">
              <input formControlName="description" matInput placeholder="Description" />
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col" style="padding-right: 15px; padding-left: 15px">
            <mat-form-field class="w-100">
              <input readonly class="opacity-70" formControlName="createdDate" matInput placeholder="Created Date" />
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col" style="padding-right: 15px; padding-left: 15px">
            <mat-form-field class="w-100">
              <input readonly class="opacity-70" formControlName="createdBy" matInput placeholder="Created By" />
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col" style="padding-right: 15px; padding-left: 15px">
            <mat-form-field class="w-100">
              <input readonly class="opacity-70" formControlName="modifiedDate" matInput placeholder="Updated Date" />
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col" style="padding-right: 15px; padding-left: 15px">
            <mat-form-field class="w-100">
              <input readonly class="opacity-70" formControlName="modifiedBy" matInput placeholder="Updated By" />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end py-3">
        <button class="btn medium em-btn em-btn-green mr-2" type="button" (click)="onSave()">
          <i class="far fa-save"></i> Save
        </button>
        <button class="btn medium em-btn em-btn-green" type="button" (click)="onCancel()">
          <i class="far fa-minus-circle"></i> Cancel
        </button>
      </div>
    </div>
  </form>
  <div *ngIf="showDeleteMessage" class="success-banner">Deleted successfully!</div>
  <div *ngIf="showSaveMessage" class="success-banner">Saved successfully!</div>

  <div class="col d-flex flex-column pr-0 h-100">
    <div class="navy-background py-2 w-100 mb-2 container-fluid" style="position: relative">
      <form>
        <div class="row">
          <div class="col-3 d-flex text-white">
            <label class="m-0"><b>Email Template Type:</b></label>
            <input class="displayValue" disabled [value]="displayEmailTemplateType" />
          </div>
          <div class="col-3 d-flex text-white">
            <label class="m-0"><b>Description:</b></label>
            <input class="displayValue" disabled [value]="displayDescription" />
          </div>
          <div class="col-3 d-flex text-white">
            <label class="m-0"><b>Created Date:</b></label>
            <input class="displayValue" disabled [value]="displayCreatedDate" />
          </div>
          <div class="col-3 d-flex text-white">
            <label class="m-0"><b>Modified Date:</b></label>
            <input class="displayValue" disabled [value]="displayModifiedDate" />
          </div>
        </div>
        <div class="row">
          <div class="col-3 d-flex text-white">
            <label class="m-0"><b>Name:</b></label>
            <input class="displayValue" disabled [value]="displayName" />
          </div>
          <div class="col-3"></div>
          <div class="col-3 d-flex text-white">
            <label class="m-0"><b>Created By:</b></label>
            <input class="displayValue" disabled [value]="displayCreatedBy" />
          </div>
          <div class="col-3 d-flex text-white">
            <label class="m-0"><b>Modified By:</b></label>
            <input class="displayValue" disabled [value]="displayModifiedBy" />
          </div>
        </div>
      </form>
    </div>
    <div
      class="w-100 minh-0 flex-grow-1 d-flex align-items-start justify-content-center px-0 pb-2 light-grey-background"
    >
      <ng-scrollbar class="w-100 h-100" [hidden]="!selectedTemplate && !editMode">
        <app-unlayer-email-editor
          [options]="editorOptions"
          (loaded)="loadEditor($event)"
          (ready)="editorReady($event)"
        ></app-unlayer-email-editor>
      </ng-scrollbar>
      <div
        *ngIf="!selectedTemplate && !editMode"
        frameBorder="0"
        class="w-100 h-100 d-flex align-items-start justify-content-center px-0 pb-2 light-grey-background"
      >
        <span class="m-auto font-weight-bold" style="font-size: 2em">Please select an email template.</span>
      </div>
    </div>
  </div>
</div>
<p-overlayPanel #hoverPanel id="hoverPanel" [dismissable]="false"></p-overlayPanel>
<app-actionpanel></app-actionpanel>
