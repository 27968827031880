import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-transactions-tab',
  templateUrl: './patient-transactions-tab.component.html',
  styleUrls: ['./patient-transactions-tab.component.less']
})
export class PatientTransactionsTabComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
