import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil, last } from 'rxjs/operators';
import { isNullOrUndefined } from '@app/shared/helpers';

import { PatientDocumentService } from '@services/patient-documents.service';
import { TagService } from '@services/tag.service';
import { PatientService } from '@services/patient.service';
import { Patient } from '@models/patient';
import { Tag } from '@models/tag/tag';
import { TagType } from '@models/tag/tag-type';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.less'],
})
export class AddDocumentComponent implements OnInit, OnDestroy {
  form: FormGroup;
  patient: Patient;
  unsub: Subject<void> = new Subject<void>();

  loading = false;
  tags: Tag[];
  @Input()
  initalTagList: Tag[];
  pillColours: String[];
  fileList: File[];
  currentFileNames: string[];

  constructor(
    public activeModal: NgbActiveModal,
    private docService: PatientDocumentService,
    private tagService: TagService,
    private patientService: PatientService
  ) {
    this.patient = this.patientService.patientPanelPatient;
    this.initalTagList = [];
  }

  ngOnInit() {
    this.fileList = [];

    this.currentFileNames = this.docService.documents.map((doc) => doc.name) || [];

    this.initForm();

    this.pillColours = this.tagService.getPillColors();

    this.patientService.thePatientUpdated$.pipe(takeUntil(this.unsub)).subscribe((patient) => {
      this.patient = this.patientService.patientPanelPatient;
      this.form.controls['patientId'].setValue(this.patient.patientId);
    });

    this.tagService.getDocumentTags().subscribe((tags) => {
      this.tags = tags;
      this.initalTagList.forEach((initalTag) => {
        const tagIndex = this.tags.findIndex((tag) => tag.id === initalTag.id);
        if (tagIndex >= 0) {
          this.tags.splice(tagIndex, 1);
        }
      });
    });

    this.docService.documents$
      .asObservable()
      .pipe(takeUntil(this.unsub))
      .subscribe((documents) => {
        this.currentFileNames = documents.map((doc) => doc.name);
      });

    // Make sure we have the latest documents loaded
    this.docService.getDocuments(this.patient.patientId).toPromise();
  }

  private initForm() {
    this.form = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        (control: AbstractControl) =>
          this.currentFileNames.indexOf(control.value) !== -1 ? { duplicateName: { value: control.value } } : null,
      ]),
      patientId: new FormControl(this.patient.patientId, Validators.required),
      filePath: new FormControl(),
      notes: new FormControl(),
      tags: new FormControl(this.initalTagList),
      documentTypeId: new FormControl(),
      uploadDate: new FormControl(new Date()),
    });
  }

  onSubmit() {
    // Do nothing if there are no files to upload
    if (!this.hasSelectedFiles()) {
      return;
    }
    this.loading = true;
    try {
      this.form.value.tags = this.form.value.tags ? this.processTags(this.form.value.tags) : this.form.value.tags;

      this.docService
        .addMultipleDocuments(this.form.value, this.fileList)
        .pipe(takeUntil(this.unsub), last())
        .subscribe((lastDoc) => {
          this.loading = false;
          this.activeModal.close(lastDoc);
        });
    } catch (e) {
      this.loading = false;
    }
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      this.fileList = [];
      for (const file of event.target.files) {
        this.fileList.push(file);
      }
    }
  }

  hasSelectedFiles(): boolean {
    return !!this.fileList.length;
  }

  processTags(tags: any[]): Tag[] {
    const tagList: Tag[] = [];
    tags.forEach((t) => {
      if (isNullOrUndefined(t.patientDocumentId)) {
        const type = !isNullOrUndefined(t.type) ? t.type : TagType.custom;
        const title = !isNullOrUndefined(t.title) ? t.title : t.display;
        let tagRefId = '';
        if (t.type !== TagType.custom) {
          if (!isNullOrUndefined(t.tagId)) {
            tagRefId = t.tagId.split('-')[1];
          }
        }
        tagList.push(new Tag(t.id, t.tagId, title, type, tagRefId));
      } else {
        tagList.push(t);
      }
    });
    return tagList;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
