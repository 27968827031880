<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>

<div [ngStyle]="{'height': 'calc(100dvh - 75px)'}">
  <kendo-grid
    [data]="gridView"
    [sort]="gridState.sort"
    [sortable]="true"
    [selectable]="true"
    [style.height.%]="100"
    (add)="onAddClick($event)"
    (cancel)="cancelHandler($event)"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)">
    <ng-template kendoGridToolbarTemplate>
      <div class="d-flex">
        <span class="pl-4 search-box border border-radius">
          <span class="fa fa-search"></span>
          <mat-form-field class="w-75 p-3">
            <input matInput placeholder="Search..." aria-label="Search Documents" [(ngModel)]="searchValue"
              [formControl]="searchCtrl" />
            <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear"
              (click)="searchValue = ''">
              <mat-icon><i class="fa fa-times-circle"></i></mat-icon>
            </button>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let docType of (filteredDocumentTypes | async)" [value]="docType.name">
                <span>{{ docType.name }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </span>
        <button kendoGridAddCommand class="btn kendo-btn"><i class="fal fa-plus-circle"></i></button>
      </div>
      <button class="btn kendo-btn kendo-btn-green em-btn mt-2" type="button" kendoGridExcelCommand
        icon="file-excel">Export to Excel</button>
    </ng-template>
    <kendo-grid-excel fileName="emily_documents.xlsx"></kendo-grid-excel>
    <kendo-grid-column field="name" title="Name" [width]="150"></kendo-grid-column>
  
    <kendo-grid-command-column title="Actions" min-width="125px" [width]="125">
      <ng-template kendoGridCellTemplate>
        <button kendoGridEditCommand class="btn kendo-btn"><i class="fas fa-edit"></i></button>
        <button kendoGridRemoveCommand class="btn kendo-btn"><i class="fas fa-minus-circle"></i></button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>

<app-actionpanel></app-actionpanel>
