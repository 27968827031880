import { ServiceDetailTemplate } from "../service/service-detail-template";

export class ServiceOrProduct {
    type: ServiceOrProductType;
    id: number;
    name: string;
    serviceDetailTemplateId: ServiceDetailTemplate;
}

export enum ServiceOrProductType {
    service,
    product
}
