import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { DocumentType } from '@models/document/document-type';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeService {
  docTypesChanged = new Subject<DocumentType[]>();
  docTypesChangeCancelled = new Subject();
  documentTypes: DocumentType[];
  defaultId = 0;

  constructor(private http: HttpClient) {}

  getDocTypes() {
    return this.http.get<DocumentType[]>(environment.baseUrl + 'api/DocumentTypes').pipe(
      map(docTypes => {
        this.documentTypes = docTypes;
        return docTypes;
      })
    );
  }

  getLoadedDocTypes() {
    return this.documentTypes.slice();
  }

  getDocType(id: number) {
    return this.http.get<DocumentType>(environment.baseUrl + 'api/DocumentTypes/' + id);
  }

  getLoadedDocType(id: number) {
    const itemIndex = this.documentTypes.findIndex(docType => docType.id === id);
    return this.documentTypes[itemIndex];
  }

  updateDocType(DocumentType: DocumentType) {
    return this.http.put<void>(environment.baseUrl + 'api/DocumentTypes/' + DocumentType.id, DocumentType).pipe(
      map(() => {
        const itemIndex = this.documentTypes.findIndex(docType => docType.id === DocumentType.id);
        this.documentTypes[itemIndex] = DocumentType;
        this.docTypesChanged.next(this.documentTypes);
        return this.documentTypes;
      })
    );
  }

  addDocType(docType: DocumentType) {
    docType.id = this.defaultId;

    return this.http.post<DocumentType>(environment.baseUrl + 'api/DocumentTypes', docType).pipe(
      map((addedDocType: DocumentType) => {
        docType.id = addedDocType.id;
        this.documentTypes.push(docType);
        this.docTypesChanged.next(this.documentTypes.sort(this.sortByName).slice());
        return docType;
      })
    );
  }

  sortByName(doc1: DocumentType, doc2: DocumentType) {
    if (doc1.name < doc2.name) {
      return -1;
    }
    if (doc1.name > doc2.name) {
      return 1;
    }
    return 0;
  }

  deleteDocType(id: number) {
    return this.http.delete(`${environment.baseUrl}api/DocumentTypes/${id}`).pipe(
      map(() => {
        const itemIndex = this.documentTypes.findIndex(docType => docType.id === id);

        this.documentTypes.splice(itemIndex, 1);
        this.docTypesChanged.next(this.documentTypes);
        return this.documentTypes;
      })
    );
  }
}
