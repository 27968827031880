import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { PatientMembership } from '@models/memberships/patient-membership';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PatientMembershipService {
  constructor(private http: HttpClient) {}

  getPatientMemberships(patientId: number, includeExpired: boolean = true) {
    return this.http
      .get<PatientMembership[]>(environment.baseUrl + 'api/PatientMemberships/' + patientId, {
        params: { includeCancelled: includeExpired },
      })
      .pipe(map(this.parseDates));
  }

  getPatentMembershipsForLineItem(lineItemId: number) {
    return this.http
      .get<PatientMembership[]>(environment.baseUrl + 'api/PatientMemberships/ForLineItem/' + lineItemId)
      .pipe(map(this.parseDates));
  }

  updatePatientMembership(patientMembership: PatientMembership) {
    return this.http.post<PatientMembership>(environment.baseUrl + 'api/PatientMemberships/', patientMembership);
  }

  private parseDates(memberships: PatientMembership[]) {
    memberships.forEach((m) => {
      m.firstPaymentDate = new Date(m.firstPaymentDate);
      m.lastPaymentDate = new Date(m.lastPaymentDate);
      m.nextPaymentDate = new Date(m.nextPaymentDate);
      m.expirationDate = new Date(m.expirationDate);
      m.cancellableDate = new Date(m.cancellableDate);
    });
    return memberships;
  }
}
