import { ClinicProduct } from '@models/clinic-product';
import { DiscountType } from './discount-type.enum';

export class MembershipProduct {
  id: number;
  membershipId: number;
  clinicProductId: number;
  clinicProductName: string;
  discountType: DiscountType;
  amount: number;
  clinicProduct: ClinicProduct;
}
