import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Patient } from '@models/patient';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageService } from '@services/image.service';
import { PatientService } from '@services/patient.service';
import { PhotoEditingService } from '@services/photo-editing.service';
import { PhotoSeriesService } from '@services/photo-series.service';
import { PhotoSignalrService } from '@services/photo-signalr.service';
import { PhotosFilterService } from '@services/photos-filter.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-patient-photos-tab',
  templateUrl: './patient-photos-tab.component.html',
  styleUrls: ['./patient-photos-tab.component.less'],
})
export class PatientPhotosTabComponent implements OnInit, OnDestroy {
  loading = false;
  unsub: Subject<void> = new Subject<void>();
  patient: Patient;

  constructor(
    // private photoEditingService: PhotoEditingService,
    // private imageService: ImageService,
    private patientService: PatientService,
    // private confirmDialog: MatDialog,
    // private modalService: NgbModal,
    // private photosFilterService: PhotosFilterService,
    // private photoSeriesService: PhotoSeriesService,
    // private photoSignalrService: PhotoSignalrService
  ) {
    // this.adminPhotos = [];
    // this.undoPhotoEdit = new Subject<void>();
    // this.photoEditorConfig = {
    //   ImageName: 'Some image',
    //   ImageUrl: 'https://static.pexels.com/photos/248797/pexels-photo-248797.jpeg',
    //   ImageType: 'image/png'
    // };
  }

  async ngOnInit() {
    let patientId = window.location.pathname.split('_')[0].split('/')[
      window.location.pathname.split('_')[0].split('/').length - 1
    ];
    if (this.patientService.patientPanelPatient && this.patientService.patientPanelPatient.patientId == +patientId) {
      this.patient = this.patientService.patientPanelPatient;
    } else {
      this.patientService.getPatientById(patientId).subscribe((patient) => {
        this.patient = patient;
        this.patientService.patientPanelPatient = this.patient;
      });
    }

    // this.photos = this.patientService.patientPanelPatient.photos;
    // this.imageService.photosTabLoadingState$.pipe(takeUntil(this.unsub)).subscribe(loading => {
    //   this.loading = loading;
    // });

    // this.patientService.thePatientUpdated$.pipe(takeUntil(this.unsub)).subscribe(patient => {
    //   if (!this.isFilterApplied) {
    //     this.patient = patient;
    //     this.photos = patient.photos.slice();
    //   } else {
    //     this.getFilteredPhotos(this.tags);
    //   }
    // });

    // this.imageService.metadataUpdated$.pipe(takeUntil(this.unsub)).subscribe(image => {
    //   let indexToUpdate = this.photos.findIndex(p => p.id === image.id && !p.isSeries);
    //   if (indexToUpdate > -1) this.photos[indexToUpdate] = image;
    //   indexToUpdate = this.adminPhotos.findIndex(p => p.id === image.id && !p.isSeries);
    //   if (indexToUpdate > -1) this.adminPhotos[indexToUpdate] = image;
    //   indexToUpdate = this.photos.findIndex(p => p.id === image.id && !p.isSeries);
    //   if (indexToUpdate > -1) this.patient.photos[indexToUpdate] = image;

    //   this.photo = image;
    // });

    // this.photoSeriesService.metadataUpdated$.pipe(takeUntil(this.unsub)).subscribe(image => {
    //   let indexToUpdate = this.photos.findIndex(p => p.id === image.id && !p.isSeries);
    //   if (indexToUpdate > -1) this.photos[indexToUpdate] = image;
    //   indexToUpdate = this.adminPhotos.findIndex(p => p.id === image.id && !p.isSeries);
    //   if (indexToUpdate > -1) this.adminPhotos[indexToUpdate] = image;
    //   indexToUpdate = this.photos.findIndex(p => p.id === image.id && !p.isSeries);
    //   if (indexToUpdate > -1) this.patient.photos[indexToUpdate] = image;

    //   this.photo = image;
    // });

    // this.photosFilterService.filterSource$.pipe(takeUntil(this.unsub)).subscribe(
    //   tags => {
    //     this.tags = tags;
    //     this.clearToolbarSearch = true;
    //     this.term = '';
    //     if (!this.adminMode) {
    //       this.getFilteredPhotos(tags);
    //       this.filterPhotoByTerm(this.term);
    //     } else {
    //       this.getFilteredAdminPhotos(tags);
    //     }
    //   }
    // );

    // this.photoSeriesService.photoSeriesSource$.pipe(takeUntil(this.unsub)).subscribe(
    //   (res) => {
    //     this.photoSeriesList = res;
    //   }
    // );

    // const extionsMap = {
    //   [PhotoEventType.EditPhoto]: photoEvent => {
    //     this.editMode = true;
    //     this.photo = photoEvent.photo;
    //     this.needsReload = false;
    //     if (this.photo && this.photo.isSeries) {
    //       this.seriesMode = true;
    //       if (!this.isNewPhotoSeries) {
    //         this.photos = this.photos.filter(i => !i.isSeries);
    //       }
    //     }
    //     this.photoEditorConfig = {
    //       ImageName: this.photo.imageName,
    //       ImageUrl: this.photo.filePath,
    //       ImageType: this.photo.imageType
    //     };
    //   },
    //   [PhotoEventType.LoadPhoto]: photoEvent => {
    //     this.editMode = false;
    //     this.photo = null;
    //     this.photo = photoEvent.photo ? Object.assign({}, photoEvent.photo) : photoEvent.photo;

    //     if (this.isNewItemAdded) {
    //       this.isNewItemAdded = false;
    //     }
    //     if (this.photo && !this.photo.isSeries) {
    //       this.seriesViewMode = false;
    //     }
    //     if (!this.photo) {
    //       this.seriesViewMode = false;
    //     }
    //     if (this.photo && this.photo.isSeries) {
    //       this.seriesViewMode = true;
    //       this.seriesType = this.photo.seriesType;
    //       this.photoSeriesList = this.photo.seriesPhotos ? this.photo.seriesPhotos : [];
    //     }
    //   },
    //   [PhotoEventType.CancelEditPhoto]: photoEvent => {
    //     this.editMode = false;
    //     if (this.seriesMode && !this.isNewPhotoSeries) {
    //       this.seriesMode = false;
    //       this.photos = this.patient.photos;
    //     }
    //   },
    //   [PhotoEventType.UndoPhotoChanges]: photoEvent => {
    //     this.undoPhotoEdit.next();
    //   },
    //   [PhotoEventType.ClearPhoto]: photoEvent => {
    //     if (this.patient.photos && this.patient.photos.length == 1){
    //       this.photo = null;
    //     }
    //     else{
    //       var i = this.patient.photos.findIndex(p => p.id == this.photo.id);
    //       this.photoEditingService.loadPhoto( this.patient.photos[i + 1 < this.patient.photos.length ? i + 1 : i -1]);
    //     }
    //   },
    //   [PhotoEventType.PhotoCropped]: photoEvent => {
    //     this.photoCropped = true;
    //   },
    //   [PhotoEventType.FileChanged]: photoEvent => {
    //     this.editedPhoto = photoEvent.file;
    //   }
    // };
    // this.photoSubscription = this.photoEditingService.getPhotoSource()
    //   .pipe(takeUntil(this.unsub), delay(0))
    //   .subscribe(photoEvent => extionsMap[photoEvent.event](photoEvent));

    // this.photoSeriesService.photoSeriesSource$.pipe(takeUntil(this.unsub)).subscribe(
    //   (res) => {
    //     this.photoSeriesList = res;
    //   }
    // );

    // this.photoSignalrService.photosChanged$.pipe(takeUntil(this.unsub)).subscribe(photo => {
    //   if (photo && this.patient && photo.patientId == this.patient.patientId){
    //     this.patientService.thePatientHasBeenUpdatedById(photo.patientId)
    //   }
    // });
  }

  // saveChanges($event) {
  //   this.loading = true;
  //   Object.assign(this.photo, new PhotoMetaData($event.value));
  //   if (isNullOrUndefined(this.photo.notes)) {
  //     this.photo.notes = '';
  //   }
  //   if (isNullOrUndefined(this.photo.photoConsentTypeId)) {
  //     this.photo.photoConsentTypeId = PhotoConsentType.None;
  //   }
  //   this.photo.tags = this.processTags($event.value.tags);
  //   if (this.photo.isSeries) {
  //     this.updateSeriesData();
  //   } else {
  //     this.updateSinglePhotoData();
  //   }
  // }

  // private updateSeriesData() {
  //   if (this.isNewPhotoSeries) {
  //     this.photoEditingService.cancelEdit();
  //     this.loading = false;
  //     this.needsReload = false;
  //     return;
  //   } else {
  //     const newArray = this.photoSeriesList.map(a => Object.assign({}, a));
  //     this.photo.seriesPhotos = newArray;
  //     this.photoSeriesService.updatePhotoSeries(this.photo).subscribe((result) => {
  //       const indexToUpdate = this.patient.photos.findIndex(p => p.id === result.id && p.isSeries);
  //       this.patient.photos[indexToUpdate] = result;
  //       this.patient.photos = this.patient.photos.slice();
  //       this.editMode = false;
  //       this.loading = false;
  //       this.seriesMode = false;
  //       this.seriesType = null;
  //       this.photoEditingService.cancelEdit();
  //       this.needsReload = true;
  //       this.photos = this.patient.photos;
  //     });
  //   }
  // }

  // private updateSinglePhotoData() {
  //   if (!isNullOrUndefined(this.editedPhoto) && this.photoCropped) {
  //     this.imageService.uploadPatientPhoto(this.editedPhoto, this.photo, { createOriginal: false }).pipe(takeUntil(this.unsub)).subscribe(result => {
  //       const indexToUpdate = this.photos.findIndex(p => p.id === result.id && !p.isSeries);
  //       this.photos[indexToUpdate] = result;
  //       this.photo = result;
  //       this.editMode = false;
  //       this.photoEditingService.cancelEdit();
  //       if (this.term) {
  //         const indexToUpdate = this.patient.photos.findIndex(p => p.id === result.id && !p.isSeries);
  //         this.patient.photos[indexToUpdate] = result;
  //         this.photos = this.photos.slice();
  //         this.loading = false;
  //         return;
  //       }
  //       if (!this.isFilterApplied) {
  //         this.patient.photos = this.photos.slice();
  //       } else {
  //         const indexToUpdate = this.patient.photos.findIndex(p => p.id === this.photo.id && !p.isSeries);
  //         this.patient.photos[indexToUpdate] = result;
  //       }
  //       this.patientService.thePatientHasBeenUpdated(this.patient);
  //       this.loading = false;
  //     },
  //       (err) => {
  //         this.loading = false;
  //         console.error('Upload Error:', err);
  //       });
  //   } else {
  //     this.imageService.uploadPhotoMetaData(this.photo).pipe(takeUntil(this.unsub)).subscribe(result => {
  //       const indexToUpdate = this.photos.findIndex(p => p.id === this.photo.id && !p.isSeries);
  //       this.photos[indexToUpdate] = result;
  //       this.photo = result;
  //       this.editMode = false;
  //       this.photoEditingService.cancelEdit();
  //       if (this.term) {
  //         const indexToUpdate = this.patient.photos.findIndex(p => p.id === this.photo.id && !p.isSeries);
  //         this.patient.photos[indexToUpdate] = result;
  //         this.photos = this.photos.slice();
  //         this.loading = false;
  //         return;
  //       }
  //       if (!this.isFilterApplied) {
  //         this.patient.photos = this.photos.slice();
  //       } else {
  //         const indexToUpdate = this.patient.photos.findIndex(p => p.id === this.photo.id && !p.isSeries);
  //         this.patient.photos[indexToUpdate] = result;
  //       }
  //       this.patientService.thePatientHasBeenUpdated(this.patient);
  //       this.loading = false;
  //     });
  //   }
  // }

  // processTags(tags: any[]): Tag[] {
  //   const tagList: Tag[] = [];
  //   tags.forEach(t => {
  //     if (isNullOrUndefined(t.photoId)) {
  //       const type = !isNullOrUndefined(t.type) ? t.type : TagType.custom;
  //       const title = !isNullOrUndefined(t.title) ? t.title : t.display;
  //       let tagRefId = '';
  //       if (t.type !== TagType.custom) {
  //         if (!isNullOrUndefined(t.tagId)) {
  //           tagRefId = t.tagId.split('-')[1];
  //         }
  //       }
  //       tagList.push(new Tag(t.id, t.tagId, title, type, tagRefId));
  //     } else {
  //       tagList.push(t);
  //     }
  //   });
  //   return tagList;
  // }

  // revertPhoto() {
  //   const dialogRef = this.confirmDialog.open(GenericDialogComponent, {
  //     width: '300px',
  //     data: {
  //       title: 'Revert Image',
  //       content: 'Are you sure?',
  //       confirmButtonText: 'Confirm'
  //     }
  //   });

  //   dialogRef
  //     .afterClosed()
  //     .pipe(
  //       filter(result => result === 'confirm'),
  //       switchMap(() => {
  //         this.imageService.photosTabLoadingStateChanged(true);

  //         const tempPhotoMetaData: PhotoMetaData = new PhotoMetaData(this.photo);

  //         // this call will download the original version of the image, delete the modified version(both main and thumbnail), and return the file in it's original state
  //         return this.imageService.revertPatientPhoto(tempPhotoMetaData);
  //       }),
  //       takeUntil(this.unsub))
  //     .subscribe(result => {
  //       const indexToUpdate = this.photos.findIndex(p => p.id === result.id && !p.isSeries);
  //       this.photos[indexToUpdate] = result;
  //       this.photo = result;
  //       this.editMode = false;
  //       this.photoEditingService.cancelEdit();
  //       if (!this.isFilterApplied) {
  //         this.patient.photos = this.photos.slice();
  //       } else {
  //         const indexToUpdate = this.patient.photos.findIndex(p => p.id === result.id && !p.isSeries);
  //         this.patient.photos[indexToUpdate] = result;
  //       }
  //       this.patientService.thePatientHasBeenUpdated(this.patient);
  //       this.imageService.photosTabLoadingStateChanged(false);
  //     });
  // }
  // getAdminData($event: boolean) {
  //   this.adminMode = $event;
  //   this.seriesMode = false;
  //   this.seriesViewMode = false;
  //   this.isNewPhotoSeries = false;
  //   this.photo = null;
  //   this.loading = true;
  //   if ($event) {
  //     if (!this.tags.length) {
  //       if (this.term) {
  //         this.filterPhotoByTerm(this.term);
  //       } else {
  //         this.imageService.getGlobalSharedImages().subscribe(adminPhotos => {
  //           this.adminPhotos = adminPhotos;
  //           this.loading = false;
  //         });
  //       }
  //     } else {
  //       this.getFilteredAdminPhotos(this.tags);
  //     }
  //   } else {
  //     if (this.tags.length) {
  //       this.getFilteredPhotos(this.tags);
  //     } else {
  //       if (this.term) {
  //         this.filterPhotoByTerm(this.term);
  //       } else {
  //         this.photos = this.patient.photos.slice();
  //         this.loading = false;
  //       }
  //     }
  //   }
  // }

  // getFilteredAdminPhotos(tags: Tag[]) {
  //   this.loading = true;
  //   if (tags.length) {
  //     this.photosFilterService.getGlobalSharedPhotosByTags(tags).subscribe(
  //       res => {
  //         this.adminPhotos = res;
  //         this.isFilterApplied = true;
  //         this.loading = false;
  //       }
  //     );
  //   } else {
  //     this.imageService.getGlobalSharedImages().subscribe(adminPhotos => {
  //       this.adminPhotos = adminPhotos;
  //       this.loading = false;
  //     });
  //   }
  // }

  // public uploadPhoto() {
  //   if (!this.editMode) {
  //     this.modalService.open(AddPhotoComponent, { windowClass: 'add-photo-modal', centered: true });

  //   } else {
  //     const dialogRef = this.confirmDialog.open(ConfirmUnsavedChangesDialogComponent, {
  //       width: '250px'
  //     });

  //     dialogRef
  //       .afterClosed()
  //       .pipe(takeUntil(this.unsub))
  //       .subscribe(result => {
  //         if (result === 'confirm') {
  //           this.editMode = false;
  //           this.photoEditingService.cancelEdit();
  //           this.modalService.open(AddPhotoComponent, { windowClass: 'add-photo-modal', centered: true });
  //         }
  //       });
  //   }
  // }

  // private getFilteredPhotos(tags: Tag[]) {
  //   this.loading = true;
  //   if (tags.length) {
  //     const consent = tags.filter(i => {
  //       if (i.tagId.indexOf('Consent-') != -1) {
  //         return i.title;
  //       }
  //     }).map(e => e.refId);

  //     this.photosFilterService.getPatientPhotosByTags(tags, this.patient.patientId, consent).subscribe(
  //       res => {
  //         if (this.seriesMode) {
  //           this.photos = res.filter(e => !e.isSeries);
  //         } else {
  //           this.photos = res;
  //         }

  //         this.isFilterApplied = true;
  //         this.loading = false;
  //       }
  //     );
  //   } else {
  //     if (this.seriesMode) {
  //       this.photos = this.patient.photos.filter(e => !e.isSeries);
  //     } else {
  //       this.photos = this.patient.photos;
  //     }
  //     this.isFilterApplied = false;
  //     this.loading = false;
  //   }
  // }

  // getToolbarHeight(toolbarEl): number{
  //   try{
  //     //hacky fix until we decide on final layout/components
  //     var div = toolbarEl.getElementsByClassName('section-photo-toolbar')[0];
  //     return div.clientHeight;
  //   }
  //   catch(e){
  //     return 56;
  //   }
  // }

  // toggleFilter() {
  //   this.isFilterMode = !this.isFilterMode;
  // }

  // switchSeriesMode($event: any) {
  //   if ($event.state) {
  //     this.photoSeriesList = [];
  //     this.seriesMode = $event.state;
  //     this.seriesViewMode = $event.state;
  //     this.seriesType = $event.type;
  //     this.isNewPhotoSeries = true;
  //     this.needsReload = false;
  //     this.photos = this.photos.filter(i => !i.isSeries);
  //   } else {
  //     this.clearToolbarSearch = true;
  //     this.isNewPhotoSeries = false;
  //     this.seriesMode = $event.state;
  //     this.seriesViewMode = $event.state;
  //     this.photos = this.patient.photos;
  //   }
  // }

  // createSeries() {
  //   this.loading = true;
  //   const data = JSON.parse(JSON.stringify(this.photoSeriesList));
  //   let series = new PhotoMetaData(this.photo);
  //   series.id = 0;
  //   series.seriesPhotos = data;
  //   series.patientId = this.patient.patientId;
  //   series.seriesType = this.seriesType;

  //   this.photoSeriesService.createPhotoSeries(series).subscribe((series: PhotoMetaData) => {
  //     this.patient.photos.push(series);
  //     this.patientService.thePatientHasBeenUpdated(this.patient);
  //     this.isNewPhotoSeries = false;
  //     this.needsReload = true;
  //     this.isNewItemAdded = true;
  //     this.clearToolbarSearch = true;
  //     this.term = '';
  //     this.photoEditingService.loadPhoto(series);
  //     this.loading = false;
  //   });
  //   this.seriesMode = false;
  // }

  // public filterPhotoByTerm(event: string) {
  //   this.clearToolbarSearch = false;
  //   this.term = event;
  //   this.loading = true;
  //   if (event) {
  //     this.photosFilterService.getPatientPhotosSearchTerm(event, this.adminMode, this.adminMode ? null : this.patientService.patientPanelPatient.patientId)
  //     .subscribe(
  //       res => {
  //         if (this.adminMode) {
  //           this.adminPhotos = res;
  //         } else {
  //           this.photos = res;
  //         }
  //         this.isFilterMode = true;
  //         this.loading = false;
  //       }
  //     );
  //   } else {
  //     if (this.seriesMode) {
  //       this.photos = this.patient.photos.filter(e => !e.isSeries);
  //     } else {
  //       if (this.adminMode) {
  //         this.getAdminData(this.adminMode);
  //       } else {
  //         this.photos = this.patient.photos;
  //       }
  //     }
  //     this.loading = false;
  //   }
  // }

  // public openLightBox(event: number) {
  //   const dialogRef = this.confirmDialog.open(PhotoLightboxComponent, {
  //     panelClass: 'lightbox-dialog',
  //     width: '100%',
  //     height: '100%',
  //     maxWidth: '100%',
  //     data: {
  //       photos: this.photos,
  //       photo: this.photo,
  //       index: event,
  //       patientPhotoIndex: this.photos.findIndex(p => p.id == this.photo.id)
  //     }
  //   });
  // }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
