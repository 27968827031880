<div #exterior kendoTooltip tooltipClass="em-tooltip" position="left">
  <ngx-loading [show]="loading || navLocked"></ngx-loading>

  <div class="container panel-item-border pt-2">
    <!-- Header -->
    <div class="row" #headerSection>
      <div class="col d-flex">
        <h5>{{ !isTreatmentPlan ? 'Treatment' : 'Assessment' }}</h5>
        <button
          (click)="saveAsFavourite()"
          class="btn btn-sm em-btn em-btn-green btn-treatment"
          data-test-id="observation-favourite-save"
          [ngClass]="{ 'mr-2': !isServicePaid }"
        >
          <i class="far fa-heart" title="Save as Favorite"></i>
        </button>
      </div>
    </div>

    <!-- Cooltone Observations -->
    <div class="coolsculpting-detail-wrapper d-flex pb-2 teal-font w-100">
      <div class="col-1 p-0"></div>
      <div class="col-3 p-0">Treatment Area</div>
      <div class="col-2 p-0">Duration (min)</div>
      <div class="col-2 p-0">Intensity (%)</div>
      <div class="col-2 p-0">Total</div>
      <div class="col-2"></div>
    </div>

    <ng-scrollbar *ngIf="treatmentMap && treatmentMap.size > 0" [autoHeightDisabled]="false" class="scrollbar-fix">
      <mat-accordion>
        <mat-expansion-panel *ngFor="let treatment of treatmentMap | keyvalue">
          <mat-expansion-panel-header class="px-0 py-0 pr-2" [collapsedHeight]="'36px'" [expandedHeight]="'36px'">
            <mat-panel-title>
              <div class="observation-area disable-input-number-spinner">
                <div class="bigger p-0 col-1 justify-content-center d-flex">
                  <i [ngStyle]="{ color: getCircleColor(treatment.key) }" class="fas fa-circle"></i>
                </div>
                <div class="col-3 p-0">
                  <h6 class="small-heading navy-font mb-0 truncate-two-lines">
                    {{ coolToneFormService.getAreaByObsKey(treatment.key).name }} :
                    {{ coolToneFormService.getPositionAndLocationText(treatment.value.observation) }}
                  </h6>
                </div>
                <div class="col-2 pl-1 pr-3">
                  <input
                    class="w-100 form-control inline-input text-right"
                    type="number"
                    min="0"
                    [(ngModel)]="treatment.value.observation.details.duration"
                    [readonly]="service.isLocked || associatedPlannedTreatment?.paymentStatus == PaymentStatus.Paid"
                    (ngModelChange)="dirtyDetails = true"
                  />
                </div>
                <div class="col-2 pl-1 pr-3">
                  <input
                    class="w-100 form-control inline-input text-right"
                    type="number"
                    min="0"
                    max="100"
                    [(ngModel)]="treatment.value.observation.details.intensity"
                    [readonly]="service.isLocked || associatedPlannedTreatment?.paymentStatus == PaymentStatus.Paid"
                    (ngModelChange)="dirtyDetails = true"
                  />
                </div>
                <div class="col-2 px-1 py-0">
                  <app-observation-billing
                    [service]="service"
                    [key]="treatment.key"
                    [observations]="[getObservation(treatment.key)]"
                    [isLocked]="service.isLocked || associatedPlannedTreatment?.paymentStatus == PaymentStatus.Paid"
                  >
                  </app-observation-billing>
                </div>
                <div class="col-2 p-0 d-flex justify-content-end">
                  <button
                    *ngIf="
                      !service?.isLocked &&
                      isPriceOverridden(treatment.key) &&
                      associatedPlannedTreatment?.paymentStatus != PaymentStatus.Paid
                    "
                    type="button"
                    class="btn btn-sm em-btn em-btn-green d-flex align-items-center justify-content-center mr-1"
                    (click)="undoOverridePrice(treatment.key)"
                  >
                    <i class="far fa-undo" title="Undo Price Overide"></i>
                  </button>

                  <button
                    *ngIf="!service?.isLocked && associatedPlannedTreatment?.paymentStatus != PaymentStatus.Paid"
                    data-test-id="observation-delete"
                    class="btn btn-sm em-btn em-btn-green d-flex align-items-center justify-content-center mr-1"
                    (click)="deleteTreatment(treatment.key)"
                  >
                    <small><i class="far fa-trash-alt" title="Delete Treatment"></i></small>
                  </button>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-scrollbar>
    <div class="d-flex" #footerArea>
      <div class="w-100 grey-four-border-top">
        <app-treatment-billing
          [service]="service"
          (sendChargeAmount)="updateChargeAmount($event)"
        ></app-treatment-billing>
      </div>
    </div>
  </div>
</div>

<!-- Notes -->
<div class="grey-two-background p-2" #notesSection>
  <div class="row px-3">
    <div class="col d-flex">
      <h6 class="mt-1 small-heading navy-font">Treatment Notes</h6>
    </div>
  </div>
  <div class="row px-3">
    <div class="col">
      <textarea
        [ngClass]="{ 'grey-two-background': service.isLocked }"
        class="service-chart-input treatment-notes-input w-100"
        placeholder="Add a note"
        [readonly]="service.isLocked"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"
        [(ngModel)]="serviceNoteText"
        (change)="currentDataService.treatmentIsDirty = true"
      ></textarea>
    </div>
  </div>
</div>

<div class="row mt-2 px-3" #saveSection>
  <button (click)="toggleView()" class="btn btn-grey-two btn-clean em-btn-black-text ml-auto">CLOSE</button>
  <button
    *ngIf="!service?.isLocked"
    (click)="saveTreatment(false); dirtyDetails = false"
    [disabled]="!currentDataService.treatmentIsDirty && !dirtyDetails"
    class="btn em-btn em-btn-green ml-2"
  >
    Save
  </button>
  <button
    *ngIf="!service?.isLocked"
    (click)="saveTreatment(true); dirtyDetails = false"
    [disabled]="!currentDataService.treatmentIsDirty && !dirtyDetails"
    class="btn em-btn em-btn-green ml-2"
  >
    Save & Close
  </button>
</div>
