import { IChargeableAsset } from '@interfaces/IChargeableAsset';
import { ChargeableAsset } from './chargeable-asset';
import { Observation } from '../observation/observation';

export class ObservationChargeableAsset extends ChargeableAsset {
  obr: Observation;

  constructor(obr: Observation) {
    super();
    this.obr = obr;
  }

  public getChargeableAsset(): IChargeableAsset {
      return this.obr;
  }
}
