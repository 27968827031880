import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { isNullOrUndefined } from '@app/shared/helpers';
import { environment } from '@environments/environment';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ChatUser } from '../models/chat-user.model';
import { ChatChannelNotificationsCount } from './../models/chat-channel-notifications-count.model';
import { ChatChannel } from './../models/chat-channel.model';
import { CreateChatChannelRequestModel } from './../models/create-chat-channel-request.model';
import { ChatMessagesService } from './chat-messages.service';
import { ChatUsersService } from './chat-users.service';

@Injectable()
export class ChatChannelsService implements OnDestroy {
  public channels: ChatChannel[] = [];
  public privateChannels: ChatChannel[] = [];
  public privateChannelTitles = new Map<number, string>();
  public channelsNotificationCounts = new Map<number, number>();
  public selectedChannel: ChatChannel = null;

  public unreadNotificationsCount = 0;
  private notificationCountChanged = new Subject<number>();
  public onNotificationsCountChange$ = this.notificationCountChanged.asObservable();

  private channelUnreadNotificationCount = new Subject<number>();
  public channelUnreadNotificationCount$ = this.channelUnreadNotificationCount.asObservable();

  private selectedChannelChanged = new Subject<ChatChannel>();
  public onChannelSelected$ = this.selectedChannelChanged.asObservable();

  private selectedChannelRemoved = new Subject<ChatChannel>();
  public selectedChannelRemoved$ = this.selectedChannelRemoved.asObservable();

  private unsub = new Subject<any>();

  constructor(
    private http: HttpClient,
    private chatUserService: ChatUsersService,
    private messagesService: ChatMessagesService
  ) {
    this.messagesService.onMessageReceived$.subscribe((message) => {
      if (!this.selectedChannel || this.selectedChannel.id !== message.channelId) {
        let localChannel =
          this.channels.find((_) => _.id === message.channelId) ??
          this.privateChannels.find((_) => _.id === message.channelId);
        if (localChannel && message.chatUserId !== this.chatUserService.currentChatUser.chatUserId) {
          localChannel.newMessages = true;
          this.addToNotificationsCount(1, localChannel.id);
        }
      }
    });

    this.chatUserService.onCurrentUserUpdated$
      .pipe(
        takeUntil(this.unsub),
        mergeMap((currUser) => this.getAllChannels())
      )
      .subscribe();

    this.getAllChannels();
  }

  setChannelsNotificationsCounts(counts: ChatChannelNotificationsCount[]) {
    counts.forEach((cc) => {
      if (cc.notificationsCount > 0) {
        this.setNotificationCount(cc.notificationsCount, cc.chatChannelId);
      }
    });
  }

  setNotificationCount(count: number, channelId: number) {
    this.channelsNotificationCounts.set(channelId, count);
    this.setTotalNotificationsCount();
  }

  addToNotificationsCount(count: number, channelId: number) {
    if (!count || channelId === 0) {
      return;
    }
    let channelCount = this.channelsNotificationCounts.get(channelId);
    if (!channelCount) {
      channelCount = count;
    } else {
      channelCount += count;
    }
    if (channelCount < 0) {
      channelCount = 0;
    }
    this.channelsNotificationCounts.set(channelId, channelCount);
    this.setTotalNotificationsCount();
  }

  private setTotalNotificationsCount() {
    this.unreadNotificationsCount = 0;
    this.notificationCountChanged.next(this.unreadNotificationsCount);
    this.channelsNotificationCounts.forEach((value, key) => {
      this.unreadNotificationsCount += value;
      if (this.unreadNotificationsCount < 0) {
        this.unreadNotificationsCount = 0;
      }
      this.notificationCountChanged.next(this.unreadNotificationsCount);
    });
  }

  createChatChannel(model: CreateChatChannelRequestModel): Observable<void> {
    return this.http.post<void>(environment.baseUrl + 'api/Messaging/CreateChatChannel', model);
  }

  getPrivateChatChannel(model: ChatChannel): Observable<ChatChannel> {
    return this.http.post<ChatChannel>(environment.baseUrl + 'api/Messaging/GetPrivateChatChannel', model);
  }

  getAllChannels(): Observable<ChatChannel[]> {
    return this.http.get<ChatChannel[]>(environment.baseUrl + 'api/Messaging/GetAllChatChannelsForUser').pipe(
      tap((result) => {
        this.channels = result.filter((c) => !c.private);
        this.privateChannels = result.filter((c) => c.private);
        this.privateChannels.forEach((channel) => this.setPrivateChatTitle(channel));
      })
    );
  }

  markMessagesAsReadForChannel(channelId: number): Observable<number> {
    return this.http.get<number>(environment.baseUrl + 'api/Messaging/ReadChannel/' + channelId);
  }

  closeChannel(channelId: number): Observable<void> {
    if (this.selectedChannel.id === channelId) {
      this.selectedChannel = null;
      this.selectedChannelChanged.next(null);
    }
    return this.http.put<void>(environment.baseUrl + 'api/Messaging/CloseChatChannel/' + channelId, null);
  }

  channelClosed(channelId: number) {
    if (this.selectedChannel && this.selectedChannel.id === channelId) {
      this.selectedChannelRemoved.next({ ...this.selectedChannel });
      this.selectedChannel = null;
      this.selectedChannelChanged.next(null);
    }
    this.channels = this.channels.filter((channel) => channel.id !== channelId);
    this.privateChannels = this.privateChannels.filter((channel) => channel.id !== channelId);
  }

  updateChannel(model: ChatChannel): Observable<ChatChannel> {
    return this.http.put<ChatChannel>(environment.baseUrl + 'api/Messaging/UpdateChatChannel', model);
  }

  addChannel(channel: ChatChannel) {
    if (channel.private) {
      if (!this.privateChannels.find((c) => c.id === channel.id)) {
        this.privateChannels.push(channel);
        this.setPrivateChatTitle(channel);
        this.privateChannels.sort((a, b) =>
          this.privateChannelTitles.get(a.id).localeCompare(this.privateChannelTitles.get(b.id))
        );
      }
    } else {
      if (!this.channels.find((c) => c.id === channel.id)) {
        this.channels.push(channel);
        this.channels.sort((a, b) => a.name.localeCompare(b.name));
      }
    }
  }

  channelSelected(channel: ChatChannel) {
    if (!isNullOrUndefined(channel) && !isNullOrUndefined(channel.id)) {
      channel.newMessages = false;
      if (channel.id && this.unreadNotificationsCount > 0) {
        this.markMessagesAsReadForChannel(channel.id).subscribe((count) => {
          this.channelUnreadNotificationCount.next(count);
          this.addToNotificationsCount(-count, channel.id);
        });
      }
      this.addToNotificationsCount(-this.channelsNotificationCounts.get(channel.id), channel.id);
      channel.unreadNotificationsCount = 0;
    }
    this.selectedChannel = channel;
    this.selectedChannelChanged.next(channel);
  }

  checkNewMessages(channel: ChatChannel): boolean {
    if (isNullOrUndefined(channel)) {
      return false;
    }
    return (
      channel.newMessages || channel.unreadNotificationsCount > 0 || this.channelsNotificationCounts.get(channel.id) > 0
    );
  }

  createPrivateChannels(currChatUser: ChatUser, selectedUsers: ChatUser[]): Observable<ChatChannel[]> {
    let newChannels = selectedUsers.map((user) => {
      const channel = new ChatChannel();
      channel.allowedUsers = [currChatUser, user];
      channel.private = true;
      channel.name = this.getChannelNameFromUserIds(channel.allowedUsers);
      channel.status = user.status;
      return channel;
    });

    return forkJoin(newChannels.map((channel) => this.getPrivateChatChannel(channel))).pipe(
      tap((channels) => {
        if (channels[0]) {
          this.channelSelected(channels[0]);
        }
      })
    );
  }

  setPrivateChatTitle(channel: ChatChannel) {
    if (!this.chatUserService.currentChatUser) return;
    let allowedUserIds = channel.name.split('-').map((id) => +id);
    allowedUserIds = allowedUserIds.filter((id) => id != this.chatUserService.currentChatUser.chatUserId);
    const chanelTitle = allowedUserIds.map((id) => this.chatUserService.chatUserLookup(id).userName).join(', ');
    this.privateChannelTitles.set(channel.id, chanelTitle);
  }

  getChannelNameFromUserIds(users: ChatUser[]) {
    return users
      .map((u) => u.chatUserId)
      .sort()
      .join('-');
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
