import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceTemplateUserCommission } from '@models/service/service-template-user-commission';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { ServiceSelectionRequiredValidator } from './clinic-service-template-validator';
import { ObservationListItem } from '@models/observation/observation';

@Component({
  selector: 'app-commission-edit-modal',
  templateUrl: './commission-edit.component.html',
  styleUrls: ['./commission-edit.component.less'],
})
export class CommissionEditComponent implements OnInit {
  editForm: FormGroup = new FormGroup({
    service: new FormControl(null, ServiceSelectionRequiredValidator),
    observationListId: new FormControl(null),
    isFlatRate: new FormControl(false),
    commission: new FormControl(
      null,
      Validators.compose([Validators.required, Validators.min(0), Validators.max(100), Validators.maxLength(4)])
    ),
  });
  isNew: boolean;
  isInjection: boolean;
  filteredServices: Observable<ClinicServiceTemplate[]>;

  constructor(
    private dialogRef: MatDialogRef<CommissionEditComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      serviceCommission: ServiceTemplateUserCommission;
      services: ClinicServiceTemplate[];
      disabledServiceIds: number[];
      disabledSupplyIds: [number, number][];
      injectableReqIds: number[];
      clinicSupplies: ObservationListItem[];
    }
  ) {}

  ngOnInit(): void {
    this.editForm.controls.service.valueChanges.subscribe((value) => {
      this.isInjection = value && value.serviceDetailTemplateId == 3; //Injections
    });

    this.editForm.controls.isFlatRate.valueChanges.subscribe((isFlatRate) => {
      const commissionControl = this.editForm.controls.commission;
      const percentValidator = Validators.compose([
        Validators.required,
        Validators.min(0),
        Validators.max(100),
        Validators.maxLength(4),
      ]);
      const flatValidators = Validators.compose([Validators.required, Validators.min(0)]);

      if (isFlatRate) {
        commissionControl.setValidators(flatValidators);
      } else {
        commissionControl.setValidators(percentValidator);
      }

      commissionControl.updateValueAndValidity();
    });

    this.filteredServices = this.editForm.controls.service.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.serviceName;
        return name ? this.servicesFilter(name as string) : this.data.services.slice();
      })
    );

    this.editForm.setValue({
      service: this.getService(this.data.serviceCommission.serviceTemplateId),
      observationListId: this.data.serviceCommission.observationListId,
      isFlatRate: this.data.serviceCommission.isFlatRate,
      commission: this.data.serviceCommission.commission,
    });

    this.isNew = this.data.serviceCommission.id == 0;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    let origComm = this.data.serviceCommission;
    let form = this.editForm.controls;
    origComm.serviceTemplateId = form.service.value.id;
    origComm.serviceTemplateTypeId = form.service.value.serviceTemplateTypeId;
    origComm.isFlatRate = form.isFlatRate.value;
    origComm.commission = form.commission.value;
    origComm.observationListId = form.observationListId.value;
    origComm.serviceName = form.service.value.serviceName;
    this.dialogRef.close(origComm);
  }

  getServiceName(service: ClinicServiceTemplate): string {
    return service ? service.serviceAltName : '';
  }

  getService(id: number): ClinicServiceTemplate {
    let service = this.data.services.find((s) => s.id == id);
    return service ?? null;
  }

  checkServiceDisabled(id: number): boolean {
    return this.data.disabledServiceIds.includes(id);
  }

  checkSupplyDisabled(supplyId: number): boolean {
    return this.data.disabledSupplyIds.some((dis) => {
      const [disabledServiceId, disabledSupplyId] = dis;
      const selectedServiceId = this.editForm.controls.service.value?.id;
      return selectedServiceId === disabledServiceId && supplyId === disabledSupplyId;
    });
  }

  checkInjectableReq(): boolean {
    const selectedService = this.editForm.controls.service.value as ClinicServiceTemplate;
    return this.data.injectableReqIds.includes(selectedService.id);
  }

  private servicesFilter(name: string): ClinicServiceTemplate[] {
    const filterValue = name.toLowerCase();
    const services = this.data.services;
    return services ? services.filter((service) => service.serviceAltName.toLowerCase().includes(filterValue)) : [];
  }
}
