<ngx-loading [show]="loading"></ngx-loading>
<div class="h-100 d-flex flex-column">
  <div class="message-area">
    <perfect-scrollbar>
      <div class="pt-3 px-3 pb-0">
        <div *ngFor="let message of messages; let i = index">
          <div
            *ngIf="i === 0 || messages[i - 1].dateStr !== message.dateStr"
            class="date-divider mb-3 font-weight-bold teal-font bigger text-center"
          >
            {{ message.when | date : 'EEEE, MMMM d' }}
          </div>

          <div
            class="d-flex mb-2 message-template"
            [class.message-new]="messages.length - i <= unreadNotificationsCount"
            [id]="'message-' + i"
          >
            <div class="author-avatar mr-3">
              <img class="img-avatar" [src]="message.avatar" alt="" />
            </div>
            <div class="">
              <div class="mb-1">
                <span class="font-weight-bold bigger mr-2">
                  {{ message.userName }}
                </span>
                {{ message?.when | date : 'h:mm a' }}
              </div>
              <div class="message-content medium" [innerHTML]="message?.content | keepHtml"></div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>

  <div class="px-3 pb-3 pt-0 message-compose">
    <app-message-compose></app-message-compose>
  </div>
</div>
