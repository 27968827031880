<ngx-loading [show]="loading"></ngx-loading>

<div class="modal-header border-0 p-0">
  <button type="button" class="btn white-font p-0 ml-auto mr-2" aria-label="Close" (click)="dialogRef.close()">
    <i class="fal fa-times"></i>
  </button>
</div>
<div class="navy-background justify-content-center mb-3 etreatment-form-container">
  <h6 class="modal-title text-uppercase white-font text-center">Assign eTreatment Form</h6>
</div>
<app-catalogue-etreatment-forms
  [assignMode]="true"
  (assigneTreatmentForm)="assigneTreatmentForm($event)"
></app-catalogue-etreatment-forms>

<!-- <div class="container p-0 pb-3">
  <div class="search-container navy-background justify-content-center etreatment-form-container">
    <h6 class="modal-title text-uppercase white-font text-center mb-4">Add eTreatment Form</h6>
    <div class="input-group pb-2">
      <div class="input-group-prepend">
        <div class="input-group-text border-radius grey-three-background border-0">
          <i class="fa fa-search"></i>
        </div>
      </div>
      <input
        [formControl]="searchFormControl"
        type="text"
        name="eTreatmentFormSearch"
        class="form-control border-0 grey-three-background"
        placeholder="Search"
      />
    </div>

    <div *ngIf="filterType" class="d-flex align-items-center justify-content-center">
      <button (click)="resetFilter()" class="btn em-btn filter-button ml-2">
        <i class="fa fa-times"></i>
        {{ filterType.name }}
      </button>
    </div>
  </div>

  <div class="etreatment-form-container">
    <div *ngFor="let item of eTreatmentForms" class="row navy-font clinic-item">
      <div class="col-6 p-2">{{ item.name }}</div>
      <div class="col-4 p-2">{{ item.eTreatmentFormCategory.name }}</div>
      <div class="col-2 justify-content-end">
        <button class="btn em-btn em-btn-green btn-small ml-0" (click)="closeModal(item)">Assign</button>
      </div>
    </div>
  </div>

  <div class="save-changes-container my-3 text-center">
    <button class="btn em-btn em-btn-green ml-2" (click)="activeModal.dismiss('Cross click')">Cancel</button>
  </div>
</div> -->
