import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { Visit } from '@models/visit';
import { Patient } from '@models/patient';
import { VisitService } from '@services/visit.service';
import { PatientService } from '@services/patient.service';
import { CurrentDataService } from '@services/currentData.service';
import { VisitConfirmedStatus } from '@models/visit-confirm-status';
import { isNullOrUndefined } from '@app/shared/helpers';

@Component({
  selector: 'app-visit-status-dialog',
  templateUrl: './visit-status-dialog.component.html',
  styleUrls: ['./visit-status-dialog.component.less'],
})
export class VisitStatusDialogComponent implements OnInit, OnDestroy {
  public visits: Visit[] = [];
  private unsub: Subject<void> = new Subject<void>();
  private date: Date;
  private checkedInList: number[] = [];

  constructor(
    public visitService: VisitService,
    public patientService: PatientService,
    public currentDataService: CurrentDataService,
    public dialogRef: MatDialogRef<VisitStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    this.checkedInList = [];
    this.date = this.data.date;
    const oneDayForward = moment(this.date).add(1, 'days').toDate();
    this.visitService
      .getVisitsByDate(this.date, oneDayForward)
      .pipe(takeUntil(this.unsub))
      .subscribe((visits) => {
        visits.forEach((v) => {
          if (!v.checkedIn && v.appointments.length > 0) {
            this.visits.push(v);
          }
        });
      });
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public onConfirmClick(): void {
    this.dialogRef.close();
    this.visits.forEach((v) => {
      if (this.checkedInList.includes(v.visitId)) {
        v.noShow = false;
        if (isNullOrUndefined(v.confirmedStatus) || v.confirmedStatus === VisitConfirmedStatus.Unconfirmed) {
          v.confirmedStatus = VisitConfirmedStatus.StaffConfirmed;
          v.confirmedTime = new Date();
        }
        v.checkedIn = true;
      }
      this.updateVisit(v);
    });
  }

  public checkInClick(visit: Visit) {
    if (this.checkedInList.includes(visit.visitId)) {
      this.checkedInList.splice(this.checkedInList.indexOf(visit.visitId), 1);
    } else {
      this.checkedInList.push(visit.visitId);
    }
  }

  public getPatientName(patient: Patient) {
    return patient.firstName + ' ' + patient.lastName;
  }

  private updateVisit(visit: Visit) {
    this.visitService.updateVisit(visit).subscribe((v) => {});
  }

  public getReadableDate() {
    return moment(this.date).format('MMM Do YYYY');
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}

export interface DialogData {
  date: Date;
}
