import { Router } from '@angular/router';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { PatientService } from '@services/patient.service';
import { ProviderDetailsReportEntity, ReportsService } from '@services/reports.service';
import { Subject } from 'rxjs';
import { distinct, mergeMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-provider-details',
  templateUrl: './provider-details.component.html',
  styleUrls: ['./provider-details.component.less'],
})
export class ProviderDetailsComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  unsub = new Subject<any>();
  providerDetails: ProviderDetailsReportEntity[] = [];

  @Input() gridState: DataSourceRequestState;
  @Input() serviceProviderId: string;

  constructor(private router: Router, private reportsService: ReportsService, private patientService: PatientService) {}

  ngOnInit() {
    this.reportsService.reportsModelUpdated
      .pipe(
        distinct(),
        tap(() => (this.loading = true)),
        mergeMap(() => this.reportsService.getProvidersReportDetails(this.serviceProviderId, this.gridState)),
        tap(() => (this.loading = false))
      )
      .subscribe((gridDataResult) => (this.providerDetails = gridDataResult));
  }

  navigateToPatientInvoice(patientId: number, invoiceId: number) {
    this.patientService
      .getPatientById(patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((patient: any) => {
        this.patientService.patientPanelPatient = patient;
        this.router.navigate([
          '/reports',
          {
            outlets: {
              'action-panel': [
                'patient',
                patientId + '_patientprofiletab',
                'patienttabs',
                'patientaccounttab',
                'invoice',
                invoiceId,
              ],
            },
          },
        ]);
      });
  }

  navigateToPatientAccountTab(patientId: number) {
    this.patientService
      .getPatientById(patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((patient: any) => {
        this.patientService.patientPanelPatient = patient;
        this.router.navigate([
          '/reports',
          {
            outlets: {
              'action-panel': [
                'patient',
                patientId + '_patientprofiletab',
                'patienttabs',
                'patientaccounttab',
                'overview',
              ],
            },
          },
        ]);
      });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
