import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppointmentService } from '@services/appointments.service';
import { BreakpointService } from '@services/breakpoint.service';
import { ClinicsService } from '@services/clinics.service';
import { CurrentDataService } from '@services/currentData.service';
import { EventsService } from '@services/events.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.less'],
})
export class ScheduleComponent implements OnInit, OnDestroy {
  private unsub = new Subject<any>();
  mobileView: boolean;
  actionPanelOpened = false;

  constructor(
    private router: Router,
    private clinicsService: ClinicsService,
    private currentDataService: CurrentDataService,
    private eventsService: EventsService,
    private appointmentService: AppointmentService,
    private breakpointService: BreakpointService
  ) {}

  ngOnInit() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const { url } = val;
        if (url.includes('action-panel')) {
          this.actionPanelOpened = true;
          this.eventsService.actionPanelOpen.next(true);
        } else {
          this.actionPanelOpened = false;
          this.eventsService.actionPanelOpen.next(false);
        }
      }
    });

    this.breakpointService.mobileBreakpoint$.pipe(takeUntil(this.unsub)).subscribe((mobileView) => {
      this.mobileView = mobileView;
    });

    this.currentDataService.fetchCurrentData();
  }

  get displayCalendar(): boolean {
    return Boolean(this.clinicsService.clinic);
  }

  changeSelectedClinic() {
    //Clear-up, close down and reload with new clinic
    this.appointmentService.closeActionPanel();
    this.unsub.next();
    this.unsub.complete();
    this.ngOnInit();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
