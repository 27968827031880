import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class ActionPanelService {

    private actionPanelOpen = new Subject();

    actionPanelClosed = new Subject();
    actionPanelClosed$ = this.actionPanelClosed.asObservable();

    constructor() { }

    actionPanelVisible() {
        return this.actionPanelOpen.asObservable();
    }

    actionPanelCompleteTransition(value: boolean) {
        this.actionPanelOpen.next(value);
    }
}

@Injectable()
export class MasterOverlayService implements OnDestroy {

    public masterOverlayEnabled = new Subject<boolean>();

    constructor() { }

    masterOverlayState(overlay: boolean) {
        this.masterOverlayEnabled.next(overlay);
    }

    ngOnDestroy() {
        this.masterOverlayEnabled.next();
    }
}
