import { ClinicsService } from '@services/clinics.service';
import { takeUntil, startWith, map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CancellationReason } from '@models/cancellation-reason';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete/confirm-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Observable } from 'rxjs';
import { CancellationReasonService } from '@services/cancellation-reason.service';
import { AuthService } from '@app/auth/auth.service';
import { authRoles } from '@app/auth/auth-permissions';
import { Policies } from '@app/auth/auth-policies';

@Component({
  selector: 'app-org-cancellation-reason',
  templateUrl: './org-cancellation-reason.component.html',
  styleUrls: ['./org-cancellation-reason.component.less'],
})
export class OrgCancellationReasonComponent implements OnInit, OnDestroy {
  searchValue = '';
  disableGrid = false;
  loading = false;
  unsub: Subject<void> = new Subject<void>();
  cancellationReason: CancellationReason[] = [];
  searchCtrl: FormControl;
  filteredCancellationReason: Observable<CancellationReason[]>;
  clinicId: number;
  gridView: GridDataResult;
  gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  formGroup: FormGroup;
  editedRowIndex: number;
  editedDataItem: CancellationReason;
  developerPolicy = Policies.developer;

  constructor(
    private clinicsService: ClinicsService,
    private cancellationReasonService: CancellationReasonService,
    private router: Router,
    private catalogueUpdatesService: CatalogueUpdatesService,
    private deleteDialog: MatDialog,
    private authService: AuthService
  ) {
    this.searchCtrl = new FormControl();
    this.filteredCancellationReason = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map((pack) => this.filterCancellationReason(pack))
    );
  }

  ngOnInit() {
    this.clinicsService.clinicIdSelected$
      .asObservable()
      .pipe(takeUntil(this.unsub))
      .subscribe((clinicId) => {
        this.clinicId = clinicId;
        this.refreshData();
      });
    this.gridView = {
      data: [],
      total: 0,
    };
    this.loading = true;
    this.catalogueUpdatesService.catalogueUpdated.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.disableGrid = false;
      if (this.catalogueUpdatesService.refreshRequired) {
        this.catalogueUpdatesService.refreshRequired = false;
        this.refreshData();
      }
    });

    this.catalogueUpdatesService.catalogueUpdated.pipe(takeUntil(this.unsub)).subscribe(() => this.refreshData());

    this.refreshData();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  filterCancellationReason(name: string) {
    let filterResults: CancellationReason[] = [];

    if (name !== '') {
      this.gridView = {
        data: this.cancellationReason.filter((rsrc) => rsrc.name.toLowerCase().includes(name.toLowerCase())),
        total: this.cancellationReason.filter((rsrc) => rsrc.name.toLowerCase().includes(name.toLowerCase())).length,
      };
      filterResults = this.cancellationReason.filter((rsrc) => rsrc.name.toLowerCase().includes(name.toLowerCase()));
    } else {
      this.gridView = {
        data: this.cancellationReason,
        total: this.cancellationReason.length,
      };
      filterResults = [];
    }

    return filterResults;
  }

  refreshData() {
    this.loading = true;
    this.cancellationReasonService.getCancellationReason(this.clinicId).subscribe((res) => {
      this.cancellationReason = [];
      res.forEach((doc) => {
        const docData = doc;
        const pushItem: CancellationReason = {
          cancellationReasonId: docData.cancellationReasonId,
          name: docData.name,
        };
        this.cancellationReason.push(pushItem);
      });
      this.loadItems();
      this.loading = false;
    });
  }

  onAddClick({ sender }) {
    this.disableGrid = true;
    this.router.navigate([
      '/management/organization/clinics/cancellation-reason',
      { outlets: { 'action-panel': ['edit-cancellation-reason', '_'] } },
    ]);
  }

  editHandler({ sender, rowIndex, dataItem }) {
    this.disableGrid = true;
    this.router.navigate([
      '/management/organization/clinics/cancellation-reason',
      { outlets: { 'action-panel': ['edit-cancellation-reason', dataItem.cancellationReasonId] } },
    ]);
  }

  cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  saveHandler({ sender, rowIndex, formGroup, isNew }) {
    const reason: CancellationReason = formGroup.value;
    if (isNew) {
      this.cancellationReasonService.addCancellationReason(reason).subscribe(() => {
        this.refreshData();
      });
    } else {
      this.cancellationReasonService.updateCancellationReason(reason).subscribe(() => {
        this.refreshData();
      });
    }
    sender.closeRow(rowIndex);
  }

  removeHandler({ dataItem }) {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        const dataItemToRemove = {
          cancellationReasonId: dataItem.cancellationReasonId,
          name: dataItem.name,
        };
        this.cancellationReasonService.removeCancellationReason(dataItemToRemove).subscribe(() => {
          this.refreshData();
        });
      }
    });
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  loadItems() {
    this.gridView = {
      data: this.cancellationReason,
      total: this.cancellationReason.length,
    };
  }

  openDeleteDialog(): void {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
      data: { result: '' },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
