import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, combineLatest } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { FormTypeService } from '@services/form-type.service';
import { Form } from '@models/forms/form';
import { FormService } from '@services/form.service';
import { PatientFormService } from '@services/patient-form.service';
import { ServiceForm } from '@models/forms/service-form';

interface FormTypeGroup {
  name: string;
  forms: Form[];
}

@Component({
  selector: 'app-assign-clinic-form',
  templateUrl: './assign-clinic-form.component.html',
  styleUrls: ['./assign-clinic-form.component.less'],
})
export class AssignClinicFormComponent implements OnInit, OnDestroy {
  unsub: Subject<void>;
  searchFormControl: FormControl;
  formItemGroups: FormTypeGroup[];
  assignedServiceForms: ServiceForm[];
  loading = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formTypeService: FormTypeService,
    private formService: FormService,
    private patientFormService: PatientFormService
  ) {}

  ngOnInit() {
    this.unsub = new Subject();
    this.loading = true;
    this.searchFormControl = new FormControl('');

    combineLatest([
      this.formService.getActiveForms(),
      this.formTypeService.getFormTypes(),
      this.patientFormService.patientForms$,
      this.searchFormControl.valueChanges.pipe(startWith('')),
    ])
      .pipe(takeUntil(this.unsub))
      .subscribe(([forms, formTypes, patientForms, searchString]) => {
        let searchedForms = this.filterForms(forms, searchString);
        for (let form of searchedForms) {
          if (this.assignedServiceForms) {
            form['assigned'] = this.assignedServiceForms.some((sf) => sf.clinicFormId == form.id);
          } else if (patientForms) {
            form['assigned'] = patientForms.some((pf) => pf.clinicFormId == form.id && pf.isSigned == false);
          } else {
            form['assigned'] = false;
          }
          
        }
        this.formItemGroups = formTypes.map((ft) => ({
          name: ft.name,
          forms: searchedForms.filter((form) => form.formTypeId === ft.id),
        }));
        this.loading = false;
      });
  }

  closeModal(form: Form) {
    this.activeModal.close(form);
  }

  filterForms(forms: Form[], searchString: string) {
    if (!searchString) return forms.slice();
    searchString = searchString.toLocaleLowerCase();
    return forms.filter((form) => form.name.toLocaleLowerCase().includes(searchString));
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
