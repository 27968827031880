export class AdminFeePurchased {
    serviceTypeDescription : string;
    serviceTemplateId: number;
    purchaseDate: Date;
    quantity: number;
    pricePaid: number;
    invoiceLineItemId: number;
    invoiceId: number;
    constructor(init?: Partial<AdminFeePurchased>) {
      Object.assign(this, init);
    }
  }