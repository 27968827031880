import { Clinic } from '@models/clinic';
import { ServiceTemplateType } from '@models/service/service-template-type';
import { User } from '@models/user';
import { FormType } from './form-type';

export class Form {
  public id: number;
  public name: string;
  public notes: string;
  public createdDate: Date;
  public modifiedDate: Date;
  public formTypeId: number;
  public formType: FormType;
  public serviceTemplateTypeId?: number;
  public serviceTemplateType: ServiceTemplateType;
  public formDefinition: string;
  public isDeleted: boolean;
  public isSharedForm: boolean;
  public sharedByUserId: string;
  public sharedByUser: User;
  public sharedByClinicId?: number;
  public sharedByClinic: Clinic;
  public sharedFormCreationDate?: Date;
  public createdByUserId: string;
  public createdByUser: User;

  public constructor(init?: Partial<Form>) {
    Object.assign(this, init);
    if (this.createdDate && typeof this.createdDate == 'string') this.createdDate = new Date(this.createdDate);
    if (this.modifiedDate && typeof this.modifiedDate == 'string') this.modifiedDate = new Date(this.modifiedDate);
    if (this.sharedFormCreationDate && typeof this.sharedFormCreationDate == 'string')
      this.sharedFormCreationDate = new Date(this.sharedFormCreationDate);
  }
}
