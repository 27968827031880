<div class="px-3 pt-3">
  <div class="row">
    <div class="col">
      <p class="text-uppercase white-font font-weight-bold mt-1 bigger mb-0">Clinic Channels</p>
    </div>
    <div class="col-2 text-right">
      <button *appAuthGuard="messagingPolicy" class="btn p-1" (click)="onAddChannel()">
        <i class="fal fa-plus-circle white-font"></i>
      </button>
    </div>
  </div>

  <div
    class="channel row mb-1"
    [ngClass]="{ selected: channelService.selectedChannel?.id === channel.id }"
    *ngFor="let channel of channelService.channels"
    (click)="onChannelSelected(channel)"
  >
    <div class="col">
      <p
        class="white-font py-1 my-0 bigger grey"
        [ngClass]="{
          'new-messages': channelService.checkNewMessages(channel),
          'white-font': channelService.selectedChannel?.name === channel.name
        }"
      >
        # {{ channel.name }}
        <span *ngIf="channelService.checkNewMessages(channel)" class="notification-badge position-absolute">{{
          channelService.channelsNotificationCounts.get(channel.id)
        }}</span>
      </p>
    </div>
  </div>
</div>
