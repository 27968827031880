<form [formGroup]="toolbarForm">
  <div class="form-row">
    <ng-container formGroupName="observation">
      <ng-container formGroupName="details">
        <!-- Area -->
        <div class="form-group col mb-0">
          <label for="area">Area</label>
          <select class="form-control" formControlName="area" data-test-id="cooltone-toolbar-area-select">
            <option *ngFor="let area of coolToneFormService.areas" [ngValue]="area.id" [label]="area.name">
              {{ area.name }}
            </option>
          </select>
        </div>
        <!--Location-->
        <div *ngIf="displayLocation()" class="form-group col mb-0">
          <label for="location">Location</label>
          <select class="form-control" formControlName="location" data-test-id="cooltone-toolbar-location-select">
            <option value="Anterior">Anterior</option>
            <option value="Posterior">Posterior</option>
          </select>
        </div>
        <!-- Position -->
        <div class="form-group col mb-0">
          <label for="position" class="d-block">L/M/R</label>
          <div class="btn-group btn-group-toggle" ngbRadioGroup formControlName="position">
            <label ngbButtonLabel class="btn em-btn btn-label mr-2 pointer">
              <input ngbButton type="radio" value="left" /> L
            </label>
            <label ngbButtonLabel class="btn em-btn btn-label pointer">
              <input ngbButton type="radio" value="middle" [disabled]="disableMiddlePosition" /> M
            </label>
            <label ngbButtonLabel class="btn em-btn btn-label ml-2 pointer">
              <input ngbButton type="radio" value="right" /> R
            </label>
          </div>
        </div>
        <!-- Inventory -->
        <div class="form-group col-1 mb-0">
          <label for="value">Inventory</label>
          <input
            class="form-control text-white text-center w-100"
            disabled
            [ngClass]="{
              'bg-danger': getInventoryRemaining(false) <= 0 && getInventoryRemaining(false) !== null,
              'bg-success': getInventoryRemaining(false) > 0,
              'bg-white': getInventoryRemaining(false) === null
            }"
            [value]="getInventoryRemaining(true)"
          />
        </div>
      </ng-container>
    </ng-container>
  </div>
</form>
