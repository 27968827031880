<ngx-loading [show]="loading"></ngx-loading>
<div class="row">
  <div class="col">
    <ng-container *ngFor="let error of errors">
      <app-error *ngFor="let fieldError of error.fieldErrors" [message]="fieldError"></app-error>
    </ng-container>
  </div>
</div>
<mat-expansion-panel
  *ngFor="let plannedTreatment of treatmentPlan?.plannedTreatments; let i = index; let last = last; trackBy: trackByFn"
  [ngClass]="{ 'mb-1': !last }"
  [expanded]="false"
  [disabled]="true"
  #panelList
>
  <mat-expansion-panel-header class="px-0 py-0" [collapsedHeight]="'42px'" [expandedHeight]="'42px'">
    <mat-panel-title class="w-100">
      <div class="d-flex panel-box panel-box--small align-items-center expand p-0">
        <div
          class="color-sign mr-2 align-self-center flex-shrink-0"
          [ngStyle]="{ background: plannedTreatment?.treatmentColour }"
        ></div>
        <div
          class="mr-2 expand planned-treatment-text"
          [title]="
            plannedTreatment?.rank +
            ') ' +
            plannedTreatment?.service?.serviceName +
            ' ' +
            getAvailablePlannedTreatments(plannedTreatment)
          "
        >
          {{
            plannedTreatment?.rank +
              ') ' +
              plannedTreatment?.service?.serviceName +
              ' ' +
              getAvailablePlannedTreatments(plannedTreatment)
          }}
        </div>
        <div class="planned-treatment-text expand" *ngIf="plannedTreatment.notes" [title]="plannedTreatment.notes">
          {{ plannedTreatment.notes }}
        </div>
        <button
          *ngIf="plannedTreatment?.service.observations?.length > 0 || plannedTreatment?.isPlannedTreatmentMultiple"
          type="button"
          class="btn btn--smaller p-0 mr-2"
          (click)="panelList.expanded = !panelList.expanded"
        >
          <mat-icon>{{ panelList.expanded ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
        </button>
        <div
          class="text-right panel-box-column shrink mr-2"
          [ngClass]="{
            danger:
              plannedTreatment?.paymentStatus === PaymentStatus.Unpaid &&
              !plannedTreatment['partialRefund'] &&
              (plannedTreatment.treatmentState === treatmentState.Scheduled ||
                plannedTreatment.treatmentState === treatmentState.Completed ||
                plannedTreatment?.isPlannedTreatmentMultiple),
            'spring-green-font':
              plannedTreatment?.paymentStatus === PaymentStatus.Paid && !plannedTreatment['partialRefund'],
            'purple-font': plannedTreatment?.paymentStatus === PaymentStatus.Refund || plannedTreatment['partialRefund']
          }"
        >
          <ng-container
            *ngIf="
              plannedTreatment?.service?.serviceDetailTemplateId === serviceDetailTemplate.None;
              else detailTemplate
            "
          >
            <ng-container *ngIf="plannedTreatment?.isPlannedTreatmentMultiple; else regularTemplate">
              {{ plannedTreatment['plannedTreatmentMultiple'].quantity }}x&nbsp;{{
                plannedTreatment?.service?.chargeAmount | currency
              }}
              /&nbsp;{{ plannedTreatment?.service?.defaultPrice | currency }}
            </ng-container>
            <ng-template #regularTemplate>
              <div
                [attr.data-test-id]="'pt-price-' + plannedTreatment?.id"
                [attr.data-test-value]="
                  plannedTreatment?.service?.overrideDefaultPrice != 0 &&
                  plannedTreatment?.service?.overrideDefaultPrice != null
                    ? plannedTreatment?.service?.overrideDefaultPrice
                    : plannedTreatment?.service?.defaultPrice
                "
              >
                {{
                  (plannedTreatment?.service?.overrideDefaultPrice != 0 &&
                  plannedTreatment?.service?.overrideDefaultPrice != null
                    ? plannedTreatment?.service?.overrideDefaultPrice
                    : plannedTreatment?.service?.defaultPrice
                  ) | currency
                }}
              </div>
            </ng-template>
          </ng-container>
          <ng-template #detailTemplate>
            <div
              [attr.data-test-id]="'pt-price-' + plannedTreatment?.id"
              [attr.data-test-value]="plannedTreatment?.service?.chargeAmount"
            >
              {{ plannedTreatment?.service?.chargeAmount | currency }}
            </div>
          </ng-template>
        </div>
        <div
          *ngIf="
            plannedTreatment?.paymentStatus === PaymentStatus.Refund ||
            (plannedTreatment['partialRefund'] && !showAddButton(plannedTreatment))
          "
          class="refund-icon-container"
        >
          <i class="fas fa-sad-tear purple-font" title="Refunded"></i>
        </div>
      </div>
      <ng-container *ngIf="plannedTreatment?.paymentStatus !== PaymentStatus.Refund">
        <!-- Begin Add Buttons -->
        <button
          *ngIf="showAddButton(plannedTreatment, TabType.Chart)"
          type="button"
          [attr.data-test-id]="'tx-list-add-button'"
          [attr.data-test-value]="'pt-id-' + plannedTreatment.id"
          class="panel-box-button align-items-center justify-content-center em-btn-green pt-id-{{
            plannedTreatment.id
          }}"
          (click)="loading = true; handleAdd(plannedTreatment, i)"
        >
          <i class="far fa-plus" title="Add to Chart"></i>
        </button>
        <button
          *ngIf="showAddButton(plannedTreatment, TabType.VisitPanel)"
          type="submit"
          [attr.data-test-id]="'tx-list-add-button'"
          [attr.data-test-value]="'pt-id-' + plannedTreatment.id"
          class="panel-box-button d-flex align-items-center justify-content-center em-btn-green pt-id-{{
            plannedTreatment.id
          }}"
          (click)="loading = true; handleAdd(plannedTreatment, i)"
        >
          <i class="far fa-plus" title="Add to Calendar"></i>
        </button>
        <button
          *ngIf="showAddButton(plannedTreatment, TabType.ChartDetail)"
          type="button"
          [attr.data-test-id]="'tx-list-add-button'"
          [attr.data-test-value]="'pt-id-' + plannedTreatment.id"
          class="panel-box-button em-btn-green pt-id-{{ plannedTreatment.id }}"
          (click)="loading = true; handleAdd(plannedTreatment, i)"
        >
          <i class="far fa-plus" title="Add to Treatment"></i>
        </button>
        <button
          *ngIf="showAddButton(plannedTreatment, TabType.Invoice)"
          type="button"
          [attr.data-test-id]="'tx-list-add-button'"
          [attr.data-test-value]="'pt-id-' + plannedTreatment.id"
          class="panel-box-button em-btn-green pt-id-{{ plannedTreatment.id }}"
          (click)="loading = true; handleAdd(plannedTreatment, i)"
        >
          <i class="far fa-plus" title="Add to Invoice"></i>
        </button>

        <!-- End Add Buttons -->

        <!-- Begin Pay Buttons -->
        <button
          *ngIf="
            (typeOfTab == TabType.Chart || typeOfTab == TabType.VisitPanel) &&
            plannedTreatment?.isPlannedTreatmentMultiple &&
            plannedTreatment?.paymentStatus === PaymentStatus.Unpaid &&
            !plannedTreatment['partialRefund']
          "
          type="button"
          [attr.data-test-id]="'tx-list-pay-button'"
          [attr.data-test-value]="'pt-id-' + plannedTreatment.id"
          class="panel-box-button em-btn-red pt-id-{{ plannedTreatment.id }}"
          (click)="
            loading = true; payForPlannedTreatmentMultiple(plannedTreatment.plannedTreatmentMultipleId, typeOfTab)
          "
        >
          <i class="far fa-dollar-sign fa-sm pt-2" title="Pay for Service"></i>
        </button>
        <!-- End Pay Buttons -->

        <!-- Begin Schedule Buttons -->
        <button
          *ngIf="
            serviceTemplateIds?.length > 0 &&
            typeOfTab == TabType.Chart &&
            plannedTreatment?.treatmentState === treatmentState.Scheduled &&
            !isAdvancedTreatmentPage &&
            !isLocked
          "
          type="button"
          [attr.data-test-id]="'tx-list-add-button'"
          [attr.data-test-value]="'pt-id-' + plannedTreatment.id"
          class="panel-box-button em-btn-green pt-id-{{ plannedTreatment.id }}"
          (click)="goToScheduledTreatment(plannedTreatment)"
        >
          <i class="far fa-calendar" [title]="plannedTreatment.plannedDate | convertUTC : true"></i>
        </button>

        <button
          *ngIf="
            serviceTemplateIds?.length > 0 &&
            typeOfTab == TabType.Chart &&
            plannedTreatment?.treatmentState === treatmentState.Completed &&
            !isLocked
          "
          (click)="loading = true; goToScheduledTreatment(plannedTreatment)"
          type="button"
          [attr.data-test-id]="'tx-list-add-button'"
          [attr.data-test-value]="'pt-id-' + plannedTreatment.id"
          class="panel-box-button em-btn-green pt-id-{{ plannedTreatment.id }}"
        >
          <i class="far fa-check" [title]="plannedTreatment.plannedDate | convertUTC : true"></i>
        </button>
        <!-- End Schedule Buttons -->
      </ng-container>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div
    class="pb-0 d-flex justify-content-start align-items-stretch note-section"
    *ngIf="
      plannedTreatment.plannedTreatmentMultipleId === undefined &&
      plannedTreatment.notes &&
      plannedTreatment.notes.length > 0
    "
  >
    <div class="color-sign" [ngStyle]="{ background: plannedTreatment?.treatmentColour }"></div>
    <div
      class="line-height-2 my-1 px-2 d-flex justify-content-center align-items-center"
      style="max-width: calc(100% - 7px)"
    >
      {{ plannedTreatment?.notes }}
    </div>
  </div>
  <div [ngClass]="{ 'px-2 pb-0 pt-2': !(plannedTreatment.notes && plannedTreatment.notes.length > 0) }">
    <app-item-detail-display
      [service]="plannedTreatment.service"
      [observationType]="
        plannedTreatment.service.serviceDetailTemplateId === serviceDetailTemplate.Injections
          ? observationTypes.Injectables
          : observationTypes.Coolsculpting
      "
      [isLocked]="isLocked"
      [itemType]="serviceListType.history"
    >
    </app-item-detail-display>
  </div>
  <div *ngIf="plannedTreatment.isPlannedTreatmentMultiple" class="px-2 pb-0 pt-2">
    <app-planned-treatment-multiple-display [plannedTreatmentMultiple]="plannedTreatment['plannedTreatmentMultiple']">
    </app-planned-treatment-multiple-display>
  </div>
</mat-expansion-panel>
