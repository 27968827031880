<div class="d-flex">
  <ng-container *ngIf="addMode">
    <button
      class="btn text-uppercase grey-five-background mr-2 position-relative"
      [class.selected]="'Head' === selectedBtn"
      (click)="selectOption('Head')"
    >
      Head
    </button>
    <button
      class="btn text-uppercase grey-five-background mr-2 position-relative"
      [class.selected]="'Body' === selectedBtn"
      (click)="selectOption('Body')"
    >
      Body
    </button>
    <button
      [disabled]="clinicPhotos.length === 0"
      [title]="clinicPhotos.length === 0 ? 'No Clinic Photos Available' : ''"
      class="btn text-uppercase grey-five-background mr-2 position-relative"
      [class.selected]="'Clinic' === selectedBtn"
      (click)="selectOption('Clinic')"
    >
      Clinic
    </button>
  </ng-container>
  <button *ngIf="addMode" class="btn medium em-btn em-btn-green ml-auto m-2" type="button" (click)="addPhoto()">
    <i class="far fa-plus-circle"></i> Add Photo
  </button>
  <button *ngIf="!addMode" class="btn medium em-btn em-btn-green ml-auto m-2" type="button" (click)="deleteDrawing()">
    <i class="far fa-trash-alt"></i> Delete
  </button>
  <button class="btn btn-sm em-btn em-btn-green m-2" (click)="close()">
    <i class="fas fa-ban" title="Close"></i> Cancel
  </button>
</div>
<div class="header-line mb-2"></div>
<div class="mt-1 d-flex">
  <div *ngIf="addMode" class="list-container">
    <app-generic-photo-list
      [photos]="photoList"
      [seriesMode]="false"
      [filter]="filter"
      [selectedPhoto]="filter.selectedPhoto"
      (selectedPhotoChange)="selectListPhoto($event)"
    >
    </app-generic-photo-list>
  </div>
  <app-draw-tool [photoDrawing]="photoDrawing" [saveCallback]="savePhoto"></app-draw-tool>
</div>
