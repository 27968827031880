import { Component, OnInit } from '@angular/core';
import { ChatMessageFromClient } from '@app/messaging/models/chat-message-from-client.model';
import { ChatChannelsService } from '@app/messaging/services/chat-channels.service';
import { MessagingSignalrService } from '@app/messaging/services/messaging-signalr.service';
import { HubConnectionState } from '@microsoft/signalr';

@Component({
  selector: 'app-message-compose',
  templateUrl: './message-compose.component.html',
  styleUrls: ['./message-compose.component.less'],
})
export class MessageComposeComponent implements OnInit {
  messageText: string = '';
  get disconnected() {
    return (
      this.messagingService.connectionState !== HubConnectionState.Connected &&
      this.messagingService.connectionState !== HubConnectionState.Connecting
    );
  }

  constructor(private channelService: ChatChannelsService, private messagingService: MessagingSignalrService) {}

  ngOnInit() {}

  onSubmit() {
    const message = new ChatMessageFromClient();
    message.content = this.messageText;
    message.channelId = this.channelService.selectedChannel.id;
    this.messagingService.sendMessage(message);
    this.messageText = '';
  }
}
