<h1 mat-dialog-title>Quick Check-In - {{getReadableDate()}}</h1>
<div mat-dialog-content>
  <p *ngIf="!visits.length">Either patients for all visits are checked in or there are no visits scheduled.</p>
  <ul class="pl-0">
    <li *ngFor="let visit of visits" class="pointer">
      <div>
        <mat-checkbox (click)="checkInClick(visit)"></mat-checkbox>
        <div class="d-inline pl-2 align-top">{{getPatientName(visit.patient)}}</div>
      </div>
    </li>
  </ul>
</div>
<div class="text-center">
  <button class="btn em-btn em-btn-green mx-1" (click)="onNoClick()">Cancel</button>
  <button class="btn em-btn em-btn-green mx-1" [disabled]="!visits.length" (click)="onConfirmClick()">Check-In</button>
</div>