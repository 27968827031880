import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { EventsService } from '@services/events.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Pharmacy } from '@models/pharmacy';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { Address } from '@models/address';
import { PharmaciesService } from '@services/pharmacies.service';
import { GeographyService } from '@services/geography.service';
import { ValidationService } from '@services/validation.service';
import { MatDialog } from '@angular/material/dialog';
import { HoursOfOperationDialogComponent } from '../../../dialogs/hours-of-operation/hours-of-operation.component';
import { HoursOfOperation, HoursOfOperationType } from '@models/hoursofoperation';
import { FormatterService } from '@services/formatter.service';
import { isValidId, formatHoursOfOperation } from '../../../../shared/helpers';
import { isNullOrUndefined } from '@app/shared/helpers';
import { MasterOverlayService } from '@services/actionpanel.service';
import { Clinic } from '@models/clinic';
import { ClinicsService } from '@services/clinics.service';

@Component({
  selector: 'app-edit-pharmacy',
  templateUrl: './edit-pharmacy.component.html',
  styleUrls: ['./edit-pharmacy.component.less'],
})
export class EditPharmacyComponent implements OnInit, OnDestroy {
  editPharmacyPanelVisible = false;
  addOrEdit = 'Add';

  pharmacyIdParam: string;

  name: FormControl;
  addressAddress1: FormControl;
  addressAddress2: FormControl;
  addressCity: FormControl;
  addressCountry: FormControl;
  addressPostalCode: FormControl;
  addressProvince: FormControl;
  phoneNumber1: FormControl;
  phoneNumber2: FormControl;
  phoneNumber3: FormControl;
  faxNumber: FormControl;
  email: FormControl;
  website: FormControl;
  hoursOfOperation: string;
  isNew: boolean;

  selectedPharmacy: Pharmacy;
  editedPharmacy: Pharmacy;
  selectedAddress: Address;
  editedAddress: Address;

  countriesOfTheWorld: string[] = [];
  provincesAndStates: string[] = [];

  submitButtonDisabledState = true;

  clinic: Clinic;

  unsub: Subject<void> = new Subject<void>();

  constructor(
    private pharmaciesService: PharmaciesService,
    private catalogueUpdatesService: CatalogueUpdatesService,
    private geographyService: GeographyService,
    public validationService: ValidationService,
    public formatterService: FormatterService,
    private route: ActivatedRoute,
    private router: Router,
    private hoursOfOperationDialog: MatDialog,
    private masterOverlayService: MasterOverlayService,
    private clinicsService: ClinicsService
  ) {
    this.name = new FormControl();
    this.addressAddress1 = new FormControl();
    this.addressAddress2 = new FormControl();
    this.addressCity = new FormControl();
    this.addressCountry = new FormControl();
    this.addressPostalCode = new FormControl('', null);
    this.addressProvince = new FormControl();
    this.phoneNumber1 = new FormControl('', this.validationService.validatePhoneNumber);
    this.phoneNumber2 = new FormControl('', this.validationService.validatePhoneNumber);
    this.phoneNumber3 = new FormControl('', this.validationService.validatePhoneNumber);
    this.faxNumber = new FormControl('', this.validationService.validatePhoneNumber);
    this.email = new FormControl('', [Validators.email]);
    this.website = new FormControl();
    this.hoursOfOperation = '';
  }

  ngOnInit() {
    for (const key in this.geographyService.countriesByName()) {
      if (this.geographyService.countriesByName().hasOwnProperty(key)) {
        this.countriesOfTheWorld.push(key);
      }
    }
    this.selectedPharmacy = this.initPharmacy(this.selectedPharmacy, this.selectedAddress);
    this.editedPharmacy = this.initPharmacy(this.editedPharmacy, this.editedAddress);

    this.isNew = true;
    this.addOrEdit = 'Add';
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      this.pharmacyIdParam = params['pharmid'];
      if (this.pharmacyIdParam !== '_' && this.pharmacyIdParam != null) {
        this.pharmaciesService.getPharmacyById(this.pharmacyIdParam).subscribe((snapshot) => {
          this.selectedPharmacy = snapshot as Pharmacy;
          this.editedPharmacy = snapshot as Pharmacy;
          this.updateProvincesStates();
          this.updateSubmitButtonState();
          this.isNew = false;
          this.addOrEdit = 'Edit';

          const days =
            this.editedPharmacy && this.editedPharmacy.hoursOfOperation
              ? this.editedPharmacy.hoursOfOperation.hoursOfOperationDays
              : [];
          this.hoursOfOperation = formatHoursOfOperation(days);
        });
      } else {
        this.clinicsService.getClinics().subscribe((res) => {
          this.clinic = res[0];
          if (this.clinic.address) {
            if (this.countriesOfTheWorld.includes(this.clinic.address.country)) {
              this.selectedPharmacy.address.country =
                this.countriesOfTheWorld[this.countriesOfTheWorld.indexOf(this.clinic.address.country)];
              this.editedPharmacy.address.country =
                this.countriesOfTheWorld[this.countriesOfTheWorld.indexOf(this.clinic.address.country)];
            }
            this.updateProvincesStates();
            if (this.provincesAndStates.includes(this.clinic.address.province)) {
              this.selectedPharmacy.address.province =
                this.provincesAndStates[this.provincesAndStates.indexOf(this.clinic.address.province)];
              this.editedPharmacy.address.province =
                this.provincesAndStates[this.provincesAndStates.indexOf(this.clinic.address.province)];
            }
          }
        });
      }
    });
  }

  onChangeCountry() {
    this.updateProvincesStates();
    this.addressPostalCode.setValue('');
  }

  updateProvincesStates() {
    this.provincesAndStates = this.geographyService.updateProvinceStateList(this.editedPharmacy.address.country);
    if (this.editedPharmacy.address.country.toLowerCase() === 'canada') {
      this.addressPostalCode.validator = this.validationService.validatePostalCode;
    } else if (this.editedPharmacy.address.country.toLowerCase() === 'united states') {
      this.addressPostalCode.validator = this.validationService.validateZipCode;
    } else {
      this.addressPostalCode.validator = null;
    }
  }

  updateSubmitButtonState() {
    if (
      (this.email.value !== '' && this.email.hasError('email')) ||
      this.phoneNumber1.hasError('phoneError') ||
      this.phoneNumber2.hasError('phoneError') ||
      this.phoneNumber3.hasError('phoneError') ||
      this.faxNumber.hasError('phoneError') ||
      this.website.hasError('websiteError') ||
      this.addressPostalCode.hasError('postalCodeError') ||
      this.addressPostalCode.hasError('zipCodeError') ||
      isNullOrUndefined(this.editedPharmacy.name) ||
      this.editedPharmacy.name.length === 0
    ) {
      this.submitButtonDisabledState = true;
    } else {
      this.submitButtonDisabledState = false;
    }
  }

  setHoursOfOperation(): void {
    if (!isValidId(this.editedPharmacy.pharmacyId)) {
      // We need to save the doctor first so that we can reference their id when creating the hours of operation
      this.pharmaciesService.addPharmacy(this.editedPharmacy).subscribe((pharmacy: Pharmacy) => {
        this.editedPharmacy = pharmacy;
        this.openHoursOfOperationDialog();
        this.isNew = false;
      });
    } else {
      this.openHoursOfOperationDialog();
    }
  }

  openHoursOfOperationDialog(): void {
    let hoursObj = this.editedPharmacy.hoursOfOperation;
    if (!hoursObj) {
      hoursObj = { hoursOfOperationId: 0, hoursOfOperationDays: [] };
    }
    const dialogRef = this.hoursOfOperationDialog.open(HoursOfOperationDialogComponent, {
      width: '700px',
      height: '400px',
      data: {
        companyId: this.editedPharmacy.pharmacyId,
        buzzHours: hoursObj,
        hoursType: HoursOfOperationType.Other,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (!isNullOrUndefined(result)) {
          this.editedPharmacy.hoursOfOperation = result;
          this.updateSubmitButtonState();
          this.hoursOfOperation = formatHoursOfOperation(result.hoursOfOperationDays);
        }
      });
  }

  updatePharmacy() {
    if (this.isNew) {
      this.pharmaciesService.addPharmacy(this.editedPharmacy).subscribe(() => {
        this.catalogueUpdatesService.refreshRequired = true;
        this.catalogueUpdatesService.catalogueUpdateComplete();
        this.masterOverlayService.masterOverlayState(false);
        this.router.navigate(['/management/address-book/pharmacies', { outlets: { 'action-panel': null } }]);
      });
    } else {
      this.pharmaciesService.updatePharmacy(this.editedPharmacy).subscribe(() => {
        this.catalogueUpdatesService.refreshRequired = true;
        this.catalogueUpdatesService.catalogueUpdateComplete();
        this.masterOverlayService.masterOverlayState(false);
        this.router.navigate(['/management/address-book/pharmacies', { outlets: { 'action-panel': null } }]);
      });
    }
  }

  cancelUpdate() {
    this.masterOverlayService.masterOverlayState(false);
    this.catalogueUpdatesService.refreshRequired = false;
    this.catalogueUpdatesService.catalogueUpdateComplete();
    this.router.navigate(['/management/address-book/pharmacies', { outlets: { 'action-panel': null } }]);
  }

  initPharmacy(pharmacy: Pharmacy, address: Address) {
    address = {
      address1: '',
      address2: '',
      city: '',
      country: '',
      postalCode: '',
      province: '',
    };
    pharmacy = {
      pharmacyId: 0,
      name: '',
      address: address,
      phoneNumber1: '',
      phoneNumber2: '',
      phoneNumber3: '',
      faxNumber: '',
      email: '',
      website: '',
      hoursOfOperation: null,
    };
    return pharmacy;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
