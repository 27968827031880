<div class="row section-photo-toolbar navy-background photo-toolbar align-items-center py-2 w-100 m-0">
  <ngx-loading [show]="loading"></ngx-loading>
  <div class="col-12 d-flex text-white align-items-center justify-content-end">
    <span class="mr-auto ml-2">Select two photos for a before & after or select three or more to create a series</span>
    <div class="mr-2" *ngIf="seriesPhotos.length <= 2">Orientation:</div>
    <div class="d-flex orientationSelection mr-4" *ngIf="seriesPhotos.length <= 2">
      <mat-select [(value)]="isUpDown" (selectionChange)="selectOrientation()">
        <mat-option [value]="false">Left/Right</mat-option>
        <mat-option [value]="true">Up/Down</mat-option>
      </mat-select>
    </div>

    <span>{{ seriesPhotos ? seriesPhotos.length : 0 }} Photo(s) selected</span>
    <button
      type="button"
      kendoTooltip
      tooltipClass="em-tooltip"
      position="top"
      (click)="saveSeriesCreation()"
      [title]="'Save Series'"
      [disabled]="seriesPhotos?.length < 2"
      class="btn em-btn em-btn-green ml-2"
    >
      <a type="button" style="color: white">
        <i class="fal fa-save"></i>
      </a>
    </button>
    <button
      type="button"
      kendoTooltip
      tooltipClass="em-tooltip"
      position="top"
      (click)="cancelSeriesCreation()"
      [title]="'Cancel creating before/after image'"
      class="btn em-btn em-btn-green ml-2"
    >
      <a type="button" style="color: white">
        <i class="fal fa-times"></i>
      </a>
    </button>
  </div>
</div>

<div
  [ngClass]="{
    'float-left container-size-leftRight': isUpDown == false,
    'container-size-upDown mx-auto': isUpDown == true,
    'invisible h-0': seriesPhotos.length > 2
  }"
>
  <ngx-loading [show]="loadingImage1"></ngx-loading>
  <img class="imgCropper" #editor1 src="/assets/profile/noprofile.jpg" crossorigin />
</div>

<div
  [ngClass]="{
    'float-right container-size-leftRight': isUpDown == false,
    'container-size-upDown mx-auto': isUpDown == true,
    'invisible h-0': seriesPhotos.length > 2
  }"
>
  <ngx-loading [show]="loadingImage2"></ngx-loading>
  <img class="imgCropper" #editor2 src="/assets/profile/noprofile.jpg" crossorigin />
</div>

<div
  *ngIf="seriesPhotos.length > 2"
  class="d-flex align-items-center w-100"
  style="height: 100%; height: -webkit-fill-available"
>
  <app-generic-photo-display
    *ngFor="let photo of seriesPhotos"
    class="w-100"
    [photo]="photo"
    [disabledRotation]="true"
  ></app-generic-photo-display>
</div>
<!-- <div class="h-25 d-flex align-items-center justify-items-center">
  <img class="img-preview ml-auto" [src]="imageDest1">
  <img class="img-preview mr-auto" [src]="imageDest2">
</div> -->
