import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';

function instanceOfClinicServiceTempalte(service: any): service is ClinicServiceTemplate {
    return !!service && typeof service !== 'string' 
        && 'id' in service 
        && 'serviceAltName' in service
        && 'serviceDetailTemplateId' in service;
}

export const ServiceSelectionRequiredValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null =>
		!instanceOfClinicServiceTempalte(control?.value) ? { matchRequired: true } : null;



