import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { AuthService } from './auth.service';
import { IPolicy } from './auth-policies';

@Directive({
  selector: '[appAuthGuard]'
})
export class AuthGuardDirective {
  private _hasView = false;
  // the user should pass in an object to determine the authorization check
  @Input()
  set appAuthGuard(policy: IPolicy) {
    const userHasPermission = this.authService.userSatisfiesPolicy(policy);
    // Check if the current user has the required permissions (copied from if check in the authguard)
    if (
      !userHasPermission && this._hasView
    ) {
      // not authorized so don't render anything
      this.viewContainer.clear();
      this._hasView = false;
    } else if (userHasPermission && !this._hasView) {
      // Render the host view
      this.viewContainer.createEmbeddedView(this.templateRef);
      this._hasView = true;
    }

  }

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authService: AuthService) {}
}
