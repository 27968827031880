import { PhotoMetaData } from "./photo-meta-data";

export class PhotoData {
	base64Data: string;
	metaData: PhotoMetaData;

	constructor(data: string, meta: PhotoMetaData) {
		this.base64Data = data;
		this.metaData = meta;
	}
}