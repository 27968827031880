import { PatientSocialHistoryEntry } from '@models/patient';
import { ActionPanelService } from '@services/actionpanel.service';
import { UsersService } from '@services/users.service';
import { Component, OnInit, ViewChild, NgZone, Input, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PrescriptionService } from '@services/prescriptions/prescription.service';
import { ExternalPrescriptionService } from '@services/prescriptions/external-prescription.service';
import { Prescription } from '@models/prescriptions/prescription';
import { ExternalPrescription } from '@models/prescriptions/external-prescription';
import { PatientService } from '@services/patient.service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-social-history',
  templateUrl: './social-history.component.html',
  styleUrls: ['./social-history.component.less'],
})
export class SocialHistoryComponent implements OnInit {
  public prescriptionList: Prescription[];
  socialHistoryEntry: PatientSocialHistoryEntry;
  public externalPrescriptionEntry: ExternalPrescription;
  @Input() panelHeight: number;
  @Input() patientId: number;

  constructor(
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private actionPanelService: ActionPanelService,
    private patientService: PatientService,
    private userService: UsersService,
  ) {}

  ngOnInit() {
    if (!this.patientId) {
      this.patientId = +this.route.snapshot.params.patId.split('_')[0];
    }

    this.getPatientHistory();
  }

  ngOnChanges() {
    this.getPatientHistory();
  }

  public getPatientHistory(){
    if (!this.patientId) return;
    
    this.patientService.getSocialHistoryByPatientId(this.patientId).subscribe((res) => {
      if (res) {
        this.socialHistoryEntry = res;
      } else {
        this.socialHistoryEntry = {
          id: 0,
          entryDate: new Date(),
          enteredByUserId: this.userService.loggedInUser.id,
          entryText: '',
          patientId: this.patientId,
        };
      }
    });
  }

  public saveSocialHistoryElement(event: string) {
    this.socialHistoryEntry.entryText = event;
    if (this.socialHistoryEntry.id) {
      this.patientService.updateSocialHistory(this.socialHistoryEntry).subscribe();
    } else {
      this.patientService.createSocialHistory(this.socialHistoryEntry).subscribe();
    }
  }
}
