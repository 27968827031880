import { Component, OnInit, OnDestroy } from '@angular/core';
import { TreatmentPlan } from '@models/treatment-planning/treatment-plan';
import { Patient } from '@models/patient';
import { PatientService } from '@services/patient.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TreatmentQuestion } from '@models/treatment-planning/treatment-questions/treatment-question';
import { TreatmentPlanQuestionService } from '@services/treatment-planning/treatment-plan-question.service';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { QuestionType } from '@models/treatment-planning/treatment-questions/question-type';
import { TreatmentAnswer } from '@models/treatment-planning/treatment-questions/treatment-answer';
import { TreatmentPlanFormService } from '@services/treatment-planning/treatment-plan-form.service';
import { PatientTreatmentAnswer } from '@models/treatment-planning/treatment-questions/patient-treatment-answer';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { isNullOrUndefined } from '@app/shared/helpers';

@Component({
  selector: 'app-treatment-questions',
  templateUrl: './treatment-questions.component.html',
  styleUrls: ['./treatment-questions.component.less'],
})
export class TreatmentQuestionsComponent implements OnInit, OnDestroy {
  patient: Patient;
  treatmentPlan: TreatmentPlan;
  treatmentQuestions: TreatmentQuestion[];
  patientTreatmentAnswers: Map<number, PatientTreatmentAnswer>;
  patientTreatmentAnswersMap: Map<number, Map<number, PatientTreatmentAnswer>>;
  questionType = QuestionType;
  treatmentQuestionsForm: FormGroup;
  loading: boolean;
  expanded: boolean;
  reviewed: boolean;
  unsub = new Subject<any>();

  constructor(
    private patientService: PatientService,
    private treatmentPlanQuestionService: TreatmentPlanQuestionService,
    private fb: FormBuilder,
    public treatmentPlanFormService: TreatmentPlanFormService,
    private treatmentPlanService: TreatmentPlanService
  ) {
    this.patient = this.patientService.patientPanelPatient;
    this.loading = true;
    this.patientTreatmentAnswers = new Map();
    this.patientTreatmentAnswersMap = new Map();
  }

  ngOnInit() {
    this.loading = true;
    this.patientService.thePatientUpdated$.pipe(takeUntil(this.unsub)).subscribe((patient) => {
      this.patient = patient;
    });

    this.treatmentPlanService.getTreatmentPlanByPatientId(this.patient.patientId).subscribe((tp: TreatmentPlan) => {
      this.treatmentPlan = tp;

      if (!isNullOrUndefined(this.treatmentPlan)) {
        this.expanded = !this.treatmentPlan.treatmentQuestionsReviewed;
        this.reviewed = this.treatmentPlan.treatmentQuestionsReviewed;
      }
    });

    this.treatmentPlanQuestionService
      .getTreatmentPlanQuestionsByPatientId(this.patient.patientId)
      .subscribe((tpq: TreatmentQuestion[]) => {
        this.initQuestions(tpq);
        this.loading = false;
      });

    this.treatmentPlanService.treatmentPlanUpdated$.pipe(takeUntil(this.unsub)).subscribe((tp) => {
      this.treatmentPlan = tp;
      this.loading = false;
    });

    this.treatmentPlanQuestionService.treatmentPlanQuestionsUpdated$.pipe(takeUntil(this.unsub)).subscribe((tpq) => {
      this.initQuestions(tpq);
      this.loading = false;
    });
  }

  initQuestions(treatmentQuestions: TreatmentQuestion[]) {
    this.loading = true;
    this.treatmentQuestions = treatmentQuestions;

    // Init map
    for (let i = 0; i < this.treatmentQuestions.length; i++) {
      const tq = this.treatmentQuestions[i];
      tq.treatmentAnswers.forEach((ta) => {
        // If there is a patient answer for the current question populate it within our map
        if (isNullOrUndefined(this.patientTreatmentAnswersMap.get(i))) {
          this.patientTreatmentAnswersMap.set(i, new Map());
        }
        if (ta.patientTreatmentAnswers.length > 0) {
          // There will only be one patientTreatmentAnswer per treatmentAnswer because we looked up the questions by patient id
          this.patientTreatmentAnswers.set(ta.id, ta.patientTreatmentAnswers[0]);

          this.patientTreatmentAnswersMap.get(i).set(ta.id, ta.patientTreatmentAnswers[0]);
        }
      });
    }

    this.initForm();
  }

  initForm() {
    const questions = this.fb.array([]);
    // PatientTreatmentAnswer for every TreatmentQuestion
    this.treatmentQuestions.forEach((tq) => {
      questions.push(
        this.fb.group({
          id: tq.id,
          value: tq.value,
          questionType: tq.questionType,
          answer: this.fb.group({
            answerId: [],
            answerText: [],
          }),
        })
      );
    });

    this.treatmentQuestionsForm = this.fb.group({
      questions: questions,
    });

    this.loading = false;
  }

  onGetQuestionControls() {
    return (<FormArray>this.treatmentQuestionsForm.get('questions')).controls;
  }

  onGetTreatmentAnswers(index: number): TreatmentAnswer[] {
    return this.treatmentQuestions[index].treatmentAnswers;
  }

  onGetDisplayOtherField(fieldName: string): boolean {
    return fieldName.endsWith(':');
  }

  onToggleExpand() {
    this.expanded = !this.expanded;
  }

  onToggleReviewed() {
    this.reviewed = !this.reviewed;
    this.treatmentPlan.treatmentQuestionsReviewed = this.reviewed;
    this.treatmentPlanFormService.updatePatientTreatmentAnswersReviewed(this.reviewed);
  }

  async onInputChange(questionIndex: number, treatmentAnswerId: number, event: Event) {
    const answerText = (event?.target as HTMLInputElement).value;
    const checked = (event?.target as HTMLInputElement).checked;
    const question = this.treatmentQuestions[questionIndex];
    const patientTreatmentAnswersToDelete: number[] = [];

    let patientAnswer = this.patientTreatmentAnswersMap.get(questionIndex).get(treatmentAnswerId);
    if (question.questionType === this.questionType.Radio) {
      // There can only be one answer
      this.onClearOldAnswer(questionIndex, treatmentAnswerId, question.questionType, patientTreatmentAnswersToDelete);
      this.onNewPatientTreatmentAnswerEntry(questionIndex, treatmentAnswerId, answerText);
    } else if (question.questionType === this.questionType.RadioFreeform) {
      if (this.patientTreatmentAnswersMap.get(questionIndex).get(treatmentAnswerId)) {
        // Patient answer already exists, just update the "other" value

        patientAnswer.treatmentAnswerId = treatmentAnswerId;
        patientAnswer.notes = answerText;
        this.patientTreatmentAnswersMap.get(questionIndex).set(treatmentAnswerId, patientAnswer);
      } else {
        // New patient answer
        // There can only be one answer
        this.onClearOldAnswer(questionIndex, treatmentAnswerId, question.questionType, patientTreatmentAnswersToDelete);
        this.onNewPatientTreatmentAnswerEntry(questionIndex, treatmentAnswerId, answerText);
      }
    } else if (question.questionType === this.questionType.Freeform) {
      if (this.patientTreatmentAnswersMap.get(questionIndex).get(treatmentAnswerId)) {
        // Patient answer already exists, just update the values
        patientAnswer.treatmentAnswerId = treatmentAnswerId;
        patientAnswer.notes = answerText;
        this.patientTreatmentAnswersMap.get(questionIndex).set(treatmentAnswerId, patientAnswer);
      } else {
        // New patient answer
        this.onNewPatientTreatmentAnswerEntry(questionIndex, treatmentAnswerId, answerText);
      }
    } else if (
      question.questionType === this.questionType.Checkbox ||
      question.questionType === this.questionType.CheckboxFreeform
    ) {
      if (!checked) {
        // Need to remove from map
        this.onClearOldAnswer(questionIndex, treatmentAnswerId, question.questionType, patientTreatmentAnswersToDelete);
      } else {
        if (this.patientTreatmentAnswersMap.get(questionIndex).get(treatmentAnswerId)) {
          // Patient answer already exists, just update the values
          patientAnswer.treatmentAnswerId = treatmentAnswerId;
          patientAnswer.notes = answerText;
          this.patientTreatmentAnswersMap.get(questionIndex).set(treatmentAnswerId, patientAnswer);
        } else {
          this.onNewPatientTreatmentAnswerEntry(questionIndex, treatmentAnswerId, answerText);
        }
      }
    }
    patientAnswer = this.patientTreatmentAnswersMap.get(questionIndex).get(treatmentAnswerId);

    // if (patientTreatmentAnswersToDelete && patientTreatmentAnswersToDelete.length > 0) await this.treatmentPlanQuestionService.deletePatientAnswers( patientTreatmentAnswersToDelete).toPromise();
    // if (patientAnswer) await this.treatmentPlanQuestionService.putPatientAnswers(patientAnswer,  this.treatmentPlan.id).toPromise();

    // // Create an array of answers
    if (patientTreatmentAnswersToDelete && patientTreatmentAnswersToDelete.length > 0)
      await this.treatmentPlanQuestionService.deletePatientAnswers(patientTreatmentAnswersToDelete).toPromise();
    const patientAnswers: PatientTreatmentAnswer[] = [];
    this.patientTreatmentAnswersMap.forEach((value: Map<number, PatientTreatmentAnswer>) => {
      value.forEach(async (pta: PatientTreatmentAnswer) => {
        patientAnswers.push(pta);
      });
    });
    if (patientAnswer) {
      let newAnswer = await this.treatmentPlanQuestionService
        .putPatientAnswers(patientAnswer, this.treatmentPlan.id)
        .toPromise();
      patientAnswer.id = newAnswer.id;
      this.patientTreatmentAnswersMap.get(questionIndex).set(treatmentAnswerId, patientAnswer);
    }
    // this.treatmentPlanFormService.updatePatientTreatmentAnswers(patientAnswers, patientTreatmentAnswersToDelete);
  }

  onNewPatientTreatmentAnswerEntry(questionIndex: number, treatmentAnswerId: number, answerText: string) {
    this.patientTreatmentAnswersMap.get(questionIndex).set(
      treatmentAnswerId,
      new PatientTreatmentAnswer({
        id: 0,
        patientId: this.patient.patientId,
        treatmentAnswerId: treatmentAnswerId,
        treatmentPlanId: this.treatmentPlan.id,
        notes: answerText,
      })
    );
  }

  onClearOldAnswer(questionIndex: number, treatmentAnswerId: number, questionType: QuestionType, toDelete: number[]) {
    const oldPta = Array.from(this.patientTreatmentAnswersMap.get(questionIndex).values())[0];
    if (!isNullOrUndefined(oldPta)) {
      toDelete.push(oldPta.id);
      if (questionType === this.questionType.Radio || questionType === this.questionType.RadioFreeform) {
        this.patientTreatmentAnswersMap.get(questionIndex).clear();
      } else if (questionType === this.questionType.Checkbox || questionType === this.questionType.CheckboxFreeform) {
        this.patientTreatmentAnswersMap.get(questionIndex).delete(treatmentAnswerId);
      }
    }
  }

  onGetAnswered(questionIndex: number, treatmentAnswerId: number): boolean {
    return !isNullOrUndefined(this.patientTreatmentAnswersMap.get(questionIndex).get(treatmentAnswerId));
  }

  onGetAnswerValue(questionIndex: number, treatmentAnswerId: number): string {
    if (!isNullOrUndefined(this.patientTreatmentAnswersMap.get(questionIndex).get(treatmentAnswerId))) {
      return this.patientTreatmentAnswersMap.get(questionIndex).get(treatmentAnswerId).notes;
    }
    return null;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
