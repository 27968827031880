<ngx-loading [show]="loading"></ngx-loading>
<kendo-grid
  class="emr-kendo-grid hide-default-sort h-100"
  [data]="gridView"
  [style.maxHeight.%]="100"
  [pageSize]="gridState.take"
  [pageable]="true"
  [skip]="gridState.skip"
  [sort]="gridState.sort"
  [sortable]="{
    allowUnsort: false,
    mode: 'single'
  }"
  [rowClass]="isReturnInvoice"
  filterable="menu"
  (excelExport)="onExcelExport($event)"
  (dataStateChange)="dataStateChange($event)"
  #grid="kendoGrid"
>
  <kendo-grid-column field="patientName" title="Patient Name" [filterable]="false">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <button
        type="button"
        class="btn-clean px-0 text-left underline"
        (click)="navigateToPatientAccountTab(dataItem.patientId)"
      >
        {{ dataItem.patientName }}
      </button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="invoiceDate" title="Invoice Date" [filterable]="false">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{ dataItem.invoiceDate | date : 'yyyy-MM-dd h:mma' }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="invoiceId" title="Invoice #" [filterable]="false">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <button
        type="button"
        class="btn-clean px-0 text-left underline"
        (click)="navigateToPatientInvoice(dataItem.patientId, dataItem.invoiceId)"
      >
        {{ dataItem.invoiceId }}
      </button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="subtotal" title="Subtotal" format="{0:c}" [filterable]="false">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="tax" title="Tax" format="{0:c}" [filterable]="false">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="invoiceTotal" title="Invoice Total" format="{0:c}" [filterable]="false">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="isPaid" title="Is Paid" [headerStyle]="{ 'text-align': 'center' }" filter="boolean">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div style="text-align: center; width: 100%">
        <label class="switch align-text-top mb-0 ml-2">
          <input
            #statusActiveToggle
            type="checkbox"
            name="statusPaid"
            [checked]="dataItem.isPaid === true ? 'checked' : ''"
            disabled
          />
          <span class="slider round"></span>
        </label>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-excel fileName="invoices_report.xlsx" [fetchData]="allData"></kendo-grid-excel>
</kendo-grid>
