import { Component, OnInit, Input, Output, EventEmitter, ViewChild, NgZone, Optional, Inject, AfterViewInit } from '@angular/core';
import { User } from '@models/user';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NudgePriority, NudgePriorityTitle } from '@models/nudges/priority';
import { NudgeTypes, NudgeTypesTitle } from '@models/nudges/type';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { take, switchMap, map } from 'rxjs/operators';
import { NudgeService } from '@services/nudge.service';
import { UsersService } from '@services/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NudgeStatusesTitle, NudgeStatuses } from '@models/nudges/status';
import * as moment from 'moment';
import { PatientDocumentService } from '@services/patient-documents.service';
import { DocumentDetailsComponent } from '../../patients-documents-tab/document-details/document-details.component';
import { DocumentPreviewComponent } from '../document-preview/document-preview.component';
import { NudgeReferenceType } from '@models/nudges/reference-type';
import { Nudge } from '@models/nudges/nudge';
import { NudgesSignalrService } from '@app/nudges/services/nudges-signalr.service';

@Component({
  selector: 'app-create-nudges',
  templateUrl: './create-nudges.component.html',
  styleUrls: ['./create-nudges.component.less']
})
export class CreateNudgesComponent implements OnInit, AfterViewInit {
  @Input() users: User[];
  @Output() saveNudge = new EventEmitter();
  @Output() cancelPanel = new EventEmitter();
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  nudgeForm: FormGroup;
  private referenceId: string;
  private referenceType: NudgeReferenceType;
  private referenceLink: string;
  public types: any;
  public priority: any;
  public status: any;
  public Status = NudgeStatuses;
  public Importance = NudgePriority;
  public nudgeHistory = [];
  disableAnimation = true;
  loading: boolean;

  constructor(
    @Optional() public dialogRef: MatDialogRef<CreateNudgesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private _ngZone: NgZone,
    private nudgeService: NudgeService,
    private userService: UsersService,
    private router: Router,
    private dialog: MatDialog,
    private documentService: PatientDocumentService,
    private nudgeSignalr: NudgesSignalrService
    ) {}

  ngOnInit() {
    this.referenceId = this.data.referenceId;
    this.referenceType = this.data.referenceType;

    this.userService.getUsers()
      .subscribe((users: User[]) => {
        this.users = users;
      });
    this.types = Object.keys(NudgeTypesTitle).map(
      e => ({title: NudgeTypesTitle[e], value: NudgeTypes[e]})
    );
    this.priority = Object.keys(NudgePriorityTitle).map(
      e => ({title: NudgePriorityTitle[e], value: NudgePriority[e]})
    );
    this.status = Object.keys(NudgeStatusesTitle).map(
      e => ({title: NudgeStatusesTitle[e], value: NudgeStatuses[e]})
    );
    this.initForm();
  }

  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => this.disableAnimation = false);
  }

  initForm() {
    if (this.data.nudgeId) {
      this.nudgeForm = this.formBuilder.group({
        nudgeType: [{value: this.data.nudgeType, disabled: this.checkStatus()}, Validators.required],
        importance: [{value: this.data.importance, disabled: this.checkStatus()}, Validators.required],
        deferredUntil: [{value: new Date(this.data.deferredUntil), disabled: this.checkStatus()}, Validators.required],
        assignedTo: [{value: this.data.assignedTo.id, disabled: this.checkStatus()}, Validators.required],
        newSubject: [{value: '', disabled: this.checkStatus()}],
        status: [{value: this.data.status, disabled: this.checkStatus()}],
        deleted: [this.data.deleted || false],
        completed: [{value: this.data.status === this.Status.Completed, disabled: this.checkStatus()}],
      });
    } else {
      this.nudgeForm = this.formBuilder.group({
        patientId: this.data.patientId,
        nudgeType: ['', Validators.required],
        importance: [this.Importance.Normal, Validators.required],
        deferredUntil: [new Date(), Validators.required],
        assignedTo: ['', Validators.required],
        newSubject: [''],
        referenceLink: this.getReferenceLink()
      });
    }
  }

  public async onNudgeFormSubmit() {
    this.loading = true;
    if (this.data.nudgeId) {
      await this.updateNudge().toPromise();
    } else {
      await this.createNudge().toPromise();
    }
    this.loading = false;
  }

  private createNudge() {
    const nudge: Nudge = Object.assign({}, this.nudgeForm.value);

    nudge.referenceType = this.referenceType;

    if (this.referenceType !== NudgeReferenceType.Patient) {
      nudge.referenceId = +this.referenceId;
      nudge.referenceLink = this.getReferenceLink();
    }

    return this.nudgeService.createNudge(nudge)
      .pipe(map(
        res => {
          this.closeModal('success');
          this.nudgeService.updateNudgeCount();
          return res;
        }
      ));
  }

  private updateNudge() {
    return this.nudgeService.updateNudge(this.nudgeForm.getRawValue(), this.data.nudgeId)
      .pipe(map(
        res => {
          this.closeModal('success');
          this.nudgeService.updateNudgeCount();
          return res;
        }
      ));
  }

  public closeModal(message?: string) {
    this.dialogRef.close(message);
  }

  public navigateToPatient(): void {
    this.router.navigate([
      '/schedule',
      { outlets: { 'action-panel': ['patient', this.data.patient.id + '_patientprofiletab'] } }
    ]);
    this.closeModal();
  }

  public navigateToReferenceLink(): void {
    if (this.data.referenceType === NudgeReferenceType.PatientDocument) {
      this.documentService.getDocument(this.data.referenceId)
      .subscribe(
        (res) => {
          this.dialog.open(DocumentPreviewComponent, {
            panelClass: 'document-preview-modal',
            data: {
              document: res,
              patientId: this.data.patient.id
            }
          });
        }
      );
    }

    if (this.data.referenceType === NudgeReferenceType.PatientClinicDocument) {
      this.documentService.getPatientClinicDocument(this.data.referenceId)
        .subscribe(
          (res) => {
            const dialogRef = this.dialog.open(DocumentPreviewComponent, {
              panelClass: 'document-preview-modal',
              data: {
                document: res,
                patientId: this.data.patient.id
              }
            });
          }
        );
    } else {
      this.router.navigateByUrl(this.data.referenceLink);
      this.closeModal();
    }
  }

  public changeValue(value, control) {
    this.nudgeForm.controls[`${control}`].patchValue(value);
  }

  public cancelNudge() {
    this.nudgeForm.controls['deleted'].patchValue(!this.nudgeForm.controls['deleted'].value);
    this.updateNudge().toPromise();
  }

  public completeNudge() {
    this.nudgeForm.controls['completed'].patchValue(!this.nudgeForm.controls['completed'].value);
    this.updateNudge().toPromise();
  }

  public checkStatus() {
    return this.data.status === this.Status.Completed || this.data.status === this.Status.Deleted;
  }

  public getHistory() {
    this.loading = true;
    if (this.nudgeHistory.length) {
      return;
    } else {
      this.nudgeService.getNudgeHistory(this.data.nudgeId)
      .subscribe(
        res => {
          this.loading = false;
          this.nudgeHistory = res;
        }
      );
    }
  }



  private getReferenceLink() {
    if (this.referenceType === NudgeReferenceType.Patient) {
      return null;
    }
    if (this.referenceType === NudgeReferenceType.Service) {
      return this.router.url + `?toServiceId=${this.referenceId}`;
    } else if (this.referenceType === NudgeReferenceType.PatientDocument) {
      if (this.router.url.indexOf('documentId') >= 0) {
        return this.router.url;
      } else {
        return this.router.url.slice(0, this.router.url.length - 1) + `;documentId=${this.referenceId}` + this.router.url.slice(this.router.url.length - 1);
      }
    } else if (this.referenceType === NudgeReferenceType.PatientClinicDocument) {
      if (this.router.url.indexOf('clinicDocumentId') >= 0) {
        return this.router.url;
      } else {
        return this.router.url.slice(0, this.router.url.length - 1) + `;clinicDocumentId=${this.referenceId}` + this.router.url.slice(this.router.url.length - 1);
      }
    } else {
      return this.router.url;
    }
  }

  triggerResize() {
    this._ngZone.onStable
      .pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }
}
