import { Patient } from '../patient';
import { IChargeableAsset } from '@interfaces/IChargeableAsset';
import { ChargeableItem } from '../billing/chargeable-item';
import { ObservationService } from '@services/observation.service';
import { ObservationChargeableAsset } from '../billing/observation-chargeable-asset';
import { ClinicSupplyType } from '@models/clinic-supply-type';
import { SupplyTag, Tag } from '@models/tag/tag';

export class Observation implements IChargeableAsset {
  id: number;
  typeId: number; // General Demographics, Filler, Injectable...
  type?: ObservationType; // populated only from DB lookup
  name: string;
  value?: string; // may be from a float or number or text or many things, so we just call it a string
  unitId: number;
  unit?: ObservationUnit; // populated only from DB lookup
  details: any; // json object of observation details, the details will vary depending on the observation type
  patientId: number;
  patient?: Patient;
  serviceId: number;
  dateCreated: Date;
  dateUpdated: Date;

  obrService: ObservationService;

  constructor(init?: Partial<Observation>) {
    Object.assign(this, init);

    this.obrService = new ObservationService(null); //??? is this just for the detailsToJSON usage below? this is unnecessary if so
    this.obrService.setObrDetailsToJson(this);
  }

  public getBaseAndChildrenChargeableItems(): ChargeableItem[] {
    // Observations do not have child chargeable items so there is no difference
    // between getBaseAndChildrenChargeableItems and getChargeableItems
    return this.getChargeableItemsCommon();
  }

  public getChargeableItems(): ChargeableItem[] {
    // Observations do not have child chargeable items so there is no difference
    // between getBaseAndChildrenChargeableItems and getChargeableItems
    return this.getChargeableItemsCommon();
  }

  public getChargeableItemsCommon(): ChargeableItem[] {
    const chargeableItems: ChargeableItem[] = [];

    // If the TotalPrice has been overriden, then we can just return that as the Chargeable Item with quantity of 1
    if (this.details.isOverrideTotalPrice) {
      const defaultPrice = this.details.productPrice * parseFloat(this.value);
      chargeableItems.push(
        new ChargeableItem({ defaultPrice: defaultPrice, overridePrice: this.details.overrideTotalPrice, quantity: 1 })
      );
    } else if (this.details.productPrice || this.details.overrideProductPrice) {
      chargeableItems.push(
        new ChargeableItem({
          defaultPrice: this.details.productPrice,
          overridePrice: this.details.overrideProductPrice,
          quantity: parseFloat(this.value),
        })
      );
    }

    return chargeableItems;
  }

  public getChargeAmount() {
    if (this.details && this.details.avaliable == 0) return 0;
    const obrAsset = new ObservationChargeableAsset(this);
    return obrAsset.getChargeAmount();
  }
}

export interface ObservationType {
  id: number;
  name: string;
}

export enum ObservationTypes {
  Injectables = 'Injectables',
  Coolsculpting = 'Coolsculpting',
  Demogrations = 'Demogrations',
  CoolTone = 'CoolTone',
}

export interface ObservationUnit {
  id: number;
  name: string;
  observationType: ObservationType;
}

export class ObservationListType {
  id: number;
  name: string;
  observationType: ObservationType;
  observationTypeId?: number;

  constructor() {}
}

export class ObservationListItem {
  id: number;
  name: string;
  displayName: string;
  clinicSupplyTypeId: number;
  clinicSupplyType?: ClinicSupplyType;
  sequenceNumber: number;
  iconURL: string;
  pricePerUnit: number;
  costPerUnit: number;
  unitsOnHand: number;
  colour?: string;
  isActive: boolean;
  supplyTag?: SupplyTag;

  constructor(init?: Partial<ObservationListItem>) {
    Object.assign(this, init);

    //this.name = this.name.bind(this);
  }

  /* public name(): string {
    if (!isNullOrUndefined(this.displayName) && this.displayName.length > 0) {
      return this.displayName;
    }
    return this.clinicSupplyType.name;
  } */
}

export class ObservationListStatic {
  id: number;
  name: string;
  abbreviation: string;
  observationListTypeId: number;
  observationListType: ObservationListType;
  sequenceNumber: number;
  iconURL: string;
  colour?: string;

  constructor(init?: Partial<ObservationListStatic>) {
    Object.assign(this, init);
  }
}
