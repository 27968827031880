export enum TaxIndicator {
  NoTax = 0,
  TaxIncluded = 1,
  TaxExempt = 2,
}

export enum TaxIndicatorTitle {
  NoTax = 'No Tax',
  TaxIncluded = 'Taxable',
  TaxExempt = 'Tax Exempt',
}
