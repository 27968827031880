import { AuthModule } from './../../../../auth/auth.module';
import { DocumentPatientNoteComponent } from './document-patient-note/document-patient-note.component';
import { DocumentFilterListComponent } from './document-filter-list/document-filter-list.component';
import { NgModule } from '@angular/core';
import { ChartOverviewComponent } from './chart-overview.component';
import { SharedModule } from '../../../../shared/shared.module';
import { PatientTransactionsModule } from '../../../../components/patient-transactions/patient-transactions.module';
import { BillingModule } from '../../../../components/billing/billing.module';
import { PrescriptionsModule } from '../prescriptions/prescriptions.module';
import { PatientAppointmentsListComponent } from './patient-appointments-list/patient-appointments-list.component';
import { ServiceChartsComponent } from './service-charts/service-charts.component';
import { MinistryBillingComponent } from './ministry-billing/ministry-billing.component';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ServiceChartDrawToolComponent } from './modals/service-chart-draw-tool/service-chart-draw-tool.component';
import { PhotosModule } from '@app/photos/photos.module';
import { eTreatmentFormComponent } from './../etreatment-form/etreatment-form.component';
import { FormsModule } from '@app/management/forms/forms.module';
import { AdvancedTreatmentModule } from '../../patient-treatment-plan-tab/service-detail/advanced-treatment/advanced-treatment.module';
import { ChartNoteEntryComponent } from './service-charts/chart-note-entry/chart-note-entry.component';
import { ChartAppointmentEntryComponent } from './service-charts/chart-appointment-entry/chart-appointment-entry.component';

@NgModule({
    declarations: [
        ChartOverviewComponent,
        DocumentFilterListComponent,
        DocumentPatientNoteComponent,
        PatientAppointmentsListComponent,
        ServiceChartsComponent,
        MinistryBillingComponent,
        ServiceChartDrawToolComponent,
        eTreatmentFormComponent,
        ChartNoteEntryComponent,
        ChartAppointmentEntryComponent,
    ],
    imports: [
        SharedModule,
        NgbTypeaheadModule,
        PatientTransactionsModule,
        BillingModule,
        PrescriptionsModule,
        AuthModule,
        MatCheckboxModule,
        PhotosModule,
        FormsModule,
        AdvancedTreatmentModule,
    ],
    exports: [
        ChartOverviewComponent,
        PatientTransactionsModule,
        PatientAppointmentsListComponent,
        ServiceChartsComponent,
        DocumentFilterListComponent,
        DocumentPatientNoteComponent,
    ]
})
export class ChartOverviewModule {}
