import { environment } from '@environments/environment';

export interface Script {
  name: string;
  src: string;
}

export const ScriptStore: Script[] = [
  { name: 'PayWithConverge', src: environment.payWithConvergeSource },
  { name: 'jQuery', src: environment.jQuerySource },
  { name: 'squareWebPaymentsApi', src: environment.squarePaymentSource },
];
