import { IPolicy } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { environment } from '@environments/environment';
import { HttpClient, HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { Subject } from 'rxjs';

export class SignalrBase<T> {
  protected _hubConnection: HubConnection;
  private connectedToServer = new Subject<void>();
  private disconnectedFromServer = new Subject<string>();
  public disconnectedFromServer$ = this.disconnectedFromServer.asObservable();
  public get connectionState(): HubConnectionState {
    return this._hubConnection.state;
  }

  constructor(
    private endpoint: string,
    private authPolicy: IPolicy,
    private authService: AuthService,
    private httpClient: HttpClient
  ) {
    this.createConnection(endpoint, authService);
    authService.userPermissionsUpdated$.subscribe(() => {
      // do authorization check return if not authorized without starting the connection
      if (authService.isAuthenticated && authService.userSatisfiesPolicy(authPolicy)) {
        // If the connection is already connected we don't need to do anything as the user has permission
        if (this._hubConnection.state === HubConnectionState.Disconnected) {
          // It is disconnected so we can just start the connection
          this.startConnection();
        }
      } else if (this._hubConnection.state === HubConnectionState.Connected) {
        // User doesn't have permission so close connection if it is open
        this._hubConnection.stop().catch(console.error);
      }
    });
  }

  protected registerServerEvent(eventName: string, callback: (data: T) => void): void {
    this.connectedToServer.subscribe(() => {
      this._hubConnection.on(eventName, callback);
    });
  }

  private createConnection(endpoint: string, authService: AuthService) {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(environment.signalRUrl + endpoint, {
        accessTokenFactory: () => authService.loginToken,
        httpClient: this.httpClient,
      })
      .withAutomaticReconnect()
      .configureLogging(environment.signalRLogLevel)
      .build();
  }

  private async startConnection() {
    this._hubConnection.onreconnecting((err) => {
      console.warn(`SignalR - ${this.endpoint} disconnected . Attempting to reconnect...`);
    });

    this._hubConnection.onclose((err) => {
      console.warn(`SignalR - ${this.endpoint} disconnected.`);
    });

    await this._hubConnection
      .start()
      .catch(() => {
        console.error(`SignalR - ${this.endpoint}, Error while establishing connection`);
      })
      .then(() => {
        console.log(`Signalr - ${this.endpoint} Connected.`);
        this.connectedToServer.next();
      });
  }
}
