import { Component, OnInit, Input, Output, EventEmitter, ViewChild, NgZone, SimpleChanges, OnChanges } from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-textarea-widget',
  templateUrl: './textarea-widget.component.html',
  styleUrls: ['./textarea-widget.component.less']
})
export class TextareaWidgetComponent implements OnInit, OnChanges {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() minRows: number;
  @Input() maxRows: number;
  @Input() textValue: string;
  @Input() isDanger: boolean;
  @Output() emitText = new EventEmitter<string>();

  public isEdited: boolean;
  private initialTextValue: string;
  constructor(
    private _ngZone: NgZone
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initialTextValue = changes['textValue']?.currentValue;
    if (changes['textValue'] && !changes['textValue'].firstChange) {
      this.isEdited = false;
    }
   }

  public textValueChange(text: string) {
    this.isEdited = text !== null && text !== this.initialTextValue ? true : false;
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }
}
