<ng-container>
  <div class="row h-100">
    <!-- Left Column -->
    <div class="col-xl-3 col-6 d-flex flex-column px-2 h-100">
      <!-- Treatment Plan -->
      <!-- <div *ngIf="service?.serviceDetailTemplateId !== serviceDetailTemplate.Coolsculpting" class="row pb-3">
        <div class="col px-0 mx-3 panel-item-border w-100">
          <app-treatment-plan-overview
            [showProducts]="false"
            [serviceTemplateId]="service.templateId"
            [showDetailButton]="false"
            [isLocked]="service?.isLocked || associatedPlannedTreatment?.paymentStatus == PaymentStatus.Paid"
            [isTreatmentPlanning]="treatmentPlanning"
            [isAdvancedTreatmentPage]="true"
            [typeOfTab]="typeOfTab"
          >
          </app-treatment-plan-overview>
        </div>
      </div> -->
      <!-- Previous Treatments -->
      <div class="row pb-3 h-50">
        <div class="col px-0 mx-3 panel-item-border w-100 h-100">
          <app-service-list
            [listtype]="serviceListType.history"
            [serviceTemplateId]="service.templateId"
            [patientId]="patientId"
            [isLocked]="service?.isLocked || associatedPlannedTreatment?.paymentStatus == PaymentStatus.Paid"
            [customClasses]="customListClasses"
          >
          </app-service-list>
        </div>
      </div>
      <!-- Favourites -->
      <div class="row h-50">
        <div class="col px-0 mx-3 panel-item-border w-100 h-100">
          <app-service-list
            *ngIf="service?.serviceDetailTemplateId"
            [listtype]="serviceListType.favourites"
            [serviceTemplateId]="service.templateId"
            [serviceDetailTemplateId]="service.serviceDetailTemplateId"
            [serviceIDColour]="service.serviceTemplate.serviceIDColour"
            [patientId]="patientId"
            [isLocked]="service?.isLocked || associatedPlannedTreatment?.paymentStatus == PaymentStatus.Paid"
            [customClasses]="customListClasses"
          >
          </app-service-list>
        </div>
      </div>
    </div>
    <!-- Main Column -->
    <div
      class="col h-100 panel-item-border"
      [attr.data-test-id]="'is-tx-toolbar-hidden'"
      [attr.data-test-value]="associatedPlannedTreatment?.paymentStatus == PaymentStatus.Paid"
    >
      <div
        *ngIf="!service?.isLocked"
        class="row treatment-toolbar"
        [ngStyle]="{ display: associatedPlannedTreatment?.paymentStatus == PaymentStatus.Paid ? 'none' : 'inherit' }"
      >
        <div class="col mb-2">
          <!-- Treatments Toolbar -->
          <app-treatment-toolbar
            *ngIf="ready"
            [associatedPlannedTreatment]="associatedPlannedTreatment"
            [service]="service"
            [treatmentPlanning]="treatmentPlanning"
          ></app-treatment-toolbar>
        </div>
      </div>
      <div class="row" [ngStyle]="{ height: !service?.isLocked ? 'calc(100% - 125px)' : '100%' }">
        <div class="col-6 h-100 pb-2">
          <!-- Image Panel -->
          <app-image [service]="service"></app-image>
        </div>
        <div class="col-6 h-100 pl-0" #detailSection>
          <!-- Treatment Details -->
          <ng-container
            *ngIf="service.serviceDetailTemplateId === serviceDetailTemplate.Coolsculpting && !service?.isLocked"
          >
          </ng-container>
          <app-service-observations
            *ngIf="ready"
            [service]="service"
            [associatedPlannedTreatment]="associatedPlannedTreatment"
          ></app-service-observations>
        </div>
      </div>
    </div>
  </div>
</ng-container>
