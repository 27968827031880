import { ClinicServiceTemplate } from '../service/clinic-service-template';
import { User } from '../user';

export class ClinicServiceTemplateUser {
  clinicServiceTemplateId: number;
  clinicServiceTemplate: ClinicServiceTemplate;
  userId: string;
  user: User;

  public constructor(clinicServiceTemplateId: number, userId: string) {
    this.clinicServiceTemplateId = clinicServiceTemplateId;
    this.userId = userId;
  }
}
