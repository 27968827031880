<div class="edit-container position-absolute d-block z-index-3 white-background">
  <ngx-loading [show]="loading"></ngx-loading>
  <div class="re-links-container">
    <button 
      class="btn em-btn teal-background" 
      (click)="cancel()">
        <i class="far fa-times" aria-hidden="true"></i>
    </button>
  </div>

  <div class="row mx-0 teal-background">
    <div class="col">
      <h6 class="white-font uppercase my-0 py-2">
        Campaign Recipients Preview
      </h6>
    </div>
  </div>

  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0;">
    <div class="result" *ngFor="let r of recipients">
      {{r.name}}
      <br>
      {{r.email}}
      <br>
      {{r.mobileNumber}}
      <br>
      {{r.birthDate | date: 'yyyy-MM-dd' }}
      <hr>
    </div>
    <app-campaign-recipients-grid [details]="recipients"></app-campaign-recipients-grid>
  </perfect-scrollbar>


</div>
