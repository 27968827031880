import { Component, OnDestroy, OnInit } from '@angular/core';
import { EditUserComponent } from '@app/messaging/dialogs/edit-user/edit-user.component';
import { ChatChannel } from '@app/messaging/models/chat-channel.model';
import { ChatChannelsService } from '@app/messaging/services/chat-channels.service';
import { ChatUsersService } from '@app/messaging/services/chat-users.service';
import { isNullOrUndefined } from '@app/shared/helpers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-messaging-sidebar-conversations',
  templateUrl: './messaging-sidebar-conversations.component.html',
  styleUrls: ['./messaging-sidebar-conversations.component.less'],
})
export class MessagingSidebarConversationsComponent implements OnInit, OnDestroy {
  private unsub: Subject<any> = new Subject<any>();
  selectedChannelId: number;

  constructor(
    public channelService: ChatChannelsService,
    private modalService: NgbModal,
    private chatUsersService: ChatUsersService
  ) {}

  ngOnInit() {
    this.channelService.onChannelSelected$.pipe(takeUntil(this.unsub)).subscribe((c) => {
      if (!isNullOrUndefined(c)) {
        this.selectedChannelId = c.id;
      }
    });
  }

  onChannelSelected(channel: ChatChannel) {
    this.channelService.channelSelected(channel);
  }

  onAddPrivateChannel() {
    const modalRef = this.modalService.open(EditUserComponent, {});
    modalRef.closed
      .pipe(
        filter((selectedUsers) => selectedUsers != null),
        mergeMap((selectedUsers) =>
          this.channelService.createPrivateChannels(this.chatUsersService.currentChatUser, selectedUsers)
        )
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
