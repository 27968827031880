import { RetentionCampaignType } from './retention-campaign-types';

export class Campaign {
  id: number;
  status: number;
  completed: boolean;
  title: string;
  campaignType: RetentionCampaignType;
  smsCampaignText: string;
  startOnDate: Date;
  sendTime: moment.Duration;
  endOnDate: Date;
  dayToSend: string;
  isRecurring: boolean;
  repeatPeriod: string;

  sendToAll: boolean;
  timeElapsedSinceVisit: string;
  andTimeElapsedSinceVisit: string;
  visitDateWasBefore: string;
  andVisitDateWasBefore: string;
  lastStaffId: string;
  amountOfVisits: string;
  amountOfLoyaltyPoints: string;
  amountSpent: string;
  gender: string;
  birthdayBefore: Date;
  birthdayAfter: Date;
  minBirthYear: number;
  maxBirthYear: number;

  dontEmailPeriod: number;
  dontEmailPeriodUnit: number;
  emailsSent: string;
  emailJSON: string;
  emailHTML: string;
  emailSubjectLine: any;
  createdFromEmailTemplateId: number;
  futureBirthdayPeriod: any;
  futureBirthdayPeriodUnit: any;

  filterProductServiceStartPeriod: number;
  filterProductServiceEndPeriod: number;
  filterProductServicePeriodUnit: number;

  //Not used
  purchaseDateStartOn: Date;
  purchaseDateEndOn: Date;

  hasPurchasedFilter: boolean;
  serviceIds: string;
  productIds: string;
  obsListIds: string; //clinic supplies associated with either cs or injectables service

  hasNotPurchasedFilter: boolean;
  serviceIdsNegative: string;
  productIdsNegative: string;
  obsListIdsNegative: string;

  notPurchasedSince: boolean;
  notPurchasedSinceNegative: boolean;
  //Not used
  noFutureAppointmentsSince: boolean;
}
