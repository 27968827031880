import { ChatMessage } from './chat-message.model';
import { ChatUserStatus } from './chat-user-status';
import { ChatUser } from './chat-user.model';

export class ChatChannel {
  id: number;
  name: string;
  private: boolean;
  status: ChatUserStatus;
  allowedUsers: ChatUser[] = [];
  newMessages: boolean;
  unreadNotificationsCount = 0;
  hasMessages?: boolean;
}
