import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { Observable } from 'rxjs';
import { BlobService } from './blob.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PhotoshopService {
  constructor(private http: HttpClient, private blobService: BlobService) {}

  applyFilter(filterName: string, photoId: number): Observable<PhotoMetaData> {
    return this.http
      .get<PhotoMetaData>(environment.baseUrl + 'api/Photoshop', { params: { filter: filterName, photoId: photoId } })
      .pipe(
        map((result) => {
          const sas = this.blobService.getReadOnlySAS();
          result.filePath += sas;
          result.filePathOriginal += sas;
          result.filePathThumb += sas;
          return result;
        })
      );
  }
}
