import { MerchantDevice } from './../../../models/finance/merchant-device';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { Clinic } from '@models/clinic';
import { ClinicsService } from '@services/clinics.service';
import { MerchantDeviceService } from '@services/merchant-device.service';
import { Subject, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-org-merchant-devices',
  templateUrl: './org-merchant-devices.component.html',
  styleUrls: ['./org-merchant-devices.component.less'],
})
export class OrgMerchantDevicesComponent implements OnInit, OnDestroy {
  disableGrid = false;
  loading = false;
  gridData: any[];
  unsub = new Subject<any>();
  clinicId: number;
  clinic: Clinic;

  constructor(
    private merchantDeviceService: MerchantDeviceService,
    private clinicsService: ClinicsService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.clinicsService.clinicIdSelected$
      .asObservable()
      .pipe(takeUntil(this.unsub))
      .subscribe((clinicId) => {
        this.clinicId = clinicId;
        this.getMerchantDevices();
        this.clinicsService.getClinicById(this.clinicId).subscribe((snapshot) => {
          this.clinic = snapshot as Clinic;
        });
      });

    this.merchantDeviceService.merchantDeviceUpdateSource$.pipe(takeUntil(this.unsub)).subscribe((res) => {
      if (res) {
        this.getMerchantDevices();
      }
      this.disableGrid = false;
    });
  }

  private getMerchantDevices() {
    this.loading = true;

    this.merchantDeviceService.getMerchantDevices().subscribe((res) => {
      this.gridData = res;
      this.loading = false;
    });
  }

  public onAddClick({ sender }) {
    this.disableGrid = true;
    this.merchantDeviceService.shareMerchantDevice(null);
    this.router.navigate([
      '/management/organization/clinics/merchant-devices',
      { outlets: { 'action-panel': ['edit-merchant-device', '_'] } },
    ]);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.disableGrid = true;
    this.merchantDeviceService.shareMerchantDevice(dataItem);
    this.router.navigate([
      '/management/organization/clinics/merchant-devices',
      { outlets: { 'action-panel': ['edit-merchant-device', dataItem.id] } },
    ]);
  }

  public removeHandler({ dataItem }) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.loading = true;
        this.merchantDeviceService.deleteMerchantDevice(dataItem).subscribe(
          (res) => {
            this.getMerchantDevices();
          },
          (error) => {
            this.loading = false;
            if (error.error && error.message) {
              this.dialog.open(GenericDialogComponent, {
                maxWidth: '500px',
                data: {
                  showCancel: false,
                  title: 'Removal Failed',
                  content: error.message,
                  confirmButtonText: 'Ok',
                },
              });
            } else return throwError(error);
          }
        );
      }
    });
  }

  toggleMerchantDevices() {
    this.clinicsService.updateClinicMerchantDevicesSettings(this.clinic).subscribe(() => {});
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
