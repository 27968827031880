import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';

export enum ErrorMessageType {
  Info,
  Error
}

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorComponent implements OnInit {
  @Input() message: string;
  @Input() type: ErrorMessageType = ErrorMessageType.Error;
  @Input() set showCloseButton(value: boolean) {
    this._showCloseButton = value;
  }
  @Output() onClose = new EventEmitter();
  private _showCloseButton: boolean = false;
  get showCloseButton() {
    return this._showCloseButton || this.onClose.observers.length > 0;
  }
  errorMessageType = ErrorMessageType;

  constructor() {}

  ngOnInit() {}
}
