import { SharedModule } from '../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EditDocumentTypeComponent } from '../actionpanel/edit-document-type/edit-document-type.component';
import { CatalogueDocumentTypesComponent as CatalogueDocumentTypesComponent } from './catalogue-document-types.component';
import { DocumentTypeDetailsComponent } from './document-type-details/document-type-details.component';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    CatalogueDocumentTypesComponent,
    EditDocumentTypeComponent,
    DocumentTypeDetailsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    GridModule,
    ExcelModule,
    MatAutocompleteModule
  ],
  exports: [
    CatalogueDocumentTypesComponent,
    EditDocumentTypeComponent,
    DocumentTypeDetailsComponent
  ]
})
export class CatalogueDocumentTypesModule {}
