<div class="modal-title d-flex mb-4" mat-dialog-title>
  <button class="btn ml-auto white-font" (click)="closeDialog()">
    <i class="far fa-times"></i>
  </button>
</div>
<app-error *ngIf="errorMessage" [message]="errorMessage"></app-error>
<div class="modal-content d-flex flex-column flex-grow-1 mb-4" mat-dialog-content>
  <div class="card grey-one-background pt-3 mx-4">
    <div class="mx-auto img-container">
      <img class="img-avatar" [src]="selectedUser ? selectedUser.avatar : currentUser.avatar" alt="User Avatar" />
    </div>
    <h4 class="info text-center text-uppercase m-2">
      {{ selectedUser ? selectedUser.fullName : currentUser.fullName }}
    </h4>
    <!-- <app-select-clinic class="d-block mx-auto" [disabled]="selectedUser ? true : false"></app-select-clinic> -->
  </div>
  <ng-container *ngIf="!selectedUser; else passwordInput">
    <h5 class="text-center text-uppercase mt-3">Switch User</h5>
    <div class="users card flex-grow-1 mx-4">
      <ng-scrollbar class="h-100">
        <div
          class="d-flex flex-row m-2 p-2 pointer grey-two-background"
          *ngFor="let user of clinicUsers"
          (click)="setSelectedUser(user)"
        >
          <div class="img-container flex-shrink-0">
            <img class="img-avatar" [src]="user.avatar" alt="User Avatar" />
          </div>
          <h4 class="info text-uppercase m-2 text-truncate">{{ user.fullName }}</h4>
        </div>
      </ng-scrollbar>
    </div>
  </ng-container>
</div>

<ng-template #passwordInput>
  <div class="flex-grow-1">
    <form class="mb-4 mx-auto">
      <mat-form-field class="w-100">
        <input
          matInput
          type="password"
          placeholder="Password"
          [formControl]="passwordFormControl"
          [errorStateMatcher]="matcher"
          autocomplete="off"
        />
        <mat-hint>Enter your password.</mat-hint>
        <mat-error *ngIf="passwordFormControl.hasError('required')"> Password is <strong>required</strong> </mat-error>
      </mat-form-field>
    </form>
    <div class="buttons mx-auto">
      <button class="btn em-btn em-btn-green action mr-2" (click)="clearSelectedUser()">Cancel</button>
      <button class="btn em-btn em-btn-green action" [disabled]="!passwordFormControl.valid || !passwordFormControl.dirty" (click)="loginSelectedUser()">Login</button>
    </div>
  </div>
</ng-template>
