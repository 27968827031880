import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GridModule } from '@progress/kendo-angular-grid';

import { OrgAccessLocationsComponent } from './org-access-locations.component';
import { EditAccessLocationComponent } from './edit-access-location/edit-access-location.component';
import { SharedModule } from '@app/shared/shared.module';
import { AccessLocationService } from '@services/access-location.service';

@NgModule({
  declarations: [
    OrgAccessLocationsComponent,
    EditAccessLocationComponent
  ],
  imports: [
    SharedModule,
    GridModule,
    RouterModule
  ],
  exports: [
    OrgAccessLocationsComponent,
    EditAccessLocationComponent
  ],
  providers: [AccessLocationService]
})
export class AccessLocationModule { }
