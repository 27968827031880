import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { isNullOrUndefined } from '@app/shared/helpers';
import { environment } from '@environments/environment';
import { User } from '@models/user';
import { BlobService } from '@services/blob.service';
import { UsersService } from '@services/users.service';
import { Observable, Subject, forkJoin } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { ChatMessage } from '../models/chat-message.model';
import { ChatUser } from '../models/chat-user.model';

@Injectable()
export class ChatUsersService implements OnDestroy {
  private _chatUsers: Map<number, ChatUser> = new Map();
  private userUpdated = new Subject<ChatUser>();
  private currentUserUpdated = new Subject<ChatUser>();
  private unsub: Subject<any> = new Subject<any>();
  public onUserUpdated$ = this.userUpdated.asObservable();
  public onCurrentUserUpdated$ = this.currentUserUpdated.asObservable();
  public currentChatUser: ChatUser;

  constructor(private http: HttpClient, private blobService: BlobService, private usersService: UsersService) {
    this.usersService.loggedInUserUpdated$
      .pipe(takeUntil(this.unsub))
      .subscribe((user) => this.loggedInUserUpdated(user));
  }

  loggedInUserUpdated(user: User) {
    if (user && user.id) {
      forkJoin([this.getCurrentChatUser(), this.getAllChatUsers()]).subscribe(([currUser, allUsers]) => {
        this.currentChatUser = currUser;
        this.currentUserUpdated.next(currUser);
      });
    }
  }

  getCurrentChatUser(): Observable<ChatUser> {
    return this.http.get<ChatUser>(environment.baseUrl + 'api/Messaging/GetCurrentChatUser');
  }

  getAllChatUsers(activeOnly = false): Observable<ChatUser[]> {
    return forkJoin([
      this.blobService.getReadOnlySASObservable().pipe(first()),
      this.http.get<ChatUser[]>(environment.baseUrl + 'api/Messaging/GetAllChatUsers/' + activeOnly),
    ]).pipe(
      map(([readOnlySAS, users]) => {
        users.map((u) => {
          if (isNullOrUndefined(u.avatar) || u.avatar === '' || u.avatar === 'avatar') {
            u.avatar = '/assets/Avatars/avatar-placeholder.png';
          } else {
            u.avatar = (u.avatar ? u.avatar.trim() : '') + readOnlySAS;
          }
          this._chatUsers.set(u.chatUserId, u);
          return u;
        });
        return users;
      })
    );
  }

  chatUserLookup(id: number): ChatUser {
    return this._chatUsers.get(id);
  }

  mapUserDetails(messages: ChatMessage[]): ChatMessage[] {
    messages.forEach((message) => {
      if (isNullOrUndefined(message.userName)) {
        const chatUser = this.chatUserLookup(message.chatUserId);
        if (chatUser) {
          message.userName = chatUser.userName;
          message.avatar = chatUser.avatar;
        } else {
          message.userName = 'unknown';
        }
        message.dateStr = new Date(message.when).toDateString();
      }
    });
    return messages;
  }

  addOrUpdateUser(user: ChatUser) {
    const existing = this._chatUsers.get(user.chatUserId);
    if (!existing) {
      this._chatUsers.set(user.chatUserId, user);
      this.userUpdated.next(user);
    } else if (existing.status !== user.status) {
      existing.status = user.status;
      this.userUpdated.next(user);
    }
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }
}
