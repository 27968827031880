import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ServiceEventType } from '@models/service/service-event-type';
import { Service } from '@models/service/service';
import { ObservationTypes, Observation } from '@models/observation/observation';
import { TreatmentObservationGroup } from '@models/treatment-planning/treatment-observation-group';

@Injectable({
  providedIn: 'root'
})
export class ServiceEventService {
  private serviceSource = new Subject<any>();
  private treatmentSaved = new Subject<{service: Service, isClosed: boolean}>();
  treatmentSaved$ = this.treatmentSaved.asObservable();
  billingObservationsOverridden = new Subject<TreatmentObservationGroup>();
  treatmentObservationsGroupDeleted = new Subject<string>();
  recalculateObservationBilling = new Subject<TreatmentObservationGroup>();
  recalculateTreatmentBilling = new Subject<Observation[]>();
  recalculateTotal = new Subject<TreatmentObservationGroup>();

  observationTotalOverriden = new Subject<TreatmentObservationGroup>();
  observationTotalOverriden$ = this.observationTotalOverriden.asObservable();
  observationTotalOverrideCancelled = new Subject<TreatmentObservationGroup>();
  observationTotalOverrideCancelled$ = this.observationTotalOverrideCancelled.asObservable();

  observationUnitPriceOverriden = new Subject<TreatmentObservationGroup>();
  observationUnitPriceOverriden$ = this.observationUnitPriceOverriden.asObservable();
  observationUnitPriceOverrideCancelled = new Subject<TreatmentObservationGroup>();
  observationUnitPriceOverrideCancelled$ = this.observationUnitPriceOverrideCancelled.asObservable();

  constructor() { }

  emitApplyTreatment(service: Service, obrType: ObservationTypes) {
    this.serviceSource.next({ event: ServiceEventType.ApplyTreatment, service: service, obrType: obrType });
  }

  emitTreatmentSaved(service: Service, isClosed: boolean) {
    this.treatmentSaved.next({service, isClosed});
  }

  getServiceSource() {
    return this.serviceSource.asObservable();
  }
}
