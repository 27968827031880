import { TreatmentState } from './../../../../../models/treatment-planning/treatment-state';
import { Component, OnInit, Input, OnDestroy, OnChanges, Output, EventEmitter } from '@angular/core';
import { TreatmentPlan } from '@models/treatment-planning/treatment-plan';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { TreatmentPlanFormService } from '@services/treatment-planning/treatment-plan-form.service';
import { ObservationTypes } from '@models/observation/observation';
import { ServiceListType } from '@models/service/service-list-type';
import { AppointmentService } from '@services/appointments.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PlannedTreatment } from '@models/treatment-planning/planned-treatment';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { Service } from '@models/service/service';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { TabType } from '@models/tab-type.enum';

@Component({
  selector: 'app-visits-treatment-plan',
  templateUrl: './visits-treatment-plan.component.html',
  styleUrls: ['./visits-treatment-plan.component.less'],
})
export class VisitsTreatmentPlanComponent implements OnInit, OnDestroy, OnChanges {
  @Input() patientId: number;
  @Input() serviceTemplateIds: number[];
  @Output() addServiceToSchedule: EventEmitter<Service> = new EventEmitter();

  unsub: Subject<void> = new Subject<void>();
  treatmentPlan: TreatmentPlan;
  treatmentState = TreatmentState;
  selectedTreatmentIndex: number;
  observationTypes = ObservationTypes;
  serviceListType = ServiceListType;
  expanded: boolean;
  loading = false;
  TabType = TabType;
  public serviceDetailTemplate = ServiceDetailTemplate;

  constructor(
    private treatmentPlanService: TreatmentPlanService,
    private treatmentPlanFormService: TreatmentPlanFormService,
    private appointmentService: AppointmentService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.detectChanges();

    // When an appointment is added refresh the unplanned treatments
    // A previously unplanned treatment may have been added to the schedule
    this.appointmentService.apptsAdded$.pipe(takeUntil(this.unsub)).subscribe({
      next: (appt) => this.getUnplannedTreatments(),
    });
  }

  ngOnChanges() {
    this.getUnplannedTreatments();
  }

  detectChanges() {
    this.treatmentPlanService.treatmentPlanScheduled$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.getUnplannedTreatments();
    });

    this.treatmentPlanService.plannedTreatmentCancelled$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.getUnplannedTreatments();
    });
  }

  getUnplannedTreatments() {
    this.loading = true;
    this.treatmentPlanService
      .getUnplannedTreatmentPlanByPatientId(this.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((tp) => {
        this.treatmentPlan = this.treatmentPlanFormService.onCleanTreatmentPlan(tp);
        this.loading = false;
      });
  }

  // COMMENT OUT BEGIN
  addService(treatmentItem: PlannedTreatment, index: number) {
    const authorizedServiceIndex = this.serviceTemplateIds.findIndex(
      (templateId) => templateId === treatmentItem.service.templateId
    );
    if (authorizedServiceIndex === -1) {
      this.dialog.open(GenericDialogComponent, {
        width: '300px',
        data: {
          title: 'Error',
          content: "This provider can't perform the booked service!",
          confirmButtonText: 'Ok',
          showCancel: false,
        },
      });
      return;
    }
    this.selectedTreatmentIndex = index;
    this.treatmentPlanService.treatmentPlanScheduled = treatmentItem;
    this.addServiceToSchedule.next(treatmentItem.service);
  }

  trackByFn(index) {
    return index;
  }

  // COMMENT OUT END

  public sendServiceToSchedule(event) {
    this.addServiceToSchedule.emit(event);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
