import { EmailTemplateType } from '@models/clinic-email-template';
export class TestEmail {
  emailTemplateType: EmailTemplateType;
  templateReferenceId: number;
  senderName: string;
  senderEmail: string;
  replyToEmail: string;
  recipientName: string;
  recipientFirstName: string;
  recipientLastName: string;
  recipientEmail: string;
  html: string;
  subject: string;
}
