<ngx-loading [show]="loading"></ngx-loading>

<div class="message-header d-flex align-items-center navy-font grey-three-background w-100">
  <div class="col-7 bigger">
    <span *ngIf="channelService.selectedChannel?.private !== true"># </span>
    {{
      channelService.selectedChannel?.private == true
        ? channelService.privateChannelTitles.get(channelService.selectedChannel.id)
        : channelService.selectedChannel?.name
    }}
  </div>
  <div class="col-4">
    <!-- <app-messaging-header-search></app-messaging-header-search> -->
  </div>
  <div class="col-1 text-right">
    <button *appAuthGuard="messagingPolicy" type="menu" class="btn-clean" [matMenuTriggerFor]="channelSettings">
      <i class="far fa-cog bigger-icon"></i>
    </button>
    <mat-menu class="chat-settings-menu" #channelSettings="matMenu">
      <button
        *ngIf="!channelService.selectedChannel?.private"
        (click)="onEditChannel()"
        class="btn-clean px-2 mat-menu-item"
      >
        <i class="fas fa-edit pr-2"></i>
        Edit
      </button>
      <button (click)="onRemoveChannel()" class="btn-clean px-2 mat-menu-item">
        <i class="fas fa-trash pr-2"></i>
        Remove
      </button>
    </mat-menu>
  </div>
</div>
