import { IChargeableAsset } from '@interfaces/IChargeableAsset';
import { ChargeableItem } from './chargeable-item';

export abstract class ChargeableAsset {
  public abstract getChargeableAsset(): IChargeableAsset;

  public getUnadjustedChargeAmount(): number {
    const chargeableAsset = this.getChargeableAsset();

    let charge = 0;
    for (const item of chargeableAsset.getBaseAndChildrenChargeableItems()) {
      charge += item.defaultPrice;
    }

    return charge;
  }

  public getChargeAmount(): number {
    const chargeableAsset = this.getChargeableAsset();

    let charge = 0;
    for (const item of chargeableAsset.getChargeableItems()) {
      charge += this.calcItemCharge(item);
    }
    return +charge.toFixed(2);
  }

  private calcItemCharge(item: ChargeableItem): number {
    if (item.overridePrice != null) {
      return item.overridePrice * (item.quantity ? item.quantity : 1);
    } else if (item.defaultPrice != null) {
      if (item.discount != null) {
        return item.defaultPrice * (1 - item.discount);
      } else {
        return item.defaultPrice * (item.quantity ? item.quantity : 1);
      }
    } else {
      return 0;
    }
  }
}
