import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ServiceeTreatmentForm } from '@models/etreatment-forms/service-etreatment-form';

@Injectable({
  providedIn: 'root',
})
export class ServiceeTreatmentFormService {
  constructor(private http: HttpClient) {}

  getServiceeTreatmentForms(clinicServiceTemplateId: number) {
    return this.http.get<ServiceeTreatmentForm[]>(
      environment.baseUrl + 'api/ClinicServiceTemplateeTreatmentForm/' + clinicServiceTemplateId
    );
  }
}
