<div class="container-fluid h-100" kendoTooltip tooltipClass="kendo-tooltip" position="top">
  <div class="row h-100">
    <div class="col-4 h-100">
      <div (click)="goToOverview()" class="text-left text-uppercase p-4 navy-background white-font pointer w-100">
        <i class="far fa-arrow-left mr-2"></i>Chart
      </div>
      <div class="prescription-list border py-3">
        <perfect-scrollbar>
          <ngx-loading [show]="prescriptionLoading"></ngx-loading>
          <div class="px-3">
            <div
              class="prescription-item d-flex mt-2"
              [ngClass]="{ 'selected-prescription': item.id === prescriptionId }"
              *ngFor="let item of prescriptionList"
            >
              <ng-content *ngTemplateOutlet="individualPrescription; context: { item: item }"> </ng-content>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>

    <div class="col-8 pl-0">
      <app-error *ngIf="hasError" [message]="errorMessage" [showCloseButton]="true"></app-error>
      <div class="prescription-header align-items-center d-flex border-bottom pt-3 pb-3">
        <h6 class="text-uppercase navy-font m-0">Prescriptions</h6>
        <button (click)="createNewPrescription()" class="btn em-btn em-btn-green ml-auto mr-4">CREATE NEW Rx</button>
        <button [disabled]="!selectedPrescription" (click)="renewPrescription()" class="btn em-btn em-btn-green mr-4">
          RENEW Rx
        </button>
        <button
          [disabled]="!selectedPrescription"
          (click)="printPrescription(selectedPrescription)"
          class="btn em-btn em-btn-green mr-4"
        >
          PRINT
        </button>
        <button
          [disabled]="!selectedPrescription"
          (click)="faxPrescription(selectedPrescription)"
          class="btn em-btn em-btn-green mr-4"
        >
          FAX
        </button>
      </div>
      <div *ngIf="selectedPrescription" class="border mt-3 py-3 px-3 navy-font">
        <app-prescription-details
          #prescriptionDetail
          [patient]="patient"
          [clinic]="clinic"
          [prescription]="[selectedPrescription]"
        >
        </app-prescription-details>
      </div>
    </div>
  </div>
</div>

<ng-template let-item="item" #individualPrescription>
  <div class="flex-grow-1 grey-two-background">
    <div class="d-flex py-2 px-3">
      <div>{{ item.drugName }}</div>
      <div class="prescription-actions navy-font ml-auto flex-shrink-0">
        <i
          title="Renew Prescription"
          (click)="renewPrescription(item)"
          class="far fa-prescription-bottle-alt navy-font pointer"
        ></i>
        <i title="Print Prescription" (click)="printPrescription(item)" class="far fa-print ml-3 navy-font pointer"></i>
        <i title="Fax Prescription" (click)="faxPrescription(item)" class="far fa-fax ml-3 navy-font pointer"></i>
      </div>
    </div>
    <div class="d-flex py-2 px-3">
      <div class="col pl-0 pr-1">Date: {{ getDateStr(item.createdDate) }}</div>
      <div class="col pl-0 pr-1">QTY: {{ item.quantity ? item.quantity : '-' }}</div>
      <div class="col pl-0 pr-1">Repeats: {{ item.refillCount ? item.refillCount : '-' }}</div>
      <div class="col flex-shrink-0"></div>
    </div>
  </div>

  <button (click)="changePrescription(item)" type="button" class="prescription-btn ml-auto">
    <i title="View Details" class="far fa-arrow-right"></i>
  </button>
</ng-template>

<div style="opacity: 0; position: absolute; width: 0; height: 0">
  <ng-template #exportContainer></ng-template>
</div>
