import { Injectable } from '@angular/core';
import { Patient } from '@models/patient';
import { PatientService } from './patient.service';
import { EventsService } from './events.service';
import { AuthService } from '@app/auth/auth.service';
import { SignalrBase } from './signalr-base';
import { SignalrHttpClientService } from './signalr-http-client.service';
import { Policies } from '@app/auth/auth-policies';

@Injectable({
  providedIn: 'root',
})
export class PatientSignalrService extends SignalrBase<number | Patient> {
  constructor(
    private eventsService: EventsService,
    private patientService: PatientService,
    authService: AuthService,
    private signalrHttpClientService: SignalrHttpClientService
  ) {
    super(
      'Patients',
      Policies.patientPanel,
      authService,
      signalrHttpClientService
    );
    this.registerServerEvents();
  }

  private registerServerEvents(): void {
    super.registerServerEvent('UpdatePatient', (patientId: number) => {
      // Only patient ID is passed from signalR due to patient circular reference issues.
      this.checkUpdatePatientTriggers(patientId);
    });

    super.registerServerEvent('AddPatient', (data: Patient) => {
      this.checkAddPatientTriggers(data);
    });

    super.registerServerEvent('DeletePatient', (data: Patient) => {
      this.checkDeletePatientTriggers(data);
    });
  }

  private checkUpdatePatientTriggers(patientId: number) {
    this.eventsService.patientListUpdated();
    if (this.patientService.patientPanelPatient && patientId === this.patientService.patientPanelPatient.patientId) {
      this.patientService.thePatientHasBeenUpdatedById(patientId);
    }
  }

  private checkAddPatientTriggers(patient: Patient) {
    this.eventsService.patientListUpdated();
    if (
      this.patientService.patientPanelPatient &&
      patient.patientId === this.patientService.patientPanelPatient.patientId
    ) {
      this.patientService.thePatientHasBeenUpdated(patient);
    }
  }

  private checkDeletePatientTriggers(patient: Patient) {}
}
