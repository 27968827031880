<ngx-loading [show]="loading"></ngx-loading>
<div
  class="px-3 d-flex"
  [ngStyle]="{ height: panelHeight ? panelHeight + 'px' : '100%' }"
  *ngIf="serviceTemplateIds?.length > 0"
>
  <div class="row">
    <div class="col">
      <ng-container *ngFor="let error of errors">
        <app-error *ngFor="let fieldError of error.fieldErrors" [message]="fieldError"></app-error>
      </ng-container>
    </div>
  </div>
  <div class="treatment-plan flex-grow-1 minh-0">
    <div
      [ngStyle]="{ 'max-height': !isTreatmentPlanning ? panelHeight + 'px' : 'inherit' }"
      [ngClass]="{ 'list-mode': !isTreatmentPlanning }"
    >
      <div class="d-flex align-items-center w-100 flex-shrink-0" style="height: 48px">
        <div class="column-header py-0 mr-2">Treatment Plan</div>
        <button
          *ngIf="showDetailButton"
          type="button"
          class="btn btn-sm em-btn em-btn-green ml-auto"
          (click)="onViewPlan()"
        >
          <i class="far fa-file-alt" title="Show Details"></i>
        </button>
      </div>
      <ng-scrollbar [autoHeightDisabled]="false">
        <mat-accordion [multi]="true" *ngIf="patient?.patientId">
          <app-planned-treatment-list-panel
            [treatmentPlan]="treatmentPlan"
            [isLocked]="false"
            [typeOfTab]="typeOfTab"
            [currentInvoice]="currentInvoice"
            [serviceTemplateIds]="serviceTemplateIds"
            [serviceTemplateId]="serviceTemplateId"
            [isAdvancedTreatmentPage]="isAdvancedTreatmentPage"
            [patientId]="patient?.patientId"
            (selectedItem)="selectService($event)"
          >
          </app-planned-treatment-list-panel>
          <ng-container *ngIf="showProducts">
            <p *ngIf="treatmentPlan?.productRecommendations?.length > 0" class="font-weight-bold list-header mb-1">
              Product Recommendations
            </p>
            <mat-expansion-panel
              class="mb-2"
              *ngFor="let productRecommendation of treatmentPlan?.productRecommendations"
              [expanded]="false"
              [disabled]="true"
            >
              <mat-expansion-panel-header class="px-0 py-0" [collapsedHeight]="'36px'" [expandedHeight]="'36px'">
                <mat-panel-title>
                  <div class="d-flex panel-box panel-box--small px-3">
                    <div class="panel-box-column panel-box-column--main">
                      {{ productRecommendation?.clinicProduct?.displayName }}
                    </div>
                    <div class="panel-box-column">
                      {{ productRecommendation?.retailPrice * productRecommendation?.quantity | currency }}
                    </div>
                  </div>
                </mat-panel-title>
                <button
                  *ngIf="isInvoicePage"
                  kendoTooltip
                  tooltipClass="em-tooltip"
                  position="top"
                  type="button"
                  class="panel-box-button h-auto"
                  (click)="selectedItem.emit(productRecommendation?.clinicProduct)"
                >
                  <i class="far fa-plus" title="Add to Invoice"></i>
                </button>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </ng-scrollbar>
    </div>
  </div>
</div>
