import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Service } from '@models/service/service';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { ObservationService } from './observation.service';
import { Observable } from 'rxjs';
import { UserFavouriteService } from '../models/service/user-favourite-service';

@Injectable()
export class FavouriteServicesService {
  constructor(private http: HttpClient, private observationService: ObservationService) {}

  getFavouriteServices(userId: string = null) {
    return this.http
      .get<UserFavouriteService[]>(environment.baseUrl + 'api/FavouriteServices' + (userId ? '/' + userId : ''))
      .pipe(
        map((userFavServices: UserFavouriteService[]) => {
          userFavServices.forEach((s) => {
            // Map observations details from string to TS JSON object
            this.observationService.detailsToJson(s.service?.observations);
          });
          return userFavServices;
        })
      );
  }

  updateFavouritesServiceOrder(favourites: UserFavouriteService[]): Observable<any> {
    return this.http.post(environment.baseUrl + 'api/FavouriteServices/UpdateFavouriteServicesOrder', favourites);
  }

  addFavouriteService(favServices: UserFavouriteService) {
    return this.http.post(environment.baseUrl + 'api/FavouriteServices', favServices);
  }

  deleteFavouriteService(favServiceId: number) {
    return this.http.delete(environment.baseUrl + 'api/FavouriteServices/' + favServiceId);
  }
}
