<div class="panel-item-border mt-0 pt-2">
  <!-- <div class="panel-flex-row px-3"><div class="column-header">Patient Portal</div></div> -->
  <div class="d-flex flex-column justify-content-center my-2 w-100">
    <button
      class="btn btn-sm em-btn em-btn-green py-2 px-3 mx-auto font-weight-bold btn-width"
      kendoTooltip
      tooltipClass="em-tooltip"
      [title]="
        patientPortalAccountExists
          ? 'A patient portal account already exists'
          : thePatient?.email == null || thePatient?.email == ''
          ? 'Please enter an email for this patient'
          : 'Create a patient portal account for this patient'
      "
      [disabled]="patientPortalAccountCreationButtonDisabled()"
      style="line-height: 1em"
      (click)="showConfirmPortalAccountPopup()"
    >
      <i *ngIf="!patientPortalAccountCreationRequested" class="fas fa-user pr-1"></i>
      {{ patientPortalAccountCreationRequested ? 'Creating account...' : 'Create Portal Account' }}
    </button>
  </div>
  <div *ngIf="createdDate != null" class="d-flex flex-column text-center w-100">
    Created Date: {{ createdDate | date : 'yyyy-MM-dd h:mm a' }}
  </div>
  <div *ngIf="createdDate != null" class="d-flex flex-column text-center w-100">Created By: {{ createdBy }}</div>
  <div class="d-flex flex-column justify-content-center my-2 w-100">
    <button
      class="btn btn-sm em-btn em-btn-green py-2 px-3 mx-auto font-weight-bold btn-width"
      kendoTooltip
      tooltipClass="em-tooltip"
      [title]="
        thePatient?.email == null || thePatient?.email == ''
          ? 'Please enter an email for this patient'
          : !patientPortalAccountExists
          ? 'A patient portal account doesn\'t exist'
          : 'Send a patient portal reset password email to this patient'
      "
      [disabled]="sendPasswordResetButtonDisabled()"
      style="line-height: 1em"
      (click)="showConfirmPasswordResetPopup()"
    >
      <i *ngIf="!passwordResetRequested" class="fas fa-envelope pr-1"></i>
      {{ passwordResetRequested ? 'Sending password reset...' : 'Send Password Reset' }}
    </button>
  </div>
  <div class="d-flex flex-column justify-content-center my-2 w-100">
    <button
      class="btn btn-sm em-btn em-btn-green py-2 px-3 mx-auto font-weight-bold btn-width"
      kendoTooltip
      tooltipClass="em-tooltip"
      [title]="
        thePatient?.email == null || thePatient?.email == ''
          ? 'Please enter an email for this patient'
          : !patientPortalAccountExists
          ? 'A patient portal account doesn\'t exist'
          : 'Send Documents to Complete email'
      "
      [disabled]="sendDocumentstToCompleteButtonDisabled()"
      style="line-height: 1em"
      (click)="sendDocumentsToComplete()"
    >
      <i class="far fa-file-alt pr-1"></i>
      {{ sendingDocumentsToCompleteEmail ? 'Sending E-Forms email...' : 'Send E-Forms email' }}
    </button>
  </div>
  <div class="d-flex flex-column justify-content-center my-2 w-100">
    <button
      class="btn btn-sm em-btn em-btn-green py-2 px-3 mx-auto font-weight-bold btn-width"
      kendoTooltip
      tooltipClass="em-tooltip"
      [title]=""
      style="line-height: 1em"
      (click)="showKioskMode()"
    >
      <i class="far fa-magic pr-1"></i>
      Patient Kiosk
    </button>
  </div>
  <div class="d-flex flex-column justify-content-center text-center my-2 w-100">
    <mat-error class="errorBox" [hidden]="!portalError">
      {{ errorMessage }}
    </mat-error>
  </div>
</div>
