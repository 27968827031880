<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>

<div class="edit-container edit-document position-absolute d-block z-index-3 white-background">
  <div class="re-links-container">
    <button class="btn em-btn teal-background" (click)="cancelUpdate()">
      <i class="far fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row mx-0 teal-background">
        <div class="col px-2">
          <h6 class="white-font uppercase p-2 mb-0">{{ addOrUpdate }} eForm</h6>
        </div>
      </div>

      <!-- Form Type -->
      <div class="row mx-0 mt-2">
        <div class="col">
          <mat-form-field class="w-75">
            <mat-select
              placeholder="eForm Type"
              formControlName="formTypeId"
              autofocus
              required
              (selectionChange)="onFormTypeChange($event)"
            >
              <mat-option *ngFor="let formType of formTypes" [value]="formType.id">
                {{ formType.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Service Template Type -->
      <div class="row mx-0 mt-2">
        <div class="col">
          <mat-form-field class="w-75">
            <mat-select
              formControlName="serviceTemplateTypeId"
              placeholder="Service Template"
              aria-label="Service Template"
              [required]="serviceTemplateTypeValidationRequired"
            >
              <mat-option [value]="null"> None </mat-option>
              <mat-option *ngFor="let serviceTemplateType of serviceTemplateTypes" [value]="serviceTemplateType.id">
                {{ serviceTemplateType.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- Form Name-->
      <div class="row mx-0 mt-2">
        <div class="col">
          <mat-form-field class="w-100">
            <input matInput placeholder="eForm Title" formControlName="name" required />
          </mat-form-field>
        </div>
      </div>

      <!-- Notes -->
      <div class="row mx-0 mt-2">
        <div class="col">
          <mat-form-field class="w-100">
            <input matInput placeholder="Notes" formControlName="notes" />
          </mat-form-field>
        </div>
      </div>

      <!-- Submit Buttons-->
      <div class="row mx-4 mt-4">
        <button type="submit" class="btn em-btn em-btn-green ml-auto mr-2" [disabled]="isFormDisabled()">
          {{ addOrUpdate }}
        </button>
        <button class="btn em-btn em-btn-green" (click)="cancelUpdate()">Cancel</button>
      </div>
    </form>
  </perfect-scrollbar>
</div>
