import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PhotoConsentTitle, PhotoConsentType } from '@models/photo/photo-consent-type';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { SeriesType } from '@models/photo/series-type';
import { Tag } from '@models/tag/tag';
import { TagType } from '@models/tag/tag-type';
import { ImageService } from '@services/image.service';
import { PatientService } from '@services/patient.service';
import { PhotoDrawingService } from '@services/photo-drawing.service';
import { PhotoSeriesService } from '@services/photo-series.service';
import { TagService } from '@services/tag.service';
import * as moment from 'moment';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, debounceTime, delay, map, take, takeUntil } from 'rxjs/operators';
import { PhotoFilter, PhotoTag, TagCategory } from '../photo-filter';
import { GenericPhotoService } from '../services/generic-photo.service';
import { ConfirmDeleteDialogComponent } from './../../management/dialogs/confirm-delete/confirm-delete.component';
import { GenericDialogComponent } from './../../management/dialogs/generic-confirm/generic-confirm.component';
import { Patient } from './../../models/patient';
import { PhotoEventType } from './../../models/photo/photo-event-type';
import { PhotoEditingService } from './../../services/photo-editing.service';
import { ITagTypes } from './../../services/tag.service';
import { PhotoshopService } from '@services/photoshop.service';

@Component({
  selector: 'app-generic-photo-metadata',
  templateUrl: './generic-photo-metadata.component.html',
  styleUrls: ['./generic-photo-metadata.component.less'],
})
export class GenericPhotoMetadataComponent implements OnInit, OnDestroy {
  @Output() editModeChanged = new EventEmitter<boolean>();
  @Input() applyWatermark: boolean = false;
  @Output() applyWatermarkChange = new EventEmitter<boolean>();
  @Output() photoUpdated = new EventEmitter<PhotoMetaData>();
  @Input() photos: PhotoMetaData[];
  private _filter: PhotoFilter;
  @Input()
  set filter(filter: PhotoFilter) {
    this._filter = filter;
    this.patient = filter.patient;
  }
  get filter() {
    return this._filter;
  }
  private _currentPhoto: PhotoMetaData = null;
  originalPhotoMetadata: PhotoMetaData;
  @Input() set photo(currentPhoto) {
    if (this.editModeEnabled) {
      this.editModeEnabled = false;
      this.editModeChanged.emit(this.editModeEnabled);
    }
    this.originalPhotoMetadata = new PhotoMetaData(currentPhoto);
    if (currentPhoto) this.initPhotoMetadata(currentPhoto);
  }
  get photo() {
    return this._currentPhoto;
  }

  tagCategories: TagCategory[];
  private _tags: ITagTypes = {
    Service: [],
    BodyPart: [],
    PhotoType: [],
    Supply: [],
    AutoGenerated: [],
  };

  get tags() {
    return this._tags;
  }
  @Input() set tags(tags: ITagTypes) {
    this._tags = tags;
    const categoryMap = new Map<number, TagCategory>();
    for (let bodyTag of this._tags.BodyPart) {
      if (bodyTag.category) categoryMap.set(bodyTag.category.id, bodyTag.category);
    }
    this.tagCategories = Array.from(categoryMap, ([name, value]) => value);
  }

  currentPhotoTags = {
    Service: [],
    BodyPart: [],
    PhotoType: [],
    AutoGenerated: [],
    Supply: [],
  };

  get currentGenericPhotoTags() {
    let tags = this.currentPhotoTags.BodyPart.concat(this.currentPhotoTags.Service).concat(
      this.currentPhotoTags.Supply
    );
    return tags;
  }

  @ViewChild('imageNameInput') imageNameInput: ElementRef;
  @ViewChild('metadataForm') form: NgForm;
  photoDate: Date = null;
  PhotoConsentType = PhotoConsentType;
  PhotoTypeTag = TagType.photoType;
  loading: boolean = false;
  _clearToolbarSearch: boolean;
  searchResults: any;
  tagList: Tag[];
  get filteredTagList() {
    if (this.photo && this.photo.tags && this.tagList)
      return this.tagList.filter((tag) => this.photo.tags.findIndex((t) => t.tagId == tag.tagId) == -1);
    else return [];
  }
  selected: string;
  unsub: Subject<void> = new Subject<void>();
  photoSeriesList: PhotoMetaData[] = [];
  editingImageTags = false;
  pillColours = this.tagService.pillColours;
  TAGTYPES = TagType;
  editModeEnabled: boolean = false;
  isAvatar: boolean = false;
  patient: Patient = null;
  photoEdited: boolean = false;
  photoTagsEdited: boolean = false;
  emailAddress = new FormControl('', Validators.required);

  constructor(
    private tagService: TagService,
    private photoSeriesService: PhotoSeriesService,
    private photoEditingService: PhotoEditingService,
    private confirmDialog: MatDialog,
    private imageService: ImageService,
    private patientService: PatientService,
    private photoService: GenericPhotoService,
    private photoDrawingService: PhotoDrawingService,
    private photoshopService: PhotoshopService
  ) {}

  ngOnInit() {
    if (this.patient && this.patient.email) this.emailAddress.setValue(this.patient.email);

    this.tagService.getAllPhotoTags().subscribe((tags) => {
      this.tagList = tags;
    });

    this.photoSeriesService.photoSeriesSource$.pipe(takeUntil(this.unsub)).subscribe((res) => {
      this.photoSeriesList = res;
    });

    const extionsMap = {
      [PhotoEventType.LoadPhoto]: (photoEvent) => {
        this.photo = photoEvent.photo ? Object.assign({}, photoEvent.photo) : photoEvent.photo;
        if (this.photo && this.photo.isSeries) {
          this.photoSeriesList = this.photo.seriesPhotos ? this.photo.seriesPhotos : [];
        }
      },
      [PhotoEventType.ClearPhoto]: (photoEvent) => {
        this.photo = null;
      },
    };

    this.photoEditingService
      .getPhotoSource()
      .pipe(takeUntil(this.unsub), delay(0))
      .subscribe((photoEvent) => extionsMap[photoEvent.event](photoEvent));

    this.photoService.photoEdited$.pipe(takeUntil(this.unsub)).subscribe({
      next: (edited) => (this.photoEdited = edited),
    });

    this.patientService.thePatientUpdated$.pipe(takeUntil(this.unsub)).subscribe({
      next: (pat) => {
        this.patient = pat;
        this.isAvatar = this.getIsAvatar();
        if (this.patient.email) this.emailAddress.setValue(this.patient.email);
      },
    });
  }

  initPhotoMetadata(data: PhotoMetaData) {
    if (data) {
      this._currentPhoto = new PhotoMetaData(data);
      this.photoDate =
        this._currentPhoto && this._currentPhoto.dateTaken
          ? this._currentPhoto.dateTaken.toDate
            ? this._currentPhoto.dateTaken.toDate()
            : new Date(this._currentPhoto.dateTaken.toString())
          : null;
      this.isAvatar = this.getIsAvatar();
      // this._currentPhoto.addConsentTags();
      // if (this.isAvatar) this._currentPhoto.addAvatarTag();
      this.currentPhotoTags = {
        Service: data.tags.filter((tag) => tag && tag.type == TagType.service),
        BodyPart: data.tags.filter((tag) => tag && tag.type == TagType.bodyPart),
        PhotoType: data.tags.filter((tag) => tag && tag.type == TagType.photoType),
        AutoGenerated: data.tags.filter((tag) => tag && tag.type == TagType.autoGenerated),
        Supply: data.tags.filter((tag) => tag && tag.type == TagType.supply),
      };
    } else {
      this._currentPhoto = null;
      this.photoDate = null;
      this.isAvatar = false;
    }
  }

  getIsAvatar() {
    let result = false;
    if (!this.patient || !this.patient.avatar || !this._currentPhoto) return result;
    const currPath = this._currentPhoto.filePath.split('?')[0];
    const avatarPath = this.patient.avatar.split('?')[0];
    return avatarPath.replace('/originals', '') == currPath; //filePathOriginal corrupted in some cases so i left this as is
  }

  tagsChanged(tagData, type: TagType) {
    var tags: Tag[] = tagData.value;
    var existingTags = this.photo.tags.filter((tag) => tag && tag.type != type).map((tag) => tag.tagId);
    var tagsToUpdate = tags.map((tag) => tag.tagId).concat(existingTags);
    this.photoService.updatePhotoTags(this.photo.id, [...new Set(tagsToUpdate)]).subscribe((photo) => {
      this.filter.setSelectedPhoto(photo, this.photos);
      this.filter.filterChanged$.next(this.filter);
    });
  }

  getConsentString(consent: PhotoConsentType): string {
    return PhotoConsentTitle[PhotoConsentType[consent]];
  }

  checkSelected(tag) {
    return this.photo.tags.some((tag2) => tag2.tagId == tag);
  }

  getCurrentPhotoTags(tags: Tag[]) {
    return tags.map((t) => t.title).join(', ');
  }

  resetUpdate: Subject<any> = new Subject<any>();
  updateMetaData(debouncedTime: number = 750): Observable<PhotoMetaData> {
    var currentPhoto = Object.assign({}, this.photo);
    if (!currentPhoto) return of();
    else if (!currentPhoto.tags) currentPhoto.tags = [];
    else {
      currentPhoto.tags = currentPhoto.tags
        .filter((tag) => tag.tagId.indexOf('Consent-') == -1)
        .filter((tag) => tag.tagId.indexOf('Avatar-') == -1);
    }
    return this.imageService.uploadPhotoMetaData(currentPhoto).pipe(
      takeUntil(this.resetUpdate),
      debounceTime(debouncedTime),
      map((photo) => {
        //this.loading = false;
        currentPhoto = new PhotoMetaData(currentPhoto);
        currentPhoto.tags = photo.tags;
        // currentPhoto.addConsentTags();
        this.photoUpdated.emit(currentPhoto);
        return photo;
      })
    );
  }

  changedData() {
    this.resetUpdate.next();
    this.updateMetaData(1500).subscribe((photo) => {});
  }

  photoDateChanged(event) {
    this.photoDate = event;
    this.photo.dateTaken = moment(this.photoDate);
    this.loading = true;
    this.updateMetaData().subscribe((photo) => {
      this.loading = false;
    });
  }
  compareObjects(o1: PhotoTag, o2: PhotoTag): boolean {
    return o1 && o2 && o1.tagId === o2.tagId;
  }

  emailPhoto = (email: string) => {
    const currentPhoto = this._currentPhoto;
    var filename = !currentPhoto.isSeries
      ? currentPhoto.imageName
        ? currentPhoto.imageName
        : new Date().toISOString() + '_' + currentPhoto.patientId
      : (currentPhoto.seriesType == SeriesType.BeforeAfter ? 'Before-After_' : 'Multi-Image_') +
        (currentPhoto.imageName ? currentPhoto.imageName : currentPhoto.patientId);
    return this.imageService.emailPhoto(email, currentPhoto.id, filename, currentPhoto.patientId);
  };

  downloadPhoto(currentPhoto: PhotoMetaData) {
    this.loading = true;
    this.imageService.downloadSingleImage(currentPhoto).subscribe((resp) => {
      let blob = <Blob>resp.body;
      this.imageService.downloadImageLine(resp.headers.get('Filename-Emily'), URL.createObjectURL(blob));
      this.loading = false;
    });
  }

  deletePhoto(photo: PhotoMetaData) {
    this.photoDrawingService.getPhotoDrawingsByPhotoId(photo.id).subscribe((drawings) => {
      if (drawings?.length > 0) {
        this.confirmDialog.open(GenericDialogComponent, {
          width: '250px',
          data: {
            title: 'Warning',
            content: `This photo is associated with a chart entry drawing or patient note drawing.
            It cannot be deleted unless the associated drawing is deleted first`,
            confirmButtonText: 'Ok',
            showCancel: false,
          },
        });
      } else {
        let dialogOpts = this.isAvatar
          ? {
              width: '250px',
              data: {
                extraMessage: "Caution: You are removing the patient's current avatar.",
              },
            }
          : {
              width: '250px',
            };
        const dialogRef = this.confirmDialog.open(ConfirmDeleteDialogComponent, dialogOpts);

        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.unsub))
          .subscribe((result) => {
            if (result === 'delete') {
              this.imageService.deletePhoto(photo).subscribe(
                () => {
                  // this.filter.filterChanged$.next(this.filter);
                  // this.filter.setSelectedPhoto(null, this.photos);
                  this.photoService.photoDeleted$.next(photo);
                },
                (error) => {
                  if (error && error.error) {
                    const dialogRef = this.confirmDialog.open(GenericDialogComponent, {
                      width: '250px',
                      data: {
                        title: 'Warning',
                        content: `${error.error}`,
                        confirmButtonText: 'Ok',
                        showCancel: false,
                      },
                    });

                    dialogRef
                      .afterClosed()
                      .pipe(takeUntil(this.unsub))
                      .subscribe(() => {});
                  }
                }
              );
              if (this.isAvatar) {
                this.patientService.removePatientAvatar(photo.patientId).subscribe((res) => {
                  this.patientService.thePatientHasBeenUpdatedById(photo.patientId);
                });
              }
            }
          });
      }
    });
  }

  favouriteToggle() {
    this.photo.isFavourite = !this.photo.isFavourite;
    this.imageService.setPhotoFavouriteStatus(this.photo, this.photo.isFavourite).subscribe((p) => {
      this.photoUpdated.emit(this.photo);
    });
  }

  GetFilteredTags(tagType: TagType) {
    return this.photo.tags.filter((tag) => tag && tag.type == tagType);
  }

  enableEditMode() {
    this.editModeEnabled = !this.editModeEnabled;
    this.editModeChanged.emit(this.editModeEnabled);
    if (this.editModeEnabled && this._currentPhoto && this.photo && this.imageNameInput)
      this.imageNameInput.nativeElement.focus();
  }

  resetChanges() {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.controls[key].markAsPristine();
    });
    this.photo = this.originalPhotoMetadata;
    this.photoTagsEdited = false;
    this.editModeEnabled = false;
    this.editModeChanged.emit(this.editModeEnabled);
  }

  onTagsSelected(tags: Tag[]) {
    this.currentPhotoTags.Service = tags.filter((t) => t.type == TagType.service);
    this.currentPhotoTags.BodyPart = tags.filter((t) => t.type == TagType.bodyPart);
    this.currentPhotoTags.Service = tags.filter((t) => t.type == TagType.service);
    this.photoTagsEdited = true;
  }

  async onSubmit() {
    this.loading = true;
    let promises: Promise<PhotoMetaData>[] = [];
    if (this.form.controls.photoDateTaken.dirty) {
      this.photoDate = this.form.controls.photoDateTaken.value;
      this.photo.dateTaken = moment(this.photoDate);
    }
    // Update metadata if there are changes
    if (
      this.form.controls.photoName.dirty ||
      this.form.controls.photoNotes.dirty ||
      this.form.controls.photoSharingTags.dirty ||
      this.form.controls.photoDateTaken.dirty
    )
      promises.push(this.updateMetaData(0).pipe(take(1)).toPromise());
    // Update tags if there are changes
    if (this.form.controls.photoTypeTag.dirty || this.photoTagsEdited) {
      const updatedTags: Tag[] = this.currentGenericPhotoTags.concat(this.currentPhotoTags.PhotoType);
      const tagsToUpdate = updatedTags.map((tag) => tag.tagId);
      promises.push(
        this.photoService
          .updatePhotoTags(this.photo.id, [...new Set(tagsToUpdate)])
          .pipe(take(1))
          .toPromise()
      );
    }

    // Reset form status to pristine
    Object.keys(this.form.controls).forEach((key) => {
      this.form.controls[key].markAsPristine();
    });
    this.photoTagsEdited = false;

    // Wait for all changes to save
    const results = await Promise.all(promises);

    let updatedPhoto: PhotoMetaData = null;
    // Merge response objects to get updated tags and metadata
    if (results.length == 2) {
      updatedPhoto = results[0];
      updatedPhoto.tags = results[1].tags;
    } else if (results.length == 1) updatedPhoto = results[0];

    // Notify gallery & filter of changes if there are any
    if (updatedPhoto) {
      this.photo = updatedPhoto;
      this.photoUpdated.emit(updatedPhoto);
      this.filter.setSelectedPhoto(this.photo, this.photos);
      this.filter.filterChanged$.next(this.filter);
    }

    this.loading = false;
    this.editModeEnabled = false;
    this.editModeChanged.emit(this.editModeEnabled);
    this.photoService.photoSaved$.next(true);
  }

  onApplyWatermark() {
    this.applyWatermark = !this.applyWatermark;
    this.applyWatermarkChange.emit(this.applyWatermark);
  }

  async applyFilter(filterName: string) {
    this.loading = true;
    const photoId = this.filter.selectedPhoto.id;
    const result = await this.photoshopService
      .applyFilter(filterName, photoId)
      .pipe(
        catchError((err) => {
          this.loading = false;
          return throwError(err);
        })
      )
      .toPromise();
    this.filter.selectedPhoto = result;
    this.photoService.photoSaved$.next(true);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }
}
