<nav mat-tab-nav-bar>
  <a mat-tab-link routerLink="list" routerLinkActive #forms="routerLinkActive" [active]="forms.isActive">eForms</a>
  <a
    mat-tab-link
    routerLink="etreatment-forms"
    routerLinkActive
    #eTreatmentForms="routerLinkActive"
    [active]="eTreatmentForms.isActive"
    >eTreatment Forms</a
  >
</nav>
<div class="content">
  <router-outlet></router-outlet>
</div>
