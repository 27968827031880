import { PaymentProcessorType } from '@models/payments/payment-processor-type.enum';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { isNullOrUndefined } from '@app/shared/helpers';
import { Address } from '@models/address';
import { PaymentStatus } from '@models/appointments/payment-status';
import { Clinic } from '@models/clinic';
import { Doctor } from '@models/doctor';
import { Patient } from '@models/patient';
import { Pharmacy } from '@models/pharmacy';
import { PreviousTreatment } from '@models/treatment-planning/previous-treatment';
import { ActionPanelService } from '@services/actionpanel.service';
import { AppointmentService } from '@services/appointments.service';
import { BreakpointService } from '@services/breakpoint.service';
import { ClinicsService } from '@services/clinics.service';
import { DoctorsService } from '@services/doctors.service';
import { FormatterService } from '@services/formatter.service';
import { PatientService } from '@services/patient.service';
import { PharmaciesService } from '@services/pharmacies.service';
import { ValidationService } from '@services/validation.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-patient-profile-tab',
  templateUrl: './patient-profile-tab.component.html',
  styleUrls: ['./patient-profile-tab.component.less'],
})
export class PatientProfileTabComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  get thePatient(): Patient {
    return this.patientService.patientPanelPatient;
  }
  set thePatient(value: Patient) {
    this.patientService.patientPanelPatient = value;
    this.patientService.thePatientHasBeenUpdated(value);
  }

  @ViewChild('panelContainer', { static: true }) panelContainer: ElementRef;

  panelHeight: number;
  editPatient: FormGroup;
  patientIdParam = '0';
  patientPanelVisible = false;
  addOrEdit = 'Add';
  loading = false;
  disableGrid = false;
  mobileView = true;
  showNewSocialHistoryField = false;
  boolChoices: string[] = ['Yes', 'No'];
  communicationPreferences: string[] = ['Email', 'Phone', 'Text', 'None'];
  genders: string[] = ['Male', 'Female', 'X'];
  isNew: boolean;
  selectedPatient: Patient;
  editedPatient: Patient;
  selectedAddress: Address;
  editedAddress: Address;
  patientInfoEditable = false;
  countriesOfTheWorld: string[] = [];
  provincesAndStates: string[] = [];
  maxBirthdate = new Date();
  editIsEnabled = false;
  thePharmacies: Pharmacy[];
  theDoctors: Doctor[];
  unsub: Subject<void> = new Subject<void>();
  previousAppointments: PreviousTreatment[];
  futureAppointments: PreviousTreatment[];
  errorMessage: '';
  patientId: number;
  cardSwipeError;

  AppointmentPaymentStatus = PaymentStatus;
  clinic: Clinic;

  PaymentProcessorType = PaymentProcessorType;

  constructor(
    private validationService: ValidationService,
    private pharmaciesService: PharmaciesService,
    private doctorsService: DoctorsService,
    private fb: FormBuilder,
    public patientService: PatientService,
    public formatterService: FormatterService,
    private appointmentService: AppointmentService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private actionPanelService: ActionPanelService,
    private breakpointService: BreakpointService,
    private clinicsService: ClinicsService
  ) {}

  ngOnInit() {
    this.breakpointService.mobileBreakpoint$.pipe(takeUntil(this.unsub)).subscribe((mobileView) => {
      this.mobileView = mobileView;
    });

    this.patientId = +this.route.snapshot.params.patId.split('_')[0];
    if (this.clinicsService.clinic) {
      this.clinic = this.clinicsService.clinic;
    }
    this.actionPanelService
      .actionPanelVisible()
      .pipe(takeUntil(this.unsub))
      .pipe(take(1))
      .subscribe(() => {
        this.adjustComponentHeights();
      });

    this.patientService.thePatientUpdated$.pipe(takeUntil(this.unsub)).subscribe((patient) => {
      this.thePharmacies = [];
      this.pharmaciesService.getPharmacies().subscribe((pharms) => {
        this.thePharmacies = pharms;
      });

      this.theDoctors = [];
      this.doctorsService.getDoctors().subscribe((docs) => {
        this.theDoctors = docs;
      });
    });
    // load the previous visits
    this.getPreviousTreatmentAppointments();
    this.getFutureTreatmentAppointments();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.adjustComponentHeights();
  }

  ngAfterViewInit() {
    this.adjustComponentHeights();
  }

  public getBirthday() {
    return moment(this.thePatient.birthDate).format('YYYY-MM-DD');
  }

  public getAge() {
    const today = new Date();
    const birthDate = new Date(this.thePatient.birthDate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age.toString();
  }

  public getGender() {
    if (this.thePatient.gender === 'Female' || this.thePatient.gender === 'F') {
      return 'F';
    } else if (this.thePatient.gender === 'Male' || this.thePatient.gender === 'M') {
      return 'M';
    }
    return this.thePatient.gender;
  }

  public formatVisitDate(treatment: PreviousTreatment) {
    return moment(treatment.date).add(moment.duration(treatment.startTime)).format('YYYY-MM-DD h:mma');
  }

  public formatCancellationDate(treatment: PreviousTreatment) {
    if (treatment.cancellationDate === null) {
      return '';
    }

    return moment(treatment.cancellationDate).format('YYYY-MM-DD h:mma');
  }

  public getChargeAmount(treatment: PreviousTreatment) {
    if (
      treatment.cancellationCharge &&
      treatment.reasonCancellationNotCharged &&
      treatment.reasonCancellationNotCharged != ''
    ) {
      return `$0 / $${treatment.cancellationCharge}`;
    } else return `$${treatment.cancellationCharge}`;
  }

  public editPatientInfo() {
    this.initForm();
    this.editIsEnabled = !this.editIsEnabled;
  }

  public savePatientInfo() {
    if (this.editPatient.valid) {
      if (isNullOrUndefined(this.editPatient.value.preferredPharmacy.pharmacyId)) {
        this.editPatient.value.preferredPharmacy = null;
      }
      if (isNullOrUndefined(this.editPatient.value.familyPhysician.doctorId)) {
        this.editPatient.value.familyPhysician = null;
      }
      this.thePatient = Object.assign(this.thePatient, this.editPatient.value);
      this.patientService.updatePatient(this.thePatient).subscribe(
        () => {
          this.editIsEnabled = !this.editIsEnabled;
        },
        (error) => {
          if (error.error && error.error.errors) {
            const errors = error.error.errors;
            const formControl = this.editPatient.get(errors[0].field);
            formControl.setErrors({ serverError: true });
            formControl.markAsTouched();
            this.errorMessage = errors[0].fieldErrors[0];
          }
        }
      );
    }
  }

  private initForm() {
    const doctorId = this.thePatient.familyPhysician ? this.thePatient.familyPhysician.doctorId : null;
    const pharmacyId = this.thePatient.preferredPharmacy ? this.thePatient.preferredPharmacy.pharmacyId : null;
    this.editPatient = this.fb.group({
      mspCardSwipe: [],
      clientId: [this.thePatient.clientId, this.validationService.validateMSPNumber],
      firstName: [this.thePatient.firstName],
      lastName: [this.thePatient.lastName],
      nickname: [this.thePatient.nickName],
      birthDate: [new Date(this.thePatient.birthDate), this.validationService.validateDate],
      gender: [this.thePatient.gender],
      address: this.fb.group({
        address1: [this.thePatient.address.address1],
        address2: [this.thePatient.address.address2],
        city: [this.thePatient.address.city],
        country: [this.thePatient.address.country],
        postalCode: [this.thePatient.address.postalCode],
        province: [this.thePatient.address.province],
      }),
      homeNumber: [this.thePatient.homeNumber, this.validationService.validatePhoneNumber],
      mobileNumber: [this.thePatient.mobileNumber, this.validationService.validatePhoneNumber],
      email: [this.thePatient.email, Validators.email],
      isPreferred: isNullOrUndefined(this.thePatient.isPreferred) ? false : [this.thePatient.isPreferred],
      familyPhysician: this.fb.group({ doctorId: [doctorId] }),
      preferredPharmacy: this.fb.group({ pharmacyId: [pharmacyId] }),
      sendAppointmentEmailNotifications: [this.thePatient.sendAppointmentEmailNotifications ? true : false],
      sendAppointmentSMSNotifications: [this.thePatient.sendAppointmentSMSNotifications ? true : false],
      sendRetentionEmails: [this.thePatient.sendRetentionEmails ? true : false],
      communicationPreference: [this.thePatient.communicationPreference],
    });

    this.editPatient.markAllAsTouched();
  }

  unsubscribedFromEmailNotificationsChanged(event) {
    event.preventDefault();
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '250px',
      data: {
        showCancel: true,
        title: 'Warning',
        content:
          'The patient unsubscribed from Email Appointment Reminders, are you sure you would like to re-subscribe them?',
        confirmButtonText: 'Yes',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.editPatient.controls['unsubscribedFromEmailNotifications'].patchValue(
          !this.editPatient.controls['unsubscribedFromEmailNotifications'].value
        );
      }
    });
  }

  public updateValue(formName: string) {
    if (formName === 'mobileNumber' || formName === 'homeNumber') {
      this.formatterService.formatPhoneNumber(this.editPatient.get(formName));
    } else {
      this.formatterService.textCapitalize(this.editPatient.get(formName));
    }
  }

  private getPreviousTreatmentAppointments() {
    this.loading = true;
    this.appointmentService
      .getTreatmentAppointmentsInDateRange(this.patientId, undefined, undefined, new Date(new Date().toDateString()))
      .pipe(takeUntil(this.unsub))
      .subscribe((appointments: PreviousTreatment[]) => {
        this.previousAppointments = appointments.filter((appt) => {
          let startTime = moment(appt.date).startOf('day').add(moment.duration(appt.startTime)).toDate();
          let curTime = new Date();

          return startTime <= curTime;
        });
        this.loading = false;
      });
  }

  private getFutureTreatmentAppointments() {
    this.loading = true;
    this.appointmentService
      .getTreatmentAppointmentsInDateRange(this.patientId, undefined, new Date(new Date().toDateString()))
      .pipe(takeUntil(this.unsub))
      .subscribe((appointments: PreviousTreatment[]) => {
        this.futureAppointments = appointments
          .filter((appt) => {
            let startTime = moment(appt.date).startOf('day').add(moment.duration(appt.startTime)).toDate();
            let curTime = new Date();

            return startTime > curTime;
          })
          .reverse();
        this.loading = false;
      });
  }

  public savePatientNotes(event: string) {
    this.thePatient.notesAndAlerts = event;
    this.patientService
      .updatePatientNotesAlerts({
        patientId: this.thePatient.patientId,
        notesAndAlerts: event,
      })
      .subscribe();

    this.editIsEnabled = false;
  }

  private adjustComponentHeights() {
    if (this.panelContainer !== null && this.panelContainer !== undefined) {
      this.panelHeight = this.panelContainer.nativeElement.offsetHeight; //50px padding top/bottom and 16px padding between
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
