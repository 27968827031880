<kendo-pdf-export #pdf paperSize="A4" [scale]="1">
  <kendo-pdf-export-margin top="1cm" left="0.5cm" right="0.5cm" bottom="1cm"> </kendo-pdf-export-margin>
  <div class="row mx-2 font-size-11">
    <div *ngIf="clinic?.logoUrl" class="col-4 px-0">
      <img src="{{ clinic.logoUrl }}" class="clinic-logo" alt="clinic logo" />
    </div>
    <div class="col-8 d-flex">
      <div class="col-5 px-0">
        <div *ngIf="prescription[0].createdByUser?.firstName; else currentUserName">
          <i class="fal fa-user-md py-2 px-2"></i> {{ prescription[0].createdByUser?.firstName }}
          {{ prescription[0].createdByUser?.lastName }}
        </div>
        <ng-template #currentUserName>
          <div *ngIf="currentUser?.firstName">
            <i class="fal fa-user-md py-2 px-2"></i> {{ currentUser.firstName }} {{ currentUser.lastName }}
          </div>
        </ng-template>
        <div *ngIf="prescription[0].createdByUser?.provincialBillingNumber; else userProvincialId">
          <i class="fal fa-cart-plus py-2 px-2"></i> MSP #:
          {{
            prescription[0].createdByUser?.provincialBillingNumber
              ? prescription[0].createdByUser?.provincialBillingNumber
              : '-'
          }}
        </div>
        <ng-template #userProvincialId>
          <div *ngIf="currentUser?.provincialBillingNumber">
            <i class="fal fa-cart-plus py-2 px-2"></i> MSP #: {{ currentUser.provincialBillingNumber }}
          </div>
        </ng-template>
        <div *ngIf="prescription[0].createdByUser?.medicalLicenseNumber; else userCpsId">
          <i class="fal fa-heartbeat py-2 px-2"></i> CPSBC:
          {{
            prescription[0].createdByUser?.medicalLicenseNumber
              ? prescription[0].createdByUser?.medicalLicenseNumber
              : '-'
          }}
        </div>
        <ng-template #userCpsId>
          <div *ngIf="currentUser?.medicalLicenseNumber">
            <i class="fal fa-cart-plus py-2 px-2"></i> CPSBC: {{ currentUser.medicalLicenseNumber }}
          </div>
        </ng-template>
        <div *ngIf="clinic?.phoneNumber">
          <i class="fal fa-phone py-2 px-2"></i> {{ clinic.phoneNumber ? (clinic.phoneNumber | phoneNumber) : '-' }}
        </div>
        <div *ngIf="clinic?.faxNumber">
          <i class="far fa-fax py-2 px-2"></i> {{ clinic.faxNumber ? (clinic.faxNumber | phoneNumber) : '-' }}
        </div>
      </div>
      <div class="col-7 px-0">
        <div *ngIf="clinic?.name"><i class="fal fa-first-aid py-2 px-2"></i> {{ clinic.name ? clinic.name : '-' }}</div>
        <div *ngIf="clinic?.email">
          <i class="fal fa-envelope py-2 px-2"></i> {{ clinic.email ? clinic.email : '-' }}
        </div>
        <div *ngIf="clinic?.website">
          <i class="fal fa-globe py-2 px-2"></i> {{ clinic.website ? clinic.website : '-' }}
        </div>
        <div *ngIf="clinic?.address">
          <i class="far fa-map-marker-alt py-2 px-2"></i>
          <ng-container *ngIf="clinic?.address">
            <div style="display: inline-flex; display: -webkit-inline-box">
              {{ clinic?.address?.address1 ? clinic?.address?.address1 + ', ' : '' }}
              {{ clinic?.address?.address2 ? clinic?.address?.address2 + ', ' : '' }}<br />
              {{ clinic?.address?.city }}, {{ clinic?.address?.province }}
            </div>
          </ng-container>
          <ng-container *ngIf="!clinic?.address"> - </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="row border-top mx-2">
    <div class="col-8 px-0 pt-2">
      <div class="font-weight-bold py-1">{{ patient.firstName }} {{ patient.lastName }}</div>
      <div class="py-1">
        {{ patient.address.city }}
        <ng-container *ngIf="patient.address.city.length > 0 && patient.address.province.length > 0">, </ng-container>
        {{ patient.address.province }}
      </div>
      <div class="py-1">
        {{ patient.mobileNumber ? patient.mobileNumber : '-' }}
      </div>
    </div>
    <div class="col-4 px-0 pt-2">
      <div class="font-weight-bold py-1">
        {{ prescription.length > 1 ? getCurrentDayStr() : getPrescriptionDateStr(prescription[0].createdDate) }}
      </div>
      <div class="font-weight-bold py-1">{{ patient.clientId }}</div>
    </div>
  </div>
  <div class="row border-top mx-2" *ngFor="let item of prescription">
    <div>
      <div class="py-2 font-weight-bold">{{ item.drugName }}</div>
      <div class="py-2"><span class="font-weight-bold">SIG: </span>{{ item.sig ? item.sig : '-' }}</div>
      <div class="py-2">
        <span class="font-weight-bold">QTY/Mitte: </span> {{ item.quantity ? item.quantity : '-' }}
      </div>
      <div class="py-2">
        <span class="font-weight-bold">Repeats: </span>{{ item.refillCount ? item.refillCount : '-' }}
      </div>
      <div *ngIf="item?.isQhs || item?.isPrn || item?.isNoSubs" class="py-2 font-weight-bold">
        <span *ngIf="item?.isQhs" class="mr-1">QHS</span>
        <span *ngIf="item?.isPrn" class="mr-1">PRN</span>
        <span *ngIf="item?.isNoSubs" class="mr-1">No Subs</span>
      </div>
    </div>
  </div>
  <div class="row mx-2 pt-1 flex-column">
    <div class="d-flex">
      <span class="font-weight-bold">Signature </span>
      <div class="border-bottom flex-grow-1">
        <!-- <img class="signature" *ngIf="signatureUrl" [src]="signatureUrl" /> -->
        <img class="signature" *ngIf="prescription[0].signature?.filePath; else storageSignature" [src]="prescription[0].signature.filePath" />
        <ng-template #storageSignature>
          <img class="signature" *ngIf="signatureUrl" [src]="signatureUrl" />
        </ng-template>
      </div>
    </div>
    <div class="ml-5 mt-1">
      {{ prescription[0].createdByUser?.firstName }} {{ prescription[0].createdByUser?.lastName }}
    </div>
    <div class="ml-5 mt-1">{{ prescription[0].createdByUser?.medicalLicenseNumber }}</div>
  </div>
</kendo-pdf-export>
