import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Pharmacy } from '@models/pharmacy';
import { PharmaciesService } from '@services/pharmacies.service';

@Component({
  selector: 'app-prescription-fax-modal',
  templateUrl: './prescription-fax-modal.component.html',
  styleUrls: ['./prescription-fax-modal.component.less'],
})
export class PrescriptionFaxModalComponent implements OnInit {
  pharmacies: Pharmacy[];
  pharmacyId = new FormControl('', [Validators.required]);
  pharmacyMethod = new FormControl('', [Validators.required]);
  hasError: boolean;
  errorMessage: string;

  constructor(
    private pharmaciesService: PharmaciesService,
    public dialogRef: MatDialogRef<PrescriptionFaxModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.pharmacyMethod.valueChanges.subscribe((val) => {
      if (val === 'alternative') {
        this.pharmacyId.enable();
        if (this.pharmacyId.value) {
          this.hasError = !this.pharmacies.find((e) => e.pharmacyId === this.pharmacyId.value).faxNumber;
          this.errorMessage = 'The selected pharmacy does not have a fax number.';
        } else {
          this.pharmacyId.setValue(this.pharmacies[0]?.pharmacyId);
        }
      } else {
        this.pharmacyId.disable();
        if (this.data.patient.preferredPharmacy && this.data.patient.preferredPharmacy.faxNumber) {
          this.hasError = !this.data.patient.preferredPharmacy.faxNumber;
          this.errorMessage = 'The selected pharmacy does not have a fax number.';
        } else {
          this.hasError = !this.data.patient.preferredPharmacy;
          this.errorMessage = "This patient doesn't have a preferred pharmacy selected.";
        }
      }
    });
    this.pharmacyId.valueChanges.subscribe((value) => {
      if (value) {
        this.hasError = !this.pharmacies.find((e) => e.pharmacyId === value).faxNumber;
        this.errorMessage = 'The selected pharmacy does not have a fax number.';
      }
    });
    this.pharmaciesService.getPharmacies().subscribe((pharmacies) => {
      this.pharmacies = pharmacies;
      this.pharmacyMethod.setValue('default');
    });
  }

  // preferredPharmacyChanged(event) {
  //   if (event.value) {
  //     this.hasError = !this.pharmacies.find((e) => e.pharmacyId === event.value).faxNumber;
  //     this.errorMessage = 'Selected Pharmacy does not contain a fax number.';
  //   }
  // }

  getFaxNumber() {
    let faxNumber =
      this.pharmacyMethod.value === 'default'
        ? this.data.patient.preferredPharmacy.faxNumber
        : this.pharmacies.find((e) => e.pharmacyId === this.pharmacyId.value).faxNumber;
    faxNumber = faxNumber.split('-').join('');
    return faxNumber;
  }
}
