import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Policies } from '@app/auth/auth-policies';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { EditChannelComponent } from '@app/messaging/dialogs/edit-channel/edit-channel.component';
import { ChatChannelsService } from '@app/messaging/services/chat-channels.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-messaging-header',
  templateUrl: './messaging-header.component.html',
  styleUrls: ['./messaging-header.component.less'],
})
export class MessagingHeaderComponent implements OnInit {
  loading = false;
  messagingPolicy = Policies.messaging;

  constructor(
    private modalService: NgbModal,
    private matDialog: MatDialog,
    public channelService: ChatChannelsService
  ) {}

  ngOnInit() {}

  onEditChannel() {
    const editModal = this.modalService.open(EditChannelComponent);
    editModal.componentInstance.channel = this.channelService.selectedChannel;
  }

  onRemoveChannel() {
    const confirmModal = this.matDialog.open(GenericDialogComponent, {
      data: {
        title: 'Remove Channel?',
        content: this.channelService.selectedChannel?.private
          ? ''
          : 'This action can not be undone. All chat messages in the channel will be removed.',
        confirmButtonText: 'Confirm',
      },
    });
    confirmModal.afterClosed().subscribe((result) => {
      if ((result = 'confirm')) {
        this.loading = true;
        this.channelService
          .closeChannel(this.channelService.selectedChannel.id)
          .subscribe(() => (this.loading = false));
      }
    });
  }
}
