import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-patient-merge',
  templateUrl: './confirm-patient-merge.component.html',
  styleUrls: ['./confirm-patient-merge.component.less']
})
export class ConfirmMergePatientComponent {

  public dialog: MatDialog;

  constructor(
    public dialogRef: MatDialogRef<ConfirmMergePatientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.data.result = 'cancel';
  }

}
