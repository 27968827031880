import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { IReportComponent } from '@models/reports/ireport-component';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { DataSourceRequestState, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Tax } from '@models/reports/tax/tax';
import { Subject, Observable } from 'rxjs';
import { ReportsService } from '@services/reports.service';
import { takeUntil, map } from 'rxjs/operators';
import { CurrencyPipe } from '@angular/common';
import { ServiceReport } from '@models/reports/service/service-report';

@Component({
  selector: 'app-service-reports',
  templateUrl: './service-reports.component.html',
  styleUrls: ['./service-reports.component.less'],
})
export class ServiceReportsComponent implements OnInit, OnDestroy, IReportComponent {
  @ViewChild('grid') grid;
  gridView: GridDataResult;
  gridState: DataSourceRequestState;
  loading: boolean = false;
  possibleTaxes: Tax[] = [];
  unsub: Subject<void> = new Subject<void>();

  constructor(private reportsService: ReportsService, private currencyPipe: CurrencyPipe) {}

  ngOnInit() {
    this.initDefaultValue();
    this.getPossibleTaxes();
    this.getReportData();
    this.allData = this.allData.bind(this);
  }

  initDefaultValue() {
    this.gridState = {
      skip: 0,
      take: 100,
      // set default state of sorting icon
      sort: [{ field: 'serviceName', dir: 'asc' }],
    };

    this.gridView = {
      data: [],
      total: 0,
    };
  }

  getPossibleTaxes() {
    this.reportsService
      .getServicePossibleTaxes()
      .pipe(takeUntil(this.unsub))
      .subscribe((taxes: Tax[]) => {
        taxes.forEach((tax: Tax) => {
          if (!this.possibleTaxes.some((possibleTax: Tax) => possibleTax.id === tax.id)) {
            this.possibleTaxes.push(tax);
          }
        });
      });
  }

  getReportData() {
    this.loading = true;

    this.reportsService
      .getServicesReportEntities(this.gridState)
      .pipe(takeUntil(this.unsub))
      .subscribe((serviceReports: any) => {
        this.gridView = {
          data: serviceReports.data,
          total: serviceReports.total || serviceReports.data.length,
        };
        this.loading = false;
      });
  }

  exportToExcel() {
    this.grid.saveAsExcel();
  }

  onExcelExport(e: any) {
    this.reportsService.onExcelExport(e, 'Services Report');
  }

  pageChange(gridState: DataStateChangeEvent) {
    this.gridState = gridState;
    this.getReportData();
  }

  sortChange(sort: SortDescriptor[]) {
    this.gridState.sort = sort;
    this.gridView.data = orderBy(this.gridView.data, this.gridState.sort);
  }

  sortIconClass(column: any) {
    return this.gridState.sort.length &&
      this.gridState.sort[0].field === column.field &&
      this.gridState.sort[0].dir === 'asc'
      ? 'fad fa-sort-up'
      : this.gridState.sort.length &&
        this.gridState.sort[0].field === column.field &&
        this.gridState.sort[0].dir === 'desc'
      ? 'fad fa-sort-down'
      : 'fas fa-sort';
  }

  allData = (): Observable<any> => {
    const gridState: DataSourceRequestState = {};
    return this.reportsService.getServicesReportEntities(gridState).pipe(
      map((serviceReports: any) => {
        return {
          data: serviceReports.data.map((element: ServiceReport) => {
            this.possibleTaxes.forEach((item) => {
              element[item.name] = this.currencyPipe.transform(element.taxCollected[item.name]);
            });
            return {
              ...element,
              salesAmount: this.currencyPipe.transform(element.salesAmount),
            };
          }),
          total: serviceReports.total,
        };
      })
    );
  };

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
