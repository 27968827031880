export class Address {
    addressId?: number;
    address1: string;
    address2: string;
    city: string;
    country: string;
    postalCode: string;
    province: string;

    constructor(init?: Partial<Address>) {
        Object.assign(this, init);
    }
}
