<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>

<div
  *ngIf="thePatient"
  class="row h-100 panel-container"
  [ngClass]="{ 'py-2 mx-2': mobileView, 'panel-container': !mobileView }"
  kendoTooltip
  tooltipClass="em-tooltip"
  position="top"
>
  <!-- Left Column - Patient Details -->
  <div class="h-100 patient-info" [ngClass]="{ 'col p-0 border border-radius': mobileView, 'col-3': !mobileView }">
    <ng-scrollbar class="h-100">
      <app-patient-profile-detail [patientId]="thePatient.patientId"></app-patient-profile-detail>
    </ng-scrollbar>
  </div>

  <!-- Center Column -->
  <div *ngIf="!mobileView" class="col-6 h-100 d-flex flex-column">
    <div class="panel-item-border panel-flex-column mh-50 mb-2">
      <div class="panel-flex-row px-3">
        <div class="column-header">Future Appointments</div>
      </div>
      <div *ngIf="!(futureAppointments?.length > 0)" class="px-3 pb-2">No Future Appointments</div>

      <ng-scrollbar *ngIf="futureAppointments?.length > 0" [autoHeightDisabled]="false" class="pb-2">
        <div class="px-2">
          <mat-accordion [multi]="true" class="appointments-list">
            <mat-expansion-panel
              class="mb-2 expansion-panel-margin"
              *ngFor="let item of futureAppointments"
              [expanded]="false"
              [disabled]="true"
              #panelFutureAppointments
            >
              <mat-expansion-panel-header
                class="px-0 py-0"
                [ngClass]="{ cancelled: item?.isCancelled }"
                [ngClass]="{ pointer: item?.isCancelled }"
                (click)="panelFutureAppointments.expanded = !panelFutureAppointments.expanded"
              >
                <mat-panel-title class="grey-two-background">
                  <div class="service-chart-color" [ngStyle]="{ background: item.serviceIdColour }"></div>
                  <div class="p-2 pl-3 navy-font panel-box-column panel-box-column--main">
                    <div class="content-row">
                      <div>
                        <div>
                          <span class="bigger font-weight-bold">{{ item.serviceName }}</span
                          ><span
                            *ngIf="item.associatedPlannedTreatment?.isPlannedTreatmentMultiple"
                            class="ml-2 medium navy-font"
                            >({{ item.associatedPlannedTreatment.rank }} of
                            {{ item.associatedPlannedTreatment.plannedTreatmentMultipleQuantity }})</span
                          >
                        </div>
                        <div>
                          <span class="medium navy-font">{{ formatVisitDate(item) }}</span>
                        </div>
                      </div>
                      <div class="medium navy-font">{{ item.serviceProviderName }}</div>
                      <div class="w-100"></div>
                      <div
                        *ngIf="!item.isCancelled"
                        class="profile-currency bigger font-weight-bold"
                        [attr.data-test-id]="'appointment-' + item.appointmentId + '-price'"
                        [attr.data-test-value]="item?.price | currency"
                        [ngClass]="{
                          'calendar-green-font': item?.paymentStatus == AppointmentPaymentStatus.Paid,
                          'calendar-red-font': item?.paymentStatus == AppointmentPaymentStatus.Unpaid,
                          'purple-font': item?.paymentStatus == AppointmentPaymentStatus.Refund
                        }"
                      >
                        {{ item.price | currency }}
                      </div>
                      <div *ngIf="item.isCancelled" class="navy-font panel-box-column panel-box-column--main">
                        <div>
                          <span
                            class="bigger font-weight-bold"
                            [ngClass]="{
                              'calendar-green-font': item?.paymentStatus == AppointmentPaymentStatus.Paid,
                              'calendar-red-font':
                                item?.reasonCancellationNotCharged && item?.reasonCancellationNotCharged != ''
                            }"
                            >CANCELLED</span
                          >
                          <button
                            *ngIf="item?.isCancelled"
                            type="button"
                            class="btn btn--smaller pl-4 py-0 float-right"
                          >
                            <mat-icon>{{
                              panelFutureAppointments.expanded ? 'arrow_drop_up' : 'arrow_drop_down'
                            }}</mat-icon>
                          </button>
                        </div>
                        <div *ngIf="item.isCancelled" class="medium navy-font">
                          <span *ngIf="item.cancellationReason && item.cancellationReason != ''"
                            >({{ item.cancellationReason }} {{ item.cancellationMessage }})</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div *ngIf="item.isCancelled" class="panel-box-content pr-2 py-3 border-0">
                <div class="cancelled-content-row medium">
                  <div class="pr-1">Cancelled Date: {{ formatCancellationDate(item) }}</div>
                  <div class="pr-1">Cancelled By: {{ item.cancelledByUserName }}</div>
                  <div class="w-100">
                    <span
                      *ngIf="item?.reasonCancellationNotCharged && item?.reasonCancellationNotCharged != ''"
                      class="calendar-red-font"
                      >Reason: {{ item?.reasonCancellationNotCharged }}</span
                    >
                  </div>
                  <div class="w-100">
                    <span
                      *ngIf="item?.cancellationCharge"
                      [ngClass]="{
                        'calendar-green-font': item?.paymentStatus == AppointmentPaymentStatus.Paid,
                        'calendar-red-font':
                          item?.reasonCancellationNotCharged && item?.reasonCancellationNotCharged != ''
                      }"
                      >Charge amount: {{ getChargeAmount(item) }}</span
                    >
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </ng-scrollbar>
    </div>
    <div class="panel-item-border panel-flex-column flex-grow-1">
      <div class="panel-flex-row px-3">
        <div class="column-header">Previous Appointments</div>
      </div>
      <div *ngIf="!(previousAppointments?.length > 0)" class="px-3 pb-2">No Previous Appointments</div>
      <div *ngIf="previousAppointments?.length > 0" class="flex-grow-1 minh-0">
        <ng-scrollbar class="h-100">
          <div class="px-2">
            <mat-accordion [multi]="true" class="appointments-list">
              <mat-expansion-panel
                class="mb-2 expansion-panel-margin"
                *ngFor="let item of previousAppointments"
                [expanded]="false"
                [disabled]="true"
                #panelPreviousAppointments
              >
                <mat-expansion-panel-header
                  class="px-0 py-0"
                  [ngClass]="{ cancelled: item?.isCancelled }"
                  [ngClass]="{ pointer: item?.isCancelled }"
                  (click)="panelPreviousAppointments.expanded = !panelPreviousAppointments.expanded"
                >
                  <mat-panel-title class="grey-two-background">
                    <div class="service-chart-color" [ngStyle]="{ background: item.serviceIdColour }"></div>
                    <div class="p-2 pl-3 navy-font panel-box-column panel-box-column--main">
                      <div class="content-row">
                        <div>
                          <div>
                            <span class="bigger font-weight-bold">{{ item.serviceName }}</span
                            ><span
                              *ngIf="item.associatedPlannedTreatment?.isPlannedTreatmentMultiple"
                              class="ml-2 medium navy-font"
                              >({{ item.associatedPlannedTreatment.rank }} of
                              {{ item.associatedPlannedTreatment.plannedTreatmentMultipleQuantity }})</span
                            >
                          </div>
                          <div>
                            <span class="medium navy-font">{{ formatVisitDate(item) }}</span>
                          </div>
                        </div>
                        <div class="medium navy-font">{{ item.serviceProviderName }}</div>
                        <div class="w-100"></div>
                        <div
                          *ngIf="!item.isCancelled"
                          class="profile-currency bigger font-weight-bold"
                          [attr.data-test-id]="'appointment-' + item.appointmentId + '-price'"
                          [attr.data-test-value]="item?.price | currency"
                          [ngClass]="{
                            'calendar-green-font': item?.paymentStatus == AppointmentPaymentStatus.Paid,
                            'calendar-red-font': item?.paymentStatus == AppointmentPaymentStatus.Unpaid,
                            'purple-font': item?.paymentStatus == AppointmentPaymentStatus.Refund
                          }"
                        >
                          {{ item.price | currency }}
                        </div>
                        <div *ngIf="item.isCancelled" class="navy-font panel-box-column panel-box-column--main">
                          <div>
                            <span
                              class="bigger font-weight-bold"
                              [ngClass]="{
                                'calendar-green-font': item?.paymentStatus == AppointmentPaymentStatus.Paid,
                                'calendar-red-font':
                                  item?.reasonCancellationNotCharged && item?.reasonCancellationNotCharged != ''
                              }"
                              >CANCELLED</span
                            >
                            <button
                              *ngIf="item?.isCancelled"
                              type="button"
                              class="btn btn--smaller pl-4 py-0 float-right"
                            >
                              <mat-icon>{{
                                panelPreviousAppointments.expanded ? 'arrow_drop_up' : 'arrow_drop_down'
                              }}</mat-icon>
                            </button>
                          </div>
                          <div *ngIf="item.isCancelled" class="medium navy-font">
                            <span *ngIf="item.cancellationReason && item.cancellationReason != ''"
                              >({{ item.cancellationReason }} {{ item.cancellationMessage }})</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div *ngIf="item.isCancelled" class="panel-box-content pr-2 py-3 border-0">
                  <div class="cancelled-content-row medium">
                    <div class="pr-1">Cancelled Date: {{ formatCancellationDate(item) }}</div>
                    <div class="pr-1">Cancelled By: {{ item.cancelledByUserName }}</div>
                    <div class="w-100">
                      <span
                        *ngIf="item?.reasonCancellationNotCharged && item?.reasonCancellationNotCharged != ''"
                        class="calendar-red-font"
                        >Reason: {{ item?.reasonCancellationNotCharged }}</span
                      >
                    </div>
                    <div class="w-100">
                      <span
                        *ngIf="item?.cancellationCharge"
                        [ngClass]="{
                          'calendar-green-font': item?.paymentStatus == AppointmentPaymentStatus.Paid,
                          'calendar-red-font':
                            item?.reasonCancellationNotCharged && item?.reasonCancellationNotCharged != ''
                        }"
                        >Charge amount: {{ getChargeAmount(item) }}</span
                      >
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </ng-scrollbar>
      </div>
    </div>
  </div>

  <!-- Right Column -->
  <div *ngIf="!mobileView" class="col-3 h-100">
    <ng-scrollbar class="h-100">
      <app-patient-portal-overview></app-patient-portal-overview>
      <div *ngIf="clinic?.paymentProcessorType == PaymentProcessorType.Square" class="mt-3">
        <app-patient-square-overview [panelHeight]="300" [patientId]="patientId"></app-patient-square-overview>
      </div>
      <div *ngIf="clinic?.paymentProcessorType == PaymentProcessorType.EmilyPay" class="mt-3">
        <app-patient-converge-overview [patientId]="patientId"></app-patient-converge-overview>
      </div>
    </ng-scrollbar>
  </div>
</div>

<app-actionpanel></app-actionpanel>
