import { MembershipClinicTax } from '@models/memberships/membership-clinic-tax';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AddPhotoComponent } from '@app/patients/patient-tabs/patient-photos-tab/add-photo/add-photo.component';
import { ClinicTax } from '@models/clinic';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { TaxService } from '@services/tax.service';
import { Subject, from, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AssignItemsToMembershipComponent } from './dialogs/assign-items-to-membership/assign-items-to-membership.component';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { MembershipService } from '@models/memberships/membership-service';
import { DiscountType } from '@models/memberships/discount-type.enum';
import { MembershipProduct } from '@models/memberships/membership-product';
import { MembershipsService } from '@services/memberships.service';
import { Membership } from '@models/memberships/membership';
import { BlobService } from '@services/blob.service';
import { Tax } from '@models/tax';
import { BillingFrequency } from '@models/memberships/billing-frequency.enum';
import { ItemType } from '@models/memberships/item-type.enum';

@Component({
  selector: 'app-edit-membership',
  templateUrl: './edit-membership.component.html',
  styleUrls: ['./edit-membership.component.less'],
})
export class EditMembershipComponent implements OnInit {
  unsub: Subject<void> = new Subject<void>();
  membership: Membership;
  membershipForm: FormGroup;
  actionType: String;
  isNewMembership: boolean;
  clinicTaxes: ClinicTax[] = [];
  membershipImageUrl: string = '';
  expiresAfterBillingCycles: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  cancellableAfterBillingCycles: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  membershipServices: MembershipService[] = [];
  membershipProducts: MembershipProduct[] = [];
  errorMessage: string;
  readOnlySas: string;
  loading: boolean;

  constructor(
    private catalogueUpdatesService: CatalogueUpdatesService,
    private route: ActivatedRoute,
    private router: Router,
    private taxService: TaxService,
    private modalService: NgbModal,
    private membershipsService: MembershipsService,
    private blobService: BlobService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.taxService.getTaxes().subscribe((taxes) => {
      if (taxes) {
        taxes.forEach((element) => {
          const clinicTax = {
            clinicId: 0,
            clinic: null,
            taxId: element.taxId,
            tax: element,
          };
          this.clinicTaxes.push(clinicTax);
        });
      }
    });

    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      const id = params['id'];
      if (id && id === '_') {
        this.actionType = 'Add';
        this.isNewMembership = true;
        this.membership = new Membership();
        this.loading = false;
      } else {
        var membershipId = Number(id);
        this.actionType = 'Update';
        this.isNewMembership = false;
        // Get Membership by Id
        this.membershipsService
          .getMembershipById(membershipId)
          .pipe(takeUntil(this.unsub))
          .subscribe((membership: Membership) => {
            this.membership = membership;
            this.loadForm();
            this.loading = false;
          });
      }

      this.initForm();
    });
  }

  private initForm() {
    this.membershipForm = new FormGroup({
      id: new FormControl(0),
      isActive: new FormControl(false),
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      amount: new FormControl('', Validators.required),
      selectedTaxes: new FormControl(),
      billingFrequency: new FormControl(BillingFrequency.Monthly),
      expiresAfter: new FormControl(),
      cancellableAfter: new FormControl(),
      allowableRedemptions: new FormControl(),
      percentageAppliedToCredit: new FormControl(),
      expirationOption: new FormControl(0),
      cancellationOption: new FormControl(0),
      redemptionOption: new FormControl(0),
    });

    this.membershipForm.controls['expiresAfter'].disable();
    this.membershipForm.controls['cancellableAfter'].disable();
    this.membershipForm.controls['allowableRedemptions'].disable();
    this.membershipForm?.valueChanges.pipe(takeUntil(this.unsub)).subscribe((res) => {
      if (this.errorMessage) {
        this.errorMessage = '';
      }
    });
  }

  loadForm() {
    if (!this.isNewMembership) {
      var membershipClinicTaxes: MembershipClinicTax[] = [];
      this.membershipForm.controls['id'].setValue(this.membership.id);
      this.membershipForm.controls['name'].setValue(this.membership.name);
      this.membershipForm.controls['description'].setValue(this.membership.description);
      this.membershipForm.controls['amount'].setValue(this.membership.amount);
      this.membership.membershipClinicTaxes.forEach((t) =>
        membershipClinicTaxes.push({
          id: t.id,
          membershipId: t.membershipId,
          taxId: t.taxId,
          tax: t.tax,
        })
      );
      this.membershipForm.controls['selectedTaxes'].setValue(membershipClinicTaxes);
      this.membershipImageUrl =
        this.membership.imageUrl != '' ? this.membership.imageUrl + this.blobService.getReadOnlySAS() : '';
      this.membershipForm.controls['billingFrequency'].setValue(this.membership.billingFrequency);
      this.membershipForm.controls['expiresAfter'].setValue(this.membership.expiresAfter);
      this.membershipForm.controls['cancellableAfter'].setValue(this.membership.cancellableAfter);
      this.membershipForm.controls['allowableRedemptions'].setValue(this.membership.allowableRedemptions);
      this.membershipForm.controls['percentageAppliedToCredit'].setValue(this.membership.percentageAppliedToCredit);
      this.membershipForm.controls['isActive'].setValue(this.membership.isActive);

      if (this.membership.expiresAfter) {
        this.membershipForm.controls['expirationOption'].setValue(1);
        this.membershipForm.controls['expiresAfter'].enable();
      } else this.membershipForm.controls['expirationOption'].setValue(0);

      if (this.membership.cancellableAfter) {
        this.membershipForm.controls['cancellationOption'].setValue(1);
        this.membershipForm.controls['cancellableAfter'].enable();
      } else this.membershipForm.controls['cancellationOption'].setValue(0);
      if (this.membership.allowableRedemptions) {
        this.membershipForm.controls['redemptionOption'].setValue(1);
        this.membershipForm.controls['allowableRedemptions'].enable();
      } else {
        this.membershipForm.controls['redemptionOption'].setValue(0);
      }

      this.membershipServices = this.membership.membershipServices;
      this.membershipProducts = this.membership.membershipProducts;
    }
  }
  onSubmit() {
    this.loading = true;
    if (this.isNewMembership) {
      let membership = this.getMembershipDetails();
      this.membershipsService.addMembership(membership).subscribe(
        () => {
          this.catalogueUpdatesService.refreshRequired = true;
          this.catalogueUpdatesService.catalogueUpdateComplete();
          this.router.navigate(['/management/catalogue/memberships', { outlets: { 'action-panel': null } }]);
        },
        (error) => {
          this.loading = false;
          if (error.error && error.message) {
            this.errorMessage = error.error;
            console.log(error.message);
          } else {
            return throwError(error);
          }
        }
      );
    } else {
      let membership = this.getMembershipDetails();
      this.membershipsService.updateMembership(membership).subscribe(
        () => {
          this.catalogueUpdatesService.refreshRequired = true;
          this.catalogueUpdatesService.catalogueUpdateComplete();
          this.router.navigate(['/management/catalogue/memberships', { outlets: { 'action-panel': null } }]);
        },
        (error) => {
          this.loading = false;
          if (error.error && error.message) {
            this.errorMessage = error.error;
            console.log(error.message);
          } else {
            return throwError(error);
          }
        }
      );
    }
  }

  getMembershipDetails() {
    let membership = new Membership();
    membership.id = this.membershipForm.controls['id'].value;
    membership.name = this.membershipForm.controls['name'].value;
    membership.description = this.membershipForm.controls['description'].value;
    membership.amount = this.membershipForm.controls['amount'].value;

    if (this.membershipForm.controls['selectedTaxes'].value != null) {
      membership.membershipClinicTaxes = [];
      this.membershipForm.controls['selectedTaxes'].value.forEach((st) =>
        membership.membershipClinicTaxes.push({
          id: st.id ? st.id : 0,
          membershipId: membership.id,
          taxId: st.taxId,
          tax: null,
        })
      );
    }
    membership.imageUrl = this.membershipImageUrl.split('?')[0];
    membership.billingFrequency = this.membershipForm.controls['billingFrequency'].value;
    membership.expiresAfter =
      this.membershipForm.controls['expirationOption'].value == 1
        ? this.membershipForm.controls['expiresAfter'].value
        : null;
    membership.cancellableAfter =
      this.membershipForm.controls['cancellationOption'].value == 1
        ? this.membershipForm.controls['cancellableAfter'].value
        : null;
    membership.allowableRedemptions =
      this.membershipForm.controls['redemptionOption'].value == 1
        ? this.membershipForm.controls['allowableRedemptions'].value
        : null;
    membership.percentageAppliedToCredit = this.membershipForm.controls['percentageAppliedToCredit'].value;
    membership.membershipServices = this.membershipServices;
    membership.membershipProducts = this.membershipProducts;
    membership.isActive = this.membershipForm.controls['isActive'].value;
    return membership;
  }

  cancelUpdate() {
    this.catalogueUpdatesService.refreshRequired = false;
    this.catalogueUpdatesService.catalogueUpdateComplete();
    this.router.navigate(['/management/catalogue/memberships', { outlets: { 'action-panel': null } }]);
  }

  compareClinicTaxObjects(o1: any, o2: any): boolean {
    return o1?.tax?.name === o2?.tax?.name && o1?.tax?.taxId === o2?.tax?.taxId;
  }

  onRemoveImageUrl() {
    this.membershipImageUrl = '';
  }

  onAddPhoto() {
    const modalRef = this.modalService.open(AddPhotoComponent, { windowClass: 'add-photo-modal', centered: true });
    (modalRef.componentInstance as AddPhotoComponent).isClinicPhoto = true;
    (modalRef.componentInstance as AddPhotoComponent).showGallery = true;
    (modalRef.componentInstance as AddPhotoComponent).singleImageSelect = true;
    modalRef.result
      .then((result: PhotoMetaData) => {
        const photoPathWithoutSAS = result.filePath.split('?')[0];
        this.membershipImageUrl = result.filePath;
      })
      .catch(() => {
        // user exited the modal without selecting a photo
      });
  }

  onAddServices() {
    const assignModal = this.modalService.open(AssignItemsToMembershipComponent, {
      centered: true,
      windowClass: 'assign-modal',
    });
    assignModal.componentInstance.membershipId = this.membership?.id;
    assignModal.componentInstance.itemType = ItemType.Services;
    assignModal.componentInstance.discountValue = this.membership?.membershipServices[0]?.amount
      ? this.membership?.membershipServices[0]?.amount
      : 10;
    let clonedMembershipServices = Object.assign([], this.membershipServices);
    assignModal.componentInstance.selectedMembershipServices = clonedMembershipServices;
    assignModal.result.then((membershipServices) => {
      if (membershipServices) {
        this.membershipServices = membershipServices;
        this.membershipForm.markAsDirty();
      }
    });
  }

  onAddProducts() {
    const assignModal = this.modalService.open(AssignItemsToMembershipComponent, {
      centered: true,
      windowClass: 'assign-modal',
    });
    assignModal.componentInstance.membershipId = this.membership?.id;
    assignModal.componentInstance.itemType = ItemType.Products;
    assignModal.componentInstance.discountValue = this.membership?.membershipProducts[0]?.amount
      ? this.membership?.membershipProducts[0]?.amount
      : 10;
    let clonedMembershipProducts = Object.assign([], this.membershipProducts);
    assignModal.componentInstance.selectedMembershipProducts = clonedMembershipProducts;
    from(assignModal.result).subscribe((membershipProducts) => {
      if (membershipProducts) {
        // Remove any membership products that are no longer selected
        let unselectedMemberProducts: any[] = [];
        this.membershipProducts.forEach((mp) => {
          if (membershipProducts.findIndex((m) => m.clinicProduct.id === mp.clinicProduct.id) < 0) {
            unselectedMemberProducts.push(mp.clinicProduct.id);
          }
        });
        unselectedMemberProducts.forEach((id) => {
          this.membershipProducts.splice(
            this.membershipProducts.findIndex((mp) => mp.clinicProduct.id === id),
            1
          );
        });
        this.membershipProducts = membershipProducts;
        this.membershipProducts.sort((a, b) => (a.clinicProduct.displayName < b.clinicProduct.displayName ? -1 : 1));
        this.membershipForm.markAsDirty();
      }
    });
  }

  formatDiscount(membershipService: MembershipService): string {
    if (membershipService.discountType == DiscountType.Dollars) {
      return membershipService.amount.toLocaleString(undefined, {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
      });
    } else {
      return (membershipService.amount / 100).toLocaleString(undefined, {
        style: 'percent',
        maximumFractionDigits: 1,
      });
    }
  }

  disableExpiration() {
    this.membershipForm.controls['expiresAfter'].setValue(null);
    this.membershipForm.controls['expiresAfter'].disable();
  }
  enableExpiration() {
    this.membershipForm.controls['expiresAfter'].enable();
  }

  disableCancellation() {
    this.membershipForm.controls['cancellableAfter'].setValue(null);
    this.membershipForm.controls['cancellableAfter'].disable();
  }
  enableCancellation() {
    this.membershipForm.controls['cancellableAfter'].enable();
  }

  disableRedemption() {
    this.membershipForm.controls['allowableRedemptions'].setValue(null);
    this.membershipForm.controls['allowableRedemptions'].disable();
  }
  enableRedemption() {
    this.membershipForm.controls['allowableRedemptions'].enable();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
