import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CurrentDataService } from '@services/currentData.service';
import { ServicesService } from '@services/services.service';
import { ServiceListType } from '@models/service/service-list-type';
import { Service } from '@models/service/service';
import { AppointmentService } from '@services/appointments.service';
import { PreviousTreatment } from '@models/treatment-planning/previous-treatment';
import { UsersService } from '@services/users.service';
import { FavouriteServicesService } from '@services/favourite-services.service';
import { UserFavouriteService } from '../../models/service/user-favourite-service';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.less'],
})
export class ServiceListComponent implements OnInit, OnDestroy {
  @Input() listtype: ServiceListType;
  @Input() serviceTemplateId: number;
  @Input() serviceDetailTemplateId: number;
  @Input() patientId: number;
  @Input() isLocked: boolean;
  @Input() serviceIDColour: string;
  @Input() customClasses = [''];

  public loading: boolean;
  serviceListType = ServiceListType;
  previousServices: Service[] = [];
  favouriteServices: UserFavouriteService[] = [];
  previousAppointments: PreviousTreatment[] = [];
  startDate: Date = new Date(1980, 10, 30); // set random start date
  endDate: Date = new Date();

  unsub = new Subject<any>();

  constructor(
    private currentDataService: CurrentDataService,
    private servicesService: ServicesService,
    private appointmentService: AppointmentService,
    private userService: UsersService,
    private favouriteServicesService: FavouriteServicesService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.favouriteServicesService.getFavouriteServices(this.userService.loggedInUser.id).subscribe((s) => {
      this.favouriteServices = [];
      s.forEach((serv) => {
        if (serv.service.serviceDetailTemplateId === this.serviceDetailTemplateId) {
          this.favouriteServices.push(serv);
        }
      });
      this.loading = false;
    });

    this.currentDataService.favouritesDataUpdated$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.favouriteServicesService.getFavouriteServices(this.userService.loggedInUser.id).subscribe((s) => {
        this.favouriteServices = [];
        s.forEach((serv) => {
          if (serv.service.serviceDetailTemplateId === this.serviceDetailTemplateId) {
            this.favouriteServices.push(serv);
          }
        });
      });
    });

    this.getPreviousTreatmentAppointments(this.serviceTemplateId);
  }

  private getPreviousTreatmentAppointments(serviceTemplateId?: number) {
    this.loading = true;
    this.appointmentService
      .getTreatmentAppointmentsInDateRange(
        this.patientId,
        serviceTemplateId,
        undefined,
        new Date(new Date().toDateString())
      )
      .pipe(takeUntil(this.unsub))
      .subscribe((appointments: PreviousTreatment[]) => {
        this.previousAppointments = appointments;
        this.loading = false;
      });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
