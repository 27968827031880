import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UnlayerEmailEditorComponent } from '@app/shared/components/unlayer-email-editor/unlayer-email-editor.component';
import { ClinicEmailTemplate, EmailTemplateType } from '@models/clinic-email-template';
import { editorOptions, emptyDesign } from '@models/email-editor-options';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClinicEmailTemplateService } from '@services/clinic-email-template.service';
import { CommunicationsService } from '@services/communications.service';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmDeleteDialogComponent } from '../dialogs/confirm-delete/confirm-delete.component';
import { ImportJSONComponent } from './import-json/import-json.component';

@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.less'],
})
export class EmailTemplatesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(UnlayerEmailEditorComponent) emailEditor: UnlayerEmailEditorComponent;

  iPad =
    navigator.userAgent.match(/(iPad)/) /* iOS pre 13 */ ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) /* iPad OS 13 */;

  loading = false;
  unsub: Subject<void> = new Subject<void>();

  emailTemplateTypes = EmailTemplateType;
  emailTemplates: ClinicEmailTemplate[] = [];
  selectedTemplate: ClinicEmailTemplate;
  clickedIndex: number;
  editorOptions = editorOptions;

  emailTemplateType:
    | 'AppointmentReminder'
    | 'RetentionCampaign'
    | 'Reviews'
    | 'PortalWelcome'
    | 'PortalResetPassword'
    | 'PortalSetPassword'
    | 'PortalCompleteDocuments'
    | 'AppointmentScheduled' = 'AppointmentReminder';

  form: FormGroup;
  private _editMode: boolean = false;
  get editMode() {
    return this._editMode;
  }
  set editMode(value: boolean) {
    this._editMode = value;
    if (!this.iPad) {
      value ? this.emailEditor.editor.hidePreview() : this.emailEditor.editor.showPreview('desktop');
    }
  }

  showDeleteMessage: boolean = false;
  showSaveMessage: boolean = false;

  displayEmailTemplateType: string = '-';
  displayName: string = '-';
  displayDescription: string = '-';
  displayCreatedDate: string = '-';
  displayCreatedBy: string = '-';
  displayModifiedDate: string = '-';
  displayModifiedBy: string = '-';

  constructor(
    private communicationService: CommunicationsService,
    private clinicEmailTemplateService: ClinicEmailTemplateService,
    private dialog: MatDialog,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.communicationService.communicationsSettingsUpdated.pipe(takeUntil(this.unsub)).subscribe(() => {
      if (this.communicationService.refreshRequired) {
        this.communicationService.refreshRequired = false;
        this.getEmailTemplates();
      }
    });

    this.initForm();
    this.getEmailTemplates();
  }

  ngAfterViewInit() {
    this.editMode = false;
  }

  initForm() {
    this.form = new FormGroup({
      template: new FormControl(null),
      emailTemplateType: new FormControl(1, Validators.required),
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      createdDate: new FormControl(null),
      createdBy: new FormControl(),
      modifiedDate: new FormControl(null),
      modifiedBy: new FormControl(),
    });
  }

  getEmailTemplates() {
    this.loading = true;
      this.clinicEmailTemplateService.getClinicEmailTemplates().subscribe((templates) => {
      this.emailTemplates = templates;
      this.loading = false;
    });
  }

  loadEditor(item: ClinicEmailTemplate) {
    if (Object.keys(item).length === 0) return;
    this.form.controls['emailTemplateType'].setValue(item.emailTemplateType);
    this.form.controls['name'].setValue(item.name);
    this.form.controls['description'].setValue(item.description);
    this.form.controls['createdDate'].setValue(moment(item.createdDate).format('YYYY-MM-DD h:mm A'));
    this.form.controls['createdBy'].setValue(item.createdByUser ? item.createdByUser.userName : '-');
    this.form.controls['modifiedDate'].setValue(
      item.modifiedDate.toString() == '0001-01-01T00:00:00'
        ? '-'
        : moment(item.modifiedDate).format('YYYY-MM-DD h:mm A')
    );
    this.form.controls['modifiedBy'].setValue(item.modifiedByUser ? item.modifiedByUser.userName : '-');
    this.emailEditor.editor.loadDesign(JSON.parse(item.definition));
    if (this.iPad) this.emailEditor.editor.showPreview('Mobile');
  }

  editorReady(event) {}

  onClone() {
    this.loading = true;
    this.emailEditor.exportHtml((htmlObj) => {
      this.emailEditor.editor.saveDesign((definition) => {
        const formVals = this.form.value;
        const template = new ClinicEmailTemplate();
        template.definition = JSON.stringify(definition);
        template.html = (htmlObj as any).html;
        template.name = formVals['name'] + ' clone';
        template.description = formVals['description'];
        template.emailTemplateType = formVals['emailTemplateType'];
        this.addTemplate(template);
      });
    });
  }

  onSave() {
    this.loading = true;
    this.emailEditor.exportHtml((htmlObj) => {
      this.emailEditor.editor.saveDesign((definition) => {
        const formVals = this.form.value;
        const template = new ClinicEmailTemplate();
        template.definition = JSON.stringify(definition);
        template.html = (htmlObj as any).html;
        template.name = formVals['name'];
        template.description = formVals['description'];
        template.emailTemplateType = formVals['emailTemplateType'];
        if (this.selectedTemplate) {
          template.id = this.selectedTemplate.id;
          this.updateTemplate(template);
        } else {
          this.addTemplate(template);
        }
      });
    });
  }

  onDelete() {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'delete') {
          this.deleteTemplate(this.selectedTemplate);
        }
      });
  }

  onCancel() {
    this.editMode = false;
    this.displayEmailTemplateType = '-';
    this.displayName = '-';
    this.displayDescription = '-';
    this.displayCreatedDate = '-';
    this.displayCreatedBy = '-';
    this.displayModifiedDate = '-';
    this.displayModifiedBy = '-';
    this.clickedIndex = -1;
  }

  onEdit() {
    this.editMode = true;
  }

  addTemplate(template: ClinicEmailTemplate) {
    this.clinicEmailTemplateService
      .addClinicEmailTemplate(template)
      .pipe(takeUntil(this.unsub))
      .subscribe({
        next: (savedTemplate) => {
          this.emailTemplates.push(savedTemplate);
          this.form.controls['template'].setValue(savedTemplate);
          this.loading = false;
          this.showSaveMessage = true;
          setTimeout(async () => {
            this.showSaveMessage = false;
          }, 5000);
        },
      });
  }

  updateTemplate(template: ClinicEmailTemplate) {
    this.clinicEmailTemplateService
      .updateClinicEmailTemplate(template)
      .pipe(takeUntil(this.unsub))
      .subscribe({
        next: (savedTemplate) => {
          const index = this.emailTemplates.findIndex((t) => t.id === savedTemplate.id);
          this.emailTemplates[index] = savedTemplate;
          this.form.controls['template'].setValue(savedTemplate);
          this.loading = false;
          this.showSaveMessage = true;
          setTimeout(async () => {
            this.showSaveMessage = false;
          }, 5000);
        },
      });
  }

  deleteTemplate(template: ClinicEmailTemplate) {
    this.clinicEmailTemplateService
      .deleteClinicEmailTemplate(template.id)
      .pipe(takeUntil(this.unsub))
      .subscribe({
        next: (deletedTemplate) => {
          const index = this.emailTemplates.findIndex((t) => t.id === deletedTemplate.id);
          this.emailTemplates.splice(index, 1);
          if (this.selectedTemplate.id == deletedTemplate.id)
            if (this.emailTemplates.length) {
              this.form.controls['template'].setValue(this.emailTemplates[0]);
            } else {
              this.form.controls['template'].setValue(null);
            }
          this.showDeleteMessage = true;
          this.loading = false;
          setTimeout(async () => {
            this.showDeleteMessage = false;
          }, 5000);
        },
      });
  }

  createTemplate() {
    console.log('Creating new Template');
    this.editMode = true;
    this.selectedTemplate = null;
    this.form.controls['emailTemplateType'].setValue(null);
    this.form.controls['name'].setValue(null);
    this.form.controls['description'].setValue(null);
    this.form.controls['createdDate'].setValue('-Not Saved-');
    this.form.controls['createdBy'].setValue('-Not Saved-');
    this.form.controls['modifiedDate'].setValue('-Not Saved-');
    this.form.controls['modifiedBy'].setValue('-Not Saved-');
    this.displayEmailTemplateType = '-Not Saved-';
    this.displayName = '-Not Saved-';
    this.displayDescription = '-Not Saved-';
    this.displayCreatedDate = '-Not Saved-';
    this.displayCreatedBy = '-Not Saved-';
    this.displayModifiedDate = '-Not Saved-';
    this.displayModifiedBy = '-Not Saved-';

    this.emailEditor.editor.loadDesign(emptyDesign);
    if (this.iPad) this.emailEditor.editor.showPreview('Mobile');
  }

  onImportJSON() {
    const modalRef = this.modalService.open(ImportJSONComponent, { windowClass: 'add-photo-modal', centered: true });
    modalRef.result.then((result: ClinicEmailTemplate) => {
      console.log('Importing template');
      this.editMode = true;
      this.selectedTemplate = null;
      this.form.controls['emailTemplateType'].setValue(result.emailTemplateType);
      this.form.controls['name'].setValue(result.name);
      this.form.controls['description'].setValue(result.description);
      this.form.controls['createdDate'].setValue('-Not Saved-');
      this.form.controls['createdBy'].setValue('-Not Saved-');
      this.form.controls['modifiedDate'].setValue('-Not Saved-');
      this.form.controls['modifiedBy'].setValue('-Not Saved-');
      this.displayEmailTemplateType = '-Not Saved-';
      this.displayName = '-Not Saved-';
      this.displayDescription = '-Not Saved-';
      this.displayCreatedDate = '-Not Saved-';
      this.displayCreatedBy = '-Not Saved-';
      this.displayModifiedDate = '-Not Saved-';
      this.displayModifiedBy = '-Not Saved-';
      let definition = result.definition ? result.definition : emptyDesign;
      this.emailEditor.editor.loadDesign(definition);
      if (this.iPad) this.emailEditor.editor.showPreview('Mobile');
    });
  }

  selectTemplate(template: ClinicEmailTemplate, i) {
    this.selectedTemplate = template;
    this.clickedIndex = i;
    this.loadEditor(template);
    this.displayEmailTemplateType = EmailTemplateType[template.emailTemplateType];
    this.displayName = template.name;
    this.displayDescription = template.description;
    this.displayCreatedDate = moment(template.createdDate).format('YYYY-MM-DD h:mm A');
    this.displayCreatedBy = template.createdByUser.userName;
    this.displayModifiedDate =
      template.modifiedDate.toString() == '0001-01-01T00:00:00'
        ? '-'
        : moment(template.modifiedDate).format('YYYY-MM-DD h:mm A');
    this.displayModifiedBy = template.modifiedByUser ? template.modifiedByUser.userName : '-';
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
