import { Injectable } from '@angular/core';
import { TreatmentPlan } from '@models/treatment-planning/treatment-plan';
import { Patient } from '@models/patient';
import { PatientService } from '../patient.service';
import { ProductRecommendation } from '@models/treatment-planning/product-recommendation';
import { Subject, zip, forkJoin, of } from 'rxjs';
import { TreatmentPlanService } from './treatment-plan.service';
import { CurrentDataService } from '../currentData.service';
import { isNullOrUndefined } from '@app/shared/helpers';
import { PatientTreatmentAnswer } from '@models/treatment-planning/treatment-questions/patient-treatment-answer';
import { TreatmentPlanQuestionService } from './treatment-plan-question.service';
import { takeUntil } from 'rxjs/operators';
import { ServicesService } from '@services/services.service';
import { TreatmentPlanPhoto } from '@models/treatment-planning/treatment-plan-photo';

@Injectable({
  providedIn: 'root',
})
export class TreatmentPlanFormService {
  treatmentPlan: TreatmentPlan;
  patientTreatmentAnswers: PatientTreatmentAnswer[];
  patient: Patient;
  treatmentPlanDirty: boolean;
  saveTreatmentPlanPhotos$: Subject<void>;
  formValid: boolean;
  editingPhoto: boolean;

  // Arrays of ids to delete
  productRecommendationsToDelete: number[];
  plannedTreatmentsToDelete: number[];
  patientTreatmentAnswersToDelete: number[];
  scheduledServicePriceUpdate: Array<{ serviceId: number; appointmentId: number; price: number | string }> = [];

  constructor(
    private patientService: PatientService,
    private treatmentPlanService: TreatmentPlanService,
    private currentDataService: CurrentDataService,
    private servicesService: ServicesService,
    private treatmentPlanQuestionService: TreatmentPlanQuestionService
  ) {
    this.patient = this.patientService.patientPanelPatient;
    this.treatmentPlanDirty = false;
    this.saveTreatmentPlanPhotos$ = new Subject();
    this.productRecommendationsToDelete = [];
    this.plannedTreatmentsToDelete = [];
    this.patientTreatmentAnswersToDelete = [];
    this.patientTreatmentAnswers = [];
    this.patientService.thePatientUpdated$.subscribe((patient) => {
      this.patient = patient;

      this.treatmentPlanService.getTreatmentPlanByPatientId(this.patient?.patientId).subscribe((tp: TreatmentPlan) => {
        this.treatmentPlan = tp;
      });
    });
    this.treatmentPlanService.getTreatmentPlanByPatientId(this.patient?.patientId).subscribe((tp: TreatmentPlan) => {
      this.treatmentPlan = tp;
    });
    this.formValid = true;
    this.editingPhoto = false;
  }

  updateProductRecomendations(
    productRecommendations: ProductRecommendation[],
    productRecommendationsToDelete: number[],
    formValid: boolean
  ) {
    // Delete the Product Taxes as it's not needed and has issues with the deserialization
    productRecommendations.forEach((pr) => (pr.clinicProduct.productTaxes = null));
    this.treatmentPlan.productRecommendations = productRecommendations;
    this.treatmentIsDirty();
    this.productRecommendationsToDelete.push(...productRecommendationsToDelete);
    this.formValid = formValid;
  }

  updatePatientTreatmentAnswers(
    patientTreatmentAnswers: PatientTreatmentAnswer[],
    patientTreatmentAnswersToDelete: number[]
  ) {
    this.patientTreatmentAnswers = patientTreatmentAnswers;
    this.patientTreatmentAnswersToDelete.push(...patientTreatmentAnswersToDelete);
    this.treatmentIsDirty();
  }

  updatePatientTreatmentAnswersReviewed(reviewed: boolean) {
    this.treatmentPlan.treatmentQuestionsReviewed = reviewed;
    this.treatmentIsDirty();
  }

  updateTreatmentsofInterest(treatmentPlan: TreatmentPlan, plannedTreatmentsToDelete: number[], formValid: boolean) {
    if (isNullOrUndefined(this.treatmentPlan)) {
      this.treatmentPlan = treatmentPlan;
    }
    this.treatmentPlan.plannedTreatments = treatmentPlan.plannedTreatments;
    this.treatmentPlan.notes = treatmentPlan.notes;
    this.treatmentIsDirty();
    this.plannedTreatmentsToDelete.push(...plannedTreatmentsToDelete);
    this.formValid = formValid;
  }

  updateTreatmentPlanPhotos(photos: TreatmentPlanPhoto[]) {
    this.treatmentPlan.photos = photos;
    this.treatmentIsDirty();
    this.editingPhoto = false;
  }

  private updateChangedPricesForScheduledService() {
    if (this.scheduledServicePriceUpdate.length == 0) return of([]);
    return forkJoin(this.scheduledServicePriceUpdate.map((data) => this.servicesService.updateServicePrice(data)));
  }

  treatmentPlanUpdated(tp: TreatmentPlan) {
    this.treatmentPlan = tp;
    this.patientService.thePatientHasBeenUpdated(this.patient);
    this.treatmentPlanService.treatmentPlanUpdated$.next(this.treatmentPlan);
    this.treatmentIsNotDirty();
  }

  // saveTreatmentPlan() {
  //   if (this.formValid) {
  //     this.treatmentPlanQuestionService.deletePatientAnswers(this.patientTreatmentAnswersToDelete).subscribe(() => {
  //       zip(
  //         this.treatmentPlanQuestionService.putPatientAnswers(this.patientTreatmentAnswers[0], 1),
  //         this.treatmentPlanService.deletePlannedTreatments(this.plannedTreatmentsToDelete),
  //         this.treatmentPlanService.deleteProductRecommendations(this.productRecommendationsToDelete),
  //         this.updateChangedPricesForScheduledService()
  //       ).subscribe(([patientTreatmentAnswers, , ,]) => {
  //         this.plannedTreatmentsToDelete = [];
  //         this.productRecommendationsToDelete = [];
  //         this.patientTreatmentAnswersToDelete = [];
  //         this.scheduledServicePriceUpdate = [];

  //         if (patientTreatmentAnswers) {
  //           this.treatmentPlanQuestionService.treatmentPlanQuestionsUpdated$.next(patientTreatmentAnswers);
  //         }

  //         this.treatmentPlanService.updateTreatmentPlan(this.treatmentPlan).subscribe((tp) => {
  //           this.treatmentPlan = tp;
  //           this.patientService.thePatientHasBeenUpdated(this.patient);
  //           this.treatmentPlanService.treatmentPlanUpdated$.next(this.treatmentPlan);
  //           this.treatmentIsNotDirty();
  //         });
  //       });
  //     });
  //   }
  // }

  private treatmentIsDirty() {
    this.treatmentPlanDirty = true;
    this.currentDataService.treatmentIsDirty = true;
  }

  private treatmentIsNotDirty() {
    this.treatmentPlanDirty = false;
    this.currentDataService.treatmentIsDirty = false;
  }

  clearUnsavedTreatmentPlan() {
    this.treatmentIsNotDirty();
  }

  onCleanTreatmentPlan(treatmentPlan: TreatmentPlan) {
    if (!isNullOrUndefined(treatmentPlan)) {
      return JSON.parse(JSON.stringify(treatmentPlan));
    }
    return treatmentPlan;
  }
}
