export class ChartEntry {
  public patientNoteId: number;
  public appointmentId: number;
  public serviceId: number;
  public serviceTemplateId: number;
  public date: Date;
  public isLocked: boolean;

  public constructor(init?: Partial<ChartEntry>) {
    Object.assign(this, init);
  }
}
