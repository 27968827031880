<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>

<div [ngStyle]="{ height: 'calc(100dvh - 75px)' }">
  <kendo-grid
    [data]="gridView"
    [pageSize]="gridState.take"
    [skip]="gridState.skip"
    [sort]="gridState.sort"
    [sortable]="true"
    [selectable]="true"
    [style.height.%]="100"
    (add)="onAddClick()"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <div class="d-flex">
        <span class="pl-4 search-box border border-radius">
          <span class="fa fa-search"></span>
          <mat-form-field class="w-75 p-3">
            <input
              matInput
              placeholder="Search..."
              aria-label="Search Products"
              [(ngModel)]="searchValue"
              [formControl]="searchCtrl"
              autocomplete="off"
            />
            <button
              mat-button
              *ngIf="searchValue"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="searchValue = ''"
            >
              <mat-icon class="clear-icon"><i class="fa fa-times-circle"></i></mat-icon>
            </button>
          </mat-form-field>
        </span>
      </div>

      <button kendoGridAddCommand class="btn em-btn"><i class="fal fa-plus-circle"></i> Add Product</button>
      <button kendoButton class="btn em-btn" type="button" (click)="openManageCategoryDialog()">
        <i class="fal fa-folder-open"></i> Manage Categories
      </button>
      <button kendoGridExcelCommand class="btn em-btn" type="button">
        <i class="fal fa-file-excel"></i> Export to Excel
      </button>
    </ng-template>
    <kendo-grid-column title="Brand" [width]="125">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.product?.productBrand ? dataItem.product.productBrand.name : 'No Brand Selected' }}
      </ng-template>
      <!-- <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
        <kendo-dropdownlist
          [data]="brandsDropDown"
          textField="name"
          valueField="name"
          [formControl]="formGroup.get('productBrand')"
        >
        </kendo-dropdownlist>
      </ng-template> -->
    </kendo-grid-column>
    <kendo-grid-column field="displayName" title="Name" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="category.name" title="Category" [width]="125"> </kendo-grid-column>
    <kendo-grid-column field="quantityInStock" title="Qty In-Stock" [width]="100"></kendo-grid-column>
    <kendo-grid-column field="quantity" title="Reorder Quantity" [width]="125"></kendo-grid-column>
    <kendo-grid-column field="usageDuration" title="Usage Duration (days)" [width]="125"></kendo-grid-column>
    <kendo-grid-column field="wholesalePrice" title="Wholesale Price" [width]="125">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.wholesalePrice | currency }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="retailPrice" title="Retail Price" [width]="100">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.retailPrice | currency }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="productCode" title="Product Code" [width]="125"></kendo-grid-column>

    <kendo-grid-command-column title="Actions" min-width="125px" [width]="125">
      <ng-template kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridEditCommand class="btn kendo-btn"><i class="fas fa-edit"></i></button>
        <button kendoGridRemoveCommand class="btn kendo-btn"><i class="fas fa-minus-circle"></i></button>
        <button kendoGridSaveCommand [disabled]="formGroup?.invalid">{{ isNew ? 'Add' : 'Update' }}</button>
        <button kendoGridCancelCommand>{{ isNew ? 'Discard changes' : 'Cancel' }}</button>
      </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-excel fileName="Products.xlsx"></kendo-grid-excel>
  </kendo-grid>
</div>

<app-actionpanel></app-actionpanel>
