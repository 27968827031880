import { Component, Inject, Input, OnInit } from '@angular/core';
import { Patient } from '@models/patient';
import { EmilySquareInfo } from '@models/square/emily-square-info';
import { SquareCard } from '@models/square/square-card';
import { SquareCardInfoRequest } from '@models/square/square-card-info-request';
import { SquareCardToken } from '@models/square/square-card-token';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ScriptService } from '@services/script.service';
import { SquareService } from '@services/square.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-square-card',
  templateUrl: './add-square-card.component.html',
  styleUrls: ['./add-square-card.component.less'],
})
export class AddSquareCardComponent implements OnInit {
  // appId: string; // = 'sandbox-sq0idb-Efwo4_GFd3cS7tAeGNPPcg';
  // locationId: string; // = 'LHC0SRJ7A5AJS';
  emilySquareInfo: EmilySquareInfo;

  @Input() minimumExpiryDate: Date = new Date(Date.now());
  @Input() patient: Patient;

  private unsub = new Subject<any>();
  loading = false;
  private card: any;
  isFormValid: boolean;
  errorMessage: string;

  constructor(
    public activeModal: NgbActiveModal,
    private squareService: SquareService,
    private scriptService: ScriptService
  ) {}

  async ngOnInit() {
    this.loading = true;
    try {
      await this.scriptService.loadScript('squareWebPaymentsApi');
      this.loading = false;
    } catch (error) {
      this.loading = false;
      console.error(error);
      if (error.error && error.error.errors) {
        const errors = error.error.errors;
        this.errorMessage = errors[0].fieldErrors[0];
      }
    }

    await this.fetchEmilySquareInfo();
    await this.initializeCard();
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

  async onAddCardPressed() {
    this.loading = true;
    const cardTokenResult = await this.getPaymentToken();

    // console.log(cardTokenResult);
    // TODO: handle results other than OK
    if (cardTokenResult.status != 'OK') {
      console.log('Invalid card.');
      console.log(cardTokenResult.status);
      console.log(cardTokenResult.errors);
      this.errorMessage = 'Invalid Card -' + cardTokenResult.status + ' - ' + cardTokenResult.errors;
      return;
    }

    const cardToken = {
      cardToken: cardTokenResult.token,
    } as SquareCardToken;

    const squareCardInfoRequest = {
      squareCardToken: cardToken,
      patientId: this.patient?.patientId,
    } as SquareCardInfoRequest;

    this.squareService.addCardOnFile(squareCardInfoRequest).subscribe({
      next: (newCard) => {
        this.loading = false;
        this.activeModal.close(newCard);
      },
      error: (error) => {
        this.loading = false;
        console.error(error);
        if (error.error && error.error.errors) {
          const errors = error.error.errors;
          this.errorMessage = errors[0].fieldErrors[0];
        }
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  async fetchEmilySquareInfo() {
    const emilySquareInfo = await this.squareService.getEmilySquareInfo().toPromise();
    this.emilySquareInfo = emilySquareInfo;
  }

  async initializeCard() {
    this.loading = true;
    if (this.card) this.card.destroy();
    const square = (window as any).Square;
    if (!square) throw new Error('Square.js failed to load properly');
    const payments = square.payments(this.emilySquareInfo.applicationId, this.emilySquareInfo.locationId);

    try {
      const card = await payments.card();
      await card.attach('#card-container');
      this.card = card;

      const cardFields = {
        cardNumber: false,
        expirationDate: false,
        cvv: false,
        postalCode: false,
      };

      card.addEventListener('focusClassRemoved', async (e) => {
        let field = e.detail.field;
        let value = e.detail.currentState.isCompletelyValid;

        cardFields[field] = value;

        if (Object.values(cardFields).every((item) => item === true)) {
          this.isFormValid = true;
        } else {
          // Disable confirm button
          this.isFormValid = false;
        }
      });

      card.addEventListener('focusClassAdded', (e) => {
        let field = e.detail.field;
        let value = e.detail.currentState.isCompletelyValid;

        if (Object.values(cardFields).every((item) => item === true)) {
          this.isFormValid = true;
        } else {
          // Disable confirm button
          this.isFormValid = false;
        }
      });

      this.loading = false;
    } catch (error) {
      this.loading = false;
      console.error('Initializing card failed', error);
      if (error.error && error.error.errors) {
        const errors = error.error.errors;
        this.errorMessage = errors[0].fieldErrors[0];
      }
      throw error;
    }
  }

  public async getPaymentToken() {
    const tokenResult = await this.card.tokenize();
    if (tokenResult.status != 'OK') {
      console.error(tokenResult.errors);
      this.errorMessage = tokenResult.errors;
    }
    return tokenResult;
  }
}
