import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { isNullOrUndefined } from '@app/shared/helpers';
import { Patient } from '@models/patient';
import { PatientStatus } from '@models/patient-status.enum';
import { AppointmentService } from '@services/appointments.service';
import { CurrentDataService } from '@services/currentData.service';
import { PatientService } from '@services/patient.service';
import * as moment from 'moment';
import 'moment-duration-format';
import { TypeaheadDirective, TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable, Subject } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { EditPatientContentComponent } from '../actionpanel/edit-patient-content/edit-patient-content.component';

@Component({
  selector: 'app-patient-typehead',
  templateUrl: './patient-typehead.component.html',
  styleUrls: ['./patient-typehead.component.less'],
})
export class PatientTypeheadComponent implements OnInit, OnDestroy {
  @Input() searchType: any = null;
  @Input() typeheadPlaceholder: any = 'Patient Search';
  @Output() typeaheadOnSelectEmitter = new EventEmitter();
  @Input() asyncSelected: any = null;
  @Input() patientStatuses: PatientStatus[] = null;
  @ViewChild('typeaheadDirective') _typeahead: TypeaheadDirective;
  @ViewChild('patientSearch') _input: any;
  @ViewChild(EditPatientContentComponent) patientContentComponent: EditPatientContentComponent;

  public get container() {
    return this._typeahead._container;
  }

  loading = false;
  patient: Patient;
  dataSource: Observable<any>;
  private patientId: number;
  private unsub: Subject<void> = new Subject<void>();
  PatientStatus = PatientStatus;

  constructor(
    public appointmentService: AppointmentService,
    private patientService: PatientService,
    public currentDataService: CurrentDataService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.dataSource = new Observable((observer: any) => {
      observer.next(this.asyncSelected);
    }).pipe(
      mergeMap((token: string) => {
        return this.patientService.searchPatientByTerm(token, undefined, this.patientStatuses).pipe(
          map((value) => {
            return value.map((i) => {
              i['formatOption'] = function () {
                const nickname = this.nickname ? ` "${this.nickname}" ` : ' ';
                return `${this.firstName}${nickname}${this.lastName}`;
              };
              return i;
            });
          })
        );
      }),
      takeUntil(this.unsub)
    );
  }

  public getAge(DOB) {
    if (!isNullOrUndefined(DOB)) {
      const today = new Date();
      const birthDate = new Date(DOB);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    } else {
      return 'N/A';
    }
  }

  public getBirthday(date: string): string {
    if (!isNullOrUndefined(date)) {
      return moment(date).format('YYYY-MM-DD');
    } else {
      return 'N/A';
    }
  }

  public typeaheadOnSelect(e: TypeaheadMatch): void {
    this.patientId = e.item.patientId;
    this.loading = true;
    this.patientService
      .getPatientById(this.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((p) => {
        this.patient = p;
        this.typeaheadOnSelectEmitter.emit({ type: this.searchType, data: this.patient });
        this.loading = false;
      });
  }

  public removeSearchTerm() {
    this.patient = null;
    this.asyncSelected = '';
    this.typeaheadOnSelectEmitter.emit({ type: this.searchType, data: this.patient });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  public setInitialFocus() {
    if (this._input && this._input.nativeElement) {
      this._input.nativeElement.focus();
    }
  }
}
