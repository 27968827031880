import { Component, OnInit, HostListener } from '@angular/core';
import { CanComponentDeactivate } from '@app/shared/guards/confirm-deactivate.guard';
import { PatientFormService } from '@services/patient-form.service';

@Component({
  selector: 'app-patients-document-tab',
  templateUrl: './patients-document-tab.component.html',
  styleUrls: ['./patients-document-tab.component.less']
})
export class PatientsDocumentTabComponent implements OnInit, CanComponentDeactivate {
  constructor(private patientFormService: PatientFormService) { }

  ngOnInit() {
  }

  // HostListener to guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  confirm(): boolean {
    return !this.patientFormService.formDirty$.getValue()
  }
}
