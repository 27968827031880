<ngx-loading [show]="loading"></ngx-loading>
<div [ngStyle]="{ height: 'calc(100dvh - 115px)' }">
  <kendo-grid
    [data]="gridView"
    [sort]="gridState.sort"
    [sortable]="true"
    [selectable]="true"
    [style.height.%]="100"
    (add)="onAddClick()"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <div class="d-flex">
        <button kendoGridAddCommand class="btn em-btn"><i class="fal fa-plus-circle"></i> Add Group</button>
      </div>
    </ng-template>
    <kendo-grid-column field="name" title="Name"></kendo-grid-column>
    <kendo-grid-column field="price" title="Price" [width]="100" filter="numeric" format="{0:c}"></kendo-grid-column>
    <kendo-grid-command-column title="Actions" min-width="125px" [width]="125" class="text-center">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <button kendoGridEditCommand class="btn kendo-btn">
          <i class="fas fa-edit"></i>
        </button>
        <button kendoGridRemoveCommand class="btn kendo-btn">
          <i class="fas fa-minus-circle"></i>
        </button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
