import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MasterOverlayService, ActionPanelService } from '@services/actionpanel.service';
import { FormType } from '@models/forms/form-type';
import { FormTypeService } from '@services/form-type.service';
import { Form } from '@models/forms/form';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { SharedFormService } from '@services/shared-form.service';
import { SharedForm } from '@models/forms/shared-form';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-form-library-overview',
  templateUrl: './form-library-overview.component.html',
  styleUrls: ['./form-library-overview.component.less'],
})
export class FormLibraryOverviewComponent implements OnInit, OnDestroy {
  actionPanelOpen = false;

  private unsub: Subject<void> = new Subject<void>();

  loading = false;
  initialized = false;

  formTypes: FormType[] = [];
  activeTab: any;

  disableGrid = false;

  forms: SharedForm[];
  public gridView: GridDataResult;

  gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  selectedRowIndex: number;
  selectedDataItem: Form;
  formSelected: boolean = false;

  public selectedKeys: number[] = [];

  constructor(
    private router: Router,
    private masterOverlayService: MasterOverlayService,
    private actionPanelService: ActionPanelService,
    private formTypeService: FormTypeService,
    private sharedFormService: SharedFormService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getFormTypes();
    setTimeout(() => {
      this.initialized = true;
    });
  }

  getFormTypes() {
    this.formTypes = [];
    this.formTypeService.getFormTypes().subscribe((res) => {
      this.formTypes = res;
      if (this.formTypes.length > 0) {
        this.selectTab(this.formTypes[0]);
      }
    });
  }

  selectTab(formType: FormType) {
    // Clear out selected Form
    //  this.clearFormSelection();
    this.loadForms(formType);
    this.activeTab = formType;
  }

  loadForms(formType?: FormType | null) {
    this.loading = true;
    this.disableGrid = true;

    this.sharedFormService.getSharedFormsByFormType(formType).subscribe((forms) => {
      this.forms = forms;

      this.gridView = {
        data: forms.slice(),
        total: forms.length,
      };

      this.loading = false;
      this.disableGrid = false;
    });
  }

  public onSelectRow(e) {
    if (e.selectedRows.length > 0) {
      this.selectRow(e.selectedRows[0].dataItem);
    }
  }

  private selectRow(dataItem: Form) {
    this.selectedDataItem = dataItem;
    if (this.selectedKeys.length > 0) {
      this.selectedKeys = [];
    }
    this.selectedKeys.push(this.selectedDataItem.id);
    this.formSelected = true;
  }

  public showDetails(dataItem: SharedForm): boolean {
    return dataItem.sharedByClinic != undefined && dataItem.sharedByUser !== undefined;
  }

  addFromLibraryHandler(formId: number) {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '325px',
      data: {
        title: 'Add Shared Form?',
        content: 'Are you sure you want to add this Form to your collection?',
        confirmButtonText: 'Add',
        showCancel: true,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') {
          this.loading = true;
          this.sharedFormService.addFromLibrary(formId).subscribe((newForm: Form) => {});
        }
      });
  }

  public closeFormLibrary() {
    this.masterOverlayService.masterOverlayState(false);
    const returnURL = this.router.url.slice(0, this.router.url.indexOf('('));
    this.router.navigate([returnURL, { outlets: { 'action-panel-secondary': null } }]);

    this.actionPanelService.actionPanelClosed.next();
  }

  public formLibraryOpen(event: TransitionEvent) {
    if (event && event.propertyName && event.propertyName == 'width') {
      this.actionPanelService.actionPanelCompleteTransition(true);
      this.actionPanelOpen = true;
    }
  }

  ngOnDestroy() {
    this.masterOverlayService.masterOverlayState(false);
    this.actionPanelOpen = false;
    this.unsub.next();
    this.unsub.complete();
  }
}
