import { Injectable } from '@angular/core';
import { TitleCasePipe, UpperCasePipe } from '@angular/common';
import { FormControl } from '@angular/forms';

@Injectable()
export class FormatterService {

    constructor() { }

    textCapitalize(formCtrl) {
        formCtrl.setValue(new TitleCasePipe().transform(formCtrl.value));
    }

    textUppercase(formCtrl) {
        formCtrl.setValue(new UpperCasePipe().transform(formCtrl.value));
    }

    formatPhoneNumber(formCtrl) {;
        let result = this.formatPhoneString(formCtrl.value);
        formCtrl.setValue(result, {emitEvent: false});
    }

    formatPostalCode(formCtrl: FormControl) {
        let str = ("" + formCtrl.value)
                    .replace(/[^\da-zA-Z]/g, '')
                    .toUpperCase();
        let match = str.match(/^([A-Z]\d[A-Z])(\d[A-Z]\d)$/);
        const result = (match) ? `${match[1]}-${match[2]}`
                               : str;
        formCtrl.setValue(result, {emitEvent: false});
    }

    public formatPhoneString(str: string): string {
      const s2 = ("" + str).replace(/\D/g, '');
      let result = s2;
      const matchFull = s2.match(/^(\d{3})(\d{3})(\d{1,4})$/);
      const matchPartial = s2.match(/^(\d{3})(\d{1,3})$/);
      // Attempt to match entire number
      if (matchFull) {
          result = matchFull[1] + "-" + matchFull[2] + "-" + matchFull[3];
      }
      // Attempt to match patial number
      else if (matchPartial) {
          result = matchPartial[1] + "-" + matchPartial[2];
      }
      return result;
    }
}
