<ngx-loading [show]="loading"></ngx-loading>
<div class="d-flex flex-column h-100">
  <div class="d-flex align-items-center w-100" style="height: 48px">
    <div class="column-header pl-3 py-0 mr-2">
      {{ listtype }}
    </div>
  </div>

  <ng-container>
    <ng-container *ngIf="listtype === serviceListType.history; else favourites">
      <app-history-item
        class="flex-1"
        [appointments]="previousAppointments"
        [serviceTemplateId]="serviceTemplateId"
        [isLocked]="isLocked"
      >
      </app-history-item>
    </ng-container>
    <ng-template #favourites>
      <app-favourite-item
        class="flex-1"
        [services]="favouriteServices"
        [serviceTemplateId]="serviceTemplateId"
        [serviceIDColour]="serviceIDColour"
        [isLocked]="isLocked"
      >
      </app-favourite-item>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="previousAppointments?.length === 0 && !serviceTemplateId && !loading">
    <div class="px-3 pb-3">The patient hasn't had treatments yet</div>
  </ng-container>
</div>
