<div class="edit-container position-absolute d-block z-index-3 white-background">
  <ngx-loading [show]="loading"></ngx-loading>

  <div class="re-links-container">
    <button
    type="button"
      class="btn em-btn teal-background"
      (click)="cancelUpdate()">
        <i class="far fa-times" aria-hidden="true"></i>
    </button>
  </div>

  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0;">
    <form [formGroup]="reminderSettingsForm" (ngSubmit)="saveChanges()">

      <div class="row mx-0 teal-background">
        <div class="col">
          <h6 class="white-font uppercase my-0 py-2">
            Edit Visit Reminder {{type}}
          </h6>
        </div>
      </div>

      <div *ngIf="type === 'email'" class="py-3">
        <div class="row mx-0">
          <div class="col">
            <mat-form-field class="w-100">
              <mat-label>Template</mat-label>
              <mat-select formControlName="emailTemplate">
                <mat-option *ngFor="let template of emailTemplates" [value]="template">
                  {{ template.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput placeholder="Sender Name" formControlName="emailSenderName">
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput placeholder="Sender Email Address" formControlName="emailSenderEmail">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="type === 'sms'" class="py-3">
        <div class="row mx-0">
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput placeholder="SMS Title" formControlName="smsTitle">
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col">
            <mat-form-field class="w-100">
              <textarea
                matInput
                placeholder="SMS Message Preview"
                formControlName="smsMessage"

                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="10"
                (keydown.enter)="$event.stopPropagation()"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row mx-0 teal-background">
        <div class="col">
          <h6 class="white-font uppercase mb-0 py-2">
            Available fields
          </h6>
        </div>
      </div>

      <div class="py-3">
        <div class="row mx-0">
          <div class="col">
            <ul class="mb-0">
              <li>{{"FirstName"}}</li>
              <li>{{"PatientName"}}</li>
              <li>{{"VisitDate"}}</li>
              <li>{{"VisitTime"}}</li>
              <li>{{"ConfirmVisitEndpoint"}}</li>
              <li>{{"UnsubscribeEndpoint"}}</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row mx-0 teal-background">
        <div class="col">
          <h6 class="white-font uppercase mb-0 py-2">
            When to send
          </h6>
        </div>
      </div>

      <div class="py-3">
        <div class="row mx-0">
          <div class="col d-flex flex-column navy-font">
            Send visit reminders between:
            <div class="d-flex justify-content-between align-items-center mb-2">
              <kendo-timepicker
                class="communication-timepicker"
                name="startTime"
                placeholder="Select"
                [steps]="{ minute: minimumDuration }"
                [(value)]="workerStartTime"
                (valueChange)="onStartTimeChange($event)">
              </kendo-timepicker>
              <span class="mx-3">and</span>
              <kendo-timepicker
                class="communication-timepicker"
                name="endTime"
                placeholder="Select"
                [steps]="{ minute: minimumDuration }"
                [min]="minEndTime"
                [(value)]="workerEndTime"
                (valueChange)="onEndTimeChange($event)">
              </kendo-timepicker>
            </div>
          </div>
        </div>

        <div class="row mx-0">
          <div class="col navy-font d-flex align-items-center">
            Send
            <input
              type="number"
              class="input-number mx-2"
              formControlName="firstNotificationPeriod"
              min="1">
            <mat-form-field class="sender-type ml-1 mr-2">
              <mat-select formControlName="firstNotificationUnit">
                <mat-option *ngFor="let type of senderUnits" [value]="type">
                  {{ type }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            before their visit
          </div>
        </div>

        <div class="row mx-0">
          <div class="col d-flex align-items-center navy-font">
            <mat-checkbox
              class="reports-checkbox mr-2"
              formControlName="enabledRepeatNotification">
            </mat-checkbox>
            <div
              class="d-flex align-items-center"
              [ngClass]="{'disabled-element': !this.reminderSettingsForm.controls['enabledRepeatNotification'].value}">
                Send every
                <input
                  type="number"
                  class="input-number mx-2"
                  formControlName="repeatNotificationPeriod"
                  min="1">
                <mat-form-field class="sender-type ml-1 mr-2">
                  <mat-select formControlName="repeatNotificationUnit">
                    <mat-option *ngFor="let type of senderUnits" [value]="type">
                      {{ type }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                until the visit has been confirmed
            </div>
          </div>
        </div>

        <div class="row mt-5 mx-0">
          <div class="col d-flex justify-content-end">
            <button
              class="btn em-btn em-btn-green mr-2"
              type="submit"
              [disabled]="!reminderSettingsForm.valid">
                Save {{type}}
            </button>
            <button
              type="button"
              class="btn em-btn cancel-btn"
              (click)="cancelUpdate()">
                Cancel
            </button>
          </div>
        </div>
      </div>

    </form>
  </perfect-scrollbar>
</div>