<ngx-loading [show]="loading"></ngx-loading>

<ng-container *ngIf="!loading">
  <div class="row">
    <div class="col pb-2 pointer" (click)="onToggleExpand()">
      <h5 class="d-inline">Treatment Questions</h5>
      <span class="float-right">
        <i [ngClass]="{'fa-angle-up': expanded, 'fa-angle-down': !expanded}" class="fal" title="Toggle Expansion"></i>
      </span>
    </div>
  </div>
  <ng-container *ngIf="expanded">
    <form [formGroup]="treatmentQuestionsForm">
      <div class="row">
        <ng-container formArrayName="questions">
          <ng-container
            *ngFor="let questionCtrl of onGetQuestionControls(); let i = index; let last = last; let even = even; let odd = odd;"
            [formGroupName]="i">
            <div class="pb-2"
              [ngClass]="{'col': last && even, 'col-6': !(last && even), 'grey-six-border-left': odd && !last}">
              <div class="row mb-2">
                <div class="col grey-four-background navy-font font-weight-bold py-2">
                  {{questionCtrl.value.value}}
                </div>
              </div>
              <ng-container formGroupName="answer">
                <div class="row pt-1 white-background"
                  *ngFor="let treatmentAnswer of onGetTreatmentAnswers(i); let j = index">
                  <div class="col">
                    <!-- Freeform -->
                    <ng-container *ngIf="questionCtrl.value.questionType === questionType.Freeform">
                      <textarea class="form-control w-100 mb-2" formControlName="answerText"
                        (change)="onInputChange(i, treatmentAnswer.id, $event)"
                        [value]="onGetAnswerValue(i, treatmentAnswer.id)"></textarea>
                    </ng-container>

                    <!-- Checkbox -->
                    <ng-container *ngIf="questionCtrl.value.questionType === questionType.Checkbox">
                      <div class="k-form-field-checkbox-wrap">
                        <label class="align-text-top">
                          <input class="mr-1" [id]="'userAnswer-' + questionCtrl.value.id + '-' + j" type="checkbox"
                          (change)="onInputChange(i, treatmentAnswer.id, $event)"
                          [checked]="onGetAnswered(i, treatmentAnswer.id)">
                          </label>
                          {{treatmentAnswer.value}}
                      </div>
                    <!-- Toggle option
                      <label class="switch align-text-top">
                        <input [id]="'userAnswer-' + questionCtrl.value.id + '-' + j" type="checkbox"
                          (change)="onInputChange(i, treatmentAnswer.id, null, $event.target.checked)"
                          [checked]="onGetAnswered(i, treatmentAnswer.id)">
                        <span class="slider round"></span>
                      </label>
                      {{treatmentAnswer.value}}
                    -->
                    </ng-container>

                   <!-- Checkbox Freeform -->
                   <ng-container *ngIf="questionCtrl.value.questionType === questionType.CheckboxFreeform">
                    <div class="k-form-field-checkbox-wrap">
                      <label class="align-text-top">
                        <input class="mr-1" [id]="'userAnswer-' + questionCtrl.value.id + '-' + j" type="checkbox"
                        (change)="onInputChange(i, treatmentAnswer.id, $event)"
                        [checked]="onGetAnswered(i, treatmentAnswer.id)">
                        </label>
                        {{treatmentAnswer.value}}
                    </div>
                  <!-- Toggle option
                     <label class="switch align-text-top">
                        <input [id]="'userAnswer-' + questionCtrl.value.id + '-' + j" type="checkbox"
                          [checked]="onGetAnswered(i, treatmentAnswer.id)" formControlName="answerId"
                          [value]="treatmentAnswer.id" (change)="onInputChange(i, treatmentAnswer.id, null, $event.target.checked)">
                        <span class="slider round"></span>
                      </label>
                      {{treatmentAnswer.value}} -->
                      <ng-container *ngIf="onGetDisplayOtherField(treatmentAnswer.value)">
                        <textarea class="form-control w-100" formControlName="answerText"
                          (change)="onInputChange(i, treatmentAnswer.id, $event)"
                          [value]="onGetAnswerValue(i, treatmentAnswer.id)"></textarea>
                      </ng-container>
                    </ng-container>

                    <!-- Radio -->
                    <ng-container *ngIf="questionCtrl.value.questionType === questionType.Radio">
                      <div class="custom-control custom-radio">
                        <input [id]="'userAnswer-' + questionCtrl.value.id + '-' + j" type="radio"
                          [checked]="onGetAnswered(i, treatmentAnswer.id)" [value]="treatmentAnswer.id"
                          formControlName="answerId" class="custom-control-input"
                          (change)="onInputChange(i, treatmentAnswer.id, null)" />
                        <label class="custom-control-label" [for]="'userAnswer-' + questionCtrl.value.id + '-' + j">
                          <!-- Empty (needed for custom radio button styling)-->
                        </label>
                        <span class="align-sub">{{treatmentAnswer.value}}</span>
                      </div>
                    </ng-container>

                    <!-- Radio Freeform -->
                    <ng-container *ngIf="questionCtrl.value.questionType === questionType.RadioFreeform">
                      <div class="custom-control custom-radio">
                        <input [id]="'userAnswer-' + questionCtrl.value.id + '-' + j" type="radio"
                          [value]="treatmentAnswer.id" [checked]="onGetAnswered(i, treatmentAnswer.id)"
                          formControlName="answerId" class="custom-control-input"
                          (change)="onInputChange(i, treatmentAnswer.id, null)" />
                        <label class="custom-control-label" [for]="'userAnswer-' + questionCtrl.value.id + '-' + j">
                          <!-- Empty (needed for custom radio button styling)-->
                        </label>
                        <span class="align-sub">{{treatmentAnswer.value}}</span>
                        <ng-container *ngIf="onGetDisplayOtherField(treatmentAnswer.value)">
                          <textarea class="form-control w-100" formControlName="answerText"
                            (change)="onInputChange(i, treatmentAnswer.id, $event)"
                            [value]="onGetAnswerValue(i, treatmentAnswer.id)"></textarea>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </form>

    <div class="row">
      <div class="col text-right pr-0">
        <button class="btn em-btn em-btn-green btn-sm mx-2 mb-2" (click)="onToggleReviewed()">
          <ng-container *ngIf="reviewed; else notReviewed">
            Reviewed
          </ng-container>
          <ng-template #notReviewed>
            Not Reviewed
          </ng-template>
        </button>

        <!-- <button (click)="onSave()" [disabled]="!treatmentPlanFormService.treatmentPlanDirty"
          [ngClass]="{'invalid': !treatmentPlanFormService.formValid}" class="btn btn-sm em-btn em-btn-green mr-2 mb-2"><i class="far fa-save" title="Save"></i></button> -->
      </div>
    </div>
  </ng-container>
</ng-container>
