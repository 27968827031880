<h1 mat-dialog-title style="text-align: center;">Confirmation</h1>
<div mat-dialog-content style="text-align: center;">
  <p>Are you sure you want to merge the selected patient records?
    <br>
    This operation cannot be reversed.
  </p>
</div>
<div mat-dialog-actions style="justify-content: center;">
  <button mat-button [mat-dialog-close]="'cancel'">Cancel</button>
  <button mat-button [mat-dialog-close]="'confirm'" cdkFocusInitial style="margin-left: 0px;">Confirm</button>
</div>