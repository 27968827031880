import { ProductCategory } from './product-category';
import { Package } from './package';
import { Service } from './service/service';
import { Tax } from './tax';
import { Special } from './special';
import { ChargeableItem } from './billing/chargeable-item';
import { IChargeableAsset } from '@interfaces/IChargeableAsset';
import { ClinicServiceTemplate } from './service/clinic-service-template';
import { Product } from './product';
import { TaxIndicator } from './finance/tax-indicator.enum';

export class ClinicProduct implements IChargeableAsset {
  id: number;
  productId: number;
  product: Product;
  displayName: string;
  productCode: string;
  quantityInStock: number;
  retailPrice: number;
  overrideRetailPrice: number;
  wholesalePrice: number;
  overrideWholesalePrice: number;
  quantity: number;
  usageDuration: number;
  productCategoryId: number;
  usageInstructions: string;
  category: ProductCategory;
  productTaxes: ProductTax[];
  dollarsToLoyaltyPointRate?: number;
  taxIndicator: TaxIndicator;

  constructor(init?: Partial<ClinicProduct>) {
    Object.assign(this, init);
  }

  public getBaseAndChildrenChargeableItems(): ChargeableItem[] {
    // Products do not have child chargeable items so there is no difference
    // between getDefaultChargeableItems and getChargeableItems
    const chargeableItems: ChargeableItem[] = [];

    if (this.retailPrice || this.overrideRetailPrice) {
      chargeableItems.push(
        new ChargeableItem({ defaultPrice: this.retailPrice, overridePrice: this.overrideRetailPrice })
      );
    }

    return chargeableItems;
  }

  public getChargeableItems(): ChargeableItem[] {
    const chargeableItems: ChargeableItem[] = [];

    if (this.retailPrice || this.overrideRetailPrice) {
      chargeableItems.push(
        new ChargeableItem({ defaultPrice: this.retailPrice, overridePrice: this.overrideRetailPrice })
      );
    }

    return chargeableItems;
  }
}

/*
export interface ProductDotNet {
  productId: number;
  name: string;
  productCode: string;
  quantityInStock: number;
  retailPrice: number;
  overrideRetailPrice: number;
  wholesalePrice: number;
  overrideWholesalePrice: number;
  quantity: number;
  usageInstructions: string;
  productCategoryId: number;
  productTaxes: ProductTax[];
  usageDuration: number;
  dollarsToLoyaltyPointRate?: number;
}
*/

export interface RequiredProduct {
  clinicProductId: number;
  clinicProduct: ClinicProduct;
  productQuantity: number;
  serviceId?: number;
  service?: Service;
  serviceTemplateId: number;
  serviceTemplate?: ClinicServiceTemplate;
}

export interface RecommendedProduct {
  clinicProductId: number;
  clinicProduct: ClinicProduct;
  productQuantity: number;
  serviceId?: number;
  service?: Service;
  serviceTemplateId: number;
  serviceTemplate?: ClinicServiceTemplate;
  isAddedToInvoice: boolean;
}

export interface PackageProduct {
  clinicProductId: number;
  clinicProduct: ClinicProduct;
  productQuantity: number;
  packageId: number;
  package: Package;
}

export interface SpecialProduct {
  clinicProductId: number;
  clinicProduct: ClinicProduct;
  productQuantity: number;
  specialId: number;
  special: Special;
}

export interface ProductTax {
  clinicProductId: number;
  clinicProduct: ClinicProduct;
  taxId: number;
  tax: Tax;
}
