export class ResourceAllocationFilterModel {
  date: Date;
  startTime: moment.Duration;
  endTime: moment.Duration;
  clinicId: number;
  appointmentId: number;

  constructor(init?: Partial<ResourceAllocationFilterModel>) {
    Object.assign(this, init);
  }
}
