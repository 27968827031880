<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>
<div class="row">
  <div class="col">
    <ngx-loading [show]="loading"></ngx-loading>
    <div [ngStyle]="{ height: 'calc(100dvh - 115px)' }">
      <kendo-grid
        [data]="gridView"
        [pageSize]="gridState.take"
        [skip]="gridState.skip"
        [sort]="gridState.sort"
        [sortable]="true"
        [selectable]="true"
        [style.height.%]="100"
        [pageable]="true"
        (dataStateChange)="dataStateChange($event)"
        (add)="addHandler()"
        (edit)="editHandler($event)"
        (remove)="removeHandler($event)"
      >
        <ng-template *appAuthGuard="editPolicy" kendoGridToolbarTemplate>
          <button kendoGridAddCommand class="btn em-btn"><i class="fal fa-plus-circle"></i> Add New Membership</button>
        </ng-template>
        <!-- Active -->
        <kendo-grid-column field="isActive" title="Active" [width]="25" [filterable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div style="text-align: center; width: 100%">
              <label class="switch">
                <input
                  type="checkbox"
                  name="isActive"
                  aria-label="Active"
                  id="isActive"
                  [checked]="dataItem.isActive"
                  [disabled]="!editPolicySatisfied"
                  (change)="updateIsActive(dataItem, $event)"
                />
                <span [ngStyle]="{ 'pointer-events': 'none' }" class="slider round"></span>
              </label>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="name" title="Name" [width]="75"></kendo-grid-column>
        <kendo-grid-column field="description" title="Description" [width]="150"></kendo-grid-column>
        <kendo-grid-column title="Image" [width]="100" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="image-placeholder" [style.backgroundImage]="'url(' + getImageUrl(dataItem) + ')'"></div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="amount"
          title="Price"
          [width]="50"
          filter="numeric"
          format="{0:c}"
        ></kendo-grid-column>
        <kendo-grid-column field="taxes" title="Taxes" [width]="50">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ formatTaxesToString(dataItem) }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="billingFrequency" title="Billing Frequency" [width]="50">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ getBillingFrequency(dataItem) }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="cancellableAfter" title="Cancellable After" [width]="50" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.cancellableAfter ? dataItem.cancellableAfter + ' month(s)' : 'Anytime' }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="expiresAfter" title="Expires After" [width]="50" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.expiresAfter ? dataItem.expiresAfter + ' month(s)' : 'No expiry' }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="allowableRedemptions" title="Redemptions" [width]="50" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.allowableRedemptions ? dataItem.allowableRedemptions + ' per month' : 'Unlimited' }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-command-column *appAuthGuard="editPolicy" title="Actions" min-width="35" [width]="35">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            <button kendoGridEditCommand class="btn kendo-btn"><i class="fas fa-edit"></i></button>
            <button kendoGridRemoveCommand class="btn kendo-btn"><i class="fas fa-minus-circle"></i></button>
          </ng-template>
        </kendo-grid-command-column>
      </kendo-grid>
    </div>
  </div>
</div>

<app-actionpanel></app-actionpanel>
