import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Clinic } from '@models/clinic';
import { ClinicsService } from '@services/clinics.service';
import { Editor } from 'primeng/editor';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-org-invoice-text-editor',
  templateUrl: './org-invoice-text-editor.component.html',
  styleUrls: ['./org-invoice-text-editor.component.less']
})
export class OrgInvoiceTextEditorComponent implements OnInit, OnDestroy {

  loading: boolean;
  clinicId: number;
  clinic: Clinic;
  invoiceText: string;
  unsub = new Subject<any>();

  @ViewChild('editor', { static: true }) editor: Editor;

  constructor(private clinicsService: ClinicsService) { }

  ngOnInit() {
    this.clinicsService.clinicIdSelected$
      .asObservable()
      .pipe(takeUntil(this.unsub))
      .subscribe((clinicId) => {
        this.clinicId = clinicId;
        this.init();
      });
  }

  init() {
    this.loading = true;
    this.clinicsService
      .getClinicById(this.clinicId)
      .pipe(takeUntil(this.unsub))
      .subscribe({
        next: (clinic) => {
          this.clinic = clinic;
          this.invoiceText = clinic.customInvoiceText;
          this.loading = false;
        }
      });
  }

  onSave() {
    // Get the clinic in case it's updated
    this.loading = true;
    this.clinicsService
      .getClinicById(this.clinicId)
      .pipe(takeUntil(this.unsub))
      .subscribe({
        next: (clinic) => {
          // Update the clinic's invoice text
          clinic.customInvoiceText = this.invoiceText;
          this.clinicsService
            .updateClinic(clinic)
            .pipe(takeUntil(this.unsub))
            .subscribe({
              next: () => {
                // Update the clinic's customInvoiceText to use for the reset button
                this.clinic.customInvoiceText = this.invoiceText;
                this.clinicsService.clinicUpdated.next(this.clinic);
                this.loading = false;
              },
              complete: () => {
                this.loading = false;
              }
            });
        }
      });
  }

  onReset() {
    this.invoiceText = this.clinic.customInvoiceText;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

}
