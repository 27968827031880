import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Aborter,
  BlockBlobURL,
  ContainerURL,
  IUploadToBlockBlobOptions,
  uploadBrowserDataToBlockBlob,
  Pipeline,
  BlobURL,
} from '@azure/storage-blob'; 
import { BlobDownloadResponse } from '@azure/storage-blob/typings/src/generated/src/models';
import * as moment from 'moment';
import { Observable, throwError, timer, from, ReplaySubject, of } from 'rxjs';
import { switchMap, retryWhen, tap, map, take } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { AuthService } from '@app/auth/auth.service';
import { removeURIQueryString } from '@app/lib/fun';

interface IReadOnlySAS {
  sas: string; // query string
  expiry: number; // unix timestamp (seconds)
}

/**
 * This is to get the parts of a URI that matter (container and blob)
 * @param blobUri is the uri to be decoded
 * @returns Array of [containerId, blobNameDecoded]
 */
export function decodeBlobURI(blobUri: string) {
  // containerId is after the first slash in the uri and before the second however a typical uri has a scheme //:
  // https://emilyemrprodstorage.blob.core.windows.net/<containerId>/<directory>%2F1%2F789736e7-c48a-4f90-94d5-3693996b578bhyperlight.JPG
  const splitURI = blobUri.split('/');
  const containerId = splitURI[3];
  return [containerId, decodeURIComponent(splitURI[4])];
}

@Injectable({
  providedIn: 'root'
})
export class BlobService {
  private _blobContainerURL: ContainerURL;
  private _readOnlySASSource$ = new ReplaySubject<IReadOnlySAS>(1);
  private _readOnlySAS$ = this._readOnlySASSource$.asObservable();
  private _currentReadOnlySAS: string;

  constructor(private http: HttpClient, private authService: AuthService) {
    // Trigger the blobService to get the read only SAS after successful login
    this.authService.userIdUpdated$.subscribe(userId => {
      if (userId) {
        this.requestReadOnlySAS();
      } else {
        this.resetReadOnlySAS();
      }
    });

    this._currentReadOnlySAS = '';
  }

  getReadOnlySAS() {
    return this._currentReadOnlySAS;
  }

  getReadOnlySASObservable() {
    return this._readOnlySAS$.pipe(map(rosas => rosas.sas));
  }

  resetReadOnlySAS() {
    this._currentReadOnlySAS = '';
    this._readOnlySASSource$.next({ sas: '', expiry: 0 });
  }

  requestReadOnlySAS() {
    const recurse = () => {
      this.http
        .get<IReadOnlySAS>(`${environment.baseUrl}api/AzureStorage/GetReadOnlySAS`)
        .pipe(take(1))
        .subscribe(roSAS => {
          // run this function again 10 seconds after expiry time (which is 50 seconds before the actual SAS expires)
          let time = moment
            .unix(roSAS.expiry)
            .add(10, 'seconds')
            .diff(moment(), 'milliseconds');
          // setTimeout stores the delay as 32-bit integer. We were getting overflow, causing infinite requests.
          // https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#maximum_delay_value
          time = Math.min(time, 2147483646);
          setTimeout(
            recurse,
            time
          );

          this._currentReadOnlySAS = roSAS.sas;
          this._readOnlySASSource$.next(roSAS);
        });
    };
    recurse();
  }

  checkUnixExpiry(expiry: number): boolean {
    return moment.unix(expiry).isAfter(moment());
  }

  uploadFileToBlobStorage(formFile: File, blobName: string, options: IUploadToBlockBlobOptions): Observable<string> {
    const uploadFileObservable: Observable<string> = new Observable(observer => {
      try {
        const blockBlobURL = BlockBlobURL.fromContainerURL(this._blobContainerURL, blobName);

        uploadBrowserDataToBlockBlob(Aborter.none, formFile, blockBlobURL, options)
          .then(res => {
            observer.next(res._response.request.url);
          })
          .catch(error => {
            observer.error(error);
          });
      } catch (e) {
        observer.error(e);
      }
    }).pipe(
      map((blobURIWithSAS: string) => {
        return blobURIWithSAS.split('?')[0];
      }),
      // Added the following for if there is an error - we get the current sas container uri from the back end and try again
      // fail over 3 times and the error will be returned
      retryWhen(error =>
        error.pipe(
          switchMap((err, i) => {
            const maxRetryAttempts = 3;
            const retryDelay = 2000;

            if (i >= maxRetryAttempts) {
              return throwError(err);
            } else {
              return timer((i + 1) * retryDelay).pipe(
                switchMap(() =>
                  this.getSASContainerURI().pipe(
                    tap(
                      sasContainerUri =>
                        (this._blobContainerURL = new ContainerURL(sasContainerUri.sasUri, new Pipeline([])))
                    )
                  )
                )
              );
            }
          })
        )
      )
    );

    if (!this._blobContainerURL) {
      return this.getSASContainerURI().pipe(
        switchMap(sasContainerUri => {
          this._blobContainerURL = new ContainerURL(sasContainerUri.sasUri, new Pipeline([]));

          return uploadFileObservable;
        })
      );
    }

    return uploadFileObservable;
  }

  // Get Shared Access Signature URI from back end
  getSASContainerURI(containerId = null): Observable<{ sasUri: string }> {
    if (containerId) {
      return this.http.get<{ sasUri: string }>(
        `${environment.baseUrl}api/AzureStorage/GetSAS?containerId=${containerId}`
      );
    }
    return this.http.get<{ sasUri: string }>(`${environment.baseUrl}api/AzureStorage/GetSAS`);
  }

  /**
   * @param blobUri string - is the blobs read uri
   */
  downloadFileFromBlobStorage(blobUri: string): Observable<BlobDownloadResponse> {
    blobUri = removeURIQueryString(blobUri);
    const [containerId, blobName] = decodeBlobURI(blobUri);

    return this.getSASContainerURI(containerId).pipe(
      switchMap(sasContainerUri => {
        try {
          const containerURL = new ContainerURL(sasContainerUri.sasUri, new Pipeline([]));

          const blobURL = BlobURL.fromContainerURL(containerURL, blobName);

          return from(blobURL.download(Aborter.none, 0));
        } catch (e) {
          return throwError(e);
        }
      })
    );
  }

  /**
   * @param blobUri string - is the blobs read uri
   */
  removeFileFromBlobStorage(blobUri: string) {
    blobUri = removeURIQueryString(blobUri);
    const [containerId, blobName] = decodeBlobURI(blobUri);

    return this.getSASContainerURI(containerId).pipe(
      switchMap(sasContainerUri => {
        try {
          const containerURL = new ContainerURL(sasContainerUri.sasUri, new Pipeline([]));

          const blobURL = BlobURL.fromContainerURL(containerURL, blobName);

          return from(blobURL.delete(Aborter.none));
        } catch (e) {
          return throwError(e);
        }
      })
    );
  }

  /**
   * convertBase64toBlob - take base64 string of any type and return a binary large object (blob) in promise
   * @param base64: string - representing base64 data
   * @param contentType: string - mimetype defined here: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
   */
  convertBase64toBlob(base64: string, contentType:string): Blob{
    const sliceSize = 512;
    const byteCharacters = atob(base64);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob: Blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
}
