import { Resource } from "@models/resource";

export class ServiceTemplateResource {
  id: number;
  serviceTemplateId: number;
  resourceId: number;
  resource: Resource;

  constructor(init?: Partial<ServiceTemplateResource>) {
    Object.assign(this, init);
  }
}
