<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>
<app-org-company></app-org-company>
<app-select-clinic
  class="main-clinic-select"
  [showDevClinic]="currentRoute === 'users' || currentRoute === 'user-permissions'"
></app-select-clinic>
<nav mat-tab-nav-bar class="border-0">
  <button
    *ngFor="let tab of authorizedTabLinks | keyvalue : originalOrder"
    mat-tab-link
    class="btn em-btn em-btn-nav-tab tab-btn"
    [routerLink]="tab.value"
    [active]="currentRoute === tab.value"
    [ngClass]="{ 'selected': currentRoute === tab.value }"
  >
    {{ tab.key | uppercase }}
  </button>
</nav>
<div class="header-line mx-1 mb-2"></div>
<router-outlet></router-outlet>
<p-overlayPanel #hoverPanel id="hoverPanel" [dismissable]="false"> Content </p-overlayPanel>
<buttonpp-actionpanel></buttonpp-actionpanel>
