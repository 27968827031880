<h1 mat-dialog-title>Warning - Service Provider Not Available</h1>
<div mat-dialog-content>
  <p [innerHTML]="content"></p>
  <h2 mat-dialog-title>Please choose another provider</h2>
  <span><strong>Please choose one of the following providers who are available to perform the service.</strong></span>
  <form class="mt-2">
    <button
      mat-button
      *ngFor="let serviceProvider of providers"
      class="select-provider-modal-selection"
      [title]="getTitle(serviceProvider)"
      [disabled]="isDisabled(serviceProvider)"
      (click)="providerSelected(serviceProvider)"
      cdkFocusInitial
    >
      {{ serviceProvider.title }}
    </button>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-button #btnFocus="matButton" [autofocus]="btnFocus.focus()" (click)="cancel()">Cancel</button>
</div>
