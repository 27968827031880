import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { PatientService } from '@services/patient.service';
import { ReportsService } from '@services/reports.service';
import { Subject } from 'rxjs';
import { distinct, mergeMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-supply-details',
  templateUrl: './supply-details.component.html',
  styleUrls: ['./supply-details.component.less'],
})
export class SupplyDetailsComponent implements OnInit, OnDestroy {
  @Input() observationListId: number;
  @Input() gridState: DataSourceRequestState;

  gridView: GridDataResult;
  loading: boolean = true;
  unsub = new Subject<any>();

  constructor(private router: Router, private reportsService: ReportsService, private patientService: PatientService) {}

  ngOnInit() {
    this.reportsService.reportsModelUpdated
      .pipe(
        distinct(),
        tap(() => (this.loading = true)),
        mergeMap(() => this.reportsService.getSupplyReportDetails(this.observationListId, this.gridState)),
        tap(() => (this.loading = false))
      )
      .subscribe((gridDataResult) => (this.gridView = gridDataResult));
  }

  navigateToPatientInvoice(patientId: number, invoiceId: number) {
    this.patientService
      .getPatientById(patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((patient: any) => {
        this.patientService.patientPanelPatient = patient;
        this.router.navigate([
          '/reports',
          {
            outlets: {
              'action-panel': [
                'patient',
                patientId + '_patientprofiletab',
                'patienttabs',
                'patientaccounttab',
                'invoice',
                invoiceId,
              ],
            },
          },
        ]);
      });
  }

  navigateToPatientAccountTab(patientId: number) {
    this.patientService
      .getPatientById(patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((patient: any) => {
        this.patientService.patientPanelPatient = patient;
        this.router.navigate([
          '/reports',
          {
            outlets: {
              'action-panel': [
                'patient',
                patientId + '_patientprofiletab',
                'patienttabs',
                'patientaccounttab',
                'overview',
              ],
            },
          },
        ]);
      });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
