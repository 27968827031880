import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { Subject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { BlobService } from './blob.service';
import { Tag } from '@models/tag/tag';
import { isNullOrUndefined } from '@app/shared/helpers';
import { PhotoConsentType } from '@models/photo/photo-consent-type';

@Injectable({
  providedIn: 'root',
})
export class PhotosFilterService {
  private filterSource = new Subject<any>();
  filterSource$ = this.filterSource.asObservable();

  constructor(private http: HttpClient, private blobService: BlobService) {}

  addTags(tags: Tag[]) {
    this.filterSource.next(tags);
  }

  getPatientPhotosByTags(tags: Tag[], patientId: number, consent: string[]) {
    const filteredTags = tags.filter((i) => i.tagId.indexOf('Consent-') == -1).map((e) => e.title);

    const params = {
      patientId: String(patientId),
      tagList: filteredTags,
    };
    if (consent) {
      params['consent'] = consent;
    }

    return combineLatest([
      this.blobService.getReadOnlySASObservable(),
      this.http.get<PhotoMetaData[]>(environment.baseUrl + 'api/Tag/PatientPhotosByTags', { params: params }),
    ]).pipe(
      map(([readOnlySAS, images]) => {
        images.forEach((image) => {
          image.filePath = (image.filePath ? image.filePath.trim() : '') + readOnlySAS;
          image.filePathThumb = (image.filePathThumb ? image.filePathThumb.trim() : '') + readOnlySAS;
          if (!isNullOrUndefined(image.seriesPhotos)) {
            image.seriesPhotos.forEach((sp: PhotoMetaData) => {
              sp.filePath = (sp.filePath ? sp.filePath.trim() : '') + readOnlySAS;
              sp.filePathThumb = (sp.filePathThumb ? sp.filePathThumb.trim() : '') + readOnlySAS;
            });
          }
        });
        return images;
      })
    );
  }

  getConsentedPhotosByTags(tags: Tag[]) {
    const filteredTags = tags.filter((i) => i.tagId.indexOf('Consent-') == -1).map((e) => e.title);
    const consent = tags
      .filter((i) => {
        if (i.tagId.indexOf('Consent-') != -1) {
          return i.title;
        }
      })
      .map((e) => e.refId);

    const params = {
      tagList: filteredTags,
    };
    if (consent) {
      params['consent'] = consent;
    }

    return combineLatest([
      this.blobService.getReadOnlySASObservable(),
      this.http.get<PhotoMetaData[]>(environment.baseUrl + 'api/Tag/ConsentedPhotosByTags', { params: params }),
    ]).pipe(
      map(([readOnlySAS, images]) => {
        images.forEach((image) => {
          image.filePath = (image.filePath ? image.filePath.trim() : '') + readOnlySAS;
          image.filePathThumb = (image.filePathThumb ? image.filePathThumb.trim() : '') + readOnlySAS;
          if (!isNullOrUndefined(image.seriesPhotos)) {
            image.seriesPhotos.forEach((sp: PhotoMetaData) => {
              sp.filePath = (sp.filePath ? sp.filePath.trim() : '') + readOnlySAS;
              sp.filePathThumb = (sp.filePathThumb ? sp.filePathThumb.trim() : '') + readOnlySAS;
            });
          }
        });
        return images;
      })
    );
  }

  getGlobalSharedPhotosByTags(tags: Tag[]) {
    const filteredTags = tags.filter((i) => i.tagId.indexOf('Consent-') == -1).map((e) => e.title);
    const consent = tags
      .filter((i) => {
        if (i.tagId.indexOf('Consent-') != -1) {
          return i.title;
        }
      })
      .map((e) => e.refId);

    const params = {
      tagList: filteredTags,
    };
    if (consent) {
      params['consent'] = consent;
    }

    return combineLatest([
      this.blobService.getReadOnlySASObservable(),
      this.http.get<PhotoMetaData[]>(environment.baseUrl + 'api/Tag/GlobalSharedPhotosByTags', { params: params }),
    ]).pipe(
      map(([readOnlySAS, images]) => {
        images.forEach((image) => {
          image.filePath = (image.filePath ? image.filePath.trim() : '') + readOnlySAS;
          image.filePathThumb = (image.filePathThumb ? image.filePathThumb.trim() : '') + readOnlySAS;
          if (!isNullOrUndefined(image.seriesPhotos)) {
            image.seriesPhotos.forEach((sp: PhotoMetaData) => {
              sp.filePath = (sp.filePath ? sp.filePath.trim() : '') + readOnlySAS;
              sp.filePathThumb = (sp.filePathThumb ? sp.filePathThumb.trim() : '') + readOnlySAS;
            });
          }
        });
        return images;
      })
    );
  }

  getPatientPhotosSearchTerm(searchText: string, isGlobalSharedImage: boolean, patientId: number) {
    const params = {
      isGlobalSharedImage: isGlobalSharedImage ? String(isGlobalSharedImage) : 'false',
      searchText: searchText,
    };

    if (!isNullOrUndefined(patientId)) {
      params['patientId'] = patientId;
    }

    return combineLatest([
      this.blobService.getReadOnlySASObservable(),
      this.http.get<PhotoMetaData[]>(environment.baseUrl + `api/Photo/SearchPhotosAndSeriesBasedOnTag`, {
        params: params,
      }),
    ]).pipe(
      map(([readOnlySAS, images]) => {
        images.forEach((image) => {
          image.filePath = (image.filePath ? image.filePath.trim() : '') + readOnlySAS;
          image.filePathThumb = (image.filePathThumb ? image.filePathThumb.trim() : '') + readOnlySAS;
          if (!isNullOrUndefined(image.seriesPhotos)) {
            image.seriesPhotos.forEach((sp: PhotoMetaData) => {
              sp.filePath = (sp.filePath ? sp.filePath.trim() : '') + readOnlySAS;
              sp.filePathThumb = (sp.filePathThumb ? sp.filePathThumb.trim() : '') + readOnlySAS;
            });
          }
        });
        return images;
      })
    );
  }
}
