import { Injectable } from '@angular/core';
import { CancellationReason } from '@models/cancellation-reason';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable()
export class CancellationReasonService {

  constructor(private http: HttpClient) { }

  addCancellationReason(cancellationReason: CancellationReason) {
    return this.http.post<CancellationReason>(environment.baseUrl + 'api/CancellationReasons', cancellationReason);
  }

  updateCancellationReason(cancellationReason: CancellationReason) {
    return this.http.put<void>(environment.baseUrl + 'api/CancellationReasons/' + cancellationReason.cancellationReasonId, cancellationReason);
  }

  removeCancellationReason(cancellationReason: CancellationReason) {
    return this.http.delete(environment.baseUrl + 'api/CancellationReasons/' + cancellationReason.cancellationReasonId);
  }

  saveCancellationReason(cancellationReason: CancellationReason, isNew: boolean) {
    if (isNew) {
      return this.addCancellationReason(cancellationReason);
    } else {
      return this.updateCancellationReason(cancellationReason);
    }
  }

  getCancellationReason(clinicId: number = +localStorage.getItem('clinicId')) {
    return this.http.get<CancellationReason[]>(environment.baseUrl + 'api/CancellationReasons');
  }

  getCancellationReasonById(cancellationReasonId) {
    return this.http.get<CancellationReason>(environment.baseUrl + 'api/CancellationReasons/' + cancellationReasonId);
  }
}
