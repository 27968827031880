import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { EventsService } from '@services/events.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-calnav-noheader',
  templateUrl: './calnavnoheader.component.html',
  styleUrls: ['./calnavnoheader.component.less'],
})
export class CalnavnoheaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() calendarNumber: number;
  @Input() previousCalendar: MatCalendar<Date>;
  @Input() weekStart: moment.Moment;
  @Input() weekEnd: moment.Moment;
  @ViewChild('matCalendar', { static: true }) matCalendar: MatCalendar<any>;
  @Output() changeDate = new EventEmitter<string>();
  selectedDate: Date;
  private unsub = new Subject<void>();

  constructor(private _eventService: EventsService) {}

  ngOnInit() {
    this._eventService.currentDate.pipe(takeUntil(this.unsub)).subscribe((date) => {
      this.selectedDate = date;
    });
  }

  private setStart() {
    if (this.previousCalendar.monthView) {
      const activeDate = moment(this.previousCalendar.monthView.activeDate).add(1, 'month').toDate();
      this.matCalendar.monthView.activeDate = activeDate;
      this.matCalendar.stateChanges.next();
    }
  }

  ngAfterViewInit() {
    this.previousCalendar.stateChanges.pipe(takeUntil(this.unsub)).subscribe(() => this.setStart());
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  selectedChange = (event) => {
    this.selectedDate = event.toDate();
    this.changeDate.emit(event);
  };
}
