import { CdkDrag, CdkDragDrop, CdkDropList, copyArrayItem, transferArrayItem } from '@angular/cdk/drag-drop';
import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthRoleClaimsService } from '@app/auth/auth-role-claims.service';
import { Claim, Role } from '@models/user';
import { forkJoin } from 'rxjs';
import { map, mergeAll, mergeMap, toArray } from 'rxjs/operators';

@Component({
  selector: 'app-org-role-permissions',
  templateUrl: './org-role-permissions.component.html',
  styleUrls: ['./org-role-permissions.component.less'],
})
export class OrgRolePermissionsComponent implements OnInit {
  roles: Map<string, string> = new Map();
  roleClaims: Map<string, Claim[]> = new Map();
  claims: Claim[] = [];
  loading = false;

  constructor(private authRoleClaimsService: AuthRoleClaimsService) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.roles.clear();
    this.roleClaims.clear();
    forkJoin([
      this.authRoleClaimsService.getRoles().pipe(
        mergeAll(),
        mergeMap((role) =>
          this.authRoleClaimsService
            .getRoleClaims(role.id)
            .pipe(map<Claim[], [Role, Claim[]]>((claims) => [role, claims]))
        ),
        toArray()
      ),
      this.authRoleClaimsService.getClaims(),
    ]).subscribe(([roleClaims, allClaims]) => {
      roleClaims.forEach((roleClaim) => {
        const [role, claims] = roleClaim;
        this.roles.set(role.id, role.name);
        this.roleClaims.set(role.id, claims);
      });
      this.claims = allClaims;
      this.loading = false;
    });
  }

  onDrop(event: CdkDragDrop<Claim[], Claim[], Claim>) {
    if (event.previousContainer !== event.container) {
      const claim = event.item.data;
      const roleId = event.container.id;
      const previousRoleId = event.previousContainer.id;
      const insertionIndex = this.getInsertionIndex(
        claim.value,
        event.container.data.map((claim) => claim.value)
      );
      if (previousRoleId === 'allClaims') {
        copyArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, insertionIndex);
        this.authRoleClaimsService.setRoleClaim(roleId, claim).subscribe();
      } else {
        transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, insertionIndex);
        this.authRoleClaimsService.removeRoleClaim(previousRoleId, claim.value).subscribe();
        this.authRoleClaimsService.setRoleClaim(roleId, claim).subscribe();
      }
    }
  }

  private getInsertionIndex(insertion: string, existing: string[]): number {
    for (let i = 0; i < existing.length; i++) {
      if (existing[i].localeCompare(insertion) > 0) return i;
    }
    return existing.length;
  }

  onRemove(roleId: string, claimValue: string) {
    const currentClaims = this.roleClaims.get(roleId);
    this.roleClaims.set(
      roleId,
      currentClaims.filter((claim) => claim.value !== claimValue)
    );
    this.authRoleClaimsService.removeRoleClaim(roleId, claimValue).subscribe();
  }

  neverPredicate(): boolean {
    return false;
  }

  claimValuePredicate(dragEvent: CdkDrag<Claim>, dropList: CdkDropList<Claim[]>): boolean {
    const claimValue = dragEvent.data.value;
    const canEnter = !dropList.data.some((claim) => claim.value === claimValue);
    return canEnter;
  }

  valueAscOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return a.value.localeCompare(b.value);
  };
}
