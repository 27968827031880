import { Router } from '@angular/router';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ReportsService } from '@services/reports.service';
import { Subject } from 'rxjs';
import { distinct, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { DataSourceRequestState, orderBy } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { Tax } from '@models/reports/tax/tax';

@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.less'],
})
export class ServiceDetailComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  unsub = new Subject<any>();
  gridView: GridDataResult;
  gridState: DataSourceRequestState;

  @Input() possibleTaxes: Tax[] = [];
  @Input() clinicServiceTemplateId: number;

  constructor(private router: Router, private reportsService: ReportsService) {}

  ngOnInit() {
    this.initDefaultValue();
    this.reportsService.reportsModelUpdated
      .pipe(
        distinct(),
        tap(() => (this.loading = true)),
        mergeMap(() => this.reportsService.getServiceDetailReportEntities(this.clinicServiceTemplateId, this.gridState)),
        tap(() => (this.loading = false))
      )
      .subscribe((gridDataResult) => (this.gridView = gridDataResult));
  }

  initDefaultValue() {
    this.gridState = {
      skip: 0,
      take: 100,
      sort: [{ field: 'patientName', dir: 'asc' }],
    };

    this.gridView = {
      data: [],
      total: 0,
    };
  }

  getReportData() {
    this.loading = true;

    this.reportsService
      .getServiceDetailReportEntities(this.clinicServiceTemplateId, this.gridState)
      .pipe(takeUntil(this.unsub))
      .subscribe((serviceDetailReports: any) => {
        this.gridView = serviceDetailReports;
        this.loading = false;
      });
  }

  stateChange(gridState: DataStateChangeEvent) {
    this.gridState = gridState;
    this.gridView.data = orderBy(this.gridView.data, this.gridState.sort);
  }

  sortIconClass(column: any) {
    return this.gridState.sort.length &&
      this.gridState.sort[0].field === column.field &&
      this.gridState.sort[0].dir === 'asc'
      ? 'fad fa-sort-up'
      : this.gridState.sort.length &&
        this.gridState.sort[0].field === column.field &&
        this.gridState.sort[0].dir === 'desc'
      ? 'fad fa-sort-down'
      : 'fas fa-sort';
  }

  navigateToPatient(patientId: number) {
    this.router.navigate([
      '/reports',
      {
        outlets: {
          'action-panel': ['patient', patientId + '_patientprofiletab', 'patienttabs', 'patientprofiletab'],
        },
      },
    ]);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
