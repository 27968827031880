import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { DefaultHttpClient, HttpError, HttpRequest, HttpResponse } from '@microsoft/signalr';

@Injectable({
  providedIn: 'root',
})
export class SignalrHttpClientService extends DefaultHttpClient {
  private _authService: AuthService;

  constructor(authService: AuthService) {
    super(console);
    this._authService = authService;
  }

  public async send(request: HttpRequest): Promise<HttpResponse> {
    try {
      const response = await super.send(request);
      return response;
    } catch (err) {
      if (err instanceof HttpError && err.statusCode === 401) {
        console.warn('SignalR - Attempting token refresh after unauthorized api call...');
        let token = await this._authService.refreshTokenRequest('emilyEMRActiveUserRefresh').toPromise();
        request.headers['Authorization'] = `Bearer ${token}`;
        return super.send(request);
      } else {
        throw err;
      }
    }
  }
}
