import { MatDialog } from '@angular/material/dialog';
import { DocumentDetailsComponent } from './../../../patients-documents-tab/document-details/document-details.component';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, first, catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { PatientDocument } from '@models/document/patient-document';
import { PatientDocumentService } from '@services/patient-documents.service';
import { AssignClinicFormComponent } from '@app/patients/patient-tabs/patients-documents-tab/assign-clinic-form/assign-clinic-form.component';
import { DocumentType } from '@models/document/document-type';
import { PatientForm } from '@models/forms/patient-form';
import { FormType } from '@models/forms/form-type';
import { PatientFormService } from '@services/patient-form.service';
import { FormTypeEnum } from '@models/forms/form-type.enum';

interface ListItem {
  id: number;
  isLegacy: boolean;
  form: PatientDocument | PatientForm;
  name: string;
  date: Date;
}

@Component({
  selector: 'app-document-filter-list',
  templateUrl: './document-filter-list.component.html',
  styleUrls: ['./document-filter-list.component.less'],
})
export class DocumentFilterListComponent implements OnInit, OnDestroy {
  @Input() showFilters: boolean = true;
  @Input() patientId: number;
  formTypeEnum = FormTypeEnum;
  formTypeList: string[];
  listTypes: (DocumentType | FormType)[] = [];
  listItems: ListItem[];
  defaultListItems: ListItem[];
  isFiltered = false;
  isLoading = false;
  errorMessage = '';
  unsub: Subject<void> = new Subject<void>();

  private legacyForms: PatientDocument[] = [];

  constructor(
    private patientDocumentService: PatientDocumentService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private patientFormService: PatientFormService
  ) {}

  ngOnInit() {
    this.formTypeList = Object.keys(this.formTypeEnum).filter((key) => Number.isNaN(parseInt(key)));
    this.formTypeList.push('Document');
    if (this.route.snapshot.params.patId) {
      this.patientId = +this.route.snapshot.params.patId.split('_')[0];
    }
    this.loadDocuments();

    this.patientFormService.patientForms$
      .pipe(takeUntil(this.unsub))
      .subscribe((patientForms) => this.updateForms(this.legacyForms, patientForms));
  }

  ngOnChanges() {
    this.loadDocuments();
  }

  private loadDocuments() {
    this.isLoading = true;
    forkJoin({
      docs: this.patientDocumentService.getDocuments(this.patientId).pipe(first()),
      forms: this.patientFormService.getPatientForms(this.patientId).pipe(first()),
    }).subscribe(
      (result) => {
        this.legacyForms = result.docs;
        this.updateForms(result.docs, result.forms);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.errorMessage = 'Unable to load documents. Please try again.';
      }
    );
  }

  private updateForms(docs: PatientDocument[], forms: PatientForm[]) {
    let legacyForms: ListItem[] =
      docs?.map((form) => ({
        id: form.id,
        isLegacy: true,
        form: form,
        name: form.name,
        date: new Date(form.uploadDate ? form.uploadDate : form.modifiedDate),
      })) ?? [];
    let newForms: ListItem[] =
      forms?.map((form) => ({
        id: form.id,
        isLegacy: false,
        form: form,
        name: form.clinicForm.name,
        date: new Date(form.assignedDate),
      })) ?? [];
    this.listItems = this.sortListItems([...legacyForms, ...newForms]);
    this.defaultListItems = this.listItems;
  }

  private sortListItems(listItems: ListItem[]) {
    return listItems.sort((itemA, itemB) => new Date(itemB.date).getTime() - new Date(itemA.date).getTime());
  }

  isPatientForm(doc: PatientDocument | PatientForm): doc is PatientForm {
    return (doc as PatientForm).formEntry !== undefined;
  }

  navigateToAllDocuments() {
    this.router.navigate(['../../patientdocumentstab/'], { relativeTo: this.route });
  }

  assignForm() {
    let assignModal = this.modalService.open(AssignClinicFormComponent);
    assignModal.result.then(async (form) => {
      if (form) {
        this.isLoading = true;
        const patientForm = PatientForm.fromForm(this.patientId, form);
        const assignedForm = await this.patientFormService
          .addPatientForm(patientForm)
          .pipe(
            catchError((err) => {
              this.isLoading = false;
              this.errorMessage = 'Unable to assign form. Please try again.';
              throw err;
            })
          )
          .toPromise();
        this.isLoading = false;
        let dialogRef = this.dialog.open(DocumentDetailsComponent, {
          panelClass: 'document-view-modal',
          disableClose: true,
        });
        await dialogRef.afterOpened().toPromise();
        this.patientFormService.patientFormSelected$.next(assignedForm);
      }
    });
  }

  onFilterDocument(formType: string) {
    if (formType == 'Document') {
      this.listItems = this.listItems.filter((li) => li.isLegacy);
    } else {
      this.listItems = this.listItems.filter((li) => {
        let form = li.form as PatientForm;
        return form.clinicForm?.formTypeId === this.formTypeEnum[formType];
      });
    }
    this.isFiltered = true;
  }

  clearFilter() {
    this.isFiltered = false;
    this.listItems = this.defaultListItems;
  }

  onSelectItem(item: ListItem) {
    let dialogRef = this.dialog.open(DocumentDetailsComponent, {
      panelClass: 'document-view-modal',
      disableClose: true,
    });
    dialogRef
      .afterOpened()
      .pipe(first())
      .subscribe(() => {
        if (this.isPatientForm(item.form)) {
          this.patientFormService.patientFormSelected$.next(item.form);
        } else {
          this.patientDocumentService.documentSelected.next(item.form);
        }
      });
  }

  ngOnDestroy() {
    this.patientFormService.patientForms$.next(null);
    this.unsub.next();
    this.unsub.complete();
  }
}
