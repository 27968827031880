import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { PaymentMethod } from '@models/finance/payment-method';
import { environment } from '@environments/environment';
import { InvoicePayment } from '@models/finance/invoice-payment';
import { LoyaltyConverting } from '@models/finance/loyalty-convering';
import { InvoiceTransaction } from '@models/finance/invoice-transaction';
import { ResultModel } from '@models/result-model';
import { PaidCancellationInvoiceTransaction } from '@models/finance/paid-cancellation-invoice-transaction';
import { RequestCreditCard } from '@models/square/request-credit-card';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FinanceService {
  invoicePaid = new Subject();
  invoicePaid$ = this.invoicePaid.asObservable();
  private unsub = new Subject<any>();

  constructor(private http: HttpClient, private dialog: MatDialog) {}

  addTransactions(invoicePayment: InvoicePayment) {
    return this.http.post(`${environment.baseUrl}api/Finance/AddTransactions`, invoicePayment);
  }

  addTransaction(invoiceTransaction: InvoiceTransaction) {
    return this.http.post<ResultModel<InvoiceTransaction>>(
      `${environment.baseUrl}api/Finance/AddFinancialTransaction`,
      invoiceTransaction
    );
  }

  addPaidCancellationRefundTransaction(paidCancellationInvoiceTransaction: PaidCancellationInvoiceTransaction) {
    return this.http.post<ResultModel<InvoiceTransaction>>(
      `${environment.baseUrl}api/Finance/AddPaidCancellationFinancialTransaction`,
      paidCancellationInvoiceTransaction
    );
  }

  getPatientAvailableCredit(patientId: number): Observable<number> {
    return this.http.get<number>(`${environment.baseUrl}api/Finance/GetPatientAvailableCredit/${patientId}`);
  }

  updatePatientAvailableCredit(patientId: number, newAvailableCreditAmount: number): Observable<number> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.put<number>(
      `${environment.baseUrl}api/Finance/UpdatePatientAvailableCredit/${patientId}`,
      newAvailableCreditAmount,
      httpOptions
    );
  }

  getOutstandingAmount(invoiceId: number): Observable<number> {
    return this.http.get<number>(`${environment.baseUrl}api/Finance/GetOutstandingAmount/${invoiceId}`);
  }

  getPatientAvailableLoyaltyPoints(patientId: number): Observable<number> {
    return this.http.get<number>(`${environment.baseUrl}api/Finance/GetPatientAvailableLoyaltyPoints/${patientId}`);
  }

  getPatientAvailableLoyaltyPointsDollarValue(patientId: number): Observable<number> {
    return this.http.get<number>(
      `${environment.baseUrl}api/Finance/GetPatientAvailableLoyaltyPointsDollarValue/${patientId}`
    );
  }

  getInvoicePaidAmount(invoiceId: number): Observable<number> {
    return this.http.get<number>(`${environment.baseUrl}api/Finance/GetPaidAmount/${invoiceId}`);
  }

  getFinanceTransaction(financeTransactionId: number): Observable<InvoiceTransaction> {
    return this.http.get<InvoiceTransaction>(
      `${environment.baseUrl}api/Finance/GetFinanceTransaction/${financeTransactionId}`
    );
  }

  convertLoyaltyPointsToCredit(loyaltyConvertingModel: LoyaltyConverting) {
    return this.http.post(`${environment.baseUrl}api/Finance/ConvertLoyaltyPointsToCredit`, loyaltyConvertingModel);
  }
  getInvoiceTransactions(invoiceId: number): Observable<InvoiceTransaction[]> {
    return this.http.get<InvoiceTransaction[]>(`${environment.baseUrl}api/Finance/GetInvoiceTransactions/${invoiceId}`);
  }

  voidInvoiceTransaction(invoiceTransaction: InvoiceTransaction): Observable<InvoiceTransaction> {
    return this.http.post<InvoiceTransaction>(
      `${environment.baseUrl}api/Finance/VoidFinanceTransaction`,
      invoiceTransaction
    );
  }

  requestCreditCardModal(patientId: number, appointmentId: number) {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '330px',
      data: {
        title: 'Send Request Credit Card Email?',
        content: 'Are you sure you want to send the patient a request to add a new credit card?',
        confirmButtonText: 'Yes',
        showCancel: true,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') {
          return this.requestCreditCard(patientId, appointmentId).subscribe(
            () => {
              this.dialog.open(GenericDialogComponent, {
                data: {
                  content: 'Email sent to patient successfully',
                  confirmButtonText: 'Ok',
                  showCancel: false,
                },
              });
            },
            (error) => {
              var errorMessage = 'An error occurred during processing.<br>';
              if (error.error?.errors) {
                const errors = error.error.errors;
                errorMessage += errors[0].fieldErrors[0];
              } else if (error.error) {
                errorMessage += `Status: ${error.error?.status} - Message: ${error.error?.title}`;
              } else {
                errorMessage += error?.message;
              }

              this.dialog.open(GenericDialogComponent, {
                data: {
                  title: 'Failed to send email',
                  content: '<div class="text-danger">' + errorMessage + '</div>',
                  confirmButtonText: 'Ok',
                  showCancel: false,
                },
              });
            }
          );
        }
      });
  }

  requestCreditCard(patientId: number, appointmentId: number) {
    let requestCreditCard = new RequestCreditCard();
    requestCreditCard.patientId = patientId;
    requestCreditCard.appointmentId = appointmentId;
    return this.http.post(`${environment.baseUrl}api/Finance/RequestCreditCardFromPatient`, requestCreditCard);
  }
}
