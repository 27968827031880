import { Patient } from '../patient';
import { PlannedTreatment } from './planned-treatment';
import { ProductRecommendation } from './product-recommendation';
import { ITransactionTargetItem } from '@interfaces/ITransactionTargetItem';
import { ColourVariables } from '../constants/colour-variables';
import { TreatmentPlanPhoto } from './treatment-plan-photo';
import { PlannedTreatmentMultiple } from './planned-treatment-multiple';

export class TreatmentPlan implements ITransactionTargetItem {
  public id: number;
  public patient: Patient;
  public createdDate: Date;
  public updatedDate: Date;
  public notes: string;
  public treatmentQuestionsReviewed: boolean;
  public plannedTreatments: PlannedTreatment[];
  public productRecommendations: ProductRecommendation[];
  public photos: TreatmentPlanPhoto[] = [];
  public plannedTreatmentMultiples: PlannedTreatmentMultiple[];

  public constructor(init?: Partial<TreatmentPlan>) {
    Object.assign(this, init);
  }

  getColor(): string {
    return new ColourVariables().pink;
  }

  isRoutable(): boolean {
    return true;
  }
}
