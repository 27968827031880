import { ReportsService } from '@services/reports.service';
import { ClinicReportDetailsViewModel } from './../../../../services/reports.service'; 
import { Router } from '@angular/router';
import { Component, OnInit, Input, OnDestroy } from '@angular/core'; 
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { PatientService } from '@services/patient.service'; 
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
 
@Component({
  selector: 'app-sales-summary-details',
  templateUrl: './sales-summary-details.component.html',
  styleUrls: ['./sales-summary-details.component.less']
})
export class SalesSummaryDetailsComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  unsub = new Subject<any>(); 
  salesDetails: ClinicReportDetailsViewModel[] = [];
 
  @Input() gridState: DataSourceRequestState;
  @Input() summaryTitle: string; 

  constructor(
    private router: Router,
    private reportsService: ReportsService,
    private patientService: PatientService
  ) {
   
  }

  async ngOnInit() { 
    this.reportsService.getSalesSummaryDetails(  this.summaryTitle , this.gridState ).toPromise().catch(e => {
      this.loading = false;
      return null;
    }).then((salesDetails) => {
      this.salesDetails  = salesDetails; 
      this.loading = false;
    });
    
  }

  navigateToPatientInvoice(patientId: number, invoiceId: number) {
    this.patientService.getPatientById(patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((patient: any) => {
        this.patientService.patientPanelPatient = patient;
        this.router.navigate(['/reports', { outlets: { 'action-panel': ['patient', patientId + '_patientprofiletab', 'patienttabs', 'patientaccounttab', 'invoice', invoiceId] } }]);
      });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
