import { Injectable } from '@angular/core';
import { Service } from '@models/service/service';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { TaxIndicator } from '@models/finance/tax-indicator.enum';

@Injectable()
export class ServiceDefaultsService {
  defaultService: Service;
  defaultCoolsculpt: Service;
  defaultBotox: Service;

  defaultServiceTemplate: ClinicServiceTemplate = {
    id: 0,
    serviceTemplateTypeId: 0,
    serviceTemplateType: null,
    serviceDetailTemplateId: 0,
    serviceName: '',
    quantity: 0,
    serviceAltName: '',
    defaultDurationMinutes: 0,
    diagnosticCode: '',
    templateIcon: '',
    serviceCategoryId: 0,
    serviceCategory: {
      serviceCategoryId: 0,
      name: '',
    },
    defaultPrice: 0,
    status: true,
    attachedForms: null,
    serviceTaxes: null,
    governmentBilling: false,
    recommendedProducts: [],
    room: null,
    equipment: null,
    serviceRecProductsString: '',
    resourcesString: '',
    notes: null,
    signedBy: '',
    signedTime: null,
    serviceTemplateBillingCodes: [],
    taxIndicator: TaxIndicator.NoTax,
  };

  constructor() {
    this.setDefaultService();
    this.setDefaultCoolsculptService();
    this.setDefaultBotoxService();
  }

  setDefaultCoolsculptService() {
    this.defaultCoolsculpt = new Service();
    this.defaultCoolsculpt.setFullObject(
      0, // serviceId
      'CoolSculpt', // serviceName
      0, // quantity
      0, // serviceCategoryId
      {
        // category
        serviceCategoryId: 0,
        name: '',
      },
      '', // serviceRecProductsString
      '', // resourcesString
      0, // templateId
      null, // serviceTemplateId
      ServiceDetailTemplate.Coolsculpting,
      true, // status
      '', // serviceAltName
      0, // defaultDurationMinutes
      '', // diagnosticCode
      '', // templateIcon
      0, // defaultPrice
      null, // overrideDefaultPrice
      null, // attachedForms
      false, // governmentBilling
      null, // serviceTaxes
      null, // userCategories
      null, // room
      null, // serviceNotes
      null, //planned treatment note
      null, // servicePhotoPath
      null, // observations
      null, // signedTime
      '', // signedBy
      null, // equipment
      false, // isLocked,
      [] // serviceBillingCodes
    );
  }

  setDefaultService() {
    this.defaultService = new Service();
    this.defaultService.setFullObject(
      0, // serviceId
      '', // serviceName
      0, // quantity
      0, // serviceCategoryId
      {
        // category
        serviceCategoryId: 0,
        name: '',
      },
      '', // serviceRecProductsString
      '', // resourcesString
      0, // templateId
      null, // serviceTemplate
      ServiceDetailTemplate.None,
      true, // status
      '', // serviceAltName
      0, // defaultDurationMinutes
      '', // diagnosticCode
      '', // templateIcon
      0, // defaultPrice
      null, // overrideDefaultPrice
      null, // attachedForms
      false, // governmentBilling
      null, // serviceTaxes
      null, // userCategories
      null, // room
      null, // serviceNotes
      null, //planned treatment note
      null, // servicePhotoPath
      null, // observations
      null, // signedTime
      '', // signedBy
      null, // equipment
      false, // isLocked
      [] // serviceBillingCodes
    );
  }

  setDefaultBotoxService() {
    this.defaultBotox = new Service();
    this.defaultBotox.setFullObject(
      0, // serviceId
      'Botox', // serviceName
      0, // quantity
      0, // serviceCategoryId
      {
        // category
        serviceCategoryId: 0,
        name: '',
      },
      '', // serviceRecProductsString
      '', // resourcesString
      0, // templateId
      null, // serviceTemplate
      ServiceDetailTemplate.Injections,
      true, // status
      '', // serviceAltName
      0, // defaultDurationMinutes
      '', // diagnosticCode
      '', // templateIcon
      0, // defaultPrice
      null, // overrideDefaultPrice
      null, // attachedForms
      false, // governmentBilling
      null, // serviceTaxes
      null, // userCategories
      null, // room
      null, // serviceNotes
      null, //planned treatment note
      null, // servicePhotoPath
      null, // observations
      null, // signedTime
      '', // signedBy
      null, // equipment
      false, // isLocked
      [] // serviceBillingCodes
    );
  }
}
