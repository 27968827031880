<div class="edit-container position-absolute d-block z-index-3 white-background" *ngIf="!loading">
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0">
    <div class="row mx-0 teal-background">
      <div class="col px-0">
        <h6 class="white-font uppercase p-2 mb-0">{{ isNewUser ? 'Add' : 'Edit' }} User</h6>
      </div>
    </div>
    <app-error *ngIf="serverValidationError" [message]="serverValidationError"></app-error>

    <form [formGroup]="userForm" autocomplete="off">
      <div class="row mx-0 mt-2">
        <div class="col px-2">
          <mat-form-field>
            <input
              autocomplete="off"
              matInput
              placeholder="First Name"
              class="capitalized"
              aria-label="First Name"
              required
              (change)="formatterService.textCapitalize(userForm.get('firstName'))"
              formControlName="firstName"
            />
          </mat-form-field>
        </div>
        <div class="col px-2">
          <mat-form-field>
            <input
              autocomplete="off"
              matInput
              placeholder="Last Name"
              class="capitalized"
              aria-label="Last Name"
              required
              (change)="formatterService.textCapitalize(userForm.get('lastName'))"
              formControlName="lastName"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="row mx-0">
        <div class="col px-2">
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              aria-label="User Name"
              placeholder="User Name"
              formControlName="userName"
              required
            />
          </mat-form-field>
        </div>
        <div class="col px-2">
          <mat-form-field>
            <input autocomplete="off" matInput placeholder="Email" aria-label="Email" formControlName="email" />
            <mat-error *ngIf="userForm.get('email').hasError('email')">Not a Valid Email</mat-error>
            <mat-error *ngIf="userForm.get('email').hasError('required')"
              >Cannot be empty with Patient Portal</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div class="row mx-0">
        <div class="col px-2">
          <mat-form-field style="width: 180px">
            <input
              autocomplete="new-password"
              matInput
              required="{{ isNewUser }}"
              placeholder="{{ isNewUser ? 'Enter password' : 'New password (optional)' }}"
              [type]="hidePassword ? 'password' : 'text'"
              aria-label="Password"
              formControlName="password"
            />
            <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword">
              <mat-icon class="larger-icon">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <mat-error *ngIf="userForm.get('password').hasError('passwordsNotMatching')"
              >Passwords do not match</mat-error
            >
            <mat-error *ngIf="userForm.get('password').hasError('minLength')">Passwords to short</mat-error>
            <mat-error *ngIf="userForm.get('password').hasError('maxLength')">Passwords to long</mat-error>
            <mat-error *ngIf="userForm.get('password').hasError('noUpperCase')"
              >Password has no uppercase letter</mat-error
            >
            <mat-error *ngIf="userForm.get('password').hasError('noLowerCase')"
              >Password has no lowercase letter</mat-error
            >
            <mat-error *ngIf="userForm.get('password').hasError('noNumberCharacter')">Password has no number</mat-error>
            <mat-error *ngIf="userForm.get('password').hasError('noSpecialCharacter')"
              >Password has no special character</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col px-2">
          <mat-form-field style="width: 180px">
            <input
              autocomplete="new-password"
              matInput
              required="{{ isNewUser }}"
              placeholder="Confirm password"
              [type]="hideConfirmPassword ? 'password' : 'text'"
              aria-label="Password"
              formControlName="passwordConfirm"
            />
            <button mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">
              <mat-icon class="larger-icon">{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <mat-error *ngIf="userForm.get('passwordConfirm').hasError('passwordsNotMatching')"
              >Passwords do not match</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col-12 px-2" style="font-size: small; text-align: center">
          Passwords must be at least 8 characters with one alphanumeric character, one digit and one uppercase
          character.
        </div>
      </div>

      <form formGroupName="address" autocomplete="off">
        <div class="row mx-0">
          <div class="col px-2">
            <mat-form-field>
              <input
                autocomplete="off"
                matInput
                placeholder="Address 1"
                class="capitalized"
                aria-label="Address1"
                formControlName="address1"
                (change)="formatterService.textCapitalize(userForm.get('address.address1'))"
                (focusout)="formatterService.textCapitalize(userForm.get('address.address1'))"
              />
            </mat-form-field>
          </div>
          <div class="col px-2">
            <mat-form-field>
              <input
                autocomplete="off"
                matInput
                placeholder="Address 2"
                class="capitalized"
                aria-label="Address2"
                formControlName="address2"
                (change)="formatterService.textCapitalize(userForm.get('address.address2'))"
                (focusout)="formatterService.textCapitalize(userForm.get('address.address2'))"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row mx-0">
          <div class="col px-2">
            <mat-form-field>
              <input
                autocomplete="off"
                matInput
                placeholder="City"
                class="capitalized"
                aria-label="City"
                formControlName="city"
                (change)="formatterService.textCapitalize(userForm.get('address.city'))"
                (focusout)="formatterService.textCapitalize(userForm.get('address.city'))"
              />
            </mat-form-field>
          </div>
          <div class="col px-2">
            <mat-form-field>
              <mat-select formControlName="province">
                <mat-option *ngFor="let provinceState of provinceAndStateOptions" [value]="provinceState">
                  {{ provinceState }}
                </mat-option>
              </mat-select>
              <mat-placeholder class="placeholder"
                >{{ userForm.get('address.country').value === 'United States' ? 'State' : 'Province' }}
              </mat-placeholder>
            </mat-form-field>
          </div>
        </div>

        <div class="row mx-0">
          <div class="col px-2">
            <mat-form-field>
              <mat-select placeholder="Country" formControlName="country">
                <mat-option *ngFor="let country of countryOptions" [value]="country">
                  {{ country }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col px-2">
            <mat-form-field>
              <input
                autocomplete="off"
                matInput
                aria-label="Postal Code"
                class="uppercased"
                formControlName="postalCode"
                (change)="formatterService.textCapitalize(userForm.get('address.postalCode'))"
                (focusout)="formatterService.textCapitalize(userForm.get('address.postalCode'))"
              />
              <mat-placeholder class="placeholder">
                {{
                  userForm.get('address.country').value === 'United States' ? 'Zip Code' : 'Postal Code'
                }}</mat-placeholder
              >
              <mat-error *ngIf="userForm.get('address.postalCode').invalid">
                {{
                  userForm.get('address.country').value === 'United States'
                    ? 'Not a Valid Zip Code'
                    : 'Not a Valid Postal Code'
                }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>

      <div class="row mx-0">
        <div class="col px-2">
          <mat-form-field>
            <input
              autocomplete="off"
              matInput
              placeholder="Phone Number"
              aria-label="Phone Number"
              formControlName="phoneNumber"
              (change)="formatterService.textCapitalize(userForm.get('phoneNumber'))"
            />
            <mat-error *ngIf="userForm.get('phoneNumber').invalid">Not a Valid Phone #</mat-error>
          </mat-form-field>
        </div>
        <div class="col px-2">
          <mat-form-field>
            <mat-select
              placeholder="User Category"
              [compareWith]="compareCategories"
              formControlName="userCategories"
              multiple
              required
            >
              <mat-option *ngFor="let userCat of userCategories" [value]="userCat">
                {{ userCat.categoryName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row mx-0">
        <div class="col px-2">
          <mat-form-field>
            <mat-select placeholder="Service Provider" formControlName="serviceProvider">
              <mat-option [value]="true"> Yes </mat-option>
              <mat-option [value]="false"> No </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col px-2">
          <mat-form-field>
            <input
              autocomplete="off"
              matInput
              placeholder="Hourly Wage"
              aria-label="Hourly Wage"
              formControlName="hourlyWage"
              (change)="onCurrencyChange()"
            />
            <span matPrefix>$&nbsp;</span>
            <mat-error *ngIf="userForm.get('hourlyWage').invalid">Invalid Wage</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mx-0 teal-background">
        <div class="col px-0">
          <h6 class="white-font uppercase p-2 mb-0">Prescription Info</h6>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col px-2">
          <mat-form-field>
            <mat-select placeholder="Medical College" formControlName="medicalCollegeId">
              <ng-container *ngIf="userForm.get('address.country').value === 'Canada'">
                <mat-option *ngFor="let college of medicalColleges" [value]="college.id">
                  {{ college.collegeInitials }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col px-2">
          <mat-form-field>
            <input
              matInput
              [placeholder]="'Medical License #'"
              aria-label="Medical License #"
              formControlName="medicalLicenseNumber"
            />
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="userForm.get('address.country').value === 'Canada'">
        <div class="row mx-0 teal-background">
          <div class="col px-0">
            <h6 class="white-font uppercase p-2 mb-0">Billing Info</h6>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col px-2">
            <mat-form-field>
              <input
                matInput
                [placeholder]="'Provincial Billing #'"
                aria-label="Provider Number"
                formControlName="provincialBillingNumber"
              />
              <mat-error *ngIf="userForm.get('provincialBillingNumber').invalid"
                >Provincial Billing Number must be numeric</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col px-2">
            <mat-form-field>
              <input
                matInput
                [placeholder]="'ClinicAid Payee #'"
                aria-label="ClinicAid Payee Number"
                formControlName="clinicAidPayeeNumber"
              />
              <mat-error *ngIf="userForm.get('clinicAidPayeeNumber').invalid">Payee # Number must be numeric</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>

    <div class="row mx-0 teal-background">
      <div class="col px-0">
        <h6 class="white-font uppercase p-2 mb-0">Save User</h6>
      </div>
    </div>
    <div class="row mx-0 my-3">
      <div class="col px-2 text-center">
        <button
          class="btn em-btn em-btn-green"
          (click)="submitUser()"
          [disabled]="userForm.invalid || userForm.pristine"
        >
          {{ isNewUser ? 'Add' : 'Update' }}
        </button>
      </div>
      <div class="col px-2 text-center">
        <button class="btn em-btn em-btn-green" (click)="cancelUpdate()">Cancel</button>
      </div>
    </div>
    <div class="row mx-0 teal-background">
      <div class="col px-0">
        <h6 class="white-font uppercase p-2 mb-0">Edit Commission</h6>
      </div>
    </div>
    <div class="row mx-0 mb-2">
      <div class="col px-2">
        <app-edit-user-commissions [userId]="user?.id"></app-edit-user-commissions>
      </div>
    </div>
  </perfect-scrollbar>
</div>
