import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { isNullOrUndefined } from '@app/shared/helpers';
import { HubConnectionState } from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChatChannel } from '../models/chat-channel.model';
import { ChatChannelsService } from '../services/chat-channels.service';
import { MessagingSignalrService } from '../services/messaging-signalr.service';

@Component({
  selector: 'app-messaging-panel',
  templateUrl: './messaging-panel.component.html',
  styleUrls: ['./messaging-panel.component.less'],
})
export class MessagingPanelComponent implements OnInit, OnDestroy {
  private unsub: Subject<any> = new Subject<any>();
  channelSelected = false;
  get disconnected() {
    return (
      this.messagingService.connectionState !== HubConnectionState.Connected &&
      this.messagingService.connectionState !== HubConnectionState.Connecting
    );
  }

  constructor(
    public channelService: ChatChannelsService,
    private matDialog: MatDialog,
    private messagingService: MessagingSignalrService
  ) {}

  ngOnInit() {
    this.channelService.onChannelSelected$.pipe(takeUntil(this.unsub)).subscribe((c) => {
      this.channelSelected = !isNullOrUndefined(c);
    });

    this.channelService.selectedChannelRemoved$.pipe(takeUntil(this.unsub)).subscribe((c) => {
      this.displayChannelDeleted(c);
    });
  }

  getConnectionErrorMessage(): string {
    switch (this.messagingService.connectionState) {
      case HubConnectionState.Disconnected:
      case HubConnectionState.Disconnecting:
        return '<strong>Connection Issue</strong>';
      case HubConnectionState.Reconnecting:
        return '<strong>Connection Issue, Reconnecting...</strong>';
      default:
        return '';
    }
  }

  displayChannelDeleted(channel: ChatChannel) {
    const message = channel.private
      ? `<b>${this.channelService.privateChannelTitles.get(channel.id)}</b> has removed this channel.`
      : `The channel <b>${channel.name}</b> has been removed by another user.`;
    this.matDialog.open(GenericDialogComponent, {
      data: {
        title: 'Channel Closed',
        content: message,
        confirmButtonText: 'Ok',
        showCancel: false,
      },
    });
  }

  ngOnDestroy() {
    this.channelService.channelSelected(null);
    this.unsub.next();
    this.unsub.complete();
  }
}
