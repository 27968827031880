<ngx-loading [show]="loading"></ngx-loading>
<div class="image-drawing">
  <canvas id="canvas"></canvas>
  <div class="toolbar ml-2">
    <div class="tools">
      <div class="tools-col mr-2">
        <i
          class="material-icons tool-btn"
          [class.selected]="drawingMode"
          (click)="enableDrawingMode()"
          title="Brush"
          >brush</i
        >
        <i
          class="material-icons tool-btn"
          [class.selected]="!drawingMode"
          (click)="enablePanningMode()"
          title="Pan"
          >open_with</i
        >
        <span
          *ngFor="let drawingSizeName of drawingSizesName"
          class="size tool-btn"
          [ngClass]="currentColor"
          [style.background]="colors[currentColor]" 
          [style.width.px]="drawingSizes[drawingSizeName] * 0.8 + 8"
          [style.height.px]="drawingSizes[drawingSizeName] * 0.8 + 8"
          [style.borderRadius.px]="drawingSizes[drawingSizeName] * 0.4 + 4"
          [class.selected]="currentSize == drawingSizeName"
          [title]="drawingSizeName"
          (click)="selectDrawingSize(drawingSizeName)"
        >
        </span>
        <i class="material-icons tool-btn" [class.disabled]="!canUndo" (click)="undo()" title="Undo">undo</i>
        <i class="material-icons tool-btn" [class.disabled]="!canRedo" (click)="redo()" title="Redo">redo</i>
        <i class="material-icons tool-btn" (click)="clearCanvas()" title="Clear">replay</i>
      </div>
      <div class="tools-col">
        <div
          *ngFor="let colorName of colorsName"
          [class.selected]="currentColor == colorName"
          class="color"
          [ngClass]="colorName"
          [style.background]="colors[colorName]"
          [title]="colorName"
          (click)="selectColor(colorName)"
        ></div>
      </div>
    </div>
    <button href="#" class="btn medium em-btn em-btn-green mt-2" (click)="saveImage(); $event.preventDefault()">
      <i class="far fa-check"></i> Done
    </button>
  </div>
</div>
