<div *ngIf="hoveredAppointment" class="hover-box">
  <div
    class="hover-top-bar d-flex justify-content-end"
    [ngStyle]="{ 'background-color': hoveredAppointment?.service?.serviceTemplate.serviceIDColour }"
  >
    <button class="d-flex justify-content-center align-items-center px-0 py-0 btn pointer" (click)="closeHoverPanel()">
      <i class="fal fa-times"></i>
    </button>
  </div>
  <div
    class="hover-info-box d-flex flex-column"
    kendoTooltip
    tooltipClass="kendo-tooltip"
    position="top"
    [ngStyle]="{ background: hexToTranslucentRgbA('0.1') }"
  >
    <div class="hover-bar hover-title align-items-center justify-content-between font-weight-bold">
      <div class="title-bar-patient-name align-items-center">
        {{
          hoveredAppointment?.patient?.firstName +
            (hoveredAppointment?.patient?.nickName ? ' "' + hoveredAppointment?.patient?.nickName + '" ' : ' ') +
            hoveredAppointment?.patient?.lastName | titlecase
        }}
      </div>
      <div class="title-bar-patient-gender align-items-center px-2">
        {{ '(' + getGender() + ')' }}
      </div>
      <div class="title-bar-icons d-flex justify-content-end">
        <button
          *appAuthGuard="patientProfilePolicy"
          id="patientprofiletab"
          class="btn patient-btn px-1 no-btn-shadow"
          (click)="patientProfileClick()"
        >
          <i class="fal fa-user" title="Patient Profile"></i>
        </button>
        <button
          *appAuthGuard="patientChartPolicy"
          id="patientcharttab"
          class="btn patient-btn px-1 no-btn-shadow"
          (click)="patientChartClick()"
        >
          <i class="fal fa-notes-medical" title="Patient Chart"></i>
        </button>
        <button
          *appAuthGuard="patientAccountPolicy"
          id="patientaccounttab"
          class="btn patient-btn px-1 no-btn-shadow"
          (click)="patientAccountClick()"
        >
          <i class="fal fa-file-invoice-dollar" title="Patient Invoices"></i>
        </button>
        <ng-container *appAuthGuard="appointmentsPolicy">
          <button
            *ngIf="getVisitStatus() === 'uncomfirmed'"
            id="checkin"
            class="btn patient-btn px-1 no-btn-shadow"
            (click)="hoverCheckInClick()"
          >
            <i class="fal fa-calendar-minus" title="Check-in Unconfirmed"></i>
          </button>
          <button
            *ngIf="getVisitStatus() === 'confirmed'"
            id="checkin"
            class="btn patient-btn btn-blue px-1 no-btn-shadow"
            (click)="hoverCheckInClick()"
          >
            <i class="fal fa-calendar-check" title="Check-in Confirmed"></i>
          </button>
          <button
            *ngIf="getVisitStatus() === 'checkedIn'"
            id="checkin"
            class="btn patient-btn px-1 btn-green no-btn-shadow"
            (click)="hoverCheckInClick()"
          >
            <i class="fal fa-calendar-check" title="Checked in"></i>
          </button>
          <button
            *ngIf="getVisitStatus() === 'noShow'"
            id="checkin"
            class="btn patient-btn px-1 btn-red no-btn-shadow"
            (click)="hoverCheckInClick()"
          >
            <i class="fal fa-calendar-exclamation" title="No Show"></i>
          </button>
        </ng-container>
      </div>
    </div>
    <div class="hover-bar d-flex align-items-center mt-0 text-uppercase">
      <div
        class="appt-info"
        [innerHTML]="
          (hoveredAppointment?.roomName ? 'ROOM ' + hoveredAppointment?.roomName + '&nbsp;&ndash;&nbsp;' : '') +
          (hoveredAppointment.start ? getStartTime() : 'N/A') +
          ':&nbsp;' +
          hoveredAppointment?.service?.serviceName
        "
      ></div>
    </div>
    <div
      class="hover-bar hover-vip-bar d-flex align-items-center font-weight-bold mt-0"
      *ngIf="hoveredAppointment?.patient?.isPreferred"
    >
      <i class="hover-icon fas fa-star" title="VIP"></i>
      <div class="vip-bar-info">VIP Customer</div>
    </div>
    <div
      *ngIf="hoveredAppointment?.patient?.address?.city || hoveredAppointment?.patient?.address?.province"
      class="hover-bar d-flex align-items-center"
    >
      <i class="hover-icon fas fa-map-marker-alt" title="Location"></i>
      &nbsp;{{ hoveredAppointment?.patient?.address?.city
      }}<ng-container
        *ngIf="
          hoveredAppointment?.patient?.address?.city?.length > 0 &&
          hoveredAppointment?.patient?.address?.province?.length > 0
        "
        >, </ng-container
      >{{ hoveredAppointment?.patient?.address?.province }}
    </div>
    <div *ngIf="hoveredAppointment?.patient?.homeNumber" class="hover-bar d-flex align-items-center">
      <i class="hover-icon fas fa-phone" title="Phone Number"></i>
      &nbsp;{{ hoveredAppointment?.patient?.homeNumber }}
    </div>
    <div *ngIf="hoveredAppointment?.patient?.mobileNumber" class="hover-bar d-flex align-items-center">
      <i class="hover-icon fas fa-mobile" title="Mobile Number"></i>
      &nbsp;{{ hoveredAppointment?.patient?.mobileNumber }}
    </div>
    <div
      *ngIf="hoveredAppointment?.patient"
      class="hover-bar d-flex align-items-center"
      defaultBirthdayCheck
      [birthDate]="hoveredAppointment?.patient?.birthDate"
    >
      <i class="hover-icon fas fa-gift" title="Birth Date"></i>
      &nbsp;{{ getBirthday() }}
    </div>
    <div *ngIf="hoverAppointmentVisit?.confirmedStatus" class="hover-bar d-flex align-items-center">
      <i class="hover-icon fas fa-calendar-check" title="Confirmation Status"></i>
      &nbsp;{{ getConfirmationStatus() }}
    </div>
    <div
      *ngIf="!hoverAppointmentVisit?.confirmedStatus && hoverAppointmentVisit?.smsReminderSent"
      class="hover-bar d-flex align-items-center"
    >
      SMS Reminder Sent: {{ hoverAppointmentVisit?.smsReminderSent | date : 'yyyy-MM-dd h:mm a' }}
    </div>
    <div
      *ngIf="!hoverAppointmentVisit?.confirmedStatus && hoverAppointmentVisit?.emailReminderSent"
      class="hover-bar d-flex align-items-center"
    >
      Email Reminder Sent: {{ hoverAppointmentVisit?.emailReminderSent | date : 'yyyy-MM-dd h:mm a' }}
    </div>
  </div>

  <div class="hover-appt-visit-notes-box d-flex flex-column">
    <div class="hover-appt-visit-notes-title-bar font-weight-bold">Visit Notes</div>
    <div class="hover-appt-visit-notes-content">{{ getHoverPanelVisitNoteContent() }}</div>
    <div class="hover-appt-visit-notes-controls-bar d-none">
      <button class="btn px-0 py-0">
        <i class="hover-icon far fa-arrow-left"></i>
      </button>
      <button class="btn px-0 py-0">
        <i class="hover-icon far fa-arrow-right"></i>
      </button>
    </div>
  </div>
  <div
    class="hover-appt-visit-notes-box d-flex flex-column"
    *ngIf="hoveredAppointment?.service?.plannedTreatmentNotes"
    [ngStyle]="{ background: hexToTranslucentRgbA('0.1') }"
  >
    <div class="hover-appt-visit-notes-title-bar font-weight-bold">Planned Treatment Notes</div>
    <div class="hover-appt-visit-notes-content mw-100">{{ hoveredAppointment.service.plannedTreatmentNotes }}</div>
    <div class="hover-appt-visit-notes-controls-bar d-none">
      <button class="btn px-0 py-0">
        <i class="hover-icon far fa-arrow-left"></i>
      </button>
      <button class="btn px-0 py-0">
        <i class="hover-icon far fa-arrow-right"></i>
      </button>
    </div>
  </div>
  <div
    *ngIf="hoveredAppointment?.appointmentForms"
    class="hover-appt-visit-notes-box d-flex flex-column"
    [ngStyle]="{ background: hexToTranslucentRgbA('0.3') }"
  >
    <div class="hover-appt-visit-notes-title-bar font-weight-bold">eForms</div>
    <div *ngIf="hoveredAppointment?.appointmentForms?.length > 0">
      <div *ngFor="let appointmentForm of hoveredAppointment?.appointmentForms" class="hover-appt-visit-notes-form">
        <i
          class="far bigger-icon form-icon"
          [ngClass]="appointmentForm?.isSigned ? 'fa-check spring-green-font' : 'fa-times calendar-red-font'"
        ></i
        >{{ appointmentForm?.clinicForm?.name }}
      </div>
    </div>
    <div *ngIf="hoveredAppointment?.appointmentForms?.length == 0" class="hover-appt-visit-notes-content">
      No eForms for this appointment.
    </div>
  </div>
  <div class="hover-appt-visit-notes-box d-flex flex-column" [ngStyle]="{ background: hexToTranslucentRgbA('0.5') }">
    <div class="hover-appt-visit-notes-title-bar font-weight-bold">
      <span *ngIf="hoveredAppointment?.patient?.rushPatientNote" class="important-color"
        ><i class="far fa-exclamation-circle important-animation"></i> IMPORTANT
      </span>
      Permanent Notes/Alerts
    </div>
    <div class="hover-appt-visit-notes-content">{{ getHoverPatientNoteContent() }}</div>
    <div class="hover-appt-visit-notes-controls-bar d-none">
      <button class="btn px-0 py-0">
        <i class="hover-icon far fa-arrow-left"></i>
      </button>
      <button class="btn px-0 py-0">
        <i class="hover-icon far fa-arrow-right"></i>
      </button>
    </div>
  </div>
</div>
