import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Tax } from '@models/tax';
import { ClinicTax } from '@models/clinic';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TaxService {

  private taxesUpdateSource = new BehaviorSubject<any>(undefined);
  taxesUpdateSource$ = this.taxesUpdateSource.asObservable();

  constructor(private http: HttpClient) { }

  updateTaxData(state: boolean) {
    this.taxesUpdateSource.next(state);
  }

  getTaxes() {
    return this.http.get<Tax[]>(environment.baseUrl + 'api/taxes');
  }

  getTax(taxId: number) {
    return this.http.get<Tax>(environment.baseUrl + 'api/taxes/' + taxId);
  }

  addTax(tax: Tax) {
    return this.http.post<Tax>(environment.baseUrl + 'api/taxes', tax);
  }

  updateTax(tax: Tax) {
    return this.http.put<Tax>(environment.baseUrl + 'api/taxes/' + tax.taxId, tax);
  }

  deleteTax(tax: Tax){
    return this.http.delete<Tax>(environment.baseUrl + 'api/taxes/' + tax.taxId);
  }

  getClinicTaxes() {
    return this.http.get<ClinicTax[]>(environment.baseUrl + 'api/ClinicTaxes');
  }

  getClinicTaxEnabled(taxId: number) {
    return this.http.get<boolean>(environment.baseUrl + 'api/ClinicTaxes/' + taxId);
  }

  enableTax(tax: Tax) {
    return this.http.post<Tax>(environment.baseUrl + 'api/ClinicTaxes/' + tax.taxId, tax);
  }

  disableTax(tax: Tax) {
    return this.http.delete<Tax>(environment.baseUrl + 'api/ClinicTaxes/' + tax.taxId);
  }
}

