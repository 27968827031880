<div class="edit-container position-absolute d-block z-index-3 white-background">
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0;">
    <div class="row teal-background">
      <div class="col">
        <h6 class="white-font uppercase p-2 mb-0">
          {{addOrEdit}} Doctor
        </h6>
      </div>
    </div>
    <div class="row mx-0 pt-2">
      <div class="col px-2">
        <app-title-select [(titleValue)]="editedDoctor.proTitle" (titleValueChange)="updateSubmitButtonState()"></app-title-select>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="First Name" class="capitalized" aria-label="First Name"
            [(ngModel)]="editedDoctor.firstName" (change)="formatterService.textCapitalize(firstName);updateSubmitButtonState();"
            data-test-id="doctorFirstName"
            [formControl]="firstName" required>
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="Last Name" class="capitalized" aria-label="Last Name"
            [(ngModel)]="editedDoctor.lastName" (change)="formatterService.textCapitalize(lastName);updateSubmitButtonState()"
            data-test-id="doctorLastName"
            [formControl]="lastName" required>
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="Address 1" class="capitalized" aria-label="Address1"
            [(ngModel)]="editedDoctor.address.address1" (change)="formatterService.textCapitalize(addressAddress1);updateSubmitButtonState()"
            data-test-id="doctorAddress1"
            [formControl]="addressAddress1">
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="Address 2" class="capitalized" aria-label="Address2"
            [(ngModel)]="editedDoctor.address.address2" (change)="formatterService.textCapitalize(addressAddress2);updateSubmitButtonState()"
            data-test-id="doctorAddress2"
            [formControl]="addressAddress2">
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="City" class="capitalized" aria-label="City"
            [(ngModel)]="editedDoctor.address.city" (change)="formatterService.textCapitalize(addressCity);updateSubmitButtonState()"
            data-test-id="doctorCity"
            [formControl]="addressCity">
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <mat-select [(ngModel)]="editedDoctor.address.province" data-test-id="doctorProvince" name="usStates" (selectionChange)="updateSubmitButtonState()">
            <mat-option *ngFor="let provinceState of provincesAndStates" [value]="provinceState">
              {{ provinceState }}
            </mat-option>
          </mat-select>
          <mat-placeholder class="placeholder">
            {{editedDoctor.address.country == 'United States' ? 'State' : 'Province'}}
          </mat-placeholder>
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <mat-select placeholder="Country" [(ngModel)]="editedDoctor.address.country" name="usStates"
            data-test-id="doctorCountry"
            (selectionChange)="onChangeCountry();updateSubmitButtonState()">
            <mat-option *ngFor="let country of countriesOfTheWorld" [value]="country">
              {{ country }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input matInput class="uppercased" aria-label="Postal Code" [(ngModel)]="editedDoctor.address.postalCode"
            (change)="formatterService.textUppercase(addressPostalCode);updateSubmitButtonState()"
            data-test-id="doctorPostalCode"
            [formControl]="addressPostalCode">
          <mat-placeholder class="placeholder">
            {{editedDoctor.address.country == 'United States' ? 'Zip Code' : 'Postal Code'}}</mat-placeholder>
          <mat-error *ngIf="addressPostalCode.invalid">
            {{editedDoctor.address.country == 'United States' ? 'Not a Valid Zip Code' : 'Not a Valid Postal Code'}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="Phone Number" aria-label="Phone Number" [(ngModel)]="editedDoctor.phoneNumber"
            (change)="formatterService.formatPhoneNumber(phoneNumber);updateSubmitButtonState()"
            data-test-id="doctorPhoneNumber"
            [formControl]="phoneNumber">
          <mat-error *ngIf="phoneNumber.invalid">Not a Valid Phone #</mat-error>
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="Fax Number" aria-label="Fax Number" [(ngModel)]="editedDoctor.faxNumber"
            (change)="formatterService.formatPhoneNumber(faxNumber);updateSubmitButtonState()"
            data-test-id="doctorFaxNumber"
            [formControl]="faxNumber">
          <mat-error *ngIf="faxNumber.invalid">Not a Valid Fax #</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="Email" aria-label="Email" [(ngModel)]="editedDoctor.email" [formControl]="email"
            data-test-id="doctorEmail"
            (change)="updateSubmitButtonState()">
          <mat-error *ngIf="email.invalid">Not a Valid Email</mat-error>
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="Website" aria-label="Website" [(ngModel)]="editedDoctor.website"
            data-test-id="doctorWebsite"
            [formControl]="website" (change)="updateSubmitButtonState()">
          <mat-error *ngIf="website.invalid">Not a Valid URL</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <mat-select placeholder="Specialty" [(ngModel)]="editedDoctor.specialty" name="specialty" data-test-id="doctorSpecialty" (selectionChange)="updateSubmitButtonState()">
            <mat-option *ngFor="let specialty of specialties" [value]="specialty">
              {{ specialty }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <mat-select placeholder="Medical College" [(ngModel)]="editedDoctor.medicalCollegeId" name="medicalCollegeId" data-test-id="doctorMedicalCollegeId" (selectionChange)="onMedicalCollegeSwitched(editedDoctor.medicalCollegeId);updateSubmitButtonState()">
            <mat-option *ngFor="let college of medicalColleges" [value]="college.id">
              {{ college.collegeInitials }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input matInput [placeholder]="(selectedMedicalCollege ? selectedMedicalCollege.collegeInitials : 'CPSBC') + ' #'" aria-label="CPS Number" data-test-id="doctorCpsNumber" [(ngModel)]="editedDoctor.cpsNumber"
            [formControl]="cpsNumber" (change)="updateSubmitButtonState()">
          <mat-error *ngIf="cpsNumber.invalid">Not a Valid CPS Number</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input matInput [placeholder]="(editedDoctor.medicalCollege ? editedDoctor.medicalCollege.collegeBillingAcronym : 'MSP') + ' Billing #'" aria-label="Provincial ID Number"
            data-test-id="doctorMspBillingNumber"
            [(ngModel)]="editedDoctor.provincialIdNumber" [formControl]="provincialIdNumber"
            (change)="updateSubmitButtonState()">
          <mat-error *ngIf="provincialIdNumber.invalid">Not a Valid Provincial ID Number</mat-error>
        </mat-form-field>
      </div>
    </div>
    
    <div class="row pt-2">
      <div class="col px-2 text-center">
        <button
          class="btn em-btn em-btn-green"
          (click)="updateDoctor()"
          data-test-id="doctorSubmit"
          [disabled]="submitButtonDisabledState">{{addOrEdit == 'Add' ? 'Add' : 'Update'}} Doctor
        </button>
      </div>
      <div class="col px-2 text-center">
        <button class="btn em-btn em-btn-green" data-test-id="doctorCancel" (click)="cancelUpdate()">Cancel</button>
      </div>
    </div>
  </perfect-scrollbar>
</div>
