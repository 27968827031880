import { ICachePair } from './decorator/base';
import * as sizeOf from 'object-sizeof';

export class CacheStats {
    size: string;
    keys = new Array<string>();

    public update(rawData: Map<string, Array<ICachePair<any>>>) {
        this.keys.length = 0;
        let sz = 0;

        if (rawData) {
          rawData.forEach((value, key) => {
            sz += sizeOf.default(value);
            let totalRows = 0;
            value.forEach(item => {
              if (item.response) {
                if (item.isCollection && item.response.data) {
                  totalRows += item.response.data.length;
                } else {
                  totalRows = 1;
                }
              }
            });
            this.keys.push(key + ' with ' + value.length + ' entries ' + totalRows + ' total rows');
          });
        }

        if (sz > 1024 * 1024) {
          this.size = (sz / 1024 / 1024).toFixed(2) + ' MB';
        } else if (sz > 1024) {
          this.size = (sz / 1024).toFixed(2) + ' KB';
        } else {
          this.size = sz + ' bytes';
        }

        localStorage.setItem('cache stats', JSON.stringify(this,
          (key, value) => { if (key === 'cache') { return undefined; } return value; }) );
    }
}
