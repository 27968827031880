<ngx-loading [show]="loading" [config]="{ fullScreenBackdrop: true }"></ngx-loading>
<div
  class="edit-container position-absolute z-index-3 white-background row w-100"
  [ngClass]="{ 'w-0': !initialized, 'w-100': initialized }"
>
  <div class="re-links-container">
    <button class="btn em-btn teal-background" (click)="cancelUpdate()">
      <i class="far fa-times" aria-hidden="true"></i>
    </button>
  </div>

  <div class="toolbar row px-2 navy-background mx-auto w-100">
    <div class="col-12 d-flex align-items-center justify-content-between">
      <h5 class="white-font text-uppercase m-0 mr-2">Edit Visit Reminder Email</h5>
      <div class="d-flex flex-nowrap mr-3">
        <button
          class="btn em-btn em-btn-green mr-4 campaign-button"
          (click)="saveChanges()"
          [disabled]="!reminderSettingsForm.valid"
          [hidden]="disabledForm"
        >
          SAVE
        </button>
        <button class="btn em-btn em-btn-green mr-4" (click)="sendTest('email')" [hidden]="disabledForm">
          Send Test
        </button>
        <button class="btn em-btn em-btn-green mr-4 campaign-button" (click)="cancelUpdate()">Cancel</button>
      </div>
    </div>
  </div>

  <div *ngIf="showSuccessMessage" class="success-banner">Saved successfully!</div>

  <div class="d-flex w-100" style="height: calc(100% - 80px)">
    <form [formGroup]="reminderSettingsForm" class="line-height-1 px-0 light-grey-background col-4 h-100">
      <div class="row mx-0 teal-background">
        <div class="col">
          <h6 class="white-font uppercase my-0 py-2">Email Settings</h6>
        </div>
      </div>

      <div class="py-3">
        <div class="row mx-0">
          <div class="col">
            <mat-form-field class="w-75">
              <mat-label>Original Email Template</mat-label>
              <mat-select formControlName="template">
                <mat-option *ngFor="let template of emailTemplates" [value]="template">
                  {{ template.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button
              class="btn btn-sm em-btn em-btn-green py-2 mx-2 font-weight-bold mb-3"
              style="line-height: 1em"
              tooltipclass="em-tooltip"
              title="Revert back to original email template."
              (click)="revertToOriginalTemplate()"
            >
              <i class="fas fa-undo pr-2"></i>Revert
            </button>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput placeholder="Sender Name" formControlName="emailSenderName" />
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput placeholder="Sender Email Address" formControlName="emailSenderEmail" />
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col">
            <mat-form-field class="w-100">
              <input formControlName="emailSubjectLine" matInput placeholder="Email Subject" />
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0 mb-3">
          <div class="col mx-0">
            <div class="text-dark">
              <ng-template #emojiTemplate>
                Include emojis 😍💖😊 in your email subject line!
                <ul class="mb-0">
                  <li>On Mac: Press Control+Command+Space while editing the text</li>
                  <li>On Windows: Press Windows+. while editing the text</li>
                </ul>
              </ng-template>
              <div kendoTooltip [tooltipTemplate]="emojiTemplate" filter="i" position="right">
                Use Emoji <i class="fa fa-question-circle teal-font"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-0 teal-background">
        <div class="col">
          <h6 class="white-font uppercase mb-0 py-2">Available fields</h6>
        </div>
      </div>

      <div class="py-3">
        <div class="row mx-0">
          <div class="col">
            <ul class="mb-0">
              <li>{{ 'FirstName' }}</li>
              <li>{{ 'PatientName' }}</li>
              <li>{{ 'VisitDate' }}</li>
              <li>{{ 'VisitTime' }}</li>
              <li>{{ 'ConfirmVisitEndpoint' }}</li>
              <li>{{ 'UnsubscribeEndpoint' }}</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row mx-0 teal-background">
        <div class="col">
          <h6 class="white-font uppercase mb-0 py-2">When to send</h6>
        </div>
      </div>

      <div class="py-3">
        <div class="row mx-0">
          <div class="col-6 d-flex flex-column navy-font">
            Send visit reminders between:
            <div class="d-flex justify-content-between align-items-center mb-2 mt-2">
              <kendo-timepicker
                class="communication-timepicker"
                name="startTime"
                placeholder="Select"
                [steps]="{ minute: minimumDuration }"
                [(value)]="workerStartTime"
                (valueChange)="onStartTimeChange($event)"
              >
              </kendo-timepicker>
              <span class="mx-3">and</span>
              <kendo-timepicker
                class="communication-timepicker"
                name="endTime"
                placeholder="Select"
                [steps]="{ minute: minimumDuration }"
                [min]="minEndTime"
                [(value)]="workerEndTime"
                (valueChange)="onEndTimeChange($event)"
              >
              </kendo-timepicker>
            </div>
          </div>
        </div>

        <div class="row mx-0">
          <div class="col navy-font d-flex align-items-center">
            Send
            <input type="number" class="input-number mx-2" formControlName="firstNotificationPeriod" min="1" />
            <mat-form-field class="sender-type ml-1 mr-2">
              <mat-select formControlName="firstNotificationUnit">
                <mat-option *ngFor="let type of senderUnits" [value]="type">
                  {{ type }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            before their visit
          </div>
        </div>

        <div class="row mx-0">
          <div class="col d-flex align-items-center navy-font">
            <mat-checkbox class="reports-checkbox mr-2" formControlName="enabledRepeatNotification"> </mat-checkbox>
            <div
              class="d-flex align-items-center"
              [ngClass]="{ 'disabled-element': !this.reminderSettingsForm.controls['enabledRepeatNotification'].value }"
            >
              Send every
              <input type="number" class="input-number mx-2" formControlName="repeatNotificationPeriod" min="1" />
              <mat-form-field class="sender-type ml-1 mr-2">
                <mat-select formControlName="repeatNotificationUnit">
                  <mat-option *ngFor="let type of senderUnits" [value]="type">
                    {{ type }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              until the visit has been confirmed
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="px-3 mr-3 h-100 flex-grow-1">
      <ngx-loading [show]="templateLoading"></ngx-loading>
      <div class="d-flex flex-column h-100">
        <div class="row mx-0 teal-background">
          <div class="col d-flex justify-content-between">
            <ng-template #editorHelpTemplate>
              For the best email editing experience use a desktop or laptop computer.
            </ng-template>
            <h6 class="white-font uppercase my-0 mr-auto py-2">Template Definition</h6>
            <h6
              *ngIf="iPad"
              kendoTooltip
              [tooltipTemplate]="editorHelpTemplate"
              position="left"
              filter="span"
              class="white-font uppercase my-0 py-2"
            >
              <span class="ml-auto pl-5"><i class="fa fa-question-circle"></i></span>
            </h6>
            <!-- <a class="btn kendo-btn  white-font pl-1"  style="display:contents" target="_blank" [href]="'https://app.mailjet.com/template/' + form.get('template')?.value?.id + '/build'"><i class="fas fa-edit"></i></a> -->
          </div>
        </div>

        <div class="w-100 flex-grow-1 d-flex align-items-start justify-content-center px-0 pb-2 light-grey-background">
          <div class="h-100 w-100">
            <app-unlayer-email-editor
              id="unlayer"
              class="email-editor"
              [options]="editorOptions"
              (loaded)="loadEditor($event)"
              (ready)="editorReady($event)"
              #editor
            ></app-unlayer-email-editor>
          </div>
          <!--   <div
          #templateFiller
          *ngIf="reminderSettingsForm?.controls['campaignType']?.value == 'SMS'"
          frameBorder="0"
          class="w-100"
        >
          <span class="m-auto font-weight-bold" style="font-size: 2em">
            {{
              reminderSettingsForm?.controls['campaignType']?.value == 'SMS'
                ? 'HTML template disabled for SMS only campaigns'
                : 'Please select an email template'
            }}
          </span>
        </div>
        <div *ngIf="disabledForm">
          <perfect-scrollbar style="max-width: 100%; top: 0; left: 0" appDetermineMaxScrollHeight [addHeight]="0">
            <div [innerHTML]="emailHtml | keepHtml"></div>
          </perfect-scrollbar>
        </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
