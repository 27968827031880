import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByProperty'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], field: string, searchText: string): any {
    if (!searchText) { return items; }
    return items.filter(item => {
      return item[field].toLowerCase().includes(searchText.toLowerCase());
    });
  }

}
