<ngx-loading [show]="loading"></ngx-loading>
<div class="edit-container position-absolute d-block z-index-3 white-background">
  <div class="re-links-container" kendoTooltip tooltipClass="em-tooltip" position="top">
    <div class="re-links">
      <button class="btn em-btn" (click)="cancelUpdate()">
        <i class="far fa-times" aria-hidden="true" title="Close Panel"></i>
      </button>
    </div>
  </div>
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0">
    <div class="row teal-background">
      <div class="col">
        <h6 class="white-font uppercase p-2 mb-0">Edit Provider</h6>
      </div>
    </div>
    <form [formGroup]="form">
      <div class="row mx-0 mt-2">
        <div class="col px-2">
          <mat-form-field class="w-100">
            <input
              matInput
              placeholder="Provider Name Reference"
              class="capitalized"
              aria-label="Provider Name Reference"
              formControlName="title"
            />
          </mat-form-field>
        </div>
        <div class="col px-2">
          <mat-form-field class="w-100">
            <input
              matInput
              placeholder="Online Name"
              class="capitalized"
              aria-label="Online Name"
              formControlName="onlineBookingProviderName"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0 mt-2">
        <div class="col px-2">
          <mat-form-field class="w-100">
            <input
              matInput
              placeholder="Available to book from"
              class="capitalized"
              aria-label="Available to book from"
            />
          </mat-form-field>
        </div>
        <div class="col px-2">
          <mat-form-field class="w-100">
            <input matInput placeholder="Available to book to" class="capitalized" aria-label="Available to book to" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0 mt-2">
        <div class="col px-2 text-center">
          <button class="btn em-btn em-btn-green" (click)="updateProvider()" [disabled]="this.form.pristine">
            Update
          </button>
        </div>
        <div class="col px-2 text-center">
          <button class="btn em-btn em-btn-green" (click)="cancelUpdate()">Cancel</button>
        </div>
      </div>
    </form>
  </perfect-scrollbar>
</div>
