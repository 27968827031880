import { Component, OnInit, ViewChild, NgZone, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PrescriptionService } from '@services/prescriptions/prescription.service';
import { ExternalPrescriptionService } from '@services/prescriptions/external-prescription.service';
import { Prescription } from '@models/prescriptions/prescription';
import { ExternalPrescription } from '@models/prescriptions/external-prescription';
import { PatientService } from '@services/patient.service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-external-prescriptions',
  templateUrl: './external-prescriptions.component.html',
  styleUrls: ['./external-prescriptions.component.less'],
})
export class ExternalPrescriptionsComponent implements OnInit {
  public prescriptionList: Prescription[];
  private externalPrescriptionList: ExternalPrescription[];
  public externalPrescriptionEntry: ExternalPrescription;
  @Input() panelHeight: number;
  @Input() patientId: number;

  constructor(
    private prescriptionService: PrescriptionService,
    private externalPrescriptionService: ExternalPrescriptionService,
    private patientService: PatientService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (!this.patientId) {
      this.patientId = this.route.snapshot.params.patId.split('_')[0];
    }
    this.getExternalPrescriptionsByPatientId();
  }

  ngOnChanges() {
    this.getExternalPrescriptionsByPatientId();
  }

  public getExternalPrescriptionsByPatientId() {
    if (this.patientId) {
      this.externalPrescriptionService.getExternalPrescriptionsByPatientId(this.patientId).subscribe((res) => {
        this.externalPrescriptionList = res;
        this.externalPrescriptionEntry = this.externalPrescriptionList.length
          ? this.externalPrescriptionList[0]
          : new ExternalPrescription({
              description: '',
              patientId: (this.patientService.patientPanelPatient)
                ? this.patientService.patientPanelPatient.patientId
                : (this.patientId) ? this.patientId : 0
            });
      });
    }
  }

  public addExternalPrescription(entry: string) {
    this.externalPrescriptionEntry.description = entry;
    if (this.externalPrescriptionEntry.id) {
      this.updateExternalPrescription(this.externalPrescriptionEntry);
    } else {
      this.externalPrescriptionService
        .addExternalPrescription(this.externalPrescriptionEntry)
        .subscribe((externalRx: ExternalPrescription) => {
          this.externalPrescriptionEntry = externalRx;
        });
    }
  }

  public updateExternalPrescription(item: ExternalPrescription) {
    this.externalPrescriptionService.updateExternalPrescription(item).subscribe(() => {});
  }
}
