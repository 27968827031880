<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
  <h1 mat-dialog-title class="text-center">{{ isNew ? 'Add User Commission' : 'Edit User Commission' }}</h1>
  <div mat-dialog-content>
    <div class="container">
      <div class="row justify-content-center">
        <div class="toggle-container mb-2 d-flex justify-content-center align-items-center">
          <mat-button-toggle-group formControlName="isFlatRate" aria-label="Commission Type">
            <mat-button-toggle [value]="true">Flat Rate</mat-button-toggle>
            <mat-button-toggle [value]="false">Percentage</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div class="row justify-content-center">
        <mat-form-field appearance="fill">
          <mat-label>Service</mat-label>
          <input type="text" matInput [matAutocomplete]="auto" formControlName="service" required />
          <mat-icon matSuffix class="drop-down-arrow">arrow_drop_down</mat-icon>
          <mat-autocomplete autoSelectActiveOption #auto="matAutocomplete" [displayWith]="getServiceName">
            <mat-option
              *ngFor="let service of filteredServices | async"
              [value]="service"
              [disabled]="checkServiceDisabled(service.id)"
            >
              {{ service.serviceAltName }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div *ngIf="isInjection" class="row justify-content-center">
        <mat-form-field appearance="fill">
          <mat-label>Injectable</mat-label>
          <mat-select formControlName="observationListId" [required]="checkInjectableReq()">
            <mat-option
              *ngFor="let supply of data.clinicSupplies"
              [value]="supply.id"
              [disabled]="checkSupplyDisabled(supply.id)"
            >
              {{ supply.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row justify-content-center">
        <mat-form-field appearance="fill">
          <mat-label>Commission {{ editForm.controls.isFlatRate.value ? '($)' : '(%)' }}</mat-label>
          <input matInput type="number" formControlName="commission" required />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-center">
    <button
      matDialogClose
      class="btn em-btn em-btn-green mx-2 action-button"
      type="submit"
      [disabled]="!editForm.valid || !editForm.dirty"
    >
      {{ isNew ? 'Save' : 'Update' }}
    </button>
    <button matDialogClose class="btn em-btn em-btn-green mx-2 action-button" (click)="onCancel()">Cancel</button>
  </div>
</form>
