<ngx-loading [show]="loading" id="gridDisableDiv"></ngx-loading>
<div class="px-2">
  <div class="etreatment-forms" [ngStyle]="{ height: 'calc(100dvh - 75px)' }">
    <div class="row mt-2">
      <div class="col-4">
        <perfect-scrollbar appDetermineMaxScrollHeight [itemsToWatch]="[eTreatmentForms]">
          <ngx-loading [show]="disableTabsAndGrid" id="gridDisableDiv"></ngx-loading>
          <kendo-grid
            *ngIf="!assignMode"
            [data]="gridView"
            [pageSize]="gridState.take"
            [skip]="gridState.skip"
            [sort]="gridState.sort"
            [sortable]="true"
            [selectable]="true"
            [style.height.%]="100"
            (add)="addHandler($event)"
            (edit)="editHandler($event)"
            (remove)="deactivateHandler($event)"
            (selectionChange)="onSelectRow($event)"
            kendoGridSelectBy="id"
            [selectedKeys]="selectedKeys"
            [rowClass]="rowCallback"
          >
            <ng-template kendoGridToolbarTemplate *appAuthGuard="developerPolicy">
              <!--               <div class="d-flex">
                <span class="pl-4 search-box border border-radius">
                  <span class="fa fa-search"></span>
                  <mat-form-field class="w-75 p-3">
                    <input
                      matInput
                      placeholder="Search..."
                      aria-label="Search eTreatment Forms"
                      [(ngModel)]="searchValue"
                      [formControl]="searchCtrl"
                    />
                    <button
                      mat-button
                      *ngIf="searchValue"
                      matSuffix
                      mat-icon-button
                      aria-label="Clear"
                      (click)="searchValue = ''"
                    >
                      <mat-icon><i class="fa fa-times-circle"></i></mat-icon>
                    </button>
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option *ngFor="let form of filteredForms | async" [value]="form.name">
                        <span>{{ form.name }}</span>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </span>
              </div> -->
              <button kendoGridAddCommand class="btn kendo-btn kendo-btn-green em-btn" icon="plus-outline">
                Add New Form
              </button>
            </ng-template>
            <kendo-grid-column
              field="name"
              title="Name"
              [headerClass]="'teal-background white-font'"
              [class]="{ nameColumn: true }"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="d-flex">
                  <span>{{ dataItem.name }}</span>
                  <div *ngIf="dataItem.isSharedForm" class="ml-auto">
                    <i class="far fa-cloud" title="Shared Form"></i>
                  </div>
                </div>
              </ng-template>
            </kendo-grid-column>
            <ng-template kendoGridDetailTemplate let-dataItem>
              <section>
                <div><strong>Created Date:</strong> {{ dataItem.createdDate | date : 'yyyy-MM-dd' }}</div>
                <div>
                  <strong>Created by User:</strong>
                  {{
                    dataItem.createdByUser
                      ? dataItem.createdByUser.firstName + ' ' + dataItem.createdByUser.lastName
                      : 'Anonymous'
                  }}
                </div>
                <div *ngIf="dataItem.isSharedForm">
                  <div>
                    <strong>Shared by User:</strong>
                    {{
                      dataItem.sharedByUser
                        ? dataItem.sharedByUser.firstName + ' ' + dataItem.sharedByUser.lastName
                        : 'Anonymous'
                    }}
                  </div>
                  <div>
                    <strong>Shared by Clinic:</strong>
                    {{ dataItem.sharedByClinic ? dataItem.sharedByClinic.name : 'Anonymous' }}
                  </div>

                  <div><strong>Shared Date:</strong> {{ dataItem.sharedFormCreationDate | date : 'yyyy-MM-dd' }}</div>
                </div>
              </section>
            </ng-template>
            <kendo-grid-column
              field="eTreatmentFormCategory.name"
              title="Category"
              [headerClass]="'teal-background white-font'"
            ></kendo-grid-column>
            <kendo-grid-command-column
              *appAuthGuard="developerPolicy"
              min-width="115px"
              [width]="115"
              [headerClass]="'teal-background white-font'"
            >
              <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                <div *ngIf="!dataItem.isDeleted">
                  <button kendoGridEditCommand class="btn kendo-btn" kendoTooltip title="Edit eTreatment Form Details">
                    <i class="fas fa-pencil"></i>
                  </button>
                  <button
                    class="btn kendo-btn k-button"
                    (click)="editeTreatmentFormHandler(dataItem)"
                    kendoTooltip
                    tooltipClass="em-tooltip"
                    position="top"
                    title="Edit eTreatment Form"
                  >
                    <i class="far fa-edit"></i>
                  </button>
                  <button
                    class="btn kendo-btn k-button"
                    (click)="cloneeTreatmentFormHandler(dataItem.id)"
                    kendoTooltip
                    title="Clone eTreatment Form"
                  >
                    <i class="fal fa-copy"></i>
                  </button>
                  <button kendoGridRemoveCommand class="btn kendo-btn" kendoTooltip title="Deactivate eTreatment Form">
                    <i class="fas fa-minus-circle"></i>
                  </button>
                </div>
                <div *ngIf="dataItem.isDeleted" class="center">
                  <label class="switch align-text-top" kendoTooltip title="Activate Form"
                    ><input type="checkbox" (click)="activateeTreatmentFormHandler(dataItem)" /><span
                      class="slider round"
                    ></span
                  ></label>
                </div>
              </ng-template>
            </kendo-grid-command-column>
          </kendo-grid>
          <kendo-grid
            *ngIf="assignMode"
            [data]="gridView"
            [pageSize]="gridState.take"
            [skip]="gridState.skip"
            [sort]="gridState.sort"
            [sortable]="true"
            [selectable]="true"
            [style.height.%]="100"
            (add)="addHandler($event)"
            (edit)="editHandler($event)"
            (remove)="deactivateHandler($event)"
            (selectionChange)="onSelectRow($event)"
            kendoGridSelectBy="id"
            [selectedKeys]="selectedKeys"
            [rowClass]="rowCallback"
          >
            <kendo-grid-column
              field="name"
              title="Name"
              [headerClass]="'teal-background white-font'"
              [class]="{ nameColumn: true }"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="d-flex">
                  <span>{{ dataItem.name }}</span>
                  <div *ngIf="dataItem.isSharedForm" class="ml-auto">
                    <i class="far fa-cloud" title="Shared Form"></i>
                  </div>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="eTreatmentFormCategory.name"
              title="Category"
              [headerClass]="'teal-background white-font'"
            ></kendo-grid-column>
            <kendo-grid-command-column
              title="Add"
              min-width="55px"
              [width]="55"
              [headerClass]="'teal-background white-font'"
            >
              <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                <div class="center">
                  <button
                    class="btn kendo-btn k-button"
                    (click)="assigneTreatmentFormHandler(dataItem)"
                    kendoTooltip
                    title="Assign Form"
                  >
                    <i class="fal fa-plus-circle"></i>
                  </button>
                </div>
              </ng-template>
            </kendo-grid-command-column>
          </kendo-grid>
        </perfect-scrollbar>
      </div>
      <div class="col-8">
        <div *ngIf="eTreatmentFormSelected && editMode" class="d-flex justify-content-end">
          <button type="button" class="btn em-btn em-btn-green flex-nowrap mr-1 mb-1" (click)="saveHandler()">
            <i class="fal fa-save"></i>
            &nbsp;Save
          </button>
          <button type="button" class="btn em-btn em-btn-green flex-nowrap mr-1 mb-1" (click)="cancelHandler()">
            <i class="fal fa-ban"></i>
            &nbsp;Cancel
          </button>
        </div>
        <perfect-scrollbar>
          <div
            class="w-100 align-items-start justify-content-center px-0 light-grey-background"
            [ngStyle]="{ height: '90dvh' }"
          >
            <div *ngIf="eTreatmentFormSelected">
              <app-form-builder
                *ngIf="editMode"
                [form]="this.editingDataItem"
                [refreshBuilder]="eTreatmentFormRefreshed$"
              ></app-form-builder>
              <div *ngIf="!editMode" class="card">
                <div class="card-body">
                  <div class="card bg-light p-3">
                    <app-form-viewer
                      [form]="this.selectedDataItem"
                      [editable]="false"
                      [refreshViewer]="eTreatmentFormRefreshed$"
                    ></app-form-viewer>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="!eTreatmentFormSelected"
              frameBorder="0"
              class="w-100 h-100 d-flex align-items-start justify-content-center px-0 pb-2 light-grey-background"
            >
              <span class="m-auto font-weight-bold" style="font-size: 2em">Please select an eTreatment Form.</span>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</div>

<app-actionpanel></app-actionpanel>
