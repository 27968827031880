import { ClinicsService } from '@services/clinics.service';

import { takeUntil, startWith, map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Resource } from '@models/resource';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { orderBy, SortDescriptor, State } from '@progress/kendo-data-query';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete/confirm-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Observable } from 'rxjs';
import { ResourcesService } from '@services/resources.service';
import { ResourceType } from '@models/resource-type';

@Component({
  selector: 'app-org-resources',
  templateUrl: './org-resources.component.html',
  styleUrls: ['./org-resources.component.less'],
})
export class OrgResourcesComponent implements OnInit, OnDestroy {
  searchValue = '';
  disableGrid = false;
  loading = false;
  unsub: Subject<void> = new Subject<void>();
  resources: Resource[] = [];
  searchCtrl: FormControl;
  filteredResources: Observable<Resource[]>;

  gridView: GridDataResult;
  gridState: State = {
    sort: [
      {
        field: 'resourceType',
        dir: 'asc',
      },
      {
        field: 'name',
        dir: 'asc',
      },
    ],
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  formGroup: FormGroup;
  editedRowIndex: number;
  editedDataItem: Resource;
  clinicId: number;

  ResourceType = ResourceType;

  constructor(
    private clinicsService: ClinicsService,
    private resourcesService: ResourcesService,
    private router: Router,
    private deleteDialog: MatDialog
  ) {
    this.searchCtrl = new FormControl();
    this.filteredResources = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map((pack) => this.filterResources(pack))
    );
  }

  ngOnInit() {
    this.gridView = {
      data: [],
      total: 0,
    };

    this.clinicsService.clinicIdSelected$
      .asObservable()
      .pipe(takeUntil(this.unsub))
      .subscribe((clinicId) => {
        this.clinicId = clinicId;
        this.refreshData();
      });

    this.resourcesService.resourcesUpdated$.pipe(takeUntil(this.unsub)).subscribe((res) => {
      if (res) {
        this.refreshData();
      }
      this.disableGrid = false;
    });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  filterResources(name: string) {
    let filterResults: Resource[] = [];
    let filteredResources: Resource[] = [];
    let total: number;
    if (name !== '') {
      filteredResources = this.resources.filter((rsrc) => rsrc.name.toLowerCase().includes(name.toLowerCase()));
      total = filteredResources.length;
      filterResults = filteredResources;
    } else {
      filteredResources = this.resources;
      total = filteredResources.length;
      filterResults = [];
    }
    this.setGridView(filteredResources, total);
    return filterResults;
  }

  setGridView(resources: Resource[], total: number) {
    this.gridView = {
      data: orderBy(resources, this.gridState.sort),
      total: total,
    };
  }

  refreshData() {
    this.loading = true;
    this.resources = [];
    this.resourcesService.getResources(this.clinicId).subscribe((res) => {
      res.forEach((doc) => {
        const docData = doc;
        const pushItem: Resource = {
          resourceId: docData.resourceId,
          name: docData.name,
          resourceType: docData.resourceType,
          disabled: docData.disabled,
        };
        this.resources.push(pushItem);
      });
      this.filterResources(this.searchValue);
      this.loading = false;
    });
  }

  addResource(rsrc: Resource) {
    this.resourcesService.addResource(rsrc);
  }

  onAddClick({ sender }) {
    this.disableGrid = true;
    this.router.navigate([
      '/management/organization/clinics/resources',
      { outlets: { 'action-panel': ['edit-resource', '_'] } },
    ]);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.disableGrid = true;
    this.router.navigate([
      '/management/organization/clinics/resources',
      { outlets: { 'action-panel': ['edit-resource', dataItem.resourceId] } },
    ]);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    const rsrc: Resource = formGroup.value;
    if (isNew) {
      this.resourcesService.addResource(rsrc).subscribe(() => {
        this.refreshData();
      });
    } else {
      this.resourcesService.updateResource(rsrc).subscribe(() => {
        this.refreshData();
      });
    }
    sender.closeRow(rowIndex);
  }

  public removeHandler({ dataItem }) {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        const dataItemToRemove = {
          resourceId: dataItem.resourceId,
          name: dataItem.name,
          resourceType: dataItem.resourceType,
          disabled: dataItem.disabled,
        };
        this.resourcesService.removeResource(dataItemToRemove).subscribe(() => {
          this.refreshData();
        });
      }
    });
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  loadItems() {
    this.setGridView(this.resources, this.resources.length);
  }

  sortChange(sort: SortDescriptor[]) {
    this.gridState.sort = sort;
    this.filterResources(this.searchValue);
  }

  openDeleteDialog(): void {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
      data: { result: '' },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  getDetails(resourceType: ResourceType): string {
    if (resourceType === ResourceType.Room) {
      return '200 sq.ft.';
    }
  }
}
