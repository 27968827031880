import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Allergy } from '@models/allergy';
import { AllergiesService } from '@services/allergies/allergies.service';
import { UsersService } from '@services/users.service';

@Component({
  selector: 'app-allergies',
  templateUrl: './allergies.component.html',
  styleUrls: ['./allergies.component.less']
})
export class AllergiesComponent implements OnInit, OnDestroy {
  patientId: number;
  private unsub: Subject<void> = new Subject<void>();
  loading: boolean = false;
  allergy: Allergy;
  public errorMessage = '';

  constructor(
    private allergiesService: AllergiesService,
    private usersService: UsersService,
    public dialogRef: MatDialogRef<AllergiesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.allergy = this.data.allergy;
    this.patientId = this.data.patientId;
    if (!this.allergy) {
      this.allergy = new Allergy();
      this.allergy.allergyId = 0;
      this.allergy.dateCreated = new Date();
      this.allergy.dateUpdated = new Date();
      this.allergy.patientId = this.patientId;
      this.allergy.user = this.usersService.loggedInUser;
    }
  }


  public saveAllergies(event: string) {
    this.allergy.description = event;
    this.loading = true;

    if (this.allergy.allergyId === 0) {
      this.allergiesService.addAllergy(this.allergy)
        .pipe(takeUntil(this.unsub))
        .subscribe(
          (allergy: Allergy) => {
            this.allergy = allergy;
            this.dialogRef.close({ allergy});
            this.loading = false;
          },
          (error: HttpErrorResponse) => {
            this.errorMessage = error.error.errors[0].fieldErrors[0];
            this.loading = false;
          });
    } else {
      this.allergiesService.updateAllergy(this.allergy)
        .pipe(takeUntil(this.unsub))
        .subscribe(
          (allergy: Allergy) => {
            this.allergy = allergy;
            this.dialogRef.close({ allergy});
            this.loading = false;
          },
          (error: HttpErrorResponse) => {
            this.errorMessage = error.error.errors[0].fieldErrors[0];
            this.loading = false;
          });
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
