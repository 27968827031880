import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { EmbeddedReport } from '@models/reports/embeded-report';
import { PowerBiService } from '@services/power-bi.service';
import { models, IReportEmbedConfiguration } from 'powerbi-client';
import { EventHandler } from 'powerbi-client-angular/components/powerbi-embed/powerbi-embed.component';

@Component({
  selector: 'app-power-bi-reports',
  templateUrl: './power-bi-reports.component.html',
  styleUrls: ['./power-bi-reports.component.less'],
})
export class PowerBiReportsComponent implements OnInit {
  embedConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  };

  eventHandlers = new Map<string, EventHandler>([
    // ['loaded', () => console.log('Report loaded')],
    // ['rendered', () => console.log('Report rendered')],
    // ['error', (event) => console.log(event.detail)],
  ]);

  loading = false;
  errorMessage = '';
  reports: EmbeddedReport[];

  constructor(private powerBiService: PowerBiService) {}

  ngOnInit(): void {
    this.loading = true;
    this.powerBiService.getReports().subscribe(
      (reports: EmbeddedReport[]) => {
        this.reports = reports;
        if (reports.length) {
          this.selectReport(reports[0]);
        }
        else {
          this.errorMessage = 'No Reports Found'
        }
        
      },
      (err: HttpErrorResponse) => {
        if (err.status == 404) this.errorMessage = 'Error: Reports Not Found';
        else if (err.status == 401) this.errorMessage = 'Error: Unauthorized';
        else this.errorMessage = 'An unknown error has occurred';
      },
      () => (this.loading = false)
    );
  }

  selectReport(report: EmbeddedReport) {
    if (report) {
      this.embedConfig = { ...this.embedConfig, id: report.id, embedUrl: report.embedUrl, accessToken: report.token };
    }
  }
}
