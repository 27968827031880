<div class="modal-title d-flex" mat-dialog-title>
  <button class="btn ml-auto white-font" (click)="dialogRef.close()">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-container" mat-dialog-content>
  <h6 class="text-center navy-font mb-3">Rx signature</h6>
  <formio [form]="form" (change)="onFormChange($event)"> </formio>
</div>

<div class="d-flex justify-content-center pb-4" mat-dialog-actions>
  <button type="button" [disabled]="!signature" (click)="dialogRef.close(signature)" class="btn em-btn em-btn-green">SIGN</button>
</div>
