import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { PhotoLightboxComponent } from '@app/patients/patient-tabs/shared-photos/photo-lightbox/photo-lightbox.component';
import { Patient, PatientNote } from '@models/patient';
import { PhotoDrawing } from '@models/photo/photo-drawing';
import { ChartNote } from '@models/service-chart/chart-note';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionPanelService, MasterOverlayService } from '@services/actionpanel.service';
import { AppointmentService } from '@services/appointments.service';
import { BreakpointService } from '@services/breakpoint.service';
import { PatientNoteService } from '@services/patient-note.service';
import { PhotoDrawingService } from '@services/photo-drawing.service';
import { UsersService } from '@services/users.service';
import moment from 'moment';
import { EMPTY, Observable, Subject, forkJoin, throwError } from 'rxjs';
import { catchError, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { ServiceChartDrawToolComponent } from '../../modals/service-chart-draw-tool/service-chart-draw-tool.component';

@Component({
  selector: 'app-chart-note-entry',
  templateUrl: './chart-note-entry.component.html',
  styleUrls: ['./chart-note-entry.component.less'],
})
export class ChartNoteEntryComponent implements OnInit, OnDestroy {
  errorMessage = '';
  isLoading = false;
  mobileView = true;
  showServiceDetail = false;
  submitButtons = { draft: false, save: false };
  chartEntry: ChartNote;
  parseInt = parseInt;
  unsub: Subject<void> = new Subject<void>();
  @ViewChild('existingNote') existingNote: ElementRef<HTMLTextAreaElement>;
  @ViewChild('existingNoteHtml') existingNoteHtml: ElementRef<HTMLDivElement>;
  @ViewChild('noteParent') noteParent: ElementRef<HTMLDivElement>;
  @ViewChild('newNote') newNote: ElementRef<HTMLTextAreaElement>;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() noteId: number;
  @Input() scrollIndex: number;
  @Input() patient: Patient;
  @Input() applyNote$: Subject<string>;
  @Input() applyPhoto$: Subject<PhotoDrawing>;
  @Input() focusedIndex: number;
  @Output() focusedIndexChange = new EventEmitter<number>();
  @Input() set expandAll(expand: boolean) {
    this.showServiceDetail = expand;
    this.toggleTextArea();
  }
  @Output() chartEdited = new EventEmitter<boolean>();
  @Output() removeEntry = new EventEmitter<void>();

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private appointmentService: AppointmentService,
    private patientNoteService: PatientNoteService,
    private photoDrawingService: PhotoDrawingService,
    private userService: UsersService,
    private modalService: NgbModal,
    private masterOverlayService: MasterOverlayService,
    private actionPanelService: ActionPanelService,
    private _ngZone: NgZone,
    private breakpointService: BreakpointService
  ) {}

  ngOnInit(): void {
    this.getChartEntry();

    this.applyNote$.pipe(takeUntil(this.unsub)).subscribe((note) => {
      if (this.scrollIndex && this.focusedIndex && this.scrollIndex === this.focusedIndex) {
        const htmlElement = this.newNote.nativeElement;
        htmlElement.focus();
        if (htmlElement.value) {
          htmlElement.value = htmlElement.value + '\n' + note;
        } else {
          htmlElement.value = note;
        }
        this.chartEdited.emit(true);
        this.submitButtons.draft = true;
      }
    });

    this.actionPanelService
      .actionPanelVisible()
      .pipe(takeUntil(this.unsub))
      .subscribe(() => {
        setTimeout(() => {
          this.triggerResize();
        });
      });

    this.applyNote$.pipe(takeUntil(this.unsub)).subscribe((note) => {
      if (this.scrollIndex != null && this.focusedIndex != null && this.scrollIndex === this.focusedIndex) {
        const htmlElement = this.newNote.nativeElement;
        htmlElement.focus();
        if (htmlElement.value) {
          htmlElement.value = htmlElement.value + '\n' + note;
        } else {
          htmlElement.value = note;
        }
        this.chartEdited.emit(true);
        this.submitButtons.draft = true;
      }
    });

    this.applyPhoto$.pipe(takeUntil(this.unsub)).subscribe((photoDrawing) => {
      if (this.scrollIndex != null && this.focusedIndex != null && this.scrollIndex === this.focusedIndex) {
        this.chartEntry.photoDrawings.push(photoDrawing);
        this.chartEdited.emit(true);
        this.submitButtons.draft = true;
      }
    });

    this.breakpointService.mobileBreakpoint$.pipe(takeUntil(this.unsub)).subscribe((mobileView) => {
      this.mobileView = mobileView;
    });
  }

  private getChartEntry() {
    if (this.noteId === 0) {
      const chartNote = new ChartNote();
      chartNote.date = moment().utcOffset(0, true).toDate();
      chartNote.isLocked = false;
      chartNote.patientNoteId = 0;
      chartNote.photoDrawings = [];
      this.chartEntry = chartNote;
      return;
    }

    this.isLoading = true;
    this.appointmentService.getChartNote(this.noteId).subscribe(
      (chartEntry) => {
        if (chartEntry.entryText !== '') {
          this.submitButtons.draft = false;
          this.submitButtons.save = false;
        }
        this.chartEntry = chartEntry;
        this.isLoading = false;
      },
      (error) => {
        this.errorMessage = error;
        this.isLoading = false;
      }
    );
  }

  deleteNote() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '330px',
      data: {
        title:
          'Are you sure you want to delete this chart entry? It may take up to 2 minutes for the deletion to propagate throughout the system',
        content: '',
        confirmButtonText: 'Yes',
        showCancel: true,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.isLoading = true;
        if (this.chartEntry.patientNoteId === 0) {
          this.removeEntry.emit();
          this.isLoading = false;
        } else {
          this.chartEntry.photoDrawings.forEach((drawing) => (drawing['delete'] = true));
          this.updateDrawings()
            .pipe(mergeMap(() => this.patientNoteService.deletePatientNote(this.chartEntry.patientNoteId)))
            .subscribe(
              () => {
                this.isLoading = false;
                this.removeEntry.emit();
              },
              (error) => {
                this.errorMessage = error.errorMessage;
                this.isLoading = false;
              }
            );
        }
      }
    });
  }

  async updateChart(isLock: boolean): Promise<any> {
    this.isLoading = true;
    const element = this.newNote.nativeElement;
    let value = element.value;
    const signedInfo: string =
      '- Signed by ' +
      this.userService.loggedInUser.firstName +
      ' ' +
      this.userService.loggedInUser.lastName +
      ' (' +
      moment(new Date()).format('YYYY-MM-DD h:mm A') +
      ')';

    if (isLock) {
      value += '\n' + signedInfo + '\n';
      this.chartEntry.entryText ? (this.chartEntry.entryText += '\n' + value) : (this.chartEntry.entryText = value);
      this.chartEntry.draftText = '';
    } else {
      this.chartEntry.draftText = value;
    }

    await this.updatePatientNote().toPromise();
    this.chartEdited.emit(false);

    if (isLock) {
      element.value = '';
      await this.lockChartEntry().toPromise();
    }
    this.isLoading = false;
  }

  lockChartEntry(): Observable<any> {
    return this.patientNoteService.lockPatientNote(this.chartEntry.patientNoteId).pipe(
      map(() => {
        this.updateSignInformation();
        return true;
      }),
      catchError((err) => {
        this.errorMessage == err;
        return throwError(err);
      })
    );
  }

  updatePatientNote(): Observable<any> {
    const newPatientNote: PatientNote = {
      patientNoteId: this.chartEntry.patientNoteId,
      entryDate: this.chartEntry.date,
      enteredBy: this.userService.loggedInUser.firstName + ' ' + this.userService.loggedInUser.lastName,
      entryText: this.chartEntry.entryText,
      draftText: this.chartEntry.draftText,
      photoDrawings: this.chartEntry.photoDrawings,
      patientId: this.patient.patientId,
    };

    if (newPatientNote.patientNoteId == 0) {
      const newNoteRequest = this.patientNoteService.addPatientNote(this.patient.patientId, newPatientNote).pipe(
        tap((patientNote: PatientNote) => {
          this.chartEntry.patientNoteId = patientNote.patientNoteId;
        }),
        catchError((error) => {
          this.errorMessage == error;
          return throwError(error);
        })
      );

      return newNoteRequest.pipe(
        mergeMap((newNote) => {
          this.chartEntry.photoDrawings.forEach((drawing) => (drawing.patientNoteId = newNote.patientNoteId));
          return this.updateDrawings();
        })
      );
    } else {
      const updateNoteRequest = this.patientNoteService.updatePatientNote(this.patient.patientId, newPatientNote).pipe(
        catchError((error) => {
          this.errorMessage == error;
          return throwError(error);
        })
      );

      this.chartEntry.photoDrawings.forEach((drawing) => (drawing.patientNoteId = this.chartEntry.patientNoteId));
      return forkJoin([updateNoteRequest, this.updateDrawings()]);
    }
  }

  toggleTextArea() {
    if (this.showServiceDetail) {
      this.existingNote?.nativeElement?.classList?.remove('collapsed-area');
    } else {
      this.existingNote?.nativeElement?.classList?.add('collapsed-area');
    }
  }

  onTextareaFocus() {
    if (!this.chartEntry.isLocked) {
      this.focusedIndex = this.scrollIndex;
      this.focusedIndexChange.emit(this.scrollIndex);
    }
    this.showServiceDetail = true;
  }

  onTextareaKeyDown() {
    this.chartEdited.emit(true);
    if (this.chartEntry && !this.chartEntry.isLocked) {
      this.submitButtons.draft = true;
      this.submitButtons.save = false;
    }
  }

  notesFocus() {
    this.newNote.nativeElement.focus();
    this.newNote.nativeElement.classList.remove('collapsed-area');
    this.focusedIndex = this.scrollIndex;
    this.focusedIndexChange.emit(this.scrollIndex);
  }

  private updateDrawings(): Observable<any> {
    let toAdd = [];
    let toUpdate = [];
    let toDelete = [];
    let toDeleteIndexes = [];

    if (!this.chartEntry.photoDrawings) return EMPTY;

    this.chartEntry.photoDrawings.forEach((drawing, index) => {
      if (!drawing.id) toAdd.push(drawing);
      else if (drawing['update']) {
        toUpdate.push(drawing);
      } else if (drawing['delete']) {
        let removeIndex = this.patient.photos.findIndex((photo) => photo.id == drawing.photoId);
        this.patient.photos.splice(removeIndex, 1);
        toDelete.push(drawing);
        toDeleteIndexes.push(index);
      }
    });

    toDeleteIndexes.forEach((index) => this.chartEntry.photoDrawings.splice(index, 1));

    let addRequests = toAdd.map((pd) =>
      this.photoDrawingService.addPhotoDrawing(pd).pipe(
        tap((added) => {
          pd.id = added.id;
          this.patient.photos.push(added.photo);
        })
      )
    );
    let updateRequests = toUpdate.map((pd) =>
      this.photoDrawingService.updatePhotoDrawing(pd).pipe(
        tap((updated) => {
          pd = updated;
          const updateLocation = this.patient.photos.findIndex((photo) => photo.id == pd.photo.id);
          this.patient.photos[updateLocation] = pd.photo;
        })
      )
    );
    let deleteRequest = this.photoDrawingService.deletePhotoDrawings(toDelete);

    let allRequests = [deleteRequest];
    allRequests.push(...addRequests);
    allRequests.push(...updateRequests);

    return forkJoin([...addRequests, ...updateRequests, deleteRequest]);
  }

  onUnlockClick() {
    this.notesFocus();
    this.submitButtons.draft = true;
    this.showServiceDetail = true;
    this.unLockChartEntry();
  }

  unLockChartEntry() {
    this.isLoading = true;
    this.patientNoteService.unLockPatientNote(this.chartEntry.patientNoteId).subscribe(
      () => {
        this.chartEntry.isLocked = false;
        this.isLoading = false;
        this.submitButtons.draft = false;
      },
      (error) => {
        this.errorMessage == error;
        this.isLoading = false;
      }
    );
  }

  openPhotoDrawing(photoDrawing: PhotoDrawing) {
    if (this.chartEntry.isLocked) {
      this.dialog.open(PhotoLightboxComponent, {
        panelClass: 'lightbox-dialog',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        data: {
          photoURL: photoDrawing.photo.filePath,
        },
      });
    } else {
      const modalRef = this.modalService.open(ServiceChartDrawToolComponent, {
        centered: true,
        windowClass: 'draw-tool-modal',
      });
      modalRef.componentInstance.patient = this.patient;
      modalRef.componentInstance.photoDrawing = photoDrawing;
      modalRef.result.then((result) => {
        // this.scrollToEntry.emit(null);
        if (result && result !== 'delete') {
          let [data, updatedPhotoDrawing]: [Blob, PhotoDrawing] = result;
          photoDrawing = updatedPhotoDrawing;
          photoDrawing.photo.filePath = photoDrawing.photo.filePathThumb = URL.createObjectURL(data);
          photoDrawing['update'] = true;
          this.chartEdited.emit(true);
          this.submitButtons.draft = true;
        } else if (result === 'delete') {
          photoDrawing['delete'] = true;
          this.chartEdited.emit(true);
          this.submitButtons.draft = true;
        }
      });
    }
  }

  updateSignInformation() {
    this.chartEntry.isLocked = true;
    this.chartEntry.signedTime = new Date();
    this.chartEntry.signedByUser =
      this.userService.loggedInUser.firstName + ' ' + this.userService.loggedInUser.lastName;
  }

  async signSaveExit() {
    await this.updateChart(true);
    this.submitButtons.save = false;
    this.submitButtons.draft = false;
    this.closePatientPanel();
  }

  closePatientPanel() {
    this.masterOverlayService.masterOverlayState(false);
    const returnURL = this.router.url.slice(0, this.router.url.indexOf('('));
    this.router.navigate([returnURL, { outlets: { 'action-panel': null } }]);
  }

  triggerResize() {
    this._ngZone.onStable.pipe(take(1)).subscribe(() => {
      if (this.autosize && this.autosize.resizeToFitContent) {
        this.autosize.resizeToFitContent(true);
      }
    });
  }

  isHtml(content: string) {
    if (!content) {
      return false;
    }

    return /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(content);
  }

  getHtmlComponentHeight(): number {
    if (this.existingNoteHtml) {
      let height = this.existingNoteHtml.nativeElement.clientHeight;
      return height;
    } else return 0;
  }

  getParentNoteComponentHeight(): number {
    if (this.noteParent) {
      let height = this.noteParent.nativeElement.clientHeight;
      return height;
    } else return 0;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
    this.chartEntry?.photoDrawings?.forEach((pd) => {
      if (pd.photo?.filePath) {
        URL.revokeObjectURL(pd.photo.filePath);
      }
    });
  }
}
