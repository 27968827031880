import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from '@app/shared/helpers';
import { Clinic } from '@models/clinic';
import { Invoice } from '@models/invoice/invoice';
import { ObservationType, ObservationTypes } from '@models/observation/observation';
import { Patient } from '@models/patient';
import { Service } from '@models/service/service';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { ServiceListType } from '@models/service/service-list-type';
import { TabType } from '@models/tab-type.enum';
import { PlannedTreatment } from '@models/treatment-planning/planned-treatment';
import { TreatmentPlan } from '@models/treatment-planning/treatment-plan';
import { TreatmentState } from '@models/treatment-planning/treatment-state';
import { Visit } from '@models/visit';
import { VisitConfirmedStatus } from '@models/visit-confirm-status';
import { AppointmentService } from '@services/appointments.service';
import { ClinicsService } from '@services/clinics.service';
import { CurrentDataService } from '@services/currentData.service';
import { EventsService } from '@services/events.service';
import { InvoicesService } from '@services/invoices.service';
import { ObservationTypesService } from '@services/observation-types.service';
import { ObservationService } from '@services/observation.service';
import { PatientService } from '@services/patient.service';
import { ServiceEventService } from '@services/service-event.service';
import { ServiceTemplatesService } from '@services/service-templates.service';
import { ServicesService } from '@services/services.service';
import { TreatmentPlanFormService } from '@services/treatment-planning/treatment-plan-form.service';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { UsersService } from '@services/users.service';
import { VisitService } from '@services/visit.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-treatment-plan-overview',
  templateUrl: './treatment-plan-overview.component.html',
  styleUrls: ['./treatment-plan-overview.component.less'],
})
export class TreatmentPlanOverviewComponent implements OnInit, OnDestroy {
  @Input() showProducts: boolean;
  @Input() serviceTemplateId: number;
  @Input() showDetailButton = true;
  @Input() isLocked = true;
  @Input() isTreatmentPlanning = false;
  @Input() isAdvancedTreatmentPage = false;
  @Input() typeOfTab: TabType;
  @Output() selectedItem = new EventEmitter();
  @Input() panelHeight: number;
  @Input() patient: Patient;
  @Input() patientId: number;
  _currentInvoice: Invoice = null;
  get currentInvoice() {
    return this._currentInvoice;
  }
  @Input() set currentInvoice(currentInvoice) {
    this._currentInvoice = currentInvoice;
    if (currentInvoice) {
      this.isInvoicePage = true;
      this.invoicesService.invoiceSet.next(currentInvoice);
    } else {
      this.isInvoicePage = false;
    }
  }

  isInvoicePage = false;
  get currentInvoiceLineItemIds() {
    return this.currentInvoice ? this.currentInvoice.invoiceLineItems.map((ili) => ili.serviceId) : [];
  }
  ServiceDetailTemplate = ServiceDetailTemplate;
  TreatmentState = TreatmentState;
  TabType = TabType;
  visit: Visit;
  selectedTreatmentIndex: number;
  treatmentPlan: TreatmentPlan;

  unsub = new Subject();
  expanded: boolean;
  toggleIndex: number;
  observationType: ObservationType;

  startTime: Date;
  selectedService: Service;
  calculatedDuration: number;
  loading = false;
  errors: any[] = [];

  serviceListType = ServiceListType;
  observationTypes = ObservationTypes;
  serviceDetailTemplate = ServiceDetailTemplate;
  treatmentState = TreatmentState;
  clinic: Clinic;
  serviceTemplateIds: number[];

  constructor(
    private observationService: ObservationService,
    private patientService: PatientService,
    private eventsService: EventsService,
    private treatmentPlanService: TreatmentPlanService,
    private appointmentService: AppointmentService,
    private route: ActivatedRoute,
    private router: Router,
    private treatmentPlanFormService: TreatmentPlanFormService,
    private currentDataService: CurrentDataService,
    private observationTypesService: ObservationTypesService,
    private serviceEventService: ServiceEventService,
    private serviceTemplatesService: ServiceTemplatesService,
    private servicesService: ServicesService,
    private invoicesService: InvoicesService,
    private visitsService: VisitService,
    private userService: UsersService,
    private clinicsService: ClinicsService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.expanded = false;
    this.toggleIndex = -1;

    if (this.route.snapshot.params.patId) {
      this.patientId = +this.route.snapshot.params.patId.split('_')[0];
    }

    // get patient detail if not available
    if (this.patientId) {
      this.getPatient();
    }

    this.serviceTemplatesService
      .getServiceTemplateList()
      .subscribe((templates) => (this.serviceTemplateIds = templates.map((template) => template.id)));
    this.getPlanOverview();
  }

  ngOnChanges() {
    this.getPlanOverview();
  }

  private getPatient() {
    this.patientService.getPatientById(this.patientId).subscribe((patient) => {
      this.patient = patient;
    });
  }

  private getPlanOverview() {
    this.invoicesService.invoiceUpdated$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.loading = false;
      this.loadTreatmentPlan();
    });

    this.treatmentPlanService.treatmentPlanScheduled$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.loadTreatmentPlan();
    });

    this.treatmentPlanService.plannedTreatmentCompleted$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.loadTreatmentPlan();
    });

    this.treatmentPlanService.plannedTreatmentCancelled$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.loadTreatmentPlan();
    });
    this.loadTreatmentPlan();
    this.loadVisits();
    this.findObservationType();

    this.treatmentPlanService.treatmentPlanUpdated$.pipe(takeUntil(this.unsub)).subscribe((tp) => {
      this.treatmentPlan = this.treatmentPlanFormService.onCleanTreatmentPlan(tp);
      this.onFilterPlannedTreatments();
    });
    this.patientService.thePatientUpdated$.pipe(takeUntil(this.unsub)).subscribe((patient) => {
      this.treatmentPlanService.getTreatmentPlanByPatientIdExcludeCompleted(this.patientId).subscribe(async (tp) => {
        this.treatmentPlan = this.treatmentPlanFormService.onCleanTreatmentPlan(tp);
        this.onFilterPlannedTreatments();
      });
    });
    this.onFilterPlannedTreatments();

    this.invoicesService.invoiceLineItemDeleted$.pipe(takeUntil(this.unsub)).subscribe((invoiceLineItem) => {
      this.loadTreatmentPlan();
    });

    this.clinic = this.clinicsService.clinic;
  }

  private loadTreatmentPlan() {
    if (this.patientId) {
      this.loading = true;
      this.treatmentPlanService
        .getTreatmentPlanByPatientIdExcludeCompleted(this.patientId)
        .pipe(takeUntil(this.unsub))
        .subscribe(async (tp) => {
          this.treatmentPlan = this.treatmentPlanFormService.onCleanTreatmentPlan(tp);
          this.onFilterPlannedTreatments();
          // this.addPlannedTreatmentMultiples();
          this.loading = false;
        });
    }
  }

  getUnplannedTreatments() {
    this.loading = true;
    this.treatmentPlanService
      .getUnplannedTreatmentPlanByPatientId(this.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((tp) => {
        this.treatmentPlan = this.treatmentPlanFormService.onCleanTreatmentPlan(tp);
        this.loading = false;
      });
  }

  // COMMENT OUT BEGIN

  private findObservationType() {
    if (!isNullOrUndefined(this.serviceTemplateId)) {
      this.serviceTemplatesService.getServiceTemplateById(this.serviceTemplateId).subscribe((st) => {
        if (!isNullOrUndefined(st)) {
          //const observationTypes = this.currentDataService.observationTypes;
          this.observationTypesService.getAllObservationTypes().subscribe((observationTypes) => {
            if (st.serviceDetailTemplateId === this.serviceDetailTemplate.Injections) {
              this.observationType = observationTypes.filter((obrType) => obrType.name === 'Injectables')[0];
            } else {
              if (st.serviceDetailTemplateId === this.serviceDetailTemplate.Coolsculpting) {
                this.observationType = observationTypes.filter((obrType) => obrType.name === 'Coolsculpting')[0];
              }
            }
          });
        }
      });
    }
  }

  private loadVisits() {
    if (this.patientId) {
      this.eventsService.currentDate.pipe(takeUntil(this.unsub)).subscribe((date) => {
        const visitIdString = this.patientId.toString() + date.toDateString();
        const visitToAdd: Visit = new Visit({
          visitId: 0,
          visitIdString: visitIdString,
          patientId: this.patientId,
          patient: null,
          visitNotes: '',
          patientNotes: '',
          cancellationReason: '',
          cancellationMessage: '',
          isCancellationAlert: false,
          cancellationDate: null,
          cancelled: false,
          appointments: [],
          totalVisitCost: 0,
          checkedIn: false,
          confirmedStatus: VisitConfirmedStatus.Unconfirmed,
          noShow: false,
          date: date,
          createdBy: this.userService.loggedInUser.firstName + ' ' + this.userService.loggedInUser.lastName,
        });
        this.visitsService
          .getVisitByEvent(this.patientId.toString() + date.toDateString())
          .pipe(takeUntil(this.unsub))
          .subscribe((visit) => {
            if (visit && visit.visitId && !visit.cancelled) {
              this.visit = visit;
            } else {
              this.visitsService
                .addVisit(visitToAdd)
                .pipe(
                  switchMap((visit: Visit) => {
                    this.visit = visit;
                    return this.patientService.getPatientById(visit.patientId);
                  }),
                  takeUntil(this.unsub)
                )
                .subscribe((patient: Patient) => {
                  this.visit.patient = patient;
                });
            }
          });
      });
    }
  }

  goToScheduledTreatment(plannedTreatment: PlannedTreatment, index: number) {
    this.appointmentService.shareServiceTemplateId(plannedTreatment.service.templateId);
  }

  public async onApplyTreatment(treatment: PlannedTreatment) {
    let service = treatment.service;
    if (this.isLocked || this.isTreatmentPlanning) {
      return;
    }
    const observationType: ObservationTypes =
      this.observationType.name === this.observationTypes.Injectables
        ? this.observationTypes.Injectables
        : this.observationTypes.Coolsculpting;

    this.serviceEventService.emitApplyTreatment(service, observationType);
    this.currentDataService.treatmentIsDirty = true;
  }

  private onFilterPlannedTreatments() {
    if (
      !isNullOrUndefined(this.serviceTemplateId) &&
      !isNullOrUndefined(this.treatmentPlan) &&
      !isNullOrUndefined(this.treatmentPlan.plannedTreatments)
    ) {
      this.treatmentPlan.plannedTreatments = this.treatmentPlan.plannedTreatments.filter((pt) => {
        return pt.service.templateId === this.serviceTemplateId;
      });
    }
  }

  // COMMENT OUT END

  public onViewPlan() {
    this.router.navigateByUrl(
      `/schedule/(action-panel:patient/${this.patientId}_patientprofiletab/patienttabs/patienttreatmentplantab/overview)`
    );
  }

  public selectService(service) {
    this.loading = true;
    this.selectedItem.emit(service);
  }

  public getServiceColor(item) {
    let value = {
      background: item.treatmentColour,
    };

    if (this.isTreatmentPlanning) {
      value = {
        background: item.service.serviceId !== +this.route.snapshot.params.id ? '#B0B0B0' : item.treatmentColour,
      };
    }

    return value;
  }

  // COMMENT OUT BEGIN

  private addPlannedTreatmentMultiples() {
    if (!isNullOrUndefined(this.treatmentPlan) && !isNullOrUndefined(this.treatmentPlan.plannedTreatmentMultiples)) {
      let newRank = this.treatmentPlan.plannedTreatments.length + 1;

      let plannedTreatmentMultiples = this.treatmentPlan.plannedTreatmentMultiples;
      for (let i = 0; i < plannedTreatmentMultiples.length; i++) {
        let plannedTreatmentMultiple = plannedTreatmentMultiples[i];
        // How many scheduled ones do we have already?
        const totalPlannedTreatmentsCount = plannedTreatmentMultiple.plannedTreatments.length;
        if (totalPlannedTreatmentsCount > 0) {
          const availablePlannedTreatments = plannedTreatmentMultiple.plannedTreatments.filter((pt) => {
            return isNullOrUndefined(pt.plannedDate);
          });
          if (availablePlannedTreatments.length > 0) {
            // Use the values from the first available Planned Treatments
            const firstAvailablePlannedTreatment = availablePlannedTreatments[0];

            // Convert to PlannedTreatment for the list of Planned Treatment
            const plannedTreatmentToAdd: PlannedTreatment = new PlannedTreatment({
              id: firstAvailablePlannedTreatment.id,
              rank: newRank++,
              service: firstAvailablePlannedTreatment.service,
              scheduledService: null,
              scheduledServiceId: null,
              concern: '',
              notes: plannedTreatmentMultiple.notes,
              createdDate: moment(plannedTreatmentMultiple.createdDate).toString(),
              updatedDate: null,
              plannedDate: null,
              dueDate: null,
              treatmentState: TreatmentState.Unplanned,
              treatmentColour: firstAvailablePlannedTreatment.treatmentColour,
              paymentStatus: plannedTreatmentMultiple.paymentStatus,
              plannedTreatmentMultipleId: plannedTreatmentMultiple.id,
            });

            // Update the Service Price with thee Planned Treatment Multiple Price
            plannedTreatmentToAdd.service.defaultPrice = plannedTreatmentMultiple.totalAmount;
            this.treatmentPlan.plannedTreatments.push(plannedTreatmentToAdd);
          }
        }
      }
    }
  }

  public getPlannedTreatmentMultiple(plannedTreatmentMultipleId: number) {
    let plannedTreatmentMultiple = this.treatmentPlan.plannedTreatmentMultiples.find(
      (plannedTreatmentMultiple) => plannedTreatmentMultiple.id === plannedTreatmentMultipleId
    );
    if (isNullOrUndefined(plannedTreatmentMultiple)) {
      return null;
    } else {
      return plannedTreatmentMultiple;
    }
  }

  public payForPlannedTreatmentMultiple(plannedTreatmentMultipleId: number) {
    this.invoicesService
      .getInvoiceByPlannedTreatmentMultipleId(plannedTreatmentMultipleId)
      .pipe(takeUntil(this.unsub))
      .subscribe(
        (invoice: Invoice) => {
          this.loading = false;
          // Goto the Invoice
          this.router.navigate(['patientaccounttab/invoice', invoice.id], { relativeTo: this.route.parent.parent });
        },
        (errorResponse: HttpErrorResponse) => {
          this.errors = errorResponse.error.errors;
          this.loading = false;
        }
      );
  }
  // COMMENT OUT END

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
