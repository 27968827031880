import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-catalogue-clinic-supplies',
  templateUrl: './catalogue-clinic-supplies.component.html',
  styleUrls: ['./catalogue-clinic-supplies.component.less'],
})
export class CatalogueClinicSuppliesComponent implements OnInit {
  disableGrid = false;

  constructor() {}

  ngOnInit() {}

  changeDisableGrid($event: boolean) {
    this.disableGrid = $event;
  }
}
