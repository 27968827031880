<div class="input-group">
  <p-editor placeholder="Type a message" [(ngModel)]="messageText">
    <p-header>
      <span class="ql-formats">
        <button class="ql-bold" aria-label="Bold"></button>
        <button class="ql-italic" aria-label="Italic"></button>
        <button class="ql-underline" aria-label="Underline"></button>
        <button class="ql-strike" aria-label="Strike"></button>
      </span>
      <span class="ql-formats">
        <select title="Text Color" class="ql-color">
          <option value="rgb(255,255,255)" label="rgb(255,255,255)"></option>
          <option value="rgb(0,0,0)" label="rgb(0,0,0)"></option>
          <option value="rgb(255,0,0)" label="rgb(255,0,0)"></option>
          <option value="rgb(0,255,0)" label="rgb(0,255,0)"></option>
          <option value="rgb(0,0,255)" label="rgb(0,0,255)"></option>
          <option value="rgb(128,128,128)" label="rgb(128,128,128)"></option>
          <option value="rgb(255,140,0)" label="rgb(255,140,0)"></option>
        </select>
      </span>
      <span class="ql-formats">
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
      </span>
      <span class="ql-formats">
        <button aria-label="Link" class="ql-link"></button>
      </span>
    </p-header>
  </p-editor>
  <!-- <textarea 
    matInput
    class="form-textarea border-radius border-0"
    formControlName="compose"
    placeholder="Type a message"
    cdkTextareaAutosize
    #autosize="cdkTextareaAutosize"
    cdkAutosizeMinRows="1"
    cdkAutosizeMaxRows="10"
    (keydown)="$event.stopPropagation();"></textarea> -->
  <button
    type="submit"
    (click)="onSubmit()"
    class="ml-3 btn em-btn em-btn-green"
    [disabled]="!messageText || messageText == '' || disconnected"
  >
    Send
  </button>
</div>
