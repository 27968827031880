import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { eTreatmentFormCategory } from '@models/etreatment-forms/etreatment-form-category';

@Injectable({
  providedIn: 'root',
})
export class eTreatmentFormCategoryService {
  constructor(private http: HttpClient) {}

  geteTreatmentFormCategories() {
    return this.http.get<eTreatmentFormCategory[]>(environment.baseUrl + 'api/eTreatmentFormCategory');
  }
}
