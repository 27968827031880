import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';

import { ImageService } from '@services/image.service';
import { BlobService } from '@services/blob.service';
import { ClinicsService } from '@services/clinics.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';

@Component({
  selector: 'app-clinic-logo',
  templateUrl: './clinic-logo.component.html',
  styleUrls: ['./clinic-logo.component.less'],
})
export class ClinicLogoComponent implements OnInit, OnChanges {
  @Input() selectedClinic: any;
  loading = false;
  selectedFile: File = null;
  clinicLogo: string;
  readOnlySas: string;
  maxWidth: number = 1500;
  maxHeight: number = 750;
  constructor(
    private imageService: ImageService,
    private blobService: BlobService,
    private clinicsService: ClinicsService,
    private dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit() {
    this.readOnlySas = this.blobService.getReadOnlySAS();
    this.clinicLogo = this.selectedClinic.logoUrl ? this.selectedClinic.logoUrl + this.readOnlySas : '';
  }

  onFileSelected(event) {
    this.loading = true;
    this.selectedFile = <File>event.target.files[0];

    if (this.selectedFile.type != 'image/png' && this.selectedFile.type != 'image/jpeg') {
      this.dialog.open(GenericDialogComponent, {
        width: '300px',
        data: {
          showCancel: false,
          title: 'Format not supported',
          content: 'Only images with the following extensions are allowed: png, jpg, jpeg',
          confirmButtonText: 'Ok',
        },
      });
      this.loading = false;
    } else {
      const URL = window.URL || window.webkitURL;
      const img = new Image();

      img.src = URL.createObjectURL(this.selectedFile);
      img.onload = () => {
        var currentHeight: number = img.height;
        var currentWidth: number = img.width;

        //resize logic
        let newWidth: number;
        let newHeight: number;

        if (currentWidth > currentHeight) {
          newHeight = currentHeight * (this.maxWidth / currentWidth);
          newWidth = this.maxWidth;
        } else {
          newWidth = currentWidth * (this.maxHeight / currentHeight);
          newHeight = this.maxHeight;
        }

        let canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        let context = canvas.getContext('2d');

        context.drawImage(img, 0, 0, newWidth, newHeight);

        //convert canvas to file
        canvas.toBlob((blob) => {
          var newFile = new File([blob], this.selectedFile.name, { type: this.selectedFile.type });
          this.selectedFile = newFile;
          if (this.selectedFile) {
            this.imageService.uploadClinicLogo(this.selectedFile).subscribe((avatarUri) => {
              this.selectedClinic.logoUrl = avatarUri;
              this.clinicLogo = avatarUri + this.readOnlySas;
              this.updateClinicLogo();
              this.loading = false;
            });
          }
        }, this.selectedFile.type);
      };
    }
  }

  updateClinicLogo() {
    this.clinicsService.updateClinic(this.selectedClinic).subscribe(() => {});
  }

  removeLogo() {
    this.selectedClinic.logoUrl = '';
    this.clinicLogo = '';
    this.updateClinicLogo();
  }
}
