import { RouterModule } from '@angular/router';
import { ReviewsComponent } from './reviews.component';
import { NgModule } from '@angular/core'; 
import { SharedModule } from '@app/shared/shared.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { ReviewsHistoryComponent } from './reviews-history/reviews-history.component';
import { ReviewsReportsComponent } from './reviews-reports/reviews-reports.component'; 
import { AuthModule } from '@app/auth/auth.module';

@NgModule({
  declarations: [
    ReviewsComponent,
    ReviewsHistoryComponent,
    ReviewsReportsComponent, 
  ],
  imports: [
    AuthModule,
    SharedModule,
    GridModule,
    RouterModule,
  ],
  exports: [
    ReviewsComponent,
  ]
})
export class ReviewsModule { }
