import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { eTreatmentFormComponent } from '@app/patients/patient-tabs/patient-chart-tab/etreatment-form/etreatment-form.component';
import { AssignClinicFormComponent } from '@app/patients/patient-tabs/patients-documents-tab/assign-clinic-form/assign-clinic-form.component';
import { BillingCodeModalComponent } from '@app/shared/billing-code-modal/billing-code-modal.component';
import { DiagnosticCodeModalComponent } from '@app/shared/diagnostic-code-modal/diagnostic-code-modal.component';
import { ClinicProduct, RecommendedProduct } from '@models/clinic-product';
import { ColourVariables } from '@models/constants/colour-variables';
import { eTreatmentForm } from '@models/etreatment-forms/etreatment-form';
import { ServiceeTreatmentForm } from '@models/etreatment-forms/service-etreatment-form';
import { TaxIndicator, TaxIndicatorTitle } from '@models/finance/tax-indicator.enum';
import { Form } from '@models/forms/form';
import { ServiceForm } from '@models/forms/service-form';
import { Resource } from '@models/resource';
import { ResourceType } from '@models/resource-type';
import { ServiceProvider } from '@models/service-provider';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { ClinicServiceTemplateUser } from '@models/service/clinic-service-template-user';
import { ServiceBillingCode } from '@models/service/service-billing-code';
import { ServiceCategory, ServiceTemplateIcon } from '@models/service/service-category';
import { ServiceDetailTemplate, ServiceDetailTemplateDetail } from '@models/service/service-detail-template';
import { ServiceTemplateResource } from '@models/service/service-template-resource';
import { ServiceTemplateType } from '@models/service/service-template-type';
import { Tax } from '@models/tax';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridDataResult, RemoveEvent } from '@progress/kendo-angular-grid';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { ClinicsService } from '@services/clinics.service';
import { FormService } from '@services/form.service';
import { FormatterService } from '@services/formatter.service';
import { AgedBillingGroup, MinistryService } from '@services/ministry.service';
import { ProductDefaultsService } from '@services/product-defaults.service';
import { ResourcesService } from '@services/resources.service';
import { ServiceDefaultsService } from '@services/service-defaults.service';
import { ServiceeTreatmentFormService } from '@services/service-etreatment-form.service';
import { ServiceFormService } from '@services/service-form.service';
import { ServiceProviderService } from '@services/service-provider.service';
import { ServiceTemplateTypeService } from '@services/service-template-type.service';
import { ServiceTemplatesService } from '@services/service-templates.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { Observable, Subject, from } from 'rxjs';
import 'rxjs/add/operator/concatMap';
import { first, map, startWith, takeUntil } from 'rxjs/operators';
import { AssigneTreatmentFormComponent } from '../../catalogue-services/assign-etreatment-form/assign-etreatment-form.component';
import { MatFormField } from '@angular/material/form-field';
import { read } from 'fs';

@Component({
  selector: 'app-edit-service',
  templateUrl: './edit-service.component.html',
  styleUrls: ['./edit-service.component.less'],
})
export class EditServiceComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('recommendedProducts', { static: true })
  recommendedProductsAutoComplete: MatAutocompleteTrigger;
  @ViewChild('scrollbar') scrollbar: NgScrollbar;
  @ViewChildren(MatFormField, { read: ElementRef }) formFieldRefs: QueryList<ElementRef>;

  view: Observable<ClinicServiceTemplate>;
  editServicePanelVisible = false;
  addProductFilter: string = '';

  ageSpecificBillingCodes: AgedBillingGroup[] = null;

  unsub: Subject<void> = new Subject<void>();
  categories: ServiceCategory[] = [];
  taxes: Tax[] = [];
  selectedRecommendedProducts: RecommendedProduct[] = [];
  serviceDetailTemplates: ServiceDetailTemplateDetail[] = [];
  equipmentResources: Resource[] = [];
  roomResources: Resource[] = [];
  serviceTemplateTypes: ServiceTemplateType[] = [];

  serviceProviders: ServiceProvider[];
  serviceProvidersSelected: ServiceProvider[] = [];

  isNew = true;
  viewInitialized = false;

  public templateIconsDropDown: Array<ServiceTemplateIcon> = [
    { serviceTemplateIconId: 0, icon: 'fas fa-syringe' },
    { serviceTemplateIconId: 0, icon: 'fas fa-child' },
    { serviceTemplateIconId: 0, icon: 'fas fa-user-md' },
    { serviceTemplateIconId: 0, icon: 'fas fa-user-md-chat' },
    { serviceTemplateIconId: 0, icon: 'fas fa-users-medical' },
    { serviceTemplateIconId: 0, icon: 'fas fa-users' },
    { serviceTemplateIconId: 0, icon: 'fas fa-user-clock' },
    { serviceTemplateIconId: 0, icon: 'fas fa-star-of-life' },
    { serviceTemplateIconId: 0, icon: 'fas fa-raindrops' },
    { serviceTemplateIconId: 0, icon: 'fas fa-snowflake' },
    { serviceTemplateIconId: 0, icon: 'fas fa-tint' },
    { serviceTemplateIconId: 0, icon: 'fas fa-hand-holding-water' },
    { serviceTemplateIconId: 0, icon: 'fas fa-lips' },
    { serviceTemplateIconId: 0, icon: 'fas fa-stethoscope' },
    { serviceTemplateIconId: 0, icon: 'fas fa-scalpel-path' },
    { serviceTemplateIconId: 0, icon: 'fas fa-scalpel' },
    { serviceTemplateIconId: 0, icon: 'fas fa-notes-medical' },
    { serviceTemplateIconId: 0, icon: 'fas fa-magnet' },
    { serviceTemplateIconId: 0, icon: 'fas fa-wave-sine' },
    { serviceTemplateIconId: 0, icon: 'fas fa-waveform-path' },
    { serviceTemplateIconId: 0, icon: 'fas fa-waveform' },
    { serviceTemplateIconId: 0, icon: 'fas fa-signal-stream' },
    { serviceTemplateIconId: 0, icon: 'fas fa-bolt' },
  ];

  allAvailableProducts: ClinicProduct[] = [];
  recommendedproductsunsub: any;
  recentRecommendedProducts: ClinicProduct[];
  filteredRecommendedProducts: Observable<ClinicProduct[]>;
  recommendedProductToAdd: ClinicProduct = null;
  serviceTemplate: ClinicServiceTemplate = null;
  CoolsculptingDetailTemplateId = ServiceDetailTemplate.Coolsculpting;
  InjectableDetailTemplateId = ServiceDetailTemplate.Injections;
  serviceColours = new ColourVariables().textMappings;
  colorMappings = new ColourVariables().colourMappings;
  colourVariables = new ColourVariables();
  form: FormGroup;
  loading = false;

  availableForms: Form[] = [];
  originalServiceForms: ServiceForm[] = [];
  assignedServiceForms: ServiceForm[] = [];
  updatedServiceForms: ServiceForm[] = [];

  availableeTreatmentForms: eTreatmentForm[] = [];
  originalServiceeTreatmentForms: ServiceeTreatmentForm[] = [];
  assignedServiceeTreatmentForms: ServiceeTreatmentForm[] = [];
  updatedServiceeTreatmentForms: ServiceeTreatmentForm[] = [];
  taxIndicators: { title: any; value: any }[];

  get formGridData(): GridDataResult {
    return { data: this.assignedServiceForms, total: this.availableForms.length };
  }

  get eTreatmentFormGridData(): GridDataResult {
    return { data: this.assignedServiceeTreatmentForms, total: this.availableeTreatmentForms.length };
  }
  constructor(
    private serviceTemplatesService: ServiceTemplatesService,
    private serviceDefaultsService: ServiceDefaultsService,
    private productDefaultsService: ProductDefaultsService,
    private catalogueUpdatesService: CatalogueUpdatesService,
    private ministryService: MinistryService,
    private clinicService: ClinicsService,
    public formatterService: FormatterService,
    public resourcesService: ResourcesService,
    private serviceTemplateTypesService: ServiceTemplateTypeService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private serviceProviderService: ServiceProviderService,
    private formService: FormService,
    private serviceFormService: ServiceFormService,
    private modalService: NgbModal,
    private serviceeTreatmentFormService: ServiceeTreatmentFormService
  ) { }

  ngOnInit() {
    this.initForm();
    this.serviceTemplate = { ...this.serviceDefaultsService.defaultServiceTemplate };
    this.recommendedProductToAdd = this.productDefaultsService.defaultProduct;
    this.ageSpecificBillingCodes = this.ministryService.AgeSpecificBillingCodes;

    this.taxIndicators = Object.keys(TaxIndicatorTitle).map((e) => ({
      title: TaxIndicatorTitle[e],
      value: TaxIndicator[e],
    }));

    this.getClinicTaxes();
    this.loadResources();
    this.getServiceTemplateTypesList();
    this.serviceProviderService
      .getServiceProvidersOnlineBooking()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        this.serviceProviders = result;
        this.loading = false;
      });

    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      let id = parseInt(params['servid']);
      if (Number.isInteger(id) && id > 0) {
        this.serviceTemplatesService
          .getServiceTemplateForEdit(id)
          .pipe(takeUntil(this.unsub))
          .subscribe(
            (dto) => {
              if (dto.clinicServiceTemplate) {
                if (dto.clinicServiceTemplate.serviceTemplateBillingCodes.length === 0) {
                  dto.clinicServiceTemplate.serviceTemplateBillingCodes.push(this.initEmptyServiceBillingCode());
                }
                this.serviceTemplate = dto.clinicServiceTemplate;
                if (this.serviceTemplate.clinicServiceTemplateUsers) {
                  this.serviceTemplate.clinicServiceTemplateUsers.forEach((cstu) => {
                    this.serviceProvidersSelected.push(
                      new ServiceProvider({
                        id: cstu.userId,
                        title: cstu.user.fullName,
                      })
                    );
                  });
                }
                this.categories = dto.serviceCategories;
                this.allAvailableProducts = dto.clinicProducts;
                this.isNew = false;
                this.setForm();
              } else {
                // TODO: decide what to do if there is no more the package in the database
              }
            },
            () => {
              // TODO: decide what to do with err
            }
          );

        this.serviceFormService
          .getServiceForms(id)
          .pipe(takeUntil(this.unsub))
          .subscribe((serviceForms) => {
            this.assignedServiceForms = serviceForms;
            this.originalServiceForms = [...serviceForms];
          });

        this.serviceeTreatmentFormService
          .getServiceeTreatmentForms(id)
          .pipe(takeUntil(this.unsub))
          .subscribe((serviceeTreatmentForms) => {
            this.assignedServiceeTreatmentForms = serviceeTreatmentForms;
            this.originalServiceeTreatmentForms = [...serviceeTreatmentForms];
          });
      } else {
        this.serviceTemplatesService
          .getListsForNewServiceTemplate()
          .pipe(takeUntil(this.unsub))
          .subscribe(
            (dto) => {
              this.categories = dto.serviceCategories;
              this.allAvailableProducts = dto.clinicProducts;
              this.addBillingCode();
            },
            () => {
              // TODO: decide what to do with err
            }
          );
      }
    });

    this.filteredRecommendedProducts = this.form.controls['selectedRecommendedProduct'].valueChanges.pipe(
      startWith(''),
      map((val) => this.recommendedProductFilter(val))
    );

    this.serviceTemplatesService
      .getServiceDetailTemplates()
      .pipe(takeUntil(this.unsub))
      .subscribe((templates: ServiceDetailTemplateDetail[]) => {
        this.serviceDetailTemplates = templates;
      });

    this.form.controls['serviceTemplateTypeId'].valueChanges
      .concatMap((value) => this.formService.getFormsByServiceTemplateType(value))
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => (this.availableForms = result));
  }

  ngAfterViewInit(): void {
    this.viewInitialized = true
  }

  loadResources() {
    this.resourcesService
      .getResources()
      .pipe(takeUntil(this.unsub))
      .subscribe((res) => {
        const sortedRoomsAndResources = this.resourcesService.sortRoomsAndResources(res);
        this.roomResources = sortedRoomsAndResources[0];
        this.equipmentResources = sortedRoomsAndResources[1];
      });
  }

  getServiceTemplateTypesList() {
    this.serviceTemplateTypes = [];
    this.serviceTemplateTypesService
      .getAllServiceTemplateTypes()
      .pipe(takeUntil(this.unsub))
      .subscribe((res) => {
        res.forEach((type) => {
          const typeData = type;
          const pushItem: ServiceTemplateType = {
            id: typeData.id,
            name: typeData.name,
          };
          this.serviceTemplateTypes.push(pushItem);
        });
      });
  }

  private recommendedProductFilter(val: ClinicProduct | string): ClinicProduct[] {
    if (!val) return [];
    if (typeof val === 'string') {
      return this.allAvailableProducts.filter((option) => option.displayName.toLowerCase().includes(val.toLowerCase()));
    } else {
      return this.allAvailableProducts.filter((option) =>
        option.displayName.toLowerCase().includes(val.displayName.toLowerCase())
      );
    }
  }

  recommendedProductDisplayFn(user?: ClinicProduct): string | undefined {
    return user ? user.displayName : undefined;
  }

  /**
   * Event handler for Add Product button on recommended products.
   * Adds recommended product user enters to service and selection lists:
   */
  addRecommendedProduct(): void {
    let productToAdd: ClinicProduct = null;
    // If product is selected from the auto-complete menu, it will be a product object.
    // If it is manually typed in, it will be a string.
    if (typeof this.form.controls['selectedRecommendedProduct'].value === 'object') {
      productToAdd = this.form.controls['selectedRecommendedProduct'].value;
    } else {
      // For strings, get the full object:
      productToAdd = this.findSelectedProductByName(this.form.controls['selectedRecommendedProduct'].value);
    }
    if (productToAdd) {
      this.selectedRecommendedProducts.push({
        clinicProductId: productToAdd.id,
        clinicProduct: productToAdd,
        productQuantity: 1,
        serviceTemplateId: this.serviceTemplate.id,
        service: null,
        isAddedToInvoice: true,
      });
      this.form.controls['selectedRecommendedProduct'].reset();
      this.form.markAsDirty();
    }
  }

  /**
   * Finds and returns the Product object from this.allAvailableProducts
   * that matches the product name passed in.
   *
   * @param name Name of the product to look for
   *
   * @returns The product whose name matches the name passed in or, if not found, null.
   */
  findSelectedProductByName(name: string): ClinicProduct {
    const matchingProducts = this.allAvailableProducts.filter(
      (prod) => prod.displayName.toLowerCase() === name.trim().toLowerCase()
    );
    return matchingProducts.length ? matchingProducts[0] : null;
  }

  /**
   * Click handler for the remove recommended products button.
   * Removes the recommended product from the selected recommended products array
   * @param productToRemove The product to be removed
   */
  removeRecommendedProduct(productToRemove: ClinicProduct): void {
    // Looks for first product with productId.
    // Won't necessarily be the one user clicked on but they're all the same anyway.
    this.selectedRecommendedProducts.splice(
      this.selectedRecommendedProducts.indexOf(
        this.selectedRecommendedProducts.find((rp) => rp.clinicProductId === productToRemove.id)
      ),
      1
    );
    this.form.markAsDirty();
  }

  addFormHandler() {
    const assignModal = this.modalService.open(AssignClinicFormComponent, {
      centered: true,
    });
    (assignModal.componentInstance as AssignClinicFormComponent).assignedServiceForms = this.assignedServiceForms
    from(assignModal.result).subscribe((form: Form) => {
      if (form) {
        this.assignedServiceForms.push(
          new ServiceForm({ clinicServiceTemplateId: this.serviceTemplate.id, clinicFormId: form.id, clinicForm: form })
        );
        this.form.markAsDirty();
      }
    });
  }

  removeFormHandler(args: RemoveEvent) {
    this.assignedServiceForms.splice(args.rowIndex, 1);
    this.form.markAsDirty();
  }

  updateIsSelectedByDefault(serviceForm: ServiceForm, event) {
    serviceForm.isSelectedByDefault = event.target.checked;
    const existingIndex = this.updatedServiceForms.findIndex((s) => s.id === serviceForm.id);
    if (existingIndex < 0) this.updatedServiceForms.push(serviceForm);
    this.form.markAsDirty();
  }

  addeTreatmentFormHandler() {
    const dialogRef = this.dialog.open(AssigneTreatmentFormComponent, {
      panelClass: 'custom-dialog-container',
      width: '1250px',
    });

    const instance = dialogRef.componentInstance;
    instance.alleTreatmentForms = this.availableeTreatmentForms;

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((eTreatmentForm) => {
        if (eTreatmentForm) {
          this.assignedServiceeTreatmentForms.push(
            new ServiceeTreatmentForm({
              clinicServiceTemplateId: this.serviceTemplate.id,
              eTreatmentFormId: eTreatmentForm.id,
              eTreatmentForm: eTreatmentForm,
            })
          );
          this.form.markAsDirty();
        }
      });
  }

  removeeTreatmentFormHandler(args: RemoveEvent) {
    this.assignedServiceeTreatmentForms.splice(args.rowIndex, 1);
    this.form.markAsDirty();
  }

  updateeTreatmentFormIsSelectedByDefault(serviceeTreatmentForm: ServiceeTreatmentForm, event) {
    serviceeTreatmentForm.isSelectedByDefault = event.target.checked;
    const existingIndex = this.updatedServiceeTreatmentForms.findIndex((s) => s.id === serviceeTreatmentForm.id);
    if (existingIndex < 0) this.updatedServiceeTreatmentForms.push(serviceeTreatmentForm);
    this.form.markAsDirty();
  }

  openeTreatmentFormViewer(eTreatmentForm: eTreatmentForm) {
    let dialogRef = this.dialog.open(eTreatmentFormComponent, {
      panelClass: 'document-view-modal',
      disableClose: false,
      data: {
        appointmenteTreatmentForm: eTreatmentForm,
        editable: false,
      },
    });
    dialogRef
      .afterOpened()
      .pipe(first())
      .subscribe(() => { });
  }

  getClinicTaxes() {
    this.clinicService
      .getClinicById(localStorage.getItem('clinicId'))
      .pipe(takeUntil(this.unsub))
      .subscribe((c) => {
        c.clinicTaxes.forEach((ct) => this.taxes.push(ct.tax));
      });
  }

  getAgeSpecificBillingCodes() {
    if (this.ageSpecificBillingCodes) {
      return []
        .concat(
          ...this.ageSpecificBillingCodes
            .filter((a) => a.billingGroupId == this.form.controls['billingGroupId'].value)
            .map((a) => a.billingCodes)
        )
        .map((a) => a.code)
        .join(', ');
    } else return [];
  }

  onFormSubmit() {
    const valid = this.checkValidation();
    if (!valid) return;
    this.updateServiceTemplate();
    if (this.isNew) {
      this.serviceTemplatesService
        .addServiceTemplate(this.serviceTemplate)
        .pipe(takeUntil(this.unsub))
        .subscribe((st) => {
          this.catalogueUpdatesService.refreshRequired = true;
          this.catalogueUpdatesService.catalogueUpdateComplete();
          this.router.navigate(['/management/catalogue/services', { outlets: { 'action-panel': null } }]);
        });
    } else {
      this.serviceTemplatesService
        .updateServiceTemplate(this.serviceTemplate)
        .pipe(takeUntil(this.unsub))
        .subscribe(() => {
          this.catalogueUpdatesService.refreshRequired = true;
          this.catalogueUpdatesService.catalogueUpdateComplete();
          this.router.navigate(['/management/catalogue/services', { outlets: { 'action-panel': null } }]);
        });
    }
  }

  private checkValidation(): boolean {
    if (this.form.valid) return true;
    let firstInvalidElement: HTMLElement = null;
    for (const field of this.formFieldRefs) {
      const controlElement = field.nativeElement.querySelector('[formcontrolname]') as HTMLElement;
      const formControlName = controlElement.getAttribute('formcontrolname');
      const control = this.form.get(formControlName);
      if (control.invalid) {
        if (!firstInvalidElement) firstInvalidElement = controlElement;
        control.markAsTouched({ onlySelf: true });
      }
    }
    let rowElement = firstInvalidElement?.closest('div.row') as HTMLElement;
    if (rowElement) {
      this.scrollbar.scrollToElement(rowElement);
    }
    return false;
  }

  updateServiceTemplate() {
    this.serviceTemplate.serviceName = this.form.controls['serviceName'].value;
    this.serviceTemplate.serviceAltName = this.form.controls['serviceAltName'].value;
    this.serviceTemplate.serviceCategoryId = this.form.controls['serviceCategory'].value;
    this.serviceTemplate.serviceIDColour = this.form.controls['serviceIDColour'].value;
    this.serviceTemplate.templateIcon = this.form.controls['serviceIcon'].value;
    this.serviceTemplate.defaultDurationMinutes = this.form.controls['defaultDuration'].value;
    this.serviceTemplate.serviceTemplateTypeId = this.form.controls['serviceTemplateTypeId'].value;
    this.serviceTemplate.serviceDetailTemplateId = this.form.controls['serviceDetailTemplateId'].value;
    this.serviceTemplate.isTreatmentPlanMultiple = this.form.controls['isTreatmentPlanMultiple'].value;
    this.serviceTemplate.requireCardOnFile = this.form.controls['requireCardOnFile'].value;

    this.serviceTemplate.resourcesString = '';
    this.serviceTemplate.serviceTemplateResources = [];
    if (this.form.controls['equipmentResourcesSelected'].value != null) {
      this.form.controls['equipmentResourcesSelected'].value.forEach((er) => {
        this.serviceTemplate.serviceTemplateResources.push(
          new ServiceTemplateResource({
            serviceTemplateId: this.serviceTemplate.id,
            resourceId: er.resourceId,
          })
        );
        this.serviceTemplate.resourcesString =
          this.serviceTemplate.resourcesString + er.resourceType + ': ' + er.name + ',';
      });
    }

    if (this.form.controls['roomResourcesSelected'].value != null) {
      this.form.controls['roomResourcesSelected'].value.forEach((rr) => {
        this.serviceTemplate.serviceTemplateResources.push(
          new ServiceTemplateResource({
            serviceTemplateId: this.serviceTemplate.id,
            resourceId: rr.resourceId,
          })
        );
        this.serviceTemplate.resourcesString =
          this.serviceTemplate.resourcesString + rr.resourceType + ': ' + rr.name + ',';
      });
    }
    this.serviceTemplate.governmentBilling = this.form.controls['isGovtBilling'].value;
    this.serviceTemplate.diagnosticCode = this.form.controls['diagnosticCode'].value;
    this.serviceTemplate.billingGroupId = this.form.controls['billingGroupId'].value;

    this.serviceTemplate.serviceTemplateBillingCodes = [];
    this.serviceTemplate.serviceTemplateBillingCodes = this.validateServiceTemplateBillingCodes(
      this.form.controls['billingCodes'].value
    );

    this.serviceTemplate.taxIndicator = this.form.controls['taxIndicator'].value;
    this.serviceTemplate.defaultPrice = this.form.controls['retailPrice'].value;

    this.serviceTemplate.serviceTaxes = [];
    if (this.form.controls['selectedTaxes'].value != null) {
      this.form.controls['selectedTaxes'].value.forEach((st) =>
        this.serviceTemplate.serviceTaxes.push({
          serviceId: this.serviceTemplate.id,
          service: null,
          taxId: st.taxId,
          tax: null,
        })
      );
    }
    this.serviceTemplate.dollarsToLoyaltyPointRate = this.form.controls['dollarsToLoyaltyPointRate'].value;

    this.serviceTemplate.serviceRecProductsString = '';
    this.serviceTemplate.recommendedProducts = [];
    this.selectedRecommendedProducts.forEach((rp) => {
      var currRecProd = this.serviceTemplate.recommendedProducts.find(
        (temp) => temp.clinicProductId == rp.clinicProductId
      );
      if (currRecProd != undefined) {
        currRecProd.productQuantity++;
      } else {
        this.serviceTemplate.recommendedProducts.push(rp);
      }
      this.serviceTemplate.serviceRecProductsString =
        this.serviceTemplate.serviceRecProductsString +
        rp.clinicProduct.displayName +
        '(' +
        rp.productQuantity +
        ')-$' +
        rp.clinicProduct.retailPrice;
      this.serviceTemplate.serviceRecProductsString = this.serviceTemplate.serviceRecProductsString + ',';
    });

    this.serviceTemplate.signedTime = new Date();

    this.serviceTemplate.clinicServiceTemplateUsers = [];
    if (this.form.controls['selectedServiceProviders'].value != null) {
      this.form.controls['selectedServiceProviders'].value.forEach((serviceProvider: ServiceProvider) => {
        this.serviceTemplate.clinicServiceTemplateUsers.push(
          new ClinicServiceTemplateUser(this.serviceTemplate.id, serviceProvider.id)
        );
      });
    }

    this.serviceTemplate.serviceForms = this.assignedServiceForms;
    this.serviceTemplate.clinicServiceTemplateeTreatmentForms = this.assignedServiceeTreatmentForms;
  }

  cancelUpdate() {
    this.catalogueUpdatesService.refreshRequired = false;
    this.catalogueUpdatesService.catalogueUpdateComplete();
    this.router.navigate(['/management/catalogue/services', { outlets: { 'action-panel': null } }]);
  }

  onCompareServiceTemplateType(a: ServiceTemplateType, b: ServiceTemplateType): boolean {
    if (a === null || a === undefined) {
      return false;
    }
    if (b === null || b === undefined) {
      return false;
    }
    return a.id === b.id;
  }

  onCompareResource(a: Resource, b: Resource): boolean {
    if (a === null || a === undefined) {
      return false;
    }
    if (b === null || b === undefined) {
      return false;
    }
    return a.resourceId === b.resourceId;
  }

  compareServiceProviders(s1: ServiceProvider, s2: ServiceProvider): boolean {
    if (s1 === null || s1 === undefined) {
      return false;
    }
    if (s2 === null || s2 === undefined) {
      return false;
    }
    return s1.id === s2.id;
  }

  getOptionStyles(color: any) {
    return {
      'background-color': this.colorMappings.get(color.key),
      'border-left': '10px solid ' + color.key,
      color: this.colourVariables.black_custom,
    };
  }

  getSelectedOptionStyles() {
    const value = {
      'background-color': this.colorMappings.get(this.form.controls['serviceIDColour'].value),
      'border-left': '10px solid ' + this.form.controls['serviceIDColour'].value,
      color: this.colourVariables.black_custom,
      padding: '5px 0 5px 5px',
    };

    return value;
  }

  compareItems(o1: string, o2: string) {
    return o1 && o2 && o1 === o2;
  }

  compareObjects(o1: number, o2: number): boolean {
    return o1 && o2 && o1 === o2;
  }

  public openDiagnosticSearch() {
    const dialogRef = this.dialog.open(DiagnosticCodeModalComponent, {
      panelClass: 'custom-dialog-container',
      width: '420px',
    });

    const instance = dialogRef.componentInstance;
    instance.initialSearchTerm = this.form.controls['diagnosticCode'].value;

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((res) => {
        if (res) {
          this.form.controls['diagnosticCode'].setValue(res.code);
          this.form.markAsDirty();
        }
      });
  }

  public openBillingSearch(index: number) {
    const dialogRef = this.dialog.open(BillingCodeModalComponent, {
      panelClass: 'custom-dialog-container',
      width: '420px',
    });
    const instance = dialogRef.componentInstance;
    instance.initialSearchTerm = this.billingCodes.controls[index].value.billingCode;

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((res) => {
        if (res) {
          ((this.form.controls['billingCodes'] as FormArray).controls[index] as FormGroup).controls[
            'billingCode'
          ].setValue(res.code);

          ((this.form.controls['billingCodes'] as FormArray).controls[index] as FormGroup).controls[
            'serviceUnits'
          ].setValue(
            this.billingCodes.controls[index].value.serviceUnits !== null &&
              this.billingCodes.controls[index].value.serviceUnits !== undefined
              ? this.billingCodes.controls[index].value.serviceUnits
              : 1
          );
          this.form.markAsDirty();
        }
      });
  }

  initEmptyServiceBillingCode(): ServiceBillingCode {
    return {
      billingCode: '',
      serviceUnits: null,
      id: 0,
      amount: null,
      isPaid: false,
    };
  }

  removeBillingCode(index: number) {
    (this.form.controls['billingCodes'] as FormArray).removeAt(index);
    this.form.markAsDirty();
    if ((this.form.controls['billingCodes'] as FormArray).length === 0) {
      this.addBillingCode();
    }
  }

  addBillingCode() {
    (this.form.controls['billingCodes'] as FormArray).push(
      new FormGroup({
        billingCode: new FormControl(),
        serviceUnits: new FormControl(),
      })
    );
  }

  validateServiceTemplateBillingCodes(billingCodes: ServiceBillingCode[]) {
    return billingCodes.filter(
      (item) => item.serviceUnits && item.billingCode
    );
  }

  compareClinicTaxObjects(o1: any, o2: any): boolean {
    return o1 && o2 && o1.name === o2.name && o1.taxId === o2.taxId;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  initForm() {
    this.form = this.fb.group({
      serviceName: new FormControl('', [Validators.required]),
      serviceAltName: new FormControl('', [Validators.required]),
      serviceCategory: new FormControl(),
      serviceIDColour: new FormControl(),
      serviceIcon: new FormControl(this.templateIconsDropDown[0].icon),
      defaultDuration: new FormControl('', [Validators.required, Validators.min(1)]),
      serviceTemplateTypeId: new FormControl(null, [Validators.required]),
      serviceDetailTemplateId: new FormControl(null, [Validators.required]),
      isTreatmentPlanMultiple: new FormControl(false),
      equipmentResourcesSelected: new FormControl(),
      roomResourcesSelected: new FormControl(),
      isGovtBilling: new FormControl(false),
      diagnosticCode: new FormControl(''),
      billingGroupId: new FormControl(),
      billingCodes: this.fb.array([]),
      taxIndicator: new FormControl(null, [Validators.required]),
      retailPrice: new FormControl(0),
      selectedTaxes: new FormControl(null, [Validators.required]),
      requireCardOnFile: new FormControl(false),
      dollarsToLoyaltyPointRate: new FormControl(),
      selectedRecommendedProduct: new FormControl(),
      selectedServiceProviders: new FormControl(),
    });

    this.form.get('selectedTaxes').disable();
    this.form.get('taxIndicator').valueChanges.pipe(takeUntil(this.unsub)).subscribe((value) => {
      if (value === TaxIndicator.TaxIncluded) {
        this.form.get('selectedTaxes').enable();
      } else {
        this.form.get('selectedTaxes').disable();
      }
    })
  }

  setForm() {
    var serviceTax: Tax[] = [];
    var equipment: Resource[] = [];
    var rooms: Resource[] = [];
    this.form.controls['serviceName'].setValue(this.serviceTemplate.serviceName);
    this.form.controls['serviceAltName'].setValue(this.serviceTemplate.serviceAltName);
    this.form.controls['serviceCategory'].setValue(this.serviceTemplate.serviceCategoryId);
    this.form.controls['serviceIDColour'].setValue(this.serviceTemplate.serviceIDColour);
    this.form.controls['serviceIcon'].setValue(this.serviceTemplate.templateIcon);
    this.form.controls['defaultDuration'].setValue(this.serviceTemplate.defaultDurationMinutes);
    this.form.controls['serviceTemplateTypeId'].setValue(this.serviceTemplate.serviceTemplateTypeId);
    this.form.controls['serviceDetailTemplateId'].setValue(this.serviceTemplate.serviceDetailTemplateId);
    this.form.controls['isTreatmentPlanMultiple'].setValue(this.serviceTemplate.isTreatmentPlanMultiple ? true : false);
    this.serviceTemplate.serviceTemplateResources.forEach((str: ServiceTemplateResource) => {
      if (str.resource.resourceType === ResourceType.Equipment) equipment.push(str.resource);
      if (str.resource.resourceType === ResourceType.Room) rooms.push(str.resource);
    });
    this.form.controls['equipmentResourcesSelected'].setValue(equipment);
    this.form.controls['roomResourcesSelected'].setValue(rooms);
    this.form.controls['isGovtBilling'].setValue(this.serviceTemplate.governmentBilling ? true : false);
    this.form.controls['diagnosticCode'].setValue(this.serviceTemplate.diagnosticCode);
    this.form.controls['billingGroupId'].setValue(this.serviceTemplate.billingGroupId);

    this.serviceTemplate.serviceTemplateBillingCodes.forEach((bc) =>
      (this.form.controls['billingCodes'] as FormArray).push(
        new FormGroup({
          billingCode: new FormControl(bc.billingCode),
          serviceUnits: new FormControl(bc.serviceUnits),
        })
      )
    );

    this.form.controls['retailPrice'].setValue(
      this.serviceTemplate.defaultPrice != undefined ? this.serviceTemplate.defaultPrice : 0
    );
    this.form.controls['taxIndicator'].setValue(this.serviceTemplate.taxIndicator);
    this.serviceTemplate.serviceTaxes.forEach((st) => serviceTax.push(st.tax));
    this.form.controls['selectedTaxes'].setValue(serviceTax);
    this.form.controls['requireCardOnFile'].setValue(this.serviceTemplate.requireCardOnFile);
    this.form.controls['dollarsToLoyaltyPointRate'].setValue(this.serviceTemplate.dollarsToLoyaltyPointRate);
    // Make copies of the recommended products
    // for displaying as a list in the UI.
    // Each copy will have productQuantity = 1 because we are not grouping them.
    // Even identical products are to be listed separately.
    this.serviceTemplate.recommendedProducts.forEach((rp) => {
      for (let count = 1; count <= rp.productQuantity; count++) {
        const rpCopy = { ...rp };
        rpCopy.productQuantity = 1;
        this.selectedRecommendedProducts.push(rpCopy);
      }
    });
    this.form.controls['selectedServiceProviders'].setValue(this.serviceProvidersSelected);
  }

  get billingCodes() {
    return this.form.controls['billingCodes'] as FormArray;
  }

  updateAddToInvoice(recommendedProduct: RecommendedProduct) {
    let selectedRecommendedProduct = this.selectedRecommendedProducts.find((rp) => rp === recommendedProduct);
    if (selectedRecommendedProduct)
      selectedRecommendedProduct.isAddedToInvoice = !selectedRecommendedProduct.isAddedToInvoice;
    this.form.markAsDirty();
  }
}
