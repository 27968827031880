import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { eTreatmentForm } from '@models/etreatment-forms/etreatment-form';
import { eTreatmentFormCategory } from '@models/etreatment-forms/etreatment-form-category';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class eTreatmentFormService {
  //formsLoaded = new Subject<Form[]>();
  //formSelected = new Subject<Form>();
  eTreatmentFormUpdated = new Subject<eTreatmentForm>();
  eTreatmentFormAdded = new Subject<eTreatmentForm>();
  eTreatmentFormDeactivated = new Subject<eTreatmentForm>();
  eTreatmentFormActivated = new Subject<eTreatmentForm>();
  eTreatmentFormChangeCancelled = new Subject<eTreatmentForm>();
  eTreatmentFormDeleted = new Subject<eTreatmentForm>();

  defaultId = 0;

  constructor(private http: HttpClient) {}

  geteTreatmentForms(eTreatmentFormCategory: any | null) {
    if (eTreatmentFormCategory) return this.geteTreatmentFormsByCategory(eTreatmentFormCategory);
    else return this.http.get<eTreatmentForm[]>(environment.baseUrl + 'api/eTreatmentForms');
  }

  geteTreatmentFormsByCategory(eTreatmentFormCategory: eTreatmentFormCategory): Observable<eTreatmentForm[]> {
    return this.http.get<eTreatmentForm[]>(
      environment.baseUrl + 'api/eTreatmentForms/ByeTreatmentFormCategory/' + eTreatmentFormCategory.id
    );
  }

  geteTreatmentFormById(eTreatmentFormId): Observable<eTreatmentForm> {
    return this.http.get<eTreatmentForm>(environment.baseUrl + 'api/eTreatmentForms/' + eTreatmentFormId);
  }

  addeTreatmentForm(eTreatmentform: eTreatmentForm) {
    return this.http.post<eTreatmentForm>(environment.baseUrl + 'api/eTreatmentForms', eTreatmentform).pipe(
      map((addedeTreatmentForm) => {
        this.eTreatmentFormAdded.next(addedeTreatmentForm);
        return addedeTreatmentForm;
      })
    );
  }

  updateeTreatmentForm(eTreatmentForm: eTreatmentForm) {
    return this.http.put<eTreatmentForm>(environment.baseUrl + 'api/eTreatmentForms', eTreatmentForm).pipe(
      map((updatedeTreatmentForm) => {
        this.eTreatmentFormUpdated.next(updatedeTreatmentForm);
        return updatedeTreatmentForm;
      })
    );
  }

  deactivateeTreatmentForm(eTreatmentform: eTreatmentForm) {
    return this.http
      .put(environment.baseUrl + 'api/eTreatmentForms/SeteTreatmentFormActiveStatus/' + eTreatmentform.id + '/' + 0, {})
      .pipe(
        map(() => {
          this.eTreatmentFormDeactivated.next();
          return;
        })
      );
  }

  deleteeTreatmentForm(eTreatmentform: eTreatmentForm) {
    return this.http.delete(environment.baseUrl + 'api/eTreatmentForms/' + eTreatmentform.id).pipe(
      map(() => {
        this.eTreatmentFormDeleted.next();
        return;
      })
    );
  }

  activateeTreatmentForm(eTreatmentform: eTreatmentForm) {
    return this.http
      .put(environment.baseUrl + 'api/eTreatmentForms/SeteTreatmentFormActiveStatus/' + eTreatmentform.id + '/' + 1, {})
      .pipe(
        map(() => {
          this.eTreatmentFormActivated.next();
          return;
        })
      );
  }

  cloneeTreatmentForm(eTreatmentFormId: number) {
    return this.http
      .post<eTreatmentForm>(environment.baseUrl + 'api/eTreatmentForms/CloneeTreatmentForm/' + eTreatmentFormId, {})
      .pipe(
        map((clonedeTreatmentForm) => {
          this.eTreatmentFormAdded.next(clonedeTreatmentForm);
          return clonedeTreatmentForm;
        })
      );
  }
}
