<ngx-loading [show]="loading"></ngx-loading>
<div [ngStyle]="{ height: 'calc(100dvh - 115px)' }">
  <kendo-grid
    [data]="gridView"
    [pageSize]="gridState.take"
    [skip]="gridState.skip"
    [sort]="gridState.sort"
    [sortable]="true"
    [selectable]="true"
    [style.height.%]="100"
    [pageable]="true"
    (dataStateChange)="dataStateChange($event)"
    (add)="onAddClick()"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <!--<div class="d-flex">
        <span class="pl-4 search-box border border-radius">
          <span class="fa fa-search"></span>
          <mat-form-field class="w-75 p-3">
            <input matInput placeholder="Search..." aria-label="Search Clinic Supply Item" [(ngModel)]="searchValue"
              [formControl]="searchCtrl" />
            <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear"
              (click)="searchValue = ''">
              <mat-icon><i class="fa fa-times-circle"></i></mat-icon>
            </button>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let csItem of filteredClinicSupplies | async" [value]="csItem.name">
                <span>{{ csItem.name }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </span>
        <button kendoGridAddCommand class="btn kendo-btn"><i class="fal fa-plus-circle"></i></button>
      </div>-->
      <input placeholder="Search in all columns..." kendoTextBox (input)="onFilter($any($event.target).value)" />
      <!--   <button
        kendoGridExcelCommand
        class="btn kendo-btn kendo-btn-green em-btn"
        icon="file-excel"
        style="float: right"
        type="button"
      >
        Export to Excel
      </button>
      <button kendoGridPDFCommand class="btn kendo-btn kendo-btn-green em-btn" icon="file-pdf" style="float: right">
        Export to PDF
      </button> -->
      <button kendoGridAddCommand class="btn em-btn"><i class="fal fa-plus-circle"></i> Add Clinic Supply</button>
    </ng-template>
    <kendo-grid-excel fileName="emily_clinic_supplies.xlsx"></kendo-grid-excel>
    <kendo-grid-pdf fileName="emily_clinic_supplies.pdf" [allPages]="true">
      <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
    </kendo-grid-pdf>
    <kendo-grid-column
      field="clinicSupplyType.observationListType.name"
      title="Supply Type"
      [width]="150"
    ></kendo-grid-column>
    <kendo-grid-column field="name" title="Supply Name" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="displayName" title="Display Name" [width]="150"></kendo-grid-column>

    <kendo-grid-column field="unitsOnHand" title="Units On Hand" [width]="75" filter="numeric"></kendo-grid-column>
    <kendo-grid-column
      field="pricePerUnit"
      title="Price"
      [width]="100"
      filter="numeric"
      format="{0:c}"
    ></kendo-grid-column>
    <kendo-grid-column
      field="costPerUnit"
      title="Cost"
      [width]="100"
      filter="numeric"
      format="{0:c}"
    ></kendo-grid-column>
    <kendo-grid-column field="clinicSupplyType.observationUnit.name" title="Unit" [width]="75"></kendo-grid-column>
    <kendo-grid-column field="sequenceNumber" title="Sort Order" [width]="75" filter="numeric"></kendo-grid-column>
    <!-- Enable Tagging -->
    <kendo-grid-column field="supplyTag" title="Enable Tagging" [width]="75" [filterable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div style="text-align: center; width: 100%">
          <label class="switch">
            <input
              type="checkbox"
              name="tag"
              aria-label="Enable Tagging"
              id="tag"
              [checked]="dataItem.supplyTag?.isActive"
              (change)="toggleSupplyTag(dataItem, $event)"
            />
            <span [ngStyle]="{ 'pointer-events': 'none' }" class="slider round"></span>
          </label>
        </div>
      </ng-template>
    </kendo-grid-column>
    <!-- Active -->
    <kendo-grid-column field="isActive" title="Active" [width]="75" [filterable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div style="text-align: center; width: 100%">
          <label class="switch">
            <input
              type="checkbox"
              name="isActive"
              aria-label="Active"
              id="isActive"
              [checked]="dataItem.isActive"
              (change)="updateIsActive(dataItem, $event)"
            />
            <span [ngStyle]="{ 'pointer-events': 'none' }" class="slider round"></span>
          </label>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="Actions" min-width="100px" [width]="100">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <button kendoGridEditCommand class="btn kendo-btn"><i class="fas fa-edit"></i></button>
        <!-- <button kendoGridRemoveCommand class="btn kendo-btn"><i class="fas fa-minus-circle"></i></button> -->
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
