import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { Patient } from '@models/patient';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { PhotoProcessingOperation } from '@models/photo/photo-processing';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PatientService } from '@services/patient.service';
import { PhotoSignalrService } from '@services/photo-signalr.service';
import { TagService } from '@services/tag.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AddPhotoComponent } from '../../patients/patient-tabs/patient-photos-tab/add-photo/add-photo.component';
import { PhotoFilter } from '../photo-filter';
import { ITagTypes } from './../../services/tag.service';
import { GenericPhotoService } from './../services/generic-photo.service';

@Component({
  selector: 'app-generic-photo-gallery',
  templateUrl: './generic-photo-gallery.component.html',
  styleUrls: ['./generic-photo-gallery.component.less'],
})
export class GenericPhotoGalleryComponent implements OnInit {
  filter: PhotoFilter;
  @Input() patient: Patient;
  tags: ITagTypes = {
    Service: [],
    BodyPart: [],
    PhotoType: [],
    Supply: [],
    AutoGenerated: [],
  };
  photos: PhotoMetaData[];
  selectedPhotosForSeriesCreation: PhotoMetaData[] = [];
  seriesMode = false; //for creating b/a or series images
  editModeEnabled = false;
  loading = false;
  private unsub: Subject<void> = new Subject<void>();
  cameraConnected: boolean = false;
  successBannerLabel: string = 'Camera connected.';
  processSelection: PhotoProcessingOperation[];
  applyWatermark: boolean;

  constructor(
    private photoService: GenericPhotoService,
    private tagService: TagService,
    private patientService: PatientService,
    private photoSignalrService: PhotoSignalrService,
    private modalService: NgbModal,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    var isIpad = navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
    this.filter = new PhotoFilter(isIpad, this.patient);

    this.filter.filterChanged$.pipe(takeUntil(this.unsub)).subscribe(async (filterUpdated) => {
      this.filter = filterUpdated;
      this.fetchPhotos();
    });
    this.photoService.photoDeleted$.pipe(takeUntil(this.unsub)).subscribe(async (deletedPhoto) => {
      this.fetchPhotos().add(() => {
        if (this.filter.selectedPhoto.id == deletedPhoto.id) this.filter.setSelectedPhoto(null, this.photos);
      });
    });
    this.photoService.photoSaved$.pipe(takeUntil(this.unsub)).subscribe(() => this.fetchPhotos());
    this.fetchPhotos();
    this.tags = await this.tagService.getPhotoTagsByType().toPromise();

    this.patientService.thePatientUpdated$.pipe(takeUntil(this.unsub)).subscribe((ignored) => this.fetchPhotos());

    // New photos uploaded to sync folder trigger SignalR
    this.photoSignalrService.photosChanged$.pipe(takeUntil(this.unsub)).subscribe((photo: PhotoMetaData) => {
      if (photo) {
        //console.log('New photo detected: ' + photo.id);
        const isPatientGallery = this.patient?.patientId == photo.patientId;
        // If we are on the patient's page or we are in a generic gallery, refresh when a new photo is added.
        if (this.filter.isGlobal() || this.filter.isConsult() || isPatientGallery)
          this.fetchPhotos(false).add(() => {
            // If we are on the patient's page and the new photo is on the page we are viewing (not page 2, 3...)
            const newPhoto = this.photos.find((p) => p.id == photo.id);
            if (isPatientGallery && newPhoto)
              // Select the new photo
              this.filter.selectedPhoto = newPhoto;
          });
      }
    });
    // Camera Not Found SignalR event handling
    this.photoSignalrService.cameraNotFound$.pipe(takeUntil(this.unsub)).subscribe((message: string) => {
      this.cameraConnected = false;
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '300px',
        data: {
          title: 'Camera Connection Error',
          content: message,
          confirmButtonText: 'OK',
          showCancel: false,
        },
      });
    });
    // Camera Error SignalR event handling
    this.photoSignalrService.cameraError$.pipe(takeUntil(this.unsub)).subscribe((message: string) => {
      this.cameraConnected = false;
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '300px',
        data: {
          title: 'Camera Connection Error',
          content: message,
          confirmButtonText: 'OK',
          showCancel: false,
        },
      });
    });
    // Camera Connected SignalR event handling
    this.photoSignalrService.cameraConnected$.pipe(takeUntil(this.unsub)).subscribe((message: string) => {
      this.cameraConnected = true;
    });
    // Camera Disconnected SignalR event handling
    this.photoSignalrService.cameraDisconnected$.pipe(takeUntil(this.unsub)).subscribe((message: string) => {
      this.cameraConnected = false;
    });
    // Camera Automatically Disconnected SignalR event handling
    this.photoSignalrService.cameraShutDown$.pipe(takeUntil(this.unsub)).subscribe((message: string) => {
      this.cameraConnected = false;
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '300px',
        data: {
          title: 'Camera Shut Down',
          content: message,
          confirmButtonText: 'OK',
          showCancel: false,
        },
      });
    });
  }

  fetchPhotos(showLoad: boolean = true) {
    if (showLoad) this.loading = true;
    return this.photoService
      .getPhotos(this.filter)
      .pipe(takeUntil(this.unsub))
      .pipe(take(1))
      .subscribe(
        (photoResults: { results: PhotoMetaData[]; totalResults: number }) => {
          this.filter.totalPhotos = photoResults.totalResults;
          this.photos = photoResults.results;
          this.filter.setSelectedPhoto(
            this.filter.selectedPhoto ? this.filter.selectedPhoto : this.getSelectedPhoto(),
            this.photos
          );

          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.filter.filterError$.next('Error fetching photos: ' + JSON.stringify(err));
        }
      );
  }

  updatePhotoMetaData(photo: PhotoMetaData) {
    let _photos = Array.from(this.photos);
    this.photos = [];
    let index = _photos.findIndex((p) => p.id == photo.id);
    if (index > -1) {
      _photos[index] = photo;
    }
    this.photos = _photos;
    this.filter.setSelectedPhoto(photo, this.photos);
  }

  updateEditMode(editEnabled: boolean) {
    this.editModeEnabled = editEnabled;
  }

  toggleSeriesCreationMode(seriesMode: boolean) {
    this.seriesMode = seriesMode;
    this.selectedPhotosForSeriesCreation = [];
  }

  getSelectedPhoto() {
    return this.seriesMode || !this.photos || this.photos.length == 0 ? null : this.photos[0];
  }

  public uploadPhoto() {
    let modal = this.modalService.open(AddPhotoComponent, { windowClass: 'add-photo-modal', centered: true });
    modal.componentInstance.filter = this.filter;
    modal.componentInstance.photos = this.photos;
  }

  onSeriesCreated(photo: PhotoMetaData) {
    this.filter.setSelectedPhoto(photo, this.photos);
    this.seriesMode = false;
    this.filter.filterChanged$.next(this.filter);
  }

  cancelSeriesCreation() {
    this.seriesMode = false;
    this.filter.filterChanged$.next(this.filter);
  }

  public startCapture() {
    this.loading = true;
    this.photoService.startCapture(this.patient.patientId).subscribe(() => {
      this.loading = false;
    });
  }

  public stopCapture() {
    this.loading = true;
    this.photoService.stopCapture(this.patient.patientId).subscribe(() => {
      this.loading = false;
    });
  }

  updateProcessSelection(selection: PhotoProcessingOperation[]) {
    this.processSelection = selection;
  }

  ngOnDestroy() {
    this.filter = null;
    this.unsub.next();
    this.unsub.complete();
  }
}
