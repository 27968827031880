
export function noInstance(v: any): boolean {
  return v === undefined || v === null;
}

export function cloneArray<T extends string | number>(arr: T[]): T[] {
  if (arr) {
    return arr.slice(0);
  }
}

/**
 * Converts an object to a Map.
 * The names of the properties of the object are used as the map's keys.
 * All the property values of the object must be of the same type.
 * @param o Object to convert to Map.
 */
export function toMap<V>(o: { [key: string]: V }): Map<string, V> {
  if (o) {
    const keys = Object.getOwnPropertyNames(o);
    const map = new Map();

    for (const key of keys) {
      map[key] = o[key];
    }

    return map;
  }
}

export function getPosition(el): { x: number, y: number } {
  let xPos = 0;
  let yPos = 0;

  while (el) {
    if (el.tagName === 'BODY') {
      // deal with browser quirks with body/window/document and page scroll
      const xScroll = el.scrollLeft || document.documentElement.scrollLeft;
      const yScroll = el.scrollTop || document.documentElement.scrollTop;

      xPos += (el.offsetLeft - xScroll + el.clientLeft);
      yPos += (el.offsetTop - yScroll + el.clientTop);
    } else {
      // for all other non-BODY elements
      xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
      yPos += (el.offsetTop - el.scrollTop + el.clientTop);
    }

    el = el.offsetParent;
  }
  return {
    x: xPos,
    y: yPos
  };
}

export function removeURIQueryString(uri: string): string {
  return uri.split('?')[0];
}

export function generateRandomString(count: number = 10): string {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < count; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
