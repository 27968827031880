import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Lab } from '@models/lab';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { Address } from '@models/address';
import { LabsService } from '@services/labs.service';
import { MatDialog } from '@angular/material/dialog';
import { HoursOfOperationDialogComponent } from '../../../dialogs/hours-of-operation/hours-of-operation.component';
import { GeographyService } from '@services/geography.service';
import { ValidationService } from '@services/validation.service';
import { FormatterService } from '@services/formatter.service';
import { HoursOfOperation, HoursOfOperationType } from './../../../../models/hoursofoperation';
import { isNullOrUndefined } from '@app/shared/helpers';
import { isValidId, formatHoursOfOperation } from '../../../../shared/helpers';
import { ClinicsService } from '@services/clinics.service';
import { Clinic } from '@models/clinic';

@Component({
  selector: 'app-edit-lab',
  templateUrl: './edit-lab.component.html',
  styleUrls: ['./edit-lab.component.less'],
})
export class EditLabComponent implements OnInit, OnDestroy {
  editLabPanelVisible = false;
  addOrEdit = 'Add';

  labIdParam: string;

  name: FormControl;
  addressAddress1: FormControl;
  addressAddress2: FormControl;
  addressCity: FormControl;
  addressCountry: FormControl;
  addressPostalCode: FormControl;
  addressProvince: FormControl;
  phoneNumber1: FormControl;
  phoneNumber2: FormControl;
  phoneNumber3: FormControl;
  faxNumber: FormControl;
  email: FormControl;
  website: FormControl;
  hoursOfOperation = '';
  labType: FormControl;
  isNew: boolean;

  selectedLab: Lab;
  editedLab: Lab;
  selectedAddress: Address;
  editedAddress: Address;

  countriesOfTheWorld: string[] = [];
  provincesAndStates: string[] = [];

  submitButtonDisabledState = true;
  clinic: Clinic;

  unsub: Subject<void> = new Subject<void>();

  constructor(
    private labsService: LabsService,
    private catalogueUpdatesService: CatalogueUpdatesService,
    private route: ActivatedRoute,
    public validationService: ValidationService,
    public formatterService: FormatterService,
    private geographyService: GeographyService,
    private hoursOfOperationDialog: MatDialog,
    private router: Router,
    private clinicsService: ClinicsService
  ) {
    this.name = new FormControl();
    this.addressAddress1 = new FormControl();
    this.addressAddress2 = new FormControl();
    this.addressCity = new FormControl();
    this.addressCountry = new FormControl();
    this.addressPostalCode = new FormControl('', null);
    this.addressProvince = new FormControl();
    this.phoneNumber1 = new FormControl('', this.validationService.validatePhoneNumber);
    this.phoneNumber2 = new FormControl('', this.validationService.validatePhoneNumber);
    this.phoneNumber3 = new FormControl('', this.validationService.validatePhoneNumber);
    this.faxNumber = new FormControl('', this.validationService.validatePhoneNumber);
    this.email = new FormControl('', [Validators.email]);
    this.website = new FormControl();
    this.hoursOfOperation = '';
    this.labType = new FormControl();
  }

  ngOnInit() {
    for (const key in this.geographyService.countriesByName()) {
      if (this.geographyService.countriesByName().hasOwnProperty(key)) {
        this.countriesOfTheWorld.push(key);
      }
    }

    this.selectedLab = this.initLab(this.selectedLab, this.selectedAddress);
    this.editedLab = this.initLab(this.editedLab, this.editedAddress);

    this.isNew = true;
    this.addOrEdit = 'Add';
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      this.labIdParam = params['labid'];
      if (this.labIdParam !== '_' && this.labIdParam != null) {
        this.labsService.getLabById(this.labIdParam).subscribe((snapshot) => {
          this.selectedLab = snapshot as Lab;
          this.editedLab = snapshot as Lab;
          this.updateProvincesStates();
          this.updateSubmitButtonState();
          this.isNew = false;
          this.addOrEdit = 'Edit';

          const days =
            this.editedLab && this.editedLab.hoursOfOperation
              ? this.editedLab.hoursOfOperation.hoursOfOperationDays
              : [];
          this.hoursOfOperation = formatHoursOfOperation(days);
        });
      } else {
        this.clinicsService.getClinics().subscribe((res) => {
          this.clinic = res[0];
          if (this.clinic.address) {
            if (this.countriesOfTheWorld.includes(this.clinic.address.country)) {
              this.selectedLab.address.country =
                this.countriesOfTheWorld[this.countriesOfTheWorld.indexOf(this.clinic.address.country)];
              this.editedLab.address.country =
                this.countriesOfTheWorld[this.countriesOfTheWorld.indexOf(this.clinic.address.country)];
            }
            this.updateProvincesStates();
            if (this.provincesAndStates.includes(this.clinic.address.province)) {
              this.selectedLab.address.province =
                this.provincesAndStates[this.provincesAndStates.indexOf(this.clinic.address.province)];
              this.editedLab.address.province =
                this.provincesAndStates[this.provincesAndStates.indexOf(this.clinic.address.province)];
            }
          }
        });
      }
    });
  }

  onChangeCountry() {
    this.updateProvincesStates();
    this.addressPostalCode.setValue('');
  }

  updateProvincesStates() {
    this.provincesAndStates = this.geographyService.updateProvinceStateList(this.editedLab.address.country);
    if (this.editedLab.address.country.toLowerCase() === 'canada') {
      this.addressPostalCode.validator = this.validationService.validatePostalCode;
    } else if (this.editedLab.address.country.toLowerCase() === 'united states') {
      this.addressPostalCode.validator = this.validationService.validateZipCode;
    } else {
      this.addressPostalCode.validator = null;
    }
  }

  updateSubmitButtonState() {
    if (
      (this.email.value !== '' && this.email.hasError('email')) ||
      this.phoneNumber1.hasError('phoneError') ||
      this.phoneNumber2.hasError('phoneError') ||
      this.phoneNumber3.hasError('phoneError') ||
      this.faxNumber.hasError('phoneError') ||
      this.website.hasError('websiteError') ||
      this.addressPostalCode.hasError('postalCodeError') ||
      this.addressPostalCode.hasError('zipCodeError') ||
      isNullOrUndefined(this.editedLab.name) ||
      this.editedLab.name.length === 0
    ) {
      this.submitButtonDisabledState = true;
    } else {
      this.submitButtonDisabledState = false;
    }
  }

  updateLab() {
    if (this.isNew) {
      this.labsService.addLab(this.editedLab).subscribe(() => {
        this.catalogueUpdatesService.refreshRequired = true;
        this.catalogueUpdatesService.catalogueUpdateComplete();
        this.router.navigate(['/management/address-book/labs', { outlets: { 'action-panel': null } }]);
      });
    } else {
      this.labsService.updateLab(this.editedLab).subscribe(() => {
        this.catalogueUpdatesService.refreshRequired = true;
        this.catalogueUpdatesService.catalogueUpdateComplete();
        this.router.navigate(['/management/address-book/labs', { outlets: { 'action-panel': null } }]);
      });
    }
  }

  setHoursOfOperation(): void {
    if (!isValidId(this.editedLab.labId)) {
      // We need to save the doctor first so that we can reference their id when creating the hours of operation
      this.labsService.addLab(this.editedLab).subscribe((lab: Lab) => {
        this.editedLab = lab;
        this.openHoursOfOperationDialog();
        this.isNew = false;
      });
    } else {
      this.openHoursOfOperationDialog();
    }
  }

  openHoursOfOperationDialog(): void {
    let hoursObj = this.editedLab.hoursOfOperation;
    if (!hoursObj) {
      hoursObj = { hoursOfOperationId: 0, hoursOfOperationDays: [] };
    }
    const dialogRef = this.hoursOfOperationDialog.open(HoursOfOperationDialogComponent, {
      width: '700px',
      height: '400px',
      data: {
        companyId: this.editedLab.labId,
        buzzHours: hoursObj,
        hoursType: HoursOfOperationType.Other,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (!isNullOrUndefined(result)) {
          this.editedLab.hoursOfOperation = result;
          this.updateSubmitButtonState();
          this.hoursOfOperation = formatHoursOfOperation(result.hoursOfOperationDays);
        }
      });
  }

  cancelUpdate() {
    this.catalogueUpdatesService.refreshRequired = false;
    this.catalogueUpdatesService.catalogueUpdateComplete();
    this.router.navigate(['/management/address-book/labs', { outlets: { 'action-panel': null } }]);
  }

  initLab(lab: Lab, address: Address) {
    address = {
      address1: '',
      address2: '',
      city: '',
      country: '',
      postalCode: '',
      province: '',
    };
    lab = {
      labId: 0,
      name: '',
      address: address,
      phoneNumber1: '',
      phoneNumber2: '',
      phoneNumber3: '',
      faxNumber: '',
      email: '',
      website: '',
      hoursOfOperation: null,
      labType: '',
    };
    return lab;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
