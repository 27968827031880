import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ObservationListItem, Observation, ObservationListStatic } from '@models/observation/observation';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { TreatmentType } from '@models/treatment-type';
import { isNullOrUndefined } from '@app/shared/helpers';
import { ObservationPlot } from '@models/observation/observation-plot';
import { DecimalPipe } from '@angular/common';
import { ClinicSupplyType } from '@models/clinic-supply-type';

@Injectable()
export class ObservationListItemsService {
  public observationListItems: Map<number, ObservationListItem>;
  public observationListStatic: Map<number, ObservationListStatic>;
  public newEntityId = 0;
  public inventoryRemainingWarningNumber = 10;

  private observationListsUpdated = new Subject<ObservationListItem>(); // for SignalR service
  observationListsChanged = new Subject<ObservationListItem[]>();
  observationListsChangeCancelled = new Subject();

  private observationListsStaticUpdated = new Subject<ObservationListStatic>();
  public observationListsStaticUpdated$ = this.observationListsStaticUpdated.asObservable();

  public newClinicSupplyTypeAdded = new Subject<ClinicSupplyType>();
  newClinicSupplyTypeAdded$ = this.newClinicSupplyTypeAdded.asObservable();

  private refreshRequired = new Subject<any>();
  refreshRequired$ = this.refreshRequired.asObservable();

  constructor(private http: HttpClient, private decimalPipe: DecimalPipe) {
    this.observationListItems = new Map();
    this.observationListStatic = new Map();
  }

  updateRefreshRequired(state: boolean) {
    this.refreshRequired.next(state);
  }

  assignPriceToObservation(obr: Observation) {
    let obrListItem = this.getObservationListItemFromObr(obr);
    obr.details.productPrice = obrListItem.pricePerUnit ?? 0;
    obr.details.costPerUnit = obrListItem.costPerUnit;
  }

  assignCostToObservation(obr: Observation) {
    let obrListItem = this.getObservationListItemFromObr(obr);
    obr.details.costPerUnit = obrListItem.costPerUnit;
  }

  getObservationListItemFromObr(obr: Observation) {
    let obrListItem: ObservationListItem;

    if (
      obr.name === TreatmentType.Toxins ||
      obr.name === TreatmentType.Fillers ||
      obr.name === TreatmentType.Deoxycholate
    ) {
      obrListItem = this.observationListItems.get(obr.details.injected);
    } else if (obr.name === TreatmentType.Coolsculpting || obr.name === TreatmentType.CoolTone) {
      obrListItem = this.observationListItems.get(obr.details.applicator);
    }

    return obrListItem;
  }

  getAllObservationListItems(): Observable<ObservationListItem[]> {
    return this.http.get<ObservationListItem[]>(environment.baseUrl + 'api/ObservationList').pipe(
      map((obrListItems: ObservationListItem[]) => {
        obrListItems.forEach((obrListItem) => {
          this.observationListItems.set(obrListItem.id, Object.assign(new ObservationListItem(), obrListItem));
        });
        return obrListItems;
      })
    );
  }

  getObservationListStatic(): Observable<ObservationListStatic[]> {
    return this.http
      .get<ObservationListStatic[]>(environment.baseUrl + 'api/ObservationList/ObservationListStatic')
      .pipe(
        map((obrListStatic: ObservationListStatic[]) => {
          obrListStatic.forEach((obrListStatic) => {
            this.observationListStatic.set(obrListStatic.id, Object.assign(new ObservationListItem(), obrListStatic));
          });
          return obrListStatic;
        })
      );
  }

  getClinicSupplies() {
    return this.http.get<ObservationListItem[]>(environment.baseUrl + 'api/ObservationList/ClinicSupplies');
  }

  getObservationListItem(id: number) {
    return this.http.get<ObservationListItem>(`${environment.baseUrl}api/ObservationList/${id}`);
  }

  addObservationListItem(obrListItem: ObservationListItem) {
    obrListItem.id = this.newEntityId;
    return this.http.post<ObservationListItem>(environment.baseUrl + 'api/ObservationList', obrListItem);
  }

  updateObservationListItem(obrListItem: ObservationListItem) {
    return this.http.put<ObservationListItem>(
      environment.baseUrl + 'api/ObservationList/' + obrListItem.id,
      obrListItem
    );
  }

  deleteObservationListItem(id: number) {
    return this.http.delete<ObservationListItem>(`${environment.baseUrl}api/ObservationList/${id}`);
  }

  updateIsActive(observationListItem: ObservationListItem, isActive: boolean) {
    return this.http.put(`${environment.baseUrl}api/ObservationList/UpdateIsActive`, {
      observationListId: observationListItem.id,
      isActive: isActive,
    });
  }

  emitEventObservationListsUpdated(data: ObservationListItem) {
    this.observationListsUpdated.next(data);
  }

  emitEventObservationListStaticUpdated(data: ObservationListStatic) {
    this.observationListsStaticUpdated.next(data);
  }

  getInventoryRemaining(
    obrListItem: ObservationListItem,
    pendingNewObservations: ObservationPlot[],
    pendingDeletedObservations: ObservationPlot[],
    formatString: boolean = true
  ) {
    if (isNullOrUndefined(obrListItem) || isNullOrUndefined(obrListItem.unitsOnHand)) {
      return null;
    } else {
      let pendingNewObrInventoryAmount = 0;
      let pendingDeleteObrInventoryAmount = 0;

      for (const pendingNewObr of pendingNewObservations) {
        const obrListId = this.getObrListTypeIdFromObr(pendingNewObr.observation);

        if (obrListItem.id === obrListId) {
          pendingNewObrInventoryAmount += +pendingNewObr.observation.value;
        }
      }

      for (const pendingDeleteObr of pendingDeletedObservations) {
        const obrListId = this.getObrListTypeIdFromObr(pendingDeleteObr.observation);

        if (obrListItem.id === obrListId) {
          pendingDeleteObrInventoryAmount += +pendingDeleteObr.observation.value;
        }
      }
      let total: string | number =
        obrListItem.unitsOnHand - pendingNewObrInventoryAmount + pendingDeleteObrInventoryAmount;
      if (formatString) {
        try {
          total = this.decimalPipe.transform(String(total), '1.2-2');
        } catch (e) {
          total = obrListItem.unitsOnHand - pendingNewObrInventoryAmount + pendingDeleteObrInventoryAmount;
        }
      }

      return total;
    }
  }

  getInventoryRemainingCoolTone(
    obrListItem: ObservationListItem,
    pendingNewObservations: ObservationPlot[],
    pendingDeletedObservations: ObservationPlot[],
    formatString: boolean = true
  ) {
    if (isNullOrUndefined(obrListItem) || isNullOrUndefined(obrListItem.unitsOnHand)) {
      return null;
    } else {
      let pendingNewObrInventoryAmount = 0;
      let pendingDeleteObrInventoryAmount = 0;

      let areasCT: Map<string, number> = new Map<string, number>();

      for (const pendingNewObr of pendingNewObservations) {
        const obrListAreaId = this.getObrListAreaIdFromObr(pendingNewObr.observation);
        const obrListLocation = this.getObrListLocationFromObr(pendingNewObr.observation);
        const key = `${obrListAreaId}/${obrListLocation}`;
        if (!areasCT.has(key)) {
          areasCT.set(key, 1);
        } else {
          areasCT.set(key, areasCT.get(key) + 1);
        }
      }

      for (const pendingDeleteObr of pendingDeletedObservations) {
        const obrListAreaId = this.getObrListAreaIdFromObr(pendingDeleteObr.observation);
        const obrListLocation = this.getObrListLocationFromObr(pendingDeleteObr.observation);
        const key = `${obrListAreaId}/${obrListLocation}`;
        if (areasCT.has(key)) {
          if (areasCT.get(key) - 1 == 0) {
            areasCT.delete(key);
          } else {
            areasCT.set(key, areasCT.get(key) - 1);
          }
        }
      }

      let total: string | number = obrListItem.unitsOnHand - areasCT.size;
      if (formatString) {
        try {
          total = this.decimalPipe.transform(String(total), '1.2-2');
        } catch (e) {
          total = obrListItem.unitsOnHand - pendingNewObrInventoryAmount + pendingDeleteObrInventoryAmount;
        }
      }

      return total;
    }
  }

  isInventoryRemainingDisplayed(
    obrListItem: ObservationListItem,
    pendingObservations: ObservationPlot[],
    pendingDeletedObservations: ObservationPlot[]
  ) {
    const invQuantity = this.getInventoryRemaining(
      obrListItem,
      pendingObservations,
      pendingDeletedObservations
    ) as number;

    if (invQuantity === null) {
      return false;
    } else if (invQuantity > this.inventoryRemainingWarningNumber) {
      return false;
    } else {
      return true;
    }
  }

  getObrListTypeIdFromObr(obr: Observation): number {
    if (
      obr.name === TreatmentType.Toxins ||
      obr.name === TreatmentType.Fillers ||
      obr.name === TreatmentType.Deoxycholate
    ) {
      return obr.details.injected;
    } else if (obr.name === TreatmentType.Coolsculpting || obr.name === TreatmentType.CoolTone) {
      return obr.details.applicator;
    } else {
      return -1;
    }
  }

  getObrListAreaIdFromObr(obr: Observation): number {
    return obr.details.area;
  }

  getObrListLocationFromObr(obr: Observation): string {
    if (obr.details.location != null) return obr.details.location;
    return '';
  }
}
