import { TagType } from './../models/tag/tag-type';
import { PhotoMetaData } from './../models/photo/photo-meta-data';
import { Subject } from 'rxjs';
import { Patient } from '@models/patient';
import { Tag } from '@models/tag/tag';

export enum PhotoGalleryMode{
  Patient = 1,
  Global = 2 ,
  Consultation = 3,
  Clinic = 4
}

export enum PhotoType{
  Single = 1 ,
  BeforeAfter = 2,
  Series = 3
}

export enum PhotoSharingType {
  None = 1,
  Internal = 2,
  External = 3
}

export interface PhotoTag{
  tagId: string;
  photoId: string;
  title: string;
  type: TagType;
  customTag?: Tag;
}

export interface TagCategory {
  id: number;
  name: string;
  order: number;
  tags: Tag[];
}


export class PhotoFilter{


  private _gallery_mode: PhotoGalleryMode;
  get gallery_mode(){
    return this._gallery_mode;
  }

  set gallery_mode(gallery_mode: PhotoGalleryMode){
    switch(gallery_mode){
      case PhotoGalleryMode.Patient:
        this.allow_service_template_filters = false;
        this._include_stock_photos = false;
        this._gender_filter = null;
        this.sharing_types_allowed = [PhotoSharingType.External, PhotoSharingType.Internal, PhotoSharingType.None];
        if (this.gallery_mode === PhotoGalleryMode.Consultation)
          this.sharing_types_selected = [PhotoSharingType.External, PhotoSharingType.Internal, PhotoSharingType.None];
        break;
      case PhotoGalleryMode.Consultation:
        this.allow_service_template_filters = true;
        this.sharing_types_allowed = [PhotoSharingType.External, PhotoSharingType.Internal];
        this._service_template_filters = [];
        this.sharing_types_selected =    this.sharing_types_selected.length > 0 && this.sharing_types_selected.find(type => type == PhotoSharingType.None ) == null  ?  this.sharing_types_selected : [PhotoSharingType.External, PhotoSharingType.Internal];
        break;
      case PhotoGalleryMode.Global:
        this.allow_service_template_filters = true;
        this._include_stock_photos = false;
        this._gender_filter = null;
        this.sharing_types_allowed = [PhotoSharingType.External, PhotoSharingType.Internal, PhotoSharingType.None];
        if (this.gallery_mode === PhotoGalleryMode.Consultation)
          this.sharing_types_selected = [PhotoSharingType.External, PhotoSharingType.Internal, PhotoSharingType.None];
      default:

        break;
    }
    this._gallery_mode = gallery_mode;
    this.filterUpdated$.next(true);
  }


  patient: Patient;
  patientId: number;
  private filterUpdated$: Subject<boolean> = new Subject<boolean>(); //bool corresponds to resetPagination flag

  incrementSelectedPhoto$: Subject<1|-1> = new Subject<1|-1>(); //+/- corresponds to next/prev
  filterChanged$: Subject<PhotoFilter> = new Subject<PhotoFilter>();
  filterError$: Subject<string> = new Subject<string>();

  tagPillClicked$: Subject<any> = new Subject<any>();

  photo_types_selected: PhotoType[] = [PhotoType.Single,PhotoType.BeforeAfter,PhotoType.Series]; // all types allowed to be filtered 0..3
  photo_types_allowed: PhotoType[] = [PhotoType.Single,PhotoType.BeforeAfter,PhotoType.Series]; //start with all types selected by default

  sharing_types_allowed: PhotoSharingType[] = [PhotoSharingType.None,PhotoSharingType.Internal,PhotoSharingType.External]; //start with all types allowed by default
  sharing_types_selected: PhotoSharingType[] = [PhotoSharingType.None,PhotoSharingType.Internal,PhotoSharingType.External]; //start with no sharing selected by defult

  allow_service_template_filters: boolean = false;

  set service_tag_filters(service_tag_filters){
    this._service_tag_filters = service_tag_filters;
    this.filterUpdated$.next(true);
  }
  get service_tag_filters(){
    return this._service_tag_filters;
  }
  private _service_tag_filters: PhotoTag[] = [];


  set body_tag_filters(body_tag_filters){
    this._body_tag_filters = body_tag_filters;
    this.filterUpdated$.next(true);
  }
  get body_tag_filters(){
    return this._body_tag_filters;
  }
  private _body_tag_filters: PhotoTag[] = [];


  set service_template_filters(service_template_filters){
    this._service_template_filters = service_template_filters;
    this.filterUpdated$.next(true);
  }
  get service_template_filters(){
    return this._service_template_filters;
  }
  private _service_template_filters: PhotoTag[] = [];

  set supply_tag_filters(supply_tag_filters) {
    this._supply_tag_filters = supply_tag_filters;
    this.filterUpdated$.next(true);
  }
  get supply_tag_filters() {
    return this._supply_tag_filters;
  }
  private _supply_tag_filters: PhotoTag[] = [];

  set all_tag_filters(tags: any[]) {
    this._service_tag_filters = tags.filter(tag => tag.type == TagType.service);
    this._body_tag_filters = tags.filter(tag => tag.type == TagType.bodyPart);
    this._supply_tag_filters = tags.filter(tag => tag.type == TagType.supply);
    this.filterUpdated$.next(true);
  }

  get all_tag_filters() {
    return this._service_tag_filters.concat(this._body_tag_filters).concat(this._supply_tag_filters);
  }


  selectedPhoto: PhotoMetaData = null;
  selectedPhotoIndex: number = 0;
  selectedPhotoDimensions: string = '';
  totalPhotos: number = 0;
  skip: number = 0;
  take: number = 50;

  get totalPages(){
    if (this.totalPhotos == 0)
      return 1;
    else
      return Math.ceil(this.totalPhotos/this.take);
  }

  resetPagination(){
    this._page = 1;
    this.skip = 0;
  }

  private _page: number = 1;
  get page(){
    return this._page;
  }

  set page(page){
    let nPages = this.totalPages;
    if (page > 0){
      if (page > nPages) this._page = 1
      else this._page = page;


      this.skip = this.take * (this._page - 1);
    }
    else{
      this.page = nPages;
    }
    this.filterUpdated$.next(false);
  }

  set gender_filter(gender_filter){
    this._gender_filter = gender_filter;
    this.filterUpdated$.next(true);
  }
  get gender_filter(){
    return this._gender_filter;
  }
  private _gender_filter: 'M'|'F' = null;


  get include_stock_photos(){
    return this._include_stock_photos;
  }
  set include_stock_photos(include_stock_photos){
    this._include_stock_photos = include_stock_photos;
    this.filterUpdated$.next(true);
  }
  private _include_stock_photos: boolean = false;



  get only_show_favourites(){
    return this._only_show_favourites;
  }
  set only_show_favourites(only_show_favourites){
    this._only_show_favourites = only_show_favourites;
    this.filterUpdated$.next(true);
  }
  private _only_show_favourites: boolean = false;

  constructor(isIpad: boolean,patient?: Patient ){
    if (isIpad) this.take = 20;
    else this.take = 50;
    if (patient){
      this.patient = patient;
      this.patientId = patient.patientId;
    }
    this.gallery_mode = this.getDefaultGalleryMode();

    this.filterUpdated$.subscribe((resetPagination: boolean) => {
      if (resetPagination){
        this.resetPagination();
      }
      this.filterChanged$.next(this);
    });

  }

  private getDefaultGalleryMode(){
    return this.patient ? PhotoGalleryMode.Patient : PhotoGalleryMode.Global;
  }

  toggleConsultationMode(){
    //if its in consultation mode or stock mode (which requires being in consult mode) then revert to default based on if this is patient gallery or not
    this.gallery_mode = this.gallery_mode == PhotoGalleryMode.Consultation   ?  this.getDefaultGalleryMode() :  PhotoGalleryMode.Consultation;

  }

  resetFilter() {
    this.photo_types_selected = [PhotoType.Single,PhotoType.BeforeAfter,PhotoType.Series];
    this.sharing_types_selected = [PhotoSharingType.None,PhotoSharingType.Internal,PhotoSharingType.External];
    this._body_tag_filters = [];
    this._service_template_filters = [];
    this._service_tag_filters = [];
    this._gender_filter = null;
    this._only_show_favourites = false;
    // Updating the gallery with the setter will update the filterUpdated$ Subject
    this.gallery_mode = this.getDefaultGalleryMode();
  }


  setSelectedPhoto(photo: PhotoMetaData, photos: PhotoMetaData[]){
    this.selectedPhoto = photo;
    if (photo && photos && photos.length > 0){
        this.selectedPhotoIndex = photos.findIndex(p => p.id == photo.id);
    }
    else if (!photo && photos && photos.length > 0) {
      this.selectedPhoto = photos[0];
      this.selectedPhotoIndex = 0;
    }
    else this.selectedPhotoIndex = 0;
  }

  updateTagFilters(new_tag: PhotoTag) {
    if (new_tag.type == TagType.service){
      this.updateServiceTagFilters(new_tag);
    }
    else if (new_tag.type == TagType.bodyPart){
      this.updateBodyTagFilters(new_tag);
    }
    else if (new_tag.type == TagType.supply){
      this.updateSupplyTagFilters(new_tag);
    }
    this.tagPillClicked$.next(new_tag);
  }

  updateServiceTagFilters(new_tag: PhotoTag){
    let index = this.service_tag_filters.findIndex(tag => tag.tagId == new_tag.tagId);
    if (index > -1){
      this.service_tag_filters.splice(index, 1);
    }
    else{
      this.service_tag_filters.push(new_tag);
    }
    this.filterUpdated$.next(true);
  }

  updateBodyTagFilters(new_tag: PhotoTag){
    let index = this.body_tag_filters.findIndex(tag => tag.tagId == new_tag.tagId);
    if (index > -1){
      this.body_tag_filters.splice(index, 1);
    }
    else{
      this.body_tag_filters.push(new_tag);
    }
    this.filterUpdated$.next(true);
  }

  updateSupplyTagFilters(new_tag: PhotoTag){
    let index = this.supply_tag_filters.findIndex(tag => tag.tagId == new_tag.tagId);
    if (index > -1){
      this.supply_tag_filters.splice(index, 1);
    }
    else{
      this.supply_tag_filters.push(new_tag);
    }
    this.filterUpdated$.next(true);
  }

  updatePatientServiceTemplateFilters(new_tag: PhotoTag){
    if (!this.allow_service_template_filters){
      this.filterError$.next("Cannot select service template filters because these images are not associated with patients.");
    }
    else{
      let index = this.service_template_filters.findIndex(tag => tag.tagId == new_tag.tagId);
      if (index > -1){
        this.service_template_filters.splice(index, 1);
      }
      else{
        this.service_template_filters.push(new_tag);
      }
      this.filterUpdated$.next(true);
    }
  }

  updateSelectedSharingMode(new_sharing_type: PhotoSharingType){
    if (this.sharing_types_allowed.findIndex( sharing_type => sharing_type == new_sharing_type) == -1){
      this.filterError$.next("The selected photo sharing is not available in " + this.getDescriptionOfGalleryMode().toLowerCase() + " mode. The following sharing modes are allowed: " + this.getDescriptionOfEnums(this.sharing_types_allowed));
    }
    else{
      let index = this.sharing_types_selected.findIndex(sharing_type => sharing_type == new_sharing_type);
      if (index > -1){
        this.sharing_types_selected.splice(index, 1);
      }
      else{
        this.sharing_types_selected.push(new_sharing_type);
      }
      this.filterUpdated$.next(true);
    }
  }
  updateSelectedPhotoType(new_photo_type: PhotoType){
    if (this.photo_types_allowed.findIndex( photo_type => photo_type == new_photo_type) == -1){
      this.filterError$.next("The selected photo type is not available in " + this.getDescriptionOfGalleryMode().toLowerCase() + " mode. The following photo types are allowed: " + this.getDescriptionOfEnums(this.photo_types_allowed));
    }
    else{
      let index = this.photo_types_selected.findIndex( photo_type => photo_type == new_photo_type);
      if (index > -1){
        this.photo_types_selected.splice(index, 1);
      }
      else{
        this.photo_types_selected.push(new_photo_type);
      }
      this.filterUpdated$.next(true);
    }
  }

 getDescriptionOfGalleryMode(): string{
   var galleryDescription  = '';
    switch (this.gallery_mode){
      case PhotoGalleryMode.Patient:
        galleryDescription = "Patient Gallery"
      case PhotoGalleryMode.Consultation:
        galleryDescription = "Consultation Gallery"
      case PhotoGalleryMode.Global:
      default:
        galleryDescription = "Global Admin Gallery"
    }
    if (this.include_stock_photos) galleryDescription += ' + Stock Photos'
    return galleryDescription;
  }

  private getDescriptionOfEnums(enumerator) : string{
    return Object.keys(enumerator).join(', ')
  }
  isSelectedPhotoType(type: PhotoType): boolean {
    return this.photo_types_selected.findIndex(t => t === type) != -1;
  }
  isSelectedSharingType(sharingType: PhotoSharingType) {
    return this.sharing_types_selected.findIndex(t => t === sharingType) != -1;
  }

  filterActive() {
    return this.gallery_mode === PhotoGalleryMode.Consultation ||
           this.only_show_favourites ||
           !(this.isSelectedPhotoType(PhotoType.Single) &&
             this.isSelectedPhotoType(PhotoType.BeforeAfter) &&
             this.isSelectedPhotoType(PhotoType.Series)) ||
           !(this.isSelectedSharingType(PhotoSharingType.None) &&
             this.isSelectedSharingType(PhotoSharingType.Internal) &&
             this.isSelectedSharingType(PhotoSharingType.External)) ||
           this.gender_filter != null ||
           this.body_tag_filters.length != 0 ||
           this.service_tag_filters.length != 0 ||
           this.service_template_filters.length != 0;
  }

  isConsult() {
    return this.gallery_mode === PhotoGalleryMode.Consultation;
  }
  isPatient() {
    return this.gallery_mode === PhotoGalleryMode.Patient;
  }
  isGlobal() {
    return this.gallery_mode === PhotoGalleryMode.Global;
  }

}
