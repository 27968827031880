import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  private mobileBreakpoint = new BehaviorSubject<boolean>(false);
  mobileBreakpoint$ = this.mobileBreakpoint.asObservable();

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe((result) => {
      this.mobileBreakpoint.next(result.matches);
    });
  }
}
