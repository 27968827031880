import * as moment from 'moment';

export abstract class CommunicationsSettingsBase {
    enabled: boolean;
    communicationSettingType: CommunicationSettingType;
    workerCronSchedule: string;
    workerStartTime: moment.Duration;
    workerEndTime: moment.Duration;
    firstNotificationBefore: string;
    repeatNotificationEvery: string;
}

export enum CommunicationSettingType {
    ScheduledConfirmation = 1,
    ChangedConfirmation = 2,
    CancelledConfirmation = 3,
    Reminder = 4,
}
