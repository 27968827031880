<div class="d-flex align-items-center navy-background py-2 mt-2 mb-4">
  <div class="total-row white-font font-weight-bold pl-2 w-100">
    <div>
      TOTAL
      {{ service?.serviceDetailTemplateId === serviceDetailTemplate.Injections ? 'INJECTABLES' : 'TREATMENT' }} COST:
    </div>
    <div></div>
    <div></div>
    <div class="d-flex justify-content-end pr-3">{{ serviceChargeDisplayed | currency }}</div>
  </div>
  <div class="action-column"></div>
</div>
