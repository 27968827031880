<h1 mat-dialog-title class="text-center">{{ data.title }}</h1>
<div mat-dialog-content style="overflow: hidden; width: 300px">
  <mat-tab-group class="group-dialog-tab" animationDuration="0ms" (selectedTabChange)="switchTab($event)">
    <mat-tab label="Preset">
      <ng-template matTabContent>
        <ng-scrollbar [autoHeightDisabled]="false" style="max-height: 400px; min-height: 100px">
          <div *ngIf="observationGroups.length == 0; else displayList" class="d-flex justify-content-center">
            <div class="mt-3">No Presets available.</div>
          </div>
          <ng-template #displayList>
            <div *ngFor="let obsg of observationGroups">
              <div class="row mx-0 pb-2 align-items-center">
                <div class="col item grey-two-background font-weight-bold">
                  {{ obsg.name }}
                  <span class="float-right">{{ obsg.price | currency }}</span>
                </div>

                <button type="button" class="panel-box-button" (click)="onApplyGroup(obsg)">
                  <i class="far fa-plus" title="Apply Group"></i>
                </button>
              </div>
            </div>
          </ng-template>
        </ng-scrollbar>
      </ng-template>
    </mat-tab>
    <mat-tab label="Custom">
      <ng-template matTabContent>
        <div style="height: 160px">
          <div class="text-center disable-input-number-spinner">
            <div class="d-flex align-items-center form-field group-modal-input">
              <mat-form-field>
                <input
                  matInput
                  class="capitalized"
                  placeholder="Group Name (Optional)"
                  [(ngModel)]="result.groupName"
                />
              </mat-form-field>
            </div>
            <div class="d-flex align-items-center form-field group-modal-input">
              <mat-form-field class="w-50">
                <input
                  matInput
                  onlyNumber
                  numericType="decimal"
                  type="number"
                  min="0"
                  class="text-right"
                  placeholder="Custom Price"
                  [(ngModel)]="result.groupPrice"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
<div mat-dialog-actions class="justify-content-center">
  <button mat-button [mat-dialog-close]="'cancel'">Cancel</button>
  <button *ngIf="customSelected" mat-button [mat-dialog-close]="result" cdkFocusInitial>Apply</button>
</div>
