<h1 mat-dialog-title class="text-center">{{ title }}</h1>
<div mat-dialog-content class="text-center">
  <div [innerHTML]="content | keepHtml"></div>
</div>
<div mat-dialog-actions class="justify-content-center">
  <button data-test-id="generic-confirm-modal-confirm" mat-button [mat-dialog-close]="'confirm'" cdkFocusInitial>
    {{ confirmButtonText }}
  </button>
  <button data-test-id="generic-confirm-modal-cancel" mat-button [mat-dialog-close]="'cancel'" *ngIf="showCancel">
    {{ cancelButtonText ? cancelButtonText : 'Cancel' }}
  </button>
</div>
