import { PaymentHelper } from '@services/helpers/payment-helper';
import { Observable } from 'rxjs';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppointmentService } from '@services/appointments.service';
import { CancellationReasonService } from '@services/cancellation-reason.service';
import { ChargeableAppointment } from '@models/billing/chargeable-appointment';
import { ConfirmCancelData } from '@models/billing/confirm-cancel-data';
import { VisitService } from '@services/visit.service';

@Component({
  selector: 'app-confirm-cancel-with-reason',
  templateUrl: './confirm-cancel-with-reason.component.html',
  styleUrls: ['./confirm-cancel-with-reason.component.less'],
})
export class ConfirmCancelWithReasonDialogComponent {
  public dialog: MatDialog;
  cancelReasons = [];
  chargeableAppointment: ChargeableAppointment = new ChargeableAppointment();
  loading: boolean = false;
  errors: any[] = [];

  PaymentHelper = PaymentHelper;

  constructor(
    private appointmentService: AppointmentService,
    private visitService: VisitService,
    dialogRef: MatDialogRef<ConfirmCancelWithReasonDialogComponent>,
    private cancellationReasonService: CancellationReasonService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmCancelData
  ) {
    if (!data.title) {
      data.title = 'Cancel the Appointment?';
    }
    data.result = '';
    data.selectedCancelReason = '';
    data.customCancelReason = '';
    data.isCancelChargeable = false;
    data.chargeCancelAppointment = true;
    data.reasonCancellationNotCharged = '';

    cancellationReasonService.getCancellationReason().subscribe((cr) => {
      cr.forEach((reason) => {
        this.cancelReasons.push(reason.name);
      });
      this.cancelReasons.push('Custom Reason');
    });
  }

  ngOnInit() {
    if (this.data.visitId) {
      this.loading = true;
      this.visitService.isVisitCancellationChargeable(this.data.visitId).subscribe(
        (chargeableAppointment) => {
          this.chargeableAppointment = chargeableAppointment;
          this.data.isCancelChargeable = this.chargeableAppointment.isChargeable;
          this.loading = false;
        },
        (errorResponse: any) => {
          this.errors = errorResponse.error?.errors;
          this.loading = false;
        }
      );
    } else if (this.data.appointmentId) {
      this.loading = true;
      this.appointmentService.isAppointmentCancellationChargeable(this.data.appointmentId).subscribe(
        (chargeableAppointment) => {
          this.chargeableAppointment = chargeableAppointment;
          this.data.isCancelChargeable = this.chargeableAppointment.isChargeable;
          this.loading = false;
        },
        (errorResponse: any) => {
          this.errors = errorResponse.error?.errors;
          this.loading = false;
        }
      );
    }
  }
}
