import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-update-method',
  templateUrl: './confirm-update-method.component.html',
  styleUrls: ['./confirm-update-method.component.less']
})
export class ConfirmUpdateMethodDialogComponent {
  public dialog: MatDialog;
  selectedMethod = 'Current date only';
  updateMethods = ['Current date only', 'Current date and all future dates'];
  updateMethodsMap: Map<string, string> = new Map();

  constructor(
    public dialogRef: MatDialogRef<ConfirmUpdateMethodDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.updateMethodsMap.set('Current date only', 'single');
      this.updateMethodsMap.set('Current date and all future dates', 'forward');
    }

    getSelectedMethodKey() {
      return this.updateMethodsMap.get(this.selectedMethod);
    }

    onNoClick(): void {
      this.data.result = 'cancel';
    }
  }