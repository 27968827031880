import { User } from '@models/user';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Patient } from '@models/patient';
import { Clinic } from '@models/clinic';
import { Prescription } from '@models/prescriptions/prescription';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { Group, exportPDF } from '@progress/kendo-drawing';
import { BlobService } from '@services/blob.service';
import { UsersService } from '@services/users.service';

@Component({
  selector: 'app-prescription-details',
  templateUrl: './prescription-details.component.html',
  styleUrls: ['./prescription-details.component.less'],
})
export class PrescriptionDetailsComponent implements OnInit {
  @ViewChild('pdf', { static: true }) prescriptionDetails: PDFExportComponent;
  @Input() clinic: Clinic;
  @Input() patient: Patient;
  @Input() prescription: Prescription[];
  @Input() signatureUrl: string;
  currentUser: User;

  constructor(private blobService: BlobService, private usersService: UsersService) {}

  ngOnInit() {
    this.currentUser = this.usersService.loggedInUser;
  }

  public getPrescriptionDateStr(date: string) {
    return moment(date).format('MMMM D, YYYY');
  }

  public getCurrentDayStr() {
    return moment(new Date()).format('MMMM D, YYYY');
  }

  public async exportToPDF(): Promise<{ blob: Blob; base64: string; url: string }> {
    const group = await this.prescriptionDetails.export();
    const dataUri = await exportPDF(group/*, { title: this.getFileName() }*/);
    const base64 = dataUri.replace('data:application/pdf;base64,', '');
    const blob = await this.blobService.convertBase64toBlob(base64, 'application/pdf');
    const printable = URL.createObjectURL(blob);
    return { blob, base64, url: printable };
  }
}
