<ngx-loading [show]="loading"></ngx-loading>

<div class="modal-header border-0 p-0">
  <button
    type="button"
    class="btn white-font p-0 ml-auto mr-2"
    (click)="activeModal.dismiss('Cross click')">
      <i class="fal fa-times"></i>
  </button>
</div>

<div class="p-4">
  <h6 class="mt-0 mb-3 navy-font text-center">DIRECT MESSAGES</h6>

  <input
    type="text"
    autofocus
    class="form-control border-0 grey-three-background"
    [(ngModel)]="enteredUserName"
    placeholder="Enter User Name">

    <div *ngIf="selectedUsers.length > 0" class="d-flex mt-2 flex-wrap position-relative">
      <div *ngFor="let user of selectedUsers" class="selected-user">
        {{user.userName}} 
        <span class="unselect" (click)="unselectUser(user)">
          <i class="fal fa-times"></i>
        </span>
      </div>
      <button class="users-submit btn em-btn em-btn-green px-2 py-1" (click)="selectFinished()">GO</button>
    </div>
  
    <div *ngIf="users?.length > 0" class="mt-3 grey-four-border-top">
      <perfect-scrollbar style="max-height: calc(100dvh - 200px);">
        <div 
          *ngFor="let user of users | filterByProperty:'userName':enteredUserName" 
          class="d-flex user-channel-item align-items-center navy-font grey-four-border-bottom py-2 bigger"
          (click)="selectUser(user)">
            <i
              *ngIf="user.status === 1"
              class="fas fa-circle spring-green-font smaller pr-2">
            </i>
            <i
              *ngIf="user.status !== 1"
              class="far fa-circle navy-font smaller pr-2">
            </i>
            {{user?.userName}}
        </div>
      </perfect-scrollbar>
    </div>
</div>