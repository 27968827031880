export const UserData = {
  title: 'User Form Fields',
  weight: 40,
  default: false,
  components: {
    userFirstName: {
      title: 'First Name',
      key: 'userFirstName',
      schema: {
        label: 'User First Name',
        type: 'textfield',
        key: 'userFirstName',
        disabled: true,
        input: true,
      },
    },
    userLastName: {
      title: 'Last Name',
      key: 'userLastName',
      schema: {
        label: 'User Last Name',
        type: 'textfield',
        key: 'userLastName',
        disabled: true,
        input: true,
      },
    },
    userName: {
      title: 'Full Name',
      key: 'userName',
      schema: {
        label: 'User Full Name',
        type: 'textfield',
        key: 'userName',
        disabled: true,
        input: true,
      },
    },
  },
};
