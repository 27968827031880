import { KeyValue } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { authPermissions } from '@app/auth/auth-permissions';
import { Policies } from '@app/auth/auth-policies';
import { AuthRoleClaimsService } from '@app/auth/auth-role-claims.service';
import { AuthService } from '@app/auth/auth.service';
import { Claim, Role } from '@models/user';
import { ClinicsService } from '@services/clinics.service';
import { UsersService } from '@services/users.service';
import { EMPTY, Subject, concat, forkJoin } from 'rxjs';
import { catchError, map, mergeAll, mergeMap, takeUntil, toArray } from 'rxjs/operators';

@Component({
  selector: 'app-org-user-permissions',
  templateUrl: './org-user-permissions.component.html',
  styleUrls: ['./org-user-permissions.component.less'],
})
export class OrgUserPermissionsComponent implements OnInit {
  @ViewChild('leadingScroll') leadingScrollElement: ElementRef;
  @ViewChildren('followingScroll') followingScrollElements: ElementRef[];

  roleNames: Map<string, string> = new Map();
  roleClaims: Map<string, string[]> = new Map();
  userNames: Map<string, string> = new Map();
  userClaims: Map<string, string[]> = new Map();
  selectedRoleKey: string;
  copyUserId: string = null;
  errorMessage: string;
  loading = false;
  unsub: Subject<void> = new Subject<void>();
  private devPolicySatisfied = false;

  get selectedClinicId() {
    return this.clinicsService.clinicIdSelected$.getValue();
  }

  constructor(
    private usersService: UsersService,
    private authRoleClaimsService: AuthRoleClaimsService,
    private authService: AuthService,
    private clinicsService: ClinicsService
  ) {}

  ngOnInit(): void {
    this.usersService.refreshRequired$.pipe(takeUntil(this.unsub)).subscribe(() => {
      if (this.usersService.refreshRequired$) {
        this.loadData();
      }
    });

    this.clinicsService.clinicIdSelected$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.loadData();
    });

    this.devPolicySatisfied = this.authService.userSatisfiesPolicy(Policies.developer);
  }

  loadData() {
    this.loading = true;
    this.roleNames.clear();
    this.roleClaims.clear();
    this.userNames.clear();
    this.userClaims.clear();

    const isDevClinic = this.clinicsService.clinicIdSelected$.value === 0;
    const getUsersClaimsRequest = this.authRoleClaimsService.getClinicUsersClaims(isDevClinic);
    const getRolesRequest = this.authRoleClaimsService.getRoles().pipe(
      mergeAll(),
      mergeMap((role) =>
        this.authRoleClaimsService
          .getRoleClaims(role.id)
          .pipe(map<Claim[], [Role, Claim[]]>((claims) => [role, claims]))
      ),
      toArray()
    );

    forkJoin([getRolesRequest, getUsersClaimsRequest]).subscribe(([roleClaims, usersClaims]) => {
      roleClaims.forEach((roleClaim) => {
        const [role, claims] = roleClaim;
        this.roleNames.set(role.id, role.name);
        this.roleClaims.set(
          role.id,
          claims.map((c) => c.value)
        );
      });
      usersClaims.forEach((userClaim) => {
        this.userNames.set(userClaim.userId, userClaim.fullName);
        this.userClaims.set(userClaim.userId, userClaim.claimValues);
      });
      this.selectedRoleKey = this.roleNames.keys().next().value;
      this.loading = false;
    });
  }

  valueAscOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return a.value.localeCompare(b.value);
  };

  updateScroll() {
    const leading = this.leadingScrollElement.nativeElement as HTMLElement;
    const following = this.followingScrollElements.map((e) => e.nativeElement as HTMLElement);
    following.forEach((e) => (e.scrollLeft = leading.scrollLeft));
  }

  getTrimmedClaimName(claimName: string, selectedRoleKey: string) {
    let roleName = this.roleNames.get(selectedRoleKey);
    if (!roleName || roleName === 'Developer') return claimName;
    if (roleName === 'Patients') roleName = 'Patient';
    return claimName.replace(roleName, '');
  }

  userHasClaim(userId: string, claimValue: string) {
    const userClaims = this.userClaims.get(userId);
    return userClaims.some((claim) => claim === claimValue);
  }

  userHasAllRoleClaims(userId: string, roleId: string) {
    const roleClaims = this.roleClaims.get(roleId);
    const userClaims = this.userClaims.get(userId);
    return roleClaims.every((roleClaim) => userClaims.some((userClaim) => userClaim === roleClaim));
  }

  userHasPartialRoleClaims(userId: string, roleId: string) {
    const roleClaims = this.roleClaims.get(roleId);
    const userClaims = this.userClaims.get(userId);
    return roleClaims.some((roleClaim) => userClaims.some((userClaim) => userClaim === roleClaim));
  }

  userHasNoRoleClaims(userId: string, roleId: string) {
    const roleClaims = this.roleClaims.get(roleId);
    const userClaims = this.userClaims.get(userId);
    return roleClaims.every((roleClaim) => !userClaims.some((userClaim) => userClaim === roleClaim));
  }

  async onCopyClick(userId: string) {
    if (this.copyUserId === userId) {
      this.copyUserId = null;
    } else if (this.copyUserId) {
      await this.copyUserClaims(this.copyUserId, userId);
      if (this.usersService.loggedInUser.id === userId) {
        await this.authService.refreshTokenRequest('emilyEMRActiveUserRefresh').toPromise();
      }
      this.copyUserId = null;
    } else {
      this.copyUserId = userId;
    }
  }

  async toggleUserClaimsForRole(userId: string, roleId: string) {
    if (this.userHasAllRoleClaims(userId, roleId)) {
      await this.removeAllClaimsForRole(userId, roleId);
    } else {
      await this.setAllClaimsForRole(userId, roleId);
    }
    if (this.usersService.loggedInUser.id === userId) {
      await this.authService.refreshTokenRequest('emilyEMRActiveUserRefresh').toPromise();
    }
  }

  async toggleUserClaim(userId: string, claimValue: string) {
    if (this.userHasClaim(userId, claimValue)) {
      await this.removeClaim(userId, claimValue);
    } else {
      await this.setClaim(userId, claimValue);
    }
    if (this.usersService.loggedInUser.id === userId) {
      await this.authService.refreshTokenRequest('emilyEMRActiveUserRefresh').toPromise();
    }
  }

  private async setClaim(userId: string, claimValue: string): Promise<void> {
    const currentUserClaims = this.userClaims.get(userId);
    this.userClaims.set(userId, currentUserClaims.concat(claimValue));
    await this.authRoleClaimsService
      .setUserClaim(userId, claimValue)
      .pipe(
        catchError((err) => {
          this.userClaims.set(
            userId,
            currentUserClaims.filter((userClaim) => userClaim !== claimValue)
          );
          this.errorMessage = `Could not set <b>${claimValue}</b> permission for <b>${this.userNames.get(userId)}</b>.`;
          return EMPTY;
        })
      )
      .toPromise();
    if (claimValue === authPermissions.Developer && this.devPolicySatisfied) {
      const user = await this.usersService.getUserById(userId).toPromise();
      user.clinicId = null;
      await this.usersService.updateUser(user, null).toPromise();
    }
  }

  private async removeClaim(userId: string, claimValue: string): Promise<void> {
    const currentUserClaims = this.userClaims.get(userId);
    this.userClaims.set(
      userId,
      currentUserClaims.filter((userClaim) => userClaim !== claimValue)
    );
    await this.authRoleClaimsService
      .removeUserClaim(userId, claimValue)
      .pipe(
        catchError((err) => {
          this.userClaims.set(userId, currentUserClaims.concat(claimValue));
          this.errorMessage = `Could not remove <b>${claimValue}</b> permission for <b>${this.userNames.get(
            userId
          )}</b>.`;
          return EMPTY;
        })
      )
      .toPromise();
    if (claimValue === authPermissions.Developer && this.devPolicySatisfied) {
      const user = await this.usersService.getUserById(userId).toPromise();
      user.clinicId = this.usersService.loggedInUserClinic;
      await this.usersService.updateUser(user, null).toPromise();
    }
  }

  private async setAllClaimsForRole(userId: string, roleId: string): Promise<void> {
    const currentUserClaims = this.userClaims.get(userId);
    const roleClaims = this.roleClaims.get(roleId);
    this.userClaims.set(userId, roleClaims);
    await this.authRoleClaimsService
      .setAllUserClaimsInRole(userId, roleId)
      .pipe(
        catchError((err) => {
          this.userClaims.set(userId, currentUserClaims);
          const userName = this.userNames.get(userId);
          const roleName = this.roleNames.get(roleId);
          this.errorMessage = `Could not set all claims for <b>${roleName}</b> for <b>${userName}</b>.`;
          return EMPTY;
        })
      )
      .toPromise();
    if (roleClaims.includes(authPermissions.Developer) && this.devPolicySatisfied) {
      const user = await this.usersService.getUserById(userId).toPromise();
      user.clinicId = null;
      await this.usersService.updateUser(user, null).toPromise();
    }
  }

  private async removeAllClaimsForRole(userId: string, roleId: string): Promise<void> {
    const currentUserClaims = this.userClaims.get(userId);
    const roleClaims = this.roleClaims.get(roleId);
    this.userClaims.set(userId, []);
    await this.authRoleClaimsService
      .removeAllUserClaimsInRole(userId, roleId)
      .pipe(
        catchError((err) => {
          this.userClaims.set(userId, currentUserClaims);
          const userName = this.userNames.get(userId);
          const roleName = this.roleNames.get(roleId);
          this.errorMessage = `Could not remove all claims for <b>${roleName}</b> for <b>${userName}</b>.`;
          return EMPTY;
        })
      )
      .toPromise();
    if (roleClaims.includes(authPermissions.Developer) && this.devPolicySatisfied) {
      const user = await this.usersService.getUserById(userId).toPromise();
      user.clinicId = this.usersService.loggedInUserClinic;
      await this.usersService.updateUser(user, null).toPromise();
    }
  }

  private async copyUserClaims(fromUserId: string, toUserId: string) {
    const fromUserClaims = this.userClaims.get(fromUserId);
    const toUserClaims = this.userClaims.get(toUserId);

    await this.authRoleClaimsService
      .setUserClaims(toUserId, fromUserClaims)
      .pipe(
        catchError((err) => {
          this.userClaims.set(toUserId, toUserClaims);
          const fromUser = this.userNames.get(fromUserId);
          const toUser = this.userNames.get(toUserId);
          this.errorMessage = `Could not copy claims from <b>${fromUser}</b> to <b>${toUser}</b>.`;
          return EMPTY;
        })
      )
      .toPromise();

    if (fromUserClaims.includes(authPermissions.Developer) && this.devPolicySatisfied) {
      const user = await this.usersService.getUserById(toUserId).toPromise();
      user.clinicId = null;
      await this.usersService.updateUser(user, null).toPromise();
    }

    this.userClaims.set(toUserId, fromUserClaims);
  }
}
