<ngx-loading [show]="loading"></ngx-loading>
<div cdkDropListGroup class="d-flex h-100">
  <div class="section border-top border-bottom border-left">
    <div class="section-header justify-content-center">Roles</div>
    <div class="flex-grow-1">
      <ng-scrollbar class="h-100" [viewClass]="'p-3'">
        <mat-accordion [multi]="true">
          <mat-expansion-panel *ngFor="let role of roles | keyvalue : valueAscOrder" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title> {{ role.value | spaceTitleCase }} </mat-panel-title>
            </mat-expansion-panel-header>
            <div
              cdkDropList
              [id]="role.key"
              [cdkDropListData]="roleClaims.get(role.key)"
              [cdkDropListSortingDisabled]="true"
              [cdkDropListEnterPredicate]="claimValuePredicate"
              class="drop-list"
              (cdkDropListDropped)="onDrop($event)"
            >
              <div class="drop-item" *ngFor="let claim of roleClaims.get(role.key)" cdkDrag [cdkDragData]="claim">
                <span class="mr-auto">{{ claim.value | spaceTitleCase }}</span>
                <button mat-icon-button (click)="onRemove(role.key, claim.value)">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-scrollbar>
    </div>
  </div>
  <div class="h-100 border-top border-bottom">
    <div class="section-header p-0">
      <mat-icon>swap_horiz</mat-icon>
    </div>
  </div>
  <div class="section border-top border-bottom border-right">
    <div class="section-header justify-content-center">Claims</div>
    <div class="flex-grow-1">
      <ng-scrollbar class="h-100" [viewClass]="'p-3'">
        <div
          *ngIf="claims.length > 0"
          cdkDropList
          [id]="'allClaims'"
          [cdkDropListData]="claims"
          [cdkDropListSortingDisabled]="true"
          [cdkDropListEnterPredicate]="neverPredicate"
          class="drop-list"
        >
          <div class="drop-item" *ngFor="let claim of claims" cdkDrag [cdkDragData]="claim">
            {{ claim.value | spaceTitleCase }}
          </div>
        </div>
      </ng-scrollbar>
    </div>
  </div>
</div>
