<ngx-loading [show]="loading"></ngx-loading>

<div class="component-wrapper">
  <mat-card>
    <img class="restrict-logo" [src]="clinicLogoUrl ? clinicLogoUrl : '/assets/svg/emily-logo-final.svg'" />
    <mat-card-subtitle>Sign in to continue</mat-card-subtitle>
    <mat-card-content>
      <form>
        <mat-form-field class="w-100">
          <mat-label>User Name</mat-label>
          <input matInput type="username" [formControl]="userNameFormControl" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="userNameFormControl.hasError('required')">
            User Name is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Password</mat-label>
          <input matInput type="password" [formControl]="passwordFormControl" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="passwordFormControl.hasError('required')">
            Password is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <mat-error class="errorBox" [hidden]="!validationError">
          {{ validationErrorMessage }}
        </mat-error>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button class="btn em-btn em-btn-green d-block mx-auto" (click)="login()">Login</button>
    </mat-card-actions>
  </mat-card>
</div>
