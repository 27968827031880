import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExternalPrescription } from '@models/prescriptions/external-prescription';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExternalPrescriptionService {

  constructor(private http: HttpClient) { }

  addExternalPrescription(externalPrescription: ExternalPrescription): Observable<ExternalPrescription> {
    return this.http.post<ExternalPrescription>(`${environment.baseUrl}api/ExternalPrescriptions`, externalPrescription);
  }

  updateExternalPrescription(externalPrescription: ExternalPrescription): Observable<ExternalPrescription> {
    return this.http.put<ExternalPrescription>(`${environment.baseUrl}api/ExternalPrescriptions`, externalPrescription);
  }

  getExternalPrescriptionById(id: number): Observable<ExternalPrescription> {
    return this.http.get<ExternalPrescription>(`${environment.baseUrl}api/ExternalPrescriptions/${id}`);
  }

  getExternalPrescriptionsByPatientId(patientId: number): Observable<ExternalPrescription[]> {
    return this.http.get<ExternalPrescription[]>(`${environment.baseUrl}api/ExternalPrescriptions/ByPatientId/${patientId}`);
  }

  deleteExternalPrescription(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.baseUrl}api/ExternalPrescriptions/${id}`);
  }
}
