import { Component, OnInit, Input } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ServicesService } from '@services/services.service';
import { ObservationService } from '@services/observation.service';
import { ServiceEventService } from '@services/service-event.service';
import { CurrentDataService } from '@services/currentData.service';
import { Service } from '@models/service/service';
import { Observation } from '@models/observation/observation';
import { TreatmentObservationGroup } from '@models/treatment-planning/treatment-observation-group';

@Component({
  selector: 'app-unit-billing',
  templateUrl: './unit-billing.component.html',
  styleUrls: ['./unit-billing.component.less'],
})
export class UnitBillingComponent implements OnInit {
  @Input() service: Service;
  _observations: Observation[];
  @Input() set observations(observations: Observation[]) {
    this._observations = observations;
  }

  get observations() {
    return this._observations;
  }
  @Input() key: string;
  @Input() isLocked = false;

  // editMode = false;
  isServicePaid: boolean;
  unsub = new Subject<any>();
  obrChargeDisplayed = 0;

  constructor(
    private obrService: ObservationService,
    private serviceEventService: ServiceEventService,
    private currentDataService: CurrentDataService,
    private sService: ServicesService
  ) {}

  ngOnInit() {
    this.isServicePaid = this.sService.isPaid;
    this.setObrChargeDisplayed();
    this.obrService.detailsToJson(this.observations);
    this.serviceEventService.recalculateTotal
      .pipe(takeUntil(this.unsub))
      .subscribe((obrGroup: TreatmentObservationGroup) => {
        if (this.key === obrGroup.key) {
          this.observations = obrGroup.observations;
          this.setObrChargeDisplayed();
        }
      });
    this.serviceEventService.observationTotalOverriden$.subscribe(
      (treatmentObservationGroup: TreatmentObservationGroup) => {
        if (treatmentObservationGroup.observations == this.observations) this.isLocked = true;
      }
    );
    this.serviceEventService.observationUnitPriceOverrideCancelled$.subscribe(
      (treatmentObservationGroup: TreatmentObservationGroup) => {
        if (treatmentObservationGroup.observations == this.observations) this.isLocked = false;
      }
    );
  }

  setObrChargeDisplayed(): void {
    let chargeAmount = 0;
    if (this.observations[0].details.isOverrideProductPrice) {
      this.isLocked = false;
      chargeAmount = this.observations[0].details.overrideProductPrice;
    } else if (this.observations[0].details.isOverrideTotalPrice) {
      this.isLocked = true;
      // If it's an overriden total price, just use the overriden total price
      chargeAmount =
        this.observations[0].details.overrideTotalPrice /
        this.observations.map((obs) => +obs.value).reduce((sum, x) => sum + x, 0);
      // chargeAmount = this.observations[0].details.overrideTotalPrice / this.observations.map(obs => obs.details.avaliable + (this.incrementAvailable ? 1 : 0)).reduce((sum,x) => sum + x,0);
    } else {
      chargeAmount = this.observations[0].details.productPrice;
    }
    this.obrChargeDisplayed = +chargeAmount.toFixed(2);
  }

  onOverrideCharge() {
    const obrPrice: number = this.obrChargeDisplayed == null ? 0 : this.obrChargeDisplayed;
    this.observations.forEach((obr: Observation) => {
      obr.details.overrideProductPrice = obrPrice;
      obr.details.isOverrideTotalPrice = false;
      obr.details.isOverrideProductPrice = true;
    });

    // this.editMode = false;
    this.currentDataService.treatmentIsDirty = true;

    const obrGroup = new TreatmentObservationGroup({ key: this.key, observations: this.observations });
    this.serviceEventService.recalculateTotal.next(obrGroup);
    this.serviceEventService.billingObservationsOverridden.next(obrGroup);
    this.serviceEventService.observationUnitPriceOverriden.next(obrGroup);
  }

  hasOverrideCharge(): boolean {
    if (
      this.observations &&
      this.observations[0] &&
      this.observations[0].details &&
      this.observations[0].details.isOverrideProductPrice
    ) {
      return true;
    } else {
      return false;
    }
  }

  // exitEditMode(): void {
  //   if (!this.obrChargeDisplayed) this.obrChargeDisplayed = 0;
  //   this.editMode = false;
  // }
}
