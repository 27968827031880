import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ClinicSupplyType } from '@models/clinic-supply-type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClinicSupplyService {
  constructor(private http: HttpClient) {}

  clinicSupplies: ClinicSupplyType[];

  getAllClinicSupplies(): Observable<ClinicSupplyType[]> {
    return this.http.get<ClinicSupplyType[]>(environment.baseUrl + 'api/ClinicSupplyType').pipe(
      map((clinicSupplies: ClinicSupplyType[]) => {
        this.clinicSupplies = clinicSupplies;
        return clinicSupplies;
      })
    );
  }
}
