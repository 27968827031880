<div class="modal-header border-0 p-0">
  <button
    type="button"
    class="btn white-font p-0 ml-auto mr-2"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <i class="fal fa-times"></i>
  </button>
</div>
<div class="p-4">
  <h6 class="mt-0 mb-4 navy-font text-center uppercase">Add new Credit Card</h6>
  <div mat-dialog-content style="text-align: center">
    <app-error class="d-block mb-3" *ngIf="errorMessage" [message]="errorMessage"></app-error>
    <ngx-loading [show]="loading"></ngx-loading>
    <div id="card-container"></div>
  </div>
  <div class="row mt-2">
    <div class="col d-flex justify-content-center">
      <button class="btn em-btn em-btn-green mr-2" (click)="onAddCardPressed()" [disabled]="!isFormValid">
        Add Card
      </button>
      <button class="btn em-btn em-btn-green" (click)="activeModal.dismiss('Cancel button')">Cancel</button>
    </div>
  </div>
</div>
