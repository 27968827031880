<div class="modal-header border-0 px-2 py-1">
  <button type="button" class="btn white-font p-0 ml-auto" aria-label="Close" (click)="closeModal()">
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="p-4 d-flex flex-column align-items-center">
  <h6 class="mt-0 mb-3 navy-font">QUICK ADD PHYSICIAN</h6>
  <form [formGroup]="physicianForm" class="w-100 mb-2">
    <div class="navy-font d-flex flex-column justify-content-center font-weight-bold form-field">
      <span>First Name: <span class="text-danger">*</span></span>
      <div class="flex-grow-1">
        <mat-form-field [floatLabel]="'never'" class="w-100">
          <input matInput formControlName="firstName">
          <mat-error>Please enter the physician's first name.</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="navy-font d-flex flex-column justify-content-center font-weight-bold form-field">
      <span>Last Name: <span class="text-danger">*</span></span>
      <div class="flex-grow-1">
        <mat-form-field [floatLabel]="'never'" class="w-100">
          <input matInput formControlName="lastName" (input)="validatePhysician( )">
          <mat-error>Please enter the physician's last name.</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="navy-font d-flex flex-column justify-content-center font-weight-bold form-field">
      <span>Fax Number: <span class="text-danger">*</span></span>
      <div class="flex-grow-1">
        <mat-form-field [floatLabel]="'never'" class="w-100">
          <input
            matInput
            formControlName="faxNumber"
            (input)="validatePhysician()"
            (change)="formatterService.formatPhoneNumber(physicianForm.get('faxNumber'))">
          <mat-error *ngIf="physicianForm.get('faxNumber').hasError('required')">
            Please enter the physician's fax number.
          </mat-error>
          <mat-error *ngIf="!physicianForm.get('faxNumber').hasError('required') &&
                            physicianForm.get('faxNumber').hasError('phoneError')">
            Please enter a valid fax number.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="navy-font d-flex flex-column justify-content-center font-weight-bold form-field">
      Physician (Referral) Number:
      <div class="flex-grow-1">
        <mat-form-field [floatLabel]="'never'" class="w-100">
          <input matInput formControlName="provincialIdNumber" (input)="validatePhysician( )">
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="container" *ngIf="possibleDuplicates.length > 0">
    <div class="row">
        <span class="navy-font font-weight-bold">Possible duplicates:</span>
        <ul>
          <li *ngFor="let doctor of possibleDuplicates">
            {{doctor.proTitle}} {{doctor.lastName}}, {{doctor.firstName}} {{doctor.faxNumber ? '(' + doctor.faxNumber +')' : ''}}
          </li>
        </ul>
    </div>
  </div>
  <div class="text-center">
    <button
      type="button"
      class="btn em-btn em-btn-green form-button"
      [disabled]="!physicianForm.valid"
      (click)="physicianFormSubmit()">
        Add
    </button>
    <button
      type="button"
      class="btn em-btn em-btn-green form-button ml-2"
      (click)="closeModal()">
        Cancel
    </button>
  </div>
</div>
    