<ngx-loading [show]="savingInProgress"></ngx-loading>
<div class="h-100" *ngIf="photo">
  <ngx-loading [show]="loading"></ngx-loading>
  <div class="rotation-controls" *ngIf="!disabledRotation && editModeEnabled">
    <!-- <button
      *ngIf="canvasRotation != 0"
      class="btn em-btn py-0 px-2 em-btn-green mr-2" kendoTooltip tooltipclass="em-tooltip" title="Save image rotation"
      (click)="saveChanges()"
    >
      <i class="fas fa-save"></i>
    </button>
    <button
      *ngIf="canvasRotation != 0"
      class="btn em-btn py-0 px-2 em-btn-red mr-2" kendoTooltip tooltipclass="em-tooltip" title="Discard image rotation"
      (click)="discardChanges()"
    >
      <i class="fas fa-ban"></i>
    </button> -->
    <button
      class="btn em-btn py-0 px-2 em-btn-green"
      (click)="rotate(-1)"
      kendoTooltip
      tooltipclass="em-tooltip"
      title="Rotate image left"
    >
      <i class="fas fa-undo"></i>
    </button>
    <button
      class="btn em-btn py-0 px-2 em-btn-green ml-2"
      (click)="rotate(1)"
      kendoTooltip
      tooltipclass="em-tooltip"
      title="Rotate image right"
    >
      <i class="fas fa-redo"></i>
    </button>
    <button
      kendoTooltip
      tooltipClass="em-tooltip"
      position="top"
      [title]="
        filter.isConsult() && filter?.isPatient() ? 'Cannot restore in consult mode' : 'Restore photo to original state'
      "
      *ngIf="photo && filter?.isPatient()"
      class="btn em-btn em-btn-green py-0 px-2 ml-2"
      [disabled]="filter.isConsult() && filter?.isPatient()"
      (click)="restoreToOriginal()"
    >
      <i class="far fa-history"></i>
    </button>
  </div>
  <div class="text-center h-100">
    <ng-container *ngIf="photo && editMode && imageURL; else previewMode">
      <!-- <custom-ngx-image-editor
        [undoEvent]="undoPhotoEdit.asObservable()"
        [config]="photoEditorConfig"
        (file)="getEditedFile($event)"
        (cropped)="imageCropped()">
      </custom-ngx-image-editor> -->
      <div class="h-100">
        <div>
          <image-cropper
            *ngIf="imageURL"
            [imageBase64]="imageURL"
            [maintainAspectRatio]="false"
            [containWithinAspectRatio]="false"
            [aspectRatio]="1 / 1"
            [cropperMinWidth]="128"
            [onlyScaleDown]="true"
            [roundCropper]="false"
            [canvasRotation]="canvasRotation"
            [transform]="transform"
            [style.display]="showCropper ? null : 'none'"
            format="png"
            [disabled]="true"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady($event)"
            (loadImageFailed)="loadImageFailed()"
          ></image-cropper>
        </div>
      </div>
    </ng-container>
    <ng-template #previewMode>
        <app-image-watermark *ngIf="applyWatermark" class="d-block h-100" [photo]="photo" (result)="onWatermarkDone($event)"></app-image-watermark>
        <div *ngIf="!applyWatermark" class="d-flex align-items-center h-100 justify-content-center">
          <img
            #imageElement
            appDetermineMaxScrollHeight
            [itemsToWatch]="[photo]"
            (load)="imageCompletedLoading()"
            (error)="imageCompletedLoading()"
            class="h-auto mh-100 mw-100"
            style="cursor: pointer"
            [src]="photo?.filePath"
            alt="{{ photo?.imageName }}"
            (click)="photoClicked()"
          />
        </div>
    </ng-template>
  </div>
</div>
