import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ChatUser } from '@app/messaging/models/chat-user.model';
import { ChatUsersService } from '@app/messaging/services/chat-users.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '@services/users.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class EditUserComponent implements OnInit, OnDestroy {
  private unsub: Subject<any> = new Subject<any>();
  public loading: boolean;

  users: ChatUser[] = [];
  enteredUserName: string;
  selectedUsers: ChatUser[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private chatUsersService: ChatUsersService,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    this.getAllChatUsers();
  }
  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  getAllChatUsers() {
    this.chatUsersService
      .getAllChatUsers(true)
      .pipe(takeUntil(this.unsub))
      .subscribe((users) => {
        const currUser = this.usersService.loggedInUser;
        const currUserName = currUser.firstName + ' ' + currUser.lastName;
        this.users = users.filter((u) => u.userName !== currUserName);
      });
  }

  selectUser(user) {
    this.selectedUsers.push(user);
    this.users = this.users.filter((u) => u !== user);
  }

  unselectUser(user) {
    this.users.push(user);
    this.selectedUsers = this.selectedUsers.filter((u) => u !== user);
  }

  selectFinished() {
    this.activeModal.close(this.selectedUsers);
  }
}
