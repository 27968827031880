import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-prescription-signature-modal',
  templateUrl: './prescription-signature-modal.component.html',
  styleUrls: ['./prescription-signature-modal.component.less']
})
export class PrescriptionSignatureModalComponent implements OnInit {
  dialog: MatDialog;
  form: any;
  signature: string;

  constructor(
    public dialogRef: MatDialogRef<PrescriptionSignatureModalComponent>,
  ) { }

  ngOnInit(): void {
    this.form = {
      display: 'form',
      components: [
        {
          type: 'signature',
          key: 'signature',
          // label: 'Please sign below:',
          input: true,
          backgroundColor: 'transparent',
          height: '200px',
        },
      ],
    };
  }

  onFormChange(value: any) {
    this.signature = value.data.signature;
  }
}
