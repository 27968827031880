import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PhotoFilter } from '../../../../photos/photo-filter';

@Component({
  selector: 'app-photo-lightbox',
  templateUrl: './photo-lightbox.component.html',
  styleUrls: ['./photo-lightbox.component.less']
})
export class PhotoLightboxComponent implements OnInit {

  filter: PhotoFilter;
  photoURL: string;
  loading = false;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.filter){
      if (event.key === 'ArrowLeft') {
        this.incrementPhoto(-1);
      }
      else if (event.key === 'ArrowRight') {
        this.incrementPhoto(1);
      }
    }
  }

  constructor(
    public dialogRef: MatDialogRef<PhotoLightboxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.filter = this.data.filter;
    this.photoURL = this.data.photoURL;
  }

  ngOnInit() {

  }

  incrementPhoto(increment: 1|-1){
    this.loading = true;
    this.filter?.incrementSelectedPhoto$.next(increment);
  }

  public closeLightBox() {
    this.dialogRef.close();
  }



}
