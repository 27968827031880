import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ObservationService } from '@services/observation.service';
import { ServiceEventService } from '@services/service-event.service';
import { CurrentDataService } from '@services/currentData.service';
import { ServicesService } from '@services/services.service';
import { Service } from '@models/service/service';
import { Observation } from '@models/observation/observation';
import { TreatmentObservationGroup } from '@models/treatment-planning/treatment-observation-group';

@Component({
  selector: 'app-observation-billing',
  templateUrl: './observation-billing.component.html',
  styleUrls: ['./observation-billing.component.less'],
})
export class ObservationBillingComponent implements OnInit, OnDestroy {
  @Input() service: Service;
  @Input() observations: Observation[];
  @Input() key: string;
  @Input() isLocked = false;

  isServicePaid: boolean;
  // editMode = false;
  unsub = new Subject<any>();
  obrChargeDisplayed = 0;

  constructor(
    private obrService: ObservationService,
    private serviceEventService: ServiceEventService,
    private currentDataService: CurrentDataService,
    private sService: ServicesService
  ) {}

  ngOnInit() {
    this.isServicePaid = this.sService.isPaid;
    this.setObrChargeDisplayed();
    this.obrService.detailsToJson(this.observations);
    this.serviceEventService.treatmentSaved$.subscribe((data) => {
      this.observations = data.service.observations;
      if (!this.hasCharge()) {
        this.saveObrChargeAmount();
      }
    });

    this.serviceEventService.observationUnitPriceOverriden$.subscribe(
      (treatmentObservationGroup: TreatmentObservationGroup) => {
        if (treatmentObservationGroup.observations == this.observations) this.isLocked = true;
      }
    );
    this.serviceEventService.observationTotalOverrideCancelled$.subscribe(
      (treatmentObservationGroup: TreatmentObservationGroup) => {
        if (treatmentObservationGroup.observations == this.observations) this.isLocked = false;
      }
    );
    this.serviceEventService.recalculateObservationBilling
      .pipe(takeUntil(this.unsub))
      .subscribe((obrGroup: TreatmentObservationGroup) => {
        // If it's an existing Group, we will recalculate the total for the group
        if (this.key === obrGroup.key) {
          this.observations = obrGroup.observations;
          if (obrGroup.observations[0].details.isOverrideTotalPrice) {
            const totalPrice = obrGroup.observations[0].details.overrideTotalPrice;
            this.observations.forEach((obr) => {
              obr.details.overrideTotalPrice = totalPrice;
              obr.details.isOverrideTotalPrice = true;
            });
            const group = new TreatmentObservationGroup({ key: this.key, observations: this.observations });
            this.serviceEventService.billingObservationsOverridden.next(group);
          } else if (obrGroup.observations[0].details.isOverrideProductPrice) {
            const obrPrice = obrGroup.observations[0].details.overrideProductPrice;
            this.observations.forEach((obr) => {
              obr.details.overrideProductPrice = obrPrice;
              obr.details.isOverrideProductPrice = true;
            });
            const group = new TreatmentObservationGroup({ key: this.key, observations: this.observations });
            this.setObrChargeDisplayed();
            this.serviceEventService.billingObservationsOverridden.next(group);
          } else {
            this.setObrChargeDisplayed();
          }
        }
      });

    this.serviceEventService.recalculateTotal
      .pipe(takeUntil(this.unsub))
      .subscribe((obrGroup: TreatmentObservationGroup) => {
        if (this.key === obrGroup.key) {
          this.observations = obrGroup.observations;
          this.setObrChargeDisplayed();
        }
      });
  }

  setObrChargeDisplayed(): void {
    let chargeAmount = 0;
    this.observations.forEach((obrInit) => {
      const obr = new Observation(obrInit);
      if (obr.details.isOverrideProductPrice) {
        this.isLocked = true;
        chargeAmount += obr.getChargeAmount();
      } else if (obr.details.isOverrideTotalPrice) {
        this.isLocked = false;
        // If it's an overriden total price, just use the overriden total price
        chargeAmount = obr.details.overrideTotalPrice;
      } else {
        chargeAmount += obr.getChargeAmount();
      }
    });
    this.obrChargeDisplayed = +chargeAmount.toFixed(2);
  }

  saveObrChargeAmount(): void {
    let obrProductPrice: number;
    if (this.obrChargeDisplayed != null) {
      const totalObrVal = this.observations.reduce((sum, item) => sum + Number(item.value), 0);
      obrProductPrice = +(this.obrChargeDisplayed / totalObrVal).toFixed(2);
    } else {
      obrProductPrice = null;
    }

    const obrsToSave: Observation[] = [];
    this.observations.forEach((obr: Observation) => {
      obr.details.productPrice = obrProductPrice;
      obrsToSave.push(JSON.parse(JSON.stringify(obr)));
    });

    this.obrService
      .addObservations(obrsToSave, obrsToSave && obrsToSave.length > 0 ? obrsToSave[0].serviceId : 0)
      .subscribe();
  }

  onOverrideCharge(): void {
    const obrTotalPrice: number = this.obrChargeDisplayed == null ? 0 : this.obrChargeDisplayed;
    this.observations.forEach((obr: Observation) => {
      obr.details.overrideTotalPrice = obrTotalPrice;
      obr.details.isOverrideTotalPrice = true;
      obr.details.isOverrideProductPrice = false;
      // obr.details.overrideProductPrice = 0; // null out the product price when we override the total
    });

    this.setObrChargeDisplayed();
    this.currentDataService.treatmentIsDirty = true;
    // this.editMode = false;
    const obrGroup = new TreatmentObservationGroup({ key: this.key, observations: this.observations });
    this.serviceEventService.recalculateTotal.next(obrGroup);
    this.serviceEventService.billingObservationsOverridden.next(obrGroup);
    this.serviceEventService.observationTotalOverriden.next(obrGroup);
  }

  // exitEditMode(): void {
  //   if (!this.obrChargeDisplayed) this.obrChargeDisplayed = 0;
  //   this.editMode = false;
  // }

  hasCharge(): boolean {
    if (
      this.observations &&
      this.observations[0] &&
      this.observations[0].details &&
      this.observations[0].details.productPrice
    ) {
      return true;
    } else {
      return false;
    }
  }

  hasOverrideCharge(): boolean {
    if (
      this.observations &&
      this.observations[0] &&
      this.observations[0].details &&
      this.observations[0].details.isOverrideTotalPrice
    ) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
