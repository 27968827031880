import { ClinicReportDetailsViewModel } from '@services/reports.service';
import { Router } from '@angular/router';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReportsFilterModel } from '@models/reports/reports-filter-model';
import { ServiceProvider } from '@models/service-provider';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { numberSymbols } from '@progress/kendo-angular-intl';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { PatientService } from '@services/patient.service';
import { ProviderDetailsReportEntity, ReportsService } from '@services/reports.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
 
@Component({
  selector: 'app-clinic-tax-details',
  templateUrl: './clinic-tax-details.component.html',
  styleUrls: ['./clinic-tax-details.component.less']
})
export class ClinicTaxDetailsComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  unsub = new Subject<any>();
  providerDetails: ClinicReportDetailsViewModel[] = [];

   
  @Input() summaryTitle: string; 

 
  @Input() gridState: DataSourceRequestState; 
  @Input() set dataItem(dataItem){
    console.log(dataItem);
  }

  constructor(
    private router: Router,
    private reportsService: ReportsService,
    private patientService: PatientService
  ) {
   
  }

  async ngOnInit() { 
   this.reportsService.getClinicTaxReportDetails(this.summaryTitle, this.gridState ).toPromise().catch(e => {
      this.loading = false;
      return [];
    }).then(providerDetails => {
      this.providerDetails  = providerDetails; 
      this.loading = false;
    });
  }

  navigateToPatientInvoice(patientId: number, invoiceId: number) {
    this.patientService.getPatientById(patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((patient: any) => {
        this.patientService.patientPanelPatient = patient;
        this.router.navigate(['/reports', { outlets: { 'action-panel': ['patient', patientId + '_patientprofiletab', 'patienttabs', 'patientaccounttab', 'invoice', invoiceId] } }]);
      });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
