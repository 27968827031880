import { Location } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChildren,
  QueryList,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ServicesService } from '@services/services.service';
import { VisitService } from '@services/visit.service';
import { ServiceEventService } from '@services/service-event.service';
import { CurrentDataService } from '@services/currentData.service';
import { ObservationService } from '@services/observation.service';
import { Observation } from '@models/observation/observation';
import { TreatmentObservationGroup } from '@models/treatment-planning/treatment-observation-group';
import { Visit } from '@models/visit';
import { Service } from '@models/service/service';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { TreatmentType } from '@models/treatment-type';

@Component({
  selector: 'app-treatment-billing',
  templateUrl: './treatment-billing.component.html',
  styleUrls: ['./treatment-billing.component.less'],
})
export class TreatmentBillingComponent implements OnInit, OnDestroy {
  @Input() service: Service;
  @ViewChildren('serviceChargeDisplayedInput') serviceChargeDisplayedInput: QueryList<ElementRef>;
  editMode = false;
  isServicePaid: boolean;
  serviceChargeDisplayed: number;
  unsub = new Subject<any>();
  @Output() sendChargeAmount = new EventEmitter<number>();

  public serviceDetailTemplate = ServiceDetailTemplate;

  constructor(
    private servicesService: ServicesService,
    private visitService: VisitService,
    private serviceEventService: ServiceEventService,
    private currentDataService: CurrentDataService,
    private observationService: ObservationService,
    private location: Location
  ) {}

  ngOnInit() {
    this.isServicePaid = this.servicesService.isPaid;
    this.service = new Service(this.service);
    this.serviceChargeDisplayed = this.service.getChargeAmount();
    this.serviceEventService.recalculateTreatmentBilling
      .pipe(takeUntil(this.unsub))
      .subscribe((obrs: Observation[]) => {
        this.service.observations = obrs;
        this.calculateChargeAmount();
      });

    this.serviceEventService.treatmentObservationsGroupDeleted
      .pipe(takeUntil(this.unsub))
      .subscribe((obrGroupKey: string) => {
        this.service.observations = this.service.observations.filter(
          (obr) => {
            let obrKey = this.observationService.getObrKey(obr)
            if (obr.name === TreatmentType.CoolTone) {
              obrKey = obrKey.split('/')[0];
            }
            return obrKey !== obrGroupKey
          }
        );
        this.calculateChargeAmount();
      });

    this.serviceEventService.billingObservationsOverridden
      .pipe(takeUntil(this.unsub))
      .subscribe((obrGroup: TreatmentObservationGroup) => {
        this.service.observations.forEach((obr: Observation) => {
          if (this.observationService.getObrKey(obr) === obrGroup.key) {
            obr.details.overrideProductPrice = obrGroup.observations[0].details.overrideProductPrice;
            obr.details.overrideTotalPrice = obrGroup.observations[0].details.overrideTotalPrice;
            obr.details.isOverrideTotalPrice = obrGroup.observations[0].details.isOverrideTotalPrice;
          }
        });
        this.calculateChargeAmount();
      });

    this.serviceEventService.treatmentSaved$
      .pipe(takeUntil(this.unsub))
      .subscribe((data: { service: Service; isClosed: boolean }) => {
        this.service.chargeAmount = this.serviceChargeDisplayed;
        this.service.servicePhotoPath = data.service.servicePhotoPath;
        this.currentDataService.currentReplayDataHasBeenUpdated({ serviceChartPriceUpdate: this.service });
        this.servicesService.updateService(this.service).subscribe(() => {
          this.visitService.getVisitByServiceId(this.service.serviceId).subscribe((visit: Visit) => {
            if (visit != null) {
              visit = new Visit(visit);
              visit.chargeAmount = null;
              visit.chargeAmount = visit.getChargeAmount();
              this.visitService.updateVisit(visit).subscribe(() => {
                this.currentDataService.setPreventUserFromNavigation(false);
                if (data.isClosed) {
                  this.location.back();
                }
              });
            } else {
              this.currentDataService.setPreventUserFromNavigation(false);
              if (data.isClosed) {
                this.location.back();
              }
            }
          });
        });
      });
  }

  calculateChargeAmount() {
    if (!(this.service instanceof Service)) {
      this.service = new Service(this.service);
    }
    // Group the observations so the charge amount is calculated properly for overriden values for the Totals
    const originalObservations = this.service.observations;
    const groupedObservations: Observation[] = this.getGroupedObservations(originalObservations);
    this.service.observations = groupedObservations;
    this.service.chargeAmount = null;
    this.serviceChargeDisplayed = this.service.getChargeAmount();
    // Set it back to what it was supposed to be after the calculations are done for the Totals.
    this.service.observations = originalObservations;
    this.sendChargeAmount.emit(this.serviceChargeDisplayed);
  }

  getGroupedObservations(obrs: Observation[]): Observation[] {
    const groupedMap: Map<string, Observation> = new Map();
    const grouping: Observation[] = [];
    obrs.forEach((observation) => {
      const key = this.observationService.getObrKey(observation);
      if (observation.details.isOverrideTotalPrice) {
        // Check if it exists in the grouped collection already; if not add it
        if (!groupedMap.has(key)) {
          groupedMap.set(key, observation);
          grouping.push(observation);
        }
      } else {
        grouping.push(observation);
      }
    });
    // return the grouped observations
    return grouping;
  }

  exitEditMode(): void {
    this.editMode = false;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
