import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Product } from '@models/product';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) { }

  addProduct(product: Product) {
    return this.http.post<Product>(environment.baseUrl + 'api/Products', product);
  }

  updateProduct(product: Product) {
    return this.http.put<void>(environment.baseUrl + 'api/Products/' + product.id, product);
  }

  deleteProduct(product: Product) {
    return this.http.delete(environment.baseUrl + 'api/Products/' + product.id);
  }

  getProducts() {
    return this.http.get<Product[]>(environment.baseUrl + 'api/Products');
  }

  getProductById(productId) {
    return this.http.get<Product>(environment.baseUrl + 'api/Products/' + productId);
  }
}
