import { isNullOrUndefined } from '@app/shared/helpers';

export class ColourVariables {
  // IMPORTANT: These colours exist in colour-variables.less as well and are used in Startup.cs to seed the service colours. If you update
  // The colours here you need to update them in those places too

  public white = '#FFFFFF';

  /** Greys **/
  public grey_one = '#F7FBFC';
  public grey_two = '#F0F0F0';
  public grey_three = '#EBEBEB';
  public grey_four = '#DEDEDE';
  public grey_five = '#C9C9C9';
  public grey_six = '#B0B0B0';
  public grey_seven = '#F7F7F7';
  public grey_eight = '#E3E3E3';

  /* Core Colours*/
  public lime_green = '#C6E170';
  public spring_green = '#92C46D';
  public spring_green_light = '#E9F3E2';
  public teal = '#297D7D';
  public teal_light = '#C5ECEC';
  public navy = '#1C344E';
  public navy_dark = '#142639';
  public black_custom = '#252F38';

  /* Calendar */
  public calendar_red = '#DC4E41';
  public calendar_blue = '#0071B9';
  public calendar_green = '#8BC461';

  /* Global */
  public blue = '#29A8DF';
  public blue_light = '#F4FBFE';
  public orange = '#FF7F00';
  public orange_light = '#F8EBDF';
  public pink = '#D1145A';
  public pink_light = '#FDF3F6';
  public turquoise = '#00A69A';
  public turquoise_light = '#F2FBFA';
  public goldenrod = '#F8AD3B';
  public goldenrod_light = '#FFFBF5';
  public purple = '#662D8E';
  public purple_light = '#F7F4F9';
  public electric_purple = '#BD08FF';
  public electric_purple_light = '#F2CDFF';
  public pelorous = '#2592BA';
  public pelorous_light = '#D3E9F1';
  public pastel_red = '#FD6A6A';
  public pastel_red_light = '#FFE1E1';
  public jordy_blue = '#88A3FF';
  public jordy_blue_light = '#E7EDFF';
  public tickle_me_pink = '#FF88B4';
  public tickle_me_pink_light = '#FFE7F0';
  public barney = '#B5139C';
  public barney_light = '#F8E7F5';
  public lagoon = '#3BDBC9';
  public lagoon_light = '#EBFCFA';
  public teardrop = '#4680C4';
  public teardrop_light = '#ECF2F9';
  public diva = '#DB4DB5';
  public diva_light = '#FCEDE8';
  public sahara_sun = '#E3552A';
  public sahara_sun_light = '#FCEDE9';
  public deep_blue_sea = '#2E4F9A';
  public deep_blue_sea_light = '#E9EDF5';
  public cherry_tomato = '#E20000';
  public cherry_tomato_light = '#FCE5E5';
  public sage = '#94AF7E';
  public sage_light = '#F4F7F2';
  public petal_pink = '#EF9191';
  public petal_pink_light = '#FEF4F4';
  public yukon_gold = '#E6C75D';
  public yukon_gold_light = '#FDF9EE';
  public ice_blue = '#00BAFF';
  public ice_blue_light = '#E5F8FF';
  public fusia = '#FF08B3';
  public fusia_light = '#FFE5F7';
  public peach_fuzz = '#FFBA88';
  public peach_fuzz_light = '#FFF8F3';
  public thunderstorm = '#93A2C3';
  public thunderstorm_light = '#F4F6F9';
  public cactus_flower = '#FF365D';
  public cactus_flower_light = '#FFEFEE';
  public mustard = '#E3CA00';
  public mustard_light = '#FCFAE5';
  public succulent_green = '#6EBFB0';
  public succulent_green_light = '#F0F9F7';
  public crimson = '#BC1212';
  public crimson_light = '#F8E6E6';
  public oceanspray = '#006A91';
  public oceanspray_light = '#E5F0F4';
  public babydoll = '#FF4082';
  public babydoll_light = '#FFEBF2';

  public colourMappings = new Map([
    [this.purple, this.purple_light],
    [this.goldenrod, this.goldenrod_light],
    [this.turquoise, this.turquoise_light],
    [this.pink, this.pink_light],
    [this.orange, this.orange_light],
    [this.blue, this.blue_light],
    [this.electric_purple, this.electric_purple_light],
    [this.pelorous, this.pelorous_light],
    [this.pastel_red, this.pastel_red_light],
    [this.jordy_blue, this.jordy_blue_light],
    [this.tickle_me_pink, this.tickle_me_pink_light],
    [this.spring_green, this.spring_green_light],
    [this.teal, this.teal_light],
    [this.barney, this.barney_light],
    [this.lagoon, this.lagoon_light],
    [this.teardrop, this.teardrop_light],
    [this.diva, this.diva_light],
    [this.sahara_sun, this.sahara_sun_light],
    [this.deep_blue_sea, this.deep_blue_sea_light],
    [this.cherry_tomato, this.cherry_tomato_light],
    [this.sage, this.sage_light],
    [this.petal_pink, this.petal_pink_light],
    [this.yukon_gold, this.yukon_gold_light],
    [this.ice_blue, this.ice_blue_light],
    [this.fusia, this.fusia_light],
    [this.peach_fuzz, this.peach_fuzz_light],
    [this.thunderstorm, this.thunderstorm_light],
    [this.cactus_flower, this.cactus_flower_light],
    [this.mustard, this.mustard_light],
    [this.succulent_green, this.succulent_green_light],
    [this.crimson, this.crimson_light],
    [this.oceanspray, this.oceanspray_light],
    [this.babydoll, this.babydoll_light],
  ]);

  public textMappings = new Map([
    [this.purple, 'Purple'],
    [this.goldenrod, 'Goldenrod'],
    [this.turquoise, 'Turquoise'],
    [this.pink, 'Pink'],
    [this.orange, 'Orange'],
    [this.blue, 'Blue'],
    [this.electric_purple, 'Electric Purple'],
    [this.pelorous, 'Pelorous'],
    [this.pastel_red, 'Pastel Red'],
    [this.jordy_blue, 'Jordy Blue'],
    [this.tickle_me_pink, 'Tickle Me Pink'],
    [this.spring_green, 'Spring Green'],
    [this.teal, 'Teal'],
    [this.barney, 'Barney'],
    [this.lagoon, 'Lagoon'],
    [this.teardrop, 'Teardrop'],
    [this.diva, 'Diva'],
    [this.sahara_sun, 'Sahara Sun'],
    [this.deep_blue_sea, 'Deep Blue Sea'],
    [this.cherry_tomato, 'Cherry Tomato'],
    [this.sage, 'Sage'],
    [this.petal_pink, 'Petal Pink'],
    [this.yukon_gold, 'Yukon Gold'],
    [this.ice_blue, 'Ice Blue'],
    [this.fusia, 'Fusia'],
    [this.peach_fuzz, 'Peach Fuzz'],
    [this.thunderstorm, 'Thunderstorm'],
    [this.cactus_flower, 'Cactus Flower'],
    [this.mustard, 'Mustard'],
    [this.succulent_green, 'Succulent Green'],
    [this.crimson, 'Crimson'],
    [this.oceanspray, 'Oceanspray'],
    [this.babydoll, 'Babydoll'],
  ]);

  getColourList(): string[] {
    return Array.from(this.colourMappings.keys());
  }

  getCoolsculptingColourList(): string[] {
    const coolColours: string[] = [
      this.purple,
      this.goldenrod,
      this.turquoise,
      this.pink,
      this.orange,
      this.blue,
      this.electric_purple,
      this.pelorous,
      this.pastel_red,
      this.jordy_blue,
      this.tickle_me_pink,
      this.spring_green,
      this.teal,
    ];
    return coolColours;
  }

  getColourLightMapping(color: string): string {
    if (!isNullOrUndefined(this.colourMappings.get(color))) {
      return this.colourMappings.get(color);
    }
    // if there is no mapping return null so that we know that the caller has some work to do
    return null;
  }

  getDefaultForegroundColour(): string {
    return this.purple;
  }

  getDefaultBackgroundColour(): string {
    return this.purple_light;
  }

  colourExists(color: string): boolean {
    return !isNullOrUndefined(this.colourMappings.get(color));
  }
}
