import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { SignalrBase } from '@services/signalr-base';
import { SignalrHttpClientService } from '@services/signalr-http-client.service';
import { ChatChannelNotificationsCount } from '../models/chat-channel-notifications-count.model';
import { ChatChannel } from '../models/chat-channel.model';
import { ChatMessageFromClient } from '../models/chat-message-from-client.model';
import { ChatMessageFromServer } from '../models/chat-message-from-server.model';
import { ChatUser } from '../models/chat-user.model';
import { ChatChannelsService } from './chat-channels.service';
import { ChatMessagesService } from './chat-messages.service';
import { ChatUsersService } from './chat-users.service';
import { Policies } from '@app/auth/auth-policies';

@Injectable({
  providedIn: 'root',
})
export class MessagingSignalrService extends SignalrBase<any> {
  constructor(
    authService: AuthService,
    private channelsService: ChatChannelsService,
    private messagesService: ChatMessagesService,
    private userService: ChatUsersService,
    private signalrHttpClientService: SignalrHttpClientService
  ) {
    super('Messaging', Policies.empty, authService, signalrHttpClientService);
    this.registerServerEvents();
  }

  private registerServerEvents(): void {
    super.registerServerEvent('NewChannelFromServer', (channel: ChatChannel) => {
      this.channelsService.addChannel(channel);
    });

    super.registerServerEvent('ChannelClosedFromServer', (channelId: number) => {
      this.channelsService.channelClosed(channelId);
    });

    super.registerServerEvent('NewMessageFromServer', (message: ChatMessageFromServer) => {
      this.messagesService.addMessage(message);
    });

    super.registerServerEvent('NewUserFromServer', (user: ChatUser) => {
      this.userService.addOrUpdateUser(user);
    });

    super.registerServerEvent('UpdateUserStatusFromServer', (user: ChatUser) => {
      this.userService.addOrUpdateUser(user);
    });

    super.registerServerEvent('UpdateNotificationCountFromServer', (counts: ChatChannelNotificationsCount[]) => {
      this.channelsService.setChannelsNotificationsCounts(counts);
    });
  }

  sendMessage(message: ChatMessageFromClient) {
    this._hubConnection.invoke('ChatMessageFromClient', message);
  }
}
