import { Component, OnInit } from '@angular/core';
import { Policies } from '@app/auth/auth-policies';

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.less'],
})
export class CatalogueComponent implements OnInit {
  developerPolicy = Policies.developer;
  constructor() {}

  ngOnInit() {}
}
