<ngx-loading [show]="loading"></ngx-loading>

<div class="modal-header border-0 p-0">
  <button type="button" class="btn white-font p-0 ml-auto mr-2" aria-label="Close" (click)="dialogRef.close()">
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="container p-0 pb-3">
  <div class="search-container navy-background justify-content-center products-container">
    <h6 class="modal-title text-uppercase white-font text-center mb-4">Add Products</h6>
    <div class="input-group pb-2">
      <div class="input-group-prepend">
        <div class="input-group-text border-radius grey-three-background border-0">
          <i class="fa fa-search"></i>
        </div>
      </div>
      <input
        [formControl]="searchControl"
        type="text"
        name="docSearch"
        class="form-control border-0 grey-three-background"
        placeholder="Search"
      />
    </div>
  </div>

  <div class="products-container" style="height: 440px">
    <perfect-scrollbar>
      <mat-accordion multi="false">
        <section [formGroup]="productsForm">
          <ng-container
            formArrayName="brandGroups"
            *ngFor="let brandGroup of brandGroups.controls; let brandIdx = index"
          >
            <mat-expansion-panel *ngIf="brandProducts(brandIdx).length > 0" class="my-1">
              <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                <mat-panel-title>
                  {{ brandGroup.get('brandName').value }}
                </mat-panel-title>
                <mat-icon>arrow_drop_up</mat-icon>
              </mat-expansion-panel-header>
              <div
                *ngFor="let product of brandProducts(brandIdx).controls; let prodIdx = index"
                class="product-item"
                [ngClass]="{ 'product-added': product.get('selected').disabled }"
              >
                <div [class]="product.get('selected').disabled ? 'grey-five-font' : 'navy-font'">
                  {{ product.get('name').value }}
                </div>
                <mat-checkbox class="calendar-green-font" [formControl]="$any(product.get('selected'))"></mat-checkbox>
              </div>
            </mat-expansion-panel>
          </ng-container>
        </section>
      </mat-accordion>
    </perfect-scrollbar>
  </div>

  <mat-error class="my-1 text-center" *ngIf="errorMessage">{{ errorMessage }}</mat-error>
  <div class="save-changes-container my-3 text-center">
    <button class="btn em-btn em-btn-green mr-2" (click)="onSave()">Add</button>
    <button class="btn em-btn em-btn-green" (click)="dialogRef.close()">Cancel</button>
  </div>
</div>
