import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { PhotoProcessingOperation } from '@models/photo/photo-processing';
import { ImageService } from '@services/image.service';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PhotoFilter } from '../photo-filter';
import { GenericPhotoService } from '../services/generic-photo.service';
import { PhotoLightboxComponent } from './../../patients/patient-tabs/shared-photos/photo-lightbox/photo-lightbox.component';

@Component({
  selector: 'app-generic-photo-display',
  templateUrl: './generic-photo-display.component.html',
  styleUrls: ['./generic-photo-display.component.less'],
})
export class GenericPhotoDisplayComponent implements OnInit, OnDestroy, OnChanges {
  @Input() applyWatermark: boolean;
  @Input() photos: PhotoMetaData[];
  @Output() applyWatermarkChange = new EventEmitter<boolean>();
  @Input() filter: PhotoFilter;
  private _photo: PhotoMetaData;
  @Input()
  set photo(photo: PhotoMetaData) {
    if (this._photo && photo && (this._photo.id != photo.id || this._photo.filePath != photo.filePath)) {
      this.loading = true;
      this.filter.selectedPhotoDimensions = '...';
    }
    this._photo = photo;
    this.imageURL = null;
    this.resetCropOptions();
    if (this.applyWatermark) {
      this.applyWatermark = false;
      this.applyWatermarkChange.emit(false);
    }
  }
  get photo(): PhotoMetaData {
    return this._photo;
  }
  @Input() disabledRotation: boolean = false;
  private _editModeEnabled: boolean;
  @Input()
  set editModeEnabled(value: boolean) {
    if (this.editModeEnabled && !value) this.discardChanges();
    this._editModeEnabled = value;
  }
  get editModeEnabled(): boolean {
    return this._editModeEnabled;
  }

  @Output() photoUpdated = new EventEmitter<PhotoMetaData>();

  private _canvasRotation = 0;
  set canvasRotation(val: number) {
    while (val < 0) val = val + 4;
    this._canvasRotation = val % 4;
  }
  get canvasRotation() {
    return this._canvasRotation;
  }

  @ViewChild('imageElement') imageElement: any;
  editMode: boolean = false;
  loading: boolean = false;
  savingInProgress: boolean = false;
  transform: ImageTransform = {};
  loaded: boolean;
  showCropper: boolean;
  imageURL: any;
  croppedImage: string = '';
  unsub: Subject<void> = new Subject<void>();

  constructor(
    private imageService: ImageService,
    private photoService: GenericPhotoService,
    private dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }

  ngOnInit(): void {
    this.photoService.photoSaved$.subscribe({
      next: (saved) => {
        if (saved && this.canvasRotation % 4 != 0) {
          this.saveChanges();
        } else this.discardChanges();
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['photo'] &&
      changes['photo'].currentValue &&
      changes['photo'].currentValue.id &&
      this.photo &&
      this.photo.id != changes['photo'].currentValue.id
    ) {
      //this.loading = true;
    }
  }

  toDataURL = (url) => {
    return fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );
  };

  photoClicked(): void {
    this.dialog.open(PhotoLightboxComponent, {
      panelClass: 'lightbox-dialog',
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      data: {
        filter: this.filter,
      },
    });
  }

  public async rotate(inc: number) {
    this.loading = true;
    await this.enableEditMode();
    this.canvasRotation += inc;
    console.log('canvas: %d\n%4: %d', this.canvasRotation, this.canvasRotation % 4);
    this.flipAfterRotate();
    this.photoService.photoEdited$.next(this.canvasRotation % 4 != 0);
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  resetCropOptions() {
    this.transform = {};
    this.canvasRotation = 0;
  }

  imageCropped(event: ImageCroppedEvent) {
    //this.photoEditingService.photoCropped();
    this.croppedImage = event.base64;
    //this.photoEditingService.fileChanged(file);
  }

  public cropperReady(sourceImageDimensions: Dimensions) {}

  public imageLoaded() {
    this.showCropper = true;
    this.loading = false;
  }

  loadImageFailed() {
    console.error('image-cropper could not load image');
    this.loaded = false;
    this.resetCropOptions();
    this.editMode = false;
    this.loading = false;
  }

  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  async enableEditMode() {
    if (this.editMode) return;
    if (this.photo != null) {
      const dataUrl = await this.toDataURL(this.photo.filePath);
      this.loaded = true;
      this.imageURL = dataUrl;
      this.editMode = true;
    }
  }

  restoreToOriginal() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '330px',
      data: {
        title: 'Confirm Revert to Original',
        content:
          "Are you sure you wish to revert this photo to it's original state? This will discard all changes made to the photo.",
        confirmButtonText: 'Yes',
        showCancel: true,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') {
          this.loading = true;
          this.photoService
            .restoreToOriginal(this.photo)
            .pipe(takeUntil(this.unsub))
            .subscribe({
              next: (res) => {
                this.photoUpdated.emit(res);
                this.loading = false;
              },
              error: (error) => {
                console.error(error);
                this.loading = false;
              },
            });
        }
      });
  }
  /*
  saveChanges() {
    this.savingInProgress = true;
    const file = this.dataURLtoFile(this.croppedImage, this.photo.fileId);
    // Find the folder of the file
    //const folder = this.patientService.getPatientPanelPatientContainerFolderName(); // Only works for patient photos
    const foundPath = this.photo.filePath.match(/patients\/photos\/([^\/]+\/[^\/]+)/);
    if (!foundPath) {
      console.error('Photo path not found, cannot update.');
      this.savingInProgress = false;
      return;
    }
    const folder = foundPath[1];
    this.photo.patientContainerFolderName = folder;
    this.imageService.uploadPatientPhoto(file, this.photo, { createOriginal: false }).subscribe({
      next: metaData => {
        this.editMode = false;
        this.photo = metaData;
        this.discardChanges();
        this.photoUpdated.emit(this.photo);
        this.savingInProgress = false;
      },
      error: error => {
        console.error(error);
        this.savingInProgress = false;
      }
    });
  }
  */
  saveChanges() {
    this.savingInProgress = true;
    this.photoService
      .rotatePhoto(this.photo, this.canvasRotation % 4)
      .pipe(take(1))
      .subscribe({
        next: (photo) => {
          this.photoUpdated.emit(photo);
          this.photo = photo;
        },
        error: (error) => {
          console.error(error);
          this.savingInProgress = false;
        },
      });
    // This forces the image to refresh, without sometimes the unrotated unrotated image is shown
    // this.photo = new PhotoMetaData({ filePath: '/assets/profile/noprofile.jpg', });
    this.editMode = false;
    this.discardChanges();
  }

  imageCompletedLoading() {
    this.loading = false;
    this.savingInProgress = false;
    const img = this.imageElement.nativeElement as HTMLImageElement;
    this.filter.selectedPhotoDimensions = `${img.naturalWidth}x${img.naturalHeight}`;
  }

  discardChanges() {
    this.imageURL = null;
    this.editMode = false;
    this.resetCropOptions();
  }

  onWatermarkDone(result: PhotoMetaData) {
    if (this.filter && result) {
      this.filter.selectedPhoto = result;
      this.photoService.photoSaved$.next(true);
    }
    this.applyWatermark = false;
    this.applyWatermarkChange.emit(false);
  }
}
