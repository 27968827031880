<div class="panel-item-border">
  <mat-accordion kendoTooltip tooltipClass="em-tooltip" position="top">
    <mat-expansion-panel
      class="pb-2"
      [expanded]="true"
      [disabled]="true"
      [ngStyle]="{ 'max-height': panelHeight ? panelHeight + 'px' : 'inherit' }"
      #panel
    >
      <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
        <mat-panel-title>
          <div class="d-flex align-items-center w-100">
            <div class="column-header py-0 mr-2">Square Cards on File</div>
            <button type="button" class="d-flex btn navy-font p-0 ml-auto" (click)="panel.expanded = !panel.expanded">
              <mat-icon title="Toggle Expansion">{{ panel.expanded ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
            </button>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <perfect-scrollbar [ngStyle]="{ 'max-height': panelHeight ? panelHeight - 48 + 'px' : 'inherit' }">
        <div class="row">
          <ng-container *ngFor="let card of customer?.cards; index as i">
            <div class="col-md-6 col d-flex mb-3 py-2 square-card-container">
              <app-square-card-on-file class="square-card mx-auto" [card]="card"></app-square-card-on-file>
            </div>
          </ng-container>
        </div>
      </perfect-scrollbar>
    </mat-expansion-panel>
  </mat-accordion>
</div>
