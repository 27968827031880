<div class="px-3 py-4 navy-dark-background">
  <div class="row mb-2">
    <div class="col">
      <p class="white-font font-weight-bold text-uppercase bigger my-0">{{clinic?.name}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <p class="bigger d-flex align-items-center my-0">
        <i class="fas fa-circle spring-green-font smaller pr-2"></i>
        <span class="white-font">{{currentUser?.firstName}} {{currentUser?.lastName}}</span>
      </p>
    </div>
  </div>
</div>
