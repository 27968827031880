import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class CryptoHelperService {
  encrypt(message: string): CryptoData {
    // server known value to decrypt new password
    const key = CryptoJS.enc.Utf8.parse('920773c26ca9b690');
    const iv = CryptoJS.enc.Utf8.parse('1c939001c7444759');
    const cfg = {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    };

    const cipherParams = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(message), key, cfg);

    return {
      ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
      iv: cipherParams.iv.toString(),
    };
  }
}

export interface CryptoData {
  ct: string;
  iv?: string;
  s?: string;
}


