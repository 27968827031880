<ngx-loading [show]="loading"></ngx-loading>

<ng-container *ngIf="!loading">
  <div class="edit-container position-absolute d-block z-index-3 white-background">
    <perfect-scrollbar style="max-width: 100%; top: 0; left: 0;">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row mx-0 teal-background">
          <div class="col px-2">
            <h6 class="white-font uppercase p-2 mb-0">
              Update {{billingSettings?.clinic.name}} Billing Settings
            </h6>
          </div>
        </div>
        <div class="row mx-0 pt-1">
          <div class="col px-2">
            <p class="teal-font uppercase font-weight-bold mb-0">Clinic Aid Settings</p>
          </div>
        </div>
        <!-- Clinic Aid Id -->
        <div class="row mx-0 mt-2">
          <div class="col px-2">
            <mat-form-field class="w-100">
              <input matInput required placeholder="Clinic Aid Id" formControlName="clinicAidId" /> 
              <mat-error *ngIf="form.controls.clinicAidId.hasError('required')">Id is required.</mat-error>
            </mat-form-field>
          </div>
        </div>
        <!-- Clinic Aid Api Key -->
        <div class="row mx-0 mt-2">
          <div class="col px-2">
            <mat-form-field class="w-100">
              <input matInput required placeholder="Clinic Aid API Key" formControlName="clinicAidApiKey" />
              <mat-error *ngIf="form.controls.clinicAidApiKey.hasError('required')">API Key is required.</mat-error>
            </mat-form-field>
          </div>
        </div>
        <!-- Submit Buttons-->
        <div class="row mx-0 mt-2">
          <div class="col text-center px-2">
            <button type="submit" class="btn em-btn em-btn-green" [disabled]="!form.valid">
              Update
            </button>
          </div>
          <div class="col text-center px-2">
            <button class="btn em-btn em-btn-green" (click)="cancelUpdate()">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </perfect-scrollbar>
  </div>
</ng-container>
