export const PatientData = {
  title: 'Patient Form Fields',
  weight: 30,
  default: false,
  components: {
    firstName: {
      title: 'First Name',
      key: 'firstName',
      schema: {
        label: 'First Name',
        type: 'textfield',
        key: 'firstName',
        disabled: true,
        input: true,
      },
    },
    lastName: {
      title: 'Last Name',
      key: 'lastName',
      schema: {
        label: 'Last Name',
        type: 'textfield',
        key: 'lastName',
        disabled: true,
        input: true,
      },
    },
    name: {
      title: 'Full Name',
      key: 'name',
      schema: {
        label: 'Full Patient Name',
        type: 'textfield',
        key: 'name',
        disabled: true,
        input: true,
      },
    },
  },
};
