import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmChangeUserStatusComponent } from '@app/management/dialogs/confirm-change-user-status/confirm-change-user-status.component';
import { User } from '@models/user';
import { CellClickEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { ClinicsService } from '@services/clinics.service';
import { UsersService } from '@services/users.service';
import { Observable, Subject } from 'rxjs';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete/confirm-delete.component';

@Component({
  selector: 'app-org-users',
  templateUrl: './org-users.component.html',
  styleUrls: ['./org-users.component.less'],
})
export class OrgUsersComponent implements OnInit {
  searchValue = '';
  disableGrid = false;
  loading = false;
  unsub: Subject<void> = new Subject<void>();
  users: User[] = [];
  searchCtrl: FormControl;
  filteredUsers: Observable<User[]>;
  activeItem: any;
  clinicId: number;
  gridView: GridDataResult;
  gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  formGroup: FormGroup;
  editedRowIndex: number;
  editedDataItem: User;

  constructor(
    private usersService: UsersService,
    private router: Router,
    private deleteDialog: MatDialog,
    private clinicsService: ClinicsService,
    private userStatusDialog: MatDialog
  ) {
    this.searchCtrl = new FormControl();
    this.filteredUsers = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map((pack) => this.filterUsers(pack))
    );
  }

  ngOnInit() {
    this.clinicsService.clinicIdSelected$
      .asObservable()
      .pipe(takeUntil(this.unsub))
      .subscribe((clinicId) => {
        this.loadClinic(clinicId);
      });
    this.gridView = {
      data: [],
      total: 0,
    };
    this.loading = true;
    this.usersService.refreshRequired$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.disableGrid = false;
      if (this.usersService.refreshRequired$) {
        this.refreshData();
      }
    });
  }

  loadClinic(clinicId) {
    if (this.clinicId === clinicId) {
      return;
    }
    this.clinicId = clinicId;
    this.refreshData();
  }

  filterUsers(name: string) {
    let filterResults: User[] = [];
    let searchArgs: string[] = [];
    searchArgs = name.split(' ', 2);

    if (searchArgs[0] !== '') {
      this.gridView = {
        data: this.users.filter(
          (user) =>
            user.firstName.toLowerCase().includes(searchArgs[0].toLowerCase()) &&
            (searchArgs[1] === undefined ||
              searchArgs[1] === '' ||
              user.lastName.toLowerCase().includes(searchArgs[1].toLowerCase()))
        ),
        total: this.users.filter(
          (user) =>
            user.firstName.toLowerCase().includes(searchArgs[0].toLowerCase()) &&
            (searchArgs[1] === undefined ||
              searchArgs[1] === '' ||
              user.lastName.toLowerCase().includes(searchArgs[1].toLowerCase()))
        ).length,
      };
      filterResults = this.users.filter(
        (user) =>
          user.firstName.toLowerCase().includes(searchArgs[0].toLowerCase()) &&
          (searchArgs[1] === undefined ||
            searchArgs[1] === '' ||
            user.lastName.toLowerCase().includes(searchArgs[1].toLowerCase()))
      );
    } else {
      this.gridView = {
        data: this.users,
        total: this.users.length,
      };
      filterResults = [];
    }

    return filterResults;
  }

  toggleActiveStatus(status: boolean) {
    let waitStatus = setInterval(() => {
      //You have to wait as the CellEvent may not fire first
      if (this.activeItem != null) {
        clearInterval(waitStatus);
        waitStatus = undefined;
        const user: User = this.activeItem as User;
        this.gridView.data.find((u) => u.id === user.id).isActive = status ? 1 : 0;
        const dialogRef = this.userStatusDialog.open(ConfirmChangeUserStatusComponent, {
          width: '350px',
        });
        dialogRef
          .afterClosed()
          .subscribe((result) => {
            if (result === 'update') {
              this.usersService.updateUserStatus(user.id, status).subscribe(() => {
                this.refreshData();
              });
            } else {
              this.gridView.data.find((u) => u.id === user.id).isActive = status ? 0 : 1;
            }
          })
          .add(() => {
            this.activeItem = undefined;
          });
      }
    }, 20);
  }

  cellClickHandler({ sender, rowIndex, column, columnIndex, dataItem, isEdited, originalEvent }: CellClickEvent) {
    this.activeItem = this.gridView.data[rowIndex];
  }

  refreshData() {
    this.loading = true;
    if (this.clinicId === 0) {
      this.usersService.getDevUsers().subscribe((users: User[]) => {
        this.users = users;
        this.loadItems();
        this.loading = false;
      });
    } else {
      this.usersService.getUsers(false).subscribe((users: User[]) => {
        this.users = users;
        this.loadItems();
        this.loading = false;
      });
    }
  }

  onAddClick({ sender }) {
    this.disableGrid = true;
    this.router.navigate([
      '/management/organization/clinics/users',
      { outlets: { 'action-panel': ['edit-user', '_'] } },
    ]);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.disableGrid = true;
    this.router.navigate([
      '/management/organization/clinics/users',
      { outlets: { 'action-panel': ['edit-user', dataItem.id] } },
    ]);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    const user: User = formGroup.value;
    this.usersService.updateUser(user, null).subscribe(() => {
      this.refreshData();
    });
    sender.closeRow(rowIndex);
  }

  public removeHandler({ dataItem }) {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        const dataItemToRemove = Object.assign(new User(), {
          id: dataItem.id,
          firstName: dataItem.firstName,
          lastName: dataItem.lastName,
          avatar: dataItem.avatar,
          password: dataItem.password,
          phoneNumber: dataItem.phoneNumber,
          email: dataItem.email,
          address: dataItem.address,
          clinics: dataItem.clinics,
          userCategory: dataItem.userCategory,
          userCategoryId: dataItem.userCategoryId,
          serviceProvider: dataItem.serviceProvider,
        });

        this.usersService
          .removeUser(dataItemToRemove)
          .pipe(take(1))
          .subscribe(() => {
            this.refreshData();
          });
      }
    });
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  loadItems() {
    this.gridView = {
      data: this.users,
      total: this.users.length,
    };
  }

  openDeleteDialog(): void {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
      data: { result: '' },
    });

    dialogRef.afterClosed().subscribe();
  }
}
