import { CurrencyPipe, DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IPolicy, Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { ClinicsService } from '@services/clinics.service';
import { ReportsService } from '@services/reports.service';
import { Subject } from 'rxjs';

enum ReportFilterRanges {
  Today = 'TODAY',
  ThreeDays = '3DAYS',
  Week = 'WEEK',
  Month = 'MONTH',
  LastMonth = 'LASTMONTH',
  ThreeMonths = '3MONTHS',
  YearToDate = 'YTD',
  Picker = 'PICKER',
}
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.less'],
  providers: [DatePipe, CurrencyPipe],
})
export class ReportsComponent implements OnInit, AfterViewInit {
  @ViewChild('reportTab') private reportComponent;
  filterRanges = ReportFilterRanges;
  activeDateFilter = this.filterRanges.Today;
  dateRange = new FormControl(null);
  showDeletedRecords: boolean = false;
  defaultInitialized: boolean = false;
  unsub: Subject<void> = new Subject<void>();
  authorizedTabs: Map<string, IPolicy> = null;
  activeTab: string = null;
  private tabPolicies = new Map([
    ['Clinic', Policies.reportsClinic],
    ['Clinic Supplies', Policies.reportsClinicSupplies],
    ['Commissions', Policies.reportsCommissions],
    ['Invoices', Policies.reportsInvoices],
    ['Liabilities', Policies.reportsLiabilities],
    ['Memberships', Policies.empty],
    ['Patients', Policies.reportsPatients],
    ['Products', Policies.reportsProducts],
    ['Providers', Policies.reportsProviders],
    ['Services', Policies.reportsServices],
  ]);

  constructor(
    private reportsService: ReportsService,
    private clinicsService: ClinicsService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authorizedTabs = new Map(
      [...this.tabPolicies].filter(([title, policy]) => this.authService.userSatisfiesPolicy(policy))
    );
    this.activeTab = Array.from(this.authorizedTabs.keys())[0];
    const date = new Date();
    let begin = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    let end = new Date(begin);
    this.dateRange.setValue({
      begin: begin,
      end: end,
    });

    this.initDefaultFilter();
  }

  ngAfterViewInit() {
    this.dateRange.valueChanges.subscribe(() => this.getReportData());
  }

  initDefaultFilter() {
    this.clinicsService.getClinics().subscribe((c) => {
      if (c && c[0] && c[0].clinicId != null) {
        this.reportsService.initDefaultValue(c[0].clinicId);
        this.defaultInitialized = true;
      }
    });
  }

  filterByPresetRange(key: ReportFilterRanges) {
    this.activeDateFilter = key;
    const date = new Date();
    let begin = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    let end = new Date(begin);
    switch (key) {
      case ReportFilterRanges.Today:
        break;

      case ReportFilterRanges.ThreeDays:
        begin.setDate(begin.getDate() - 3);
        break;

      case ReportFilterRanges.Week:
        begin.setDate(begin.getDate() - begin.getDay());
        break;

      case ReportFilterRanges.Month:
        begin.setDate(1);
        break;

      case ReportFilterRanges.LastMonth:
        begin.setMonth(begin.getMonth() - 1);
        begin.setDate(1);
        end.setDate(0);
        break;

      case ReportFilterRanges.ThreeMonths:
        begin.setMonth(begin.getMonth() - 3);
        break;

      case ReportFilterRanges.YearToDate:
        begin = new Date(begin.getFullYear(), 0);
        break;
    }
    this.dateRange.setValue({ begin: begin, end: end });
  }

  incrementDayBy(increment: number) {
    const begin = new Date(this.dateRange.value.begin);
    const end = new Date(this.dateRange.value.end);
    begin.setDate(begin.getDate() + increment);
    end.setDate(end.getDate() + increment);
    this.dateRange.setValue({
      begin: begin,
      end: end,
    });
  }

  onShowDeletedRecords() {
    this.reportsService.defaultReportsModel = {
      ...this.reportsService.defaultReportsModel,
      showDeletedRecords: this.showDeletedRecords,
    };
    this.reportComponent.getReportData();
  }

  clearFilter() {
    this.activeDateFilter = this.filterRanges.Today;
    this.filterByPresetRange(this.activeDateFilter);
  }

  getReportData() {
    const start = new Date(this.dateRange.value.begin);
    const end = new Date(this.dateRange.value.end);
    end.setDate(end.getDate() + 1);
    end.setMilliseconds(-1);
    this.reportsService.defaultReportsModel = {
      ...this.reportsService.defaultReportsModel,
      startDate: start,
      endDate: end,
    };
    this.reportComponent.getReportData();
  }

  exportToExcel() {
    this.reportComponent.exportToExcel();
  }

  loadClinic(clinicId) {
    if (clinicId !== -1) {
      this.getReportData();
    }
  }
}
