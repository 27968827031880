import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { Patient } from '@models/patient';
import { MasterOverlayService } from '@services/actionpanel.service';
import { AppointmentService } from '@services/appointments.service';
import { EventsService } from '@services/events.service';
import { PatientService } from '@services/patient.service';
import * as moment from 'moment';

@Component({
  selector: 'app-visit-patient-info',
  templateUrl: './visit-patient-info.component.html',
  styleUrls: ['./visit-patient-info.component.less'],
})
export class VisitPatientInfoComponent implements OnInit {
  private imgLoadingTimeout: NodeJS.Timeout;
  private _patient: Patient;
  get patient() {
    return this._patient;
  }
  @Input() set patient(patient: Patient) {
    this._patient = patient;
    this.imgLoading = false;
    if (this.imgLoadingTimeout) clearTimeout(this.imgLoadingTimeout);
    this.imgLoadingTimeout = setTimeout(() => (this.imgLoading = true), 250);
  }
  @Input() showActionButtons: boolean;
  @Output() editPatientEvent = new EventEmitter();
  patientPanelPolicySatisfied = false;
  imgLoading = false;
  patientProfilePolicy = Policies.patientProfile;
  patientChartPolicy = Policies.patientChart;
  patientAccountPolicy = Policies.patientAccount;

  constructor(
    private authService: AuthService,
    private patientService: PatientService,
    private eventService: EventsService,
    private masterOverlayService: MasterOverlayService,
    private appointmentService: AppointmentService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.patientPanelPolicySatisfied = this.authService.userSatisfiesPolicy(Policies.patientPanel);
  }

  imgLoaded() {
    if (this.imgLoadingTimeout) clearTimeout(this.imgLoadingTimeout);
    this.imgLoading = false;
  }

  savePatientNotes(newNotes: string) {
    this.patientService
      .updatePatientNotesAlerts({
        patientId: this.patient.patientId,
        notesAndAlerts: newNotes,
      })
      .subscribe();
  }

  goToPatientPanel() {
    this.patientService.patientPanelPatient = this.patient;
    this.masterOverlayService.masterOverlayState(true);
    this.appointmentService.deleteAppointmentReservation();
    this.router.navigate(['/schedule', { outlets: { 'action-panel': ['patient', this.patient.patientId] } }]);
  }

  goToPatientProfile() {
    this.patientService.patientPanelPatient = this.patient;
    this.masterOverlayService.masterOverlayState(true);
    this.router.navigate([
      '/schedule',
      { outlets: { 'action-panel': ['patient', this.patient.patientId + '_' + 'patientprofiletab'] } },
    ]);
    this.eventService.closePanel();
  }

  goToPatientChart() {
    this.patientService.patientPanelPatient = this.patient;
    this.masterOverlayService.masterOverlayState(true);
    this.router.navigate([
      '/schedule',
      {
        outlets: {
          'action-panel': [
            'patient',
            this.patient.patientId + '_patientcharttab',
            'patienttabs',
            'patientcharttab',
            'overview',
          ],
        },
      },
    ]);
    this.eventService.closePanel();
  }

  goToPatientInvoices() {
    this.patientService.patientPanelPatient = this.patient;
    this.masterOverlayService.masterOverlayState(true);
    this.router.navigate([
      '/schedule',
      {
        outlets: {
          'action-panel': [
            'patient',
            this.patient.patientId + '_patientaccounttab',
            'patienttabs',
            'patientaccounttab',
            'overview',
          ],
        },
      },
    ]);
    this.eventService.closePanel();
  }

  togglePatientRushNote(toggle: boolean) {
    this.patientService.togglePatientRushNote(this.patient.patientId, toggle).subscribe(() => {
      this.patient.rushPatientNote = toggle;
    });
  }

  getPatientName() {
    if (!this.patient) {
      return '';
    }
    return `${this.patient.firstName}${this.patient.nickName ? ' "' + this.patient.nickName + '"' : ''} ${
      this.patient.lastName
    }`;
  }

  formatBirthday(date: Date): string {
    if (date) {
      return moment(date).format('YYYY-MM-DD');
    } else {
      return 'N/A';
    }
  }

  formatAge(dob: Date) {
    if (dob) {
      const today = new Date();
      const birthDate = new Date(dob);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    } else {
      return 'N/A';
    }
  }

  onEditPatient() {
    this.editPatientEvent.emit();
  }
}
