<div [class.isDisabled]="isDisabled" [ngClass]="{ 'text-dark': isDisabled }">
  <ngx-loading [show]="loading"></ngx-loading>
  <mat-label *ngIf="convergeCards?.length > 0" class="label-header teal-font" [class.isDisabled]="isDisabled"
    >Please select a Credit Card on file:</mat-label
  >
  <div class="mb-1 mt-2">
    <mat-radio-group
      name="selectedCard"
      id="selectedCard"
      [(ngModel)]="selectedConvergeCard"
      (change)="onConvergeCardSelected($event)"
    >
      <ng-container *ngFor="let convergeCard of convergeCards">
        <mat-radio-button
          class="select-card-radio-button card mb-2 p-2 mr-2 grey-four-background"
          [disabled]="compareExpiryToMinimum(convergeCard)"
          [value]="convergeCard"
        >
          <div class="d-flex flex-column">
            <app-converge-card [card]="convergeCard" [showSmallBrandLogo]="false"></app-converge-card>
            <span *ngIf="compareExpiryToMinimum(convergeCard)" class="card-error">Card expires before appointment</span>
          </div>
        </mat-radio-button>
      </ng-container>
    </mat-radio-group>
  </div>
  <div class="justify-content-center" [ngClass]="{ 'd-flex': !showRequestCreditCardButton }">
    <ng-container *ngIf="!showRequestCreditCardButton">
      <div class="request-credit-card flex-column mt-2 mr-3">
        <mat-slide-toggle
          title="Request credit card"
          [checked]="requestCreditCard"
          (change)="onRequestCreditCardChangedEvent($event)"
          class="align-middle"
        >
        </mat-slide-toggle>
        <label class="ml-1 mb-0 teal-font text-truncate d-inline font-weight-bold">Request new card from patient</label>
      </div>
    </ng-container>
    <button
      class="add-credit-card btn em-btn em-btn-green mb-1 w-100"
      [disabled]="isDisabled"
      (click)="initiateLightbox()"
      [ngClass]="{ 'flex-column': !showRequestCreditCardButton, 'w-100': showRequestCreditCardButton }"
    >
      Add New Credit Card
    </button>
    <ng-container *ngIf="showRequestCreditCardButton">
      <button
        class="request-credit-card btn em-btn em-btn-green mb-1 w-100"
        [disabled]="isDisabled"
        (click)="openRequestNewCardModal()"
      >
        Request Credit Card
      </button>
    </ng-container>
  </div>
</div>
