<div class="m-2">
  <!--Close Button-->
  <button *ngIf="modalView && !kioskMode" class="btn em-btn em-btn-green mr-1" (click)="closeDialog()">
    <i class="far fa-times" title="Close"></i>
  </button>

  <!--Save Button-->
  <button
    *ngIf="patientForm && !patientForm.isSigned && modalView && !kioskMode"
    class="btn em-btn em-btn-green mr-1"
    [disabled]="!isDirty && !isUnassigned"
    (click)="saveForm()"
  >
    <i class="far fa-save" title="Save & Close"></i>
  </button>
  <!--Send Nudge Button-->
  <button
    *ngIf="!kioskMode && (patientDocument || patientForm?.isSigned)"
    class="btn em-btn em-btn-green mr-1"
    (click)="openNudgeModal()"
  >
    <i class="far fa-bell-plus" title="Send Nudge"></i>
  </button>
  <!--Download Button-->
  <button *ngIf="!kioskMode" class="btn em-btn em-btn-green mr-1" (click)="downloadDoc()">
    <i class="far fa-download" title="Download"></i>
  </button>
  <!--Print Button-->
  <button *ngIf="!patientDocument && !kioskMode" class="btn em-btn em-btn-green mr-1" (click)="printDoc()">
    <i class="far fa-print" title="Print"></i>
  </button>
  <!--Email Button-->
  <button *ngIf="!kioskMode" class="btn em-btn em-btn-green mr-1" (click)="emailDocument()">
    <i class="far fa-envelope white-font" title="Email Document to Patient"></i>
  </button>
  <!--Exit Kiosk Mode Button-->
  <button *ngIf="kioskMode" class="btn em-btn em-btn-green float-right" (click)="exitKiosk()">
    <i class="far fa-external-link mr-2" title="Exit"></i>Exit
  </button>
</div>

<div class="card document-container mx-2 mb-2">
  <div class="card-body">
    <ngx-loading [show]="loading"></ngx-loading>
    <!--Legacy Form Deleted-->
    <div *ngIf="patientDocument?.isDeleted" class="pastel-red-font text-center">
      <h2>DOCUMENT DELETED</h2>
    </div>
    <!--PDF Forms and Signed Forms-->
    <pdf-viewer
      *ngIf="(patientDocument?.filePath && !patientDocument?.pdfToHtmlUrl) || patientForm?.isSigned"
      [src]="patientDocument ? patientDocument.filePath : patientForm.blobUrl"
      [original-size]="false"
      [fit-to-page]="true"
      [render-text]="true"
      (page-rendered)="pageRendered($event)"
    >
    </pdf-viewer>
    <!--Legacy Forms-->
    <app-eform-viewer
      *ngIf="patientDocument?.pdfToHtmlUrl"
      #eformViewer
      [docId]="patientDocument.id"
      [formDefinition]="docFormDefinition"
      [formData]="docFormData"
      [editable]="false"
      [modalView]="modalView"
      (submitted)="onSubmit($event)"
      (changed)="pageRendered($event)"
    >
    </app-eform-viewer>
    <!--Unsigned Forms-->
    <app-form-viewer
      *ngIf="patientForm && !patientForm.isSigned"
      class="mx-2"
      [form]="patientForm.clinicForm"
      [submission]="patientForm.formEntry"
      [patientFormId]="patientForm.id"
      [editable]="true"
      [modalView]="modalView"
      (submitted)="onFormSubmit($event)"
      (changed)="onFormChange($event)"
    >
    </app-form-viewer>
  </div>
</div>
