import { ServiceDetailComponent } from './../../patients/patient-tabs/patient-treatment-plan-tab/service-detail/service-detail.component';
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ConfirmDeactivateServiceDetailGuard implements CanDeactivate<ServiceDetailComponent> {
  canDeactivate(component: ServiceDetailComponent ) {
    return component.currentDataService.preventUserFromNavigationLastSetting !== true;

}
}
