import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { VisitBillingComponent } from './visit-billing/visit-billing.component';

@NgModule({
  declarations: [VisitBillingComponent],
  imports: [SharedModule],
  exports: [VisitBillingComponent]
})
export class BillingModule {}
