<ngx-loading [show]="loading"></ngx-loading>

<div class="modal-header border-0 p-0">
  <button
    type="button"
    class="btn white-font p-0 ml-auto mr-2"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="container p-0 pb-3">
  <div class="search-container navy-background justify-content-center clinic-supply-container">
    <h6 class="modal-title text-uppercase white-font text-center">Add Clinic Supply</h6>
  </div>

  <div class="clinic-supply-container" style="height: 440px">
    <perfect-scrollbar>
      <mat-accordion [multi]="false">
        <div *ngFor="let group of groupedClinicSupplies | keyvalue : originalOrder" class="my-1">
          <mat-expansion-panel #panelList1="matExpansionPanel" [expanded]="true">
            <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
              <mat-panel-title>
                <div class="d-flex align-items-center panel-box-retention py-1 text-uppercase" #panelHeader1>
                  {{ group.key }}
                  <button type="button" class="btn d-flex flex-column expand-btn ml-auto p-0">
                    <mat-icon>{{ panelList1.expanded ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
                  </button>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="retention-campaign-accordion teal-font">
              <div
                class="d-flex align-items-center navy-font clinic-item ng-star-inserted"
                *ngFor="let supply of group.value"
              >
                <div class="d-flex p-2">{{ supply.name }}</div>
                <div class="d-flex flex-grow-1 justify-content-end">
                  <button class="btn em-btn em-btn-green btn-small" (click)="assignClinicSupply(supply)">Add</button>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
      </mat-accordion>
    </perfect-scrollbar>
  </div>
  <div class="save-changes-container my-3 text-center">
    <button class="btn em-btn em-btn-green ml-2" (click)="activeModal.dismiss('Cross click')">Cancel</button>
  </div>
</div>
