import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { PhotoEventType } from '@models/photo/photo-event-type';

@Injectable({
  providedIn: 'root'
})
export class PhotoEditingService {
 
  private photoSource = new Subject<any>();

  constructor() { }

  editPhoto(photo: PhotoMetaData) {
    this.photoSource.next({ event: PhotoEventType.EditPhoto, photo: photo });
  }

  loadPhoto(photo: PhotoMetaData) {
    this.photoSource.next({ event: PhotoEventType.LoadPhoto, photo: photo });
  }

  cancelEdit() {
    this.photoSource.next({ event: PhotoEventType.CancelEditPhoto });
  }

  fileChanged(file: File) {
    this.photoSource.next({ event: PhotoEventType.FileChanged, file: file });
  }

  undoPhotoChanges() {
    this.photoSource.next({ event: PhotoEventType.UndoPhotoChanges });
  }

  photoCropped() {
    this.photoSource.next({ event: PhotoEventType.PhotoCropped });
  }

  clearSelectedPhoto() {
    this.photoSource.next({ event: PhotoEventType.ClearPhoto });
  }

  getPhotoSource(): Observable<any> {
    return this.photoSource.asObservable();
  }

 
}
