import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from '@app/auth/auth.module';
import { PatientTransactionsModule } from '@app/components/patient-transactions/patient-transactions.module';
import { DatePickerModule, DateTimePickerModule, TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { SharedModule } from '../../../shared/shared.module';
import { AccountOverviewComponent } from './account-overview/account-overview.component';
import { UpdateCreditComponent } from './account-overview/update-credit/update-credit.component';
import { InvoiceDiscountComponent } from './invoice-discount/invoice-discount.component';
import { MembershipSelectionComponent } from './membership-selection/membership-selection.component';
import { PatientInvoiceHistoryComponent } from './patient-invoice-history/patient-invoice-history.component';
import { ProductSelectionComponent } from './product-selection/product-selection.component';
import { ReturnPaymentComponent } from './return-payment/return-payment.component';
import { ServiceProductsComponent } from './service-products/service-products.component';
import { StandardInvoiceComponent } from './standard-invoice/standard-invoice.component';
import { UpdateInvoiceDateComponent } from './update-invoice-date/update-invoice-date.component';
@NgModule({
  declarations: [
    PatientInvoiceHistoryComponent,
    StandardInvoiceComponent,
    AccountOverviewComponent,
    ReturnPaymentComponent,
    ServiceProductsComponent,
    UpdateCreditComponent,
    UpdateInvoiceDateComponent,
    InvoiceDiscountComponent,
    MembershipSelectionComponent,
    ProductSelectionComponent,
  ],
  imports: [
    AuthModule,
    SharedModule,
    PatientTransactionsModule,
    BrowserModule,
    BrowserAnimationsModule,
    GridModule,
    PDFModule,
    ReactiveFormsModule,
    InputsModule,
    PopupModule,
    DatePickerModule,
    TimePickerModule,
    MatCheckboxModule,
    DateTimePickerModule,
  ],
  exports: [PatientInvoiceHistoryComponent, StandardInvoiceComponent, AccountOverviewComponent, ReturnPaymentComponent],
})
export class PatientAccountModule {}
