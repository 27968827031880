<ngx-loading [show]="loading"></ngx-loading>
<p-editor
  placeholder="Invoice Bottom Text&#10;E.g. Thank you for your business!"
  [(ngModel)]="invoiceText"
  class="flex-grow-1"
  [style]="{ height: '100%' }"
>
  <p-header>
    <select class="ql-size">
      <option value="small"></option>
      <option selected></option>
      <option value="large"></option>
      <option value="huge"></option>
    </select>
    <select class="ql-font"></select>
    <span class="ql-format-separator"></span>
    <span class="ql-formats">
      <button class="ql-bold" aria-label="Bold"></button>
      <button class="ql-italic" aria-label="Italic"></button>
      <button class="ql-underline" aria-label="Underline"></button>
      <button class="ql-strike" aria-label="Strike"></button>
      <button class="ql-script" value="sub" aria-label="Subscript"></button>
      <button class="ql-script" value="super" aria-label="Superscript"></button>
    </span>
    <span class="ql-formats">
      <select title="Text Color" class="ql-color">
        <option value="rgb(255,255,255)" label="rgb(255,255,255)"></option>
        <option value="rgb(0,0,0)" label="rgb(0,0,0)"></option>
        <option value="rgb(255,0,0)" label="rgb(255,0,0)"></option>
        <option value="rgb(0,255,0)" label="rgb(0,255,0)"></option>
        <option value="rgb(0,0,255)" label="rgb(0,0,255)"></option>
        <option value="rgb(128,128,128)" label="rgb(128,128,128)"></option>
        <option value="rgb(255,140,0)" label="rgb(255,140,0)"></option>
      </select>
      <span class="ql-format-separator"></span>
      <select title="Background Color" class="ql-background" defaultValue="rgb(255,255,255)">
        <option value="rgb(255,255,255)" label="rgb(255,255,255)"></option>
        <option value="rgb(37, 47, 56)" label="rgb(37, 47, 56)"></option>
        <option value="rgb(255,0,0)" label="rgb(255,0,0)"></option>
        <option value="rgb(0,255,0)" label="rgb(0,255,0)"></option>
        <option value="rgb(0,0,255)" label="rgb(0,0,255)"></option>
        <option value="rgb(128,128,128)" label="rgb(128,128,128)"></option>
        <option value="rgb(0,128,128)" label="rgb(0,128,128)"></option>
        <option value="rgb(255,140,0)" label="rgb(255,140,0)"></option>
      </select>
    </span>
    <span class="ql-formats">
      <button class="ql-list" value="ordered"></button>
      <button class="ql-list" value="bullet"></button>
    </span>
    <span class="ql-formats">
      <button class="ql-align" value=""></button>
      <button class="ql-align" value="center"></button>
      <button class="ql-align" value="right"></button>
    </span>
    <span class="ql-formats">
      <button aria-label="Link" class="ql-link"></button>
    </span>
    <span class="ql-formats">
      <button class="ql-clean" aria-label="Remove Styles"></button>
    </span>
  </p-header>
</p-editor>
<div class="mt-2 w-100 d-flex justify-content-start">
  <button class="btn em-btn em-btn-green" (click)="onSave()">Save</button>
  <button class="btn em-btn em-btn-green ml-2" (click)="onReset()">Reset</button>
</div>
