
import { Component, OnInit } from '@angular/core';
import { ActionPanelService } from '@services/actionpanel.service';

@Component({
  selector: 'app-actionpanel',
  templateUrl: './actionpanel.component.html',
  styleUrls: ['./actionpanel.component.less']
})
export class ActionPanelComponent implements OnInit {

  visible = true;
  actionPanelOpened = true;

  constructor(private actionpanelService: ActionPanelService) { }

  ngOnInit() {
  }

}
