<ngx-loading [show]="loading"></ngx-loading>
<kendo-grid
  class="emr-kendo-grid"
  [data]="providerDetails"
  [style.maxHeight.%]="100"
  [pageable]="false"
  [sortable]="{
    allowUnsort: false,
    mode: 'single'
  }"
  #grid="kendoGrid"
>
  <kendo-grid-column field="patientId" title="Patient">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <button
        type="button"
        class="btn-clean px-0 text-left underline"
        (click)="navigateToPatientAccountTab(dataItem.patientId)"
      >
        {{ dataItem.patientName }}
      </button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="invoiceDate" title="Invoice Date">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{ dataItem.invoiceDate | date : 'yyyy-MM-dd h:mma' }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="creditAmount" title="Credit Amount" format="{0:c}">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="serviceAmount" title="Service Amount" format="{0:c}">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="productAmount" title="Product Amount" format="{0:c}">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="returnAmount" title="Return Amount" format="{0:c}">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="invoiceId" title="Invoice #">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <button
        type="button"
        class="btn-clean px-0 text-left underline"
        (click)="navigateToPatientInvoice(dataItem.patientId, dataItem.invoiceId)"
      >
        {{ dataItem.invoiceId }}
      </button>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
