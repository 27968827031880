import { WizardComponent } from './../patients/patient-tabs/patient-profile-tab/patient-portal-overview/wizard/wizard.component';
import { FormioModule } from '@formio/angular';
import { FormBuilderComponent } from './../management/forms/form-builder/form-builder.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { CatalogueEtreatmentFormsComponent } from './../management/catalogue/catalogue-etreatment-forms/catalogue-etreatment-forms.component';
import { AssigneTreatmentFormComponent } from './../management/catalogue/catalogue-services/assign-etreatment-form/assign-etreatment-form.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { MultiCheckFilterComponent } from '@app/nudges/multi-check.component';
import { BalloonsComponent } from './components/balloons/balloons.component';
import { ProvinceCodePipe } from './pipes/province-code.pipe';
import { Routing } from '@app/app.routing';
import { AsyncPipe, CommonModule, CurrencyPipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingModule } from 'ngx-loading';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PlottingEventService } from '@services/plotting-event.service';
import { TextToolsService } from '@services/tools/text-tools.service';
import { ActionPanelComponent } from './../schedule/appointments/actionpanel/actionpanel.component';
import { FavouriteItemComponent } from './service-list/favourite-item/favourite-item.component';
import { HistoryItemComponent } from './service-list/history-item/history-item.component';
import { ServiceListComponent } from './service-list/service-list.component';
import { ItemDetailDisplayComponent } from './service-list/item-detail-display/item-detail-display.component';
import { CustomNgxImageEditorComponent } from './custom-ngx-image-editor/custom-ngx-image-editor.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { TreatmentPlanOverviewComponent } from '../patients/patient-tabs/patient-chart-tab/chart-overview/treatment-plan-overview/treatment-plan-overview.component';
import { DropDownListFilterComponent } from './components/dropdownlist-filter/dropdownlist-filter.component';
import { PlannedTreatmentMultipleDisplayComponent } from '../patients/patient-tabs/patient-chart-tab/chart-overview/treatment-plan-overview/planned-treatment-multiple-display/planned-treatment-multiple-display.component';
import { PlannedTreatmentListPanelComponent } from '../patients/patient-tabs/patient-chart-tab/chart-overview/treatment-plan-overview/planned-treatment-list-panel/planned-treatment-list-panel.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { DragMoveDirective } from './directives/drag-move.directive';
import { SpaceTitleCasePipe } from './pipes/space-title-case.pipe';
import { PanelComponent } from './components/panel/panel.component';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { RestrictDecimalPlacesNumberDirective } from './directives/restrict-decimal-places.directive';
import { PopupAnchorDirective } from './directives/popup.anchor-target.directive';
import { EscapeHtmlPipe } from './pipes/keep-html.pipe';
import { SafeResourcePipe } from './pipes/safe-resource.pipe';
import { ErrorComponent } from './components/error/error.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProductPurschaseHistoryComponent } from '@app/patients/patient-tabs/patient-account-tab/product-purschase-history/product-purschase-history.component';
import { ApplyTextTemplateComponent } from './components/apply-text-template/apply-text-template.component';
import { ApplyServiceComponent } from './components/apply-service/apply-service.component';
import { DiagnosticCodeModalComponent } from './diagnostic-code-modal/diagnostic-code-modal.component';
import { BillingCodeModalComponent } from './billing-code-modal/billing-code-modal.component';
import { TitleSelectComponent } from './title-select/title-select.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextareaWidgetComponent } from './components/textarea-widget/textarea-widget.component';
import { InvoicePaymentComponent } from '@app/patients/patient-tabs/patient-account-tab/invoice-payment/invoice-payment.component';
import { DocumentDetailsComponent } from '@app/patients/patient-tabs/patients-documents-tab/document-details/document-details.component';
import { FilterPipe } from './pipes/filter.pipe';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { DetermineMaxScrollHeightDirective } from './directives/determine-max-scroll-height.directive';
import { DomChangeListenerDirective } from './directives/dom-change-listener.directive';
import { PhonePipe } from './phone-number.pipe';
import { CSSTransitionEndDirective } from './directives/css-transition-end.directive';
import { DefaultBirthdayCheckDirective } from './directives/default-birthday-check.directive';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { EnumToArrayPipe } from './pipes/enum-array.pipe';
import { SelectClinicComponent } from './components/select-clinic/select-clinic.component';
import { EscapeStylePipe } from './pipes/keep-style.pipe';
import { SocialHistoryComponent } from '@app/patients/patient-tabs/patient-chart-tab/chart-overview/social-history/social-history.component';
import { PrescriptionsWidgetComponent } from '@app/patients/patient-tabs/patient-chart-tab/chart-overview/prescriptions-widget/prescriptions-widget.component';
import { ExternalPrescriptionsComponent } from '@app/patients/patient-tabs/patient-chart-tab/chart-overview/external-prescriptions/external-prescriptions.component';
import { AppointmentDatePipe } from './pipes/tx-date';
import { ConvertUTCPipe } from './pipes/convert-utc.pipe';
import { GenericSendEmailPopoverComponent } from './components/generic-send-email-popover/generic-send-email-popover.component';
import { DrawToolComponent } from './components/draw-tool/draw-tool.component';
import { FormViewerComponent } from '@app/management/forms/form-viewer/form-viewer.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgScrollbarReachedModule } from 'ngx-scrollbar/reached-event';
import { UnlayerEmailEditorComponent } from './components/unlayer-email-editor/unlayer-email-editor.component';
import { PatientTypeheadComponent } from '@app/patients/patient-typehead/patient-typehead.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { PatientSearchModalComponent } from './components/patient-search-modal/patient-search-modal.component';
import { AddSquareCardComponent } from '@app/management/dialogs/add-square-card/add-square-card.component';
import { SquareCardComponent } from '@app/components/payments/square-card/square-card.component';
import { SquareCardOnFileComponent } from '@app/components/billing/square-card-on-file/square-card-on-file.component';
import { PatientMembershipOverviewComponent } from '@app/patients/patient-tabs/patient-account-tab/patient-membership-overview/patient-membership-overview.component';
import { AuthModule } from '@app/auth/auth.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
};
@NgModule({
  declarations: [
    ServiceListComponent,
    HistoryItemComponent,
    FavouriteItemComponent,
    BalloonsComponent,
    ActionPanelComponent,
    ItemDetailDisplayComponent,
    CustomNgxImageEditorComponent,
    TreatmentPlanOverviewComponent,
    ProductPurschaseHistoryComponent,
    SocialHistoryComponent,
    PrescriptionsWidgetComponent,
    ExternalPrescriptionsComponent,
    DragMoveDirective,
    OnlyNumberDirective,
    RestrictDecimalPlacesNumberDirective,
    AutofocusDirective,
    PopupAnchorDirective,
    DetermineMaxScrollHeightDirective,
    CSSTransitionEndDirective,
    DefaultBirthdayCheckDirective,
    DomChangeListenerDirective,
    SpaceTitleCasePipe,
    PhonePipe,
    AppointmentDatePipe,
    EscapeStylePipe,
    EscapeHtmlPipe,
    ProvinceCodePipe,
    SafeResourcePipe,
    SafeUrlPipe,
    PanelComponent,
    ErrorComponent,
    ApplyTextTemplateComponent,
    ApplyServiceComponent,
    DiagnosticCodeModalComponent,
    BillingCodeModalComponent,
    TitleSelectComponent,
    TextareaWidgetComponent,
    InvoicePaymentComponent,
    FilterPipe,
    DropDownListFilterComponent,
    EnumToArrayPipe,
    ConvertUTCPipe,
    SelectClinicComponent,
    PlannedTreatmentMultipleDisplayComponent,
    PlannedTreatmentListPanelComponent,
    MultiCheckFilterComponent,
    GenericSendEmailPopoverComponent,
    DrawToolComponent,
    AssigneTreatmentFormComponent,
    CatalogueEtreatmentFormsComponent,
    FormBuilderComponent,
    FormViewerComponent,
    WizardComponent,
    UnlayerEmailEditorComponent,
    PatientTypeheadComponent,
    PatientSearchModalComponent,
    SquareCardComponent,
    AddSquareCardComponent,
    SquareCardOnFileComponent,
    PatientMembershipOverviewComponent,
  ],
  providers: [
    PlottingEventService,
    TextToolsService,
    CurrencyPipe,
    AsyncPipe,
    TitleCasePipe,
    DecimalPipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatTabsModule,
    LoadingModule,
    OverlayPanelModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    PerfectScrollbarModule,
    NgbModule,
    Routing,
    MatProgressSpinnerModule,
    MatSliderModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatMomentDateModule,
    DragDropModule,
    MatRadioModule,
    MatSlideToggleModule,
    PopoverModule.forRoot(),
    DropDownsModule,
    TooltipModule,
    TimePickerModule,
    DatePickerModule,
    GridModule,
    FormioModule,
    PDFExportModule,
    NgScrollbarModule,
    NgScrollbarReachedModule,
    TypeaheadModule,
    AuthModule
  ],
  exports: [
    CommonModule,
    MatExpansionModule,
    MatTabsModule,
    LoadingModule,
    BalloonsComponent,
    ServiceListComponent,
    OverlayPanelModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    PerfectScrollbarModule,
    NgbModule,
    ActionPanelComponent,
    ItemDetailDisplayComponent,
    CustomNgxImageEditorComponent,
    MatDatepickerModule,
    MatMomentDateModule,
    MatRadioModule,
    MatSlideToggleModule,
    TreatmentPlanOverviewComponent,
    ProductPurschaseHistoryComponent,
    SocialHistoryComponent,
    PrescriptionsWidgetComponent,
    ExternalPrescriptionsComponent,
    AutofocusDirective,
    OnlyNumberDirective,
    RestrictDecimalPlacesNumberDirective,
    DetermineMaxScrollHeightDirective,
    CSSTransitionEndDirective,
    DefaultBirthdayCheckDirective,
    DomChangeListenerDirective,
    PopupAnchorDirective,
    SpaceTitleCasePipe,
    PhonePipe,
    AppointmentDatePipe,
    EscapeStylePipe,
    EscapeHtmlPipe,
    ProvinceCodePipe,
    SafeResourcePipe,
    SafeUrlPipe,
    PanelComponent,
    ErrorComponent,
    PopoverModule,
    ApplyTextTemplateComponent,
    ApplyServiceComponent,
    TitleSelectComponent,
    DragDropModule,
    DiagnosticCodeModalComponent,
    BillingCodeModalComponent,
    TextareaWidgetComponent,
    InvoicePaymentComponent,
    FilterPipe,
    DropDownListFilterComponent,
    TooltipModule,
    TimePickerModule,
    DatePickerModule,
    EnumToArrayPipe,
    ConvertUTCPipe,
    SelectClinicComponent,
    PlannedTreatmentMultipleDisplayComponent,
    PlannedTreatmentListPanelComponent,
    MultiCheckFilterComponent,
    GenericSendEmailPopoverComponent,
    DrawToolComponent,
    AssigneTreatmentFormComponent,
    FormBuilderComponent,
    FormViewerComponent,
    WizardComponent,
    PDFExportModule,
    NgScrollbarModule,
    NgScrollbarReachedModule,
    UnlayerEmailEditorComponent,
    PatientTypeheadComponent,
    SquareCardComponent,
    AddSquareCardComponent,
    SquareCardOnFileComponent,
    PatientMembershipOverviewComponent,
  ],
})
export class SharedModule {}
