<div class="row py-1">
  <div class="col">
    <input class="form-control" [formControl]="control" [placeholder]="placeholder" #input>
  </div>
  <div class="col-2" *ngIf="isSaveButtonVisible">
    <button class="btn" (click)="save()">
      <i class="fal fa-save"></i>
    </button>
  </div>
  <div class="col-2">
    <button class="btn" (click)="delete()">
      <i class="fal fa-trash-alt"></i>
    </button>
  </div>
</div>

<mat-progress-bar mode="indeterminate" [hidden]="!isProgressBarVisible"></mat-progress-bar>
