import { FormTypeEnum } from './../../../models/forms/form-type.enum';
import { FormTypeService } from './../../../services/form-type.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from '@app/shared/helpers';
import { FormService } from '@services/form.service';
import { FormatterService } from '@services/formatter.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ServiceTemplateType } from '@models/service/service-template-type';
import { ServiceTemplateTypeService } from '@services/service-template-type.service';
import { FormType } from '@models/forms/form-type';
import { Form } from '@models/forms/form';

@Component({
  selector: 'app-form-edit',
  templateUrl: './form-edit.component.html',
  styleUrls: ['./form-edit.component.less'],
})
export class FormEditComponent implements OnInit {
  loading = false;
  disableGrid = true;
  unsub: Subject<void> = new Subject<void>();
  formId: number;

  form: FormGroup;
  addOrUpdate: String;
  isNew = false;

  formTypes: FormType[] = [];
  serviceTemplateTypes: ServiceTemplateType[] = [];
  serviceTemplateTypeValidationRequired: boolean = true;

  constructor(
    private fb: FormBuilder,
    private formTypeService: FormTypeService,
    private formService: FormService,
    private serviceTemplateTypesService: ServiceTemplateTypeService,
    public formatterService: FormatterService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      if (+params['id'] === this.formService.defaultId) {
        this.isNew = true;
        this.addOrUpdate = 'Create';
        this.formId = 0;
      } else {
        this.formId = +params['id'];
        this.isNew = false;
        this.addOrUpdate = 'Update';
      }

      this.getServiceTemplateTypesList();
      this.getFormTypes();
      this.initForm();

      this.formService.getFormById(this.formId).subscribe((form) => {
        if (!isNullOrUndefined(form)) {
          this.setFormValues(form);
        }
      });
    });
  }

  getFormTypes() {
    this.formTypes = [];
    this.formTypeService.getFormTypes().subscribe((res) => {
      this.formTypes = res;
    });
  }

  getServiceTemplateTypesList() {
    this.serviceTemplateTypes = [];
    this.serviceTemplateTypesService.getAllServiceTemplateTypes().subscribe((res) => {
      res.forEach((type) => {
        const typeData = type;
        const pushItem: ServiceTemplateType = {
          id: typeData.id,
          name: typeData.name,
        };
        this.serviceTemplateTypes.push(pushItem);
      });
    });
  }
  private initForm() {
    this.form = this.fb.group({
      id: new FormControl(this.formId, [Validators.required]),
      formTypeId: new FormControl(0, [Validators.required]),
      serviceTemplateTypeId: new FormControl(null, [Validators.required]),
      name: new FormControl('', [Validators.required]),
      notes: new FormControl(''),
      formDefinition: new FormControl(''),
    });
  }

  private setFormValues(form: Form) {
    this.form.controls['id'].setValue(form.id);
    this.form.controls['formTypeId'].setValue(form.formTypeId);
    this.form.controls['serviceTemplateTypeId'].setValue(form.serviceTemplateTypeId);
    this.form.controls['name'].setValue(form.name);
    this.form.controls['notes'].setValue(form.notes);
    this.form.controls['formDefinition'].setValue(form.formDefinition);

    this.updateServiceTemplateTypeValidator(form.formTypeId);
  }

  onFormTypeChange(args: any) {
    let selectedFormTypeId = args.value;
    this.updateServiceTemplateTypeValidator(selectedFormTypeId);
  }

  updateServiceTemplateTypeValidator(formTypeId: number) {
    this.serviceTemplateTypeValidationRequired =
      formTypeId == FormTypeEnum.Questionnaire || formTypeId == FormTypeEnum.Intake ? false : true;
    this.form.controls['serviceTemplateTypeId'].clearValidators();
    this.form.controls['serviceTemplateTypeId'].setValidators(
      this.serviceTemplateTypeValidationRequired ? [Validators.required] : null
    );
    this.form.controls['serviceTemplateTypeId'].updateValueAndValidity();
  }

  isFormDisabled(): boolean {
    return !this.form.valid;
  }

  onSubmit() {
    this.loading = true;

    try {
      if (this.isNew) {
        this.formService.addForm(this.form.value).subscribe(() => {
          this.router.navigate(['/management/forms/list', { outlets: { 'action-panel': null } }]);
        });
      } else {
        this.formService.updateForm(this.form.value).subscribe(() => {
          this.router.navigate(['/management/forms/list', { outlets: { 'action-panel': null } }]);
        });
      }
    } catch {
      this.loading = false;
    }
  }

  cancelUpdate() {
    this.formService.formChangeCancelled.next();
    this.router.navigate(['/management/forms/list', { outlets: { 'action-panel': null } }]);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
