import { Component, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateNudgesComponent } from '@app/patients/patient-tabs/patient-nudges-tab/create-nudges/create-nudges.component';
import { DocumentDetailsComponent } from '@app/patients/patient-tabs/patients-documents-tab/document-details/document-details.component';
import { Nudge } from '@models/nudges/nudge';
import { NudgesQueryParameters } from '@models/nudges/nudges-query-paramaters';
import { NudgePriority, NudgePriorityTitle } from '@models/nudges/priority';
import { NudgeReferenceType } from '@models/nudges/reference-type';
import { NudgeStatuses, NudgeStatusesTitle } from '@models/nudges/status';
import { NudgeTypes, NudgeTypesTitle } from '@models/nudges/type';
import { User } from '@models/user';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  isCompositeFilterDescriptor,
  State,
} from '@progress/kendo-data-query';
import { NudgeService } from '@services/nudge.service';
import { PatientDocumentService } from '@services/patient-documents.service';
import { PatientFormService } from '@services/patient-form.service';
import { UsersService } from '@services/users.service';
import * as moment from 'moment';
import { forkJoin, Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-nudges',
  templateUrl: './nudges.component.html',
  styleUrls: ['./nudges.component.less'],
})
export class NudgesComponent implements OnInit {
  users: User[];
  selectedNudgeType: number;
  patientId: number;
  state: State;
  gridView: any;
  filterByUser = false;
  statusTitleValues: any;
  priorityTitleValues: any;
  typeTitleValues: any;
  myNudgesCounters: any = {};
  loading = false;
  NudgePriority = NudgePriority;
  NudgeTypes = NudgeTypes;
  NudgeStatuses = NudgeStatuses;

  private currentUser: User;
  private unsub: Subject<void> = new Subject<void>();
  private defaultState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [
      {
        field: 'deferredUntil',
        dir: 'asc',
      },
    ],
  };

  constructor(
    private userService: UsersService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private nudgeService: NudgeService,
    private router: Router,
    private documentService: PatientDocumentService,
    private formService: PatientFormService,
    @Optional() private dialogRef: MatDialogRef<DocumentDetailsComponent>
  ) {}

  ngOnInit() {
    this.patientId = this.route.snapshot.params.patId && +this.route.snapshot.params.patId.split('_')[0];
    if (!this.patientId) {
      this.filterByUser = true;
      this.defaultState.filter.logic = 'or';
      this.defaultState.filter.filters.push(
        {
          field: 'status',
          operator: 'eq',
          value: NudgeStatuses.Active,
        },
        {
          field: 'status',
          operator: 'eq',
          value: NudgeStatuses.Overdue,
        }
      );
    }
    this.state = structuredClone(this.defaultState);

    this.userService.getUsers().subscribe((users: User[]) => {
      this.users = users;
    });

    this.userService.loggedInUserUpdated$
      .pipe(
        takeUntil(this.unsub),
        filter((user) => !!user.id)
      )
      .subscribe((user) => {
        this.currentUser = user;
        this.loadNudges();
      });

    this.statusTitleValues = Object.keys(NudgeStatusesTitle).map((e) => ({
      title: NudgeStatusesTitle[e],
      value: NudgeStatuses[e],
    }));

    this.priorityTitleValues = Object.keys(NudgePriorityTitle).map((e) => ({
      title: NudgePriorityTitle[e],
      value: NudgePriority[e],
    }));

    this.typeTitleValues = Object.keys(NudgeTypesTitle).map((e) => ({
      title: NudgeTypesTitle[e],
      value: NudgeTypes[e],
    }));

    this.typeTitleValues.unshift({ title: 'all', value: 0 });
    this.selectedNudgeType = this.typeTitleValues[0].value;
    this.loadNudges();
  }

  private loadNudges(): void {
    this.loading = true;
    const filters = this.flattenFilters(this.state.filter.filters);
    const query: NudgesQueryParameters = {
      page: Math.floor((this.state.skip || 0) / this.state.take),
      limit: this.state.take,
      sortBy: this.state.sort[0]?.field ?? '',
      sortDirection: this.state.sort[0]?.dir ?? 'asc',
      nudgeType: this.selectedNudgeType > 0 ? [this.selectedNudgeType] : [],
      patientId: this.patientId ? [this.patientId] : [],
      assignedTo: this.filterByUser ? [this.currentUser.id] : [],
      status: filters.filter((e) => e.field === 'status').map((e) => e.value),
      nudgeImportance: filters.filter((e) => e.field === 'importance').map((e) => e.value),
      createdBy: filters.filter((e) => e.field === 'createdBy').map((e) => e.value),
    };

    this.nudgeService.getNudges(query).subscribe((res) => {
      const result = res.results.map((e) => {
        e['completed'] = e.status === NudgeStatuses.Completed;
        return e;
      });
      this.gridView = {
        data: result,
        total: res.total || result.length,
      };

      this.loading = false;
    });
    this.getCounters();
  }

  private flattenFilters(filters: (CompositeFilterDescriptor | FilterDescriptor)[]): FilterDescriptor[] {
    return filters.reduce((previous, curr) => {
      if (isCompositeFilterDescriptor(curr)) {
        previous.push(...this.flattenFilters(curr.filters));
        return previous;
      }
      previous.push(curr);
      return previous;
    }, []);
  }

  private getCounters() {
    forkJoin([
      this.nudgeService.getNudgesCount(),
      this.nudgeService.getNudgesCount(NudgeTypes['Dictation/Letter']),
      this.nudgeService.getNudgesCount(NudgeTypes['Follow-Up']),
      this.nudgeService.getNudgesCount(NudgeTypes.Investigation),
      this.nudgeService.getNudgesCount(NudgeTypes.Referral),
      this.nudgeService.getNudgesCount(NudgeTypes.Other),
      this.nudgeService.getNudgesCount(NudgeTypes.Inventory),
    ]).subscribe(([res1, res2, res3, res4, res5, res6, res7]) => {
      this.myNudgesCounters = {
        0: res1,
        1: res2,
        2: res3,
        3: res4,
        4: res5,
        5: res6,
        6: res7,
      };
    });
  }

  dataStateChange(state: State) {
    this.state = state;
    this.loadNudges();
  }

  filterByUserChange() {
    this.state.skip = 0;
    this.loadNudges();
  }

  selectNudgeType(value: number) {
    this.selectedNudgeType = value;
    this.state.skip = 0;
    this.loadNudges();
  }

  defaultFilters() {
    this.state = structuredClone(this.defaultState);
    this.loadNudges();
  }

  openNudgeModal({ sender, rowIndex, dataItem }) {
    const dialogRef = this.dialog.open(CreateNudgesComponent, {
      panelClass: 'custom-dialog-container',
      width: '550px',
      data: dataItem,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.loadNudges();
      }
    });
  }

  navigateToPatientChart(id: number): void {
    this.router.navigate([
      '/nudges',
      {
        outlets: { 'action-panel': ['patient', id + '_patientcharttab', 'patienttabs', 'patientcharttab', 'overview'] },
      },
    ]);
  }

  navigateToReferenceLink(item: Nudge) {
    this.router.navigateByUrl(item.referenceLink);
  }

  previewPatientDocument(item: Nudge) {
    this.dialogRef = this.dialog.open(DocumentDetailsComponent, {
      panelClass: 'document-view-modal',
      disableClose: true,
    });
    this.dialogRef
      .afterOpened()
      .pipe(first())
      .subscribe(() => {
        this.documentService
          .getDocument(item.referenceId)
          .pipe(first())
          .subscribe((res) => {
            this.documentService.documentSelected.next(res);
          });
      });
  }

  previewPatientClinicDocument(item: Nudge) {
    var dialogRef = this.dialog.open(DocumentDetailsComponent, {
      panelClass: 'document-view-modal',
      disableClose: true,
    });
    dialogRef
      .afterOpened()
      .pipe(first())
      .subscribe(() => {
        this.documentService
          .getPatientClinicDocument(item.referenceId)
          .pipe(first())
          .subscribe((res) => {
            this.documentService.documentSelected.next(res);
          });
      });
  }

  previewPatientForm(item: Nudge) {
    var dialogRef = this.dialog.open(DocumentDetailsComponent, {
      panelClass: 'document-view-modal',
      disableClose: true,
    });
    dialogRef
      .afterOpened()
      .pipe(first())
      .subscribe(() => {
        this.formService.getPatientFormById(item.referenceId).subscribe((res) => {
          this.formService.patientFormSelected$.next(res);
        });
      });
  }

  updateStatus(item, event) {
    const data = Object.assign({}, item);
    data.completed = event.target.checked;
    data.assignedTo = item.assignedTo.id;
    this.nudgeService.updateNudge(data, item.nudgeId).subscribe((res) => {
      this.loadNudges();
      this.nudgeService.updateNudgeCount();
    });
  }

  returnDateTimeUTC(date: string | moment.Moment) {
    return moment(date).format('YYYY-MM-DD hh:mma');
  }

  sortIconClass(column: any) {
    return this.state.sort.length && this.state.sort[0].field === column.field && this.state.sort[0].dir === 'asc'
      ? 'fad fa-sort-up'
      : this.state.sort.length && this.state.sort[0].field === column.field && this.state.sort[0].dir === 'desc'
      ? 'fad fa-sort-down'
      : 'fas fa-sort';
  }

  isGreaterThanOneWeekOverdue(dueDate: Date) {
    return moment().diff(moment(dueDate), 'days') > 7;
  }

  isServiceReferenceType(item): boolean {
    return item && item.referenceType == NudgeReferenceType.Service;
  }

  isPatientDocumentReferenceType(item): boolean {
    return item && item.referenceType == NudgeReferenceType.PatientDocument;
  }

  isPatientClinicDocumentReferenceType(item): boolean {
    return item && item.referenceType == NudgeReferenceType.PatientClinicDocument;
  }

  isPatientFormReferenceType(item): boolean {
    return item && item.referenceType == NudgeReferenceType.PatientForm;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
    this.dialog.closeAll();
  }
}
