import { Directive, HostListener, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[onlyNumber]'
})
export class OnlyNumberDirective {
  @Input('numericType') numericType: string = 'number'; // number | decimal
  @Input('maxDigits') maxDigits: number = null;
  @Input('allowNegative') set allowNegative(allowNegative){

    if (allowNegative){
      this.specialKeys.number.push('-') ;
      this.specialKeys.decimal.push('-') ;
      this.regex = {
        number: new RegExp(/^\-?\d+$/),
        decimal: new RegExp(/^\-?[0-9]+(\.[0-9]*){0,1}$/g)
      }
    }



  }

  private regex = {
    number: new RegExp(/^\d+$/),
    decimal: new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g)
  };

  private specialKeys = {
    number: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' ],
    decimal: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' ],
  };

  constructor(private el: ElementRef) {}

  @HostListener('keydown', [ '$event' ])
  onKeyDown(event: KeyboardEvent) {
    let current: string = this.el.nativeElement.value;
    if (this.specialKeys[this.numericType].indexOf(event.key) !== -1 && (!this.allowNegative || current.lastIndexOf('-') <= 0 )) {
      return;
    }
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex[this.numericType]) || (this.maxDigits && next.length > this.maxDigits)) {
      event.preventDefault();
    }
  }
}
