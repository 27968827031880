<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>
<div class="grid-container pb-2">
  <kendo-grid
    class="ml-1"
    [data]="gridView"
    [pageSize]="gridState.take"
    [skip]="gridState.skip"
    [sort]="gridState.sort"
    [sortable]="{
      allowUnsort: true,
      mode: 'multiple'
    }"
    [selectable]="true"
    [style.height.%]="100"
    (sortChange)="sortChange($event)"
    (add)="onAddClick($event)"
    (cancel)="cancelHandler($event)"
    (save)="saveHandler($event)"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <div class="d-flex">
        <span class="pl-4 search-box border border-radius">
          <span class="fa fa-search"></span>
          <mat-form-field class="w-75 px-3">
            <input
              matInput
              placeholder="Search..."
              aria-label="Search Resources"
              [(ngModel)]="searchValue"
              [formControl]="searchCtrl"
            />
            <button
              mat-button
              *ngIf="searchValue"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="searchValue = ''"
            >
              <mat-icon><i class="fa fa-times-circle"></i></mat-icon>
            </button>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let resource of filteredResources | async" [value]="resource.name">
                <span>{{ resource.name }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </span>
      </div>
      <button kendoGridAddCommand class="p-2 btn em-btn"><i class="fal fa-plus-circle"></i> Add Resource</button>
    </ng-template>
    <kendo-grid-column field="resourceType" title="Resource Type" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ ResourceType[dataItem.resourceType] }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="name" title="Name" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="details" title="Details" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ getDetails(dataItem.resourceType) }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="Actions" [width]="100">
      <ng-template kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridEditCommand class="btn kendo-btn"><i class="fas fa-edit"></i></button>
        <button kendoGridRemoveCommand class="btn kendo-btn"><i class="fas fa-minus-circle"></i></button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
<p-overlayPanel #hoverPanel id="hoverPanel" [dismissable]="false"> Content </p-overlayPanel>
<app-actionpanel></app-actionpanel>
