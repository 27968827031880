import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';
import { Tax } from '@models/tax';
import { ClinicsService } from '@services/clinics.service';
import { TaxService } from '@services/tax.service';
import { Subject } from 'rxjs';
import { ClinicTax } from '@models/clinic';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-org-taxes',
  templateUrl: './org-taxes.component.html',
  styleUrls: ['./org-taxes.component.less'],
})
export class OrgTaxesComponent implements OnInit {
  loading: boolean = false;
  disableGrid: boolean = false;
  unsub = new Subject<any>();
  gridData: any[];

  constructor(
    private clinicsService: ClinicsService,
    private taxService: TaxService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.gridData = [];
    this.getTaxInfo();
    this.taxService.taxesUpdateSource$.pipe(takeUntil(this.unsub)).subscribe((updated) => {
      if (updated) this.getTaxInfo();
      this.disableGrid = false;
    });
  }

  private async getTaxInfo() {
    const taxesPromise = this.taxService.getTaxes().toPromise();
    const clinicTaxesPromise = this.taxService.getClinicTaxes().toPromise();
    let taxes: Tax[];
    let clinicTaxes: ClinicTax[];
    [taxes, clinicTaxes] = await Promise.all([taxesPromise, clinicTaxesPromise]);

    this.gridData = [];
    taxes.forEach((tax) => {
      const clinicTax = clinicTaxes.find((ct) => ct.taxId == tax.taxId);
      const toAdd = tax as any;
      if (clinicTax) toAdd.isActive = true;
      else toAdd.isActive = false;
      this.gridData.push(toAdd);
    });
  }

  public onAddClick({ sender }) {
    this.disableGrid = true;
    this.router.navigate([
      this.router.routerState.snapshot.url,
      { outlets: { 'action-panel': ['edit-tax', '_'] } },
    ]);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.disableGrid = true;
    this.router.navigate([
      this.router.routerState.snapshot.url,
      { outlets: { 'action-panel': ['edit-tax', dataItem.taxId] } },
    ]);
  }

  public removeHandler({ dataItem }) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        // Delete tax
        this.taxService
          .deleteTax(dataItem)
          .pipe(takeUntil(this.unsub))
          .subscribe((tax) => {
            this.getTaxInfo();
          });
      }
    });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
