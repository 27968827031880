import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { SendTestEmailComponent } from '@app/management/dialogs/send-test-email/send-test-email.component';
import { UnlayerEmailEditorComponent } from '@app/shared/components/unlayer-email-editor/unlayer-email-editor.component';
import { isNullOrUndefined } from '@app/shared/helpers';
import { ClinicEmailTemplate, EmailTemplateType } from '@models/clinic-email-template';
import { EmailNotificationsSettings } from '@models/communications/email-notifications-settings.model';
import {
  CommunicationSettingType,
  CommunicationsSettingsBase,
} from '@models/communications/notifications-settings-base.model';
import { editorOptions } from '@models/email-editor-options';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClinicEmailTemplateService } from '@services/clinic-email-template.service';
import { CommunicationsService } from '@services/communications.service';
import moment from 'moment';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-email-visit-reminder',
  templateUrl: './edit-email-visit-reminder.component.html',
  styleUrls: ['./edit-email-visit-reminder.component.less'],
})
export class EditEmailVisitReminderComponent implements OnInit {
  private unsub: Subject<void> = new Subject<void>();
  loading: boolean;
  templateLoading = false;
  iPad =
    navigator.userAgent.match(/(iPad)/) /* iOS pre 13 */ ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) /* iPad OS 13 */;

  reminderSettingsForm: FormGroup;
  minEndTime = moment().toDate();
  minimumDuration: number = 5;
  senderUnits: string[] = ['hours', 'days', 'weeks', 'months'];

  workerStartTime = moment().startOf('day').toDate();
  workerEndTime = moment().startOf('day').toDate();

  defaultStartTime = '7:00:00';
  defaultEndTime = '19:00:00';

  enabled: boolean;

  disabledForm = false;
  initialized = false;

  showSuccessMessage = false;
  emailTemplates: ClinicEmailTemplate[] = [];
  @ViewChild(UnlayerEmailEditorComponent) emailEditor: UnlayerEmailEditorComponent;
  editorOptions = editorOptions;
  emailDetailsObj: object;
  emailHtml: String;
  emailEditorEdited: boolean = false;

  submitted = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private communicationsService: CommunicationsService,
    private clinicEmailTemplateService: ClinicEmailTemplateService,
    private zone: NgZone,
    private dialog: MatDialog,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      this.initForm();
      this.toggleRepeatNotificationValidators();
      this.populateCommunicationSettingsValue();
    });
  }

  initForm() {
    this.reminderSettingsForm = new FormGroup({
      emailSenderName: new FormControl('', Validators.required),
      emailSenderEmail: new FormControl('', [Validators.email, Validators.required]),
      emailSubjectLine: new FormControl('', Validators.required),
      firstNotificationPeriod: new FormControl('', Validators.required),
      firstNotificationUnit: new FormControl('', Validators.required),
      repeatNotificationPeriod: new FormControl('', Validators.required),
      repeatNotificationUnit: new FormControl('', Validators.required),
      enabledRepeatNotification: new FormControl(true),
      template: new FormControl(''),
    });
    setTimeout(() => {
      this.initialized = true;
    });

    this.reminderSettingsForm
      .get('template')
      .valueChanges.pipe(takeUntil(this.unsub))
      .subscribe((template: ClinicEmailTemplate) => {
        // this.getTemplateDetails();
        this.emailEditor.editor.loadDesign(JSON.parse(template.definition));
      });
  }

  toggleRepeatNotificationValidators() {
    this.reminderSettingsForm.controls['enabledRepeatNotification'].valueChanges.subscribe((status: boolean) => {
      this.reminderSettingsForm.controls['repeatNotificationPeriod'].setValidators(
        status ? [Validators.required] : null
      );
      this.reminderSettingsForm.controls['repeatNotificationUnit'].setValidators(status ? [Validators.required] : null);
      this.reminderSettingsForm.controls['repeatNotificationPeriod'].updateValueAndValidity();
      this.reminderSettingsForm.controls['repeatNotificationUnit'].updateValueAndValidity();
    });
  }

  populateCommunicationSettingsValue() {
    this.loading = true;
    combineLatest([
      this.clinicEmailTemplateService.getClinicEmailTemplatesByType(EmailTemplateType.AppointmentReminder),
      this.communicationsService.getEmailNotificationsSettingsByType(CommunicationSettingType.Reminder),
    ])
      .pipe(takeUntil(this.unsub))
      .subscribe(([emailTemplates, reminderSettings]) => {
        this.emailTemplates = emailTemplates;
        this.populateEmailSettingsValues(reminderSettings);
        this.populateCommonSettingsValues(reminderSettings);
        this.loading = false;
      });
  }

  populateEmailSettingsValues(settings: EmailNotificationsSettings) {
    if (this.emailEditor?.editor && settings.emailJson && settings.emailJson != '') {
      this.reloadEmailEditor(settings.emailJson, settings.emailHtml);
    }

    let selectedTemplate = this.emailTemplates.find((_) => _.id === settings.createdFromEmailTemplateId);
    if (isNullOrUndefined(selectedTemplate)) {
      selectedTemplate = null;
    }

    this.reminderSettingsForm.controls['emailSenderName'].setValue(settings.senderName);
    this.reminderSettingsForm.controls['emailSenderEmail'].setValue(settings.senderEmail);
    this.reminderSettingsForm.controls['emailSubjectLine'].setValue(settings.emailSubjectLine);
    this.reminderSettingsForm.controls['template'].setValue(selectedTemplate, { emitEvent: false });
  }

  reloadEmailEditor(definition: string, html: string): void {
    this.templateLoading = true;
    this.emailDetailsObj = JSON.parse(definition);
    this.emailEditor.editor.loadDesign(this.emailDetailsObj);
    if (!isNullOrUndefined(html)) this.emailHtml = html;

    this.templateLoading = false;
  }

  populateCommonSettingsValues(settings: CommunicationsSettingsBase) {
    if (settings.workerStartTime.hours() == 0) {
      settings.workerStartTime = moment.duration(this.defaultStartTime);
    }

    if (settings.workerEndTime.hours() == 0) {
      settings.workerEndTime = moment.duration(this.defaultEndTime);
    }

    this.enabled = settings.enabled;
    this.workerStartTime = moment().startOf('day').add(settings.workerStartTime).toDate();
    this.workerEndTime = moment().startOf('day').add(settings.workerEndTime).toDate();
    if (settings.firstNotificationBefore) {
      this.parseSenderValue(settings.firstNotificationBefore, 'firstNotificationPeriod', 'firstNotificationUnit');
    }

    if (settings.repeatNotificationEvery) {
      this.parseSenderValue(settings.repeatNotificationEvery, 'repeatNotificationPeriod', 'repeatNotificationUnit');
    } else {
      this.reminderSettingsForm.controls['enabledRepeatNotification'].setValue(false);
    }
  }

  saveChanges() {
    this.saveEmailSettings();
    this.router.navigate(['/management/communication/appointments/', { outlets: { 'action-panel': null } }]);
  }

  saveEmailSettings() {
    const data = new EmailNotificationsSettings();
    data.communicationSettingType = CommunicationSettingType.Reminder;
    data.enabled = this.enabled;
    if (this.reminderSettingsForm.controls['template'].value != null)
      data.createdFromEmailTemplateId = this.reminderSettingsForm.controls['template'].value.id;
    data.senderName = this.reminderSettingsForm.controls['emailSenderName'].value;
    data.senderEmail = this.reminderSettingsForm.controls['emailSenderEmail'].value;
    data.emailSubjectLine = this.reminderSettingsForm.controls['emailSubjectLine'].value;
    data.workerStartTime = this.stripTimeAsDuration(this.workerStartTime);
    data.workerEndTime = this.stripTimeAsDuration(this.workerEndTime);
    data.firstNotificationBefore = this.formatSenderValue('firstNotificationPeriod', 'firstNotificationUnit');
    if (this.reminderSettingsForm.controls['enabledRepeatNotification'].value) {
      data.repeatNotificationEvery = this.formatSenderValue('repeatNotificationPeriod', 'repeatNotificationUnit');
    }

    this.emailEditor.exportHtml((htmlObj: any) => {
      this.emailEditor.editor.saveDesign((definition) => {
        data.emailHtml = htmlObj.html;
        data.emailJson = JSON.stringify(definition);
        // Update virtual template in list
        if (this.emailTemplates.length >= 1 && this.emailTemplates[0].id == 0) {
          this.emailTemplates[0].name = data.senderName + "'s Saved Template";
          this.emailTemplates[0].definition = data.emailJson;
          this.emailTemplates[0].html = data.emailHtml;
        } else {
          this.addVirtualTemplateToList(data);
        }
        this.communicationsService.updateEmailNotificationsSettings(data).subscribe(
          () => {
            this.communicationsService.refreshRequired = true;
            this.communicationsService.communicationsSettingsUpdated.next();
            this.emailEditorEdited = false;
            this.submitted = true;
            this.showSuccessMessage = true;
            this.zone.run(() => (this.loading = false));
            this.reminderSettingsForm.markAsPristine();
            this.emailEditorEdited = false;
            setTimeout(async () => {
              this.showSuccessMessage = false;
            }, 5000);
          },
          (error) => {
            this.loading = false;
            console.error(error);
          }
        );
      });
    });
  }

  addVirtualTemplateToList(settings: EmailNotificationsSettings) {
    this.emailTemplates.unshift({
      id: 0,
      emailTemplateType: 2,
      name: settings.senderName + "'s Saved Template",
      description: '',
      definition: settings.emailJson,
      html: settings.emailHtml,
      createdByUser: null,
      createdDate: new Date(),
      modifiedByUser: null,
      modifiedDate: new Date(),
    });
  }

  cancelUpdate() {
    this.communicationsService.refreshRequired = false;
    this.communicationsService.communicationsSettingsUpdated.next();
    this.router.navigate(['/management/communication/appointments/', { outlets: { 'action-panel-secondary': null } }]);
  }

  onStartTimeChange(date: Date) {
    this.minEndTime = moment(date).add(this.minimumDuration.toString(), 'minutes').toDate();
  }

  onEndTimeChange(date: Date) {}

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  private parseSenderValue(value: string, periodCtrl: string, unitCtrl: string) {
    const split = value.split(' ');
    if (split.length === 1) {
      this.reminderSettingsForm.controls[periodCtrl].setValue(1);
      this.reminderSettingsForm.controls[unitCtrl].setValue(split[0] + 's');
    } else {
      this.reminderSettingsForm.controls[periodCtrl].setValue(split[0]);
      this.reminderSettingsForm.controls[unitCtrl].setValue(split[1]);
    }
  }

  private formatSenderValue(periodCtrl: string, unitCtrl: string): string {
    const value = this.reminderSettingsForm.controls[periodCtrl].value;
    const unit = this.reminderSettingsForm.controls[unitCtrl].value;
    if (value === 1) {
      return unit.substr(0, unit.length - 1);
    }
    return value + ' ' + unit;
  }

  private stripTimeAsDuration(dateTime: Date): moment.Duration {
    const startStr = dateTime.getHours() + ':' + dateTime.getMinutes() + ':00';
    return moment.duration(startStr);
  }

  loadEditor(event) {
    if (this.emailDetailsObj) this.emailEditor.editor.loadDesign(this.emailDetailsObj);
    if (this.iPad) this.emailEditor.editor.showPreview('Mobile');
  }

  editorReady(event) {
    this.zone.run(() => (this.templateLoading = false));
    this.emailEditor.editor.addEventListener('design:updated', (data) => {
      this.emailEditorEdited = true;
    });
    this.emailEditor.editor.addEventListener('design:loaded', (data) => {
      this.zone.run(() => (this.templateLoading = false));
    });
  }

  revertToOriginalTemplate() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '330px',
      data: {
        title: 'Revert to Original Template',
        content:
          'Are you sure you want to revert to the original email template definition? All your changes will be lost.',
        confirmButtonText: 'Yes',
        showCancel: true,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') {
          this.loading = true;
          var selectedTemplateId = this.reminderSettingsForm.controls['template'].value.id;
          let selectedTemplate = this.emailTemplates.find((_) => _.id === selectedTemplateId);
          if (this.emailEditor?.editor && !isNullOrUndefined(selectedTemplate)) {
            this.reloadEmailEditor(selectedTemplate.definition, selectedTemplate.html);
          }
          this.loading = false;
        }
      });
  }

  sendTest(type: string) {
    const sendTestModal = this.modalService.open(SendTestEmailComponent, {
      centered: true,
      windowClass: 'send-test-modal',
    });
    sendTestModal.componentInstance.type = type;
    sendTestModal.componentInstance.quickView = true;
    sendTestModal.componentInstance.emailTemplateType = EmailTemplateType.AppointmentReminder;
  }
}
