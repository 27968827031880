<ngx-loading [show]="loading"></ngx-loading>

<div class="edit-container position-absolute d-block z-index-3 white-background">
  <div class="re-links-container" kendoTooltip tooltipClass="em-tooltip" position="top">
    <div class="re-links">
      <button class="btn em-btn" (click)="cancelUpdate()">
        <i class="far fa-times" aria-hidden="true" title="Close Panel"></i>
      </button>
    </div>
  </div>
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0">
    <div class="row mx-0 teal-background">
      <div class="col px-2">
        <h6 class="white-font uppercase p-2 mb-0">{{ addOrEdit }} Product</h6>
      </div>
    </div>
    <div class="row mx-0 px-2">
      <div class="col px-2">
        <mat-form-field [floatLabel]="'always'" class="remove-underline">
          <mat-select
            readonly
            placeholder="Product Brand"
            name="productBrands"
            [(ngModel)]="clinicProduct.product.productBrandId"
            [formControl]="productBrand"
          >
            <mat-option *ngFor="let productBrand of productBrands" [value]="productBrand.id">
              {{ productBrand.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0 px-2">
      <div class="col pl-2 pr-4">
        <mat-form-field [floatLabel]="'always'" class="d-block remove-underline">
          <input
            matInput
            placeholder="Product Name"
            class="capitalized"
            aria-label="Product Name"
            [(ngModel)]="clinicProduct.displayName"
            [formControl]="name"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0 teal-background">
      <div class="col">
        <h6 class="white-font uppercase mb-0 py-2">Product Details</h6>
      </div>
    </div>
    <div class="row mx-0 px-2">
      <div class="col pl-2 pr-4">
        <mat-form-field class="d-block">
          <input
            matInput
            placeholder="Product Code"
            aria-label="Diagnostic Code"
            [(ngModel)]="clinicProduct.productCode"
            [formControl]="productCode"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0 px-2">
      <div class="col px-2">
        <mat-form-field>
          <mat-select
            placeholder="Product Category"
            [(ngModel)]="clinicProduct.productCategoryId"
            name="productCategories"
          >
            <mat-option *ngFor="let category of categories" [value]="category.productCategoryId">
              {{ category.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input
            type="number"
            matInput
            placeholder="Quantity In-Stock"
            aria-label="Quantity In-Stock"
            [(ngModel)]="clinicProduct.quantityInStock"
            [formControl]="quantityInStock"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0 px-2">
      <div class="col px-2">
        <mat-form-field>
          <input
            type="number"
            matInput
            placeholder="Reorder Quantity"
            aria-label="Quantity"
            [(ngModel)]="clinicProduct.quantity"
            [formControl]="quantity"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0 pt-1 px-2">
      <div class="col px-0">
        <h6 class="p-2 mb-0 uppercase">Billing Details</h6>
      </div>
    </div>
    <div class="row mx-0 px-2">
      <div class="col px-2">
        <mat-form-field>
          <input
            type="number"
            matInput
            required
            placeholder="Retail Price"
            aria-label="Retail Price"
            [(ngModel)]="clinicProduct.retailPrice"
            [formControl]="retailPrice"
            type="number"
          />
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input
            type="number"
            matInput
            placeholder="Wholesale Price"
            aria-label="Wholesale Price"
            [(ngModel)]="clinicProduct.wholesalePrice"
            [formControl]="wholesalePrice"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0 px-2">
      <div class="col px-2">
        <mat-form-field>
          <mat-select
            placeholder="Tax Indicator"
            name="taxIndicator"
            [(ngModel)]="clinicProduct.taxIndicator"
            required
            [formControl]="taxIndicator"
          >
            <mat-option *ngFor="let item of taxIndicators" [value]="item.value">
              {{ item.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <mat-select
            placeholder="Taxes"
            name="taxes"
            multiple
            [(ngModel)]="selectedTaxes"
            [compareWith]="compareClinicTaxObjects"
          >
            <mat-option *ngFor="let clinicTax of taxes" [value]="clinicTax">
              {{ clinicTax?.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0 pt-1 px-2">
      <div class="col px-0">
        <h6 class="p-2 mb-0 uppercase">Usage details</h6>
      </div>
    </div>
    <div class="row mx-0 px-2">
      <div class="col px-2">
        <mat-form-field>
          <input
            matInput
            placeholder="Usage Instructions"
            aria-label="Usage Instructions"
            [(ngModel)]="clinicProduct.usageInstructions"
            class="w-100"
          />
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input type="number" matInput placeholder="Usage Duration (Days)" [(ngModel)]="clinicProduct.usageDuration" />
        </mat-form-field>
      </div>
    </div>

    <div class="row mx-0 pt-1">
      <div class="col px-0">
        <h6 class="p-2 mb-0">ePOINTS</h6>
      </div>
    </div>
    <div class="row mx-0 px-2">
      <div class="col px-2">
        <span>$1 = </span>
        <mat-form-field>
          <input
            type="number"
            matInput
            [(ngModel)]="clinicProduct.dollarsToLoyaltyPointRate"
            [formControl]="dollarsToLoyaltyPointRate"
          />
        </mat-form-field>
        <span class="ml-1">ePoints</span>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col px-2 text-center">
        <button class="btn em-btn em-btn-green mr-2" [disabled]="!productFormValid()" (click)="updateProduct()">
          {{ addOrEdit == 'Add' ? 'Add' : 'Update' }} Product
        </button>

        <button class="btn em-btn em-btn-green" (click)="cancelUpdate()">Cancel</button>
      </div>
    </div>
  </perfect-scrollbar>
</div>
