import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gift-cards',
  templateUrl: './gift-cards.component.html',
  styleUrls: ['./gift-cards.component.less']
})
export class GiftCardsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
