import { Component, OnInit, OnDestroy, ViewChildren } from '@angular/core';
import { ReportsService } from '@services/reports.service';
import { Subject, forkJoin } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { takeUntil } from 'rxjs/operators';
import { IReportComponent } from '@models/reports/ireport-component';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-liabilities-report',
  templateUrl: './liabilities-report.component.html',
  styleUrls: ['./liabilities-report.component.less']
})
export class LiabilitiesReportComponent implements OnInit, OnDestroy, IReportComponent {
  @ViewChildren('grid') grid;
  gridViews: GridDataResult[];
  gridHeaders: string[];
  loading: boolean = false;
  unsub: Subject<void> = new Subject<void>();

  constructor(
    private reportsService: ReportsService,
    private currencyPipe: CurrencyPipe
    ) {}

    ngOnInit() {
      this.initDefaultValue();
      this.getReportData();
    }

    initDefaultValue() {
      this.gridViews = [
        {data: [], total: 0},
        {data: [], total: 0 },
        {data: [], total: 0},
        {data: [], total: 0}
      ];
      this.gridHeaders = [
        'Prepayments Summary',
        'Credits Summary',
        'ePoints Summary',
        'Gift Cards Summary',
      ];
    }

    getReportData() {
      this.loading = true;
      forkJoin(
        this.reportsService.getClinicPrepaymentsReportEntities(),
        this.reportsService.getClinicCreditsReportEntities(null, true),
        this.reportsService.getClinicePointsReportEntities(),
        this.reportsService.getClinicGiftCardsReportEntities(),
      ).pipe(takeUntil(this.unsub))
       .subscribe((response: any) => {
        this.gridViews.forEach((gridView, index) => {
            gridView.data = response[index].map((item) => ({...item, total: this.currencyPipe.transform(item.total)}));
            gridView.total = response[index].length;
          });
          this.loading = false;
       });
      }

      exportToExcel() {
        this.grid.toArray().forEach((item) => item.saveAsExcel());
      }

      onExcelExport(e: any, index: number) {
        this.reportsService.onExcelExport(e, `${this.gridHeaders[index]} Report`, 0);
      }

      getFileName(pdf: boolean, index: number) {
        const format = pdf ? '.pdf' : '.xlsx';
        const title = this.gridHeaders[index].replace(/\s+/g, '_').toLowerCase();
        return `${title}_report${format}`;
      }

      ngOnDestroy() {
        this.unsub.next();
        this.unsub.complete();
      }

}
