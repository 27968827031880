<div class="edit-container position-absolute d-block z-index-3 white-background">
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0;">
    <div class="row mx-0 teal-background">
      <div class="col px-2">
        <h6 class="white-font uppercase p-2 mb-0">
          {{addOrEdit}} Lab
        </h6>
      </div>
    </div>
    <div class="row pt-2 mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="Lab Name" class="capitalized" aria-label="Lab Name"
            (change)="formatterService.textCapitalize(name);updateSubmitButtonState()" [(ngModel)]="editedLab.name" [formControl]="name" required>
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="Address 1" class="capitalized" aria-label="Address1"
            (change)="formatterService.textCapitalize(addressAddress1);updateSubmitButtonState()" [(ngModel)]="editedLab.address.address1"
            [formControl]="addressAddress1">
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="Address 2" class="capitalized" aria-label="Address2"
            (change)="formatterService.textCapitalize(addressAddress2);updateSubmitButtonState()" [(ngModel)]="editedLab.address.address2"
            [formControl]="addressAddress2">
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="City" class="capitalized" aria-label="City"
            (change)="formatterService.textCapitalize(addressCity);updateSubmitButtonState()" [(ngModel)]="editedLab.address.city"
            [formControl]="addressCity">
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <mat-select [(ngModel)]="editedLab.address.province" name="usStates" (selectionChange)="updateSubmitButtonState()">
            <mat-option *ngFor="let provinceState of provincesAndStates" [value]="provinceState">
              {{ provinceState }}
            </mat-option>
          </mat-select>
          <mat-placeholder class="placeholder">{{editedLab.address.country == 'United States' ? 'State' : 'Province'}}
          </mat-placeholder>
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <mat-select placeholder="Country" [(ngModel)]="editedLab.address.country" name="usStates"
            (selectionChange)="onChangeCountry();updateSubmitButtonState()">
            <mat-option *ngFor="let country of countriesOfTheWorld" [value]="country">
              {{ country }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input matInput aria-label="Postal Code" class="uppercased" [(ngModel)]="editedLab.address.postalCode"
            (change)="formatterService.textUppercase(addressPostalCode);updateSubmitButtonState()"
            [formControl]="addressPostalCode">
          <mat-placeholder class="placeholder">
            {{editedLab.address.country == 'United States' ? 'Zip Code' : 'Postal Code'}}</mat-placeholder>
          <mat-error *ngIf="addressPostalCode.invalid">
            {{editedLab.address.country == 'United States' ? 'Not a Valid Zip Code' : 'Not a Valid Postal Code'}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="Phone Number" aria-label="Phone Number" [(ngModel)]="editedLab.phoneNumber1"
            (change)="formatterService.formatPhoneNumber(phoneNumber1);updateSubmitButtonState()"
            [formControl]="phoneNumber1">
          <mat-error *ngIf="phoneNumber1.invalid">Not a Valid Phone #</mat-error>
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="Phone Number - Alternate 1" aria-label="Phone Number - Alternate 1"
            [(ngModel)]="editedLab.phoneNumber2"
            (change)="formatterService.formatPhoneNumber(phoneNumber2);updateSubmitButtonState()"
            [formControl]="phoneNumber2">
          <mat-error *ngIf="phoneNumber2.invalid">Not a Valid Phone #</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="Phone Number - Alternate 2" aria-label="Phone Number - Alternate 2"
            [(ngModel)]="editedLab.phoneNumber3"
            (change)="formatterService.formatPhoneNumber(phoneNumber3);updateSubmitButtonState()"
            [formControl]="phoneNumber3">
          <mat-error *ngIf="phoneNumber3.invalid">Not a Valid Phone #</mat-error>
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="Fax Number" aria-label="Fax Number" [(ngModel)]="editedLab.faxNumber"
            (change)="formatterService.formatPhoneNumber(faxNumber);updateSubmitButtonState()"
            [formControl]="faxNumber">
          <mat-error *ngIf="faxNumber.invalid">Not a Valid Fax #</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="Email" aria-label="Email" [(ngModel)]="editedLab.email" [formControl]="email"
            (change)="updateSubmitButtonState()">
          <mat-error *ngIf="email.invalid">Not a Valid Email</mat-error>
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="Website" aria-label="Website" [(ngModel)]="editedLab.website"
            [formControl]="website" (change)="updateSubmitButtonState()">
          <mat-error *ngIf="website.invalid">Not a Valid URL</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field id="hoursOfOperationInput">
          <input matInput placeholder="Hours of Operation" (click)="setHoursOfOperation()"
            aria-label="Hours of Operation" [readonly]="true" [value]="hoursOfOperation">
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="Lab Type" aria-label="Lab Type" [(ngModel)]="editedLab.labType"
            [formControl]="labType" (change)="updateSubmitButtonState()">
        </mat-form-field>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col px-2 text-center">
        <button class="btn em-btn em-btn-green" (click)="updateLab()"
          [disabled]="submitButtonDisabledState">{{addOrEdit == 'Add' ? 'Add' : 'Update'}} Lab</button>
      </div>
      <div class="col px-2 text-center">
        <button class="btn em-btn em-btn-green" (click)="cancelUpdate()">Cancel</button>
      </div>
    </div>
  </perfect-scrollbar>
</div>
