import { Injectable } from '@angular/core';
import { ClinicProduct } from '@models/clinic-product';

@Injectable()
export class ProductDefaultsService {

  defaultProduct = new ClinicProduct ({
    id: null,
    displayName: '',
    productCode: '',
    quantityInStock: 0,
    retailPrice: 0,
    overrideRetailPrice: 0,
    wholesalePrice: 0,
    overrideWholesalePrice: 0,
    quantity: 0,
    usageDuration: 0,
    usageInstructions: '',
    productCategoryId: 0,
    category: { productCategoryId: 0, name: '' },
    productTaxes: []
  });

  constructor() { }

}
