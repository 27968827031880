import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observation } from '@models/observation/observation';
import { environment } from '@environments/environment';
import { throwError } from 'rxjs';
import { TreatmentType } from '@models/treatment-type';

@Injectable()
export class ObservationService {
  constructor(private http: HttpClient) {}

  getAllObservations() {
    return this.http.get<Observation[]>(environment.baseUrl + 'api/Observation').pipe(
      map((observations: Observation[]) => {
        return this.detailsToJson(observations);
      })
    );
  }

  getAllObservationsByPatientId(patientId: number) {
    return this.http.get<Observation[]>(environment.baseUrl + 'api/Observation/' + patientId).pipe(
      map((observations: Observation[]) => {
        return this.detailsToJson(observations);
      })
    );
  }

  addObservations(observations: Observation[], serviceId: number, isTreatmentPlan: boolean = false) {
    this.detailsToString(observations);
    return this.http
      .put<Observation[]>(environment.baseUrl + 'api/Observation/' + isTreatmentPlan + '/' + serviceId, observations)
      .pipe(
        map((observations: Observation[]) => {
          return this.detailsToJson(observations);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  deleteObservations(idList: number[], serviceId: number) {
    return this.http.post(environment.baseUrl + 'api/Observation/Delete/' + serviceId, idList);
  }

  detailsToString(observations: Observation[]) {
    observations.forEach((obr) => {
      if (typeof obr.details !== 'string') {
        obr.details = JSON.stringify(obr.details);
      }
    });
  }

  detailsToJson(observations: Observation[]) {
    observations.forEach((obr) => {
      this.setObrDetailsToJson(obr);
    });
    return observations;
  }

  setObrDetailsToJson(obr: Observation) {
    if (typeof obr.details === 'string') {
      obr.details = JSON.parse(obr.details);
    }
  }

  getObrKey(obr: Observation): string {
    this.setObrDetailsToJson(obr);
    if (
      obr.name === TreatmentType.Toxins ||
      obr.name === TreatmentType.Fillers ||
      obr.name === TreatmentType.Deoxycholate
    ) {
      return obr.details.area + '/' + obr.details.injected;
    } else if (obr.name === TreatmentType.Coolsculpting) {
      return obr.details.area + '/' + obr.details.applicator + '/' + obr.details.position + '/' + obr.details.angle;
    } else if (obr.name === TreatmentType.CoolTone) {
      return obr.details.area + '/' + (obr.details.location ? obr.details.location + '/' : '') + obr.details.position;
    } else {
      return null;
    }
  }
}
