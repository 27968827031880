<ngx-loading [show]="backgroundLoading || markLoading"></ngx-loading>
<div class="mt-2 mr-2 controls">
  <button
    type="button"
    kendoTooltip
    tooltipClass="em-tooltip"
    position="top"
    title="Select Watermark Image"
    class="btn em-btn em-btn-green ml-2"
    [disabled]="clinicPhotos?.length == 0"
    (click)="selectClinicPhoto()"
  >
    <i class="far fa-images"></i>
  </button>
  <div class="px-2 ml-2 rounded slider-wrapper text-white">
    <i class="far fa-adjust align-self-center px-1"></i>
    <mat-slider min="0" max="1" step="0.01" [value]="initialAlpha" (input)="alphaChange($event)"></mat-slider>
  </div>
  <button type="button" class="btn em-btn em-btn-green ml-2" (click)="onCancel()">Cancel</button>
  <button type="button" class="btn em-btn em-btn-green ml-2" (click)="onSave()">Save</button>
</div>
<div id="canvas-container" class="h-100">
  <canvas id="canvas"></canvas>
</div>
