export class ClinicConvergeMerchant {
  constructor(init?: Partial<ClinicConvergeMerchant>) {
    Object.assign(this, init);
  }

  id: number = 0;
  merchantId: string = '';
  merchantUserId: string = '';
  merchantPinCode: string = '';
}
