<div class="h-100 d-flex flex-column p-relative print-element">
  <div class="d-flex px-3 justify-content-between align-items-center" style="height: 48px; max-height: 48px">
    <div class="column-header">Documents & eForms</div>

    <div
      class="d-flex justify-content-end ml-auto"
      kendoTooltip
      tooltipClass="em-tooltip"
      position="top"
      *ngIf="showFilters"
    >
      <div ngbDropdown class="documents-list documents-list--chart">
        <button
          [hidden]="!isFiltered"
          (click)="clearFilter()"
          type="button"
          title="Filter By Type"
          class="btn btn-sm em-btn em-btn-green btn-clean mr-2 action-button"
        >
          <i class="fas fa-filter"></i>
        </button>
        <button
          [hidden]="isFiltered"
          ngbDropdownToggle
          type="button"
          title="Filter By Type"
          class="btn btn-sm em-btn em-btn-green btn-clean mr-2 action-button"
        >
          <i class="far fa-filter"></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
          <button
            type="button"
            class="dropdown-item"
            *ngFor="let formType of formTypeList"
            (click)="onFilterDocument(formType)"
          >
            {{ formType }}
          </button>
        </div>
      </div>
      <button
        (click)="assignForm()"
        type="button"
        title="Assign Form"
        class="btn btn-sm em-btn em-btn-green btn-clean action-button assign-doc-button mr-2"
      >
        <i class="far fa-plus-circle pointer"></i>
      </button>
      <button
        (click)="navigateToAllDocuments()"
        type="button"
        title="All Documents"
        class="btn btn-sm em-btn em-btn-green btn-clean action-button"
      >
        <i class="far fa-arrow-right pointer"></i>
      </button>
    </div>
  </div>

  <div class="grey-four-border-bottom" style="flex: 1; min-height: 0">
    <ngx-loading [show]="isLoading"></ngx-loading>
    <app-error
      *ngIf="errorMessage"
      [message]="errorMessage"
      [showCloseButton]="true"
      (onClose)="errorMessage = ''"
    ></app-error>
    <div *ngIf="!isLoading && listItems?.length === 0" class="panel-box p-3 w-100">
      <span>No Documents or eForms found.</span>
    </div>
    <ng-scrollbar [autoHeightDisabled]="false">
      <div class="d-flex mb-2 px-3" *ngFor="let item of listItems">
        <div class="panel-box px-3">
          <div class="d-flex flex-column mr-auto text-truncate pr-2">
            <span class="document-title">{{ item.name }}</span>
            <span>{{ item.date | date : 'yyyy-MM-dd' }}</span>
          </div>
          <ng-container *ngIf="isPatientForm(item.form)">
            <i *ngIf="item.form.isSigned" class="far fa-check bigger spring-green-font"></i>
            <i *ngIf="!item.form.isSigned" class="far fa-times bigger calendar-red-font"></i>
          </ng-container>
        </div>
        <button class="btn navy-font panel-box-button" (click)="onSelectItem(item)">
          <i class="far fa-file-alt small"></i>
        </button>
      </div>
    </ng-scrollbar>
  </div>
</div>
