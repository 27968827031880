<ngx-loading [show]="loading"></ngx-loading>
<ng-scrollbar [autoHeightDisabled]="false" style="max-height: 30vh">
  <kendo-grid
    class="emr-kendo-grid"
    [data]="salesDetails"
    [style.maxHeight.%]="100"
    [pageable]="false"
    [sortable]="{
      allowUnsort: false,
      mode: 'single'
    }"
    #grid="kendoGrid"
  >
    <kendo-grid-column field="patientName" title="Patient">
      <ng-template kendoGridHeaderTemplate let-column>
        {{ column.title }}
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <div class="truncate-ellisis">{{ dataItem.patientName | titlecase }}</div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="amount" title="Amount" format="{0:c}">
      <ng-template kendoGridHeaderTemplate let-column>
        {{ column.title }}
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <button
          type="button"
          class="btn-clean px-0 text-left underline"
          (click)="navigateToPatientInvoice(dataItem.patientId, dataItem.invoiceId)"
        >
          {{ (dataItem.amount ? dataItem.amount : 0) | currency }}
        </button>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="invoiceId" title="Invoice #">
      <ng-template kendoGridHeaderTemplate let-column>
        {{ column.title }}
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <button
          type="button"
          class="btn-clean px-0 text-left underline"
          (click)="navigateToPatientInvoice(dataItem.patientId, dataItem.invoiceId)"
        >
          {{ dataItem.invoiceId }}
        </button>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="invoiceDate" title="Date">
      <ng-template kendoGridHeaderTemplate let-column>
        {{ column.title }}
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        {{ dataItem.invoiceDate | date : 'MM-dd HH:mm' }}
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</ng-scrollbar>
