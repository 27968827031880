import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Category } from '@models/category';
import { CategoryType } from '@models/category-type';
import { CategoryService } from '@services/category.service';

@Component({
  selector: 'app-manage-categories',
  templateUrl: './manage-categories.component.html',
  styleUrls: ['./manage-categories.component.less'],
})
export class ManageCategoriesDialogComponent implements OnInit {
  categoryType: CategoryType;
  isHiddedAddBtn = false;

  list: Category[];
  title: string;

  constructor(
    public dialogRef: MatDialogRef<ManageCategoriesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: CategoryType,
    private service: CategoryService
  ) {
    this.categoryType = data;
    this.service.categoryType = data;
  }

  ngOnInit() {
    this.title = this.getTitle(this.service.categoryType);
    this.service.getAll().subscribe((list) => {
      this.list = list;
    });
  }

  private getTitle(categoryType: CategoryType) {
    switch (categoryType) {
      case CategoryType.Product:
        return `Manage Product Categories`;

      case CategoryType.Service:
        return `Manage Service Categories`;

      default:
        return categoryType;
    }
  }

  addCategory() {
    const id = 0;
    this.list.push({ id: id, name: '' });

    this.isHiddedAddBtn = true;
  }

  remove(id: number) {
    this.list = this.list.filter((c) => c.id !== id);
    if (this.isHiddedAddBtn && !id) {
      this.changeState();
    }
  }

  close() {
    this.dialogRef.close();
  }

  public changeState() {
    this.isHiddedAddBtn = !this.isHiddedAddBtn;
  }
}
