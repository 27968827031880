import { Component, OnInit, OnDestroy } from '@angular/core';
import { TextTemplate } from '@models/text-template';
import { Observable, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TextTemplatesService } from '@services/text-templates.service';
import { takeUntil, startWith, map } from 'rxjs/operators';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { isNullOrUndefined } from '@app/shared/helpers';

@Component({
  selector: 'app-catalogue-text-templates',
  templateUrl: './catalogue-text-templates.component.html',
  styleUrls: ['./catalogue-text-templates.component.less'],
})
export class CatalogueTextTemplatesComponent implements OnInit, OnDestroy {
  searchCtrl: FormControl;
  filteredTextTemplates: Observable<TextTemplate[]>;

  unsub: Subject<void> = new Subject<void>();
  disableGrid = false;
  loading = false;

  textTempltates: TextTemplate[];
  public gridView: GridDataResult;

  gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  editedRowIndex: number;
  editedDataItem: TextTemplate;

  constructor(
    private textTemplatesService: TextTemplatesService,
    private catalogueUpdatesService: CatalogueUpdatesService,
    private deleteDialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.initDefaultValue();
    this.initData();
    this.initSearchControl();
    this.refreshData();
  }

  initDefaultValue() {
    this.gridView = {
      data: [],
      total: 0,
    };
    this.loading = true;
    this.disableGrid = false;
    this.searchCtrl = new FormControl();
  }

  initData() {
    this.textTemplatesService
      .getAllTextTemplates()
      .pipe(takeUntil(this.unsub))
      .subscribe((textTemplates: TextTemplate[]) => {
        this.textTempltates = textTemplates;

        this.gridView = {
          data: textTemplates.slice(),
          total: textTemplates.length,
        };

        this.loading = false;
        this.disableGrid = false;
      });
  }

  initSearchControl() {
    this.filteredTextTemplates = this.searchCtrl.valueChanges.pipe(
      map((textTempltate) => this.filterTextTemplates(textTempltate))
    );
  }

  refreshData() {
    this.catalogueUpdatesService.catalogueUpdated.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.disableGrid = false;
      if (this.catalogueUpdatesService.refreshRequired) {
        this.catalogueUpdatesService.refreshRequired = false;
        this.initData();
      }
    });
  }

  filterTextTemplates(name: string) {
    let filterResults: TextTemplate[] = [];
    if (!isNullOrUndefined(name) && name !== '') {
      this.gridView = {
        data: this.textTempltates.filter((textTempltate) =>
          textTempltate.name.toLowerCase().includes(name.toLowerCase())
        ),
        total: this.textTempltates.filter((textTempltate) =>
          textTempltate.name.toLowerCase().indexOf(name.toLowerCase())
        ).length,
      };
      filterResults = this.textTempltates.filter((textTempltate) =>
        textTempltate.name.toLowerCase().includes(name.toLowerCase())
      );
    } else {
      this.gridView = {
        data: this.textTempltates,
        total: this.textTempltates.length,
      };
      filterResults = [];
    }
    return filterResults;
  }

  public editHandler({ dataItem }) {
    this.disableGrid = true;
    this.router.navigate([
      '/management/catalogue/text-templates',
      { outlets: { 'action-panel': ['edit-text-template', dataItem.id] } },
    ]);
  }

  onAddClick() {
    this.disableGrid = true;
    this.router.navigate([
      '/management/catalogue/text-templates',
      { outlets: { 'action-panel': ['edit-text-template', '_'] } },
    ]);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public removeHandler({ dataItem }) {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'delete') {
          this.textTemplatesService
            .deleteTextTemplate(dataItem.id)
            .pipe(takeUntil(this.unsub))
            .subscribe(() => {
              this.catalogueUpdatesService.refreshRequired = true;
              this.catalogueUpdatesService.catalogueUpdateComplete();
            });
        }
      });
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
