import { Component, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { DiscountType } from '@models/memberships/discount-type.enum';
import { PatientMembership } from '@models/memberships/patient-membership';
import { User } from '@models/user';
import { PatientMembershipService } from '@services/patient-membership.service';

@Component({
  selector: 'app-patient-membership-overview',
  templateUrl: './patient-membership-overview.component.html',
  styleUrls: ['./patient-membership-overview.component.less'],
})
export class PatientMembershipOverviewComponent implements OnInit {
  @Input() patientId: number;
  @Input() allowCancellation: boolean = false;
  patientMemberships: PatientMembership[] = [];
  DiscountType = DiscountType;
  cancelPolicy = Policies.cancelMemberships;
  earlyCancelPolicy = Policies.earlyCancelMemberships;
  loading = false;

  constructor(
    private patientMembershipService: PatientMembershipService,
    private dialogService: MatDialog,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.initMemberships();
  }

  async initMemberships() {
    this.loading = true;
    this.patientMemberships = await this.patientMembershipService
      .getPatientMemberships(this.patientId, this.allowCancellation)
      .toPromise();
    this.loading = false;
  }

  cancelMembership(membership: PatientMembership) {
    const cancellableDate = new Date(membership.cancellableDate.toDateString());
    const today = new Date(new Date().toDateString());
    const cancellable = today > cancellableDate;
    const canCancelEarly = this.authService.userSatisfiesPolicy(this.earlyCancelPolicy);

    if (!cancellable && !canCancelEarly) {
      const dialogRef = this.dialogService.open(GenericDialogComponent, {
        width: '300px',
        data: {
          title: 'Membership Not Yet Cancellable',
          content:
            'This membership is not yet cancellable. You do not have the required permission to cancel it early.',
          confirmButtonText: 'Okay',
        },
      });
      return;
    }

    const dialogRef = this.dialogService.open(GenericDialogComponent, {
      width: '300px',
      data: {
        title: 'Cancel Membership',
        content:
          (cancellable
            ? "Do you wish to cancel this patient's membership?"
            : 'This membership is not cancellable until after ' +
              `<b>${cancellableDate.toDateString()}</b>` +
              '. Do you wish to override this?') +
          '\nThe membership will still be active until the end of the current billing cycle.',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancel: true,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result !== 'confirm') return;
      membership.cancelled = true;
      await this.patientMembershipService.updatePatientMembership(membership).toPromise();
      await this.initMemberships();
    });
  }

  getCancelledByUserName(membership: PatientMembership) {
    if (!membership?.cancelledByUser) return '';
    // Instantiate new object to provide fullName getter
    let cancellationUser = new User(membership.cancelledByUser);
    return cancellationUser.fullName;
  }
}
