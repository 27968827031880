import { Subject } from 'rxjs';
import { PlannedTreatment } from '@models/treatment-planning/planned-treatment';
import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PatientService } from '@services/patient.service';
import { CurrentDataService } from '@services/currentData.service';
import { Service } from '@models/service/service';
import { ServiceListType } from '@models/service/service-list-type';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { TabType } from '@models/tab-type.enum';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { takeUntil } from 'rxjs/operators';
import { PaymentStatus } from '@models/appointments/payment-status';

@Component({
  selector: 'app-advanced-treatment',
  templateUrl: './advanced-treatment.component.html',
  styleUrls: ['./advanced-treatment.component.less'],
})
export class AdvancedTreatmentComponent implements OnInit, OnDestroy {
  @Input() set service(service) {
    this._service = service;
  }
  private _service: Service;
  get service() {
    return this.associatedPlannedTreatment ? this.associatedPlannedTreatment.service : this._service;
  }
  @ViewChild('detailSection', { static: true }) detailSection: ElementRef;

  treatmentPlanning: boolean;
  customListClasses: string[];
  patientId: number;
  typeOfTab = TabType.TreatmentPlanDetail;

  TabType = TabType;

  @HostListener('window:resize', ['$event'])
  onScreenResize() {
    if (this.detailSection) {
      this.currentDataService.serviceDetailSectionHeightUpdated(this.detailSection.nativeElement.offsetHeight);
    }
  }
  unsub: Subject<void> = new Subject<void>();

  public loading = true;
  public tab = 'fav';
  public ready = false;
  public serviceListType = ServiceListType;
  public serviceDetailTemplate = ServiceDetailTemplate;
  public associatedPlannedTreatment: PlannedTreatment;

  PaymentStatus = PaymentStatus;

  constructor(
    public patientService: PatientService,
    private route: ActivatedRoute,
    private currentDataService: CurrentDataService,
    private treatmentPlanService: TreatmentPlanService
  ) {
    this.customListClasses = ['w-100'];
  }
  ngOnDestroy() {
    this.currentDataService.serviceDetailSectionHeightUpdated(0);

    this.unsub.next();
    this.unsub.complete();
  }

  ngOnInit() {
    this.currentDataService.preventUserFromNavigation$.pipe(takeUntil(this.unsub)).subscribe((loading) => {
      this.loading = loading;
    });

    this.patientId = this.route.snapshot.params.patId.split('_')[0];
    this.currentDataService.treatmentIsDirty = false;
    this.route.params.subscribe((params) => {
      this.treatmentPlanning = JSON.parse(params.treatmentPlanning);
      if (this.treatmentPlanning) {
        this.typeOfTab = TabType.TreatmentPlanDetail;
      } else {
        this.typeOfTab = TabType.ChartDetail;
      }

      if (this.service) {
        //only do the observation copy-back if adding from the appointment
        this.treatmentPlanService.getAssociatedTreatmentByServiceId(this.service.serviceId).subscribe((pt) => {
          if (pt) {
            this.associatedPlannedTreatment = pt;
            this.associatedPlannedTreatment.service.isLocked = this._service.isLocked;
          }
          this.ready = true;
        });
      } else {
        this.ready = true;
      }
      this.loading = false;
      setTimeout(() => {
        if (this.detailSection) {
          this.currentDataService.serviceDetailSectionHeightUpdated(this.detailSection.nativeElement.offsetHeight);
        }
      });
    });
  }
}
