import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { isNullOrUndefined } from '@app/shared/helpers';
import { environment } from '@environments/environment';
import { ReportsFilterModel } from '@models/reports/reports-filter-model';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { BehaviorSubject, Observable } from 'rxjs';
import { CompanyService } from './company.service';
import { CurrentDataService } from './currentData.service';

export interface ProviderDetailsReportEntity {
  invoiceDate: Date;
  invoiceId: number;
  patientId: number;
  productAmount?: number;
  serviceAmount?: number;
  returnAmount?: number;
}

export interface ClinicReportDetailsViewModel {
  invoiceDate: Date;
  invoiceId: number;
  patientId: number;
  amount: number;
  patientName: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  get defaultReportsModel() {
    return this._defaultReportsModel;
  }
  set defaultReportsModel(model: ReportsFilterModel) {
    this._defaultReportsModel = model;
    this._reportsModelUpdated.next(model);
  }
  private _defaultReportsModel: ReportsFilterModel;
  private _reportsModelUpdated: BehaviorSubject<ReportsFilterModel>;
  reportsModelUpdated: Observable<ReportsFilterModel>;
  companyName: string;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private companyService: CompanyService,
    private http: HttpClient,
    private currentDataService: CurrentDataService
  ) {
    this._reportsModelUpdated = new BehaviorSubject<ReportsFilterModel>(null);
    this.reportsModelUpdated = this._reportsModelUpdated.asObservable();
    this.initClinic();
  }

  getCommissionsReportEntities(
    state: DataSourceRequestState,
    reportsModel?: ReportsFilterModel
  ): Observable<GridDataResult> {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString(state)}`;
    return this.http.post<GridDataResult>(
      environment.baseUrl + 'api/Reports/GetCommissionsReportEntities?' + queryStr,
      reportsModel
    );
  }

  getClinicTaxReportEntities(reportsModel?: ReportsFilterModel) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    return this.http.post(environment.baseUrl + 'api/Reports/GetClinicTaxReportEntities', reportsModel);
  }

  getClinicPaymentTypeReportEntities(reportsModel?: ReportsFilterModel) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    return this.http.post(environment.baseUrl + 'api/Reports/GetClinicPaymentTypeReportEntities', reportsModel);
  }

  getClinicIncomeRevenueReportEntities(reportsModel?: ReportsFilterModel, summaryType: string = '') {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    return this.http.post(
      environment.baseUrl + `api/Reports/GetClinicIncomeRevenueReportEntities/${summaryType}`,
      reportsModel
    );
  }

  getClinicPrepaymentsReportEntities(reportsModel?: ReportsFilterModel) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    return this.http.post(environment.baseUrl + 'api/Reports/GetClinicPrepaymentsReportEntities', reportsModel);
  }

  getClinicePointsReportEntities(reportsModel?: ReportsFilterModel) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    return this.http.post(environment.baseUrl + 'api/Reports/GetClinicePointsReportEntities', reportsModel);
  }

  getClinicGiftCardsReportEntities(reportsModel?: ReportsFilterModel) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    return this.http.post(environment.baseUrl + 'api/Reports/getClinicGiftCardsReportEntities', reportsModel);
  }

  getClinicCreditsReportEntities(reportsModel?: ReportsFilterModel, showTotalLiabilities?: boolean) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    if (!showTotalLiabilities) {
      showTotalLiabilities = false;
    }
    // if (showTotalLiabilities) { //always showing liabilities from front-end perspective on this api call. the sales summary uses the same call on the back end but with the liabilities turned off
    return this.http.post(environment.baseUrl + 'api/Reports/GetClinicCreditsWithTotalReportEntities', reportsModel);
    // } else {
    //   return this.http.post(environment.baseUrl + 'api/Reports/GetClinicCreditsReportEntities', reportsModel);
    // }
  }

  getClinicCategorySalesReportEntities(reportsModel?: ReportsFilterModel) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    return this.http.post(environment.baseUrl + 'api/Reports/GetClinicCategorySalesReportEntities', reportsModel);
  }

  getClinicReturnsReportEntities(reportsModel?: ReportsFilterModel) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    return this.http.post(environment.baseUrl + 'api/Reports/GetClinicReturnsReportEntities', reportsModel);
  }

  getPatientReportEntities(state: DataSourceRequestState, reportsModel?: ReportsFilterModel) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString(state)}`;
    return this.http.post(environment.baseUrl + 'api/Reports/GetPatientReportEntities?' + queryStr, reportsModel);
  }

  getBDPointsReportEntities(state: DataSourceRequestState, reportsModel?: ReportsFilterModel) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString(state)}`;
    return this.http.post(environment.baseUrl + 'api/Reports/GetBDPointsReportEntities?' + queryStr, reportsModel);
  }

  getInvoicesReportEntities(state: DataSourceRequestState, reportsModel?: ReportsFilterModel) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString(state)}`;
    return this.http.post(environment.baseUrl + 'api/Reports/GetInvoicesReportEntities?' + queryStr, reportsModel);
  }

  getListPatientReportEntities(state: DataSourceRequestState, reportsModel?: ReportsFilterModel) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString(state)}`;
    return this.http.post(environment.baseUrl + 'api/Reports/GetListPatientReportEntities?' + queryStr, reportsModel);
  }

  getProductReportEntities(state: DataSourceRequestState, reportsModel?: ReportsFilterModel) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString(state)}`;
    return this.http.post(environment.baseUrl + 'api/Reports/GetProductReportEntities?' + queryStr, reportsModel);
  }

  getServicesReportEntities(state: DataSourceRequestState, reportsModel?: ReportsFilterModel) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString(state)}`;
    return this.http.post(environment.baseUrl + 'api/Reports/GetServicesReportEntities?' + queryStr, reportsModel);
  }

  getServiceDetailReportEntities(
    clinicServiceTemplateId: number,
    state: DataSourceRequestState,
    reportsModel?: ReportsFilterModel
  ) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString(state)}`;
    return this.http.post<GridDataResult>(
      environment.baseUrl + 'api/Reports/GetServiceDetailReportEntities/' + clinicServiceTemplateId + '?' + queryStr,
      reportsModel
    );
  }

  getProductPossibleTaxes(reportsModel?: ReportsFilterModel) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    return this.http.post(environment.baseUrl + 'api/Reports/GetProductPossibleTaxes', reportsModel);
  }

  getServicePossibleTaxes(reportsModel?: ReportsFilterModel) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    return this.http.post(environment.baseUrl + 'api/Reports/GetServicePossibleTaxes', reportsModel);
  }
  getMembershipPossibleTaxes(reportsModel?: ReportsFilterModel) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    return this.http.post(environment.baseUrl + 'api/Reports/GetMembershipPossibleTaxes', reportsModel);
  }

  getProvidersReportEntities(state: DataSourceRequestState, reportsModel?: ReportsFilterModel) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString(state)}`;
    return this.http.post(environment.baseUrl + 'api/Reports/GetProvidersReportEntities?' + queryStr, reportsModel);
  }

  getProvidersReportDetails(
    providerId: string,
    state: DataSourceRequestState,
    reportsModel?: ReportsFilterModel
  ): Observable<ProviderDetailsReportEntity[]> {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString(state)}`;
    return this.http.post<ProviderDetailsReportEntity[]>(
      environment.baseUrl + 'api/Reports/GetProvidersReportDetails/' + providerId + '?' + queryStr,
      reportsModel
    );
  }

  getSalesSummaryDetails(
    salesSummaryType: string,
    state: DataSourceRequestState = {
      sort: [],
      skip: 0,
      take: 1000,
      filter: {
        logic: 'and',
        filters: [],
      },
    },
    reportsModel?: ReportsFilterModel
  ): Observable<ProviderDetailsReportEntity[]> {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString(state)}`;
    return this.http.post<ClinicReportDetailsViewModel[]>(
      environment.baseUrl + 'api/Reports/GetClinicIncomeRevenueReportDetails/' + salesSummaryType + '?' + queryStr,
      reportsModel
    );
  }

  getClinicTaxReportDetails(
    categoryId: string,
    state: DataSourceRequestState = {
      sort: [],
      skip: 0,
      take: 1000,
      filter: {
        logic: 'and',
        filters: [],
      },
    },
    reportsModel?: ReportsFilterModel
  ): Observable<ClinicReportDetailsViewModel[]> {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString(state)}`;
    return this.http.post<ClinicReportDetailsViewModel[]>(
      environment.baseUrl + 'api/Reports/GetClinicTaxReportDetails/' + categoryId + '?' + queryStr,
      reportsModel
    );
  }

  getCategorySalesReportDetails(
    categoryId: string,
    state: DataSourceRequestState = {
      sort: [],
      skip: 0,
      take: 1000,
      filter: {
        logic: 'and',
        filters: [],
      },
    },
    reportsModel?: ReportsFilterModel
  ): Observable<ClinicReportDetailsViewModel[]> {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString(state)}`;
    return this.http.post<ClinicReportDetailsViewModel[]>(
      environment.baseUrl + 'api/Reports/GetCategorySalesReportDetails/' + categoryId + '?' + queryStr,
      reportsModel
    );
  }

  getPaymentTypeReportDetails(
    paymentTypeId: string,
    state: DataSourceRequestState = {
      sort: [],
      skip: 0,
      take: 1000,
      filter: {
        logic: 'and',
        filters: [],
      },
    },
    reportsModel?: ReportsFilterModel
  ): Observable<ClinicReportDetailsViewModel[]> {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString(state)}`;
    return this.http.post<ClinicReportDetailsViewModel[]>(
      environment.baseUrl + 'api/Reports/GetPaymentTypeReportDetails/' + paymentTypeId + '?' + queryStr,
      reportsModel
    );
  }

  getReferralsReportEntities(state: DataSourceRequestState, reportsModel?: ReportsFilterModel) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString(state)}`;
    return this.http.post(environment.baseUrl + 'api/Reports/GetReferralsReportEntities?' + queryStr, reportsModel);
  }

  getSuppliesReportEntities(
    state: DataSourceRequestState,
    reportsModel?: ReportsFilterModel
  ): Observable<GridDataResult> {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString({ skip: state.skip, take: state.take })}`; // group not working in GetSuppliesReportEntities
    return this.http.post<GridDataResult>(
      environment.baseUrl + 'api/Reports/GetSuppliesReportEntities?' + queryStr,
      reportsModel
    );
  }

  getSupplyReportDetails(
    observationListId: number,
    state: DataSourceRequestState,
    reportsModel?: ReportsFilterModel
  ): Observable<GridDataResult> {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString({ skip: state.skip, take: state.take })}`;
    return this.http.post<GridDataResult>(
      environment.baseUrl + `api/Reports/GetSupplyReportDetails/${observationListId}?` + queryStr,
      reportsModel
    );
  }

  getProviderSuppliesReportEntities(
    state: DataSourceRequestState,
    reportsModel?: ReportsFilterModel
  ): Observable<GridDataResult> {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString({ skip: state.skip, take: state.take })}`; // group not working in GetSuppliesReportEntities
    return this.http.post<GridDataResult>(
      environment.baseUrl + 'api/Reports/GetProviderSuppliesReportEntities?' + queryStr,
      reportsModel
    );
  }

  getMembershipsReportEntities(state: DataSourceRequestState, reportsModel?: ReportsFilterModel) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString(state)}`;
    return this.http.post(environment.baseUrl + 'api/Reports/GetMembershipsReportEntities?' + queryStr, reportsModel);
  }

  getMembershipDetailReportEntities(
    membershipId: number,
    state: DataSourceRequestState,
    reportsModel?: ReportsFilterModel
  ) {
    if (!reportsModel) {
      reportsModel = this.defaultReportsModel;
    }
    const queryStr = `${toDataSourceRequestString(state)}`;
    return this.http.post<GridDataResult>(
      environment.baseUrl + 'api/Reports/GetMembershipDetailReportEntities/' + membershipId + '?' + queryStr,
      reportsModel
    );
  }

  initDefaultValue(clinicId: number) {
    const date = new Date();
    let begin = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    let end = new Date(begin);
    end.setDate(end.getDate() + 1);
    end.setMilliseconds(-1);
    this.defaultReportsModel = new ReportsFilterModel({
      startDate: begin,
      endDate: end,
      showDeletedRecords: false,
      clinicId: clinicId,
    });
  }

  initClinic() {
    this.companyService.getCompanies().subscribe((company: any) => {
      this.companyName = company[0].name.split(' ')[0];
    });
  }

  onExcelExport(e: any, title: string, totalValue?: any) {
    const sheet = e.workbook.sheets[0];
    let value = this.companyName + ' ' + title;
    if (!isNullOrUndefined(this.defaultReportsModel.startDate)) {
      value += ` from ${formatDate(this.defaultReportsModel.startDate, 'yyyy-MM-dd', this.locale)}`;
      value += ` till ${formatDate(this.defaultReportsModel.endDate, 'yyyy-MM-dd', this.locale)}`;
    } else {
      value += ` for all time`;
    }

    const header = [
      {
        value: value,
        fontSize: 16,
        textAlign: 'center',
        background: '#fff',
        color: '#000000',
        rowSpan: 1,
        colSpan: sheet.columns.length,
      },
    ];

    if (totalValue) {
      const summarizeRow = [
        {
          value: 'Total',
          fontSize: 16,
          background: '#297D7D',
          color: '#FFFFFF',
        },
        {
          value: totalValue,
          fontSize: 16,
          background: '#297D7D',
          color: '#FFFFFF',
        },
      ];
      sheet.rows.push({ cells: summarizeRow });
    }

    sheet.rows.splice(0, 0, { cells: header, type: 'header', height: 20 });
  }
}
