<ngx-loading [show]="loading"></ngx-loading>

<div class="input-group">
  <input
    autocomplete="off"
    #typeaheadDirective="bs-typeahead"
    [(ngModel)]="asyncSelected"
    [typeaheadAsync]="true"
    [typeahead]="dataSource"
    (typeaheadOnSelect)="typeaheadOnSelect($event)"
    [typeaheadItemTemplate]="customItemTemplate"
    [optionsListTemplate]="customListTemplate"
    container="body"
    typeaheadOptionField="formatOption()"
    [typeaheadWaitMs]="400"
    type="text"
    name="patientSearch"
    class="form-control border-0 grey-three-background"
    placeholder="{{ typeheadPlaceholder }}"
    #patientSearch
  />
  <i *ngIf="asyncSelected" class="fal fa-times-circle search-clear pointer" (click)="removeSearchTerm()"></i>

  <ng-template #customListTemplate let-matches="matches" let-itemTemplate="itemTemplate" let-query="query">
    <ng-template ngFor let-match let-i="index" [ngForOf]="matches">
      <h6 *ngIf="match.isHeader()" class="dropdown-header">{{ match }}</h6>
      <ng-template [ngIf]="!match.isHeader()">
        <button
          #liElements
          class="dropdown-item px-2"
          (click)="container?.selectMatch(match, $event)"
          (mouseenter)="container?.selectActive(match)"
          [class.active]="container?.isActive(match)"
        >
          <ng-template
            [ngTemplateOutlet]="itemTemplate"
            [ngTemplateOutletContext]="{ item: match.item, index: i, match: match, query: query }"
          >
          </ng-template>
        </button>
      </ng-template>
    </ng-template>
  </ng-template>

  <ng-template #customItemTemplate let-item="item" let-match="match" let-query="query">
    <div class="col d-flex">
      <div class="d-inline pr-2">
        {{ item.firstName | titlecase }}{{ item.nickname ? ' "' + (item.nickname | titlecase) + '" ' : ' '
        }}{{ item.lastName | titlecase }}
      </div>

      <div defaultBirthdayCheck [birthDate]="item?.birthDate">
        {{ item ? getBirthday(item.birthDate) : 'N/A' }} (Age: {{ item ? getAge(item.birthDate) : 'N/A' }})
      </div>
      <div class="d-inline pl-2">
        {{ item.patientStatus == PatientStatus.Merged ? '(Status: Merged)' : '' }}
      </div>
    </div>
  </ng-template>
</div>
