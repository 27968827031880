import { UserCategory } from './user-category';
import { ClinicServiceTemplate } from './service/clinic-service-template';

// Simplified user object used to pass around on the calendar
export class ServiceProvider {
  id: string;
  title: string;
  onlineBookingProviderName: string;
  userCategory: UserCategory;
  authorizedServiceIds: number[];
  visible: boolean;
  working: boolean;
  clinicId: number;
  allowGovernmentBilling: boolean;
  availableForOnlineBooking: boolean;
  order?: number;

  constructor(init?: Partial<ServiceProvider>) {
    Object.assign(this, init);
  }
}
