import { TreatmentState } from '@models/treatment-planning/treatment-state';
import { PlannedTreatmentMultiple } from '@models/treatment-planning/planned-treatment-multiple';
import { Component, Input, OnInit } from '@angular/core';
import { PaymentStatus } from '@models/appointments/payment-status';

@Component({
  selector: 'app-planned-treatment-multiple-display',
  templateUrl: './planned-treatment-multiple-display.component.html',
  styleUrls: ['./planned-treatment-multiple-display.component.less'],
})
export class PlannedTreatmentMultipleDisplayComponent implements OnInit {
  @Input() plannedTreatmentMultiple: PlannedTreatmentMultiple;
  PaymentStatus = PaymentStatus;
  treatmentState = TreatmentState;

  constructor() {}

  ngOnInit() {}
}
