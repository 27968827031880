import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { ObservationUnit } from '@models/observation/observation';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObservationUnitsService {
  public observationUnits: Map<number, ObservationUnit>;

  constructor(private http: HttpClient) {
    this.observationUnits = new Map();
  }

  getAllObservationUnits() : Observable<ObservationUnit[]>{
    return this.http.get<ObservationUnit[]>(environment.baseUrl + 'api/ObservationUnit').pipe(
      map(response => {
        response.forEach(obrUnits => {
          this.observationUnits.set(obrUnits.id, obrUnits);
        });
        return response;
      })
    );
  }

  getObservationUnitsByObservationTypeId(observationTypeId: number) {
    return this.http.get<ObservationUnit[]>(environment.baseUrl + 'api/ObservationUnit/byTypeId/' + observationTypeId);
  }

  getObservationUnitsByObservationTypeName(observationTypeName: string) {
    return this.http.get<ObservationUnit[]>(environment.baseUrl + 'api/ObservationUnit/byTypeName/' + observationTypeName);
  }
}
