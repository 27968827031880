<div class="avatar-wrapper">
  <div
    class="avatar-placeholder"
    *ngIf="selectedUser"
    [style.backgroundImage]="userAvatar ? 'url('+ userAvatar +')' : 'url(/assets/Avatars/avatar-placeholder.png)'">
    <label class="uploader-circle position-absolute pointer text-center">
      <i class="fal fa-camera white-font"></i>
      <input
        (change)="onFileSelected($event)"
        type="file"
        id="uploader"
        accept="image/*"/>
    </label>
    <i
      *ngIf="userAvatar"
      class="fal fa-times-circle delete-icon pointer"
      (click)="removeAvatar()"></i>
  </div>
</div>
