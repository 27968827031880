<div class="edit-container position-absolute d-block z-index-3 white-background">
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0;">
    <div class="row teal-background">
      <div class="col">
        <h6 class="white-font uppercase p-2 mb-0">
          {{addOrEdit}} Resource
        </h6>
      </div>
    </div>
    <div class="row mt-2 mx-0">
      <div class="col px-2">
      <mat-form-field floatLabel="always" class="w-100">
        <mat-label>Resource Type</mat-label>
        <mat-select
          placeholder="Select"
          name="resourceType"
          [(ngModel)]="editedResource.resourceType"
          required>
          <mat-option *ngFor="let item of resourceTypes" [value]="item.value">
            {{ item.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row mt-2 mx-0">
    <div class="col px-2">
      <mat-form-field>
        <input matInput placeholder="Resource Name" class="capitalized" aria-label="Resource Name"
          (change)="formatterService.textCapitalize(name)" [(ngModel)]="editedResource.name" [formControl]="name" required>
      </mat-form-field>
    </div>
    </div>
      <div class="row mt-2 mx-0" *ngIf="editedResource.resourceType === ResourceType.Room">
        <div class="col px-2">
          <mat-form-field class="mr-2">
            <input type="number" matInput placeholder="Room Size" class="capitalized" aria-label="Room Size" value="200"
               required>
          </mat-form-field>
          <mat-button-toggle-group value="1">
            <mat-button-toggle value="1">sq. ft.</mat-button-toggle>
            <mat-button-toggle value="2">sq. m.</mat-button-toggle>
          </mat-button-toggle-group>
      </div>
    </div>
    <div class="row mt-2 mx-0">
      <div class="col px-2 text-center">
        <button
          class="btn em-btn em-btn-green mr-2"
          (click)="updateResource()"
          [disabled]="editedResource?.name === '' || editedResource.resourceType === null">
            {{addOrEdit == 'Add' ? 'Add' : 'Update'}}
        </button>
        <button class="btn em-btn em-btn-green" (click)="cancelUpdate()">Cancel</button>
      </div>
    </div>
  </perfect-scrollbar>
</div>
