export class ChargeableItem {
  defaultPrice: number;
  overridePrice: number;
    discount: number;
    quantity: number;

  constructor(init?: Partial<ChargeableItem>) {
    Object.assign(this, init);
  }
}
