<ngx-loading [show]="loading"></ngx-loading>

<ng-container *ngIf="thePatient">
  <div *ngIf="mobileView" class="patient-photo ml-2 mt-2">
    <img [attr.src]="onGetAvatarURI(thePatient.avatar)" class="w-100 h-auto" />
  </div>
  <app-edit-patient-content [currentPatient]="thePatient" [viewModeDefault]="true"> </app-edit-patient-content>
</ng-container>
<!-- Patient Notes/Alerts -->
<div class="patient-notes p-2 grey-two-background" *ngIf="thePatient && showNotes">
  <div class="d-flex justify-content-between align-items-center pb-2">
    <h6 class="m-0">Notes / Alerts</h6>
    <mat-slide-toggle
      [color]="'warn'"
      class="rush-note-toggle"
      [ngModel]="thePatient.rushPatientNote"
      title="Toggle important patient note"
      (change)="togglePatientRushNote($event?.checked)"
    >
    </mat-slide-toggle>
  </div>
  <app-textarea-widget
    *ngIf="thePatient"
    [minRows]="4"
    [maxRows]="4"
    [textValue]="thePatient.notesAndAlerts"
    (emitText)="savePatientNotes($event)"
  >
  </app-textarea-widget>
</div>
<!-- Directly request review from patient -->
<div *appAuthGuard="reviewsRequestPolicy" class="d-flex flex-column justify-content-center my-3 w-100">
  <button
    (click)="reviewManuallyRequested ? null : showRequestReviewPopup()"
    data-test-id="request-review-button"
    class="btn btn-sm em-btn em-btn-green py-2 px-3 mx-auto font-weight-bold"
    kendoTooltip
    tooltipClass="em-tooltip"
    [title]="
      !clinicHasReviewsEnabled
        ? 'Clinic has Google reviews disabled'
        : !thePatient?.sendReviews
        ? 'Review requests disabled for this patient'
        : (thePatient?.email == null || thePatient?.email == '') &&
          (thePatient?.mobileNumber == null || thePatient.mobileNumber == '')
        ? 'Please enter an email and/or mobile # for this patient'
        : 'Request a Google review from this patient'
    "
    [disabled]="requestReviewButtonDisabled()"
    style="line-height: 1em"
  >
    <i *ngIf="!reviewManuallyRequested" class="fas fa-envelope small pr-2"></i>
    {{ reviewManuallyRequested ? 'Review Requested' : 'Request Google Review' }}
  </button>
  <span class="mx-auto" style="font-size: 1.1em">{{ latestReviewRequestMessage }}</span>
</div>
