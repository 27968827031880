import { AppointmenteTreatmentForm } from '@models/etreatment-forms/appointment-etreatment-form';
import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { eTreatmentForm } from '@models/etreatment-forms/etreatment-form';
import { AppointmenteTreatmentFormService } from '@services/appointment-etreatment-form.service';

interface Dict {
  [key: string]: any;
}

@Component({
  selector: 'app-etreatment-form',
  templateUrl: './etreatment-form.component.html',
  styleUrls: ['./etreatment-form.component.scss'],
})
export class eTreatmentFormComponent implements OnInit {
  isIpad: boolean;
  isDirty: boolean = false;
  editable = true;
  eFormData: Dict = {};
  loading = true;
  modalView = false;
  unsub: Subject<void> = new Subject<void>();

  appointmenteTreatmentForm: AppointmenteTreatmentForm;

  constructor(
    private dialogService: MatDialog,
    @Optional() private dialogRef: MatDialogRef<eTreatmentFormComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private appointmenteTreatmentFormService: AppointmenteTreatmentFormService
  ) {
    this.appointmenteTreatmentForm = data.appointmenteTreatmentForm;
    this.editable = data.editable;
  }

  ngOnInit() {
    this.isIpad = navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;

    if (this.dialogRef) {
      this.modalView = true;
    }
    this.loading = false;
  }

  private async confirmLeaveForm(): Promise<boolean> {
    const dialogRef = this.dialogService.open(GenericDialogComponent, {
      width: '300px',
      data: {
        title: 'Warning',
        content: 'You have unsaved changes to this eTreatment form. Are you sure you want to proceed?',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancel: true,
      },
    });
    let proceed = await dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .map((result) => result === 'confirm')
      .toPromise();
    return proceed;
  }

  onFormSubmit(data) {
    this.loading = true;
    this.eFormData = data;
    this.appointmenteTreatmentFormService
      .updateAppointmenteTreatmentForm(this.appointmenteTreatmentForm, data)
      .pipe(first())
      .subscribe(() => {
        this.loading = false;
        this.isDirty = false;
        this.closeDialog();
      });
  }

  onFormChange(data) {
    this.isDirty = data.isModified;
  }

  pageRendered(event) {
    this.loading = false;
  }

  async closeDialog() {
    if (this.isDirty) {
      let proceed = await this.confirmLeaveForm();
      if (!proceed) {
        return;
      }
    }
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
