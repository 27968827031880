import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isNullOrUndefined } from '@app/shared/helpers';
import { NgxGalleryImage, NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { Patient } from '@models/patient';
import { PatientConsent } from '@models/patient-consent';
import { PhotoConsentTitle, PhotoConsentType } from '@models/photo/photo-consent-type';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { Service } from '@models/service/service';
import { TagType } from '@models/tag/tag-type';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageService } from '@services/image.service';
import { PatientService } from '@services/patient.service';
import { PhotoEditingService } from '@services/photo-editing.service';
import { ServicesService } from '@services/services.service';
import { TagService } from '@services/tag.service';
import exifr from 'exifr';
import { Subject } from 'rxjs';
import { PhotoFilter } from '../../../../photos/photo-filter';
import { PhotoTag } from './../../../../photos/photo-filter';
import { takeUntil } from 'rxjs/operators';

enum SelectedFiles {
  None,
  Single,
  Multiple,
}
@Component({
  selector: 'app-add-photo',
  templateUrl: './add-photo.component.html',
  styleUrls: ['./add-photo.component.less'],
})
export class AddPhotoComponent implements OnInit, OnDestroy {
  @Input() isServicePhoto: boolean;
  @Input() isClinicPhoto: boolean;
  @Input() showGallery: boolean;
  @Input() patient: Patient = null;
  @Input() singleImageSelect: boolean;
  @Input() service: Service;
  @Input() filter: PhotoFilter;
  @Input() photos: PhotoMetaData[];

  private unsub: Subject<void> = new Subject<void>();

  get selectedFiles() {
    return SelectedFiles;
  }

  public loading = false;

  public addPhotoForm: FormGroup;
  public tagList: any[];
  public pillColours = ['#b8de36', '#e7f4bd', '#7c9a19'];
  public filesSelected = SelectedFiles.None;
  private fileList: File[];
  private galleryImagesMetaData: PhotoMetaData[];
  public galleryOptions: NgxGalleryOptions[];
  public galleryImages: NgxGalleryImage[];
  public photoConsentOptions: { title: PhotoConsentTitle; value: PhotoConsentType }[] = [];
  private patientConsent: PatientConsent;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private patientService: PatientService,
    private tagsService: TagService,
    private imageService: ImageService,
    private serviceService: ServicesService,
    private photoEditingService: PhotoEditingService
  ) {
    this.tagList = [];
    this.galleryImagesMetaData = [];
    this.galleryImages = [];
  }

  ngOnInit() {
    this.photoConsentOptions = Object.keys(PhotoConsentTitle).map((e) => ({
      title: PhotoConsentTitle[e],
      value: PhotoConsentType[e],
    }));

    if (!this.isClinicPhoto) {
      this.patientService.getPatientConsent(this.patientService.patientPanelPatient.patientId).subscribe((res) => {
        this.patientConsent = res;
        this.initForm();
      });
      this.tagsService.getAllPhotoTags().subscribe((tags) => {
        this.tagList = tags as any;
      });
      this.loadPatientPhotos(this.patientService.patientPanelPatient, '');
    } else {
      this.initForm();
      this.loadClinicPhotos();
    }

    this.setGalleryOptions();
  }

  private initForm() {
    this.addPhotoForm = this.fb.group({
      file: [null, Validators.required],
      metadata: this.fb.group({
        imageName: [null, Validators.required],
        notes: [null],
        photoConsentTypeId: [this.isClinicPhoto ? 1 : this.patientConsent.id],
        patientId: [this.isClinicPhoto ? null : this.patientService.patientPanelPatient.patientId],
        patientContainerFolderName: [
          this.isClinicPhoto ? null : this.patientService.getPatientPanelPatientContainerFolderName(),
        ],
        tags: [[]],
      }),
    });
    this.addPhotoForm['controls'].metadata['controls'].imageName.disable();
    this.addPhotoForm['controls'].metadata['controls'].notes.disable();
    this.addPhotoForm['controls'].metadata['controls'].tags.disable();
  }

  deleteAvatar() {
    this.patientService.removePatientAvatar(this.patient.patientId).subscribe(() => {
      delete this.patient.avatar;
      this.loading = false;
    });
  }

  public onFileChange(files) {
    this.fileList = [];
    if (files && files.length) {
      this.addPhotoForm.patchValue({
        file: files[0],
      });
      if (files.length === 1) {
        this.filesSelected = SelectedFiles.Single;
        this.addPhotoForm['controls'].metadata['controls'].imageName.enable();
        this.addPhotoForm['controls'].metadata['controls'].notes.enable();
        this.addPhotoForm['controls'].metadata['controls'].tags.enable();
      } else {
        this.filesSelected = SelectedFiles.Multiple;
        this.addPhotoForm['controls'].metadata['controls'].imageName.disable();
        this.addPhotoForm['controls'].metadata['controls'].notes.disable();
        this.addPhotoForm['controls'].metadata['controls'].tags.disable();
        for (let i = 0; i < files.length; i++) {
          this.fileList.push(files[i]);
        }
      }
    } else {
      this.filesSelected = SelectedFiles.None;
      this.addPhotoForm['controls'].metadata['controls'].imageName.disable();
      this.addPhotoForm['controls'].metadata['controls'].notes.disable();
      this.addPhotoForm['controls'].metadata['controls'].tags.disable();
    }
  }

  public onSubmit() {
    this.loading = true;
    try {
      switch (this.filesSelected) {
        case SelectedFiles.Single:
          const file = this.addPhotoForm.value.file;
          const metadata = new PhotoMetaData(this.addPhotoForm.value.metadata);
          exifr.parse(file).then((output) => {
            if (output !== null && output !== undefined) {
              if (output.DateTimeOriginal !== null && output.DateTimeOriginal !== undefined) {
                metadata.dateTaken = output.DateTimeOriginal;
              }
            }
          });
          metadata.imageType = this.addPhotoForm.value.file.type;
          metadata.imageHeight = 0;
          metadata.tags = this.processTags(
            (<FormGroup>this.addPhotoForm.controls.metadata).controls.tags.value,
            metadata.id
          );
          this.imageService.uploadPhoto(file, metadata).subscribe((result) => {
            this.loading = false;
            if (this.isServicePhoto) {
              this.serviceService.servicePhotoFilePath = result.filePath;
              this.service.servicePhotoPath = result.filePath;
              this.serviceService.servicePhotoIsUpdated(this.service);
            } else if (!this.isClinicPhoto) {
              // Add the newly uploaded photo to the patient's photo
              this.patientService.patientPanelPatient.photos.unshift(result);
            }

            if (this.filter) {
              this.filter.setSelectedPhoto(result, this.photos);
              this.filter.filterChanged$.next(this.filter);
            }

            if (!this.isClinicPhoto) {
              this.patientService.thePatientHasBeenUpdated(this.patientService.patientPanelPatient);
              this.photoEditingService.loadPhoto(result);
            }
            this.activeModal.close(result);
          });
          break;
        case SelectedFiles.Multiple:
          this.uploadMultipleImages();
          break;
      }
    } catch {
      this.loading = false;
    }
  }

  private uploadMultipleImages() {
    let count = this.fileList.length;
    for (let i = 0; i < this.fileList.length; i++) {
      const file = this.fileList[i];
      const metadata = new PhotoMetaData(this.addPhotoForm.value.metadata);
      metadata.imageType = this.addPhotoForm.value.file.type;
      metadata.imageHeight = 0;
      let fname = file.name.split('.');
      fname.pop();
      metadata.imageName = fname.join('.');
      metadata.tags = this.processTags(
        (<FormGroup>this.addPhotoForm.controls.metadata).controls.tags.value,
        metadata.id
      );

      this.imageService.uploadPhoto(file, metadata).subscribe((result) => {
        this.loading = false;
        this.activeModal.close(result);
        // Add the newly uploaded photo to the patient's photo
        count--;
        if (count == 0) {
          if (!this.isClinicPhoto) {
            this.patientService.thePatientHasBeenUpdatedById(this.patientService.patientPanelPatient.patientId);
          }
          if (this.filter) {
            this.filter.setSelectedPhoto(result, this.photos);
            this.filter.filterChanged$.next(this.filter);
          }
        }
      });
    }
  }

  private processTags(tags: any[], photoId: number): PhotoTag[] {
    const tagList: PhotoTag[] = [];
    if (!isNullOrUndefined(tags)) {
      tags.forEach((t) => {
        const type = !isNullOrUndefined(t.type) ? t.type : TagType.custom;

        tagList.push({ photoId: String(photoId), tagId: t.tagId, type, title: t.title });
      });
    }
    let res = [];
    tagList.forEach((tl) => {
      res.push({ title: tl.title, tagId: tl.tagId, photoId: photoId, type: tl.type });
    });
    return res;
  }

  private loadPatientPhotos(patient: Patient, filter: string) {
    const photos = patient.photos.filter((e) => !e.isSeries);
    this.loadPhotosInGallery(photos, filter);
  }

  private loadClinicPhotos() {
    this.imageService
      .getClinicStockPhotos()
      .pipe(takeUntil(this.unsub))
      .subscribe((photos) => {
        this.loadPhotosInGallery(photos, '');
      });
  }

  private loadPhotosInGallery(photos: PhotoMetaData[], filter: string): void {
    if (!isNullOrUndefined(photos)) {
      let searchArgs: string[] = [];
      searchArgs = filter.split(' ');

      this.galleryImagesMetaData = [];
      this.galleryImages = [];
      photos.forEach((photoMetadata) => {
        if (filter !== '') {
          if (
            photoMetadata.tags.find(
              (t) => searchArgs.find((arg) => arg.toLowerCase() === t.title.toLowerCase()) !== undefined
            )
          ) {
            this.galleryImagesMetaData.push(photoMetadata);
          }
        } else {
          this.galleryImagesMetaData.push(photoMetadata);
        }
      });
    }

    this.galleryImagesMetaData = this.imageService.sortImages(this.galleryImagesMetaData);

    // Populate the gallery to match the indexes of the metadata
    this.galleryImagesMetaData.forEach((image) => {
      this.galleryImages.push({
        small: image.filePathThumb,
        medium: image.filePathThumb,
        big: image.filePathThumb,
      });
    });
  }

  private setGalleryOptions() {
    this.galleryOptions = [
      {
        preview: false,
        thumbnailsColumns: 3,
        thumbnailsRows: 1,
        thumbnailsOrder: 3,
        thumbnailMargin: 10,
        thumbnailsMargin: 10,
        image: false,
        height: '100%',
        width: '100%',
        thumbnailsArrowsAutoHide: true,
        thumbnailsMoveSize: 3,
      },
      {
        breakpoint: 500,
        width: '100%',
      },
    ];
  }

  public imageClicked(event) {
    if (this.isServicePhoto) {
      this.serviceService.servicePhotoFilePath = this.galleryImagesMetaData[event.index].filePath;
      this.service.servicePhotoPath = this.galleryImagesMetaData[event.index].filePath;
      this.serviceService.servicePhotoIsUpdated(this.service);
    }
    this.activeModal.close(this.galleryImagesMetaData[event.index]);
  }

  public uploadIsInvalid() {
    return !this.addPhotoForm.valid || this.filesSelected === SelectedFiles.None;
  }

  public setPhotoConsent() {
    this.addPhotoForm['controls'].metadata['controls'].photoConsentTypeId.setValue(
      (document.getElementById('photoConsentSelect') as any).value
    );
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }
}
