import { Component, Input, OnInit } from '@angular/core';
import { ConvergePaymentsService } from '@services/converge-payments.service';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-patient-converge-overview',
  templateUrl: './patient-converge-overview.component.html',
  styleUrls: ['./patient-converge-overview.component.less'],
})
export class PatientConvergeOverviewComponent implements OnInit {
  constructor(private convergePaymentService: ConvergePaymentsService) {}

  private unsub: Observable<any> = new Observable<any>();

  private _patientId: number;
  @Input() set patientId(patientId: number) {
    if (patientId != 0 && patientId != this._patientId) {
      this._patientId = patientId;
      this.getPatientConvergeCards();
    }
  }

  get patientId() {
    return this._patientId;
  }

  cards: any[];

  ngOnInit() {}

  getPatientConvergeCards() {
    this.convergePaymentService
      .getPatientCards(this.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((cards) => {
        this.cards = cards;
      });
  }
}
