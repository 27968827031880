<ngx-loading [show]="loading"></ngx-loading>
<div class="edit-container position-absolute d-block z-index-3 white-background" *ngIf="merchantDeviceForm">
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0">
    <ng-container [formGroup]="merchantDeviceForm">
      <div class="row teal-background">
        <div class="col">
          <h6 class="white-font uppercase p-2 mb-0">{{ addOrEdit }} Merchant Device</h6>
        </div>
      </div>
      <app-error *ngIf="errorMessage" [message]="errorMessage"></app-error>

      <div class="row mt-2 mx-0">
        <div class="col px-2">
          <mat-form-field>
            <input
              matInput
              placeholder="Device Name"
              class="capitalized"
              formControlName="deviceFriendlyName"
              required
              autocomplete="off"
            />
            <mat-error *ngIf="merchantDeviceForm.controls.deviceFriendlyName.hasError('maxlength')"
              >Device Name cannot exceed 12 characters.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col px-2">
          <mat-form-field>
            <input
              matInput
              placeholder="Location"
              class="capitalized"
              formControlName="location"
              required
              autocomplete="off"
            />
            <mat-error *ngIf="merchantDeviceForm.controls.location.hasError('maxlength')"
              >Location cannot exceed 15 characters.</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-2 mx-0">
        <div class="col px-2">
          <mat-form-field>
            <input
              matInput
              placeholder="Pairing Code"
              class="capitalized"
              formControlName="pairingCode"
              required
              autocomplete="off"
            />
            <mat-error *ngIf="merchantDeviceForm.controls.pairingCode.hasError('maxlength')"
              >Pairing code cannot exceed 6 characters.</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="editedMerchantDevice" class="row mt-2 mx-0">
        <div class="col px-2">
          <mat-form-field>
            <input
              matInput
              readonly
              placeholder="Device Id"
              class="capitalized"
              formControlName="deviceId"
              required
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <div class="col px-2">
          <mat-form-field>
            <input
              matInput
              readonly
              placeholder="Serial Number"
              class="capitalized"
              formControlName="serialNumber"
              required
              autocomplete="off"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="row mx-0 mt-4">
        <div class="col px-2 text-center">
          <button
            [disabled]="!merchantDeviceForm.valid"
            class="btn em-btn em-btn-green mr-2"
            (click)="updateMerchantDevice()"
          >
            {{ isNew ? 'Add' : 'Update' }}
          </button>
          <button class="btn em-btn em-btn-green" (click)="cancelUpdate()">Cancel</button>
        </div>
      </div>
    </ng-container>
  </perfect-scrollbar>
</div>
