<div class="px-3 py-0">
  <div class="transactions-header">Transactions</div>

  <div class="transactions-search input-group d-flex mb-3">
    <div class="input-group-prepend">
      <div class="input-group-text border-radius border-0 py-3 px-3">
        <i class="fa fa-search"></i>
      </div>
    </div>
    <input
      type="text"
      class="input-group-text border-0 flex-grow-1 pl-0 pr-4 py-1 text-left"
      (keyup)="searchTerm$.next($any($event.target).value)"
      placeholder="Search"
      #searchTerm
    />
    <button
      *ngIf="searchTerm.value"
      type="button"
      class="btn-clean reset-text px-0"
      (click)="searchTerm.value = ''; searchTerm$.next('')"
    >
      <i class="far fa-times-circle"></i>
    </button>
  </div>

  <div *ngIf="selectedUsers?.length > 0" class="transactions-tags px-2 py-3 mb-3">
    <ul class="transactions-staff-list list-unstyled d-flex align-items-center flex-wrap mt-0 mb-1 px-0 py-0">
      <li *ngFor="let user of selectedUsers" class="transactions-staff-item py-1 px-2 mb-2" (click)="removeUser(user)">
        {{ getUserNameById(user) }}
      </li>
    </ul>
    <div class="d-flex">
      <a class="ml-auto pointer mr-2 teal-color" (click)="selectedUsers = []; searchPatientTransactions()">Clear All</a>
    </div>
  </div>

  <div class="transactions-staff">
    <div class="transactions-staff-title mb-2">Filter by Staff</div>
    <ul class="transactions-staff-list list-unstyled d-flex align-items-center flex-wrap mt-0 mb-3 px-0 py-0">
      <li *ngFor="let user of users" class="transactions-staff-item py-1 px-2 mb-2" (click)="searchByUser(user.id)">
        {{ user?.firstName }} {{ user?.lastName }}
      </li>
    </ul>
  </div>

  <ngx-loading [show]="loading"></ngx-loading>

  <div
    *ngIf="patientTransactions?.length > 0"
    class="transactions-list pt-3"
    appDetermineMaxScrollHeight
    [applyToHeight]="true"
    [addHeight]="-35 - 20"
  >
    <ng-scrollbar [autoHeightDisabled]="false">
      <app-patient-transactions-item
        *ngFor="let patientTransactionEl of patientTransactions"
        [patientTransaction]="patientTransactionEl"
      >
      </app-patient-transactions-item>
    </ng-scrollbar>
  </div>

  <div
    *ngIf="transactionCountForPatient > defaultPageSize && patientTransactions?.length < transactionCountForPatient"
    class="panel-flex-row pb-2 pt-1"
    style="height: 35px"
  >
    <div class="column-header-link" (click)="loadPagedData()">Load More Transactions...</div>
  </div>
</div>
