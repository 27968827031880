import { Component, EventEmitter, Input, OnInit, OnChanges, Output, ViewChild, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Form } from '@models/forms/form';
import { PatientFormService } from '@services/patient-form.service';
import { FormioComponent } from '@formio/angular';
import { AnyForJSON } from 'formiojs';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { eTreatmentForm } from '@models/etreatment-forms/etreatment-form';
import { AppointmenteTreatmentForm } from '@models/etreatment-forms/appointment-etreatment-form';

@Component({
  selector: 'app-form-viewer',
  templateUrl: './form-viewer.component.html',
  styleUrls: ['./form-viewer.component.less'],
})
export class FormViewerComponent implements OnInit, OnChanges {
  @ViewChild('formView') formView: FormioComponent;
  @Input() form: Form | AppointmenteTreatmentForm | eTreatmentForm;
  @Input() submission?: string;
  @Input() patientFormId: number;
  @Input() editable: boolean;
  @Input() modalView: boolean;
  @Input() refreshViewer?: Observable<Form | AppointmenteTreatmentForm | eTreatmentForm>;

  @Output() submitted: EventEmitter<any> = new EventEmitter();
  @Output() changed: EventEmitter<any> = new EventEmitter();

  patientId: number;
  formObj: AnyForJSON;
  submissionObj: AnyForJSON;
  unsub: Subject<void> = new Subject<void>();

  constructor(private route: ActivatedRoute, private patientFormService: PatientFormService) {}

  ngOnInit() {
    if (Object.keys(this.route.snapshot.params).length > 0) {
      this.patientId = +this.route.snapshot.params.patId.split('_')[0];
    }

    if (this.patientFormId && this.patientId) {
      this.patientFormService
        .getPatientFormById(this.patientFormId)
        .pipe(takeUntil(this.unsub))
        .subscribe((patientForm) => {
          this.form = patientForm.clinicForm;
          this.submission = patientForm.formEntry;
          this.initViewer();
        });
    }

    this.refreshViewer?.pipe(takeUntil(this.unsub)).subscribe((form) => {
      this.form = form;
      this.initViewer();
    });

    this.initViewer();
    this.preventScrollingInSignatureCanvas();
  }

  private initViewer() {
    // Initialize an empty instance
    this.formObj = { components: [] };
    this.submissionObj = {};

    if (this.form) {
      if (this.form.formDefinition && this.form.formDefinition !== '')
        this.formObj = JSON.parse(this.form.formDefinition);
    }

    if (this.submission && this.submission !== '') {
      this.submissionObj = JSON.parse(this.submission);
    }
  }

  private preventScrollingInSignatureCanvas() {
    // Prevent scrolling when touching the canvas
    const canvases = document.getElementsByClassName('signature-pad-canvas');
    for (let i = 0; i < canvases.length; i++) {
      canvases[i].addEventListener(
        'touchstart',
        function (e) {
          e.preventDefault();
        },
        { passive: false }
      );
      canvases[i].addEventListener(
        'touchend',
        function (e) {
          e.preventDefault();
        },
        { passive: false }
      );
      canvases[i].addEventListener(
        'touchmove',
        function (e) {
          e.preventDefault();
        },
        { passive: false }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['form'] || changes['editable'] || changes['submission']) {
      this.initViewer();
    }
  }

  onFormChange(event: any) {
    if (event?.data) {
      this.changed.emit(event);
    }
  }

  onSubmit(event: any) {
    this.submitted.emit(event);
  }

  onDomChange(event) {}

  submit(data) {
    this.formView.submit.next(data);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
