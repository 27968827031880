<ngx-loading [show]="loading"></ngx-loading>
<mat-accordion [multi]="true" class="h-100 d-flex flex-column">
  <mat-expansion-panel *ngIf="!kioskMode" class="panel1" [expanded]="true" [disabled]="true" #panel1>
    <mat-expansion-panel-header class="p-0" [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
      <mat-panel-title>
        <div class="d-flex align-items-center justify-content-around teal-background w-100 px-2 py-2">
          <div class="column-header py-0">Documents</div>
          <button
            class="btn em-btn em-btn-green btn-sm ml-auto header-button"
            [attr.data-test-id]="'documents-tab-add-patient-document'"
            (click)="uploadDocument()"
          >
            <i class="fas fa-plus-circle"></i>
          </button>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-scrollbar [autoHeightDisabled]="false">
      <mat-expansion-panel
        *ngFor="let item of uploadedDocs"
        (click)="onSelectDocument(item)"
        [ngClass]="{ 'active-document': item.id == currentItem?.id && currentItem?.docType == DocumentType.Upload }"
        [expanded]="currentItem?.id == item.id && currentItem?.docType == DocumentType.Upload"
        [disabled]="true"
      >
        <mat-expansion-panel-header
          (keydown.Space)="$event.stopImmediatePropagation()"
          class="inner-expansion"
          [collapsedHeight]="'48px'"
          [expandedHeight]="'48px'"
        >
          <div class="column-sub-header">
            <div *ngIf="item.id !== editItem?.id" class="py-0 px-2 mr-auto">
              {{ item.name }}
            </div>
            <div *ngIf="item.id === editItem?.id" class="py-0 px-2 w-100">
              <input
                type="text"
                class="form-control details-control input-filed editing-doc-name"
                [attr.data-test-id]="'editing-document-name-input'"
                [(ngModel)]="item.name"
                placeholder="Edit name"
              />
            </div>
            <mat-icon class="pl-2" [hidden]="!item['loadingDocument']">{{ 'av_timer' }}</mat-icon>
            <button type="button" class="btn font-white px-0 py-0">
              <mat-icon>{{ currentItem && currentItem.id == item.id ? 'arrow_drop_down' : 'arrow_drop_up' }}</mat-icon>
            </button>
          </div>
        </mat-expansion-panel-header>

        <div class="container" kendoTooltip tooltipClass="em-tooltip" position="top">
          <div class="row py-2 px-1" *ngIf="item?.id !== editItem?.id">
            <div class="col default-cursor">
              <div>
                <i class="far fa-calendar-alt mr-2" title="Date Added"></i>{{ item?.uploadDate | date : 'MMM d, y' }}
              </div>
            </div>
          </div>
          <div class="row py-2 px-1" *ngIf="item?.id === editItem?.id">
            <div class="col align-items-start">
              <kendo-datepicker
                #datepicker
                [(value)]="item.uploadDate"
                [format]="'yyyy-MM-dd'"
                class="bigger document-datepicker"
              ></kendo-datepicker>
            </div>
          </div>
          <div class="row pb-2 px-1">
            <div class="col d-flex align-items-center">
              <i *ngIf="item.notes && !(item.id === editItem?.id)" class="far fa-book mr-2" title="Notes"></i>
              <div *ngIf="item.notes && !(item.id === editItem?.id)">{{ item.notes }}</div>
              <i *ngIf="item.id === editItem?.id" class="far fa-book mr-2" title="Notes"></i>
              <input
                *ngIf="item.id === editItem?.id"
                type="text"
                class="form-control details-control input-filed"
                [(ngModel)]="item.notes"
                placeholder="Add notes"
              />
            </div>
          </div>
          <div class="row align-items-baseline pb-2 px-1" *ngIf="item.tags?.length && !(item.id === editItem?.id)">
            <div class="col d-flex align-items-center">
              <i class="far fa-tags tags-icon-margin" title="Tags"></i>
              <div class="pill-container">
                <div
                  class="tag tag-pill d-inline"
                  *ngFor="let tag of item.tags"
                  [ngStyle]="{ 'border-color': pillColours[tag.type] }"
                >
                  {{ tag.title }}
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-baseline pb-2 px-1" *ngIf="item.id === editItem?.id">
            <div class="col d-flex">
              <i class="far fa-tags mt-2 mr-2" title="Tags"></i>
              <tag-input
                [(ngModel)]="item.tags"
                name="tags"
                [inputClass]="'tag-input'"
                #input
                [onlyFromAutocomplete]="true"
              >
                <ng-template let-item="item" let-index="index">
                  <div class="tag-pill" [ngStyle]="{ 'border-color': pillColours[item.type] }">
                    <ng-container *ngIf="item.title; else text">
                      {{ item.title }}
                    </ng-container>
                    <ng-template #text>
                      {{ item.display }}
                    </ng-template>
                    <delete-icon (click)="input.removeItem(item, index)"></delete-icon>
                  </div>
                </ng-template>
                <tag-input-dropdown
                  [focusFirstElement]="true"
                  [autocompleteItems]="tags"
                  [zIndex]="99999"
                  [displayBy]="'title'"
                  [identifyBy]="'tagId'"
                  [showDropdownIfEmpty]="true"
                  [keepOpen]="false"
                >
                </tag-input-dropdown>
              </tag-input>
            </div>
          </div>
        </div>

        <div *ngIf="!(item.id === editItem?.id)" class="d-flex p-3 flex-wrap">
          <button
            class="btn em-btn btn-sm em-btn-green mr-1 mb-1"
            (click)="enterEditMode(item)"
            [attr.data-test-id]="'patient-document-edit-button'"
            [attr.data-test-value]="item.id"
          >
            Edit
          </button>
          <!--
          <a class="btn em-btn btn-sm em-btn-green mx-1 mb-1" [href]="item?.filePath" target="_blank" [attr.data-test-id]="'patient-document-download-button'" [attr.data-test-value]="item.id" >
            Download
          </a>
          -->
          <button
            class="btn em-btn btn-sm em-btn-green mr-1 mb-1"
            (click)="onDelete(item)"
            [attr.data-test-id]="'patient-document-delete-button'"
            [attr.data-test-value]="item.id"
          >
            Delete
          </button>
        </div>

        <div *ngIf="item.id === editItem?.id" class="d-flex p-3 flex-wrap">
          <button
            class="btn em-btn btn-sm em-btn-green ml-1 mb-1"
            (click)="save(item)"
            [attr.data-test-id]="'patient-document-save-button'"
            [attr.data-test-value]="item.id"
          >
            Save
          </button>
          <button
            class="btn em-btn btn-sm em-btn-green mx-1 mb-1"
            (click)="exitEditMode()"
            [attr.data-test-id]="'patient-document-cancel-edit-button'"
            [attr.data-test-value]="item.id"
          >
            Cancel
          </button>
          <button
            class="btn em-btn btn-sm em-btn-green mr-1 mb-1"
            (click)="onDelete(item)"
            [attr.data-test-id]="'patient-document-delete-button'"
            [attr.data-test-value]="item.id"
          >
            Delete
          </button>
        </div>
      </mat-expansion-panel>

      <div *ngIf="uploadedDocs?.length === 0">This patient doesn't have any Documents yet</div>
    </ng-scrollbar>
  </mat-expansion-panel>

  <mat-expansion-panel class="panel2" [expanded]="true" [disabled]="true" #panel2>
    <mat-expansion-panel-header class="p-0" [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
      <mat-panel-title>
        <div class="d-flex align-items-center justify-content-around teal-background w-100 px-2 py-2">
          <div class="column-header py-0">eForms</div>
          <button
            *ngIf="!kioskMode"
            class="btn em-btn em-btn-green btn-sm ml-auto header-button"
            [attr.data-test-id]="'documents-tab-add-patient-eform'"
            (click)="assignForm()"
          >
            <i class="fas fa-plus-circle"></i>
          </button>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-scrollbar [autoHeightDisabled]="false">
      <mat-expansion-panel
        *ngFor="let item of formItems"
        (click)="onSelectForm(item)"
        [ngClass]="{ 'active-document': item.id == currentItem?.id && item.type == currentItem?.docType }"
        [expanded]="item.id == currentItem?.id && item.type == currentItem?.docType"
        [disabled]="true"
      >
        <mat-expansion-panel-header
          (keydown.Space)="$event.stopImmediatePropagation()"
          class="inner-expansion"
          [collapsedHeight]="'48px'"
          [expandedHeight]="'48px'"
        >
          <div class="column-sub-header">
            <div *ngIf="item.id !== editItem?.id || item.type == DocumentType.Form" class="py-0 px-2 mr-auto">
              {{ item.name }}
            </div>
            <div *ngIf="item.id === editItem?.id && item.type != DocumentType.Form" class="py-0 px-2 mr-auto">
              <input
                type="text"
                class="form-control details-control input-filed editing-doc-name"
                [attr.data-test-id]="'editing-document-name-input'"
                [(ngModel)]="item.name"
                placeholder="Edit name"
              />
            </div>
            <mat-icon *ngIf="item.form['loadingDocument']" class="mr-2">{{ 'av_timer' }}</mat-icon>
            <ng-container *ngIf="isPatientForm(item.form)">
              <i *ngIf="item.form.isSigned" class="far fa-check bigger spring-green-font"></i>
              <i *ngIf="!item.form.isSigned" class="far fa-times bigger calendar-red-font"></i>
            </ng-container>
            <button type="button" class="btn p-0">
              <mat-icon>{{ currentItem && currentItem.id == item.id ? 'arrow_drop_down' : 'arrow_drop_up' }}</mat-icon>
            </button>
          </div>
        </mat-expansion-panel-header>

        <div class="container" kendoTooltip tooltipClass="em-tooltip" position="top">
          <div class="row py-2 px-1" *ngIf="!(editItem && editItem.id == item.id) || item.type == DocumentType.Form">
            <div class="col default-cursor">
              <div>
                <i class="far fa-calendar-alt mr-2" title="Upload Date"></i>{{ item?.date | date : 'yyyy-MM-dd' }}
              </div>
            </div>
          </div>
          <ng-container *ngIf="item.type !== DocumentType.Form">
            <div class="row py-2 px-1" *ngIf="editItem?.id === item.id && item?.date">
              <div class="col align-items-start">
                <kendo-datepicker
                  #datepicker
                  [(value)]="item.date"
                  [format]="'yyyy-MM-dd'"
                  class="bigger document-datepicker"
                ></kendo-datepicker>
              </div>
            </div>
            <div *ngIf="!isPatientForm(item.form)" class="row pb-2 px-1">
              <div class="col d-flex align-items-center">
                <i *ngIf="item.form.notes && !(item.id === editItem?.id)" class="far fa-book mr-2" title="Notes"></i>
                <div *ngIf="item.form.notes && !(item.id === editItem?.id)">{{ item.form.notes }}</div>
                <i *ngIf="item.id === editItem?.id" class="far fa-book mr-2" title="Notes"></i>
                <input
                  *ngIf="item.id === editItem?.id"
                  type="text"
                  class="form-control details-control input-filed"
                  [(ngModel)]="item.form.notes"
                  placeholder="Add notes"
                />
              </div>
            </div>
            <div
              class="row align-items-baseline pb-2 px-1"
              *ngIf="!isPatientForm(item.form) && item.form.tags?.length && !(item.id === editItem?.id)"
            >
              <div class="col d-flex align-items-center">
                <i class="far fa-tags tags-icon-margin" title="Tags"></i>
                <div class="pill-container">
                  <div
                    class="tag tag-pill d-inline"
                    *ngFor="let tag of item.form.tags"
                    [ngStyle]="{ 'border-color': pillColours[tag.type] }"
                  >
                    {{ tag.title }}
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!isPatientForm(item.form) && item.id === editItem?.id" class="row align-items-baseline pb-2 px-1">
              <div class="col d-flex">
                <i class="far fa-tags mt-2 mr-2" title="Tags"></i>
                <tag-input
                  [(ngModel)]="item.form.tags"
                  name="tags"
                  [inputClass]="'tag-input'"
                  #input
                  [onlyFromAutocomplete]="true"
                >
                  <ng-template let-item="item" let-index="index">
                    <div class="tag-pill" [ngStyle]="{ 'border-color': pillColours[item.form.type] }">
                      <ng-container *ngIf="item.form.title; else text">
                        {{ item.form.title }}
                      </ng-container>
                      <ng-template #text>
                        {{ item.form.display }}
                      </ng-template>
                      <delete-icon
                        (click)="input.removeItem(item.form, index); reAddTagToList(item.form)"
                      ></delete-icon>
                    </div>
                  </ng-template>
                  <tag-input-dropdown
                    [focusFirstElement]="true"
                    [autocompleteItems]="formTags"
                    [zIndex]="99999"
                    [displayBy]="'title'"
                    [identifyBy]="'tagId'"
                    [showDropdownIfEmpty]="true"
                    [keepOpen]="false"
                  >
                  </tag-input-dropdown>
                </tag-input>
              </div>
            </div>
          </ng-container>
        </div>

        <div *ngIf="!kioskMode" class="d-flex p-3 flex-wrap">
          <button
            *ngIf="!isPatientForm(item.form) && item.id !== editItem?.id"
            class="btn em-btn btn-sm em-btn-green mr-1 mb-1"
            (click)="enterEditMode(item.form)"
            [attr.data-test-id]="'patient-eform-edit-button'"
            [attr.data-test-value]="item.id"
          >
            Edit
          </button>
          <button
            *ngIf="item.id === editItem?.id || item.type === DocumentType.Form"
            class="btn em-btn btn-sm em-btn-green mr-1 mb-1"
            [disabled]="(isPatientForm(item.form) && item.form.isSigned) || !patientFormDirty"
            (click)="save(item.form)"
            [attr.data-test-id]="'patient-eform-save-button'"
            [attr.data-test-value]="item.id"
          >
            Save
          </button>
          <!--
          <a class="btn em-btn btn-sm em-btn-green text-white mx-1 mb-1" *ngIf="item.id === currentItem?.id && !isIpad" (click)="downloadForm(item.form)" target="_blank" [attr.data-test-id]="'patient-eform-download-button'" [attr.data-test-value]="item.id">
            Download
          </a>
          -->
          <button
            *ngIf="item.id === editItem?.id && item.type !== DocumentType.Form"
            class="btn em-btn btn-sm em-btn-green mx-1 mb-1"
            (click)="exitEditMode()"
            [attr.data-test-id]="'patient-eform-cancel-edit-button'"
            [attr.data-test-value]="item.id"
          >
            Cancel
          </button>
          <button
            *ngIf="!isPatientForm(item.form) || !item.form.isSigned"
            class="btn em-btn btn-sm em-btn-green ml-1 mb-1"
            (click)="onDelete(item.form)"
            [attr.data-test-id]="'patient-eform-delete-button'"
            [attr.data-test-value]="item.id"
          >
            Delete
          </button>
        </div>
      </mat-expansion-panel>

      <div *ngIf="formItems.length === 0">This patient doesn't have any eForms yet</div>
    </ng-scrollbar>
  </mat-expansion-panel>
</mat-accordion>
