import { CommunicationsSettingsBase } from './notifications-settings-base.model';

export class EmailNotificationsSettings extends CommunicationsSettingsBase {
  templateReferenceId: number;
  senderName: string;
  senderEmail: string;
  emailJson: string;
  emailHtml: string;
  emailSubjectLine: string;
  createdFromEmailTemplateId: number;
}
