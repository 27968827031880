import { moment } from 'fullcalendar';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ProductPurchased } from '@models/invoice/product-purchased';
import { InvoicesService } from '@services/invoices.service';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-product-purschase-history',
  templateUrl: './product-purschase-history.component.html',
  styleUrls: ['./product-purschase-history.component.less']
})
export class ProductPurschaseHistoryComponent implements OnInit, OnDestroy {
  @Input() patientId: number;
  @Input() panelHeight: number;
  @Input() isInvoice: boolean = true;
  @Output() selectProduct = new EventEmitter();
  productsPurchased: ProductPurchased[] = [];
  unsub = new Subject<any>();
  constructor(
    private route: ActivatedRoute,
    private invoicesService: InvoicesService
  ) { }

  ngOnInit() {

    this.getPatientId();
    this.getProductsPurchased();
  }

  ngOnChanges() {
    this.getPatientId();
    this.getProductsPurchased();
  }
  
  getPatientId() {
    if (!this.patientId) {
      this.patientId = this.route.snapshot.params.patId.split('_')[0];
    }
  }

  getProductsPurchased() {
    this.invoicesService.getProductsPurchasedByPatient(this.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((productsPurchased: ProductPurchased[]) => {
        this.productsPurchased = productsPurchased;
      });
  }

  getColourFromDaysToExpiry(daysToExpiry: number){
    if (daysToExpiry <= -14){
      return 'black';
    }
    else if (daysToExpiry < 0){
      return 'rgb(224, 156, 18)';
    }
    else{
      return 'rgb(219, 65, 18)';
    }
  }

  getMonthsFromPurchase(purchaseDate: Date){
    
    return moment().diff(purchaseDate, 'days') / 7 / 4;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
