import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { Patient } from '@models/patient';
import { PatientService } from '@services/patient.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-patient-portal-overview',
  templateUrl: './patient-portal-overview.component.html',
  styleUrls: ['./patient-portal-overview.component.less'],
})
export class PatientPortalOverviewComponent implements OnInit {
  get thePatient(): Patient {
    return this.patientService.patientPanelPatient;
  }

  patientPortalAccountExists: boolean = true;
  patientPortalAccountCreationRequested: boolean = false;
  passwordResetRequested: boolean = false;
  sendingDocumentsToCompleteEmail: boolean = false;
  createdDate: Date = null;
  unsub: Subject<void> = new Subject<void>();

  errorMessage = '';
  portalError = false;
  createdBy: string = '';

  constructor(private router: Router, public patientService: PatientService, public dialog: MatDialog) {}

  ngOnInit() {
    // Check if a Patient Portal account exists for the Patient
    this.patientService.existsPatientPortalAccount(this.thePatient.patientId).subscribe((result) => {
      this.patientPortalAccountExists = result;
      if (result) {
        this.patientService.getCreatedData(this.thePatient.patientId).subscribe((results) => {
          this.createdDate = results.createdDate;
          this.createdBy = results.createdBy == null ? 'Patient Portal' : results.createdBy;
        });
      }
    });
  }

  showConfirmPortalAccountPopup() {
    let content =
      'Are you sure you want to create a portal account for <strong>' +
      this.thePatient.firstName +
      ' ' +
      this.thePatient.lastName +
      '</strong>? This will create an account and send them an email at <strong>' +
      this.thePatient.email +
      '</strong> to set their password.';
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '350px',
      data: {
        title: 'Confirm - Create Portal Account',
        content: content,
        confirmButtonText: 'Create',
        showCancel: true,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') this.createPortalAccount(this.thePatient.patientId);
      });
  }

  createPortalAccount(patientId: number) {
    this.errorMessage = '';
    this.portalError = false;

    this.patientService.createPatientPortalAccount(patientId).subscribe(
      () => {
        this.patientPortalAccountCreationRequested = true;
      },
      (error) => {
        if (error.error && error.error.errors) {
          const errors = error.error.errors;
          this.errorMessage = errors[0].fieldErrors[0];
          this.portalError = true;
        }
      }
    );
  }

  patientPortalAccountCreationButtonDisabled(): boolean {
    return (
      this.patientPortalAccountExists ||
      this.patientPortalAccountCreationRequested ||
      this.thePatient == null ||
      this.thePatient?.email == null ||
      this.thePatient?.email == ''
    );
  }

  showConfirmPasswordResetPopup() {
    let content =
      'Are you sure you want to send a patient portal password reset email to <strong>' +
      this.thePatient.firstName +
      ' ' +
      this.thePatient.lastName +
      '</strong>? using the email address <strong>' +
      this.thePatient.email +
      '</strong>';
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '350px',
      data: {
        title: 'Confirm - Send Password Reset',
        content: content,
        confirmButtonText: 'Send',
        showCancel: true,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') this.sendPasswordReset(this.thePatient.patientId);
      });
  }

  sendPasswordReset(patientId: number) {
    this.errorMessage = '';
    this.portalError = false;

    this.patientService.sendPortalPasswordReset(patientId).subscribe(
      () => {
        this.passwordResetRequested = true;
      },
      (error) => {
        if (error.error && error.error.errors) {
          const errors = error.error.errors;
          this.errorMessage = errors[0].fieldErrors[0];
          this.portalError = true;
        }
      }
    );
  }

  sendPasswordResetButtonDisabled(): boolean {
    return (
      !this.patientPortalAccountExists ||
      this.passwordResetRequested ||
      this.thePatient == null ||
      this.thePatient?.email == null ||
      this.thePatient?.email == ''
    );
  }

  sendDocumentstToCompleteButtonDisabled(): boolean {
    return (
      !this.patientPortalAccountExists ||
      this.sendingDocumentsToCompleteEmail ||
      this.thePatient == null ||
      this.thePatient?.email == null ||
      this.thePatient?.email == ''
    );
  }

  showKioskMode() {
    this.router.navigateByUrl(`/portal/patientportalwizard/${this.thePatient.patientId})`);
  }

  sendDocumentsToComplete() {
    this.errorMessage = '';
    this.portalError = false;
    this.sendingDocumentsToCompleteEmail = true;
    this.patientService.sendDocumentsToComplete(this.thePatient.patientId).subscribe(
      () => {
        setTimeout(() => {
          this.sendingDocumentsToCompleteEmail = false;
        }, 5000);
      },
      (error) => {
        this.sendingDocumentsToCompleteEmail = false;
        if (error.error && error.error.errors) {
          const errors = error.error.errors;
          this.errorMessage = errors[0].fieldErrors[0];
          this.portalError = true;
        }
      }
    );
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
