import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';
import { BillingFrequency } from '@models/memberships/billing-frequency.enum';
import { Membership } from '@models/memberships/membership';
import { MembershipClinicTax } from '@models/memberships/membership-clinic-tax';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { BlobService } from '@services/blob.service';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { MembershipsService } from '@services/memberships.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-catalogue-memberships',
  templateUrl: './catalogue-memberships.component.html',
  styleUrls: ['./catalogue-memberships.component.less'],
})
export class CatalogueMembershipsComponent implements OnInit {
  disableGrid: boolean = false;
  @Input() height = 'calc(100dvh - 75px)';
  @Output() disableGridEvent = new EventEmitter<boolean>();

  searchValue = '';
  searchCtrl: FormControl;

  unsub: Subject<void> = new Subject<void>();
  loading = false;

  public gridView: GridDataResult;

  gridState: State = {
    sort: [
      {
        field: 'fieldName',
        dir: 'desc',
      },
    ],
    skip: 0,
    take: 20,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  editedRowIndex: number;
  editedDataItem: Membership;

  memberships: Membership[];
  readOnlySas: string;
  editPolicy = Policies.editMemberships;
  editPolicySatisfied = false;

  constructor(
    private deleteDialog: MatDialog,
    private router: Router,
    private membershipsService: MembershipsService,
    private catalogueUpdatesService: CatalogueUpdatesService,
    private blobService: BlobService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.editPolicySatisfied = this.authService.userSatisfiesPolicy(this.editPolicy);
    
    this.gridView = {
      data: [],
      total: 0,
    };

    this.catalogueUpdatesService.catalogueUpdated.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.disableGrid = false;
      if (this.catalogueUpdatesService.refreshRequired) {
        this.refreshData(true);
        this.catalogueUpdatesService.refreshRequired = false;
      }
    });

    this.refreshData(true);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.gridState = state;
    this.refreshData();
  }

  changeDisableGrid($event: boolean) {
    this.disableGrid = $event;
  }

  public editHandler({ dataItem }) {
    this.disableGrid = true;
    this.router.navigate([
      '/management/catalogue/memberships',
      { outlets: { 'action-panel': ['edit-membership', dataItem.id] } },
    ]);
  }

  addHandler() {
    this.disableGrid = true;
    this.router.navigate([
      '/management/catalogue/memberships',
      { outlets: { 'action-panel': ['edit-membership', '_'] } },
    ]);
  }

  public removeHandler({ dataItem }) {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'delete') {
          this.membershipsService.deleteMembership(dataItem.id).subscribe(() => {
            this.refreshData();
          });
        }
      });
  }

  refreshData(showLoad: boolean = true) {
    if (showLoad) this.loading = true;
    this.readOnlySas = this.blobService.getReadOnlySAS();
    this.memberships = [];
    this.membershipsService.getMemberships().subscribe((m) => {
      this.memberships = m;
      this.gridView = {
        data: this.memberships,
        total: this.memberships.length,
      };
      this.loading = false;
    });
  }

  getImageUrl(dataItem: Membership) {
    return dataItem.imageUrl != '' ? dataItem.imageUrl + this.readOnlySas : '';
  }

  getBillingFrequency(dataItem: Membership) {
    return BillingFrequency[dataItem.billingFrequency];
  }

  formatTaxesToString(dataItem: Membership) {
    var taxString = '';

    dataItem?.membershipClinicTaxes?.forEach((tax: MembershipClinicTax) => {
      taxString = taxString + tax?.tax?.name + ', ';
    });
    // Remove trailing commas
    taxString = taxString.replace(/,\s*$/, '');
    return taxString;
  }

  updateIsActive(membership: Membership, event) {
    this.membershipsService.updateIsActive(membership, event.target.checked).subscribe(() => {});
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
