<mat-error>
{{filter?.filterError$ | async}}
</mat-error>
<div class="photo-filter-container p-1 d-flex flex-column " style="border-bottom:1px solid grey;">
  <div class="row">
    <div
      title="Toggle Admin Mode"
      kendoTooltip
      tooltipClass="em-tooltip"
      position="top"
      title="Toggle consultation gallery images"
      class="h-100 filter-item special-filter-item special-filter-item-responsive white-font px-2 text-center"
      [ngClass]="{ 'selected-item': filter?.gallery_mode === GALLERY_MODE.Consultation }"
      (click)="filter.toggleConsultationMode()"
    >
      <i class="far fa-portrait portrait-icon pr-1"></i>Consult
    </div>

    <div
      title="Toggle Favourite Mode"

      kendoTooltip
      tooltipClass="em-tooltip"
      position="top"
      title="Toggle only showing favourite photos"
      class="h-100 filter-item white-font special-filter-item special-filter-item-responsive px-2 text-center"
      [ngClass]="{ 'selected-item': filter?.only_show_favourites === true }"
      (click)="filter.only_show_favourites = !filter.only_show_favourites"
    >
      <i class="far fa-heart portrait-icon pr-1"></i>Favourites
    </div>

    <!-- <div
      title="Toggle Coolsculpting Gallery"
      *ngIf="filter.gallery_mode === GALLERY_MODE.Global && photos && photos.length > 0"
      kendoTooltip
      tooltipClass="em-tooltip"
      position="top"
      title="Toggle only showing coolsculpting photos"
      class="h-100 filter-item white-font special-filter-item w-100 col-3 text-center"
      [ngClass]="{ 'selected-item': csMode }"
      (click)="toggleCoolsculptingMode()"
    >
      <i class="far fa-snowflake portrait-icon pr-1"></i>Coolsculpting
    </div> -->

    <div
      *ngIf="filter.gallery_mode === GALLERY_MODE.Consultation"
      title="Toggle stock images in gallery"
      kendoTooltip
      tooltipClass="em-tooltip"
      position="top"
      title="Toggle showing stock (Bodify) images"
      class="h-100 filter-item white-font special-filter-item special-filter-item-responsive px-2 text-center"
      [ngClass]="{ 'selected-item': filter.include_stock_photos }"
      (click)="filter.include_stock_photos = !filter.include_stock_photos"
    >
      <i class="far fa-stocking portrait-icon pr-1"></i>Stock Images
    </div>

    <div
      *ngIf="filterActive"
      kendoTooltip
      tooltipClass="em-tooltip"
      position="top"
      title="Reset photo filters"
      class="col-auto ml-auto white-font text-center btn em-btn em-btn-green reset-button"
      (click)="filter.resetFilter()"
    >
      <i class="fas fa-sync-alt"></i>
    </div>
  </div>
  <div class="row">

    <!--Photo type filter-->
    <div class="filter-column">
      <div class="text-center filter-header">Type</div>
      <div
        class="text-center filter-item"
        [ngClass]="{ 'selected-item': filter.isSelectedPhotoType(PHOTO_TYPES.Single) }"
        (click)="filter?.updateSelectedPhotoType(PHOTO_TYPES.Single)"
      >
        Single
      </div>
      <div
        class="text-center filter-item"
        [ngClass]="{ 'selected-item': filter.isSelectedPhotoType(PHOTO_TYPES.BeforeAfter) }"
        (click)="filter?.updateSelectedPhotoType(PHOTO_TYPES.BeforeAfter)"
      >
        B&A
      </div>
      <div
        class="text-center filter-item"
        [ngClass]="{ 'selected-item': filter.isSelectedPhotoType(PHOTO_TYPES.Series) }"
        (click)="filter?.updateSelectedPhotoType(PHOTO_TYPES.Series)"
      >
        Series
      </div>
    </div>

    <!--Sharing filter-->
    <div class="filter-column">
      <div class="px-0 text-center filter-header">Sharing</div>
      <div
        class="text-center filter-item"
        #referenceButton
        kendoTooltip
        tooltipclass="em-tooltip"
        [showOn]="filter?.gallery_mode === GALLERY_MODE.Consultation ? 'hover' : 'none'"
        title="Consultation mode blocks this option"
        [ngClass]="{ 'selected-item': filter?.isSelectedSharingType(SHARING_TYPES.None),
                     'disabled-item': filter?.gallery_mode === GALLERY_MODE.Consultation }"
        (click)="filter?.gallery_mode === GALLERY_MODE.Consultation ? null : filter.updateSelectedSharingMode(SHARING_TYPES.None)"
      >
        None
      </div>
      <div
        class="text-center filter-item"
        #referenceButton
        [ngClass]="{ 'selected-item': filter?.isSelectedSharingType(SHARING_TYPES.Internal) }"
        (click)="filter.updateSelectedSharingMode(SHARING_TYPES.Internal)"
      >
        Internal
      </div>
      <div
        class="text-center filter-item"
        #referenceButton
        [ngClass]="{ 'selected-item': filter?.isSelectedSharingType(SHARING_TYPES.External) }"
        (click)="filter.updateSelectedSharingMode(SHARING_TYPES.External)"
      >
        External
      </div>
    </div>

    <!--Gender Filter-->
    <div class="filter-column" *ngIf="filter?.isConsult() || filter?.isGlobal()">
      <div class="px-0 text-center filter-header">Gender</div>
      <div
        class="text-center filter-item"
        #referenceButton
        [ngClass]="{ 'selected-item': filter?.gender_filter == null }"
        (click)="filter.gender_filter = null"
      >
        Any
      </div>
      <div
        class="text-center filter-item"
        #referenceButton
        [ngClass]="{ 'selected-item': filter?.gender_filter == 'F' }"
        (click)="filter.gender_filter = 'F'"
      >
        Female
      </div>
      <div
        class="text-center filter-item"
        #referenceButton
        [ngClass]="{ 'selected-item': filter?.gender_filter == 'M' }"
        (click)="filter.gender_filter = 'M'"
      >
        Male
      </div>
    </div>

    <!--Patient service template filter-->
    <div class="filter-column" *ngIf="filter?.gallery_mode === GALLERY_MODE.Global">
      <div class="text-center filter-header">
        <mat-select
          #selectedVals1
          [disabled]="tags?.AutoGenerated?.length == 0"
          [(value)]="filter.service_template_filters"
          multiple
          placeholder="Patient"
        >
          <mat-option *ngFor="let tag of tags.AutoGenerated" [value]="tag">{{ tag.title }}</mat-option>
          <mat-select-trigger style="color: black; font-weight: bold">
           Patient
            <!-- <div *ngIf="filter?.service_template_filters?.length > 3" class="pl-1">
              (+{{ filter?.service_template_filters?.length - 3 }})
            </div> -->
          </mat-select-trigger>
        </mat-select>
      </div>
      <div
        *ngIf="filter?.service_template_filters?.length > 0"
        class="text-center filter-item selected-item"
        (click)="filter?.updatePatientServiceTemplateFilters(filter.service_template_filters[0])"
      >
        {{ filter.service_template_filters[0].title }}
      </div>
      <div
        *ngIf="filter?.service_template_filters?.length > 1"
        class="text-center filter-item selected-item"
        (click)="filter?.updatePatientServiceTemplateFilters(filter.service_template_filters[1])"
      >
        {{ filter.service_template_filters[1].title }}
      </div>
      <div
        *ngIf="filter?.service_template_filters?.length > 2"
        class="text-center filter-item selected-item"
        (click)="filter?.updatePatientServiceTemplateFilters(filter.service_template_filters[2])"
      >
        {{ filter.service_template_filters[2].title }}
      </div>
      <div class="filter-overflow">
        <span *ngIf="filter?.service_template_filters?.length > 3">
          (+{{ filter?.service_template_filters?.length - 3 }})
        </span>
      </div>
    </div>

    <!--Photo tag filter-->
    <div class="filter-column">
      <div class="text-center filter-header">
        <app-photo-tag-tree-dropdown [tags]="tags" [filter]="filter" (tagsSelected)="onTagsSelected($event)"></app-photo-tag-tree-dropdown>
      </div>
      <div
        *ngIf="filter?.all_tag_filters?.length > 0"
        class="text-center filter-item selected-item"
        (click)="filter?.updateTagFilters(filter.all_tag_filters[0])"
      >
        {{ filter.all_tag_filters[0].title }}
      </div>
      <div
        *ngIf="filter?.all_tag_filters?.length > 1"
        class="text-center filter-item selected-item"
        (click)="filter?.updateTagFilters(filter.all_tag_filters[1])"
      >
        {{ filter.all_tag_filters[1].title }}
      </div>
      <div
        *ngIf="filter?.all_tag_filters?.length > 2"
        class="text-center filter-item selected-item"
        (click)="filter?.updateTagFilters(filter.all_tag_filters[2])"
      >
        {{ filter.all_tag_filters[2].title }}
      </div>
      <div class="filter-overflow">
        <span *ngIf="filter?.all_tag_filters?.length > 3">
          (+{{ filter?.all_tag_filters?.length - 3 }})
        </span>
      </div>
    </div>
  </div>
</div>