import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { MerchantDevice } from '@models/finance/merchant-device';
import { PaymentMethod } from '@models/finance/payment-method';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MerchantDeviceService {
  private merchantDeviceSource = new BehaviorSubject<any>(undefined);
  merchantDeviceSource$ = this.merchantDeviceSource.asObservable();

  private merchantDeviceUpdateSource = new Subject<any>();
  merchantDeviceUpdateSource$ = this.merchantDeviceUpdateSource.asObservable();

  constructor(private http: HttpClient) {}

  shareMerchantDevice(merchantDevice: MerchantDevice) {
    this.merchantDeviceSource.next(merchantDevice);
  }

  updateMerchantDeviceData(state: boolean) {
    this.merchantDeviceUpdateSource.next(state);
  }

  getMerchantDevices() {
    return this.http.get<MerchantDevice[]>(environment.baseUrl + 'api/MerchantDevice');
  }

  getMerchantDevicePaymentMethods() {
    return this.http.get<PaymentMethod[]>(environment.baseUrl + 'api/MerchantDevice/MerchantDevicePaymentMethods');
  }

  getMerchantDeviceById(id) {
    return this.http.get<MerchantDevice>(environment.baseUrl + 'api/MerchantDevice/' + id);
  }

  addMerchantDevice(merchantDevice: MerchantDevice) {
    return this.http.post<MerchantDevice>(environment.baseUrl + 'api/MerchantDevice', merchantDevice);
  }

  updateMerchantDevice(merchantDevice: MerchantDevice) {
    return this.http.put<MerchantDevice>(environment.baseUrl + 'api/MerchantDevice', merchantDevice);
  }

  deleteMerchantDevice(merchantDevice: MerchantDevice) {
    return this.http.delete<MerchantDevice>(environment.baseUrl + 'api/MerchantDevice/' + merchantDevice.id);
  }
}
