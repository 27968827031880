<div class="px-3">
  <div class="row">
    <div class="col">
      <p class="text-uppercase white-font font-weight-bold mt-1 bigger mb-0">Direct Messages</p>
    </div>
    <div class="col-2 text-right">
      <button class="btn p-1" (click)="onAddPrivateChannel()">
        <i class="fal fa-plus-circle white-font"></i>
      </button>
    </div>
  </div>
  <div *ngFor="let channel of channelService.privateChannels">
    <div
      class="private-channel row mb-1"
      [ngClass]="{ selected: selectedChannelId === channel.id }"
      (click)="onChannelSelected(channel)"
    >
      <div class="col">
        <p
          class="d-flex align-items-center py-1 my-0 bigger grey"
          [ngClass]="{ 'white-font': selectedChannelId === channel.id }"
        >
          <i
            class="fas fa-circle smaller pr-2"
            [ngClass]="{
              'transparent-font': channel.status !== 1,
              'spring-green-font': selectedChannelId !== channel.id && channel.status === 1,
              'white-font': selectedChannelId === channel.id && channel.status === 1
            }"
          >
          </i>
          <span [ngClass]="{ 'new-messages': channelService.checkNewMessages(channel) }">
            {{ channelService.privateChannelTitles.get(channel.id) }}
            <span *ngIf="channelService.checkNewMessages(channel)" class="notification-badge position-absolute">{{
              channelService.channelsNotificationCounts.get(channel.id)
            }}</span>
          </span>
        </p>
      </div>
    </div>
  </div>
</div>
