<ngx-loading [show]="loading"></ngx-loading>
<div class="p-3 d-flex flex-column align-items-center light-grey-background" *ngIf="!isOpenPaymentModal">
  <h6 class="navy-font text-center">REFUND</h6>
  <form [formGroup]="returnForm" class="w-100 payment-form">
    <mat-radio-group name="returnType" formControlName="returnType">
      <!-- Product Section -->
      <div class="card mb-2">
        <div class="card-body">
          <mat-radio-button data-test-id="returnProduct" [value]="PaymentType.RefundProduct">
            Product
          </mat-radio-button>
          <div
            formArrayName="formReturnProducts"
            *ngFor="
              let paymentMethod of returnForm?.get('formReturnProducts')['controls'];
              let i = index;
              let length = count
            "
          >
            <div [formGroupName]="i" class="w-100 d-flex align-items-center justify-content-between">
              <div class="w-100 navy-font d-flex flex-column">
                <div
                  class="d-flex align-items-center w-100 flex-grow-1"
                  [ngClass]="{
                    'form-field-disabled': returnType !== PaymentType.RefundProduct
                  }"
                >
                  <div class="w-100">
                    <mat-form-field [floatLabel]="'never'">
                      <mat-select
                        formControlName="product"
                        data-test-id="returnProductName"
                        (selectionChange)="onSelectProductsPurchased(i)"
                      >
                        <mat-option
                          *ngFor="let item of productsPurchased"
                          [value]="item"
                          [ngClass]="{ 'force-hide': isSelectedProduct(item) }"
                        >
                          <div class="d-flex flex-column w-100" style="line-height: 1.3em">
                            <div class="d-flex">
                              <div class="text-left mr-1" style="width: calc(100% / 3)">
                                Invoice #{{ item.invoiceId }}
                              </div>
                              <div class="text-left text-truncate flex-grow-1" style="width: calc(100% / 3)">
                                {{ item.quantity }}x {{ item.name }}
                              </div>
                              <div class="text-right">{{ item.pricePaid | currency }}</div>
                            </div>
                            <div class="d-flex">
                              <span
                                *ngIf="item.purchaseDate; else servicesListSpacer"
                                class="font-weight-light text-truncate mr-auto"
                                style="color: grey; font-size: 0.8em"
                              >
                                {{ item.purchaseDate | date : 'yyyy-MM-dd' }}</span
                              >
                              <ng-template #servicesListSpacer>&nbsp;</ng-template>
                            </div>
                          </div>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div
                    *ngIf="returnForm?.get('formReturnProducts')['controls'][i]?.value?.product?.quantity > 1"
                    class="navy-font flex-shrink-1 ml-2"
                  >
                    <div class="d-flex align-items-center">
                      Qty:
                      <div class="ml-2">
                        <mat-form-field [floatLabel]="'never'" style="min-width: 4em">
                          <input
                            matInput
                            onlyNumber
                            data-test-id="returnProductQuantity"
                            type="number"
                            min="1"
                            [max]="returnForm?.get('formReturnProducts')['controls'][i]?.value?.product?.quantity"
                            formControlName="productQuantity"
                            (keyup)="
                              validateProductsQuantity(
                                $event,
                                returnForm?.get('formReturnProducts')['controls'][i]?.value?.product?.quantity
                              )
                            "
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div
                      *ngIf="!isEnteredQuantityValid && returnType !== PaymentType.RefundProduct"
                      class="alert-danger px-1"
                    >
                      Max quantity is
                      {{ returnForm?.get('formReturnProducts')['controls'][i]?.value?.product?.quantity }}
                    </div>
                  </div>
                  <button
                    *ngIf="length > 1"
                    type="button"
                    class="btn btn-sm em-btn em-btn-green ml-2"
                    data-test-id="returnRemoveProduct"
                    (click)="removeProduct(i)"
                  >
                    <i class="far fa-minus-circle"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            [ngClass]="{
              'form-field-disabled': returnType !== PaymentType.RefundProduct
            }"
          >
            <button type="button" class="btn btn-sm em-btn em-btn-green" (click)="addProduct()">
              <i class="far fa-plus-circle"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- Service Section -->
      <div class="card mb-2">
        <div class="card-body">
          <mat-radio-button data-test-id="returnService" [value]="PaymentType.RefundService">
            Service
          </mat-radio-button>
          <div
            formArrayName="formReturnServices"
            *ngFor="let paymentMethod of returnForm?.get('formReturnServices')['controls']; let i = index"
          >
            <div [formGroupName]="i" class="w-100 d-flex align-items-center justify-content-between">
              <div class="w-100 navy-font d-flex flex-column font-weight-bold">
                <div
                  class="d-flex align-items-center w-100"
                  [ngClass]="{
                    'form-field-disabled': returnType !== PaymentType.RefundService
                  }"
                >
                  <div class="w-100">
                    <mat-form-field [floatLabel]="'never'">
                      <mat-select
                        formControlName="service"
                        [attr.data-test-id]="'return-service-select-open-' + i"
                        (selectionChange)="onSelectServicePurchased(i)"
                      >
                        <mat-select-trigger
                          >Invoice #{{ formReturnServices?.controls[i].value.service?.invoiceId }}
                          {{ formReturnServices?.controls[i].value.service?.name }}
                          {{ formReturnServices?.controls[i].value.service?.pricePaid | currency }}</mat-select-trigger
                        >
                        <mat-option
                          *ngFor="let item of servicesPurchased"
                          [value]="item"
                          [attr.data-test-id]="
                            'return-service-select-' +
                            item.invoiceId +
                            '-' +
                            item.name +
                            (item.serviceTypeDescription == null || item.serviceTypeDescription == ''
                              ? ''
                              : '-' + item.serviceTypeDescription)
                          "
                          [ngClass]="{ 'force-hide': isSelectedService(item) || item.pricePaid === 0 }"
                        >
                          <div class="d-flex flex-column w-100" style="line-height: 1.3em">
                            <div class="d-flex">
                              <div class="text-left" style="width: calc(100% / 3)">Invoice #{{ item.invoiceId }}</div>
                              <div class="d-inline-flex" style="width: calc((100% / 3) * 2)">
                                <span class="text-left text-truncate">{{ item.name }} </span>
                                <div class="flex-grow-1 text-right">{{ item.pricePaid | currency }}</div>
                              </div>
                            </div>
                            <div class="d-flex">
                              <span
                                *ngIf="item.purchaseDate; else cancellationsListSpacer"
                                class="font-weight-light text-truncate mr-auto"
                                style="color: grey; font-size: 0.8em"
                              >
                                {{ item.purchaseDate | date : 'yyyy-MM-dd' }}</span
                              >
                              <span
                                *ngIf="item.serviceTypeDescription; else servicesListSpacer"
                                class="font-weight-light text-truncate ml-auto"
                                style="color: grey; font-size: 0.8em"
                              >
                                {{ item.serviceTypeDescription }}</span
                              >
                              <ng-template #servicesListSpacer>&nbsp;</ng-template>
                            </div>
                          </div>
                        </mat-option>
                      </mat-select>
                      <mat-hint>{{ formReturnServices?.controls[i].value?.service?.serviceTypeDescription }}</mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div [formGroup]="plannedTreatmentMultiplesForm" *ngIf="isPlannedTreatmentMultiple" class="pt-2">
              <mat-checkbox
                *ngFor="let plannedTreatment of plannedTreatmentMultiple?.plannedTreatments"
                class="pl-4"
                style="display: block"
                [disabled]="plannedTreatment.treatmentState !== TreatmentState.Unplanned"
                (click)="plannedTreatmentMultipleClick(plannedTreatment)"
                >{{ plannedTreatmentMultiple?.service }}: {{ plannedTreatmentMultiple?.price | currency }} -
                <span
                  *ngIf="
                    plannedTreatment.treatmentState === TreatmentState.Scheduled ||
                    plannedTreatment.treatmentState === TreatmentState.Completed
                  "
                  >{{ plannedTreatment.plannedDate | date : 'yyyy-MM-dd' }}</span
                >
                <span *ngIf="plannedTreatment.treatmentState === TreatmentState.Unplanned"> Available</span>
                <span *ngIf="plannedTreatment.treatmentState === TreatmentState.Returned"> Refunded </span>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
      <!-- Admin Fee Section -->
      <div class="card mb-2">
        <div class="card-body">
          <mat-radio-button data-test-id="returnAdminFee" [value]="PaymentType.RefundAdminFee">
            Admin Fee
          </mat-radio-button>
          <div
            formArrayName="formReturnFees"
            *ngFor="let returnFeeControl of returnForm?.get('formReturnFees')['controls']; let i = index"
          >
            <div [formGroupName]="i" class="w-100 d-flex align-items-center justify-content-between">
              <div class="w-100 navy-font d-flex flex-column font-weight-bold">
                <div
                  class="d-flex align-items-center w-100"
                  [ngClass]="{
                    'form-field-disabled': returnType !== PaymentType.RefundAdminFee
                  }"
                >
                  <div class="w-100">
                    <mat-form-field [floatLabel]="'never'">
                      <mat-select formControlName="fee" [attr.data-test-id]="'return-fee-select-open-' + i">
                        <mat-select-trigger
                          >Invoice #{{ returnFeeControl.value.fee?.invoiceId }}
                          {{ returnFeeControl.value.fee?.pricePaid | currency }}</mat-select-trigger
                        >
                        <mat-option
                          *ngFor="let item of adminFeesPurchased"
                          [value]="item"
                          [attr.data-test-id]="
                            'return-fee-select-' +
                            item.invoiceId +
                            '-' +
                            (item.serviceTypeDescription == null || item.serviceTypeDescription == ''
                              ? ''
                              : '-' + item.serviceTypeDescription)
                          "
                          [ngClass]="{ 'force-hide': isSelectedFee(item) || item.pricePaid === 0 }"
                        >
                          <div class="d-flex flex-column w-100" style="line-height: 1.3em">
                            <div class="d-flex">
                              <div class="text-left" style="width: calc(100% / 3)">Invoice #{{ item.invoiceId }}</div>
                              <div class="d-inline-flex" style="width: calc((100% / 3) * 2)">
                                <div class="flex-grow-1 text-right">{{ item.pricePaid | currency }}</div>
                              </div>
                            </div>
                            <div class="d-flex">
                              <span
                                *ngIf="item.purchaseDate; else cancellationsListSpacer"
                                class="font-weight-light text-truncate mr-auto"
                                style="color: grey; font-size: 0.8em"
                              >
                                {{ item.purchaseDate | date : 'yyyy-MM-dd' }}</span
                              >
                              <span
                                *ngIf="item.serviceTypeDescription; else servicesListSpacer"
                                class="font-weight-light text-truncate ml-auto"
                                style="color: grey; font-size: 0.8em"
                              >
                                {{ item.serviceTypeDescription }}</span
                              >
                              <ng-template #servicesListSpacer>&nbsp;</ng-template>
                            </div>
                          </div>
                        </mat-option>
                      </mat-select>
                      <mat-hint>{{ returnFeeControl.value?.fee?.serviceTypeDescription }}</mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Cancellations Section -->
      <div class="card mb-2">
        <div class="card-body">
          <mat-radio-button data-test-id="returnPaidCancellation" [value]="PaymentType.RefundPaidCancellation">
            Cancellation Fee
          </mat-radio-button>
          <div
            formArrayName="formPaidCancellations"
            *ngFor="let paidCancellationControl of returnForm?.get('formPaidCancellations')['controls']; let i = index"
          >
            <div [formGroupName]="i" class="w-100 d-flex align-items-center justify-content-between">
              <div class="w-100 navy-font d-flex flex-column font-weight-bold">
                <div
                  class="d-flex align-items-center w-100"
                  [ngClass]="{
                    'form-field-disabled': returnType !== PaymentType.RefundPaidCancellation
                  }"
                >
                  <div class="w-100">
                    <mat-form-field [floatLabel]="'never'">
                      <mat-select
                        formControlName="paidCancellation"
                        [attr.data-test-id]="'paid-cancellation-select-open-' + i"
                      >
                        <mat-select-trigger
                          >Invoice #{{ paidCancellationControl.value?.paidCancellation?.invoiceId }}
                          {{
                            paidCancellationControl.value?.paidCancellation?.pricePaid | currency
                          }}</mat-select-trigger
                        >
                        <mat-option
                          *ngFor="let item of paidCancellations"
                          [value]="item"
                          [attr.data-test-id]="
                            'paid-cancellation-select-' +
                            item.invoiceId +
                            '-' +
                            (item.description == null || item.description == '' ? '' : '-' + item.description)
                          "
                          [ngClass]="{ 'force-hide': isSelectedPaidCancellation(item) || item.pricePaid === 0 }"
                        >
                          <div class="d-flex flex-column w-100" style="line-height: 1.3em">
                            <div class="d-flex">
                              <div class="text-left" style="width: calc(100% / 3)">Invoice #{{ item.invoiceId }}</div>
                              <div class="d-inline-flex" style="width: calc((100% / 3) * 2)">
                                <div class="flex-grow-1 text-right">{{ item.pricePaid | currency }}</div>
                              </div>
                            </div>
                            <div class="d-flex">
                              <span
                                *ngIf="item.purchaseDate; else cancellationsListSpacer"
                                class="font-weight-light text-truncate mr-auto"
                                style="color: grey; font-size: 0.8em"
                              >
                                {{ item.purchaseDate | date : 'yyyy-MM-dd' }}</span
                              >
                              <span
                                *ngIf="item.description; else cancellationsListSpacer"
                                class="font-weight-light text-truncate ml-auto"
                                style="color: grey; font-size: 0.8em"
                              >
                                {{ item.description }}</span
                              >
                            </div>
                          </div>
                        </mat-option>
                      </mat-select>
                      <mat-hint>{{ paidCancellationControl.value?.paidCancellation?.description }}</mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Credit Section -->
      <div class="card mb-2">
        <div class="card-body">
          <mat-radio-button
            [disabled]="!creditsPurchased || creditsPurchased <= 0"
            data-test-id="returnCredit"
            [value]="PaymentType.RefundCredit"
          >
            Credit
          </mat-radio-button>
          <div
            formArrayName="formReturnCredits"
            *ngFor="let paymentMethod of returnForm?.get('formReturnCredits')['controls']; let i = index"
          >
            <div [formGroupName]="i" class="w-100">
              <div class="w-100 navy-font d-flex flex-column font-weight-bold">
                <div
                  class="d-flex align-items-center w-100 flex-grow-1"
                  [ngClass]="{
                    'mb-3': !isEnteredQuantityValid && returnType === PaymentType.RefundCredit,
                    'form-field-disabled': returnType !== PaymentType.RefundCredit
                  }"
                >
                  <div class="navy-font d-flex flex-column w-100">
                    <div class="d-flex align-items-center">
                      Amount ($):
                      <div class="flex-grow-1 ml-1">
                        <mat-form-field [floatLabel]="'never'">
                          <input
                            matInput
                            onlyNumber
                            RestrictDecimalPlacesNumber
                            [nDecimalPlaces]="2"
                            numericType="decimal"
                            formControlName="creditQuantity"
                            data-test-id="returnCreditQuantity"
                            (keyup)="validateProductsQuantity($event, creditsPurchased)"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div
                      *ngIf="!isEnteredQuantityValid && returnType === PaymentType.RefundCredit"
                      class="alert-danger px-1"
                    >
                      Credit available to refund: ${{ creditsPurchased | number : '1.2-2' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-radio-group>
    <div class="d-flex">
      <button
        type="button"
        class="schedNewCommButton em-btn em-btn-green py-2 px-3 mb-0 mx-auto"
        (click)="onReturnFormSubmit()"
        data-test-id="returnNextPage"
        [disabled]="nextDisabled()"
        [ngClass]="{
          'form-field-disabled': nextDisabled()
        }"
      >
        Next
      </button>
    </div>
  </form>
</div>

<ng-template #cancellationsListSpacer>&nbsp;</ng-template>

<div class="next-step" [ngClass]="{ active: isOpenPaymentModal }">
  <app-invoice-payment
    [paymentType]="returnType"
    [patientId]="patientId"
    [selectedInvoice]="selectedInvoice$"
    [paidCancellation]="paidCancellation"
    (backToReturnPanel)="backToReturnPanel()"
    (paymentComplete)="onInvoicePay()"
  >
  </app-invoice-payment>
</div>
