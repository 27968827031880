import { ClinicPaymentProcessorSettings } from './../../../models/payments/clinic-payment-processor-settings';
import { PaymentProcessorType } from '@models/payments/payment-processor-type.enum';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Clinic } from '@models/clinic';
import { ClinicConvergeMerchant } from '@models/payments/clinic-converge-merchant';
import { ClinicConvergeMerchantService } from '@services/clinic-converge-merchant.service';
import { ClinicsService } from '@services/clinics.service';
import { SquareService } from '@services/square.service';
import { pipe, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-org-payment-processors',
  templateUrl: './org-payment-processors.component.html',
  styleUrls: ['./org-payment-processors.component.less'],
})
export class OrgPaymentProcessorsComponent implements OnInit {
  form: FormGroup;
  loading: boolean;
  unsub = new Subject<any>();
  clinicId: number;
  clinic: Clinic;
  clinicConvergeMerchant: ClinicConvergeMerchant;
  squareAuthPageUrl: string = '';
  isSquareLinked: boolean = null;

  constructor(
    private formBuilder: FormBuilder,
    private clinicsService: ClinicsService,
    private clinicConvergeMerchantService: ClinicConvergeMerchantService,
    private squareService: SquareService
  ) {}

  ngOnInit() {
    this.initForm();

    this.clinicsService.clinicIdSelected$
      .asObservable()
      .pipe(takeUntil(this.unsub))
      .subscribe((clinicId) => {
        this.clinicId = clinicId;
        this.init();
      });
    this.getIsSquareLinked();
  }

  init() {
    this.loading = true;

    this.clinicConvergeMerchantService
      .getClinicConvergeMerchant()
      .pipe(takeUntil(this.unsub))
      .subscribe({
        next: (clinicConvergeMerchant) => {
          if (clinicConvergeMerchant == null) {
            this.clinicConvergeMerchant = new ClinicConvergeMerchant();
          } else {
            this.clinicConvergeMerchant = clinicConvergeMerchant;
          }
          this.loading = false;
          this.initForm();
        },
      });

    this.clinicsService
      .getClinicById(this.clinicId)
      .pipe(takeUntil(this.unsub))
      .subscribe({
        next: (clinic) => {
          this.clinic = clinic;
          this.form.get('paymentProcessorType').setValue(this.clinic.paymentProcessorType);
          this.whenPaymentProcessorTypeChanged(this.clinic.paymentProcessorType);
          this.loading = false;
        },
      });
  }

  initForm() {
    this.form = this.formBuilder.group({
      paymentProcessorType: this.clinic?.paymentProcessorType,
      id: [this.clinicConvergeMerchant?.id],
      merchantId: [this.clinicConvergeMerchant?.merchantId, [Validators.required]],
      merchantUserId: [this.clinicConvergeMerchant?.merchantUserId, [Validators.required]],
      merchantPinCode: [this.clinicConvergeMerchant?.merchantPinCode, [Validators.required]],
    });
  }

  getIsSquareLinked() {
    this.squareService
      .getIsSquareLinked()
      .pipe(takeUntil(this.unsub))
      .subscribe((isSquareLinked) => {
        this.isSquareLinked = isSquareLinked;
      });
  }

  onLinkSquareAccountClick() {
    this.squareService
      .getAuthPageUrl()
      .pipe(takeUntil(this.unsub))
      .subscribe((url) => {
        const windowRef = window.open(url, 'Link Square to Emily', 'left=20,top=20,width=500,toolbar=1,resizable=0');
        windowRef.focus();
        var timer = setInterval(() => {
          if (windowRef.closed) {
            clearInterval(timer);
            this.getIsSquareLinked();
          }
        }, 500);
      });
  }

  whenPaymentProcessorTypeChanged(value) {
    if (value === PaymentProcessorType.EmilyPay) {
      this.form.controls['merchantId'].enable();
      this.form.controls['merchantUserId'].enable();
      this.form.controls['merchantPinCode'].enable();
    } else {
      this.form.controls['merchantId'].disable();
      this.form.controls['merchantUserId'].disable();
      this.form.controls['merchantPinCode'].disable();
    }
  }

  onSave() {
    this.loading = true;
    // Save the Payment Processor Type for the Clinic
    let processorType = this.form.get('paymentProcessorType').value;

    let clinicPaymentProcessorSettings: ClinicPaymentProcessorSettings = {
      paymentProcessorType: processorType,
      clinicConvergeMerchant: this.form.value,
      clinicId: this.clinicId,
    };

    this.clinicsService
      .updateClinicPaymentProcessorSettings(this.clinicId, clinicPaymentProcessorSettings)
      .pipe(takeUntil(this.unsub))
      .subscribe({
        next: () => {
          this.loading = false;
        },
      });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
