import { TagType } from '@models/tag/tag-type';
import { PhotoConsentType } from '@models/photo/photo-consent-type'; 
import { TagCategory } from '@app/photos/photo-filter';

export class Tag {
  id?: number;
  tagId: string;
  title: string;
  hexColour?: string ;
  tagCategoryId?: number;
  category?: TagCategory;
  type: TagType;
  refId: string;
  isActive: boolean;

  static consentedTags: Tag[] = [
    {
      tagId: 'Consent-0',
      title: 'No Sharing',
      type: TagType.consentType,
      refId: String(PhotoConsentType.None),
      isActive: true
    },
    {
      tagId: 'Consent-1',
      title: 'Internal',
      type: TagType.consentType,
      refId: String(PhotoConsentType.Internal),
      isActive: true
    },
    {
      tagId: 'Consent-2',
      title: 'External',
      type: TagType.consentType,
      refId: String(PhotoConsentType.InternalExternal),
      isActive: true
    }
  ];

  static avatarTag: Tag = {
    tagId: 'Avatar-0',
    title: 'Current Avatar',
    type: TagType.avatar,
    refId: 'Current Avatar',
    isActive: true
  }

  static typeTag = {
    ba: {
      tagId: 'Photo-Type-Before-After', //hardcoded now
      title: 'Before/After',
      type: TagType.photoType,
      refId: 'before-after-custom-tag',
      isActive: true
    },
    multi: {
      tagId: 'Photo-Type-Multi',  //hardcoded now
      title: 'Series',
      type: TagType.photoType,
      refId: 'series-custom-tag',
      isActive: true
    }
  };
   

  public constructor(id: number, tagId: string, title: string, type: TagType, refId: string) {
    this.id = id;
    this.tagId = tagId;
    this.title = title;
    this.type = type;
    this.refId = refId;
    this.isActive = true;
  }
}

export class ServiceTag extends Tag {
  public constructor(id: number, tagId: string, title: string, type: TagType, refId: string, clinicServiceTemplateId: number) {
    super(id, tagId, title, type, refId);
    this.clinicServiceTemplateId = clinicServiceTemplateId;
  }

  clinicServiceTemplateId: number; 
}

export class SupplyTag extends Tag {
  public constructor(id: number, tagId: string, title: string, type: TagType, refId: string, observationListId: number) {
    super(id, tagId, title, type, refId);
    this.observationListId = observationListId;
  }

  observationListId: number;
}