<div #exterior kendoTooltip tooltipClass="em-tooltip" position="left">
  <ngx-loading [show]="loading || navLocked"></ngx-loading>

  <!-- <app-assessment-feed *ngIf="!isTreatmentPlan" [serviceId]="service?.serviceId" [serviceTemplateId]="service?.templateId" [associatedPlannedTreatment]="associatedPlannedTreatment">
  </app-assessment-feed> -->
  <div class="container panel-item-border pt-2">
    <!-- Header -->
    <div class="row" #headerSection>
      <div class="col d-flex">
        <h5>{{ !isTreatmentPlan ? 'Treatment' : 'Assessment' }}</h5>

        <button
          (click)="onSaveAsFavourite()"
          class="btn btn-sm em-btn em-btn-green btn-treatment"
          data-test-id="observation-favourite-save"
          [ngClass]="{ 'mr-2': !isServicePaid }"
        >
          <i class="far fa-heart" title="Save as Favorite"></i>
        </button>
      </div>
    </div>

    <!-- Coolsculpting Observations -->
    <div class="coolsculpting-detail-wrapper d-flex pb-2 teal-font w-100">
      <div class="col-1 p-0"></div>
      <div class="col-5 p-0">Applicator & Treatment Area</div>
      <div class="col-2 p-0">$/Cycle</div>
      <div class="col-2 p-0">Total</div>
      <div class="col-2"></div>
    </div>

    <perfect-scrollbar
      *ngIf="treatmentMap && treatmentMap.size > 0"
      class="scrollbar-container"
      style="position: relative"
      appDetermineMaxScrollHeight
      [addHeight]="
        -1 *
        (25 +
          headerSection?.offsetHeight +
          footerArea?.offsetHeight +
          notesSection?.offsetHeight +
          saveSection?.offsetHeight)
      "
      [itemsToWatch]="[treatmentMap]"
    >
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let treatment of treatmentMap | keyvalue"
          [expanded]="false"
          [disabled]="true"
          #panelList
        >
          <mat-expansion-panel-header class="px-0 py-0 pr-2" [collapsedHeight]="'36px'" [expandedHeight]="'36px'">
            <mat-panel-title>
              <div class="observation-area">
                <div class="bigger p-0 col-1 justify-content-center d-flex">
                  <i [ngStyle]="{ color: onGetCircleColor(treatment.key) }" class="fas fa-circle"></i>
                </div>
                <div class="col-5 p-0">
                  <h6
                    class="small-heading navy-font mb-0 truncate-two-lines"
                    title="{{ coolsculptingFormService.onGetApplicator(treatment.key)?.name }} :
                  {{ coolsculptingFormService.onGetArea(treatment.key).name }} :
                  {{ coolsculptingFormService.onGetPosition(treatment.key) }} : {{ onGetCycles(treatment.key) }}"
                  >
                    {{ coolsculptingFormService.onGetApplicator(treatment.key).name }} :
                    {{ coolsculptingFormService.onGetArea(treatment.key).name }} :
                    {{ coolsculptingFormService.onGetPosition(treatment.key) }} : {{ onGetCycles(treatment.key) }}
                    <!-- : {{coolsculptingFormService.vacuum}} -->
                  </h6>
                </div>
                <div class="col-2 px-1 py-0">
                  <app-unit-billing
                    [service]="service"
                    [key]="treatment.key"
                    [observations]="onGetObservations(treatment.key)"
                    [isLocked]="service.isLocked || associatedPlannedTreatment?.paymentStatus == PaymentStatus.Paid"
                  >
                    >
                  </app-unit-billing>
                </div>
                <div class="col-2 px-1 py-0">
                  <app-observation-billing
                    [service]="service"
                    [key]="treatment.key"
                    [observations]="onGetObservations(treatment.key)"
                    [isLocked]="service.isLocked || associatedPlannedTreatment?.paymentStatus == PaymentStatus.Paid"
                  >
                  </app-observation-billing>
                </div>
                <div class="col-2 p-0 d-flex justify-content-end">
                  <button
                    *ngIf="
                      !service?.isLocked &&
                      isPriceOverriden(treatment.key) &&
                      associatedPlannedTreatment?.paymentStatus != PaymentStatus.Paid
                    "
                    type="button"
                    class="btn btn-sm em-btn em-btn-green d-flex align-items-center justify-content-center mr-1"
                    (click)="undoOverridePrice(treatment.key)"
                  >
                    <i class="far fa-undo" title="Undo Treatment"></i>
                  </button>

                  <button
                    *ngIf="!service?.isLocked && associatedPlannedTreatment?.paymentStatus != PaymentStatus.Paid"
                    data-test-id="observation-delete"
                    class="btn btn-sm em-btn em-btn-green d-flex align-items-center justify-content-center mr-1"
                    (click)="onDeleteTreatment(treatment.key)"
                  >
                    <small><i class="far fa-trash-alt" title="Delete Treatment"></i></small>
                  </button>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </mat-accordion>
    </perfect-scrollbar>
    <div class="d-flex" #footerArea>
      <div class="w-100 grey-four-border-top">
        <app-treatment-billing [service]="service" (sendChargeAmount)="updateChargeAmount($event)"></app-treatment-billing>
      </div>
    </div>
  </div>
</div>

<!---->

<!-- Notes -->
<div class="grey-two-background p-2" #notesSection>
  <div class="row px-3">
    <div class="col d-flex">
      <h6 class="mt-1 small-heading navy-font">Treatment Notes</h6>
    </div>
  </div>
  <div class="row px-3">
    <div class="col">
      <textarea
        [ngClass]="{ 'grey-two-background': service.isLocked }"
        class="service-chart-input treatment-notes-input w-100"
        placeholder="Add a note"
        [readonly]="service.isLocked"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"
        [(ngModel)]="serviceNoteText"
        (change)="currentDataService.treatmentIsDirty = true"
      ></textarea>
    </div>
  </div>
</div>

<div class="row mt-2 px-3" #saveSection>
  <button (click)="onToggleView()" class="btn btn-grey-two btn-clean em-btn-black-text ml-auto">CLOSE</button>
  <button
    *ngIf="!service?.isLocked"
    (click)="onSave(false)"
    [disabled]="!currentDataService.treatmentIsDirty"
    class="btn em-btn em-btn-green ml-2"
  >
    Save
  </button>
  <button
    *ngIf="!service?.isLocked"
    (click)="onSave(true)"
    [disabled]="!currentDataService.treatmentIsDirty"
    class="btn em-btn em-btn-green ml-2"
  >
    Save & Close
  </button>
</div>
