<ngx-loading [show]="loading"></ngx-loading>
<div class="grey-seven-background">  
  <ng-container *ngFor="let error of errors">
    <app-error *ngFor="let fieldError of error.fieldErrors" [message]="fieldError"></app-error>
  </ng-container>

  <div class="p-4 d-flex flex-column align-items-center grey-two-background">   
    <h5 class="modal-title text-uppercase navy-font text-center">UPDATE PATIENT CREDITS</h5>
    <div class="credit mt-3 mb-2">     
      <div class="credit-row p-4 font-weight-bold white-font navy-background">
        <div>CURRENT PATIENT CREDITS:</div>
        <div>{{this.patientAvailableCredit | currency}}</div>
      </div>     
    </div>
  </div>
  <div class="px-5 py-4 d-flex flex-column align-items-center">
  <h6 class="my-0 navy-font w-100">
   New Patient Credits
  </h6>
  <form [formGroup]="updateCreditForm" class="w-100 credit-form">
    <div class="pb-3 field-wrapper">
      <div class="navy-font d-flex align-items-center font-weight-bold form-field ml-auto">
        Amount ($):
        <div class="flex-grow-1 ml-2">
          <mat-form-field [floatLabel]="'never'">
            <input matInput formControlName="newCreditAmount" onlyNumber numericType="decimal">
          </mat-form-field>
        </div>
      </div>
      <div class="alert-danger px-1" *ngIf="updateCreditForm.get('newCreditAmount').value < 0 ? true : false">
        Please enter a valid amount.
      </div>
    </div>   
    <div class="d-flex justify-content-around align-items-center">        
      <button
        type="button"
        class="schedNewCommButton center em-btn em-btn-green py-2 px-3 mb-0"
        [disabled]="!updateCreditForm.valid ||  updateCreditForm.get('newCreditAmount').value < 0"
        (click)="onUpdateClick()">
        Update
      </button>
      <button
      type="button"
      class="schedNewCommButton center em-btn em-btn-green py-2 px-3 mb-0"
      (click)="onCancelClick()">
       Cancel
    </button>  
    </div>
  </form>
</div>
</div>