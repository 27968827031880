import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-generic-input',
  templateUrl: './generic-input.component.html'
})
export class GenericInputComponent implements OnInit {
  public dialog: MatDialog;
  title: string;
  content: string;
  inputLabel: string;
  confirmButtonText: string;
  input: any;

  constructor(public dialogRef: MatDialogRef<GenericInputComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.title = this.data.title;
    this.content = this.data.content;
    this.inputLabel = this.data.inputLabel;
    this.confirmButtonText = this.data.confirmButtonText;
  }

  onNoClick(): void {
    this.data = null;
  }

}
