import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { PatientTransaction, PatientTransactionServiceNote } from '@models/patient-transaction/patient-transaction';
import { moment } from 'fullcalendar';

@Injectable({
  providedIn: 'root'
})
export class PatientTransactionService {
  patientTransactionChanged = new Subject<PatientTransaction[]>();

  toggleItemInput = new Subject<{patientTransaction: PatientTransaction, serviceId?: number}>();
  serviceNoteEditClick = new Subject<{ptServiceNote: PatientTransactionServiceNote, serviceId: number}>();
  saveServiceNote = new Subject<void>();

  ptServiceNoteUpdated = new Subject<PatientTransactionServiceNote>();

  defaultId = 0;
  defaultPageSize = 10;

  constructor(private http: HttpClient) {}

  getPatientTransactions(patientId: number, pageNumber?: number, pageSize?: number) {
    let apiUri = 'api/PatientTransactions/PatientTransactionsByPatientId/' + patientId;

    if (pageNumber !== undefined && pageSize !== undefined) {
      apiUri += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }

    return this.http.get<PatientTransaction[]>(environment.baseUrl + apiUri);
  }

  searchPatientTransactions(patientId: number, userIdList: string[], searchText: string, pageNumber?: number, pageSize?: number): Observable<PatientTransaction[]> {
    const apiUri = `${environment.baseUrl}api/PatientTransactions/PatientTransactionsByPatientId/${patientId}`;
    const apiSearchUrl = `${environment.baseUrl}api/PatientTransactions/Search/${patientId}`;
    const currentUrl = searchText === '' && userIdList.length === 0 ? apiUri : apiSearchUrl;

    const paramsObj = {};

    if (userIdList.length > 0) {
      paramsObj['userIdList'] = userIdList;
    }
    if (searchText !== '') {
      paramsObj['searchText'] = searchText;
    }
    if (pageNumber !== undefined && pageSize !== undefined) {
      paramsObj['pageNumber'] = pageNumber;
      paramsObj['pageSize'] = pageSize;
    }
    const params = new HttpParams({ fromObject: paramsObj });
    return this.http.get<PatientTransaction[]>(currentUrl, {params});
  }

  getPatientTransactionCount(patientId: number, userIdList: string[], searchText: string): Observable<number> {
    const apiUri = `${environment.baseUrl}api/PatientTransactions/PatientTransactionsCountByPatientId/${patientId}`;
    const paramsObj = {};

    if (userIdList.length > 0) {
      paramsObj['userIdList'] = userIdList;
    }
    if (searchText !== '') {
      paramsObj['searchText'] = searchText;
    }

    const params = new HttpParams({ fromObject: paramsObj });

    return this.http.get<number>(apiUri, { params });
  }

  updatePatientTransaction(patientTransaction: PatientTransaction) {
    return this.http
      .put<PatientTransaction>(
        environment.baseUrl + 'api/PatientTransactions/' + patientTransaction.id,
        patientTransaction
      )
      .pipe(
        map((updatedPatientTransaction: PatientTransaction) => {
          this.patientTransactionChanged.next();
          return updatedPatientTransaction;
        })
      );
  }

  addPatientTransaction(patientTransaction: PatientTransaction) {
    patientTransaction.id = this.defaultId;

    return this.http.post<PatientTransaction>(environment.baseUrl + 'api/PatientTransactions', patientTransaction).pipe(
      map((addedPatientTransaction: PatientTransaction) => {
        this.patientTransactionChanged.next();
        return addedPatientTransaction;
      })
    );
  }

  getPatientTransactionsByTarget(targetId: number, targetTypeId: number) {
    const apiUri = 'api/PatientTransactions/' + targetId + '/' + targetTypeId;

    return this.http.get<PatientTransaction[]>(environment.baseUrl + apiUri).pipe(
      map(patientTransaction => {
        return patientTransaction;
      })
    );
  }

  formatDate(date: Date) {
    return moment(date)
      .format('dddd, MMMM Do YYYY')
      .toString();
  }
}
