export enum PhotoConsentType {
  None = 1,
  Internal = 2,
  InternalExternal = 3
}

export enum PhotoConsentTitle {
  None = 'None',
  Internal = 'Internal',
  InternalExternal = 'External'
}
