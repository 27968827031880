import { Injector } from '@angular/core';
import { FormioCustomComponentInfo, registerCustomFormioComponent } from 'angular-formio-emilyemr';
import { CustomTextfieldWrapperComponent } from './custom-text-field';

const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
  type: 'customtextfield', // custom type. Formio will identify the field with this type.
  selector: 'custom-textfield', // custom selector. Angular Elements will create a custom html tag with this selector
  title: 'Text Field', // Title of the component
  group: 'basic', // Build Group
  icon: 'fa fa-star', // Icon
//  template: 'input', // Optional: define a template for the element. Default: input
//  changeEvent: 'valueChange', // Optional: define the changeEvent when the formio updates the value in the state. Default: 'valueChange',
  editForm: minimalEditForm
//  documentation: '', // Optional: define the documentation of the field
//  weight: 0, // Optional: define the weight in the builder group
//  schema: {}, // Optional: define extra default schema for the field
//  extraValidators: [], // Optional: define extra validators  for the field
//  emptyValue: null, // Optional: the emptyValue of the field
};

export function registerCustomTextFieldComponent(injector: Injector) {
  registerCustomFormioComponent(COMPONENT_OPTIONS, CustomTextfieldWrapperComponent, injector);
}

export function minimalEditForm() {
    return {
      components: [
        { key: 'type', type: 'hidden' },
        {
          weight: 0,
          type: 'textfield',
          input: true,
          key: 'label',
          label: 'Label',
          placeholder: 'Label',
          validate: {
            required: true,
          },
        },
      ],
    };
  }
