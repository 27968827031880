import { ClinicsService } from '@services/clinics.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccessLocationService } from '@services/access-location.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-org-access-locations',
  templateUrl: './org-access-locations.component.html',
  styleUrls: ['./org-access-locations.component.less'],
})
export class OrgAccessLocationsComponent implements OnInit, OnDestroy {
  disableGrid: boolean;
  gridData: any[];
  unsub = new Subject<any>();
  loading = false;
  clinicId: number;
  constructor(
    private accessLocationService: AccessLocationService,
    private clinicsService: ClinicsService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.clinicsService.clinicIdSelected$
      .asObservable()
      .pipe(takeUntil(this.unsub))
      .subscribe((clinicId) => {
        this.clinicId = clinicId;
        this.getAccessLocationData();
      });
    this.getAccessLocationData();
    this.accessLocationService.accessLocationUpdateSource$.pipe(takeUntil(this.unsub)).subscribe((res) => {
      if (res) {
        this.getAccessLocationData();
      }

      this.disableGrid = false;
    });
  }

  private getAccessLocationData() {
    this.loading = true;
    this.accessLocationService.getAccessLocations(this.clinicId).subscribe((res) => {
      this.gridData = res;
      this.loading = false;
    });
  }

  public onAddClick({ sender }) {
    this.disableGrid = true;
    this.accessLocationService.shareAccessLocation(null);
    this.router.navigate([
      '/management/organization/clinics/access-locations',
      { outlets: { 'action-panel': ['edit-access-location', '_'] } },
    ]);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.disableGrid = true;
    this.accessLocationService.shareAccessLocation(dataItem);
    this.router.navigate([
      '/management/organization/clinics/access-locations',
      { outlets: { 'action-panel': ['edit-access-location', dataItem.id] } },
    ]);
  }

  public removeHandler({ dataItem }) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.accessLocationService.deleteAccessLocations(dataItem).subscribe(() => {
          this.getAccessLocationData();
        });
      }
    });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
