import { PhotoDrawing } from '@models/photo/photo-drawing';

export class ChartNote {
  public patientNoteId: number;
  public date: Date;
  public entryText: string;
  public draftText: string;
  public signedByUser: string;
  public signedTime: Date;
  public isLocked: boolean;
  public photoDrawings: PhotoDrawing[];

  public constructor(init?: Partial<ChartNote>) {
    Object.assign(this, init);
  }
}
