import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ObservationGroupService } from '../../../services/observation-group.service';
import { ObservationGroup } from '@models/observation/observation-group';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-group-pricing-observations',
  templateUrl: './group-pricing-observations.component.html',
  styleUrls: ['./group-pricing-observations.component.less'],
})
export class GroupPricingObservationsDialogComponent implements OnInit {
  public dialog: MatDialog;
  result: { groupPrice: number; groupName: string } = { groupPrice: 0.0, groupName: '' };
  observationGroups: ObservationGroup[];
  customSelected = false;

  constructor(
    public dialogRef: MatDialogRef<GroupPricingObservationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private observationGroupService: ObservationGroupService
  ) {}

  ngOnInit(): void {
    this.observationGroupService.getObservationGroups().subscribe((obsg) => {
      this.observationGroups = obsg;
    });
  }

  onApplyGroup(obsg: ObservationGroup) {
    if (obsg != null) {
      this.result.groupPrice = obsg.price;
      this.result.groupName = obsg.name;
      this.dialogRef.close(this.result);
    }
  }

  switchTab(event: MatTabChangeEvent) {
    if (event.tab.textLabel == 'Custom') {
      this.customSelected = true;
    } else {
      this.customSelected = false;
    }
  }
}
