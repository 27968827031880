import { Injectable } from '@angular/core';

@Injectable()
export class GeographyService {
  countryMap = {
    CA: 'Canada',
    US: 'United States',
    AU: 'Australia',
    '--': '---------------',
    AF: 'Afghanistan',
    AX: 'Åland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'AndorrA',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    BN: 'Brunei Darussalam',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CD: 'Congo,The Democratic Republic of the',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: 'Cote D"Ivoire',
    HR: 'Croatia',
    CU: 'Cuba',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FK: 'Falkland Islands (Malvinas)',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard Island and Mcdonald Islands',
    VA: 'Holy See (Vatican City State)',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran,Islamic Republic Of',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KP: 'Korea,Democratic People"S Republic of',
    KR: 'Korea,Republic of',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Lao People"S Democratic Republic',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libyan Arab Jamahiriya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MK: 'Macedonia,The Former Yugoslav Republic of',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia,Federated States of',
    MD: 'Moldova,Republic of',
    MC: 'Monaco',
    MN: 'Mongolia',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    AN: 'Netherlands Antilles',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinian Territory,Occupied',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Reunion',
    RO: 'Romania',
    RU: 'Russian Federation',
    RW: 'RWANDA',
    SH: 'Saint Helena',
    KN: 'Saint Kitts and Nevis',
    LC: 'Saint Lucia',
    PM: 'Saint Pierre and Miquelon',
    VC: 'Saint Vincent and the Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Sao Tome and Principe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    CS: 'Serbia and Montenegro',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia and the South Sandwich Islands',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard and Jan Mayen',
    SZ: 'Swaziland',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syrian Arab Republic',
    TW: 'Taiwan,Province of China',
    TJ: 'Tajikistan',
    TZ: 'Tanzania,United Republic of',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos Islands',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    UM: 'United States Minor Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Viet Nam',
    VG: 'Virgin Islands,British',
    VI: 'Virgin Islands,U.S.',
    WF: 'Wallis and Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  };

  provinceMap = {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NL: 'Newfoundland and Labrador',
    NS: 'Nova Scotia',
    NT: 'Northwest Territories',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Québec',
    SK: 'Saskatchewan',
    YT: 'Yukon',
  };

  stateMap = {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FM: 'Federated States Of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  };

  /*   timezoneMap = {
    AWST: 'Australian Western Standard Time',
    ACWST: 'Australian Central Western Standard Time',
    ACST: 'Australian Central Standard Time',
    AEST: 'Australian Eastern Standard Time',
    LHST: 'Lord Howe Standard Time',
    PT: 'Pacific Standard Time',
    MT: 'Mountain Standard Time',
    CS: 'Central Standard Time',
    ES: 'Eastern Standard Time',
    AS: 'Atlantic Standard Time',
  }; */
  timezoneMap = [
    { id: 'Pacific Standard Time', value: '(UTC-08:00; PST) Pacific Standard Time' },
    { id: 'Mountain Standard Time', value: '(UTC-07:00; MST) Mountain Standard Time ' },
    { id: 'Central Standard Time', value: '(UTC-06:00; CST) Central Standard Time' },
    { id: 'Eastern Standard Time', value: '(UTC-05:00; EST) Eastern Standard Time' },
    { id: 'Atlantic Standard Time', value: '(UTC-04:00; AST) Atlantic Standard Time' },
    { id: 'Australian Western Standard Time', value: '(UTC+08:00; AWST) Australian Western Standard Time' },
    {
      id: 'Australian Central Western Standard Time',
      value: '(UTC+08:45; ACWST) Australian Central Western Standard Time',
    },
    { id: 'Australian Central Standard Time', value: '(UTC+09:30; ACST) Australian Central Standard Time' },
    { id: 'Australian Eastern Standard Time', value: '(UTC+10:00; AEST) Australian Eastern Standard Time' },
    { id: 'Lord Howe Standard Time', value: '(UTC+10:30; LHST) Lord Howe Standard Time' },
  ];

  countriesByAbbreviation() {
    return this.countryMap;
  }

  countriesByName() {
    const results = {};
    Object.keys(this.countryMap).forEach((k) => {
      results[this.countryMap[k]] = k;
    });
    return results;
  }

  timezonesByName() {
    const results = {};
    Object.keys(this.timezoneMap).forEach((k) => {
      results[this.timezoneMap[k]] = k;
    });
    return results;
  }

  timeZonesMap() {
    return this.timezoneMap;
  }

  updateProvinceStateList(country: string) {
    const provincesAndStates = [];
    if (country === 'Canada') {
      for (const key in this.provinceByName()) {
        provincesAndStates.push(key);
      }
    } else if (country === 'United States') {
      for (const key in this.stateByName()) {
        provincesAndStates.push(key);
      }
    } else {
      for (const key in this.provinceByName()) {
        provincesAndStates.push(key);
      }
      for (const key in this.stateByName()) {
        provincesAndStates.push(key);
      }
    }
    return provincesAndStates;
  }

  provinceByAbbreviation() {
    return this.provinceMap;
  }

  provinceByName() {
    const results = {};
    Object.keys(this.provinceMap).forEach((k) => {
      results[this.provinceMap[k]] = k;
    });
    return results;
  }

  getProvinceCode(province: string): string {
    let provinceCode = Object.keys(this.provinceMap).find((key) => this.provinceMap[key] === province);
    return provinceCode ? provinceCode : province;
  }

  stateByAbbreviation() {
    return this.stateMap;
  }

  stateByName() {
    const results = {};
    Object.keys(this.stateMap).forEach((k) => {
      results[this.stateMap[k]] = k;
    });
    return results;
  }
}
