import { NgModule } from '@angular/core';
import { NudgesComponent } from './nudges.component';
import { SharedModule } from '@app/shared/shared.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { MultiCheckFilterComponent } from './multi-check.component';

@NgModule({
  declarations: [
    NudgesComponent
  ],
  imports: [
    SharedModule,
    GridModule,
  ],
  exports: [
    NudgesComponent,
  ]
})
export class NudgesModule { }
