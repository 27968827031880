import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceProvider } from '@models/service-provider';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ServiceProviderService } from '@services/service-provider.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-org-online-providers-settings',
  templateUrl: './org-online-providers-settings.component.html',
  styleUrls: ['./org-online-providers-settings.component.less'],
})
export class OrgOnlineProvidersSettingsComponent implements OnInit {
  loading = false;
  unsub: Subject<void> = new Subject<void>();
  @Output() disableGridEvent = new EventEmitter<boolean>();
  disableGrid = false;
  gridView: GridDataResult;
  providers: ServiceProvider[] = [];

  constructor(private router: Router, private serviceProviderService: ServiceProviderService) {}

  ngOnInit(): void {
    this.loading = true;
    this.serviceProviderService.refreshRequired$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.disableGrid = false;
      this.sendDisableGrid(this.disableGrid);
      if (this.serviceProviderService.refreshRequired$) {
        this.refreshData();
      }
    });
    this.refreshData();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  public editHandler({ dataItem }) {
    this.disableGrid = true;
    this.sendDisableGrid(this.disableGrid);
    this.router.navigate([
      '/management/organization/clinics/online-booking',
      { outlets: { 'action-panel': ['edit-online-booking-provider', dataItem.id] } },
    ]);
  }

  refreshData() {
    this.loading = true;
    this.serviceProviderService.getServiceProvidersOnlineBooking().subscribe((result) => {
      this.providers = result;
      this.loadItems();
      this.loading = false;
    });
  }

  loadItems() {
    this.gridView = {
      data: this.providers,
      total: this.providers.length,
    };
  }

  sendDisableGrid(value: boolean) {
    this.disableGridEvent.emit(value);
  }

  updateProviderAvailable(provider: ServiceProvider, event) {
    this.serviceProviderService
      .updateServiceProviderOnlineAvailability(provider, event.target.checked)
      .subscribe(() => {});
  }
}
