<kendo-grid
  [data]="userCommissions"
  (edit)="editHandler($event)"
  (remove)="removeHandler($event)"
  (add)="addHandler($event)"
>
  <ng-template kendoGridToolbarTemplate>
    <button kendoGridAddCommand class="btn em-btn em-btn-green">Add</button>
  </ng-template>
  <kendo-grid-column field="serviceTemplateId" title="Service">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getServiceName(dataItem) }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="commission" [width]="105" title="Commission">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ formatCommission(dataItem) }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-command-column title="Action" [width]="105">
    <ng-template kendoGridCellTemplate>
      <button kendoGridEditCommand class="btn kendo-btn" [primary]="true">
        <i class="fas fa-edit"></i>
      </button>
      <button kendoGridRemoveCommand class="btn kendo-btn">
        <i class="fas fa-trash"></i>
      </button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
