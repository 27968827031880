<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>

<div class="grid-container" [ngStyle]="{ height: 'calc(100dvh - 75px)' }">
  <kendo-grid
    [data]="gridView"
    [pageSize]="gridState.take"
    [skip]="gridState.skip"
    [sort]="gridState.sort"
    [sortable]="true"
    [selectable]="true"
    [style.height.%]="100"
    (edit)="editHandler($event)"
  >
    <kendo-grid-column field="clinicAidId" title="Clinic Aid Id"></kendo-grid-column>

    <kendo-grid-command-column title="Actions" min-width="125px" [width]="125">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <button kendoGridEditCommand class="btn kendo-btn"><i class="fas fa-edit"></i></button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>

<app-actionpanel></app-actionpanel>
