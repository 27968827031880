<div class="p-3 navy-dark-background white-font">
  <div class="d-flex py-3">
    <h5 class="mr-auto my-0 align-self-center text-uppercase">Patient Messaging</h5>
    <button *appAuthGuard="sendPolicy" class="btn em-btn em-btn em-btn-green" (click)="addConversation()">
      <i class="fal fa-plus-circle"></i> Add
    </button>
    <button
      *appAuthGuard="removePolicy"
      class="btn em-btn em-btn-green ml-2"
      [disabled]="!selectedConversation"
      (click)="removeConversation(selectedConversation)"
    >
      <i class="fal fa-archive"></i> Archive
    </button>
  </div>
  <div class="input-group pb-2">
    <div class="input-group-prepend">
      <div class="input-group-text border-radius grey-three-background border-0">
        <i class="fa fa-search"></i>
      </div>
    </div>
    <input
      [formControl]="searchFormControl"
      type="text"
      name="docSearch"
      class="form-control border-0 grey-three-background"
      placeholder="Search"
    />
  </div>
</div>
<div class="flex-grow-1 navy-background position-relative">
  <ngx-loading [show]="loading"></ngx-loading>
  <h5
    *ngIf="!filteredConversations?.length"
    class="d-flex w-100 justify-content-center align-items-center white-font mt-4"
  >
    No Conversations Found
  </h5>
  <mat-selection-list
    #conversationSelectionList
    class="h-100"
    [multiple]="false"
    (selectionChange)="onSelectionChange($event)"
  >
    <ng-scrollbar #scrollbar class="h-100">
      <ng-container *ngFor="let conversation of filteredConversations">
        <mat-list-option
          *ngIf="conversationsPatient.get(conversation.sid) | async as patient"
          [id]="conversation.uniqueName"
          [value]="conversation"
          [selected]="selectedConversation && selectedConversation.sid === conversation.sid"
        >
          <span *ngIf="conversationsUnreadCount.get(conversation.sid) | async as unreadCount" class="unread-badge">{{
            unreadCount
          }}</span>
          <img
            class="navy-dark-background avatar"
            mat-list-avatar
            [src]="patient?.avatar ?? '../../../../assets/Avatars/avatar-placeholder.png'"
          />
          <h3 mat-line class="white-font">
            {{ conversation.friendlyName | titlecase }}
          </h3>
          <p mat-line [ngClass]="conversation.sid === selectedConversation?.sid ? 'white-font' : 'grey-five-font'">
            <span>
              {{ patient.birthDate | date : 'yyyy-MM-dd' }} ({{ getAge(patient.birthDate) }})
              {{ patient.gender ? ', ' + patient.gender : '' }}
              {{ patient.address?.city ? ', ' + (patient.address?.city | titlecase) : '' }}
            </span>
          </p>
          <p mat-line [ngClass]="conversation.sid === selectedConversation?.sid ? 'white-font' : 'grey-five-font'">
            <span>{{ conversationsLastMessage.get(conversation.sid) | async }}</span>
          </p>
          <!-- <button
            mat-list-icon
            title="Delete Conversation"
            (click)="deleteConversation(conversation)"
            class="btn em-btn em-btn-green btn-sm my-auto action-button ml-2"
          >
            <i class="fas fa-trash"></i>
          </button> -->
          <button
            mat-list-icon
            title="Patient Profile"
            (click)="goToPatientProfile(patient)"
            class="btn em-btn em-btn-green btn-sm my-auto action-button"
          >
            <i class="fal fa-user"></i>
          </button>
        </mat-list-option> </ng-container
    ></ng-scrollbar>
  </mat-selection-list>
</div>
