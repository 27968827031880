<ngx-loading [show]="loading"></ngx-loading>

<div class="modal-header">
  <h4 class="modal-title no-transform">Import Unlayer Email Template</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form *ngIf="importJSONForm" [formGroup]="importJSONForm" class="photo-form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <!-- Name -->
    <div class="row py-1">
      <div class="col">
        <input type="text" class="form-control" placeholder="Add template name" formControlName="name">
      </div>
    </div>
    <!-- Template Type -->
    <div class="row py-1">
      <div class="col">
        <label for="emailTemplate">Template Type</label>
        <select class="form-control" formControlName="emailTemplateType" title="Template Type">
          <option [value]="null" [selected]="true">--Select template type--</option>
          <option [value]="emailTemplateTypes.AppointmentReminder"> Appointment Reminder </option>
          <option [value]="emailTemplateTypes.RetentionCampaign"> Retention Campaign </option>
          <option [value]="emailTemplateTypes.Reviews"> Reviews </option>
        </select>
      </div>
    </div>
    <!-- Description -->
    <div class="row py-1">
      <div class="col">
        <textarea class="form-control" rows="3" placeholder="Template description" formControlName="description"></textarea>
      </div>
    </div>
    <!-- Definition -->
    <div class="row py-1">
      <div class="col">
        <textarea class="form-control" rows="3" placeholder="Unlayer JSON Import" formControlName="definition"></textarea>
      </div>
    </div>
    <div class="row py-3">
      <div class="col text-center">
        <button type="submit" class="btn em-btn em-btn-green" [disabled]="importJSONForm.invalid">
          Import
        </button>
      </div>
    </div>
  </div>
</form>