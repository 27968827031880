import { Component, OnDestroy, OnInit } from '@angular/core';
import { MasterOverlayService } from '@services/actionpanel.service';
import { BreakpointService } from '@services/breakpoint.service';
import { NavStateService } from '@services/navstate.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-emily',
  templateUrl: './emily.component.html',
  styleUrls: ['./emily.component.less'],
})
export class EmilyComponent implements OnInit, OnDestroy {
  unsub: Subject<void> = new Subject<void>();
  sideNavExpanded: boolean;
  masterOverlayEnabled: boolean;
  mobileView: boolean;

  constructor(
    private navStateService: NavStateService,
    private masterOverlayService: MasterOverlayService,
    private breakpointService: BreakpointService
  ) {}

  ngOnInit() {
    this.navStateService.sideNavExpanded.pipe(takeUntil(this.unsub)).subscribe((exp) => {
      this.sideNavExpanded = exp;
    });

    this.masterOverlayService.masterOverlayEnabled.pipe(takeUntil(this.unsub)).subscribe((ovr) => {
      this.masterOverlayEnabled = ovr;
    });

    this.breakpointService.mobileBreakpoint$.pipe(takeUntil(this.unsub)).subscribe((mobileView) => {
      this.mobileView = mobileView;
    });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
