import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UnlayerEmailEditorComponent } from '@app/shared/components/unlayer-email-editor/unlayer-email-editor.component';
import { ClinicEmailTemplate, compareTemplateToSettingType } from '@models/clinic-email-template';
import { EmailNotificationsSettings } from '@models/communications/email-notifications-settings.model';
import { CommunicationSettingType } from '@models/communications/notifications-settings-base.model';
import { editorOptions } from '@models/email-editor-options';
import { ClinicEmailTemplateService } from '@services/clinic-email-template.service';
import { CommunicationsService } from '@services/communications.service';
import { forkJoin, Subject } from 'rxjs';

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.less'],
})
export class EmailSettingsComponent implements OnInit {
  @ViewChild(UnlayerEmailEditorComponent) emailEditor: UnlayerEmailEditorComponent;

  emailTemplates: ClinicEmailTemplate[] = [];
  allowedEmailTemplates: ClinicEmailTemplate[] = [];
  emailSettings: EmailNotificationsSettings[] = [];
  selectedSetting: EmailNotificationsSettings;
  showSaveMessage: boolean = false;
  loading = false;
  editorOptions = editorOptions;
  unsub: Subject<void> = new Subject<void>();

  CommunicationSettingType = CommunicationSettingType;

  settingsForm = new FormGroup({
    senderName: new FormControl('', Validators.required),
    senderEmail: new FormControl('', Validators.required),
    emailJson: new FormControl('', Validators.required),
    emailHtml: new FormControl('', Validators.required),
    emailSubjectLine: new FormControl('', Validators.required),
    createdFromEmailTemplateId: new FormControl(0, Validators.required),
  });

  constructor(
    private communicationService: CommunicationsService,
    private clinicEmailTemplateService: ClinicEmailTemplateService
  ) {}

  ngOnInit() {
    this.settingsForm.get('createdFromEmailTemplateId').valueChanges.subscribe((templateId) => {
      if (templateId) {
        const template = this.emailTemplates.find((t) => t.id === templateId);
        if (template) {
          this.settingsForm.get('emailJson').setValue(template.definition);
          this.settingsForm.get('emailHtml').setValue(template.html);
          this.emailEditor.editor.loadDesign(JSON.parse(template.definition));
          this.emailEditor.editor.showPreview('desktop');
        }
      }
    });

    this.loadSettings();
  }

  loadSettings() {
    this.loading = true;
    this.selectedSetting = null;
    forkJoin([
      this.clinicEmailTemplateService.getClinicEmailTemplates(),
      this.communicationService.getEmailNotificationsSettings(),
    ]).subscribe(
      ([templates, settings]) => {
        this.emailTemplates = templates;
        this.emailSettings = settings;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  onSave() {
    this.loading = true;
    const emailSettings: EmailNotificationsSettings = {
      templateReferenceId: 0,
      senderName: this.settingsForm.get('senderName').value,
      senderEmail: this.settingsForm.get('senderEmail').value,
      emailJson: this.settingsForm.get('emailJson').value,
      emailHtml: this.settingsForm.get('emailHtml').value,
      emailSubjectLine: this.settingsForm.get('emailSubjectLine').value,
      createdFromEmailTemplateId: this.settingsForm.get('createdFromEmailTemplateId').value,
      enabled: this.selectedSetting.enabled,
      communicationSettingType: this.selectedSetting.communicationSettingType,
      workerCronSchedule: this.selectedSetting.workerCronSchedule,
      workerStartTime: this.selectedSetting.workerStartTime,
      workerEndTime: this.selectedSetting.workerEndTime,
      firstNotificationBefore: this.selectedSetting.firstNotificationBefore,
      repeatNotificationEvery: this.selectedSetting.repeatNotificationEvery,
    };

    this.communicationService.updateEmailNotificationsSettings(emailSettings).subscribe(
      () => {
        this.loading = false;
        this.showSaveMessage = true;
        setTimeout(() => {
          this.showSaveMessage = false;
        }, 4000);
        this.loadSettings();
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  onCancel() {
    this.selectedSetting = null;
  }

  selectSetting(setting: EmailNotificationsSettings) {
    this.selectedSetting = setting;
    this.allowedEmailTemplates = this.emailTemplates.filter((t) =>
      compareTemplateToSettingType(t.emailTemplateType, setting.communicationSettingType)
    );
    this.emailEditor.editor.loadDesign(JSON.parse(setting.emailJson));
    this.emailEditor.editor.showPreview('desktop');
    this.setSettingForm(setting);
  }

  private setSettingForm(setting: EmailNotificationsSettings) {
    this.settingsForm.controls['senderName'].setValue(setting.senderName);
    this.settingsForm.controls['senderEmail'].setValue(setting.senderEmail);
    this.settingsForm.controls['emailJson'].setValue(setting.emailJson);
    this.settingsForm.controls['emailHtml'].setValue(setting.emailHtml);
    this.settingsForm.controls['emailSubjectLine'].setValue(setting.emailSubjectLine);
    this.settingsForm.controls['createdFromEmailTemplateId'].setValue(setting.createdFromEmailTemplateId, {
      emitEvent: false,
    });
  }

  getSettingName(setting: EmailNotificationsSettings) {
    return CommunicationSettingType[setting.communicationSettingType];
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
