import * as moment from 'moment';
import { Component, OnInit, ViewChild, OnDestroy, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { takeUntil, mergeMap, take } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { TypeaheadMatch, TypeaheadDirective } from 'ngx-bootstrap/typeahead';

import { PrescriptionFavouriteService } from '@services/prescriptions/prescription-favourite.service';
import { PrescriptionFavourite } from '@models/prescriptions/prescription-favourite';
import { UsersService } from '@services/users.service';
import { PrescriptionService } from '@services/prescriptions/prescription.service';
import { Prescription } from '@models/prescriptions/prescription';
import { PatientService } from '@services/patient.service';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';
import { PrescriptionDetailsComponent } from '../prescriptions-view/prescription-details/prescription-details.component';
import { Patient } from '@models/patient';
import { Clinic } from '@models/clinic';
import { ClinicsService } from '@services/clinics.service';
import { BlobService } from '@services/blob.service';

@Component({
  selector: 'app-add-prescription',
  templateUrl: './add-prescription.component.html',
  styleUrls: ['./add-prescription.component.less'],
})
export class AddPrescriptionComponent implements OnInit, OnDestroy {
  favouritePrescriptionList: PrescriptionFavourite[];
  patientId: number;
  userId: string;
  input_focussed = -1;
  unsub: Subject<void> = new Subject<void>();
  asyncSelected: string;
  typeaheadLoading: boolean;
  noResult = false;
  dataSource: Observable<any>;
  prescriptionItems: Prescription[];
  favouritePresciption: PrescriptionFavourite;
  clinic: Clinic;
  readOnlySas: string;
  loading = false;
  errorMessage = '';
  @ViewChild('typeaheadDirective', { static: true }) _typeahead: TypeaheadDirective;
  @ViewChild('exportContainer', { read: ViewContainerRef }) exportContainer: ViewContainerRef;
  public get container() {
    return this._typeahead._container;
  }
  get patient(): Patient {
    return this.patientService.patientPanelPatient;
  }

  constructor(
    private prescriptionFavouriteService: PrescriptionFavouriteService,
    private prescriptionService: PrescriptionService,
    private patientService: PatientService,
    private clinicsService: ClinicsService,
    private blobService: BlobService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UsersService,
    private dialog: MatDialog,
    private resolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    this.prescriptionItems = [];
    this.clinicsService.getClinics().subscribe((res) => {
      this.clinic = res[0];
      this.readOnlySas = this.blobService.getReadOnlySAS();
      if (this.clinic.logoUrl) {
        this.clinic.logoUrl = this.clinic.logoUrl + this.readOnlySas;
      }
    });
    this.prescriptionService.prescriptionSource$.pipe(takeUntil(this.unsub)).subscribe((data) => {
      if (data) {
        if (data.isLocked) {
          delete data.isLocked;
        }
        if (data.signature) {
          data.signature = null;
        }
        if (data.signatureId) {
          //if signatureId is set, then this is a renewal, so we need to remove the id
          data.signatureId = null;
        }
        this.addToPrescription(data);
      }
    });
    this.userService.loggedInUserUpdated$.pipe(takeUntil(this.unsub)).subscribe((u) => {
      this.userId = this.userService.loggedInUser.id;
      this.getFavouritePrescriptions(this.userId);
    });

    this.patientId = this.route.snapshot.params.patId.split('_')[0];
    this.userId = this.userService.loggedInUser.id;
    if (this.userId) {
      this.getFavouritePrescriptions(this.userId);
    }

    if (this.prescriptionItems.length == 0) this.addNewPrescriptionForm(); //add blank item for "new" but not for renewal
    this.dataSource = new Observable((observer: any) => {
      // Runs on every search
      observer.next(this.asyncSelected);
    }).pipe(mergeMap((token: string) => this.prescriptionFavouriteService.getDrug(token, this.userId)));
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  public typeaheadOnSelect(e: TypeaheadMatch): void {
    this.addToPrescription(e.item);
  }

  public typeaheadNoResults(event: boolean): void {
    this.noResult = event;
  }

  public addFavouriteToPrescription(item: Prescription) {
    let itemClone = Object.assign({}, item); //deep clone from favourites to allow multiple of same fav in one script
    itemClone.id = 0;
    itemClone.index = Date.now() + '_';
    itemClone.signatureId = null;
    itemClone.patientId = this.patient.patientId;
    delete itemClone.createdDate;
    this.addToPrescription(itemClone);
  }

  public addToPrescription(item: Prescription) {
    this.stripInvalidDrugs();
    this.prescriptionItems.unshift(item);
  }

  private getFavouritePrescriptions(id: string) {
    this.prescriptionFavouriteService.getPrescriptionFavouritesByUserId(id).subscribe((res) => {
      this.favouritePrescriptionList = res;
    });
  }

  public deleteFavouritePrescription(item: PrescriptionFavourite) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'delete') {
          this.prescriptionFavouriteService.deletePrescriptionFavourite(item.id).subscribe(() => {
            this.getFavouritePrescriptions(this.userId);
          });
        }
      });
  }

  public removePrescription(i: number) {
    this.prescriptionItems.splice(i, 1);
  }

  public selectFavourite(item: Prescription) {
    this.favouritePresciption = {} as PrescriptionFavourite;
    this.favouritePresciption.prescription = item;
  }

  public addFavouritePrescription() {
    const data = Object.assign({}, this.favouritePresciption);
    data.user = this.userService.loggedInUser;
    this.prescriptionFavouriteService.addPrescriptionFavourite(data).subscribe((favRx: PrescriptionFavourite) => {
      this.getFavouritePrescriptions(this.userId);
    });
  }

  public addNewPrescriptionForm() {
    this.prescriptionItems.unshift(
      new Prescription({
        drugName: this.asyncSelected,
        index: Date.now() + '_',
        patientId: this.patientId,
      })
    );

    this.asyncSelected = '';
    this.noResult = false;
  }

  public goToOverview() {
    this.router.navigate(['overview'], { relativeTo: this.route.parent });
  }

  public navigateToPrescriptionView() {
    this.router.navigate(['prescription/view'], { relativeTo: this.route.parent });
  }

  async printPrescription() {
    this.loading = true;
    this.errorMessage = null;
    await this.prescriptionService
      .printPrescription(this.prescriptionItems, this.exportContainer, this.patient)
      .catch((reason) => (this.errorMessage = reason));
    this.loading = false;
    if (!this.errorMessage) {
      this.prescriptionItems.map((i) => {
        i.isLocked = true;
      });
      let rx = this.prescriptionItems;
      this.router.navigate(['prescription/view'], {
        relativeTo: this.route.parent,
        queryParams: { prescriptionId: rx[0].id },
      });
    }
  }

  async faxPrescription() {
    this.loading = true;
    this.errorMessage = null;
    await this.prescriptionService
      .faxPrescription(this.prescriptionItems, this.exportContainer, this.patient)
      .catch((reason) => (this.errorMessage = reason));
    this.loading = false;
    if (!this.errorMessage) {
      this.prescriptionItems.forEach((item) => {
        item.isLocked = true;
      });
      let rx = this.prescriptionItems;
      this.router.navigate(['prescription/view'], {
        relativeTo: this.route.parent,
        queryParams: { prescriptionId: rx[0].id },
      });
    }
  }

  //see #213 will allow for form to be submitted if ANY of the drugs listed are complete (with name) and strip the blank ones (if any) out
  anyListedDrugsValid() {
    let anyValidDrugNames = false;
    this.prescriptionItems.some((item) => {
      anyValidDrugNames = anyValidDrugNames || Boolean(item && item.drugName);
      return anyValidDrugNames;
    });

    return anyValidDrugNames;
  }

  private stripInvalidDrugs() {
    const data = this.prescriptionItems.filter(
      (drug) => drug && drug.drugName
    );
    this.prescriptionItems = data;
  }

  public getTime() {
    return Date.now();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
