<ngx-loading [show]="loading"></ngx-loading>

<div class="modal-header border-0 p-0">
  <button type="button" class="btn white-font p-0 ml-auto mr-2" aria-label="Close" (click)="dismissModal()">
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="container p-0 pb-3">
  <div class="search-container navy-background justify-content-center section-container">
    <h6 class="modal-title text-uppercase white-font text-center mb-4">Select Products</h6>
    <div class="input-group pb-2">
      <div class="input-group-prepend">
        <div class="input-group-text border-radius grey-three-background border-0">
          <i class="fa fa-search"></i>
        </div>
      </div>
      <input
        [formControl]="searchFormControl"
        type="text"
        name="docSearch"
        class="form-control border-0 grey-three-background"
        placeholder="Search"
      />
    </div>
  </div>

  <div class="selection-container" [ngClass]="{ 'bottom-margin': selectedProducts.size > 0 }">
    <div *ngIf="selectedProducts.size > 0" class="selected-product-header">Selected Products</div>
    <div *ngFor="let selectedProduct of selectedProducts" class="selected-product">
      <div class="mr-auto">{{ selectedProduct.displayName }}</div>
      <div class="">${{ selectedProduct.retailPrice }}</div>
      <span class="unselect" (click)="unselectProduct(selectedProduct)">
        <i class="fal fa-times"></i>
      </span>
    </div>
  </div>

  <div class="list-container">
    <ng-scrollbar *ngIf="productBrandGroups?.size > 0; else noProducts" class="h-100">
      <mat-accordion multi>
        <ng-container *ngFor="let productBrandGroup of productBrandGroups | keyvalue">
          <mat-expansion-panel *ngIf="productBrandGroup.value.length > 0" [expanded]="shouldExpand" class="my-1">
            <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
              <mat-panel-title>
                {{ productBrandGroup.key }}
              </mat-panel-title>
              <mat-icon>arrow_drop_up</mat-icon>
            </mat-expansion-panel-header>
            <div class="product-item navy-font font-weight-bold">
              <div class="name-col">Name</div>
              <div class="qty-col">Qty. In Stock</div>
              <div class="price-col">Price</div>
              <div class="checkbox-col">
                <mat-checkbox class="invisible"> </mat-checkbox>
              </div>
            </div>
            <div *ngFor="let product of productBrandGroup.value" class="product-item navy-font">
              <div class="name-col">{{ product.displayName }}</div>
              <div class="qty-col">{{ product.quantityInStock }}</div>
              <div class="price-col">${{ product.retailPrice }}</div>
              <div class="checkbox-col">
                <mat-checkbox [checked]="productIsSelected(product)" (change)="toggleProductSelection(product)">
                </mat-checkbox>
              </div>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </ng-scrollbar>
    <ng-template #noProducts
      ><div class="text-center"><h6>No products found.</h6></div></ng-template
    >
  </div>

  <div class="save-changes-container my-3 text-center">
    <button class="btn em-btn em-btn-green mr-2" [disabled]="selectedProducts.size === 0" (click)="closeModal()">
      Add
    </button>
    <button class="btn em-btn em-btn-green" (click)="dismissModal()">Cancel</button>
  </div>
</div>
