import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoicesService } from '@services/invoices.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IntlService } from '@progress/kendo-angular-intl';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';
import * as moment from 'moment';

@Component({
  selector: 'app-update-invoice-date',
  templateUrl: './update-invoice-date.component.html',
  styleUrls: ['./update-invoice-date.component.scss']
})
export class UpdateInvoiceDateComponent implements OnInit, OnDestroy {
  @Input() invoiceDate: any;
  @Input() invoiceId: number;
  @Output() newDate: Date;
  @Output() passResult: EventEmitter<any> = new EventEmitter();
  @ViewChild('datepicker') datepicker: DatePickerComponent;

  private unsub: Subject<any> = new Subject<any>();

  loading = false;
  changesMade = false;
  originalDate: Date;
  constructor(
    public activeModal: NgbActiveModal,
    private invoicesService: InvoicesService,
    private intl: IntlService) { }

  ngOnInit() {
    this.invoiceDate = new Date(this.intl.parseDate(this.invoiceDate)); // This is to satisfy the binding of the date to the DateTimePicker control
    this.originalDate = new Date(this.invoiceDate);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  onChange(value: Date) {
    if (this.originalDate.getDate() !== value.getDate()) {
      this.changesMade = true;
      this.datepicker.blur();
    } else {
      this.changesMade = false;
    }
  }

  saveChanges(newDate: Date) {
    this.invoicesService.updateInvoiceDate(this.invoiceId, moment.utc(newDate).toDate())  // Construct date in UTC format
    .pipe(takeUntil(this.unsub))
    .subscribe(() => {
      this.activeModal.dismiss('Date updated');
      this.passResult.emit(newDate);
    });
  }
}
