<div class="modal-header border-0 p-0">
  <button
    type="button"
    class="btn white-font p-0 ml-auto mr-2"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <i class="fal fa-times"></i>
  </button>
</div>
<div>
  <div class="navy-background justify-content-center p-3">
    <h6 class="modal-title text-uppercase white-font text-center">Select Clinic Photo</h6>
  </div>
  <app-generic-photo-list
    [photos]="photos"
    [seriesMode]="false"
    [selectedPhoto]="filter.selectedPhoto"
    [filter]="filter"
    (selectedPhotoChange)="filter?.setSelectedPhoto($event, photos)"
  >
  </app-generic-photo-list>
  <div class="my-3 text-center">
    <button class="btn em-btn em-btn-green" [disabled]="!filter.selectedPhoto" (click)="activeModal.close(filter.selectedPhoto)">Select</button>
    <button class="btn em-btn em-btn-green ml-2" (click)="activeModal.dismiss()">Cancel</button>
  </div>
</div>
