<ngx-loading [show]="loading"></ngx-loading>

<div class="modal-header border-0 p-0">
  <button
    type="button"
    class="btn em-btn p-0 ml-auto mr-2"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="container flex-1 minh-0 d-flex flex-column p-0">
  <div class="navy-background justify-content-center items-container">
    <h6 class="modal-title text-uppercase white-font text-center mb-2">Select {{ getTypeString() }}</h6>
    <div class="row py-2">
      <div class="col text-center align-items-center">
        <label class="mr-1 bold bigger white-font d-block" [for]="discountValue">Discount Percentage</label>
        <kendo-numerictextbox
          #discountTextbox
          [(value)]="discountValue"
          [decimals]="1"
          [min]="0"
          [max]="100"
          [autoCorrect]="true"
          [format]="'# \\%'"
          [style.width.px]="150"
          class=""
        ></kendo-numerictextbox>
        <div class="font-weight-bold center m-2 white-font">
          The above discount will be applied to the selected {{ getTypeString() }} below
        </div>
      </div>
    </div>
  </div>

  <div class="flex-1 minh-0">
    <ng-scrollbar class="h-100">
      <div class="items-container">
        <div class="px-3 pb-1">
          <h6 class="mt-0 mb-2 navy-font text-center capitalize">{{ getTypeString() }}</h6>
          <input
            *ngIf="isServicesType()"
            type="text"
            autofocus
            class="form-control border-0 grey-three-background mt-3"
            [(ngModel)]="enteredServiceName"
            placeholder="Filter..."
          />

          <ng-container *ngIf="isServicesType()">
            <div *ngIf="selectedMembershipServices.length > 0" class="d-flex mt-2 flex-wrap position-relative">
              <div *ngFor="let membershipService of selectedMembershipServices" class="selected-service">
                {{ membershipService.clinicServiceTemplate.serviceName }}
                <span class="unselect" (click)="unselectServiceTemplate(membershipService)">
                  <i class="fal fa-times"></i>
                </span>
              </div>
              <div (click)="removeAll()" style="font-weight: bold; font-size: 14px" class="pointer underline">
                Clear all
              </div>
            </div>

            <div *ngIf="serviceTemplates?.length > 0" class="mt-3 grey-four-border-top flex-grow-1">
              <div
                style="font-weight: bold"
                (click)="selectAll()"
                class="d-flex service-item align-items-center navy-font grey-four-border-bottom py-2 bigger"
              >
                <i class="far fa-square pr-3"></i> Select all
              </div>
              <div
                *ngFor="let serviceTemplate of serviceTemplates | filterByProperty : 'serviceName' : enteredServiceName"
                class="d-flex service-item align-items-center navy-font grey-four-border-bottom py-2 bigger"
                (click)="selectServiceTemplate(serviceTemplate)"
              >
                <i class="far fa-square pr-3"> </i>
                {{ serviceTemplate?.serviceName }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="isProductsType()">
            <div class="flex-wrap position-relative">
              <kendo-treeview
                kendoTreeViewHierarchyBinding
                [kendoTreeViewCheckable]="checkableSettings"
                kendoTreeViewExpandable
                [filterable]="true"
                filterInputPlaceholder="Filter..."
                [expandOnFilter]="filterExpandSettings"
                [nodes]="productBrands"
                childrenField="clinicProducts"
                [children]="children"
                [hasChildren]="hasChildren"
                [textField]="['name', 'displayName']"
                checkBy="id"
                [(checkedKeys)]="checkedProducts"
                [loadOnDemand]="false"
                [isExpanded]="isExpanded"
                expandBy="id"
                [(expandedKeys)]="expandedKeys"
                (checkedChange)="handleChecking($event)"
                [isChecked]="isChecked"
              >
              </kendo-treeview>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-scrollbar>
  </div>

  <div class="save-changes-container my-3 text-center">
    <button [disabled]="!discountValue" class="btn em-btn em-btn-green" (click)="selectFinished()">Apply</button>
    <button class="btn em-btn em-btn-green ml-2" (click)="activeModal.dismiss('Cancel')">Cancel</button>
  </div>
</div>
