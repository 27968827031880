import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '@app/auth/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private _authService: AuthService) { }

    intercept(req: HttpRequest<any>,
      next: HttpHandler): Observable<HttpEvent<any>> {

        const idToken = this._authService.loginToken;

        // Check that we have a token before adding it to the header
        if (idToken && req.url.indexOf('form.io') === -1 && !req.headers.get("Skip-Token")) {
          req = req.clone({
              headers: req.headers.set('Authorization', `Bearer ${idToken}`)
          });
        }

        return next.handle(req);
    }
}
