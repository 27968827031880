import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { AuthModule } from '@app/auth/auth.module';
import { SharedModule } from '@app/shared/shared.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ClickOutsideModule } from 'ng-click-outside';
import { InlineSVGModule } from 'ng-inline-svg';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AddPatientComponent } from './quick-links/add-patient/add-patient.component';
import { PatientCheckInComponent } from './quick-links/patient-check-in/patient-check-in.component';
import { QuickLinksComponent } from './quick-links/quick-links.component';
import { SearchPatientComponent } from './quick-links/search-patient/search-patient.component';
import { SidenavComponent } from './sidenav.component';
import { UserAccountDialogComponent } from './user-account-dialog/user-account-dialog.component';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    QuickLinksComponent,
    SidenavComponent,
    PatientCheckInComponent,
    AddPatientComponent,
    SearchPatientComponent,
    UserAccountDialogComponent
  ],
  imports: [
    SharedModule,
    AuthModule,
    RouterModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatMenuModule,
    InlineSVGModule,
    ClickOutsideModule,
    DropDownsModule,
    TypeaheadModule.forRoot(),
    TooltipModule
  ],
  exports: [SidenavComponent, SearchPatientComponent]
})
export class SidenavModule {}
