import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ObservationGroup } from '@models/observation/observation-group';
import { ObservationGroupService } from '@services/observation-group.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-observation-group',
  templateUrl: './edit-observation-group.component.html',
  styleUrls: ['./edit-observation-group.component.less'],
})
export class EditObservationGroupComponent implements OnInit, OnDestroy {
  unsub: Subject<void> = new Subject<void>();
  observationGroupId: number;
  observationGroupForm: FormGroup;
  actionType: String;
  isNewObservationGroup: boolean;

  constructor(
    private observationGroupService: ObservationGroupService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      const id = params['id'];
      if (id && id === '_') {
        this.actionType = 'Add';
        this.isNewObservationGroup = true;
        this.observationGroupId = 0;
      } else {
        this.observationGroupId = Number(id);
        this.actionType = 'Update';
        this.isNewObservationGroup = false;
      }
      this.initForm();
    });
  }

  initForm() {
    this.observationGroupForm = new FormGroup({
      id: new FormControl(this.observationGroupId),
      name: new FormControl('', Validators.required),
      price: new FormControl(0, Validators.required),
    });

    if (!this.isNewObservationGroup) {
      this.observationGroupService
        .getObservationGroupById(this.observationGroupId)
        .pipe(takeUntil(this.unsub))
        .subscribe((observationGroup: ObservationGroup) => {
          this.observationGroupForm.controls['name'].setValue(observationGroup.name);
          this.observationGroupForm.controls['price'].setValue(observationGroup.price);
        });
    }
  }

  onSubmit() {
    if (this.isNewObservationGroup) {
      this.observationGroupService
        .addObservationGroup(this.observationGroupForm.value)
        .pipe(takeUntil(this.unsub))
        .subscribe(() => {
          this.observationGroupService.updateRefreshRequired(true);
          this.router.navigate([
            this.router.routerState.snapshot.url.split('(')[0],
            { outlets: { 'action-panel': null } },
          ]);
        });
    } else {
      this.observationGroupService
        .updateObservationGroup(this.observationGroupForm.value)
        .pipe(takeUntil(this.unsub))
        .subscribe(() => {
          this.observationGroupService.updateRefreshRequired(true);
          this.router.navigate([
            this.router.routerState.snapshot.url.split('(')[0],
            { outlets: { 'action-panel': null } },
          ]);
        });
    }
  }

  cancelUpdate() {
    this.observationGroupService.updateRefreshRequired(false);
    this.router.navigate([this.router.routerState.snapshot.url.split('(')[0], { outlets: { 'action-panel': null } }]);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
