import { Review } from './review';

export class ReviewResponse {
  results: Review[] = [];
  page: number;
  pages: number;
  total: number;
  links: any;
  nextPageToken: string;
}
