<div class="document-section patient-note border grey-two-background mt-3 print-element" [ngStyle]="{'max-height': panelHeight  ?  panelHeight + 'px' : 'inherit'}">
  <button class="toggle-button btn-clean px-0 mt-2 mr-2" (click)="isCollapsed = !isCollapsed;">
    <mat-icon>{{!isCollapsed ? 'arrow_drop_up' : 'arrow_drop_down'}}</mat-icon>
  </button>
  <mat-tab-group animationDuration="0ms" (selectedTabChange)="isEdited = false;" disableRipple id="display-patient-note">
    <ng-container *ngFor="let documentName of documentTypesNames">
      <mat-tab [label]="documentName">
        <div  class="px-3 pb-2">
          <div class="d-flex align-items-start">
            <app-textarea-widget
              [minRows]="1"
              [maxRows]="4"
              [textValue]="getPatientNotesByDocumentKey(documentName)"
              (emitText)="savePatientNote($event, documentName)">
            </app-textarea-widget>
          </div>
        </div>
      </mat-tab>
    </ng-container>
  </mat-tab-group>

    <div class="row m-0" id="print-patient-note" >
      <div class="col" *ngFor="let documentName of documentTypesNames">
        <div class="row d-flex align-items-start doctype-name pl-3 ">
          {{documentName}}
        </div>
        <div class="row d-flex align-items-start">
          <app-textarea-widget
            [minRows]="1"
            [maxRows]="4"
            [textValue]="getPatientNotesByDocumentKey(documentName)"
            (emitText)="savePatientNote($event, documentName)">
          </app-textarea-widget>
        </div>
      </div>
    </div>

</div>
