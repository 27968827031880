import { Patient } from '@models/patient';
import * as moment from 'moment';

export class ExternalPrescription {
  id: number;
  patientId: number;
  patient: Patient;
  description: string;
  createdDate: moment.Moment;

  constructor(init?: Partial<ExternalPrescription>) {
    Object.assign(this, init);
  }
}
