import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObservationGroup } from '@models/observation/observation-group';
import { environment } from '@environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ObservationGroupService {
  private refreshRequired = new Subject<any>();
  refreshRequired$ = this.refreshRequired.asObservable();
  observationListsChanged = new Subject<ObservationGroup[]>();

  constructor(private http: HttpClient) {}

  updateRefreshRequired(state: boolean) {
    this.refreshRequired.next(state);
  }

  getObservationGroups() {
    return this.http.get<ObservationGroup[]>(environment.baseUrl + 'api/ObservationGroups/GetObservationGroups');
  }

  getObservationGroupById(obsGroupId: number) {
    return this.http.get<ObservationGroup>(
      `${environment.baseUrl}api/ObservationGroups/GetObservationGroupById/${obsGroupId}`
    );
  }

  addObservationGroup(obsGroup: ObservationGroup) {
    return this.http.post<ObservationGroup>(
      environment.baseUrl + 'api/ObservationGroups/AddObservationGroup',
      obsGroup
    );
  }

  updateObservationGroup(obsGroup: ObservationGroup) {
    return this.http.put<ObservationGroup>(
      environment.baseUrl + 'api/ObservationGroups/UpdateObservationGroup',
      obsGroup
    );
  }

  deleteObservationGroup(obsGroupId: number) {
    return this.http.delete<ObservationGroup>(
      `${environment.baseUrl}api/ObservationGroups/DeleteObservationGroup/${obsGroupId}`
    );
  }
}
