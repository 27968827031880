import { Injectable } from '@angular/core';
import { ServiceCategory } from '@models/service/service-category';
import { ClinicProduct } from '@models/clinic-product';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Tax } from '@models/tax';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { Subject, Observable } from 'rxjs';
import { ServiceDetailTemplateDetail } from '@models/service/service-detail-template';

@Injectable()
export class ServiceTemplatesService {
  private serviceTemplatesUpdated = new Subject<any>();
  serviceTemplatesUpdated$ = this.serviceTemplatesUpdated.asObservable();

  private refreshRequired = new Subject<any>();
  refreshRequired$ = this.refreshRequired.asObservable();

  constructor(private http: HttpClient) {}

  updateRefreshRequired(state: boolean) {
    this.refreshRequired.next(state);
  }

  addServiceTemplate(service: ClinicServiceTemplate) {
    this.prepServiceTemplateForDotNet(service);
    return this.http.post<ClinicServiceTemplate>(environment.baseUrl + 'api/ServiceTemplates', service);
  }

  updateServiceTemplate(serviceTemplate: ClinicServiceTemplate) {
    this.prepServiceTemplateForDotNet(serviceTemplate);
    return this.http.put<void>(environment.baseUrl + 'api/ServiceTemplates/' + serviceTemplate.id, serviceTemplate);
  }

  deactivateServiceTemplate(serviceTemplate: ClinicServiceTemplate) {
    return this.http.put<void>(environment.baseUrl + 'api/ServiceTemplates/Deactivate', serviceTemplate);
  }

  getServiceTemplates(includeCoolsculpting: boolean = true): Observable<ClinicServiceTemplate[]> {
    return this.http.get<ClinicServiceTemplate[]>(
      environment.baseUrl + `api/ServiceTemplates/All/${includeCoolsculpting}`
    );
  }

  // Does not include related entities
  getServiceTemplateList(): Observable<ClinicServiceTemplate[]> {
    return this.http.get<ClinicServiceTemplate[]>(environment.baseUrl + `api/ServiceTemplates/List`);
  }

  getServiceTemplateMultiples(): Observable<ClinicServiceTemplate[]> {
    return this.http.get<ClinicServiceTemplate[]>(environment.baseUrl + 'api/ServiceTemplates/Multiples');
  }

  getServiceTemplateById(serviceId): Observable<ClinicServiceTemplate> {
    return this.http.get<ClinicServiceTemplate>(environment.baseUrl + 'api/ServiceTemplates/' + serviceId);
  }

  getListsForNewServiceTemplate() {
    return this.http.get<GetListsForNewServiceTemplateDTO>(`${environment.baseUrl}api/ServiceTemplates/new`);
  }

  getServiceTemplateForEdit(id) {
    return this.http.get<GetServiceTemplateForEditDTO>(`${environment.baseUrl}api/ServiceTemplates/${id}/edit`);
  }

  getServiceDetailTemplates() {
    return this.http.get<ServiceDetailTemplateDetail[]>(
      `${environment.baseUrl}api/ServiceTemplates/GetServiceDetailTemplates`
    );
  }

  prepServiceTemplateForDotNet(serviceTemplate: ClinicServiceTemplate) {
    serviceTemplate.serviceCategory = null;
    if (serviceTemplate.serviceTemplateBillingCodes) {
      serviceTemplate.serviceTemplateBillingCodes.forEach((code, index, array) => {
        array[index].id = 0;
      });
    }
    if (serviceTemplate.recommendedProducts) {
      serviceTemplate.recommendedProducts.forEach((rp) => {
        rp.clinicProduct = null;
        rp.service = null;
      });
    }
    if (serviceTemplate.serviceForms) {
      serviceTemplate.serviceForms.forEach((sf) => {
        sf.clinicForm = null;
      });
    }
  }

  serviceTemplatesHaveUpdated(value) {
    this.serviceTemplatesUpdated.next(value);
  }

  updateServiceTemplateOnlineBooking(serviceTemplate: ClinicServiceTemplate) {
    this.prepServiceTemplateForDotNet(serviceTemplate);
    return this.http.put(
      environment.baseUrl + 'api/ServiceTemplates/UpdateServiceTemplateOnlineBooking',
      serviceTemplate
    );
  }

  getServiceTemplateBookingSettingsById(serviceId) {
    return this.http.get<ClinicServiceTemplate>(
      `${environment.baseUrl}api/ServiceTemplates/GetServiceTemplateBookingSettingsById/${serviceId}`
    );
  }

  updateRequireCardOnFile(clinicServiceTemplate: ClinicServiceTemplate, requireCardOnFile) {
    return this.http.put<void>(environment.baseUrl + `api/ServiceTemplates/UpdateRequireCardOnFile`, {
      clinicServiceTemplateId: clinicServiceTemplate.id,
      requireCardOnFile: requireCardOnFile,
    });
  }
}

export interface GetListsForNewServiceTemplateDTO {
  serviceCategories: ServiceCategory[];
  clinicProducts: ClinicProduct[];
  taxes: Tax[];
}

export interface GetServiceTemplateForEditDTO {
  clinicServiceTemplate: ClinicServiceTemplate;
  serviceCategories: ServiceCategory[];
  clinicProducts: ClinicProduct[];
  taxes: Tax[];
}
