import { MembershipService } from '@models/memberships/membership-service';
import { Component, OnInit } from '@angular/core';
import { Form, FormControl } from '@angular/forms';
import { DiscountType } from '@models/memberships/discount-type.enum';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceTemplatesService } from '@services/service-templates.service';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { MembershipProduct } from '@models/memberships/membership-product';
import { ItemType } from '@models/memberships/item-type.enum';
import {
  CheckableSettings,
  CheckedState,
  FilterExpandSettings,
  TreeItemLookup,
} from '@progress/kendo-angular-treeview';
import { ClinicProductsService } from '@services/clinic-products.service';
import { ProductBrand } from '@models/product-brand';
import { ClinicProduct } from '@models/clinic-product';

@Component({
  selector: 'app-assign-items-to-membership',
  templateUrl: './assign-items-to-membership.component.html',
  styleUrls: ['./assign-items-to-membership.component.less'],
})
export class AssignItemsToMembershipComponent implements OnInit {
  unsub: Subject<void>;
  loading = false;
  membershipId: number = 0;
  discountValue: number = 10;
  serviceTemplates: ClinicServiceTemplate[] = [];
  enteredServiceName: string;
  selectedMembershipServices: MembershipService[] = [];
  selectedMembershipProducts: MembershipProduct[] = [];
  itemType: ItemType = ItemType.Services;
  ItemType: ItemType;

  public productBrands: ProductBrand[] = [];

  public checkedProducts: any[] = [];
  public expandedKeys: any[] = [];

  public children = (brands: any): Observable<any[]> => of(brands.clinicProducts);
  public hasChildren = (brands: any): boolean => !!brands.clinicProducts;

  public filterExpandSettings: FilterExpandSettings = {
    expandMatches: true,
  };
  public get checkableSettings(): CheckableSettings {
    return {
      checkOnClick: true,
    };
  }

  constructor(
    public activeModal: NgbActiveModal,
    private serviceTemplatesService: ServiceTemplatesService,
    private clinicProductsService: ClinicProductsService
  ) {}

  ngOnInit() {
    this.unsub = new Subject();
  }

  ngAfterContentInit(): void {
    if (this.itemType == ItemType.Services) this.getAllServiceTemplates();
    if (this.itemType == ItemType.Products) this.getBrandsAndProducts();
  }

  getAllServiceTemplates() {
    this.loading = true;
    this.serviceTemplatesService.getServiceTemplates().subscribe((services) => {
      var serviceTemplates = services.filter((service) => !service.serviceName.startsWith('_'));
      if (this.selectedMembershipServices.length > 0) {
        // Filter out selectedServiceTemplates
        this.serviceTemplates = serviceTemplates.filter(
          (st) => this.selectedMembershipServices.findIndex((ms) => ms.clinicServiceTemplateId == st.id) < 0
        );
      } else this.serviceTemplates = serviceTemplates;

      this.loading = false;
    });
  }

  getBrandsAndProducts() {
    this.loading = true;
    this.clinicProductsService.getBrandAndProducts().subscribe((productBrands) => {
      this.productBrands = productBrands;
      if (this.selectedMembershipProducts.length > 0) {
        // Checked products
        this.selectedMembershipProducts.forEach((p) => {
          if (this.isClinicProduct(p.clinicProduct.id)) {
            this.checkedProducts = this.checkedProducts.concat(p.clinicProduct.id);
            // Expand parent
            let parent = this.getClinicProductParent(p.clinicProduct.id);
            if (parent) {
              if (this.expandedKeys.findIndex((id) => id == parent.id) < 0) {
                this.expandedKeys = this.expandedKeys.concat(parent.id);
              }
            }
          }
        });
        // Check if parents need to be checked too.
        // For each expanded parent, check if all children are checked
        this.expandedKeys.forEach((brandId) => {
          let brand = this.getBrand(brandId);
          if (brand) {
            let allChecked = true;
            brand.clinicProducts.forEach((p) => {
              if (!this.isClinicProductChecked(p.id)) {
                allChecked = false;
              }
            });
            if (allChecked) {
              if (this.checkedProducts.findIndex((id) => id == brand.id) < 0) {
                this.checkedProducts = this.checkedProducts.concat(brand.id);
              }
            }
          }
        });
      }
      this.loading = false;
    });
  }

  selectServiceTemplate(serviceTemplate: ClinicServiceTemplate) {
    let membershipService = this.getMembershipService(serviceTemplate);
    this.selectedMembershipServices.push(membershipService);
    this.selectedMembershipServices.sort((a, b) =>
      a.clinicServiceTemplate.serviceName < b.clinicServiceTemplate.serviceName ? -1 : 1
    );
    this.serviceTemplates = this.serviceTemplates.filter((s) => s !== serviceTemplate);
  }

  getMembershipService(serviceTemplate: ClinicServiceTemplate) {
    let membershipService = new MembershipService();
    membershipService.membershipId = this.membershipId;
    membershipService.discountType = DiscountType.Percent;
    membershipService.amount = this.discountValue;
    membershipService.clinicServiceTemplate = serviceTemplate;
    membershipService.clinicServiceTemplateId = serviceTemplate.id;
    return membershipService;
  }

  getMembershipProduct(clinicProduct: ClinicProduct) {
    let membershipProduct = new MembershipProduct();
    membershipProduct.membershipId = this.membershipId;
    membershipProduct.discountType = DiscountType.Percent;
    membershipProduct.amount = this.discountValue;
    membershipProduct.clinicProduct = clinicProduct;
    membershipProduct.clinicProductId = clinicProduct.id;
    return membershipProduct;
  }

  unselectServiceTemplate(membershipService: MembershipService) {
    this.serviceTemplates.push(membershipService.clinicServiceTemplate);
    this.serviceTemplates.sort((a, b) => (a.serviceName < b.serviceName ? -1 : 1));
    this.selectedMembershipServices = this.selectedMembershipServices.filter(
      (s) => s.clinicServiceTemplateId !== membershipService.clinicServiceTemplateId
    );
  }

  selectFinished() {
    if (this.itemType == ItemType.Services) {
      this.selectedMembershipServices.forEach((s) => {
        s.amount = this.discountValue;
      });
      this.activeModal.close(this.selectedMembershipServices);
    }
    if (this.itemType == ItemType.Products) {
      // Remove unselected products
      let unselectedMemberProducts: any[] = [];
      this.selectedMembershipProducts.forEach((mp) => {
        if (this.checkedProducts.findIndex((c) => c.id === mp.clinicProduct.id) < 0) {
          unselectedMemberProducts.push(mp.clinicProduct.id);
        }
      });
      unselectedMemberProducts.forEach((id) => {
        this.selectedMembershipProducts.splice(
          this.selectedMembershipProducts.findIndex((mp) => mp.clinicProduct.id === id),
          1
        );
      });
      // Add any new products
      this.checkedProducts.forEach((id) => {
        if (this.isClinicProduct(id)) {
          if (this.selectedMembershipProducts.findIndex((mp) => mp.clinicProduct.id === id) < 0) {
            // If it isn't already selected
            let clinicProduct = this.getClinicProduct(id);
            this.selectedMembershipProducts.push(this.getMembershipProduct(clinicProduct));
          }
        }
      });
      this.selectedMembershipProducts.forEach((p) => {
        p.amount = this.discountValue;
      });
      this.activeModal.close(this.selectedMembershipProducts);
    }
  }

  isClinicProduct(id: number) {
    // Check Brands
    for (var productBrand of this.productBrands) {
      if (productBrand.id == id) {
        return false;
      } else {
        // Check Products of Brand
        for (var clinicProduct of productBrand.clinicProducts) {
          if (clinicProduct.id == id) {
            return true;
          }
        }
      }
    }
    return false;
  }

  isClinicProductChecked(id: number) {
    if (this.checkedProducts.findIndex((p) => p == id) >= 0) return true;
    return false;
  }

  getClinicProduct(clinicProductId: number) {
    for (var productBrand of this.productBrands) {
      for (var clinicProduct of productBrand.clinicProducts) {
        if (clinicProduct.id == clinicProductId) {
          return clinicProduct;
        }
      }
    }
    return null;
  }

  getBrand(brandId: number) {
    let brand = this.productBrands.find((b) => b.id == brandId);
    return brand;
  }

  getClinicProductParent(clinicProductId: number) {
    for (var productBrand of this.productBrands) {
      for (var clinicProduct of productBrand.clinicProducts) {
        if (clinicProduct.id == clinicProductId) {
          return productBrand;
        }
      }
    }
    return null;
  }

  selectAll() {
    this.selectedMembershipServices.push(
      ...this.serviceTemplates.map((serviceTemplate) => this.getMembershipService(serviceTemplate))
    );
    this.serviceTemplates = [];
  }

  removeAll() {
    this.serviceTemplates.push(...this.selectedMembershipServices.map((service) => service.clinicServiceTemplate));
    this.selectedMembershipServices = [];
  }

  isServicesType(): any {
    if (this.itemType == ItemType.Services) return true;
    else return false;
  }

  isProductsType(): any {
    if (this.itemType == ItemType.Products) return true;
    else return false;
  }

  getTypeString(): any {
    if (this.itemType == ItemType.Products) return 'Products';
    else if (this.itemType == ItemType.Services) return 'Services';
    else if (this.itemType == ItemType.ClinicSupplies) return 'Clinic Supplies';
  }

  public handleChecking(itemLookup: TreeItemLookup): void {
    // If it's a parent
    if (itemLookup.parent == null) {
      // And it's checked, add to expanded keys
      if (this.checkedProducts.indexOf(itemLookup.item.dataItem.id) != -1) {
        if (this.expandedKeys.indexOf(itemLookup.item.dataItem.id) == -1)
          this.expandedKeys = this.expandedKeys.concat(itemLookup.item.dataItem.id);
      }
      // If it's unchecked, remove from expanded keys
      else {
        var index = this.expandedKeys.indexOf(itemLookup.item.dataItem.id);
        if (index != -1) {
          this.expandedKeys.splice(index, 1);
          let newKeys = Object.assign([], this.expandedKeys);
          this.expandedKeys = newKeys;
        }
      }
    }
  }

  // Custom logic handling Indeterminate state when custom data item property is persisted
  public isChecked = (dataItem: any, index: string): CheckedState => {
    if (this.containsItem(dataItem)) {
      return 'checked';
    }

    if (this.isIndeterminate(dataItem.clinicProducts)) {
      return 'indeterminate';
    }

    return 'none';
  };

  private containsItem(item: any): boolean {
    return this.checkedProducts.indexOf(item['id']) > -1;
  }

  private isIndeterminate(items: any[] = []): boolean {
    let idx = 0;
    let item;

    while ((item = items[idx])) {
      if (this.isIndeterminate(item.clinicProducts) || this.containsItem(item)) {
        return true;
      }
      idx += 1;
    }
    return false;
  }

  public isExpanded = (dataItem: any, index: string) => {
    return this.expandedKeys.indexOf(dataItem.id) > -1;
  };

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
