import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { isNullOrUndefined } from '@app/shared/helpers';
import { Clinic } from '@models/clinic';
import { Invoice } from '@models/invoice/invoice';
import { InvoiceLineItem } from '@models/invoice/invoice-line-item';
import { ObservationType, ObservationTypes } from '@models/observation/observation';
import { Patient } from '@models/patient';
import { TargetTypes } from '@models/patient-transaction/target-types';
import { PaymentStatus } from '@models/appointments/payment-status';
import { ServiceProvider } from '@models/service-provider';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { Service } from '@models/service/service';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { ServiceListType } from '@models/service/service-list-type';
import { ServiceNote } from '@models/service/service-note';
import { TabType } from '@models/tab-type.enum';
import { PlannedTreatment } from '@models/treatment-planning/planned-treatment';
import { PlannedTreatmentMultiple } from '@models/treatment-planning/planned-treatment-multiple';
import { TreatmentPlan } from '@models/treatment-planning/treatment-plan';
import { TreatmentState } from '@models/treatment-planning/treatment-state';
import { Visit } from '@models/visit';
import { MasterOverlayService } from '@services/actionpanel.service';
import { AppointmentService } from '@services/appointments.service';
import { ClinicsService } from '@services/clinics.service';
import { CurrentDataService } from '@services/currentData.service';
import { EventsService } from '@services/events.service';
import { InvoicesService } from '@services/invoices.service';
import { ObservationTypesService } from '@services/observation-types.service';
import { ObservationService } from '@services/observation.service';
import { PatientFormService } from '@services/patient-form.service';
import { PatientService } from '@services/patient.service';
import { ServiceEventService } from '@services/service-event.service';
import { ServiceProviderService } from '@services/service-provider.service';
import { ServiceTemplatesService } from '@services/service-templates.service';
import { ServicesService } from '@services/services.service';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { UsersService } from '@services/users.service';
import { VisitService } from '@services/visit.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-planned-treatment-list-panel',
  templateUrl: './planned-treatment-list-panel.component.html',
  styleUrls: ['./planned-treatment-list-panel.component.less'],
})
export class PlannedTreatmentListPanelComponent implements OnInit, OnDestroy {
  @Input()
  set treatmentPlan(tp: TreatmentPlan) {
    tp?.plannedTreatments.forEach((pt) => {
      if (pt.isPlannedTreatmentMultiple) {
        pt['plannedTreatmentMultiple'] = tp.plannedTreatmentMultiples.find(
          (ptm) => ptm.id === pt.plannedTreatmentMultipleId
        );
        pt['partialRefund'] = (pt['plannedTreatmentMultiple'] as PlannedTreatmentMultiple)?.plannedTreatments.some(
          (pt) => pt.paymentStatus == PaymentStatus.Refund
        );
      }
    });
    this._treatmentPlan = tp;
  }
  get treatmentPlan(): TreatmentPlan {
    return this._treatmentPlan;
  }
  private _treatmentPlan: TreatmentPlan;
  @Output() selectedItem = new EventEmitter();
  @Input() isLocked = true;
  @Input() isTreatmentPlanning = false;
  @Input() isAdvancedTreatmentPage = false;
  @Input() isVisitPanel = false;
  @Input() typeOfTab: TabType;
  @Output() addServiceToSchedule: EventEmitter<Service> = new EventEmitter();
  @Input() serviceTemplateId: number;
  @Input() patientId: number;

  get isInvoicePage() {
    //i dont understand how this component was written or how this is attempting to be set - its not right so always resets itself
    //hack way to determine instead for now
    return window.location.pathname.indexOf('patientaccounttab/invoice/') != -1;
  }
  loading: boolean;
  startTime: Date;
  calculatedDuration: number;
  selectedTreatmentIndex: number;
  unsub = new Subject();

  patient: Patient;
  visit: Visit;
  errors: any[] = [];
  _currentInvoiceLineItemIds: InvoiceLineItem[] = null;
  observationType: ObservationType;
  TabType = TabType;

  @Input() currentInvoice: Invoice;

  public serviceListType = ServiceListType;
  public observationTypes = ObservationTypes;
  public serviceDetailTemplate = ServiceDetailTemplate;
  public treatmentState = TreatmentState;
  public clinic: Clinic;
  public isNullOrUndefined = isNullOrUndefined;
  @Input() serviceTemplateIds: number[];

  get currentInvoiceLineItemIds(): number[] {
    return this.currentInvoice && this.currentInvoice.invoiceLineItems
      ? this.currentInvoice.invoiceLineItems.map((ili) => ili.serviceId)
      : [];
  }
  serviceProviders: ServiceProvider[] = [];
  isCurrentUserServiceProvider = false;

  PaymentStatus = PaymentStatus;

  constructor(
    private treatmentPlanService: TreatmentPlanService,
    private appointmentService: AppointmentService,
    private userService: UsersService,
    private clinicsService: ClinicsService,
    private patientService: PatientService,
    private servicesService: ServicesService,
    private observationService: ObservationService,
    private serviceTemplatesService: ServiceTemplatesService,
    private invoicesService: InvoicesService,
    private route: ActivatedRoute,
    private router: Router,
    private serviceEventService: ServiceEventService,
    private visitsService: VisitService,
    private eventsService: EventsService,
    public dialog: MatDialog,
    private currentDataService: CurrentDataService,
    private observationTypesService: ObservationTypesService,
    private masterOverlayService: MasterOverlayService,
    private serviceProviderService: ServiceProviderService,
    private patientFormService: PatientFormService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.prepareServiceProviders();
    // Get the Patient info based on where the control is loaded
    if (this.patientId == 0) {
      // From the Patient Panel
      if (this.route.snapshot.params.patId) {
        this.patientId = this.route.snapshot.params.patId.split('_')[0];
        this.patient = this.patientService.patientPanelPatient;
      } else {
        // From the Visits Panel
        this.patientId = this.route.snapshot.params.patId && +this.route.snapshot.params['patientId'];
        this.patient = this.patientService.patientPanelPatient;
      }
    }

    if (
      (!this.patient || this.patient.patientId == 0 || this.patient.patientId != this.patientId) &&
      this.patientId &&
      this.patientId !== 0
    ) {
      this.patientService.getPatientById(this.patientId).subscribe((patient) => {
        this.patient = patient;
      });
    }
    this.loadVisits();
    this.findObservationType();
    this.onFilterPlannedTreatments();

    if (this.typeOfTab == TabType.Invoice) {
      this.invoicesService.invoiceUpdated$.pipe(takeUntil(this.unsub)).subscribe(() => {
        this.loading = false;
      });
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  private loadVisits() {
    if (this.patientId) {
      this.eventsService.currentDate.pipe(takeUntil(this.unsub)).subscribe((date) => {
        const visitIdString = this.patientId.toString() + date.toDateString();
        // const visitToAdd: Visit = new Visit({
        //   visitId: 0,
        //   visitIdString: visitIdString,
        //   patientId: this.patientId,
        //   patient: null,
        //   visitNotes: '',
        //   patientNotes: '',
        //   cancellationReason: '',
        //   cancellationMessage: '',
        //   isCancellationAlert: false,
        //   cancellationDate: null,
        //   cancelled: false,
        //   appointments: [],
        //   totalVisitCost: 0,
        //   checkedIn: false,
        //   confirmedStatus: VisitConfirmedStatus.Unconfirmed,
        //   noShow: false,
        //   date: date,
        //   createdBy: this.userService.loggedInUser.firstName + ' ' + this.userService.loggedInUser.lastName,
        // });

        this.visitsService
          .getVisitByEvent(visitIdString)
          .pipe(takeUntil(this.unsub))
          .subscribe((visit) => {
            if (visit && visit.visitId && !visit.cancelled) {
              this.visit = visit;
            } else {
              // There was a race condition between this component and visits.component.ts where both were trying to create a visit at the same time
              // Instead, if no visit is found we will wait for visits.component.ts to create the visit and then we will get it
              this.visitsService.visitCreatedListener$.pipe(takeUntil(this.unsub)).subscribe({
                next: async (visit) => {
                  if (visit && visit.patientId == this.patientId && visit.visitIdString === visitIdString) {
                    this.visit = visit;
                    this.visit.patient = await this.patientService
                      .getPatientById(visit.patientId)
                      .pipe(takeUntil(this.unsub))
                      .toPromise();
                  }
                },
              });
              // this.visitsService
              //   .addVisit(visitToAdd)
              //   .pipe(
              //     switchMap((visit: Visit) => {
              //       this.visit = visit;
              //       return this.patientService.getPatientById(visit.patientId);
              //     }),
              //     takeUntil(this.unsub)
              //   )
              //   .subscribe((patient: Patient) => {
              //     this.visit.patient = patient;
              //   });
            }
          });
      });
    }
  }

  //oroginally just for multiples appendage - will now also include # appointments associated with a C/S tx since C/S cant be included in multiples they will never overlap fxn
  public getAvailablePlannedTreatments(plannedTreatment: PlannedTreatment) {
    if (isNullOrUndefined(plannedTreatment.plannedTreatmentMultipleId)) {
      // if (plannedTreatment.service.serviceDetailTemplateId == ServiceDetailTemplate.Coolsculpting){
      //     return  '(' + (plannedTreatment.appointmentCount ? plannedTreatment.appointmentCount : 0) + ' Appts)'
      // }
      // else
      return '';
    } else {
      // Find the PlannedTreatmentMultiple and determine the available services count
      let plannedTreatmentMultiple = plannedTreatment['plannedTreatmentMultiple'];
      if (isNullOrUndefined(plannedTreatmentMultiple)) {
        return '';
      }
      // How many scheduled ones do we have already?
      const totalPlannedTreatmentsCount = plannedTreatmentMultiple.plannedTreatments.length;
      if (totalPlannedTreatmentsCount > 0) {
        const availablePlannedTreatments = plannedTreatmentMultiple.plannedTreatments.filter((pt) => {
          return pt.treatmentState == TreatmentState.Unplanned;
        });
        const usage = '(' + availablePlannedTreatments.length + '/' + totalPlannedTreatmentsCount + '\xa0avail.)';
        return usage;
      }
    }
  }

  private prepareServiceProviders() {
    this.serviceProviderService.getServiceProviderByDate(new Date()).subscribe((sps) => (this.serviceProviders = sps));
  }

  public async createAppointmentNowFromTreatment(treatment: PlannedTreatment, i: number) {
    this.loading = true;
    this.treatmentPlanService.treatmentPlanScheduled = null;
    const serviceTemplate: ClinicServiceTemplate = await this.serviceTemplatesService
      .getServiceTemplateById(treatment.service.templateId)
      .toPromise();
    const treatmentService = await this.servicesService.getServiceById(treatment.service.serviceId).toPromise();

    const appointment = await this.appointmentService.createAppointmentFromTemplate(
      serviceTemplate,
      this.patientService.patientPanelPatient,
      treatment.id
    );
    if (!appointment) {
      this.loading = false;
      return;
    }

    if (treatmentService.observations && treatmentService.observations.length > 0)
      await this.duplicateObservationsFromTreatmentPlan(appointment.service, treatmentService);

    this.selectedTreatmentIndex = i;

    if (treatment.notes && treatment.notes.length > 0) {
      const serviceNote = new ServiceNote({
        serviceNoteId: 0,
        entryDate: new Date(),
        enteredBy: 'Automatically copied from planned treatment #' + treatment.id,
        entryText: treatment.notes,
      });
      await this.servicesService
        .addServiceNote(appointment.appointmentId, serviceNote, TargetTypes.Appointment)
        .toPromise();
    }

    treatment.scheduledServiceId = appointment.serviceId;
    treatment.treatmentState = TreatmentState.Scheduled;
    await this.treatmentPlanService.updatePlannedTreatment(treatment).toPromise();
    this.treatmentPlanService.treatmentPlanScheduled$.next()

    this.currentDataService.treatmentIsDirty = true;
    this.loading = false;
  }

  async duplicateObservationsFromTreatmentPlan(service: Service, serviceToDuplicate: Service): Promise<any> {
    const observations = this.servicesService.applyTreatmentObservationsToService(
      serviceToDuplicate,
      service,
      this.patient
    );
    return this.observationService.addObservations(observations, service.serviceId, false).toPromise();
  }

  public payForPlannedTreatmentMultiple(plannedTreatmentMultipleId: number, typeOfTab: TabType) {
    this.invoicesService
      .getInvoiceByPlannedTreatmentMultipleId(plannedTreatmentMultipleId)
      .pipe(takeUntil(this.unsub))
      .subscribe(
        (invoice: Invoice) => {
          this.loading = false;
          // Goto the Invoice
          if (typeOfTab == TabType.Chart)
            this.router.navigate(['patientaccounttab/invoice', invoice.id], { relativeTo: this.route.parent.parent });
          else {
            this.goToInvoiceFromVisitPanel(invoice.id);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.errors = errorResponse.error.errors;
          this.loading = false;
        }
      );
  }

  public goToInvoiceFromVisitPanel(invoiceId: number) {
    this.patientService.getPatientById(this.patientId).subscribe((pat) => {
      if (!isNullOrUndefined(pat)) {
        this.patientService.patientPanelPatient = pat;
        this.masterOverlayService.masterOverlayState(true);
        this.router.navigate([
          '/schedule',
          {
            outlets: {
              'action-panel': [
                'patient',
                pat.patientId + '_' + '_patientaccounttab',
                'patienttabs',
                'patientaccounttab',
                'invoice',
                invoiceId,
              ],
            },
          },
        ]);
        this.eventsService.closePanel();
      }
    });
  }

  goToScheduledTreatment(plannedTreatment: PlannedTreatment) {
    if (plannedTreatment.scheduledServiceId) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { toServiceId: plannedTreatment.scheduledServiceId },
        queryParamsHandling: 'merge',
      });
    }
  }

  public selectService(service, index: number, plannedTreatmentMultiple: PlannedTreatmentMultiple = null) {
    this.loading = true;
    service.plannedTreatmentMultipleId = plannedTreatmentMultiple?.id;
    if (!isNullOrUndefined(plannedTreatmentMultiple)) {
      service.plannedTreatmentMultipleQuantity = plannedTreatmentMultiple.quantity;
    }

    this.selectedItem.emit(service);
  }

  public async onApplyTreatment(treatment: PlannedTreatment) {
    let service = treatment.service;
    if (this.isLocked || this.isTreatmentPlanning) {
      return;
    }
    const observationType: ObservationTypes =
      this.observationType.name === this.observationTypes.Injectables
        ? this.observationTypes.Injectables
        : this.observationTypes.Coolsculpting;

    this.serviceEventService.emitApplyTreatment(service, observationType);
    this.currentDataService.treatmentIsDirty = true;
    this.loading = false;
  }

  addService(treatmentItem: PlannedTreatment, index: number) {
    const authorizedServiceIndex = this.serviceTemplateIds.findIndex(
      (templateId) => templateId === treatmentItem.service.templateId
    );
    if (authorizedServiceIndex === -1) {
      this.dialog.open(GenericDialogComponent, {
        width: '300px',
        data: {
          title: 'Error',
          content: "This provider can't perform the booked service!",
          confirmButtonText: 'Ok',
          showCancel: false,
        },
      });
      return;
    }
    this.selectedTreatmentIndex = index;
    this.treatmentPlanService.treatmentPlanScheduled = treatmentItem;
    this.addServiceToSchedule.next(treatmentItem.service);
  }

  trackByFn(index) {
    return index;
  }

  private findObservationType() {
    if (!isNullOrUndefined(this.serviceTemplateId)) {
      this.serviceTemplatesService.getServiceTemplateById(this.serviceTemplateId).subscribe((st) => {
        if (!isNullOrUndefined(st)) {
          //const observationTypes = this.currentDataService.observationTypes;
          this.observationTypesService.getAllObservationTypes().subscribe((observationTypes) => {
            if (st.serviceDetailTemplateId === this.serviceDetailTemplate.Injections) {
              this.observationType = observationTypes.filter((obrType) => obrType.name === 'Injectables')[0];
            } else {
              if (st.serviceDetailTemplateId === this.serviceDetailTemplate.Coolsculpting) {
                this.observationType = observationTypes.filter((obrType) => obrType.name === 'Coolsculpting')[0];
              }
            }
          });
        }
      });
    }
  }

  private onFilterPlannedTreatments() {
    if (
      !isNullOrUndefined(this.serviceTemplateId) &&
      !isNullOrUndefined(this.treatmentPlan) &&
      !isNullOrUndefined(this.treatmentPlan.plannedTreatments)
    ) {
      this.treatmentPlan.plannedTreatments = this.treatmentPlan.plannedTreatments.filter((pt) => {
        return pt.service.templateId === this.serviceTemplateId;
      });
    }
  }

  public handleAdd(plannedTreatment: PlannedTreatment, index: number) {
    if (isNullOrUndefined(plannedTreatment) || index < 0) {
      this.loading = false;
      return;
    }

    switch (this.typeOfTab) {
      case TabType.VisitPanel:
        this.loading = false;
        this.addService(plannedTreatment, index);
        break;

      case TabType.Chart:
        this.createAppointmentNowFromTreatment(plannedTreatment, index);
        break;

      case TabType.ChartDetail:
        this.onApplyTreatment(plannedTreatment);
        break;

      case TabType.Invoice:
        this.selectService(plannedTreatment.service, index, plannedTreatment['plannedTreatmentMultiple']);
        break;
    }
  }

  showAddButton(plannedTreatment: PlannedTreatment, tabType?: TabType): boolean {
    if (tabType && this.typeOfTab !== tabType) return false;
    switch (this.typeOfTab) {
      case TabType.Chart:
        return (
          this.serviceTemplateIds?.length > 0 &&
          plannedTreatment?.treatmentState === TreatmentState.Unplanned &&
          !this.isLocked &&
          !this.isTreatmentPlanning &&
          (!plannedTreatment?.isPlannedTreatmentMultiple ||
            (plannedTreatment?.isPlannedTreatmentMultiple && plannedTreatment?.paymentStatus == PaymentStatus.Paid) ||
            (plannedTreatment?.isPlannedTreatmentMultiple && plannedTreatment['partialRefund']))
        );

      case TabType.VisitPanel:
        return (
          this.serviceTemplateIds?.length > 0 &&
          ((plannedTreatment?.isPlannedTreatmentMultiple && plannedTreatment?.paymentStatus == PaymentStatus.Paid) ||
            (!plannedTreatment?.isPlannedTreatmentMultiple &&
              plannedTreatment?.treatmentState === TreatmentState.Unplanned &&
              !this.isLocked))
        );

      case TabType.ChartDetail:
        return (
          this.serviceTemplateIds?.length > 0 &&
          plannedTreatment?.treatmentState === TreatmentState.Scheduled &&
          this.isAdvancedTreatmentPage &&
          plannedTreatment?.paymentStatus === PaymentStatus.Unpaid &&
          !this.isLocked &&
          !this.isTreatmentPlanning
        );

      case TabType.Invoice:
        return (
          this.isInvoicePage &&
          this.currentInvoice &&
          !this.currentInvoice?.isPaid &&
          plannedTreatment?.paymentStatus != PaymentStatus.Paid &&
          !plannedTreatment.service.governmentBilling &&
          (plannedTreatment.service &&
            plannedTreatment.service.serviceId &&
            this.currentInvoiceLineItemIds.indexOf(plannedTreatment.service.serviceId)) == -1 &&
          plannedTreatment.treatmentState == TreatmentState.Unplanned
        );
    }
  }
}
