import { LocationStrategy } from '@angular/common';
import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { FormioComponent, FormioForm, FormioUtils } from '@formio/angular';
import { Patient } from '@models/patient';
import { MedicalInfo } from '@models/patient-portal/medical-info';
import { User } from '@models/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeographyService } from '@services/geography.service';
import { PatientPortalService } from '@services/patient-portal.service';
import { PatientService } from '@services/patient.service';
import { UsersService } from '@services/users.service';
import { allCountries } from 'country-region-data';
import * as moment from 'moment';
import { Subject, forkJoin } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { PasscodeComponent } from '../passcode/passcode.component';
import WizardData from './wizard-form.json';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.less'],
})
export class WizardComponent implements OnInit {
  @ViewChild('formio') formioComponent: FormioComponent;
  unsub: Subject<boolean>;
  user: User;
  loading: boolean = false;

  formObj: FormioForm;
  submissionObj: any;
  patientId: number;
  errors: any[] = [];

  constructor(
    private userService: UsersService,
    private authService: AuthService,
    private patientService: PatientService,
    private geoService: GeographyService,
    private patientPortalService: PatientPortalService,
    private route: ActivatedRoute,
    private router: Router,
    private location: LocationStrategy,
    private modalService: NgbModal
  ) {
    this.patientId = +this.route.snapshot.params.patId;

    // preventing back button in browser
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      // Show the passcode window
      let passcodeModal = this.modalService.open(PasscodeComponent, {
        centered: true,
      });
      passcodeModal.componentInstance.patientId = this.patientId;
      let waitStatus = setInterval(() => {
        clearInterval(waitStatus);
        waitStatus = undefined;
        history.pushState(null, null, window.location.href);
      }, 50);
    });
  }

  ngOnInit() {
    this.unsub = new Subject<boolean>();

    this.userService.loggedInUserUpdated$.pipe(takeUntil(this.unsub)).subscribe({
      next: (user) => {
        this.user = user;
      },
    });

    window.onbeforeunload = () => this.ngOnDestroy();
    this.initViewer();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initViewer();
  }

  private initViewer() {
    this.loading = true;
    this.formObj = { components: [] };
    this.submissionObj = { data: {} };

    this.formObj = WizardData;
    const countryComponent = FormioUtils.getComponent(this.formObj.components, 'country', false);
    countryComponent.data.values = allCountries.map((c) => ({ label: c[0], value: c[0] }));

    forkJoin([
      this.patientService.getPatientById(this.patientId),
      this.patientPortalService.getMedicalInfo(this.patientId),
    ]).subscribe(([patient, medicalInfo]: [Patient, MedicalInfo]) => {
      let patientFields = {};
      patientFields = {
        patientId: patient.patientId,
        firstName: patient.firstName,
        lastName: patient.lastName,
        nickName: patient.nickName,
        gender: patient.gender,
        birthDate: moment(patient.birthDate).format('MM/DD/YYYY'),
        clientId: patient.clientId,
        homeNumber: patient.homeNumber,
        mobileNumber: patient.mobileNumber,
        email: patient.email,
        sendRetentionEmails: patient.sendRetentionEmails,
        sendReviews: patient.sendReviews,
        sendAppointmentEmailNotifications: patient.sendAppointmentEmailNotifications,
        sendAppointmentSMSNotifications: patient.sendAppointmentSMSNotifications,
      };
      if (patient.address) {
        patientFields = {
          ...patientFields,
          address1: patient.address.address1,
          address2: patient.address.address2,
          city: patient.address.city,
          country: patient.address.country,
          postalCode: patient.address.postalCode,
          province: this.geoService.getProvinceCode(patient.address.province),
        };
      }
      patientFields = {
        ...patientFields,
        allergies: medicalInfo.allergies,
        externalPrescriptions: medicalInfo.externalPrescriptions,
        familyHistory: medicalInfo.familyHistory,
        medicalHistory: medicalInfo.medicalHistory,
        surgicalHistory: medicalInfo.surgicalHistory,
      };

      this.onCountryChange(patientFields['country'], 0);
      this.submissionObj = { data: patientFields };
      this.loading = false;
    });
  }

  onFormChange(event: any) {
    if (event.changed?.component?.key === 'country') {
      this.onCountryChange(event.changed.value, 1);
    }
  }

  onCountryChange(countryName: string, currPage: number) {
    let country = allCountries.find((country) => country[0] == countryName);
    if (country) {
      const provinceComponent = FormioUtils.getComponent(this.formObj.components, 'province', false);
      provinceComponent.dataSrc = 'values';
      delete provinceComponent.data.allValues;
      delete provinceComponent.data.custom;
      provinceComponent.data.values = country[2].map((c) => ({ label: c[0], value: c[0] }));

      const postalCodeComponent = FormioUtils.getComponent(this.formObj.components, 'postalCode', false);
      if (country[1] == 'CA') {
        postalCodeComponent.inputMask = 'A9A 9A9';
        postalCodeComponent.applyMaskOn = 'change';
      } else if (country[1] == 'US') {
        postalCodeComponent.inputMask = '99999';
        postalCodeComponent.applyMaskOn = 'change';
      } else if (country[1] == 'AU') {
        postalCodeComponent.inputMask = '9999';
        postalCodeComponent.applyMaskOn = 'change';
      } else {
        delete postalCodeComponent.inputMask;
        delete postalCodeComponent.applyMaskOn;
      }

      this.submissionObj.data.province = null;
      this.submissionObj.data.postalCode = null;

      this.formObj = JSON.parse(JSON.stringify(this.formObj));
      this.formioComponent.formLoad
        .asObservable()
        .pipe(first())
        .subscribe(() => this.formioComponent.formio.setPage(currPage));
    }
  }

  onSubmit(event: any) {
    this.loading = true;
    this.patientPortalService
      .addWizardData(this.patientId, event.data)
      .pipe(takeUntil(this.unsub))
      .subscribe(
        () => {
          this.loading = false;
          this.authService.refreshTokenRequest().subscribe(
            () => {
              this.router.navigateByUrl(`/patientdocumentstab/${this.patientId}/true)`);
            },
            (errorResponse) => {
              this.loading = false;
              if (errorResponse.error) {
                this.errors = errorResponse.error.errors;
              }
            }
          );
        },
        (errorResponse) => {
          this.loading = false;
          if (errorResponse.error) {
            this.errors = errorResponse.error.errors;
          }
        }
      );
  }

  async ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }
}
