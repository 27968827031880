<form-builder #builder [form]="formObj" (change)="onChange($event)" [options]="builderOptions"> </form-builder>

<div class="row">
  <div class="col-2"></div>
  <div class="col-10">
    <hr />
    <h3 class="text-center text-muted">Rendered Form</h3>
    <div class="card">
      <div class="card-body">
        <div class="card bg-light p-3">
          <div class="well jsonviewer">
            <formio [form]="formObj" [refresh]="refreshForm"></formio>
          </div>
        </div>
      </div>
    </div>
    <div *appAuthGuard="developerPolicy">
      <br />
      <hr />
      <h3 class="text-center text-muted">JSON Schema</h3>
      <div class="well jsonviewer">
        <pre id="json"><code class="language-json" #json></code></pre>
      </div>
    </div>
  </div>
</div>
