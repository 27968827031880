<ngx-loading [show]="loading"></ngx-loading>
<ng-scrollbar class="h-100">
  <div class="container" cdkDropList (cdkDropListDropped)="onFavouriteDropped($event)">
    <div class="row pb-2 pr-3" *ngFor="let favourite of services; let i = index" cdkDrag>
      <div class="col">
        <div class="row ml-0 align-items-center">
          <div
            class="favourite-service-color align-self-stretch"
            [ngStyle]="{ 'background-color': serviceIDColour }"
          ></div>
          <div class="panel-box-button d-flex justify-content-center align-items-center draggable" cdkDragHandle>
            <i class="fas fa-arrows-alt-v"></i>
          </div>
          <div class="col d-flex favourite-item grey-two-background navy-font font-weight-bold">
            <div class="flex-grow-1 text-truncate mr-2">{{favourite.service.serviceName }}</div>
            <div>{{ favourite.service.chargeAmount | currency }}</div>
          </div>
          <button
            *ngIf="favourite.service.observations.length > 0 && !isLocked"
            type="button"
            data-test-id="observation-favourite-id"
            [attr.data-test-value]="favourite.service.serviceName"
            class="panel-box-button h-auto"
            (click)="onApplyTreatment(favourite.service)"
          >
            <i class="far fa-plus" title="Apply Details"></i>
          </button>
          <i
            *ngIf="favourite.service.observations.length > 0"
            [ngClass]="{ 'fa-caret-up': expanded === i, 'fa-caret-down': expanded !== i }"
            class="fas ml-2 bigger pointer"
            (click)="onToggleExpand(i)"
          >
          </i>
        </div>

        <ng-container *ngIf="expanded === i">
          <!-- Injections -->
          <ng-container *ngIf="observationType?.name === observationTypes.Injectables">
            <app-item-detail-display
              [service]="favourite.service"
              [observationType]="observationTypes.Injectables"
              [isLocked]="isLocked"
              [itemType]="serviceListType.favourites"
            >
            </app-item-detail-display>
          </ng-container>

          <!-- Coolsculpting -->
          <ng-container *ngIf="observationType?.name === observationTypes.Coolsculpting">
            <app-item-detail-display
              [service]="favourite.service"
              [observationType]="observationTypes.Coolsculpting"
              [isLocked]="isLocked"
              [itemType]="serviceListType.favourites"
            >
            </app-item-detail-display>
          </ng-container>

          <!-- CoolTone -->
          <ng-container *ngIf="observationType?.name === observationTypes.CoolTone">
            <app-item-detail-display
              [service]="favourite.service"
              [observationType]="observationTypes.CoolTone"
              [isLocked]="isLocked"
              [itemType]="serviceListType.favourites"
            >
            </app-item-detail-display>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-scrollbar>
