<ngx-loading [show]="loading"></ngx-loading>

<!-- Clinics -->
<!--<div class="row mt-1 mb-0 teal-background">
  <div class="col">
    <h6 class="white-font uppercase p-2 mb-0">Clinics</h6>
  </div>
</div>
<div class="row pt-2 mx-0">
  <div class="col px-2">
    <mat-form-field>
      <input
        id="clinicsInput"
        type="text"
        matInput
        [formControl]="selectedClinic"
        placeholder="Search for a clinic"
        [matAutocomplete]="clinics"
      />
    </mat-form-field>
    <mat-autocomplete #clinics="matAutocomplete" [displayWith]="clinicDisplayFn">
      <mat-option *ngFor="let clinic of filteredClinics | async" [value]="clinic">
        {{ clinic.name }}
      </mat-option>
    </mat-autocomplete>
  </div>-->
<!--- The button is useless in adding a clinic -->
<!--<div class="col px-2" *ngIf="this.canAddClinic">
    <button
      class="btn em-btn em-btn-green"
      (click)="addClinicToCompany()">
        Add Clinic
    </button>
  </div>
</div>
<div class="row mx-0" *ngFor="let clinic of selectedClinics">
  <div class="col px-2">
    {{ clinic?.address?.address1 }}, {{ clinic?.address?.city }}, {{ clinic?.address?.province }},
    {{ clinic?.address?.country }}
  </div>
  <div class="col px-2" *ngIf="this.canRemoveClinic && filteredClinics.length > 1">
    <button class="btn p-0" (click)="removeClinicFromCompany(clinic)"><i class="fa fa-times-circle"></i></button>
  </div>
</div> -->
<!-- Organization -->
<div class="ml-1">
  <mat-accordion>
    <mat-expansion-panel class="teal-background" #panelList>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h6 class="white-font uppercase">Organization</h6>
        </mat-panel-title>
        <mat-icon class="right">{{ panelList.expanded ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
      </mat-expansion-panel-header>
      <div class="white-background">
        <div class="row mt-2 mx-0">
          <div class="col-2 px-2">
            <mat-form-field>
              <input
                matInput
                placeholder="Company Name"
                aria-label="Company Name"
                [(ngModel)]="editedCompany.name"
                [formControl]="name"
              />
            </mat-form-field>
          </div>
          <div class="col-2 px-2">
            <mat-form-field>
              <input
                matInput
                placeholder="Contact Name"
                class="capitalized"
                aria-label="Contact Name"
                (change)="formatterService.textCapitalize(contactName)"
                [(ngModel)]="editedCompany.contactName"
                [formControl]="contactName"
              />
            </mat-form-field>
          </div>
          <div class="col-2 px-2">
            <mat-form-field>
              <input
                matInput
                placeholder="Address 1"
                class="capitalized"
                aria-label="Address1"
                (change)="formatterService.textCapitalize(addressAddress1)"
                [(ngModel)]="editedCompany.address.address1"
                [formControl]="addressAddress1"
              />
            </mat-form-field>
          </div>
          <div class="col-2 px-2">
            <mat-form-field>
              <input
                matInput
                placeholder="Address 2"
                class="capitalized"
                aria-label="Address2"
                (change)="formatterService.textCapitalize(addressAddress2)"
                [(ngModel)]="editedCompany.address.address2"
                [formControl]="addressAddress2"
              />
            </mat-form-field>
          </div>
          <div class="col-2 p-2">
            <mat-checkbox
              [(ngModel)]="editedCompany.isSharingPatients"
              class="mr-2"
              type="checkbox"
              [formControl]="isSharingPatients"
              aria-label="Sharing Patients"
            ></mat-checkbox>
            <span class="teal-font">Sharing Patients</span>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col-2 px-2">
            <mat-form-field>
              <input
                matInput
                placeholder="Company URL"
                aria-label="Url"
                [(ngModel)]="editedCompany.url"
                [formControl]="url"
                [readonly]="!developerPolicySatisfied"
              />
            </mat-form-field>
          </div>
          <div class="col-2 px-2">
            <mat-form-field>
              <input
                matInput
                placeholder="Contact Phone"
                aria-label="Contact Phone"
                [(ngModel)]="editedCompany.contactPhone"
                (change)="formatterService.formatPhoneNumber(contactPhone); updateSubmitButtonState()"
                [formControl]="contactPhone"
              />
              <mat-error *ngIf="contactPhone.invalid">Not a Valid Phone #</mat-error>
            </mat-form-field>
          </div>
          <div class="col-2 px-2">
            <mat-form-field>
              <mat-select
                placeholder="Country"
                [(ngModel)]="editedCompany.address.country"
                name="usStates"
                (change)="onChangeCountry()"
              >
                <mat-option *ngFor="let country of countriesOfTheWorld" [value]="country">
                  {{ country }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-2 px-2">
            <mat-form-field>
              <mat-select [(ngModel)]="editedCompany.address.province" name="usStates">
                <mat-option *ngFor="let provinceState of provincesAndStates" [value]="provinceState">
                  {{ provinceState }}
                </mat-option>
              </mat-select>
              <mat-placeholder class="placeholder">
                {{ editedCompany.address.country == 'United States' ? 'State' : 'Province' }}
              </mat-placeholder>
            </mat-form-field>
          </div>
        </div>

        <div class="row mx-0 mb-2">
          <div class="col-2 px-2">
            <mat-form-field class="w-50">
              <mat-select placeholder="Timezone" [(ngModel)]="editedCompany.timezone" name="timezones">
                <mat-option *ngFor="let timezone of timezonesOfTheWorld" [value]="timezone.id">
                  {{ timezone.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-2 px-2"></div>
          <div class="col-2 px-2">
            <mat-form-field>
              <input
                matInput
                placeholder="City"
                class="capitalized"
                aria-label="City"
                (change)="formatterService.textCapitalize(addressCity)"
                [(ngModel)]="editedCompany.address.city"
                [formControl]="addressCity"
              />
            </mat-form-field>
          </div>
          <div class="col-2 px-2">
            <mat-form-field>
              <input
                matInput
                aria-label="Postal Code"
                class="uppercased"
                [(ngModel)]="editedCompany.address.postalCode"
                (change)="formatterService.textUppercase(addressPostalCode); updateSubmitButtonState()"
                [formControl]="addressPostalCode"
              />
              <mat-placeholder class="placeholder">{{
                editedCompany.address.country == 'United States' ? 'Zip Code' : 'Postal Code'
              }}</mat-placeholder>
              <mat-error *ngIf="addressPostalCode.invalid">{{
                editedCompany.address.country == 'United States' ? 'Not a Valid Zip Code' : 'Not a Valid Postal Code'
              }}</mat-error>
            </mat-form-field>
          </div>
          <div *appAuthGuard="developerPolicy" class="col px-2">
            <button
              class="btn em-btn em-btn-green mt-1"
              [disabled]="submitButtonDisabledState"
              (click)="updateCompany()"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
