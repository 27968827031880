import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subject, Observable } from 'rxjs';
import { ClinicsService } from '@services/clinics.service';

@Component({
  selector: 'app-org-clinic-general',
  templateUrl: './org-clinic-general.component.html',
  styleUrls: ['./org-clinic-general.component.less']
})
export class OrgClinicGeneralComponent implements OnInit, OnDestroy {
  unsub: Subject<void> = new Subject<void>();
  constructor(private clinicsService: ClinicsService ) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

}
