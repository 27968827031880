<ngx-loading [show]="loading"></ngx-loading>

<div class="modal-header p-1 spring-green-background">
  <button
    type="button"
    class="btn white-font p-0 ml-auto mr-2"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="p-4">
  <h5 class="text-uppercase navy-font text-center mt-0 mb-3">UPDATE DATE</h5>  
  <div class="navy-font d-flex align-items-center font-weight-bold form-field center mb-2">
    Note: Updating the date will change the date for the payment transactions in this invoice as well.</div>
  <div class="row py-3" >
    <div class="col text-center align-items-center">
      <label class="mr-1 bold bigger" [for]="datepicker">Choose a date:</label>
      <kendo-datepicker
        #datepicker
        [(value)]="this.invoiceDate"
        [format]="'yyyy-MM-dd'"
        (valueChange)="onChange($event)" 
        class="bigger"       
      ></kendo-datepicker>  
    </div>
  </div>
  <br />
  <!-- <input
    #channelNameInput
    type="text"
    autoFocus
    class="form-control border-0 grey-three-background"
    placeholder="Choose a date"
    value="{{ channelName }}"
    (input)="onChange($event.target.value)"
  /> -->

  <div class="save-changes-container mt-3 text-center">
    <button [disabled]="!changesMade" class="btn em-btn em-btn-green" (click)="saveChanges(datepicker.value)">
      Update
    </button>
    <button class="btn em-btn em-btn-green ml-2" (click)="activeModal.dismiss('Cancel click')">Cancel</button>
  </div>
</div>
