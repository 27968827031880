import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { environment } from '@environments/environment';
import { EmilySquareInfo } from '@models/square/emily-square-info';
import { RequestCreditCard } from '@models/square/request-credit-card';
import { SquareCard } from '@models/square/square-card';
import { SquareCardInfoRequest } from '@models/square/square-card-info-request';
import { SquarePayment } from '@models/square/square-payment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SquareService {
  private baseUrl = environment.baseUrl + 'api/Square';
  private unsub = new Subject<any>();

  constructor(private http: HttpClient, private dialog: MatDialog) {}

  getEmilySquareInfo() {
    return this.http.get<EmilySquareInfo>(`${this.baseUrl}/EmilySquareInfo`);
  }

  getAuthPageUrl() {
    return this.http.get(`${this.baseUrl}/AuthPageUrl`, { responseType: 'text' });
  }

  getIsSquareLinked() {
    return this.http.get<boolean>(`${this.baseUrl}/IsSquareLinked`);
  }

  getCustomerByPatientId(patientId: number) {
    return this.http.get<any>(`${this.baseUrl}/CustomerByPatientId/${patientId}`);
  }

  getCardsOnFile(patientId: number) {
    return this.http.get<SquareCard[]>(`${this.baseUrl}/PatientCardsOnFile/${patientId}`);
  }

  getCardById(cardId: string) {
    return this.http.get<SquareCard>(`${this.baseUrl}/GetCardById/${cardId}`);
  }

  addCardOnFile(squareCardInfoRequest: SquareCardInfoRequest) {
    return this.http.post<SquareCard>(
      `${this.baseUrl}/PatientCardsOnFile/${squareCardInfoRequest.patientId}`,
      squareCardInfoRequest
    );
  }

  addSquarePayment(squarePayment: SquarePayment) {
    return this.http.post<SquarePayment>(`${this.baseUrl}/SquarePayment`, squarePayment);
  }

  requestCreditCardModal(patientId: number, appointmentId: number) {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '330px',
      data: {
        title: 'Send Request Credit Card Email?',
        content: 'Are you sure you want to send the patient a request to add a new credit card?',
        confirmButtonText: 'Yes',
        showCancel: true,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') {
          return this.requestCreditCard(patientId, appointmentId).subscribe(
            () => {
              this.dialog.open(GenericDialogComponent, {
                data: {
                  content: 'Email sent to patient successfully',
                  confirmButtonText: 'Ok',
                  showCancel: false,
                },
              });
            },
            (error) => {
              var errorMessage = 'An error occurred during processing.<br>';
              if (error.error?.errors) {
                const errors = error.error.errors;
                errorMessage += errors[0].fieldErrors[0];
              } else if (error.error) {
                errorMessage += `Status: ${error.error?.status} - Message: ${error.error?.title}`;
              } else {
                errorMessage += error?.message;
              }

              this.dialog.open(GenericDialogComponent, {
                data: {
                  title: 'Failed to send email',
                  content: '<div class="text-danger">' + errorMessage + '</div>',
                  confirmButtonText: 'Ok',
                  showCancel: false,
                },
              });
            }
          );
        }
      });
  }

  requestCreditCard(patientId: number, appointmentId: number) {
    let requestCreditCard = new RequestCreditCard();
    requestCreditCard.patientId = patientId;
    requestCreditCard.appointmentId = appointmentId;
    return this.http.post(`${this.baseUrl}/RequestCreditCardFromPatient`, requestCreditCard);
  }
}
