<h1 mat-dialog-title>Hours of Operation</h1>
<div mat-dialog-content>
  <div class="closedColumn">
    <div class="itemRow">
      <label>Closed</label>
    </div>

    <div class="itemRow">
      <mat-checkbox [(ngModel)]="businessWeek.sun.closed"></mat-checkbox>
    </div>
    <div class="itemRow">
      <mat-checkbox [(ngModel)]="businessWeek.mon.closed"></mat-checkbox>
    </div>
    <div class="itemRow">
      <mat-checkbox [(ngModel)]="businessWeek.tue.closed"></mat-checkbox>
    </div>
    <div class="itemRow">
      <mat-checkbox [(ngModel)]="businessWeek.wed.closed"></mat-checkbox>
    </div>
    <div class="itemRow">
      <mat-checkbox [(ngModel)]="businessWeek.thu.closed"></mat-checkbox>
    </div>
    <div class="itemRow">
      <mat-checkbox [(ngModel)]="businessWeek.fri.closed"></mat-checkbox>
    </div>
    <div class="itemRow">
      <mat-checkbox [(ngModel)]="businessWeek.sat.closed"></mat-checkbox>
    </div>
  </div>

  <div class="dayOfWeekColumn">
    <div class="itemRow">
      <label>Day of Week</label>
    </div>

    <div class="itemRow">
      <label>Sunday</label>
    </div>
    <div class="itemRow">
      <label>Monday</label>
    </div>
    <div class="itemRow">
      <label>Tuesday</label>
    </div>
    <div class="itemRow">
      <label>Wednesday</label>
    </div>
    <div class="itemRow">
      <label>Thursday</label>
    </div>
    <div class="itemRow">
      <label>Friday</label>
    </div>
    <div class="itemRow">
      <label for="startTime">Saturday</label>
    </div>
  </div>

  <div class="startEndTimeColumn">
    <div class="itemRow">
      <label>Opening Time</label>
    </div>

    <div class="itemRow">
      <kendo-timepicker name="startTime" [steps]="{ minute: 5 }" [(ngModel)]="businessWeek.sun.openTime"
        [(value)]="businessWeek.sun.openTime" [disabled]="businessWeek.sun.closed"></kendo-timepicker>
    </div>
    <div class="itemRow">
      <kendo-timepicker name="startTime" [steps]="{ minute: 5 }" [(ngModel)]="businessWeek.mon.openTime"
        [(value)]="businessWeek.mon.openTime" [disabled]="businessWeek.mon.closed"></kendo-timepicker>
    </div>
    <div class="itemRow">
      <kendo-timepicker name="startTime" [steps]="{ minute: 5 }" [(ngModel)]="businessWeek.tue.openTime"
        [(value)]="businessWeek.tue.openTime" [disabled]="businessWeek.tue.closed"></kendo-timepicker>
    </div>
    <div class="itemRow">
      <kendo-timepicker name="startTime" [steps]="{ minute: 5 }" [(ngModel)]="businessWeek.wed.openTime"
        [(value)]="businessWeek.wed.openTime" [disabled]="businessWeek.wed.closed"></kendo-timepicker>
    </div>
    <div class="itemRow">
      <kendo-timepicker name="startTime" [steps]="{ minute: 5 }" [(ngModel)]="businessWeek.thu.openTime"
        [(value)]="businessWeek.thu.openTime" [disabled]="businessWeek.thu.closed"></kendo-timepicker>
    </div>
    <div class="itemRow">
      <kendo-timepicker name="startTime" [steps]="{ minute: 5 }" [(ngModel)]="businessWeek.fri.openTime"
        [(value)]="businessWeek.fri.openTime" [disabled]="businessWeek.fri.closed"></kendo-timepicker>
    </div>
    <div class="itemRow">
      <kendo-timepicker name="startTime" [steps]="{ minute: 5 }" [(ngModel)]="businessWeek.sat.openTime"
        [(value)]="businessWeek.sat.openTime" [disabled]="businessWeek.sat.closed"></kendo-timepicker>
    </div>
  </div>

  <div class="startEndTimeColumn">
    <div class="itemRow">
      <label>Closing Time</label>
    </div>

    <div class="itemRow">
      <kendo-timepicker name="endTime" [steps]="{ minute: 5 }" [(ngModel)]="businessWeek.sun.closeTime"
        [(value)]="businessWeek.sun.closeTime" [disabled]="businessWeek.sun.closed"></kendo-timepicker>
    </div>
    <div class="itemRow">
      <kendo-timepicker name="endTime" [steps]="{ minute: 5 }" [(ngModel)]="businessWeek.mon.closeTime"
        [(value)]="businessWeek.mon.closeTime" [disabled]="businessWeek.mon.closed"></kendo-timepicker>
    </div>
    <div class="itemRow">
      <kendo-timepicker name="endTime" [steps]="{ minute: 5 }" [(ngModel)]="businessWeek.tue.closeTime"
        [(value)]="businessWeek.tue.closeTime" [disabled]="businessWeek.tue.closed"></kendo-timepicker>
    </div>
    <div class="itemRow">
      <kendo-timepicker name="endTime" [steps]="{ minute: 5 }" [(ngModel)]="businessWeek.wed.closeTime"
        [(value)]="businessWeek.wed.closeTime" [disabled]="businessWeek.wed.closed"></kendo-timepicker>
    </div>
    <div class="itemRow">
      <kendo-timepicker name="endTime" [steps]="{ minute: 5 }" [(ngModel)]="businessWeek.thu.closeTime"
        [(value)]="businessWeek.thu.closeTime" [disabled]="businessWeek.thu.closed"></kendo-timepicker>
    </div>
    <div class="itemRow">
      <kendo-timepicker name="endTime" [steps]="{ minute: 5 }" [(ngModel)]="businessWeek.fri.closeTime"
        [(value)]="businessWeek.fri.closeTime" [disabled]="businessWeek.fri.closed"></kendo-timepicker>
    </div>
    <div class="itemRow">
      <kendo-timepicker name="endTime" [steps]="{ minute: 5 }" [(ngModel)]="businessWeek.sat.closeTime"
        [(value)]="businessWeek.sat.closeTime" [disabled]="businessWeek.sat.closed"></kendo-timepicker>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button (click)="save()" cdkFocusInitial>Set</button>
</div>