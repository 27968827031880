import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-change-user-status',
  templateUrl: './confirm-change-user-status.component.html',
  styleUrls: ['./confirm-change-user-status.component.less']
})
export class ConfirmChangeUserStatusComponent {

  public dialog: MatDialog;

  constructor(
    public dialogRef: MatDialogRef<ConfirmChangeUserStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.data.result = 'cancel';
  }

}
