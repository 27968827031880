import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ServiceProvider } from '@models/service-provider';
import { CacheBuster, Cacheable } from 'ngx-cacheable';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { mapTo, startWith } from 'rxjs/internal/operators';
import { map, share, shareReplay, takeUntil } from 'rxjs/operators';

export const staffBlockedScheduleCacheBuster$ = new Subject<void>();
@Injectable({
  providedIn: 'root',
})
export class ServiceProviderService {
  private serviceProviders = new BehaviorSubject<ServiceProvider[]>([]);
  private serviceProvidersByDate = new BehaviorSubject<ServiceProvider[]>([]);
  private clinicId: number;
  private providerDate: Date;

  private refreshRequired = new Subject<any>();
  refreshRequired$ = this.refreshRequired.asObservable();

  constructor(private http: HttpClient) {}

  updateRefreshRequired(state: boolean) {
    this.refreshRequired.next(state);
  }

  getServiceProvidersInProgress: Observable<ServiceProvider[]> = null;
  getServiceProviders(): Observable<ServiceProvider[]> {
    if (this.clinicId && this.clinicId != parseInt(localStorage.getItem('clinicId'))) {
      this.getServiceProvidersInProgress = null;
    }

    if (!this.getServiceProvidersInProgress) {
      if (!this.clinicId || this.clinicId != parseInt(localStorage.getItem('clinicId'))) {
        this.getServiceProvidersInProgress = this._getServiceProviders().pipe(
          share(),
          map((providers) => {
            this.serviceProviders.next(providers);
            this.getServiceProvidersInProgress = null;
            return providers;
          })
        );
      } else return this.serviceProviders;
    }
    return this.getServiceProvidersInProgress;
  }

  private _getServiceProviders(): Observable<ServiceProvider[]> {
    this.clinicId = parseInt(localStorage.getItem('clinicId'));
    return this.http
      .get<ServiceProvider[]>(environment.baseUrl + 'api/ServiceProvider')
      .pipe(
        map((providers: ServiceProvider[]) =>
          providers.filter((provider) => !this.clinicId || provider.clinicId === this.clinicId)
        )
      );
  }
  getServiceProviderByDateInProgress: Observable<ServiceProvider[]> = null;
  getServiceProviderByDateInProgressDate: Date = null;
  newDateUnsub: Subject<any> = new Subject<any>();

  getServiceProviderByDate(date: Date): Observable<ServiceProvider[]> {
    if (
      this.getServiceProviderByDateInProgressDate &&
      date.getTime() != this.getServiceProviderByDateInProgressDate.getTime()
    ) {
      this.newDateUnsub.next();
      this.getServiceProviderByDateInProgress = null;
      this.getServiceProviderByDateInProgressDate = null;
    }
    if (!this.getServiceProviderByDateInProgress) {
      this.getServiceProviderByDateInProgress = this._getServiceProviderByDate(date).pipe(
        shareReplay(),
        takeUntil(this.newDateUnsub),
        map((providers) => {
          this.serviceProvidersByDate.next(providers as ServiceProvider[]);
          return providers;
        })
      );
    }
    return this.getServiceProviderByDateInProgress.finally(() => {
      this.getServiceProviderByDateInProgress = null; //refresh complete - can do a fresh call next time
      this.getServiceProviderByDateInProgressDate = date;
    });
  }
  private _getServiceProviderByDate(date: Date): Observable<ServiceProvider[]> {
    if (
      this.providerDate == null ||
      this.providerDate != date ||
      this.serviceProvidersByDate.pipe(mapTo(false), startWith(true))
    ) {
      this.providerDate = date;
      const dateParam = date.toDateString();
      return this.http
        .get<ServiceProvider[]>(environment.baseUrl + 'api/ServiceProvider/WorkingByDate/' + dateParam)
        .pipe(
          map((providers) => {
            return providers.filter((provider) => provider.clinicId === parseInt(localStorage.getItem('clinicId')));
          })
        );
    }
    return this.serviceProvidersByDate.map((providers) =>
      providers.filter((provider) => provider.clinicId === parseInt(localStorage.getItem('clinicId')))
    );
  }

  @Cacheable({
    cacheBusterObserver: staffBlockedScheduleCacheBuster$,
  })
  getServiceProviderById(id: string): Observable<ServiceProvider> {
    return this.http.get<ServiceProvider>(environment.baseUrl + 'api/ServiceProvider/' + id);
  }

  @CacheBuster({
    cacheBusterNotifier: staffBlockedScheduleCacheBuster$,
  })
  addServiceProviderVisibleOnSchedule(staffId: string): Observable<any> {
    const response = this.http.post(
      environment.baseUrl + 'api/ServiceProvider/AddServiceProviderVisibleOnSchedule/' + staffId,
      {}
    );
    // this._getServiceProviders();
    return response;
  }

  @CacheBuster({
    cacheBusterNotifier: staffBlockedScheduleCacheBuster$,
  })
  removeServiceProviderVisibleOnSchedule(staffId: string): Observable<any> {
    const response = this.http.post(
      environment.baseUrl + 'api/ServiceProvider/RemoveServiceProviderVisibleOnSchedule/' + staffId,
      {}
    );
    // this._getServiceProviders();
    return response;
  }

  @CacheBuster({
    cacheBusterNotifier: staffBlockedScheduleCacheBuster$,
  })
  removeAllServiceProvidersVisibleOnSchedule(): Observable<any> {
    const response = this.http.post(
      environment.baseUrl + 'api/ServiceProvider/RemoveAllServiceProvidersVisibleOnSchedule',
      {}
    );
    // this._getServiceProviders();
    return response;
  }

  @CacheBuster({
    cacheBusterNotifier: staffBlockedScheduleCacheBuster$,
  })
  updateServiceProvidersOrder(staffIds: string[]): Observable<void> {
    return this.http.post<null>(environment.baseUrl + 'api/ServiceProvider/UpdateServiceProvidersOrder', staffIds);
  }

  updateServiceProviderBookingSettings(provider: ServiceProvider) {
    return this.http.put(environment.baseUrl + 'api/ServiceProvider/UpdateServiceProviderBookingSettings', provider);
  }

  updateServiceProviderOnlineAvailability(provider: ServiceProvider, isAvailable: boolean) {
    return this.http.put(environment.baseUrl + 'api/ServiceProvider/UpdateServiceProviderOnlineAvailability', {
      id: provider.id,
      availableForOnlineBooking: isAvailable,
    });
  }

  getServiceProvidersOnlineBooking() {
    return this.http
      .get<ServiceProvider[]>(environment.baseUrl + 'api/ServiceProvider')
      .pipe(
        map((providers: ServiceProvider[]) =>
          providers.filter((provider) => !this.clinicId || provider.clinicId === this.clinicId)
        )
      );
  }
}
