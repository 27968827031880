import { Component, Input, OnInit } from '@angular/core';
import { BillingFrequency } from '@models/memberships/billing-frequency.enum';
import { DiscountType } from '@models/memberships/discount-type.enum';
import { Membership } from '@models/memberships/membership';
import { PatientMembership } from '@models/memberships/patient-membership';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MembershipsService } from '@services/memberships.service';
import { PatientMembershipService } from '@services/patient-membership.service';

@Component({
  selector: 'app-membership-selection',
  templateUrl: './membership-selection.component.html',
  styleUrls: ['./membership-selection.component.less'],
})
export class MembershipSelectionComponent implements OnInit {
  @Input() patientId: number;

  loading = false;
  memberships: Membership[] = [];
  selectedMemberships: Membership[] = [];
  patientMemberships: PatientMembership[] = [];
  invoiceMembershipIds: number[] = [];

  DiscountType = DiscountType;
  BillingFrequency = BillingFrequency;

  constructor(
    private activeModal: NgbActiveModal,
    private membershipsService: MembershipsService,
    private patientMembershipService: PatientMembershipService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  private async loadData() {
    this.loading = true;
    var [memberships, patientMemberships] = await Promise.all([
      this.membershipsService.getMemberships(false).toPromise(),
      this.patientMembershipService.getPatientMemberships(this.patientId, false).toPromise(),
    ]);
    this.memberships = memberships;
    this.patientMemberships = patientMemberships;
    this.loading = false;
  }

  selectionChanged(checked: boolean, membership: Membership) {
    if (checked) {
      this.selectedMemberships.push(membership);
    } else {
      this.selectedMemberships.splice(this.selectedMemberships.indexOf(membership), 1);
    }
  }

  isActive(membership: Membership) {
    const existingMembership = this.patientMemberships.find((pm) => pm.membershipId == membership.id);
    const invoiceMembership = this.invoiceMembershipIds.some((id) => id == membership.id);
    if (existingMembership) return existingMembership.expired == false;
    if (invoiceMembership) return true;
    return false;
  }

  closeModal(memberships?: Membership[]) {
    memberships?.sort((a, b) => a.name.localeCompare(b.name));
    this.activeModal.close(memberships);
  }
}
