import { Component, OnInit } from '@angular/core';
import { Policies } from '@app/auth/auth-policies';
import { EditChannelComponent } from '@app/messaging/dialogs/edit-channel/edit-channel.component';
import { ChatChannel } from '@app/messaging/models/chat-channel.model';
import { ChatChannelsService } from '@app/messaging/services/chat-channels.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-messaging-sidebar-channels',
  templateUrl: './messaging-sidebar-channels.component.html',
  styleUrls: ['./messaging-sidebar-channels.component.less'],
})
export class MessagingSidebarChannelsComponent implements OnInit {
  messagingPolicy = Policies.messaging;

  constructor(
    private modalService: NgbModal,
    public channelService: ChatChannelsService
  ) {}

  ngOnInit() {}

  onAddChannel() {
    const assignModal = this.modalService.open(EditChannelComponent, {});
  }

  onChannelSelected(channel: ChatChannel) {
    this.channelService.channelSelected(channel);
  }
}
